import PrimaryButton from 'components/shareable/PrimaryButton';
import { Body2, Subtitle1, Subtitle2 } from 'components/shareable/Typography';
import React from 'react';
import styled from 'styled-components';
import { Container, OutlinedButton, StyledLabel, TextButton } from 'styles/shared';

export type MarketingSelectorOption = {

    title: string;
    description: string;
    image: string;

}
// Define the types for the component's props
type MarketingSelectorVerticalProps = {
    options: MarketingSelectorOption[]; // The source URL for the image
    selected: MarketingSelectorOption | null; // The title text
    onSelected: ({
        title,
        description,
        image,
    }: MarketingSelectorOption) => void; // The description text
    mobileView: boolean;
};

// Styled-components for the various elements
type ButtonProps = {
    selected: boolean;
}
const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: white;
  padding: 15px;
  border-radius: 10px;
  transition: all 0.3s;
  border 1px solid #EDECF2;
  max-width: 600px;
  gap: 14px;
  text-align: left;

  &:hover {
    background: #fcf8ff;
    cursor: pointer;
  }

  ${({ selected }) => selected && `
  background: #f6ebff;
  cursor: pointer;
  border 1px solid #8800ff;

  &:hover {
    background: #f6ebff;
  }
  `}
`;

const ImageBackground = styled(Container)`
  border-radius: 12px;
  background: #F5EBFF;
  width: 60px;
  height:60px;
  flex-shrink: 0;
  
`
const Image = styled.img`
  height: auto; 
  width: 50%;
  object-fit: contain;
`;


// The component definition using styled-components
const MarketingSelectorVertical: React.FC<MarketingSelectorVerticalProps> = ({
    options,
    selected,
    onSelected,
    mobileView
}) => {
    return (
        <Container display='flex' flexDirection='column' gap={mobileView ? "12px" : "24px"}>

            {options.map((option: MarketingSelectorOption, index) => {
                const {
                    title,
                    description,
                    image,
                } = option;

                const currentlySelected = selected ? selected.title === title : false
                return (
                    <ButtonContainer selected={currentlySelected} onClick={() => onSelected(option)}
                    >
                        <ImageBackground display="flex" alignItems='center' justifyContent='center'>
                            <Image src={image} alt={title} />
                        </ImageBackground>
                        <Container

                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems='flex-start'
                            width="100%">
                            <Subtitle1>{title}</Subtitle1>
                            <Body2>{description}</Body2>
                        </Container>
                    </ButtonContainer>
                )
            })}
        </Container>

    );
};

export default MarketingSelectorVertical;
