import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  localReleaseContainer: {
    borderRadius: 10,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    gap: 16,
    backgroundColor: 'rgba(136, 0, 255, 0.08)',
  },
  globalIconContainer: {
    borderRadius: 8,
    padding: 8,
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  globalIcon: {
    color: "#8800FF",
    height: 24,
    width: 24,
  },
  autoPresaveSwitch: {
    marginTop: 14,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#E5E7EB',
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#8800FF',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#8800FF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      borderRadius: 34,
    },
    '& .MuiSwitch-track': {
      marginTop: 2,
      marginLeft: 1,
      borderRadius: 34,
      width: 42,
      height: 24,
      backgroundColor: '#E5E7EB',
      opacity: 1,
    },
  },
}));
