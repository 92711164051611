import dayjs from 'dayjs';
import moment from 'moment';
import momentTz from 'moment-timezone';

export const getDiffInDays = (duration: {
  startDate: string | Date | null;
  endDate: string | Date | null;
}) => {
  const { startDate, endDate } = duration;
  const formattedStartDate = moment(startDate).startOf('day');
  const formattedEndDate = moment(endDate).startOf('day');
  const diff = Math.abs(moment(formattedStartDate).diff(formattedEndDate, "days"));
  return diff;
}

export const areValidDates = (dates: { startDate: string, endDate: string }) : ({
  validDates: boolean,
  formattedEndDate: Date | null,
  formattedStartDate: Date | null
}) => {
  const today = dayjs();
  const startDateFormatted = dayjs(dates.startDate);
  const endDateFormatted = dayjs(dates.endDate);
  const validStartDate = startDateFormatted.isValid() &&
    (startDateFormatted.isAfter(today, 'day') || startDateFormatted.isSame(today, 'day'));
  const validEndDate = endDateFormatted.isValid() &&
    validStartDate &&
    endDateFormatted.isAfter(startDateFormatted, 'day') &&
    endDateFormatted.isAfter(today, 'day')

  return {
    validDates: validEndDate && validStartDate,
    formattedStartDate: startDateFormatted.isValid() ? startDateFormatted.toDate() : null,
    formattedEndDate: validEndDate ? endDateFormatted.toDate() : null,
  }
}

export const getFormattedDateLastUpdate = (date: string): string => {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');
  if (today.isBefore(date)) return `Today at ${moment(date).format('hh:mm A')}`;
  else if (yesterday.isBefore(date)) return `Yesterday at ${moment(date).format('hh:mm A')}`;
  else return moment(date).format('MMMM DD, YYYY');
}

export const getFormatDateCampaigns = (date: string, timezone?: string) => {
  if (timezone) {
    const abbrev = momentTz.tz(timezone).zoneAbbr();
    return momentTz(date).tz(timezone).format(`MMM D, YYYY h:mma [${abbrev}]`);
  }
  return moment(date).format('MMM D, YYYY ha');
}
