/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FunctionComponent } from "react";
import InfoSection from "components/InfoSection";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import GeographicTargeting from "../../SongAds/TargetingView/GeographicTargeting";
import { FbSuggestion } from "types/global";
import { Option as SpotifyArtistsOption } from "./Components/AutoCompleteSpotifyArtists/utils";
import { TargetingExistingCustomAudiences } from "../../Components/TargetDemographics/TargetingExistingCustomAudiences";
import { TargetingViewProps } from "../../Components/TargetingView/utils";
import TargetingAdditionalPageAudiences from "../../Components/TargetDemographics/TargetingAdditionalPageAudiences";
import AudienceTargeting from "../../Components/TargetingView/AudienceTargeting";
import { CAMPAIGN_TYPES } from "../../../../../constants";

const TITLE = "Audience Targeting";
const DESCRIPTION =
  "Refine the audiences you want to target with your marketing.";

const TargetingView: FunctionComponent<TargetingViewProps> = ({
  fbSuggestions,
  isBelowRecommendedBudget,
  loading,
  selectedFBAdAccount,
  customAudiences,
  setCustomAudiences,
  genre,
  showTrashIcon,
  isResponsive,
  deleteIconColor,
  showSaveButton,
  handleIsEditing,
  handleTargets,
  handleAudiences,
  handleArtists,
  handleTotalBudget,
  setShowTargetingView,
  setAdditionalPagesAudiences,
  additionalPageAudiences,
  selectedFBPage
}) => {
  const [audiences, setAudiences] = useState([] as FbSuggestion[]);
  const [artists, setArtists] = useState<SpotifyArtistsOption[]>([]);

  const [openTargetingModal, setOpenTargetingModal] = useState(false);
  const [openAdditionalPagesModal, setOpenAdditionaPagesModal] = useState(false);

  const handleTargetingModalClose = () => setOpenTargetingModal(false);
  const handleAdditionalPageModalClose = () => setOpenAdditionaPagesModal(false);
  const content = loading ? (
    <LoadingIndicator>
      <p className="mt-1 text-center px-1">
        Symphony is generating audiences that are most optimal for increasing
        link clicks.
      </p>
    </LoadingIndicator>
  ) : (
    <React.Fragment>
      <AudienceTargeting
        {...{
          setAudiences,
          setArtists,
          genre,
          deleteIconColor,
          setOpenTargetingModal
        }}
        openTargetingModal={() => setOpenTargetingModal(true)}
        openAdditionalPagesModal={() => setOpenAdditionaPagesModal(true)}

        customAudiences={customAudiences}
        setCustomAudiences={setCustomAudiences}

        additionalPageAudiences={additionalPageAudiences}
        setAdditionalPagesAudiences={setAdditionalPagesAudiences}

        initialTargetingAudiences={fbSuggestions}
      />

      <GeographicTargeting
        {...{
          campaignType: CAMPAIGN_TYPES.boost_instagram,
          isBelowRecommendedBudget,
          audiences,
          artists,
          setShowTargetingView,
          showTrashIcon,
          isResponsive,
          deleteIconColor,
          handleTargets,
          handleAudiences,
          handleArtists,
          handleTotalBudget,
          handleIsEditing,
          showSaveButton
        }}
      />

      {openTargetingModal && <TargetingExistingCustomAudiences
        selectedFBAdAccount={selectedFBAdAccount}
        setCustomAudiences={setCustomAudiences}
        customAudiences={customAudiences}
        openTargetingModal={openTargetingModal}
        setOpenTargetingModal={setOpenTargetingModal}
        discardChanges={handleTargetingModalClose}
        isMobile={false}
        handleOnChangeDemographics={console.log}
        handleClose={handleTargetingModalClose}
      />}

      {openAdditionalPagesModal && <TargetingAdditionalPageAudiences
        selectedFBPage={selectedFBPage}
        setCustomAudiences={setAdditionalPagesAudiences}
        customAudiences={additionalPageAudiences}
        openTargetingModal={openAdditionalPagesModal}
        setOpenTargetingModal={setOpenAdditionaPagesModal}
        discardChanges={handleAdditionalPageModalClose}
        isMobile={false}
        handleClose={handleAdditionalPageModalClose}
      />}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <InfoSection title={TITLE} description={DESCRIPTION} />
      {content}
    </React.Fragment>
  );
};

export default TargetingView;
