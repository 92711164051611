import { FunctionComponent, useState, useEffect } from 'react';
import { Dialog, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { toast, ToastOptions } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import Axios from 'helpers/Interceptor';
import { ButtonConfirm, ButtonCancel, ButtonsContainer, InputContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import { StyledLabel } from 'styles/shared';

interface Props {
    open: boolean;
    currentSpendLimit: number;
    defaultSpendLimit: number;
    closeModal: () => void;
    updateSpendLimit: (newLimit: number) => void;
    brandSlug: string;
}

const EditSpendLimitModal: FunctionComponent<Props> = ({
    open,
    currentSpendLimit,
    defaultSpendLimit,
    closeModal,
    updateSpendLimit,
    brandSlug
}) => {
    const [spendLimit, setSpendLimit] = useState<number>(currentSpendLimit);
    const [loading, setLoading] = useState<boolean>(false);
    const [spendLimitError, setSpendLimitError] = useState<boolean>(false);

    const classes = useStyles();
    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setSpendLimit(currentSpendLimit);
            }, 500);
        }
    }, [open]);

    const handleSpendLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newLimit = Number(event.target.value);
        if (newLimit > currentSpendLimit) {
            setSpendLimitError(true);
        } else {
            setSpendLimitError(false);
        }
        setSpendLimit(newLimit);
    };

    const resetToDefaultLimit = () => {
        setSpendLimit(defaultSpendLimit);
    };

    const handleConfirmEditSpendLimit = async () => {
        setLoading(true);
        try {
            await Axios.post(
                `/subscription/brand/${brandSlug}/usage/campaigns`, {
                newBudget: spendLimit
            }
            )
            updateSpendLimit(spendLimit);
            toast.success('Spend limit updated!', { /* your toast options */ });
        } catch (e) {
            // Handle error
        } finally {
            closeModal();
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} classes={{ root: classes.rootDialog }}>
            <ModalContainer width={setResponsiveView ? "auto" : "678px"} padding={setResponsiveView ? "32px 24px" : "32px"}>
                <HeaderModal
                    closeModal={closeModal}
                    title="Admin: Edit User's Monthly Spend Limit"
                    subtitle="You can edit the user's monthly spend limit to any amount lower than what it's at."
                />
                <InputContainer>
                    <TextField
                        InputProps={{ classes: { root: classes.inputBorder } }}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        type="number"
                        defaultValue={''}
                        value={spendLimit}
                        onChange={handleSpendLimitChange}
                    />
                    {spendLimitError && <StyledLabel
                        color="red"
                        fontSize={16}
                    >Cannot exceed current spend limit of ${currentSpendLimit}</StyledLabel>}

                    <button onClick={resetToDefaultLimit}>Reset to Default Limit</button>

                </InputContainer>
                <ButtonsContainer flexDirection={setResponsiveView ? "column-reverse" : "row"}>
                    <ButtonCancel disabled={loading} onClick={closeModal} width={setResponsiveView ? "100%" : "fit-content"}>
                        Cancel
                    </ButtonCancel>
                    <ButtonConfirm disabled={loading || spendLimitError} onClick={handleConfirmEditSpendLimit} width={setResponsiveView ? "100%" : "50%"}>
                        {loading ? "Updating..." : "Update Spend Limit"}
                    </ButtonConfirm>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    );
};

export default EditSpendLimitModal;