import { TextField } from "@material-ui/core"
import {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from "react"
import PrimaryButton from "components/shareable/PrimaryButton"
import useStyles, { ActionsContainer, AlertContainer } from "./styles"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { Body2, Subtitle1 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { PulledUsers, addNewUser } from "../UsersTab/api"
import { checkIfIsProTier, isValidEmail } from "utils"
import { Row } from "../UsersTab/utils"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { getAlertProps } from "./utils"

interface Props {
  brandId?: number | null
  open: boolean
  users: Row[]
  hasReachedLimit?: boolean
  onSaveUsers: (pulledUsers: PulledUsers) => void
  onClose: () => void
}

const AddUserModal: FunctionComponent<Props> = ({
  brandId,
  open,
  users,
  hasReachedLimit,
  onSaveUsers,
  onClose,
}: Props) => {
  const classes = useStyles()
  const { mobileView } = useMediaBreakpoints()
  const { currentBrand } = useContext(CurrentBrandContext)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const isTeamPro = checkIfIsProTier(currentBrand) && currentBrand?.subscription?.tier === 'organization'
  const { type, body, subtitle } = getAlertProps(hasReachedLimit, isTeamPro)
  const isOnOldPlan = currentBrand?.subscription?.onOldPlan

  const handleAddUser = async () => {
    let sanitizedEmail = email.toLowerCase().trim()
    await addNewUser({
      newUserEmail: sanitizedEmail,
      users,
      brandId,
      mobileView,
      onChangeNewUserEmail: setEmail,
      onLoading: setLoading,
      onSaveUsers,
    })
    onClose()
  }

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()
    setError(value.length > 0 && !isValidEmail(value))
    setEmail(value)
  }

  const handleClose = () => !loading && onClose()

  return (
    <DialogPaper {...{ open }} onClose={handleClose}>
      <Fragment>
        <HeaderModal
          HeaderIcon={
            <UserIcon width={32} height={32} className={classes.userIcon} />
          }
          title="Add a new member"
          subtitle="Manage the members who can access to your team"
          closeModal={handleClose}
        />
        <TextField
          {...{ error }}
          value={email}
          helperText={error && "Please type a valid email"}
          fullWidth
          placeholder="Email address"
          variant="outlined"
          onChange={handleChangeEmail}
        />
        {!isOnOldPlan && (
          <AlertContainer {...{ type }}>
            <Subtitle1>{subtitle}</Subtitle1>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{body}</Body2>
          </AlertContainer>
        )}
        <ActionsContainer>
          <PrimaryButton
            {...{ loading }}
            disabled={error || loading || !email.length}
            width="159px"
            text="Send invite"
            onClick={handleAddUser}
          />
        </ActionsContainer>
      </Fragment>
    </DialogPaper>
  )
}

export default AddUserModal
