import { FunctionComponent } from "react"
import { Container, Slide } from "./styles"
import { Body1, Headline3 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"

interface Props {
  action?: string
  quantity?: string
  units?: string
  testimony: string
  witness: string
  occupation?: string
  picture?: string
  slideHeight?: string
  slidePadding?: string
}

const Testimonial: FunctionComponent<Props> = ({
  testimony,
  witness,
  picture,
  slideHeight,
  slidePadding,
}: Props) => {
  return (
    <Slide {...{ slideHeight, slidePadding }} backgroundImage={picture}>
      <Headline3 color={SystemColors.PAPER_CONTAINER}>“{testimony}”</Headline3>
      <Container marginTop="24px">
        <Body1 color={SystemColors.PAPER_CONTAINER}>- {witness}</Body1>
      </Container>
    </Slide>
  )
}

export default Testimonial
