import styled from "styled-components"

interface PostAuthContainerProps {
  backgroundColor?: string
  height?: string
}

interface RouterContainerProps {
  backgroundColor?: string
  height?: string
  minHeight?: string
}

export const RouterContainer = styled.div`
  background-color: ${({ backgroundColor }: RouterContainerProps) =>
    backgroundColor || "#ffffff"};
  min-height: ${({minHeight }: RouterContainerProps) => minHeight || "100vh"};
  height: ${({ height }: RouterContainerProps) => height || "100%"};
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }
`

export const PostAuthContainer = styled.div`
  overscroll-behavior-y: contain;
  overscroll-behavior-x: none;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 0px;
  height: ${({ height }: PostAuthContainerProps) => height || "fit-content"};
  background-color: ${({ backgroundColor }: PostAuthContainerProps) =>
    backgroundColor || "#F5F3F6"};
  @media (min-width: 1024px) {
    padding-bottom: 0px;
    flex: 1 1 0%;
    overflow: auto;
  }
`
