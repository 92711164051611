import { Fragment, FunctionComponent } from 'react';
import { OverallCampaignStatus, OverallCampaignStatusType } from 'types/global';

const {
  ACTIVE,
  PENDING,
  IN_REVIEW_AFTER_ACTIVE,
  PAUSED,
  DENIED,
  LIVE_WITH_ISSUES,
} = OverallCampaignStatus;

const AdsStatusColor: Record<string, string> = {
  "denied": "bg-red-500",
  "error": "bg-red-500",
  "to_retry": "bg-yellow-500",
  "in_review": "bg-yellow-500",
  "live_with_no_stats": "bg-green-500",
  "live_with_issues": "bg-green-500",
}

interface Props {
  overallCampaignStatus: OverallCampaignStatusType;
}

const AdsStatusMessage: FunctionComponent<Props> = ({
  overallCampaignStatus,
}: Props) => {
  const { status } = overallCampaignStatus

  if ([PENDING, ACTIVE, IN_REVIEW_AFTER_ACTIVE, DENIED].includes(status)) {
    return <Fragment />
  }
  const color = AdsStatusColor[status] || "bg-yellow-500"

  if (
    overallCampaignStatus.status === PAUSED
    || overallCampaignStatus.status === LIVE_WITH_ISSUES
  ) {
    return <Fragment />;
  }

  return (
    <div className={`rounded-sm ${color} text-white text-md py-3 px-3 text-center`}>
      <p className="w-8/12 m-auto">{overallCampaignStatus.note}</p>
    </div>
  );
}

export default AdsStatusMessage;