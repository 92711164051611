import millify from "millify";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockPresavesByDayImage from "assets/images/unlock-presaves-by-day.svg";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import dayjs from "dayjs";
import { Fragment } from 'react';
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "../constants";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";

type StatsPerDay = {
    cpc: number,
    cpic: number,
    date: string,
    clicks: string,
    inlineClicks: number,
    spend: number,
    totalClicks: number,
    totalImpressions: number,
    totalReach: number,
    totalSpend: number
    costPerConversion: number,
    conversions: number,
}

interface DailyStatsProps {
    campaignDetails: {
        startDate: string;
        endDate: string;
        platform_details: any;
        ad_account: string;
    },
    dailyStats: StatsPerDay[],
    isPro: boolean,
    loading: boolean,
    shown?: boolean,
    d1?: number | null | undefined,
    currency?: CurrencyCodesType
    hasConversions?: boolean
    conversionsEventName?: string
}

export default function DailyStats(props: DailyStatsProps) {

    const {
        dailyStats,
        isPro,
        loading,
        shown,
        d1 = null,
        currency,
        hasConversions,
        conversionsEventName,
    } = props

    const theme = useTheme()

    const hideBannerImage = useMediaQuery(theme.breakpoints.down(701));

    let processedDailyStats = null
    if (dailyStats) {
        processedDailyStats = dailyStats
        if (d1) {
            processedDailyStats = dailyStats.map((o: StatsPerDay) => {
                let dayJsDate = dayjs(o.date)
                if (d1) {
                    if (d1 > 0) {
                        dayJsDate = dayJsDate.add(d1, 'day')
                    } else if (d1 < 0) {
                        dayJsDate = dayJsDate.subtract(1, 'day')
                    }
                }
                const formattedDate = dayJsDate.format('MM/DD')

                return {
                    ...o,
                    date: formattedDate
                }
            })
        }
    }

    if (!shown) return <Fragment />
    if (loading || !dailyStats || !processedDailyStats) {
        return (
            <div className="mx-auto my-6 flex-col flex items-center ">
                <NewLoader black />
                <p className="mt-1 text-center">Loading Daily Stats...</p>
            </div>
        );
    }

    if (!isPro) {
        return (
            <>
                <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
                    <UnlockDataBanner
                        source="IG Campaign Details - Daily Stats"
                        title="Track daily growth"
                        description="Go Pro to see how your marketing is performing over time"
                        image={UnlockPresavesByDayImage}
                        hideImage={hideBannerImage}
                    />
                </div>
                <div className="px-6">
                    {hasConversions && (
                        <>
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-semibold flex flex-row gap-1 items-center">Cost-per-Conversion Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                                <p className="text-sm text-gray-500">
                                    This graph shows how much it cost to actually get someone to complete the event "{conversionsEventName}". If the cost-per-conversion is going down or is stable, it means that the campaign is performing well.
                                </p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-semibold flex flex-row gap-1 items-center">Conversions Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                                <p className="text-sm text-gray-500">
                                    This graph shows total number of conversions for your goal “{conversionsEventName}”. If it stays steady or goes up over time, it means your campaign is continuing to perform well.
                                </p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                        </>
                    )}
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex gap-1 flex-row items-center">Daily Spend<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">This graph shows the daily spend on marketing. This may fluctuate up and down as the ads optimize to find the best audiences to target.</p>
                    </div>
                    <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex gap-1 flex-row items-center">Clicks Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">
                            {hasConversions ?
                                'This graph shows how the cost-per-click to your landing page. If the cost-per-click is going down or is stable, it means that the campaign is performing well.'
                                : 'This graph shows the daily number of clicks to streaming services that are coming from your ads.'
                            }
                        </p>
                    </div>
                    <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex gap-1 flex-row items-center">Cost-per-Click Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">This graph shows how the cost-per-click to a streaming service changed over the duration of the campaign. If the cost-per-click is going down or is stable, it means that the campaign is performing well.</p>
                    </div>
                    <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                </div>
            </>
        );
    }

    return (
        <div className="px-6">
            {hasConversions && (
                <>
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex flex-row gap-1 items-center">Cost-per-Conversion Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">
                            This graph shows how much it cost to actually get someone to complete the event "{conversionsEventName}". If the cost-per-conversion is going down or is stable, it means that the campaign is performing well.
                        </p>
                    </div>
                    <div>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                width={500}
                                height={400}
                                data={processedDailyStats.map((o: StatsPerDay) => {

                                    const {
                                        costPerConversion,
                                        date,
                                    } = o

                                    return {
                                        date,
                                        costPerConversion,
                                    }
                                })}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis dataKey="costPerConversion" />
                                <Tooltip
                                    formatter={(value: any, name: any, props: any) => {
                                        const regExp = /[a-zA-Z]/g;
                                        const millifiedValue = millify(value, { precision: 2 })
                                        const hasLetters = regExp.test(millifiedValue)
                                        return [`${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${hasLetters ? millifiedValue : value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, "Cost Per Conversion"]
                                    }} />
                                <Line type="monotone" dataKey="costPerConversion" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="px-6 lg:px-10 py-5 mt-2">
                        <p className="text-lg font-semibold flex flex-row gap-1 items-center">Conversions Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-sm text-gray-500">
                            This graph shows total number of conversions for your goal “{conversionsEventName}”. If it stays steady or goes up over time, it means your campaign is continuing to perform well.
                        </p>
                    </div>
                    <div>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                width={500}
                                height={400}
                                data={processedDailyStats.map((o: StatsPerDay) => {

                                    const {
                                        conversions,
                                        date,
                                    } = o

                                    return {
                                        date: date,
                                        conversions: conversions,
                                    }
                                })}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis dataKey="conversions" />
                                <Tooltip
                                    formatter={(value: any, name: any, props: any) => [`${millify(value)}`, "Conversions"]} />
                                <Line type="monotone" dataKey="conversions" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </>
            )}
            <div className="px-6 lg:px-10 py-5 mt-2">
                <p className="text-lg font-semibold flex flex-row gap-1 items-center">Clicks Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                <p className="text-sm text-gray-500">
                    {hasConversions ?
                        'This graph shows how the cost-per-click to your landing page. If the cost-per-click is going down or is stable, it means that the campaign is performing well.'
                        : 'This graph shows the daily number of clicks to streaming services that are coming from your ads.'
                    }
                </p>
            </div>
            <div>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        width={500}
                        height={400}
                        data={processedDailyStats.map((o: StatsPerDay) => {

                            const {
                                cpc,
                                date,
                                inlineClicks,
                                totalSpend
                            } = o

                            return {
                                date: date,
                                clicks: inlineClicks,
                                spend: totalSpend,
                                cpc: cpc
                            }
                        })}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis dataKey="clicks" />
                        <Tooltip
                            formatter={(value: any, name: any, props: any) => [`${millify(value)}`, "Clicks"]} />
                        <Line type="monotone" dataKey="clicks" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="px-6 lg:px-10 py-5 mt-2">
                <p className="text-lg font-semibold flex flex-row items-center gap-1">Cost-per-Click Over Time<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                <p className="text-sm text-gray-500">This graph shows how the cost-per-click to a streaming service changed over the duration of the campaign. If the cost-per-click is going down or is stable, it means that the campaign is performing well.</p>
            </div>
            <div>

                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        width={500}
                        height={400}
                        data={processedDailyStats.map((o: StatsPerDay) => {

                            const {
                                cpic,
                                date
                            } = o

                            return {
                                date: date,
                                cpic: cpic.toFixed(2)
                            }
                        })}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis dataKey="cpic" />
                        <Tooltip formatter={(value: any, name: any, props: any) => [`${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${value}`, "Cost-per-Click"]} />
                        <Line type="monotone" dataKey="cpic" stroke="#8884d8" fill="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="px-6 lg:px-10 py-5 mt-2">
                <p className="text-lg font-semibold flex items-center flex-row gap-1">Daily Spend<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                <p className="text-sm text-gray-500">This graph shows the daily spend on marketing. This may fluctuate up and down as the ads optimize to find the best audiences to target.</p>

            </div>
            <div>

                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        width={500}
                        height={400}
                        data={processedDailyStats.map((o: StatsPerDay) => {

                            const {
                                date,
                                spend
                            } = o

                            return {
                                date: date,
                                spend: spend
                            }
                        })}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis dataKey="spend" />
                        <Tooltip
                            formatter={(value: any, name: any, props: any) => [`${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${value}`, "Amount Spent"]} />
                        <Line type="monotone" dataKey="spend" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
