import { Body2, Subtitle2 } from "components/shareable/Typography";
import { Container, SHARED_WEBSITE_STYLES, StyledLabel, WebsiteEditorCard, Image } from "pages/post-auth/Website/styles"
import { useStyles } from "../../styles";
import { Switch, TextField } from "@material-ui/core";
import Text from 'modules/Text';
import { useContext, useState } from "react";
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { checkIfIsProTier, deepCopy } from "utils";
import SecondaryButton from "components/shareable/SecondaryButton";
import LinkButton from "components/shareable/LinkButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FieldsList } from '../FieldsList';
import { clsx } from "clsx";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import SymphonyLogo from 'assets/images/symphonyLogo.svg';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import { SystemColors } from "types/globalStyles";
import DollarSignIcon from "assets/images/gettingStarted/dollar_sign.svg"
import Intercom from "helpers/Intercom";

const initFieldItem = {
  id: "custom",
  name: "",
  required: false,
  priority: 0,
  reserved: false,
}

const fieldItems: Record<string, { key: string, title: string, label: string, isProOnly: boolean }> = {
  email_number: {
    key: "email_number",
    title: "Email - Number",
    label: "What's your email/number?",
    isProOnly: false,
  },
  name: {
    key: "name",
    title: "Name",
    label: "What's your name?",
    isProOnly: false,
  },
  age: {
    key: "age",
    title: "Age",
    label: "What's your age?",
    isProOnly: true,
  },
  email: {
    key: "email",
    title: "Email",
    label: "What's your email?",
    isProOnly: true,
  },
  phone_number: {
    key: "phone_number",
    title: "Phone Number",
    label: "What's your number?",
    isProOnly: true,
  },
  custom: {
    key: "custom",
    title: "Custom",
    label: '',
    isProOnly: true,
  }
}

const upgradeToProChip = () => <UpgradeToProChip
  type="gem"
  color="purple"
  height={20}
  width={20}
/>

export const DataCollectorSetupSection = () => {
  const classes = useStyles();
  const { currentBrand } = useContext(CurrentBrandContext)
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);
  const sharedClasses = SHARED_WEBSITE_STYLES();
  const isProUser = checkIfIsProTier(currentBrand);


  const {
    titleText, setTitleText,
    subtitle, setSubtitle,
    showSecondaryLine, setShowSecondaryLine,
    fields = [], setFields,
    submitButtonText, setSubmitButtonText,
    removeSymphonyBrand, setRemoveSymphonyBrand,
    addAffiliateLinkToLogo, setAddAffiliateLinkToLogo
  } = useContext(WebsiteContext)

  const [addNewField, setAddNewField] = useState<boolean>(false)
  const [newFieldText, setNewFieldText] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleIsRequiredField = (index: number, isRequired: boolean) => {
    if (!fields?.length) return
    const newFields = deepCopy(fields)
    newFields[index].required = isRequired
    setFields(newFields)
  }

  const handleRemoveSymphonyBrand = (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => setRemoveSymphonyBrand(!checked);

  const saveNewField = (id: string, label: string) => {
    if (fields?.length) {
      const newFields = deepCopy(fields)
      newFields.push({
        ...initFieldItem,
        id,
        label,
      })
      setFields(newFields)
    }
    else {
      const newFields = [
        {
          ...initFieldItem,
          id,
          label,
        }
      ]
      setFields(newFields)
    }
    setAddNewField(false)
    setNewFieldText('')
  }

  const deleteField = (index: number) => {
    if (!fields?.length) return
    const newFields = deepCopy(fields)
    newFields.splice(index, 1)
    setFields(newFields)
  }

  const addNewFieldItem = (type: string) => {
    if (type === 'custom') setAddNewField(true)
    else saveNewField(type, fieldItems[type].label)
    setAnchorEl(null)
  }

  const handleAddNewFieldItem = (field: string) => {
    if (!isProUser && fieldItems[field].isProOnly) handleOpenUpgradeToProModal()
    else addNewFieldItem(field)
  }

  const renderFieldItemList = () => {
    return Object.keys(fieldItems).map((key) => {
      const disabled = Boolean(fields.find(field => field.id === fieldItems[key].key) && fieldItems[key].key !== 'custom')
      return (
        <MenuItem
          key={key}
          onClick={() => handleAddNewFieldItem(key)}
          disabled={disabled}
        >
          {fieldItems[key].title}
          {fieldItems[key].isProOnly && upgradeToProChip()}
        </MenuItem>
      )
    })
  }

  return (
    <div className={classes.customizeWebsiteV2Variable}>
      <div className='w-full flex flex-col gap-4'>
        <WebsiteEditorCard title='Title'>
          <input
            style={Text.input}
            type='text'
            value={titleText}
            placeholder='Title'
            onChange={(e) => setTitleText(e.target.value)}
          />
          <div className={classes.secondaryTextSection}>
            <Switch
              checked={showSecondaryLine}
              onChange={(e: any) => setShowSecondaryLine(e.target.checked)}
              className={clsx(showSecondaryLine && classes.switchEnabled)}
            />
            <div className='addSecondaryText' style={Text.secondary}>
              Add secondary line
            </div>
          </div>
          {showSecondaryLine && (
            <input
              style={Text.input}
              type='text'
              value={subtitle}
              placeholder='Secondary line'
              onChange={(e) => setSubtitle(e.target.value)}
            />
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Form Elements'>
          <FieldsList
            deleteField={deleteField}
            toggleIsRequiredField={toggleIsRequiredField}
          />
          {!addNewField ? (
            <div className='flex justify-center'>
              <LinkButton
                text='+ Add New Field'
                onClick={(e) => {
                  setAnchorEl(e.currentTarget)
                }}
              />
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                className={classes.fieldsMenu}
              >
                {renderFieldItemList()}
              </Menu>
            </div>
          ) : (
            <div className='flex flex-col gap-4'>
              <div
                className={sharedClasses.innerPanelSection}
                style={{ display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 0 }}
              >
                <Subtitle2>Add a Custom Field</Subtitle2>
                <Subtitle2>What info do you want to collect?</Subtitle2>
                <TextField
                  variant='outlined'
                  inputProps={{ className: classes.socialMediaInput }}
                  value={newFieldText}
                  placeholder={'i.e. What\'s your favorite song?'}
                  onChange={(e) => setNewFieldText(e.target.value)}
                />
              </div>
              <div className='flex gap-2'>
                <SecondaryButton
                  text='Cancel'
                  onClick={() => setAddNewField(false)}
                />
                <SecondaryButton
                  text='Save'
                  onClick={() => saveNewField('custom', newFieldText)}
                  disabled={!newFieldText.length}
                />
              </div>
            </div>
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Submit Button Text'>
          <TextField
            variant='outlined'
            inputProps={{ className: clsx(classes.socialMediaInput, classes.submitButtonInput) }}
            value={submitButtonText}
            placeholder='Subscribe'
            onChange={(e) => setSubmitButtonText(e.target.value)}
          />
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Additional Options'>
          <Container
            display='flex'
            flexDirection='row'
            alignItems={'center'}
            justifyContent='flex-start'
            onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
          >
            <Container alignItems='center' justifyContent='center'>
              <Switch
                disabled={!isProUser}
                onChange={handleRemoveSymphonyBrand}
                checked={!removeSymphonyBrand}
                className={clsx(!removeSymphonyBrand && classes.switchEnabled)}
              />
            </Container>
            <Container display='flex' gap="4px" alignItems="center">
              <StyledLabel fontSize={16}>Show Symphony Logo</StyledLabel>
              {!isProUser && (
                <GemIcon color="purple" width={18} height={18} />
              )}
            </Container>
          </Container>
          {!removeSymphonyBrand && (
            <Container
              display='flex'
              alignItems='center'
              gap='16px'
              borderRadius='8px'
              padding='16px'
              justifyContent='center'
              width='100%'
              backgroundColor='#8C8C8C'
            >
              <StyledLabel fontSize={16} color='#ffffff'>
                Powered by
              </StyledLabel>
              <Container
                backgroundColor='#000000'
                padding='4px 12px'
                borderRadius='4px'
              >
                <Image
                  width={130}
                  height={17}
                  alt='symphony-logo.svg'
                  src={SymphonyLogo}
                />
              </Container>
            </Container>
          )}
          <Container
            display='flex'
            flexDirection='row'
            alignItems={'center'}
            justifyContent='flex-start'
            onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
          >
            <Container alignItems='center' justifyContent='center'>
              <Switch
                disabled={!isProUser}
                onChange={(e: any) => setAddAffiliateLinkToLogo(e.target.checked)}
                className={clsx(addAffiliateLinkToLogo && classes.switchEnabled)}
                checked={addAffiliateLinkToLogo}
              />
            </Container>
            <StyledLabel fontSize={16}>Add your Referral Link to Symphony Logo</StyledLabel>
          </Container>
          {(
            <div className={classes.banner}>
              <Container display="flex" gap="8px" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <Container display="flex" flexDirection="row" gap={"8px"} alignItems="center">
                  <img className="w-8 h-8" src={DollarSignIcon} />
                  <Subtitle2>Get paid when people sign up using your Referral Link.</Subtitle2>
                </Container>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>The Symphony referral program gives you a 50% commission for every new Symphony Pro subscriber you drive. Earn up to $120 / year.</Body2>
                <LinkButton
                  onClick={() => {
                    Intercom.openArticleWithId('7002468')
                  }}
                  text={`Learn More >`}></LinkButton>
              </Container>
            </div>
          )}
        </WebsiteEditorCard>
      </div>
    </div>
  )
}