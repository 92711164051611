import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

interface ModalContainerProps {
  minHeight?: string
  height?: string
  maxHeight?: string
  width?: string
  margin?: string
  padding?: string
  gap?: string
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }) => padding || "32px"};
  gap: ${({ gap }) => gap || "48px"};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  width: ${({ width }) => width || "100%"};
  margin: ${({ margin }) => margin || "auto"};
  background: #ffffff;
  border-radius: 8px;
`

export const StyledAnchor = styled.a`
  display: inline;
  width: 120px;
  text-decoration: none;
  color: ${SystemColors.ACCENT_COLOR};
  cursor: pointer;
  gap: 8px;
`

export const StyledSpan = styled.span``

export const LoadingContainer = styled.div`
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ErrorLabelContainer = styled.div`
  margin-top: 16px;
`
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;