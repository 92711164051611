import { OnboardingArtist } from "types/global";
import { Fragment, FunctionComponent } from "react";
import { ArtistList as StyledArtistList, ArtistListLine } from "../../style";
import ArtistItem from "./ArtistItem/index";
import Grid from "@material-ui/core/Grid";
import NotFoundArtistLabel from "pages/post-auth/AddArtist/Components/NotFoundArtistLabel";

interface Props {
  loading?: boolean;
  artists: OnboardingArtist[];
  selectedArtist: OnboardingArtist | null;
  isMobile?: boolean;
  selectArtist: (artist: OnboardingArtist) => void;
}

const ArtistList: FunctionComponent<Props> = ({
  loading,
  artists,
  selectedArtist,
  isMobile,
  selectArtist,
}: Props) => {

  const renderedList: React.ReactElement[] = []

  artists.map((artist, index) => {
    renderedList.push(
      <Fragment key={artist.id}>
        <Fragment>
          <ArtistItem
            loading={loading}
            isSelected={
              selectedArtist ? selectedArtist?.id === artist.id : loading
            }
            artist={artist}
            selectArtist={selectArtist}
            buttonLabel="Connect"
          />
          <ArtistListLine />
        </Fragment>

      </Fragment>
    )
  })

  // add zero state 
  renderedList.push(
    <Grid className={`mx-auto ${isMobile ? "my-1" : "my-2"}`}>
      <NotFoundArtistLabel />
    </Grid>
  )
  return (
    <StyledArtistList gap={isMobile ? "4px" : "8px"}>
      {renderedList}
    </StyledArtistList>
  )
}

export default ArtistList
