import { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { getAdsComission } from "utils";
import { TotalBannerContainer } from "./styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Container, SymphonyTooltip } from 'styles/shared';
import Intercom from 'helpers/Intercom';
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { SystemColors } from "types/globalStyles";
import { Body2, Caption } from "components/shareable/Typography";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { MONTHLY_PRO_PLAN_PRICE } from "../../../../../../../constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import { getConvertedCurrency } from "services/symphonyApi";
import { getNumberWithDecimals } from "helpers/General";

interface Props {
  budget: number;
  isProUser: boolean;
  freeTrialOver: boolean;
}

const format = (budget: number, showDigits?: boolean) => {
  const numberOfDigits = showDigits ? 2 : 0

  return budget.toLocaleString("en-US", {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
  })
}

const BudgetCard: FunctionComponent<Props> = ({ budget, isProUser, freeTrialOver }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();
  // TODO: Let's comment this for now in case roll back is needed
  const adsComission = 0 // getAdsComission(isProUser);
  const proFee = !isProUser && freeTrialOver ? MONTHLY_PRO_PLAN_PRICE : 0;
  const { data, currency } = useContext(SpendBudgetContext)
  const { feeTotal, feePrice, feeUnit, total: proTierTotal, newSpentLimit } = data || {}
  const freeTierTotal = Number(budget) + Number(proFee);
  const { currentBrand } = useContext(CurrentBrandContext)
  const isOnOldPlan = currentBrand?.subscription?.onOldPlan
  const currencySymbol = getSymbolFromCurrency(currency)

  const [proFeeConverted, setProFeeConverted] = useState<number>(proFee);

  const openIntercom = () => Intercom.openSymphonyProArticle();

  useEffect(() => {
    if (currency !== DEFAULT_CURRENCY && proFee > 0) {
      getConvertedCurrency({ amount: proFee, from: DEFAULT_CURRENCY, to: currency }).then((res) => {
        setProFeeConverted(Number(getNumberWithDecimals(res, 2)));
      });
    }
  }, [currency]);

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={1} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Budget
            </StyledLabel>
          </Grid>
        </Grid>
        <Container className={clsx(classes.space, classes.border)}
          display="flex" flexDirection="row"
          justifyContent="space-between">
          <Container width="75%" justifyContent="flex-start">
            <StyledLabel fontSize={16} fontWeight={600}>
              Marketing Budget
            </StyledLabel>
            <StyledLabel color="#707070" fontSize={14} fontWeight={400}>
              This amount will be charged and spent on your YouTube Ads campaign.
            </StyledLabel>
          </Container>
          <Container width="25%" display="flex" justifyContent="flex-end">
            <StyledLabel fontSize={16} fontWeight={400}>
              {getSymbolFromCurrency(currency)}
              {format(budget - adsComission * budget, true)}

            </StyledLabel>
          </Container>
        </Container>


      </Grid>
    </Card>
  );
};

export default BudgetCard;
