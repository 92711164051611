// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { HowToPopupPill } from "../HowToPopup";
import BadgeContainer from "../Components/BadgeCampaignTypeContainer/BadgeContainer";
import PLATFORMS_BADGE_ICONS from "../Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import ListComponent from "components/marketing/HelperPopup/list-section";
import ContactUsHelpPopupSection from "components/marketing/HelperPopup/contact-us";

interface HelperPopup {

}

export default function BoostInstagramHelperPopup(props: HelperPopup) {

    return (
        <>
            <div className=" mb-6">
                <p className="text-lg ">The "Boost Instagram Engagement" playbook is an easy way to use Symphony's automated fan finding technology to supercharge your IG. </p>
                <div className="flex mt-3 text-sm">
                    <BadgeContainer text="Runs Instagram Ads" imageUrls={[
                        PLATFORMS_BADGE_ICONS["instagram"]
                    ]} />
                </div>
            </div>

            <ListComponent
                title="When to use it:"
                bulletPoints={[
                    "You want to grow your followers on Instagram or increase your reach on Instagram."
                ]}
            />
            <ListComponent
                title="The Symphony Advantage:"
                bulletPoints={[
                    "Symphony automatically targets the best audiences for your ads to drive new comments and likes. Our AI automatically creates audiences using data from your current fanbase, so ads are targeted to new fans that already have an affinity towards your type of content.",
                    "Compared to boosting your post on Instagram or setting up ads yourself, Symphony's Instagram ads tend to have a 2x cheaper cost-per-engagement."
                ]}
            />
            <ListComponent
                title="What you need to get started:"
                bulletPoints={[
                    "You should have access to your Facebook + Instagram pages.",
                    "You should have creative ad assets ready, ideally a story-sized video for Instagram Story + Instagram Reels ads and a square or 4x5 video for Instagram Feed ads."
                ]}
            />

            <ListComponent
                title="The Dashboard:"
                bulletPoints={[
                    "Track ads data, including cost-per-click, total amount spent, comments, and shares on your ad.",
                    "Track countries & demographics that are clicking on your ad the most."
                ]}
                swiperImages={[
                    require("../../../../assets/images/onboarding/data-examples/growth-example.png").default,
                    require("../../../../assets/images/onboarding/data-examples/demographics-example.png").default,
                    require("../../../../assets/images/onboarding/data-examples/countries-example.png").default
                ]}
            />


            <ContactUsHelpPopupSection />
        </>
    )
}