import {
  ButtonUpdateToSuggest,
  CurrentBudgetAmount,
  CurrentBudgetContainer,
  ImageUp,
  LowDailySpentContainer,
  LowDailySpentErrorContainer,
  MinimalAmountContainer,
  MinimalBudgetText,
  NewDailySpentContainer,
  NewDailySpentText,
  SuggestedBudgetContainer,
  TextLowDailySpend
} from './style';
import { ReactComponent as ArrowIconRed } from 'assets/images/arrow-red.svg';
import { ReactComponent as ArrowIconGray } from 'assets/images/arrow-right-icon.svg';
import ArrowIconPurple from 'assets/images/arrowRight.svg';
import { Fragment, FunctionComponent } from 'react';
import { WarningCodesEditBudget } from './constants';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { CurrencyCodesType } from 'types/global';
import getSymbolFromCurrency from 'currency-symbol-map';
import { DEFAULT_CURRENCY } from '../../constants';

interface ChangedEndDateInfoProps {
  startDate?: Date | string
  endDate?: Date | string
}

const ChangedEndDateInfo: FunctionComponent<ChangedEndDateInfoProps> = ({
  startDate,
  endDate,
}: ChangedEndDateInfoProps) => (
  <Fragment>
    {endDate && startDate && (
      <NewDailySpentText>
        Your end date will be changed from {moment(startDate).format("MMMM D")}{" "}
        to {moment(endDate).format("MMMM D")}.
      </NewDailySpentText>
    )}
  </Fragment>
)

const getFormattedMoney = (amount?: number, currency?: string) =>
  `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${amount?.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })} ${currency?.toUpperCase() || "USD"}`

const getFormattedDate = (date?: Date) => moment(date).format('MMM DD')

const getTextLowDailySpend = (args: {
  selectedBudget?: number
  suggestedBudget?: number
  totalSpent: number
  currency?: string
}) => {
  const { selectedBudget = 0, suggestedBudget, totalSpent, currency } = args
  const formattedSuggestedBudget = getFormattedMoney(suggestedBudget, currency)
  const formattedCurrentBudget = getFormattedMoney(totalSpent, currency)

  if (selectedBudget < totalSpent) {
    return `Your campaign has already spent ${formattedCurrentBudget}. Please enter at least a ${formattedSuggestedBudget} budget.`
  }

  return `Please enter at least a ${formattedSuggestedBudget} budget.`
}

interface Props {
  totalSpent: number,
  currentBudget: number,
  selectedBudget?: number,
  suggestedBudget?: number,
  dailySpend?: number,
  currency?: CurrencyCodesType,
  date?: Date,
  errorCode: WarningCodesEditBudget,
  currentEndDate?: Date | string
  selectedEndDate?: Date | string
  clickUpdate?: (amount: number) => void,
}

const LowDailySpendContainer: FunctionComponent<Props> = ({
  totalSpent,
  currentBudget,
  selectedBudget,
  suggestedBudget,
  currency,
  errorCode,
  dailySpend,
  date,
  selectedEndDate,
  currentEndDate,
  clickUpdate,
}: Props) => {
  const handleClickUpdate = () => {
    if (suggestedBudget && clickUpdate) clickUpdate(suggestedBudget)
  }

  switch (errorCode) {
    case WarningCodesEditBudget.DAILY_MINIMAL:
      return (
        <LowDailySpentContainer>
        <TextLowDailySpend>
          With this budget, your daily spend would be too low to provide an effective campaign. Please increase your budget to at least {getFormattedMoney(suggestedBudget, currency)}.
        </TextLowDailySpend>
        <MinimalAmountContainer>
          <CurrentBudgetContainer>
            <MinimalBudgetText>Current budget</MinimalBudgetText>
            <CurrentBudgetAmount>{getFormattedMoney(currentBudget, currency)}</CurrentBudgetAmount>
          </CurrentBudgetContainer>
          <ArrowIconGray />
          <SuggestedBudgetContainer>
            <MinimalBudgetText>Suggested budget</MinimalBudgetText>
            <CurrentBudgetAmount>{getFormattedMoney(suggestedBudget, currency)}</CurrentBudgetAmount>
          </SuggestedBudgetContainer>
          <ButtonUpdateToSuggest
            onClick={handleClickUpdate}
          >
            Use suggested budget
          </ButtonUpdateToSuggest>
        </MinimalAmountContainer>
        </LowDailySpentContainer>
      )
    case WarningCodesEditBudget.INCREASE:
      return (
        <NewDailySpentContainer>
          <ImageUp src={ArrowIconPurple} alt="arrow-up" />
          <Grid container direction="column">
            <ChangedEndDateInfo
              startDate={currentEndDate}
              endDate={selectedEndDate}
            />
            <NewDailySpentText>
              Your campaign's budget will be increased from{" "}
              {getFormattedMoney(currentBudget, currency)} to{" "}
              {getFormattedMoney(selectedBudget, currency)}. Your new daily
              spend will increase to {getFormattedMoney(dailySpend, currency)}.
            </NewDailySpentText>
          </Grid>
        </NewDailySpentContainer>
      )
    case WarningCodesEditBudget.DECREASE:
      return (
        <NewDailySpentContainer>
          <ArrowIconRed />
          <Grid container direction="column">
            <ChangedEndDateInfo
              startDate={currentEndDate}
              endDate={selectedEndDate}
            />
            <NewDailySpentText>
              Your campaign's budget will be reduced from{" "}
              {getFormattedMoney(currentBudget, currency)} to{" "}
              {getFormattedMoney(selectedBudget, currency)}. Your new daily
              spend will decrease to {getFormattedMoney(dailySpend, currency)}.
            </NewDailySpentText>
          </Grid>
        </NewDailySpentContainer>
      )
    case WarningCodesEditBudget.FACEBOOK_MINIMAL:
      return (
        <LowDailySpentErrorContainer>
          <TextLowDailySpend>
            {getTextLowDailySpend({
              totalSpent,
              currency,
              selectedBudget,
              suggestedBudget,
            })}
          </TextLowDailySpend>
          <MinimalAmountContainer>
            <CurrentBudgetContainer>
              <MinimalBudgetText>Current budget</MinimalBudgetText>
              <CurrentBudgetAmount>
                {getFormattedMoney(currentBudget, currency)}
              </CurrentBudgetAmount>
            </CurrentBudgetContainer>
            <ArrowIconGray />
            <SuggestedBudgetContainer>
              <MinimalBudgetText>Suggested budget</MinimalBudgetText>
              <CurrentBudgetAmount>
                {getFormattedMoney(suggestedBudget, currency)}
              </CurrentBudgetAmount>
            </SuggestedBudgetContainer>
          </MinimalAmountContainer>
          <ButtonUpdateToSuggest onClick={handleClickUpdate}>
            Use suggested budget
          </ButtonUpdateToSuggest>
        </LowDailySpentErrorContainer>
      )
    case WarningCodesEditBudget.DECREASE_DAILY:
      return (
        <NewDailySpentContainer>
          <ArrowIconRed />
          <Grid container direction="column">
            <ChangedEndDateInfo
              startDate={currentEndDate}
              endDate={selectedEndDate}
            />
            <NewDailySpentText>
              Your new daily spend will decrease to{" "}
              {getFormattedMoney(dailySpend, currency)}.
            </NewDailySpentText>
          </Grid>
        </NewDailySpentContainer>
      )
    case WarningCodesEditBudget.INCREASE_DAILY:
      return (
        <NewDailySpentContainer>
          <ImageUp src={ArrowIconPurple} alt="arrow-up" />
          <Grid container direction="column">
            <ChangedEndDateInfo
              startDate={currentEndDate}
              endDate={selectedEndDate}
            />
            <NewDailySpentText>
              Your new daily spend will increase to{" "}
              {getFormattedMoney(dailySpend, currency)}.
            </NewDailySpentText>
          </Grid>
        </NewDailySpentContainer>
      )
    case WarningCodesEditBudget.MANY_DAYS:
      return (
        <LowDailySpentContainer>
          <TextLowDailySpend>
            If you set your end date to {getFormattedDate(date)}, your daily
            budget will be too low for effective ads targeting. We recommend
            selecting an earlier end date or updating your overall budget to
            ensure an effective campaign.
          </TextLowDailySpend>
          <MinimalAmountContainer>
            <CurrentBudgetContainer>
              <MinimalBudgetText>Current budget</MinimalBudgetText>
              <CurrentBudgetAmount>
                {getFormattedMoney(currentBudget, currency)}
              </CurrentBudgetAmount>
            </CurrentBudgetContainer>
            <ArrowIconGray />
            <SuggestedBudgetContainer>
              <MinimalBudgetText>Updated budget</MinimalBudgetText>
              <CurrentBudgetAmount>
                {getFormattedMoney(suggestedBudget, currency)}
              </CurrentBudgetAmount>
            </SuggestedBudgetContainer>
          </MinimalAmountContainer>
        </LowDailySpentContainer>
      )
    default:
      return <Fragment />
  }
}

export default LowDailySpendContainer;
