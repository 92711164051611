import { FunctionComponent } from 'react';
import {
  BodyContainer,
  CloseIconContainer,
  HeaderContainer,
  InformationContainer,
  SubTitle,
  TextContainer,
  Title,
  BackButtonContainer,
  Body
} from './styles';
import { ReactComponent as CloseIcon } from 'assets/images/close-black.svg';
import { ReactComponent as BackArrowIcon } from 'assets/images/BackArrow.svg';
interface Props {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  body?: string;
  closeModal: () => void;
  HeaderIcon?: JSX.Element;
  handleGoBack?: () => void;
}

const HeaderModal: FunctionComponent<Props> = ({
  title,
  subtitle,
  body,
  closeModal,
  HeaderIcon,
  handleGoBack
}: Props) => {
  return (
    <InformationContainer>
      <HeaderContainer justifyContent={Boolean(handleGoBack) ? 'space-between' : 'flex-end'}>
        {
          Boolean(handleGoBack) &&
          <BackButtonContainer onClick={handleGoBack}>
            <BackArrowIcon />
          </BackButtonContainer>
        }
        <CloseIconContainer onClick={closeModal}>
          <CloseIcon/>
        </CloseIconContainer>
      </HeaderContainer>
      <BodyContainer>
        {HeaderIcon && (HeaderIcon)}
        <TextContainer>
          <Title>{title}</Title>
          {subtitle && <SubTitle className='whitespace-pre-wrap'>{subtitle}</SubTitle>}
          {body &&
            <Body>
              {body}
            </Body>
          }
        </TextContainer>
      </BodyContainer>
    </InformationContainer>
  )
};

export default HeaderModal;
