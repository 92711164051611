import styled from "styled-components";

interface IconContainerProps {
  width?: string;
  transform?: string;
}

export const IconContainer = styled.div`
  width: ${({ width }: IconContainerProps) => width};
  transform: ${({ transform }: IconContainerProps) => transform};
`;
