import { MainContainer } from "./styles";

export default function MainContentContainer(props: any) {
  const { children, flex, className, backgroundColor } = props;
  
  return (
    <MainContainer
      backgroundColor={backgroundColor}
      className={
        className ?
        `${className} mainWebsiteRootContainer`
        :
        `mainWebsiteRootContainer h-full w-full 
        lg:w-12/12 xl:w-12/12 mx-auto lg:pl-16 lg:pr-8 lg:box-border
        relative ${flex ? "flex justify-center align-center" : ""}`
      }
    >
      {children}
    </MainContainer>
  );
}