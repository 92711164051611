import { IconButton } from "@material-ui/core";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import { ExpandIcon } from "pages/post-auth/DashboardModule/components/icons";
import { Line } from "react-chartjs-2"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import EmptyGraphImage from "../../assets/images/ui/loading-data.png"
import { CloudDownloadOutlined, CloudOffOutlined } from "@material-ui/icons";
import dayjs from "dayjs";

interface GraphProps {
    expandGraph: () => void,
    imageAsset: string,
    primaryValue: number | string,
    platformName: string,
    subtitle: string,
    graphData: any,
    graphOptions: any,
    changeValue: number,
    key: string,
    index: number;
    hideChangeValueAtZero?: boolean
    decommissionMessage?: string
}
export default function SmallGraph(props: GraphProps) {
    const {
        index,
        expandGraph,
        imageAsset,
        primaryValue,
        platformName,
        subtitle,
        graphData,
        graphOptions,
        changeValue,
        hideChangeValueAtZero,
        decommissionMessage
    } = props


    const hasData = graphData.length > 0



    // id for the graph gradient 
    const gradientId = `small-graph-gradient-${index}`

    function renderChangeValue() {
        if (!hasData) return null
        if (changeValue === 0 && hideChangeValueAtZero) return null

        return (
            <div className="w-auto">
                <p className={`text-lg md:text-xl font-semibold ${changeValue >= 0 ? "text-green" : "text-gray-500"}`}>{changeValue > 0 ? "+" : changeValue < 0 ? "" : ""}{changeValue || 0}%</p>
            </div>
        )


    }

    function renderGatheringState() {
        if (decommissionMessage) {
            return (
                <div className="ml-2 text-left self-center">
                    <p className="text-md font-medium  flex items-center"><div className="mr-1"><CloudOffOutlined fontSize="small" /></div>{platformName} {subtitle}</p>
                    <p className="text-xs">{decommissionMessage}</p>
                </div>
            )
        } else {
            return (
                <div className="ml-2 text-left self-center">
                    <p className="text-md font-medium  flex items-center"><div className="mr-1"><CloudDownloadOutlined fontSize="small" /></div>{platformName} {subtitle}</p>
                    <p className="text-xs">We're still gathering data... Check back tomorrow for updated stats!</p>
                </div>
            )
        }
    }

    return (
        <div className="relative my-1.5  border rounded-md flex flex-col w-full 
        
        "
            style={{
                marginLeft: index % 2 === 0 ? 0 : (window.innerWidth > 1200 ? "0.5%" :
                    window.innerWidth >= 768 ? '0.5%' : "0%"),
                marginRight: index % 2 === 1 ? 0 : (window.innerWidth > 1200 ? "0.5%" :
                    window.innerWidth >= 768 ? '0.5%' : "0%"),
                width:
                    window.innerWidth > 1200 ? "49%" :
                        window.innerWidth > 768 ? '49%' : "100%"
            }}>
            <div className="flex w-full justify-between items-center pt-3 md:py-2 px-4">
                <div className="flex flex-row">
                    <img
                        className="flex-shrink-0"

                        width={50}
                        src={
                            imageAsset
                        }
                        alt=""
                    />
                    {hasData ? (
                        <div className="ml-2 text-left self-center">
                            <p className="text-xl font-medium" style={{
                                marginBottom: "-4px"
                            }}>{primaryValue}</p>
                            <p className="text-sm text-gray-600">{subtitle}</p>
                        </div>
                    )
                        : renderGatheringState()}

                </div>
                {renderChangeValue()}
            </div>
            <div className="flex-1	flex flex-row justify-between items-center">

                {hasData ? (
                    <>
                        <div className="w-full">

                            <ResponsiveContainer width="100%" height={window.screen.width > 500 ? 100 : 80} >
                                <AreaChart

                                    data={graphData}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 20,
                                    }}
                                >
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <XAxis hide={true} dy={12} dataKey="date" />
                                    <YAxis hide={true} domain={['auto', 'auto']} />
                                    <Tooltip
                                        position={window.innerWidth < 768 ? { y: -80 } : undefined}
                                        allowEscapeViewBox={window.innerWidth < 768 ? { x: false, y: true } : undefined}

                                        cursor={{ stroke: '#42c221', strokeWidth: 2 }}
                                        content={({ active, payload, label }) => {

                                            if (active && payload && payload.length) {
                                                return (
                                                    <GraphTooltip
                                                        title={dayjs(label).format('dd MM/DD')}
                                                        value={payload[0].value!.toLocaleString('en')}
                                                    />
                                                );
                                            }

                                            return null;
                                        }}

                                    />

                                    <defs>

                                        <linearGradient id={gradientId}
                                            x1="0" y1="0" x2="0" y2="1"
                                        >

                                            <stop stopColor="#42c221" stopOpacity={0.4} />
                                            <stop offset="1" stopColor="#42c221" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <Area type="monotone"
                                        fill={`url(#${gradientId})`}
                                        strokeWidth={2}
                                        dataKey="value" stroke="#42c221" fillOpacity="0.8" activeDot={{ r: 8 }} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="absolute right-0 bottom-0 mr-0.5 mb-0.5 ">
                            <IconButton
                                onClick={expandGraph}
                                aria-label="Expand graph"
                                style={{
                                    "padding": "4px"
                                }}
                            >
                                <ExpandIcon />
                            </IconButton>
                        </div>
                    </>
                ) : (
                    <img
                        draggable={false}
                        src={EmptyGraphImage} className="w-full" />
                )
                }

            </div>

        </div>

    )
}