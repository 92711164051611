import { useState } from 'react';

export interface UseBannerData {
  visible: boolean;
  hide: () => void;
  show: (source?: string) => void;
}

interface Props {
  initiallyVisible?: boolean;
}

const useBanner = ({ initiallyVisible = false }: Props = {}) => {
  const [visible, setVisible] = useState<boolean>(initiallyVisible);
  const hide = () => setVisible(false);
  const show = (source?: string) => setVisible(true);
  return {
    visible,
    hide,
    show,
  };
}

export default useBanner;