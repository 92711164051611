import millify from 'millify';
import React from 'react';
import { useState } from 'react';
import LinkButton from 'components/shareable/LinkButton';
import { fbLoggedIn, loginWithFB } from '../../helpers/FB'
import { EditPlatformContainer } from './MarketingPage/styles';

interface FacebookProps {
    updateFB_Pages: (res: any) => void;
    selectedFB_page: any;
    // facebookLoginResponse: (res: any) => void;
}

const FacebookConnect: React.FC<FacebookProps> = (props: any) => {

    const {
        // facebookLoginResponse,
        selectedFB_page,
        updateFB_Pages
    } = props;

    async function loginWithFacebook() {
        try {
            await loginWithFB()
        } catch (e) {
            console.error("error logging in with FB", e)
        }
    }

    return (
        <div>
            {fbLoggedIn() ? (
                <div>
                    <div className="flex items-center ">
                        {selectedFB_page?.name ?
                            <EditPlatformContainer>
                              <div className="pl-6 pr-3 text-fontColor-100 text-right">
                                  <p>{selectedFB_page.name}</p>
                                  <p className="text-xs">{millify(selectedFB_page.followers_count)} Followers</p>
                              </div>
                              <LinkButton onClick={updateFB_Pages} text='Edit' />
                            </EditPlatformContainer>
                            : <LinkButton text='Connect' onClick={updateFB_Pages}/>
                        }
                    </div>
                </div>
            ) : (
                <LinkButton text='Connect' onClick={loginWithFacebook}/>
            )}
        </div>
    )
}

export default FacebookConnect;