import { MessageDataContentKeys, Tiers } from "types/global";

export const labels: Record<MessageDataContentKeys, string> = {
  sentTo: "Sent to",
  openedBy: "Opened by",
  openingRate: "Open Rate",
  targets: "",
};

export const statusLabelColors = {
  SUBMITTED: "#10B981",
  SCHEDULED: "#F18500",
};

export const HYPHEN = "-";

export const PERCENT_SYMBOL = "%";

export const US_COUNTRY_CODE = "US";

export const FanbaseTableValidFields = [
  "id",
  "email",
  "name",
  "profile_url",
  "lastname",
  "city",
  "state",
  "country",
  "platform",
  "tier",
  "color",
  "origin",
  "song_name",
  "createdAt",
  "checked",
];

export const MAX_SUBJECT_LINE_LENGTH = 50;

export const INFO_LABEL =
  "Once your messages are sent, you’ll be able to see stats on who opened + clicked in the Campaigns dashboard.";

export const MATCH_FILE_TAB_DEFAULT_VALUE = "none";

export enum fanbasePlatforms {
  "spotify",
  "deezer",
  "apple_music",
}

export const orderByFields: Record<string, string>  = {
  "platform": "platform",
  "source": "source",
  "email": "email",
  "location": "location",
  "added": "added",
}

export const orderByDirection: Record<string, string> = {
  "DESC": "DESC",
  "ASC": "ASC",
}
export interface getFanbaseParams {
  targeting?: boolean,
  city?: string,
  state?: string,
  country?: string,
  platform?: string,
  limit?: number,
  offset?: number,
  orderBy?: string,
  direction?: string,
}

export interface FansMetrics {
  fanbaseGrowth: string,
  foreverPresaveFans: number
  foreverPresaveFansGrowth: string,
  totalFanbase: number,
}
