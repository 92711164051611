import { CSSProperties } from 'react'
import { ImCheckboxUnchecked, ImCheckboxChecked } from 'react-icons/im'

const getImageTagStyles = (image?: string, imageTagStyles?: CSSProperties) => {
    if (image && imageTagStyles) return imageTagStyles
    if (image) return {
        height: "auto",
        minWidth: "98px",
        maxWidth: "98px",
        maxHeight: '40px',
    }
    return {}
}

export default function RowItem(props: any) {
    const {
        key,
        onClick,
        disabled,
        image,
        fallbackImage,
        name,
        secondRowContent,
        imageComponent,
        item,
        hideImage,
        imageStyles = '',
        selectable = false,
        selected,
        multiSelect,
        showRightValue,
        rightValue,
        rightValueComponent,
        imageTagStyles,
        arrowIconContainerStyles,
        secondRowContentContainerStyles
    } = props

    const imgStyles = getImageTagStyles(image, imageTagStyles)

    return (
        <li key={key}
            onClick={() => {
                if (onClick) onClick(item)
            }}
            className="cursor-pointer sy-card">
            <div className="flex items-center">
                {imageComponent ? imageComponent
                    : !hideImage ? (<div className={imageStyles ? `${imageStyles}` : 'flex-auto flex-shrink-0'} style={!image ? { background: '#07c806', borderRadius: '50%' } : {}}>
                        <img
                            src={image || fallbackImage || require("assets/images/profileIcon.svg").default}
                            alt="platform icon"
                            style={imgStyles}
                        />
                    </div>) : null}
                <div style={secondRowContentContainerStyles} className="px-3 w-full">
                    <h5 className="text-dark">{name}</h5>
                    {secondRowContent ? secondRowContent : null}
                </div>
            </div>
            {selectable ? <div style={arrowIconContainerStyles} className={"icon-block"} onClick={() => onClick(item)} >
                <img
                    alt="right-arrow.svg"
                    src={
                        require("../../assets/images/right-arrow.svg")
                            .default
                    }
                />
            </div> :
                showRightValue ? rightValueComponent ? rightValueComponent : rightValue ?
                    (<p>{rightValue}</p>)
                    : null : null}




            {multiSelect && !disabled ? <div className="icon-block" onClick={() => onClick(item)} >
                {selected ? (<ImCheckboxChecked />) : (<ImCheckboxUnchecked />)}
            </div> : null}
        </li>

    )
}