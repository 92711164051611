import { Headline2, Subtitle1 } from "components/shareable/Typography";
import React from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { SystemColors } from "types/globalStyles";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

interface ListComponentProps {
    title: string;
    bulletPoints: React.ReactNode[]; // Change to React.ReactNode[]
    swiperImages?: string[]; // Array of image paths
    videoUrl?: string;
    subtitle?: string;

}

const ListComponent: React.FC<ListComponentProps> = ({ title, bulletPoints, swiperImages, videoUrl, subtitle }) => {
    return (
        <div className="mb-10">
            <Headline2
                style={{
                    textDecoration: 'underline'
                }}
                color={SystemColors.PRIMARY_TEXT_COLOR}
            >
                {title}
            </Headline2>
            {subtitle && <Subtitle1 className="mt-2 mb-4">{subtitle}</Subtitle1>}

            <ul className="pl-4 text-lg list-disc">
                {bulletPoints.map((point, index) => (
                    <li key={index} className="my-3">{point}</li>
                ))}
            </ul>
            {swiperImages && swiperImages.length > 0 && (
                <div className="relative z-0 rounded-md overflow-hidden">
                    <Swiper
                        navigation={true}
                        loop={true}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: true
                        }}
                        modules={[Navigation, Autoplay]}
                    >
                        {swiperImages.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div>
                                    <img src={image} alt={`Slide ${index}`} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            {videoUrl && (
                <div className="w-full">
                    <iframe width="100%" height="315" src={videoUrl} title="Embedded video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            )}
        </div>
    );
};

export default ListComponent;
