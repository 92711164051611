import millify from "millify";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import { queryFB } from "../../helpers/FB";
import { DebounceInput } from "react-debounce-input";

interface TikTokConnectProps {
    closeModal: () => void;
    artist: any;
    selected: any;
    set: any;
}

export function isAnalytics(str: string) {
    return (/^[A-Z0-9]{20}$/i).test(str.toString());
}

export default function TikTokPixelConnect(props: TikTokConnectProps) {

    const {
        closeModal,
        artist,
        selected,
        set
    } = props

    const [loading, setLoading] = useState<any>(null)
    const [error, setError] = useState<any>(null)

    const [valueString, setValueString] = useState<string>(selected ? selected : '')


    function updateInBrand() {

        // connect to brand
        // ~~
        // setConnectModal('');
        Axios.put(`/brand/${artist.slug}/connect`, {
            id: artist.id,
            name: artist.slug,
            service: 'tiktok_pixel',
            value: valueString
        })
            .then((result) => {
                // reloadBrand()
                set(valueString)
                closeModal()
            })
            .catch((err) => {
                console.log('error creating brand', err)
            })

    }

    return <div className="bg-white w-full h-full mx-auto">
        <div
            className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">Paste in your TikTok Pixel ID</p>
            <a onClick={closeModal}>
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </a>
        </div>
        <div className="middle-wrapper px-4 lg:px-10 ">

            <div className="input-form flex flex-row align-middle">
                <label
                    htmlFor=""
                    className="absolute h-16 bg-white items-center flex text-lg "
                >
                    Pixel ID
                </label>
                <input
                    className="border-b p-6 outline-none text-right border-gray-200 w-full"
                    // minLength={2}
                    placeholder="ABC1234567890000000"
                    value={valueString}
                    onChange={(e) => setValueString(e.target.value)} />
            </div>
            {/* <p className="w-12/12 mt-5 text-2x text-center">Make sure you're using <span>Universal Analytics</span> by Google Analytics. Learn how to set it up <a className="text-2x text-gray-700 text-center underline" target="_blank" href="https://support.google.com/analytics/answer/10269537?hl=en">here</a>.</p> */}

            <div className="text-center px-4 lg:px-10 py-4">
                <button disabled={error || (!valueString || valueString === "") || !isAnalytics(valueString)} onClick={() => {
                    updateInBrand()
                    setLoading(true)
                }} className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3">{loading ? <div className="h-6 m-auto flex justify-center"><LoadingIndicator /></div> : 'Connect'}</button>
            </div>

        </div>
    </div>

}