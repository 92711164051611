interface Props {
    color?: string,
    direction?: "up" | "down" | "left" | "right",
    width?: number,
    height?: number
    marginRight?: number
}

export default function DropdownArrow({
    color = "#B2B2B2",
    direction = "down",
    width = 24,
    height = 24,
    marginRight = 0,
}: Props) {
    const compiledStyle = {
        transform: `rotate(${direction === "up" ? "180deg" : direction === "left" ? "90deg" : direction === "right" ? "-90deg" : "0deg"})`,
        transition: "transform 0.2s ease",
        transformOrigin: 'center',
        marginRight: marginRight + "px"
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24"
        style={compiledStyle}>
            <path stroke={color} stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.293 8.996 12 14.703l5.707-5.707" />
        </svg>
    )
}