import { SvgIcon } from "@material-ui/core";
import { LoadingIndicator } from "components/Loader";
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import { InstagramPageType, LanguageChip, OverallCampaignStatus, CampaignMetadata, FacebookAdAccount, AdAccountPageType, FacebookPageType, OverallCampaignStatusKeys, CurrentBrand } from "types/global";
import { fbLoggedIn, getInstagramPosts, loginWithFB, queryFB } from "helpers/FB";
import Intercom from "helpers/Intercom";
import Axios from 'helpers/Interceptor';
import * as Sentry from "@sentry/react";
import { checkImageUrlValidity } from 'helpers/General';
import { AGE_RESTRICTED_COUNTRIES, AgeRestrictedCountry, MINIMUM_MEDIA_WIDTH } from '../constants';
import { GeographicTargetingType, Location } from "../SongAds/TargetingView/reducer";
import { SelectedPlatforms } from "../SongAds/utils";
import { CreativeAssetTypes } from "../constants"
import PrimaryButton from "components/shareable/PrimaryButton";
import { FunctionComponent, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { SystemColors } from "types/globalStyles";
import { Caption } from "components/shareable/Typography";
import { updateCampaignStatus } from "services/symphonyApi";

import ClickIcon from "assets/images/campaignDetails/click.svg"
import CommentsIcon from "assets/images/campaignDetails/comments.svg"
import EyeIcon from "assets/images/campaignDetails/eye.svg"
import MoneyIcon from "assets/images/campaignDetails/money.svg"
import { CardFormat, MarketDetail } from "../Components/MarketsDetail"
import { FacebookAdAccountInfo } from "../Components/FacebookCampaignIssuesBanner";

const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export type PreConnectionConfigs = {
    status: boolean
    custom_audience_accepted: {
        status: boolean
        action: null | string
        name: string
    }
    value_based_custom_audience_tos: {
        status: boolean
        action: null | string
        name: string
    }
    user_tos_accepted: {
        status: boolean
        action: null | string
        name: string
    }
    account_status: {
        status: number
        action: null | string
        name: string
    }
}

export type SelectedFbAdAccount = {
    id: string,
    account_status: number,
    business?: {
        id: string,
        name: string,
    },
    amount_spent: number,
    name: string,
    userHasAccess: boolean,
    user_tasks: string[],
}

export type SelectedFbPage = {
    id: string,
    name: string,
    businessAccount: {
        id: string,
        username: string,
    },
    instagramAccount: {
        id: string,
        username: string,
    },
}

/**
 * Returns whether a caption contains explicit content (simple check)
 * @param caption 
 * @returns 
 */
export function containsExplicitContent(caption: string) {
    const substrings = [
        'fuck',
        'ass',
        'bitch',
        'shit',
        'motherfucker',
    ];
    const regex = new RegExp(`\\b(?:${substrings.join('|')})\\b`, 'i');
    return regex.test(caption);
}

export function ExplicitContentWarning() {
    return (
        <div className="w-full mb-4">
            <div className="rounded-md"
                style={{
                    backgroundColor: "#FDF6EC"
                }}>
                <div className="p-5">
                    <p style={{
                        color: "#754600"
                    }}>Your caption contains explicit content. Unfortunately due to Facebook's ads guidelines, your ad will most likely be rejected. Please select another post or change your caption.</p>

                </div>

            </div>
        </div>
    )
}
/**
* Returns whether the user has at least entered a single caption
* @param captions format is usually ({
   all: "",
   spotify: "",
   apple: "",
   youtube: "",
 }
*/

export function hasEnteredCaption(captions: any) {
    const filtered = Object.keys(captions)
        .filter((key: string) => {
            const captionValue: string = (captions as any)[key];
            return captionValue
        })

    return filtered.length > 0
}

/**
 * Checks if the required data is present for the selected connection type
 * @param connectionsType 
 * @param data 
 * @param requiredData 
 * @returns 
 */
export const getIfPagesAreSelected = (
    connectionsType: string,
    data: {
        fbPixel: Record<string, unknown> | null
        fbAdAccount: Record<string, unknown> | null
        fbPage: Record<string, unknown> | null
        instaPage: Record<string, unknown> | null
    },
    requiredData: {
        fbPixel: boolean,
        fbAdAccount: boolean,
        fbPage: boolean,
        instaPage: boolean,
    }
) => {
    const {
        fbPixel,
        fbAdAccount,
        fbPage,
        instaPage,
    } = data

    let isValid = true
    if (requiredData.fbPixel) {
        isValid = isValid && Boolean(fbPixel)
    }

    if (requiredData.fbAdAccount) {
        isValid = isValid && Boolean(fbAdAccount)
    }

    if (requiredData.fbPage) {
        isValid = isValid && Boolean(fbPage)
    }

    if (requiredData.instaPage) {
        isValid = isValid && Boolean(instaPage)
    }

    return isValid
    // const conditions = [
    //   Boolean(selectedFBPixel),
    //   Boolean(selectedFBAdAccount),
    //   Boolean(selectedFB_page),
    //   Boolean(selectedInstaPage),
    // ]

    // return connectionsType === "quick"
    //   ? every(conditions.slice(1), Boolean)
    //   : every(conditions, Boolean)
}


/**
 * During account connections, this handles showing the artist any error states
 * that may occur with the FB / IG connection, including:
 *  - non-connected Facebook + IG account
 *  - IG business account not connected
 * @param props 
 * @returns 
 */

interface ShowFbSelectedNoIgSelectedStateProps {
    facebookPage: any,
    instagramPage: InstagramPageType
    requireInstagramConnection: boolean
    onInstagramPageRefresh: () => Promise<boolean>
}

export const ShowFbSelectedNoIgSelectedState: FunctionComponent<ShowFbSelectedNoIgSelectedStateProps> = ({
    facebookPage,
    instagramPage,
    requireInstagramConnection,
    onInstagramPageRefresh,
}: ShowFbSelectedNoIgSelectedStateProps) => {

    const [loading, setLoading] = useState(false)
    const [refreshError, setRefreshError] = useState(false)

    const onClickRefresh = async () => {
        setLoading(true)
        const refreshed = await onInstagramPageRefresh()
        setRefreshError(!refreshed)
        setLoading(false)
    }

    // console.log("facebookPage", facebookPage)
    // console.log("instagramPage", instagramPage)



    // if the user hasn't selected an Instagram account or there is no linked IG account,
    // ask them to link their accounts
    if (requireInstagramConnection &&
        (facebookPage && !facebookPage.connected_instagram_account)
        && (facebookPage && !instagramPage)) {
        return (
            <div className="sy-card px-5 lg:px-8">

                <div className="rounded-md"
                    style={{
                        backgroundColor: "#FDF6EC"
                    }}>
                    <div className="p-5">
                        <p style={{
                            color: "#754600"
                        }}>To run a Boost Instagram campaign, you'll need to link your Instagram + Facebook together so we can run ads on your behalf. By connecting them you'll be able to:</p>
                        <ul style={{
                            color: "black"
                        }}
                            className="list-disc pl-5	">
                            <li>Run ads directly through your Instagram Page</li>
                            <li>Grow followers on your Instagram</li>
                            <li>Target better audiences to get more effective ads</li>
                        </ul>
                        <button
                            className="mt-2 font-medium"
                            style={{
                                color: "#8800FF"
                            }}
                            onClick={() => {
                                Intercom.openFacebookInstagramConnectorArticle()
                            }}>Learn how to connect them &gt;</button>
                    </div>

                </div>
            </div>
        )

    } else if (facebookPage &&
        (
            (instagramPage && !instagramPage.connected_instagram_account) || (!instagramPage)
        )
    ) {
        // if the user's connected their IG, but its not a business account, 
        // recommend they connect them
        return (
            <div className="sy-card px-5 lg:px-10">

                <div className="rounded-md"
                    style={{
                        backgroundColor: "#FDF6EC"
                    }}>
                    <div className="p-5">
                        <p style={{
                            color: "#754600"
                        }}>It looks like your Instagram and Facebook Page aren't connected.</p>
                        <p style={{
                            color: "black"
                        }}>
                            We recommend connecting your Instagram and Facebook Page to run ads directly through your Instagram profile + grow IG followers.&nbsp;
                        </p>

                        <button
                            className="font-medium"

                            style={{
                                color: "#8800FF"
                            }}
                            onClick={() => {
                                Intercom.openFacebookInstagramConnectorArticle()
                            }}>Learn how to link your Instagram &amp; Facebook Page &gt;</button>
                    </div>
                </div>
            </div >
        )
    } else if ((facebookPage && instagramPage
        && !instagramPage.businessAccount)
        || (facebookPage && facebookPage!.id === '101499913989079')) {
        // if the user's connected their IG, but its not a business account, 
        // recommend they connect them
        return (
            <div className="sy-card px-5 lg:px-10">

                <div className="rounded-md"
                    style={{
                        backgroundColor: "#FDF6EC"
                    }}>
                    <div className="p-5">
                        <p style={{
                            color: "#754600"
                        }}>It looks like your Instagram isn't a business account.</p>
                        <p style={{
                            color: "black"
                        }}>
                            We recommend switching your Instagram into a business account to get 2-3x better results on your marketing campaigns.&nbsp;
                            <button
                                className="font-medium"

                                style={{
                                    color: "#8800FF"
                                }}
                                onClick={() => {
                                    Intercom.openInstagramBusinessAccountArticle()
                                }}>Learn how to switch to a Business Account &gt;</button>
                        </p>
                        <div className="pt-4">
                            <PrimaryButton
                                text='Refresh IG Business Account Status'
                                onClick={onClickRefresh}
                                disabled={loading}
                                loading={loading}
                                loadingText="Rechecking your IG business account status"
                                iconLeft={<RefreshIcon sx={{ color: SystemColors.PAPER_CONTAINER }} />}
                            />
                        </div>
                        {refreshError && (
                            <div className="pt-2">
                                <Caption color={SystemColors.ERROR_COLOR}>It looks like your Instagram still isn’t a business account. Please enable it again.</Caption>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return <></>
}

/**
 * Type for assets that are uploaded via Cloudinary - specifically for
 * FB / IG campaigns
 */
export type UploadedAssetType = {
    thumbnail: string;
    cloudinary_id: string;
    height: number;
    width: number;
    name: string;
    url: string;
    fb_id: string;
    formatType: "story" | "feed" | "reels";
    uploading: boolean;
}


export type AssetUploadTypes = "upload_video" | "instagram_posts"

/**
 * Renders the Asset Upload Type selector depending on the type of data available -
 * either shows Instagram Posts or Upload Video, or just Upload Video if no IG is linked
 * @param props 
 * @returns 
 */
export function AssetUploadTypeSelector(props: {
    instagramPage: InstagramPageType | null,
    selected: AssetUploadTypes,
    onChange: (evt: any) => void;
}) {

    const {
        instagramPage,
        selected,
        onChange
    } = props

    // if no instagram page selected, don't show a selector - user has to upload
    //  - if there is an instagram account, make sure it has a linked 
    if (!instagramPage || (instagramPage && (!instagramPage.connected_instagram_account && !instagramPage.businessAccount))) {
        return null
    } else {

        return (
            <div className={`px-6 lg:px-10 py-5 mt-2 ${selected === UPLOAD_VIDEO && 'border-b'}`}>
                <div id="top-tabs" className="radio-toolbar">
                    <input
                        type="radio"
                        id={INSTAGRAM_POSTS}
                        name="dataview-type-0"
                        value={INSTAGRAM_POSTS}
                        onChange={onChange}
                        checked={selected === INSTAGRAM_POSTS}
                    />
                    <label
                        style={{
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                        }}
                        className="w-full text-center border border-primary"
                        htmlFor={INSTAGRAM_POSTS}
                    >
                        Instagram Posts
                    </label>
                    <input
                        type="radio"
                        id={UPLOAD_VIDEO}
                        name="dataview-type-1"
                        value={UPLOAD_VIDEO}
                        onChange={onChange}
                        checked={selected === UPLOAD_VIDEO}
                    />
                    <label
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                        className="w-full text-center border border-primary"
                        htmlFor={UPLOAD_VIDEO}
                    >
                        Upload Videos
                    </label>
                </div>
            </div>
        )
    }

}

/**
 * Takes an InstagramPage and returns a list of Instagram Posts or an 
 * empty array if there is no Instagram Page or no Instagram Account
 * @param props 
 * @returns 
 */
export async function loadInstagramPosts(props: {
    instagramPage: InstagramPageType | null,
    limit?: number
}): Promise<InstagramPost[]> {
    const {
        instagramPage,
        limit = 120
    } = props

    if (instagramPage) {
        const ig_account = instagramPage.businessAccount?.id || instagramPage.connected_instagram_account?.id
        if (ig_account) {
            const instagramPosts = await getInstagramPosts({ ig_account, limit })
            const validatedIgPosts = await checkSelectablePosts(instagramPosts)
            return validatedIgPosts
        }
    }

    return []
}

export async function checkSelectablePosts(instagramPosts: InstagramPost[]) {
    const response = []
    for (const post of instagramPosts) {
        try {
            const img = await checkImageUrlValidity(post.thumbnail_url ?? post.media_url!)
            post.selectable = Boolean(img.width > MINIMUM_MEDIA_WIDTH)
            response.push(post)
        } catch {
            continue
        }
    }
    return response
}

/**
 * Component to upload videos and show previews of them
 */
export function VideoUploader(props: {
    maxAssets: number,
    previewedVideo?: UploadedAssetType | null,
    setPreviewedVideo?: (video: UploadedAssetType | null) => void,
    selectedAssets: UploadedAssetType[] | null,
    uploadingAssets: boolean,
    removeAsset: (assetId: string) => void,
}) {

    const {
        maxAssets = 3,
        uploadingAssets,
        selectedAssets,
        removeAsset,
        setPreviewedVideo,
        previewedVideo
    } = props

    const VideoUploadButton = (
        <button id="all_widget" className="bg-gray-200 rounded-md w-full px-3 items-center justify-center flex p-4">
            <SvgIcon viewBox="0 0 25 25">
                <path fill="none" stroke="#80F" strokeLinecap="round" strokeWidth="2" d="M4.151 5.77a2 2 0 0 1 2-2h9.172a2 2 0 0 1 1.414.585l2.829 2.829a2 2 0 0 1 .585 1.414v11.171a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-14Z" clip-rule="evenodd" /><path stroke="#80F" strokeLinecap="round" strokeWidth="2" d="M15.151 4.77v2a2 2 0 0 0 2 2h2" /><path stroke="#80F" strokeLinecap="round" strokeWidth="2" d="m14.437 13.199-3.528 2.117a.5.5 0 0 1-.758-.43v-4.233a.5.5 0 0 1 .758-.429l3.528 2.117a.5.5 0 0 1 0 .858Z" clip-rule="evenodd" />
            </SvgIcon>
            <p className="ml-2 text-lg text-primary font-semibold">Upload a video (up to {maxAssets} max)</p>
        </button>
    )

    if (uploadingAssets) {
        return (
            <div className="flex flex-none text-center">
                <div id="all_widget" className=" flex flex-row justify-center items-center relative">
                    <img src={require('../../../../assets/images/upload-bg-green.svg').default} />
                    <img className="absolute" src={require('../../../../assets/images/upload-icon-white.svg').default} />
                </div>
                <div className="px-3 items-center flex text-left">
                    <h5 className="text-dark">Uploading...</h5>
                </div>
            </div>
        )
    }

    const hasUploadedMaxAssets = selectedAssets && selectedAssets.length >= maxAssets
    const noAssetsUploaded = !selectedAssets || selectedAssets.length === 0


    return (
        <div className="flex flex-col w-full">
            {(noAssetsUploaded || !hasUploadedMaxAssets) && (
                <div className="flex flex-col text-center w-full mb-4">
                    {VideoUploadButton}
                    <p className="text-xs mt-2 text-gray-500 lg:text-base">We recommend uploading at least 1 feed asset (in a 4x5 sizing ratio, like 1080px by 1350px) and 1 story asset (in 9x16 sizing, like 1080px by 1920px).</p>
                </div>
            )}
            {!noAssetsUploaded && (
                <>
                    <p className="text-xl mb-2">Uploaded Videos</p>
                    <div className=" w-full items-center flex flex-col text-left">

                        {selectedAssets.map((item: UploadedAssetType, index: number) => {
                            return (
                                <div key={index} className="mb-3 border rounded-md 
                               py-2 px-3 w-full flex flex-row items-center">
                                    <a className="relative md:w-8 h-auto" href={item.url} target="_blank">

                                        <img key={index} className="w-6 h-full md:m-2" src={item.thumbnail} alt="" />

                                    </a>
                                    <div className="ml-2 text-left w-5/12 md:w-6/12">
                                        <p className="text-base truncate">{item.name}</p>
                                        <p className="text-sm">{item.width}x{item.height}  ({item.formatType.toUpperCase()})</p>
                                    </div>
                                    {
                                        !item.uploading
                                            ?
                                            <div className="ml-auto flex items-center justify-center ">
                                                {setPreviewedVideo ? (item.cloudinary_id === previewedVideo?.cloudinary_id) ?
                                                    (<div className="border border-purple-500 mr-2 text-primary rounded-md p-1 text-xs" >PREVIEWING</div>) :
                                                    (<button
                                                        onClick={() => setPreviewedVideo(item)} className="bg-purple-500 mr-2 text-white rounded-md p-1 text-xs" >PREVIEW</button>) : null}
                                                <button onClick={() => removeAsset(item.cloudinary_id)} className="bg-red-500 text-white rounded-md p-1 text-xs" >REMOVE</button>
                                            </div>
                                            : <div className="flex items-center justify-center ml-auto">
                                                <LoadingIndicator height="25px" color="black" />
                                            </div>}

                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

export async function isLoggedInFbUser(brandId: number | string) {
    try {
        const fbToken = await Axios.get(`/token/${brandId}/facebook`);
        const responseData = fbToken.data.data as {
            accessToken: string | null;
            valid: boolean;
        };
        return responseData;
    } catch (e) {
        console.log("Error getting facebook token logged: ", JSON.stringify(e));
        Sentry.captureException(e);
    }
}

export async function getMinimalAmount(currency: string) {
    try {
        const response = await Axios.get(`/campaign/minimal-amount/${currency}`);
        const { data } = response;
        if (!data) return 0;
        return data.data.amount;
    } catch (e) {
        Sentry.setTag("apilayer", "getMinimalAmount");
        Sentry.captureException(e);
    }
}

const {
    ACTIVE,
    DENIED,
    ERROR,
    IN_REVIEW,
    IN_REVIEW_AFTER_ACTIVE,
    LIVE_WITH_ISSUES,
    LIVE_WITH_NO_STATS,
    PAUSED,
    TO_RETRY,
    PENDING,
    ANTICIPATED_PAUSE,
} = OverallCampaignStatus;

export function showAdsStatusLabel(status: OverallCampaignStatus, daysLeft: number) {
    switch (status) {
        case PENDING:
            return "";
        case ACTIVE:
        case IN_REVIEW_AFTER_ACTIVE:
            return daysLeft >= 0 ? "LIVE" : "FINISHED";
        case ANTICIPATED_PAUSE:
        case PAUSED:
            return "PAUSED";
        case ERROR:
            return "ISSUES";
        case TO_RETRY:
        case IN_REVIEW:
            return daysLeft >= 0 ? "IN REVIEW" : "FINISHED";
        case LIVE_WITH_NO_STATS:
        case LIVE_WITH_ISSUES:
            return daysLeft >= 0 ? "LIVE" : "FINISHED";
        case DENIED:
            return "DECLINED";
        default:
            return "";
    }
}

export function showCampaignLengthStatus(status: OverallCampaignStatus, daysLeft: number) {
    if (status === PENDING) return '';
    if (status === PAUSED) return 'Campaign Paused';

    return daysLeft >= 0
        ? daysLeft === 1
            ? '1 Day Left'
            : `${daysLeft.toFixed()} Days Left`
        : "Campaign Finished";
}

export const getFormattedDemographics = (data: {
    minAge: number,
    maxAge: number,
    gender: string,
    locales: Array<LanguageChip>
}) => {
    const { gender, locales, maxAge, minAge } = data;
    const languages = locales;
    const age = { min: minAge, max: maxAge };
    return {
        languages,
        age,
        gender
    }
}

export function getMaxWidth(screenDimensions: any) {
    const { largeView, strictMediumView, strictSmallView, mobileView } = screenDimensions;
    if (largeView)
        return "85%"
    if (strictMediumView)
        return "90%"
    if (strictSmallView)
        return "95%"
    if (mobileView)
        return "100%"
    return ""
}

export const validateTargetingCountriesAge = (geographicTargets: GeographicTargetingType[]) => {
    const allCountryCodes: string[] = []
    geographicTargets.forEach(market => {
        for (const [key, value] of Object.entries(market.locations)) {
            allCountryCodes.push(String(value.country_code))
        }
    })

    const countriesToFix: AgeRestrictedCountry[] = []
    for (const country of AGE_RESTRICTED_COUNTRIES) {
        const countryFound = allCountryCodes.includes(country.country_code)
        if (countryFound) {
            countriesToFix.push(country)
        }
    }
    return countriesToFix;
}

export const removeCountries = (
    geographicTargets: GeographicTargetingType[],
    countriesToFix: AgeRestrictedCountry[]
) => {
    const countriesIndex: Array<number> = []

    geographicTargets.forEach((market: GeographicTargetingType, index) => {
        const locations = Object.entries(market.locations)
        locations.forEach(([key, value]: Array<string | Location>) => {
            const locationToRemove = countriesToFix.find(country => {
                return (value as Location).country_code === country.country_code;
            })

            if (locationToRemove) {
                delete market.locations[key as string]
            }
        })

        if (!Object.entries(market.locations).length) {
            countriesIndex.push(index)
        }
    })

    for (let index = countriesIndex.length - 1; index >= 0; index--) {
        geographicTargets.splice(countriesIndex[index], 1)
    }

    return geographicTargets
}

export const distributeBudget = (geographicTargets: GeographicTargetingType[]) => {
    const numberOfMarkets = geographicTargets.length;
    if (numberOfMarkets === 1) {
        geographicTargets[0].budget = 1;
    } else if (numberOfMarkets > 1) {
        const percentageToDistribute = 1 / numberOfMarkets;
        let currentBudget = 0;

        geographicTargets.forEach(market => {
            currentBudget += market.budget;
        });
        const budgetToDistribute = 1 - currentBudget;
        const budgetForEachMarket = budgetToDistribute * percentageToDistribute;

        geographicTargets.forEach(market => {
            market.budget += budgetForEachMarket;
        })
    }
}

export const isUsingConversionsApi = (campaign_metadata: CampaignMetadata) => {
    return Boolean(
        campaign_metadata?.campaign_state?.campaign?.conversions?.conversionsApiAccessToken &&
        campaign_metadata?.campaign_state?.campaign?.conversions?.conversionsDomain &&
        campaign_metadata?.campaign_state?.campaign?.conversions?.conversionsEventName &&
        campaign_metadata?.campaign_state?.campaign?.conversions?.conversionsEnabled
    )
}

export const getCardDetails = (market: MarketDetail, hasConversions: boolean): CardFormat[] => {
    return [
        {
            icon: ClickIcon,
            title: hasConversions ? 'Conversions' : 'Clicks',
            value: hasConversions ? (market.performance.conversions || market.performance.clicks) : market.performance.clicks,
            isMoney: false,
        },
        {
            icon: EyeIcon,
            title: 'Views',
            value: market.performance.views,
            isMoney: false,
        },
        {
            icon: EyeIcon,
            title: 'Impressions',
            value: market.performance.impressions,
            isMoney: false,
        },
        {
            icon: CommentsIcon,
            title: 'Comments',
            value: market.performance.comments,
            isMoney: false,
        },
        {
            icon: MoneyIcon,
            title: 'Shares',
            value: market.performance.shares,
            isMoney: false,
        },
        {
            icon: MoneyIcon,
            title: hasConversions ? 'Cost-per-conversion' : 'Cost-per-click',
            value: hasConversions ? market.performance.costPerConversion : market.performance.costPerClick,
            isMoney: true,
        },
        {
            icon: ClickIcon,
            title: 'Reach',
            value: market.performance.reach,
            isMoney: false,
        },
        {
            icon: MoneyIcon,
            title: 'CPM',
            value: market.performance.cpm,
            isMoney: true,
        },
    ];
};

export const getAdAccountDetails = async (selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null, access_token?: string) => {
    try {
        if (!fbLoggedIn()) {
            await loginWithFB()
        }
        if (!selectedFBAdAccount) return false;
        const results = await queryFB(`/${selectedFBAdAccount.id}`, {
            params: {
                fields: 'tos_accepted,offsite_pixels_tos_accepted,user_tos_accepted,business,account_status,currency',
                access_token,
            }
        })
        return results;
    } catch (error) {
        console.log("Error getting ad account details: " + JSON.stringify(error))
        Sentry.captureException(error);
        return null;
    }
}

export const getFbAccountDetails = async (selectedFBAccount: SelectedFbPage | FacebookPageType | null) => {
    if (!selectedFBAccount) return false;
    try {
        const results = await queryFB(`/${selectedFBAccount.id}`, {
            method: 'get',
            params: { fields: 'access_token' },
        });
        return results;
    } catch (error) {
        return null;
    }
}

export const getInstagramDetails = async (selectedInstaPage: InstagramPageType | null) => {
    if (!selectedInstaPage?.instagramAccount) return false;

    const selectedInstaAccountId = selectedInstaPage.instagramAccount.id;
    try {
        const results = await queryFB(`/${selectedInstaAccountId}`, {
            params: { fields: 'profile_pic' }
        });
        selectedInstaPage.instagramAccount.profile_pic = results.profile_pic;
        const igDetails = await Axios.put('/platform/instagram/picture', { profile_pic: results.profile_pic });
        return igDetails;
    } catch (e) {
        return null
    }
}

export const validateInstagram = async (igPage: InstagramPageType | null, fbPage: FacebookPageType | null) => {
    if (!igPage || !fbPage) return false;

    try {
        const fbAccountData = await queryFB(`/${fbPage.id}`, {
            params: {
                access_token: fbPage.access_token,
                fields:
                    'connected_instagram_account,instagram_business_account,instagram_accounts',
            },
        });

        const fbLinkedPages = [
            fbAccountData.connected_instagram_account,
            fbAccountData.instagram_business_account,
            ...(fbAccountData.instagram_accounts.data || []),
        ];

        const isFbPageLinkedToIg = Boolean(
            fbLinkedPages.find(
                (igAccount: InstagramPageType) => igAccount.id === igPage.instagramAccount.id
            )
        );

        return isFbPageLinkedToIg;

    } catch (e) {
        console.log("Error validating instagram: " + JSON.stringify(e))
        Sentry.captureException(e);
        return null
    }
}

export const getPreconnectionConfigurationsStatus = (
    preconnectionConfigurations: PreConnectionConfigs | null,
    type: 'tos' | 'account'
): boolean => {
    if (type === 'tos') {
        return Boolean(
            preconnectionConfigurations?.custom_audience_accepted?.status &&
            preconnectionConfigurations?.value_based_custom_audience_tos?.status &&
            preconnectionConfigurations?.user_tos_accepted?.status
        )
    }
    return Boolean(preconnectionConfigurations?.account_status?.status)
}

export function hasAtLeastOnePlatform(platforms: SelectedPlatforms) {
    return Object.values(platforms).some(platform => platform.selected)
}

type CampaignInfoWhenMissMatchStatus = {
    statusInDb: OverallCampaignStatusKeys,
    fbStatus: OverallCampaignStatus,
    campaignId: string,
    daysLeft: number
}

export const handleUpdateCampaignStatusIfMissMatch = async (
    { campaignId, fbStatus, statusInDb, daysLeft }: CampaignInfoWhenMissMatchStatus,
    postUpdateCampaignStatus?: () => Promise<any>,
) => {
    const fbStatusLabel = showAdsStatusLabel(fbStatus, daysLeft)
    const dBStatusLabel = showAdsStatusLabel(OverallCampaignStatus[statusInDb], daysLeft)
    if (fbStatusLabel === dBStatusLabel) return

    if (fbStatusLabel === 'PAUSED') {
        const response = await updateCampaignStatus({ campaignId, status: fbStatusLabel })
        if (postUpdateCampaignStatus) await postUpdateCampaignStatus();
        return response.data?.campaign?.status;
    }
    return null;
}

export const getCampaignAdAccountInfo = (currentBrand: CurrentBrand, campaign_metadata: CampaignMetadata): FacebookAdAccountInfo => {
    const { campaign_state } = campaign_metadata;
    const facebookPixel = campaign_state?.brand?.connections?.facebook_pixel;
    let adAccount: FacebookAdAccountInfo | undefined = currentBrand?.connections?.facebook_ad_account;

    if (!adAccount) {
        adAccount = campaign_state?.brand?.connections?.facebook_ad_account;
    }
    if (!adAccount) {
        adAccount = {
            id: campaign_metadata?.platform_details?.ad_account,
            name: ''
        };
    }

    return {
        id: adAccount?.id,
        name: adAccount?.name,
        business: adAccount?.business
            ? { id: adAccount.business.id, name: adAccount.business.name, }
            : undefined,
        pixel: facebookPixel
            ? { id: facebookPixel.id, name: facebookPixel.name, }
            : undefined,
    }
}