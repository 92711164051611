import { SystemColors } from 'types/globalStyles'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles(() => ({
  fbIconSecondaryColor: {
    '&& path[fill="#1977F3"]': {
      fill: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  fbIconHeader: {
    width: '32px',
    height: '32px',
  },
}))

export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 384px;
  justify-content: center;
`

export const CenterContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const NoAccountsDiv = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 24px;
  background-color: ${SystemColors.PAPER_DIVIDER_CONTAINER};
  border-radius: 8px;
  height: max-content;
`

export const StyledAnchor = styled.a`
  color: ${SystemColors.ACCENT_COLOR};
`
