/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from "react";
import InfoSection from "components/InfoSection";
import { EmailCampaignDetailsData as Data } from "types/global";
import {
  FlexContainer,
  LeftColumn,
  RightColumn,
  SubjectLine,
  EditorContainer,
  FinalizeTabPreview,
  StyledText,
} from "../MessageYourFans/styles";
import useStyles, {
  TabsContainer,
  TopSpacedContainer,
  Label,
  LoadingContainer,
  StyledLabel,
  HeadContainer,
  MainNavbar,
  ViewTitle,
} from "./styles";
import Grid from "@material-ui/core/Grid";
import { TabPanel } from "react-headless-tabs";
import { TabSelector } from "helpers/TabSelector";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import IndicatorsSection from "./Components/IndicatorsSection";
import CustomTable from "./Components/CustomTable";
import { LoadingIndicator } from "components/Loader";
import { getCampaignDetailsData } from "../../api";
import ClickIcon from "assets/images/click.svg";
import MailIcon from "assets/images/mail.svg";
import PaperPlaneIcon from "assets/images/paper-plane.svg";
import { useHistory, useParams } from "react-router-dom";
import Back from "components/shareable/back";
import MainContentContainer from "components/shareable/MainContentContainer";
import Card from "@material-ui/core/Card";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import EmailTemplateDialog from "./Components/EmailTemplateDialog";

enum Tabs {
  SENT_TO = "SENT_TO",
  OPENED_BY = "OPENED_BY",
  CLICKS = "CLICKS",
}

const { SENT_TO, OPENED_BY, CLICKS } = Tabs;

const HYPHEN = "-";
const US_COUNTRY_CODE = "US";

const formatItem = (item: {
  id?: number;
  name: string;
  lastname: string;
  email: string;
  profile_url?: string | null;
  city: string;
  state: string;
  country: string;
}) => {
  const { id, name, lastname, email, profile_url, city, state, country } = item;
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const profileUrl = profile_url;
  let fullName = name || HYPHEN;
  let location = `${city || "Unknown"}, ${state || "Unknown"}`;

  if (lastname) {
    fullName += ` ${lastname.at(0)}.`;
  }

  if (isEqual(country, US_COUNTRY_CODE)) {
    location += ` (${country})`;
  }

  const data = {
    fullName,
    location,
    email,
    profileUrl,
    color: randomColor,
  };

  if (id) {
    return {
      id,
      ...data,
    };
  }

  return data;
};

const FanbaseCampaignDetails: FunctionComponent = () => {
  const theme = useTheme();
  const params = useParams<{ id?: string }>();
  const history = useHistory();
  const id = !isNil(params.id) ? params.id : "0";
  const campaignId = Number(id);
  const classes = useStyles();
  const showRightColumn = useMediaQuery(theme.breakpoints.up(721));
  const showFullHeight = useMediaQuery(theme.breakpoints.down(1024));
  const addCardElevation = useMediaQuery(theme.breakpoints.up(1024));
  const elevation = addCardElevation ? 2 : 0;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(SENT_TO);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({} as Data);
  const content = data?.campaign_metadata?.content;
  const { html_content: template, subject_line: subjectLine } = content || {};
  const emailFanbase = data?.campaign_metadata?.email_fanbase;
  const { openingRate, clicks, openedBy, sentTo, openAverage } =
    emailFanbase || {};
  const sentList = emailFanbase?.sentList?.map((item) => formatItem(item));
  const openedList = emailFanbase?.openedList?.map((item) => formatItem(item));
  const clicksList = emailFanbase?.clicksList?.map((item) => formatItem(item));
  const openingRatesRelation =
    openingRate / openAverage > 1.5 ? 100 : 2 * openAverage;
  const indicators = [
    {
      id: "emails-sent",
      label: "Emails sent",
      value: sentTo,
      icon: PaperPlaneIcon,
    },
    {
      id: "email-opens",
      label: "Emails opens",
      value: openedBy,
      icon: MailIcon,
    },
    {
      id: "emails-clicks",
      label: "Emails clicks",
      value: clicks,
      icon: ClickIcon,
    },
  ];

  const handleOpenEmailTemplateDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseEmailTemplateDialog = () => {
    setOpenDialog(false);
  };

  const handleGoBack = () => {
    history.push("/fans");
  };

  const handleSelectTab = (tab: Tabs) => () => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    (async () => {
      const { data, error } = await getCampaignDetailsData(campaignId);

      if (!error) {
        setLoading(false);
        setData(data as Data);
      }
    })();
  }, [campaignId]);

  return (
    <MainContentContainer>
      <HeadContainer>
        <Card {...{ elevation }} square>
          <MainNavbar>
            <Back
              onClick={handleGoBack}
              parentClassName="absolute top-1/2 transform-gpu -translate-y-1/2 cursor-pointer"
              color="white"
              childClassName="stroke-current text-primary lg:text-white"
            />
            <ViewTitle>Message Analytics</ViewTitle>
          </MainNavbar>
          {loading && (
            <LoadingContainer>
              <LoadingIndicator height="80px" color="black" />
              <StyledLabel marginTop={4}>Loading Campaign Data...</StyledLabel>
            </LoadingContainer>
          )}
          {!loading && (
            <FlexContainer height={showFullHeight ? "100%" : "85vh"}>
              <LeftColumn
                width={showRightColumn ? "55%" : "100%"}
                overflowY="scroll"
              >
                <InfoSection
                  title="Overview"
                  description="Let’s see your message performance"
                />
                <IndicatorsSection
                  {...{
                    openAverage,
                    openingRate,
                    openingRatesRelation,
                    indicators,
                  }}
                />
                <TabsContainer>
                  <TabSelector
                    isActive={isEqual(selectedTab, SENT_TO)}
                    onClick={handleSelectTab(SENT_TO)}
                  >
                    Sent
                  </TabSelector>
                  <TabSelector
                    isActive={isEqual(selectedTab, OPENED_BY)}
                    onClick={handleSelectTab(OPENED_BY)}
                  >
                    Opens
                  </TabSelector>
                  <TabSelector
                    isActive={isEqual(selectedTab, CLICKS)}
                    onClick={handleSelectTab(CLICKS)}
                  >
                    Clicks
                  </TabSelector>
                </TabsContainer>
                <Grid className={classes.paddedGrid}>
                  <Grid>
                    <TabPanel hidden={!isEqual(selectedTab, SENT_TO)}>
                      <TopSpacedContainer>
                        <Label>Fans who received your email</Label>
                        {!showRightColumn && (
                          <StyledLabel
                            color="#8800FF"
                            fontSize={16}
                            fontWeight={400}
                            cursor="pointer"
                            onClick={handleOpenEmailTemplateDialog}
                          >
                            Open email
                          </StyledLabel>
                        )}
                        <TopSpacedContainer>
                          <CustomTable data={sentList} />
                        </TopSpacedContainer>
                      </TopSpacedContainer>
                    </TabPanel>
                    <TabPanel hidden={!isEqual(selectedTab, OPENED_BY)}>
                      <TopSpacedContainer>
                        <Label>Fans who opened your email</Label>
                        {!showRightColumn && (
                          <StyledLabel
                            color="#8800FF"
                            fontSize={16}
                            fontWeight={400}
                            cursor="pointer"
                            onClick={handleOpenEmailTemplateDialog}
                          >
                            Open email
                          </StyledLabel>
                        )}
                        <TopSpacedContainer>
                          <CustomTable data={openedList} />
                        </TopSpacedContainer>
                      </TopSpacedContainer>
                    </TabPanel>
                    <TabPanel hidden={!isEqual(selectedTab, CLICKS)}>
                      <TopSpacedContainer>
                        <Label>Fans who clicked your email</Label>
                        {!showRightColumn && (
                          <StyledLabel
                            color="#8800FF"
                            fontSize={16}
                            fontWeight={400}
                            cursor="pointer"
                            onClick={handleOpenEmailTemplateDialog}
                          >
                            Open email
                          </StyledLabel>
                        )}
                        <TopSpacedContainer>
                          <CustomTable data={clicksList} />
                        </TopSpacedContainer>
                      </TopSpacedContainer>
                    </TabPanel>
                  </Grid>
                </Grid>
              </LeftColumn>
              {showRightColumn && (
                <RightColumn width="45%" overflowY="scroll">
                  <SubjectLine>
                    <StyledText>Subject:</StyledText> {subjectLine}
                  </SubjectLine>
                  <EditorContainer height="96.8%">
                    <FinalizeTabPreview
                      dangerouslySetInnerHTML={{ __html: template }}
                    />
                  </EditorContainer>
                </RightColumn>
              )}
            </FlexContainer>
          )}
        </Card>
      </HeadContainer>
      <EmailTemplateDialog
        {...{ template }}
        subject={subjectLine}
        open={openDialog}
        handleClose={handleCloseEmailTemplateDialog}
      />
    </MainContentContainer>
  );
};

export default FanbaseCampaignDetails;
