import styled from "styled-components";

export const TotalBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #ededed;
  border-radius: 6px;
`;
