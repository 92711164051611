import { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, TextField, Checkbox, FormControlLabel, useMediaQuery, useTheme, InputAdornment } from '@material-ui/core';
import { toast } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import Axios from 'helpers/Interceptor';
import { ButtonConfirm, ButtonCancel, ButtonsContainer, InputContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import clsx from 'clsx';
import { localStyles } from '../EditWebsiteSlugModal/styles';

interface Props {
    open: boolean;
    closeModal: () => void;
    onSuccess: (newSlug: string) => void;
    currentSlug: string; // Updated prop name for clarity
}

const EditBrandSlugModal: FunctionComponent<Props> = ({
    open,
    closeModal,
    onSuccess,
    currentSlug // Updated prop name for clarity
}) => {
    const [newSlug, setNewSlug] = useState<string>(currentSlug);
    const [updatePreSaveCampaigns, setUpdatePreSaveCampaigns] = useState<boolean>(false);
    const [slugError, setSlugError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const classes = useStyles();
    const localClasses = localStyles()

    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

    useEffect(() => {
        if(!open) {
            setNewSlug(currentSlug);
            setUpdatePreSaveCampaigns(false);
            setSlugError('');
        }
    }, [closeModal])

    const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSlug(event.target.value);
        if (event.target.value === '') {
            setSlugError('Slug cannot be empty.');
        } else {
            setSlugError('');
        }
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatePreSaveCampaigns(event.target.checked);
    };

    const handleConfirmEditSlug = async () => {
        if (!newSlug) {
            setSlugError('Slug cannot be empty.');
            return;
        }
        setLoading(true);
        try {
            await Axios.post(`/brand/${currentSlug}/update-slug`, {
                newSlug: newSlug,
                updateActivePreSaveCampaigns: updatePreSaveCampaigns
            });
            toast.success('Brand slug updated successfully!');
            onSuccess(newSlug)
            // closeModal();
        } catch (error) {
            setSlugError('Failed to update slug. It might be already in use or there was another issue.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog open={open} classes={{ root: classes.rootDialog }}>
            <ModalContainer width={setResponsiveView ? "auto" : "678px"} padding={setResponsiveView ? "32px 24px" : "32px"}>
                <HeaderModal
                    closeModal={closeModal}
                    title="Admin: Edit Brand Slug"
                    subtitle={`Edit the brand slug used in URLs (currently "${currentSlug}"). Be careful, as changing the slug will affect all related URLs.`}
                />
                <InputContainer>
                    <TextField
                        InputProps={{
                            classes: { root: classes.inputBorder },
                            startAdornment: <InputAdornment
                                position="start"
                                className={localClasses.inputAdornment}>https://symphony.to/</InputAdornment>,
                        }}
                        className={clsx(localClasses.textField, classes.textField)}
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={newSlug}
                        onChange={handleSlugChange}
                        error={!!slugError}
                        helperText={slugError}

                    />
                    <FormControlLabel
                        control={<Checkbox checked={updatePreSaveCampaigns} onChange={handleCheckboxChange} />}
                        label="Do you also want to update URLs for any active pre-save campaigns?"
                    />
                </InputContainer>
                <ButtonsContainer flexDirection={setResponsiveView ? "column-reverse" : "row"}>
                    <ButtonCancel disabled={loading} onClick={closeModal} width={setResponsiveView ? "100%" : "fit-content"}>
                        Cancel
                    </ButtonCancel>
                    <ButtonConfirm disabled={loading || !newSlug} onClick={handleConfirmEditSlug} width={setResponsiveView ? "100%" : "50%"}>
                        {loading ? "Updating..." : "Update Slug"}
                    </ButtonConfirm>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    );
};

export default EditBrandSlugModal;
