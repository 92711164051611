export const TiktokCampaignTypes = [
  {
    id: "profile-visits",
    name: "✅ Superboost Profile Visits",
    text: "Superboost visits to your profile using this video ad.",
    enabled: true
  },
  {
    id: "link-clicks",
    name: "🗣 Link Traffic",
    text: "Send traffic to a link using your video ads.",
    enabled: true
  },
]

export const VideoGeographyTypes = [
  {
    id: "english",
    name: "🌎 English-speaking Markets",
    text: "Run your video ads only in countries where English is the primary language.",
    subtitle: "This includes markets like the US and Canada."

  },
  {
    id: "global",
    name: "🌐 Global Markets",
    text: "Run your video ads globally, everywhere TikTok is available.",
    subtitle: "This includes markets like Turkey, Egypt, Korea, and the US."
  },

  
  {
    id: "custom",
    name: "📍 Custom",
    text: "Run your video ads in specific countries where TikTok is available.",
    subtitle: null
  },
]

export const minimumDailyBudgetCalculator = (targetingType:string) => {
  if(targetingType === "engagement") {
    return 20 
  } else {
    return 10
  }
} 


export const REVIEW = "Review"
export const REVIEW_YOUR_CAMPAIGN = "Review your campaign"
export const NEXT = "Next"
