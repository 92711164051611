import { NavLink } from "react-router-dom"

export default function NavItem(props: {
    link?: string,
    onClick?: (e: any) => void,
    selected?: boolean
    innerComponent: any
}) {
    const {
        link,
        onClick,
        selected,
        innerComponent
    } = props

    const defaultStyles = `nav-link cursor-pointer text-black  block lg:flex items-center p-0 lg:py-1.5
    lg:px-0 w-full  lg:px-5 transition-all ${selected ? 'active-stroke text-white ' : 'hover:text-gray-300 text-gray-400'}`

    if (link) {
        return (
            <NavLink
                className={defaultStyles}
                activeClassName="text-white active-stroke"
                to={link}
                onClick={(e: any) => {
                    if (onClick) {
                        onClick(e);
                    }
                }}
                exact
            >
                {innerComponent}
            </NavLink>
        )
    } else {
        return (
            <div className={defaultStyles}
                onClick={(e: any) => {
                    if (onClick) {
                        onClick(e);
                    }
                }}>
                {innerComponent}
            </div>
        )

    }
}