import { makeStyles } from "@material-ui/core"
import { SystemColors } from "types/globalStyles"
import styled from "styled-components"

export const useStyles = makeStyles(() => ({
  iconBackgroundSecondaryColor: {
    '& circle': {
      fill: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
}))

export const AdAccountInvalidCtn = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${SystemColors.PAPER_ERROR_CONTAINER};
  padding: 8px;
  gap: 10px;
  font-size: 16px;
  color: ${SystemColors.SECONDARY_TEXT_COLOR};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ExternalLink = styled.a`
  color: ${SystemColors.ACCENT_COLOR};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`