
type GlobalWindow = Window & {
    Intercom: (type: string, article?: number | string) => void;
};

export const IntercomArticleIds = {
    HOW_TO_FIND_CONVERSIONS_EVENT: '8282719',
    PROPER_FB_ACCOUNT_SETUP: '8397001',
    FACEBOOK_INSTAGRAM_CONNECTOR: '6841020',
    HOW_TO_EDIT_CURRENCY: '8725971'
}

const Intercom = {

    /**
     * Opens Intercom
     */
    open: () => {

        (window as unknown as GlobalWindow).Intercom('show')
    },

    /**
   * Opens Intercom Messages
   */
    showNewMessage: (value?: string) => {
        if (value) {
            (window as unknown as GlobalWindow).Intercom('showNewMessage', value);
        } else {
            (window as unknown as GlobalWindow).Intercom('showNewMessage');

        }
    },

    /**
 * Opens Intercom Help Center
 */
    showHelpCenter: () => {

        (window as unknown as GlobalWindow).Intercom('showSpace', 'help')
    },

    /**
* Opens Intercom MEssages list
*/
    showMessages: () => {

        (window as unknown as GlobalWindow).Intercom('showMessages')
    },

    openArticleWithId: (id: string) => {
        (window as unknown as GlobalWindow).Intercom('showArticle', id)
    },

    /** links to an article summarizing Google's ads review policy, shown when ads are denied */
    openYouTubeAdsReview: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8207859")
    },

    openFacebookInstagramConnectorArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "6841020")
    },

    /**
     * Opens the article for setting up an Instagram Business Account
     */
    openInstagramBusinessAccountArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "6841005")
    },

    /**
     * Opens the article that tells you how to find your song's UPC / ISRC
     * to setup a pre-save campaign
     */
    openFindPresaveUPCArticle: () => {
        (window as any).Intercom('showArticle', "6870240")
    },

    /**
     * Tutorial to walk user through how to get Spark ads code
     */
    openTikTokSparkAdsGuide: () => {
        (window as any).Intercom('showArticle', "6984195")
            (window as unknown as GlobalWindow).Intercom('showArticle', "6870240")
    },

    /**
     * Explains what Pre-save Boosts are
     */
    openPresaveBoostArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "6987393")
    },

    openIGPerformanceStatsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "6995580")
    },

    /**
     * Details about the Ambassador Program
     */
    openAmbassadorProgramArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7002468")
    },

    /**
     * Details about the Ambassador Program
     */
    openPlaylistingArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7062153")
    },

    openForeverSavesArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7118407")
    },

    openFacebookPageAccessArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7142088")
    },

    openFacebookSessionArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7546269")
    },

    openSymphonyProArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "7833671")
    },

    openFacebookAdAccountInactiveArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8070103")
    },

    openAcceptFBTermsOfService: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8062946")
    },
    /**
     * Open ad not playing video
     */
    openAdNotPlayingVideo: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8052822")
    },



    /** CONVERSIONS */

    openPresavesConversionsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8210224")
    },

    openTourConversionsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8210224")
    },

    openMerchConversionsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8210224")
    },

    OpenGrowConversionsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8264075")
    },

    openConnectYourPixelToAdAccountArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8264150")
    },

    openWhatIsAPixelArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8264181")
    },

    openWhereIsMyConversionsTokenArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8264198")
    },

    openLearnHowConversionsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "8264252")
    },

    openLearnHowFindConversionsEventArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', IntercomArticleIds.HOW_TO_FIND_CONVERSIONS_EVENT)
    },

    openLearnHowEditCurrency: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', IntercomArticleIds.HOW_TO_EDIT_CURRENCY)
    },

    openWhereIsMyTiktokPixelArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "9043408")
    },

    openWhereIsMyGoogleAnalyticsArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "9043413")
    },

    openWhereIsMyFacebookPixelArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "9043415")
    },

    openRealTimeReleaseArticle: () => {
        (window as unknown as GlobalWindow).Intercom('showArticle', "9358024")
    }
}

export default Intercom