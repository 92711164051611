import millify from "millify";
import { useEffect } from "react";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import { queryFB } from "../../helpers/FB";
import { DebounceInput } from "react-debounce-input";
import { STREAMLINK_REGEX } from "../../helpers/StreamingMappings";
import CloseButton from "components/shareable/CloseButton";
import PopupHeader from "components/shareable/Popup/header";
import { getTiktokPicture } from "pages/post-auth/AddArtist/Components/ConnectPlatformModal/TikTok/api";
import { uploadProfilePicture } from "pages/post-auth/AddArtist/Components/ConnectPlatformModal/InstagramBusinessDiscovery/utils";
import { useAuth0 } from "@auth0/auth0-react";
import gravatar from "gravatar";
import { checkIsValidUsername } from "pages/post-auth/AddArtist/Components/ConnectPlatformModal/TikTok/utils";

interface TikTokConnectProps {
    closeModal: () => void;
    artist: any;
    selected: any;
    set?: any;

    showSave: any;
    localSave: any;
    onSave?: any;
}

export default function TiktokConnect(props: TikTokConnectProps) {
    const { user } = useAuth0();
    const {
        closeModal,
        artist,
        selected,
        set,
        showSave,
        localSave,
        onSave
    } = props

    const [loading, setLoading] = useState<any>(null)
    const [error, setError] = useState<any>(null)

    // these strings map to the username
    //      -- to get the URL, update to `https://tiktok.com/${formattedValue}`
    const [valueString, setValueString] = useState<string>(selected && selected.username ? selected.username : '')

    const [formattedValue, setFormattedValue] = useState<any>(selected ? selected : null)


    async function updateInBrand() {

        let cloudinaryUrl = gravatar.url(user?.email as string, { protocol: 'https', d: 'retro' })
        //TODO: get tiktok picture
        /*try{ 
            //const picture = await getTiktokPicture(formattedValue.username)
            //cloudinaryUrl = await uploadProfilePicture(picture) as string
            if (cloudinaryUrl) {
                setFormattedValue({ ...formattedValue, picture: cloudinaryUrl })
            }
        } catch(e) {
            console.error("Error fetching Tiktok profile picture")
        }*/
        setFormattedValue({ ...formattedValue, picture: cloudinaryUrl })
        Axios.put(`/brand/${artist.slug}/connect`, {
            id: artist.id,
            name: artist.slug,
            service: 'tiktok',
            value: { ...formattedValue, picture: cloudinaryUrl }
        })
        .then((result) => {
            if (set) set(formattedValue)
            onSave()
            closeModal()
        })
        .catch((err) => {
            console.log('error creating brand', err)
        })

    }

    useEffect(() => {

        const foundTiktokName = valueString

        // if first character is an @, remove the @ before storing
        // also force lowercase
        var nameWithoutAt = foundTiktokName.toLowerCase()
        if (nameWithoutAt.charAt(0) === '@') {
            nameWithoutAt = nameWithoutAt.substring(1)
        }
        if (foundTiktokName) {
            setFormattedValue({
                url: `https://tiktok.com/@${nameWithoutAt}`,
                username: nameWithoutAt
            })

        }
    }, [valueString])

    useEffect(() => {
        // if (localSave) {
        //     set(formattedValue)
        // }
    }, [formattedValue])

    function isValid(str: string) {
        return formattedValue && checkIsValidUsername(str);
    }

    return <div className="bg-white w-full h-full mx-auto">

        <PopupHeader
            text="Connect TikTok"
            onClose={closeModal} />
        <div className="middle-wrapper px-4 lg:px-10 ">
            <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
                What is {artist.name}'s TikTok username?
            </h4>
            <div className="input-form flex flex-row align-middle relative">
                <input
                    className="border-b p-6 outline-none text-left border-gray-200 w-full"
                    // minLength={2}
                    placeholder="e.g. xyz"
                    value={valueString}
                    onChange={(e) => setValueString(e.target.value)} />
            </div>
            <p className={`w-12/12 mt-5 text-2x text-center ${!isValid(valueString) ? 'text-red-500' : ''}`}>
                {valueString ? isValid(valueString) ? " " : "Please enter a valid TikTok username." : " "}
            </p>

            <div className="text-center px-4 lg:px-10 py-4">
                <button disabled={error || (!valueString || valueString === "") || !isValid(valueString)} onClick={() => {
                    if (!localSave) {

                        updateInBrand()
                        setLoading(true)
                    } else {
                        if (set) set(formattedValue)

                        closeModal()
                    }
                }} className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3">{loading ? <div className="h-6 m-auto flex justify-center"><LoadingIndicator /></div> : 'Connect'}</button>
            </div>

        </div>
    </div>

}