import {
  BackArrowActionsContainerMobile,
  OverviewAction,
  OverviewActionsContainer,
  OverviewBadgesContainer,
  OverviewCampaignCardContainer,
  OverviewCampaignCardContainerMobile,
  OverviewInfoContainer,
  OverviewSongImage,
  OverviewText,
  OverviewTitleContainer,
  SongName,
  useStyles,
} from './styles';
import DefaultImg from 'assets/images/music-key-green-bg.svg';
import { ReactComponent as PauseIcon } from 'assets/images/pause-purple.svg';
import { ReactComponent as PlayIcon } from 'assets/images/play-purple.svg';
import { ReactComponent as ContextualMenuIcon } from 'assets/images/contextualMenu.svg';
import SmokingLaptopIcon from 'assets/images/smoking-laptop.png';
import IGBoostViews from 'assets/images/ui/ig-boost-campaign-img.png';
import IGBoostFollowers from 'assets/images/ui/ig-boost-profile-visits-img.png';
import CustomMenu, { MenuElement } from 'components/shareable/CustomMenu/index';
import { ReactComponent as DuplicateIcon } from 'assets/images/duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as MoneyIcon } from 'assets/images/money.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import { ReactComponent as BackArrowIcon } from 'assets/images/BackArrow.svg';
import { ReactComponent as GlobeIcon } from 'assets/images/campaignDetails/globe.svg';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { daysLeftCalculator } from 'helpers/General';
import { Fragment, useContext, useMemo, useState } from 'react';
import BadgeCampaignTypeContainer from '../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer';
import CampaignStatusBadge from '../CampaignStatusBadge';
import Loaderv2 from 'components/Loader/v2';
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext';
import ViewSetupOptionsModal from '../Modals/ViewSetupOptionsModal';
import { Settings } from '@material-ui/icons';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { ArtistPermissions } from 'types/global';
import { isBrandAdministrator } from 'utils';
import { CampaignOption } from '../../GrowInstagram/utils';

interface Props {
  goBack: () => void;
  selectedCampaign: any;

  // optional
  campaignName?: string;
  isIGCampaign?: boolean;
  thumbnail?: string;
  campaignStatus?: string;
  campaignStatusLabel?: string;
  isLoading?: boolean;

  downloadReport?: () => void;
  downloadingReport?: boolean;
  disableCampaignUpdates?: boolean; // New prop

  // required for IG + FB campaigns
  handleOpenEditBudgetModal?: () => void; //  optional
  handleOpenEditEndDateModal?: () => void; //  optional
  handleOpenPauseModal?: () => void; //optional
  handleSetMarketsTab?: () => void; // optional

  // avail on all IG + video view campaigns 
  handleOpenDuplicateModal?: () => void;
  handleOpenRenameCampaignModal?: () => void;

  isProUser?: boolean;

  showSetupOptions?: boolean;
}

const STATUSES_TO_SHOW_PAUSE_BUTTON = [
  'active',
  'paused',
  'in_review',
  'live_with_no_stats',
  'live_with_issues',
  'anticipated_puase',
  'to_retry',
];

const STATUSES_TO_SHOW_DOWNLOAD_BUTTON = [
  'active',
  'paused',
  'live_with_issues',
  'finished'
];

const OverviewCampaignCard = ({
  isLoading,
  campaignName,
  goBack,
  thumbnail,
  selectedCampaign,
  campaignStatus = 'pending',
  campaignStatusLabel,
  disableCampaignUpdates,
  handleOpenEditBudgetModal,
  handleOpenEditEndDateModal,
  handleOpenPauseModal,
  handleOpenDuplicateModal,
  handleSetMarketsTab,
  downloadReport,
  downloadingReport,
  handleOpenRenameCampaignModal,
  isProUser,
  showSetupOptions,
  isIGCampaign,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdWidthScreen = useMediaQuery(theme.breakpoints.down(1024));

  const { handleOpenModal } = useContext(UpgradeModalContext);
  const { currentBrand } = useContext(CurrentBrandContext);

  const [openSetupModal, setOpenSetupModal] = useState(false);

  const { campaign_metadata } = selectedCampaign;
  const { content, campaign_type, friendlyName, campaign_state, creative } = campaign_metadata;
  const {
    thumbnail_url,
    type: contentType
  } = content;

  const active: boolean = campaignStatus !== 'finished';
  const pause: boolean = campaignStatus === 'paused' || campaignStatus === 'anticipated_puase';
  const labelPause = pause ? 'Resume' : 'Pause';

  let setupDetails: MenuElement | undefined = undefined;
  const hasAdminAccess = isBrandAdministrator(currentBrand!)

  if (isIGCampaign && hasAdminAccess) {
    setupDetails = {
      icon: <Settings />,
      text: 'View setup details',
      show: true,
      onClick: () => setOpenSetupModal(true)

    }
  }
  const getMenuItems = (): MenuElement[] => {
    const items: MenuElement[] = []
    // Check if campaign updates are disabled,
    // if so don't show anything that edits the campaign's 
    // budget, end date, or locations, etc.

    if (disableCampaignUpdates) {
      if (handleOpenRenameCampaignModal) {
        items.push({
          icon: <EditIcon className={classes.menuItemSvgGray} />,
          text: 'Rename campaign',
          show: !!handleOpenRenameCampaignModal,
          onClick: handleOpenRenameCampaignModal
        })
      }

      if (handleOpenDuplicateModal) {
        items.push(
          {
            icon: <DuplicateIcon />,
            text: 'Duplicate campaign',
            show: true,
            onClick: handleOpenDuplicateModal
          }
        )
      }

      return items
    }

    if (handleOpenEditBudgetModal) {
      items.push({
        icon: <MoneyIcon />,
        text: 'Edit budget',
        show: active && !!handleOpenEditBudgetModal,
        onClick: handleOpenEditBudgetModal
      })
    }

    if (handleOpenEditEndDateModal) {
      items.push({
        icon: <CalendarIcon color='black' />,
        text: 'Edit end date',
        show: active && !!handleOpenEditEndDateModal,
        onClick: handleOpenEditEndDateModal
      })
    }

    if (handleSetMarketsTab) {
      items.push({
        icon: <GlobeIcon className={classes.menuItemSvgGray} />,
        text: 'Edit locations',
        show: !!handleSetMarketsTab,
        onClick: handleSetMarketsTab
      })
    }


    if (handleOpenRenameCampaignModal) {
      items.push({
        icon: <EditIcon className={classes.menuItemSvgGray} />,
        text: 'Rename campaign',
        show: true,
        onClick: handleOpenRenameCampaignModal
      });
    }

    if (handleOpenDuplicateModal) {
      items.push({
        icon: <DuplicateIcon />,
        text: 'Duplicate campaign',
        show: true,
        onClick: handleOpenDuplicateModal
      });
    }

    if (showSetupOptions && setupDetails) {
      items.push(setupDetails);
    }

    return items;
  };

  const menuItems: MenuElement[] = getMenuItems()

  const { endDate } = campaign_metadata;
  const daysLeft: number = useMemo(
    () => daysLeftCalculator(new Date().toString(), endDate),
    [endDate]
  );

  const showDownload = (status: string) => (
    !isLoading &&
    Boolean(downloadReport) &&
    STATUSES_TO_SHOW_DOWNLOAD_BUTTON.includes(status))

  const showDownloadButton: boolean = showDownload(campaignStatus);

  const showPause = (status: string) => (
    !isLoading &&
    Boolean(handleOpenPauseModal) &&
    STATUSES_TO_SHOW_PAUSE_BUTTON.includes(status) &&
    daysLeft >= 0)
  const showPauseButton: boolean = showPause(campaignStatus);

  function renderDownloadButton() {
    if (!downloadReport || !hasAdminAccess) return null
    if (downloadingReport) {
      return (
        <OverviewAction>
          Downloading
          <div className='ml-1'>
            <Loaderv2 height={16} width={16} />
          </div>
        </OverviewAction>
      )
    } else {
      return (<OverviewAction onClick={() => {
        if (!isProUser) {
          handleOpenModal({
            source: "IG Campaign Details - Download Report"
          })
          return
        } else {
          downloadReport()
        }
      }}>
        Download Report
        <PlayIcon className={classes.actionIcon} />
      </OverviewAction>)
    }
  }

  const showMoreOptions = (status: string) =>
    !isLoading && (status !== 'to_retry' && status !== 'anticipated_puase');

  const showMoreOptionsButton: boolean = showMoreOptions(campaignStatus);

  const campaignImage = () => {
    switch (campaign_type) {
      case 'record_streams':
      case 'grow_playlist_followers':
        return <OverviewSongImage src={thumbnail_url || DefaultImg} />;
      case 'link_clicks':
        return <OverviewSongImage src={SmokingLaptopIcon} />;
      case 'boost_instagram':


        let campaignImg = IGBoostViews
        let campaignSubtype: CampaignOption | undefined = undefined;
       
        if (campaign_type === 'boost_instagram') {
          campaignSubtype = campaign_state?.campaign?.targeting?.campaignType || undefined
        }

        if (campaignSubtype) {

          switch (campaignSubtype?.id) {
            case 'boost_views':
              campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || creative?.owner?.profile_pic || IGBoostViews
              break;
            case 'boost_follows':
              campaignImg = creative?.owner?.profile_pic || creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || IGBoostFollowers
              break;
          }

        } else {
          campaignImg = creative?.thumbnail_url || creative?.children?.data?.[0]?.media_url || creative?.owner?.profile_pic || IGBoostViews

        }

        return <OverviewSongImage src={campaignImg} />;
      case 'increase_video_views':
        return <OverviewSongImage src={thumbnail || DefaultImg} />;
      default:
        return <></>;
    }
  };

  const getCampaignName = () => {
    if (campaignName) return campaignName

    switch (campaign_type) {
      default:
        let mainName = (friendlyName || content?.name || content?.campaign_name || campaignName)

        if (contentType === 'playlist') {
          mainName = content?.name + ' (Playlist)'
        }
        return mainName
    }
  };

  const getMenuItemsByStatus = (status: string) => {
    if (
      (status === 'active' && daysLeft <= 0) ||
      status === 'live_with_issues' ||
      campaignStatusLabel === 'FINISHED'
    ) {
      const options = []
      const renameItem = menuItems.find(
        (item) => item.text === 'Rename campaign'
      );

      if (renameItem) options.push(renameItem)

      const duplicateItem = menuItems.find(
        (item) => item.text === 'Duplicate campaign'
      );

      if (duplicateItem) options.push(duplicateItem)

      if (setupDetails) {
        options.push(setupDetails)

      }

      return options
    }

    return menuItems;
  };

  const internalModals = [
    (
      <Fragment>
        {
          !isLoading && selectedCampaign && <ViewSetupOptionsModal
            selectedCampaign={selectedCampaign}
            closeModal={() => {
              setOpenSetupModal(false)
            }}
            open={openSetupModal}
          />
        }
      </Fragment>
    )
  ]

  let defaultCampaignName = getCampaignName()



  if (isMdWidthScreen) {
    return (
      <Fragment>
        <OverviewCampaignCardContainerMobile>
          <BackArrowActionsContainerMobile>
            <BackArrowIcon onClick={goBack} />
            <OverviewActionsContainer>
              {showPauseButton && hasAdminAccess && (
                <OverviewAction onClick={handleOpenPauseModal}>
                  {labelPause}
                  {pause ? (
                    <PlayIcon className={classes.actionIcon} />
                  ) : (
                    <PauseIcon className={classes.actionIcon} />
                  )}
                </OverviewAction>
              )}
              {/* {showDownloadButton && renderDownloadButton()} */}
              {showMoreOptionsButton && hasAdminAccess && (
                <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
                  <OverviewAction>
                    Edit <ContextualMenuIcon className={classes.actionIcon} />
                  </OverviewAction>
                </CustomMenu>
              )}
            </OverviewActionsContainer>
          </BackArrowActionsContainerMobile>
          <OverviewTitleContainer>
            {campaignImage()}
            <OverviewInfoContainer>
              <OverviewText>Overview</OverviewText>
              <SongName>{defaultCampaignName}</SongName>
              <OverviewBadgesContainer>
                <BadgeCampaignTypeContainer campaignType={campaign_type} />
                {!isLoading && (
                  <CampaignStatusBadge
                    campaignStatus={campaignStatus}
                    campaignStatusLabel={campaignStatusLabel}
                  />
                )}
              </OverviewBadgesContainer>
            </OverviewInfoContainer>
          </OverviewTitleContainer>
        </OverviewCampaignCardContainerMobile>
        {internalModals}

      </Fragment>
    );
  }

  return (
    <>
      <OverviewCampaignCardContainer>
        <BackArrowIcon onClick={goBack} />
        <OverviewTitleContainer>
          {campaignImage()}
          <OverviewInfoContainer>
            <OverviewText>Overview</OverviewText>
            <SongName>{defaultCampaignName}</SongName>
            <OverviewBadgesContainer>
              <BadgeCampaignTypeContainer campaignType={campaign_type} />
              {!isLoading && (
                <CampaignStatusBadge
                  campaignStatus={campaignStatus}
                  campaignStatusLabel={campaignStatusLabel}
                />
              )}
            </OverviewBadgesContainer>
          </OverviewInfoContainer>
        </OverviewTitleContainer>
        <OverviewActionsContainer>
          {showPauseButton && hasAdminAccess && (
            <OverviewAction onClick={handleOpenPauseModal}>
              {labelPause}
              {pause ? (
                <PlayIcon className={classes.actionIcon} />
              ) : (
                <PauseIcon className={classes.actionIcon} />
              )}
            </OverviewAction>
          )}
          {showDownloadButton && hasAdminAccess && renderDownloadButton()}
          {showMoreOptionsButton && hasAdminAccess && (
            <>
              <CustomMenu menuItems={getMenuItemsByStatus(campaignStatus)}>
                <OverviewAction>
                  Edit <ContextualMenuIcon className={classes.actionIcon} />
                </OverviewAction>
              </CustomMenu>
            </>
          )}
        </OverviewActionsContainer>
      </OverviewCampaignCardContainer>
      {internalModals}
    </>
  );
};

export default OverviewCampaignCard;
