import React, { ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useMemo, useState, useEffect } from 'react'
import {
  MainContainer,
  ModalPlatformInputContainer,
  PlatformLogoContainer,
  SearchListContainer,
  useStyles
} from './styles'
import { ReactComponent as SpotifyIcon } from "assets/images/spotify.svg"
import { InputAdornment, TextField } from '@material-ui/core'
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from 'react-lottie'
import debounce from "lodash/debounce"
import { getHelperText } from 'pages/post-auth/AddArtist/Components/ConnectPlatformModal/Spotify/utils'
import ArtistList from '../ArtistList'
import { CurrentBrand, SpotifyArtist } from 'types/global'
import axios from 'axios'
import { getSpotifyToken, getIfSpotifyArtistExists, connectBrand } from '../../api'
import { sleep } from 'utils'
import { track } from "analytics"
import * as Sentry from "@sentry/react"
import { getSpotifyArtistIdFromUrl } from 'helpers/Spotify'

interface Props {
  disabled?: boolean,
  allowNewSpotifyArtistConnection?: boolean,
  artistName: string,
  localSave: boolean
  currentBrand?: CurrentBrand,
  setSpotifyArtist?: Dispatch<SetStateAction<SpotifyArtist | null>>,
  handleShowAlreadyExistsModal: (artist: SpotifyArtist, existingBrand: CurrentBrand) => void,
  closeModal: () => void,
  onSave: () => void,
  children?: React.ReactNode,
  shown?: boolean,
}

const InputSearchSpotify: FunctionComponent<Props> = ({
  disabled,
  artistName,
  setSpotifyArtist,
  allowNewSpotifyArtistConnection,
  handleShowAlreadyExistsModal,
  localSave = true,
  currentBrand,
  onSave,
  children,
  shown
}: Props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>(artistName || '');
  const [error, setError] = useState<boolean>(false)
  const [searchResults, setSearchResult] = useState<any[]>([])
  const [spotifySearchString, setSpotifySearchString] = useState("")
  const [selectedArtist, setSelectedArtist] = useState<SpotifyArtist | null>(null)

  const [userExecutedSearch, setUserExecutedSearch] = useState<boolean>(false)
  const helperText = getHelperText({
    error,
    results: searchResults,
    loading,
    spotifySearchString,
    textFieldValue,
    disabled,
  })

  const refreshSpotifyToken = async () => {
    const { data, error } = await getSpotifyToken();

    if (!error && data?.token) {
      localStorage.setItem('spotify_token', data.token)
    }
  }

  useEffect(() => {
    refreshSpotifyToken()
  }, []);


  useEffect(() => {
    if (shown) {
      callSpotifySearch(artistName)
    }
  }, [shown])

  async function callSpotifySearch(value: string) {
    try {
      const spotifyToken = localStorage.getItem('spotify_token')
      if (!spotifyToken) await refreshSpotifyToken()

      const spotifyHeaders = {
        Authorization: 'Bearer ' + spotifyToken
      }

      // if the search string includes spotify URL, then extract ID
      const spotifyArtistIdMatch = getSpotifyArtistIdFromUrl(value)
      if (spotifyArtistIdMatch.valid && spotifyArtistIdMatch.artistId) {
        const artistId = spotifyArtistIdMatch.artistId
        const results = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, { headers: spotifyHeaders })
        if (results && results.data) {
          setSearchResult([results.data])
        }
      } else {
        const results = await axios.get(`https://api.spotify.com/v1/search?q=${value}&type=artist&limit=30`, { headers: spotifyHeaders })
        setSearchResult(results.data.artists.items)
      }
    } catch (e) {
      await refreshSpotifyToken()
    } finally {
      setLoading(false)

    }
  }
  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await callSpotifySearch(value)
      }, 800),
    []
  )

  const handleChangeTextField = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (error) setError(false)

    if (value.length) {
      setLoading(true)
      debouncedChangeHandler(value)
    } else {
      setSearchResult([]);
    }
    setTextFieldValue(value)
  }


  async function searchSpotify(artistId: string): Promise<SpotifyArtist | null> {
    const spotifyToken = localStorage.getItem('spotify_token')
    if (!spotifyToken) await refreshSpotifyToken()

    try {
      const spotifyHeaders = {
        Authorization: 'Bearer ' + spotifyToken
      }
      const result = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, { headers: spotifyHeaders })

      return result.data || null as SpotifyArtist | null;
    } catch (e: unknown) {
      const { response } = e as { response: { status: number } }
      const { status } = response

      if (status === 401) {
        console.error("Spotify access token expired ~ grabbing new one")
        await refreshSpotifyToken()
        return await searchSpotify(artistId)
      } else {
        console.error('error pulling spotify', response)
        return null
      }
    }
  }

  const selectArtist = async (selectedArtist: SpotifyArtist) => {
    let spotifyProfile: SpotifyArtist | null = null

    setSelectLoading(true)
    setSelectedArtist(selectedArtist)

    setSpotifyArtist?.(null);
    localStorage.removeItem('spotify_artist');

    if (!selectedArtist.id) {
      setError(true)
      setSelectLoading(false);
      return;
    }

    track("Selected Artist from Modal", {
      ...selectedArtist,
      pulledFromChartmetric: false,
    })

    try {
      spotifyProfile = await searchSpotify(selectedArtist.id)
    } catch (err) {
      try {
        await sleep(5000);
        spotifyProfile = await searchSpotify(selectedArtist.id)
      } catch (err) {
        setError(true)
        setSelectLoading(false);
        Sentry.captureException(err);
      }
    }

    if (!error) {

      if (!allowNewSpotifyArtistConnection) {
        const { data: spotifyArtistExistsData, error: spotifyArtistExistsError } =
          await getIfSpotifyArtistExists(spotifyProfile?.id || null)

        if (spotifyArtistExistsError) {
          setError(true);
          setSelectLoading(false);
          return;
        }

        if (spotifyArtistExistsData?.id) {
          track("New Artist Already Exists", selectedArtist)
          setSelectLoading(false)
          handleShowAlreadyExistsModal(selectedArtist, spotifyArtistExistsData);
          return;
        }
      }

      if (localSave) {
        setSpotifyArtist?.(spotifyProfile)
      } else {
        try {
          const slug = currentBrand?.slug || '';
          if (slug && spotifyProfile) {
            await connectBrand(slug, spotifyProfile);
            setSpotifyArtist?.(spotifyProfile);
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      }

      onSave()
    }

    track("Linked Spotify from Modal")
    setSpotifySearchString(textFieldValue)
    setError(false)
    setSelectLoading(false);
  }

  return (
    <MainContainer>
      <ModalPlatformInputContainer>
        <PlatformLogoContainer backgroundColor="#191919" width='48px' height='48px'>
          <SpotifyIcon height="32px" width="32px" />
        </PlatformLogoContainer>
        <TextField
          InputProps={{
            classes: {
              root: classes.inputBorder,
              disabled: classes.inputDisable,
            },
            endAdornment: loading && (
              <InputAdornment position="end">
                <Lottie
                  height={24}
                  width={24}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SymphonyLoadingLottie,
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={textFieldValue}
          onChange={handleChangeTextField}
          className={classes.textField}
          variant="outlined"
          placeholder="Bad Bunny,  SZA..."
          error={Boolean(error)}
          FormHelperTextProps={{
            classes: {
              root: classes.formHelperText
            },
          }}
          fullWidth
          helperText={helperText}
          disabled={disabled}
        />
      </ModalPlatformInputContainer>
      {searchResults && !disabled && searchResults.length > 0 && (
        <SearchListContainer>
          <ArtistList
            loading={selectLoading}
            artists={searchResults}
            selectArtist={selectArtist}
            selectedArtist={selectedArtist}
          />
        </SearchListContainer>
      )}
    </MainContainer>
  )
}

export default InputSearchSpotify
