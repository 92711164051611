import { FunctionComponent, useEffect, useState, useContext } from "react";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import { ReactComponent as AddIcon } from "assets/images/add-icon.svg";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";
import BrandsListItemModal from "./BrandListItemModal";
import { CurrentBrand, Tiers } from "types/global";
import { TeamPlanContext } from "pages/post-auth/OrganizationPage/hooks/TeamMembersContext";
import { Backdrop, Fade, Grid, Modal } from "@material-ui/core";
import { CAMPAIGN_CARDS } from "pages/post-auth/MarketingPage/utils/marketing";
import CreateCampaignCard from "pages/post-auth/MarketingPage/Components/CreateNewCampaignModal/CreateCampaignCard";
import { trackNewCampaign } from "pages/post-auth/MarketingPage/Components/CampaignsSection/analytics";
import useStyles from "./styles";
import Axios from "helpers/Interceptor";
import { ReactComponent as UserIcon } from 'assets/images/user.svg';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import InputWithDebounce from "../InputWithDebounce"
import { LoadingLottie } from "components/Loader/LoadingLottie"
import MaestroCampaignRecommender from "pages/post-auth/MarketingPage/Components/CreateNewCampaignModal/MaestroCampaignRecommender"
import { Body2 } from "../Typography"
import { SystemColors } from "types/globalStyles"

interface Props {
  isOpen: boolean;
  onClose: () => void;
  existingBrands: CurrentBrand[];
  currentBrand: CurrentBrand | undefined;
  setCurrentBrand: (brand: CurrentBrand) => void;
}

interface DisplayedBrand {
  name: string;
  image: string;
  slug: string;
  isPro: boolean;
  isLoading: boolean;
  id: string;
}

type ModalView = "selectBrand" | "createCampaign";

const CreateCampaignModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  existingBrands,
}: Props) => {
  const { teamArtists } = useContext(TeamPlanContext);
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
  const classes = useStyles();
  const [displayedBrands, setDisplayedBrands] = useState<DisplayedBrand[]>([]);
  const [currentView, setCurrentView] = useState<ModalView>("selectBrand");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    const filteredBrands = defaultDisplayedBrands().filter((brand) =>
      brand.name.toLowerCase().includes(value.toLowerCase())
    );
    setDisplayedBrands(filteredBrands);
  };

  const handleChangeText = (value: string) => {
    if (!value) {
      setDisplayedBrands([])
    } else {
      setSearchTerm(value);
    }
  }


  async function handleBrandSelection(brandId: string) {
    try {
      setIsLoading(true)
      if (currentBrand?.id === parseInt(brandId)) {
        setCurrentView("createCampaign");
        return;
      }
      const response = await Axios.get(`/brand/${brandId}`);
      const newBrand = response.data.data as CurrentBrand;
      setCurrentBrand(newBrand || currentBrand);
      setCurrentView("createCampaign");
    } catch (error) {
      return null;
    } finally {
      setIsLoading(false)
    }
  }

  function renderSelectArtistList() {
    if(!displayedBrands.length) {
      return (
        <div className="flex flex-col justify-center items-center h-64 bg-gray-200">
          <UserIcon className="w-8 h-8 text-gray-500" />
          <p className="text-gray-500">No results found, try another search.</p>
        </div>
      );
    }

    return (
      <div>
        {displayedBrands.map((brand: DisplayedBrand, idx: number) => {
          return (
            <BrandsListItemModal
              slug={brand.slug!}
              name={brand.name!}
              isPro={brand.isPro}
              image={brand.image!}
              onClickListItem={() => handleBrandSelection(brand.id)}
              key={idx}
            />
          );
        })}
      </div>
    );
  }

  function defaultDisplayedBrands(): DisplayedBrand[] {
    if (teamArtists.length && existingBrands.length) {
      const filteredBrands = existingBrands.filter(({ id }: CurrentBrand) => {
        const isMemberOfTeam = teamArtists.some((artist) => artist.id === id);
        return isMemberOfTeam;
      });
      const formattedBrands = filteredBrands.map((o: CurrentBrand) => {
        let selectedForPro = o.tier === Tiers.PRO;
        return {
          id: String(o.id),
          name: o.name!,
          image: o.image!,
          slug: o.slug!,
          isPro: selectedForPro,
          isLoading: false,
        };
      });
      return formattedBrands;
    }
    return [];
  }

  function renderChoosePlaybook() {
    return (
      <div className={classes.playbooksContainer}>
        <div className={`${classes.playbooksContent} px-6`}>
          <div className="mb-4 w-full flex items-center flex-row justify-center px-2 gap-1.5">
            <div className="h-0.5 flex-1 w-auto" style={{ background: SystemColors.DIVIDER_LINE }}></div>
            <Body2>Or choose a Playbook:</Body2>
            <div className="h-0.5 flex-1 w-auto" style={{ background: SystemColors.DIVIDER_LINE }}></div>
          </div>
          <div className="flex mt-4">
            <div className={classes.campaignContainer}>
              <div className={classes.rightSection}>
                <div id="all">
                  <Grid container spacing={2}>
                    {CAMPAIGN_CARDS.sort((campaignA: any, campaignB: any) => {
                      if (
                        campaignA.underMaintenance &&
                        !campaignB.underMaintenance
                      ) {
                        return 1;
                      }
                      if (
                        !campaignA.underMaintenance &&
                        campaignB.underMaintenance
                      ) {
                        return -1; 
                      }
                      return 0; 
                    }).map((campaign, index) => {
                      if (campaign.underMaintenance) {
                        return (
                          <Grid item key={index} xs={12} sm={6} md={4}>
                            <CreateCampaignCard
                              requiresPro={campaign.requiresPro}
                              title={campaign.title}
                              description={campaign.description}
                              imageUrl={campaign.imageUrl}
                              chipLabel
                              statusMessage={
                                campaign.underMaintenance
                                  ? campaign.statusMessage
                                  : null
                              }
                              status={
                                campaign.underMaintenance ? "maintenance" : null
                              }
                            />
                          </Grid>
                        );
                      } else {
                        return (
                          <Grid item key={index} xs={12} sm={6} md={4}>
                            <CreateCampaignCard
                              requiresPro={campaign.requiresPro}
                              link={`/marketing/new/${campaign.link}`}
                              chipLabel
                              onLinkClick={() => {
                                trackNewCampaign({
                                  type: campaign.title,
                                  from: "list",
                                  zeroState: false,
                                  aiQuestion: null,
                                  currentBrand,
                                });
                              }}
                              title={campaign.title}
                              description={campaign.description}
                              imageUrl={campaign.imageUrl}
                              status={
                                campaign.underMaintenance ? "maintenance" : null
                              }
                              statusMessage={
                                campaign.underMaintenance
                                  ? campaign.statusMessage
                                  : null
                              }
                              isConversionsCampaign={
                                campaign.isConversionsCampaign
                              }
                            />
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function closeModal() {
    onClose();
    setCurrentView("selectBrand");
  }

  useEffect(() => {
    if (teamArtists.length && existingBrands.length) {
      setDisplayedBrands(defaultDisplayedBrands());
    }
  }, [existingBrands, teamArtists]);

  return (
    <Modal
        aria-labelledby="connector-modal"
        aria-describedby="connector-modal for platforms"
        className={"flex items-center justify-center"}
        open={isOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 200,
        }}
    >
      <Fade in={isOpen}>
        <div
            className={`${classes.modalContent} w-full ${isLoading && 'h-full'} max-w-5xl	bg-white md:rounded-md ${currentView === "selectBrand" ? "md:w-4/6 lg:w-2/6" : "md:w-4/6 lg:w-4/6"}`}>
          {isLoading ? (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <LoadingLottie
                black
                width={24}
                height={24}
              />
            </div>
          ) : currentView === "selectBrand" ? (
              <div className="flex flex-col gap-8 px-8 py-8">
                <HeaderModal
                  title="Create a new campaign"
                  subtitle="Select the artist account you want to promote"
                  HeaderIcon={AddIcon}
                  closeModal={closeModal}
                />
                  <InputWithDebounce
                    onChange={handleChangeText}
                    debounceHandler={handleSearchChange}
                    value={searchTerm}
                    placeholder="Search..."
                    loading={false}
                    debounceTime={800}
                    leftIcon={<SearchIcon />}
                  />
                {renderSelectArtistList()}
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex flex-col pt-10 pb-14 px-8 gap-10" style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                  <HeaderModal
                    title={`New campaign for ${currentBrand?.name}`}
                    subtitle="Run automated marketing campaigns to grow your streams, views and followers."
                    closeModal={closeModal}
                    textCenter
                    imageIcon={currentBrand?.image || ""}
                  />
                  <MaestroCampaignRecommender />
                </div>
                {renderChoosePlaybook()}
              </div>
            )
          }
        </div>
      </Fade>
    </Modal>
  );
};

export default CreateCampaignModal;
