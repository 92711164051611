import { Theme, makeStyles } from '@material-ui/core';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    display: 'flex !important',
    justifyContent: 'space-between',
    width: '100%',
    '& > td': {
      display: 'flex',
      border: 'none !important',
      alignItems: 'center',
    },
  },
  bordered: {
    borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
  },
  redIcon: {
    '& path': {
      stroke: SystemColors.ERROR_COLOR,
    },
  },
  dataGridRoot: {
    border: "none !important",
    outline: "none !important",
    "& .MuiDataGrid-row:hover": {
      background: "none !important",
    },
    "& .MuiDataGrid-main, .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: `${SystemColors.PAPER_CONTAINER} !important`,
    },
    "& .MuiDataGrid-cell:focus": {
      outline: "none",
      borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    "& .MuiDataGrid-cell, .MuiDataGrid-row": {
      borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
      cursor: "pointer",
    },
    "& .MuiDataGrid-row:last-of-type": {
      outline: "none",
      borderBottom: 'none',
    },
    "& .MuiDataGrid-row:first-of-type": {
      outline: "none",
      borderTop: 'none',
    },
    "& .MuiDataGrid-cell:hover": {
      cursor: "default",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
      outline: "none !important",
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none !important',
    },
  },
}));
