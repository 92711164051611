import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

interface FlexContainerProps {
  marginTop?: string
  margin?: string
  flexDirection?: string
  justifyContent?: string
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  margintop: ${({ marginTop }) => marginTop};
  margin: ${({ margin }) => margin};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${SystemColors.DIVIDER_LINE};
`

export const GrayContainer = styled.div`
  padding: 8px 8px 8px 16px;
  background-color: ${SystemColors.DIVIDER_LINE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
`

export const StyledContainer = styled.div``
