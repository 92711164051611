import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";
import { SystemColors } from "types/globalStyles";

interface Props {
  error?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  overall: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    borderBottomWidth: 1,
  },
  marketing: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
  },
  automationFee: {
    marginTop: theme.spacing(2),
  },
  noBottomBorder: {
    borderBottomWidth: 0,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  paddingBottomThemeSpacing: {
    paddingBottom: theme.spacing(1),
  },
  textField: { height: 48, maxWidth: 116, borderRadius: 6 },
  inputProps: ({ error }: Props) => ({
    fontFamily: "DIN",
    textAlign: "right",
    color: error ? SystemColors.ERROR_COLOR : SystemColors.PRIMARY_TEXT_COLOR,
  }),
}))

export const SpendLimitContainer = styled.div`
  margin-top: 16px;
`

export default useStyles;
