import { Theme, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import Text from "modules/Text"


//// new way of doing styles
import { CSSProperties, FunctionComponent, ReactNode } from "react";
import { MEDIA_BREAKPOINTS } from "types/global";
import { SystemColors } from "types/globalStyles";

export interface SHARED_WEBSITE_STYLES_PROPS {
  subHeaderText: CSSProperties & { marginBottom: number };
  subPanel: CSSProperties;
  editorPane: CSSProperties & { maxWidth: number };
  innerPanelSection: CSSProperties;
}

export const SHARED_WEBSITE_STYLES = makeStyles((_theme: Theme) => ({
  subHeaderText: {
    ...Text.subHeader,
  },
  subPanel: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    backgroundColor: "white",
    borderRadius: 12,
    padding: 24,
    [MEDIA_BREAKPOINTS.mobileView]: {
      borderRadius: 0,
    }
  },
  editorPane: {
    maxWidth: 680,
    width: "60%",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [MEDIA_BREAKPOINTS.mobileView]: {
      width: "100%",
    },
  },
  innerPanelSection: {
    padding: 16,
    borderRadius: 12,
    border: "1px solid #EDECF2",
  },
}))

interface WebsiteEditorCardProps {
  title?: string;
  subtitle?: string;
  gap?: number;
  padding?: string | number;
  children: ReactNode;
}

export const WebsiteEditorCard: FunctionComponent<WebsiteEditorCardProps> = ({
  title,
  subtitle,
  gap = 8,
  padding = 24,
  children,
}) => {
  const classes = SHARED_WEBSITE_STYLES()
  return (
    <div className={classes.subPanel} style={{ gap, padding }}>
      {title && <SubHeaderText text={title} />}
      {subtitle && (
        <div style={Text.primary}>{subtitle}</div>
      )}
      {children}
    </div>
  )
}

interface SubHeaderTextProps {
  text: string;
}

export const SubHeaderText: FunctionComponent<SubHeaderTextProps> = ({
  text,
}) => {
  const classes = SHARED_WEBSITE_STYLES()
  return (
    <p className={classes.subHeaderText}>
      {text}
    </p>
  )
}

export const renderBorderSeparator = (style?: object) => {
  return (
    <div className="borderSeparator" style={{ ...Text.borderSeparator, ...style }} />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  grayText: {
    color: "#707070",
    cursor: "pointer",
    fontSize: 16,
  },
  select: {
    height: 44,
    marginLeft: "auto",
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: theme.spacing(1.75),
    },
    [theme.breakpoints.down(361)]: {
      width: "100%",
    },
  },
  drawerPaper: {
    alignItems: 'center',
    backgroundColor: 'rgb(245, 243, 246)',
    borderRadius: '8px 8px 0px 0px',
  },
}));

export interface ContainerProps {
  responsiveFlexDirection?: string;
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
  height?: string;
  marginLeft?: string;
  borderRadius?: string;
  justifyContent?: string;
  marginTop?: string;
  cursor?: string;
  maxWidth?: string
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
}

export interface ImageProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  backgroundColor?: string;
  alignItems?: string;
  width?: number;
  height?: number;
  marginLeft?: string;
  borderRadius?: string;
  justifyContent?: string;
}

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
`;

export const Image = styled.img`
  gap: ${({ gap }: ImageProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ImageProps) => flexDirection};
  display: ${({ display }: ImageProps) => display};
  align-items: ${({ alignItems }: ImageProps) => alignItems};
  padding: ${({ padding }: ImageProps) => padding};
  background-color: ${({ backgroundColor }: ImageProps) => backgroundColor};
  width: ${({ width }: ImageProps) => width};
  margin-left: ${({ marginLeft }: ImageProps) => marginLeft};
  border-radius: ${({ borderRadius }: ImageProps) => borderRadius};
  justify-content: ${({ justifyContent }: ImageProps) => justifyContent};
  height: ${({ height }: ImageProps) => height};
`;

export const Container = styled.div<ContainerProps>`
  gap: ${({ gap }) => gap || "0px"};
  cursor: ${({ cursor }) => cursor || "default"};
  flex-direction: ${({ flexDirection }) => flexDirection};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${({ borderBottom }) => borderBottom};
  width: ${({ width }) => width};
  margin-left: ${({ marginLeft }) => marginLeft};
  border-radius: ${({ borderRadius }) => borderRadius};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin-top: ${({ marginTop }) => marginTop};
  @media (max-width: 1280px) {
    flex-direction: ${({ responsiveFlexDirection }) => responsiveFlexDirection};
  }
  height: ${({ height }) => height};
  max-width: ${({ maxWidth }) => maxWidth};
`;

interface TopBarContentContainerProps {
  published?: boolean;
}

export const TopBarContentContainer = styled.div<TopBarContentContainerProps>`
  width: ${({ published }) => (published ? "50%" : "fit-content")};
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin-left: 8px;
  ${MEDIA_BREAKPOINTS.mobileView} {
    width: fit-content;
    padding: 8px 0px 8px 8px;
    justify-content: space-between;
    margin-left: 0px;
  }
`

export const Footer = styled.div`
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  justify-content: center;
  padding: 16px;
  background: ${SystemColors.PAPER_CONTAINER};
  border-radius: 8px 8px 0px 0px;
  z-index: 200;
`;

export const Puller = styled.div`
  width: 40px;
  min-height: 5px;
  background-color: ${SystemColors.DISABLED_TEXT_COLOR};
  border-radius: 8px;
  margin-top: 8px;
  position: absolute;
  top: 0;
  left: 47%;
`;

export default useStyles;
