import { track } from 'analytics';
import { BodyContainer, CardContainer, ImageContainer, Text } from './styles';
import fbTokenExpired from "assets/images/fb-token-expired.png"
import SecondaryButton from 'components/shareable/SecondaryButton';
import FB, { loginWithFB } from 'helpers/FB';
import Axios from 'helpers/Interceptor';
import { FunctionComponent, useEffect, useState } from 'react';
import { CurrentBrand } from 'types/global';
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import { useMediaQuery, useTheme } from '@material-ui/core';
import { SymphonyTooltip } from 'styles/shared';
import Intercom from 'helpers/Intercom';
import { NewLoader } from 'components/Loader/ConnectBrandLoader';

interface Props {
  onSuccess?: () => void,
  currentBrand: CurrentBrand,
  campaignId: string | number,
  campaignType: string | number,
}

const MAINTENANCE_MODE = false

const FbTokenExpired: FunctionComponent<Props> = ({
  currentBrand,
  onSuccess,
  campaignId,
  campaignType,
}: Props) => {


  const [loading, setLoading] = useState<boolean>(false)

  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

  const updateInBrand = async () => {
    try {
      let logged_in_fb_user = null;
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        logged_in_fb_user = authResp.accessToken
      } else {
        await loginWithFB()
        const authResp = FB().getAuthResponse()
        if (authResp && authResp.accessToken) {
          logged_in_fb_user = authResp.accessToken
        }
      }
      if (logged_in_fb_user) {
        await Axios.put(`/brand/${currentBrand.slug}/connect`, {
          id: currentBrand.id,
          name: currentBrand.name,
          service: 'logged_in_fb_user',
          value: {
            logged_in_fb_user,
          }
        })
        onSuccess?.()
        track('User reconnected FB', {
          brand_id: currentBrand.id,
          brand_name: currentBrand.name,
          campaign_id: campaignId,
          campaign_type: campaignType,
        })
      }
    } catch (error) {
      track('Error reconnecting Facebook', {
        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        campaign_id: campaignId,
        campaign_type: campaignType,
        error,
      })
    }
  }

  const onClickLogin = async () => {
    setLoading(true);
    await updateInBrand();
    setLoading(false);
  }

  const [getCampaignUrl, setGetCampaignUrl] = useState(false)
  const [gettingCampaignUrl, setGettingCampaignUrl] = useState(true)
  const [campaignUrl, setCampaignUrl] = useState('');


  async function getCampaignInfo() {
    setGettingCampaignUrl(true)
    const campaignData = await Axios.get(`/campaign/${campaignId}`)
    if (campaignData.data && campaignData.data.data) {
      const { data } = campaignData.data
      const { campaign_metadata } = data
      const {
        platform_details
      } = campaign_metadata
      const campaignIds = platform_details.campaigns
      let adAccount = platform_details.ad_account
      adAccount = adAccount.replace('act_', '')


      const ids = campaignIds.join(',');

      const url = `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=${adAccount}&nav_entry_point=am_local_scope_selector&date=2023-02-24_2023-05-03,maximum&selected_campaign_ids=${ids}`;

      // set the state
      setCampaignUrl(url);

    } else {
      // error state
    }
    setGettingCampaignUrl(false)


  }

  useEffect(() => {
    if (MAINTENANCE_MODE) {
      setGetCampaignUrl(true)
    }
    getCampaignInfo()
  }, [])

  useEffect(() => {
    if (getCampaignUrl) {
      getCampaignInfo()
    }
  }, [getCampaignUrl])

  const openIntercom = () => Intercom.openFacebookSessionArticle();

  if (MAINTENANCE_MODE) {
    return (
      <CardContainer paddingY={setResponsiveView ? 32 : 120}>
        <ImageContainer width={setResponsiveView ? "56%" : "40%"}>
          <img src={fbTokenExpired} alt="facebook-token-expired" />
        </ImageContainer>
        <BodyContainer>
          <Text fontSize={20} color="#000000" textAlign="center">
            Your Facebook session has expired
            <button onClick={openIntercom} className="cursor">
              <SymphonyTooltip
                className="ml-2 w-6 h-6"
                title="Click here to learn more."
                arrow
              >
                <QuestionMarkIcon height={24} width={24} />
              </SymphonyTooltip>
            </button>
          </Text>
          <Text fontSize={18} color="#707070" textAlign="center">
            Facebook's Ads servers are having issues pulling data at the moment. The Symphony team is working with Facebook to resolve, but while this is being solved you can view campaign stats at the link below:
          </Text>
          <SecondaryButton
            disabled={loading}
            loading={loading}
            onClick={() => {
              if (!campaignUrl) return
              window.open(campaignUrl, '_blank')
            }}
            text={!(!campaignUrl || gettingCampaignUrl) ? "View on Facebook" : ''}
            content={!campaignUrl || gettingCampaignUrl ? <div><NewLoader black={true}/></div> : ''}
          />
          <Text fontSize={18} color="#707070" textAlign="center">
            We should have this resolved shortly. Your data is safe and secure and you'll see your dashboard soon.
          </Text>
        </BodyContainer>
      </CardContainer>
    )
  }
  return (
    <CardContainer paddingY={setResponsiveView ? 32 : 120}>
      <ImageContainer width={setResponsiveView ? "56%" : "40%"}>
        <img src={fbTokenExpired} alt="facebook-token-expired" />
      </ImageContainer>
      <BodyContainer>
        <Text fontSize={20} color="#000000" textAlign="center">
          Your Facebook session has expired
          <button onClick={openIntercom} className="cursor">
            <SymphonyTooltip
              className="ml-2 w-6 h-6"
              title="Click here to learn more."
              arrow
            >
              <QuestionMarkIcon height={24} width={24} />
            </SymphonyTooltip>
          </button>
        </Text>
        <Text fontSize={18} color="#707070" textAlign="center">
          Please login to your Facebook account to reconnect Symphony to your
          Facebook. (You’ll only have do this once)
        </Text>
        <SecondaryButton
          disabled={loading}
          loading={loading}
          onClick={onClickLogin}
          text="Login to Facebook"
        />
      </BodyContainer>
    </CardContainer>
  );
}

export default FbTokenExpired;