import { FunctionComponent, useEffect, useState } from 'react';
import PresaveMiniature from '../PresaveMiniature';
import { CampaignStatus, Website } from 'types/global';
import TertiaryButton from 'components/shareable/TertiaryButton';
import { FadeContainer, LinkContainer, MainContainer, MiniatureContainer, PresaveLinkContainer, TextLinkPresave, ButtonsContainer, Title, MobileButtonsContainer } from './styles';
import Logger from 'Logger';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { track } from 'analytics';
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { SymphonyTooltip } from "styles/shared";
import { SYMPHONY_WEBSITE_URL } from '../../../../../../constants';

interface Props {
  presaveImage: string;
  style: Website;
  status: string;
  contentSlug: string;
  songName: string;
  brandName: string;
  campaignId: number;
  brandId: number;
  brandSlug: string;
  daysLeft: number;
  canEdit?: boolean;
  openPresaveModal: () => void;
  closePresaveModal: () => void;
  onOpenEditLinkModal?: () => void;
}

const { FINISHED } = CampaignStatus

const PresaveLink: FunctionComponent<Props> = ({
  presaveImage,
  style,
  contentSlug,
  status,
  songName,
  brandName,
  campaignId,
  brandId,
  brandSlug,
  daysLeft,
  canEdit = true,
  openPresaveModal,
  closePresaveModal,
  onOpenEditLinkModal
}: Props) => {

  const { mobileView } = useMediaBreakpoints();
  const showShareButton = Boolean(navigator.share) && canEdit;

  const [copyButtonLabel, setCopyButtonLabel] = useState<string>('Copy');
  const [fade, setFade] = useState<boolean>(false);

  const isFinished = status === FINISHED;
  const constructLink = `${SYMPHONY_WEBSITE_URL}/${brandSlug}/${contentSlug}`

  useEffect(() => {
    if (!mobileView) {
      closePresaveModal();
    }
  }, [mobileView]);

  const onClickCopy = (source: string) => {
    track("Copied Pre-save URL", {
      campaign_id: campaignId,
      campaign_type: 'pre_save',
      brand_id: brandId,
      brand_slug: brandSlug,
      days_left: daysLeft,
      source,
    })
    navigator.clipboard.writeText(constructLink)
    setCopyButtonLabel('Copied!')
    setFade(true)
    setTimeout(() => {
      setCopyButtonLabel('Copy')
      setFade(false)
    }, 3000)
  }

  const onClickShare = () => {
    track("Shared Pre-save URL", {
      campaign_id: campaignId,
      campaign_type: 'pre_save',
      brand_id: brandId,
      brand_slug: brandSlug,
      days_left: daysLeft,
    })
    try {
      navigator.share({
        title: `${isFinished ? "Listen to" : "Presave"} ${songName} by ${brandName}`,
        text: `${isFinished ? "Listen to" : "Presave"} ${songName} by ${brandName}`,
        url: constructLink,
      })
      setFade(true)
      setTimeout(() => {
        setFade(false)
      }, 3000)
    } catch (e) {
      Logger.error('This action is only suported on mobiles')
    }
  }

  if (mobileView) {
    return (
      <MainContainer padding='16px'>
        <MiniatureContainer>
          <PresaveMiniature image={presaveImage} style={style} />
        </MiniatureContainer>
        <LinkContainer>
          <Title>{isFinished ? "Website Link" : "Pre-save Link"}</Title>
          <PresaveLinkContainer onClick={() => onClickCopy("input")}>
            <TextLinkPresave>{constructLink}</TextLinkPresave>
            {fade && <FadeContainer />}
          </PresaveLinkContainer>
          <MobileButtonsContainer>
            <TertiaryButton text='View full screen' onClick={openPresaveModal} />
            <ButtonsContainer>
              {showShareButton && (
                <TertiaryButton text='Share' onClick={onClickShare} />
              )}
              <SecondaryButton text={copyButtonLabel} onClick={() => onClickCopy("button")} height="39px" />
            </ButtonsContainer>
          </MobileButtonsContainer>
        </LinkContainer>
      </MainContainer>
    );
  }

  return (
    <>
      <MainContainer borderRadius='8px' padding="24px">
        <LinkContainer>
          <Title>{isFinished ? "Website Link" : "Pre-save Link"}</Title>
          <PresaveLinkContainer>
            <TextLinkPresave>{constructLink}</TextLinkPresave>
            <ButtonsContainer>
              {showShareButton && (
                <TertiaryButton text='Share' onClick={onClickShare} height="15px" />
              )}
              <TertiaryButton text={copyButtonLabel} onClick={() => onClickCopy("button")} height="15px" />
              {canEdit &&
                <SymphonyTooltip title="Edit URL Slug">
                  <EditIcon onClick={onOpenEditLinkModal} color="#8800FF" cursor="pointer" />
                </SymphonyTooltip>
              }
            </ButtonsContainer>
            {fade && <FadeContainer />}
          </PresaveLinkContainer>
        </LinkContainer>
      </MainContainer>


    </>
  );
}

export default PresaveLink;
