export const benefits = [
  {
    id: "full-access",
    icon: "🔐",
    label: "Full access to Symphony’s ever-updating suite",
  },
  { id: "unlimited-access", icon: "🔥", label: "The full power of AI-powered automated ads" },

  {
    id: "dedicated-marketing",
    icon: "🤝",
    label:
      "Priority support via chat / email ",
  },
  {
    id: "unlimited-campaigns",
    icon: "🎯",
    label: "Unlimited data access",
  },
];
