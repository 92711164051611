import { useMediaQuery, useTheme } from '@material-ui/core';
import { THEME_BREAKPOINTS } from 'types/global';

interface Props {
  custom?: string;
}

const useMediaBreakpoints = (props?: Props) => {
  const {
    custom = "min-width: 0px"
  } = props || {};

  const theme = useTheme();
  const customView = useMediaQuery(custom);
  const { mobile, small, large } = THEME_BREAKPOINTS;

  const largeView = useMediaQuery(theme.breakpoints.up(large));
  const strictMediumView = useMediaQuery(theme.breakpoints.between(small, large));
  const strictSmallView = useMediaQuery(theme.breakpoints.between(mobile, small));
  const mobileView = useMediaQuery(theme.breakpoints.down(mobile));

  const smallView = useMediaQuery(theme.breakpoints.down(small + 1));
  const mediumView = useMediaQuery(theme.breakpoints.down(large + 1));

  const mobileOnwards = useMediaQuery(theme.breakpoints.up(mobile));
  const smallOnwards = useMediaQuery(theme.breakpoints.up(small));

  return {
    largeView,
    strictMediumView,
    strictSmallView,
    mobileView,
    smallView,
    mediumView,
    customView,
    mobileOnwards,
    smallOnwards,
  }
}

export default useMediaBreakpoints;
