import { FunctionComponent } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import dayjs from "dayjs";
import CircularStep from "../CircularStep";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  startDate: Date;
  endDate: Date;
}

const formatDate = (date: Date) => dayjs(date).format("MM/DD/YY");

const DurationCard: FunctionComponent<Props> = ({
  startDate,
  endDate,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={1} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Duration
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <Grid
            className={clsx(classes.border, classes.withPaddingBottom)}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <StyledLabel fontSize={16} fontWeight={600}>
              Start and end date
            </StyledLabel>
            <StyledLabel fontSize={16} fontWeight={400}>
              From {formatDate(startDate)} to {formatDate(endDate)}
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={classes.space}>
          <Grid container alignItems="center" justifyContent="space-between">
            <StyledLabel fontSize={16} fontWeight={600}>
              Campaign length
            </StyledLabel>
            <StyledLabel fontSize={16} fontWeight={400}>
              {dayjs(endDate).diff(startDate, "day")} days
            </StyledLabel>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DurationCard;
