import { FunctionComponent, useContext, useEffect } from "react";
import { track } from "analytics";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import useStyles, {
  LeftSection,
  RightSection,
  DialogContent,
  DialogTitle,
  DialogTitleContainer,
  BenefitsMainContainer,
  BenefitContainer,
  BenefitIcon,
  BenefitLabel,
  CloseIconContainer,
  Image,
  BenefitListContainer,
} from "./styles";
import { finalizeSubscription } from "./api";
import CloseIcon from "components/shareable/CloseIcon";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { benefits } from "./constants";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ProfileIcon from "assets/images/profileIcon.svg";
import { AvatarUserStatus } from "../AvatarUserStatus";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { triggerVWOVirtualPageView } from "utils";
import PrimaryButton from "../PrimaryButton";

interface Props {
  sessionId?: string;
  brandSlug: string | null;
  brandName: string | null;
  brandImage: string | null;
  dialogProps: DialogProps;
  isOrganization?: boolean;
}

const UpgradeToProSucceededModal: FunctionComponent<Props> = ({
  sessionId,
  brandSlug,
  brandName,
  brandImage,
  dialogProps,
  isOrganization
}: Props) => {
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const { onClose, open } = dialogProps;
  const classes = useStyles();
  const { reloadBrand } = useContext(CurrentBrandContext)

  useEffect(() => {
    if (open) {
      triggerVWOVirtualPageView('/welcome-to-pro-modal'); // Trigger VWO virtual page view with full URL
    }
  }, [open]);


  const handleCloseDialog = (event: {}) =>
    onClose && onClose(event, "backdropClick");

  const handleFinalizeSubscription = () => {
    if (sessionId) {
      finalizeSubscription(sessionId, brandSlug).then(async () => {
        await reloadBrand();
      });
    }
  };

  useEffect(() => {
    if (sessionId) {
      track("Finalizing Subscription", { name: "UpgradeToProSucceededModal" });
      handleFinalizeSubscription()
    }
  }, [sessionId]);

  return (
    <Dialog
      {...dialogProps}
      classes={{ root: classes.rootDialog }}
      maxWidth="md"
      PaperProps={{ className: classes.paper }}
    >
      <DialogContent flexDirection={setResponsiveView ? "column" : "row"}>
        <CloseIconContainer onClick={handleCloseDialog}>
          <CloseIcon />
        </CloseIconContainer>
        <LeftSection width={setResponsiveView ? "100%" : "40%"} height={setResponsiveView ? "180px" : "auto"}>
          <Image
            src={require("../../../assets/images/settingsScreen/proSucceeded.png").default}
            width={setResponsiveView ? "120px" : "auto"}
            height={setResponsiveView ? "120px" : "auto"}
          />
        </LeftSection>

        <RightSection width={setResponsiveView ? "100%" : "60%"} padding={setResponsiveView ? "32px" : "40px 32px"}>
          <DialogTitleContainer>
            <UpgradeToProLabel
              color="PURPLE"
              label={isOrganization ? "Symphony for Teams" : "Symphony Pro"}
              maxWidth={isOrganization ? "210px" : "168px"} />
            <DialogTitle>
              Welcome to Symphony Pro!
            </DialogTitle>
          </DialogTitleContainer>

          <AvatarUserStatus
            withoutWhiteBorder
            isProUser={true}
            showProIcon={false}
            brandName={brandName || ""}
            mainContainerProps={{
              backgroundColor: "#FFF2E2",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
            }}
            userNameTextProps={{ color: "black", fontSize: 16, textAlign: "left" }}
            proTextProps={{ color: "#F5BA23", fontSize: 10 }}
            avatar={brandImage ? brandImage : ProfileIcon}
          />
          <BenefitsMainContainer>
            <BenefitLabel fontWeight="bold">You’ve unlocked:</BenefitLabel>
            <BenefitListContainer>
              {benefits.map(({ id, icon, label }) => (
                <BenefitContainer key={id} >
                  <BenefitIcon>{icon}</BenefitIcon>
                  <BenefitLabel>{label}</BenefitLabel>
                </BenefitContainer>
              ))}
            </BenefitListContainer>
          </BenefitsMainContainer>
          {isOrganization && (<PrimaryButton
            width="100%"
            text={"Explore Symphony"}
            onClick={() => handleCloseDialog({})}></PrimaryButton>)}
        </RightSection>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradeToProSucceededModal;
