import { CampaignDetails, MarketingCampaignType } from "types/global";
import SongAdsHelperPopup from "../SongAds/HelperPopup";
import PlaylistingHelperPopup from "../Playlisting/HelperPopup";
import VideoAdsHelperPopup from "../VideoAds/HelperPopup";
import PresaveHelperPopup from "../PreSave/HelperPopup";
import BoostInstagramHelperPopup from "../GrowInstagram/HelperPopup";
import { CampaignTypeMapping } from "../Components/CampaignCard";
import LinkClicksHelperPopup from "../LinkClicks/HelperPopup";
const linkClicksCampaignImage = require('assets/images/ui/link-clicks-campaign-img.png').default
const boostIgCampaignImage = require('assets/images/ui/ig-boost-campaign-img.png').default

export const availableGoals: Record<string, MarketingCampaignType> = {
  "increase_record_streams": {
    title: "Increase Music Streams",
    key: "increase_record_streams",
    description:
      "Grow your Spotify monthly listeners & social following by targeting high-value fans using automated Instagram ads.",
    icon: require("assets/images/campaigns/musicstreams.png").default,
    link: 'music-streams',
    category: "releases",
    helperContent: <SongAdsHelperPopup subtype="increase_record_streams"/>,
    underMaintenance: false,
    isConversionsCampaign: true,
    backgroundColor: '#E4FAEC',
    requiresPro: true,
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=iEvmA-_hkLk'
  },

  "pre_save": {
    title: "Create a Pre-Save",
    key: "pre_save",
    description: "Grow your day 1 streams and build your email list by collecting pre-saves for your release.",
    icon: require("assets/images/campaigns/presave.png").default,
    link: 'presave',
    category: "releases",
    requiresPro: false,
    helperContent: <PresaveHelperPopup />,
    backgroundColor: "#D3E7FF",
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=-jAi8Yu8Vck'
  },
  "increase_video_views": {
    title: "Increase Video Views",
    key: "increase_video_views",
    description:
      "Grow your YouTube video views & subscribers by targeting high-value fans with smart YouTube ads.",
    icon: require("assets/images/campaigns/youtube.png").default,
    comingSoon: false,
    newTag: false,
    link: 'video-views',
    category: "releases",
    helperContent: <VideoAdsHelperPopup />,
    underMaintenance: false,
    requiresPro: true,
    backgroundColor: "#FFEBEB",
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=NH6BOYW2cOI'

  },
  "grow_instagram_engagement": {
    title: "Boost Instagram Engagement",
    key: "grow_instagram_engagement",
    description: "Boost your Instagram profile engagement by putting your content in front of new followers using automated highly-targeted, Instagram ads.",
    icon: require("assets/images/campaigns/boost_ig.png").default,
    link: 'grow-instagram-engagement',
    helperContent: <BoostInstagramHelperPopup />,
    category: "fanbase",

    newTag: true,
    underMaintenance: false,
    requiresPro: true,
    backgroundColor: "radial-gradient(144.41% 122.74% at 1.81% 90.83%, rgba(254, 213, 118, 0.08) 0%, rgba(244, 113, 51, 0.08) 26.34%, rgba(188, 48, 129, 0.08) 60.91%, rgba(76, 99, 210, 0.08) 100%)",
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=4eaw6cInq0I&feature=youtu.be'

  },

  "grow_playlist_followers": {
    title: "Grow Playlist Followers",
    key: "grow_playlist_followers",
    description:
      "Grow your playlist following, engagement, and streams by targeting high-value fans using automated Instagram ads.",
    icon: require("assets/images/campaigns/playlistfollowers.png").default,
    link: 'grow-playlist-followers',
    category: "releases",
    // helperContent: <SongAdsHelperPopup subtype="grow_playlist_followers" />,
    underMaintenance: false,
    isConversionsCampaign: true,
    backgroundColor: '#E4FAEC',
    requiresPro: true,
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=iEvmA-_hkLk'
  },

  "increase_link_clicks": {
    title: "Increase Link Clicks",
    key: "increase_link_clicks",
    description: "Drive highly-targeted traffic from Instagram to your merch store, tickets page, or anywhere else.",
    icon: require("assets/images/campaigns/linkclicks.png").default,
    link: 'link-clicks',
    category: "traffic",
    helperContent: <LinkClicksHelperPopup />,

    comingSoon: false,
    underMaintenance: false,
    isConversionsCampaign: true,
    requiresPro: true,
    backgroundColor: "#ECE0F7",
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=8PfUADfUWSQ'
  },
  "get_playlisted": {
    title: "Get Playlisted",
    key: "get_playlisted",
    description:
      "Grow your Spotify monthly listeners & followers by pitching potential listeners on high-traffic user-generated Spotify playlists.",
    icon: require("assets/images/campaigns/playlisting.png").default,
    link: 'playlists',
    category: "releases",
    requiresPro: false,

    helperContent: <PlaylistingHelperPopup />,
    backgroundColor: "#E4FAEC",
    underMaintenance: false,

    statusMessage: "Our curation team is on pause for the holidays, and this playbook will be back on January 3!",
    walkthroughVideoUrl: 'https://www.youtube.com/watch?v=h7gJh9csR3o'
  },
  // "tiktok_ads": {
  //   title: "Boost TikTok Engagement",
  //   key: "tiktok_ads",
  //   description: "Boost your TikTok videos with highly-targeted traffic.",
  //   icon: require("assets/images/campaigns/linkclicks.png").default,
  //   link: 'tiktok-ads',
  //   category: "traffic",

  //   comingSoon: false,
  //   underMaintenance: false
  // }
};

export const CAMPAIGN_CARDS = Object.values(availableGoals).map((goal: MarketingCampaignType) => ({
  ...goal,
  title: goal.title,
  requiresPro: goal.requiresPro,
  id: goal.key,
  description: goal.description,
  imageUrl: goal.icon, // Replace with the correct property name for the icon URL
  link: goal.link,
  underMaintenance: goal.underMaintenance,
  backgroundColor: goal.backgroundColor || '#ECE0F7',
  statusMessage: goal.statusMessage,
  isConversionsCampaign: goal.isConversionsCampaign || false
}));


export function getIconForGoal(goal: string) {
  return availableGoals[goal].icon;
}


export const CAMPAIGN_TYPE_MAPPINGS: CampaignTypeMapping = {
  record_streams: {
    text: "Increase Music Streams",
    route: "/marketing/new/music-streams",
  },
  grow_playlist_followers: {
    text: "Grow Playlist Followers",
    route: "/marketing/new/grow-playlist-followers",
  },
  link_clicks: {
    text: "Increase Link Clicks",
    route: "/marketing/new/link-clicks",
    defaultImage: linkClicksCampaignImage,
  },
  get_playlisted: {
    text: "Pitch Playlists",
    route: "/marketing/new/playlists",
  },
  increase_video_views: {
    text: "Increase Video Views",
    route: "/marketing/new/video-views",
  },
  boost_instagram: {
    text: "Boost Instagram Engagement",
    route: "/marketing/new/grow-instagram-engagement",
    defaultImage: boostIgCampaignImage,
  },
  pre_save: {
    text: "Pre-save Campaign",
    route: "/marketing/new/presave",
  },
  music_site: {
    text: "Music Site",
    route: "/marketing/new/music_site",
  },
  data_collector: {
    text: "Data Collector",
    route: "/marketing/new/data_collector",
  },

}

export const CAMPAIGN_LABEL_NAME_MAPPINGS: Record<string, string> = {
  'releases': "Grow your releases",
  'fanbase': 'Grow your fanbase',
  'traffic': 'Drive traffic'
}

export const OrderCampaignsByEndDate = (campaigns: CampaignDetails[], order?: 'ASC' | 'DESC') => {
  const orderValue = order === 'ASC' ? 1 : -1;
  return campaigns.sort((a, b) => {
    if (a.campaign_metadata.endDate < b.campaign_metadata.endDate) {
      return -1 * orderValue;
    }
    if (a.campaign_metadata.endDate > b.campaign_metadata.endDate) {
      return 1 * orderValue;
    }
    return 0;
  })
}
