import Axios from "helpers/Interceptor"
import * as Sentry from "@sentry/react"

interface IGValue {
  url: string;
  username: string;
}

export const updateInBrand = async (
  artist: { slug: string; id: string },
  value: IGValue,
) => {
  try {
    const response = await Axios.put(`/brand/${artist.slug}/connect`, {
      id: artist.id,
      name: artist.slug,
      service: "business_discovery_instagram",
      value,
    })

    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error updating brand ig platform", error)
    Sentry.captureException(error)
    return { data: null, error }
  }
}
