import { SvgIcon, useMediaQuery, useTheme } from "@material-ui/core";
import styled from 'styled-components'

export function EmptyImage() {
    return (
        <SvgIcon viewBox="0 0 31 31" fill="none">
            <g stroke="#707070"
                fill="none"
                stroke-linecap="round" stroke-width="2.47" opacity=".72">
                <path d="M23.176 5.854H8.356a2.47 2.47 0 0 0-2.47 2.47v14.82a2.47 2.47 0 0 0 2.47 2.469h14.82a2.47 2.47 0 0 0 2.47-2.47V8.323a2.47 2.47 0 0 0-2.47-2.469Z" /><path d="M11.444 12.646a1.235 1.235 0 1 0 0-2.47 1.235 1.235 0 0 0 0 2.47ZM7.122 24.378l6.481-7.562a1.235 1.235 0 0 1 1.867-.01l7.706 8.807M17.619 18.82l2.837-3.31a1.235 1.235 0 0 1 1.81-.069l3.38 3.38" />
            </g>
        </SvgIcon>
    )
}
/**
 * Instagram Icon for like
 * @returns 
 */
export function HeartIcon(props: {
    color?: string
}) {
    const {
        color
    } = props

    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color ? color : 'white'} viewBox="0 0 14 13">
            <path stroke={color} stroke-width=".53" d="M6.78 11.964c-.234 0-.458-.077-.648-.22-1.285-.976-4.357-3.401-5.128-4.871C-.004 4.953.6 2.486 2.35 1.375A3.462 3.462 0 0 1 4.207.826c.973 0 1.888.408 2.574 1.133.685-.725 1.6-1.133 2.573-1.133.65 0 1.29.19 1.856.549 1.75 1.11 2.355 3.577 1.35 5.498-.77 1.47-3.843 3.895-5.129 4.87-.192.144-.416.22-.65.22ZM4.208 1.64c-.501 0-.995.147-1.432.424-1.4.89-1.88 2.874-1.067 4.422.715 1.364 3.931 3.868 4.9 4.602a.288.288 0 0 0 .343 0c.968-.736 4.185-3.24 4.9-4.602.813-1.548.333-3.534-1.067-4.422a2.657 2.657 0 0 0-1.43-.424c-.877 0-1.699.435-2.253 1.19l-.32.44-.32-.44c-.555-.755-1.374-1.19-2.254-1.19Z" />
        </SvgIcon>

    )
}

/**
 * Instagram Icon for comments
 */
export function CommentIcon(props: {
    color?: string
}) {
    const {
        color
    } = props

    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color ? color : 'white'} viewBox="0 0 13 12" >
            <path fill-rule="evenodd" stroke={color} stroke-width=".53" d="M12.345 11.316 11.7 8.903a.577.577 0 0 1 .045-.408c.563-1.133.751-2.485.404-3.896A5.448 5.448 0 0 0 8.292.651 6.036 6.036 0 0 0 6.77.449C3.219.45.446 3.788 1.404 7.503 1.848 9.22 3.679 11 5.4 11.42c.465.114.923.167 1.368.167.955 0 1.849-.247 2.634-.671a.59.59 0 0 1 .28-.075c.048 0 .096.006.144.02l2.344.627a.203.203 0 0 0 .037.006c.09 0 .161-.085.137-.178ZM10.96 9.102l.365 1.366-1.299-.348a1.342 1.342 0 0 0-.99.122 4.715 4.715 0 0 1-2.266.576c-.395 0-.794-.05-1.188-.146-1.458-.356-3.064-1.925-3.434-3.363-.386-1.5-.082-3.041.833-4.227A4.755 4.755 0 0 1 6.77 1.218c.439 0 .886.059 1.328.178a4.655 4.655 0 0 1 3.31 3.389c.287 1.167.168 2.33-.348 3.368a1.345 1.345 0 0 0-.1.949Z" clip-rule="evenodd" />
        </SvgIcon>

    )
}

/**
 * Instagram Icon for sharing
 */
export function ShareIcon(props: {
    color?: string
}) {
    const {
        color
    } = props

    return (
        <SvgIcon
            fontSize="small"
            htmlColor={color ? color : 'white'} viewBox="0 0 14 13" >
            <path fill-rule="evenodd" stroke={color} stroke-width=".53" d="M12.973 1.201a.533.533 0 0 0-.468-.271L1.062.938c-.23 0-.43.145-.51.362a.56.56 0 0 0 .033.467c.03.051.07.1.117.142L5.11 5.792l1.075 5.832c.042.23.214.4.44.436a.547.547 0 0 0 .563-.272l5.782-10.035c.1-.172.1-.382.002-.552ZM1.841 1.78h9.317l-5.61 3.268L1.842 1.78Zm5.021 8.9L5.96 5.777l5.617-3.271-4.714 8.173Z" clip-rule="evenodd" />
        </SvgIcon>

    )
}

/**
 * 
 * @returns 
 */
export function SpotifyIcon() {
    return (
        <SvgIcon viewBox="0 0 20 20">
            <g clip-path="url(#a)"><path fill="#1ED760" d="M9.848.313a9.57 9.57 0 0 0 0 19.141 9.57 9.57 0 0 0 9.571-9.57c0-5.285-4.285-9.57-9.57-9.57Zm4.39 13.804a.596.596 0 0 1-.821.198c-2.247-1.373-5.076-1.684-8.408-.923a.597.597 0 0 1-.265-1.163c3.646-.833 6.773-.474 9.296 1.067.28.173.37.54.197.82Zm1.17-2.606a.746.746 0 0 1-1.026.246c-2.572-1.582-6.494-2.04-9.537-1.116a.747.747 0 0 1-.433-1.428c3.476-1.054 7.797-.544 10.751 1.272.35.216.462.675.246 1.026Zm.101-2.714c-3.084-1.832-8.173-2-11.119-1.107a.895.895 0 1 1-.52-1.713c3.382-1.026 9.002-.828 12.553 1.28a.894.894 0 1 1-.913 1.54Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.249.282h19.2v19.2H.249z" /></clipPath></defs>
        </SvgIcon>
    )
}

export function AppleIcon() {
    return (
        <SvgIcon viewBox="0 0 16 20">
            <g clip-path="url(#a)"><path fill="#707070" d="M14.888 6.943c-.107.083-1.998 1.15-1.998 3.519 0 2.74 2.406 3.71 2.478 3.735-.01.059-.382 1.328-1.269 2.62-.79 1.139-1.616 2.274-2.872 2.274-1.256 0-1.579-.73-3.029-.73-1.413 0-1.915.754-3.064.754-1.15 0-1.95-1.052-2.873-2.345-1.067-1.519-1.93-3.877-1.93-6.116 0-3.59 2.335-5.495 4.633-5.495 1.22 0 2.238.802 3.005.802.73 0 1.867-.85 3.256-.85.527 0 2.418.048 3.663 1.832Zm-4.322-3.352c.574-.682.98-1.628.98-2.573 0-.131-.01-.264-.034-.372-.935.036-2.047.623-2.717 1.4-.527.599-1.018 1.545-1.018 2.503 0 .144.024.289.035.335.06.01.155.024.251.024.839 0 1.893-.562 2.503-1.317Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.331.646h15.035v18.471H.331z" /></clipPath></defs>
        </SvgIcon>
    )
}

export function YoutubeIcon() {
    return (
        <SvgIcon viewBox="0 0 20 14">
            <g clip-path="url(#a)"><path fill="red" fill-rule="evenodd" d="M17.178.687a2.361 2.361 0 0 1 1.667 1.666c.575 2.319.537 6.702.012 9.057a2.36 2.36 0 0 1-1.666 1.666c-2.295.568-12.571.498-14.732 0A2.36 2.36 0 0 1 .792 11.41C.25 9.2.287 4.527.78 2.365A2.36 2.36 0 0 1 2.446.7C5.514.059 16.086.265 17.178.687ZM8.002 4.056l4.927 2.825-4.927 2.826V4.056Z" clip-rule="evenodd" /></g><defs><clipPath id="a"><path fill="#fff" d="M.249.24h19.2v13.283H.249z" /></clipPath></defs>
        </SvgIcon>
    )
}

export function LinkIcon() {
    return (
        <SvgIcon viewBox="0 0 21 21" fill="none">
            <path stroke="#80F" stroke-linecap="round" stroke-width="2" d="M12.849 9.882h3a4 4 0 0 0 0-8h-3M8.849 9.882h-3a4 4 0 0 1 0-8h3M6.349 5.882h9" />
        </SvgIcon>
    )
}

/**
 * Renders the top bar area where a brand's name is shown
 * next to their profile pic.
 * @param props 
 * @returns 
 */
export function IGAdArtistName(props: {
    name: string;
    imageUrl?: string;
    nameColor?: string;
    showTextShadow?: boolean;
    simplePreview?: boolean;
}) {

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    const {
        name,
        imageUrl,
        nameColor,
        showTextShadow = false,
        simplePreview = false,
    } = props
    return (
        <div className={`flex flex-row items-center ${simplePreview ? "mt-2 mb-1" : "my-2"}`}
            style={simplePreview ? { marginBottom: "2%", marginTop: "4%"} : {}}
        >
            <div className="flex flex-row items-center">
                {imageUrl && (<div
                    className={`bg-white rounded-full overflow-hidden ${!simplePreview && "h-6 w-6"}`}
                    style={simplePreview ? { width: "12%" } : {}}
                >
                    <img className="w-full h-full object-fit-cover" src={imageUrl} />
                </div>)}
                <div className={`text-shadow ${imageUrl ? (simplePreview ? "ml-1" : "ml-2") : ""} text-xs font-semibold text-white`}
                    style={{
                        textShadow: showTextShadow ? '0px 0px 2px rgba(0,0,0,0.5)' : 'none',
                        color: nameColor ? nameColor : 'white',
                        fontSize: simplePreview ? setResponsiveView ? "1.7vw" : "0.7vw" : "16px",
                        lineHeight: 0,
                    }}>{name}</div>
            </div>
        </div>
    )
}

interface StoryParentDivProps {
    simplePreview?: boolean
    autoheight?: boolean
}

export const StoryParentDiv = styled.div`
    width: ${({ simplePreview }: StoryParentDivProps) => simplePreview ? "50%" : "70vw"};
    aspect-ratio:  ${({ autoheight }: StoryParentDivProps) => autoheight ? "auto" : "9 / 16"};
    @media (min-width: 768px) {
        width: ${({ simplePreview }: StoryParentDivProps) => simplePreview ? "70%" : "250px"};
    }
    overflow: auto;
`