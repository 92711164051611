export const DEFAULT_CURRENCY = "USD";
export const MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS = 10;
export const PAYMENT_METHODS: Record<number, string> = {
  0: "UNSET",
  1: "CREDIT_CARD",
  2: "FACEBOOK_WALLET",
  3: "FACEBOOK_PAID_CREDIT",
  4: "FACEBOOK_EXTENDED_CREDIT",
  5: "ORDER",
  6: "INVOICE",
  7: "FACEBOOK_TOKEN",
  8: "EXTERNAL_FUNDING",
  9: "FEE",
  10: "FX",
  11: "DISCOUNT",
  12: "PAYPAL_TOKEN",
  13: "PAYPAL_BILLING_AGREEMENT",
  14: "FS_NULL",
  15: "EXTERNAL_DEPOSIT",
  16: "TAX",
  17: "DIRECT_DEBIT",
  18: "DUMMY",
  19: "ALTPAY",
  20: "STORED_BALANCE",
};
export const PAYPAL_BILLING_AGREEMENT = 13;
export const CREDIT_CARD = 1;
