import { useAuth0 } from "@auth0/auth0-react";
import Axios from "helpers/Interceptor";
import getUserByAuth0Id from "layouts/controllers/getUserByAuth0Id";
import { useState, createContext, ReactNode, FunctionComponent } from "react";
import { UserData } from "types/global";

interface Context {
  currentUser: UserData | undefined;
  setCurrentUser: (user: UserData) => UserData | undefined;
  reloadUser: () => Promise<UserData> | undefined;
  loadingUser: boolean;
}

// Creating a context with a similar structure to the CurrentBrandContext
export const CurrentUserContext = createContext<Context>({
  currentUser: undefined,
  setCurrentUser: () => undefined,
  reloadUser: () => undefined,
  loadingUser: false,
});

type Props = {
  user?: UserData;
  children: ReactNode[] | ReactNode;
};

const CurrentUserProvider: FunctionComponent<Props> = ({ children }: Props) => {
  const [storedUser, setUser] = useState<UserData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    user
  } = useAuth0();

  async function reloadUser(): Promise<UserData> {
    setLoading(true);
    const userData: UserData = await getUserByAuth0Id(user!.sub!)

    setUser(userData);
    setLoading(false);

    return userData;
  }

  function setCurrentUser(user: UserData): UserData | undefined {
    setUser(user);
    return user;
  }

  const context = {
    currentUser: storedUser,
    setCurrentUser: setCurrentUser,
    reloadUser: reloadUser,
    loadingUser: loading,
  };

  return (
    <CurrentUserContext.Provider value={context}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;