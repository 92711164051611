import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((_theme: Theme) => ({
  chip: {

    fontSize: 10,
    margin: '4px',
  },
  loginWithFBButton: {
    height: 51,
    textTransform: "none",
    justifyContent: "space-between",
    backgroundColor: "#8800ff",
    color: "#ffffff"
  },
  colorPrimary: {
    color: "#80f",
  },
  paper: { borderRadius: 12 },
  inputBorder: {
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  igInput: {
    backgroundColor: "#EDECF2",
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8800FF',
      },
      '&:hover fieldset': {
        borderColor: '#8800FF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  modalBackground: {
    [_theme.breakpoints.down(701)]: {
      backgroundColor: "#F5F3F6",
    }
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      [_theme.breakpoints.down(701)]: {
        margin: 0,
        borderRadius: 0,
        height: "100%",
        padding: "4px",
        boxShadow: "none",
      },
      [_theme.breakpoints.up(701)]: {
        margin: "auto",
        borderRadius: 12,
      },
    },
  },
  dividerTop: {
    borderTop: "1px solid #EDECF2",
  },
  dividerBottom: {
    borderBottom: "1px solid #EDECF2",
  },
  channelItemRow: {
    paddingTop: _theme.spacing(2),
    paddingBottom: _theme.spacing(2),
  },
  channelImage: {
    width: 80,
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16
    }
  },
  connectButtonContainer: {
    width: 116,
  },
  warningContainer: {
    backgroundColor: "#FDF6EC",
    marginTop: _theme.spacing(1),
    paddingLeft: _theme.spacing(2),
    paddingRight: _theme.spacing(2),
    paddingTop: _theme.spacing(1.5),
    paddingBottom: _theme.spacing(1.5),
    textAlign: "left",
  },

  button: {
    display: "flex",
    color: "#ffffff",
    width: "100%",
  },
  buttonText: {
    padding: "6px 12px",
    textAlign: 'left'
  },
  menuItem: {
    borderBottom: "1px solid #F2F2F2",
  },
}));

interface MainContainerProps {
  opacity?: number;
}

export interface ContainerProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
}

interface GradientRoundedContainerProps {
  width?: string;
  height?: string;
}

interface AvatarContainerProps {
  top?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
  marginLeft?: string;
}




export const MainContainer = styled.div`
  border-radius: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: ${({ opacity }: MainContainerProps) => opacity};
`;

export const Container = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  width: ${({ width }: ContainerProps) => width};
`;

export const GradientRoundedContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: ${({ width }: GradientRoundedContainerProps) => width || "50px"};
  height: ${({ height }: GradientRoundedContainerProps) => height || "50px"};
  background: linear-gradient(to top right, #8e33ff 25%, #ff7e33, #ffc300 90%);
  border-radius: 50%;
`;

export const WhiteRoundedContainer = styled.div`
  position: relative;
  top: 2.5px;
  left: 2.5px;
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 50%;
`;

export const AvatarContainer = styled.div`
  position: relative;
  left: 2.5px;
  top: ${({ top }: AvatarContainerProps) => top || "3px"};
`;

export default useStyles;


interface PlatformLogoContainerProps {
  backgroundColor?: string;
  padding?: string;
  width?: string;
  height?: string;
}

interface ModalPlatformInputContainerProps {
  order?: string | number
}

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  margin?: string
  padding?: string
  paddingLeft?: string
  marginLeft?: string
  fontFamily?: string
}

interface StyledButtonProps {
  width?: string
}


interface PlatformsContainerProps {
  paddingLeft: string,
}

interface ConnectFanbaseContainerProps {
  padding: string,
  margin: string,
  gap: string,
}

interface InstagramListContainerProps {
  height?: string
  overflowY?: string
}

interface OutlinedButtonProps {
  width?: string
}

interface StyledAnchorTagProps {
  color?: string
}

interface SearchListContainerProps {
  height?: string
}

interface PlatformButtonContainerProps {
  backgroundColor: string
}

export const OutlinedButton = styled.button`
  text-align: left;
  width: ${({ width }: OutlinedButtonProps) => width || "100%"};
  border: 1px solid rgba(136, 0, 255, 1);
  background-color: #ffffff;
  color: rgba(136, 0, 255, 1);
  font-size: 16px;
  border-radius: 4px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
`

export const StyledAnchorTag = styled.a`
  color: ${({ color }: StyledAnchorTagProps) => color};
  font-size: 12px;
  font-weight: 500;
`

export const InstagramListContainer = styled.div`
  height: ${({ height }: InstagramListContainerProps) => height};
  width: 100%;
  max-height: 400px;
  overflow-y: ${({ overflowY }: InstagramListContainerProps) =>
    overflowY};
  overflow-x: hidden;
`

export const LottieLoadingContainer = styled.div`
  width: 25px;
`

export const SearchListContainer = styled.div`
  width: 100%;
  height: ${({ height }: SearchListContainerProps) => height || "100%"};
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledButton = styled.button`
  width: ${({ width }: StyledButtonProps) => width || "116px"};
  color: rgba(136, 0, 255, 1);
  font-size: 16px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
`

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
  font-family: ${({ fontFamily }: StyledLabelProps) => fontFamily};
`;

export const MainBackground = styled.div`
  position: absolute;
  background: #F5F3F6;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const ConnectFanbaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }: ConnectFanbaseContainerProps) => padding};
  gap:  ${({ gap }: ConnectFanbaseContainerProps) => gap};
  background: #FFFFFF;
  border-radius: 8px;
  max-width: 678px;
  min-width: 300px;
  width: fit-content;
  height: fit-content;
  margin: ${({ margin }: ConnectFanbaseContainerProps) => margin};
`;

export const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-left: ${({ paddingLeft }: PlatformsContainerProps) => paddingLeft};
  gap: 32px;
  width: 100%;
`;

export const BrandNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const TickContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #07C806;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 10px);
  z-index: 200;
`;

interface LeftSectionProps {
  width?: string;
  isMobile?: boolean;
}

interface RightSectionProps {
  display?: string;
}

interface FlexContainerProps {
  hidden?: boolean;
}

interface ModalContainerProps {
  minHeight?: string
  height?: string
  maxHeight?: string
  width?: string
  margin?: string
  padding?: string
  gap?: string
}

interface TitleContainerProps {
  gap?: string;
}

interface ArtistListProps {
  gap?: string;
}

interface ModalPlatformInputContainerProps {
  alignItems?: string
}

interface ArtistImageProps {
  widthStyle?: string
  heightStyle?: string
}

interface ConnectPlatformContainerProps {
  order?: number,
}

interface ConnectingLineProps {
  height: string
  left: string
}

export const FlexContainer = styled.div`
  display: ${({ hidden }: FlexContainerProps) => hidden ? 'none' : 'flex'};
  width: 100%;
  height: 100%;
  flex-direction: row;
  background: #F5F3F6;
`;

export const LeftColumn = styled.div`
  width: ${({ width }: LeftSectionProps) => width};
  margin: ${({ isMobile }: LeftSectionProps) => isMobile ? '32px 16px' : '0'};
  height: ${({ isMobile }: LeftSectionProps) => isMobile ? 'fit-content' : '100%'};
  border-radius: ${({ isMobile }: LeftSectionProps) => isMobile ? '8px' : '0'};
  padding: ${({ isMobile }: LeftSectionProps) => isMobile ? '32px 16px 16px 16px' : '36px 80px 56px'};
  max-height: ${({ isMobile }: LeftSectionProps) => isMobile ? 'calc(100% - 64px)' : '100%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ isMobile }: LeftSectionProps) => isMobile ? '32px' : '48px'};;
  background: #FFFFFF;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: ${({ gap }: TitleContainerProps) => gap || "18px"};
`;

export const MainTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`;

export const SubTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  opacity: 0.56;
`;

export const EnterArtistContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  gap: 24px;
  overflow-y: auto;
`;

export const EnterArtistLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`;

export const ArtistInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

export const PlatformLogoContainer = styled.div`
  background: ${({ backgroundColor }: PlatformLogoContainerProps) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: ${({ padding }: PlatformLogoContainerProps) => padding};
  height: ${({ height }: PlatformLogoContainerProps) => height};
  min-width: ${({ width }: PlatformLogoContainerProps) => width};
  z-index: 10;
`;

export const ArtistInput = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 448px;
  height: 67px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #8800ff;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 1;
`

export const ArtistList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ gap }: ArtistListProps) => gap || "8px"};
  overflow-y: auto;
`;

export const ArtistItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 0px;
  gap: 16px;
  height: 80px;
  width: 100%;
  cursor: pointer;
`;

export const ArtistListLine = styled.div`
  height: 0px;
  border: 0.5px solid #edecf2;
  flex: none;
  align-self: stretch;
`;

export const ArtistInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  height: 64px;
  overflow:hidden;
`;

export const ConnectArtistLabel = styled.div`
  height: 40px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 416;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #8800ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`

export const ArtistImage = styled.img`
  width: ${({ widthStyle }: ArtistImageProps) => widthStyle || "58px"};
  height: ${({ heightStyle }: ArtistImageProps) => heightStyle || "58px"};
  background: url("assets/images/not-found-user.png");
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const ArtistName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 39px;
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const ArtistNameLabel = styled.div`
  height: 19px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const ArtistNameBoldLabel = styled.span`
  font-weight: 600;
`;

export const ArtistFollowersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 16px;
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const ArtistFollowersPlatform = styled.div`
  width: 16px;
  height: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const ArtistFollowers = styled.div`
  height: 15px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #707070;
  opacity: 0.72;
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const NoArtistItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  height: 25px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`

export const NoArtistLable = styled.div`
  height: 20px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const LogoContainer = styled.div`
  height: 32px;
`;
export const NoArtistLine = styled.div`
  height: 0px;
  opacity: 0.32;
  border: 0.5px solid #edecf2;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`

export const CreateNewArtistcontainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 10px;
  height: 158px;
  background: linear-gradient(
      0deg,
      rgba(136, 0, 255, 0.08),
      rgba(136, 0, 255, 0.08)
    ),
    #ffffff;
  border-radius: 8px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  cursor: pointer;
`

export const CreateNewArtistImage = styled.img`
  width: 80px;
  height: 80px;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const CreateNewBrandLabel = styled.div`
  height: 20px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #8800ff;
  flex: none;
  order: 1;
  flex-grow: 0;
`

export const ConnectFanbaseTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 51px;
  transform: rotate(180deg);
  cursor: pointer;
`

export const BackArrowImage = styled.img`
  width: 24px;
  height: 24px;
`

export const BackButtonText = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  opacity: 0.56;
`;

export const BackToDashboardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  margin-top: 0;
`;

export const ConnectFanbaseTitlesLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
`

export const ConnectFanbaseMainTitleLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`

export const ConnectFanbaseSubTitleLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`

export const ConnectingLine = styled.div`
  position: absolute;
  width: 0;
  height: ${({ height }: ConnectingLineProps) => height};
  top: 32px;
  left: ${({ left }: ConnectingLineProps) => left};
  border: 1px solid #edecf2;
  z-index: 1;
`

export const ConnectPlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  order: ${({ order }: ConnectPlatformContainerProps) => order === undefined ? 0 : order}
`

export const SpotifyLogoContainer = styled.div`
  position: relative;
  z-index: 10;
`

export const SpotifyLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 56px;
  height: 56px;
  background: #000000;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: -12px 0px;
`

export const CheckImage = styled.img`
  width: 12px;
  height: 12px;
`

export const ConnectPlatformInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
`

export const ConnectPlatformArtistInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const PlatformLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`

export const ArtistLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
`

export const ConnectLabel = styled.div`
  height: 20px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #8800ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
`

export const ConnectButtonContainer = styled.div`
  width: 100%;
  padding: 0px 16px;
`

export const ConnectBrandButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 100%;
  background: #8800ff;
  border-radius: 48px;
  color: #ffffff;
`

export const RightColumn = styled.div`
& { 
  width: 50%;
  height: 100%;
  display: ${({ display }: RightSectionProps) => display};
  justify-content: flex-end;
  align-items: center;
  overflow-x: hidden;
  background: linear-gradient(290.62deg, #8800FF 0.13%, #D7B3ED 43.95%, #A1F0D4 74.68%);
}
&:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100;
  width:100%;
  height:100%;
  background: linear-gradient(269.32deg, #8800FF 18.14%, #8800FF 33.94%, rgba(161, 240, 212, 0.22) 102.22%);  
}
`;

export const RightColumnBase = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    110.62deg,
    #8800ff 0.13%,
    #d7b3ed 43.95%,
    #a1f0d4 74.68%
  );
  transform: rotate(-180deg);
  opacity: 0.8;
`

export const LottieContainer = styled.div`
  width: 660px;
  margin-left: 60px;
  background: rgba(255, 255, 255, 0.96);
  border-radius: 23px 0 0 23px;
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }: ModalContainerProps) => padding || "32px"};
  gap: ${({ gap }: ModalContainerProps) => gap || "48px"};
  height: ${({ height }: ModalContainerProps) => height || "484px"};
  min-height: ${({ minHeight }: ModalContainerProps) => minHeight};
  max-height: ${({ maxHeight }: ModalContainerProps) => maxHeight};
  width: ${({ width }: ModalContainerProps) => width || "673px"};
  margin: ${({ margin }: ModalContainerProps) => margin || "auto"};
  background: #ffffff;
  border-radius: 8px;
`

export const ModalTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
`

export const CloseButtonContainer = styled.div`
  width: 100%;
`

export const CloseIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 50%;
  cursor: pointer;
`

export const ModalTitlesLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: block;
`

export const ModalMainTitleLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`

export const ModalSubtitleLabel = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  width: 100%;
`

export const ModalPlatformInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }: ModalPlatformInputContainerProps) => alignItems || "flex-start"};
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const ModalConnectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 44px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`

export const ModalConnectButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 48px;
  background: ${({ color }: StyledLabelProps) => color || "#8800ff"};
  border-radius: 48px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 0.5px solid #8800ff;
  cursor: pointer;
`

export const ModalConnectButtonLabel = styled.label`
  height: 20px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 416;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${({ color }: StyledLabelProps) => color || "#ffffff"};
  flex: none;
  order: 0;
  flex-grow: 0;
  line-height: 0;
  cursor: pointer;
`
export const SpotifyLogoContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 10px;
  width: 48px;
  height: 48px;
  background: #191919;
  border-radius: 12px;
`;

export const AnotherSocialPlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

export const CreateAccountWithoutSpotifyText = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
`;

export const PlatformsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

export const PlatformButtonContainer = styled.div`
  display: grid;
  place-items: center;
  width: 48px;
  height: 48px;
  background: ${({ backgroundColor }: PlatformButtonContainerProps) => backgroundColor};
  border-radius: 12px;
  cursor: pointer;
`;

interface BrandNameTextInputProps {
  padding: string
}

export const BrandNameTextInput = styled.input`
  type: text;
  border-radius: 6px;
  border: 1px solid #ADB7BC;
  padding: ${({ padding }: BrandNameTextInputProps) => padding};  
  height: 67px;
  width: 100%;
  margin: 8px 0px;
`;

export const GRADIENT_BACKGROUND_COLOR = "radial-gradient(144.41% 122.74% at 1.81% 90.83%, #FED576 0%, #F47133 26.34%, #BC3081 60.91%, #4C63D2 100%)"
