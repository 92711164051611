import Axios from "helpers/Interceptor";

export const getFacebookInformation = async (brandId: number) => {
    try {
        const response = await Axios.get(`/admin/ad-account/${brandId}`);
        return response.data.data.fbInfo;
    } catch (error) {
        return null;
    }
}

export const getCampaingInformation = async (campaignId: number) => {
    try {
        const response = await Axios.get(`/admin/campaign-info/${campaignId}`);
        return response.data.data;
    } catch (error) {
        return null;
    }
}