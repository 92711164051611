import Axios from 'helpers/Interceptor';
import * as Sentry from "@sentry/react";

export const getPresavedByFan = async (fanId: number) => {
  try {
    const response = await Axios.get(`fan/${fanId}/pre_saves`);
    const data = response.data.data;
    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
};

export const setForeverPresaves = async (data: { fanId: number, activate: boolean }) => {
  const { fanId, activate } = data;
  try {
    const response = await Axios.put(`fan/${fanId}/forever_pre_saves`, {
      activate
    });
    const data = response.data.data;
    return { data, error: null };
  } catch (error) {
    Sentry.captureException(error);
    return { data: null, error };
  }
}
