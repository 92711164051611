import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components"
import { MEDIA_BREAKPOINTS } from "types/global";
import { SystemColors } from "types/globalStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    height: "80%",
    maxWidth: 700,
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  creativeTabContainer: {
    maxWidth: 1300,
    background: 'none',
    display: "flex",
    alignItems: "baseline",
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    gap: 24,
    backgroundColor: `${SystemColors.PAPER_BACKGROUND_CONTAINER} !important`
  },
  gridCreativeTabContainer: {
    [theme.breakpoints.up(1279)]: {
      maxWidth: 800
    }
  },
  campaignCreationMainContainer: {
    backgroundColor: "#F5F3F6",
    height: "calc(100vh - 64px)",
    paddingTop: theme.spacing(4),
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    borderRadius: 12,
    [theme.breakpoints.down(701)]: {
      paddingTop: theme.spacing(0),
    },
    [theme.breakpoints.up(1023)]: {
      width: "91.666667%",
    },
    [theme.breakpoints.up(1279)]: {
      width: "83.333333%",
      maxWidth: 800
    }
  },
  targetingView: {
    display: "flex",
    flexDirection: "row",
    color: "#8800FF",
    borderRadius: 32,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    gap: 6,
    alignItems: "center",
  },
  audienceChipLabelColor: {
    color: "#8800FF",
  },
  audienceChipBgColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  mbWhenTargetingView: {
    marginBottom: theme.spacing(30)
  },
  mbWhenNotTargetingView: {
    marginBottom: theme.spacing(20)
  },
  tabsHolder: {
    padding: "0px 8px"
  },
  externalLink: {
    display: "inline",
    height: "20px",
    width: "20px",
  },
  svgAccent: {
    height: '18px',
    width: '18px',
    '& path': {
      stroke: SystemColors.ACCENT_COLOR,
    },
  },
  svgAccentFill: {
    height: '18px',
    width: '18px',
    '& path': {
      fill: SystemColors.ACCENT_COLOR,
    },
  },
  platformIcon: {
    minHeight: '56px',
    minWidth: '56px',
    maxHeight: '56px',
    maxWidth: '56px',
  },
  accordion: {
    '& .MuiAccordionSummary-root': {
      padding: '0px'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px'
    },
  }
}));

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  width: 600px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  height: 250px;
`

interface CampaignDetailsContainerProps {
  backgroundColor: string;
  maxWidth?: string;
  gap?: string;
}

interface TabsContainerProps {
  width?: string;
}

interface PlatformContainerProps {
  flexDirection?: string;
}

export const CampaignDetailsContainer = styled.div`
  background-color: ${({ backgroundColor }: CampaignDetailsContainerProps) => (backgroundColor ? backgroundColor : "#EDECF2")};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }: CampaignDetailsContainerProps) => gap || "24px"};
  max-width: ${({ maxWidth }: CampaignDetailsContainerProps) => maxWidth};
  min-width: ${({ maxWidth }: CampaignDetailsContainerProps) => maxWidth};
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0px 8px;
  border-radius: 8px;
  min-width: ${({ width }: TabsContainerProps) => width};
  max-width: ${({ width }: TabsContainerProps) => width};
`;



export const ConversionsBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${SystemColors.PAPER_ACCENT_CONTAINER};
  gap: 4px;
  padding: 8px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const BannerHeader = styled.div`
  display: flex;
  gap: 4px;
`;

export const TermsOfServiceContainer = styled.div`
  margin: 16px 40px 0px 40px;
`

export const PlatformContainer = styled.div<PlatformContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }: PlatformContainerProps) => flexDirection};
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom-width: 1px;
  ${MEDIA_BREAKPOINTS.largeView} {
    padding: 16px 40px;
  }
`;

export const PlatformDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AccountCardContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 32px;
  align-items: center;
  justify-content: space-between;
`;

export const CheckedIcon = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  right: -10px;
  bottom: -10px;
`;

export const PlatformIconContainer = styled.div`
  position: relative;
  height: fit-content;
`;