import styled from "styled-components";

const getWidthValue = (value: number) => {
  if (value > 100) {
    return 100;
  }

  if (value <= 0) {
    return 0;
  }

  return value;
};

interface ProgressBarProps {
  progress: number;
}

interface IndicatorProps {
  value: number;
}

export const MainContainer = styled.div`
  position: relative;
`;

export const BackgroundBar = styled.div`
  width: 100%;
  background-color: #f4f4f4;
  height: 24px;
  border-radius: 4px;
`;

export const Indicator = styled.div`
  position: absolute;
  background-color: transparent;
  border-right: 2px solid #f18500;
  width: ${({ value }: IndicatorProps) => getWidthValue(value)}%;
  border-style: dashed;
  height: 40px;
  top: -8px;
`;

export const IndicatorLabel = styled.div`
  position: relative;
  width: 100px;
  top: 40px;
  left: 53px;
  color: #f18500;
`;

export const ProgressBar = styled.div`
  width: ${({ progress }: ProgressBarProps) => getWidthValue(progress)}%;
  height: 24px;
  background-color: #8800ff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
