import { queryFB } from "helpers/FB"
import Axios from "helpers/Interceptor"
import Logger from "Logger"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"

const ON_REQUEST_FIELDS =
  "funding_source,funding_source_details,capabilities,spend_cap,currency"
const ON_ERROR_FIELDS = "funding_source,capabilities,spend_cap,currency"

export const getCurrency = async (fbAdAccountId?: string | null) => {
  if (!fbAdAccountId) return DEFAULT_CURRENCY

  let results = null
  const url = `/${fbAdAccountId}`

  try {
    results = await queryFB(url, { params: { fields: ON_REQUEST_FIELDS } })
  } catch (e) {
    results = await queryFB(url, { params: { fields: ON_ERROR_FIELDS } })
  }

  if (results && results.currency && results.currency !== DEFAULT_CURRENCY) {
    return results.currency
  }

  return DEFAULT_CURRENCY
}

export const getData = async (data: {
  brandSlug?: string | null,
  isOrganization: boolean,
  budget: number
  currency: string
}) => {
  const { brandSlug, budget, currency, isOrganization } = data

  try {
    if (isOrganization) {
      const response = await Axios.get(
        `/organization/campaign-usage?budget=${budget}&currency=${currency}`
      )

      const data = response.data.data
      return data
    } else {
      const response = await Axios.get(
        `/subscription/brand/${brandSlug}/usage/campaigns?budget=${budget}&currency=${currency}`
      )

      const data = response.data.data
      return data
    }
  } catch (error) {
    Logger.error(
      "Error when retrieving spend budget data in BudgetTabSpendContext: ",
      error
    )
    return null
  }
}
