import StatsCard from "./card";
import { GridContainer } from "./styles";
import { Stat } from "./utils";

interface StatsCardRowProps {
    stats: Stat[];
    disablePadding?: boolean
}

const StatsCardRow: React.FC<StatsCardRowProps> = ({ stats, disablePadding }) => {
    return (
        <GridContainer
            style={{
                padding: disablePadding ? 0 : ``
            }}>
            {stats.map((stat, index) => (
                <StatsCard
                    key={index}
                    stat={stat}
                    className={index === 0 ? 'border-t' : ''}
                />
            ))}
        </GridContainer>
    );
};

export default StatsCardRow