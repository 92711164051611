import { Dispatch, SetStateAction } from "react";
import Axios from "helpers/Interceptor";
import { CurrentBrand } from "types/global";
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import { GeographicTargetingType } from "./TargetingView/reducer";
import { SAVE, REVIEW, REVIEW_YOUR_CAMPAIGN, CONTINUE, NEXT, CONNECT } from "./constants";
import { PreConnectionConfigs, UploadedAssetType } from "../utils/fbCampaigns";

export enum STEPS {
  CAMPAIGN_GOAL = "goal",
  CREATIVE = "creative",
  BUDGET = "budget",
  FINALIZATION = "review",
}

const { CREATIVE, BUDGET } = STEPS

const dev = process.env.NODE_ENV !== "production";

export interface CampaignOption {
  id:string;
  label: string;
  icon: string;
  description: string;
  recommendation: string;
  conversionGoal: string;
  tips: string;
  whenToUse?: string[];
  setupNeeded?: string[];
  conversionType: string;
  averageConversionCost: number;
  available?: boolean;
  availabilityNote?: string;
}

export const campaignOptions: CampaignOption[] = [
  {
    id: "boost_views",
    icon: require("assets/images/campaigns/boost_ig.png").default,
    label: "Boost IG Post Views",
    conversionGoal: "Ads will optimize to boost views + likes on your post.",
    description: "Increase views on an Instagram post, to increase your overall reach. Best used when your goal is to increase engagement on a post.",
    recommendation: "Best for getting cheap views on a post, not recommended for growing followers.",
    tips: "Optimize for cost-effectiveness to maximize the number of views and likes. Not the most efficient method for increasing followers. Can significantly extend your reach with a sufficient budget.",
    whenToUse: [
      "When you have a post or reel that you want to push for maximum exposure.",
      "Best for getting cheap views on a post, not recommended for growing followers.",
      "To re-engage the algorithm to benefit your content's visibility."
    ],
    setupNeeded: [
      "Access to your Facebook and Instagram business accounts.",
      "A visual advertising asset, like a video."
    ],
    conversionType: "view",
    averageConversionCost: 0.02
  },
  {
    id: "boost_follows",
    // availabilityNote: "We're adding some upgrades to this campaign type - it should be live again by August 7, 2024!",
    icon: require("assets/images/campaigns/ig_profile_visits.png").default,
    label: "Boost IG Profile Visits",
    conversionGoal: "Ads will optimize to boost profile visits.",
    description: "Optimize for more visits to your profile, resulting in potentially more followers. Best used when your goal is to increase followers or boost your reach.",
    recommendation: "Focuses on acquiring high-intent followers likely to engage with your content.",
    tips: "More costly per action but yields followers with a higher chance of engagement. Avoid if your primary goal is to gain cheap views rather than valuable followers.",
    whenToUse: [
      "When looking to grow your Instagram profile with new, high-quality followers.",
      "To re-engage the algorithm to benefit your content's visibility."
    ],
    setupNeeded: [
      "Access to your Facebook and Instagram business accounts.",
      "A compelling advertising asset to attract potential followers, like a video."
    ],
    conversionType: "profile visit",
    averageConversionCost: 0.30,
    available: true,
  }
];
export const getCampaignOptionById = (id: string): CampaignOption | undefined => {
  return campaignOptions.find((option) => option.id === id);
};


export enum Platforms {
  SPOTIFY = "spotify",
  ALL = "all",
  APPLE_MUSIC = "apple_music",
  YOUTUBE = "youtube",
}

type DataPlatform = {
  name: string
  key: string
  goal: string
  selected: boolean
  subtitle: string | null
  uploadingAssets: boolean
}

export type SelectedPlatforms = {
  spotify: DataPlatform
  all: DataPlatform
  apple_music: DataPlatform
  youtube: DataPlatform
}

export const getIfTargetingSaveButtonIsDisabled = (
  targets: GeographicTargetingType[],
  totalBudget: number
) => {
  const locations = targets.map(({ locations }) => Object.values(locations))
  const budgets = targets.map(({ budget }) => budget)
  return (
    totalBudget !== 1
    || !locations.filter((location) => location.length > 0).length
    || budgets.includes(0)
  )
};

export const getWhenCreativeTabNextButtonIsDisabled = (args: {
  adConnectionsAdded: boolean
  adConnectionsConfirmed: boolean
  selectedFB_page: Record<string, unknown> | null
  selectedInstaPage: Record<string, unknown> | null
  selectedFBAdAccount: Record<string, unknown> | null
  selectedInstagramPost?: InstagramPost | null
  preConnectionConfigurations: PreConnectionConfigs | null
  selectedCreativeType: string
  arePagesSelected: boolean
  uploadingAssets: boolean
  uploadedImages: UploadedAssetType[] | null;
  cta: { value: string; label: string }
  caption: string
}) => {
  const {
    uploadedImages,
    adConnectionsAdded,
    adConnectionsConfirmed,
    uploadingAssets,
    selectedInstagramPost,
    selectedFB_page,
    selectedInstaPage,
    arePagesSelected,
    selectedFBAdAccount,
    preConnectionConfigurations,
    selectedCreativeType,
    cta,
    caption,
  } = args

  if (!adConnectionsAdded) {
    return !(
      selectedFB_page &&
      selectedFBAdAccount &&
      selectedInstaPage &&
      preConnectionConfigurations &&
      preConnectionConfigurations.status
    )
  }

  if (!adConnectionsConfirmed) {
    return false
  }

  return selectedCreativeType === "instagram_posts"
    ? !(arePagesSelected && selectedInstagramPost && cta)
    : !(
      !uploadingAssets &&
      arePagesSelected &&
      uploadedImages &&
      uploadedImages.length > 0 &&
      cta
    )
}

export const handleConnectionsType = (
  args: {
    connectionsType: string
    selectedFBBusinessManager: { id: string }
    selectedFBAdAccount: { id: string; business: { id: string } }
    currentBrand: CurrentBrand
    previouslySelectedBizMgr: { current: { id: string } }
    previouslySelectedAdAccount: { current: { id: string } }
    selectedFB_page: { id: string; current: { id: string } }
    previouslySelectedFbPage: { current: { id: string } }
    setFBBusinessManager: Dispatch<SetStateAction<Record<string, unknown> | null>>
    setFBAdAccount: Dispatch<SetStateAction<Record<string, unknown> | null>>
    setFBPixel: Dispatch<SetStateAction<Record<string, unknown> | null>>
    setSelectedInstaPage: Dispatch<SetStateAction<Record<string, unknown> | null>>
  }
) => {
  const {
    connectionsType,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    currentBrand,
    previouslySelectedBizMgr,
    previouslySelectedAdAccount,
    selectedFB_page,
    previouslySelectedFbPage,
    setSelectedInstaPage,
    setFBBusinessManager,
    setFBAdAccount,
    setFBPixel,
  } = args

  if (connectionsType === "custom") {
    if (dev) console.log("Custom connection")
    if (dev) console.log("bizmgr", selectedFBBusinessManager)
    if (dev) console.log("ad account", selectedFBAdAccount)

    // if a user changes their business manager,
    // fb ad account + pixel must be deselected
    // OR if a user goes from quick to custom
    // and the business ID in the ad account doesnt match the selected biz mgr
    if (
      selectedFBBusinessManager &&
      selectedFBAdAccount &&
      (!selectedFBAdAccount.business ||
        (selectedFBAdAccount.business &&
          selectedFBAdAccount.business.id &&
          selectedFBAdAccount.business.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("remove biz mgr")
      setFBBusinessManager(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_business_manager",
        value: selectedFBBusinessManager,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting biz mgr", err)
        })
    }

    if (
      !selectedFBBusinessManager ||
      (selectedFBBusinessManager &&
        (!previouslySelectedBizMgr.current ||
          previouslySelectedBizMgr.current.id !== selectedFBBusinessManager.id))
    ) {
      if (dev) console.log("selected biz mgr changed")

      if (!selectedFBBusinessManager) {
        Axios.put(`/brand/${currentBrand.slug}/connect`, {
          id: currentBrand.id,
          name: currentBrand.slug,
          service: "facebook_business_manager",
          value: selectedFBBusinessManager,
        })
          .then((result) => {
            // reloadBrand()
          })
          .catch((err) => {
            console.log("error connecting biz mgr", err)
          })
      }
      // set the ad account null in backend too
      setFBAdAccount(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_ad_account",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting ad account", err)
        })

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })

      previouslySelectedBizMgr.current = selectedFBBusinessManager
    }

    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  } else if (connectionsType === "quick") {
    // if a user changes their selected ad account,
    // fb pixel must be deselected
    if (
      selectedFBAdAccount &&
      (!previouslySelectedAdAccount.current ||
        previouslySelectedAdAccount.current.id !== selectedFBAdAccount.id)
    ) {
      // set the pixel null in backend too
      if (dev) console.log("selected FB Ad Account changed")

      setFBPixel(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "facebook_pixel",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting fb pixel", err)
        })
      previouslySelectedAdAccount.current = selectedFBAdAccount
    }

    // if a user changes their selected Facebook page,
    // IG page must be deselected
    if (
      selectedFB_page &&
      (!previouslySelectedFbPage.current ||
        previouslySelectedFbPage.current.id !== selectedFB_page.id)
    ) {
      // set the IG to null
      if (dev) console.log("selected FB Page changed")
      setSelectedInstaPage(null)
      Axios.put(`/brand/${currentBrand.slug}/connect`, {
        id: currentBrand.id,
        name: currentBrand.slug,
        service: "instagram_page",
        value: null,
      })
        .then((result) => {
          // reloadBrand()
        })
        .catch((err) => {
          console.log("error connecting instagram", err)
        })

      previouslySelectedFbPage.current = selectedFB_page
    }
  }
}

export const getNextButtonLabel = (args: {
  showTargetingView: boolean
  selectedTab?: STEPS | null
  isNextButtonMobile: boolean
  connectModalType: string
}) => {
  const { showTargetingView, selectedTab, isNextButtonMobile, connectModalType } = args

  if (showTargetingView) return SAVE
  if (selectedTab === BUDGET && isNextButtonMobile) return REVIEW
  if (selectedTab === BUDGET && !isNextButtonMobile) return REVIEW_YOUR_CAMPAIGN
  if (selectedTab === CREATIVE && connectModalType === "") return CONTINUE
  if (selectedTab === CREATIVE && connectModalType !== "") return CONNECT
  return NEXT
}

export const getNextButtonWidth = (args: {
  selectedTab?: STEPS | null
  isNextButtonResponsive: boolean
  isNextButtonMobile: boolean
}) => {
  const { selectedTab, isNextButtonMobile, isNextButtonResponsive } = args

  if (selectedTab === BUDGET && isNextButtonMobile) return undefined
  if (selectedTab === BUDGET && !isNextButtonMobile && isNextButtonResponsive) return "220px"
  if (selectedTab === BUDGET) return "300px"
  return undefined
}

export const convertArrayDraftDataToSendAsObject = (data: Record<string, unknown>[]) => {
  const [
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    minAge,
    maxAge,
    gender,
    languages,
    addVisualSelected,
    selectedCampaignType
  ] = data

  return {
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics: {
      age: {
        min: minAge,
        max: maxAge,
      },
      gender,
      languages,
    },
    addVisualSelected,
    selectedCampaignType
  }
}
