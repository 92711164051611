import styled from "styled-components";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(1.5),
  },
  toggleButtonGroup: {
    height: 36,
  },
  outlinedTextField: {
    marginTop: theme.spacing(2),
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C8C7CC",
    },
  },
  select: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C8C7CC",
    },
  },
  nativeInput: {
    borderRadius: 6,
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
  },
  infoTitle: {
    fontWeight: 500,
    fontSize: 18,
  },
  infoDescription: {
    fontSize: 14,
    marginTop: theme.spacing(2),
    color: "#707070",
  },
  goBackParent: {
    position: "absolute",
    cursor: "pointer",
    top: "50%",
    transform:
      "translate3d(0, -50%, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1)",
  },
  goBackChild: {
    stroke: "currentColor",
    fontSize: 24,
    color: "white",
    [theme.breakpoints.down(1024)]: {
      color: "rgba(136, 0, 255, 1)",
    },
  },
  toggleButtonPreviewSelected: {
    cursor: "default",
    pointerEvents: "none",
    maxWidth: "100%",
    backgroundColor: "#8800FF",
    color: "#ffffff",
    "&.MuiToggleButton-root": {
      textTransform: "none",
    },
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
    "&.MuiToggleButton-root.Mui-selected:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  toggleButtonPreviewUnselected: {
    maxWidth: "100%",
    backgroundColor: "##ffffff",
    color: "#8800FF",
    "&.MuiToggleButton-root": {
      textTransform: "none",
    },
  },
  toggleButtonScheduledSelected: {
    cursor: "default",
    pointerEvents: "none",
    maxWidth: "100%",
    backgroundColor: "#8800FF",
    color: "#ffffff",
    "&.MuiToggleButton-root": {
      textTransform: "none",
      width: 230,
      [theme.breakpoints.down(492)]: {
        width: 180,
      },
      [theme.breakpoints.down(400)]: {
        width: 120,
      },
    },
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
    "&.MuiToggleButton-root.Mui-selected:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  toggleButtonScheduledUnselected: {
    maxWidth: "100%",
    backgroundColor: "##ffffff",
    color: "#000000",
    "&.MuiToggleButton-root": {
      textTransform: "none",
      width: 230,
      [theme.breakpoints.down(492)]: {
        width: 180,
      },
      [theme.breakpoints.down(400)]: {
        width: 120,
      },
    },
  },
  groupedHorizontal: {
    border: "1px solid #8800FF",
    borderRight: "1px solid #8800FF",
    borderLeft: "1px solid #8800FF",
    "&:not(:first-child)": {
      border: "1px solid #8800FF",
      borderRight: "1px solid #8800FF",
      borderLeft: "1px solid #8800FF",
    },
    "&:first-child": {
      border: "1px solid #8800FF",
      borderRight: "1px solid #8800FF",
      borderLeft: "1px solid #8800FF",
    },
  },
  emptyClass: {},
  infoSectionMainContainer: {
    width: "100%",
    padding: "16px 20px",
    borderBottom: "1px solid rgba(229, 231, 235, 1)",
    [theme.breakpoints.up(1023)]: {
      padding: "16px 40px",
    },
  },
  scheduleSection: {
    width: "100%",
    padding: "16px 20px",
    borderBottom: "1px solid rgba(229, 231, 235, 1)",
    [theme.breakpoints.up(1023)]: {
      padding: "16px 40px",
    },
  },
  closeIcon: {
    width: 9,
    height: 9,
    marginRight: theme.spacing(1.5),
    cursor: "pointer",
  },
  chipLabelColor: {
    color: "white",
  },
  chipBackgroundColor: {
    backgroundColor: "#80f",
  },
  inputBorder: {
    borderRadius: 8,
  },
  eventIcon: {
    width: 40,
    height: 40,
  },
  datePickerTextFieldWhenSelectedDate: {
    borderRadius: 8,
    width: "100%",
    maxWidth: 360,
    "& input": {
      fontFamily: "DIN",
      color: "#80f",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
      color: "#80f",
    },
  },
  datePickerTextFieldWhenNotSelectedDate: {
    borderRadius: 8,
    width: "100%",
    maxWidth: 200,
    "& input": {
      fontFamily: "DIN",
      color: "#80f",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
      color: "#80f",
      opacity: 1,
    },
  },
  successLabelContainer: {
    paddingLeft: theme.spacing(4),
  },
  successLabelPrincipalContainer: {
    display: "flex",
    flex: "auto",
    flexDirection: "row",
    backgroundColor: "#AEEDAE",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    borderRadius: 10,
  },
  iconContainer: {
    paddingTop: theme.spacing(1),
  },
  roundContainer: {
    width: 72,
    height: 72,
    backgroundColor: "#ffffff",
    display: "inline-block",
    borderRadius: "50%",
  },
}));

interface FlexContainerProps {
  height?: string;
  flexDirection?: "row" | "column";
  overflowY?: string;
}

interface ToggleButtonsContainerProps {
  borderBottom?: boolean;
  alignItems?: string;
  justifyContent?: string;
}

interface SectionProps {
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: string;
  borderBottom?: string;
  columnGap?: string;
  justifyContent?: string;
}

interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  padding?: string;
}

interface EditorContainerProps {
  height?: string;
}

interface LeftColumnProps {
  paddingBottom?: string;
  overflowY?: string;
  width?: string;
  height?: string;
}

interface RightColumnProps {
  overflowY?: string;
  width?: string;
  height?: string;
}

interface ButtonContainerProps {
  padding?: string;
}

export const HeadContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  @media (min-width: 768px) {
    padding-top: 16px;
    padding-bottom: 80px;
  }
`;

export const MainNavbar = styled.div`
  position: relative;
  width: 100%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 0, 255, var(--tw-bg-opacity));
  }
`;

export const ViewTitle = styled.h4`
  text-align: center;
  @media (min-width: 1024px) {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
  }
`;

export const TabsContainer = styled.nav`
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
`;

export const FlexContainer = styled.div`
  width: 100%;
  height: ${({ height }: FlexContainerProps) => height};
  display: flex;
  flex-direction: ${({ flexDirection }: FlexContainerProps) =>
    flexDirection || "row"};
  overflow-y: ${({ overflowY }: FlexContainerProps) => overflowY};
`;

export const LeftColumn = styled.div`
  padding-bottom: ${({ paddingBottom }: LeftColumnProps) =>
    paddingBottom || "224px"};
  width: ${({ width }: LeftColumnProps) => width || "50%"};
  height: ${({ height }: LeftColumnProps) => height};
  border-right: 1px solid rgba(209, 213, 219, 1);
  overflow-y: ${({ overflowY }: LeftColumnProps) => overflowY};
`;

export const RightColumn = styled.div`
  overflow-y: ${({ overflowY }: RightColumnProps) => overflowY};
  width: ${({ width }: RightColumnProps) => width || "50%"};
  height: ${({ height }: RightColumnProps) => height};
`;

export const ButtonContainer = styled.div`
  padding: ${({ padding }: ButtonContainerProps) =>
    padding || "24px 24px 110px 24px"};
`;

export const FanbaseTableContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  padding-left: 32px;
  padding-right: 32px;
`;

export const ToggleButtonsContainer = styled.div`
  display: flex;
  align-items: ${({ alignItems }: ToggleButtonsContainerProps) =>
    alignItems || "flex-start"};
  justify-content: ${({ justifyContent }: ToggleButtonsContainerProps) =>
    justifyContent || "flex-start"};
  padding: 8px 0px;
  width: 100%;
  border-bottom: ${({ borderBottom }: ToggleButtonsContainerProps) =>
    borderBottom ? "1px solid #c8c7cc" : ""};
  @media (max-width: 720px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const BorderedContainer = styled.div`
  margin-top: 24px;
  border: 1px solid #c8c7cc;
  border-radius: 6px;
`;

export const ContainerWithRightBorder = styled.div`
  width: 20%;
  padding: 16px;
  border-right: 1px solid #c8c7cc;
  text-align: center;
  @media (max-width: 720px) {
    width: 40%;
  }
`;

export const ChipsContainer = styled.div`
  width: 80%;
  padding-right: 8px;
  padding-left: 8px;
`;

export const StyledPlaceholder = styled.span`
  font-family: DIN;
  font-weight: 400;
  font-size: 16px;
  padding: 16px 4px;
  text-align: center;
  color: #707070;
`;

export const StyledGrayLabel = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #707070;
`;

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  padding: ${({ padding }: StyledLabelProps) => padding};
`;

export const FlexBottomBorderedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #c8c7cc;
`;

export const PickersContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: FlexContainerProps) =>
    flexDirection || "row"};
  align-items: center;
  column-gap: 8px;
`;

export const SubjectLine = styled.div`
  padding: 16px 24px;
  word-break: break-word;
  border-bottom: 1px solid #c8c7cc;
`;

export const EditorContainer = styled.div`
  padding-bottom: 224px;
  background-color: #f2f2f2;
  height: ${({ height }: EditorContainerProps) => height || "100%"};
`;

export const LoadingContainer = styled.div`
  margin: 92px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

export const FinalizeTabPreview = styled.div`
  padding: 32px 24px;
`;

export const Section = styled.div`
  display: ${({ display }: SectionProps) => display || "flex"};
  flex-direction: ${({ flexDirection }: SectionProps) =>
    flexDirection || "row"};
  align-items: ${({ alignItems }: SectionProps) => alignItems || "center"};
  padding: ${({ padding }: SectionProps) => padding || "24px 32px"};
  border-bottom: ${({ borderBottom }: SectionProps) =>
    borderBottom || "1px solid rgba(229, 231, 235, 1)"};
  column-gap: ${({ columnGap }: SectionProps) => columnGap};
  justify-content: ${({ justifyContent }: SectionProps) => justifyContent};
`;

export const InfoViewContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 32px;
`;

export const StyledText = styled.span`
  font-weight: 500;
  font-size: 18px;
`;

export const PreviewContainer = styled.div`
  background-color: #F2F2F2;
`

export const NextButton = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button);

export default useStyles;
