import { StarsIcon } from "components/svg-icons";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import millify from "millify"
import { useContext, useEffect, useState } from "react";
import { availableTiers } from "./utils";
import { CurrentBrand } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "../constants";
import { LoadingLottie } from "components/Loader/LoadingLottie";

const playlistingTier1 = require("../../../../assets/images/playlisting/30k.png").default
const playlistingTier2 = require("../../../../assets/images/playlisting/100k.png").default
const playlistingTier3 = require("../../../../assets/images/playlisting/300k.png").default
const playlistingTier4 = require("../../../../assets/images/playlisting/600k.png").default
const playlistingTier5 = require("../../../../assets/images/playlisting/1mil.png").default

const tier_image_mapping: any = {
    30000: playlistingTier1,
    100000: playlistingTier2,
    300000: playlistingTier3,
    600000: playlistingTier4,
    1000000: playlistingTier5
}

interface PlaylistingProps {
    setTier: any,
    record: any,
    isPro: boolean
    currentBrand: CurrentBrand
}

type TiersToShowType = {
    tier: number;
    cost: number;
    proDiscount: number;
    bgImage: string;
    proPricingDifference: number;
    proPricing: number;
}

export default function PlaylistingGoalTab({
    isPro,
    record,
    setTier,
    currentBrand,
}: PlaylistingProps) {
    const { handleOpenModal } = useContext(UpgradeModalContext);
    const [tiersToShow, setTiersToShow] = useState<TiersToShowType[]>([])

    const currencySymbol = getSymbolFromCurrency(currentBrand.currency?.code ?? DEFAULT_CURRENCY)

    const initializeTiers = async () => {
        const tiers: TiersToShowType[] = []

        for (const availableTier of availableTiers) {
            let cost = await availableTier.cost(currentBrand.currency?.code)
            const { proPricingDifference, proPricing } = await getDiscountPricing({ ...availableTier, cost })
            tiers.push({
                ...availableTier,
                cost,
                proPricingDifference,
                proPricing,
            })
        }
        setTiersToShow(tiers)
    }

    useEffect(() => {
        initializeTiers()
    }, [])

    const getDiscountPricing = async (goal: {
        tier: number
        cost: number
        proDiscount: number
    }) => {
        const { cost, proDiscount } = goal

        const proPricingDifference = cost * (proDiscount)
        const proPricing = (cost * (1 - proDiscount))

        return {
            proPricingDifference,
            proPricing
        }
    }

    const handleSelectTier = async (goal: {
        tier: number
        cost: number
        proDiscount: number
        proPrice?: number
    }) => {
        const { cost, proDiscount } = goal
        const { proPricing } = await getDiscountPricing(goal)

        setTier({
            ...goal,
            originalCost: cost,
            discount: isPro ? proDiscount : 0,
            cost: isPro ? proPricing : cost,
        })
    }

    return (
        <div className="middle-wrapper lg:px-0 relative">
            <div className="sy-card px-5 lg:px-10">
                <div className="">

                    <h5 className="text-dark font-bold text-lg">Pitch Highly-Targeted Playlists</h5>
                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                        Symphony will automatically pitch "{record?.name}" to our network of highly-vetted, user-generated Spotify playlists. Select the tier of playlisting support you're looking for.
                    </p>
                </div>
            </div>
            <div className="relative h-100 lg:h-auto lg:pt-0">
                {!isPro && (
                    <div className="px-4 md:px-7 flex items-center px-2 py-3 w-full text-md"
                        onClick={() => handleOpenModal({
                            source:"Campaign Details - Playlisting"
                        })}
                        style={{
                            background: "#FFF7EB",
                            color: "#D18827"
                        }}>
                        <StarsIcon color="#D18827"></StarsIcon>
                        <p className="ml-0.5">Subscribe to PRO and save 15%</p>
                        <button className="text-sm text-primary border border-primary  text-semibold rounded-full ml-auto px-4 py-2 font-semibold">Subscribe</button>

                    </div>
                )}
                <div className="px-6  py-4">
                    {!tiersToShow.length
                      ? <LoadingLottie black/>
                      : tiersToShow.map((availableTier) => {
                        const {
                            tier,
                            cost,
                            bgImage,
                            proDiscount,
                            proPricingDifference,
                            proPricing
                        } = availableTier

                        const goal = {
                            tier,
                            cost,
                            bgImage,
                            proDiscount,
                        }

                        const imageAsset = tier_image_mapping[tier]
                        return (
                            <div key={tier} onClick={() => handleSelectTier(goal)} className=" hover:bg-purple-100 cursor-pointer transition-all flex flex-row justify-between items-center border-c4c4c4 p-4 br-10px mb-3 md:mb-5">
                                <div className="flex flex-col justify-center md:flex-row items-center">
                                    <div className="w-full md:w-9/12">
                                        <div className="flex flex-row justify-center items-center">
                                            <div className="text-2xl font-bold rounded-lg w-8/12 md:w-24 py-10 text-center mr-3 bg-cover bg-center" style={{
                                                backgroundImage: `url(${imageAsset})`
                                            }}>
                                                <p>{millify(tier)}</p>
                                            </div>
                                            <div className="flex flex-col">
                                                <p className="text-lg font-semibold my-0">{tier.toLocaleString('en')} Followers</p>
                                                <p className="w-full my-1 text-md">Pitch your song to playlists with a combined following of over {millify(tier)} followers.</p>
                                                {!isPro ? (<div className="rounded-full flex items-center px-2 py-1 text-sm"
                                                    style={{
                                                        background: "#FFF7EB",
                                                        color: "#D18827",
                                                        width: "fit-content"
                                                    }}>
                                                    <StarsIcon color="#D18827"></StarsIcon>
                                                    <p className="ml-0.5">Save {currencySymbol}{Math.round(proPricingDifference).toLocaleString('en')} with PRO</p>

                                                </div>) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3 md:my-0 md:ml-auto">
                                        <p className="text-xl font-semibold text-primary">{currencySymbol}{(isPro ? proPricing : cost).toLocaleString('en', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })}</p>
                                        {isPro && <p className="line-through text-medium text-center md:text-right font-semibold text-gray-400">{currencySymbol}{cost.toLocaleString('en', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        })}</p>}
                                    </div>
                                    {/* {isPro ? (
                                        <div>
                                            <div className="mt-3 py-1 flex flex-row text-center">
                                                <p className="text-green-600 font-semibold  bg-green-200 px-2 py-1 m2-2 rounded-md">${proPricing.toLocaleString('en', {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2
                                                })}</p>

                                            </div>
                                            <p className="text-sm mt-0.5">You're saving ${proPricingDifference.toLocaleString('en', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}  with Pro</p>
                                            <p className="text-sm mt-0.5">Non-Pro Price: ${cost.toLocaleString('en', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}</p>

                                        </div>) : (
                                        <div>
                                            <div className="mt-3 py-1 flex flex-row text-center">
                                                <p className="text-green-600 font-semibold  bg-green-200 px-2 py-1 m2-2 rounded-md">${cost.toLocaleString('en', {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2
                                                })}</p>

                                            </div>
                                            <p className="text-sm mt-0.5">Save ${proPricingDifference.toLocaleString('en', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })} with Symphony Pro</p>
                                            <p className="text-sm mt-0.5">Pro Price: ${proPricing.toLocaleString('en', {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2
                                            })}</p>

                                        </div>
                                    )} */}
                                </div>
                                <div>
                                </div>

                            </div>

                        )
                    })}
                </div>
            </div>
        </div >
    )
}