import styled from 'styled-components';

export const DateTimezoneInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  & input {
    width: 100%;
    border: 1px solid #adb7bc;
    border-radius: 6px;
    margin-bottom: 10px;
  }
`;