import { SpotifyArtist } from "types/global"

export const getHelperText = (args: {
  error: boolean
  results: SpotifyArtist[]
  loading: boolean
  spotifySearchString: string
  textFieldValue: string
  disabled?: boolean
}) => {
  const { error, results, loading, spotifySearchString, textFieldValue, disabled } = args

  if (error) return "An error occurred. Try again later."
  if (disabled) return undefined;
  if (!results.length && !loading && spotifySearchString !== textFieldValue) {
    return "Results not found."
  }

  return undefined
}

export const getModalContainerHeight = (items: number) => {
  if (items > 2) return "300px"
  return "100%"
}

