import axios from 'axios';
import * as Sentry from "@sentry/react"

const setAuthorization = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) Sentry.captureException("An access token was not provided to attach to headers")
    return `Bearer ${accessToken}`
}

// .env.development file would be used while development
// https://create-react-app.dev/docs/adding-custom-environment-variables/
// const dev = process.env.NODE_ENV !== 'production';

const Axios = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://staging-api.symphonyos.co:8080/api',
    timeout: 60000
});

const requestHandler = (request: any) => {
    request.headers.Authorization = setAuthorization();
    return request;
};

const responseHandler = (response: any) => {
    if (response.status === 401) {
        window.location.href = '/auth/login';
    }
    return response;
};

const errorHandler = (error: any) => {
    return Promise.reject(error);
};

Axios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

Axios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default Axios;
