import { FunctionComponent, useState } from "react"
import HeaderModal from "../../../HeaderModal"
import {
  CardInfo,
  CardSubTitle,
  CardTitle,
  DuplicateCard,
  DuplicateOptionsContainer,
  CardImage
} from "../../style"
import duplicateThisImage from "assets/images/campaignDetails/Temporary-Component.png"
import { ReactComponent as DuplicateIcon } from "assets/images/campaignDetails/duplicate.svg"
import Lottie from "react-lottie"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import ErrorBanner from "../ErrorBanner"
import { CAMPAIGN_TYPES } from '../../../../../../../constants';

enum Options {
  SAME = "SAME",
  OTHER = "OTHER",
}

const { SAME, OTHER } = Options

interface Props {
  loading: boolean
  error: boolean
  campaignName?: string
  recordType: string
  duplicateAnotherIcon: string
  handleDuplicateSame: () => void
  handleDuplicateAnother: () => void
  closeModal: () => void
  campaignType: string
  thumbnailUrl?: string
}

const SelectDuplicateOption: FunctionComponent<Props> = ({
  loading,
  error,
  campaignName,
  recordType,
  duplicateAnotherIcon,
  handleDuplicateSame,
  handleDuplicateAnother,
  closeModal,
  campaignType,
  thumbnailUrl
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<Options | null>(null)

  const handleSelectDuplicateSameOption = () => {
    setSelectedOption(SAME)
    handleDuplicateSame()
  }

  const handleSelectDuplicateAnotherOption = () => {
    setSelectedOption(OTHER)
    handleDuplicateAnother()
  }

  return (
    <>
      <HeaderModal
        HeaderIcon={DuplicateIcon}
        title="Duplicate campaign"
        subtitle={`Do you want to duplicate this campaign for the same ${recordType}?`}
        closeModal={closeModal}
      />
      <DuplicateOptionsContainer>
        <DuplicateCard onClick={handleSelectDuplicateSameOption}>
          <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} />
          <CardInfo>
            <CardTitle>Same {recordType}</CardTitle>
            <CardSubTitle>{`${campaignName}`}</CardSubTitle>
          </CardInfo>
          {loading && selectedOption === SAME && !error && (
            <div className="order-3">
              <Lottie
                height={32}
                width={32}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: SymphonyLoadingLottie,
                }}
              />
            </div>
          )}
        </DuplicateCard>
        <DuplicateCard onClick={handleSelectDuplicateAnotherOption}>
          <img src={duplicateAnotherIcon} width={50} height={50} />
          <CardInfo>
            <CardTitle>Choose another {campaignType === CAMPAIGN_TYPES.record_streams ? 'release' : recordType}</CardTitle>
          </CardInfo>
          {loading && selectedOption === OTHER && !error && (
            <div className="order-3">
              <Lottie
                height={32}
                width={32}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: SymphonyLoadingLottie,
                }}
              />
            </div>
          )}
        </DuplicateCard>
      </DuplicateOptionsContainer>
      {!loading && error && (
        <div className="order-2">
          <ErrorBanner errorText="A problem ocurred when duplicating your campaign... Please try again." />
        </div>
      )}
    </>
  )
}

export default SelectDuplicateOption
