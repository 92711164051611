import React, { useEffect, useState } from "react";
import { Chip, FormControl, MenuItem, Popover, TextField } from "@material-ui/core";
import dayjs from "dayjs";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CustomDateSelector } from "pages/post-auth/DashboardModule/components/LargeGraphPopup";
import { Container, MenuItemPopOver, SelectPopOver, TitlePopOver } from "styles/shared";
import { convertSnakeCase } from "utils";
import { CAMPAIGN_CARDS, availableGoals } from "../../utils/marketing";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { CampaignFilterType, FilterTypeBudget, FilterTypeDate } from "./types";
import CampaignFilterListItemPopover from "./popovers/list";
import { GeneralPurpleButton } from "pages/post-auth/SettingsPage/styles";
import Loaderv2 from "components/Loader/v2";
import DropdownArrow from "components/shareable/DropdownArrow";
import { TextButton } from "styles/shared";

// The filter options available to a user
const CAMPAIGN_FILTERS = [
    { name: "campaign_type", title: "Campaign Type" },
    { name: "campaign_status", title: "Status" },
    { name: "date", title: "Date" },
    { name: "budget", title: "Budget" },
    // { name: "release", title: "Release" }
];

const CAMPAIGN_STATUS_TYPES = [
    { name: "draft", title: "Draft" },
    { name: "in_review", title: "In Review" },
    { name: "active", title: "Active" },
    { name: "paused", title: "Paused" },
    { name: "needs_action", title: "Needs Action" },
    { name: "finished", title: "Finished" }
];

export default function CampaignTypesFilter({
    updatedAppliedFilterObject,
    searchQuery
}: {
    updatedAppliedFilterObject: (filterObject: CampaignFilterType) => void;
    searchQuery: string;
}) {

    const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
    // Filter Options:
    // - campaign types
    const [filterCampaignTypes, setFilterCampaignTypes] = useState<string[]>([]);
    // - campaign status
    const [filterCampaignStatuses, setFilterCampaignStatuses] = useState<string[]>([]);
    // - date filters 
    const [filterDateModifier, setFilterDateModifier] = useState<FilterTypeDate>("all_time");
    const [filterDays, setFilterDays] = useState<string>("30");
    const [filterDate, setFilterDate] = useState<{
        startDate: string;
        endDate: string;
    }>({
        startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    })

    // - budget filters
    const [filterBudget, setFilterBudget] = useState<FilterTypeBudget>("no_budget_filter");
    const [budgetAmount1, setBudgetAmount1] = useState<string>("");
    const [budgetAmount2, setBudgetAmount2] = useState<string>("");
    const [appliedFilterObject, setAppliedFilterObject] = useState<CampaignFilterType>({
        campaignTypes: [],
        campaignStatuses: [],
        dateFilter: {
            dateModifier: "all_time",
            startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            filterDays: "30",
        },
        budgetModifier: "no_budget_filter",
        budgetAmount1: "",
        budgetAmount2: "",
        limit: 15,
        offset: 0,
        campaignName: "",
    });
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

    // applies the filters, and sends the update to the parent
    const applyFilters = async () => {
        const filterObject: CampaignFilterType = {
            ...appliedFilterObject,
            campaignTypes: filterCampaignTypes,
            campaignStatuses: filterCampaignStatuses,
            dateFilter: {...filterDate, dateModifier: filterDateModifier, filterDays: filterDays},
            budgetModifier: filterBudget,
            budgetAmount1: budgetAmount1,
            budgetAmount2: budgetAmount2,
            campaignName: ""
        };

        // send this to the parent to handle as needed

        setAppliedFilterObject(filterObject);
        // getCampaigns(filterObject, true);
    }

    const removeFilter = () => {
        const newFilterObject = { ...appliedFilterObject };
    
        switch (openPopoverIndex) {
            case 0:
                setFilterCampaignTypes([]);
                newFilterObject.campaignTypes = [];
                break;
            case 1:
                setFilterCampaignStatuses([]);
                newFilterObject.campaignStatuses = [];
                break;
            case 2:
                setFilterDateModifier("all_time");
                setFilterDate({
                    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                });
                setFilterDays("30");
                newFilterObject.dateFilter = {
                    dateModifier: "all_time",
                    startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                    filterDays: "30"
                };
                break;
            case 3:
                setFilterBudget("no_budget_filter");
                setBudgetAmount1("");
                setBudgetAmount2("");
                newFilterObject.budgetModifier = "no_budget_filter";
                newFilterObject.budgetAmount1 = "";
                newFilterObject.budgetAmount2 = "";
                break;
            default:
                break;
        }
    
        setAppliedFilterObject(newFilterObject);
    }
    

    const handleSetFilterCampaignTypes = (key: string) => {


        const newSelectedTypes = [...filterCampaignTypes];

        const currentIndex = newSelectedTypes.findIndex((ct) => ct === key)

        if (currentIndex === -1) {
            // Status isn't selected yet, so add it
            newSelectedTypes.push(key);
        } else {
            // Status is already selected, so remove it
            newSelectedTypes.splice(currentIndex, 1);
        }

        setFilterCampaignTypes(newSelectedTypes);
    }

    const handleSetFilterCampaignNames = (name: string) => {
        setAppliedFilterObject({
            ...appliedFilterObject,
            campaignName: name
        });
    }

    const handleSetFilterCampaignStatuses = (filterStatus: string) => {
        const currentIndex = filterCampaignStatuses.indexOf(filterStatus);
        const newSelectedStatuses = [...filterCampaignStatuses];

        if (currentIndex === -1) {
            // Status isn't selected yet, so add it
            newSelectedStatuses.push(filterStatus);
        } else {
            // Status is already selected, so remove it
            newSelectedStatuses.splice(currentIndex, 1);
        }

        setFilterCampaignStatuses(newSelectedStatuses);
    }

    const handleSetFilterDateModifier = (event: SelectChangeEvent) => {
        const dateModifier: FilterTypeDate = event.target.value as FilterTypeDate;
        setFilterDateModifier(dateModifier);
    };

    const handleSetBudget = (event: SelectChangeEvent) => {
        const budget: FilterTypeBudget = event.target.value as FilterTypeBudget;
        setFilterBudget(budget);
    };

    const handleChangeFilterDays = (event: React.ChangeEvent<HTMLInputElement>) => {
        const days = event.target.value.replace(/\D+/g, '');
        setFilterDays(days);
    };

    const handleChangeBudgetAmount1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const budget1 = event.target.value.replace(/\D+/g, '');
        setBudgetAmount1(budget1);
    };

    const handleChangeBudgetAmount2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const budget2 = event.target.value.replace(/\D+/g, '');
        setBudgetAmount2(budget2);
    };



    // returns whether the filter is active or not
    const isFilterActive = (filterName: string) => {
        switch (filterName) {
            case "campaign_type":
                return filterCampaignTypes.length;
            case "campaign_status":
                return filterCampaignStatuses.length;
            case "date":
                return filterDateModifier !== 'all_time';
            case "budget":
                return filterBudget !== 'no_budget_filter';
            case "release":
                return false; // TODO: not yet built
            default:
                return null;
        }
    }

    // Returns the string associated with the filter
    const getFilterResultString = (filter: { name: string; title: string }) => {
        let filterResultString = null;
        const darkFont = { color: "#333" };

        switch (filter.name) {
            case "campaign_type":
                if (filterCampaignTypes.length > 0) {
                    const firstTwoTypes = filterCampaignTypes.slice(0, 2).map(ctKey => {
                        const campaignTypeInfo = availableGoals[ctKey];
                        return campaignTypeInfo.title
                    });
                    const remainingCount = filterCampaignTypes.length - 2;
                    if (remainingCount > 0) {
                        filterResultString = (
                            <>
                                Campaign Type: <span style={darkFont}>{firstTwoTypes.join(", ")}</span> and {remainingCount} more
                            </>
                        );
                    } else {
                        filterResultString = (
                            <>
                                Campaign Type: <span style={darkFont}>{firstTwoTypes.join(", ")}</span>
                            </>
                        );
                    }
                } else {
                    filterResultString = <>Campaign Type</>;
                }
                break;
            case "campaign_status":
                filterResultString = <>Status{filterCampaignStatuses.length ? (<span style={darkFont}>: {filterCampaignStatuses.map(cs => convertSnakeCase(cs)).join(", ")}</span>) : null}</>;
                break;
            case "date":
                const dateModifierString = convertSnakeCase(filterDateModifier);

                const startDateString = dayjs(filterDate.startDate).format("MMM D, YYYY").toString();
                const endDateString = dayjs(filterDate.endDate).format("MMM D, YYYY").toString();
                if (filterDateModifier == "in_between") {
                    filterResultString = <>Date Between: <span style={darkFont}>{startDateString} - {endDateString}</span></>;
                } else if (filterDateModifier == "before" || filterDateModifier == "after") {
                    filterResultString = <>Date {dateModifierString}: <span style={darkFont}>{startDateString}</span></>;
                } else if (filterDateModifier == "last_days") {
                    filterResultString = <>Last <span style={darkFont}>{filterDays} days</span></>;
                } else if (filterDateModifier === "all_time") {
                    filterResultString = <>Dates</>;
                }
                break;
            case "budget":
                const filterBudgetString = convertSnakeCase(filterBudget);

                if (filterBudget == "equal") {
                    filterResultString = <>Budget <span style={darkFont}>{filterBudgetString} to: ${budgetAmount1.length ? budgetAmount1 : "0"}</span></>;
                } else if (filterBudget == "greater_than" || filterBudget == "less_than") {
                    filterResultString = <>Budget <span style={darkFont}>{filterBudgetString}: ${budgetAmount1.length ? budgetAmount1 : "0"}</span></>;
                } else if (filterBudget == "between_two_numbers") {
                    filterResultString = <>Budget Between: <span style={darkFont}>${budgetAmount1.length ? budgetAmount1 : "0"} - ${budgetAmount2.length ? budgetAmount2 : "0"}</span></>;
                } else if (filterBudget === "no_budget_filter") {
                    filterResultString = <>Budget</>;
                }

                break;
            default:
                filterResultString = null;
                break;
        }

        return filterResultString;
    }


    const renderCustomDatesPicker = () => {
        if (filterDateModifier == "all_time") return null
        if (filterDateModifier == "last_days") {
            return (
                <>
                    <TextField
                        label="Last X Days"
                        variant="outlined"
                        value={filterDays}
                        onChange={handleChangeFilterDays}
                        style={{ marginBottom: '10px' }}
                        placeholder="Enter a number..."
                    />
                </>
            );
        }

        return (
            <>
                {filterDateModifier == "in_between" ? <p className="mr-1">From:</p> : null}
                <ReactDatePicker
                    filterDate={(date: Date) => {
                        return dayjs(date) < dayjs(filterDate.endDate).subtract(1, 'day');
                    }}
                    selected={dayjs(filterDate.startDate).toDate()}
                    onChange={(date) => {
                        setFilterDate({
                            startDate: dayjs(date).format("YYYY-MM-DD"),
                            endDate: filterDate.endDate
                        })
                    }}
                    customInput={<CustomDateSelector />}
                    onCalendarOpen={() => setIsDatePickerOpen(true)}
                    onCalendarClose={() => setIsDatePickerOpen(false)}
                /> 
                {filterDateModifier == "in_between" ?
                    <>
                        <p className="mr-1">To:</p>
                        <ReactDatePicker
                            minDate={dayjs(filterDate.startDate).add(1, 'day').toDate()}
                            selected={dayjs(filterDate.endDate).toDate()}
                            onChange={(date) => {
                                setFilterDate({
                                    endDate: dayjs(date).format("YYYY-MM-DD"),
                                    startDate: filterDate.startDate
                                })
                            }}
                            customInput={<CustomDateSelector />}
                        />
                    </>
                    :
                    null
                } 
            </>
        );
    }

    const renderDateFilter = () => {
        return (
            <Container 
                className="dateFilter" 
                display="flex" 
                flexDirection="column" 
                gap="16px" 
                maxWidth="340px" 
                height={isDatePickerOpen ? "400px" : "auto"}
                overflow={isDatePickerOpen ? "hidden" : "visible"}
            >
                <TitlePopOver>
                    Select Date Range
                </TitlePopOver>
                <FormControl fullWidth>
                    <Select
                        labelId="filter-select-label"
                        id="filter-select-date"
                        value={filterDateModifier}
                        label="Date Filter"
                        onChange={handleSetFilterDateModifier}
                        sx={{ fontFamily: "DIN" }}
                        IconComponent={() =>  (<DropdownArrow
                            marginRight={10}
                            width={24}
                            height={24}
                            direction={"down"} />)}
                    >
                        <MenuItemPopOver value="all_time">All-Time</MenuItemPopOver>
                        <MenuItemPopOver value="in_between">In-Between</MenuItemPopOver>
                        <MenuItemPopOver value="before">Before</MenuItemPopOver>
                        <MenuItemPopOver value="after">After</MenuItemPopOver>
                        <MenuItemPopOver value="last_days">Last Days</MenuItemPopOver>
                    </Select>
                </FormControl>

                <div className="flex items-center justify-center">
                    {renderCustomDatesPicker()}
                </div>
            </Container>
        );
    }

    const renderBudgetFilter = () => {
        return (
            <Container display="flex" flexDirection="column" gap="16px" maxWidth="340px">
                <TitlePopOver>
                    Filter by Budget
                </TitlePopOver>
                <FormControl fullWidth style={{ fontFamily: "DIN"}} >
                    <Select
                        labelId="filter-select-label"
                        id="filter-select-budget"
                        onChange={handleSetBudget}
                        value={filterBudget}
                        sx={{ fontFamily: "DIN" }}
                        IconComponent={() =>  (<DropdownArrow
                            marginRight={10}
                            width={24}
                            height={24}
                            direction={"down"} />)}
                    >
                        <MenuItemPopOver value="no_budget_filter" disabled>Select a budget filter</MenuItemPopOver>
                        <MenuItemPopOver value="equal">Equal to</MenuItemPopOver>
                        <MenuItemPopOver value="greater_than">Greater than</MenuItemPopOver>
                        <MenuItemPopOver value="less_than">Less than</MenuItemPopOver>
                        <MenuItemPopOver value="between_two_numbers">Between two numbers</MenuItemPopOver>
                    </Select>
                </FormControl>

                <TextField
                    label="Budget Amount"
                    variant="outlined"
                    value={`$${budgetAmount1}`}
                    onChange={handleChangeBudgetAmount1}
                    placeholder="Enter a number..."
                    style={{ fontFamily: "DIN" }}
                    InputProps={{ style: { fontFamily: "DIN" }}}
                />

                {filterBudget === "between_two_numbers" ?
                    <TextField
                        label="Budget Amount Upper Limit"
                        variant="outlined"
                        value={`$${budgetAmount2}`}
                        onChange={handleChangeBudgetAmount2}
                        style={{ marginBottom: '10px', fontFamily: "DIN" }}
                        InputProps={{ style: { fontFamily: "DIN" }}}
                        placeholder="Enter a number..."
                    />
                    :
                    null
                }
            </Container>
        );
    }

    const renderReleaseFilter = () => {
        <div>
            Filter by Release
        </div>
        return (
            <Container>
                renderReleaseFilter
            </Container>
        );
    }

    const renderPopoverContents = (filter: { name: string; title: string }) => {
        let body = null;
        switch (filter.name) {
            case "campaign_type":
                body = (
                    <CampaignFilterListItemPopover
                        title={"Filter by Campaign Type"}
                        listItems={CAMPAIGN_CARDS.map((goal, goalIndex) => {
                            const itemKey = goal.id;
                            const itemTitle = goal.title;
                            const checked = filterCampaignTypes.some(ct => ct === itemKey);
                            return {
                                key: itemKey,
                                onClick: handleSetFilterCampaignTypes,
                                icon: goal.icon,
                                checked: checked,
                                label: itemTitle
                            }
                        })}
                    />
                )
                break;
            case "campaign_status":
                body = (
                    <CampaignFilterListItemPopover
                        title={"Filter by status"}
                        listItems={CAMPAIGN_STATUS_TYPES.map((campaignStatusType, goalIndex) => {
                            const itemKey = campaignStatusType.name;
                            const itemTitle = campaignStatusType.title;
                            const checked = filterCampaignStatuses.some(ct => ct === itemKey);
                            return {
                                key: itemKey,
                                onClick: handleSetFilterCampaignStatuses,
                                checked: checked,
                                label: itemTitle
                            }
                        })}
                    />
                )
                break;
            case "date":
                body = renderDateFilter();
                break;
            case "budget":
                body = renderBudgetFilter();
                break;
            case "release":
                body = renderReleaseFilter();
                break;
            default:
                body = null;
                break;
        }

        return (
            <>
                {body}
                {renderApplyButton()}
            </>
        )
    }

    // when a user closes popover, and filters that weren't applied are reset
    const handleClosePopover = () => {
        setOpenPopoverIndex(null);
    };

    const handleClickFilterOption = (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
        setOpenPopoverIndex(openPopoverIndex === index ? null : index); // Toggle popover
    };

    // Shows the apply button in a popover
    const renderApplyButton = (loading?: boolean) => {
        return (
            <>     
                <GeneralPurpleButton
                    disabled={loading}
                    onClick={applyFilters}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                    {loading ? <Loaderv2 /> : 'Apply'}
                </GeneralPurpleButton>
                <TextButton onClick={removeFilter}>
                    Clear
                </TextButton>
            </>
        );
    }

    // close the popover when something is selected / changes
    useEffect(() => {
        updatedAppliedFilterObject(appliedFilterObject)
        if (openPopoverIndex !== null) {
            setOpenPopoverIndex(null);
        }
    }, [appliedFilterObject]);

    useEffect(() => {
        if (searchQuery) {
            handleSetFilterCampaignNames(searchQuery)
        }
    }, [searchQuery]);

    return (
        <Container display="flex" flexDirection="row" responsiveFlexDirection="column" gap="8px" margin="0 0 8px 0">
            <div>Filter by</div>
            <div style={{display: "flex", gap: "8px", flexWrap: "wrap"}}>
                {CAMPAIGN_FILTERS.map((filter, filterIndex) => {
                    const filterChipId = `${filter.name}-${filterIndex}`;
                    return (
                        <React.Fragment key={`key-${filterChipId}`}>
                            <Chip
                                id={filterChipId}
                                style={{
                                    backgroundColor: isFilterActive(filter.name) ? "rgba(136, 0, 255, 0.08)" : "#EBEBEB",
                                    color: isFilterActive(filter.name) ? "#8800FF" : "#777",
                                }}
                                label={getFilterResultString(filter)}
                                onClick={handleClickFilterOption(filterIndex)}
                            />
                            <Popover
                                id={filterChipId}
                                open={openPopoverIndex === filterIndex}
                                anchorEl={document.getElementById(filterChipId)}
                                onClose={handleClosePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Container
                                    className="popoverContents"
                                    display="flex"
                                    gap="8px"
                                    padding="16px"
                                    flexDirection="column"
                                >
                                    {renderPopoverContents(filter)}
                                </Container>
                            </Popover>
                        </React.Fragment>
                    )
                })}
            </div>
        </Container>
    )
}