import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  inputProps: {
    fontFamily: "DIN",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    border: "1px solid #8800FF",
    width: "100%",
    borderRadius: "6px",
    padding: theme.spacing(2),
  },
  buttonText: {
    fontFamily: "DIN",
    fontStyle: "normal",
    fontSize: "16px",
  },
  button: {
    textTransform: "none",
    background: "#8800FF",
    color: "#FFFFFF",
    borderRadius: "48px",
    padding: theme.spacing(1.5, 3),
    width: "100%",
    "&:hover": {
      background: "#8800FF",
    },
  },
  gridContainer: {
    height: "100%"
  }
}));

interface LeftSectionProps {
  width?: string;
}

interface RightSectionProps {
  display?: string;
}

export const FlexContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #F5F3F6;
`;

export const LeftContainer = styled.div`
  width: ${({ width }: LeftSectionProps) => width};
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 80px 6.25%;
  gap: 48px;
`;

export const RightContainer = styled.div`
  & { 
    width: 50%;
    height: 100%;
    display: ${({ display }: RightSectionProps) => display};
    justify-content: flex-end;
    align-items: center;
    overflow-x: hidden;
    position: relative;
    background: linear-gradient(290.62deg, #8800FF 0.13%, #D7B3ED 43.95%, #A1F0D4 74.68%);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: -100;
    width:100%;
    height:100%;
    background: linear-gradient(269.32deg, #8800FF 18.14%, #8800FF 33.94%, rgba(161, 240, 212, 0.22) 102.22%);  
  }
`;

export const SymphonyLogoContainer = styled.div`
  width: 65px;
  height: 16px;
  font-family: "DAGGERSQUARE";
  font-style: normal;
  font-weight: 400;
  font-size: 26.505px;
  text-align: center;
  color: #8800FF;
  opacity: 0.8;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const Title = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`;

export const Subtitle = styled.div`
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

export const EmailInputForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
`;

export const LottieContainer = styled.div`
  width: 660px;
  margin-left: 60px;
  background: rgba(255, 255, 255, 0.96);
  border-radius: 23px 0 0 23px;
`;
