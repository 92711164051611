import { Backdrop, Fade, Modal } from "@material-ui/core"
import AdPreviewBox from "components/shareable/FacebookAdPreview"
import { AssetUploadTypes, UploadedAssetType } from "../utils/fbCampaigns"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import useAdPreviewStyles from "components/shareable/FacebookAdPreview/styles"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"

interface Props {
  cta: {
    value: string
    label: string
  }
  showPreview: boolean
  selectedInstaPage: {
    instagramAccount?: {
      profile_pic: string
      username: string
    }
  }
  selectedFB_page: unknown
  creativeSelectorTab: AssetUploadTypes
  selectedInstagramPost?: InstagramPost
  caption: string
  previewedVideo: UploadedAssetType | null
  uploadedImages: UploadedAssetType[] | null
  canShowAdPreview: boolean,
  muted?: boolean
  enablePreviewMode: (value: boolean) => void
}

export function AdPreview({
  cta,
  selectedInstaPage,
  showPreview,
  selectedFB_page,
  creativeSelectorTab,
  selectedInstagramPost,
  caption,
  previewedVideo,
  uploadedImages,
  canShowAdPreview,
  enablePreviewMode,
  muted,
}: Props) {
  const { mediumView } = useMediaBreakpoints()
  const adPreviewStyles = useAdPreviewStyles()

  let previewModule = (shown: boolean, onClose?: () => void) => (
    <AdPreviewBox
      callToAction={cta.label}
      closePreview={onClose}
      shown={shown}
      facebookPage={selectedFB_page ? selectedFB_page : null}
      instagramAccount={
        selectedInstaPage ? selectedInstaPage.instagramAccount : undefined
      }
      captions={
        creativeSelectorTab === "instagram_posts"
          ? selectedInstagramPost
            ? [
                {
                  type: "all",
                  caption: selectedInstagramPost.caption,
                },
              ]
            : []
          : caption
          ? [
              {
                type: "all",
                caption: caption,
              },
            ]
          : []
      }
      previewedPost={
        previewedVideo && creativeSelectorTab !== "instagram_posts"
          ? {
              postType: "uploaded",
              caption: "test",
              media_url: previewedVideo.url,
              media_type: "VIDEO",
              thumbnail_url: previewedVideo.thumbnail,
              type: previewedVideo.formatType,
            }
          : null
      }
      selectedPosts={
        selectedInstagramPost && creativeSelectorTab === "instagram_posts"
          ? [
              {
                postType: "instagram",
                caption: selectedInstagramPost.caption,
                media_url: selectedInstagramPost.media_url,
                media_type: selectedInstagramPost.media_type,
                thumbnail_url: selectedInstagramPost.thumbnail_url,
                type:
                  selectedInstagramPost.media_product_type === "REELS"
                    ? "story"
                    : "feed",
              },
            ]
          : uploadedImages && uploadedImages.length > 0
          ? uploadedImages.map((uploadedVideo: UploadedAssetType) => {
              return {
                postType: "uploaded",
                caption: "test",
                media_url: uploadedVideo.url,
                media_type: "VIDEO",
                thumbnail_url: uploadedVideo.thumbnail,
                type: uploadedVideo.formatType,
              }
            })
          : null
      }
      muted={muted}
    />
  )

  if (mediumView) {
    return (
      <Modal
        aria-labelledby="connector-modal"
        aria-describedby="connector-modal for platforms"
        className={adPreviewStyles.previewAdModal}
        open={showPreview}
        onClose={() => enablePreviewMode(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
      >
        <Fade in={showPreview}>
          {previewModule(canShowAdPreview, () => enablePreviewMode(false))}
        </Fade>
      </Modal>
    )
  } else {
    return previewModule(canShowAdPreview)
  }
}
