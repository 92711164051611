import { CardDetails, CardOptionImage, CardOptionParent, CardOptionTitle, CardSubtitle } from "./styles"

export function CardOption(props: {
    image?: string,
    text: string,
    thumbnail?: string,
    subtitle?: string
    onClick?: () => void
}) {

    const {
        text,
        thumbnail,
        subtitle,
        onClick
    } = props

    return (
        <CardOptionParent onClick={onClick}>
            {thumbnail && <CardOptionImage src={thumbnail} />}

            <CardDetails>
                <CardOptionTitle>{text}</CardOptionTitle>
                {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
            </CardDetails>
        </CardOptionParent>
    )
}