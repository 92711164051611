import "./App.css";
import IndexLayout from './layouts/IndexLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { analyticsInitialize } from "./analytics";
import { LicenseInfo } from '@mui/x-license-pro';
import Hotjar from '@hotjar/browser'
import { useEffect } from "react";
import CurrentUserProvider from "Hooks/CurrentUserContext";

const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID)
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION)
const isProd = process.env.NODE_ENV === 'production'

analyticsInitialize()
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE as string);

const App = () => {
  useEffect(() => {
    if (isProd) Hotjar.init(hotjarId, hotjarVersion)
  }, [])
  
  return (
    <CurrentUserProvider>
        <IndexLayout />
        <ToastContainer />
    </CurrentUserProvider>
  );
};

export default App;
