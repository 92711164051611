/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "styles/rc-slider.css";
import GoalSelector from "./GoalSelector";
import SongAds from "./SongAds";
import GetPlaylisted from "./Playlisting";
import VideoViews from "./VideoAds";
import PreSave from "./PreSave";
import { pageView } from "analytics";
import MarketingDataProvider from "./Data/Provider";
import CreativeGenerator from "./CreativeGenerator";
import PreSaveProvider from "./Data/PreSaveProvider";
import NavbarProvider from "./Data/NavbarProvider";
import LinkClicks from "./LinkClicks";
import GrowInstagramEngagement from "./GrowInstagram";
import ConnectModalsProvider from "Hooks/ConnectModalsProvider"
import TiktokAds from "./TikTokAds";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { CampaignFilterType } from "./Components/CampaignTypesFilter/types";
import { CurrentBrand, UserData } from "types/global";
import { DEFAULT_CAMPAIGN_PARAMS } from "./constants";
import { getCampaignsForBrand } from "services/symphonyApi/brandService";

export default function MarketingFlow(props: {
  currentBrand: CurrentBrand;
  reloadBrands: any;
  setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>>
  loadedBrands: CurrentBrand[];
  user?: UserData;
}): JSX.Element {
  const { currentBrand, reloadBrands, user } = props;

  const [selectedGoal, setGoal] = useState<any>(null);
  // Active Campaigns
  const [currentCampaigns, setCurrentCampaigns] = useState<any>(null);

  // loadingCampaigns is a global loading state that will load the entire page
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);

  // locallyLoading is a local loading state that will load the current component
  const [locallyLoading, setLocallyLoading] = useState<boolean>(false);

  // specifically for load more button UIs
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  // pagination for campaigns
  const [hasMoreCampaigns, setHasMoreCampaigns] = useState<boolean>(true);

  const [campaignParams, setCampaignParams] = useState<CampaignFilterType>(DEFAULT_CAMPAIGN_PARAMS);
  const [firstRenderCampaigns, setFirstRenderCampaigns] = useState<number>(0);

  async function getCampaigns(filterObject: CampaignFilterType = DEFAULT_CAMPAIGN_PARAMS, localLoading?: boolean) {
    if (localLoading) {
      setLocallyLoading(true);
    }

    const campaigns = await getCampaignsForBrand(filterObject, currentBrand.id);
    setCurrentCampaigns(campaigns || []);
    setHasMoreCampaigns(campaigns?.length === filterObject.limit);

    setLoadingCampaigns(false);
    setLocallyLoading(false);
  }


  async function refreshCampaigns(params = DEFAULT_CAMPAIGN_PARAMS) {
    setLoadingCampaigns(true);
    clearState();

    const paramsWithOffset = { ...params, offset: 0 };
    const campaigns = await getCampaignsForBrand(paramsWithOffset, currentBrand.id);
    setCurrentCampaigns(campaigns);
    setFirstRenderCampaigns(campaigns?.length || 0)
    setHasMoreCampaigns(campaigns?.length === params.limit);

    setLoadingCampaigns(false);
  }


  function clearState() {
    setHasMoreCampaigns(true);
    setGoal(null);
  }


  const loadMoreCampaigns = async () => {
    if (!hasMoreCampaigns) return;

    const newParams = {
      ...campaignParams,
      offset: campaignParams.offset + campaignParams.limit
    };

    setLoadingMore(true);

    const newCampaigns = await getCampaignsForBrand(newParams, currentBrand.id);
    setCurrentCampaigns((currentCampaigns: any) => [...currentCampaigns, ...newCampaigns]);
    setCampaignParams(newParams);
    setHasMoreCampaigns(newCampaigns.length === campaignParams.limit);

    setLoadingMore(false);
  };


  function showGoalBasedFlow() {
    switch (selectedGoal) {
      case "increase_record_streams":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <SongAds
                subtype="increase_record_streams"
                currentUser={user!}
                setGoal={setGoal}
                currentBrand={currentBrand}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "grow_playlist_followers":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <SongAds
                subtype="grow_playlist_followers"
                currentUser={user!}
                setGoal={setGoal}
                currentBrand={currentBrand}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "increase_link_clicks":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <LinkClicks
                currentUser={user!}
                setGoal={setGoal}
                currentBrand={currentBrand}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "grow_instagram_engagement":
        return (
          <MarketingDataProvider>
            <ConnectModalsProvider>
              <GrowInstagramEngagement
                currentUser={user!}
                setGoal={setGoal}
                currentBrand={currentBrand}
                refreshCampaigns={refreshCampaigns}
                reloadBrands={reloadBrands}
              />
            </ConnectModalsProvider>
          </MarketingDataProvider>
        );
      case "get_playlisted":
        return (
          <NavbarProvider>
            <GetPlaylisted
              currentUser={user!}
              setGoal={setGoal}
              currentBrand={currentBrand}
              refreshCampaigns={refreshCampaigns}
              reloadBrands={reloadBrands}
            />
          </NavbarProvider>
        );

      case "increase_video_views":
        return (
          <VideoViews
            currentUser={user!}
            setGoal={setGoal}
            currentBrand={currentBrand}
            refreshCampaigns={refreshCampaigns}
            reloadBrands={reloadBrands}
          />
        );
      case "tiktok_ads":
        return (
          <TiktokAds
            setGoal={setGoal}
            currentBrand={currentBrand}
            refreshCampaigns={refreshCampaigns}
            reloadBrands={reloadBrands}
          />
        );
      case "creative_generator":
        return (
          <CreativeGenerator
            setGoal={setGoal}
            currentBrand={currentBrand}
            refreshCampaigns={refreshCampaigns}
            reloadBrands={reloadBrands}
          />
        );
      case "pre_save":
        return (
          <PreSaveProvider>
            <PreSave
              currentBrand={currentBrand}
              setGoal={setGoal}
            />
          </PreSaveProvider>
        );
      default:
        return <h1>Goal not supported...</h1>;
    }
  }


  // Mixpanel pageview
  useEffect(() => {
    pageView("Marketing");
  }, []);


  useEffect(() => {
    refreshCampaigns(DEFAULT_CAMPAIGN_PARAMS);
  }, [currentBrand]);


  if (loadingCampaigns) return (
    <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
      <NewLoader black />
      <p className="mt-1 text-center">Loading Campaigns...</p>
    </div>
  )


  return !selectedGoal ? (
    <GoalSelector
      returnedAllCampaigns={!hasMoreCampaigns}
      loadMoreCampaigns={loadMoreCampaigns}
      loadingMore={loadingMore}
      currentCampaigns={currentCampaigns}
      loadingCampaigns={loadingCampaigns}
      currentUser={user}
      setGoal={setGoal}
      isSpotifyConnected={Boolean(currentBrand?.connections?.spotify_artist_page)}
      getCampaigns={getCampaigns}
      setCampaignParams={setCampaignParams}
      loadingLocally={locallyLoading}
      quantityCampaigns={firstRenderCampaigns}
    />
  ) : (
    showGoalBasedFlow()
  );
}
