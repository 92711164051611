import { ReactComponent as QuestionMarkIcon } from 'assets/images/question-mark.svg'
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg'
import { SymphonyTooltip } from 'styles/shared'
import Intercom from 'helpers/Intercom'
import { Body2, ButtonText } from 'components/shareable/Typography'
import { AdAccountInvalidCtn, ExternalLink, useStyles } from './styles'

interface AccountDisabledBannerProps {
  bannerMessage: string
  linkText: string
  reviewAccountLink: string
}

export function AccountDisabledBanner({
  bannerMessage,
  linkText,
  reviewAccountLink,
}: AccountDisabledBannerProps) {
  const { iconBackgroundSecondaryColor } = useStyles()

  return (
    <AdAccountInvalidCtn>
      <div>
        <Body2>{bannerMessage}</Body2>
        <ExternalLink href={reviewAccountLink} target="_blank" rel="noreferrer">
          <ButtonText>{linkText}</ButtonText>
          <ExternalLinkIcon />
        </ExternalLink>
      </div>
      <div>
        <SymphonyTooltip
          className="block w-6 h-6 cursor-pointer"
          title="See more"
          arrow
          onClick={Intercom.openFacebookAdAccountInactiveArticle}
        >
          <QuestionMarkIcon className={iconBackgroundSecondaryColor} />
        </SymphonyTooltip>
      </div>
    </AdAccountInvalidCtn>
  )
}