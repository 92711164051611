import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { getOrganizationBrands, getOrganizationMembers } from 'services/symphonyApi/organizationService';
import { Artist, TeamMember } from 'types/global';

interface Context {
  teamMembers: TeamMember[],
  teamArtists: Artist[],
  setTeamMembers: Dispatch<SetStateAction<TeamMember[]>>,
  setTeamArtists: Dispatch<SetStateAction<Artist[]>>,
  getTeamMembers: () => Promise<void>,
}

export const TeamPlanContext = createContext<Context>({
  teamMembers: [],
  teamArtists: [],
  setTeamMembers: () => { },
  setTeamArtists: () => { },
  getTeamMembers: async () => { },
});

interface Props {
  children: ReactNode[] | ReactNode;
}

export const TeamPlanProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [teamArtists, setTeamArtists] = useState<Artist[]>([]);

  const getTeamMembers = async () => {
    const members = await getOrganizationMembers();
    if (members) setTeamMembers(members);
  }

  const getTeamArtists = async () => {
    const artists = await getOrganizationBrands();
    setTeamArtists(artists || []);
  }

  useEffect(() => {
    getTeamMembers()
    getTeamArtists()
  }, []);

  const context = {
    teamMembers,
    teamArtists,
    setTeamMembers,
    setTeamArtists,
    getTeamMembers,
  };

  return (
    <TeamPlanContext.Provider value={context}>
      {children}
    </TeamPlanContext.Provider>
  )
};
