import { Switch } from "@mui/material";
import { MAX_ADDITIONAL_USERS_WITH_TEAM_PRO } from "../../../../constants";
import React from "react";
import { Container, StyledLabel } from "styles/shared";


export default function SelectableArtist(props: {
    name: string;
    selected: boolean;
    image: string;
    isAlreadyPro: boolean;
    setSelected: (selected: boolean) => void;
    seatCount: number;
    overageSeat: boolean;
}) {

    const {
        name, selected, image, isAlreadyPro, seatCount, overageSeat
    } = props




    const styles: Record<string, React.CSSProperties> = {
        image: {
            width: '60px',
            height: '60px',
            borderRadius: '99%',
            marginRight: '16px'
        },

    }

    function showHelperText() {
        if (isAlreadyPro) {
            if (selected) {
                return "This profile will remain on Pro."
            } else {
                return "This profile will be downgraded to the Free tier."
            }
        } else if (selected) {
            return "This profile will be upgraded to Pro."
        } else {
            return "This profile is on the Free tier."
        }
    }

    function renderSeatStatus() {
        if (selected) {
            if (overageSeat) {
                return (
                    <StyledLabel color="#FF0000">
                        ${15} / month
                    </StyledLabel>
                )
            } else {
                return (
                    <StyledLabel>
                        {seatCount} / {MAX_ADDITIONAL_USERS_WITH_TEAM_PRO}
                    </StyledLabel>
                )
            }
        }

        return null
    }
    return (
        <Container borderBottom="1px solid #E5E7EB" padding="12px" display="flex"
            flexDirection="row"
            justifyContent="space-between"
            backgroundColor={selected ? `#f7eeff` : '#fff'}
            style={{
                transition: `all 0.2s ease`
            }}>
            <Container display="flex" flexDirection="row" gap="4px"
                alignItems="center"
                justifyContent="center">
                <img style={styles.image} src={image}></img>
                <Container display="flex" flexDirection="column" gap="6px">
                    <StyledLabel fontSize={16}>{name}</StyledLabel>
                    <StyledLabel>{showHelperText()}</StyledLabel>
                </Container>
            </Container>
            <Container display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Container display="flex" flexDirection="column">
                    <StyledLabel>{isAlreadyPro ? 'Pro Profile' : 'Upgrade to Pro'}</StyledLabel>
                    {renderSeatStatus()}
                </Container>
                <Switch
                    disabled={isAlreadyPro}
                    checked={selected}
                    onChange={(e) => props.setSelected(e.target.checked)}
                />
            </Container>
        </Container>
    )
}