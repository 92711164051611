import { FunctionComponent } from "react";

interface Props {
    message: string;
}

const CircularLoader: FunctionComponent<Props> = ({ message }: Props) => (
    <div className="sy-card">
        <div className="flex px-6 lg:px-10">
            <div className=" flex-none loading-blk">
                <div className="svg-loader">
                    <svg
                        className="svg-container"
                        height="36"
                        width="36"
                        viewBox="0 0 100 100"
                    >
                        <circle className="loader-svg bg" cx="50" cy="50" r="45" />
                        <circle
                            className="loader-svg animate"
                            cx="50"
                            cy="50"
                            r="45"
                        />
                    </svg>
                </div>
            </div>
            <div className="px-3 items-center flex">
                <h5 className="text-dark text-left">{message}</h5>
            </div>
        </div>
    </div>
);

export default CircularLoader;