import styled from 'styled-components'

interface BadgeTextProps {
    color?: string;
}
export const BadgeText = styled.span`
    font-family: 'DIN';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: ${({ color }: BadgeTextProps) => color || "#000000"};
  `;