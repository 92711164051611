import { makeStyles, Theme } from '@material-ui/core';
import { height } from '@mui/system';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    width: '100%',
    margin: 0,
    gap: '16px',
  },
  dialog: {
    '& .MuiPaper-root': {
      width: '100%',
      height: '350px',
    },
  },
}));

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0px;
  width: 100%;
`;

export const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #8800ff;
  border-radius: 48px;
  cursor: pointer;
`;

export const ButtonLabel = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

export default useStyles;
