import { Fragment, FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'
import { CampaignDetails } from 'types/global'
import Card from './Card'
import { IconContainer, MainContainer, Severity, TextContainer, useStyles } from './styles'
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'
import { ReactComponent as WarningIcon } from 'assets/images/campaignDetails/warning.svg';
import { SystemColors } from 'types/globalStyles'
import { Body1 } from 'components/shareable/Typography'
import SecondaryButton from 'components/shareable/SecondaryButton'
import Modal from './Modal'
import useModal from 'Hooks/useModal'

interface Props {
  campaigns: CampaignDetails[];
}

const WarningNoReleaseUpc: FunctionComponent<Props> = ({
  campaigns = [],
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { mobileView } = useMediaBreakpoints();
  const { open, openModal, closeModal } = useModal()

  const handleClickAdd = (campaign: CampaignDetails) => () => {
    history.push(`/marketing/campaigns/${campaign.id}`)
  }

  const presavesWithoutReleaseUpc = campaigns.filter(({ campaign_metadata, status }: CampaignDetails) => {
    if (!campaign_metadata) return false;
    if (status !== 'ACTIVE' && status !== 'WITH_ISSUES') return false;

    const { campaign_type } = campaign_metadata;
    if (campaign_type !== "pre_save") return false;

    return !Boolean(campaign_metadata.presave?.release_url_upc_isrc);
  })

  if (presavesWithoutReleaseUpc.length === 0) return <Fragment />;

  if (presavesWithoutReleaseUpc.length === 1) {
    const campaign = presavesWithoutReleaseUpc[0];
    return (
      <Card
        songName={campaign.campaign_metadata?.content.name as string}
        releaseDate={campaign.campaign_metadata?.endDate as string}
        handleClickAdd={handleClickAdd(campaign)}
        key={'warning-presave-' + campaign.id}
      />
    )
  }

  return (
    <MainContainer
      severity={Severity.ERROR}
      borderRadius={mobileView ? '0px' : '8px'}
      flexDirection={mobileView ? 'column' : 'row'}
      alignItems={mobileView ? 'flex-end' : 'center'}
      gap={mobileView ? '12px' : '24px'}
    >
      <TextContainer
        gap={mobileView ? '16px' : '8px'}
      >
        <IconContainer>
          <WarningIcon
            width="24px"
            height="24px"
            className={classes.svgError}
          />
        </IconContainer>
        <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>
          You have {String(presavesWithoutReleaseUpc.length)} campaigns without UPC / ISRC.
          Please review your campaigns to ensure your fans can presave successfully.
        </Body1>
      </TextContainer>
      <SecondaryButton
        onClick={openModal}
        text="Review Campaigns"
      />
      <Modal
        open={open}
        closeModal={closeModal}
        campaigns={presavesWithoutReleaseUpc}
      />
    </MainContainer>
  );
}

export default WarningNoReleaseUpc;