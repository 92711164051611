import { track } from 'analytics'
import { useState } from 'react'

export interface UseModalData{
  open: boolean
  closeModal: () => void
  openModal: (source?: string) => void
}

interface Props {
  opened?: boolean
  nameTrack?: string
  metadata?: Record<string, unknown>
}

const useModal = ({ opened, nameTrack, metadata }: Props = {}) => {
  const [open, setOpen] = useState<boolean>(opened ?? false)
  const closeModal = () => setOpen(false)
  const openModal = (source?: string) => {
    if (nameTrack && source) {
      track(nameTrack, { ...metadata, source })
    } else if (nameTrack) {
      track(nameTrack, { ...metadata })
    }

    setOpen(true)
  }

  return {
    open,
    closeModal,
    openModal,
  }
}

export default useModal
