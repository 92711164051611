import React from 'react';
import { IconHolder, SocialIconProps } from './styles';
import { ReactComponent as YandexImage } from 'assets/images/platforms/yandex.svg';

export const YandexIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <YandexImage width={30} height={30}/>
        </IconHolder>
    );
};
