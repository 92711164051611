import { makeStyles } from "@material-ui/core"
import styled from "styled-components"

const useStyles = makeStyles(() => ({
  logo: {
    transform: "scale(0.14) translate(-300%, -300%)",
    "& path": {
      stroke: "#560082",
      fill: "#560082",
    },
  },
}))

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  gap: 64px;
  width: 100%;
  height: 100%;
`

export const LeftColumn = styled.div`
  width: 50%;
`

export const RightColumn = styled.div`
  width: 50%;
`

export const FlexContainer = styled.div``

export default useStyles
