import Axios from "helpers/Interceptor";
import * as Sentry from '@sentry/browser';

export const downloadCollectedDataCSV = async (brandId: string, contentId: number) => {
    try {
        Sentry.setTag('flow', 'getPixels');
        Sentry.setContext('input', { brandId, contentId });
        const response = await Axios.post(`/website/${contentId}/csv/collected-data`);
        return response.data.data;
    } catch (error) {
        Sentry.captureException(error);
        return null;
    }
};