import styled from "styled-components";

interface ContainerProps {
  width?: string;
  height?: string;
  gap?: string;
  display?: string;
  flexDirection?: string;
  alignItems?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderRadius?: string;
  justifyContent?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontFamily?: string;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
  marginLeft?: string;
}

export const Container = styled.div`
  width: ${({ width }: ContainerProps) => width};
  display: ${({ display }: ContainerProps) => display || "flex"};
  gap: ${({ gap }: ContainerProps) => gap};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection || "row"};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-radius: ${({ borderRadius }: ContainerProps) => borderRadius};
  height: ${({ height }: ContainerProps) => height};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
`;

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-family: ${({ fontFamily }: StyledLabelProps) => fontFamily || "DIN"};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight || "32px"};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
`;


