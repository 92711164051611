import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";
import { SystemColors } from "types/globalStyles";

const useAdPreviewStyles = makeStyles((theme: Theme) => ({
    previewAdButton: {
        display: "none",
        margin: "auto",
        background: 'white',
        padding: "10px 15px",
        width: "100%",
        border: "none",
        color: "#80f",
        fontWeight: 500,
        position: 'relative',
        zIndex: 1,

        [theme.breakpoints.up(768)]: {
          padding: "10px 30px",
        },
    
        [theme.breakpoints.up(1200)]: {
          display: 'none'
        }
      },

      shownPreviewAdButton: {
        display: "flex",

        [theme.breakpoints.up(768)]: {
          'justifyContent': 'center'
        },
        [theme.breakpoints.up(1200)]: {
          display: 'none'
        }
      },
    
      shownAdButton: {
        display: "block",
        [theme.breakpoints.up(1200)]: {
          display: 'none'
        }
      },
    
      previewAdModal: {
        display: "none",
        [theme.breakpoints.down(1200)]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }
      },
      previewAdContainer: {
        display: "none",
        width: "90%",
        height: "auto",
    
        top: 0,
        margin: "auto",
        [theme.breakpoints.down(1200)]: {
          display: 'block'
        }
      },
      circleGray: {
        width: '18px',
        height: '18px',
        viewBox: '0',
        overflow: 'visible',
        cursor: 'pointer',
        '& circle': {
          fill: SystemColors.SECONDARY_TEXT_COLOR,
        },
      },
}));

interface ContainerProps {
  flexDirection?: string
  gap?: string
  padding?: string
  justifyContent?: string
  alignItems?: string
}

interface VideoButtonProps {
  color?: string
  background?: string
  height?: string
  borderRadius?: string
  aspectRatio?: string
}

interface PostContainerProps {
  borderRadius?: string
  position?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  border-radius: 10px;
  background: white;
  gap: ${({ gap }: ContainerProps) => gap};
  padding: ${({ padding }: ContainerProps) => padding}; 
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent}; 
  align-items: ${({ alignItems }: ContainerProps) => alignItems}; 
`;

export const ProfilePictureContainer = styled.img`
  border-radius: 50%;
  max-height: 20px;
  min-height: 20px;
`;

export const Icon = styled.img`
  max-height: 12px;
  min-height: 12px;
`;

export const UsernameLabel = styled.p`
  font-family: Poppins;
  font-weight: 700;
  font-size: 6px;
  line-height: 9px;
  height: fit-content;
`;

export const FeedImage = styled.img`
  max-width: 170px;
  max-height: 170px;
`;

export const VideoButton = styled.button`
  position: absolute;
  border-radius: ${({ borderRadius }: VideoButtonProps) => borderRadius};
  width: 100%;
  height: ${({ height }: VideoButtonProps) => height};
  background: ${({ background }: VideoButtonProps) => background};
  color: ${({ color }: VideoButtonProps) => color};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: ${({ aspectRatio }: VideoButtonProps) => aspectRatio};
  transition: all .5s;
  :hover {
    background-color: #00000080;
    color: white;
    transition: all .5s;
  }
`;

export const PostContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #000000;
  overflow: hidden;
  border-radius: ${({ borderRadius }: PostContainerProps) => borderRadius || '0px'};
  position: ${({ position }: PostContainerProps) => position};
`;

export const VideoAsImageWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 10px;
  background: ${SystemColors.PAPER_LIGHT_CONTAINER};
  width: 100%;
  border-radius: ${({ borderRadius }: PostContainerProps) => borderRadius || '0px'};;
  border-top: 1px solid rgba(229, 231, 235);
`;

export default useAdPreviewStyles;
