import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ArtistThumbnailProps {
  thumbnails: string[];
}

export const ArtistThumbnailsSection: React.FC<ArtistThumbnailProps> = ({ thumbnails }) => {
  const thumbnailsToShow = thumbnails.length > 5 ? thumbnails.slice(0, 5) : thumbnails;
  const excessCount = thumbnails.length > 5 ? thumbnails.length - 5 : 0;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AvatarGroup max={5}>
        {thumbnailsToShow.map((src, index) => (
          <Avatar key={index} src={src} sx={{ width: 24, height: 24 }} />
        ))}
      </AvatarGroup>
      {excessCount > 0 && (
        <Typography sx={{ fontWeight: 'bold' }}>
          +{excessCount}
        </Typography>
      )}
    </Box>
  );
};