import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    height: "80%",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    borderRadius: "12px",
    [theme.breakpoints.up(1023)]: {
      width: "91.666667%",
    },
    [theme.breakpoints.up(1279)]: {
      width: "83.333333%",
    },
    [theme.breakpoints.down(1921)]: {
      maxWidth: 800,
    },
  },
}))

export default useStyles
