import { useState, createContext, createElement as el, ReactNode, FunctionComponent } from "react"
import { GeographicTargetingType } from "../SongAds/TargetingView/reducer"
import { FbSuggestion as TargetingAudience, DemographicsTargetingType, FBCustomAudience, FacebookPageResponse } from "types/global"
import { Option as Artist } from "pages/post-auth/MarketingPage/SongAds/TargetingView/Components/AutoCompleteSpotifyArtists/utils";

interface Context {
    audiences: TargetingAudience[]
    targets: GeographicTargetingType[]
    artists: Artist[]
    demographics: DemographicsTargetingType
    saveTargets: (targets: GeographicTargetingType[]) => void
    saveAudiences: (audiences: TargetingAudience[]) => void
    saveCustomAudiences: (audiences: FBCustomAudience[]) => void
    saveAdditionalPageAudiences:(audiences: FacebookPageResponse[]) => void

    saveArtists: (artists: Artist[]) => void
    saveDemographics: (demographics: DemographicsTargetingType) => void
}

export const MarketingDataContext = createContext<Context>({
    targets: [],
    audiences: [],
    artists: [],
    demographics: {} as DemographicsTargetingType,
    saveAudiences: () => { },
    saveCustomAudiences: () => { },
    saveAdditionalPageAudiences: () => { },
    saveTargets: () => { },
    saveArtists: () => { },
    saveDemographics: () => { },
})

type Props = {
    children: ReactNode[] | ReactNode
}

const MarketingDataProvider: FunctionComponent<Props> = ({ children }) => {
    const [targets, saveTargets] = useState<GeographicTargetingType[]>([])
    const [audiences, saveAudiences] = useState<TargetingAudience[]>([])
    const [customAudiences, saveCustomAudiences] = useState<FBCustomAudience[]>([])
    const [additionalPageAudiences, saveAdditionalPageAudiences] = useState<FacebookPageResponse[]>([])

    const [artists, saveArtists] = useState<Artist[]>([])
    const [demographics, saveDemographics] = useState<DemographicsTargetingType>({} as DemographicsTargetingType)

    const onSaveAudiences = (audiences: TargetingAudience[]) => saveAudiences(audiences)
    const onSaveCustomAudiences = (audiences: FBCustomAudience[]) => saveCustomAudiences(audiences)
    const onSaveAdditionalPageAudiences = (audiences: FacebookPageResponse[]) => saveAdditionalPageAudiences(audiences)

    const onSaveTargets = (targets: GeographicTargetingType[]) => saveTargets(targets)
    const onSaveArtists = (artists: Artist[]) => saveArtists(artists)
    const onSaveDemographics = (demographics: DemographicsTargetingType) => saveDemographics(demographics)

    const context = {
        targets,
        audiences,
        artists,
        demographics,
        saveAudiences: onSaveAudiences,
        saveCustomAudiences: onSaveCustomAudiences,
        saveAdditionalPageAudiences: onSaveAdditionalPageAudiences,
        saveTargets: onSaveTargets,
        saveArtists: onSaveArtists,
        saveDemographics: onSaveDemographics,
    }

    return el(MarketingDataContext.Provider, { value: context }, children)
}

export default MarketingDataProvider

