import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  runningFor: {
    paddingTop: theme.spacing(0.5),
    borderBottomWidth: 1,
    paddingBottom: theme.spacing(2),
  },
  dailySpend: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
  },
}));

export const Container = styled.div`
  text-align: center;
  width: 100%;
`;

export const ReviewCampaignButton = styled.button`
  margin-top: 16px;
  margin-bottom: 80px;
  width: 100%;
  background-color: rgba(136, 0, 255, 1);
  color: #ffffff;
  padding: 8px;
  font-size: 24px;
  border-radius: 20px;
  @media (min-width: 1024px) {
    margin-bottom: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export default useStyles;
