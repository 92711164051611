import { FunctionComponent, useContext } from "react"
import {
  Body2,
  ButtonText,
  Headline1,
  Headline2,
  Subtitle2,
} from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import Counter from "../../Components/Counter"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import ToggleButtons from "../../Components/ToggleButtons"
import {
  Divider,
  FlexContainer,
  GrayContainer,
  StyledContainer,
} from "./styles"
import { MONTHLY_PRO_PLAN_PRICE, YEARLY_BY_MONTH_PRO_PLAN_PRICE } from "../../../../../constants";

const SetupPlan: FunctionComponent = () => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const brandName = currentBrand?.name

  return (
    <FlexContainer flexDirection="column">
      <ToggleButtons />
      <FlexContainer marginTop="16px" justifyContent="space-between">
        <StyledContainer>
          <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>Total</Headline2>
        </StyledContainer>
        <StyledContainer>
          <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>{YEARLY_BY_MONTH_PRO_PLAN_PRICE}</Headline1>
          <Subtitle2 color={SystemColors.SECONDARY_TEXT_COLOR}>
            /month
          </Subtitle2>
        </StyledContainer>
      </FlexContainer>
      <FlexContainer margin="16px 0px">
        <Divider />
      </FlexContainer>
      <FlexContainer justifyContent="space-between">
        <Body2>Subscription - Pro Artist</Body2>
        {/* Change for the real calculation */}
        <ButtonText color={SystemColors.PRIMARY_TEXT_COLOR}>{MONTHLY_PRO_PLAN_PRICE}</ButtonText>
      </FlexContainer>
      <FlexContainer justifyContent="space-between" marginTop="16px">
        <Body2>Additional users</Body2>
        {/* Change for the real calculation */}
        <ButtonText color={SystemColors.PRIMARY_TEXT_COLOR}>$0.00</ButtonText>
      </FlexContainer>
      <FlexContainer marginTop="16px">
        <GrayContainer>
          <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Add additional users
          </Body2>
          <Counter
            onSubtract={() => {
              //TODO: Handle logic to subtract
              console.log("subtract")
            }}
            onAdd={() => {
              //TODO: Handle logic to add
              console.log("add")
            }}
          />
        </GrayContainer>
      </FlexContainer>
      <FlexContainer marginTop="16px">
        <Body2>
          Your plan already includes 2 users who can access the {brandName}{" "}
          profile. You can add more for $5 / month.
        </Body2>
      </FlexContainer>
    </FlexContainer>
  )
}

export default SetupPlan
