import { FunctionComponent } from "react";
import { BoostDetailContainer, BoostDetailCount, BoostDetailDescription, BoostSubtitle, BoostTitleContainer } from "./styles";
import ArticleTitle, { Article } from 'components/shareable/ArticleTitle';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import SwitchButton from 'components/shareable/SwitchButton';
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { Container, StyledLabel } from "styles/shared";

interface BoostDetailProps {
  title: string
  subtitle: string
  actualDataDescription: string
  icon: React.ReactNode
  checked: boolean
  disabled: boolean
  isProUser: boolean
  requiresPro?: boolean
  onToggle: (setValue: boolean) => void
}

const getCardTitle = (title: string, requiresPro?: boolean) => {
  const titleElement = <StyledLabel fontWeight={600} fontSize={18}>{title}</StyledLabel>
  if (title !== 'Forever Saves')
    return titleElement
  return (
    <Container display="flex" flexDirection="row" alignItems="center" justifyContent="center"
    style={{
      fontWeight: 600
    }}>
      <ArticleTitle
        children={titleElement}
        article={Article.ForeverSaves}
        toolTipText='Click to learn more about how Forever Saves work.'
      />
      {
        requiresPro &&
        <div className="ml-2">
          <UpgradeToProChip
            type="gem"
            color="purple"
            height={18}
            width={18} />
        </div>
      }
    </Container>
  )
}

const BoostDetail: FunctionComponent<BoostDetailProps> = ({
  title,
  subtitle,
  actualDataDescription,
  icon,
  checked,
  disabled,
  isProUser,
  onToggle,
  requiresPro
}: BoostDetailProps) => {
  const { mobileView } = useMediaBreakpoints()

  return (
    <BoostDetailContainer>
      <BoostDetailDescription>
        <BoostTitleContainer>
          {icon}
          {getCardTitle(title, requiresPro)}
        </BoostTitleContainer>
        <BoostSubtitle>{subtitle}</BoostSubtitle>
        <BoostDetailCount>{actualDataDescription}</BoostDetailCount>
      </BoostDetailDescription>
      <SwitchButton
        checked={checked}
        disabled={disabled}
        onChange={onToggle}
      />
    </BoostDetailContainer>
  )
}

export default BoostDetail