import _ from "lodash";
import { ADD_LOCATION, ADD_TARGET, EDIT_TARGET, REMOVE_TARGET, UPDATE_SEARCH_TEXT, SET_LOCATIONS_RESULT, SET_LOADING_LOCATIONS, SET_TARGET_NAME, SAVE_TARGET_DATA, RESET_DEFAULTS, touringDefaultLocations, globalDefaultLocations } from "./data"

type Action =
    | { type: typeof ADD_TARGET, target: GeographicTargetingType }
    | { type: typeof REMOVE_TARGET, index: number }
    | { type: typeof EDIT_TARGET, index: number }
    | { type: typeof UPDATE_SEARCH_TEXT, searchText: string }
    | { type: typeof SET_LOCATIONS_RESULT, searchResults: FBLocation[] }
    | { type: typeof SET_LOADING_LOCATIONS, isFetchingLocations: boolean }
    | { type: typeof SET_TARGET_NAME, item: number, name: string }
    | { type: typeof SAVE_TARGET_DATA, item: number, target: GeographicTargetingType }
    | { type: typeof ADD_LOCATION, item: number, location: Location }
    | { type: typeof RESET_DEFAULTS };


export type FBLocation = {
    key: string
    name: string
    type: string
    country_code: string
    country_name: string
    region: string
    region_id: number
    supports_city: boolean
}

export type LocationsResponse = {
    data: FBLocation[]
}

export type Location = {
    value: string
    label: string
    type: string
    country_code: string | null
    region_id: string | null
}

export type GeographicTargetingType = {
    id?: string
    name: string
    budget: number
    description?: string
    locations: { [key: string]: Location }
}

type State = {
    targets: GeographicTargetingType[]
    selectedItem: number
    searchText: string
    isFetchingLocations: boolean
    searchResults: FBLocation[]
}

type Reducer<S, A> = (prevState: S, action: A) => S;

const reducer: Reducer<State, Action> = (state, action) => {
    switch (action.type) {
        case ADD_TARGET: {
            const updatedTargets = [...state.targets, action.target]
            return { ...state, targets: updatedTargets, selectedItem: updatedTargets.length - 1 }
        }
        case SAVE_TARGET_DATA: {
            const { target } = action
            const targets = state.targets
            if (target.budget === 1 && targets.length < 3) {
                targets.forEach((item, index) => {
                    if (index !== action.item) {
                        _.set(targets, `[${index}].budget`, 0)
                    }
                })
            } else if (targets.length === 2) {
                const other = action.item === 0 ? 1 : 0
                _.set(targets, `[${other}].budget`, 1 - action.target.budget)
            }
            _.set(targets, `[${action.item}]`, action.target)
            return { ...state, targets, selectedItem: -1 }
        }
        case UPDATE_SEARCH_TEXT:
            return { ...state, searchText: action.searchText }
        case SET_LOCATIONS_RESULT:
            return { ...state, searchResults: action.searchResults, isFetchingLocations: false }
        case SET_LOADING_LOCATIONS:
            return { ...state, isFetchingLocations: action.isFetchingLocations }
        case REMOVE_TARGET:
            const updatedTargets = [...state.targets]
            const newTargets = _.remove(updatedTargets, (_item, index) => index !== action.index)            
            const targets = newTargets.length === 1 ? newTargets.map(o => ({ ...o, budget: 1 })) : newTargets
                        
            return { ...state, targets }
        case EDIT_TARGET:
            return { ...state, selectedItem: action.index, searchResults: [] }
        //FIXME: Cannot use initialState it was updating everytime on save
        case RESET_DEFAULTS: 
            const touringLocations = _.keyBy(touringDefaultLocations.map(o => ({ ...o, type: "country", region_id: null, country_code: null })), 'value')
            const globalLocations = _.keyBy(globalDefaultLocations.map(o => ({ ...o, type: "country", region_id: null, country_code: null })), 'value')

            const defaults = [
                {
                    id: 'touring-markets',
                    description: "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
                    name: "Touring Markets",
                    budget: 0.7,
                    locations: touringLocations
                },
                {
                    id: 'global-markets',
                    description: "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive your algorithmic streams.",
                    name: "Global Markets",
                    budget: 0.3,
                    locations: globalLocations
                },
            ];
            return { targets: defaults, selectedItem: -1, searchText: '', searchResults: [], isFetchingLocations: false }
        default:
            return state
    }
}

export default reducer