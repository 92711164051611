import { SystemColors } from 'types/globalStyles';
import styled from 'styled-components';

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid ${SystemColors.DIVIDER_LINE};
`;

export const StyledInput = styled.input`
  width: 100%;
  outline: none;
  ::placeholder {
    color: ${SystemColors.SECONDARY_TEXT_COLOR};
  }
  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    cursor: pointer;
  }
`;
