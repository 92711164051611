import { FunctionComponent } from "react";
import Grid from "@material-ui/core/Grid";
import { StyledLabel } from "../../style";

type GlobalWindow = Window & {
  Intercom: (type: string, article?: number) => void;
};

const NotFoundArtistLabel: FunctionComponent = () => {
  const handleClickLearnHow = () => {
    (window as unknown as GlobalWindow).Intercom("showArticle", 5678366);
  };

  return (
    <Grid>
      <p>
        <StyledLabel fontSize={16}>
          Can't find your profile? Try pasting in your Spotify profile URL.
        </StyledLabel>
      </p>
      <p className="text-center">
        <StyledLabel
          className="cursor-pointer"
          fontSize={16}
          fontWeight={500}
          onClick={handleClickLearnHow}
          color="#8800ff"
        >
          Learn how
        </StyledLabel>
      </p>
    </Grid>
  );
};

export default NotFoundArtistLabel;
