/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { useEffect, useState, Dispatch, SetStateAction, useMemo, Fragment } from "react";
import { LoadingIndicator } from "components/Loader";
import Axios from "helpers/Interceptor";
import GeneralPerformanceStats from "../CampaignDetails/GeneralPerformanceNoStreaming";
import DailyStats from "../CampaignDetails/DailyStats";
import DemographicsStats from "../CampaignDetails/DemographicsStats";
import ImpactStats from "../CampaignDetails/ImpactStats/index";
import { daysCalculator, daysLeftCalculator } from "helpers/General";

import { track } from "analytics";
import { country_continents } from "helpers/Countries";
import { queryFB, queryFbAdCampaignInsights } from "helpers/FB";

import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import UnlockPresavesByDayImage from "assets/images/unlock-presaves-by-day.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditBudgetModal from '../Components/Modals/EditBudgetModal';
import OverviewCampaignCard from "../Components/OverviewCampaignCard";
import EditEndDateModal from '../Components/Modals/EditEndDateModal';
import PauseCampaignModal from '../Components/PauseCampaignModal';
import DuplicateCampaignModal from '../Components/DuplicateCampaignModal';
import DuplicateAnotherLinkClicks from 'assets/images/campaignDetails/inkyLaptop.svg';
import ErrorUpdatingCampaignModal from '../Components/ErrorUpdatingCampaignModal';
import { AdCampaignStatus, OverallCampaignStatusType, OverallCampaignStatus, CurrentBrand, CurrencyCodesType } from "types/global";
import { DEFAULT_CURRENCY, MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS } from "../constants";
import { CampaignDetailsContainer } from "../styles";
import { SelectedFbAdAccount, getAdAccountDetails, isLoggedInFbUser, showAdsStatusLabel } from '../utils/fbCampaigns';
import FbTokenExpired from '../Components/FbTokenExpired';
import AdsStatusMessage from '../Components/AdsStatusMessage';
import { FacebookAdAccountInfo } from "../Components/FacebookCampaignIssuesBanner";


// export const initialFollowersData = {
//   data: {
//     platform: "",
//     stat: "",
//     chartData: [] as FollowersChartData[],
//     growthData: {
//       currentGrowthValue: "",
//       difference: 0,
//       percentage: 0,
//     } as GrowthData,
//   },
//   isError: false,
//   message: null,
// } as FollowersData;
const {
    ACTIVE,
    DEFAULT_OVERALL_CAMPAIGN_STATUS,
    DENIED,
    ERROR,
    IN_REVIEW,
    IN_REVIEW_AFTER_ACTIVE,
    LIVE_WITH_ISSUES,
    LIVE_WITH_NO_STATS,
    PAUSED,
    PENDING,
    TO_RETRY,
} = OverallCampaignStatus;

const defaultOverallCampaignStatus = {
    status: DEFAULT_OVERALL_CAMPAIGN_STATUS,
    note: null,
} as OverallCampaignStatusType;

const getSelectedCurrentBrandData = async (
    setSelectedCurrentBrand: Dispatch<SetStateAction<any>>
) => {
    try {
        const keyLocalStorage = "selected-brand";
        const response = await Axios.get("/brand");
        const data = response.data.data;
        const cached = localStorage.getItem(keyLocalStorage);
        let saved = null;

        if (cached) {
            saved = data.find(
                (item: { id: number }) => item?.id === parseInt(cached)
            );
        }

        if (!saved) {
            [saved] = data;
            localStorage.setItem(keyLocalStorage, saved?.id);
        }

        setSelectedCurrentBrand(saved);
    } catch (error) {
        //TODO: Add error handler
        console.error("Error on component IncreaseSales/CampaignDetails", error);
    }
};

export default function CampaignDetails(props: any) {
    const theme = useTheme()
    const hideBannerImage = useMediaQuery(theme.breakpoints.down(701));

    const { selectedCampaign, currentBrand, isProUser, handleGoBack } = props;

    const { campaign_metadata, id: campaignId, status: statusInDb } = selectedCampaign;

    const {
        campaign_type,
        content,
        platform_details,
        budget: initialBudget,
        startDate,
        endDate: initialEndDate,
        campaign_state,
        currency,
    } = campaign_metadata;

    var campaign_objective: {
        name: string | null,
        type: string | null
    } = {
        name: null,
        type: null
    }

    let zRatio: number | null = null
    let o1: number | null = null
    let d1: number | null = null

    const currentAdAccountInfo: FacebookAdAccountInfo = {
        id: (currentBrand as CurrentBrand).connections.facebook_ad_account?.id,
        name: (currentBrand as CurrentBrand).connections.facebook_ad_account?.name,
        business: {
          id: (currentBrand as CurrentBrand).connections.facebook_ad_account?.business?.id,
          name: (currentBrand as CurrentBrand).connections.facebook_ad_account?.business?.name,
        },
        pixel: {
          id: campaign_state?.brand?.connections?.facebook_pixel?.id,
          name: campaign_state?.brand?.connections?.facebook_pixel?.name,
        }
    }

    if (campaign_state && campaign_state.campaign
        && campaign_state.campaign.conversions && campaign_state.campaign.conversions.conversionsEventName
        && campaign_state.campaign.conversions.conversionsEventType) {
        campaign_objective.name = campaign_state.campaign.conversions.conversionsEventName
        campaign_objective.type = campaign_state.campaign.conversions.conversionsEventType
    }

    if (campaign_state && campaign_state.campaign
        && campaign_state.campaign.zRatio) {
        zRatio = campaign_state.campaign.zRatio
    }

    if (campaign_state && campaign_state.campaign
        && campaign_state.campaign.o1) {
        o1 = campaign_state.campaign.o1
    }

    if (campaign_state && campaign_state.campaign
        && campaign_state.campaign.d1) {
        d1 = campaign_state.campaign.d1
    }


    const {
        cta: callToAction,
        link: selectedLink,
        campaign_name: campaignName,
        caption: caption
    } = content;

    const [isLoading, setLoading] = useState(true);
    const [performanceStats, setPerformanceStats] = useState<any>(null);
    const [adAccountStatus, setAdAccountStatus] = useState<any>(null)
    const [campaignStatuses, setCampaignStatuses] = useState<any>(null);
    const [adStatuses, setAdStatuses] = useState<any>(null);
    const [dailyStats, setDailyStats] = useState<any>(null)
    const [geographicBreakdown, setGeographicBreakdown] = useState<any>(null)
    const [demographicBreakdown, setDemograhicBreakdown] = useState<any>(null)
    const [overallCampaignStatus, setOverallCampaignStatus] = useState<OverallCampaignStatusType>(defaultOverallCampaignStatus);
    const [selectedCurrentBrand, setSelectedCurrentBrand] = useState(currentBrand);
    const [budget, setBudget] = useState<number>(initialBudget);
    const [endDate, setEndDate] = useState<string>(initialEndDate);
    const brandSlug = selectedCurrentBrand.slug;
    const brandId = selectedCurrentBrand.id;
    const [showEditBudgetModal, setShowEditBudgetModal] = useState<boolean>(false);
    const [showEditEndDateModal, setShowEditEndDateModal] = useState<boolean>(false);
    const [showPauseCampaignModal, setShowPauseCampaignModal] = useState<boolean>(false);
    const [showDuplicateCampaignModal, setShowDuplicateCampaignModal] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [loggedInFB, setLoggedInFb] = useState<boolean>(false);
    const [loadingFbToken, setLoadingFbToken] = useState<boolean>(true);
    // graphs | demographics | platforms
    const [dataView, setDataView] = useState<string>("demographics");
    const [currencyCode, setCurrencyCode] = useState<CurrencyCodesType>(currency || DEFAULT_CURRENCY);

    const daysLeft = useMemo(() => daysLeftCalculator(new Date().toString(), endDate), [endDate]);
    const campaignLengthInDays = useMemo(() => daysCalculator(startDate, endDate).toFixed(0), [endDate]);
    let fbToken = "";
    const handleOnReload = async () => {

        const mainArgs = {
            brandId,
            brandSlug,
            startDate,
            endDate,
        }
        getSelectedCurrentBrandData(setSelectedCurrentBrand);

    };

    const handleChangeBudget = (newBudget: number) => {
        setLoading(true)
        getCampaignData()
        setBudget(newBudget)
    }

    const handleChangeEndDate = (endDate: string, newBudget?: number) => {
        setLoading(true)
        getCampaignData()
        setEndDate(endDate);
        if (newBudget) setBudget(newBudget)
    }

    const handlePause = () => {
        setLoading(true)
        getCampaignData()
        setOverallCampaignStatus(defaultOverallCampaignStatus);
    }

    const handleCloseErrorModal = () => setShowErrorModal(false)
    const handleCloseEditBudgetModal = () => setShowEditBudgetModal(false)
    const handleCloseEditEndDateModal = () => setShowEditEndDateModal(false)
    const handleClosePauseModal = () => setShowPauseCampaignModal(false)
    const handleCloseDuplicateModal = () => setShowDuplicateCampaignModal(false);

    const handleOpenErrorModal = () => setShowErrorModal(true)
    const handleOpenEditBudgetModal = () => setShowEditBudgetModal(true)
    const handleOpenEditEndDateModal = () => setShowEditEndDateModal(true)
    const handleOpenPauseModal = () => setShowPauseCampaignModal(true)
    const handleOpenDuplicateModal = () => setShowDuplicateCampaignModal(true);

    useEffect(() => {
        if (!currency) {
          getAdAccountDetails(
            currentAdAccountInfo as SelectedFbAdAccount,
            platform_details.access_token || currentBrand.connections?.logged_in_fb_user?.access_token
          ).then((response) => {
            if (response.currency) setCurrencyCode(response.currency)
          })
        }
      }, [currencyCode])

    useEffect(() => {
        if (selectedCampaign) {
            track("Campaign Details Viewed", {
                type: "increase_link_clicks",

                brand_id: currentBrand.id,
                brand_name: currentBrand.name,
                brand_slug: currentBrand.slug,
                is_pro_user: isProUser,

                budget: budget,
                start_date: dayjs(startDate).format("YYYY-MM-DD"),
                end_date: dayjs(endDate).format("YYYY-MM-DD"),
            });
            getInitialState();
        } else {
            console.log("cant get all data");
        }
    }, [selectedCampaign]);


    useEffect(() => {
        if (adAccountStatus && campaignStatuses && adStatuses) {
            // check the statuses of campaigns to seee if either or has an error
            let totalAds = 0;
            const STATUS_MAPPING: Record<string, number> = {
                ACTIVE: 0,
                PAUSED: 0,
                WITH_ISSUES: 0,
                IN_PROCESS: 0,
                PENDING_REVIEW: 0,
                DENIED: 0,
                CAMPAIGN_PAUSED: 0,
                ADSET_PAUSED: 0,
                DISAPPROVED: 0,
                DELETED: 0,
                PENDING_BILLING_INFO: 0,
                ARCHIVED: 0,
            };

            adStatuses.forEach((ad: AdCampaignStatus) => {
                const { effective_status } = ad;

                if (
                    STATUS_MAPPING[effective_status] !== null &&
                    typeof STATUS_MAPPING[effective_status] !== "undefined"
                ) {
                    STATUS_MAPPING[effective_status] += 1;
                }

                totalAds++;
            });

            if (totalAds === 0) {
                if (adAccountStatus.error) {
                    // error state
                    setOverallCampaignStatus({
                        status: ERROR,
                        note: "There was an error setting up your campaign. Please try submitting it again or contact us at team@symphony.to.",
                    });
                } else {
                    // in review state
                    setOverallCampaignStatus({
                        status: TO_RETRY,
                        note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
                    });
                }
            } else {
                // if 80 - 100% of ads are ACTIVE, set as active
                const areCampaignsPaused = campaignStatuses.every((campaign: AdCampaignStatus) =>
                    campaign.effective_status === "PAUSED"
                )

                const pausedAdsDueToParent =
                    STATUS_MAPPING["CAMPAIGN_PAUSED"] + STATUS_MAPPING["ADSET_PAUSED"] + STATUS_MAPPING["PAUSED"];
                const totalUnpausedAds = (totalAds - pausedAdsDueToParent);

                const reviewPercentage =
                    (STATUS_MAPPING["IN_PROCESS"] + STATUS_MAPPING["PENDING_REVIEW"]) / totalUnpausedAds;
                const activePercentage =
                    STATUS_MAPPING["ACTIVE"] / totalUnpausedAds;
                const issuesPercentage =
                    (STATUS_MAPPING["WITH_ISSUES"] + STATUS_MAPPING["DELETED"] + STATUS_MAPPING["PENDING_BILLING_INFO"] + STATUS_MAPPING["ARCHIVED"]) / totalUnpausedAds;
                const deniedPercentage =
                    (STATUS_MAPPING["DENIED"] + STATUS_MAPPING["DISAPPROVED"]) / totalUnpausedAds;

                // console.log("areCampaignsPaused", areCampaignsPaused);
                // console.log("paused percentage", pausedAdsDueToParent);
                // console.log("active percentage", activePercentage);
                // console.log("review percentage", reviewPercentage);
                // console.log("issues percentage", issuesPercentage);
                // console.log("denied percentage", deniedPercentage);

                const adAccountGood = adAccountStatus.good
                if (totalAds === pausedAdsDueToParent || areCampaignsPaused) {
                    setOverallCampaignStatus({
                        status: PAUSED,
                        note: "Campaign or all adsets paused."
                    });
                } else if (activePercentage >= 0.5) {
                    if (!adAccountGood) {

                        var businessManagerId = null
                        if (campaign_state &&
                            campaign_state.brand &&
                            campaign_state.brand.connections &&
                            campaign_state.brand.connections.facebook_business_manager) {
                            businessManagerId = campaign_state.brand.connections.facebook_business_manager.id
                        }

                        // businessManagerId = false 
                        setOverallCampaignStatus({
                            status: LIVE_WITH_ISSUES,
                            note: (
                                <>
                                    It looks like there was an error with your ad account. Your ads may have been paused as a result.
                                    {businessManagerId ? (
                                        <>&nbsp;
                                            Click here to review the ad account status: &nbsp;
                                            <a className="underline" href={`https://business.facebook.com/accountquality/${businessManagerId}/${platform_details.ad_account.replace("act_", "")}`}
                                                target="_blank" rel="noreferrer">{`https://business.facebook.com/accountquality/${businessManagerId}/${platform_details.ad_account.replace("act_", "")}`}</a>
                                        </>
                                    ) : (
                                        <>
                                            &nbsp;Go to your  <a className="underline" target="_blank" href={`https://business.facebook.com`} rel="noreferrer">Facebook Business Manager</a> to resolve the issue.</>
                                    )}

                                </>)
                        });
                    } else {
                        if (performanceStats) {
                            setOverallCampaignStatus({
                                status: ACTIVE,
                                note: "Most ads are live."
                            });
                        } else {
                            setOverallCampaignStatus({
                                status: LIVE_WITH_NO_STATS,
                                note: "Your campaign just went live! As data flows in, you'll start seeing it update here in real time."
                            });
                        }
                    }

                } else {
                    if (reviewPercentage >= 0.5 && ["ACTIVE", "FINISHED", "PAUSED"].includes(statusInDb)) {
                        setOverallCampaignStatus({
                            status: IN_REVIEW_AFTER_ACTIVE,
                            note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
                        });
                    } else if (reviewPercentage >= 0.5) {
                        setOverallCampaignStatus({
                            status: IN_REVIEW,
                            note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
                        });
                    } else if (issuesPercentage === 1.0 || deniedPercentage === 1.0) {
                        const automationFeeRefundMessage = !isProUser ? "You’ll receive a refund for your Automation Fee in the next 10 business days." : ""
                        setOverallCampaignStatus({
                            status: DENIED,
                            note: `Your marketing assets have been denied by Facebook. Please make sure you use assets with no swear words or explicit content. You can re-submit your campaign through creating a new campaign. ${automationFeeRefundMessage}`,
                        });
                        return;
                    } else if (
                        activePercentage >= 0.01 &&
                        ((issuesPercentage > 0 && issuesPercentage <= 0.99) ||
                            (deniedPercentage > 0 && deniedPercentage <= 0.99))
                    ) {
                        setOverallCampaignStatus({
                            status: LIVE_WITH_ISSUES,
                            note: "Your campaign is live in Facebook, though some of your assets were denied by Facebook. We'll continue to run and optimize your campaign as much as we can.",
                        });
                        return;
                    } else if (
                        reviewPercentage >= 0.01 &&
                        (issuesPercentage >= 0.01 || deniedPercentage >= 0.01) &&
                        activePercentage === 0
                    ) {
                        setOverallCampaignStatus({
                            status: IN_REVIEW,
                            note: "Your marketing campaign is in review by Facebook. Once it goes live, you'll be able to see performance stats. It may take up to 24 hours to go live.",
                        });
                    }
                }
            }
            setLoading(false);
        }
    }, [campaignStatuses, adStatuses, adAccountStatus, performanceStats]);

    useEffect(() => {
        if (performanceStats) {
            setLoading(false);
        }
    }, [performanceStats]);

    const getInitialState = async () => {
        const logged = await checkFacebookToken();
        if (logged) {
            setLoggedInFb(logged);
            getCampaignData();
        }
        setLoadingFbToken(false);
    };

    const checkFacebookToken = async () => {
        if (loggedInFB) return true;
        const isLoggedInFb = await isLoggedInFbUser(currentBrand.id);
        if (!isLoggedInFb) return false;
        const { accessToken, valid } = isLoggedInFb;
        if (valid) {
            fbToken = accessToken || "";
            return true;
        }
        return false;
    }

    async function getCampaignData() {
        try {
            let url = `campaign/brand/${currentBrand.id}/${selectedCampaign.id}/analytics`

            if (fbToken) {
                url += `?access_token=${fbToken}`
            }

            const getData = await Axios.get(url, { timeout: 60 * 1000 })

            if (getData.data && getData.data.data) {
                const {
                    adAccountStatus,
                    adStatuses,
                    campaignPerformanceStats,
                    campaignStatuses,
                    dailyStats,
                    geographicBreakdown,
                    demographicsBreakdown
                } = getData.data.data

                setAdAccountStatus(adAccountStatus)
                setCampaignStatuses(campaignStatuses);
                setAdStatuses(adStatuses)
                setPerformanceStats(campaignPerformanceStats)
                setDailyStats(dailyStats)
                setGeographicBreakdown(geographicBreakdown)
                setDemograhicBreakdown(demographicsBreakdown)

                console.log("Got data from server")

            } else {
                console.error("Go clientside")
            }
        } catch (e) {
            console.log("Error getting data from server, trying locally", e)
            getAdAccountStatus()
            getCampaignStatuses();
            getAdStatuses();
            getCampaignPerfData();
            getDemographicBreakdown()
            getDailyPerfStats()
            getCountriesBreakdown()
        }
    }

    async function getCountriesBreakdown() {
        try {
            const geographicData = await queryFbAdCampaignInsights({
                ad_account: platform_details.ad_account,
                fields:
                    "actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type",
                level: "account",
                startDate: startDate,
                endDate: endDate,
                campaignIds: platform_details.campaigns,
                adIds: null,
                daily: null,
                breakdowns: `["country"]`,
                limit: 10,
                sort: `["inline_link_clicks_descending"]`,
            });

            if (geographicData) {
                // map it out with cumulative stats
                const mapped = geographicData.map((o: any) => {
                    return {
                        country: o.country,
                        countryName: (country_continents as any)[o.country]
                            ? (country_continents as any)[o.country].name
                            : "Unknown",
                        cpc: o.cpc ? parseFloat(o.cpc) : 0,
                        impressions: o.impressions ? parseInt(o.impressions) : 0,
                        clicks: o.inline_link_clicks ? parseInt(o.inline_link_clicks) : 0,
                        spend: o.spend ? parseFloat(o.spend) : 0,
                    };
                });

                setGeographicBreakdown(mapped);
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function getDemographicBreakdown() {
        try {
            const demographicBreakdown = await queryFbAdCampaignInsights({
                ad_account: platform_details.ad_account,
                fields: 'actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type',
                level: 'account',
                startDate: startDate,
                endDate: endDate,
                campaignIds: platform_details.campaigns,
                adIds: null,
                daily: null,
                breakdowns: `["age", "gender"]`,
                limit: 10,
                sort: `["inline_link_clicks_descending"]`
            })


            if (demographicBreakdown) {
                // map it out with cumulative stats

                const mapped = demographicBreakdown.map((o: any) => {

                    return {
                        age: o.age,
                        gender: o.gender,
                        cpc: o.cpc ? parseFloat(o.cpc) : 0,
                        impressions: o.impressions ? parseInt(o.impressions) : 0,
                        clicks: o.inline_link_clicks ? parseInt(o.inline_link_clicks) : 0,
                        spend: o.spend ? parseFloat(o.spend) : 0
                    }
                })

                setDemograhicBreakdown(mapped)
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function getAdAccountStatus() {
        try {
            const adAccountInfo = await queryFB(`/${platform_details.ad_account}`, {
                params: {
                    fields: `account_status,disable_reason`
                }
            })

            if (adAccountInfo.account_status > 1) {
                // if ad account status === DISABLED (code 2), write the disable reason
                const reason = adAccountInfo.account_status === 2 ? adAccountInfo.disable_reason : null
                setAdAccountStatus({
                    good: false,
                    error: reason ? reason : "error"
                })
            } else {
                setAdAccountStatus({
                    good: true,
                    error: null
                })
            }
        } catch (e) {
            console.error(e)
        }
    }


    async function getDailyPerfStats() {
        try {
            const campaignDailyData = await queryFbAdCampaignInsights({
                ad_account: platform_details.ad_account,
                fields: 'actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type',
                level: 'account',
                startDate: startDate,
                endDate: endDate,
                campaignIds: platform_details.campaigns,
                daily: true,
                sort: null,
                limit: null,
                adIds: null,
                breakdowns: null
            })

            if (campaignDailyData) {
                // map it out with cumulative stats
                var cumulativeSpend = 0
                var cumulativeClicks = 0
                var cumulativeImpressions = 0
                var cumulativeReach = 0

                var combined = campaignDailyData.map((o: any) => {

                    const {
                        date_start,
                        clicks,
                        cpc,
                        inline_link_clicks,
                        reach,
                        impressions,
                        spend
                    } = o

                    const formattedDate = dayjs(date_start).format("MM/DD")

                    cumulativeSpend += parseFloat(spend)
                    cumulativeClicks += parseInt(inline_link_clicks)
                    cumulativeImpressions += parseInt(impressions)
                    cumulativeReach += parseInt(reach)


                    return {
                        date: formattedDate,
                        totalSpend: cumulativeSpend,
                        totalReach: cumulativeReach,
                        totalImpressions: cumulativeImpressions,
                        totalClicks: cumulativeClicks,
                        clicks: clicks,
                        cpc: cpc ? parseFloat(cpc) : 0,
                        spend: spend ? parseFloat(spend) : 0
                    }
                })


                setDailyStats(combined)
            }

        } catch (e) {
            console.error(e)
        }
    }

    async function getCampaignStatuses() {
        try {
            const response = await Axios.get(
                `/campaign/brand/${brandId}/facebook/info`,
                {
                    params: {
                        fields: "effective_status,id,name,issues_info",
                        ids: platform_details.campaigns,
                        type: "campaigns",
                        limit: null,
                    },
                }
            );

            const campaigns = await response?.data?.data;

            const mappedCampaigns = campaigns.filter((o: any) => o.id);

            setCampaignStatuses(mappedCampaigns);
        } catch (error) {
            console.log("Error on getCampaignStatuses function ", error);
        }
    }

    async function getAdStatuses() {
        try {
            const response = await Axios.get(
                `/campaign/brand/${brandId}/facebook/info`,
                {
                    params: {
                        fields: "effective_status,id,name,issues_info",
                        ids: platform_details.campaigns,
                        type: "campaigns",
                        limit: null,
                    },
                }
            );

            const ads = await response?.data?.data;

            const mappedAds = ads.flat().filter((o: any) => o.id);

            setAdStatuses(mappedAds);
        } catch (e) {
            console.error(e)
        }
    }

    async function getCampaignPerfData() {
        try {
            const campaignPerfData = await queryFbAdCampaignInsights({
                ad_account: platform_details.ad_account,
                fields:
                    "campaign_name,campaign_id,actions,cpc,cpm,reach,impressions,spend,inline_link_clicks,outbound_clicks,clicks,cost_per_action_type",
                level: "account",
                startDate: startDate,
                endDate: endDate,
                campaignIds: platform_details.campaigns,
                limit: null,
                adIds: null,
                breakdowns: null,
                sort: null,
                daily: null,
            });

            if (campaignPerfData) setPerformanceStats(campaignPerfData[0]);
        } catch (e) {
            console.error(e)
        }
    }

    function showAdsData() {
        switch (overallCampaignStatus.status) {
            case PENDING:
                return null;
            case ACTIVE:
            case LIVE_WITH_NO_STATS:
            case LIVE_WITH_ISSUES:
            case PAUSED:
            case IN_REVIEW_AFTER_ACTIVE:
            case DENIED:
                return (
                    <>
                        <GeneralPerformanceStats
                            {...{
                                budget,
                                performanceStats,
                                campaign_type,
                                campaign_objective: campaign_objective ? campaign_objective : null,
                                handleOnReload,
                                zRatio,
                                o1,
                                currencyCode,
                            }}
                            currentBrand={selectedCurrentBrand}
                        />
                        <div className="px-3 lg:px-10 m-5">
                            <div className="radio-toolbar">
                                <input
                                    type="radio"
                                    id="demographics"
                                    name="dataview-type"
                                    value={"demographics"}
                                    onChange={() => setDataView("demographics")}
                                    checked={dataView === "demographics"}
                                />
                                <label
                                    style={{
                                        borderTopLeftRadius: 10,
                                        borderBottomLeftRadius: 10,
                                    }}
                                    className="w-full text-center border border-primary  flex items-center justify-center"
                                    htmlFor="demographics"
                                >
                                    Demographics
                                </label>
                                <input
                                    type="radio"
                                    id="graph"
                                    name="dataview-type"
                                    value={"graph"}
                                    onChange={() => setDataView("graph")}
                                    checked={dataView === "graph"}
                                />
                                <label
                                    style={{
                                        borderTopRightRadius: 10,
                                        borderBottomRightRadius: 10,
                                    }}
                                    className="w-full text-center border border-primary  flex items-center justify-center"
                                    htmlFor="graph"
                                >
                                    Daily Stats
                                </label>
                            </div>
                        </div>
                        <DailyStats
                            shown={dataView === "graph"}
                            isPro={isProUser}
                            dailyStats={dailyStats}
                            d1={d1}
                            loading={isLoading}
                            campaignDetails={{
                                startDate: startDate,
                                endDate: endDate,
                                platform_details: platform_details,
                                ad_account: platform_details.ad_account,
                            }}
                            hasConversions={false}
                        />
                        <DemographicsStats
                            shown={dataView === "demographics"}
                            isPro={isProUser}
                            loading={isLoading}
                            geographicBreakdown={geographicBreakdown}
                            demographicBreakdown={demographicBreakdown}
                            campaignDetails={{
                                startDate: startDate,
                                endDate: endDate,
                                platform_details: platform_details,
                                ad_account: platform_details.ad_account,
                            }}
                        />

                    </>
                );
            default:
                return null;
        }
    }

    function showFreeTierData() {

        return (
            <>
                <GeneralPerformanceStats
                    {...{
                        budget,
                        performanceStats,
                        handleOnReload,
                        isProUser,
                        campaign_type,
                        campaign_objective: campaign_objective ? campaign_objective : null,
                        zRatio,
                        o1,
                        currencyCode,
                    }}

                    currentBrand={selectedCurrentBrand}
                />
                <div className="px-3 lg:px-10 m-5">
                    <div className="radio-toolbar">
                        <input
                            type="radio"
                            id="demographics"
                            name="dataview-type"
                            value={"demographics"}
                            onChange={() => setDataView("demographics")}
                            checked={dataView === "demographics"}
                        />
                        <label
                            style={{
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                            }}
                            className="w-full text-center border border-primary  flex items-center justify-center"
                            htmlFor="demographics"
                        >
                            Demographics
                        </label>
                        <input
                            type="radio"
                            id="graph"
                            name="dataview-type"
                            value={"graph"}
                            onChange={() => setDataView("graph")}
                            checked={dataView === "graph"}
                        />
                        <label
                            style={{
                                borderTopRightRadius: 10,
                                borderBottomRightRadius: 10,
                            }}
                            className="w-full text-center border border-primary  flex items-center justify-center"
                            htmlFor="graph"
                        >
                            Daily Stats
                        </label>
                    </div>
                </div>
                {
                    dataView === "demographics" &&
                    <>
                        <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
                            <UnlockDataBanner
                                title="Learn exactly who your fans are"
                                description="Go Pro to see the countries + ages of fans who are clicking the most"
                                image={UnlockDataImage}
                                hideImage={hideBannerImage}
                            />
                        </div>
                        <div className="px-6">
                            <div className="lg:px-10 py-5 mt-2">
                                <p className="text-lg font-bold">Top Countries by Clicks</p>
                                <p className="text-sm text-gray-500">These are the top 10 countries that clicked on your ads the most.</p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredCountries.png").default} />
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-bold">Top Demographics by Clicks</p>
                                <p className="text-sm text-gray-500">These are the top 5 age &amp; gender breakdowns that clicked on your ads the most.</p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredDemographics.png").default} />
                        </div>
                    </>
                }
                {
                    dataView === "graph" &&
                    <>
                        <div className="px-6 lg:px-10 py-5 flex flex-col justify-between">
                            <UnlockDataBanner
                                title="Track daily growth"
                                description="Go Pro to see how your marketing is performing over time"
                                image={UnlockPresavesByDayImage}
                                hideImage={hideBannerImage}
                            />
                        </div>
                        <div className="px-6">
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-bold">Daily Spend</p>
                                <p className="text-sm text-gray-500">This graph shows the daily spend on marketing. This may fluctuate up and down as the ads optimize to find the best audiences to target.</p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-bold">Clicks Over Time</p>
                                <p className="text-sm text-gray-500">This graph shows the daily number of clicks to streaming services that are coming from your ads.</p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                            <div className="px-6 lg:px-10 py-5 mt-2">
                                <p className="text-lg font-bold">Cost-per-Click Over Time</p>
                                <p className="text-sm text-gray-500">This graph shows how the cost-per-click to a streaming service changed over the duration of the campaign. If the cost-per-click is going down or is stable, it means that the campaign is performing well.</p>
                            </div>
                            <img src={require("../../../../assets/images/campaignDetails/impactBlurredChart.png").default} />
                        </div>
                    </>
                }
            </>
        )
    }

    return (
        <CampaignDetailsContainer backgroundColor="#F3F5F6">
            <OverviewCampaignCard
                isIGCampaign={true}

                isLoading={isLoading}
                campaignStatusLabel={showAdsStatusLabel(overallCampaignStatus.status, daysLeft)}
                campaignStatus={overallCampaignStatus.status}
                goBack={handleGoBack}
                selectedCampaign={selectedCampaign}
                handleOpenEditBudgetModal={handleOpenEditBudgetModal}
                handleOpenEditEndDateModal={handleOpenEditEndDateModal}
                handleOpenPauseModal={handleOpenPauseModal}
                handleOpenDuplicateModal={handleOpenDuplicateModal}
                handleSetMarketsTab={() => { }}
            />
            {!loggedInFB && !loadingFbToken ?
                <FbTokenExpired currentBrand={currentBrand} onSuccess={getInitialState} campaignId={campaignId} campaignType={campaign_type} />
                :
                <div className="card-content bg-white shadow-none lg:shadow-lg pb-6 middle-wrapper">
                    <div className="middle-wrapper">
                        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Link</p>
                            <p className="w-auto md:w-8/12 text-lg pl-8 text-clip overflow-hidden text-right">{selectedLink}</p>
                        </div>
                        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Budget</p>
                            <p className="text-lg">${budget.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                        </div>
                        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Campaign Length</p>
                            <div className="flex flex-col text-right">
                                <p className="text-lg">
                                    {campaignLengthInDays} days (
                                    {new Date(startDate).toLocaleDateString()} to{" "}
                                    {new Date(endDate).toLocaleDateString()})
                                </p>
                                <p className="text-md">
                                    {overallCampaignStatus.status === PAUSED ? 'Campaign Paused' : daysLeft >= 0
                                        ? daysLeft === 1
                                            ? `1 Day Left`
                                            : `${daysLeft.toFixed()} Days Left`
                                        : "Campaign Finished"}
                                </p>
                            </div>
                        </div>
                        {!isLoading && <EditBudgetModal
                            budget={budget}
                            startDate={startDate}
                            endDate={endDate}
                            campaignId={campaignId}
                            closeModal={handleCloseEditBudgetModal}
                            currency='USD'
                            minimalAmountPerDay={MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS}
                            open={showEditBudgetModal}
                            totalSpent={performanceStats?.spend || 0}
                            reload={handleChangeBudget}
                            onErrorHandler={handleOpenErrorModal}
                        />}
                        {!isLoading && <EditEndDateModal
                            budget={budget}
                            campaignId={campaignId}
                            closeModal={handleCloseEditEndDateModal}
                            startDate={startDate}
                            endDate={endDate}
                            minimalAmountPerDay={MINIMUM_DAILY_BUDGET_PER_DAY_IN_DOLLARS}
                            open={showEditEndDateModal}
                            reload={handleChangeEndDate}
                            totalSpent={performanceStats?.spend || 0}
                            onErrorHandler={handleOpenErrorModal}
                        />}
                        {!isLoading && <PauseCampaignModal
                            campaignId={campaignId}
                            closeModal={handleClosePauseModal}
                            open={showPauseCampaignModal}
                            pause={overallCampaignStatus.status !== PAUSED}
                            reload={handlePause}
                            onErrorHandler={handleOpenErrorModal}
                        />}
                        {!isLoading && <DuplicateCampaignModal
                            currentBrand={currentBrand}
                            campaign={selectedCampaign}
                            closeModal={handleCloseDuplicateModal}
                            open={showDuplicateCampaignModal}
                            duplicateIcon={DuplicateAnotherLinkClicks}
                        />}
                        <ErrorUpdatingCampaignModal
                            closeModal={handleCloseErrorModal}
                            open={showErrorModal}
                            campaignId={campaignId}
                        />
                        <AdsStatusMessage overallCampaignStatus={overallCampaignStatus} />
                        {isLoading ? (
                            <div className="mx-auto my-6 flex-col flex items-center ">
                                <LoadingIndicator height="100px" color="black" />
                                <p className="mt-1 text-center">Loading Campaign Data...</p>
                            </div>
                        ) :
                            isProUser ? (
                                showAdsData()
                            )
                                : showFreeTierData()
                        }
                    </div>
                </div>
            }
        </CampaignDetailsContainer>
    );
}
