/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useContext,
} from "react";
import moment from "moment";
import { CurrencyCodesType, CurrentBrand } from "types/global";
import BudgetCard from "../../Components/BudgetCard";
import WarningsCard from "../../Components/BudgetWarningsCard";
import DatesCard from "./Components/DatesCard";
import PaymentMethodCard from "../../SongAds/BudgetTab/Components/PaymentMethodCard";
import RunningForCard from "../../GrowInstagram/BudgetTab/Components/RunningForCard";
import { checkIfIsProTier } from "utils";
import { Container } from "./styles";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { STEPS } from "../utils";
import dayjs from 'dayjs';
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { getBudgetData, loadPaymentMethod } from "../../SongAds/BudgetTab/utils";

interface BudgetProps {
  budgetValue: number;
  startDate: Date | null;
  endDate: Date | null;
  fbAdAccountId: string;
  fbBusinessId: string;
  fbAdAccountName: string;
  brand: CurrentBrand;
  showReviewButton?: boolean;
  selectedTab?: STEPS | null;
  finishFlow: () => void;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  setBudgetValue: Dispatch<SetStateAction<number>>;
  saveWhenNextButtonIsDisabled?: (isDisabled: boolean) => void;
  updatedCurrency: (currency: CurrencyCodesType) => void;

}

export default function BudgetTab({
  budgetValue,
  startDate,
  endDate,
  fbAdAccountId,
  brand,
  showReviewButton,
  selectedTab,
  finishFlow,
  setStartDate,
  setEndDate,
  setBudgetValue,
  saveWhenNextButtonIsDisabled,
  updatedCurrency,
  fbAdAccountName
}: BudgetProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769));
  const [currentEndSchedule] = useState("scheduled");
  const [paymentMethodSelected, setPaymentMethod] = useState<any>(null);
  const [spendCap, setSpendCap] = useState<number>(0);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [budgetLowError, setBudgetLowError] = useState(false);
  const [minimumSuggestedBudget, setMinimumSuggestedBudget] = useState(0);
  const [suggestedEndDate, setSuggestedEndDate] = useState(
    moment().format("MM/DD/YYYY")
  );

  // when the currency changes, we should make a call to check if it's below the minimum budget
  // and update the "isBelowRecommendedBudget" accordingly
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");

  useEffect(() => {
    // recalculate the minimum budget per day when the currency changes
    if (updatedCurrency) {
      updatedCurrency(currency)
    }
  }, [currency])


  const [minimumCurrencyBudgetPerDay, setMinimumCurrencyBudgetPerDay] =
    useState(0);

  const {
    onChangeBudget: onChangeSpendBudget,
    onChangeCurrency: onChangeSpendCurrency,
  } = useContext(SpendBudgetContext)

  const isProUser = checkIfIsProTier(brand);

  const showWarningsCard =
    budgetLowError ||
    !!(budgetValue && spendCap && budgetValue * 100 > spendCap);

  const isDisabledNextButton =
    !(
      (spendCap > 0 ? budgetValue * 100 <= spendCap : true) &&
      typeof budgetValue !== "undefined" &&
      budgetValue !== null &&
      budgetValue > 0 &&
      startDate &&
      endDate &&
      paymentMethodSelected &&
      dayjs(endDate).isAfter(startDate, 'day') &&
      (dayjs(startDate).isSame(dayjs(), 'day') || dayjs(startDate).isAfter(dayjs(), 'day')) &&
      minimumCurrencyBudgetPerDay > 0
    ) || budgetLowError || loadingPayment;

  const loadPaymentMethodArgs = {
    fbAdAccountId,
    setLoadingPayment,
    setPaymentMethod,
    setSpendCap,
    setCurrency,
    onChangeSpendCurrency,
    setMinimumCurrencyBudgetPerDay,
  };

  const handleChangeBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (Number(value) >= 0) {
      const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
        startDate,
        endDate,
        budgetValue: value,
        minimumCurrencyBudgetPerDay,
      });

      onChangeSpendBudget(value)
      setBudgetValue(value);
      setBudgetLowError(isLow);
      setMinimumSuggestedBudget(minimumSuggestedBudget);
      setSuggestedEndDate(suggestedEndDate);
    }
  };

  const handleSelectStartDate = (date: Date | null) => {
    const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
      startDate: date,
      endDate,
      budgetValue,
      minimumCurrencyBudgetPerDay,
    });

    setStartDate(date);
    setBudgetLowError(isLow);
    setMinimumSuggestedBudget(minimumSuggestedBudget);
    setSuggestedEndDate(suggestedEndDate);
  };

  const handleSelectEndDate = (date: Date | null) => {
    const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
      startDate,
      endDate: date,
      budgetValue,
      minimumCurrencyBudgetPerDay,
    });

    setEndDate(date);
    setBudgetLowError(isLow);
    setMinimumSuggestedBudget(minimumSuggestedBudget);
    setSuggestedEndDate(suggestedEndDate);
  };

  const handleRefreshPaymentMethod = () =>
    loadPaymentMethod(loadPaymentMethodArgs);


  useEffect(() => {
    if (!paymentMethodSelected && selectedTab === STEPS.BUDGET) {
      loadPaymentMethod(loadPaymentMethodArgs)
    };
  }, [selectedTab, paymentMethodSelected]);

  useEffect(() => {
    if (saveWhenNextButtonIsDisabled) {
      saveWhenNextButtonIsDisabled(isDisabledNextButton)
    }
  }, [isDisabledNextButton])

  useEffect(() => {
    if (saveWhenNextButtonIsDisabled) {
      saveWhenNextButtonIsDisabled(isDisabledNextButton)
    }
  }, [isDisabledNextButton])

  useEffect(() => {
    if (minimumCurrencyBudgetPerDay > 0) {
      const { isLow, minimumSuggestedBudget, suggestedEndDate } = getBudgetData({
        startDate,
        endDate,
        budgetValue,
        minimumCurrencyBudgetPerDay,
      });
      setBudgetLowError(isLow);
      setMinimumSuggestedBudget(minimumSuggestedBudget);
      setSuggestedEndDate(suggestedEndDate);
    }
  }, [minimumCurrencyBudgetPerDay])

  return (
    <Container>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <BudgetCard
          {...{ budgetValue, isProUser, currencyCode: currency }}
          handleChangeInputText={handleChangeBudget}
        />
      </Grid>
      {showWarningsCard && (
        <Grid className={isMobile ? "mb-0" : "mb-4"}>
          <WarningsCard
            {...{
              fbAdAccountId,
              budgetLowError,
              currency,
              minimumCurrencyBudgetPerDay,
              minimumSuggestedBudget,
              suggestedEndDate,
              budgetValue,
              spendCap,
              loadingPayment,
              handleRefreshPaymentMethod,
            }}
          />
        </Grid>
      )}
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DatesCard
          {...{
            startDate,
            endDate,
            currentEndSchedule,
            budgetValue,
            handleSelectStartDate,
            handleSelectEndDate,
          }}
        />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <PaymentMethodCard
          {...{
            fbAdAccountId,
            fbAdAccountName: fbAdAccountName,
            paymentMethodSelected,
            loadingPayment,
            handleRefreshPaymentMethod,
          }}
        />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <RunningForCard
          {...{
            budgetValue,
            startDate,
            endDate,
            finishFlow,
            showReviewButton,
            currencyCode: currency
          }}
          disableMobileButton={isDisabledNextButton}
        />
      </Grid>
    </Container>
  );
}
