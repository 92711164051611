import { FunctionComponent, Fragment } from "react";
import DatePicker from "react-datepicker";
import "styles/react-datepicker.css";

import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import useStyles, { DateLabel } from "./styles";
import { Card, StyledLabel } from "../../styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { minimumDailyBudgetCalculator } from "../../../constants";

interface Props {
  targetingType: string;
  startDate?: Date | null;
  endDate?: Date | null;
  currentEndSchedule: string;
  budgetValue: number;
  handleSelectStartDate: (date: Date | null) => void;
  handleSelectEndDate: (date: Date | null) => void;
}

const DatesCard: FunctionComponent<Props> = ({
  targetingType,
  startDate,
  endDate,
  currentEndSchedule,
  budgetValue,
  handleSelectStartDate,
  handleSelectEndDate,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();
  const MIN_DAILY_BUDGET = minimumDailyBudgetCalculator(targetingType)

  return (
    <Card borderRadius={isMobile ? undefined : '12px'}>
      <Grid container direction="column">
        <StyledLabel fontWeight={600} fontSize={18} paddingBottom="16px">
          Date
        </StyledLabel>
        <Grid
          className={classes.startDate}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Start Date
          </StyledLabel>
          <Grid>
            <DatePicker
              minDate={dayjs().add(1, "day").toDate()}
              filterDate={(date: Date) => {
                if (!startDate || !endDate) return true;
                return dayjs(date) < dayjs(endDate);
              }}
              className={!startDate ? "hidden" : ""}
              id="budget_start_date"
              selected={startDate}
              onChange={handleSelectStartDate}
            />
            <DateLabel hide={!!startDate} htmlFor="budget_start_date">
              Set starting date
            </DateLabel>
          </Grid>
        </Grid>
        <Fragment>
          {currentEndSchedule === "scheduled" && (
            <Grid
              className={classes.endDate}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <StyledLabel fontWeight={600} fontSize={16}>
                End Date
              </StyledLabel>
              <Grid item>
                <Grid container alignItems="center">
                  <DatePicker
                    filterDate={(date: Date) => {
                      if (!startDate) return false;
                      
                      var maxEndDate = null;
                      const maxDays = budgetValue ? budgetValue / MIN_DAILY_BUDGET : 0
                      if (maxDays === 0) {
                        return false;
                      } else {
                        maxEndDate = dayjs(startDate).add(maxDays, "day");
                        return (
                          dayjs(date) <= maxEndDate &&
                          dayjs(date) > dayjs(startDate)
                        );
                      }
                    }}
                    minDate={
                      startDate ? dayjs(startDate).add(1, "day").toDate() : null
                    }
                    className={!endDate ? "hidden" : ""}
                    id="budget_end_date"
                    selected={endDate}
                    onChange={handleSelectEndDate}
                  />
                  <DateLabel hide={!!endDate} htmlFor="budget_end_date">
                    Set end date
                  </DateLabel>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Fragment>
      </Grid>
    </Card>
  );
};

export default DatesCard;
