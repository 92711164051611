import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Container, ChipFilterPopOver, TitlePopOver } from "styles/shared";

interface Props {
  title: string;
  listItems: {
      key: string;
      onClick: (key: string) => void;
      icon?: string;
      checked: boolean;
      label: string;
  }[];
}

export default function CampaignFilterListItemPopover({
  title = "Campaign Types",
  listItems = [],
}: Props) {
  return (
    <Container display="flex" flexDirection="column" gap="8px" maxWidth="340px">
      <TitlePopOver>{title}</TitlePopOver>
      <List style={{ padding: 0 }}>
        {listItems.map((item) => {
          const { key, onClick, icon, checked, label } = item;
          return (
            <ListItem
              key={key}
              dense
              button
              onClick={() => onClick(key)}
              style={{ padding: "4px 0" }}
            >
              <ListItemIcon style={{ minWidth: "32px" }}>
                <Checkbox
                  edge="start"
                  checked={checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": key }}
                  color="default"
                />
              </ListItemIcon>
              <ListItemText
                id={key}
                primary={
                  <ChipFilterPopOver>
                    {icon && (
                      <img
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                          alignSelf: "center",
                        }}
                        src={icon}
                        alt={label}
                      />
                    )}
                    {label}
                  </ChipFilterPopOver>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
}
