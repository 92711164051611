import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { ConnectorIndicatorTypes, FollowersChartData, FollowersData, OverallCampaignStatus, Platforms, RecordType, StreamsChartData, StreamsData } from "types/global";
import { daysCalculator } from "helpers/General";
import ImpactChart from "components/shareable/ImpactChart/index";
import {
  getAverageCostPerUnit,
} from "./utils";
import CustomChartLabel from "components/shareable/CustomChartLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
import { SectionContainer } from "../styles";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { Connectors } from "../GeneralPerformance";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { AttributionData } from "../../SongAds/CampaignDetails";
import dayjs from "dayjs";
import ShazamConnectModal from "components/connect/ShazamConnectModal";
import ShazamIcon from "assets/images/shazam.svg";
import { CampaignOption } from "../../GrowInstagram/utils";
import Loaderv2 from "components/Loader/v2";

// Add these helper functions above the component
// Calculates the average count from daily stats, excluding today's data
export const calculateAverage = (dailyStats: any[]) => {
  if (dailyStats.length <= 1) return 0;
  const statsWithoutToday = dailyStats.slice(0, -1);
  return Math.round(statsWithoutToday.reduce((sum, data) => sum + data.count, 0) / statsWithoutToday.length);
};

// Calculates the percentage change between the start of the campaign and today
export const calculatePercentageChange = (dailyStats: any[], campaignStartDate: Date) => {
  const today = dailyStats[dailyStats.length - 1].count;
  const startIndex = dailyStats.findIndex(stat => new Date(stat.date) >= campaignStartDate);
  const start = startIndex >= 0 ? dailyStats[startIndex].count : dailyStats[0].count;
  return Number(((today - start) / start * 100).toFixed(1));
};
// Calculates the average count before the campaign start date
export const calculateAverageBeforeCampaign = (dailyStats: any[], campaignStartDate: Date) => {
  const statsBefore = dailyStats.filter(stat => new Date(stat.datetime) < campaignStartDate);
  if (statsBefore.length === 0) return 0;
  return Math.round(statsBefore.reduce((sum, stat) => sum + parseInt(stat.fanbase_count), 0) / statsBefore.length);
};

// Calculates the average count during the campaign (from start date to yesterday)
export const calculateAverageDuringCampaign = (dailyStats: any[], campaignStartDate: Date) => {
  const campaignEndDate = new Date(dailyStats[dailyStats.length - 1].datetime);
  const statsDuring = dailyStats.filter(stat => 
    new Date(stat.datetime) >= campaignStartDate && 
    new Date(stat.datetime) <= campaignEndDate
  );
  if (statsDuring.length === 0) return null;
  const sum = statsDuring.reduce((acc, stat) => acc + parseInt(stat.fanbase_count), 0);
  const average = sum / statsDuring.length;
  return isFinite(average) ? Math.round(average) : null;
};

interface ShazamConnectorIndicatorProps {
  songName?: string;
  brandSlug?: string;
  contentSlug?: string;
  reload?: () => void;
}

export const ShazamConnector: FunctionComponent<ShazamConnectorIndicatorProps> = ({
  songName,
  brandSlug,
  contentSlug,
  reload,
}: ShazamConnectorIndicatorProps) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <React.Fragment>
      <div className="grid grid-cols-12 items-center">
        <div className="col-span-2 row-start-auto">
          <img className="w-10 h-10" alt="shazam_icon.svg" src={ShazamIcon} />
        </div>
        <div className="col-span-7 row-start-auto">
          <h5 className="text-dark">
            Connect <b>Shazam</b> to automatically track your song's Shazams
          </h5>
        </div>
        <div
          className="col-span-2 row-start-auto text-green-500 cursor-pointer font-semibold"
          onClick={handleOpenModal}
        >
          Connect
        </div>
        {openModal && (
          <ShazamConnectModal
            {...{
              brandSlug,
              contentSlug,
              reload,
              songName,
            }}
            closeModal={handleCloseModal}
          />
        )}
      </div>
    </React.Fragment>
  );
};

interface Props {
  loadingStats: boolean;
  divider?: {
    top?: boolean;
    bottom?: boolean;
  };
  campaignDetails: {
    budget: number;
    startDate: string;
    endDate: string;
  };
  isShazamConnected?: boolean;
  isProUser: boolean;
  brandSlug: string;
  campaignType?: string;
  campaignSubType?: CampaignOption | undefined;
  contentSlug?: string;
  contentName?: string;
  attributionStats: AttributionData[]
  reloadState: () => void;
  campaignStatus: string;
}

const ImpactStats: FunctionComponent<Props> = ({
  brandSlug,
  divider,
  campaignDetails,
  isProUser,
  contentName,
  contentSlug,
  loadingStats,
  attributionStats,
  reloadState,
  campaignStatus,
  campaignType,
  campaignSubType,
}: Props) => {
  const theme = useTheme();
  const isCustomLabelResponsive = useMediaQuery(theme.breakpoints.down(501));
  const { budget: campaignBudget, startDate: campaignStartDate, endDate: campaignEndDate
  } = campaignDetails;
  const campaignLengthInDays = Number(
    daysCalculator(campaignStartDate, campaignEndDate).toFixed(0) || "0"
  );

  const chartDataStats = () => {
    return attributionStats
      // hide anything that needs a connection from the stats
      .filter((stat) => !stat.description.needsConnection && stat.data.dailyStats.length > 0)
      .map(stat => {
        const data = stat.data;
        const showAverage  = stat.description.showAverage;
        const { currentValue, percentageChange, netChange, dailyStats } = data;
        const unitType = stat.description.dataType;

        const totalNewCounts = Number(currentValue || "0");
        const averageCostPerUnit = getAverageCostPerUnit({
          growth: netChange,
          totalBudget: campaignBudget,
          totalLengthCampaignPeriod: campaignLengthInDays,
          campaignStartDate: campaignStartDate,
          campaignEndDate: campaignEndDate,
        });

        const campaignStartDateObj = new Date(campaignStartDate);
        const averageBeforeCampaign = calculateAverageBeforeCampaign(dailyStats, campaignStartDateObj);
        const averageDuringCampaign = calculateAverageDuringCampaign(dailyStats, campaignStartDateObj);

        return {
          ...stat,
          type: stat.description.connectorType,
          dailyStats: dailyStats.map(dailyStat => {
            let count = 0;
            if (unitType === Platforms.streams) {
              count = (dailyStat as StreamsChartData)?.views_stats || 0;
            } else if (unitType === Platforms.followers) {
              count = Number((dailyStat as FollowersChartData)?.fanbase_count || 0);
            }

            return {
              type: stat.description.connectorType,
              day: dayjs(dailyStat.datetime).format('ddd'),
              count: count,
              date: dailyStat.datetime,
              campaignStartDate: campaignStartDate,
              valueOf: dayjs(dailyStat.datetime).valueOf(),
            };
          }),
          totalNewCounts,
          averageCostPerUnit,
          percentage: percentageChange,
          showAverage,
          averageBeforeCampaign: isFinite(averageBeforeCampaign) ? averageBeforeCampaign : null,
          averageDuringCampaign: isFinite(averageDuringCampaign || 0) ? averageDuringCampaign : null,
        };
      });
  };


  const mainData = chartDataStats();


  const renderData = () => {
    if (loadingStats) {
      return (<div className="mx-auto my-6 flex-col flex items-center ">
        <Loaderv2 />
      </div>)
    } else if (isProUser) {
      return (
        <>
          <Connectors
            showWhenEmpty={campaignStatus !== 'FINISHED'}
            brandSlug={brandSlug}
            contentSlug={contentSlug}
            contentName={contentName}
            attributionData={attributionStats}
            reloadState={reloadState}
          />

          <div className="mt-6">
            {mainData.map((stat, index) => {
              const {
                data: { currentValue },
                dailyStats,
                averageCostPerUnit,
                showAverage,
                description: { platformName, mainLabel, icon, alt, hideCostLabel },
                averageBeforeCampaign,
                averageDuringCampaign,
              } = stat;


              const showLabels = !hideCostLabel;
              const campaignStartDateObj = new Date(campaignStartDate);
              const isFirstDay = dayjs().isSame(campaignStartDateObj, 'day') || dayjs().isSame(dayjs(campaignStartDateObj).add(1, 'day'), 'day');
              const isReachOrVisits = mainLabel.toLowerCase().includes('profile visits') || mainLabel.toLowerCase().includes('reach');

              const showNewAverage = !isFirstDay && showAverage && averageDuringCampaign !== null && dailyStats.length > 1;

              const chartMainLabelProps = {
                index,
                showSecondaryMetric: showLabels && !showAverage,
                isCompact: isCustomLabelResponsive,
                metricProps: {
                  icon,
                  iconAlt: alt,
                  primaryValue: showAverage ? calculateAverage(dailyStats) : currentValue,
                  label: showAverage ? `${mainLabel}` : mainLabel,
                },
                secondaryMetricProps: {
                  value: showAverage ? calculateAverage(dailyStats) : (averageCostPerUnit || 0),
                  type: stat.type,
                  customLabel: showAverage ? `Average Reach (last ${Math.min(dailyStats.length - 1, 7)} days)` : undefined,
                },
                percentageProps: {
                  value: calculatePercentageChange(dailyStats, new Date(campaignStartDate)),
                },
                isReachOrVisits,
                isFirstDay,
                averageBeforeCampaign,
                averageDuringCampaign
              };

              return (
                <React.Fragment key={index}>
                  <ImpactChart
                    width="100%"
                    index={index}
                    divider={divider}
                    chartData={dailyStats}
                    showAverage={showAverage}
                    isFirstDay={isFirstDay}
                    isCompact={isCustomLabelResponsive}
                    margin={{
                      top: 8,
                      right: 25,
                    }}
                    mainLabelComponent={
                      <CustomChartLabel {...chartMainLabelProps} />
                    }
                    campaignStartDate={campaignStartDate}
                    averageBeforeCampaign={averageBeforeCampaign !== null ? averageBeforeCampaign : 0}
                    averageDuringCampaign={showNewAverage ? averageDuringCampaign : 0}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </>
      );
    }
  };

  return (

    <React.Fragment>

      <SectionContainer>
        <div className="flex items-start">

          <p className="text-lg font-semibold flex items-center gap-1 flex-row">Impact Stats<UpgradeToProChip type="gem" color="purple" width={18} height={18} /></p>
        </div>
      </SectionContainer>
      {renderData()}


    </React.Fragment>
  );
};

export default ImpactStats;