import CloseButton from "../CloseButton";

interface PopupHeaderProps {
    text: string;
    onClose?: () => void;
}
export default function PopupHeader(props: PopupHeaderProps) {
    const {
        text,
        onClose
    } = props

    return (
        <div
            className="items-center flex flex-row justify-between 
            px-6 py-4 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">{text}</p>
            {onClose ? <CloseButton onClick={onClose} /> : null}
        </div>
    )
}