/* eslint-disable no-throw-literal */
import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";
import some from "lodash/some";
import { CurrentBrand, VideoAdsRecord } from "types/global";
import { track } from "analytics";

export const createPayPalOrder = async (args: {
  brand: CurrentBrand;
  record: VideoAdsRecord | null;
  data: Record<string, unknown>;
}) => {
  const { brand, record, data } = args;
  track("Completed Campaign Step - Checkout", {
    type: "increase_video_views",
    step: 4,
    name: "confirmation",
    brand_id: brand.id,
    brand_name: brand.name,
    brand_slug: brand.slug,
    content_name: record?.title,
    content_id: record?.id,
    payment_method: "paypal",
  });

  try {
    const response = await Axios.post(
      `/campaign/brand/${brand.id}/checkout`,
      data
    );

    if (some([!response, !response.data, !response.data.data])) {
      throw "Error creating PayPal order";
    }

    const order = response.data.data;
    const orderId = order.paypalOrder.id;
    const campaignId = order.campaignId;

    return { orderId, campaignId };
  } catch (error) {
    Sentry.captureException(error);
    console.log("error: ", error);
    return { orderId: null, campaignId: null };
  }
};

export const redirectToCheckout = async (args: {
  brand: CurrentBrand;
  record: VideoAdsRecord | null;
  data: Record<string, unknown>;
}) => {
  const { brand, record, data } = args;

  track("Completed Campaign Step - Checkout", {
    type: "increase_video_views",
    step: 4,
    name: "confirmation",
    brand_id: brand.id,
    brand_name: brand.name,
    brand_slug: brand.slug,
    content_name: record?.title,
    content_id: record?.id,
    payment_method: "stripe",
  });

  try {
    const redirectUrlReq = await Axios.post(
      `/campaign/brand/${brand.id}/checkout`,
      data
    );

    if (
      !redirectUrlReq ||
      !redirectUrlReq.data ||
      !redirectUrlReq.data.data ||
      !redirectUrlReq.data.data.url
    ) {
      throw "Error getting URL";
    }
    window.location = redirectUrlReq.data.data.url;
  } catch (e) {
    console.error("e", e);
  }
};
