import millify from "millify";
import { useEffect, useContext, useCallback } from "react";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import FB, { queryFB, AD_ACCOUNT_STATUS, checkForFBPermissions, loginWithFB, fbLoggedIn } from "../../helpers/FB";
import clsx from "clsx";
import useStyles from "./styles";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import { CreativeTabConnectTypes } from "types/global";
import externalLinkIcon from "assets/images/external-link.svg";
import { Chip } from "@material-ui/core";
import { AddAccountCtn, AddAccountChip } from './styles';
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { AccountDisabledBanner } from "components/shareable/AccountDisabledBanner";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import PrimaryButton from "components/shareable/PrimaryButton";
import { CURRENCY_CODE_DATA } from "helpers/currencies";

interface FacebookAdAccountConnectProps {
    closeModal: () => void;
    artist: any;
    selected: any;
    set: any;
    businessManager?: any;
    showBusinessManagerSelector?: any;
    setupType?: string
    withBorderRadius?: boolean;
}


enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Facebook Ad Accounts because we don't have permission. Click the button below to authorize access to your ad account."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Facebook ad accounts associated with your account. Please create a new Facebook ad account by following the instructions at the link."
    }
}

export default function FacebookAdAccountConnect(props: FacebookAdAccountConnectProps) {
    const {
        closeModal,
        artist,
        selected,
        set,
        businessManager,
        setupType,
        showBusinessManagerSelector,
        withBorderRadius,
    } = props

    const { saveData } = useContext(ConnectModalsContext)
    const { reloadBrand } = useContext(CurrentBrandContext)

    const classes = useStyles();

    // searching for ad accounts
    const [searchStr, setSearchStr] = useState('');

    // status for Facebook Account
    const [checkedFBLoginStatus, setFBCheckedLoginStatus] = useState<boolean>(false);
    const [userFBLoggedIn, setUserFBLoggedIn] = useState<boolean>(false);

    // status for checking ad accounts
    const [availAdAccounts, setAdAccounts] = useState<any>([])
    const [loadingItems, setLoadingItems] = useState<boolean>(true)
    const [checkedAdAccounts, setCheckedAccounts] = useState<boolean>(false)

    // state for new ad account error
    const [creatingNewAccount, setCreatingNewAccount] = useState<any>(false)
    const [creationError, setCreationError] = useState<any>(null)

    // state for error getting ad accounts
    const [error, setError] = useState<any>(null)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // permissions error
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES>(ERROR_STATES_STATUSES.NONE)

    const isDisabledConnectButton = error || !selected

    const getAdAccounts = async () => {
        setCheckedAccounts(true)
        setLoadingItems(true)
        try {
            var queryUrl = `/me/adaccounts`
            if (businessManager && setupType && setupType === 'custom') {
                queryUrl = `/${businessManager.id}/owned_ad_accounts`
            }

            const results = await queryFB(queryUrl, {
                params: {
                    fields: `amount_spent,name,id,account_status,user_tasks,currency,business{name,id}`,
                    limit: 250
                }
            })
            const adAccounts = results.data.map((adAccount: any) => {
                const { user_tasks, business } = adAccount
                let userHasCorrectAccess = false

                if (user_tasks) {
                    try {
                        let hasAnalyze = false
                        let hasAdvertise = false

                        // check if they have:
                        //  - ADVERTISE
                        //  - ANALYZE
                        //  - MANAGE access
                        hasAnalyze = user_tasks.indexOf('ANALYZE') > -1
                        hasAdvertise = user_tasks.indexOf('ADVERTISE') > -1

                        userHasCorrectAccess = hasAnalyze && hasAdvertise
                    } catch (e) {
                        console.error('e', e)
                    }
                }

                return {
                    ...adAccount,
                    amount_spent: parseInt(adAccount.amount_spent) / 100,
                    userHasAccess: userHasCorrectAccess
                }
            })
                .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {


                    const accountAccessA = a.userHasAccess
                    const accountAccessB = b.userHasAccess

                    if (accountAccessA && accountAccessB) {
                        return 0
                    }

                    if (accountAccessB && !accountAccessA) {
                        return 1
                    }

                    if (!accountAccessB && accountAccessA) {
                        return -1
                    }
                })
                .sort((a: any, b: any) => {


                    const accountStatusA = (AD_ACCOUNT_STATUS as any)[a.account_status].valid
                    const accountStatusB = (AD_ACCOUNT_STATUS as any)[b.account_status].valid

                    if (accountStatusA && accountStatusB || !accountStatusA && !accountStatusB) {
                        return 0
                    }

                    if (accountStatusB && !accountStatusA) {
                        return 1
                    }

                    if (!accountStatusB && accountStatusA) {
                        return -1
                    }
                })

            setAdAccounts(adAccounts);
            setLoadingItems(false)
        } catch (e) {
            setAdAccounts([]);
            setError(true)
        }
    }



    useEffect(() => {

        const loggedIn = fbLoggedIn()
        setFBCheckedLoginStatus(true)
        if (!loggedIn) {
            setUserFBLoggedIn(false)
            // subscribe to changes in login status
            FB().Event.subscribe('auth.statusChange', function (response: any) {
                if (response.status === "connected") {
                    setUserFBLoggedIn(true)
                    setError(null)
                    getAdAccounts()
                }
            });
        } else {
            setUserFBLoggedIn(true)
            setError(null)
            getAdAccounts()
        }
        // if fb not logged in, show login with FB button


        // Cleanup function to unsubscribe
        return () => {
            FB().Event.unsubscribe('auth.statusChange')
        };

    }, []);

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'ads_management',
            'ads_read',
            'business_management'
        ])
        if (!status || status.length > 0) {
            // if invalid, show dialog to re-request permissions
            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            // there are no pages
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }

        setLoadingItems(false)
    }

    useEffect(() => {
        // if we checked the pages, and they're empty - there 2 things that may
        // have happened here: 
        /**
         * 1. the artist declined "pages_show_list" permission, which we'll need alongside
         * "pages_read_engagement" to get access
         * 2. the artist has no pages, and we need to tell them to setup a page
         */
        if (checkedAdAccounts && availAdAccounts.length === 0) {
            checkPermissions()
        }
    }, [availAdAccounts])

    function updateInBrand() {
        setLoading(true)
        let facebook_ad_account = JSON.parse(JSON.stringify(selected));
        localStorage.setItem('facebook_ad_account', JSON.stringify(facebook_ad_account))

        Axios.put(`/brand/${artist.slug}/connect`, {
            id: artist.id,
            name: artist.slug,
            service: 'facebook_ad_account',
            value: selected
        })
            .then((result) => {
                reloadBrand()
                setLoading(false)
                closeModal()
            })
            .catch((err) => {
                setLoading(false)
                console.log('error creating brand', err)
            })
    }

    const handleConnect = useCallback(() => {
        updateInBrand()
    }, [artist.id, selected])

    /**
     * Tries to create a new ad account if the user doesn't have one. 
     *  - If this is on express setup, the user will need to link their business manager to create a new ad account.
     *  - otherwise they'll need to select an existing one
     * @returns 
     */
    async function createNewAdAccount() {
        if (creatingNewAccount) return

        if (!businessManager && showBusinessManagerSelector) {
            showBusinessManagerSelector()
        } else if (businessManager) {

            setCreationError(false)
            setCreatingNewAccount(true)

            try {
                // create new account 
                const creationUrl = `/${businessManager.id}/adaccount`
                const creationPayload = {
                    name: "Symphony Ad Account",
                    currency: "USD",
                    timezone_id: 1,
                    media_agency: "NONE",
                    end_advertiser: businessManager.id,
                    partner: process.env.REACT_APP_FACEBOOK_APP_ID
                }

                const creationPromise = new Promise(async (resolve, reject) => {
                    try {
                        const response = await queryFB(creationUrl, {
                            method: 'post',
                            params: creationPayload
                        })

                        if (!response || response.error) {
                            console.log('error', response)
                            reject(response.error)
                        } else {
                            resolve(response);
                        }
                    } catch (e: any) {
                        console.log("Error response", e)
                        reject(e.error.error_user_msg)

                    }
                })

                var createdAdAccount: any = await creationPromise

                // once created, assign the current user as an admin to the 
                // ad account ~ first find the "business user id" 
                // by looping thru business users and finding user where 
                // business.id matches current business id

                const getUserId = await queryFB('/me', {
                    method: 'get',
                    params: {
                        fields: 'business_users'
                    }
                })

                var userId = null

                var foundBusinessUser = getUserId.business_users.data.find((o: {
                    id: string,
                    name: string,
                    business: {
                        id: string,
                        name: string
                    }
                }) => {
                    return o.business.id === businessManager.id
                })

                if (foundBusinessUser) {
                    userId = foundBusinessUser.id
                } else {
                    throw "No business found for user"
                }


                const url = `/${createdAdAccount.id}/assigned_users`
                const payload = {
                    user: userId,
                    tasks: ["MANAGE", "ADVERTISE", "ANALYZE", "DRAFT"]
                }

                await new Promise(async (resolve, reject) => {
                    const response = await queryFB(url, {
                        method: 'post',
                        params: payload
                    })
                    if (!response || response.error) {
                        console.log('error adding user to ad account', response)
                        reject(response.error)
                    } else {
                        resolve(response);
                    }
                })

                set({

                    ...createdAdAccount,
                    name: "Symphony Ad Account"
                })



                updateInBrand()

            } catch (e) {
                setCreationError(e)
                console.error("Error creating ad account", e)
                setCreatingNewAccount(false)
            }
        }
    }


    async function callLoginWithFB() {
        console.log("callLoginWithFB")
        const p = await loginWithFB()
        console.log("p", p)
        // const loggedIn = fbLoggedIn()
        // console.log("loggedIn", loggedIn)
        // setFBCheckedLoginStatus(true)
        // if (!loggedIn) {
        //     setUserFBLoggedIn(false)
        // } else {
        //     setUserFBLoggedIn(true)
        //     setError(null)
        //     getPages()
        // }
    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any), "ads_management,ads_read,business_management,pages_manage_ads")
        if (p) {
            setError(null)
            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            getAdAccounts()
        }
    }

    useEffect(() => {
        saveData({
            loading,
            connectModalType: CreativeTabConnectTypes.FACEBOOK_AD_ACCOUNT_CONNECT,
            isDisabledConnectButton,
            handleConnect
        })
    }, [isDisabledConnectButton, handleConnect, loading])

    const AdAccountItem = ({ item }: { item: any }) => {
        const { userHasAccess, id, amount_spent, name, business = null, currency } = item
        const accountStatus = (AD_ACCOUNT_STATUS as any)[item.account_status]
        const formattedId = id.replace("act_", "")



        const adAccountUrl = (id: string, businessId?: string) => `https://business.facebook.com/accountquality/${businessId ? businessId + "/" : ""}${formattedId}`;
        const adAccountSettingsUrl = (id: string, businessId?: string) => `https://business.facebook.com/settings/ad-accounts/${formattedId}${businessId ? "?business_id=" + businessId : ""}`;

        const businessName = business && business.name ? business.name : null
        const businessId = business && business.id ? business.id : null

        const currencyNote = () => {
            if (currency !== 'USD') {
                const currencyDetails = CURRENCY_CODE_DATA[currency]
                if (currencyDetails) {
                    let name = currencyDetails.name_plural

                    return (
                        <p className="text-xs">This ad account uses the currency {name} ({currency}).</p>
                    )
                }
            }

            return null
        }
        return (
            <li
                key={id}
                className="sy-card w-full"
                onChange={() => accountStatus.valid && set(item)}
            >
                <AddAccountCtn valid={accountStatus.valid}>
                    <div className="flex flex-grow w-full flex-row gap-1">
                        <input
                            id={`facebook_${id}`}
                            type="radio"
                            className="cursor-pointer  mr-2"
                            disabled={!accountStatus.valid || !userHasAccess}
                            name='conect-facebook-pages'
                            value={selected}
                            checked={accountStatus.valid && userHasAccess && selected && selected.id === id}
                        />
                        <label htmlFor={`facebook_${id}`} className="cursor-pointer flex items-start flex-col gap-1">
                            <span>{name}</span>
                            {Boolean(amount_spent) &&
                                <p className="text-xs">{`${getSymbolFromCurrency(currency || DEFAULT_CURRENCY)}${millify(amount_spent, { precision: amount_spent < 1000 ? 0 : 1, lowercase: true })} Spent`}</p>
                            }
                            {currencyNote()}
                            {businessName && businessId && (<AddAccountChip>
                                <span>Business: {businessName}</span>
                                <span>({businessId})</span>
                            </AddAccountChip>)}
                        </label>
                        {!accountStatus.valid && (
                            <div className="flex items-center ml-auto">
                                <Chip label="Not available" disabled />
                            </div>
                        )}
                    </div>
                    <div className="flex items-center w-full pl-6">
                        {!accountStatus.valid && (
                            <AccountDisabledBanner
                                bannerMessage={`This ad account's status is ${accountStatus.name} and it cannot be used.`}
                                reviewAccountLink={externalLinkIcon}
                                linkText="Review your account"
                            />
                        )}
                        {!userHasAccess &&
                            <AccountDisabledBanner
                                bannerMessage="You don't have permission to advertise for this ad account. To use this account, click the link below to add yourself as an 'Advertiser':"
                                reviewAccountLink={
                                    businessManager
                                        ? adAccountSettingsUrl(id, businessManager.id)
                                        : adAccountSettingsUrl(id)
                                }
                                linkText="Go to Ad Account settings"
                            />
                        }
                    </div>
                </AddAccountCtn>
            </li>
        );
    };

    function handleNonError() {
        if (permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <>
                    {!loadingItems && availAdAccounts.length > 3 ? <div className="flex flex-row items-center border-b">
                        <img className="pl-5 lg:pl-10 w-auto" src={require('../../assets/images/search-icon.svg').default} />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchStr}
                            onChange={(e: any) => setSearchStr(e.target.value)}
                            className="pl-4 pr-6 py-4 outline-none border-gray-200 w-full"
                        />
                    </div> : null}
                    <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                        <>
                            {availAdAccounts && availAdAccounts.length > 0 && availAdAccounts
                                .filter((a: any) => {
                                    if (!(searchStr && searchStr !== "")) {
                                        return true
                                    } else {
                                        return a.name.toLowerCase().includes(searchStr.toLowerCase())
                                    }
                                })
                                .map((item: any) => (<AdAccountItem key={item.id} item={item} />))
                            }


                        </>
                        {showBusinessManagerSelector ? (
                            <>
                                <li className="cursor-pointer sy-card" onClick={createNewAdAccount}>
                                    <div className="flex items-center">
                                        {creatingNewAccount ? (
                                            <div className=" flex-none loading-blk">
                                                <div className="svg-loader">
                                                    <svg
                                                        className="svg-container"
                                                        height="36"
                                                        width="36"
                                                        viewBox="0 0 100 100"
                                                    >
                                                        <circle
                                                            className="loader-svg bg"
                                                            cx="50"
                                                            cy="50"
                                                            r="45"
                                                        ></circle>
                                                        <circle
                                                            className="loader-svg animate"
                                                            cx="50"
                                                            cy="50"
                                                            r="45"
                                                        ></circle>
                                                    </svg>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className="flex-shrink-0 mr-1 border w-8 h-8 flex border-green-500 text-green-500 text-3xl items-center justify-center"
                                                style={{
                                                    fontWeight: 500
                                                }}>
                                                +
                                            </div>
                                        )}
                                        <div className="flex items-center ">
                                            <div className="px-3 flex flex-col">
                                                <label className="text-green-500">{creatingNewAccount ? 'Creating Ad Account...' : 'Create a New Ad Account'}</label>
                                                {businessManager ? null : <label className="text-xs">You'll need to make sure your Facebook Business Manager is linked to Symphony.</label>}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {creationError ? (
                                    <li className="cursor-pointer sy-card bg-red-500" >
                                        <p className="text-sm text-center text-white">Ad Account Creation Error:<br />{creationError}
                                        </p>
                                    </li>
                                ) : null}
                            </>) : null}

                        <li
                        >
                            <div className="flex flex-col items-center">

                                <p className="my-4 px-5 md:px-10">Don't see your Facebook Ad Account listed? Make sure you've linked your ad account with Symphony by clicking below. If you still don't see it, you may need to create a new ad account.</p>
                                <button type="button"
                                    onClick={() => reRequestPermissions()}
                                    className="flex items-center justify-center w-full text-center p-4 text-primary font-semibold border-t border-b border-backgound-100">
                                    Link an Ad Account to Symphony&gt;
                                </button>
                            </div>

                        </li>
                    </ul>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        href="https://www.facebook.com/help/104002523024878/?helpref=uf_share" className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Create a New Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Facebook Ad Accounts Permissions &gt;
                    </button>
                </>
            )
        } else {
            return (<p className="mt-4">No Ad Accounts Found...</p>)
        }
    }


    return <div className={clsx("bg-white w-full h-full mx-auto", withBorderRadius && classes.withBorderRadius)}>
        <div
            className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">Connect Your Artist's Ad Account</p>
            <a onClick={closeModal}>
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </a>
        </div>
        <div className="middle-wrapper px-4 lg:px-10 ">
            <div className="mt-5 pb-5 border-b border-gray-200">
                <h4 className="mb-1 font-semibold ">
                    Select {artist.name}'s Facebook Ad Account
                </h4>
                <p className="text-md mb-2 text-gray-500">Connect the ad account you primarily use to run ads. This is where your ad spend will be billed. If you don’t see an ad account, try creating or linking a new ad account.</p>
                {checkedFBLoginStatus && !userFBLoggedIn && (
                    <>
                        <div className="mt-2">
                            <PrimaryButton
                                onClick={() => callLoginWithFB()}
                                text="Login with Facebook" />
                        </div>
                        {/* {isSafari && (<button
                                className="mt-2 font-medium"
                                style={{
                                    color: "#8800FF"
                                }}
                                onClick={() => {
                                    Intercom.openFacebookInstagramConnectorArticle()
                                }}>Learn how to allow Facebook Login on Safari &gt;</button>)} */}
                    </>
                )}
            </div>

            {loadingItems ? (
                <div className="mt-3 flex items-center justify-center">
                    <LoadingIndicator
                        color="black"
                        height="30px" />
                </div>) : handleNonError()}
        </div>
    </div>

}
