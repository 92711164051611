import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  inputBorder: {
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    '&:hover': {
      borderColor: '#8800FF',
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  inputDisable: {
    background: "#EDECF2",
    borderColor: "#EDECF2",
    color: "#000000",
    cursor: 'not-allowed',
    borderRadius: "6px",
    '& fieldset': {
      border: 'none',
    },
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16
    }
  },
}))
