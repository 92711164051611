import clsx from "clsx";
import millify from "millify";
import { useEffect, useState, useContext, useCallback } from "react";
import FB, { checkForFBPermissions, loginWithFB, queryFB } from "../../helpers/FB";
import Axios from "../../helpers/Interceptor";
import { LoadingIndicator } from "../Loader";
import useStyles from "./styles"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import { CreativeTabConnectTypes, CurrentBrand, InstagramPageType } from "types/global";
import { siteIconsData } from "pages/post-auth/DashboardModule/Dashboard";
import { track } from "analytics";
import Intercom from "helpers/Intercom";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Facebook Pages because we don't have permission. Click the button below to authorize access to your pages."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Facebook pages associated with your account. Please create a new Facebook page by following the instructions at the link."
    }
}
export default function CampaignCreationFacebookConnect(props: any) {

    const {
        closeModal,
        selectedFBPage,
        selectFBPage,
        selectIgPage,
        artist,
        showSave,
        localSave,
        withBorderRadius,
        fromIgConnect
    } = props

    const { saveData } = useContext(ConnectModalsContext) || {}
    const { reloadBrand } = useContext(CurrentBrandContext);

    const classes = useStyles();

    // searching for pages
    const [searchStr, setSearchStr] = useState('');

    // status for checking pages
    const [facebookPages, setPages] = useState<any>([])
    const [checkedPages, setCheckedPages] = useState<boolean>(false)
    const [loadingPages, setLoadingPages] = useState<any>(null)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // error status
    const [error, setError] = useState<any>(null)

    // permissions error
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES>(ERROR_STATES_STATUSES.NONE)

    const isDisabledConnectButton = error || !selectedFBPage

    const getPages = async () => {
        setCheckedPages(true)
        setLoadingPages(true)
        try {

            const results = await queryFB(`/me/accounts`, {
                params: {
                    fields: 'name,category,id,access_token,followers_count,picture{url},is_published,connected_instagram_account{username,id,followers_count,profile_picture_url},instagram_business_account{username,id,followers_count,profile_picture_url}',
                    limit: 200
                }
            })

            const filtered = results.data
                .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {


                    const accountStatusA = a.is_published
                    const accountStatusB = b.is_published

                    if (accountStatusA && accountStatusB || !accountStatusA && !accountStatusB) {
                        return 0
                    }

                    if (accountStatusB && !accountStatusA) {
                        return 1
                    }

                    if (!accountStatusB && accountStatusA) {
                        return -1
                    }
                })

            setPages(filtered);
            if (selectedFBPage) {
                const selectedPage = filtered.find((page:{id: string}) => page.id === selectedFBPage?.id)
                selectFBPage(selectedPage)
            }
        } catch (e) {
            console.log('e', e)
            track('Error pulling Facebook - getPages in Facebook Connector', e)

            setError(true)
        }
        setLoadingPages(false)

    }

    useEffect(() => {
        setError(null)
        getPages()
    }, []);

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'pages_show_list',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'ads_read'
        ])
        if (!status || status.length > 0) {
            // if invalid, show dialog to re-request permissions
            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            // there are no pages
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }
    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any), "pages_show_list,pages_read_engagement")
        if (p) {
            setError(null)
            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            getPages()
        }
    }

    useEffect(() => {
        // if we checked the pages, and they're empty - there 2 things that may
        // have happened here: 
        /**
         * 1. the artist declined "ads_management" permission, which we'll need alongside
         * "pages_read_engagement" to get access
         * 2. the artist has no pages, and we need to tell them to setup a page
         */
        if (checkedPages && facebookPages.length === 0) {
            checkPermissions()
        }
    }, [facebookPages])

    async function updateInBrand() {

        // connect to brand
        // ~~
        // setConnectModal('');

        // first save the FB, then save the IG
        setLoading(true)
        try {
            const connectFBPromise = new Promise(async (resolve, reject) => {
                try {
                    var fbPayload = {
                        facebook_page: selectedFBPage,
                        logged_in_fb_user: null
                    }

                    const authResp = FB().getAuthResponse()
                    if (authResp && authResp.accessToken) {
                        fbPayload.logged_in_fb_user = authResp.accessToken
                    } else {
                        // login w fb and try again 
                        await loginWithFB()
                        const authResp = FB().getAuthResponse()
                        if (authResp && authResp.accessToken) {
                            fbPayload.logged_in_fb_user = authResp.accessToken
                        }
                    }

                    await Axios.put(`/brand/${artist.slug}/connect`, {
                        id: artist.id,
                        name: artist.name,
                        service: 'facebook_page',
                        value: fbPayload
                    })

                    resolve(1)
                } catch (e) {
                    console.error("Error connecting Facebook", e)

                    reject("Error connecting Facebook")
                }
            })

            await connectFBPromise
        } catch (e) {
            console.error("Error connecting Facebook", e)
            track('Error connecting Facebook  - updateInBrand in Facebook Connector', {
                error: e
            })
        }


        try {
            const connectIGPromise = new Promise(async (resolve, reject) => {
                try {


                    // to connect IG, we need to get the `instagram_accounts` field from the FB Page

                    // In case of error just get the instagram_accounts with the page access token to safely continue

                    var igAccount = await queryFB(`/${selectedFBPage!.id}/instagram_accounts`, {
                        params: {
                            fields: 'id,follow_count,followed_by_count,username,profile_pic,is_private,is_published',
                            access_token: selectedFBPage?.access_token,
                            forceToken: selectedFBPage?.access_token
                        }
                    })

                    if (igAccount && igAccount.data && igAccount.data.length > 0) {
                        igAccount = igAccount.data[0]

                    } else {
                        throw "No Instagram Accounts found"
                    }

                    const p = await Axios.put(`/brand/${artist.slug}/connect`, {
                        id: artist.id,
                        name: artist.name,
                        service: 'instagram_page',
                        value: {
                            businessAccount: selectedFBPage.instagram_business_account,
                            instagramAccount: igAccount,
                            connected_instagram_account: selectedFBPage.connected_instagram_account ? selectedFBPage.connected_instagram_account : null
                        }
                    })

                    var autoSelectedIg: InstagramPageType = {
                        id: selectedFBPage!.id,
                        name: selectedFBPage!.name,
                        connected_instagram_account: selectedFBPage.connected_instagram_account ? selectedFBPage.connected_instagram_account : null,
                        businessAccount: selectedFBPage.instagram_business_account,
                        instagramAccount: igAccount
                    }

                    selectIgPage(autoSelectedIg)
                    resolve(1)
                } catch (e) {
                    console.error("Error connecting Instagram within Facebook", e)
                    reject("Error connecting Instagram")

                }
            })

            await connectIGPromise

        } catch (e) {
            console.error("Error connecting Instagram", e)
            selectIgPage(null)

            track('Error connecting Instagram - updateInBrand in Facebook Connector', {
                error: e
            })
        }

        setLoading(false)
        closeModal()
    }

    const handleConnect = async () => {
        if (!localSave) {
            await updateInBrand()
            await reloadBrand()
        } else {
            closeModal()
        }
    }

    useEffect(() => {
        saveData({
            loading,
            connectModalType: CreativeTabConnectTypes.FACEBOOK_CONNECT,
            isDisabledConnectButton,
            handleConnect
        })
    }, [isDisabledConnectButton, selectedFBPage, loading])

    function handleNonError() {


        if (permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <>
                    {facebookPages.length > 3 ? <div className="flex flex-row items-center border-b">
                        <img className="pl-5 lg:pl-10 w-auto" src={require('../../assets/images/search-icon.svg').default} />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchStr}
                            onChange={(e: any) => setSearchStr(e.target.value)}
                            className="pl-4 pr-6 py-4 outline-none border-gray-200 w-full"
                        />
                    </div> : null}

                    <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                        {loadingPages ? (<li className="cursor-pointer sy-card flex items-center justify-center">
                            <LoadingIndicator color="black" height="30px" />
                        </li>) : null}
                        {facebookPages.length > 0 ? (
                            <>{
                                facebookPages
                                    .filter((a: any) => {
                                        if (!(searchStr && searchStr !== "")) {
                                            return true
                                        } else {
                                            return a.name.toLowerCase().includes(searchStr.toLowerCase())
                                        }
                                    })
                                    .map((item: any) => {
                                        const connected_instagram_account = item.connected_instagram_account

                                        return (
                                            <label htmlFor={'facebook_' + item.id}>
                                                <li onChange={() => selectFBPage(item)} key={item.id} className="cursor-pointer sy-card">

                                                    <div className="flex items-center">
                                                        <input
                                                            disabled={!item.is_published}

                                                            id={'facebook_' + item.id} type="radio" className="mr-2" name={'conect-facebook-pages'} value={selectedFBPage} checked={selectedFBPage ? selectedFBPage.id === item.id : false} />

                                                        <div className="flex items-center">
                                                            <div className="artist-img" style={(!item.image_url || item.image_url === '') ? { background: '#07c806', borderRadius: '50%' } : {}}>
                                                                <img
                                                                    src={(item.picture && item.picture.data && item.picture.data.url) || require("../../assets/images/profileIcon.svg").default}
                                                                />

                                                            </div>
                                                            <div className="px-3">
                                                                <label htmlFor={'facebook_' + item.id} className="cursor-pointer" style={{ width: "100%", display: "inline-block" }}>{item.name} [{item.category}]</label>
                                                                <div className="flex flex-row items-center">

                                                                    <div className="social-text">
                                                                        <p className="text-xs">
                                                                            {item.followers_count > 0 ? millify(item.followers_count) : 0} followers
                                                                        </p>
                                                                        {connected_instagram_account ? (
                                                                            <div className="mt-1">
                                                                                <p

                                                                                    className=" text-xs flex items-center justify-start text-black">Linked to:
                                                                                    <p
                                                                                        className=" text-xs text-black flex items-center justify-start"

                                                                                    ><img
                                                                                            className="h-4 w-4 mx-1 saturate-0	"
                                                                                            src={siteIconsData['instagram']} /> @{connected_instagram_account.username}</p>
                                                                                </p>
                                                                            </div>
                                                                        ) : null}
                                                                        {!item.is_published ? (
                                                                            <>
                                                                                <p className={'text-red-500 text-xs'}>This Page is not published, and must be live before you can use it to start running ads.</p>
                                                                                <a className="text-red-500 text-xs underline"
                                                                                    href={`https://www.facebook.com/${item.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                                    target="_blank">Click here to publish the page: {`https://www.facebook.com/${item.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                                </a>
                                                                            </>
                                                                        )
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*  */}
                                                    </div>

                                                </li>
                                            </label>
                                        );
                                    })
                                // .splice(0, 3)
                            }
                                <li
                                >
                                    <div className="flex flex-col items-center">

                                        <p className="my-4 px-5 md:px-10">Don't see your Facebook page listed? Make sure you've linked your page with Symphony by clicking below. If you still don't see it, you may need to get access to the page.</p>
                                        <button type="button"
                                            onClick={() => reRequestPermissions()}
                                            className="flex items-center justify-center w-full text-center p-4 text-primary font-semibold border-t border-b border-backgound-100">
                                            Link a Page to Symphony&gt;
                                        </button>
                                        <button type="button"
                                            onClick={() => Intercom.openFacebookPageAccessArticle()}
                                            className="flex items-center justify-center w-full text-center p-4 text-primary font-semibold border-t border-b border-backgound-100">
                                            How to get Facebook Page Access &gt;
                                        </button>

                                    </div>

                                </li>
                            </>) : null}



                    </ul>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        href="https://www.facebook.com/help/104002523024878/?helpref=uf_share" className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Create a New Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Facebook Pages Permissions &gt;
                    </button>
                </>
            )
        }

    }




    return <div className={clsx("bg-white w-full h-full mx-auto", withBorderRadius && classes.withBorderRadius)}>
        <div
            className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">Connect Facebook Page</p>
            <a onClick={closeModal} className="cursor-pointer">
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </a>
        </div>
        <div className={`${localSave || showSave ? "middle-wrapper" : ""} px-4 lg:px-10`}>
            <div className="mt-5 pb-5 border-b border-gray-200">
                <h4 className="mb-1 font-semibold ">
                    {!fromIgConnect ? `Select ${artist.name}'s Facebook Page` : `To connect Instagram, first select ${artist.name}'s Facebook Page`}
                </h4>
                <p className="text-md mb-2 text-gray-500">This is the Facebook business Page that you primarily use to post updates and run ads.</p>
            </div>
            {error ? (<p className="mt-4">No Facebook Pages found...</p>) : handleNonError()}
        </div>
    </div >

}