import { Dialog, Paper, Slide } from '@material-ui/core';
import { FunctionComponent, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { BackgroundTypes, CampaignDetails, StyleTypes, Website } from 'types/global';
import { ButtonsSaveCancelContainer, CustomizeTabContainer, MainContainer, useStyles } from './styles';
import TopBar from 'components/shareable/TopBar';
import CustomizePresaveForm from '../CustomizeForm';
import RecordPreview from 'components/shareable/RecordPreview';
import { getRecordPreviewContainerStyles } from '../../utils';
import PrimaryButton from 'components/shareable/PrimaryButton';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import Axios from 'helpers/Interceptor';
import useModal from 'Hooks/useModal';
import ExitConfirmationModal from './ExitConfirmationModal';
import { TransitionProps } from '@mui/material/transitions';
import { showToast } from 'utils';
import SecondaryButton from 'components/shareable/SecondaryButton';
import DesignPresaveButton from '../DesignPresaveButton';
import PreviewModal from '../PresavePreview/PreviewModal';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';
import { track } from 'analytics';

interface Props {
  open: boolean;
  closeModal: () => void;
  onSave?: (campaign: CampaignDetails) => void;
  campaign: CampaignDetails;
  trackObject?: Record<string, any>;
}

const Transition = forwardRef<
  unknown,
  TransitionProps & {
    children?: React.ReactElement
  }
>((props, ref) => {
  return <Slide direction='up' ref={ref} {...props} />
})

const EditPresaveModal: FunctionComponent<Props> = ({
  open,
  closeModal,
  onSave,
  campaign,
  trackObject = {},
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { id, campaign_metadata } = campaign
  const { content, presave } = campaign_metadata
  const {
    style,
    options,
    platforms = ["spotify", "apple_music", "audiomack", "soundcloud"],
  } = presave || {}

  const { currentBrand } = useContext(CurrentBrandContext);
  const [releaseTitle, setReleaseTitle] = useState<string>(content.name)
  const [subtitle, setSubtitle] = useState<string>(presave?.subtitle || currentBrand?.name || "")
  const [addSubtitle, setAddSubtitle] = useState<boolean>(Boolean(presave?.subtitle))
  const [artworkUrl, setArtworkUrl] = useState<string>(content.thumbnail_url)
  const [buttonConfig, setButtonConfig] = useState<StyleTypes>(style?.buttonConfig as StyleTypes)
  const [bgType, setBgType] = useState<BackgroundTypes>(style?.bgType as BackgroundTypes || BackgroundTypes.COLOR)
  const [primaryColor, setPrimaryColor] = useState<string>(style?.primaryColor || '')
  const [secondaryColor, setSecondaryColor] = useState<string>(style?.secondaryColor || '')
  const [bgColor, setBgColor] = useState<string>(style?.bgColor || '')
  const [withCustomWebPage, setWithCustomWebPage] = useState<boolean>(Boolean(options?.redirect_to_custom_webpage))
  const [customWebPageUrl, setCustomWebPageUrl] = useState<string>(options?.redirect_url || '')

  const showPreviewMobileModal = useModal();
  const exitConfirmationModal = useModal();

  const classes = useStyles()
  const { smallView } = useMediaBreakpoints()
  const { style: containerStyle, className: containerClassName } =
    getRecordPreviewContainerStyles({
      classes,
      backgroundColor: bgColor,
      backgroundType: bgType,
    });

  const hasChange = useMemo(() => {
    return (
      (releaseTitle !== content.name && releaseTitle.length > 0) ||
      addSubtitle !== Boolean(presave?.subtitle) ||
      (addSubtitle && subtitle !== (presave?.subtitle || "")) ||
      artworkUrl !== content.thumbnail_url ||
      buttonConfig !== style?.buttonConfig ||
      bgType !== style?.bgType ||
      primaryColor !== style?.primaryColor ||
      secondaryColor !== style?.secondaryColor ||
      bgColor !== style?.bgColor ||
      withCustomWebPage !== Boolean(options?.redirect_to_custom_webpage) ||
      customWebPageUrl !== (options?.redirect_url || "")
    )
  }, [
    releaseTitle,
    subtitle,
    addSubtitle,
    artworkUrl,
    buttonConfig,
    bgType,
    primaryColor,
    secondaryColor,
    bgColor,
    withCustomWebPage,
    customWebPageUrl,
  ])

  const reset = () => {
    setReleaseTitle(content.name)
    setSubtitle(presave?.subtitle || currentBrand?.name || "")
    setAddSubtitle(Boolean(presave?.subtitle))
    setArtworkUrl(content.thumbnail_url)
    setButtonConfig(style?.buttonConfig as StyleTypes)
    setBgType(style?.bgType as BackgroundTypes || BackgroundTypes.COLOR)
    setPrimaryColor(style?.primaryColor || '')
    setSecondaryColor(style?.secondaryColor || '')
    setBgColor(style?.bgColor || '')
    setWithCustomWebPage(Boolean(options?.redirect_to_custom_webpage))
    setCustomWebPageUrl(options?.redirect_url || '')
  }

  useEffect(() => {
    reset()
  }, [campaign])

  const handleSave = async () => {
    setLoading(true)
    try {
      const body = {
        style: {
          bgType,
          bgColor,
          buttonConfig,
          primaryColor,
          secondaryColor,
        },
        name: releaseTitle,
        subtitle: addSubtitle ? subtitle : '',
        artworkImage: artworkUrl,
        showSecondaryLine: addSubtitle,
        options: {
          ...options,
          redirect_to_custom_webpage: withCustomWebPage,
          redirect_url: customWebPageUrl,
        },
      }
      const updatedCampaign = await Axios.put(`/campaign/presave/${campaign.id}/style`, body)
      if (!updatedCampaign?.data || updatedCampaign.data.isError) {
        throw new Error(updatedCampaign.data.message)
      }
      onSave?.(updatedCampaign.data.data as CampaignDetails)
      showToast({ message: 'Pre-save design updated successfully.', mobile: smallView })
      track("Saved Edit Design", {
        ...trackObject,
        opened: 'Edit Design Modal'
      })
      closeModal()
    } catch (error) {
      showToast({ message: 'Error updating campaign', error: true, mobile: smallView })
    } finally {
      setLoading(false)
    }
  };

  const handleDiscardChanges = () => {
    exitConfirmationModal.closeModal();
    reset();
    closeModal();
  }

  const handleClose = () => {
    if (hasChange) {
      exitConfirmationModal.openModal();
    } else {
      closeModal();
    }
  };

  return (
    <Dialog
      open={open}
      classes={{
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialogFullScreen,
      }}
      fullScreen
      TransitionComponent={Transition}
      transitionDuration={{
        enter: 300,
        exit: 200,
      }}
    >
      <TopBar title="Page preview" showGoBackIcon handleGoBack={handleClose} />
      <MainContainer
        paddingBottom={`${smallView ? 175 : 108}px`}
        paddingTop={smallView ? "8px" : "32px"}
      >
        <CustomizeTabContainer>
          <CustomizePresaveForm
            key={id}
            releaseTitle={releaseTitle}
            setReleaseTitle={setReleaseTitle}
            addSubtitle={addSubtitle}
            subtitle={subtitle}
            setAddSubtitle={setAddSubtitle}
            setSubtitle={setSubtitle}
            artworkUrl={artworkUrl}
            setSelectedArtworkUrl={setArtworkUrl}
            buttonConfig={buttonConfig}
            setButtonConfig={setButtonConfig}
            bgType={bgType}
            setBgType={setBgType}
            backgroundColor={bgColor}
            setSelectedBackgroundColor={setBgColor}
            primaryColor={primaryColor}
            setPrimaryColor={setPrimaryColor}
            secondaryColor={secondaryColor}
            setSecondaryColor={setSecondaryColor}
            withCustomWebPage={withCustomWebPage}
            setWithCustomWebPage={setWithCustomWebPage}
            customWebPageUrl={customWebPageUrl}
            setCustomWebPageUrl={setCustomWebPageUrl}
          />
        </CustomizeTabContainer>
        <div className={classes.previewColumn}>
          <div className={containerClassName} style={containerStyle}>
            {bgType === BackgroundTypes.ARTWORK && (
              <img
                alt="artwork_as_background"
                src={artworkUrl}
                className="filter blur-lg absolute w-auto object-cover h-full"
              />
            )}
            <RecordPreview
              className={classes.principalPreviewContainer}
              backgroundColor={bgColor}
              contentDetails={{
                name: releaseTitle,
                options: platforms.length
                  ? platforms.map((p) => {
                    return { name: p, show: true };
                  })
                  : [
                    { name: "spotify", show: true },
                    { name: "apple_music", show: true },
                    { name: "audiomack", show: true },
                    { name: "soundcloud", show: true }
                  ],
                artworkUrl,
                subtitle: addSubtitle ? subtitle : "",
              }}
              backgroundType={bgType}
              mainColor={primaryColor}
              secondaryColor={
                buttonConfig === StyleTypes.STACKED
                  ? primaryColor
                  : secondaryColor
              }
              styleType={buttonConfig}
            />
          </div>
        </div>
      </MainContainer>
      <Paper
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 20,
          height: "fit-content",
        }}
        elevation={3}
      >
        {smallView && (
          <DesignPresaveButton
            openPresaveModal={showPreviewMobileModal.openModal}
            presaveImage={artworkUrl}
            style={
              {
                bgType,
                bgColor,
                buttonConfig,
                primaryColor,
                secondaryColor,
              } as Website
            }
          />
        )}
        <ButtonsSaveCancelContainer
          padding={smallView ? "16px" : "16px 10%"}
          borderTop={smallView ? "1px solid #EDECF2" : "none"}
        >
          <SecondaryButton
            onClick={handleClose}
            text="Cancel"
            width="fit-content"
            error
          />
          <PrimaryButton
            onClick={handleSave}
            disabled={!hasChange}
            loading={loading}
            text="Save"
            width={smallView ? "100%" : "fit-content"}
          />
        </ButtonsSaveCancelContainer>
      </Paper>
      <ExitConfirmationModal
        {...exitConfirmationModal}
        onDiscardChanges={handleDiscardChanges}
        trackObject={trackObject}
      />
      {smallView && (
        <PreviewModal
          open={showPreviewMobileModal.open}
          closePreview={showPreviewMobileModal.closeModal}
          style={
            {
              bgType,
              bgColor,
              buttonConfig,
              primaryColor,
              secondaryColor,
            } as Website
          }
          contentDetails={{
            name: releaseTitle,
            options: platforms.map((p) => {
              return { name: p, show: true };
            }),
            artworkUrl,
            subtitle: addSubtitle ? subtitle : "",
          }}
          styleType={buttonConfig}
          bgColor={bgColor}
        />
      )}
    </Dialog>
  );
}

export default EditPresaveModal;
