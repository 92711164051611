/* eslint-disable no-throw-literal */
import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";
import Logger from "Logger";
import TapfiliateClient from "analytics/tapfiliate";

export const finalizeSubscription = async (
  session_id: string,
  brandSlug: string | null,
) => {
  try {
    const finalizeCall = await Axios.post(
      `/subscription/brand/${brandSlug}/finalize`,
      { session_id }
    );


    if (finalizeCall &&
      finalizeCall.data && finalizeCall.data.data) {
      const mainData = finalizeCall.data.data

      if (mainData && mainData.subscription) {
        const subscriptionDetail = mainData.subscription
        if (subscriptionDetail && subscriptionDetail.stripe_metadata
          && subscriptionDetail.stripe_metadata.customer) {
          const stripeMetadata = subscriptionDetail.stripe_metadata
          const customer = stripeMetadata.customer
          const invoice = stripeMetadata.invoice 

          const customerId = customer.id

          const chargeId = invoice.charge


          Logger.log('invoice', invoice)
          Logger.log('customer', customer)
          Logger.log('customerId', customerId)
          Logger.log('chargeId', chargeId)
          if (customerId) {
            // Check if the trial is over and free trial is also over
            if (subscriptionDetail.isTrial === false && subscriptionDetail.freeTrialOver === true) {
              // Call createConversion function with required data
              if (chargeId) {
                const stripeChargeId = chargeId; // You might need to adjust this depending on where the charge ID is located
                const orderAmount = invoice.amount_paid / 100; // Convert to dollars if it's in cents
                Logger.log('orderAmount', orderAmount)
                
                const metaData = {
                  email: customer.email,
                  brandSlug: brandSlug ? brandSlug : ""
                  // Add any other meta data here if needed
                };

                TapfiliateClient.createConversion(stripeChargeId, orderAmount, customerId, metaData);
              }
            } else {
              // this starts the trial using the tapfiliate user
              TapfiliateClient.captureTrialStart(customerId);
            }
          }

        }
      }
    } else {
      // throw "Subscription data not found."
    }
    return finalizeCall.data;

  } catch (error) {
    Sentry.captureException(error);
    console.error("error: ", error);
  }
};
