import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) => ({
  dataColumn: {
    paddingBottom: theme.spacing(0),
    width: "100%",
  },
  rootCheckbox: {
    color: "#07C806",
  },
}));

export const ErrorMessage = styled.div`
  color: #ff3d3d;
  font-family: DIN;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 0px;
`;