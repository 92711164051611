import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
`;
