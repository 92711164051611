import { Fragment, FunctionComponent } from 'react';
import { ReactComponent as SpotifyIcon } from 'assets/images/streaming/icons/spotify-i.svg';
import { ReactComponent as DeezerIcon } from 'assets/images/streaming/icons/deezer-icon.svg'
import { ReactComponent as AppleMusicIcon } from 'assets/images/streaming/icons/apple-icon.svg'
import { ReactComponent as AudiomackIcon } from 'assets/images/streaming/icons/audiomack-icon.svg'
import { ReactComponent as SoundcloudIcon } from 'assets/images/streaming/icons/soundcloud-icon.svg'


import { PlatformCellContainer } from './styles';

interface Props {
  platformKey?: string,
  flexDirection?: string,
  justifyContent?: string,
}

const iconSize = "18px"

const PLATFORM_LOGO_MAPPING: Record<string, { title: string, icon: JSX.Element }> = {
  "spotify": {
    "title": "Spotify",
    "icon": <SpotifyIcon width={iconSize} height={iconSize} />,
  },
  "deezer": {
    "icon": <DeezerIcon width={iconSize} height={iconSize} />,
    "title": "Deezer"
  },
  "apple_music": {
    "icon": <AppleMusicIcon width={iconSize} height={iconSize} />,
    "title": "Apple Music"
  },
  "audiomack": {
    "icon": <AudiomackIcon width={iconSize} height={iconSize} />,
    "title": "Audiomack"
  },
  "soundcloud": {
    "icon": <SoundcloudIcon width={iconSize} height={iconSize} />,
    "title": "SoundCloud"
  },
  "manually": {
    "icon": <Fragment />,
    "title": "Added manually"
  },
  "none": {
    "icon": <Fragment />,
    "title": "-"
  }
}

const PlatformCell: FunctionComponent<Props> = ({
  platformKey = "none",
  justifyContent,
  flexDirection,
}: Props) => {
  const { icon, title } = PLATFORM_LOGO_MAPPING[platformKey] ?? PLATFORM_LOGO_MAPPING["none"]
  
  return <PlatformCellContainer flexDirection={flexDirection} justifyContent={justifyContent}>
    {icon}
    <span>{title}</span>

  </PlatformCellContainer>
}

export default PlatformCell;