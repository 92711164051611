import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import useStyles, {
    AutomatedTargetingBanner,
    AutomatedTargetingDescription,
    DropDownContainer,
    DropDownInputContainer,
    DropDownTitle,
    ItemContainer,
    StyledLabel,
    TargetingAgeGenderModalContainer,
    SaveTargetingButton,
    ExitModalButton,
    ErrorBanner,
} from './styles';
import CloseIcon from "components/shareable/CloseIcon"
import { Dialog, Switch } from '@material-ui/core';
import { Body2 } from 'components/shareable/Typography';
import { FBCustomAudience, FacebookPageResponse, LanguageChip } from 'types/global';
import useVisible from 'Hooks/useVisible';
import { queryFB } from 'helpers/FB';
import { LoadingIndicator } from 'components/Loader';
import RowItem from 'components/shareable/RowItem';
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import Loaderv2 from 'components/Loader/v2';
import millify from 'millify';
import { ERROR_STATES_STATUSES } from 'pages/post-auth/AddArtist/Components/ConnectPlatformModal/Instagram';
import { siteIconsData } from 'pages/post-auth/DashboardModule/Dashboard';
import DialogPaper from 'components/shareable/DialogPaper';
import HeaderModal from '../HeaderModal';
import { ConnectWithoutContact } from '@mui/icons-material';



interface Props {
    openTargetingModal: boolean;
    setOpenTargetingModal: (value: boolean) => void;
    isMobile: boolean;
    selectedFBPage: any;
    discardChanges: () => void;
    handleClose: () => void;
    customAudiences: FacebookPageResponse[];
    setCustomAudiences: (value: FacebookPageResponse[]) => void;
}

const tooltipMessage = 'Turn off the Automated targeting to edit';

const TargetingAdditionalPageAudiences: FunctionComponent<Props> = ({
    selectedFBPage,
    openTargetingModal,
    setOpenTargetingModal,
    isMobile,
    discardChanges,
    handleClose,
    setCustomAudiences,
    customAudiences
}: Props) => {

    const classes = useStyles();


    const handleOnCloseModal = () => setOpenTargetingModal(false);

    const [selectedPagesToAdd, setSelectedPagesToAdd] = useState<FacebookPageResponse[]>([]);

    const handleOnSaveTargeting = () => {
        setCustomAudiences(selectedPagesToAdd);
        handleOnCloseModal();
    }




    // searching for pages
    const [searchStr, setSearchStr] = useState('');

    // status for checking pages
    const [facebookPages, setFacebookPages] = useState<FacebookPageResponse[]>([])
    const [checkedPages, setCheckedPages] = useState<boolean>(false)
    const [loadingPages, setLoadingPages] = useState<any>(null)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // error status
    const [error, setError] = useState<any>(null)

    // TODO: Add permissions checking in the future for Pages 
    // const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES>(ERROR_STATES_STATUSES.NONE)



    const getPages = async () => {
        setLoading(true)
        try {

            const results = await queryFB(`/me/accounts`, {
                params: {
                    fields: 'name,category,id,access_token,followers_count,picture{url},is_published,connected_instagram_account{username,id,followers_count,profile_picture_url},instagram_business_account{username,id,followers_count,profile_picture_url}',
                    limit: 200
                }
            })

            const filtered = results.data
                .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {


                    const accountStatusA = a.is_published
                    const accountStatusB = b.is_published

                    if (accountStatusA && accountStatusB || !accountStatusA && !accountStatusB) {
                        return 0
                    }

                    if (accountStatusB && !accountStatusA) {
                        return 1
                    }

                    if (!accountStatusB && accountStatusA) {
                        return -1
                    }
                })


            setFacebookPages(filtered);
        } catch (e) {
            console.log('e', e)

            setError(true)
        }
        setLoadingPages(false)
        setLoading(false)

    }

    useEffect(() => {
        if (openTargetingModal) {
            if (!loading && facebookPages.length === 0) {
                getPages();
            }

            if (customAudiences && customAudiences.length > 0) {
                setSelectedPagesToAdd(customAudiences);
            }
        }
    }, [openTargetingModal]);


    const filteredFbPages = facebookPages
        .filter((a: any) => {
            if (!(searchStr && searchStr !== "")) {
                return true
            } else {
                return a.name.toLowerCase().includes(searchStr.toLowerCase())
            }
        })

    const selectedId = selectedFBPage ? selectedFBPage.id : null
    return (
        <DialogPaper open={openTargetingModal}>
            <Fragment>
                <HeaderModal
                    // HeaderIcon={ConnectWithoutContact.toString}
                    closeModal={() => setOpenTargetingModal(false)}
                    title='Target Audiences from Other Facebook + Instagram Pages'
                    subtitle='Select other Facebook pages you have access to, and Symphony will automatically target their fans on FB, Instagram, and associated audiences we make automatically.'
                />

                <TargetingAgeGenderModalContainer gap={isMobile ? '24px' : '40px'} padding={isMobile ? '0px 0px' : '0px 0px'}>


                    <div className='w-full'>
                        {loading ? (
                            <div className="flex-col flex items-center m-8 mx-auto ">
                                <Loaderv2 />
                                <p className="mt-1">Loading FB Pages...</p>
                            </div>
                        ) : null}
                        {!loading && facebookPages.length > 3 ? <div className="flex flex-row items-center border-t w-full">
                            <img className="pl-5 lg:pl-10 w-auto" src={require('../../../../../assets/images/search-icon.svg').default} />
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchStr}
                                onChange={(e: any) => setSearchStr(e.target.value)}
                                className="pl-4 pr-6 py-4 outline-none border-gray-200 w-full"
                            />
                        </div> : null}
                        {!loading && facebookPages && facebookPages.length > 0 ? (

                            <ul className="add-new border-b border-t max-h-72 overflow-auto w-full">
                                {filteredFbPages.length > 0 ? filteredFbPages
                                    .sort((a: any, b: any) => {

                                        return a.name.localeCompare(b.name)
                                    })
                                    .map((facebookPage: any) => {

                                        const selected = selectedPagesToAdd.findIndex((selectedFBPage: any) => {
                                            return selectedFBPage.id === facebookPage.id
                                        }) > -1

                                        return (
                                            <RowItem
                                                multiSelect
                                                selected={selected}
                                                disabled={!facebookPage.is_published || selectedId === facebookPage.id}
                                                key={facebookPage.id}
                                                onClick={() => {
                                                    if (!facebookPage.is_published || selectedId === facebookPage.id) return
                                                    if (!selected) {
                                                        const updatedSelected = [
                                                            ...selectedPagesToAdd
                                                        ]

                                                        updatedSelected.push(facebookPage)
                                                        setSelectedPagesToAdd(updatedSelected)

                                                    } else {
                                                        const updated = selectedPagesToAdd.filter((selectedFBPage: any) => {
                                                            return selectedFBPage.id !== facebookPage.id
                                                        })


                                                        setSelectedPagesToAdd(updated)
                                                    }

                                                }}
                                                // onClick={() => selectYoutubeChannel(facebookPage)}
                                                name={`${facebookPage.name} [${facebookPage.category}]`}
                                                image={null}//
                                                imageComponent={(
                                                    <div className="artist-img flex-shrink-0" style={(!facebookPage.image_url || facebookPage.image_url === '') ? { background: '#07c806', borderRadius: '50%' } : {}}>
                                                        <img
                                                            src={(facebookPage.picture && facebookPage.picture.data && facebookPage.picture.data.url) || require("../../../../../assets/images/profileIcon.svg").default}
                                                        />

                                                    </div>
                                                )}
                                                secondRowContent={(
                                                    <>
                                                        <div className="">
                                                            <div className="flex flex-row items-center">

                                                                <div className="social-text">
                                                                    <p className="text-xs">
                                                                        {facebookPage.followers_count > 0 ? millify(facebookPage.followers_count) : 0} followers
                                                                    </p>
                                                                    {facebookPage.connected_instagram_account ? (
                                                                        <div className="mt-1">
                                                                            <p

                                                                                className=" text-xs flex items-center justify-start text-black">Linked to:
                                                                                <p
                                                                                    className=" text-xs text-black flex items-center justify-start"

                                                                                ><img
                                                                                        className="h-4 w-4 mx-1 saturate-0	"
                                                                                        src={siteIconsData['instagram']}
                                                                                    /> @{facebookPage.connected_instagram_account.username}</p>
                                                                            </p>
                                                                        </div>
                                                                    ) : null}
                                                                    {selectedId === facebookPage.id ? (
                                                                        <div className="mt-2">
                                                                            <p className=" text-sm flex items-center justify-start text-black">Your audiences will automatically be targeted by ads.</p>
                                                                        </div>
                                                                    ) : null}
                                                                    {!facebookPage.is_published ? (
                                                                        <>
                                                                            <p className={'text-red-500 text-xs'}>This Page is not published, and must be live before you can use it to start running ads.</p>
                                                                            <a className="text-red-500 text-xs underline"
                                                                                href={`https://www.facebook.com/${facebookPage.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                                target="_blank">Click here to publish the page: {`https://www.facebook.com/${facebookPage.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                            </a>
                                                                        </>
                                                                    )
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            />
                                        );
                                    })
                                    : searchStr ? (
                                        <div className='w-full p-6 text-center'>
                                            <p>No Facebook Pages found for "{searchStr}"</p>
                                        </div>
                                    ) : null}

                            </ul>) : null}

                    </div>
                    <SaveTargetingButton
                        disabled={selectedPagesToAdd.length === 0}
                        onClick={handleOnSaveTargeting}>Save custom audiences</SaveTargetingButton>
                </TargetingAgeGenderModalContainer>
            </Fragment>
        </DialogPaper>
    )
}
{/* </Dialog> */ }


export default TargetingAdditionalPageAudiences;