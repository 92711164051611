/* eslint-disable no-throw-literal */
import Axios from "helpers/Interceptor"
import * as Sentry from "@sentry/react"
import some from "lodash/some"
import { PaymentMethods } from "types/global"
import RewardfulClient from "analytics/rewardful"
import { BillingCycle } from "./utils"

export type PayPalFormattedData = {
  recurring_interval: BillingCycle
  payment_method: PaymentMethods.PAYPAL
}

export type StripeFormattedData = {
  recurring_interval: BillingCycle
  payment_method: PaymentMethods.STRIPE
}

export const createPayPalOrder = async (
  data: {
    recurring_interval: BillingCycle
    payment_method: PaymentMethods.PAYPAL
  },
  brandSlug?: string | null
) => {
  try {
    const response = await Axios.post(
      `/campaign/brand/${brandSlug}/checkout`,
      data
    )

    if (some([!response, !response.data, !response.data.data])) {
      throw "Error creating PayPal order"
    }

    const order = response.data.data
    const orderId = order.paypalOrder.id
    const campaignId = order.campaignId

    return { orderId, campaignId }
  } catch (error) {
    Sentry.captureException(error)
    console.log("error: ", error)
    return { orderId: null, campaignId: null }
  }
}

/**
 * Creates a checkout for a subscription.
 * @param data
 * @param brandSlug
 */
export const redirectToCheckout = async (
  data: {
    recurring_interval: BillingCycle
    payment_method: PaymentMethods.STRIPE,
    checkout_source?: 'onboarding-signup'
  },
  brandSlug?: string | null,
  is_trial?: boolean
) => {
  // pull rewardful data here and add it into the data object

  try {
    const response = await Axios.post(
      `/subscription/brand/${brandSlug}/checkout`,
      {
        ...data,
        is_trial
      }
    )

    window.location = response.data.data.url
  } catch (error) {
    Sentry.captureException(error)
    console.error("error: ", error)
  }
}
