import DialogPaper from "components/shareable/DialogPaper";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ChipContainer, CloseButton, MobileBanner, ModalBody, ModalContainer, ModalHeader, Video, VideoButton, VideoContainer, VideoCover, useStyles } from "./styles";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import PrimaryButton from "components/shareable/PrimaryButton";
import { Body2, Caption, Headline1, Headline2 } from "components/shareable/Typography";
import ConversionsChart from "assets/images/conversions-chart.png";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as DesktopIcon } from "assets/images/computer.svg";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import ReactPlayer from "react-player";
import { Container } from "styles/shared";
import { Backdrop, Dialog, Fade, Modal } from "@material-ui/core";
import { track } from "analytics";

interface Props {
    open: boolean;
    closeModal: () => void;
    title: string;
    innerContent?: JSX.Element | undefined | null;
    onClickButton: () => void;
    buttonText: string;
    videoUrl?: string;
    icon?: string;
}

const HorizontalModalWithVideo: FunctionComponent<Props> = ({
    open,
    closeModal,
    title,
    onClickButton,
    buttonText,
    videoUrl,
    innerContent,
    icon
}: Props) => {

    const classes = useStyles();
    const { mobileView } = useMediaBreakpoints();
    const [paused, setPaused] = useState(false);
    const video = useRef<HTMLVideoElement>(null);

    const handleOnPauseUnpauseVideo = () => {
        if (video?.current) {
            paused ? video.current.play() : video.current.pause()
            setPaused(prev => !prev)
        }
    }

    useEffect(() => {
        if (open) {
            track("Helper Popup Opened", {
                title: title
            })
        }
    }, [open])

    const dialogStyleMobile = {
        maxWidth: "unset",
        width: "100%",
        height: "100%",
        maxHeight: 'unset',
        margin: "0px",
        gap: "0px",
        padding: "0px",
    }

    const dialogStyleDesktop = {
        maxWidth: "1100px",
        width: "95%",
        height: "90%",
        maxHeight: "615px",
        gap: "0px",
        padding: "0px",
        borderRadius: '6px',
        overflow: 'hidden'
    }

    const primaryButtonHolder: React.CSSProperties = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: `1px solid ${SystemColors.PAPER_DIVIDER_CONTAINER}`,
        padding: "12px",
        boxSizing: "border-box",
        bottom: 0,
        left: 0,
        background: 'white',
        position: mobileView ? 'sticky' : 'absolute'
    }

    const bodyContentHolder: React.CSSProperties = {
        height: "100%",
        width: "100%",
        overflow: 'auto',
        padding: mobileView ? '0px' : `56px 32px 32px 32px`
    }


    const videoContainer: React.CSSProperties = {
        height: mobileView ? "350px" : "100%",
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: 'black'
    }

    const iconStyle = {
        height: '35px',
        width: 'auto'
    }


    const modalContentMobile: React.CSSProperties = {
        position: 'relative',
        background: 'white',
        padding: mobileView ? '36px' : `inherit`

    }


    const modalContainerStyleMobile: React.CSSProperties = {
        height: '100%',
        width: '100%',
        overflow: 'auto'
    }

    const modalContainerStyleDesktop: React.CSSProperties = {
        position: 'relative',
        height: '100%'
    }


    return (
        <Modal
            open={open}
            className={"flex items-center justify-center"}
            // dialogStyle={}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
        >
            <Fade in={open}>
                <div
                    style={mobileView ? dialogStyleMobile : dialogStyleDesktop}
                >
                    <Container display="flex" flexDirection={mobileView ? "column" : undefined}
                        style={mobileView ? modalContainerStyleMobile : modalContainerStyleDesktop}
                    >
                        <VideoContainer
                            width={mobileView ? "100%" : "55%"}
                            height={mobileView ? "350px" : undefined}>
                            {mobileView && (
                                <CloseButton
                                    onClick={closeModal}
                                    style={mobileView ? {
                                        background: SystemColors.PAPER_DIVIDER_CONTAINER
                                    } : {}}

                                >
                                    <CloseIcon width={24} height={24} />
                                </CloseButton>
                            )}

                            <div style={videoContainer}>
                                <ReactPlayer
                                    url={videoUrl}
                                    loop
                                    controls
                                    playsInline
                                    autoPlay
                                    width={'100%'}
                                    height={'100%'}
                                />
                            </div>
                        </VideoContainer>

                        <Container
                            padding="0px"
                            backgroundColor="white"
                            width={mobileView ? "100%" : "45%"}
                            gap="0px"
                            style={mobileView ? {

                            } : {
                                position: 'relative'
                            }}
                        >
                            {!mobileView && (<CloseButton
                                onClick={closeModal}
                                background={SystemColors.PAPER_DIVIDER_CONTAINER}
                            >
                                <CloseIcon width={24} height={24} />
                            </CloseButton>)}

                            <div style={bodyContentHolder}>

                                <div
                                    style={mobileView ? modalContentMobile : {}}>
                                    <Container
                                        alignItems="center"
                                        display="flex" flexDirection="row" gap={'12px'}>
                                        {icon && (<img
                                            style={iconStyle}
                                            alt={title}
                                            src={icon}
                                        />)}
                                        <Headline1
                                            style={{
                                                transform: `translateY(3px)`
                                            }}
                                            color={SystemColors.PRIMARY_TEXT_COLOR}>
                                            {title}
                                        </Headline1>
                                    </Container>
                                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                        {innerContent}
                                    </Body2>
                                </div>
                            </div>
                            <div style={primaryButtonHolder}>
                                <PrimaryButton
                                    width="80%"
                                    text={buttonText}
                                    onClick={onClickButton}
                                />
                            </div>
                        </Container>
                    </Container>
                </div>
            </Fade>

        </Modal>
    )
}

export default HorizontalModalWithVideo;