import { GridColDef } from "@mui/x-data-grid";
import { Body2, Caption } from "components/shareable/Typography";
import getSymbolFromCurrency from "currency-symbol-map";
import { capitalizeFirstLetterOfEveryWord } from "helpers/General";
import moment from "moment";
import { CAMPAIGN_TYPE, CAMPAIGN_TYPES, CAMPAIGN_STATUSES, CAMPAIGN_STATUS } from "types/global";
import { SystemColors } from "types/globalStyles";
import Playlisting from "assets/images/campaigns/playlisting.png";
import Presave from "assets/images/campaigns/presave.png";
import LinkClicks from "assets/images/campaigns/linkclicks.png";
import BoostIG from "assets/images/campaigns/boost_ig.png";
import MusicStreams from "assets/images/campaigns/musicstreams.png";
import PlaylistFollowers from "assets/images/campaigns/playlistfollowers.png";
import Youtube from "assets/images/campaigns/youtube.png";
import { ReactComponent as SuperboostIcon } from "assets/images/ui/superboost.svg"
import { ReactComponent as SuperboostIconGray } from "assets/images/ui/superboost-gray.svg"

const {
  get_playlisted,
  pre_save,
  boost_instagram,
  grow_playlist_followers,
  increase_video_views,
  link_clicks,
  record_streams,
} = CAMPAIGN_TYPES;

const {
  active,
  paused,
  in_progress,
  draft,
  anticipated_pause,
  denied,
  error,
  finished,
  in_review,
  not_found,
  published,
  scheduled,
  stopped,
  submitted,
  to_retry,
  with_issues
} = CAMPAIGN_STATUSES;

const formatStatusName = (status: string) => {
  return status.toUpperCase().replace('_', ' ')
}

const formatCampaignType = (status: string) => {
  if (CAMPAIGN_TYPE_MAPPINGS[status]) {
    return CAMPAIGN_TYPE_MAPPINGS[status]
  }
  return capitalizeFirstLetterOfEveryWord(status.replaceAll('_', ' '))
}

const CAMPAIGN_TYPE_MAPPINGS: Record<string, string> = {
  record_streams: "Increase Music Streams",
  grow_playlist_followers: "Grow Playlist Followers",
  link_clicks: "Increase Link Clicks",
  increase_sales: "Increase Sales",
  get_playlisted: "Pitch Playlists",
  increase_video_views: "Increase Video Views",
  boost_instagram: "Boost Instagram Engagement",
  pre_save: "Pre-save Campaign",
}

const getCampaignIcon = (type: CAMPAIGN_TYPE) => {
  switch (type) {
    case get_playlisted:
      return Playlisting;
    case pre_save:
      return Presave;
    case boost_instagram:
      return BoostIG;
    case record_streams:
      return MusicStreams;
    case grow_playlist_followers:
      return PlaylistFollowers;
    case increase_video_views:
      return Youtube;
    case link_clicks:
      return LinkClicks;
    default:
      return LinkClicks;
  }
}

const getStyleByStatus = (status: CAMPAIGN_STATUS) => {
  switch (status) {
    case active:
    case submitted:
    case published:
      return { background: SystemColors.PAPER_SUCCESS_CONTAINER, color: SystemColors.SUCCESS };
    case draft:
    case paused:
    case in_progress:
    case finished:
    case in_review:
    case to_retry:
    case anticipated_pause:
    case scheduled:
      return { background: SystemColors.PAPER_WARNING_CONTAINER, color: SystemColors.WARNING_COLOR };
    case denied:
    case error:
    case not_found:
    case with_issues:
    case stopped:
      return { background: SystemColors.PAPER_ERROR_CONTAINER, color: SystemColors.ERROR_COLOR };
    default:
      return { background: SystemColors.PAPER_ACCENT_CONTAINER };
  }
}

export const getColumnDefinition = (showConversionsColumn: boolean, showBudgetColumn: boolean, showSpentColumn: boolean, showPlaylistingData: boolean, presaveCampaignsData: boolean, videoViewsCampaignsData: boolean) => {

  const defaultColDef: GridColDef = {
    field: "field",
    headerName: "column",
    type: "string",
    editable: false,
    align: "center",
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderHeader: (params) => <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{params.field}</Body2>,
  }

  return [
    {
      ...defaultColDef,
      field: "Profile",
      headerName: "Profile",
      align: 'center',
      headerAlign: 'center',
      width: 80,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { brandImage, brandName } = formattedRow;
        return (
          <>
            <img
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '99%',
              }}
              src={brandImage}
              alt={`${brandName} profile`}
              title={brandName}
            />
          </>
        )
      },
    },
    {
      ...defaultColDef,
      field: "Status",
      headerName: "Status",
      align: 'center',
      headerAlign: 'center',
      width: 130,
      minWidth: 130,
      maxWidth: 130,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { status } = formattedRow;
        const { background, color } = getStyleByStatus(status);
        return (
          <div className="flex gap-2 rounded py-1 px-2" style={{ background }}>
            <Body2 color={color}>{formatStatusName(status)}</Body2>
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "Name",
      align: 'left',
      headerAlign: 'center',
      headerName: "Name",
      width: 200,
      minWidth: 300,
      maxWidth: 400,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { campaignName, thumbnail_url } = formattedRow;
        return (
          <div className="flex gap-2 w-full">
            {thumbnail_url && (
              <img src={thumbnail_url} className="h-6 w-6 rounded-lg object-cover" alt="content_image"/>
            )}
            <Body2
              color={SystemColors.PRIMARY_TEXT_COLOR}
              className="overflow-ellipsis overflow-hidden"
              style={{ maxWidth: thumbnail_url ? '80%' : '100%' }}
            >
              {campaignName}
            </Body2>
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "Type",
      align: 'left',
      headerName: "Type",
      headerAlign: 'left',
      width: 200,
      minWidth: 200,
      maxWidth: 250,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { type } = formattedRow;
        return (
          <div className="flex gap-2 rounded py-1 px-2 overflow-hidden" style={{ background: SystemColors.PAPER_ACCENT_CONTAINER }}>
            <img src={getCampaignIcon(type)} style={{ height: 18, width: 18 }} alt="content_type_image" />
            <Caption
              color={SystemColors.PRIMARY_TEXT_COLOR}
              className="overflow-ellipsis overflow-hidden"
            >
              {formatCampaignType(type)}
            </Caption>
          </div>
        )
      },
    },
    {
      ...defaultColDef,
      field: "Budget",
      align: 'center',
      headerName: "Budget",
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      hide: !showBudgetColumn,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { budget, currency } = formattedRow;
        return (
          <Body2>{budget ? `${getSymbolFromCurrency(currency)}${Number(budget).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '-'}</Body2>
        )
      },
    },
    {
      ...defaultColDef,
      field: "End date",
      align: 'center',
      headerName: "End date",
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { endDate } = formattedRow;
        const formattedDate = moment(endDate).format('MM/DD/YYYY');
        const timeRemaining = moment(endDate).diff(moment(), 'days');
        const unit = timeRemaining > 1 ? 'days' : 'day';
        return (
          <>
            {endDate ? (
              <Body2>{formattedDate} {timeRemaining >= 0 && `(${timeRemaining} ${unit})`}</Body2>
            ) : (
              <Body2>-</Body2>
            )}
          </>
        )
      },
    },
    {
      ...defaultColDef,
      field: "CPC",
      align: 'center',
      headerName: "CPC",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !showConversionsColumn,
      renderCell: showConversionsColumn ? (params) => {
        const formattedRow = params.row;
        const { CPC } = formattedRow;
        return (
          <Body2>{CPC ? Number(CPC).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Body2>
        )
      } : undefined,
    },
    {
      ...defaultColDef,
      field: "Start date",
      align: 'center',
      headerName: "Start date",
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { startDate } = formattedRow;
        const formattedDate = moment(startDate).format('MM/DD/YYYY');
        return (
          <>
            {startDate ? (
              <Body2>{formattedDate}</Body2>
            ) : (
              <Body2>-</Body2>
            )}
          </>
        )
      },
    },
    {
      ...defaultColDef,
      field: "Spent",
      align: 'center',
      headerName: "Spent",
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      hide: !showSpentColumn,
      renderCell: (params) => {
        const formattedRow = params.row;
        const { spent, currency } = formattedRow;
        return (
          <Body2>{spent ? `${getSymbolFromCurrency(currency)}${Number(spent).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '-'}</Body2>
        )
      },
    },
    {
      ...defaultColDef,
      field: "CPV",
      align: 'center',
      headerName: "CPV",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !videoViewsCampaignsData,
      renderCell: videoViewsCampaignsData ? (params) => {
        const formattedRow = params.row;
        const { CPV } = formattedRow;
        return (
          <Body2>{CPV ? Number(CPV).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Body2>
        )
      } : undefined,
    },
    {
      ...defaultColDef,
      field: "Pitched Playlists",
      align: 'center',
      headerName: "Pitched Playlists",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !showPlaylistingData,
      renderCell: showPlaylistingData ? (params) => {
        const formattedRow = params.row;
        const { pitchedPlaylists } = formattedRow;
        return (
          <Body2>{pitchedPlaylists || '-'}</Body2>
        )
      } : undefined,
    },
    {
      ...defaultColDef,
      field: "Followers",
      align: 'center',
      headerName: "Followers",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !showPlaylistingData,
      renderCell: showPlaylistingData ? (params) => {
        const formattedRow = params.row;
        const { followers } = formattedRow;
        return (
          <Body2>{followers || '-'}</Body2>
        )
      } : undefined,
    },
    {
      ...defaultColDef,
      field: "Pre-saves",
      align: 'center',
      headerName: "Pre-saves",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !presaveCampaignsData,
      renderCell: presaveCampaignsData ? (params) => {
        const formattedRow = params.row;
        const { presaves } = formattedRow;
        return (
          <Body2>{presaves ? presaves.totalPresaves : '-'}</Body2>
        )
      } : undefined,
    },
    {
      ...defaultColDef,
      field: "Emails Collected",
      align: 'center',
      headerName: "Emails Collected",
      headerAlign: 'center',
      minWidth: 150,
      maxWidth: 200,
      hide: !presaveCampaignsData,
      renderCell: presaveCampaignsData ? (params) => {
        const formattedRow = params.row;
        const { presaves } = formattedRow;
        return (
          <Body2>{presaves ? presaves.emailsCollected : '-'}</Body2>
        )
      } : undefined,
    },
    
    {
      ...defaultColDef,
      field: "Conv. API",
      align: 'center',
      headerName: "Conv. API",
      headerAlign: 'center',
      minWidth: 100,
      maxWidth: 100,
      hide: !showConversionsColumn,
      renderCell: showConversionsColumn ? (params) => {
        const formattedRow = params.row;
        const { conversionsEnabled } = formattedRow;
        return (
          <div
            className="flex gap-2 rounded py-1 px-2 overflow-hidden"
            style={
              conversionsEnabled
                ? { background: SystemColors.PAPER_ACCENT_CONTAINER }
                : { background: SystemColors.PAPER_DIVIDER_CONTAINER }
            }>
            {conversionsEnabled ? (
              <SuperboostIcon style={{ height: 18, width: 18 }} />
            ) : (
              <SuperboostIconGray style={{ height: 18, width: 18 }} />
            )}
          </div>
        )
      } : undefined,
    },
  ] as GridColDef[];
}