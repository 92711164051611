import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Container } from "../../styles";
import * as Sentry from "@sentry/react";
import Axios from "helpers/Interceptor";
import { OutlinedButton, StyledLabel, TextButton } from "styles/shared";
import { OrganizationBillingData, OrganizationData } from "types/global";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants";
import getSymbolFromCurrency from "currency-symbol-map";
import { useHistory } from "react-router-dom";
import PrimaryButton from "components/shareable/PrimaryButton";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";

// Extend the Window interface to include the barecancel property
declare global {
    interface Window {
        barecancel?: {
            created: boolean;
            params: {
                access_token_id: string;
                customer_oid: string;
                callback_send: (data: any) => void;
                callback_error: (error: any) => void;
            };
        };
    }
}

function useBaremetricsCancellationWidget(customerId?: string | undefined | null, onCancel?: (data?: any) => void) {
    useEffect(() => {
        if (customerId) {
            // Check if the script is already loaded
            if (!window.barecancel || !window.barecancel.created) {

                const script = document.createElement('script');
                script.src = "https://baremetrics-barecancel.baremetrics.com/js/application.js";
                script.async = true;

                // Ensure window.barecancel is initialized before setting params
                if (!window.barecancel) {
                    window.barecancel = { created: true } as any;
                }

                if (customerId) {
                    window.barecancel!.params = {
                        access_token_id: "3126ce03-b1c3-41d4-935b-894bc48e0237", // Your Cancellation API public key
                        customer_oid: customerId, // The provider id of this customer. For example, the Stripe Customer ID
                        callback_send: function (data) {
                            // Once the cancellation is recorded in Baremetrics, you should actually cancel the customer.
                            onCancel && onCancel()
                            return
                        },
                        callback_error: function (error) {
                            // Handle errors
                            console.error(error);
                            // onCancel && onCancel()
                        }
                    };
                    window.barecancel!.created = true;
                };
                script.onload = () => {};
                document.body.appendChild(script);
            }
        }
    }, [customerId]); // Empty dependency array ensures this runs once on mount
}


interface SpendStatusObject {
    status: "healthy" | string; // if 'healthy' is not the only possible value, otherwise, keep it as "healthy"
    spent: number | null;
    // this is used only if the user doesn't have a pending cancellation. if they do, then the scheduledCancellationDate will be used
    nextBillingDate?: string;
    // this is used only if the user has a pending cancellation. if they don't, then the nextBillingDate will be used
    scheduledCancellationDate?: string;
    trialEndDate?: string;
    planType: 'monthly' | 'annual'
    spentLimit: number;
    spentToDate: number;
    spentLeft: number;
    feeToDate: number;
    newSpent: number | null;
    newSpentLimit: number;
    feeUnitsToCharge?: number;
    feePrice: number;
    feeUnit: number;
    feeTotal: number;
    total: number | null;
    seatsOverage: number | null;
    seatsOverFee: number | null;
    totalSeatsUsed: number | null;
    viewSubscriptionUrl?: string | null
    viewCustomerUrl?: string | null
}

//TODO: Check if add all logos for each payment method
const VisaLogo = () => (
    <svg
        width="37"
        height="11"
        viewBox="0 0 37 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.1033 0.447975C26.5261 0.232048 25.6215 0 24.4913 0C21.6116 0 19.5823 1.44574 19.5653 3.51635C19.5495 5.04863 21.0135 5.90174 22.1194 6.4125C23.2534 6.93386 23.6353 7.2673 23.6293 7.73351C23.6221 8.4479 22.7239 8.77412 21.8861 8.77412C20.7193 8.77412 20.1 8.61334 19.1426 8.21543L18.7667 8.04574L18.3576 10.4315C19.0392 10.7285 20.2971 10.9868 21.6043 11C24.6684 11 26.6576 9.57123 26.6798 7.35935C26.6904 6.14693 25.9144 5.22426 24.2334 4.46448C23.2138 3.97111 22.5902 3.6415 22.597 3.14261C22.597 2.70058 23.1245 2.22588 24.2653 2.22588C25.2197 2.21103 25.9093 2.41847 26.4469 2.63482L26.7087 2.757L27.1033 0.447975ZM13.6194 10.8415L15.4418 0.185547H18.356L16.5328 10.8415H13.6194ZM32.3258 0.196254H34.5781L36.9368 10.8446H34.232C34.232 10.8446 33.9651 9.62113 33.8778 9.24824C33.6524 9.24824 32.7109 9.24705 31.8261 9.24593L31.8138 9.24591C31.0341 9.24493 30.3012 9.244 30.145 9.244C30.0318 9.53204 29.5316 10.8446 29.5316 10.8446H26.4705L30.7989 1.0799C31.1062 0.385456 31.6277 0.196254 32.3258 0.196254ZM30.9832 7.06439C31.2246 6.45055 32.1449 4.0851 32.1449 4.0851C32.1364 4.09897 32.1931 3.95228 32.2704 3.75198L32.2705 3.75186C32.3517 3.54178 32.4556 3.27279 32.531 3.06825L32.7276 3.98711C32.7276 3.98711 33.2861 6.5303 33.4032 7.06439H30.9832ZM8.32167 7.46003L11.1746 0.193585H14.2617L9.67364 10.8288L6.58955 10.833L3.98096 1.51291C5.83098 2.43601 7.48647 4.28051 8.0173 5.98333L8.32167 7.46003Z"
            fill="#1A1F71"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.67446 0.186523H0.974046L0.937012 0.407966C4.59365 1.29077 7.01324 3.42204 8.01743 5.98348L6.99536 1.08587C6.81913 0.410935 6.30703 0.209855 5.67446 0.186523Z"
            fill="#F9A51A"
        />
    </svg>
);

interface Props {
    organization: OrganizationData;
    isAdmin: boolean;
    isProUser: boolean;
}

const OrganizationProPlanView: FunctionComponent<Props> = ({ isAdmin, isProUser, organization }: Props) => {
    const theme = useTheme();
    const history = useHistory()
    const { handleOpenModal } = useContext(UpgradeModalContext);
    const { currentBrand } = useContext(CurrentBrandContext);
    const setMdWidth = useMediaQuery(theme.breakpoints.down(645));
    const setLgWidth = useMediaQuery(theme.breakpoints.between(645, 920));
    const setUpperPartAsColumn = useMediaQuery(theme.breakpoints.down(524));

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentOrganizationBillingData, setCurrentOrganizationBillingData] = useState<OrganizationBillingData | null>(null);

    const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY
    const currencySymbol = getSymbolFromCurrency(currencyCode)

    useEffect(() => {
        const fetchOrganizationBillingData = async () => {
            try {
                setIsLoading(true);
                const response = await Axios.get(
                    `/organization/billing`,
                );
                setCurrentOrganizationBillingData(response.data.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                Sentry.captureException(error);
                console.error("fetchOrganizationBillingData: ", error);
            }
        };
        fetchOrganizationBillingData();
    }, []);

    const redirectToStripeSubscription = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewSubscriptionUrl) {
            window.open(currentOrganizationBillingData.viewSubscriptionUrl, "_blank");
        }
    }
    const redirectToStripeCustomer = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewCustomerUrl) {
            window.open(currentOrganizationBillingData.viewCustomerUrl, "_blank");
        }
    }

    const openTeamPlanBillingSettings = async () => {
        history.push(`/organization?tab=BILLING`);
    }

    function dataRow(array: {
        title: string;
        text: string
        editable?: boolean;
        onEdit?: () => void
    }[]) {
        return (
            <Container
                gap={setUpperPartAsColumn ? 8 : 32}
                display="flex"
                flexDirection={setUpperPartAsColumn ? "column" : "row"}
                padding="24px 0px"
                borderBottom="1px solid #E5E7EB"
                alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
            >
                {array.map((item, index) => (
                    <Container
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width={setLgWidth ? "32%" : setMdWidth ? "100%" : "20%"}
                    >
                        <StyledLabel fontSize={16} fontWeight={400}>
                            {item.title}
                        </StyledLabel>
                        <Container display="flex" flexDirection="row" gap={12}>
                            <StyledLabel fontSize={24} fontWeight={500}>
                                {item.text}
                            </StyledLabel>
                            {item.editable && isAdmin && (
                                <Container display="flex"
                                    gap="4px"
                                    alignItems="center">
                                    <TextButton onClick={item.onEdit}>Edit Spend</TextButton>
                                </Container>
                            )}
                        </Container>
                    </Container>
                ))}
            </Container>
        )
    }

    const basePlanRowData = []
    basePlanRowData.push({
        title: "Plan",
        text: !isProUser ? 'Free Profile on Team Plan' : `Pro via Team Plan`
    })

    // handle edit
    return (
        <Container>
            {isAdmin && (
                <Container
                    gap={setUpperPartAsColumn ? 8 : 32}
                    display="flex"
                    flexDirection={"row"}
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
                >
                    <Container flexDirection={"row"}>
                        <OutlinedButton onClick={redirectToStripeSubscription}>Admin: View Stripe Subscription</OutlinedButton>
                    </Container>
                    <Container flexDirection={"row"}>
                        <OutlinedButton onClick={redirectToStripeCustomer}>Admin: View Stripe Customer</OutlinedButton>
                    </Container>
                </Container>
            )}

            {dataRow(basePlanRowData)}
            {!isProUser && (

                <Container
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    width={"100%"}
                    padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    gap={'8px'}
                >

                    <PrimaryButton
                        onClick={() => {
                            handleOpenModal({
                                source: "Settings - Billing Tab (Team Plan subscriber)"
                            })
                        }}
                        text={"Upgrade to Pro"} />
                </Container>

            )}
            <Container
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width={"100%"}
                padding="24px 0px"
                borderBottom="1px solid #E5E7EB"
                gap={'8px'}
            >
                <StyledLabel fontSize={16} fontWeight={400}>
                    You can view and update your Team Plan in the Team Plan Billing Settings:
                </StyledLabel>
                <Container flexDirection={"row"}>
                    <OutlinedButton onClick={openTeamPlanBillingSettings}>View Team Plan Billing Settings</OutlinedButton>
                </Container>
            </Container>
        </Container>
    );
};

export default OrganizationProPlanView;
