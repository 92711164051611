import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: hidden;
`;

export const CampaignsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  justify-content: flex-end;
`;

export const CampaignCard = styled.div`
  display: flex;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${SystemColors.DIVIDER_LINE};
  cursor: pointer;
`;

export const CampaignCardIcon = styled.div`
  display: flex;
  background: ${SystemColors.PAPER_ACCENT_CONTAINER};
  padding: 0px 12px;
  align-items: center;
  min-width: 60px;
`;

export const CampaignCardBody = styled.div`
  display: flex;
  padding: 16px 16px 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const ArticleCardBody = styled.div`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const CampaignIcon = styled.img`
  height: 32px;
  width: 40px;
  max-width: 'auto';
`;
