import styled from "styled-components"

interface FlexContainerProps {
  alignItems?: string
  flexDirection?: string
  margin?: string
  backgroundColor?: string
  flexGrow?: string
}

interface IconProps {
  widthStyle?: string
  heightStyle?: string
  borderRadius?: string
}

interface SlideProps {
  backgroundImage?: string
  slideHeight?: string
  slidePadding?: string
}

interface ContainerProps {
  marginTop?: string
}

export const FlexContainer = styled.div<FlexContainerProps>`
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) => backgroundColor};
  flex-grow: ${({ flexGrow }) => flexGrow};
`

export const Container = styled.div<ContainerProps>`
  margin-top: ${({ marginTop }) => marginTop};
`

export const Slide = styled.div<SlideProps>`
  padding: ${({ slidePadding }) => slidePadding};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  display: flex;
  height: ${({ slideHeight }) => slideHeight || "100%"};
  flex-direction: column;
`

export const Icon = styled.img<IconProps>`
  width: ${({ widthStyle }) => widthStyle};
  height: ${({ heightStyle }) => heightStyle};
  border-radius: ${({ borderRadius }) => borderRadius};
`
