export enum Steps {
  SETUP_PLAN = "SETUP_PLAN",
  PAYMENTS_DETAILS = "PAYMENTS_DETAILS",
  WELCOME = "WELCOME",
}

export const titles = {
  [Steps.SETUP_PLAN]: "Set up a plan that fits for you",
  [Steps.PAYMENTS_DETAILS]: "Payments details",
  [Steps.WELCOME]: "Welcome to Symphony Pro!",
}

export const subtitles = {
  [Steps.SETUP_PLAN]: "Back to Dashboard",
  [Steps.PAYMENTS_DETAILS]: "Back to Setup Plan",
  [Steps.WELCOME]: null,
}
