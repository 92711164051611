import Intercom from "helpers/Intercom";
import ListComponent from "./list-section";
import { showNewMessageIntercom } from "components/Navbar";


export default function ContactUsHelpPopupSection() {
    return (

        <ListComponent
            title="Have more questions?"
            bulletPoints={[
                <span className="underline cursor-pointer" onClick={() => {
                    showNewMessageIntercom()
                }}>
                    Chat with our support team
                </span>,
                <span>
                    Shoot us an email at <a className="underline text-black" href="mailto:team@symphony.to">team@symphony.to</a>
                </span>
            ]}
        />
    )
}