import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { track } from "../../../analytics";
import { LoadingIndicator } from "../../../components/Loader";
import MainContentContainer from "../../../components/shareable/MainContentContainer";
import Axios from "../../../helpers/Interceptor";
import TapfiliateClient from "analytics/tapfiliate";
import Logger from "Logger";
import { finalizeSubscription } from "components/shareable/UpgradeToProSucceededModal/api";
import { CurrentBrand } from "types/global";

// On this page, we should be getting brand + session_id variables
//  - if both are present, get the brand details + stripe details
//      - if stripe transaction was a success, show success state
//      - else show error state
//  - if one or the other is missing, show an error state
export default function SubscriptionSuccessPage(props: {
  currentBrand: CurrentBrand
}) {
  const {
    currentBrand,
  } = props

  const [loadingData, setLoadingData] = useState<any>(true)
  const [brandData, setBrandData] = useState<any>(null)
  const [errorState, setErrorState] = useState<boolean>(false)

  const history = useHistory()

  async function getSubscriptionStatus() {
    var urlParams = new URLSearchParams(window.location.search);

    // Get brand + session_id
    var brand = urlParams.get('brand')
    var session_id = urlParams.get('session_id')

    if (!brand || !session_id) {
      setErrorState(true)
    } else {
      // Call to finalize subscription
      try {

        const subscriptionData = await finalizeSubscription(session_id, currentBrand.slug)


        setBrandData(subscriptionData.data)
        setLoadingData(false)
      } catch (e) {
        // Error setting up subscription
        console.error('Error setting up subscription', e)
        setLoadingData(false)
        setErrorState(true)
      }
    }
  }

  const onSuccessStateHandler = () => {
    history.replace('/dashboard')
  }

  const onErrorStateHandler = () => {
    history.replace('/settings')
  }

  useEffect(() => {
    getSubscriptionStatus()
  }, [])

  // For tracking 
  useEffect(() => {
    if (brandData && brandData.subscription) {
      const { subscription } = brandData;
      mixpanel.people.increment({
        "submitted_brand_subscription": 1,
      })

      track("Submitted Subscription", {
        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        brand_slug: currentBrand.slug,
        tier: subscription.tier,
        type: subscription.type,
        payment_method: subscription.payment_method,
        recurring_interval: subscription.recurring_interval,
      })
    }
  }, [brandData])

  return loadingData ? (

    <MainContentContainer>
      <div className="flex-col flex items-center mt-20">
        <LoadingIndicator height="100px" color="black" />
        <p className="mt-1 text-center">Finalizing your subscription...</p>
      </div>
    </MainContentContainer>

  ) : !errorState ? (

    <MainContentContainer>
      <div className="md:pt-4 md:pb-32 card-body">
        <div className="card-content bg-white shadow-none lg:shadow-lg">

          <div className="top-wrapper">
            <div className="card-head relative">
              <h4 className="text-center text-dark lg:text-white text-2xl al">
                Subscription created!
              </h4>
            </div>
          </div>

          <div className="text-center mx-auto pt-4 py-8 px-20">
            <img
              className="mx-auto m-10"
              src={require("../../../assets/images/tick-light.png").default}
            />
            <h5 className="font-bold text-xl">
              Your subscription has been created successfully!
            </h5>
            You unlocked all the power of Symphony's automated marketing tools for your brand "{currentBrand.name}".
          </div>

          <div className="cta-wrapper">
            <div className="text-center pb-12 px-4 lg:px-10">
              <a className="btn-primary w-auto px-3 cursor-pointer" onClick={onSuccessStateHandler} >
                See Your Subscription details
              </a>
            </div>
          </div>

        </div>
      </div>

    </MainContentContainer>

  ) : (

    <MainContentContainer>
      <div className="md:pt-8 md:pb-24 card-body">
        <div className="card-content bg-white shadow-none lg:shadow-lg">

          <div className="top-wrapper">
            <div className="card-head relative">
              <h4 className="text-center text-dark lg:text-white text-2xl al">
                Subscription error!
              </h4>
            </div>
          </div>

          <div className="text-center mx-auto p-4 lg:p-8">
            <h5 className="font-bold text-xl">There was an error subscribing your brand.</h5>
            <p className="">
              We apologize for the inconvenience - please try submitting your subscription again.
            </p>
          </div>

          <div className="cta-wrapper">
            <div className="text-center pb-4 px-4 lg:px-10">
              <button className="btn-primary w-auto px-3 cursor-pointer" onClick={onErrorStateHandler} >
                Okay
              </button>
            </div>
          </div>

        </div>
      </div>
    </MainContentContainer>
  )

}
