import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface SymphonyLineChartProps {
  data: {
    [key: string]: number | string;
  }[]
}

export function SymphonyLineChart({ data }: SymphonyLineChartProps) {
  return (
    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
      <LineChart
        width={500}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dy={12} dataKey="date" />
        <YAxis domain={['auto', 'auto']} dataKey="pageViews" yAxisId={1} />
        <YAxis domain={['auto', 'auto']} dataKey="streamingLinkClicks"
          orientation="right"
          yAxisId={2} />
        <Legend verticalAlign="top"
          height={36}
        />
        <Tooltip />
        <Line
          yAxisId={1}
          type="monotone" dataKey="pageViews"
          name="Page Views"
          stroke="#8884d8" fill="#d9ffd9" fillOpacity="0.8" activeDot={{ r: 8 }} />
        <Line
          yAxisId={2}
          type="monotone"
          name="Streaming Link Clicks"
          dataKey="streamingLinkClicks" stroke="#42c221" fill="#d9ffd9" fillOpacity="0.8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  )
}