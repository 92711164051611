import axios from 'axios'
import { queryFB } from './FB'

/**
    * Takes a file and creates an adimage in FB from it.
    * @param {*} file 
    * @param {*} token 
    */
// export async function uploadAdImage(fileUrl, token, adAccountId) {
//     return new Promise(async (resolve, reject) => {
//         try {

//             const url = `/${adAccountId}/advideos`

//             const createItem = await FBAPI({
//                 url: `/${adAccountId}/adimages`,
//                 method: 'POST',
//                 body: {
//                     bytes: bytes
//                 },
//                 access_token: fbAccessToken
//             })


//             resolve({
//                 data: createItem.images.bytes,
//                 id: file.name,
//                 type: 'image'
//             })
//         } catch (e) {
//             reject(e)
//         }
//     })
// }

export const fbEngagersKey = 'fb_engagers'
export const igEngagersKey = 'ig_engagers'
export const fbLookalikeKey = "_fb_engagers_lookalikes"
export const igLookalikeKey = "_ig_engagers_lookalikes"

export const linkClickersKey = 'link_clickers'
export const linkClickersLookalikeKey = "_link_clicker_lookalikes"

export const sharerSaversKey = "sharer_savers"
export const sharerSaversLookalikeKey = "_sharer_savers_lookalikes"

/**
* Takes a file and creates an advideo in FB from it.
* @param {*} file f
* @param {*} token 
*/
export async function uploadAdVideo(fileUrl, adAccountId) {
    return new Promise(async (resolve, reject) => {
        try {

            const url = `/${adAccountId}/advideos`
            const payload = {
                file_url: fileUrl
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response);
            }
        } catch (e) {
            console.log('error uploading file', fileUrl, e)
            reject(e)
        }
    })
}

export async function LookalikeGenerator({
    customAudienceId,
    customAudienceName,
    startingRatio = 0.0, // 1 - 100
    ratio = 0.01, // 1 - 100
    country = null,
    brand,
    audienceBrandName = null,
    adAccountId
}) {

    // create new lookalike 
    const lookalikeName = `${audienceBrandName ? audienceBrandName : brand.name} Lookalike - ${customAudienceName} (${country ? country : 'worldwide'}, ${typeof startingRatio !== 'undefined' && startingRatio !== null ? `${startingRatio * 100} - ` : ''}${ratio * 100}%)`

    try {

        var lookalike_spec = {
            starting_ratio: startingRatio,
            ratio: ratio,
        }

        if (country) {
            lookalike_spec.country = country
        } else {
            lookalike_spec.location_spec = {
                geo_locations: {
                    country_groups: ['worldwide']
                }
            }
        }

        var specBreakdown = {
            name: lookalikeName,
            origin_audience_id: customAudienceId,
            subtype: 'LOOKALIKE',
            lookalike_spec: lookalike_spec,

        }
        // audience 
        var generatedLookalike = await createCustomAudience(specBreakdown, adAccountId)

        return {
            id: generatedLookalike.id,
            rule: generatedLookalike.rule,
            name: generatedLookalike.name
        }

    } catch (e) {
        var newError = {
            ...e,
            errorMsg: 'error for ' + lookalikeName
        }

        return newError
    }
}

export async function createFBCampaign({
    adAccountId,
    objective = "CONVERSIONS",
    status = "ACTIVE",
    name,
    special_ad_categories = "NONE",
    bid_strategy = "LOWEST_COST_WITHOUT_CAP",
    lifetime_budget,
    pacing_type = ['standard']
}) {
    const url = `/${adAccountId}/campaigns`
    const creator = await new Promise(async (resolve, reject) => {
        try {
            const payload = {
                // objective: 'blahblahh',
                // status: 'blahblah',
                objective,
                status,
                name,
                special_ad_categories,
                bid_strategy,
                lifetime_budget,
                pacing_type
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response);
            }

        } catch (e) {
            reject(e)
        }
    })

    return creator
}

export async function createFBAdset({
    adAccountId,
    promoted_object = null,
    status = "ACTIVE",
    name,
    pixel_id = null,
    optimization_goal,
    billing_event = "LINK_CLICKS",
    // attribution_spec = [
    //     {
    //         "event_type": "CLICK_THROUGH",
    //         "window_days": 1
    //     },
    //     {
    //         "event_type": "VIEW_THROUGH",
    //         "window_days": 1
    //     }
    // ],
    targeting = {
        'publisher_platforms': [
            'instagram'
        ],
        'instagram_positions': [
            'story',
            'stream',
            'explore'
        ],
        'age_min': 18,
        'age_max': 65,

    },
    end_time = null,
    campaign_id
}) {
    const url = `/${adAccountId}/adsets`
    const creator = await new Promise(async (resolve, reject) => {
        try {
            const payload = {
                status,
                name,
                promoted_object,
                pixel_id,
                optimization_goal,
                billing_event,
                // attribution_spec,
                targeting,
                end_time,
                campaign_id
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response)
            }

        } catch (e) {
            reject(e)
        }
    })

    return creator
}


export async function audienceOwnershipCheck({
    audienceId,
    currentAccountId
}) {
    const url = `/${audienceId}`

    const response = await queryFB(url, {
        params: {
            fields: 'id,name,account_id'
        }
    })

    if (!response || response.error) {
        console.log('error', response)
        throw response.error
    } else {
        const {
            account_id
        } = response
        return `act_${account_id}` === currentAccountId
    }
}
export async function createFBAd({
    adAccountId,
    creative,
    status = "ACTIVE",
    name,
    adset_id
}) {
    const url = `/${adAccountId}/ads`
    const creator = await new Promise(async (resolve, reject) => {
        try {
            const payload = {
                status,
                name,
                creative,
                adset_id
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response)
            }

        } catch (e) {
            reject(e)
        }
    })

    return creator
}

export function lookalikeRatioByPopulation(population) {
    if (!population) {
        return {
            startingRatio: 0,
            ratio: 0.03
        }
    }


    if (population > 50000000) {
        return {
            startingRatio: 0,
            ratio: 0.01
        }
    } else if (population > 10000000) {
        return {
            startingRatio: 0,
            ratio: 0.02
        }
    } else {
        return {
            startingRatio: 0,
            ratio: 0.03
        }
    }
}
export async function getCustomConversions(adAccountId) {
    const url = `/${adAccountId}/customconversions`

    const response = await queryFB(url, {
        params: {
            fields: 'name,id'
        }
    })

    if (!response || response.error) {
        console.log('error', response)
        return response.error
    } else {
        return response
    }
}

async function createCustomConversion(name, ruleset, adAccountId, fbPixelId) {
    const url = `/${adAccountId}/customconversions`
    const createAudience = await new Promise(async (resolve, reject) => {
        try {
            const payload = {
                rule: ruleset,
                name: `[S] ${name}`,
                custom_event_type: "CONTENT_VIEW",
                event_source_id: fbPixelId,
                fields: 'id,name,rule'
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response)
            }

        } catch (e) {
            reject(e)
        }
    })

    return createAudience
}

export const CustomConversionsGenerator = {
    async streamingLinkClicked({ adAccountId, fbPixelId }) {

        const name = `streaming_link_clicked`
        var customConversionsGenerator = await createCustomConversion(
            name,
            {
                "and": [
                    {
                        "event": {
                            "eq": "streaming_link_clicked"
                        }
                    }
                ]

            },
            adAccountId,
            fbPixelId)


        return {
            id: customConversionsGenerator.id,
            rule: customConversionsGenerator.rule,
            name: customConversionsGenerator.name
        }



    }
}

async function createCustomAudience(ruleset, adAccountId) {
    const url = `/${adAccountId}/customaudiences`
    const createAudience = await new Promise(async (resolve, reject) => {
        try {
            const payload = {
                ...ruleset,
                name: `[S] ${ruleset.name}`,
                fields: 'id,name,rule'
            }

            const response = await queryFB(url, {
                method: 'post',
                params: payload
            })
            if (!response || response.error) {
                console.log('error', response)
                reject(response.error)
            } else {
                resolve(response)
            }

        } catch (e) {
            reject(e)
        }
    })

    return createAudience
}



export const AudienceRulesGenerator = {
    async fbEngagers({ fbPageId, days = 365, brand, audienceBrandName = null, adAccountId }) {

        const audienceName = `${audienceBrandName ? audienceBrandName : brand.name} Facebook Engagers (${days})`
        var customAudienceGenerator = await createCustomAudience({
            name: audienceName,
            rule: {
                "event_sources": [
                    {
                        "id": fbPageId,
                        "type": "page"
                    }
                ],
                "retention_seconds": days * 24 * 60 * 60,
                "filter": {
                    "operator": "and",
                    "filters": [
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "page_engaged"
                        }
                    ]
                }
            }
        }, adAccountId)

        const audienceId = customAudienceGenerator.id


        return {
            id: audienceId,
            rule: customAudienceGenerator.rule,
            name: audienceName
        }



    },

    async igEngagers({ igBusinessId, days = 365, brand, audienceBrandName = null, adAccountId }) {


        const audienceName = `${audienceBrandName ? audienceBrandName : brand.name} Instagram Engagers (${days})`
        var customAudienceGenerator = await createCustomAudience({
            name: audienceName,
            rule: {
                "event_sources": [{
                    "type": "ig_business",
                    "id": igBusinessId
                }],
                "retention_seconds": days * 24 * 60 * 60,
                "filter": {
                    "operator": "and",
                    "filters": [{
                        "field": "event",
                        "operator": "eq",
                        "value": "ig_business_profile_all"
                    }]
                }
            }
        }, adAccountId)

        const audienceId = customAudienceGenerator.id


        return {
            id: audienceId,
            rule: customAudienceGenerator.rule,
            name: audienceName
        }


    },

    async igCtaClickers({ igBusinessId, days = 365, brand, audienceBrandName = null, adAccountId }) {

        // people who have clicked on either an:
        //  - Ad Link (CTA)
        //  - or swipe up
        const audienceName = `${audienceBrandName ? audienceBrandName : brand.name} Instagram CTA Clickers (${days})`
        var customAudienceGenerator = await createCustomAudience({
            name: audienceName,
            rule: {
                "event_sources": [{
                    "type": "ig_business",
                    "id": igBusinessId
                }],
                "retention_seconds": days * 24 * 60 * 60,
                "filter": {
                    "operator": "and",
                    "filters": [
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_ad_cta_click"
                        },
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_organic_swipe"
                        }
                    ]
                }

            }
        }, adAccountId)

        const audienceId = customAudienceGenerator.id


        return {
            id: audienceId,
            rule: customAudienceGenerator.rule,
            name: audienceName
        }

    },

    async igSharerSavers({ igBusinessId, days = 365, brand, audienceBrandName = null, adAccountId }) {

        // people who have clicked on either an:
        //  - IG ad Sharer
        //  - Ig Ad save
        //  - ig organic share
        //  - ig organic save
        const audienceName = `${audienceBrandName ? audienceBrandName : brand.name} Instagram Sharer / Savers (${days})`
        var customAudienceGenerator = await createCustomAudience({
            name: audienceName,
            rule: {
                "event_sources": [{
                    "type": "ig_business",
                    "id": igBusinessId
                }],
                "retention_seconds": days * 24 * 60 * 60,
                "filter": {
                    "operator": "or",
                    "filters": [
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_ad_share"
                        },
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_ad_save"
                        },
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_organic_share"
                        },
                        {
                            "field": "event",
                            "operator": "eq",
                            "value": "ig_organic_save"
                        }]
                }


            }
        }, adAccountId)

        const audienceId = customAudienceGenerator.id


        return {
            id: audienceId,
            rule: customAudienceGenerator.rule,
            name: audienceName
        }

    }

}

// module.exports = {
//     AudienceRulesGenerator
// }