import axios from "axios";
import { MarketingCampaignType } from "types/global";
import { availableGoals } from "../../utils/marketing";
import Axios from "helpers/Interceptor";

export interface MaestroRecommendation {
    primaryMessage: string,
    recommendations: {
        key: string;
        title: string;
        message: string;
        link: string;

    }[] | null
}

interface RecommenderProps {
    type: string
}


async function Recommender(props: RecommenderProps): Promise<MaestroRecommendation> {
    const { type } = props


    try {
        const gptCall = await Axios.post('/campaign/recommendations', {
            "request": type
        })


        if (gptCall.data && gptCall.data.data) {
            return {
                primaryMessage: gptCall.data.data.primaryMessage,
                recommendations: gptCall.data.data.recommendations
            }
        } else {
            return {
                primaryMessage: "There was an error processing your request. Please try re-asking!",
                recommendations: null
            }
        }
    } catch (e) {
        console.log('error', e)
        return {
            primaryMessage: "There was an error processing your request. Please try re-asking!",
            recommendations: null
        }
    }

}


export default Recommender