import {
  ConnectorIndicatorTypes,
  ImpactChartData,
  Payload,
  TooltipSubtitles,
} from "types/global";
import moment from "moment";
import millify from "millify";
import some from "lodash/some";

const { SPOTIFY_FOLLOWERS, SPOTIFY_STREAMS, INSTAGRAM_FOLLOWERS, SHAZAMS } =
  ConnectorIndicatorTypes;

export const getChartDivider = (divider?: {
  top?: boolean;
  bottom?: boolean;
}) => {
  let top = false;
  let bottom = false;

  if (divider?.top) top = divider.top;
  if (divider?.bottom) bottom = divider.bottom;

  return {
    top,
    bottom,
  };
};

export const getBorderClassName = (
  dividerTop: boolean,
  dividerBottom: boolean
) => {
  const top = ["border-t"];
  const bottom = ["border-b"];
  const concat: string[][] = [];

  if (dividerTop) concat.push(top);
  if (dividerBottom) concat.push(bottom);
  return concat.join(" ");
};

export const tooltipContentFormatter = (
  value: number,
  _name: string,
  item: Payload<number, string>
) => {
  const { payload } = item;
  const { type } = payload || {};
  const formattedValue = value.toLocaleString();

  switch (type) {
    case INSTAGRAM_FOLLOWERS:
    case SPOTIFY_FOLLOWERS:
      return [formattedValue, TooltipSubtitles.Followers];
    case SPOTIFY_STREAMS:
      return [formattedValue, TooltipSubtitles.Streams];
    case SHAZAMS:
      return [formattedValue, TooltipSubtitles.Shazams];

    default:
      return formattedValue;
  }
};

export const tooltipLabelFormatter = (
  _label: "string",
  payload: Payload<number, string>[]
) => {
  const data = payload[0]?.payload;
  const { day, date } = data || {};
  return `${day}, ${date?.split("T")[0]}`;
};

export const getSegment = (referenceLineX: number, referenceLineY: number) => [
  {
    x: referenceLineX,
    y: 0,
  },
  {
    x: referenceLineX,
    y: referenceLineY,
  },
];

const getWeeklyXTickFormat = (chartData: ImpactChartData[], index: number) => {
  const showTick = some([
    index === 0,
    index === chartData.length - 1,
    index % 7 === 0,
  ]);

  if (showTick) {
    return moment(chartData[index]?.date.split("T")[0]).format("M/DD");
  }

  return "";
};

const getNormalTickXFormat = (chartData: ImpactChartData[], index: number) => {
  const isOdd = (index + 1) % 2 !== 0;

  return isOdd
    ? moment(chartData[index]?.date.split("T")[0]).format("M/DD")
    : "";
};

export const xTickFormatter =
  (chartData: ImpactChartData[], weekly?: boolean) =>
  (_value: string | number, index: number) =>
    weekly
      ? getWeeklyXTickFormat(chartData, index)
      : getNormalTickXFormat(chartData, index);

export const yTickFormatter = (value: string | number, index: number) =>
  index !== 0 ? millify(value as number) : "";
