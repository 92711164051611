import DialogPaper from "components/shareable/DialogPaper";
import { ReactComponent as UserIcon } from "assets/images/user.svg";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { useStyles } from "./styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { MenuItem, Select, TextField } from "@material-ui/core";
import { Artist, ArtistPermissions, MemberRole, MemberRoles, TeamPricesData } from "types/global";
import { useContext, useEffect, useMemo, useState } from "react";
import { Body2, Subtitle1, Subtitle2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { isValidEmail } from "utils";
import { Banner } from "components/shareable/Banner";
import { TeamPlanContext } from "../../hooks/TeamMembersContext";
import { ReactComponent as ChevronDownIcon } from "assets/images/chevronDown.svg";
import { ArtistsList } from "../ArtistsList";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { addNewMember, getSimulationPrices } from "../../../../../services/symphonyApi/organizationService"
import { toast } from "react-toastify"
import { TOAST_ERROR_OPTIONS, TOAST_SUCCESS_OPTIONS } from "../../utils"
import { MAX_ADDITIONAL_USERS_WITH_TEAM_PRO } from "../../../../../constants"

const { ADMIN, MEMBER } = MemberRoles;
const { ADMINISTRATOR } = ArtistPermissions;

const MemberRolesNames = {
  owner: 'Owner',
  administrator: 'Organization Admin',
  member: 'Member',
};

interface AddMemberModalProps {
  onClose: () => void;
}

export const AddMemberModal = ({ onClose }: AddMemberModalProps) => {
  const classes = useStyles();
  const { teamMembers, teamArtists, getTeamMembers } = useContext(TeamPlanContext);
  const { mobileView } = useMediaBreakpoints();

  const [name, setName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [rol, setRol] = useState<MemberRole>()
  const [artists, setArtists] = useState<Artist[]>(teamArtists)
  const [extraCosts, setExtraCosts] = useState<TeamPricesData | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const missingFields = useMemo(
    () => !name || !lastName || !rol || !email || (email?.length && !isValidEmail(email)) || Boolean(artists.find(artist => !artist.permissionsType)),
    [name, lastName, email, rol, artists]
  )

  useEffect(() => setArtists(teamArtists), [teamArtists])

  useEffect(() => {
    switch (rol) {
      case ADMIN:
        setArtists(teamArtists.map(artist => ({ ...artist, permissionsType: ADMINISTRATOR })));
        break;
      case MEMBER:
        setArtists(teamArtists.map(artist => ({ ...artist, permissionsType: undefined })));
        break;
    }
  }, [rol])

  useEffect(() => {
    if (teamMembers.length >= 5) {
      getExtraCost()
    }
  }, [teamMembers])

  const onAddMember = async () => {
    try {
      if (!name || !lastName || !email || !rol || !isValidEmail(email)) return
      setIsLoading(true)
      const newMember = {
        name,
        lastName,
        email,
        role: rol
      }
      const brands = artists.map(artist => ({
        id: artist.id,
        permissionLevel: artist?.permissionsType || 'view_only'
      }))
      await addNewMember({ member: newMember, brands })
      getTeamMembers()
      toast.success("New member added to the team", TOAST_SUCCESS_OPTIONS)
      onClose();
    } catch (error: any) {
      let message = "Something went wrong adding the member"
      if (error?.response?.data?.message === 'User already belongs to an organization') {
        message = "User already belongs to an organization"
      } else if (error?.response?.data?.message === 'User already invited to your organization') {
        message = "User already invited to your organization"
      }
      toast.error(message, TOAST_ERROR_OPTIONS)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const onAddArtist = (artist: Artist) => {
    setArtists(prev => [...prev, artist]);
  }

  const getExtraCost = async () => {
    const extraCost = await getSimulationPrices({ typeSimulation: 'add-seat' });
    setExtraCosts(extraCost)
  }

  return (
    <DialogPaper
      open
      fullScreen={mobileView}
      dialogStyle={{
        margin: mobileView ? 0 : 'auto 16px',
        borderRadius: mobileView ? 0 : 8,
        maxWidth: 559,
      }}
      containerStyle={{ margin: mobileView ? 0 : 'auto' }}
    >
      <div className="flex flex-col gap-8">
        <HeaderModal
          title="Add a new teammate"
          subtitle="Manage the users who can access your organization and profiles."
          HeaderIcon={<UserIcon className={classes.userIcon} />}
          closeModal={onClose}
        />
        {/* TODO: in the future we should handle prices based on currency selected */}
        {(teamMembers.length >= MAX_ADDITIONAL_USERS_WITH_TEAM_PRO && extraCosts) && (
          <Banner variant="warning" classes="flex flex-col gap-4 p-4">
            <div className="flex flex-col gap-1">
              <Subtitle1>You’re out of seats</Subtitle1>
              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                You’ll be charged with + ${extraCosts.priceSeatRemaining} on your next invoice and then ${extraCosts.priceSeat} per month for this new user
              </Body2>
            </div>
            <div className="flex gap-2 justify-between">
              <Body2>Extra user prorated {extraCosts.remainingDays} days</Body2>
              <Subtitle2>${extraCosts.priceSeatRemaining}</Subtitle2>
            </div>
          </Banner>
        )}
        <div className="flex gap-4">
          <TextField
            type="text"
            required
            value={name}
            variant="outlined"
            style={{ width: '50%' }}
            placeholder="First name"
            className={classes.input}
            onChange={(e) => {
              const REG_EXP: RegExp = /^[ A-Za-z]*$/
              if (REG_EXP.test(e.target.value)) setName(e.target.value)
            }}
          />
          <TextField
            type="text"
            required
            value={lastName}
            variant="outlined"
            style={{ width: '50%' }}
            placeholder="Last name"
            className={classes.input}
            onChange={(e) => {
              const REG_EXP: RegExp = /^[ A-Za-z]*$/
              if (REG_EXP.test(e.target.value)) setLastName(e.target.value)
            }}
          />
        </div>
        <TextField
          type="email"
          error={Boolean(email?.length && !isValidEmail(email))}
          required
          value={email}
          inputMode="email"
          variant="outlined"
          fullWidth placeholder="Email"
          className={classes.input}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Select
          fullWidth
          required
          displayEmpty
          value={rol}
          onChange={(e) => setRol(e.target.value as MemberRole)}
          variant="outlined"
          className={classes.select}
          IconComponent={() => <ChevronDownIcon className="mr-4 h-8 w-8" />}
          renderValue={(value) => {
            if (!value) {
              return <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Select permissions</Body2>
            }
            return <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{MemberRolesNames[value as MemberRole]}</Body2>
          }}
        >
          <MenuItem value="administrator"><Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Organization Admin</Body2></MenuItem>
          <MenuItem value="member"><Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Member</Body2></MenuItem>
        </Select>
        {rol === ADMIN && (
          <Banner variant="accent" classes="p-4">
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Organization admins have full access to update profiles and organization settings.</Body2>
          </Banner>
        )}
        {rol === MEMBER && (
          <ArtistsList artists={artists} onUpdateArtists={setArtists}/>
        )}
        <div className="w-full flex justify-end">
          <PrimaryButton
            loading={isLoading}
            text="Send invite"
            height="39px"
            onClick={onAddMember}
            disabled={missingFields}
            tooltip={missingFields ? "Please fill all the required fields" : ""}
          />
        </div>
      </div>
    </DialogPaper>
  );
};
