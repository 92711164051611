import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  FunctionComponent,
  useContext,
} from "react"
import {
  StyledLabel,
  ArtistImage as ChannelImage,
  CloseButtonContainer,
  CloseIconContainer,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
  StyledButton,
  SearchListContainer,
  CreateNewArtistcontainer,
  CreateNewArtistImage,
  CreateNewBrandLabel,
  ArtistNameBoldLabel,
} from "../styles"
import CloseIcon from "components/shareable/CloseIcon"
import { ReactComponent as YoutubeIcon } from "assets/images/youtube-white.svg"
import { TextField, Dialog, Grid, InputAdornment, useTheme, useMediaQuery, Paper, Chip } from "@material-ui/core"
import debounce from "lodash/debounce"
import { CurrentBrand, OnboardingArtist, YouTubeChannel } from "types/global"
import clsx from "clsx"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import { getDescription, getHelperText, getModalContainerHeight } from "./utils"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import dayjs from "dayjs"
import CreateNewBrand from 'assets/images/CreateNewBrand.png';
import { useHistory } from "react-router-dom"
import { Container } from "styles/shared"

interface Props {
  open: boolean
  closeModal: () => void
}

const SearchSelector: FunctionComponent<Props> = ({

  open,

  closeModal,
}: Props) => {

  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(421))
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState<string>("")
  const [results, setResults] = useState<any[]>([])
  const [error, setError] = useState(false)


  const { setCurrentBrand, currentBrand } = useContext(CurrentBrandContext);
  // const showConnectButton = !isEqual(results, [selectedYoutubeChannel])
  const showConnectButton = false

  const containerHeight = getModalContainerHeight(results.length)


  const SEARCH_OPTIONS = [
    {
      value: 'brand',
      label: "Artist Name",

      placeholder: 'e.g. Bad Bunny, Madonna...'
    },
    {
      value: 'campaignId',
      label: "Campaign ID",
      placeholder: 'e.g. 1242'
    },

    {
      value: 'email',
      label: "User Email",
      placeholder: 'e.g. megh@integral.studio'
    },
    {
      value: 'stripe_id',
      label: "Stripe ID",
      placeholder: 'e.g. Customer ID (cus_123) or Subscription ID (sub_123)'
    },


  ]

  const [searchOption, setSearchOption] = useState(SEARCH_OPTIONS[0])

  const helperText = getHelperText({
    value: textFieldValue,
    error,
    results,
    loading,
    searchString: textFieldValue
  })

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string, searchType: string) => {
        setLoading(true);
        try {
          const response = await Axios.get(`/brand/search?searchTerm=${value}&type=${searchType}`);
          const { data } = response;
          if (data.isError) {
            setError(true);
          } else {
            setResults(data.data.map((brand: CurrentBrand) => ({
              ...brand,
              id: brand.id
            })));
          }
        } catch (e) {
          setError(true);
        }
        setLoading(false);
      }, 800),
    []
  )



  const handleOnClick = (brand: CurrentBrand) => () => {
    closeModal()
    setCurrentBrand(brand)
  }

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length && open) {
      setLoading(true)
      debouncedChangeHandler(value, searchOption.value)
    }

    setTextFieldValue(value)
  }

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])


  useEffect(() => {
    if (!textFieldValue) {
      setResults([])
      setError(false)
      setLoading(false)
    }
  }, [textFieldValue])

  useEffect(() => {
    setTextFieldValue("")
  }, [searchOption])

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Admin Artist Selector</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              Search for any artist across Symphony.
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <Container display="flex" width="100%" alignItems="center" gap="8px" margin="0px">
          <StyledLabel fontWeight={500} fontSize={16}>
            Search by:
          </StyledLabel>
          <Container display="flex" gap="10px">
            {SEARCH_OPTIONS.map((option) => {
              const selected = searchOption.value === option.value;
              return (
                <Chip
                  {...(selected ? {
                    color: 'primary',
                  } : {})}

                  label={option.label} onClick={(e) => setSearchOption(option)} />
              )
            })}


          </Container>

        </Container>
        <ModalPlatformInputContainer>

          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loading && (
                <InputAdornment position="end">
                  <Lottie
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder={searchOption.placeholder}
            helperText={helperText}
            error={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </ModalPlatformInputContainer>
        {textFieldValue && !loading && (<SearchListContainer height={containerHeight}>
          <Grid container direction="column">
            {results.length > 0 && textFieldValue != "" && !loading ? results.map((brand, index) => {
              const {
                name: title,
                image: thumbnailUrl,
                tier,
                slug,
                createdAt,
                users
              } = brand;

              const description = tier === 'pro' ? 'Pro' : 'Free';

              return (
                <Grid
                  item
                  key={brand.id}
                  className={clsx(
                    classes.channelItemRow,
                    index === 0 && classes.dividerBottom,
                    index !== 0 && index !== 1 && classes.dividerTop
                  )}
                >
                  <Grid
                    className="cursor-pointer"
                    onClick={handleOnClick(brand)}
                    spacing={showConnectButton && isMobile ? 2 : 3}
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item className={classes.channelImage}>
                      <ChannelImage
                        widthStyle={setResponsiveView ? "48px" : "56px"}
                        heightStyle={setResponsiveView ? "48px" : "56px"}
                        alt={`${title}-url-image`}
                        src={thumbnailUrl}
                      />
                    </Grid>
                    <Grid
                      item
                      className={clsx(showConnectButton && isMobile && "w-24")}
                      xs={showConnectButton && !isMobile || !showConnectButton}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <StyledLabel fontWeight={500} fontSize={16}>
                            {title}
                          </StyledLabel>
                          <StyledLabel
                            fontSize={16}
                            fontWeight={400}
                            color="#707070"
                          > ({slug})</StyledLabel>
                        </Grid>
                        <Grid item>
                          {tier === 'pro' ? (

                            <StyledLabel
                              marginLeft="auto"
                              color="#E08507"
                              fontSize={12}
                              fontWeight={400}
                            >
                              PRO ACCOUNT
                            </StyledLabel>

                          ) : (
                            <StyledLabel
                              fontSize={12}
                              fontWeight={400}
                              color="#707070"
                            >
                              Free Account
                            </StyledLabel>
                          )}
                          <StyledLabel
                            fontSize={10}
                            fontWeight={400}
                            color="#707070"
                          >
                            {"   "}/{"   "}
                          </StyledLabel>

                          <StyledLabel
                            fontSize={12}
                            fontWeight={400}
                            color="#707070"
                          >
                            Created on {dayjs(createdAt).format('MMMM DD, YYYY')}
                          </StyledLabel>
                        </Grid>
                        <Grid item>
                          <StyledLabel
                            margin="12px 0px 0px 0px"
                            fontSize={12}
                            fontWeight={400}
                            color="#707070"
                          >
                            Users:
                          </StyledLabel>
                          {users.map(({ id, email }: {
                            id: string,
                            email: string
                          }) => {
                            let icon;


                            return (
                              <Chip
                                className={classes.chip}
                                label={email}
                              />
                            );
                          })}

                        </Grid>
                      </Grid>
                    </Grid>
                    {showConnectButton && (
                      <Grid item className={classes.connectButtonContainer}>
                        <Grid container direction="row-reverse">
                          <StyledButton>Connect</StyledButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            }) : (
              <Container display="flex" gap="12px"
              flexDirection="column" width="100%">
                <StyledLabel

                  fontSize={16}
                  fontWeight={400}
                  color="#707070"
                >
                  No results found
                </StyledLabel>
                <CreateNewArtistcontainer
                  onClick={() => {
                    history.push('/add-artist');

                  }
                  }
                >
                  <CreateNewArtistImage src={CreateNewBrand} />
                  {searchOption.value === 'brand' ? (
                    <CreateNewBrandLabel>
                      Create new artist:&nbsp;
                      <ArtistNameBoldLabel>{textFieldValue}</ArtistNameBoldLabel>
                    </CreateNewBrandLabel>
                  ) : (
                    <CreateNewBrandLabel>
                      Create new artist
                    </CreateNewBrandLabel>
                  )}

                </CreateNewArtistcontainer>
              </Container>
            )}

          </Grid>
        </SearchListContainer>)}
      </ModalContainer>
    </Dialog>
  )
}

export default SearchSelector
