import RecordPreview from "components/shareable/RecordPreview";
import { BackgroundTypes, RecordPreviewData, StyleTypes, Website } from "types/global";
import useStyles, { PagePreview, PreviewHeader, BlurredPicture, TitleContainer, PreviewContainer, Preview } from "./styles";
import { ReactComponent as EditIcon } from "assets/images/edit.svg"
import { ReactComponent as CloseIcon } from "assets/images/close.svg"
import { Dialog } from "@material-ui/core";
import { SymphonyTooltip } from "styles/shared";

interface Props {
  open: boolean;
  contentDetails: RecordPreviewData;
  style: Website;
  styleType: StyleTypes;
  closePreview: () => void;
  openEditModal?: () => void;
  showEditButton?: boolean;
  bgColor?: string;
  isFinalized?: boolean;
}

const PreviewModal: React.FC<Props> = ({
  open,
  contentDetails,
  style,
  styleType,
  openEditModal,
  showEditButton,
  bgColor,
  closePreview,
  isFinalized,
}: Props) => {

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialogFullScreen,
      }}
      fullScreen
    >
      <PreviewHeader padding="16px 8px">
        <TitleContainer>
          <CloseIcon onClick={closePreview} height={24} width={24} />
          Website Preview
        </TitleContainer>

        {showEditButton && (
          <SymphonyTooltip title={isFinalized ? 'Edit your website in the “Website” tab' : "Edit your Pre-save"}>
              <EditIcon onClick={openEditModal} color={isFinalized ? "#b2b2b2" : "#8800FF"} cursor={isFinalized ? "not-allowed" : "pointer"} />
          </SymphonyTooltip>
        )}
      </PreviewHeader>
      <PagePreview width="100%" height="100%">
        <PreviewContainer
          background={bgColor}
          height="fit-content"
          position="relative"
          minHeight="100%"
        >
          {style?.bgType === BackgroundTypes.ARTWORK && (
            <BlurredPicture
              alt="artwork_as_background"
              src={contentDetails.artworkUrl}
              height='100%'
              top='0'
              className="filter"
            />
          )}
          <Preview
            width="80%"
            margin="3% 0px 8% 0px"
            borderRadius='0px'
            overflow="visible"
          >
            <RecordPreview
              backgroundColor={style?.bgColor || ""}
              mainColor={style?.primaryColor || ""}
              secondaryColor={style?.secondaryColor || ""}
              backgroundType={style?.bgType || ""}
              styleType={styleType}
              contentDetails={contentDetails}
              relative
              creation={false}
            />
          </Preview>
        </PreviewContainer>
      </PagePreview>
    </Dialog>
  );
};

export default PreviewModal;
