import { BrandContent, CurrentBrand } from "types/global"
import { WEBSITE_TYPES, WebsiteType } from "../../types"

export const formatDataToPublish = (data: any, brandContent: BrandContent, currentBrand?: CurrentBrand) => {
  const {
    bgType,
    bgValue,
    embedYt,
    enableSocialIcons,
    fields,
    fontThemeStyle,
    mainColor,
    secondaryColor,
    selectedPreviewTrack,
    submitButtonText,
    showSecondaryLine,
    streamingLinks,
    styleType,
    subtitle,
    themeStyle,
    fullVideoUrl,
    titleText,
    youtubeID,
    youtubeVideoLink,
    previewUrl,
  } = data

  const defaultTitle = brandContent.type === WEBSITE_TYPES.DATA_COLLECTOR ?  currentBrand?.name : "Listen to"
  const brandContentType: WebsiteType = brandContent?.type as WebsiteType || WEBSITE_TYPES.DATA_COLLECTOR
  let brandContentName = brandContent?.name || titleText
  if (brandContent.type === WEBSITE_TYPES.DATA_COLLECTOR) {
    brandContentName = titleText
  }

  let external_links;
  if (streamingLinks) {
    external_links = Object.entries(streamingLinks).map(([key, value]: [string, any]) => ({
      name: key === 'custom_link' ? value.title : key,
      url: value.url,
      enabled: value.enabled,
      type: value.type || key,
    }))
  }

  return {
    id: brandContent?.id || undefined,
    type: brandContentType,
    content_metadata: {
      ...brandContent.content_metadata,
      name: brandContentName,
      external_links,
      website: {
        fields,
        post_subscribe_message: "Thanks for subscribing! Stay tuned.",
        style: {
          titleText: titleText.length ? titleText : defaultTitle,
          showSecondaryLine,
          subtitle,
          secondaryColor,
          primaryColor: mainColor,
          submitButtonText,
          previewUrl: brandContent?.content_metadata.thumbnail_url || previewUrl || undefined,
          bgType,
          bgColor: bgValue?.color,
          font: fontThemeStyle,
          template: themeStyle,
          youtubeEmbed: embedYt ? { id: youtubeID, url: youtubeVideoLink } : null,
          buttonConfig: styleType,
          enableSocialIcons,
          selectedPreviewTrack,
          full_video_url: fullVideoUrl,
        }
      }
    },
    brand_id: brandContent?.brand_id,
    slug: brandContent?.slug || '',
    name: brandContentName,
    url: brandContent?.url,
  }
}
