import { AppBar, BottomNavigation, BottomNavigationAction, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, SvgIcon, Theme, Toolbar, Typography } from "@material-ui/core";
import { Chat, Favorite, Inbox, LocationOnOutlined, Menu, RestoreOutlined } from "@material-ui/icons";
import { MarketingIconPath, SupportIcon, SupportIconPath } from "components/svg-icons";
import React, { useContext, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { NavigationItem, showIntercom } from ".";
import SubscriptionStatusIndicator from "./Components/SubscriptionStatusIndicator";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";


const useStyles = makeStyles((theme: Theme) => ({
    overall: {
        fontFamily: "DIN",
        fontWeight: 'normal'
    },
    bgColor: {
        background: "#2C2135"
    },
    grayColor: {
        color: "#888888"
    },
    selected: {
        color: "#FFFFFF",
    },
    menuIcon: {
        color: "#888888", // The gray color for the Menu icon
    }
}));

interface MobileNavigationProps {
    navigationItems: NavigationItem[];
    isProUser: boolean;
    freeTrialOver: boolean;
}

/**
 * Looks through the NavigationItem array to find the info for the currently active page
 * @param location the current page location
 * @param navigationItems all of the navigation items
 * @returns 
 */
function getCurrentlySelectedPage(location: {
    pathname: string;
}, navigationItems: NavigationItem[]): NavigationItem | undefined {
    const pathname = location.pathname;
    // first search the main HREFs, then the alternate HREFs to see if it matches
    const selectedPage = navigationItems.find((navItem: NavigationItem) => {
        const {
            href: navItemHref,
            alternateHrefs,
            name
        } = navItem;

        if (navItemHref === pathname) {
            return true
        }

        if (alternateHrefs && alternateHrefs.indexOf(pathname) > -1) {
            return true
        }
    });

    return selectedPage;
}

export default function MobileNavigation(props: MobileNavigationProps) {

    const {
        navigationItems,
        isProUser,
        freeTrialOver
    } = props

    const classes = useStyles();

    // location used to check if item selected
    const location = useLocation()

    const currentNavigationItem = getCurrentlySelectedPage(location, navigationItems);
    // to handle opening / closing mobile drawer
    const [drawerOpen, setOpenDrawer] = useState<boolean>(false)

    const [value, setValue] = React.useState(0);

    // Filter navigation items for the BottomNavigation bar
    const bottomNavigationItems = navigationItems.filter((navItem: NavigationItem) => !navItem.hideOnMobile);

    // Filter navigation items for the Drawer
    const drawerNavigationItems = navigationItems.filter((navItem: NavigationItem) => navItem.hideOnMobile);

    // used to let people upgrade to Pro on mobile
    const { handleOpenModal } = useContext(UpgradeModalContext);


    function showUpgradeToProButton() {
        if (isProUser) {
            return null
        }

        // only show upgrade to Pro on DAshboard
        if (currentNavigationItem && currentNavigationItem.name === "Dashboard") {
            return (
                <BottomNavigation
                    className={`${classes.bgColor}`}
                >
                    <SubscriptionStatusIndicator
                        maxWidth={'100%'}
                        width={'100%'}
                        margin="auto"
                        text={freeTrialOver ? "Upgrade to Pro" : "Start 14-day Free Pro Trial"}
                        showStars
                        handleOpenModal={() => handleOpenModal({
                            source: "Mobile Dashboard - Bottom Bar"
                        })}
                    />
                </BottomNavigation>
            )
        }
    }
    return (
        <Box className="lg:hidden">
            <Box position="fixed" bottom={0} left={0} right={0} zIndex={99}>
                {showUpgradeToProButton()}
                <BottomNavigation
                    className={`${classes.bgColor}`}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                >
                    {bottomNavigationItems.map((item: NavigationItem, index: number) => {
                        // ... rest of your code, including styling for selected items
                        const isSelected = currentNavigationItem && currentNavigationItem.name === item.name;

                        const IconComponent = item.iconComponent;
                        const color = isSelected ? "#FFFFFF" : "#888888";

                        return (
                            <BottomNavigationAction
                                disableRipple
                                component={Link} to={item.href!}
                                // label={<Typography className={isSelected ? classes.selected : classes.grayColor}>{item.name}</Typography>}
                                icon={IconComponent ? <IconComponent color={color} /> : null}


                            />
                        );
                    })}
                    <BottomNavigationAction
                        disableRipple
                        icon={
                            <IconButton
                                style={{ color: "#888888" }}
                                onClick={() => setOpenDrawer(true)}>
                                <SupportIcon color={"#888888"} />

                            </IconButton>
                        }
                    />
                    {/* <BottomNavigationAction
                        disableRipple
                        icon={
                            <IconButton
                                style={{ color: "#888888" }}
                                onClick={() => setOpenDrawer(true)}>
                                <SupportIcon color={"#888888"} />

                            </IconButton>
                        }
                    /> */}

                </BottomNavigation>
            </Box>

            <Drawer
                // ... rest of your code
                anchor={'bottom'}
                open={drawerOpen}
                onClose={() => setOpenDrawer(false)}
            >
                <List>
                    {drawerNavigationItems.map((item: NavigationItem, index: number) => {
                        // ... rest of your code, including styling for selected items
                        const isSelected = currentNavigationItem && currentNavigationItem.name === item.name;

                        const additionalProps: any = {}

                        if (item.onClick) {
                            additionalProps['onClick'] = (e: any) => {
                                setOpenDrawer(false);
                                if (item.onClick) {

                                    item!.onClick(e)
                                }
                            }
                        } else {
                            additionalProps['component'] = Link
                            additionalProps['to'] = item.href!
                            additionalProps['onClick'] = () => {
                                setOpenDrawer(false);
                            }
                        }

                        return (
                            <ListItem
                                button
                                {...additionalProps}

                                key={item.name}>
                                <ListItemIcon style={{ minWidth: 0 }}
                                    className="mr-4"
                                >
                                    <SvgIcon
                                        htmlColor={isSelected ? "#8800FF" : "#707070"} fontSize="small" viewBox={item.svgIconViewbox!}
                                    >
                                        {item.iconPath}

                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText
                                    style={{
                                        color: isSelected ? "#8800FF" : "#707070"
                                    }}
                                    className={`${classes.overall}`}
                                    primary={item.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </Drawer>
        </Box >
    )
}