/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useState, useContext, Dispatch, SetStateAction } from "react"
import { Switch, Route, useHistory, useLocation, Router } from "react-router-dom"
import Navbar from "components/Navbar"
import AddArtist from "pages/post-auth/AddArtist"
import Website from "pages/post-auth/Website/index2"
import Dashboard from "pages/post-auth/DashboardModule/Dashboard"
import SettingsPage from "pages/post-auth/SettingsPage"
import Loader from "components/Loader"
import { useAuth0 } from "@auth0/auth0-react"
import MarketingSuccessPage from "../../pages/post-auth/MarketingPage/SuccessPage"
import MarketingFlow from "pages/post-auth/MarketingPage/index2"
import MarketingCampaignDetails from "pages/post-auth/MarketingCampaignDetails"
import { getCookie, identify, setCurrentContext } from "analytics"
import Fans from "pages/post-auth/Fans";
import PreSaveProvider from "pages/post-auth/MarketingPage/Data/PreSaveProvider"
import PreSave from "pages/post-auth/MarketingPage/PreSave"
import MarketingDataProvider from "pages/post-auth/MarketingPage/Data/Provider"
import SongAds from "pages/post-auth/MarketingPage/SongAds"
import GetPlaylisted from "pages/post-auth/MarketingPage/Playlisting"
import VideoViews from "pages/post-auth/MarketingPage/VideoAds"
import CreativeGeneratorStatusPage from "pages/post-auth/MarketingPage/CreativeGenerator/StatusPage"
import CreativeGenerator from "pages/post-auth/MarketingPage/CreativeGenerator"
import NavbarProvider from "pages/post-auth/MarketingPage/Data/NavbarProvider"
import LinkClicks from "pages/post-auth/MarketingPage/LinkClicks"
import AlertContext from "Hooks/AlertContext"
import FanbaseCampaignDetails from "pages/post-auth/Fans/Components/Details";
import UpgradeModalContext from "Hooks/UpgradeModalContext"
import isEqual from "lodash/isEqual"
import SubscriptionSuccessPage from "pages/post-auth/SettingsPage/SubscriptionSuccessPage"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { PostAuthContainer, RouterContainer } from "./styles"
import { CAMPAIGN_WIZARD_ROUTES } from "../../constants"
import GrowInstagramEngagement from "pages/post-auth/MarketingPage/GrowInstagram"
import ConnectModalsProvider from "Hooks/ConnectModalsProvider"
import EnterEmail from "pages/post-auth/EnterEmail"
import { CurrentBrand, UserData } from "types/global"
import CurrentBrandProvider, { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import GettingStartedProvider from '../../Hooks/GettingStartedContext/index';
import { FanbaseProvider } from "Hooks/FanbaseContext"
import * as Sentry from "@sentry/react";
import TiktokAds from "pages/post-auth/MarketingPage/TikTokAds"
import ReferPage from "pages/post-auth/Refer"
import AdminDataUpload from "pages/post-auth/Admin"
import CampaignReports from "pages/post-auth/Admin/reports"
import Subscribe from "pages/post-auth/Subscribe"
import { SystemColors } from "types/globalStyles"
import TiktokCallbackPage from "pages/post-auth/MarketingPage/Components/TikTokCallback"
import Learn from "pages/post-auth/Learn"
import KPITrends from "pages/post-auth/Admin/kpis"

import AffiliateTrends from "pages/post-auth/Admin/affiliates"
import WebsitesList from "pages/post-auth/Website/components/WebsiteList"
import { createBrowserHistory } from 'history';
import getUserBrands from "layouts/controllers/getUserBrands"
import BaremetricsCreditCardRecoveryForm, { BarepayComponent } from "components/baremetrics/BaremetricsCreditCardRecoveryForm"
import OrganizationPage from "pages/post-auth/OrganizationPage"
import SourcesTrends from "pages/post-auth/Admin/sources"

const getBackgroundColor = (location: string) => {
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.filter(
    (route) => !route.includes("success")
  ).includes(location)
  if (isCampaignWizard || location.includes("add-artist") || location.includes("settings") || location.includes("fans") || location.includes("marketing/campaigns")) {
    return "#F5F3F6";
  }
  return "#ffffff";
};


const getHeight = (args: {
  path: string
  isCampaignWizard: boolean
  setFullHeightForLgWidthScreen: boolean
}) => {
  const { path, isCampaignWizard, setFullHeightForLgWidthScreen } = args

  if (
    isEqual(path, "settings") ||
    isEqual(path, "email") ||
    isEqual(path, "website") ||
    isEqual(path, "fans") ||
    isEqual(path, "organization") ||
    isCampaignWizard || setFullHeightForLgWidthScreen
  ) {
    return "100%"
  }

  return "fit-content"
}

const history = createBrowserHistory();

const PostAuthRoutes = ({
  userData,
  setUserData,
  setLoading
}: {
  userData: UserData;
  setUserData: (user: UserData) => void
  setLoading: (loading: boolean) => void
}) => {
  return (
    <Router history={history}>
      <CurrentBrandProvider>

        <AppLoader
          user={userData}
          setUser={setUserData}
        />
      </CurrentBrandProvider>
    </Router>
  )
}

function AppLoader(props: {
  user: UserData;
  setUser: (user: UserData) => void
}) {
  const {
    user,
    setUser
  } = props

  // used for routing
  const history = useHistory();
  const location = useLocation();
  const { logout, user: auth0User } = useAuth0();
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);

  const [loadedBrands, setLoadedBrands] = useState<CurrentBrand[] | null>(null);
  const [loadingBrands, setLoadingBrands] = useState(false);

  // set to true if we should display routes (if the user is linked)
  const [canDisplayRoutes, setCanDisplayRoutes] = useState(false);



  // first order of business - if the user does not have an email linked, we should set it
  useEffect(() => {
    if (user) {
      // if there's no email attached, dont load brands yet - just go to the email page
      if (!user.email) {
        history.push("/email")
        setCanDisplayRoutes(true)
      } else {
        // if the user has an email, link it to the local user
        identify({
          id: String(user.userid),
          email: user.email ? user.email : undefined,
          name: undefined
        });
        // get loaded brands once user has set their email + we have a user
        if (!loadedBrands && !loadingBrands) {
          getBrands()
        }
      }
    } else {
      setCanDisplayRoutes(false)
    }
  }, [user])

  function linkUserEmail(email: string) {

  }
  // if loaded brands is set, and its empty, then we should redirect to add artist
  useEffect(() => {
    if (typeof loadedBrands !== "undefined" && loadedBrands !== null) {
      if ((loadedBrands as any).length === 0) {
        history.push("/add-artist")
        setCanDisplayRoutes(true)
      }
    }
  }, [loadedBrands])

  // when the user selects a brand from the dropdown in the navbar, reflect it in storage too
  useEffect(() => {
    // when current brand updates, set its ID in localStorage
    // and set the current context on intercom, and everywhere else
    if (currentBrand) {
      setCanDisplayRoutes(true)


      const additionalProps = {
        ...user?.metadata?.onboarding_answers
      } || {}


      // send the affiliate link to rewardful
      if (user?.metadata?.rewardful_affiliate_link) {
        additionalProps.rewardful_affiliate_link = user?.metadata?.rewardful_affiliate_link
      }

      setCurrentContext({
        id: currentBrand.id,
        name: currentBrand.name,
        slug: currentBrand.slug,
        tier: currentBrand.tier,
        additionalProps: additionalProps
      })
    }
  }, [currentBrand])


  // called to logout
  const logoutSession = () => logout({ returnTo: window.location.origin })


  // this is once a user is logged in ~ grab their availabel brands
  async function getBrands() {
    try {
      setLoadingBrands(true)
      const brandsArr: CurrentBrand[] = await getUserBrands()
      // if there is a brand associated with the user, then try to use
      // localstorage to get the most recently used brand
      // otherwise default to the first brand
      if (brandsArr.length > 0) {

        // use localstorage to get the last used brand
        const cachedBrand = localStorage.getItem("selected-brand")

        let startingBrand: CurrentBrand | undefined | null = null
        if (cachedBrand) {
          startingBrand = brandsArr.find(
            (o: any) => o.id === parseInt(cachedBrand!)
          )
        }

        // otherwise default to the first brand
        if (!startingBrand) {
          const firstBrand = brandsArr[0]
          localStorage.setItem("selected-brand", String(firstBrand.id!))
          startingBrand = firstBrand
        }
        setCurrentBrand(startingBrand)
      }
      setLoadedBrands(brandsArr)
      setLoadingBrands(false)
    } catch (err) {
      console.error("Error getting brands", err)
      if ((err as any).response?.status === 500) {
        Sentry.captureException(`Error getting the brands with status 500: ${err}`)
        // log the user out
        logoutSession()
      }
    }
  }

  // used to determine how views should show up
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.filter(
    (route) => !route.includes("success")
  ).includes(location.pathname)
  const hideNavBar = canDisplayRoutes
    ? (location.pathname === "/add-artist" || location.pathname === '/subscribe' || !loadedBrands || loadedBrands.length === 0)
    : true
  const backgroundColor = getBackgroundColor(location.pathname)


  const mainApp = canDisplayRoutes ? (
    <PostAuthApp
      setCurrentBrand={setCurrentBrand}
      currentBrand={currentBrand!}
      getBrands={getBrands}
      loadedBrands={loadedBrands!}
      user={user}
      setUser={setUser}
      isLoadingBrands={loadingBrands}
    />
  ) : (
    <Loader />
  )


  return (
    <RouterContainer
      {...{ backgroundColor }}
      height={isCampaignWizard ? "auto" : "100%"}
      minHeight="100vh"
    >
      <UpgradeModalContext
        reloadBrands={getBrands}
        brandSlug={currentBrand?.slug}
        user={user}>
        <GettingStartedProvider currentBrand={currentBrand?.id}>
          <FanbaseProvider currentBrand={currentBrand?.id}>
            <AlertContext>
              <NavbarProvider>
                <Navbar
                  user={user}
                  isHidden={hideNavBar}
                  setCurrentBrand={setCurrentBrand}
                  currentBrand={currentBrand}
                  loadedBrands={loadedBrands}
                />
                {mainApp}


              </NavbarProvider>
            </AlertContext>
          </FanbaseProvider>
        </GettingStartedProvider>
      </UpgradeModalContext>
    </RouterContainer>

  )
}

function PostAuthApp(props: {
  user: UserData;
  getBrands: () => Promise<void>;
  setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>>
  currentBrand: CurrentBrand;
  loadedBrands: CurrentBrand[];
  isLoadingBrands: boolean;
  setUser: (user: UserData) => void
}) {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const [, path] = location.pathname.split("/")
  const setFullHeightForLgWidthScreen = useMediaQuery(
    theme.breakpoints.up(1023)
  )
  const isCampaignWizard = CAMPAIGN_WIZARD_ROUTES.includes(location.pathname)
  const backgroundColor =
    path === "subscribe"
      ? SystemColors.PAPER_CONTAINER
      : SystemColors.PAPER_BACKGROUND_CONTAINER
  const height = getHeight({
    path,
    isCampaignWizard,
    setFullHeightForLgWidthScreen
  })
  const {
    getBrands,
    currentBrand,
    loadedBrands,
    setCurrentBrand,
    user,
    setUser,
    isLoadingBrands,
  } = props


  return (
    <PostAuthContainer
      id="main-content"
      className="main-body"
      {...{ height, backgroundColor }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route
            exact
            path={"/email"}
            children={<EnterEmail user={user} setUser={setUser} />}
          />
          <Route
            exact
            path={["/", "/dashboard"]}
            children={
              <Dashboard
                loadedBrands={loadedBrands}
                isLoadingBrands={isLoadingBrands}
                user={user}
              />
            }
          />
          <Route
            exact
            path="/marketing/campaigns/:id"
            children={<MarketingCampaignDetails {...{ currentBrand }} />}
          />
          <Route
            exact
            path="/marketing/tools/creatives"
            children={<CreativeGeneratorStatusPage {...{ currentBrand }} />}
          />
          <Route
            exact
            path="/marketing/new/creatives"
            children={
              <CreativeGenerator
                setGoal={() => null}
                currentBrand={currentBrand}
                refreshCampaigns={() => null}
                reloadBrands={() => null}
              />
            }
          />
          <Route
            exact
            path="/marketing/new/presave"
            children={
              <PreSaveProvider>
                <PreSave currentBrand={currentBrand} setGoal={() => null} />
              </PreSaveProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/music-streams"
            children={
              <MarketingDataProvider>
                <ConnectModalsProvider>
                  <SongAds
                    subtype="increase_record_streams"
                    currentUser={user!}
                    setGoal={() => null}
                    currentBrand={currentBrand}
                    refreshCampaigns={() => null}
                    reloadBrands={getBrands}
                  />
                </ConnectModalsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/grow-playlist-followers"
            children={
              <MarketingDataProvider>
                <ConnectModalsProvider>
                  <SongAds
                    subtype="grow_playlist_followers"
                    currentUser={user!}
                    setGoal={() => null}
                    currentBrand={currentBrand}
                    refreshCampaigns={() => null}
                    reloadBrands={getBrands}
                  />
                </ConnectModalsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/grow-instagram-engagement"
            children={
              <MarketingDataProvider>
                <ConnectModalsProvider>
                  <GrowInstagramEngagement
                    currentUser={user!}
                    setGoal={() => null}
                    currentBrand={currentBrand}
                    refreshCampaigns={() => null}
                    reloadBrands={getBrands}
                  />
                </ConnectModalsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            exact
            path="/marketing/new/playlists"
            children={
              <GetPlaylisted
                currentUser={user!}
                setGoal={() => null}
                currentBrand={currentBrand}
                refreshCampaigns={() => null}
                reloadBrands={() => null}
              />
            }
          />
          <Route
            exact
            path="/marketing/new/video-views"
            children={
              <VideoViews
                currentUser={user!}
                setGoal={() => null}
                currentBrand={currentBrand}
                refreshCampaigns={() => null}
                reloadBrands={() => null}
              />
            }
          />
          <Route
            exact
            path="/marketing/new/tiktok-ads"
            children={
              <TiktokAds
                setGoal={() => null}
                currentBrand={currentBrand}
                refreshCampaigns={() => null}
                reloadBrands={() => null}
              />
            }
          />
          <Route
            exact
            path="/marketing/new/link-clicks"
            children={
              <MarketingDataProvider>
                <ConnectModalsProvider>
                  <LinkClicks
                    currentUser={user!}
                    setGoal={() => null}
                    currentBrand={currentBrand}
                    refreshCampaigns={() => null}
                    reloadBrands={getBrands}
                  />
                </ConnectModalsProvider>
              </MarketingDataProvider>
            }
          />
          <Route
            path="/add-artist"
            children={
              <AddArtist
                reloadBrands={getBrands}
                user={user}
                setUser={setUser}
                setGlobalCurrentBrand={setCurrentBrand}
              />
            }
          />
          <Route
            path="/marketing/success"
            children={
              <MarketingSuccessPage
                reloadBrands={getBrands}
                currentBrand={currentBrand}
                refreshCampaigns={() => {
                  history.push("/marketing")
                }}
              />
            }
          />
          <Route
            path="/tiktok/callback"
            children={
              <TiktokCallbackPage
                currentBrand={currentBrand}
                loadedBrands={loadedBrands}
                refreshCampaigns={() => {
                  history.push("/marketing")
                }}
              />
            }
          />
          <Route
            path="/marketing"
            children={
              <MarketingFlow
                reloadBrands={getBrands}
                currentBrand={currentBrand}
                setCurrentBrand={setCurrentBrand}
                loadedBrands={loadedBrands}
                user={user}
              />
            }
          />
          <Route
            path="/website/:websiteType/:websiteSlug"
            children={
              <Website reloadBrands={getBrands} />
            }
          />
          <Route
            path="/website"
            exact
            children={
              <WebsitesList />
            }
          />
          <Route
            path="/fans/campaigns/:id"
            component={() => <FanbaseCampaignDetails />}
          />
          <Route
            path="/fans"
            children={
              <Fans
                reloadBrands={getBrands}
                loadedBrands={loadedBrands}
              />
            }
          />
          <Route
            path="/learn"
            children={
              <Learn
                reloadBrands={getBrands}
                currentBrand={currentBrand}
                loadedBrands={loadedBrands}
              />
            }
          />

          <Route
            path="/settings"
            exact
            children={
              <SettingsPage
                currentBrand={currentBrand}
                setCurrentBrand={setCurrentBrand}
                loadedBrands={loadedBrands}
                user={user}
              />
            }
          />

          <Route
            path="/organization"
            exact
            children={
              <OrganizationPage
                reloadBrands={getBrands}
                loadedBrands={loadedBrands}
                user={user}
              />
            }
          />
          <Route
            path="/refer"
            exact
            children={
              <ReferPage />
            }
          />
          <Route
            path="/admin"
            exact
            children={
              <AdminDataUpload
                user={user}

              />
            }
          />
          <Route
            path="/admin/reports"
            exact
            children={
              <CampaignReports
                user={user}

              />
            }
          />
          <Route
            path="/admin/kpis"
            exact
            children={
              <KPITrends
                user={user}

              />
            }
          />
          <Route
            path="/admin/sources"
            exact
            children={
              <SourcesTrends
                user={user}

              />
            }
          />
          <Route
            path="/admin/affiliates"
            exact
            children={
              <AffiliateTrends
                user={user}

              />
            }
          />
          <Route
            path="/subscription/success"
            exact
            children={
              <SubscriptionSuccessPage
                currentBrand={currentBrand}
              />
            }
          />
          <Route
            path="/subscribe"
            exact
            children={
              <Subscribe />
            }
          />
        </Switch>
      </Suspense>
    </PostAuthContainer>
  )
}
export default PostAuthRoutes
