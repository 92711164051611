import { FunctionComponent } from "react";
import useStyles, { AbsoluteContainer, RelativeContainer, RowFlexContainer, ColumnFlexContainer, ConversionsHeaderContainer, ConversionsVideo, VideoBanner, VideoContainer, MainContainer } from "./styles";
import { ReactComponent as StarsIcon } from "assets/images/stars-icon.svg"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import { useTheme } from "styled-components";
import SecondaryButton from "components/shareable/SecondaryButton";
import TertiaryButton from "components/shareable/TertiaryButton";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

interface Props {
  title: string;
  description: string;
  buttonText: string;
  videoUrl: string;
  enableCloseButton?: boolean;
  showCloseButtonAsIcon?: boolean;
  showAsRecommended?: boolean;
  rounded?: boolean;
  hide: () => void;
  onButtonClick: () => void;
  onVideoClick: () => void;
  padding?: string;
}

const CallToActionCard: FunctionComponent<Props> = ({
  title,
  description,
  buttonText,
  videoUrl,
  showCloseButtonAsIcon,
  showAsRecommended,
  rounded,
  onButtonClick,
  onVideoClick,
  hide,
  enableCloseButton = true,
  padding
}: Props) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { mobileView } = useMediaBreakpoints();

  if (mobileView) {
    return (
      <MainContainer
        flexDirection="column"
        position="relative"
      >
        {enableCloseButton && showCloseButtonAsIcon &&
          <CloseIcon className={classes.svgGrayAbsolute} onClick={hide} />
        }
        <RowFlexContainer gap="8px" justifyContent="space-beetwen" width="100%">
          <ConversionsHeaderContainer>
            <ColumnFlexContainer gap="4px">
              {showAsRecommended &&
                <RowFlexContainer>
                  <StarsIcon className={classes.svgPrimary} />
                  <Caption>Recommended</Caption>
                </RowFlexContainer>
              }
              <Subtitle1>{title}</Subtitle1>
              <Body2>{description}</Body2>
            </ColumnFlexContainer>
          </ConversionsHeaderContainer>
          <RowFlexContainer alignItems="center">
            <RelativeContainer>
              <VideoContainer
                height="80px"
                onClick={onVideoClick}
              >
                <AbsoluteContainer background="#0000007A" />
                <ConversionsVideo src={videoUrl} autoPlay muted loop playsInline />
                <PlayIcon className={classes.svgWhite} />
              </VideoContainer>
              <VideoBanner
                bottom="-5px"
                fontSize="11.679px"
                padding="3.893px 7.786px"
              >
                ⭐ NEW!
              </VideoBanner>
            </RelativeContainer>
          </RowFlexContainer>
        </RowFlexContainer>
        {enableCloseButton && (showCloseButtonAsIcon ? (
          <SecondaryButton onClick={onButtonClick} text={buttonText} height="39px" />
        ) : (
          <RowFlexContainer gap="16px" alignItems="center">
            <SecondaryButton onClick={onButtonClick} text={buttonText} height="39px" />
            <TertiaryButton onClick={hide} text="Maybe later" width="max-content" />
          </RowFlexContainer>
        ))}
      </MainContainer>
    );
  }

  return (
    <MainContainer
      borderRadius={rounded ? "8px" : "0px"}
      padding={padding}
    
    >
      <RowFlexContainer gap="8px">
        <RowFlexContainer alignItems="center">
          <RelativeContainer>
            <VideoContainer
              height="60px"
              onClick={onVideoClick}
            >
              <AbsoluteContainer background="#0000007A" />
              <ConversionsVideo src={videoUrl} autoPlay muted loop />
              <PlayIcon className={classes.svgWhite} onClick={onVideoClick} />
            </VideoContainer>
            <VideoBanner
              bottom="0.233px"
              left="8.216px"
              fontSize="8.48px"
              padding="2.825px 5.65px"
            >
              ⭐ NEW!
            </VideoBanner>
          </RelativeContainer>
        </RowFlexContainer>
        <ConversionsHeaderContainer>
          <ColumnFlexContainer gap="4px">
            {showAsRecommended &&
              <RowFlexContainer>
                <StarsIcon className={classes.svgPrimary} />
                <Caption>Recommended</Caption>
              </RowFlexContainer>
            }
            <Subtitle1>{title}</Subtitle1>
            <Body2>{description}</Body2>
          </ColumnFlexContainer>
          {showCloseButtonAsIcon ? (
            <RowFlexContainer gap="16px" alignItems="center">
              <SecondaryButton onClick={onButtonClick} text={buttonText} height="39px" />
              {enableCloseButton && (<CloseIcon className={classes.svgGray} onClick={hide} />)}
            </RowFlexContainer>
          ) : (
            <RowFlexContainer gap="16px" alignItems="center">
              {enableCloseButton && (<TertiaryButton onClick={hide} text="Maybe later" width="max-content" />)}
              <SecondaryButton onClick={onButtonClick} text={buttonText} height="39px" />
            </RowFlexContainer>
          )}
        </ConversionsHeaderContainer>
      </RowFlexContainer>
    </MainContainer>
  );
};

export default CallToActionCard;