import { useCallback, useContext, useEffect } from "react";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import { queryFB } from "../../helpers/FB";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { CreativeTabConnectTypes } from "types/global";

interface FacebookBusinessManagerConnectProps {
    closeModal: () => void;
    artist: any;
    selectedBusinessManager: any;
    setBusinessManager: any;
    showConnectButton?: boolean
    reloadBrand: () => void;
}

export default function FacebookBusinessManagerConnect(props: FacebookBusinessManagerConnectProps) {
    const {
        closeModal,
        artist,
        selectedBusinessManager,
        setBusinessManager,
        showConnectButton = true
    } = props

    const { saveData } = useContext(ConnectModalsContext)

    const [availBusinessManagers, setBusinessManagers] = useState<any>(null)
    const [loading, setLoading] = useState<any>(null)
    const [error, setError] = useState<any>(null)

    // state for when items are loading
    const [loadingItems, setLoadingItems] = useState<boolean>(true)

    const isDisabledConnectButton = error || !selectedBusinessManager

    const getBusinessManagers = async () => {
        setLoadingItems(true)

        try {

            const results = await queryFB(`/me/businesses`, {
                params: {
                    fields: `id,name,profile_picture_uri,permitted_roles`,
                    limit: 50
                }
            })

            const sorted = results.data
                .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {

                    if (b.permitted_roles.indexOf("ADMIN") > -1 && a.permitted_roles.indexOf("ADMIN") > -1) {
                        return 0
                    }

                    if (b.permitted_roles.indexOf("ADMIN") > -1 && a.permitted_roles.indexOf("ADMIN") === -1) {
                        return 1
                    }

                    if (b.permitted_roles.indexOf("ADMIN") === -1 && a.permitted_roles.indexOf("ADMIN") > -1) {
                        return -1
                    }
                })

            setBusinessManagers(sorted);
            // setBusinessManagers([]);

        } catch (e) {
            setError(true)
        }
        setLoadingItems(false)

    }

    useEffect(() => {
        setError(null)
        getBusinessManagers()
    }, [])

    function updateInBrand() {

        // setSpotifyArtist(item);
        let facebook_business_manager = JSON.parse(JSON.stringify(selectedBusinessManager));
        localStorage.setItem('facebook_business_manager', JSON.stringify(facebook_business_manager))

        // connect to brand
        // ~~
        // setConnectModal('');
        Axios.put(`/brand/${artist.slug}/connect`, {
            id: artist.id,
            name: artist.name,
            service: 'facebook_business_manager',
            value: selectedBusinessManager
        })
            .then((result) => {
                // reloadBrand()
                closeModal()
            })
            .catch((err) => {
                console.log('error creating brand', err)
            })

    }

    const handleConnect = useCallback(() => {
        updateInBrand()
        setLoading(true)
    }, [artist.id, selectedBusinessManager]) 

    useEffect(() => {
        saveData({ 
            loading,
            connectModalType: CreativeTabConnectTypes.FACEBOOK_BUSINESS_MANAGER_CONNECT,
            isDisabledConnectButton,
            handleConnect
        })
    }, [isDisabledConnectButton, handleConnect, loading])

    return <div className="bg-white w-full h-full mx-auto">
        <div
            className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">Connect FB Business Manager</p>
            <a onClick={closeModal}>
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </a>
        </div>
        <div className="middle-wrapper px-4 lg:px-10 ">
            <div className="mt-5 pb-5 border-b border-gray-200">
                <h4 className="mb-1 font-semibold ">
                    Select {artist.name}'s Facebook Business Manager
                </h4>
                <p className="text-md mb-2 text-gray-500">Connect the Business Manager you use to manage your artist Page and accounts. You can only connect a Business Manager you have admin access to.</p>
                <p className="text-md mb-2 text-gray-500">After you connect, you may receive a verification email from Facebook. If so, you’ll need to confirm your business email address before proceeding.</p>
            </div>
            {loadingItems ? (
                <div className="mt-3 flex items-center justify-center">
                    <LoadingIndicator
                        color="black"
                        height="30px" />
                </div>) :
                error ? (
                    <p className="mt-4">No Business Managers found...</p>) : (
                    <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                        {availBusinessManagers && availBusinessManagers.length > 0 ? availBusinessManagers

                            .map((item: any) => {

                                var isAdmin = item.permitted_roles && item.permitted_roles.indexOf('ADMIN') > -1
                                return (
                                    <li onChange={() => {
                                        if (isAdmin) setBusinessManager(item)
                                    }} key={item.id} className="cursor-pointer sy-card">

                                        <div className="flex items-center">
                                            <input
                                                id={'facebook_' + item.id}
                                                type="radio"
                                                className="mr-2"
                                                name={'conect-facebook-pages'}
                                                disabled={!isAdmin}
                                                value={selectedBusinessManager}
                                                checked={isAdmin && selectedBusinessManager ? selectedBusinessManager.id === item.id : false} />

                                            <div className="flex items-center">
                                                <div className="artist-img" style={(!item.image_url || item.image_url === '') ? { background: '#07c806', borderRadius: '50%' } : {}}>
                                                    <img
                                                        src={(item.profile_picture_uri) || require("../../assets/images/profileIcon.svg").default}
                                                        alt="profile"
                                                    />

                                                </div>
                                                <div className="px-3 flex flex-col">
                                                    <label htmlFor={'facebook_' + item.id} className="">{item.name}</label>
                                                    {/* <div className=""> */}
                                                    <a target="_blank"
                                                        href={`https://business.facebook.com/home/accounts?business_id=${item.id}`}
                                                        className="text-green-500 text-sm">
                                                        View on Facebook
                                                    </a>
                                                    {/* </div> */}
                                                    <p className={isAdmin ? 'hidden' : 'text-red-500 text-xs'}>Request admin access to connect this Business Manager</p>

                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                );
                            })
                            // .splice(0, 3)
                            : (<p className="my-4 mx-auto text-center">No Business Managers found. Make sure you have admin access to your Business Manager inside of Meta Business Settings.</p>)}
                    </ul>
                )}
            {showConnectButton && <div className="text-center px-4 lg:px-10 py-4">
                <button disabled={isDisabledConnectButton} onClick={handleConnect} className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3">{loading ? <div className="h-6 m-auto flex justify-center"><LoadingIndicator /></div> : 'Connect'}</button>
            </div>}
            <h6 className="w-12/12 mb-5 text-2x text-center pb-6">
                Don't see your business manager? You can create a new one via Facebook by following <a className="text-2x text-gray-700 text-center underline" href={`https://www.facebook.com/business/help/1710077379203657?id=180505742745347`} target="_blank"> these instructions. Learn how &gt;</a>
            </h6>
        </div>
    </div >

}