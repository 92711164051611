import millify from "millify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Axios from "../../helpers/Interceptor";
import RowItem from "../shareable/RowItem";
import { LoadingIndicator } from "../Loader";
import { DebounceInput } from "react-debounce-input";
import { getSpotifyArtistIdFromUrl } from "helpers/Spotify";




const SpotifyConnect = (props: any) => {
    const {
        closeModal,
        selectSpotifyArtist,
        setSpotifySearchResult,
        spotifySearchResults,
        spotifySearchString,
        setSpotifySearchString
    } = props

    const [spotifyToken, setSpotifyToken] = useState<any>(null);

    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

    const getSpotifyToken = async () => {
        const tokenData = await Axios.get('/token/spotify')

        if (tokenData && tokenData.data && tokenData.data.data.token) {
            localStorage.setItem('spotify_token', tokenData.data.data.token);
            setSpotifyToken(tokenData.data.data.token);
        }
    }

    useEffect(() => {
        const spotifyToken = localStorage.getItem('spotify_token');
        if (!spotifyToken) {
            getSpotifyToken();
        } else {
            setSpotifyToken(spotifyToken);
        }
    }, []);

    useEffect(() => {
        const searchArtist = async () => {
            if (spotifySearchString.length) {
                setLoadingSearch(true)

                const spotify_token = localStorage.getItem('spotify_token')
                try {
                    const spotifyHeaders = {
                        Authorization: 'Bearer ' + spotify_token
                    }

                    // if the search string includes spotify URL, then extract ID
                    const spotifyArtistIdMatch = getSpotifyArtistIdFromUrl(spotifySearchString)
                    if (spotifyArtistIdMatch.valid && spotifyArtistIdMatch.artistId) {
                        const artistId = spotifyArtistIdMatch.artistId
                        const results = await axios.get(`https://api.spotify.com/v1/artists/${artistId}`, { headers: spotifyHeaders })
                        if (results && results.data) {
                            setSpotifySearchResult([results.data])
                            setLoadingSearch(false)
                        }

                    } else {
                        const results = await axios.get(`https://api.spotify.com/v1/search?q=${spotifySearchString}&type=artist&limit=30`, { headers: spotifyHeaders })
                        setSpotifySearchResult(results.data.artists.items)
                        setLoadingSearch(false)
                    }



                } catch (e) {
                    console.log('e', e)

                    getSpotifyToken()
                    setLoadingSearch(false)


                }
            }
        }
        searchArtist();
    }, [spotifySearchString])

    const searchSpotify = async (e: any) => {
        setSpotifySearchString(e.target.value);
    }

    return (
        <div className="bg-white w-full h-full mx-auto">
            <div
                className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >
                <p className="font-semibold text-gray-800">Connect Spotify</p>
                <a onClick={closeModal}>
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </a>
            </div>
            <div className="middle-wrapper px-4 lg:px-10 ">
                <h4 className="mt-5  pb-5 text-2x font-semibold border-b border-gray-200">
                    Enter your artist name or Spotify URL.
                </h4>
                {/* <hr className="h-px	bg-gray-200" /> */}
                <form noValidate action="">
                    <div className="input-form flex flex-row align-middle relative">
                        <DebounceInput
                            className="border-b p-6 outline-none text-left border-gray-200 w-full"
                            minLength={2}
                            placeholder="Enter your artist name or Spotify URL"
                            debounceTimeout={300}
                            value={spotifySearchString}
                            onChange={searchSpotify} />
                        <label
                            htmlFor=""
                            className="absolute h-16 bg-white items-center flex text-lg right-0"
                        >
                            {loadingSearch ? (
                                <div className="ml-3 flex items-center justify-center">
                                    <LoadingIndicator
                                        color="black"
                                        height="30px" />
                                </div>
                            ) : null}
                        </label>


                    </div>
                    {spotifySearchString && spotifySearchString.length > 0 ? (
                        <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                            {spotifySearchResults
                                .map((item: any) => {
                                    return (
                                        <RowItem
                                            selectable
                                            key={item.id}
                                            onClick={() => selectSpotifyArtist(item)}
                                            image={item?.images[0]?.url}
                                            name={item?.name}
                                            secondRowContent={(
                                                <div className="flex flex-row items-center">
                                                    <div className="social-img">
                                                        <img
                                                            src={
                                                                require("../../assets/images/spotify.svg")
                                                                    .default
                                                            }
                                                        />
                                                    </div>
                                                    <div className="social-text">
                                                        <p className="text-xs ml-1">
                                                            {item?.followers?.total > 0 ? millify(item?.followers?.total) : 0} followers
                                                        </p>
                                                    </div>
                                                </div>


                                            )}
                                        />
                                    )
                                })
                            }

                            {!loadingSearch && spotifySearchString && (spotifySearchResults && spotifySearchResults.length === 0) ? (
                                <>
                                    <li className="text-center mt-3 pb-3 border-b">No artists found for "<span className="font-semibold">{spotifySearchString}</span>".</li>

                                </>)
                                : null}
                        </ul>) : null}
                </form>
            </div>
        </div>

    )
};



export default SpotifyConnect;