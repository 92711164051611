import React, { useState, useEffect } from 'react';
import Axios from 'helpers/Interceptor';
import {
    ResponsiveContainer, AreaChart, CartesianGrid, XAxis,
    YAxis, Tooltip, Area, TooltipProps,
} from 'recharts';
import millify from 'millify';
import dayjs from 'dayjs';
import { isAdmin } from 'helpers/admin';
import { Redirect } from 'react-router-dom';
import { UserData } from 'types/global';
import Loaderv2 from 'components/Loader/v2';
import { StyledLabel } from 'styles/shared';
import { FormControlLabel, MenuItem, Select, Switch } from '@material-ui/core';
import { ArrowDownward, ArrowDropDown, ArrowUpward } from '@material-ui/icons';



interface CustomTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
    active?: boolean;
    payload?: any[];
    label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="p-2 bg-white shadow-lg border border-gray-300 rounded">
                <p className="mb-2 font-bold">{label}</p>
                {payload.map((entry, index) => (
                    <p key={`payload-${index}`} className="mb-1 text-gray-700">
                        {
                            entry.name === 'userAccountsCreated' ? 'User Accounts Created' :
                            entry.name === 'artistAccountsCreated' ? 'Artist Accounts Created' :
                            entry.name === 'maus' ? 'Monthly Active Users' :
                            entry.name === 'cumulativeArtistAccounts' ? 'Cumulative Artist Accounts' :
                            entry.name === 'mrr' ? 'Monthly Recurring Revenue' :
                            entry.name === 'subscribers' ? 'Subscribers' : ''
                        }: {entry.name === 'mrr' ? `$${entry.value.toLocaleString('en')}` : entry.value.toLocaleString('en')}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

interface ProUserCountProps {
    count: number;
}

const ProUserCount: React.FC<ProUserCountProps> = ({ count }) => {
    return (
        <div className="w-full max-w-2xl p-4 mt-4 bg-white rounded shadow-lg">
            <StyledLabel className="mb-4" fontSize={20} padding='0px auto 20px'>Total Pro Users</StyledLabel>
            <div className="text-3xl font-bold text-center">
                {count.toLocaleString('en')}
            </div>
        </div>
    );
};

const KPITrends = ({
    user
}: {
    user: UserData
}) => {

    const userIsAdmin = user ? isAdmin(user) : false;
    const [loading, setLoading] = useState(true);

    const [kpiData, setKpiData] = useState<any[]>([]);

    const [showUsers, setShowUsers] = useState(true);
    const [showArtists, setShowArtists] = useState(true);
    const [showMAUs, setShowMAUs] = useState(true);
    const [showCumulativeArtists, setShowCumulativeArtists] = useState(false);
    const [showMRR, setShowMRR] = useState(false);
    const [showSubscribers, setShowSubscribers] = useState(false);

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [tableData, setTableData] = useState<any[]>([]);

    const [projection, setProjection] = useState<number>(0);


    const handleSort = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        const sortedData = [...tableData].sort((a, b) => {
            if (newSortOrder === 'asc') {
                return dayjs(a.month).isBefore(dayjs(b.month)) ? -1 : 1;
            } else {
                return dayjs(a.month).isBefore(dayjs(b.month)) ? 1 : -1;
            }
        });
        setTableData(sortedData);
    };

    const fetchKPIData = async () => {
        try {
            const response = await Axios.post('/admin/get-kpis');
            setKpiData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);

        }
    };
    useEffect(() => {


        fetchKPIData();
    }, []);

    const computeProjections = (data: any, months: any) => {
        if (months === 0) return data;

        const projections = [...data];
        for (let i = 0; i < months; i++) {
            const lastDataPoint = projections[projections.length - 1];
            const secondLastDataPoint = projections[projections.length - 2];

            const userGrowthRate = (lastDataPoint.userAccountsCreated - secondLastDataPoint.userAccountsCreated) / secondLastDataPoint.userAccountsCreated;
            const artistGrowthRate = (lastDataPoint.artistAccountsCreated - secondLastDataPoint.artistAccountsCreated) / secondLastDataPoint.artistAccountsCreated;
            const mauGrowthRate = (lastDataPoint.maus - secondLastDataPoint.maus) / secondLastDataPoint.maus;

            const projectedDataPoint = {
                month: dayjs(lastDataPoint.month).add(1, 'month').format('YYYY-MM'),
                userAccountsCreated: Math.round(lastDataPoint.userAccountsCreated * (1 + userGrowthRate)),
                artistAccountsCreated: Math.round(lastDataPoint.artistAccountsCreated * (1 + artistGrowthRate)),
                maus: Math.round(lastDataPoint.maus * (1 + mauGrowthRate)),

            };
            projections.push(projectedDataPoint);
        }
        return projections;
    };


    useEffect(() => {
        setTableData(kpiData);
    }, [kpiData]);

    useEffect(() => {
        const projectedData = computeProjections(kpiData, projection);
        setKpiData(projectedData);
    }, [projection]);


    if (!userIsAdmin) {
        return <Redirect to="/dashboard" />;
    }


    return (

        <div className="flex flex-col items-center justify-center p-4 space-y-4">
            {loading ? (
                <Loaderv2 />
            ) : (
                <>
                    {/* <ProUserCount count={totalProUsers} /> */}

                    <div className="w-full max-w-4xl p-4 bg-white rounded shadow-lg">
                        <StyledLabel className="mb-4" fontSize={20} padding='0px auto 20px'>Signups + Artist Creations + MAUs</StyledLabel>
                        <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
                            <AreaChart
                                data={kpiData}
                                margin={{
                                    top: 15, right: 0, left: window.innerWidth >= 768 ? 20 : 0, bottom: 5,
                                }}
                            >
                                <CartesianGrid stroke="#8800FF29" strokeWidth={1.5} strokeDasharray="8 8" />
                                <XAxis
                                    dy={12}
                                    axisLine={false}
                                    dataKey="month"
                                    tickLine={false}
                                    minTickGap={window.innerWidth < 768 ? 20 : 30}
                                />
                                <YAxis
                                    yAxisId="left"
                                    mirror={window.innerWidth < 768}
                                    tick={(e) => {
                                        const { payload: { value } } = e;
                                        return (
                                            <text {...e}>
                                                {millify(value, { precision: 2 })}
                                            </text>
                                        );
                                    }}
                                    axisLine={false}
                                    tickLine={false}
                                    allowDecimals={false}
                                    domain={['auto', 'auto']}
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    tick={(e) => {
                                        const { payload: { value } } = e;
                                        return (
                                            <text {...e}>
                                                ${millify(value, { precision: 2 })}
                                            </text>
                                        );
                                    }}
                                    axisLine={false}
                                    tickLine={false}
                                    allowDecimals={false}
                                    domain={['auto', 'auto']}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <defs>
                                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                        <stop stopColor="#8800FF" stopOpacity={0.4} />
                                        <stop offset="1" stopColor="#8800FF" stopOpacity={0.05} />
                                    </linearGradient>
                                </defs>
                                {showUsers && <Area yAxisId="left" type="monotone" dataKey="userAccountsCreated" strokeWidth={2} stroke="#8800FF" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                                {showArtists && <Area yAxisId="left" type="monotone" dataKey="artistAccountsCreated" strokeWidth={2} stroke="#33CC33" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                                {showCumulativeArtists && <Area yAxisId="left" type="monotone" dataKey="cumulativeArtistAccounts" strokeWidth={2} stroke="#33CC33" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                                {showMAUs && <Area yAxisId="left" type="monotone" dataKey="maus" strokeWidth={2} stroke="#FF8800" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                                {showMRR && <Area yAxisId="right" type="monotone" dataKey="mrr" strokeWidth={2} stroke="#FF00FF" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                                {showSubscribers && <Area yAxisId="left" type="monotone" dataKey="subscribers" strokeWidth={2} stroke="#FF0000" fill="url(#splitColor)" activeDot={{ r: 8 }} />}
                            </AreaChart>
                        </ResponsiveContainer>
                        <div className="chart-toggles">
                            <FormControlLabel
                                control={<Switch checked={showUsers} onChange={() => setShowUsers(!showUsers)} />}
                                label="Show Users"
                            />
                            <FormControlLabel
                                control={<Switch checked={showArtists} onChange={() => setShowArtists(!showArtists)} />}
                                label="Show Artists"
                            />
                            <FormControlLabel
                                control={<Switch checked={showMAUs} onChange={() => setShowMAUs(!showMAUs)} />}
                                label="Show MAUs"
                            />
                            <FormControlLabel
                                control={<Switch checked={showCumulativeArtists} onChange={() => setShowCumulativeArtists(!showCumulativeArtists)} />}
                                label="Show Cumulative Signups"
                            />
                            <FormControlLabel
                                control={<Switch checked={showMRR} onChange={() => setShowMRR(!showMRR)} />}
                                label="Show MRR"
                            />
                            <FormControlLabel
                                control={<Switch checked={showSubscribers} onChange={() => setShowSubscribers(!showSubscribers)} />}
                                label="Show Subscribers"
                            />
                        </div>
                        <div className="projection-dropdown">
                            <Select
                                value={projection}
                                onChange={(event: any) => setProjection(event.target.value)}
                                IconComponent={ArrowDropDown}
                            >
                                <MenuItem value={0}>No Projection</MenuItem>
                                <MenuItem value={3}>3 Months Projection</MenuItem>
                                <MenuItem value={6}>6 Months Projection</MenuItem>
                                <MenuItem value={12}>12 Months Projection</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="w-full max-w-4xl p-4 mt-4 bg-white rounded shadow-lg">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th
                                        className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider cursor-pointer"
                                        onClick={handleSort}
                                    >
                                        Month {sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
                                    </th>                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">User Accounts Created</th>
                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">Artist Accounts Created</th>
                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">User Account Growth (%)</th>
                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">Artist Account Growth (%)</th>
                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">MAUs</th>
                                    <th className="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 text-gray-500 tracking-wider">MAUs Growth (%)</th>


                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((item, index) => (
                                    <tr key={`item-${index}`} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.month}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.userAccountsCreated}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.artistAccountsCreated}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{(item.userGrowthRate || 0).toFixed(2)}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{(item.artistGrowthRate || 0).toFixed(2)}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{item.maus}</td>
                                        <td className="py-2 px-4 border-b border-gray-200 text-sm">{(item.mauGrowthRate || 0).toFixed(2)}</td>




                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>

    )
};

export default KPITrends;
