import { ReactNode } from 'react';
import { SystemColors } from 'types/globalStyles';

interface BannerProps {
    variant: 'warning' | 'error' | 'accent';
    classes?: string;
    children: ReactNode;
};

export const Banner = ({ variant, children, classes = '' }: BannerProps) => {
    let backgroundColor;

    switch (variant) {
        case 'warning':
            backgroundColor = SystemColors.PAPER_WARNING_CONTAINER;
            break;
        case 'error':
            backgroundColor = SystemColors.PAPER_ERROR_CONTAINER;
            break;
        case 'accent':
            backgroundColor = SystemColors.PAPER_ACCENT_CONTAINER;
            break;
    }

    return (
        <div className={`w-full rounded-lg ${classes}`} style={{ backgroundColor }}>
            {children}
        </div>
    );
};
