import { FunctionComponent, useContext, useEffect, useState } from "react"
import Dialog from "@material-ui/core/Dialog"
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel"
import {
  Headline1,
  Headline1Accent,
  Subtitle2,
  Body2,
  Caption,
} from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import {
  TESTIMONIALS,
  getFeatures,
  BillingCycle,
  format,
  labels,
  FeatureType,
} from "./utils"
import useStyles, {
  DialogContent,
  RightContent,
  LeftContent,
  Feature,
  FeatureIcon,
  FeatureList,
  MarqueesContainer,
  MarqueeItemContainer,
  SpotifyIconContainer,
  TextContainer,
  FlexContainer,
  FixedContainer,
  TitleContainer,
  BottomBar,
  CloseIconContainer,
  StyledImg,
} from "./styles"
import Marquee from "../Marquee"
import times from "lodash/times"
import SpotifyIcon from "assets/images/logos/sidebar-collapsed.png"

import TestimonialsCarousel from "../TestimonialsCarousel"
import PrimaryButton from "../PrimaryButton"
import QuestionMarkIcon from "assets/images/question-mark.svg"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import { StripeFormattedData, redirectToCheckout } from "./api"
import { PaymentMethods } from "types/global"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import Intercom from "helpers/Intercom"
import { StyledLabel } from "styles/shared"
import { getBrandPartnerDetails } from "helpers/partners"
import { ADDITIONAL_SPEND_BUDGET, ADDITIONAL_SPEND_BUDGET_TEAM, MONTHLY_EXTRA_USER_PRICE, MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER, YEARLY_BY_MONTH_PRO_PLAN_PRICE, YEARLY_BY_MONTH_TEAM_PLAN_PRICE } from "../../../constants"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import getSymbolFromCurrency from "currency-symbol-map"
import { getConvertedCurrency } from "services/symphonyApi"
import { getNumberWithDecimals } from "helpers/General"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { track } from "analytics"
import BillingPlanOptions from "./Components/BillingOptions"
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { toast } from "react-toastify"
import Axios from "helpers/Interceptor"
import { getStatusToShowPaymentFailedBanner } from "utils"
import { getBrandStripeCustomerPortal } from "services/symphonyApi/brandService"

const { MONTHLY } = BillingCycle

interface Props {
  open: boolean
  onClose: () => void
  source?: string | null

  // for organization-specific functions
  isOrganization?: boolean
  hasOrganizationOverage?: boolean | undefined
  reloadBrands: () => void
}

const list = times(8, () => (
  <MarqueeItemContainer>
    <SpotifyIconContainer>
      <img src={SpotifyIcon} width={16} height={16} />
    </SpotifyIconContainer>
    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
      The easiest way to grow.
    </Body2>
  </MarqueeItemContainer>
))

const getMarqueeProps = (toRight: boolean) => ({
  list,
  marqueeContainerProps: {
    styles: {
      width: "600px",
      marginTop: toRight ? "0px" : "20px",
    },
  },
  toRight,
})

type CurrencyPrices = {
  annualConverted: number,
  monthlyConverted: number,
}

const UpgradeToProModal: FunctionComponent<Props> = ({ open, source, onClose, isOrganization, hasOrganizationOverage, reloadBrands }) => {
  const [billingCycle, setBillingCycle] = useState(MONTHLY)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down(901))
  const classes = useStyles({ isMobileView: mobileView })
  const { currentBrand, reloadBrand } = useContext(CurrentBrandContext)
  const { reloadUser } = useContext(CurrentUserContext);
  const isUnpaid = getStatusToShowPaymentFailedBanner(currentBrand)

  const [monthlySpendCamptionValue, setMonthlySpendCamptionValue] = useState<number>(ADDITIONAL_SPEND_BUDGET)
  const [monthlyAditionalUserValue, setMonthlyAditionalUserValue] = useState<number>(MONTHLY_EXTRA_USER_PRICE)
  const [currencyPrices, setCurrencyPrices] = useState<CurrencyPrices>({
    annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
    monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
  })

  const currencyCode = currentBrand?.currency?.code || DEFAULT_CURRENCY
  const currencySymbol = getSymbolFromCurrency(currencyCode)

  const { freeTrialOver, slug: brandSlug } = currentBrand || {}
  const { price, billed, id: priceId, billedTrialText } = labels[billingCycle]

  const handleChangeBillingCycle = (billingCycle: BillingCycle) => () =>
    setBillingCycle(billingCycle)

  const handleClickUpgradeToProButton = async () => {
    setLoading(true)
    try {

      if (isOrganization) {
        // for organizations, upgrading to Pro can be done through an endpoint
        const result = await Axios.post('/organization/upgrade-to-pro', { brandSlug: brandSlug });
        await reloadUser()
        await reloadBrand()
        await reloadBrands()
        toast.success(`You've upgraded to Pro!`, SHARED_TOAST_OPTIONS)
        handleClickCloseIconButton()
      } else if (isUnpaid) {
        // if its unpaid, but in past_due state - we can let the user 
        // self-update their payment method through Stripe Customer Portal.
        if (!currentBrand?.subscription?.retriesFailed) {
          const handleUpdatePaymentMethod = async () => {
            try {
              const response = await getBrandStripeCustomerPortal(currentBrand?.slug)

              window.location = response.url;
            } catch (error) {
            }
          }

          await handleUpdatePaymentMethod()
        } else {
          // if the subscription is in unpaid state, we need
          // to update the payment method to update the subscription
          //  OR create a new sub if they want to make a different type of 
          //     recurring_interval than their current one

          const data = {
            recurring_interval: billingCycle,
            payment_method: PaymentMethods.STRIPE,
            checkout_source: source,
          } as StripeFormattedData

          track("Checkout Started from Upgrade to Pro Modal", { source: source || null });
          redirectToCheckout(data, brandSlug, !freeTrialOver).then(async () => {
            await reloadBrand()
          })
        }

      } else {
        const data = {
          recurring_interval: billingCycle,
          payment_method: PaymentMethods.STRIPE,
          checkout_source: source,
        } as StripeFormattedData

        track("Checkout Started from Upgrade to Pro Modal", { source: source ? source : null });
        redirectToCheckout(data, brandSlug, !freeTrialOver).then(async () => {
          await reloadBrand()
        })

      }
    } catch (error) {
      console.error(error);
      toast.error("There was an updating the subscription.", SHARED_TOAST_OPTIONS);
    } finally {
      setLoading(false)
    }
  }

  const handleClickQuestionMarkIcon = () => Intercom.openSymphonyProArticle()

  const handleClickCloseIconButton = () => {
    if (onClose) onClose()
  }

  const fromPartner = getBrandPartnerDetails(currentBrand)

  // either discountAmount, or 1x (no discount)
  const partnerDiscount: number = fromPartner && fromPartner.discountAmount ? fromPartner.discountAmount : 1

  let showPartnerText;
  if (fromPartner && !isOrganization) {
    const partnerDiscountPercentage = 100 - (partnerDiscount! * 100)
    const basePartnerLabel = `artists get a ${partnerDiscountPercentage}% discount on monthly plans for their first 12 months! (${currencySymbol}${(currencyPrices.monthlyConverted * partnerDiscount!).toFixed(2)} / month instead of ${currencySymbol}${currencyPrices.monthlyConverted} / month)`
    switch (fromPartner.partnerId) {
      case 'UM':
        showPartnerText = `UnitedMasters SELECT ${basePartnerLabel}`
        break
      case 'Audiomack':
        showPartnerText = `Audiomack ${basePartnerLabel}`
        break
      case 'CDBaby':
        showPartnerText = `CDBaby ${basePartnerLabel}`
        break
      case 'Downtown':
        showPartnerText = `Downtown Music ${basePartnerLabel}`
        break
    }
  }

  function showBottomPrice() {
    const isComparison = Boolean(partnerDiscount)

    let comparisonPrice: number | null = null
    let partnerBillingNote: string | null = null
    if (isComparison && price && partnerDiscount
      && priceId === "monthly") {
      comparisonPrice = currencyPrices.monthlyConverted * partnerDiscount
    }

    // the price, formatted properly for the international currency
    const formattedAmount = format(priceId === "monthly" ? currencyPrices.monthlyConverted * partnerDiscount : currencyPrices.annualConverted * 12)
    const formattedExtraSpend = format(monthlyAditionalUserValue)
    const formattedCurrency = currencySymbol
    // the time period (monthly or annual)
    const formattedTimePeriod = billedTrialText



    let ctaText = freeTrialOver ? `Subscribe for ${formattedCurrency}${formattedAmount} / ${formattedTimePeriod}.`
      : `*Free trial is for 14 days, then ${formattedCurrency}${formattedAmount}${currencyCode !== 'USD' ? ` ${currencyCode}` : ``} / ${formattedTimePeriod} unless you cancel. You'll get an email reminder 7 days before your trial ends.`

    if (isUnpaid) {
      ctaText += " Your subscription will be re-activated after you update your payment method."
    }

    if (isOrganization) {
      if (hasOrganizationOverage) {
        ctaText = `You can upgrade this profile to Pro as part of your Team plan for ${formattedCurrency}${formattedExtraSpend} / ${formattedTimePeriod}.`
      } else {
        ctaText = `You can upgrade this profile to Pro for free as part of your Team plan subscription.`
      }
    }

    function getCheckoutButtonText() {

      if (isOrganization) {
        return hasOrganizationOverage ? `Upgrade to Pro` : `Upgrade to Pro for free`
      } else {
        if (isUnpaid) {
          return `Update Payment Method & Reactivate`
        } else {
          return freeTrialOver ? `Upgrade to Pro` : `Start my 14-day Free Trial`
        }
      }
    }

    return (<FlexContainer
      alignItems={mobileView ? undefined : "center"}
      justifyContent={mobileView ? undefined : "space-between"}
      className="w-full"
      flexDirection={mobileView ? "column" : "column"}
    >

      {mobileView && renderBillingOptionButtons()}
      {ctaText && (<StyledLabel
        fontSize={14}
        style={{
          marginBottom: mobileView ? 0 : '8px'
        }}
      >
        {ctaText}
      </StyledLabel>)}

      <FlexContainer className={mobileView ? "mt-2" : undefined}
        style={{
          width: "100%"
        }}>
        <PrimaryButton
          {...{ loading }}
          width={mobileView ? "100%" : "100%"}
          disabled={loading}
          text={getCheckoutButtonText()}
          onClick={handleClickUpgradeToProButton}
        />
      </FlexContainer>
    </FlexContainer>)

  }

  const getCaption = (feature: FeatureType) => {
    const { caption, feature: featureName } = feature
    if (typeof caption === 'string') return caption
    switch (featureName) {
      case 'Automated marketing on Instagram + YouTube':
        return (caption!)(currencyCode, monthlySpendCamptionValue)
      case 'Invite up to 2 users to access your profile':
      case 'Invite up to 5 users to access your profile':
      default:
        return (caption!)(currencyCode, monthlyAditionalUserValue)
    }
  }

  const getPricesValues = async () => {
    let baseMonthly
    let baseAnnual
    if (isOrganization) {
      baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE })
      baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_TEAM_PLAN_PRICE })
    } else {
      baseMonthly = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_PRO_PLAN_PRICE })
      baseAnnual = await getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })
    }

    setCurrencyPrices({
      annualConverted: Number(getNumberWithDecimals(baseAnnual, 2)),
      monthlyConverted: Number(getNumberWithDecimals(baseMonthly, 2)),
    })
  }

  useEffect(() => {
    if (currencyCode !== DEFAULT_CURRENCY) {
      if (isOrganization) {
        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER })
          .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))

        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ADDITIONAL_SPEND_BUDGET_TEAM })
          .then((res) => setMonthlySpendCamptionValue(Number(getNumberWithDecimals(res, 2))))
      } else {
        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_EXTRA_USER_PRICE })
          .then((res) => setMonthlyAditionalUserValue(Number(getNumberWithDecimals(res, 2))))

        getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ADDITIONAL_SPEND_BUDGET })
          .then((res) => setMonthlySpendCamptionValue(Number(getNumberWithDecimals(res, 2))))
      }

      getPricesValues()
    } else {
      // TODO: remove this else statement when we fix the way we switch from brand to brand, right now it keeps some values unchanged
      if (currencyPrices.monthlyConverted !== MONTHLY_PRO_PLAN_PRICE) {
        setCurrencyPrices({
          annualConverted: YEARLY_BY_MONTH_PRO_PLAN_PRICE,
          monthlyConverted: MONTHLY_PRO_PLAN_PRICE,
        })
        setMonthlyAditionalUserValue(Number(getNumberWithDecimals(MONTHLY_EXTRA_USER_PRICE, 2)))
        setMonthlySpendCamptionValue(Number(getNumberWithDecimals(ADDITIONAL_SPEND_BUDGET, 2)))
      }
      if (isOrganization) {
        setCurrencyPrices({
          annualConverted: YEARLY_BY_MONTH_TEAM_PLAN_PRICE,
          monthlyConverted: MONTHLY_TEAM_PLAN_PRICE,
        })
        setMonthlyAditionalUserValue(Number(getNumberWithDecimals(MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER, 2)))
        setMonthlySpendCamptionValue(Number(getNumberWithDecimals(ADDITIONAL_SPEND_BUDGET_TEAM, 2)))
      }
    }
  }, [currencyCode, isOrganization])


  function renderHeadline() {
    if (isOrganization) {
      // we need to check overages here
      if (hasOrganizationOverage) {

      } else {

      }
      return (
        <TitleContainer>
          <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
            Unlock the full power of
          </Headline1>
          <Headline1Accent>AI-powered marketing</Headline1Accent>
        </TitleContainer>
      )
    } else {
      if (freeTrialOver) {
        return (
          <TitleContainer>
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Unlock the full power of
            </Headline1>
            <Headline1Accent>AI-powered marketing</Headline1Accent>
          </TitleContainer>
        )
      } else {
        return (
          <TitleContainer>
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Try Symphony Pro for free
            </Headline1>
            <Subtitle2 color={SystemColors.PRIMARY_TEXT_COLOR}>
              Everything you need to analyze, market, and grow your fanbase + business, free for 14 days.*
            </Subtitle2>
          </TitleContainer>
        )
      }
    }
  }

  function renderBillingOptionButtons() {
    if (isOrganization) {
      return null
    } else if (isUnpaid && !currentBrand?.subscription?.retriesFailed) {

      return null
    } else {

      return (
        <BillingPlanOptions
          mobileView={mobileView}
          billingCycle={billingCycle}
          partnerDiscount={partnerDiscount}
          setBillingCycle={setBillingCycle}
        />
      );
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClickCloseIconButton}
      maxWidth={mobileView ? "xl" : "md"}
      PaperProps={{ className: classes.paper }}
    >
      <DialogContent
        overflowY={mobileView ? undefined : "hidden"}
        flexDirection={mobileView ? "column" : "row"}
      >
        {!mobileView && (
          <LeftContent
            height={mobileView ? "500px" : "700px"}
            width={mobileView ? "100%" : "45%"}
          >
            {mobileView && (
              <CloseIconContainer
                isMobileView={mobileView}
                onClick={handleClickCloseIconButton}
              >
                <CloseIcon className={classes.closeIcon} />
              </CloseIconContainer>
            )}
            <TestimonialsCarousel
              testimonials={TESTIMONIALS}
              slideHeight={mobileView ? "500px" : "800px"}
              slidePadding={
                mobileView ? "64px 24px 46px 24px" : "120px 48px 0px 48px"
              }
            />
            <MarqueesContainer isMobile={mobileView}>
              <Marquee {...getMarqueeProps(true)} />
              {!mobileView && <Marquee {...getMarqueeProps(false)} />}
            </MarqueesContainer>
          </LeftContent>
        )}
        <RightContent
          padding={mobileView ? "24px" : "32px"}
          width={mobileView ? "100%" : "55%"}
          overflowY={mobileView ? undefined : "scroll"}
        >
          <CloseIconContainer
            isMobileView={mobileView}
            onClick={handleClickCloseIconButton}
          >
            <CloseIcon />
          </CloseIconContainer>
          <UpgradeToProLabel
            maxWidth={isOrganization ? "210px" : "160px"}
            color="PURPLE"
            label={isOrganization ? "Symphony for Teams" : "Symphony Pro"}
          />
          {renderHeadline()}
          {showPartnerText && (<div style={{
            background: "#ffd56b",
            borderRadius: "4px",
            padding: "12px",
            boxSizing: "border-box",
          }}>
            <p style={{
              fontWeight: "500",
              fontSize: "0.8rem",
              color: "black",
            }}>
              {showPartnerText}
            </p>
          </div>)}
          {!mobileView && renderBillingOptionButtons()}
          <FlexContainer alignItems="center" justifyContent="space-between" className="mt-4">
            <Subtitle2>What’s included?</Subtitle2>
            <StyledImg
              src={QuestionMarkIcon}
              onClick={handleClickQuestionMarkIcon}
              alt="question-mark-icon.svg"
            />
          </FlexContainer>
          <FeatureList
            marginTop={mobileView ? '12px' : null}>
            {getFeatures(classes, isOrganization).map(
              ({ icon: Icon, feature, caption, className }, index) => (
                <Feature key={`upgrade-to-pro-modal-feature-${index}`}>
                  <FeatureIcon>
                    <Icon {...{ className }} />
                  </FeatureIcon>
                  <TextContainer>
                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                      {feature}
                    </Body2>
                    {caption && (
                      <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>
                        {getCaption({ icon: Icon, feature, caption, className })}
                      </Caption>
                    )}
                  </TextContainer>
                </Feature>
              )
            )}
          </FeatureList>
          <BottomBar isMobileView={mobileView}>
            <FixedContainer>
              {showBottomPrice()}
            </FixedContainer>
          </BottomBar>
        </RightContent>
      </DialogContent>
    </Dialog>
  )
}

export default UpgradeToProModal
