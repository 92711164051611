import Lottie from 'react-lottie';
import styled from "styled-components";
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottieBlack from 'assets/images/lotties/SymphonyLoading.json';

export function NewLoader(props: {
    black?: boolean
}) {
    return (
        <Lottie
            width={44}
            height={44}
            options={{
                rendererSettings: {
                    className: "bg-white-500"
                },
                loop: true,
                autoplay: true,
                animationData: props.black ? SymphonyLoadingLottieBlack : SymphonyLoadingLottie,
            }}
        />
    )
}
const Layout = styled.div`
    text-align: center;
    flex-flow: column;
    padding-bottom: 10rem;
    background-color: #8800FF;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 112vh;
    display: flex;
    animation: fadeIn 300ms;
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    @-moz-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-webkit-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-o-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    @-ms-keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

const Text = styled.div`
    font-family: 'DIN';
    font-style: normal;
    font-weight: 480;
    font-size: 20px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 8px;
    opacity: 0.64
`;

const ConnectBrandLoader = () => {
    return (
        <Layout>
            <NewLoader />
            <Text>Gathering fanbase data...</Text>
        </Layout>
    );
};

export default ConnectBrandLoader;
