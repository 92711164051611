import { DataGrid } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { getOrganizationCampaigns } from "services/symphonyApi/organizationService";
import { CAMPAIGN_TYPES, CurrentBrand, OrganizationCampaign } from "types/global";
import { getColumnDefinition } from "./utils";
import { useStyles } from "./styles";
import { Body2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as ChevronLeft } from "assets/images/chevronLeft.svg";
import { ReactComponent as ChevronRight } from "assets/images/chevronRight.svg";
import { ReactComponent as MarketingGray } from "assets/images/marketingIcon-gray.svg"
import { LoadingLottie } from "components/Loader/LoadingLottie";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import Axios from "helpers/Interceptor"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils"

const { get_playlisted, pre_save, increase_video_views } = CAMPAIGN_TYPES;

const PAGE_SIZE = 8;

interface Props { }

export const OrganizationMarketingTab = ({ }: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<OrganizationCampaign[]>([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isFiltering, setIsFiltering] = useState(false)
  const { currentBrand, setCurrentBrand } = useContext(CurrentBrandContext);
  const history = useHistory();

  const fetchCampaigns = async (offset: number, limit = PAGE_SIZE) => {
    setLoading(true);
    const response = await getOrganizationCampaigns(offset, limit);
    if (response) {
      setCampaigns(response.campaigns);
      setTotalCampaigns(response.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCampaigns(page);
  }, [page]);

  const campaignsWithConversions = Boolean(campaigns.find(campaign => campaign.conversionsEnabled !== undefined));
  const campaignsWithSpendValue = Boolean(campaigns.find(campaign => campaign.spent !== undefined));
  const campaignsWithBudgetValue = Boolean(campaigns.find(campaign => campaign.budget !== undefined));
  const playlistCampaignsData = Boolean(campaigns.find(campaign => campaign.type === get_playlisted));
  const presaveCampaignsData = Boolean(campaigns.find(campaign => campaign.type === pre_save));
  const videoViewsCampaignsData = Boolean(campaigns.find(campaign => campaign.type === increase_video_views));
  const needsPagination = totalCampaigns > campaigns.length;
  const firstShownCampaignIndex = page + 1;
  const lastShownCampaignIndex = firstShownCampaignIndex + (campaigns.length - 1);
  const numberOfPages = Math.ceil(totalCampaigns / PAGE_SIZE);

  const handleBrandSelection = async ({ brandId, campaignId }: { brandId: number, campaignId: number }) => {
    try {
      if (currentBrand?.id === brandId) {
        history.push(`/marketing/campaigns/${campaignId}`, { from: 'TEAM' });
      }

      setLoading(true)
      const response = await Axios.get(`/brand/${brandId}`);
      const newBrand = response.data.data as CurrentBrand;
      if (newBrand) {
        setCurrentBrand(newBrand);
        history.push(`/marketing/campaigns/${campaignId}`, { from: 'TEAM' });
      } else {
        throw new Error("Brand not found");
      }
    } catch (error) {
      toast.error("Error getting brand information", SHARED_TOAST_OPTIONS_ERROR);
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-80">
        <LoadingLottie
          black
          width={24}
          height={24}
        />
      </div>
    );
  }

  return (
    <div>
      <DataGrid
        autoHeight
        rowHeight={80}
        rows={campaigns}
        classes={{ root: classes.dataGridRoot }}
        columns={getColumnDefinition(campaignsWithConversions, campaignsWithBudgetValue, campaignsWithSpendValue, playlistCampaignsData, presaveCampaignsData, videoViewsCampaignsData)}
        paginationMode="server"
        hideFooter
        components={{ NoRowsOverlay: () => (<EmptyState isFiltering={isFiltering} />) }}
        onRowClick={({ row }) => {
          handleBrandSelection({ brandId: row.brandId, campaignId: row.id })
        }}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'scroll'
          }
        }}
      />
      {needsPagination && (
        <div className="flex gap-6 w-full justify-end py-2 items-center">
          <div className="flex gap-1">
            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Campaigns per page:</Body2>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{PAGE_SIZE}</Body2>
          </div>
          <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{firstShownCampaignIndex}-{lastShownCampaignIndex} of {totalCampaigns}</Body2>
          <div className="flex gap-6 h-4">
            <ChevronLeft
              {...(page === 0 ? {
                className: classes.disabledChevron
              } : {
                className: classes.enabledChevron,
                onClick: () => setPage(page - PAGE_SIZE)
              })}
            />
            <ChevronRight
              {...(page === numberOfPages - 1 ? {
                className: classes.disabledChevron
              } : {
                className: classes.enabledChevron,
                onClick: () => setPage(page + PAGE_SIZE)
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const EmptyState = ({ isFiltering } : { isFiltering: boolean }) => {
  return (
    <div className="flex justify-center items-center rounded-lg px-6 py-12 gap-4" style={{ background: SystemColors.BACKGROUND_EMPTY_STATE }}>
      <div className="flex flex-col items-center gap-2">
        {
          isFiltering ? (
            <>
              <MarketingGray style={{ height: 18, width: 18 }} />
              <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>No campaigns match your filters</Body2>
              {/* TODO: add a button to clear filters here */}
            </>
          ) : (
            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>No campaigns found</Body2>
          )
        }
      </div>
    </div>
  );
};