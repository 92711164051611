/* eslint-disable react-hooks/exhaustive-deps */
import mixpanel from "mixpanel-browser";
import { useEffect, useState, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import TopBar from "components/shareable/TopBar";
import { useTheme, useMediaQuery } from "@material-ui/core";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { GettingStartedContext } from "Hooks/GettingStartedContext";
import { SYMPHONY_TASKS } from "types/global";
import UpgradeToProSucceededModal from 'components/shareable/UpgradeToProSucceededModal';
import { NavbarContext } from "../../Data/NavbarProvider";
import { setUserProperty, track } from "analytics";
import MainContentContainer from "components/shareable/MainContentContainer";
import { LoadingIndicator } from "components/Loader";
import useStyles from "./styles";

// On this page, we should be getting campaign_id + session_id variables
//  - if both are present, get the campaign details + stripe details
//      - if stripe transaction was a success, show success state
//      - else show error state
//  - if one or the other is missing, show an error state
export default function TiktokCallbackPage(props: any) {

    const {
        currentBrand,
        refreshCampaigns
    } = props

    const {
        setNextButtonHandler,
    } = useContext(NavbarContext);

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))

    const [loadingData, setLoadingData] = useState<any>(true)
    const [campaignData, setCampaignData] = useState<any>(null)
    const [errorState, setErrorState] = useState<boolean>(false)
    const [sessionId, setSessionId] = useState<string>("")
    const [openWelcomeProModal, setOpenWelcomeProModal] = useState<boolean>(false)
    const metaData = campaignData?.campaign_metadata || campaignData?.campaign?.campaign_metadata

    const history = useHistory()

    async function getCampaignStatus() {
        var urlParams = new URLSearchParams(window.location.search);
    
        // get auth_code
        var auth_code = urlParams.get('auth_code')
    
        if (!auth_code) {
            setErrorState(true)
        } else {
           
            // Define app_id and secret.
            // Replace 'YOUR_APP_ID' and 'YOUR_SECRET' with your actual App ID and Secret.
            var app_id = '7190203583793463298'
            var secret = 'ecfbc3453da7606e3ba46387eb9c9f17dcd75206'
    
            try {
                const response = await axios.post('https://business-api.tiktok.com/open_api/v1.3/oauth2/access_token/', {
                    app_id: app_id,
                    auth_code: auth_code,
                    secret: secret
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

    
                if (response.data.code === 0) {
                    // successful response
                    const access_token = response.data.data.access_token;
    
                    // Save the access token to localStorage
                    localStorage.setItem('access_token', access_token);
    
                    setLoadingData(false)
                } else {
                    // error response from TikTok API
                    console.error('Error from TikTok API', response.data.message)
                    Sentry.captureException(new Error(response.data.message))
                    track("Error from TikTok API", {
                        error: response.data.message
                    })
                    setLoadingData(false)
                    setErrorState(true)
                }
            } catch (e) {
                console.error('error getting access token', e)
                Sentry.captureException(e)
                track("Error Getting Access Token", {
                    error: e
                })
                setLoadingData(false)
                setErrorState(true)
            }
        }
    }

    const onErrorStateHandler = () => {
        history.replace('/marketing')
    }

    const {
        finishedTasks,
    } = useContext(GettingStartedContext)

    useEffect(() => {
        if(!finishedTasks.includes(SYMPHONY_TASKS.CAMPAIGN_STARTED)) {
            setUserProperty(`Completed "Create Campaign" Onboarding Step`, true)
            // TODO: put -1 instead of -2 when we isolate the youtube video step
            track(`Completed "Create Campaign" Onboarding Step`, {
                remainingSteps: Object.keys(SYMPHONY_TASKS).length - finishedTasks.length - 2,
            });
            mixpanel.people.increment({
              "completed_onboarding_steps": 1, 
            });
        }
        getCampaignStatus()
    }, [])


    function campaignMessage() {
        if (campaignData && metaData) {
           

        }
        return null 
    }

    function campaignTitle() {
        if (campaignData && metaData) {
           
        }
        return null 
    }

    function errorText() {
        if (campaignData) {
            
        } else {
            return "There was an error submitting your campaign."
        }
        return null 
    }
    return <Fragment>
        <UpgradeToProSucceededModal
                dialogProps={{
                    open: openWelcomeProModal,
                    onClose: () => setOpenWelcomeProModal(false),
                }}
                sessionId={sessionId}
                brandSlug={currentBrand.slug}
                brandName={currentBrand.name}
                brandImage={currentBrand.image}
        />
    {loadingData ? (
        <>
            <TopBar title="Finalizing your campaign..." />
            <MainContentContainer className={classes.mainContainer}>
                <div className={`flex-col flex items-center mt-10 h-80 bg-white pt-16 rounded-${isMobile ? "none" : "xl"}`}>
                    <LoadingIndicator height="100px" color="black" />
                    <p className="mt-1 text-center">Finalizing your campaign...</p>
                </div>
            </MainContentContainer>
        </>
    ) : !errorState ? (
        <>
            <TopBar title="Campaign created!" />
            <MainContentContainer className={classes.mainContainer}>
                <div>
                    <div className="card-content bg-white shadow-none lg:shadow-lg">
                        <div className="top-wrapper">
                            <div className="card-head relative">

                                <h4 className="text-center text-dark lg:text-white text-2xl al">
                                    Campaign created!
                                </h4>
                            </div>
                        </div>


                        <div className="text-center mx-auto py-4 px-20">
                            <img
                                alt="tick-light.png"
                                className="mx-auto m-10"
                                src={require("../../../../../assets/images/tick-light.png").default}
                            />
                            <h5 className="font-bold text-xl">{campaignTitle()}</h5>
                            {campaignMessage()}

                        </div>
                        <div className="cta-wrapper">
                            <div className="text-center pb-4 px-4 lg:px-10">
                                <div className="btn-primary w-auto px-3 "
                                    onClick={() => {
                                        if (["boost_instagram", "get_playlisted", "increase_video_views", "record_streams", "link_clicks"].indexOf(metaData.campaign_type) > -1) {
                                            history.push(`/marketing/campaigns/${campaignData?.id || campaignData?.campaign?.id}`);

                                        } else {
                                            history.push(`/marketing/tools/creatives`);
                                        }
                                    }}>

                                    See Your Campaign
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainContentContainer>
        </>
    ) : (
        <>
            <TopBar title="Campaign Error" />
            <MainContentContainer className={classes.mainContainer}>
                <div className="card-body">
                    <div className="card-content bg-white shadow-none lg:shadow-lg">
                        <div className="top-wrapper">
                            <div className="card-head relative">

                                <h4 className="text-center text-dark lg:text-white text-2xl al">
                                    Campaign Error
                                </h4>
                            </div>
                        </div>


                        <div className="text-center mx-auto p-10 lg:p-20">

                            <h5 className="font-bold text-xl">{errorText()}</h5>
                            <p className="">
                                We apologize for the inconvenience - please try submitting your campaign again.
                            </p>
                        </div>
                        <div className="cta-wrapper">
                            <div className="text-center pb-4 px-4 lg:px-10">
                                <button className="btn-primary w-auto px-3 "
                                    onClick={() => {
                                        history.replace('/marketing')
                                    }}
                                >
                                    Okay
                                </button>
                            </div>
                        </div> 
                    </div>


                </div>
            </MainContentContainer>
        </>
    )}
    </Fragment>
}