import { useMediaQuery, useTheme } from "@material-ui/core";
import { FunctionComponent } from "react";
import CampaignDescription from "../../../Components/CampaignDescription";
import { ButtonFinish, ButtonsContainer, CardContainer, Image, ImageContainer, MainContainer, TextButtonFinish, TrashButton } from "./styles";
import defaultImage from 'assets/images/music-key-green-bg.svg';
import trashIcon from 'assets/images/trash.svg';

interface Props {
  imageUrl: string;
  campaignName: string;
  typeTitle: string;
  date: string;
  campaignId: number;
  campaignType: string;
  deleteDraft: (campaignId: number) => void;
  goToDraft: (campaignType: string, campaignId: number) => void;
}

export const Thumbnail: React.FC<{ src: string }> = ({ src }) => (
  <img className="w-12 h-12 mb-3 md:mb-0 md:w-20 md:h-20 object-cover rounded-xl mr-4" src={src} alt="" />
);

const CardCampaignDraft: FunctionComponent<Props> = ({
  imageUrl,
  campaignName,
  typeTitle,
  date,
  campaignId,
  campaignType,
  deleteDraft,
  goToDraft,
}: Props) => {
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  const handleFinishCampaign = () => goToDraft(campaignType, campaignId)

  const handleConfirmDeleteDraft = () => deleteDraft(campaignId)

  return (
    <div className={`flex flex-row justify-between items-center border-b px-0 py-5 md:px-4 md:py-7`}>
        <div className="flex flex-col items-start md:items-center md:flex-row" style={{ maxWidth: '75%' }}>
          <Thumbnail src={imageUrl ? imageUrl : defaultImage} />

          {!setResponsiveView && (
            <CampaignDescription
              campaignName={campaignName}
              date={date}
              key={campaignId}
              campaignType={campaignType}
            />
          )}

        </div>
        {setResponsiveView && (
          <CampaignDescription
            campaignName={campaignName}
            date={date}
            key={campaignId}
            campaignType={campaignType}
          />
        )}
        <div className="mt-4 md:ml-auto">
          <ButtonsContainer>
            <ButtonFinish
              onClick={handleFinishCampaign}
            >
              <TextButtonFinish>
                Finish creating
              </TextButtonFinish>
            </ButtonFinish>
            <TrashButton
              onClick={handleConfirmDeleteDraft}
              src={trashIcon}
              alt="trash-icon"
            />
          </ButtonsContainer>
        </div>
      </div>
  );
};

export default CardCampaignDraft;
