/* eslint-disable react-hooks/exhaustive-deps */
import {
  FunctionComponent,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
  Fragment,
  useContext,
} from "react"
import { ArtistPermissions, CurrentBrand, UserData } from "types/global"
import { Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core"
import { pageView } from "analytics"
import {
  StyledNavBar,
  TabsPanelsContainer,
  SupportButton,
  SupportButtonContainer,
  MainContainer,
  Card,
  StyledLabel,
  BannerContainer,
} from "./styles"
import { TabPanel } from "react-headless-tabs"
import isEqual from "lodash/isEqual"
import AccountTab from "./Components/AccountTab"
import BillingTab from "./Components/BillingTab"
import UsersTab from "./Components/UsersTab"
import { checkIfIsProTier, checkIfOldPlan, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils"
import { AdvancedSettingsTab } from "./Components/AdvancedSettingsTab"
import startCase from "lodash/startCase"
import lowerCase from "lodash/lowerCase"
import { SystemColors } from "types/globalStyles"
import { PageTabs, PageTabsViewOnly } from "./utils"
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner"
import { isAdmin } from "helpers/admin"
import { useLocation } from "react-router-dom"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

interface Props {
  currentBrand: CurrentBrand
  loadedBrands: CurrentBrand[]
  deleteBrand?: () => Promise<void>
  setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>>
  user: UserData
}

type GlobalWindow = Window & {
  Intercom: (type: string) => void
}

const { ACCOUNT, BILLING, USERS, ADVANCED_SETTINGS } = PageTabs

const tabs = [
  USERS,
  ACCOUNT,
  BILLING,
  ADVANCED_SETTINGS,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const tabsViewOnly = [
  ACCOUNT,
].map((tab) => ({
  value: tab,
  name: startCase(lowerCase(tab)),
}))

const SettingsPage: FunctionComponent<Props> = ({
  user,
}: Props) => {
  const theme = useTheme()
  const { currentBrand } = useContext(CurrentBrandContext)
  const hideSupportButton = useMediaQuery(theme.breakpoints.down(1024))
  const isProUser = checkIfIsProTier(currentBrand)
  const hasAdminAccess = isBrandAdministrator(currentBrand!)

  const isOnOldPlan = checkIfOldPlan(currentBrand)

  const userIsAdmin = user ? isAdmin(user) : false

  const showPaymentFailedBanner =
    getStatusToShowPaymentFailedBanner(currentBrand)
  const [materialTabValue, setMaterialTabValue] = useState<PageTabs | PageTabsViewOnly>(
    hasAdminAccess ? PageTabs.USERS : PageTabsViewOnly.ACCOUNT
  )

  const handleClickSupportButton = () => {
    const globalWindow = window as unknown as GlobalWindow
    if (globalWindow.Intercom) globalWindow.Intercom("show")
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromQuery = queryParams.get("tab") as PageTabs;

  useEffect(() => {
    if (tabFromQuery && Object.values(PageTabs).includes(tabFromQuery)) {
      setMaterialTabValue(tabFromQuery);
    }
  }, [tabFromQuery]);

  useEffect(() => {
    pageView("Settings");
  }, []);

  return (
    <Fragment>
      <MainContainer>
        {showPaymentFailedBanner && (
          <BannerContainer>
            <PaymentFailedBanner
              customerId={currentBrand?.subscription?.cid} />
          </BannerContainer>
        )}
        <Card padding="40px 24px" borderRadius="8px" marginBottom="16px">
          <StyledLabel fontSize={28}>Settings</StyledLabel>
          <StyledNavBar>
            <Tabs
              scrollButtons="auto"
              variant="scrollable"
              value={materialTabValue}
              onChange={(_event, newValue) => setMaterialTabValue(newValue)}
              aria-label="Settings Options Selector"
              TabIndicatorProps={{
                style: { backgroundColor: SystemColors.ACCENT_COLOR },
              }}
            >
              {(hasAdminAccess ? tabs :  tabsViewOnly).map(({ name, value }) => (
                <Tab
                  key={value}
                  disableRipple
                  value={value}
                  label={
                    <span className="capitalize font-medium text-base px-4 py-2">
                      {name}
                    </span>
                  }
                />
              ))}
            </Tabs>
            {!hideSupportButton && (
              <SupportButtonContainer>
                <SupportButton
                  disableTouchRipple
                  onClick={handleClickSupportButton}
                >
                  Support / Help
                </SupportButton>
              </SupportButtonContainer>
            )}
          </StyledNavBar>
        </Card>
        {!isEqual(materialTabValue, ADVANCED_SETTINGS) && (
          <Card marginTop="0px" padding="40px 24px">
            <TabsPanelsContainer>
              <TabPanel hidden={!isEqual(materialTabValue, ACCOUNT)}>
                <AccountTab
                  isAdmin={userIsAdmin}
                  email={user?.email}
                  brandSlug={currentBrand?.slug}
                  brandName={currentBrand?.name}
                  brandId={currentBrand?.id}
                  brandConnections={currentBrand?.connections}
                  brandSourceDetails={currentBrand?.affiliate_details}
                />
              </TabPanel>
              <TabPanel hidden={!isEqual(materialTabValue, USERS)}>
                <UsersTab
                  isAdmin={userIsAdmin}
                  email={user?.email}
                  brandId={currentBrand?.id}
                  isProUser={isProUser}
                />
              </TabPanel>
              <TabPanel hidden={!isEqual(materialTabValue, BILLING)}>
                <BillingTab
                  organization={user.organization ? user.organization : undefined}
                  subscriptionDetails={currentBrand?.subscription}
                  isAdmin={userIsAdmin}
                  isOnOldPlan={isOnOldPlan}
                  avatar={
                    currentBrand?.image ||
                    "https://www.w3schools.com/howto/img_avatar.png"
                  }
                  brandSlug={currentBrand?.slug}
                  brandName={currentBrand?.name}
                  isProUser={isProUser}
                  currency={currentBrand?.currency}
                />
              </TabPanel>
            </TabsPanelsContainer>
          </Card>
        )}
        {isEqual(materialTabValue, ADVANCED_SETTINGS) && (
          <AdvancedSettingsTab />
        )}
      </MainContainer>
    </Fragment>
  )
}

export default SettingsPage
