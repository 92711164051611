import { getAdLabelByStatus, getAdStatusBackgroundColor, getAdStatusTextColor } from '../../utils/adsStatuses'
import { BadgeText } from './styles'
import Badge from 'components/shareable/Badge'

export default function CampaignStatusBadge(props: {
  campaignStatus: string
  campaignStatusLabel: string | undefined
}) {
  const { campaignStatus, campaignStatusLabel } = props

  return (
    <Badge backgroundColor={`${getAdStatusBackgroundColor(campaignStatus)}`}>
      <BadgeText color={getAdStatusTextColor(campaignStatus)}>
        {getAdLabelByStatus(campaignStatusLabel?.toUpperCase())}
      </BadgeText>
    </Badge>
  )
}
