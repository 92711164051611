import { ReactComponent as PlayIcon } from "assets/images/play-purple.svg"
import {
  useStyles,
  VideoContainer,
  VideoCover,
  HeadlineContainer,
} from "./styles"
import PrimaryButton from "../PrimaryButton"
import { FunctionComponent } from "react"
import VideoModalLandscape from "pages/post-auth/MarketingPage/Components/VideoModalLandscape"
import { Headline1 } from "../Typography"
import { SystemColors } from "types/globalStyles"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"

interface Props {
  title: string
  videoUrl: string
  thumbnail: string
  openModal: boolean
  onOpenModal: () => void
  onCloseModal: () => void
  intercomArticle?: () => void
}

const StepVideoContainer: FunctionComponent<Props> = ({
  title,
  videoUrl,
  thumbnail,
  openModal,
  onOpenModal,
  onCloseModal,
  intercomArticle = () => {},
}: Props) => {
  const classes = useStyles()
  const { smallView } = useMediaBreakpoints()

  if (smallView) return (
    <VideoModalLandscape
      {...{ title, videoUrl }}
      open={openModal}
      buttonAction={intercomArticle}
      closeModal={onCloseModal}
    />
  );

  return (
    <VideoContainer thumbnail={thumbnail}>
      <VideoCover />
      <HeadlineContainer>
        <Headline1 color={SystemColors.WHITE_TEXT_COLOR}>{title}</Headline1>
        <PrimaryButton
          width="162px"
          height="48px"
          variant="dark"
          text="Watch video"
          iconLeft={<PlayIcon className={classes.videoIconStroke} />}
          onClick={onOpenModal}
        />
      </HeadlineContainer>
      <VideoModalLandscape
        {...{ title, videoUrl }}
        open={openModal}
        buttonAction={intercomArticle}
        closeModal={onCloseModal}
      />
    </VideoContainer>
  )
}

export default StepVideoContainer
