import { Body2, Headline2 } from "components/shareable/Typography";
import { ListElement, ElementsContainer, ListContainer, ListElementTitleContainer, ListElementValueContainer, ImageContainer } from "../styles";
import { SystemColors } from "types/globalStyles";
import { DefaultImage } from "components/shareable/StatCard/styles";
import { Container } from "styles/shared";

export type SitesListElement = {
  title: string;
  image_url?: string;
  value?: string | number;
  subValue?: string | number;
}

interface Props {
  items?: SitesListElement[];
  title: string;
  numbered?: boolean;
}

export function SitesList({
  items = [],
  title,
  numbered,
}: Props) {

  return (
    <ListContainer>
      <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>{title}</Headline2>
      <ElementsContainer alignItems={items.length === 0 ? 'center' : 'flex-start'}>
        {items.length === 0 && <Body2>No data found</Body2>}
        {items.map((item, index) => (
          <ListElement>
            <ListElementTitleContainer>
              {numbered && <Body2>{index + 1}</Body2>}
              {item.image_url && <DefaultImage
                src={item.image_url}
                width='48px'
                height='48px'
                borderRadius='8px'
                objectFit='scale-down'
              />}
              <Container overflow="hidden" textOverflow="ellipsis">
                <Body2 key={index} color={SystemColors.SECONDARY_TEXT_COLOR}>{item.title}</Body2>
              </Container>
            </ListElementTitleContainer>
            <ListElementValueContainer>
              {item.value && <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>{item.value}</Headline2>}
              {item.subValue && <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{item.subValue}</Body2>}
            </ListElementValueContainer>
          </ListElement>
        ))}
      </ElementsContainer>
    </ListContainer>
  )
}