import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { SystemColors } from "types/globalStyles";

interface FlexContainerProps {
  marginLeft?: string
  flexDirection?: string
}

export const useStyles = makeStyles(() => ({
  externalLinkIcon: {
    height: "24px",
    width: "24px",
    display: "inline",
  }
}))

export const InstructionsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  background-color: ${SystemColors.ACCENT_COLOR}14;
`;

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  margin-left: ${({ marginLeft }) => marginLeft};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const NumberedItem = styled.div`
  height: 100%;
`;

export const LinkContainer = styled.span`
  margin-left: 8px;
  gap: 4px;
`;

export const PixelContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 4px 8px 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 9px;
  background: ${SystemColors.PAPER_CONTAINER};
`;