import { FunctionComponent, SetStateAction, useRef, Dispatch } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { CurrentBrand, PlaylistsRecord as Record } from "types/global";
import { useHistory } from "react-router-dom";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import {
  ButtonCheckoutContainer,
  ButtonContainer,
  StripeButton,
} from "./styles";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { createPayPalOrder, redirectToCheckout } from "../../api";
import PrimaryButton from "components/shareable/PrimaryButton";

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

type Tier = {
  tier: number;
  cost: number;
};

type PayPalOrder = {
  orderId: string | null;
  campaignId: string | null;
};

interface Props {
  campaignId: string | null;
  brand: CurrentBrand;
  pitchNotes: string;
  creatingCampaign?: boolean;
  record: Record | null;
  tier: Tier;
  onCancelPayPalOrder?: () => void;
  onErrorPayPalOrder?: () => void;
  setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
  cancelSaving: () => void;
}

const GoLiveCard: FunctionComponent<Props> = ({
  campaignId,
  brand,
  record,
  pitchNotes,
  creatingCampaign,
  tier,
  onCancelPayPalOrder,
  onErrorPayPalOrder,
  setCreatingCampaign,
  cancelSaving,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const payPalOrder = useRef<PayPalOrder>({ orderId: null, campaignId: null });

  const handleClickStripeButton = () => {
    cancelSaving();
    setCreatingCampaign(true);
    redirectToCheckout({ campaignId, brand, record, tier, pitchNotes });
  };

  const onPayPalOrderApprove = async (
    _data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    const { campaignId, orderId } = payPalOrder.current;
    const url = `/marketing/success?campaign_id=${campaignId}&session_id=${orderId}`;

    history.push(url);
  };

  const createOrder = async () => {
    cancelSaving();
    setCreatingCampaign(true);

    const order = await createPayPalOrder({
      campaignId,
      brand,
      record,
      tier,
      pitchNotes
    });
    const { orderId } = order;

    payPalOrder.current = order;
    setCreatingCampaign(false);
    return orderId;
  };

  return (
    <Card>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step="⚡" />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Let’s go live
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
            Your campaign will be checked for eligibility before going active and may take up to 7 days to go live. If we are not able to pitch your song effectively, you will be refunded the full amount of your payment with an explanation.
          </StyledLabel>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
          <Grid container direction="column">
            <ButtonCheckoutContainer>
              <PrimaryButton
                width="100%"
                onClick={handleClickStripeButton}
                disabled={creatingCampaign}
                loading={creatingCampaign}
                text="Checkout with credit or debit card"
              />


            </ButtonCheckoutContainer>
            <ButtonContainer>
              <PayPalScriptProvider options={{ "client-id": payPalClientId }}>
                <PayPalButtons
                  className="w-full relative z-0"
                  style={{ label: "pay", shape: "pill" }}
                  fundingSource="paypal"
                  createOrder={createOrder}
                  onCancel={onCancelPayPalOrder}
                  onError={onErrorPayPalOrder}
                  onApprove={onPayPalOrderApprove}
                />
                <PayPalButtons
                  className="w-full relative z-0"
                  style={{ label: "pay", shape: "pill" }}
                  fundingSource="venmo"
                  createOrder={createOrder}
                  onCancel={onCancelPayPalOrder}
                  onError={onErrorPayPalOrder}
                  onApprove={onPayPalOrderApprove}
                />
              </PayPalScriptProvider>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GoLiveCard;
