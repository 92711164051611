import Lottie from 'react-lottie';
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottieBlack from 'assets/images/lotties/SymphonyLoading.json';

export function LoadingLottie(props: {
  black?: boolean,
  width?: number,
  height?: number,
}) {
  return (
      <Lottie
          width={props.width || 44}
          height={props.height || 44}
          options={{
              rendererSettings: {
                  className: "bg-white-500"
              },
              loop: true,
              autoplay: true,
              animationData: props.black ? SymphonyLoadingLottieBlack : SymphonyLoadingLottie,
          }}
      />
  )
}
