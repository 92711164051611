import { FunctionComponent } from "react";
import { CardContainer, IconContainer, ValueText, CardTitle, Card, Icon } from "./styles";
import { useMediaQuery, useTheme } from '@material-ui/core'
import millify from "millify";
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "../../constants";

interface Props {
  icon: string
  title: string
  value: number
  isMoney: boolean
  currency?: CurrencyCodesType
}

const getFormattedMoney = (amount?: number, currency?: string) =>
  `${getSymbolFromCurrency(currency ?? DEFAULT_CURRENCY)}${amount?.toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })} ${currency?.toUpperCase() || "USD"}`

const MarketDetailCard: FunctionComponent<Props> = ({
  icon,
  title,
  value,
  isMoney,
  currency,
}) => {

  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  return (
    <CardContainer
      width={setResponsiveView ? "calc(50% - 8px)" : "calc(25% - 8px)"}
    >
      <Card
        flexDirection={setResponsiveView ? "row" : "column"}
        alignItems={setResponsiveView ? "center" : "flex-start"}
      >
        <IconContainer>
          <Icon
            src={icon}
          />
        </IconContainer>
        <div>
          <CardTitle>
            {title}
          </CardTitle>
          <ValueText>
            {isMoney ? (
              getFormattedMoney(value, currency)
            ) : (
              millify(value)
            )}
          </ValueText>
        </div>
      </Card>
    </CardContainer>
  );
}

export default MarketDetailCard