import styled from 'styled-components';

export const ButtonContactSupport = styled.button`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #FFFFFF;
  color: #8800FF;
  border-radius: 48px;
  padding: 12px 24px;
  width: fit-content;
  border: 1px solid #8800FF;
`

export const SendEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  border-top: 1px solid #EDECF2;
  padding-top: 16px;
`;

export const SendEmailLabel = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
`;

export const EmailLabel = styled.a`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8800FF;
`;
