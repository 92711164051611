import { Fragment, FunctionComponent, useState } from "react"
import {
  ErrorBannerBody,
  ErrorBannerContainer,
} from "./styles"
import SecondaryButton from "components/shareable/SecondaryButton"
import { Subtitle2, Body2 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import LinkButton from "components/shareable/LinkButton"
import { commonCardsProps } from '../../utils'

interface Props {
  errorTitle?: string
  errorDescription?: string
  showErrorView?: boolean
  buttonText: string
  retriedText?: string
  onSkipError?: () => void
  onClickHowToLinkButton?: () => void
  onClickErrorButton?: () => void
}

const ErrorBanner: FunctionComponent<Props> = ({
  errorTitle,
  errorDescription,
  showErrorView,
  buttonText,
  retriedText,
  onSkipError,
  onClickHowToLinkButton,
  onClickErrorButton,
}: Props) => {
  const [showOnRetriedText, setShowOnRetriedText] = useState<boolean>(false)

  const handleOnClickErrorButton = () => {
    if (retriedText) setShowOnRetriedText(true)
    if (onClickErrorButton) onClickErrorButton()
  }

  return (
    <Fragment>
      {errorTitle && errorDescription ? (
        <ErrorBannerContainer>
          <ErrorBannerBody>
            <Subtitle2>{errorTitle}</Subtitle2>
            <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
              {errorDescription}
            </Body2>
            {showErrorView && (
              <LinkButton
                text="How do I link them?"
                onClick={onClickHowToLinkButton}
              />
            )}
          </ErrorBannerBody>
          {showErrorView && (
            <SecondaryButton
              customColor={SystemColors.PRIMARY_TEXT_COLOR}
              text={buttonText}
              onClick={onClickErrorButton}
              width="fit-content"
            />
          )}
        </ErrorBannerContainer>
      ) :
        showErrorView && (
          <SecondaryButton
            customColor={SystemColors.PRIMARY_TEXT_COLOR}
            text={buttonText}
            onClick={handleOnClickErrorButton}
            width="fit-content"
          />
        )}
      {(onSkipError && errorTitle !== commonCardsProps.facebookAdAccount.errorTitle)
        && <LinkButton text="I've added myself as an admin" onClick={onSkipError}/>
      }
      {(showOnRetriedText && errorTitle !== commonCardsProps.facebookAdAccount.errorTitle) && (
        <Body2 color={SystemColors.ERROR_COLOR}>
          {retriedText}
        </Body2>
      )}
    </Fragment>
  )
}

export default ErrorBanner
