import { makeStyles } from "@material-ui/core"
import { SystemColors } from "types/globalStyles"
import styled from "styled-components"

export const useStyles = makeStyles(() => ({
  externalLinkIcon: {
    height: "24px",
    width: "24px",
    display: "inline",
  }
}));

interface FlexContainerProps {
  marginLeft?: string
  flexDirection?: string
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  margin-left: ${({ marginLeft }) => marginLeft};
  flex-direction: ${({ flexDirection }) => flexDirection};
  width: 100%;
`;

export const NumberedItem = styled.div`
  height: 100%;
`;

export const InstructionsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 8px;
  border-radius: 8px;
  width: 100%;
  background-color: ${SystemColors.ACCENT_COLOR}14;
`;

export const LinkContainer = styled.div`
  margin-left: 8px;
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
`;

export default useStyles
