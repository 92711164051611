import { Theme, makeStyles } from '@material-ui/core';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    height: '32px',
    width: '32px',
    '& path': {
      stroke: SystemColors.ACCENT_COLOR,
    },
  },
  input: {
    '& .MuiInputBase-root': {
      height: '45px',
    },
    '& input': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '& input::placeholder': {
      color: `${SystemColors.SECONDARY_TEXT_COLOR} !important`,
      opacity: '1 !important',
    },
    '& fieldset': {
      borderColor: `${SystemColors.PAPER_DIVIDER_CONTAINER} !important`,
    },
		'& .Mui-focused > fieldset': {
			borderColor: `${SystemColors.ACCENT_COLOR} !important`,
    },
    '& .Mui-error > fieldset': {
			borderColor: `${SystemColors.ERROR_COLOR} !important`,
    },
  },
  select: {
    height: '45px',
    '&.MuiSelect-select': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '&.Mui-focused > .MuiSelect-select:focus': {
      backgroundColor: `${SystemColors.PAPER_CONTAINER} !important`,
    },
    '& fieldset': {
			borderColor: `${SystemColors.PAPER_DIVIDER_CONTAINER} !important`,
    },
    '&.Mui-focused > fieldset': {
			borderColor: `${SystemColors.ACCENT_COLOR} !important`,
    },
  },
}));
