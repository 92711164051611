import { TabSelector } from "helpers/TabSelector"
import {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  Fragment,
} from "react"
import HeaderModal from "../../../HeaderModal"
import { getRecentReleases } from "../../api"
import { DuplicateSteps } from "../../utils"
import {
  OptionSelectionContainer,
  InputContainer,
  useStyles,
  Card,
  CardInfo,
  CardSubContainer,
  FromName,
  ProjectImage,
  ProjectName,
  StyledContentList,
  MainContainer,
} from "./style"
import {
  CurrentBrand,
  DuplicateSongAdsRecord as IncreaseMusicStreamsRecord,
  Release,
} from "types/global"
import { CAMPAIGN_TYPES } from "../../../../../../../constants"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg"
import Lottie from "react-lottie"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import ErrorBanner from "../ErrorBanner"
import { getFormattedRecords } from "../../../../utils"
import SharedMusicViewSelector from "../../../SuperboostModal/Components/shared-music-selector-view"

const ExplicitIcon: FunctionComponent = () => (
  <p className="rounded-sm box-border ml-2 bg-gray-300 p-2 h-4 w-4 flex items-center justify-center">
    <span className="text-xs font-medium">E</span>
  </p>
)

const { SELECT_DUPLICATE_OPTION } = DuplicateSteps

const { record_streams: RECORD_STREAMS } = CAMPAIGN_TYPES

interface Props {
  errorRecordDetails: boolean
  loadingRecordDetails: boolean
  campaignType: string
  currentBrand: CurrentBrand
  isSpotifyConnected: boolean
  handleChangeRecord: (inputValue: string, slug: string | null) => void
  handleGoBack: (step: DuplicateSteps) => void
  closeModal: () => void
}

enum Tabs {
  SINGLE = "single",
  PROJECT = "project",
}

const { SINGLE, PROJECT } = Tabs

const ChooseAnotherProjectView: FunctionComponent<Props> = ({
  errorRecordDetails,
  loadingRecordDetails,
  campaignType,
  currentBrand,
  isSpotifyConnected,
  handleChangeRecord,
  closeModal,
  handleGoBack,
}: Props) => {
  const classes = useStyles()
  const [recentReleases, setRecentReleases] = useState<Release[] | null>(null)
  const [selectedTab, setSelectedTab] = useState<Tabs>(SINGLE)
  const [inputValue, setInputValue] = useState<string>("")
  const [errorReleases, setErrorReleases] = useState(false)
  const [loadingReleases, setLoadingReleases] = useState(false)
  const [typeRecord, setTypeRecord] = useState<"single" | "project">("single")
  const loading = loadingReleases || loadingRecordDetails
  const error = errorReleases || errorRecordDetails

  const records = useMemo(
    () =>
      getFormattedRecords({
        recentReleases,
        releaseType: selectedTab,
        search: inputValue,
      }),
    [selectedTab, recentReleases, inputValue]
  )

  const goBack = () => handleGoBack(SELECT_DUPLICATE_OPTION)

  const handleChangeTab = (tab: Tabs) => () => {
    setSelectedTab(tab)
    setTypeRecord(tab)
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
  }

  const handleTryAgain = async () => {
    setLoadingReleases(true)
    const { data, error } = await getRecentReleases(currentBrand)

    if (data && !error) {
      setRecentReleases(data)
    } else {
      setErrorReleases(true)
    }
    setLoadingReleases(false)
  }

  useEffect(() => {
    (async () => {
      if (
        isSpotifyConnected &&
        !recentReleases &&
        campaignType === RECORD_STREAMS
      ) {
        setLoadingReleases(true)
        const { data, error } = await getRecentReleases(currentBrand)

        if (data && !error) {
          setRecentReleases(data)
        } else {
          setErrorReleases(true)
        }
        setLoadingReleases(false)
      }
    })()
  }, [currentBrand, isSpotifyConnected, campaignType])

  return (
    <MainContainer>
      <HeaderModal
        title="Duplicate campaign"
        subtitle="Select the release you want to promote"
        closeModal={closeModal}
        handleGoBack={goBack}
      />
      <OptionSelectionContainer>
        <SharedMusicViewSelector
          isSpotifyConnected={isSpotifyConnected}
          parentLoading={loadingRecordDetails}
          parentError={errorRecordDetails}
          currentBrand={currentBrand}
          handleChangeRecord={handleChangeRecord}
          options={['single', 'project', 'playlist']}
        />

      </OptionSelectionContainer>
    </MainContainer>
  )
}

export default ChooseAnotherProjectView
