import { Switch, useTheme, Grid } from '@material-ui/core';
import { Dialog, useMediaQuery } from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  AdditionalUserInfoCard,
  AutoPresavedContainer,
  CloseButtonContainer,
  EmptyPresaves,
  FanInformationContainer,
  HeaderContainer,
  ModalContainer,
  PresavesContainer,
  StyledLabel,
  TabsContainer,
  UserInformationContainer,
  useStyles,
} from "./styles";
import { ReactComponent as CloseIcon } from 'assets/images/close-black.svg';
import { Fan } from 'types/global';
import { getFormattedLocation } from '../CustomTable/utils';
import { getPresavedByFan, setForeverPresaves } from './api';
import PresaveCard from './PresaveCard';
import { Presave } from './constants';
import PlatformCell from '../CustomTable/PlatformCell';
import isEqual from "lodash/isEqual";
import { TabSelector } from 'helpers/TabSelector';
import { TabPanel } from 'react-headless-tabs';
import { DefaultImage } from '../../styles';
import { getFormattedName, getFormattedDate, getFormattedSource } from './utils';
import emptyUser from "assets/images/user.svg";
import { LoadingLottie } from 'components/Loader/LoadingLottie';
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext';
import { StyledNavBar, TextButton } from 'styles/shared';

interface Props {
  open: boolean,
  fan: Fan,
  isProUser?: boolean,
  closeModal: () => void,
}

enum Tabs {
  PRESAVES = "Pre-saved Releases",
}

const { PRESAVES } = Tabs;

const GRAY_COLOR = "#707070";

const FansDetailsModal: FunctionComponent<Props> = ({
  fan,
  open,
  isProUser = false,
  closeModal,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const [presaves, setPresaves] = useState<Presave[]>([]);
  const [loadingPresaves, setLoadingPresaves] = useState<boolean>(false)
  const [loadingForeverPresaves, setLoadingForeverPresaves] = useState<boolean>(false)
  const [autoPresaves, setAutoPresaves] = useState<boolean>(fan.presaveFutureReleases)
  const [selectedTab, setSelectedTab] = useState<Tabs>(PRESAVES);
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const getPresaves = async (fanId: number) => {
    setLoadingPresaves(true)
    const { data, error } = await getPresavedByFan(fanId)
    if (error) {
      setPresaves([])
    } else {
      setPresaves(data as Presave[])
    }
    setLoadingPresaves(false)
  }

  const setAutopresaves = async (activate: boolean, fanId: number) => {
    setLoadingForeverPresaves(true)
    const { data, error } = await setForeverPresaves({ activate, fanId })
    if (error) {
      fan.presaveFutureReleases = !activate;
      setAutoPresaves(!activate)
    } else {

      fan.presaveFutureReleases = data.activated as boolean;
      setAutoPresaves(data.activated as boolean)
    }
    setLoadingForeverPresaves(false);
  }

  const handleSelectPresavesTab = () => setSelectedTab(PRESAVES)

  const handleChangeAutopresave = () => {
    if (!isProUser && !autoPresaves) {
      handleOpenUpgradeToProModal({
        source: "Presave Campaign Details - Enable Forever Saves"
      });
      return;
    }
    setAutopresaves(!fan.presaveFutureReleases, fan.id);
  }

  useEffect(() => {
    if (fan.id) {
      setAutoPresaves(fan.presaveFutureReleases)
    }
  }, [fan])

  useEffect(() => {
    setLoadingPresaves(true)
    if (fan.id && isEqual(selectedTab, PRESAVES)) {
      setPresaves([])
      getPresaves(fan.id)
    }
  }, [fan.id, selectedTab])


  const fromPresave = fan.origin === "presave";


  function renderForeverSavesStatus() {

    let element = null;

    if (loadingForeverPresaves) {
      element = <LoadingLottie
        black
        width={58}
        height={38}
      />
    } else {

      let fanEnabledForeverSaves = autoPresaves
      let titleMessage = fanEnabledForeverSaves ? "Subscribed to Forever Saves" : ""
      let descriptionMsg = fanEnabledForeverSaves ? "This fan is subscribed to saving all of your future releases. You can set their subscription status at the link below - if they're unsubscribed, they'll be able to re-subscribe from any future presave campaign." : "This fan hasn't consented or subscribed to Forever Saves. They'll be able to re-subscribe from any future presave campaign."
      let fanAction = fanEnabledForeverSaves && fan.updateSubscriptionLink ? <TextButton onClick={() => {
        window.open(fan.updateSubscriptionLink, "_blank")
      }}>Edit Subscription Status</TextButton> : null
      element = (<>
 
        <AdditionalUserInfoCard>
          <StyledLabel fontSize="16px">{titleMessage}</StyledLabel>
          <StyledLabel color={GRAY_COLOR} fontSize="12px">
            {descriptionMsg}
          </StyledLabel>

          {fanAction}

        </AdditionalUserInfoCard>
      </>)
    }


    return (
      <AutoPresavedContainer>
        {element}
      </AutoPresavedContainer>
    )
  }

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "auto"}
        margin={setResponsiveView ? "0px" : "auto"}
        padding={setResponsiveView ? "16px" : "32px"}
        height="fit-content"
        gap="24px"
      >
        <HeaderContainer>
          <CloseButtonContainer onClick={closeModal}>
            <CloseIcon />
          </CloseButtonContainer>
          <FanInformationContainer>
            <DefaultImage
              borderRadius="50%"
              height="56px"
              width="56px"
              src={fan.profile_url || emptyUser}
              alt="user avatar"
              backgroundColor={fan.profile_url ? "none" : GRAY_COLOR}
            />
            <UserInformationContainer>
              {fromPresave && <PlatformCell
                justifyContent="flex-start"
                // flexDirection="row-reverse"
                platformKey={fan.platform}
              />}
              <StyledLabel fontSize="32px" lineHeight="40px" blur={!isProUser}>
                {getFormattedName({ name: fan.name, lastname: fan.lastname, isProUser })}
              </StyledLabel>
              <StyledLabel fontSize="18px" color={GRAY_COLOR}>
                {fan.email || fan.cellphone || "Contact info not shared"}
              </StyledLabel>
            </UserInformationContainer>
          </FanInformationContainer>
        </HeaderContainer>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Joined
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {getFormattedDate(fan.createdAt)}
          </StyledLabel>
        </AdditionalUserInfoCard>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Contact Info - Email
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {fan.email || "Email not collected"}
          </StyledLabel>
        </AdditionalUserInfoCard>
        {fan.cellphone && (<AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Contact Info - Number
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {fan.cellphone}
          </StyledLabel>
        </AdditionalUserInfoCard>)}
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Location
          </StyledLabel>
          <StyledLabel fontSize="16px" blur={!isProUser}>
            {getFormattedLocation({ city: fan.city, country: fan.country, isProUser })}
          </StyledLabel>
        </AdditionalUserInfoCard>
        <AdditionalUserInfoCard>
          <StyledLabel color={GRAY_COLOR} fontSize="16px">
            Source
          </StyledLabel>
          <StyledLabel fontSize="16px">
            {getFormattedSource(fan.origin, presaves[0]?.contentName || "")}
          </StyledLabel>
          {fromPresave && renderForeverSavesStatus()}
        </AdditionalUserInfoCard>
        {fromPresave && (<TabsContainer>
          <StyledNavBar>
            <TabSelector
              isActive={isEqual(selectedTab, PRESAVES)}
              onClick={handleSelectPresavesTab}
            >
              {PRESAVES}
            </TabSelector>
          </StyledNavBar>
          <TabPanel hidden={!isEqual(selectedTab, PRESAVES)}>
            {presaves.length ? (
              <PresavesContainer>
                {presaves.map((presave: Presave) => (
                  <PresaveCard
                    artistName={presave.artistName}
                    imageUrl={presave.imageUrl}
                    presaveName={presave.contentName}
                    presaveType={presave.type}
                  />
                ))}
              </PresavesContainer>
            ) : loadingPresaves ? (
              <Grid className="w-full flex justify-center">
                <LoadingLottie
                  black
                  width={24}
                  height={24}
                />
              </Grid>
            ) : (
              <EmptyPresaves> This fan hasn’t pre-saved any releases yet.</EmptyPresaves>
            )}
          </TabPanel>
        </TabsContainer>)}
      </ModalContainer>
    </Dialog>
  );
}

export default FansDetailsModal;
