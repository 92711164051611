import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import { SystemColors } from "types/globalStyles"

const useStyles = makeStyles(() => ({
  root: {
    marginTop: "-60px !important",
    height: "100vh !important",
  },
  swiperNext: {
    "& path": {
      stroke: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  swiperPrev: {
    transform: "rotate(180deg)",
    "& path": {
      stroke: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
}))

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const ChevronContainer = styled.div`
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.16);
  cursor: pointer;
`

export const NavContainer = styled.div`
  bottom: 300px;
  position: absolute;
  justify-content: space-between;
  width: 45%;
  padding: 0px 48px;
  z-index: 40;
  align-items: center;
  display: flex;
  @media (max-width: 900px) {
    height: 90px;
    top: 400px;
    bottom: 0px;
    width: 100%;
  }
`

export default useStyles
