import { FunctionComponent } from "react";
import {
  BlurContainer,
  EmailCellContainer,
  EmailContainer,
  NameContainer,
  NewFanContainer,
  UserDataContainer,
  UserInformationContainer,
} from "./styles";
import emptyUser from "assets/images/user.svg";
import { DefaultImage } from '../../styles';
interface Props {
  email?: string;
  cellphone?: string;
  name?: string;
  lastname?: string;
  imageUrl?: string;
  isNew: boolean;
  isProUser?: boolean;
  newFollower?: boolean;
  newFollowerPlatformName?: string;
}

const ContactInfoCell: FunctionComponent<Props> = ({
  email,
  cellphone,
  name,
  lastname,
  imageUrl,
  isNew,
  isProUser,
  newFollower,
  newFollowerPlatformName
}: Props) => {
  const formattedName = `${name || ""} ${lastname || ""}`.trim() || "-";

  let formattedEmail = "Email not shared";
  if (cellphone) {
    formattedEmail = cellphone;
  } else if (email) {
    formattedEmail = email;
  }
  return (
    <EmailCellContainer>
      <DefaultImage
        src={imageUrl || emptyUser}
        height="24px"
        width="24px"
        borderRadius="50%"
        backgroundColor={imageUrl ? "none" : "#B2B2B224"}
      />
      <UserDataContainer>
        <EmailContainer>{formattedEmail}</EmailContainer>
        <UserInformationContainer>
          {isProUser
            ? <NameContainer>{formattedName}</NameContainer>
            : <BlurContainer>xxxxxx xxxxxx xxx</BlurContainer>
          }
          {isNew && <NewFanContainer>NEW FAN</NewFanContainer>}
          {newFollower && newFollowerPlatformName && <NewFanContainer>FOLLOWED YOU ON {newFollowerPlatformName.toUpperCase()}</NewFanContainer>}

        </UserInformationContainer>
      </UserDataContainer>
    </EmailCellContainer>
  );
};

export default ContactInfoCell;
