import millify from "millify";
import { LoadingIndicator } from "components/Loader";
import RowItem from "components/shareable/RowItem";


export interface PresaveStreamingServiceDemographicsItem {
    name: string;
    platform: string;
    tier?: string;
    count: number;
    percentage: number;
    icon?: string;
}
interface DemographicsStatsProps {
    shown: boolean;
    contentName: string;
    demographicBreakdown: PresaveStreamingServiceDemographicsItem[],
    children?: any,
}
export default function Demographics(props: DemographicsStatsProps) {

    const {
        shown,
        contentName,
        demographicBreakdown,
        children
    } = props


    return (
        <>
            <div className={` pt-0 ${shown ? 'visible' : 'hidden'}`}>
                {!(demographicBreakdown) ?
                    (<div className="mx-auto my-6 flex-col flex items-center ">
                        <LoadingIndicator height="100px" color="black" />
                        <p className="mt-1 text-center">Loading...</p>
                    </div>) : (
                        <>
                            <div className="pb-5">
                                <p className="text-lg font-semibold">Streaming Services Breakdown</p>
                                <p className="text-sm text-gray-500">These are the streaming services your fans used to pre-save "{contentName}".</p>
                            </div>
                            <div>
                                <ul className="max-h-80 overflow-auto">
                                    {
                                        demographicBreakdown
                                            .map((item: PresaveStreamingServiceDemographicsItem, index: number) => {
                                                return (
                                                    <RowItem
                                                        key={index}
                                                        image={item.icon}
                                                        name={item.name}
                                                        showRightValue={true}
                                                        rightValue={`${millify(item.count)} (${item.percentage}%)`}
                                                    />
                                                );
                                            })
                                    }
                                </ul>
                            </div>
                        </>
                    )}
            </div>
            {children}
        </>
    )
}