import { FunctionComponent, ReactElement } from "react";
import { CardContainer, CardContainerMobile, CompletedMark, CompletedMarkContainer, TaskDescription, TaskHeaderMobile, TaskIcon, TaskIconImage, TaskLink, TaskTextInfo, TaskTitle, useStyles } from "./styles";
import { clsx } from 'clsx';
import { ReactComponent as TickIcon } from "assets/images/tick.svg"
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { ReactComponent as ArrowRightIcon } from 'assets/images/arrowRight.svg';
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  icon?: string;
  title: string;
  description: string;
  actionName?: string;
  action?: () => void;
  href?: string;
  crossedOut?: boolean;
  addBorder?: boolean;
  headerAlign?: string;
  showBoxShadow?: boolean;
}

const TaskCard: FunctionComponent<Props> = ({
  icon,
  title,
  description,
  actionName,
  action,
  crossedOut = false,
  addBorder = false,
  headerAlign = "flex-start",
  showBoxShadow,
  href
}: Props) => {
  const classes = useStyles();
  const { innerWidth, isMobile } = useScreenQueryDimensions();
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(1200));
  if (setResponsiveView) {
    return (
      <CardContainerMobile>
        <TaskHeaderMobile flexDirection={setResponsiveView ? "row" : "column"}>
          <TaskIcon>
            <TaskIconImage src={icon} />
          </TaskIcon>
          <TaskTitle textAlign={headerAlign}>
            {title}
          </TaskTitle>
        </TaskHeaderMobile>
        <TaskTextInfo className={clsx(crossedOut && classes.crossedOut)}>
          {
            (!isMobile || !setResponsiveView) && (
              <TaskTitle>
                {title}
              </TaskTitle>
            )
          }
          <TaskDescription>
            {description}
          </TaskDescription>
          {
            action && !crossedOut ? <TaskLink onClick={action}>{actionName ? actionName : 'Start'}<ArrowRightIcon /></TaskLink>
              : <TickIcon className={clsx(classes.markIcon, !crossedOut && 'hidden')} />
          }
        </TaskTextInfo>
      </CardContainerMobile>
    )
  }

  return (
    <CardContainer className={addBorder ? classes.borded : ""}
      showBoxShadow={showBoxShadow}>
      <div>
        <TaskIcon>
          <TaskIconImage src={icon} />
        </TaskIcon>
      </div>
      <TaskTextInfo className={clsx(crossedOut && classes.crossedOut)}>
        <TaskTitle>
          {title}
        </TaskTitle>
        <TaskDescription>
          {description}
        </TaskDescription>

        {
          !href && action && !crossedOut &&
          <TaskLink onClick={action}>
            {actionName ? actionName : 'Start'}<ArrowRightIcon />
          </TaskLink>
        }
        {
          !action && href && !crossedOut &&
          <TaskLink href={href}>
            {actionName ? actionName : 'Start'}<ArrowRightIcon />
          </TaskLink>
        }
        {
          action && href && !crossedOut &&
          <TaskLink href={href} onClick={action}>
            {actionName ? actionName : 'Start'}<ArrowRightIcon />
          </TaskLink>
        }
      </TaskTextInfo>
      {
        crossedOut && (
          <CompletedMarkContainer>
            <TickIcon className={classes.markIcon} />
          </CompletedMarkContainer>
        )
      }
    </CardContainer>
  );
};

export default TaskCard;