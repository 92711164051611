import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    mainContainer: {
        borderRadius: theme.spacing(1.5),
        backgroundColor: "#FDF6EC",
    },
    textContainer: {
        padding: theme.spacing(2),
    },
    headerLabel: {
        color: "#754600",
    },
    content:{
        color: "black",
    },
}));

export default useStyles