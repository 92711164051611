import { FunctionComponent, useMemo } from "react"
import { FbSuggestion } from "types/global"





interface Props {
  selectedAudiences: any[];
  bestPracticeText?: string;
  tooLowThreshold?: number;
  tooMuchThreshold?: number;
}

const AudienceTargetingStatusIndicator: FunctionComponent<Props> = ({
  selectedAudiences,
  bestPracticeText,
  tooLowThreshold = 5,
  tooMuchThreshold = 8,
}: Props) => {

  const data = {
    tooLow: {
      opacity: 100,
      backgroundColor: "yellow",
      message: `Best Practice: ${bestPracticeText ? bestPracticeText : 'We recommend adding at least 5 artists.'}`,
      icon: "🛰",
    },
    tooMuch: {
      opacity: 100,
      backgroundColor: "red",
      message:
        "You added a lot of artists! To be more honed in on your targeting, add about 5 - 8 max.",
      icon: "!!",
    },
    allGood: {
      opacity: 300,
      backgroundColor: "green",
      icon: "🔥",
      message: "Your targeting is good to go!",
    },
  }
  

  const getWarningStatus = (numberOfAudiences: number) => {
    if (numberOfAudiences < tooLowThreshold) return "tooLow"
    if(tooMuchThreshold === 0) return "allGood"
    if (numberOfAudiences > tooMuchThreshold) return "tooMuch"
    return "allGood"
  }

  const numberOfAudiences = selectedAudiences.length
  const status = useMemo(
    () => getWarningStatus(numberOfAudiences),
    [numberOfAudiences]
  )
  const { backgroundColor, icon, message, opacity } = data[status]

  return (
    <div
      className={`mb-2 flex text-dark text-md p-2 bg-${backgroundColor}-${opacity} rounded-md items-center`}
    >
      <div className="bg-white rounded-full w-7 h-7 flex items-center justify-center mr-2">
        {icon}
      </div>
      <p className="text-dark">{message}</p>
    </div>
  )
}

export default AudienceTargetingStatusIndicator
