import {
  createNewAdAccount,
  FacebookAdAccountAccess,
  getAdAccounts,
  getBusinessManagers,
  isBusinessAdmin,
  updateInBrand,
} from 'services/facebookApi'
import { CenterContentDiv, NoAccountsDiv, StyledSection } from './styles'
import { Fragment, useContext, useEffect, useState } from 'react'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { AdAccountStatus, AdAccountStatuses, FacebookBusinessManager } from 'types/global'
import { ErrorStatesStatuses, useGetAdAccounts } from './hooks/useGetAdAccounts'
import { useSearchFilter } from '../../../Hooks/useSearchFilter'
import { SearchInput } from 'components/shareable/SearchInput'
import { AD_ACCOUNT_STATUS, loginWithFB } from 'helpers/FB'
import { ErrorState } from './ErrorState'
import { connectUserToFacebook } from 'pages/post-auth/SettingsPage/Components/AccountTab/api'
import { LoadingLottie } from 'components/Loader/LoadingLottie'
import { Body2 } from 'components/shareable/Typography'
import DialogPaper from 'components/shareable/DialogPaper'
import PrimaryButton from 'components/shareable/PrimaryButton'
import { SelectAdAccountHeader } from './SelectAdAccountHeader'
import { AccountsList } from './AccountsList/AccountsList'
import { Data } from '../../../Hooks/useCommonStates'
import { BusinessManagerWarningMessage } from '../../../Components/BusinessManagerWarningMessage'
import { ErrorBannerContainer } from '../../../Components/ErrorBanner/styles'
import { track } from 'analytics'

const AccountStatuses = AD_ACCOUNT_STATUS as AdAccountStatuses
interface SelectAdAccountModalProps {
  isOpen: boolean
  closeModal: (reload?: boolean) => void
  facebookPixel: Data
  facebookAdAccount: Data
}

export function SelectAdAccountModal({
  isOpen,
  closeModal,
  facebookPixel,
  facebookAdAccount
}: SelectAdAccountModalProps) {
  const { currentBrand } = useContext(CurrentBrandContext)
  const { errorState, setErrorState, adAccounts, loadingAccounts, setLoadingAccounts } = useGetAdAccounts(isOpen)
  const { searchQuery, filteredAccounts, handleOnChangeSearch, setListToFilter } = useSearchFilter(adAccounts)
  const [allAdAccountsDisabled, setAllAdAccountsDisabled] = useState<boolean>(false)
  const [selectedAdAccount, setSelectedAdAccount] = useState<FacebookAdAccountAccess>()
  const [loadingConnection, setLoadingConnection] = useState<boolean>(false)
  const [businessManager, setBusinessManager] = useState<FacebookBusinessManager>()
  const [businessAccounts, setBusinessAccounts] = useState<FacebookBusinessManager[]>([])
  const [isCreatingAdAccount, setIsCreatingAdAccount] = useState<boolean>(false)
  const [errorCreatingAdAccount, setErrorCreatingAdAccount] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const adAccountsToDisplay: FacebookAdAccountAccess[] = searchQuery
    ? (filteredAccounts as FacebookAdAccountAccess[])
    : adAccounts
  const businessAccountsToDisplay: FacebookBusinessManager[] = searchQuery
    ? (filteredAccounts as FacebookBusinessManager[])
    : businessAccounts

  useEffect(() => {
    if (!isCreatingAdAccount) {
      const currentAdAccounts = [...adAccountsToDisplay]

      const areAllDisabled: boolean = currentAdAccounts.every((account) => {
        const accountStatus: AdAccountStatus = AccountStatuses[account.account_status]

        // An ad account needs to be linked to a business
        const isPersonal: boolean = account.business === undefined;

        return !accountStatus.valid || !account.userHasAccess
      })

      setAllAdAccountsDisabled(areAllDisabled)
    } else {
      setAllAdAccountsDisabled(false)
    }
  }, [isCreatingAdAccount, adAccountsToDisplay])

  useEffect(() => {
    handleOnChangeSearch('')
  }, [isCreatingAdAccount])

  const handleOnClickConnect = async () => {
    const { id, slug, name } = currentBrand || {}
    if (!id || !slug || !name) return

    setLoadingConnection(true);

    if (selectedAdAccount?.business) {
      const isAdmin: boolean = await isBusinessAdmin(selectedAdAccount.business.id);

      if (!isAdmin) {
        facebookAdAccount.setError(true)
      } else if (facebookAdAccount.error) {
        facebookAdAccount.setError(false)
      }
    }

    await connectUserToFacebook({
      brandId: id,
      brandSlug: slug,
      brandName: name,
    })

    if (currentBrand && selectedAdAccount) {
      await updateInBrand(currentBrand?.id, currentBrand?.slug as string, selectedAdAccount)
      setErrorCreatingAdAccount(false)
      facebookPixel.setError(false)
      facebookPixel.setStepValue(null)
    }

    track("Linked Ad Account", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
    })

    handleClose(true)
  }

  const handleCreateAdAccount = async () => {
    if (businessManager) {
      try {
        setLoadingConnection(true)
        const newAdAccount = await createNewAdAccount(businessManager)
        setSelectedAdAccount(newAdAccount as unknown as FacebookAdAccountAccess)
        setErrorCreatingAdAccount(false)

        if (currentBrand && selectedAdAccount) {
          await updateInBrand(currentBrand?.id, currentBrand?.slug as string, newAdAccount)

          facebookPixel.setError(false)
          facebookPixel.setStepValue(null)
        }

        handleClose(true)
      } catch (error: any) {
        if (error.error_user_msg) {
          setErrorMessage(error.error_user_msg)
        }
        setErrorCreatingAdAccount(true)
        setLoadingConnection(false)
      }
    }
  }

  const noPagesErrorAction = () => {
    window.open('https://www.facebook.com/help/104002523024878/?helpref=uf_share', '_blank', 'noopener noreferrer')
  }

  const reRequestPermissions = async () => {
    const authResponse = await loginWithFB('rerequest' as any)
    if (authResponse) {
      setErrorState(ErrorStatesStatuses.NONE)
      getAdAccounts()
    }
  }

  const handleGetBusinessManagers = () => {
    setLoadingAccounts(true)
    getBusinessManagers().then((businessManagers) => {
      setBusinessAccounts(businessManagers)
      setLoadingAccounts(false)
      setIsCreatingAdAccount(true)
      setListToFilter(businessManagers)
    })
  }

  const handleGoBack = () => {
    setIsCreatingAdAccount(false)
    setListToFilter(adAccounts)
  }

  const handleClose = (shouldReloadBrand?: boolean) => {
    setErrorCreatingAdAccount(false)
    setLoadingAccounts(true)
    setLoadingConnection(false)
    setIsCreatingAdAccount(false)
    closeModal(shouldReloadBrand)
  }

  return (
    <DialogPaper open={isOpen} onClose={handleClose}>
      <Fragment>
        <SelectAdAccountHeader
          currentBrandName={currentBrand?.name as string}
          isCreatingAdAccount={isCreatingAdAccount}
          closeModal={handleClose}
          handleGoBack={handleGoBack}
        />
        <StyledSection>
          {isCreatingAdAccount && <BusinessManagerWarningMessage />}
          {((errorState === ErrorStatesStatuses.NONE && adAccountsToDisplay.length > 2) || searchQuery) && (
            <SearchInput
              label={isCreatingAdAccount ? 'Search Business Managers' : 'Search Ad Accounts'}
              showPlaceholder
              value={searchQuery}
              onChange={(ev) => handleOnChangeSearch(ev.target.value)}
            />
          )}
          {loadingAccounts && (
            <CenterContentDiv>
              <LoadingLottie black />
            </CenterContentDiv>
          )}
          {errorState === ErrorStatesStatuses.PERMISSIONS_ERROR && (
            <ErrorState
              description="We can't load your Facebook Ad Accounts because we don't
                have permission. Click the button below to authorize access to your ad account."
              callToActionText="Authorize access"
              callToAction={reRequestPermissions}
            />
          )}
          {errorState === ErrorStatesStatuses.NO_PAGES_ERROR && (
            <ErrorState
              description="It looks like you don't have any Facebook ad accounts associated with your
                account. Please create a new Facebook ad account by following the instructions at the link."
              callToActionText="Create a new ad account"
              callToAction={noPagesErrorAction}
            />
          )}
          {searchQuery && !adAccountsToDisplay.length && (
            <CenterContentDiv>
              <NoAccountsDiv>
                <Body2>
                  We can't find an Ad Account containing "{searchQuery}". Please try another search and make sure you
                  have access to your ad account.
                </Body2>
              </NoAccountsDiv>
            </CenterContentDiv>
          )}
          {errorState === ErrorStatesStatuses.NONE && adAccountsToDisplay.length > 0 && !loadingAccounts && (
            <AccountsList
              adAccounts={adAccountsToDisplay}
              businessAccounts={businessAccountsToDisplay}
              businessManager={businessManager as FacebookBusinessManager}
              selectedAdAccount={selectedAdAccount as FacebookAdAccountAccess}
              isCreatingAdAccount={isCreatingAdAccount}
              setSelectedAdAccount={setSelectedAdAccount}
              setBusinessManager={setBusinessManager}
              onClickCreateAdAccount={handleGetBusinessManagers}
            />
          )}
        </StyledSection>
        {errorCreatingAdAccount && (
          <ErrorBannerContainer>
            <Body2>
              {errorMessage || "It looks like there was an error creating your ad account. Please try selecting another business or try again."}
            </Body2>
          </ErrorBannerContainer>
        )}
        {errorState === ErrorStatesStatuses.NONE && (
          <PrimaryButton
            text="Connect"
            width="100%"
            loading={loadingConnection}
            onClick={isCreatingAdAccount ? handleCreateAdAccount : handleOnClickConnect}
            disabled={(searchQuery && !adAccountsToDisplay.length) || allAdAccountsDisabled}
          />
        )}
      </Fragment>
    </DialogPaper>
  )
}
