import { FunctionComponent } from "react"
import {
  OutlinedButton,
  StyledLabel,
  ErrorBannerMainContainer,
} from "../../style"

interface Props {
  errorText: string
  showActionButton?: boolean
  buttonLabel?: string
  onClickActionButton?: () => void
}

const ErrorBanner: FunctionComponent<Props> = ({
  errorText,
  buttonLabel,
  showActionButton,
  onClickActionButton,
}: Props) => (
  <ErrorBannerMainContainer>
    <StyledLabel fontSize={16} fontWeight={500}>
      {errorText}
    </StyledLabel>
    {showActionButton && (
      <OutlinedButton onClick={onClickActionButton}>
        {buttonLabel}
      </OutlinedButton>
    )}
  </ErrorBannerMainContainer>
)

export default ErrorBanner
