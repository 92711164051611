import styled, { keyframes } from 'styled-components';
import { MEDIA_BREAKPOINTS } from 'types/global';

interface MainContainerProps {
  padding?: string;
  borderRadius?: string;
}

export const fade = keyframes`
  from {
    width: 0;
    border-radius: 0;
    opacity: 0.05;
  }
  to {
    width: 100%;
    border-top-left-radius: 3px 40%;
    border-bottom-left-radius: 3px 40%;
    opacity: 0.2;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  ${MEDIA_BREAKPOINTS.mobileView} {
    width: calc(100% - 54px);
  };
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: #ffffff;
  padding: ${({ padding }: MainContainerProps) => padding};
  border-radius: ${({ borderRadius }: MainContainerProps) => borderRadius};
`;

export const MiniatureContainer = styled.div`
  padding: 22px 0px;
  width: fit-content;
`;

export const TextLinkPresave = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  max-height: 100%;
  width: 100%;
`;

export const PresaveLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  width: 100%;
  height: fit-content;
  background: #edecf2;
  border-radius: 8px;
  position: relative;
`;

export const FadeContainer = styled.div`
  animation: ${fade} 0.4s backwards;
  position: absolute;
  background: #8800ff;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
`;

export const Title = styled.div`
  font-family: DIN;
  font-size: 18px;
  font-weight: 600;
`;

export const MobileButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 16px;
`;
