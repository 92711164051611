import React, { useEffect, useState } from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      barepay: any; // specify the correct type here
    }
  }

  interface Window {
    barepay?: any;
  }
}

const BaremetricsCreditCardRecoveryForm: React.FC<{ cid?: string }> = ({ cid }) => {
  useEffect(() => {
    if (cid) {
      // Check if the script is already loaded
      if (!window.barepay || !window.barepay.created) {
        console.log("Initializing Baremetrics Barepay widget");

        // Initialize the barepay window object with params
        window.barepay = window.barepay || { created: false };
        window.barepay.created = true;
        window.barepay.params = {
          access_token_id: "bf44fd80-8b90-4b4d-a73a-bdfad253a058", // Your Recover API public key (obfuscated)
          customer_oid: cid // Customer ID whose card you're looking to update
        };

        const script = document.createElement('script');
        script.src = "https://baremetrics-dunning.baremetrics.com/js/application.js";
        script.async = true;

        script.onload = () => {
          // console.log("Script loaded");

          // const interval = setInterval(() => {
          //     const barepayElement = document.getElementById('barepay');
          //     if (barepayElement && window.barepay?.load) {
          //         clearInterval(interval);
          //         window.barepay.load();
          //         console.log("Barepay loaded");
          //     }
          // }, 100); // Check every 100ms

          // // Timeout after a reasonable period to avoid infinite loop
          // setTimeout(() => clearInterval(interval), 10000); // 10 seconds timeout
        };

        document.body.appendChild(script);
      }
    }
  }, [cid]);

  return <div id="barepay"></div>;
};


export function BarepayComponent({
  customerId
}: {
  customerId?: string | undefined
}) {
  const [loaded, setLoaded] = useState(false);

  const scriptUrl = "https://baremetrics-dunning.baremetrics.com/js/application.js";
  let script: any = document.querySelector(`script[src="${scriptUrl}"]`)

  useEffect(() => {
    if (customerId && (!script || !loaded)) {
      setLoaded(true);

      console.debug("Loading BarePay...");
      window.barepay = window.barepay || {};
      window.barepay.params = {
        ...window.barepay.params,
        access_token_id: "bf44fd80-8b90-4b4d-a73a-bdfad253a058", // Your Recover API public key
        customer_oid: customerId, // Customer ID whose card you're looking to update
        display: "banner"
      };

      script = document.createElement("script");
      script.src = scriptUrl;
      script.async = true;
      script.onload = () => { setLoaded(true) };

      document.body.append(script);


    }


    // if ((window as any).barepay && (window as any).barepay.created) {
    //   if (window.console && console.error) {
    //     console.error("Barepay snippet included twice.");
    //   }
    // } else {
    //   (window as any).barepay = { created: true };
    //   const script = document.createElement('script');
    //   script.src = "https://baremetrics-dunning.baremetrics.com/js/application.js";
    //   script.async = true;
    //   const firstScript = document.getElementsByTagName('script')[0];
    //   firstScript.parentNode?.insertBefore(script, firstScript);


    // }
  }, [customerId]);

  return (
    <barepay />
  );
};



export default BaremetricsCreditCardRecoveryForm;
