import { StyledChevronIcon, StyledDiv, StyledWarningIcon } from './styles'
import { SystemColors } from 'types/globalStyles'
import { Subtitle2 } from 'components/shareable/Typography'
import { useState } from 'react'

interface IssuesCountChipProps {
  issuesCount: number
}

export function IssuesCountSummary({ issuesCount }: IssuesCountChipProps) {
  const [isDisclosureOpen, setIsDisclosureOpen] = useState<boolean>(true)

  const handleOnClickSummary = () => {
    setIsDisclosureOpen((prevValue) => !prevValue)
  }

  return (
    <summary className="flex justify-between cursor-pointer mb-2" onClick={handleOnClickSummary}>
      <StyledDiv>
        <div>
          <StyledWarningIcon />
        </div>
        <Subtitle2 color={SystemColors.WARNING_COLOR}>
          {issuesCount} {issuesCount === 1 ? 'issue' : 'issues'} detected
        </Subtitle2>
      </StyledDiv>
      <StyledChevronIcon isFlipped={isDisclosureOpen} />
    </summary>
  )
}
