import {
  FunctionComponent,
  Fragment,
} from "react";
import CustomTable from '../CustomTable';
import EmptyFanbase from "../EmptyFanbase";
import { FanbaseContainer } from './styles';

interface Props {
  brandId: number;
  loading: boolean,
  hasFans: boolean,
  isProUser: boolean,
  openFanDetailModal: (fan: any) => void,
}

const Fanbase: FunctionComponent<Props> = ({
  brandId,
  loading,
  hasFans,
  isProUser,
  openFanDetailModal,
}: Props) => {
  if (loading) return <Fragment />
  return (
    <FanbaseContainer
      justifyContent={hasFans ? "flex-start" : "center"}
    >
      {hasFans ?
        <CustomTable
          brandId={brandId}
          openFanDetailModal={openFanDetailModal}
          isProUser={isProUser}
      />
        :
        <EmptyFanbase />
      }
    </FanbaseContainer>
  );
};

export default Fanbase;
