import LinkCopyShareComponent from "components/shareable/CopyableLink";
import { Subtitle1, Subtitle2 } from "components/shareable/Typography";
import PLATFORMS_BADGE_ICONS from "pages/post-auth/MarketingPage/Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import BadgeContainer from "pages/post-auth/MarketingPage/Components/BadgeCampaignTypeContainer/BadgeContainer";
import { Link } from "react-router-dom";
import { Container, StyledLabel, TextButton } from "styles/shared";

interface CardProps {
    title?: string;
    description?: string;
    preset: "video" | "copy" | "custom" | "action";
    icon: string;
    iconBg?: string;
    fullSizeIcon?: boolean;
    onClick?: () => void;
    copyDetails?: {
        copyUrl?: string;
    };
    videoDetails?: {
        videoUrl?: string;
        cardImage?: string;
    };
    badge?: 'Instagram Ads' | 'Website' | 'Pre-save Website' | 'Data Collector Website';


}

export default function Card({
    title,
    description,
    preset,
    onClick,
    icon,
    iconBg,
    videoDetails,
    copyDetails,
    fullSizeIcon,
    badge
}: CardProps) {



    function renderMainUi() {

        let badgeImage: string[] = [];

        if (badge) {
            switch (badge) {
                case 'Instagram Ads':
                    badgeImage = [PLATFORMS_BADGE_ICONS["instagram"]];

                    break;
                case 'Website':
                    badgeImage = [PLATFORMS_BADGE_ICONS["link_clicks"]];
                    break;
                case 'Pre-save Website':
                    badgeImage = ["spotify", "apple_music", "deezer"].map((platform) => PLATFORMS_BADGE_ICONS[platform]);

                    break;
                case 'Data Collector Website':
                    badgeImage = [PLATFORMS_BADGE_ICONS["link_clicks"]];
                    break;

            }
        }


        if (preset === "custom" || preset === "action") {
            return (
                <>
                    <Subtitle2>{title || 'Welcome to SymphonyOS!'}</Subtitle2>
                    {badge && (<BadgeContainer text={badge || 'Instagram Ads'} imageUrls={badgeImage} />)}
                    <StyledLabel fontSize={14}>{description || 'Recommended for you'}</StyledLabel>
                    <Container marginTop="auto">

                        {onClick && (<TextButton onClick={onClick}>Start &gt;</TextButton>)}
                    </Container>
                </>
            )
        } else if (preset === "video") {
            return (
                <>
                    <Subtitle2>{title || 'Welcome to SymphonyOS!'}</Subtitle2>
                    {badge && (<BadgeContainer text={badge || 'Instagram Ads'} imageUrls={badgeImage} />)}

                    <StyledLabel fontSize={14}>{description || 'Recommended for you'}</StyledLabel>
                    <Container marginTop="auto">

                        {onClick && <TextButton onClick={onClick}>Watch Now &gt;</TextButton>}
                    </Container>
                </>
            )

        } else if (preset === "copy") {
            return (
                <>
                    <Subtitle2>{title || 'Welcome to SymphonyOS!'}</Subtitle2>
                    {badge && (<BadgeContainer text={badge || 'Instagram Ads'} imageUrls={badgeImage} />)}

                    <StyledLabel fontSize={14}>{description || 'Recommended for you'}</StyledLabel>
                    {/* <LinkCopyShareComponent link={copyDetails?.copyUrl!}
                        variant="small" /> */}

                    {/* {url && <Link to={url}><TextButton >Start &gt;</TextButton></Link>} */}
                    <Container marginTop="auto">
                        {onClick && <TextButton onClick={onClick}>Learn More &gt;</TextButton>}
                    </Container>
                </>
            )
        }
    }
    return (
        <Container backgroundColor="#ffffff" borderRadius="16px"
            padding="18px"
            display="flex"
            flexDirection="row"
            width={"auto"}
            flex={1}
            height="100%"
            gap="8px"
            style={{
                flexShrink: 0
            }}>
            <Container height="auto"
                width="50px"
                backgroundColor={"#f6ecff"}
                display="flex"
                flexDirection="column"
                borderRadius="7px"
                alignItems="center"
                justifyContent="center"
                overflow="hidden"
                style={{
                    flexShrink: 0
                }}>
                <img src={icon || "https://via.placeholder.com/50"} alt="placeholder"
                    style={{
                        width: fullSizeIcon ? '100%' : '40px',
                        height: fullSizeIcon ? '100%' : 'auto',
                        objectFit: 'cover'
                    }} />
            </Container>
            <Container display="flex" flexDirection="column" alignItems="flex-start"
                width="100%"
                gap="4px">
                {renderMainUi()}
            </Container>
        </Container >
    );
}