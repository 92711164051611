import { Grid } from "@material-ui/core"
import { Fragment, FunctionComponent, useState } from "react"
import {
  DateInputContainer,
} from "./style"
import { ReactComponent as CalendarIcon } from "assets/images/calendar-email-campaign.svg"
import HeaderModal from "../../HeaderModal"
import DatePicker from "react-datepicker"
import moment from "moment"
import { WarningCodesEditBudget } from "../../LowDailySpendContainer/constants"
import LowDailySpendContainer from "../../LowDailySpendContainer"
import Axios from "helpers/Interceptor"
import some from "lodash/some"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading_White.json"
import Lottie from "react-lottie"
import FB from "helpers/FB"
import * as Sentry from "@sentry/react";
import DialogPaper from 'components/shareable/DialogPaper'
import { showToast } from 'utils'
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'
import { track } from "analytics"
import { getTotalRemaining } from "helpers/CampaignDetails"
import { ButtonCancel, ButtonConfirm, ButtonsContainer } from "../../../../../../components/shareable/Modal/styles"
import { CurrencyCodesType } from "types/global"

const UpdatingCampaignLabel: FunctionComponent = () => (
  <Grid container alignItems="center" alignContent="center">
    <Grid item xs={2}>
      <Lottie
        height={16}
        width={16}
        options={{
          loop: true,
          autoplay: true,
          animationData: SymphonyLoadingLottie,
        }}
      />
    </Grid>
    <Grid item xs>
      <p>Updating campaign...</p>
    </Grid>
  </Grid>
)

interface Props {
  totalSpent?: string | number
  open: boolean
  campaignId: number
  endDate: string
  startDate: string
  budget: number
  minimalAmountPerDay: number
  currency?: CurrencyCodesType
  trackObject?: Record<string, any>
  onErrorHandler?: () => void;
  closeModal: () => void
  reload: (endDate: string, budget: number) => void
}

const EditEndDateModal: FunctionComponent<Props> = ({
  totalSpent,
  open,
  campaignId,
  endDate,
  startDate,
  budget,
  minimalAmountPerDay,
  currency,
  trackObject,
  reload,
  closeModal,
  onErrorHandler,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(false)
  const [newEndDate, setNewEndDate] = useState<string>(endDate)
  const [warningError, setWarningError] =
    useState<WarningCodesEditBudget | null>(null)
  const [newDailySpend, setNewDailySpend] = useState<number>(0)
  const [minimalBudget, setMinimalBudget] = useState<number>(0)
  const spent = typeof totalSpent === 'string' ? Number(totalSpent) : totalSpent || 0
  const remainingBudget = Number(getTotalRemaining(budget, (totalSpent || '0') as string))
  const { mobileView } = useMediaBreakpoints();

  const dateValidator = (newDate: Date) => {
    if (moment(newDate).isSame(endDate, "day")) {
      setIsValidEndDate(false)
      setWarningError(null)
      return
    }
    const campaignStartDate = moment(startDate).isAfter() ? moment(startDate) : moment() 
    const remainingDays = Math.abs(campaignStartDate.diff(newDate, "days"))
    const costPerDay = remainingBudget / remainingDays

    if (costPerDay < minimalAmountPerDay) {
      setIsValidEndDate(false)
      setWarningError(WarningCodesEditBudget.MANY_DAYS)
      const minimalBudget = minimalAmountPerDay * remainingDays + spent
      setMinimalBudget(minimalBudget)
      return
    }
    setIsValidEndDate(true)
    setNewDailySpend(costPerDay)
    if (moment(newDate).isAfter(endDate)) {
      setWarningError(WarningCodesEditBudget.DECREASE_DAILY)
    } else {
      setWarningError(WarningCodesEditBudget.INCREASE_DAILY)
    }
  }

  const handleEditEndDate = async () => {
    setLoading(true)
    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) {}

    try {
      const response = await Axios.put(`/campaign/${campaignId}/endDate`, {
        access_token,
        endDate: moment(newEndDate).toLocaleString(),
      })
      if (
        some([
          !response,
          !response.data,
          !response.data.data,
          response.data.isError,
        ])
      ) {
        throw new Error("Error editing end date on campaign")
      }

      reload(newEndDate, budget)
      showToast({ message: 'End date updated!', mobile: mobileView })
      track('Saved Edit End Date', {
        ...trackObject,
        opened: 'Edit End Date Modal',
      })
    } catch (e) {
      Sentry.captureException(`Error editing end date in campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const handleEditEndDateAndBudget = async () => {
    setLoading(true)
    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) {}

    try {
      const response = await Axios.put(
        `/campaign/${campaignId}/budget-endDate`,
        {
          access_token,
          endDate: newEndDate,
          budget: minimalBudget,
        }
      )
      if (
        some([
          !response,
          !response.data,
          !response.data.data,
          response.data.isError,
        ])
      ) {
        throw new Error("Error editing end date and budget on campaign")
      }
      reload(newEndDate, minimalBudget)
      showToast({ message: 'Success edit end date', mobile: mobileView })
    } catch (e) {
      Sentry.captureException(`Error editing end date and budget in campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const handleChangeDate = (date: Date) => {
    setIsValidEndDate(false)
    setWarningError(null)
    setNewEndDate(date.toISOString())
    dateValidator(date)
  }

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly />
  );

  return (
    <DialogPaper open={open}>
      <Fragment>
        <HeaderModal
          HeaderIcon={CalendarIcon}
          closeModal={closeModal}
          title='Edit end date'
          subtitle='After editing your campaign, it may go into review again while your changes are reflected.'
        />
        <DateInputContainer>
          <DatePicker
            withPortal
            className="p-3 rounded w-full"
            minDate={moment().add(1, "day").toDate()}
            selected={moment(newEndDate).toDate()}
            onChange={handleChangeDate}
            placeholderText="MMMM DD, YYYY"
            dateFormat="MMMM dd, yyyy"
            customInput={<DatepickerInput />}
          />
        </DateInputContainer>
        {warningError && (
          <LowDailySpendContainer
            currentBudget={budget}
            selectedBudget={remainingBudget}
            suggestedBudget={minimalBudget}
            dailySpend={newDailySpend}
            currency={currency}
            errorCode={warningError}
            date={new Date(newEndDate)}
            totalSpent={spent}
            currentEndDate={endDate}
            selectedEndDate={newEndDate}
          />
        )}
        <ButtonsContainer
          flexDirection={mobileView ? "column-reverse" : "row"}
        >
          <ButtonCancel
            width={mobileView ? "100%" : "fit-content"}
            disabled={loading}
            onClick={closeModal}
          >
            Cancel
          </ButtonCancel>
          {warningError !== WarningCodesEditBudget.MANY_DAYS ? (
            <ButtonConfirm
              width={mobileView ? "100%" : "50%"}
              disabled={loading || !isValidEndDate}
              onClick={handleEditEndDate}
            >
              {loading ? <UpdatingCampaignLabel /> : "Update campaign"}
            </ButtonConfirm>
          ) : (
            <ButtonConfirm
              width={mobileView ? "100%" : "fit-content"}
              disabled={loading}
              onClick={handleEditEndDateAndBudget}
            >
              {loading ? (
                <UpdatingCampaignLabel />
              ) : (
                "Update campaign"
              )}
              </ButtonConfirm>
          )}
        </ButtonsContainer>
      </Fragment>
    </DialogPaper>
  )
}

export default EditEndDateModal
