import { Chip, Dialog } from "@material-ui/core";
import { ModalContainer } from "pages/post-auth/AddArtist/style";
import { FunctionComponent, useContext, useState } from "react";
import HeaderModal from "../../Components/HeaderModal";
import { ReactComponent as Globe } from "assets/images/campaignDetails/globe.svg"
import { BodyContainer, ButtonContainer, ButtonLabel, ChipsContainer, NoResultLabel, OptionsContainer } from "./styles";
import { queryFB } from "helpers/FB";
import useStyles from "./styles";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { LocationDetail } from "../MarketsDetail";
import { FBLocation, LocationsResponse } from "../../SongAds/TargetingView/reducer";
import LocationsWarning from "./LocationsWarning";
import { validateLocations } from "../MarketsDetail/api";
import LocationCell from "./LocationCell";
import { CampaignMapping } from "types/global";
import InputWithDebounce from 'components/shareable/InputWithDebounce';
import { LoadingLottie } from 'components/Loader/LoadingLottie';

interface Validation {
  toDelete: LocationDetail[]
  toAdd: FBLocation[]
  alreadyAdded: (LocationDetail | FBLocation)[]
}

interface Props {
  open: boolean
  closeModal: () => void
  onAddLocations: (locations: FBLocation[]) => Promise<void>
  market?: CampaignMapping
  campaignId: number
  locations: LocationDetail[]
}

const AddLocationModal: FunctionComponent<Props> = ({
  open,
  closeModal,
  onAddLocations,
  market,
  campaignId,
  locations,
}: Props) => {
  const classes = useStyles()
  const [selectedLocations, setSelectedLocations] = useState<FBLocation[]>([])
  const [searchText, setSearchText] = useState<string>("")
  const [searchResults, setSearchResults] = useState<FBLocation[]>([])
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false)
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false)
  const [selectedToValidate, setSelectedToValidate] = useState<string>("")
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

  const [validation, setValidation] = useState<Validation>({} as Validation)
  const [showNoResults, setShowNoResults] = useState<boolean>(false)

  const { currentBrand } = useContext(CurrentBrandContext)

  const updateSearchResults = async (search: string) => {
    if (!search) {
      setLoadingSearch(false)
      setSearchResults([])
      return
    }
    const access_token = currentBrand?.connections?.logged_in_fb_user?.access_token;
    const url = `/search?limit=10&type=adgeolocation&location_types=["country","region","city"]&q=${search}`
    await queryFB(url, { method: 'GET', params: { access_token } })
      .then(({ data }: LocationsResponse) => {
        setSearchResults(data)
        setShowNoResults(data.length === 0)
        setLoadingSearch(false)
      })
      .catch(() => {
        setLoadingSearch(false)
      })
  }

  const onReplaceLocation = (newValue: FBLocation) => {
    setSelectedLocations(selectedLocations.concat([newValue]));
    setValidation({} as Validation);
  }

  const handleOnUnselectLocation = (filteredLocation: string) => {
    setSelectedLocations(prevState => prevState.filter(({ key }) => key !== filteredLocation))
  }

  const handleOnSelectLocation = async (newValue: FBLocation) => {
    setLoadingValidation(true)
    setSelectedToValidate(newValue.key)
    const newSelected = selectedLocations.concat([newValue])
    const newValidation = await validateLocations({
      marketId: market?.fbCampaign?.id,
      locations: newSelected,
      campaignId
    })
    if (newValidation) setValidation(newValidation)
    if (newValidation?.alreadyAdded.length <= 0 && newValidation?.toDelete.length <= 0) {
      setSelectedLocations(newSelected)
    }
    setSelectedToValidate("")
    setLoadingValidation(false)
  }

  const handleOnCloseModal = () => {
    setSelectedLocations([])
    setSearchResults([])
    setSearchText('')
    setValidation({} as Validation)
    closeModal()
  }

  const handleOnClickAddButton = async () => {
    setLoadingAdd(true)
    await onAddLocations(selectedLocations)
    setLoadingAdd(false)
    handleOnCloseModal()
  }

  const handleOnCancel = () => setValidation({} as Validation);

  const handleChangeText = (value: string) => {
    if (!value) {
      setLoadingSearch(false)
      setSearchResults([])
    } else {
      setSearchText(value);
      setLoadingSearch(true);
    }
  }

  return (
    <Dialog
      open={open}
      className={classes.dialog}
    >
      <ModalContainer
        width="100%"
        height="fit-content"
        gap="32px"
      >
        <HeaderModal
          closeModal={handleOnCloseModal}
          title="Add locations"
          subtitle={`Widen your reach by adding additional locations for your ads running in ${market?.name}`}
          HeaderIcon={Globe}
        />
        <BodyContainer>
          <InputWithDebounce
            onChange={handleChangeText}
            debounceHandler={updateSearchResults}
            value={searchText}
            placeholder="Search for cities, regions, and countries"
            loading={loadingSearch}
            debounceTime={300}
          />
          <ChipsContainer>
            {
              selectedLocations.map((location) =>
                <Chip
                  key={location.key}
                  id={location.name}
                  label={`${location.name} (${location.type})`}
                  onDelete={() => handleOnUnselectLocation(location.key)}
                />
              )
            }
          </ChipsContainer>
          {
            validation.toDelete?.length > 0 && validation.alreadyAdded.length <= 0 && (
              <LocationsWarning
                newLocation={validation.toAdd[validation.toAdd.length - 1]}
                replacedLocations={validation.toDelete}
                onCancel={handleOnCancel}
                onReplace={onReplaceLocation}
              />
            )
          }
          {
            showNoResults && (
              <NoResultLabel>No locations found for "{searchText}" try another search</NoResultLabel>
            )
          }
          <OptionsContainer>
            {
              searchResults.map((result) => (
                <LocationCell
                  key={result.key}
                  value={result}
                  loading={loadingValidation}
                  isSelected={result.key === selectedToValidate}
                  existingLocations={locations}
                  selectedLocations={selectedLocations}
                  handleOnSelectLocation={handleOnSelectLocation}
                />
              ))
            }
          </OptionsContainer>
        </BodyContainer>
        <ButtonContainer
          disabled={selectedLocations.length <= 0 || loadingValidation}
          onClick={handleOnClickAddButton}
        >
          {loadingAdd ?
            <LoadingLottie width={20} height={20} />
            : <ButtonLabel>Add location</ButtonLabel>
          }
        </ButtonContainer>
      </ModalContainer>
    </Dialog>
  );
}

export default AddLocationModal