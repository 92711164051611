import { FunctionComponent, Fragment } from "react";
import { Avatar } from "@material-ui/core";
import useStyles, {
  StyledLabel,
  Container,
  ContainerProps,
  GradientRoundedContainer,
  WhiteRoundedContainer,
  AvatarContainer,
  Icon,
  StyledLabelProps,
} from "./styles";
import GemIcon from "../ProCallouts/GemIcon";

interface CustomAvatarProps {
  avatar?: string;
  isProUser?: boolean;
  withoutWhiteBorder?: boolean;
}

const CustomAvatar: FunctionComponent<CustomAvatarProps> = ({
  avatar,
  isProUser,
  withoutWhiteBorder,
}: CustomAvatarProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      {isProUser && (
        <Fragment>
          {withoutWhiteBorder && (
            <GradientRoundedContainer width="37px" height="37px">
              <AvatarContainer top="2.3px">
                <Avatar src={avatar} className={classes.avatar} />
              </AvatarContainer>
            </GradientRoundedContainer>
          )}
          {!withoutWhiteBorder && (
            <GradientRoundedContainer width="42px" height="42px">
              <WhiteRoundedContainer>
                <AvatarContainer top="3px">
                  <Avatar src={avatar} className={classes.avatar} />
                </AvatarContainer>
              </WhiteRoundedContainer>
            </GradientRoundedContainer>
          )}
        </Fragment>
      )}
      {!isProUser && <Avatar src={avatar} className={classes.avatar} />}
    </Fragment>
  );
};

interface Props {
  brandName?: string | null;
  avatar?: string;
  showProIcon?: boolean;
  isProUser?: boolean;
  withoutWhiteBorder?: boolean;
  mainContainerProps?: ContainerProps;
  proStatusContainerProps?: ContainerProps;
  userNameTextProps?: StyledLabelProps;
  proTextProps?: StyledLabelProps;
  userContainerProps?: ContainerProps;
  avatarContainerProps?: ContainerProps;
  icon?: string;
  smallSidebar?: boolean;
}

export const AvatarUserStatus: FunctionComponent<Props> = ({
  brandName,
  avatar,
  showProIcon,
  isProUser,
  withoutWhiteBorder,
  mainContainerProps,
  proStatusContainerProps,
  userNameTextProps,
  proTextProps,
  icon,
  avatarContainerProps,
  userContainerProps,
  smallSidebar
}: Props) => (
  <Container {...mainContainerProps} display="flex" alignItems="center">
    <Container {...avatarContainerProps}>
      <CustomAvatar {...{ isProUser, avatar, withoutWhiteBorder }} />
    </Container>
    <Container
      {...userContainerProps}
      display={smallSidebar ? "none" : "flex"}
      flexDirection="column"
      alignItems="flex-start"
    >
      <StyledLabel {...userNameTextProps}>{brandName}</StyledLabel>
      {isProUser && (
        <Container {...proStatusContainerProps} display="flex">
          <GemIcon />
          <StyledLabel {...proTextProps}>PRO ACCOUNT</StyledLabel>
        </Container>
      )}
    </Container>
  </Container>
);
