import { YouTubeChannel } from "types/global"

export const getDescription = (description: string, isMobile: boolean) => {
  if (isMobile && description.length) return `${description.slice(0, 50)}...`
  if (description.length > 120) return `${description.slice(0, 120)}...`
  return description
}

export const getModalContainerHeight = (channels: number) => {
  if (channels > 2) return "300px"
  return "100%"
}

export const getHelperText = (args: {
  value: string
  error: boolean
  results: YouTubeChannel[]
  loading: boolean
  youtubeSearchString: string
}) => {
  const { value, error, results, loading, youtubeSearchString } = args

  if (error) return "An error occurred. Try again later."
  if (!results.length && value && !loading && value !== youtubeSearchString) {
    return "Results not found."
  }
  return undefined
}
