import { AutocompleteCloseReason } from "@material-ui/lab";
import { FbSuggestion } from "types/global";
import Axios from "helpers/Interceptor";
import { Dispatch, SetStateAction } from "react";
import uniqBy from "lodash/uniqBy";
import { Option as SpotifyArtistsOption } from "../AutoCompleteSpotifyArtists/utils";

export const EMPTY_STRING = "";

export type AutoCompleteReason = AutocompleteCloseReason | "remove-option";

export interface Response {
  data: { data: Option[]; message: string; isError: boolean };
}

export interface Option {
  id: string;
  name: string;
  followers: number | null;
  genres: string[] | null;
  thumbnail_url: string | null;
}

export interface DeleteIconProps {
  color?: string;
  onClick: () => void;
}

export interface AutoCompleteSpotifyArtistsProps {
  deletedAudience: { artistId: string | null; audiences: FbSuggestion[] };
  deleteIconColor?: string;
  setDeletedAudience: Dispatch<
    SetStateAction<{ artistId: string | null; audiences: FbSuggestion[] }>
  >;
  setSelectedSpotifyArtists?: Dispatch<SetStateAction<Option[]>>;
  setDeletedArtist: Dispatch<SetStateAction<Option[]>>;
  setArtists: Dispatch<SetStateAction<SpotifyArtistsOption[]>>;
}

export const getTextFieldPlaceholder = (selectedOptions: Option[]) => {
  if (Boolean(selectedOptions.length)) return undefined;
  return "Enter an artist’s name to search...";
};

export const getSpotifyArtists = async (args: {
  value: string;
  selectedOptions: Option[];
  setOptions: Dispatch<SetStateAction<Option[]>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { value, selectedOptions, setOptions, setOpen, setLoading } = args;

  setLoading(true);

  try {
    const url = "/spotify/search/artists";
    const options = { params: { q: value } };
    const response: Response = await Axios.get(url, options);
    const data = await response?.data?.data;

    if (data) {
      const groupedOptions = [...selectedOptions, ...data];
      const unique = uniqBy(groupedOptions, "id");

      setOptions(unique);
      setLoading(false);
      setOpen(true);
    }
  } catch (e: unknown) {
    console.error("Error on AutoCompleteSpotifyArtists component: ", e);
    setOptions([]);
    setLoading(false);
    setOpen(false);
  }
};

export const getNoOptionsText = (value: string) => {
  if (Boolean(value.trim().length)) return `No artists found for ${value}`;
  return `No artists found`;
};
