import { CSSProperties, Dispatch, Fragment, FunctionComponent, SetStateAction, useMemo, useState } from 'react';
import { ErrorBanner, LabelPlatform, PlatformContainer, PlatformItemContainer } from './style';
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { ReactComponent as SuperBoostsIcon } from 'assets/images/ui/superboost.svg';
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import AppleMusicIcon from 'assets/images/apple_music.svg';
import DeezerIcon from 'assets/images/streaming/icons/deezer-icon.svg';
import SpotifyIcon from "assets/images/streaming/square-icons/spotify-sqr.svg";
import AudiomackIcon from "assets/images/platforms/audiomack.svg";
import SoundcloudIcon from "assets/images/platforms/soundcloud.svg";

import PrimaryButton from 'components/shareable/PrimaryButton';
import DraggableList from '../DraggableList';
import { CampaignDetails } from 'types/global';
import Axios from 'helpers/Interceptor';
import { showToast } from 'utils';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import DialogPaper from 'components/shareable/DialogPaper';
import Logger from 'Logger';
import SwitchButton from 'components/shareable/SwitchButton';
import { track } from 'analytics';

interface Props {
  open: boolean;
  closeModal: () => void;
  onSave?: (campaign: CampaignDetails) => void;
  campaign: CampaignDetails
  trackObject?: Record<string, any>
}

interface DraggableOptionOptions {
  alt: string;
  src: string;
  styling?: CSSProperties;
  switchProps: {
    checked: boolean;
    disabled: boolean;
    onChange?: (checked: boolean) => void;
  };
  platformName: string;
}

interface PositionOption {
  name: string;
  checked: boolean;
}

const DraggableOption: FunctionComponent<DraggableOptionOptions> = ({
  alt,
  src,
  switchProps,
  platformName,
  styling,
}: DraggableOptionOptions) => {
  const { checked, disabled, onChange } = switchProps;

  return (
    <PlatformItemContainer style={styling}>
      <DragIcon />
      <PlatformContainer>
        <div>
          <img className="w-10 h-10" {...{ alt, src }} />
        </div>
        <LabelPlatform>Pre-save on {platformName}</LabelPlatform>
        <SwitchButton
          defaultChecked={checked}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
      </PlatformContainer>
    </PlatformItemContainer>
  );
};

const getDraggableOptions = (args: {
  isCheckedDeezerOption: boolean;
  isCheckedAppleMusicOption: boolean;
  isCheckedAudiomackOption: boolean;
  isCheckedSoundcloudOption: boolean; // Added isCheckedSoundcloudOption
  positionsOptions: {
    name: string;
    checked: boolean,
  }[];
  setCheckDeezerOption: Dispatch<SetStateAction<boolean>>;
  setCheckAppleMusicOption: Dispatch<SetStateAction<boolean>>;
  setCheckAudiomackOption: Dispatch<SetStateAction<boolean>>;
  setCheckSoundcloudOption: Dispatch<SetStateAction<boolean>>; // Added setCheckSoundcloudOption
  setPositionsOptions: Dispatch<SetStateAction<PositionOption[]>>;
}) => {
  const {
    isCheckedDeezerOption,
    isCheckedAudiomackOption,
    isCheckedAppleMusicOption,
    isCheckedSoundcloudOption, // Added isCheckedSoundcloudOption
    positionsOptions,
    setCheckDeezerOption,
    setCheckAppleMusicOption,
    setCheckAudiomackOption,
    setCheckSoundcloudOption, // Added setCheckSoundcloudOption
    setPositionsOptions,
  } = args;

  const handleChangeDeezerOption = (checked: boolean) => {
    setCheckDeezerOption(checked);
    setPositionsOptions((prev) =>
      prev.map((o) => {
        if (o.name === "deezer") {
          return { ...o, checked };
        }
        return o;
      })
    );
  };

  const handleChangeAudiomackOption = (checked: boolean) => {
    setCheckAudiomackOption(checked);
    setPositionsOptions((prev) =>
      prev.map((o) => {
        if (o.name === "audiomack") {
          return { ...o, checked };
        }
        return o;
      })
    );
  };

  const handleChangeAppleMusicOption = (checked: boolean) => {
    setCheckAppleMusicOption(checked);
    setPositionsOptions((prev) =>
      prev.map((o) => {
        if (o.name === "apple_music") {
          return { ...o, checked };
        }
        return o;
      })
    );
  };

  const handleChangeSoundcloudOption = (checked: boolean) => { // Added handleChangeSoundcloudOption
    setCheckSoundcloudOption(checked);
    setPositionsOptions((prev) =>
      prev.map((o) => {
        if (o.name === "soundcloud") { // Added "soundcloud"
          return { ...o, checked };
        }
        return o;
      })
    );
  };

  const draggableOptions = [
    {
      id: "spotify-draggable-option",
      props: {
        alt: "spotify-icon.svg",
        src: SpotifyIcon,
        name: "Spotify",
        checkboxProps: {
          checked: true,
          disabled: true,
        },
      },
    },
    {
      id: "deezer-draggable-option",
      props: {
        alt: "deezer-icon.svg",
        src: DeezerIcon,
        name: "Deezer",
        checkboxProps: {
          checked: isCheckedDeezerOption,
          disabled: false,
          onChange: handleChangeDeezerOption,
        },
      },
    },
    {
      id: "apple-music-draggable-option",
      props: {
        alt: "apple-music-icon.svg",
        src: AppleMusicIcon,
        name: "Apple Music",
        checkboxProps: {
          checked: isCheckedAppleMusicOption,
          disabled: false,
          onChange: handleChangeAppleMusicOption,
        },
      },
    },
    {
      id: "audiomack-draggable-option",
      props: {
        alt: "audiomack-icon.svg",
        src: AudiomackIcon,
        name: "Audiomack",
        checkboxProps: {
          checked: isCheckedAudiomackOption,
          disabled: false,
          onChange: handleChangeAudiomackOption,
        },
      },
    },
    {
      id: "soundcloud-draggable-option", // Added "soundcloud-draggable-option"
      props: {
        alt: "soundcloud-icon.svg", // Replace with the actual icon for Soundcloud
        src: SoundcloudIcon, // Replace with the actual icon for Soundcloud
        name: "SoundCloud", // Replace with the actual name for Soundcloud
        checkboxProps: {
          checked: isCheckedSoundcloudOption,
          disabled: false,
          onChange: handleChangeSoundcloudOption,
        },
      },
    },
  ].map(({ id, props }) => ({
    id,
    component: <DraggableOption
      alt={props.alt}
      src={props.src}
      platformName={props.name}
      styling={{ gap: '8px' }}
      switchProps={{
        checked: props.checkboxProps.checked,
        disabled: props.checkboxProps.disabled,
        onChange: props.checkboxProps.onChange,
      }}
    />,
  }));

  const order = positionsOptions.map((o) => {
    switch (o.name) {
      case "spotify":
        return "spotify-draggable-option";
      case "apple_music":
        return "apple-music-draggable-option";
      case 'audiomack':
        return "audiomack-draggable-option";
      case "deezer":
        return "deezer-draggable-option";
      case "soundcloud": // Added "soundcloud"
        return "soundcloud-draggable-option";
      default:
        return o.name;
    }
  });

  const orderedDraggableOptions = draggableOptions.sort(
    (a, b) => order.indexOf(a.id) - order.indexOf(b.id)
  );

  return orderedDraggableOptions;
};

const getInitialPlatformsState = (platforms: string[]) => {
  const activePlatforms = platforms.map((platformId) => {
    return {
      name: platformId,
      checked: true
    }
  })
  const allPlatforms = ["spotify", "deezer", "audiomack", "apple_music", "soundcloud"] // Added "soundcloud"
  const inactivePlatforms = allPlatforms.filter((platform) => !platforms.includes(platform))
  activePlatforms.push(...inactivePlatforms.map((platformId) => {
    return {
      name: platformId,
      checked: false
    }
  }
  ))
  return activePlatforms;
}

const EditPlatformsModal: FunctionComponent<Props> = ({
  open,
  closeModal,
  onSave,
  campaign,
  trackObject = {},
}: Props) => {

  const { campaign_metadata: { presave } } = campaign;
  const platforms = presave?.platforms || [];

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isCheckedDeezerOption, setCheckDeezerOption] = useState<boolean>(platforms.includes("deezer"))
  const [isCheckedAppleMusicOption, setCheckAppleMusicOption] = useState<boolean>(platforms.includes("apple_music"))
  const [isCheckedAudiomackOption, setCheckAudiomackOption] = useState<boolean>(platforms.includes("audiomack"));
  const [isCheckedSoundcloudOption, setCheckSoundcloudOption] = useState<boolean>(platforms.includes("soundcloud")); // Added isCheckedSoundcloudOption

  const [positionsOptions, setPositionsOptions] = useState(getInitialPlatformsState(platforms))

  const { mobileView } = useMediaBreakpoints();

  const getMappedPlatforms = (platforms: string[]) => {
    return platforms.map((platformId) => {
      if (platformId.includes("deezer")) {
        return { name: "deezer", checked: isCheckedDeezerOption };
      }

      if (platformId.includes("audiomack")) {
        return { name: "audiomack", checked: isCheckedAudiomackOption };
      }

      if (platformId.includes("apple-music")) {
        return {
          name: "apple_music",
          checked: isCheckedAppleMusicOption,
        };
      }

      if (platformId.includes("soundcloud")) { // Added "soundcloud"
        return {
          name: "soundcloud",
          checked: isCheckedSoundcloudOption,
        };
      }

      return { name: "spotify", checked: true };
    });
  }

  const handleOnDragEnd = (items: { id: string; component: JSX.Element }[]) => {
    const platformsIds = items.map((item) => item.id);
    const options = getMappedPlatforms(platformsIds);
    setPositionsOptions(options);
  };

  const draggableOptions = useMemo(
    () =>
      getDraggableOptions({
        isCheckedAudiomackOption,
        isCheckedDeezerOption,
        isCheckedAppleMusicOption,
        isCheckedSoundcloudOption, // Added isCheckedSoundcloudOption
        positionsOptions,
        setCheckDeezerOption,
        setCheckAppleMusicOption,
        setCheckAudiomackOption,
        setCheckSoundcloudOption, // Added setCheckSoundcloudOption
        setPositionsOptions,
      }),
    [isCheckedAppleMusicOption, isCheckedDeezerOption, positionsOptions]
  );

  const onCancelEditing = () => {
    closeModal()
    setError(false)
    const options = getInitialPlatformsState(platforms)
    setCheckAppleMusicOption(platforms.includes("apple_music"))
    setCheckDeezerOption(platforms.includes("deezer"))
    setCheckAudiomackOption(platforms.includes("audiomack"))
    setCheckSoundcloudOption(platforms.includes("soundcloud")); // Added setCheckSoundcloudOption
    setPositionsOptions(options);
    track("Cancelled Edit Platforms", {
      ...trackObject,
      opened: 'Edit Platforms Modal'
    })
  }

  const onClickSave = async () => {
    setLoading(true)
    try {
      const checkedPlatforms = positionsOptions.filter((platform) => platform.checked).map((platform) => platform.name);
      const response = await Axios.put(`/campaign/presave/${campaign.id}/platforms`, {
        platforms: checkedPlatforms
      })
      if (!response?.data || response.data.isError) {
        throw new Error(response.data.message)
      }
      const updatedCampaign = response.data.data as CampaignDetails;
      onSave?.(updatedCampaign)
      showToast({ message: "Platforms updated successfully", mobile: mobileView })
      track("Saved Edit Platforms", {
        ...trackObject,
        opened: 'Edit Platforms Modal'
      })
      closeModal()
    } catch (error) {
      Logger.error(error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <DialogPaper open={open}>
      <Fragment>
        <HeaderModal
          HeaderIcon={SuperBoostsIcon}
          closeModal={onCancelEditing}
          title="Edit Platforms"
          subtitle="Customize which platforms your fans can pre-save from."
        />
        <DraggableList
          {...{ handleOnDragEnd }}
          options={draggableOptions}
          gap="16px"
        />
        {error && (
          <ErrorBanner>
            Something went wrong when trying to edit your platforms, check your
            connection and try again.
          </ErrorBanner>
        )}
        <div className={error ? "" : "-mt-4"}>
          <PrimaryButton
            text="Save"
            onClick={onClickSave}
            loading={loading}
            disabled={loading}
            width="100%"
          />
        </div>
      </Fragment>
    </DialogPaper>
  );
}

export default EditPlatformsModal;
