import { SystemColors } from 'types/globalStyles'
import { Description } from './Description'
import {
  ColumnContainer,
  MainCard,
  TadaIconBackground,
  ValidationMessage,
  useStyles,
  SuccessfulContainer,
} from './styles'
import { ReactComponent as TadaIcon } from 'assets/images/tada.svg'

interface ConversionsSetupDetailsProps {
  isSetupComplete: boolean
  isSetupValidated: boolean
  isSetupError: boolean
}

export function ConversionsSetupDetails({
  isSetupComplete,
  isSetupValidated,
  isSetupError,
}: ConversionsSetupDetailsProps) {
  const getStatusComponent = () => {
    if (isSetupError) {
      return <SetupErrorStatus />
    }
    if (isSetupComplete && isSetupValidated) {
      return <SuccessfulConversionsSetup />
    }
    return <SetupDefaultStatus />
  }

  return getStatusComponent()
}

function SuccessfulConversionsSetup() {
  const iconClass = useStyles()
  return (
    <MainCard>
      <SuccessfulContainer>
        <TadaIconBackground>
          <TadaIcon className={iconClass.tadaIconStroke} />
        </TadaIconBackground>
        <ColumnContainer>
          <Description
            title="You've set up your conversion API"
            details="You can now run campaigns with better results"
          />
        </ColumnContainer>
      </SuccessfulContainer>
    </MainCard>
  )
}

function SetupErrorStatus() {
  return (
    <ValidationMessage>
      <Description
        title="Something went wrong with your setup"
        details="Please reconnect your account again to setup Conversions API."
        detailsColor={SystemColors.PRIMARY_TEXT_COLOR}
      />
    </ValidationMessage>
  )
}

function SetupDefaultStatus() {
  return (
    <MainCard>
      <Description
        title="Get 4-6x better results with Symphony Conversions"
        details="Symphony's marketing AI connects to Facebook's Conversions API, an advanced ads targeting tool, to hyper-optimize your ad campaigns,
        leading to 4-6x better results than traditional setups. Once setup, you can use Conversions anywhere."
      />
    </MainCard>
  )
}
