import styled from 'styled-components';

export interface SocialIconProps {
  rounded?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  iconColor?: string;
  width?: string;
  height?: string;
  cursor?: string;
  onClick?: () => void;
}

export const IconHolder = styled.div<SocialIconProps>`
    background: ${(props) => props.backgroundColor || 'none'};
    box-sizing: ${(props) => (props.rounded ? 'border-box' : 'none')};
    border-radius: ${(props) => (props.rounded ? '99px' : '0px')};
    border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
    cursor: ${(props) => (props.cursor ?? 'auto')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

