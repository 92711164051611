import { FunctionComponent } from "react";
import { ReactComponent as LightningIcon } from "assets/images/lightning.svg";
import {
  MainContainer,
  IconContainer,
  RoundContainer,
  StyledLabel,
} from "./styles";

interface Props {
  label: string;
  labelStyles?: {
    paddingLeft?: number;
  };
  icon?: JSX.Element;
}

const SuccessInfoLabel: FunctionComponent<Props> = ({
  label,
  labelStyles,
  icon,
}: Props) => (
  <MainContainer>
    <IconContainer>
      {!!icon && icon}
      {!icon && (
        <RoundContainer>
          <LightningIcon viewBox="-20 -12 100 100" fill="#07C806" />
        </RoundContainer>
      )}
    </IconContainer>
    <StyledLabel paddingLeft={labelStyles?.paddingLeft}>{label}</StyledLabel>
  </MainContainer>
);

export default SuccessInfoLabel;
