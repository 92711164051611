import styled from "styled-components"

interface InfoBannerProps {
  minWidth?: string
  maxWidth?: string
  marginLeft?: string
  width?: string
}

export const InfoBanner = styled.div`
  width: ${({ width }: InfoBannerProps) => width || "244px"};
  margin-left: ${({ marginLeft }: InfoBannerProps) => marginLeft};
  max-width: ${({ maxWidth }: InfoBannerProps) => maxWidth};
  min-width: ${({ minWidth }: InfoBannerProps) => minWidth};
  display: flex;
  align-items: center;
  padding: 10px 6px;
  background-color: rgba(136, 0, 255, 0.08);
  height: 32px;
  border-radius: 4px;
`
