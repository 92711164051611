import * as Sentry from "@sentry/react"
import Axios from "helpers/Interceptor"
import axios from "axios"
import { getSpotifyArtistIdFromUrl } from "helpers/Spotify"

export const getSpotifyToken = async () => {
  try {
    const response = await Axios.get("/token/spotify")

    return { data: response.data.data, error: null }
  } catch (error) {
    Sentry.captureException(error)
    return { data: null, error }
  }
}

export const getSpotifySearchResults = async (
  token: string | null,
  inputValue: string
) => {
  try {
    const spotifyHeaders = {
      Authorization: "Bearer " + token,
    }

    // if the search string includes spotify URL, then extract ID
    const spotifyArtistIdMatch = getSpotifyArtistIdFromUrl(inputValue)

    if (spotifyArtistIdMatch.valid && spotifyArtistIdMatch.artistId) {
      const artistId = spotifyArtistIdMatch.artistId
      const results = await axios.get(
        `https://api.spotify.com/v1/artists/${artistId}`,
        { headers: spotifyHeaders }
      )
      if (results && results.data) {
        return { data: [results.data], error: null }
      }


      return { data: null, error: "Results not found" }
    } else {
      const results = await axios.get(
        `https://api.spotify.com/v1/search?q=${inputValue}&type=artist&limit=30`,
        { headers: spotifyHeaders }
      )

      return { data: results.data.artists.items, error: null }
    }
  } catch (error) {
    console.log("error", error)
    Sentry.captureException(error)
    getSpotifyToken()
    return { data: null, error }
  }
}
