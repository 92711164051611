import { FunctionComponent } from "react";
import { EmptyFanbaseContainer, EmptyFanbaseHeader, EmptyFanbaseLabel } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const EmptyFanbase: FunctionComponent = () => {

  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  return (
    <EmptyFanbaseContainer
      gap="0px"
      width={setResponsiveView ? "95%" : "70%"}
    >
      <EmptyFanbaseHeader>
        Create a pre-save campaign to start building your fans list.

      </EmptyFanbaseHeader>
      <EmptyFanbaseLabel>
      </EmptyFanbaseLabel>
    </EmptyFanbaseContainer>
  );
}

export default EmptyFanbase