export const VideoCampaignTypes = [
  {
    id: "default",
    name: "⚡️ Recommended",
    text: "Run your video ads with Symphony's recommended approach.",
    enabled: false
  },
  {
    id: "views",
    name: "📈 Cheapest Views",
    text: "Run your video ads to drive as many views as possible on your video.",
    enabled: true,
    adFormat: "YouTube Pre-roll Ads (Skippable)"
  },
  {
    id: "engagement",
    name: "🗣 More Engagement",
    text: "Run your video ads focused on driving more engagement to your video, including likes, comments, and subscribers.",
    enabled: true,
    adFormat: "YouTube In-feed Discovery Ads"
  },
]

export const VideoGeographyTypes = [
  {
    id: "global",
    name: "🌐 Global Markets",
    text: "Run your video ads globally, everywhere YouTube is available.",
    subtitle: "This includes markets like India, Mexico, Brazil, etc. Average cost-per-view is 1 cent USD or less."
  },
  {
    id: "english",
    name: "🌎 English-speaking Markets",
    text: "Run your video ads only in countries where English is the primary language.",
    subtitle: "This includes markets like the US, Canada, UK, and Australia. Average cost-per-view is 4 - 6 cents USD."

  },
  

  {
    id: "custom",
    name: "📍 Custom",
    text: "Run your video ads in specific countries.",
    subtitle: null
  },
]

export function getCampaignTitleByCampaignType(campaignType: 'views' | 'engagement' | 'default') {
  switch(campaignType) {
    case "views":
      return "Cheapest Views"
    case "engagement":
      return "More Engagement"
    default:
      return "Recommended"
  }
}

export function getGeographiesTitleByGeographyType(geographyType: 'global' | 'english' | 'custom') {
  switch(geographyType) {
    case "global":
      return "Global Markets"
    case "english":
      return "English-speaking Markets"
    default:
      return "Custom"
  }
}

export const minimumDailyBudgetCalculator = (targetingType:string) => {
  if(targetingType === "engagement") {
    return 20 
  } else {
    return 10
  }
} 


export const REVIEW = "Review"
export const REVIEW_YOUR_CAMPAIGN = "Review your campaign"
export const NEXT = "Next"
