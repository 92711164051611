import React from 'react';
import { Container, StyledLabel } from 'styles/shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Description, Thumbnail, Title, VideoInfo, VideoItemContainer } from '../styles';
import { Video } from '..';
import { Navigation, Pagination } from 'swiper';
import styled from 'styled-components';



export const StyledSwiper = styled(Swiper)`
    .swiper-button-next, .swiper-button-prev {
        &::after {
            color: black;
        }
    }

    .swiper-pagination-bullets {
        transform: translateY(15px);  // Adjust this value as needed
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #8800ff !important;

    }
`;

// Function to extract video ID from YouTube URL
const extractVideoId = (url: string): string | null => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const result = url.match(regex);
    return result ? result[1] : null;
};


// Function to get the thumbnail URL
const getThumbnailUrl = (video: Video, resolution: string = 'maxresdefault'): string => {
    const videoId = extractVideoId(video.embedUrl);
    if (!videoId) {
        throw new Error('Invalid YouTube URL');
    }
    return `https://img.youtube.com/vi/${videoId}/${resolution}.jpg`;
};



interface VideosRowProps {
    title: string;
    imgSrc: string;
    videos: Video[];
    onVideoClick?: (video: Video) => void;
}

export const VideosRow: React.FC<VideosRowProps> = ({ title, imgSrc, videos, onVideoClick }) => (
    <div>

        <Container
            backgroundColor='white'
            borderRadius='8px'
            padding='1rem'>
            <Container flexDirection="row" display="flex" gap="8px" alignItems="center">
                <img className="w-6 h-6" src={imgSrc} alt={title} />
                <StyledLabel fontSize={24}
                    fontWeight={600}>
                    {title}
                </StyledLabel>
            </Container>
            <StyledSwiper
                slidesPerView={window.innerWidth < 768 ? 1 :
                    window.innerWidth < 1000 ? 2 :
                    window.innerWidth < 1600 ? 3 : 4}  // Adjust slides per view based on screen width
                spaceBetween={window.innerWidth < 1200 ? 20 :
                    window.innerWidth < 1600 ? 50 : 80}  // Adjust space between slides based on screen width
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}

            >
                {videos.map((video, index) => (
                    <SwiperSlide key={index}>
                        <VideoItemContainer
                            onClick={() => onVideoClick && onVideoClick(video)}>
                            <Thumbnail src={getThumbnailUrl(video)} alt={video.title} />
                            <VideoInfo>
                                <Title>{video.title}</Title>
                                <Description>{video.description}</Description>
                            </VideoInfo>
                        </VideoItemContainer>
                    </SwiperSlide>
                ))}
            </StyledSwiper>
        </Container>
    </div>
);