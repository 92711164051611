import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((_theme: Theme) => ({
  snackbarRoot: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "#07C806",
      color: "black",
    },
  },
  closeIcon: {
    color: "black",
  },
}));

export const CustomSnackbarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #07c806;
  border-radius: 8px;
`;

export const ContentContainer = styled.div`
  padding: 4px 14px 4px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Message = styled.p`
  font-family: DIN;
  font-size: 16px;
  font-weight: 400;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: -8px;
  padding-left: 16px;
`;

export const BackgroundBar = styled.div`
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 8px;
  background-color: #07c806;
  width: 100%;
`;

export const LinearProgressBar = styled.div`
  height: 8px;
  background-color: rgba(255, 255, 255, 0.56);
  width: 100%;
`;

export default useStyles;
