import styled from "styled-components";

interface RoundContainerProps {
  width?: number;
  height?: number;
}

interface StyledLabelProps {
  paddingLeft?: number;
}

export const MainContainer = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  background-color: #aeedae;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 10px;
`;

export const IconContainer = styled.div`
  padding-top: 8px;
`;

export const RoundContainer = styled.div`
  width: ${({ width }: RoundContainerProps) => width || 72}px;
  height: ${({ height }: RoundContainerProps) => height || 72}px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 50%;
`;

export const StyledLabel = styled.div`
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft || 32}px;
`;
