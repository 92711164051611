import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { Switch } from "@mui/material";
import Loaderv2 from "components/Loader/v2";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import React from "react";
import { Container, OutlinedButton, StyledLabel, TextButton } from "styles/shared";


export default function BrandsListItem(props: {
    name: string;
    image: string;
    slug: string;
    brandId: string;
    isPro: boolean;
    isLoading: boolean;
    hideMenu?: boolean;
    onChangeBrand: (brandId: string) => void;
    onClickListItem?: (slug: string) => void;
    onClickUpgradeToPro?: (slug: string) => void;
    onClickUpgradeToFree?: (slug: string) => void;

}) {

    const {
        name,
        image,
        slug,
        isPro,
        brandId,
        hideMenu,
        onClickUpgradeToPro,
        onClickUpgradeToFree,
        onClickListItem,
        isLoading,
        onChangeBrand,
    } = props




    const styles: Record<string, React.CSSProperties> = {
        image: {
            width: '60px',
            height: '60px',
            borderRadius: '99%',
            marginRight: '16px'
        },

    }


    // function renderSeatStatus() {
    //     if (selected) {
    //         if (overageSeat) {
    //             return (
    //                 <StyledLabel color="#FF0000">
    //                     ${15} / month
    //                 </StyledLabel>
    //             )
    //         } else {
    //             return (
    //                 <StyledLabel>
    //                     {seatCount} / 5
    //                 </StyledLabel>
    //             )
    //         }
    //     }

    //     return null
    // }

    function renderFreeActions() {
        return (
            <Container display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                <Container display="flex" flexDirection="row">
                    <OutlinedButton
                        disabled={isLoading}
                        onClick={() => onClickUpgradeToPro && onClickUpgradeToPro(slug)}>
                        {isLoading ? (
                            <Loaderv2 height={18} />
                        ) : 'Upgrade To Pro'}
                    </OutlinedButton>
                </Container>

            </Container>
        )
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    function renderProActions() {

        const open = Boolean(anchorEl);

        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Container display="flex" justifyContent="center" alignItems="center">
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        // style: {
                        //     maxHeight: ITEM_HEIGHT * 4.5,
                        //     width: '20ch',
                        // },
                    }}
                >
                    <MenuItem key={'dashboard'} selected={false} onClick={() => {
                        onChangeBrand(brandId)
                        
                        handleClose()
                    }}>
                        Go to Dashboard
                    </MenuItem>
                    {isPro ? (<MenuItem key={'free'} selected={false} onClick={() => {
                        handleClose()
                        onClickUpgradeToFree && onClickUpgradeToFree(slug)
                    }}>
                        Downgrade to Free
                    </MenuItem>) : <MenuItem key={'pro'} selected={false} onClick={() => {
                        handleClose()
                        onClickUpgradeToPro && onClickUpgradeToPro(slug)
                    }}>
                        Upgrade to Pro
                    </MenuItem>}
                </Menu>
            </Container>
        )
    }


    return (
        <Container borderBottom="1px solid #E5E7EB" padding="12px" display="flex"
            flexDirection="row"
            justifyContent="space-between"
            onClick={() => onClickListItem && onClickListItem(slug)}
        >
            <Container display="flex" flexDirection="row" gap="4px"
                alignItems="center"
                justifyContent="center">
                <img style={styles.image} src={image}></img>
                <Container display="flex" flexDirection="column" gap="6px"
                    justifyContent="center" alignItems="flex-start">
                    <StyledLabel fontSize={16}>{name}</StyledLabel>
                    {isPro && (<div><UpgradeToProChip type="label"
                        mainContainerProps={{
                            margin: "0"
                        }} /></div>)}
                </Container>
            </Container>
            <Container display="flex" flexDirection="row" gap="8px">
                {!isPro && !hideMenu ? renderFreeActions() : null}
                {!hideMenu && renderProActions()}
            </Container>

        </Container>
    )
}