import Axios from "helpers/Interceptor"

export const forceRelease = async (contentSlug: string, spotifyId?: string) => {
    try {
        const { data } = await Axios.post(`/campaign/presave/${contentSlug}/force-release`, { spotifyId })
        if (data.isError) throw new Error("Error pulling releases")
        return data
    } catch (e) {
        return null
    }
}