import { CSSProperties } from "react";
import { StyleTypes, BoxContrasts, BackgroundTypes } from "types/global";
import {
  contrastLinkImgMapping,
  filterOutLinks,
} from "helpers/StreamingMappings";
import isEqual from "lodash/isEqual";

type ImageMappingArgs = [string, BoxContrasts, string];

const { BOXES, STACKED, TRADITIONAL } = StyleTypes;
const { LIGHT } = BoxContrasts;
const { COLOR } = BackgroundTypes;

export const EMPTY_STRING = "";

export const EMPTY_RECORD_NAME = "__________";

export const convertHexToRGB = (hexCode: string) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return {
    values: {
      r,
      g,
      b,
    },
    color: hexCode,
    converted: `rgba(${r},${g},${b})`,
  };
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const convertRGBToHex = (values: {
  r: number;
  g: number;
  b: number;
}) => {
  const { r, g, b } = values;
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const getTraditionalStyleButtonBackgroundColor = (values: {
  r: number;
  g: number;
  b: number;
}) => {
  const R_CONVERSION_VALUE = 41;
  const G_CONVERSION_VALUE = 56;
  const B_CONVERSION_VALUE = 18;
  const { r: rValue, g: gValue, b: bValue } = values;
  const r = rValue + R_CONVERSION_VALUE;
  const g = gValue + G_CONVERSION_VALUE;
  const b = bValue + B_CONVERSION_VALUE;

  const rFixed = r >= 255 ? 255 : r;
  const gFixed = g >= 255 ? 255 : g;
  const bFixed = b >= 255 ? 255 : b;

  return {
    color: `rgb(${rFixed},${gFixed},${bFixed})`,
    values: { r: rFixed, g: gFixed, b: bFixed, a: 1 },
  };
};

export const getAnchorTagClassName = (args: {
  index: number;
  styleType: StyleTypes;
  url?: string;
  relative: boolean;
  isLast: boolean;
  creation: boolean;
}) => {
  const { index, styleType, url, relative, isLast, creation } = args;
  const showBorderTop = isEqual(index, 0) ? 'border-t' : 'border-t-0';
  const showBorderBottom = !isLast ? 'border-b' : 'border-b-0';
  const nonePointerEvents = !url ? "pointer-events-none	" : "";
  const boxesClassName = `w-2/5 flex ${relative? 'boxes-relative' : 'boxes-ratio'} align-center justify-center bg-red-100 mb-4  ${nonePointerEvents}`;
  const stackedClassName = `w-full ${relative || !creation ? 'stacked-ratio' : 'h-16'} flex align-center justify-center bg-transparent ${showBorderTop} border-b ${nonePointerEvents}`;
  const traditionalClassName = `w-full ${showBorderTop} ${showBorderBottom} ${nonePointerEvents}`;

  switch (styleType) {
    case BOXES:
      return boxesClassName;
    case STACKED:
      return stackedClassName;
    case TRADITIONAL:
      return traditionalClassName;

    default:
      return undefined;
  }
};

export const getImageClassName = (styleType: StyleTypes) => {
  if (isEqual(styleType, BOXES)) return "w-4/6";
  if (isEqual(styleType, STACKED)) return "w-5/12";
  return "w-1/3";
};

export const getArtworkClassName = (artworkUrl: string) => {
  if (artworkUrl) {
    return "w-full h-full object-cover object-center pt-0";
  }
  return "w-28 h-30 py-9 m-auto";
};

export const getPrincipalContainerBackgroundColor = (
  backgroundType: string,
  backgroundColor: string
) => {
  if (isEqual(backgroundType, COLOR)) return backgroundColor;
  return "#fff";
};

export const getContrastColorClassName = (boxContrasts: BoxContrasts) => {
  if (isEqual(boxContrasts, LIGHT)) return "text-black";
  return "text-white";
};

export const getOptionContainerClassName = (styleType: StyleTypes) => {
  if (isEqual(styleType, TRADITIONAL)) {
    return 'traditionaloption w-full flex-grow-0 flex items-center justify-between px-4 py-4';
  }
  return 'optioncontainer flex-grow-0 flex items-center justify-center';
};

export const getPrincipalContainerStyle = (args: {
  backgroundType: BackgroundTypes;
  backgroundColor: string;
  top?: number | string;
  relative: boolean
}) => {
  const { backgroundType, backgroundColor, top, relative } = args;
  const isColorType = isEqual(backgroundType, BackgroundTypes.COLOR);
  if (isColorType) return { backgroundColor } as CSSProperties;
  return {
    position: "relative",
    height: relative ? 'fit-content' : '100%' ,
    top: top || 0,
  } as CSSProperties;
};

export const getFormattedOptions = (args: {
  options: {
    url?: string | undefined;
    name: string;
    show: boolean;
  }[];
  actionBoxContrast: BoxContrasts;
  styleType: StyleTypes;
  relative: boolean;
  creation: boolean;
}) => {
  const { options, actionBoxContrast, styleType, relative, creation } = args;
  const formatted = options
    .filter(({ name }) => filterOutLinks(name))
    .map((option, index) => {
      const { url, name } = option;
      const args: ImageMappingArgs = [EMPTY_STRING, actionBoxContrast, name];
      const imgLink = contrastLinkImgMapping(...args);
      const imageDefault = imgLink.default || null;
      const anchorClassName = getAnchorTagClassName({
        index,
        styleType,
        url,
        relative,
        isLast: isEqual(index, options.length - 1),
        creation,
      });

      return {
        ...option,
        imgLink,
        imageDefault,
        anchorClassName,
      };
    });

  return formatted;
};
