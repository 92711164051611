import { FunctionComponent } from 'react';
import { CampaignTypeText, IconContainer, PlatformsIconsContainer, PurpleContainer } from './styles';
import BadgeContainer from './BadgeContainer';
import PLATFORMS_BADGE_ICONS from './BADGE_ICONS';

interface Props {
  campaignType: string
  platforms?: string[]
}


const CAMPAIGN_TYPE_MAPPINGS: Record<string, Record<string, string | string[]>> = {
  record_streams: {
    text: "Increase Music Streams",
    badgeIcons: ["spotify"],
  },
  grow_playlist_followers: {
    text: "Grow Playlist Followers",
    badgeIcons: ["spotify"],
  },
  link_clicks: {
    text: "Increase Link Clicks",
    badgeIcons: ['link_clicks'],
  },
  increase_sales: {
    text: "Increase Sales",
    badgeIcons: ['link_clicks'],
  },
  get_playlisted: {
    text: "Pitch Playlists",
    badgeIcons: ["spotify"],
  },
  increase_video_views: {
    text: "Increase Video Views",
    badgeIcons: ["youtube"],
  },
  boost_instagram: {
    text: "Boost Instagram Engagement",
    badgeIcons: ["instagram"],
  },
  pre_save: {
    text: "Pre-save Campaign",
    badgeIcons: ["spotify", "apple_music", "deezer"],
  },
}

const BadgeCampaignTypeContainer: FunctionComponent<Props> = ({
  campaignType,
  platforms,
}: Props) => {

  const mappingData = CAMPAIGN_TYPE_MAPPINGS[campaignType]
  if(!campaignType || !mappingData) {
    return null 
  }
  const text = mappingData.text as string;

  const badgeIcons = mappingData.badgeIcons as string[];
  const showIcons = platforms || badgeIcons;
  const imageUrls = showIcons.map((platform) => PLATFORMS_BADGE_ICONS[platform]);

  return <BadgeContainer text={text} imageUrls={imageUrls} />

}

export default BadgeCampaignTypeContainer;
