import { FunctionComponent, useState } from 'react';
import { LinkContainer, MainContainer, TextLinkInput, ButtonsContainer } from './styles';
import TertiaryButton from 'components/shareable/TertiaryButton';
import SecondaryButton from 'components/shareable/SecondaryButton';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { track } from 'analytics';

interface Props {
  link: string;
  padding?: string;
  variant?: 'default' | 'small'
}

const LinkCopyShareComponent: FunctionComponent<Props> = ({ link, padding, variant }: Props) => {
  const { mobileView } = useMediaBreakpoints();

  const [copyButtonLabel, setCopyButtonLabel] = useState<string>('Copy');
  const showShareButton = Boolean(navigator.share);

  const onClickCopy = () => {
    track('Copied Link', {
      link: link
    })
    navigator.clipboard.writeText(link)
      .then(() => setCopyButtonLabel('Copied!'))
      .catch(() => setCopyButtonLabel('Failed! Try again'));
    setTimeout(() => setCopyButtonLabel('Copy'), 3000);
  };

  const onClickShare = () => {
    if (showShareButton) {
      navigator.share({
        title: "Check this out!",
        text: "Here's something interesting.",
        url: link,
      }).catch((error) => console.log('Error sharing', error));
    }
  };

  return (
    <MainContainer
    
      style={{
        width: '100%', 
        padding: variant === 'small' ? 0 : padding || '16px',
        background: 'none'
      }}>
      <LinkContainer>
        <TextLinkInput value={link} readOnly
        />
        {!mobileView && (<ButtonsContainer>
          {mobileView && showShareButton && (
            <TertiaryButton text='Share' onClick={onClickShare} />
          )}
          <SecondaryButton text={copyButtonLabel} onClick={onClickCopy}
            padding={variant === 'small' ? '8px 12px' : undefined} />
        </ButtonsContainer>)}
      </LinkContainer>
      {mobileView ? (<SecondaryButton width="100%" text={copyButtonLabel} onClick={onClickCopy} />
      ) : null}
    </MainContainer>
  );
};

export default LinkCopyShareComponent;
