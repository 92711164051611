import { FunctionComponent, Fragment, useState, ReactNode } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Grid from "@material-ui/core/Grid"
import useStyles, { StyledLabel, TitleContainer } from "./styles"
import GoBackIcon from "@material-ui/icons/ChevronLeft"
import { useTheme } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CloseIcon from "./Components/CloseIcon"
import SupportIcon from "./Components/SupportIcon"
import SaveDraftsChip from "./Components/SaveDraftsChip"
import SavesYourProgressInfoModal from "../SavesYourProgressInfoModal"
import clsx from "clsx"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"

type GlobalWindow = Window & {
  Intercom: (type: string) => void
}

interface Props {
  title?: string
  showGoBackIcon?: boolean
  showCloseIcon?: boolean
  disableCloseIcon?: boolean
  showSaveDraftChip?: boolean
  savingChanges?: boolean
  hideGetSupportLabel?: boolean
  renderRightSideContent?: ReactNode
  renderDownSideContent?: ReactNode
  handleGoBack?: () => void
  handleClose?: () => void
}

const TopBar: FunctionComponent<Props> = ({
  title,
  showGoBackIcon,
  showCloseIcon,
  disableCloseIcon,
  showSaveDraftChip,
  savingChanges,
  hideGetSupportLabel = false,
  renderRightSideContent,
  renderDownSideContent,
  handleGoBack,
  handleClose,
}: Props) => {
  const theme = useTheme()
  const isXsView = useMediaQuery(theme.breakpoints.down(421))
  const { mobileView } = useMediaBreakpoints()

  const classes = useStyles()
  const flexGrowTitleContainer = (showSaveDraftChip && mobileView) || !showSaveDraftChip ? 1 : undefined
  const [openModal, setOpenModal] = useState(false)

  const showGetSupportLabel = !hideGetSupportLabel && !mobileView

  const handleOpenModal = (open: boolean) => () => setOpenModal(open)

  const handleClickSupportButton = () => {
    const globalWindow = window as unknown as GlobalWindow
    if (globalWindow.Intercom) globalWindow.Intercom("show")
  }

  return (
    <Fragment>
      <AppBar
        classes={{ root: classes.appBar }}
        position="static"
        elevation={0}
      >
        <Toolbar
          classes={{
            gutters: clsx(
              mobileView && showSaveDraftChip && classes.responsiveGutters
            ),
          }}
        >
          {showGoBackIcon && (
            <IconButton disableTouchRipple edge="start" onClick={handleGoBack}>
              <GoBackIcon className={classes.goBackIcon} />
            </IconButton>
          )}
          {showCloseIcon && (
            <IconButton
              disabled={disableCloseIcon}
              disableTouchRipple
              edge="start"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {title && (
            <TitleContainer flexGrow={flexGrowTitleContainer}>
              <StyledLabel
                width="fit-content"
                textOverflow="ellipsis"
                maxHeight="48px"
                overflow="hidden"
                whiteSpace="nowrap"
                color="#ffffff"
                fontSize={16}
                fontWeight={400}
              >
                {title}
              </StyledLabel>
              {!isXsView && mobileView && showSaveDraftChip && (
                <SaveDraftsChip
                  {...{ savingChanges }}
                  onClick={handleOpenModal(true)}
                />
              )}
            </TitleContainer>
          )}
          {!isXsView && !mobileView && showSaveDraftChip && (
            <SaveDraftsChip
              {...{ savingChanges }}
              onClick={handleOpenModal(true)}
            />
          )}
          {!hideGetSupportLabel && (
            <IconButton
              disableTouchRipple
              color="inherit"
              onClick={handleClickSupportButton}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <SupportIcon />
                </Grid>
                {showGetSupportLabel && (
                  <Grid item>
                    <StyledLabel fontSize={16} fontWeight={500} color="#B982FB">
                      Get help
                    </StyledLabel>
                  </Grid>)}
              </Grid>
            </IconButton>
          )}
          {renderRightSideContent}
        </Toolbar>
        {renderDownSideContent && (
          <Toolbar>
            {renderDownSideContent}
          </Toolbar>
        )}
      </AppBar>
      <SavesYourProgressInfoModal
        open={openModal}
        handleClose={handleOpenModal(false)}
        onClickAction={handleOpenModal(false)}
      />
    </Fragment>
  )
}

export default TopBar
