import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      borderRadius: "8px",
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      },
    },
  },
  switchButton: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: "rgba(136, 0, 255, 0.08);",
        },

        '& .MuiSwitch-thumb': {
          backgroundColor: "#8800FF",
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: "#FFFFFF",
      },
      '& + .MuiSwitch-track': {
        backgroundColor: "rgba(136, 0, 255, 0.08);",
      },
      '&.Mui-disabled': {
        cursor: 'not-allowed',
      }
    }
  }
}));

export const PlatformItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #EDECF2;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  gap: 16px;
`;

export const PlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const LabelPlatform = styled.div`
  font-family: DIN;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  width: 100%;
`;

export const ErrorBanner = styled.div`
  padding: 16px;
  margin-top: -16px;
  border-radius: 8px;
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  background: #ff000029;
`;
