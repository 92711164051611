import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  ageSlider: {
    width: '100%',
    cursor: 'pointer',
    height: '16px !important',
    display: 'inline-block',
    padding: '13px 0',
    position: 'relative',
    boxSizing: 'content-box',
    touchAction: 'none',
    WebkitTapHighlightColor: 'transparent',
    '& .MuiSlider-rail': {
      width: '100%',
      height: 'inherit',
      display: 'block',
      position: 'absolute',
      backgroundColor: '#F1F1F1',
      borderRadius: '27px',
      padding: 0,
      opacity: 1,
    },
    '& .MuiSlider-track': {
      height: 'inherit',
      display: 'block',
      position: 'absolute',
      borderRadius: '27px',
      backgroundColor: 'rgba(136, 0, 255, 0.24)',
    },
    '& .MuiSlider-thumb': {
      width: '24px !important',
      height: '24px !important',
      display: 'flex',
      position: 'absolute',
      boxSizing: 'border-box',
      marginTop: '-5px !important',
      alignItems: 'center',
      marginLeft: '-11px !important',
      borderRadius: '34px',
      justifyContent: 'center',
      boxShadow: 'none !important',
      backgroundColor: '#8800FF',
      transform: 'none !important',
      '&:hover, :active': {
        transform: 'none !important',
      },
      '& .MuiSlider-valueLabel': {
        left: 'calc(-4px) !important',
        top: '-28px !important',
        position: 'absolute',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        width: '32px',
        height: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        transform: 'none !important',
        '& span': {
          backgroundColor: '#FFFFFF !important',
          color: '#000000 !important',
          transform: 'none',
          borderRadius: '50% !important',
        }
      },
    },
  },
  disabledThumb: {
    backgroundColor: '#FFFFFF !important',
  },
  disabledRail: {
    backgroundColor: 'rgba(136, 0, 255, 0.08)',
  },
}));

export default useStyles;