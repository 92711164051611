import millify from "millify";
import { useEffect, useContext, useCallback } from "react";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import { queryFB, AD_ACCOUNT_STATUS, checkForFBPermissions, loginWithFB } from "../../helpers/FB";
import clsx from "clsx";
import useStyles from "./styles";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import { CreativeTabConnectTypes, TikTokAdAccount } from "types/global";
import externalLinkIcon from "assets/images/external-link.svg";
import { Chip } from "@material-ui/core";
import { AddAccountCtn, AddAccountInvalidCtn, ExternalLink, AddAccountChip } from './styles';
import QuestionMarkIcon from "assets/images/question-mark.svg";
import { SymphonyTooltip } from 'styles/shared';
import Intercom from "helpers/Intercom";

interface FacebookAdAccountConnectProps {
    closeModal: () => void;
    artist: any;
    selected: any;
    set: any;

    setupType?: string
    withBorderRadius?: boolean;
    showConnectButton?: boolean;
}


enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Facebook Ad Accounts because we don't have permission. Click the button below to authorize access to your ad account."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Facebook ad accounts associated with your account. Please create a new Facebook ad account by following the instructions at the link."
    }
}

export default function TiktokAdAccountConnect(props: FacebookAdAccountConnectProps) {

    const {
        closeModal,
        artist,
        selected,
        set,
        withBorderRadius,
        showConnectButton = true
    } = props

    const { saveData } = useContext(ConnectModalsContext)

    const classes = useStyles();

    // searching for ad accounts
    const [searchStr, setSearchStr] = useState('');

    // status for checking ad accounts
    const [availAdAccounts, setAdAccounts] = useState<any>([])
    const [loadingItems, setLoadingItems] = useState<boolean>(true)
    const [checkedAdAccounts, setCheckedAccounts] = useState<boolean>(false)

    // state for error getting ad accounts
    const [error, setError] = useState<any>(null)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // permissions error
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES>(ERROR_STATES_STATUSES.NONE)

    const isDisabledConnectButton = error || !selected

    const getAdAccounts = async () => {
        setCheckedAccounts(true)
        setLoadingItems(true)
        try {

            var queryUrl = `/brand/${artist.id}/tiktok/adaccounts`;

            const results = await Axios.get<TikTokAdAccount[]>(queryUrl);

            const adAccounts = results.data.map((a: TikTokAdAccount) => {
                // adjust according to new properties
                const { status, role } = a;

                const accountStatusValid = status === 'STATUS_ENABLE';
                const userHasCorrectAccess = role === 'ROLE_ADVERTISER' || role === 'ROLE_CHILD_ADVERTISER';

                return {
                    ...a,
                    accountStatusValid,
                    userHasAccess: userHasCorrectAccess,
                    amount_spent: a.balance,


                };
            })
                .sort((a: TikTokAdAccount, b: TikTokAdAccount) => {
                    // adjust sorting according to new properties
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: TikTokAdAccount, b: TikTokAdAccount) => {
                    const accountAccessA = a.userHasAccess
                    const accountAccessB = b.userHasAccess

                    if (accountAccessA && accountAccessB) {
                        return 0
                    }

                    if (accountAccessB && !accountAccessA) {
                        return 1
                    }

                    if (!accountAccessB && accountAccessA) {
                        return -1
                    }

                    return 0
                })
                .sort((a: TikTokAdAccount, b: TikTokAdAccount) => {
                    const advertiserStatusOrder: any = {
                        'STATUS_DISABLE': 1,
                        'STATUS_PENDING_CONFIRM': 2,
                        'STATUS_PENDING_VERIFIED': 3,
                        'STATUS_CONFIRM_FAIL': 4,
                        'STATUS_ENABLE': 5,
                        'STATUS_CONFIRM_FAIL_END': 6,
                        'STATUS_PENDING_CONFIRM_MODIFY': 7,
                        'STATUS_CONFIRM_MODIFY_FAIL': 8,
                        'STATUS_LIMIT': 9,
                        'STATUS_WAIT_FOR_BPM_AUDIT': 10,
                        'STATUS_WAIT_FOR_PUBLIC_AUTH': 11,
                        'STATUS_SELF_SERVICE_UNAUDITED': 12,
                        'STATUS_CONTRACT_PENDING': 13
                    }

                    const statusA = advertiserStatusOrder[a.status]
                    const statusB = advertiserStatusOrder[b.status]

                    return statusA - statusB
                })

            // .filter((a: any) => {
            //     return a.account_status === 1
            // })

            setAdAccounts(adAccounts);
            setLoadingItems(false)
        } catch (e) {
            setAdAccounts([]);
            setError(true)
        }

    }

    useEffect(() => {
        setError(null)
        getAdAccounts()
    }, []);


    function updateInBrand() {


        Axios.put(`/brand/${artist.slug}/connect`, {
            id: artist.id,
            name: artist.slug,
            service: 'tiktok_ad_account',
            value: selected
        })
            .then((result) => {
                // reloadBrand()
                closeModal()
            })
            .catch((err) => {
                console.log('error creating brand', err)
            })

    }

    const handleConnect = useCallback(() => {
        updateInBrand()
        setLoading(true)
    }, [artist.id, selected])



    useEffect(() => {
        saveData({
            loading,
            connectModalType: CreativeTabConnectTypes.FACEBOOK_AD_ACCOUNT_CONNECT,
            isDisabledConnectButton,
            handleConnect
        })
    }, [isDisabledConnectButton, handleConnect, loading])

    const AdAccountItem = ({ item }: { item: TikTokAdAccount }) => {
        const { userHasAccess, advertiser_id, amount_spent, name, accountStatusValid } = item



        return (
            <li
                key={advertiser_id}
                className="sy-card w-full"
                onChange={() => accountStatusValid && set(item)}
            >
                <AddAccountCtn valid={accountStatusValid ? accountStatusValid : true}>
                    <div className="flex flex-grow w-full flex-row gap-1">
                        <input
                            id={`tiktok${advertiser_id}`}
                            type="radio"
                            className="cursor-pointer  mr-2"
                            disabled={!accountStatusValid || !userHasAccess}
                            name='conect-facebook-pages'
                            value={selected}
                            checked={accountStatusValid && userHasAccess && selected && selected.advertiser_id === advertiser_id}
                        />
                        <label htmlFor={`facebook_${advertiser_id}`} className="cursor-pointer flex items-start flex-col gap-1">
                            <span>{name}</span>
                            

                        </label>
                        {!accountStatusValid && (
                            <div className="flex items-center ml-auto">
                                <Chip label="Not available" disabled />
                            </div>
                        )}
                    </div>
                    <div className="flex items-center w-full pl-6">
                        {!accountStatusValid && (
                            <AddAccountInvalidCtn>
                                <div>
                                    <p>{`This ad account's status is ${accountStatusValid} and it cannot be used.`}</p>
                                    {/* <ExternalLink className="text-red-500 text-xs underline" href={adAccountUrl(id)} target="_blank" rel="noreferrer">
                                        Review account
                                        <img className="w-6 h-6" alt="Review your account" src={externalLinkIcon} />
                                    </ExternalLink> */}
                                </div>
                                <div>
                                    <SymphonyTooltip
                                        className="block w-6 h-6 cursor-pointer"
                                        title="See more"
                                        arrow
                                        onClick={() => Intercom.openFacebookAdAccountInactiveArticle()}
                                    >
                                        <img
                                            className="w-6 h-6"
                                            alt="question-mark-icon.svg"
                                            src={QuestionMarkIcon}
                                        />
                                    </SymphonyTooltip>
                                </div>

                            </AddAccountInvalidCtn>
                        )}
                     
                    </div>
                </AddAccountCtn>
            </li>
        );
    };

    function handleNonError() {
        if (permissionsError === ERROR_STATES_STATUSES.NONE) {
            return (
                <>
                    {!loadingItems && availAdAccounts.length > 3 ? <div className="flex flex-row items-center border-b">
                        <img className="pl-5 lg:pl-10 w-auto" src={require('../../assets/images/search-icon.svg').default} />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchStr}
                            onChange={(e: any) => setSearchStr(e.target.value)}
                            className="pl-4 pr-6 py-4 outline-none border-gray-200 w-full"
                        />
                    </div> : null}
                    <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                        {availAdAccounts && availAdAccounts.length > 0 && availAdAccounts
                            .filter((a: any) => {
                                if (!(searchStr && searchStr !== "")) {
                                    return true
                                } else {
                                    return a.name.toLowerCase().includes(searchStr.toLowerCase())
                                }
                            })
                            .map((item: any) => (<AdAccountItem key={item.id} item={item} />))
                        }
                  
                    </ul>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        href="https://www.facebook.com/help/104002523024878/?helpref=uf_share" className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Create a New Page &gt;
                    </a>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {
            return (
                null
            )
        } else {
            return (<p className="mt-4">No Ad Accounts Found...</p>)
        }
    }


    return <div className={clsx("bg-white w-full h-full mx-auto", withBorderRadius && classes.withBorderRadius)}>
        <div
            className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
        >
            <p className="font-semibold text-gray-800">Connect Your Artist's Ad Account</p>
            <a onClick={closeModal}>
                <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                    ></path>
                </svg>
            </a>
        </div>
        <div className="middle-wrapper px-4 lg:px-10 ">
            <div className="mt-5 pb-5 border-b border-gray-200">
                <h4 className="mb-1 font-semibold ">
                    Select {artist.name}'s TikTok Ad Account
                </h4>
                <p className="text-md mb-2 text-gray-500">Connect the ad account your ads will be billed to. You can only connect ad accounts that are owned by the connected Business Manager. If you don’t see an ad account, try connecting a different Business Manager or create a new account.</p>
            </div>

            {loadingItems ? (
                <div className="mt-3 flex items-center justify-center">
                    <LoadingIndicator
                        color="black"
                        height="30px" />
                </div>) : handleNonError()}

            {showConnectButton && <div className="text-center px-4 lg:px-10 py-4">
                <button disabled={isDisabledConnectButton} onClick={handleConnect} className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3">{loading ? <div className="h-6 m-auto flex justify-center"><LoadingIndicator /></div> : 'Connect'}</button>
            </div>}

        </div>
    </div>

}