import { Fragment, FunctionComponent } from "react"
import PrimaryButton from "components/shareable/PrimaryButton"
import { ReactComponent as UserIcon } from "assets/images/user.svg"
import { SystemColors } from "types/globalStyles"
import DialogPaper from "components/shareable/DialogPaper"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal"
import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"
import styled from "styled-components"
import { Container, TextButton } from "styles/shared"
import { MAX_ADDITIONAL_USERS_WITH_TEAM_PRO } from "../../../../constants"

interface Props {
    open: boolean
    onClose: () => void
    onConfirm: () => void
    type?: 'upgrade' | 'downgrade'
    proAccounts: number
}

const useStyles = makeStyles((_theme: Theme) => ({
    userIcon: {
        "& path": {
            stroke: SystemColors.ACCENT_COLOR,
        },
    },
}))

interface AlertContainerProps {
    type?: string
}

export const AlertContainer = styled.div<AlertContainerProps>`
    padding: 16px;
    background-color: ${({ type }) => {
        if (type === 'success') return `${SystemColors.SUCCESS}18`
        return `${SystemColors.PAPER_WARNING_CONTAINER}`
    }};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`

export const ActionsContainer = styled.div`
    align-self: flex-end;
    gap: 16px;
    display: flex;
    margin-top: 16px;
`

const ConfirmChangeSubscriptionModal: FunctionComponent<Props> = ({
    open,
    type,
    onClose,
    onConfirm,
    proAccounts,
}: Props) => {
    const classes = useStyles()

    let title = 'Upgrade to Pro'
    let subtitle = proAccounts >= MAX_ADDITIONAL_USERS_WITH_TEAM_PRO ? "You can upgrade to Pro for this profile at a discounted rate of $15 / month." : "This upgrade will be included in your 5 team Pro account slots."
    let buttonCta = 'Confirm Upgrade'
    if (type === 'downgrade') {
        title = 'Downgrade to Free'
        subtitle = "You can downgrade to Free for this profile at a discounted rate of $0 / month."
        buttonCta = 'Confirm Downgrade'
    }

    return (
        <DialogPaper {...{ open }} onClose={onClose}>
            <Fragment>
                <HeaderModal
                    HeaderIcon={
                        <UserIcon width={32} height={32} className={classes.userIcon} />
                    }
                    title={title}
                    subtitle={subtitle}
                    closeModal={onClose}
                />
                <Container display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" gap="12px">
                    <TextButton onClick={onClose}>
                        Cancel
                    </TextButton>
                    <PrimaryButton
                        width="159px"
                        text={buttonCta}
                        onClick={onConfirm}
                    />
                </Container>
            </Fragment>

        </DialogPaper>
    )
}

export default ConfirmChangeSubscriptionModal
