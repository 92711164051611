import Dialog from "@material-ui/core/Dialog"
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
} from "react"
import { useState } from "react"
import {
  CloseButtonContainer,
  CloseIconContainer,
  ModalConnectButton,
  ModalConnectButtonContainer,
  ModalConnectButtonLabel,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
  GRADIENT_BACKGROUND_COLOR
} from "../../../style"
import CloseIcon from "components/shareable/CloseIcon"
import { ReactComponent as InstagramLogo } from "assets/images/insta.svg";
import { TextField, useMediaQuery, useTheme, InputAdornment } from "@material-ui/core"
import { getFormattedValue, checkIsValid, getFormattedInstaPage, uploadProfilePicture } from "./utils"
import { OnboardingArtist } from "types/global"
import { updateInBrand } from "./api"
import Lottie from 'react-lottie';
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json';
import { track } from "analytics"
import { getIgUserData } from "helpers/FB"
import { NotFoundInstaUser } from '../../../../../../components/connect/NotFoundInstaUser/index'

interface Props {
  artist: OnboardingArtist | null
  selectedInstagramArtist: { url: string; username: string; name?: string, picture?: string }
  selectInstagramArtist: (selectedInstagramArtist: { url: string; username: string; name?: string, picture?: string }) => void
  open: boolean
  closeModal: () => void
  localSave?: boolean
  buttonLabel?: string
}

const INVALID_URL_MESSAGE =
  "Looks like this Instagram username is invalid…"

const NOT_FOUND_USER_MESSAGE =
  "We weren't able to find your Instagram data, please double check the username you entered."

  const InstagramBusinessDiscoveryConnect: FunctionComponent<Props> = ({
  artist,
  selectedInstagramArtist,
  selectInstagramArtist,
  open,
  closeModal,
  localSave = false,
  buttonLabel,
}: Props) => {
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [userFound, setUserFound] = useState<boolean>(true)
  const [error, setError] = useState("")
  const [textFieldValue, setTextFieldValue] = useState<string>(
    selectedInstagramArtist?.username || ""
  )
  const [formattedValue, setFormattedValue] = useState(
    selectedInstagramArtist || {}
  )

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const isValidUrl = checkIsValid(value, formattedValue)

    if (value.length) {
      const formatted = getFormattedValue(value)
      setFormattedValue(formatted)
      setError(!isValidUrl ? INVALID_URL_MESSAGE : "")
      setUserFound(true)
    }
    setTextFieldValue(value)
  }

  const handleOnClickConnectButton = async () => {
    setLoading(true)
    track("Linked Instagram Business Discovery")
    let newFormattedValue = formattedValue
    if (formattedValue) {
      const igUser: Record<string, string | number | unknown> = await getIgUserData(formattedValue.username)
      if (igUser) {
        newFormattedValue = await getFormattedInstaPage(igUser)
        setFormattedValue(newFormattedValue)
        selectInstagramArtist(newFormattedValue)
      } else {
        setError(NOT_FOUND_USER_MESSAGE)
        setUserFound(false)
        setLoading(false)
        return;
      }
    }
    if (!localSave && artist && formattedValue) {
      const id = String(artist.id)
      const slug = String(artist.slug)
      await updateInBrand({ id, slug }, formattedValue)
    }

    closeModal()
    setLoading(false)
  }

  useEffect(() => {
    setTextFieldValue(selectedInstagramArtist?.username || "")
  }, [selectedInstagramArtist])

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{ 
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer 
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Connect Instagram</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              What is the username for {artist?.name || ""} Instagram account?
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <ModalPlatformInputContainer alignItems='flex-start'>
          <PlatformLogoContainer height='48px' width='48px' backgroundColor={GRADIENT_BACKGROUND_COLOR}>
            <InstagramLogo height={48} width={48}/>
          </PlatformLogoContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loading ? (
                <InputAdornment position="end">
                  <Lottie 
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ) : undefined,
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder="@badbunnypr, @sza..."
            error={!!error}
            helperText={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </ModalPlatformInputContainer>
        {
          !userFound && (<>
            <NotFoundInstaUser/>
          </>
        )}
        <ModalConnectButtonContainer>
          <ModalConnectButton
            disabled={
              !!loading ||
              !!error ||
              !textFieldValue ||
              textFieldValue === "" ||
              !checkIsValid(textFieldValue, formattedValue)
            }
            onClick={handleOnClickConnectButton}
          >
            <ModalConnectButtonLabel>{buttonLabel || "Connect"}</ModalConnectButtonLabel>
          </ModalConnectButton>
        </ModalConnectButtonContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default InstagramBusinessDiscoveryConnect
