import { Fragment, FunctionComponent, MouseEventHandler, ReactElement, ReactNode } from "react"
import { ButtonContainer, ButtonTextContainer, useStyles } from "./styles"
import { ShowTooltip } from "../CustomMenu"
import { ButtonText } from "../Typography"
import { SystemColors } from "types/globalStyles"
import { LoadingLottie } from "components/Loader/LoadingLottie"

interface Props {
  text: string
  disabled?: boolean
  width?: string
  height?: string
  tooltip?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  textAlign?: string
  verticalAlign?: string
  fontSize?: string
  showInline?: boolean
  loading?: boolean
  weight?: string | number
  underlined?: boolean
  onClick?: MouseEventHandler<HTMLSpanElement>
}

const LinkButton: FunctionComponent<Props> = ({
  disabled,
  text,
  width = "fit-content",
  height = "fit-content",
  tooltip,
  rightIcon,
  leftIcon,
  textAlign = "center",
  verticalAlign = "center",
  fontSize,
  loading = false,
  showInline = false,
  weight,
  underlined = false,
  onClick,
}: Props) => {
  const classes = useStyles()
  const color = disabled
    ? SystemColors.DISABLED_TEXT_COLOR
    : SystemColors.ACCENT_COLOR
  return (
    <ButtonContainer
      className={disabled ? classes.disabled : ""}
      onClick={onClick}
      {...{ width, height, textAlign }}
    >
      <ShowTooltip tooltip={tooltip}>
        <ButtonTextContainer
          display={showInline ? "inline" : "flex"}
          alignItems={verticalAlign}
          fontSize={fontSize}
        >
          {loading ? (
            <LoadingLottie height={16} width={16} black />
          ) : (
            <Fragment>
                {leftIcon}
                <ShowUnderlined underlined={underlined}>
                  <ButtonText color={color} weight={weight}>{text}</ButtonText>
                </ShowUnderlined>
              {rightIcon}
            </Fragment>
          )}
        </ButtonTextContainer>
      </ShowTooltip>
    </ButtonContainer>
  )
}


export const ShowUnderlined = (props: { underlined: Boolean, children: ReactNode }) => {
  const { underlined, children } = props
  if (underlined) {
    return (
      <u style={{ textDecorationColor: SystemColors.ACCENT_COLOR }}>
        {children}
      </u>
    )
  } else {
    return <>{children}</>
  }
}

export default LinkButton
