import Axios from 'helpers/Interceptor';
import Logger from 'Logger';

export const getCreativePerformanceData = async (args: {
  campaignId: number;
  access_token: string;
  brandId: string;
}) => {
  const { campaignId, access_token, brandId } = args;
  try {
    const url = `campaign/${campaignId}/creative-performance`;
    const response = await Axios.put(url, {
      brandId,
      access_token,
    });
    return response.data.data;
  } catch (e) {
    Logger.error('Error getting creative performance data');
  }
};
