import { useEffect, useState } from "react";

const useScreenQueryDimensions = () => {
  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    const isMobile = innerWidth < 768;
    return { innerWidth, innerHeight, isMobile };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return windowSize;
};

export default useScreenQueryDimensions;
