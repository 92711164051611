import FacebookView from "../../FacebookView";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import CampaignCreationFacebookConnect from "../../../../components/connect/Facebook";
import InstagramView from "../../InstagramView";
import InstagramConnect from "../../../../components/connect/Instagram";
import FacebookBusinessManagerConnect from "../../../../components/connect/FacebookBusinessManager";
import FacebookGenericSelectorView from "../../FBGenericSelectorView";
import FacebookAdAccountConnect from "../../../../components/connect/FacebookAdAccount";
import FacebookPixelConnect from "../../../../components/connect/FacebookPixel";
import CloudinaryUploadWidget from "../../../../components/shareable/CloudinaryUploadWidget";
import { uploadAdVideo } from "../../../../helpers/Facebook";
import { fbLoggedIn, queryFB } from "../../../../helpers/FB";
import InfoSection from "../../../../components/InfoSection";
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils";
import Select from 'react-select'
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import InstagramPosts from "components/shareable/InstagramPosts";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { AssetUploadTypes, AssetUploadTypeSelector, containsExplicitContent, ExplicitContentWarning, getAdAccountDetails, getPreconnectionConfigurationsStatus, loadInstagramPosts, PreConnectionConfigs, SelectedFbAdAccount, ShowFbSelectedNoIgSelectedState, UploadedAssetType, VideoUploader } from "../utils/fbCampaigns";
import { getRefreshInstagramPost, showNotFoundIGPostWarningToast } from "utils";
import AcceptFBTermsOfServiceCard from "components/shareable/AcceptFBTermsOfServiceCard";
import { AccountCardContainer, TermsOfServiceContainer } from "./styles";
import { AddAccountInvalidCtn, ExternalLink } from "components/connect/styles";
import { ColumnContainer } from "../styles";
import { SymphonyTooltip } from "styles/shared";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import ExternalLinkIcon from "assets/images/external-link.svg";
import Intercom from "helpers/Intercom";
import { AdAccountPageType, InstagramPageType } from "types/global";
import { CreativeAssetTypes } from "../constants";
import Axios from "helpers/Interceptor";

const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface LinkClicksCreativeTab {
    brand: any;
    setInstagramPagesAvailable: (value: boolean) => void;
    setSelectedAssets: any;
    selectedAssets: UploadedAssetType[] | null;
    selectedFB_page: any;
    setSelectedFB_page: any;
    selectedInstaPage: any;
    setSelectedInstaPage: any;
    selectedFBBusinessManager: any;
    setFBBusinessManager: any;
    selectedFBPixel: any;
    setFBPixel: any;
    selectedFBAdAccount: any;
    setFBAdAccount: any;
    reloadBrands: any;
    setCaption: any;
    caption: any;
    setCTA: any;
    cta: any;
    selectedInstagramPost: any;
    setSelectedInstagramPost: any;
    preConnectionConfigurations: PreConnectionConfigs | null;
    saveUploadQueue?: (queue: Record<string, unknown>[] | null) => void;
    adConnectionsAdded: boolean;
    adConnectionsConfirmed: boolean;
    showContinueButton?: boolean;
    saveConnectModalType?: (type: string) => void;
    creativeSelectorTab: AssetUploadTypes;
    setCreativeSelectorTab: (type: AssetUploadTypes) => void;
    setAddVisualSelected: Dispatch<SetStateAction<AssetUploadTypes>>;
    previewedVideo: UploadedAssetType | null;
    setPreviewedVideo: (type: UploadedAssetType | null) => void;
    areTermsAccepted: boolean;
    setAreTermsAccepted: (value: boolean) => void;
    time: number,
    startTimer: boolean,
    handleStartTimer: (type: string) => void,
    getTosConfiguration: (selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null, adAccountDetails: Record<string, unknown> | false) => Promise<PreConnectionConfigs | null>,
}

export default function CreativeTab(props: LinkClicksCreativeTab) {
    const {
        brand,
        setSelectedAssets,
        selectedAssets,
        creativeSelectorTab,
        setCreativeSelectorTab,
        setAddVisualSelected,
        previewedVideo,
        setPreviewedVideo,
        setInstagramPagesAvailable,
        selectedFB_page,
        setSelectedFB_page,
        selectedInstaPage,
        setSelectedInstaPage,
        selectedFBBusinessManager,
        setFBBusinessManager,
        selectedFBPixel,
        setFBPixel,
        selectedFBAdAccount,
        setFBAdAccount,
        reloadBrands,
        setCaption,
        caption,
        setCTA,
        cta,
        selectedInstagramPost,
        setSelectedInstagramPost,
        preConnectionConfigurations,
        saveUploadQueue,
        adConnectionsAdded,
        adConnectionsConfirmed,
        showContinueButton = true,
        saveConnectModalType,
        areTermsAccepted,
        setAreTermsAccepted,
        time,
        startTimer,
        handleStartTimer,
        getTosConfiguration,
    } = props

    const { data } = useContext(ConnectModalsContext)
    const { loading: isConnectModalLoading } = data

    const [showConnectModal, setConnectModal] = useState('');
    const [uploadQueue, setUploadQueue] = useState<any>(null)
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])

    useEffect(() => {
        if (saveConnectModalType) saveConnectModalType(showConnectModal)
    }, [showConnectModal])

    // scroll to top if ad connections were added
    useEffect(() => {
        scrollToTop()
        scrollIntoView()
    }, [adConnectionsAdded])

    const loadIGPosts = async () => {
        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage })

            const hasInstagramPosts = !!instagramPosts.length
            const hasVideosUploaded = !!selectedAssets?.length

            if (hasInstagramPosts) {
                // map through IG posts, make sure they're not explicit
                const mappedIgPosts = instagramPosts.map((post: InstagramPost) => {
                    const caption = post.caption || ''
                    var explicitContent = false
                    if (caption) {
                        explicitContent = containsExplicitContent(caption)
                    }

                    return {
                        ...post,
                        explicit_content: explicitContent
                    }
                })

                setInstagramPosts(mappedIgPosts)

                if (hasVideosUploaded) {
                    setCreativeSelectorTab(UPLOAD_VIDEO)
                    setAddVisualSelected(UPLOAD_VIDEO)
                } else {
                    setCreativeSelectorTab(INSTAGRAM_POSTS)
                    setAddVisualSelected(INSTAGRAM_POSTS)
                    // If there is not an selectedInstagramPost, default to selecting first post
                    const refreshSelectedInstaPost = getRefreshInstagramPost(selectedInstagramPost, instagramPosts);
                    setSelectedInstagramPost(refreshSelectedInstaPost);
                }
            } else {
                setCreativeSelectorTab(UPLOAD_VIDEO)
                setAddVisualSelected(UPLOAD_VIDEO)
                setInstagramPosts([])
            }

            showNotFoundIGPostWarningToast(instagramPosts, selectedInstagramPost)
        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setAddVisualSelected(UPLOAD_VIDEO)
            setInstagramPosts([])
        }
    }

    useEffect(() => {
        if (preConnectionConfigurations && !instagramPosts.length) {
            loadIGPosts()
        }
    }, [selectedInstaPage, preConnectionConfigurations])


    useEffect(() => {
        if (saveUploadQueue) saveUploadQueue(uploadQueue)

        if (uploadQueue && uploadQueue.length > 0) {
            const { items } = uploadQueue
            var existing = selectedAssets ? selectedAssets : []
            existing = existing.concat(items).slice(0, 2)
            setSelectedAssets(existing)
            setUploadQueue(null)
            if (saveUploadQueue) saveUploadQueue(null)
        }
    }, [uploadQueue])

    const handleShowConnectModal = async (modal: string) => {
        if (modal) {
            const adAccountDetails = await getAdAccountDetails(selectedFBAdAccount)
            await getTosConfiguration(selectedFBAdAccount, adAccountDetails)
        }
        setConnectModal(modal);
    }

    const updateFB_Pages = async (response?: any) => {
        handleShowConnectModal('facebook');
    }

    const selectInstagramPages = async (instaData: any) => {
        handleShowConnectModal('instagram');
    }

    const selectInstagramPage = (item: any) => {
        setSelectedInstaPage(item);
        let insta_page = JSON.parse(JSON.stringify(item));
        localStorage.setItem('insta_page', JSON.stringify(insta_page))
    }

    // function cancelUploadingAssets(key: string) {
    //     const currentPlatformsStatus = {
    //         ...selectedPlatforms
    //     }

    //     currentPlatformsStatus[key].uploadingAssets = false

    //     // update to selected ++ set the default caption for the service

    //     setSelectedPlatforms(currentPlatformsStatus)
    // }

    const onClickCreativeSelectorTab = (evt: any) => {
        const tabClicked = evt.target.id;
        setCreativeSelectorTab(tabClicked);
        setAddVisualSelected(tabClicked);
    }

    async function uploadCreativeAssets(assets: Array<any>) {

        var uploadedAssets = assets.map((asset: any) => {
            return new Promise(async (resolve: any, reject: any) => {
                const { url } = asset
                try {
                    const uploadedVideo = await uploadAdVideo(url, selectedFBAdAccount.id)
                    resolve({
                        ...asset,
                        fb_id: uploadedVideo.id,
                        type: 'video'
                    })
                } catch (e) {
                    alert("Error uploading video - " + asset.name + `\n${e}`)
                    console.error('e', e)
                    resolve(null)
                }
            })
        })

        var uploadedItems = await Promise.all(uploadedAssets)

        uploadedItems = uploadedItems.filter(o => o !== null)

        return uploadedItems
    }

    useEffect(() => {
        const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
        setAreTermsAccepted(currentTermsStatus)
    }, [selectedFBAdAccount, preConnectionConfigurations])

    // ===========================
    // CLOUDINARY WIDGET FUNCTIONS
    // ===========================

    const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<Array<string>>([])
    /**
     * Adds an item to the Cloudinary upload queue, to denote that 
     */
    function addToCloudinaryUploadQueue(fileId: string) {
        setCloudinaryUploadQueue((previous) => previous.concat(fileId));
    }

    /**
    * Removes an item to the Cloudinary upload queue, to denote that 
    */
    function removeFromCloudinaryUploadQueue(fileId: string) {
        const newQueue = [
            ...cloudinaryUploadQueue
        ]

        const indexOfId = newQueue.indexOf(fileId)
        if (indexOfId > -1) {
            newQueue.splice(indexOfId, 1)

        }
        setCloudinaryUploadQueue(newQueue);
    }

    /**
     * Removes an item from the cloudinary upload queue
     * and moves it into the facebook upload queue to start uploading to fb
     */
    const successfulCloudinaryUpload = (file: any) => {

        const {
            info
        } = file

        const {
            id: fileId
        } = info

        removeFromCloudinaryUploadQueue(fileId)
        try {
            addToFacebookUploadQueue(file)
        } catch (e: any) {
            const errors = [...uploadErrors]
            errors.push(e)
            setUploadErrors(errors)
        }
    }

    // ===========================
    // FACEBOOK UPLOAD FUNCTIONS
    // ===========================

    const [facebookUploadQueue, setFacebookUploadQueue] = useState<Array<any>>(selectedAssets || [])
    const [queueToUpload, setQueueToUpload] = useState<Array<any>>([]);

    const [uploadErrors, setUploadErrors] = useState<Array<{
        name: string,
        message: string
    }>>([])

    // const [completedFbUploadQueue, setCompletedFBUploadQueue] = useState<Array<any>>([])

    /**
     * Adds an item to the FB upload queue, to denote that it is ready to be uploaded
     */
    const addToFacebookUploadQueue = (file: any) => {

        const {
            info,
        } = file

        const {
            thumbnail_url,
            asset_id,
            height,
            width,
            secure_url,
            original_filename: name,
            format

        } = info

        var sizeRatio = width / height

        // error handling
        //  - must be minimum with of 500px
        if (width < 500) {
            const error = new Error("Video must be wider than 500px.")
            error.name = `${name}.${format}`
            throw error
        }

        let type = 'feed'

        // IG Story Sizing:
        /**
         * 16/9 to 4/5
         * 9/16
         */

        // IG Feed Sizing:
        /**
         * 16/9 to 9/16
         */
        if (sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) {
            type = 'feed'
        } else if ((sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) || sizeRatio < 9 / 16 || sizeRatio >= 996 / 2156) {
            type = 'story'
        } else {
            const error = new Error("Video isn't formatted for feed or story. Please make sure the video is sized in an aspect ratio of 4x5 or 16x9 for feed, or 9x16 for story.")
            error.name = `${name}.${format}`
            throw error
        }

        // upload the asset
        const formattedValue = {
            thumbnail: thumbnail_url,
            cloudinary_id: asset_id,
            height: height,
            width: width,
            name: name,
            url: secure_url,
            formatType: type,
            uploading: true,
            fb_id: null
        }

        setQueueToUpload(previous => previous.concat(formattedValue))
        uploadToFb(formattedValue);
    }

    async function uploadToFb(file: any) {
        const {
            thumbnail,
            cloudinary_id,
            height,
            width,
            name,
            url,
            formatType,
            uploading,
            fb_id
        } = file

        let fbUploaded: any = await uploadCreativeAssets([
            {
                thumbnail,
                cloudinary_id,
                height,
                width,
                name,
                url,
                formatType
            }
        ])

        const fbUploadedValue = fbUploaded[0];
        setFacebookUploadQueue(previous => previous.concat(fbUploadedValue));
        setQueueToUpload((previous: any) => previous.filter((file: any) => file.cloudinary_id !== fbUploadedValue.cloudinary_id))
    }

    useEffect(() => {
        setSelectedAssets(facebookUploadQueue)
    }, [facebookUploadQueue])

    // Deletes an asset from the list
    function removeAsset(cloudinary_id: string) {
        const updatedWithoutAsset = selectedAssets?.filter((item: UploadedAssetType) => {
            return item.cloudinary_id !== cloudinary_id
        }) || [];
        setFacebookUploadQueue(updatedWithoutAsset)
    }

    // =====================
    // VIEW RENDERERS
    // =====================

    /**
     * Renders the "Upload creative" section and handles the different states of upload
     * 
     */

    const [previewAdAsset, setPreviewAdAsset] = useState<any>(null)


    const [generatedAdPreviewHtml, setAdPreviewHtml] = useState<any>(null)

    useEffect(() => {
        // set the first preview asset off top if its not already selected
        if (selectedInstagramPost) {
            setPreviewAdAsset(selectedInstagramPost)
        } else {
            setPreviewAdAsset(null)
            setAdPreviewHtml(null)
        }
    }, [selectedInstagramPost])

    const [expressSetupSuccess, setExpressSetupSuccess] = useState(false)
    const [expressSetupPending, setExpressSetupPending] = useState(false)
    const [expressSetupError, setExpressSetupError] = useState(false)
    const [expressSetupLoading, setExpressSetupLoading] = useState(false)

    /**
     * If the user selects express setup, this will confirm whether or not they have access.
     * 
     * @param fbPageId 
     */
    async function expressSetupConfirmation(fbPageId: string) {
        setExpressSetupLoading(true)
        // checks if Symphony business manager has appropriate access to
        // the user's FB page

        // (1) get access token for page
        const pageInfo = await queryFB(`/${fbPageId}`, {
            params: {
                fields: 'access_token'
            }
        });
        const pageAccessToken = pageInfo.access_token


        // (2) use access token to check agencies for fb page
        const agencyStatus = await queryFB(`/${fbPageId}/agencies`, {
            params: {
                fields: "name,id,permitted_tasks,access_status,access_requested_time",
                access_token: pageAccessToken
            }
        })


        const linkedAgencies = agencyStatus.data


        // 4 states:
        //  - not linked (call link button and show button to FB)
        //  - fully linked w/ permissions (good to go)
        //  - linked, but pending (show button to FB)
        //  - linked, but awaiting all permissions (cancel request + resend, then show button to FB)
        var hasProperPermissions = false
        var isPendingLink = false
        var isConfirmedLinked = false

        // ^^^ when hasProperPermissions && isConfirmedLinked === true, we're good to go
        // if no agencies avail -> not linked (call link button and show button to FB)
        // if hasProperPermissions & isConfirmedLinked -> fully linked w/ permissions (good to go)
        // if isPendingLink || isConfirmedLinked & !hasProperPermissions ->  (cancel request + resend, then show button to FB)
        // if isPendingLink && !isConfirmedLinked && hasProperPermissions -> (show button to FB)


        const symphonyBizId = process.env.REACT_APP_SYMPHONY_FB_BUSINESS_ID
        const requiredPermissions = [
            "ANALYZE",
            "ADVERTISE",
            "CREATE_CONTENT",
            "MODERATE"
        ]

        if (linkedAgencies.length > 0) {

            // check if the agency exists within the list
            const foundAgency = linkedAgencies.find((o: {
                id: string,
                name: string,
                access_status: string,
                permitted_tasks: any
            }) => {
                const found = o.id === symphonyBizId

                return found
            })



            // if not, make the call
            if (!foundAgency) {
                isConfirmedLinked = false
                isPendingLink = false
                hasProperPermissions = false
            } else {
                // if the agency exists, check if:
                // (1) access is confirmed ~ if not, show pending state
                // (2) the appropriate permissions levels are avail
                //
                const accessConfirmed = foundAgency.access_status === "CONFIRMED"

                var allPermissions = true
                // loop through the required permissions and make sure they exist
                // in the permitted_tasks
                requiredPermissions.map((permission: string) => {

                    const idxOfPermission = foundAgency.permitted_tasks.indexOf(permission)
                    if (idxOfPermission === -1) {
                        allPermissions = false
                    }
                })

                if (!accessConfirmed) {
                    isConfirmedLinked = false
                    isPendingLink = true
                } else {
                    isPendingLink = false
                    isConfirmedLinked = true
                }

                if (!allPermissions) {
                    hasProperPermissions = false
                } else {
                    hasProperPermissions = true
                }
            }



        } else {
            hasProperPermissions = false
            isPendingLink = false
            isConfirmedLinked = false
        }

        if (isConfirmedLinked && hasProperPermissions) {
            // we're good to go

            setExpressSetupSuccess(true)
        } else {
            if (isPendingLink && hasProperPermissions) {
                setExpressSetupPending(true)
            } else if (isPendingLink && !hasProperPermissions) {
                // TODO: remove request and re-request
            } else if (!isPendingLink && !hasProperPermissions) {
                // send new request

                const requestAgencyAccess = await queryFB(`/${fbPageId}/agencies`, {
                    method: "POST",

                    params: {
                        access_token: pageAccessToken,
                        business: symphonyBizId,
                        permitted_tasks: requiredPermissions
                    }
                })


                if (requestAgencyAccess.success) {

                    // request access success, show waiting state
                    setExpressSetupPending(true)

                    // check again to see if it's fully linked
                    await expressSetupConfirmation(fbPageId)
                } else {
                    console.log('request agency access error', requestAgencyAccess)

                    // request access error, show try again
                    setExpressSetupError(true)

                }
            }
        }


        setExpressSetupLoading(false)

    }

    const onInstagramPageRefresh = async () => {
        if (!selectedFB_page) return false
        try {
            const results = await queryFB(`/${selectedFB_page.id}`, {
                params: {
                    fields: 'instagram_business_account{username,id,followers_count,profile_picture_url}',
                }
            })

            const { instagram_business_account } = results
            if (!instagram_business_account) {
                console.log("No Instagram Business Account")
                return false
            }

            await Axios.put(`/brand/${brand.slug}/connect`, {
                id: brand.id,
                name: brand.name,
                service: 'instagram_page',
                value: {
                    ...selectedInstaPage,
                    businessAccount: instagram_business_account,
                }
            })

            setSelectedInstaPage((prev: InstagramPageType) => ({ ...prev, businessAccount: instagram_business_account }))
            return true
        } catch (e) {
            console.log("Error refreshing Instagram Page", e)
            return false
        }
    }

    const uploadingAssets = uploadQueue && uploadQueue.length > 0

    return (
        <div className="middle-wrapper">
            {!adConnectionsAdded || !adConnectionsConfirmed || !areTermsAccepted ? (
                <>
                    {showConnectModal === '' && (
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <div className="">
                                    <h5 className="text-dark font-bold text-lg">Connect Accounts</h5>
                                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                        To create the most optimal audiences to send traffic to your Instagram, we'll need to plug-in with your Facebook &amp; Instagram account. (You'll only have to do this one time.)
                                    </p>
                                </div>
                            </div>

                            <div className="px-5 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">Advertising Accounts</p>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                    Run Symphony marketing using your Facebook Ad Account.
                                </p>
                            </div>


                            <ColumnContainer padding="0">
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center">
                                        <div className="artist-img">
                                            <img src={require('../../../../assets/images/facebook.svg').default} />
                                            {selectedFBAdAccount && (
                                                <div className="con-icon">
                                                    <img
                                                        className="absolute w-4 h-4 "
                                                        src={
                                                            require("../../../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                        alt="tick"
                                                    />
                                                </div>)}
                                        </div>
                                        <div className="px-3 items-center flex text-left">
                                            <h5 className="text-dark">FB Ad Account</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <FacebookGenericSelectorView

                                            selected={selectedFBAdAccount}
                                            update={() => {
                                                // if (!selectedFBBusinessManager) {
                                                //     handleShowConnectModal('facebook-biz-mgr')

                                                // } else {
                                                handleShowConnectModal('facebook-ad-account')

                                                // }
                                            }}
                                        />
                                    </div>
                                </div>
                            </ColumnContainer>
                            {Boolean(preConnectionConfigurations) && !getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'account') &&
                                <ColumnContainer>
                                    <AddAccountInvalidCtn>
                                        <div>
                                            <p>{`This ad account's status is ${preConnectionConfigurations?.account_status?.name} and it cannot be used.`}</p>
                                            <ExternalLink
                                                className="text-red-500 text-xs underline"
                                                href={preConnectionConfigurations?.account_status?.action as string}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Review account
                                                <img className="w-6 h-6" alt="Review your account" src={ExternalLinkIcon} />
                                            </ExternalLink>
                                        </div>
                                        <div>
                                            <SymphonyTooltip
                                                className="block w-6 h-6 cursor-pointer"
                                                title="See more"
                                                arrow
                                                onClick={() => Intercom.openFacebookAdAccountInactiveArticle()}
                                            >
                                                <img
                                                    className="w-6 h-6"
                                                    alt="question-mark-icon.svg"
                                                    src={QuestionMarkIcon}
                                                />
                                            </SymphonyTooltip>
                                        </div>
                                    </AddAccountInvalidCtn>
                                </ColumnContainer>
                            }
                            <div>
                                {selectedFBAdAccount && preConnectionConfigurations && !areTermsAccepted && (
                                    <TermsOfServiceContainer>
                                        <AcceptFBTermsOfServiceCard
                                            timeStarted={startTimer}
                                            verifyingTime={time}
                                            configurations={preConnectionConfigurations}
                                            onStartTimer={handleStartTimer}
                                        />
                                    </TermsOfServiceContainer>
                                )}
                            </div>
                            <div className="px-6 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">Pages</p>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">Your ads will run through the Instagram page linked to your Facebook.</p>
                            </div>
                            <div>
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center ">
                                        <div className="artist-img">
                                            <img
                                                src={
                                                    require("../../../../assets/images/facebook.svg")
                                                        .default
                                                }
                                            />
                                            {fbLoggedIn() && selectedFB_page && selectedFB_page.name && (
                                                <div className="con-icon">
                                                    <img
                                                        className="absolute w-4 h-4 "
                                                        src={
                                                            require("../../../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                    />
                                                </div>)}
                                        </div>
                                        <div className="px-3 items-center text-left  flex">
                                            <h5 className="text-dark">Facebook</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <FacebookView
                                            selectedFB_page={selectedFB_page}
                                            updateFB_Pages={updateFB_Pages} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center ">
                                        <div className="artist-img">
                                            <img src={require('../../../../assets/images/insta.svg').default} />
                                            {fbLoggedIn() && selectedInstaPage && selectedInstaPage.instagramAccount && (
                                                <div className="con-icon">
                                                    <img
                                                        className="absolute w-4 h-4 "
                                                        src={
                                                            require("../../../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                    />
                                                </div>)}
                                        </div>
                                        <div className="px-3 items-center text-left  flex">
                                            <h5 className="text-dark">Instagram</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <InstagramView
                                            updateFB_Pages={() => handleShowConnectModal('facebook')}
                                            selectedFBPage={selectedFB_page}
                                            selectedInstaPage={selectedInstaPage}
                                            selectInstagramPages={selectInstagramPages} />
                                    </div>
                                </div>
                                <ShowFbSelectedNoIgSelectedState
                                    requireInstagramConnection={true}
                                    facebookPage={selectedFB_page}
                                    instagramPage={selectedInstaPage}
                                    onInstagramPageRefresh={onInstagramPageRefresh}
                                />
                            </div>
                        </>)}

                    {showConnectModal === 'facebook-biz-mgr' && (
                        <FacebookBusinessManagerConnect
                            reloadBrand={reloadBrands}
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            selectedBusinessManager={selectedFBBusinessManager}
                            setBusinessManager={setFBBusinessManager}
                            showConnectButton={isConnectModalLoading}
                        />
                    )}

                    {showConnectModal === 'facebook-ad-account' && (
                        <FacebookAdAccountConnect
                            withBorderRadius
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            showBusinessManagerSelector={() => handleShowConnectModal('facebook-biz-mgr')}
                            businessManager={selectedFBBusinessManager ? selectedFBBusinessManager : null}
                            selected={selectedFBAdAccount}
                            set={setFBAdAccount}
                        />
                    )}

                    {showConnectModal === 'facebook-pixel' && (
                        <FacebookPixelConnect
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            businessManager={selectedFBBusinessManager}
                            selected={selectedFBPixel}
                            set={setFBPixel}
                            fbAdAccount={selectedFBAdAccount}
                            showConnectButton={isConnectModalLoading}
                        />
                    )}

                    {showConnectModal === 'facebook' && (
                        <CampaignCreationFacebookConnect
                            withBorderRadius
                            artist={brand}
                            showSave={isConnectModalLoading}
                            selectedFBPage={selectedFB_page}
                            selectFBPage={(value: any) => {
                                // if this is a custom connection, we need to make sure the FB page is owned by the user
                                setSelectedFB_page(value)
                                // TODO: make it so that this only calls on successful save
                                expressSetupConfirmation(value.id)
                            }}
                            selectIgPage={selectInstagramPage}
                            closeModal={() => handleShowConnectModal('')}
                        />
                    )}

                    {showConnectModal === 'instagram' && (
                        <InstagramConnect
                            withBorderRadius
                            artist={brand}
                            setInstagramPagesAvailable={setInstagramPagesAvailable}
                            closeModal={() => handleShowConnectModal('')}
                            showSave={isConnectModalLoading}
                            selectedInstaPage={selectedInstaPage}
                            selectInstagramPage={selectInstagramPage}
                            updateFBPages={() => handleShowConnectModal('facebook')}
                            selectedFBPage={selectedFB_page}
                        />
                    )}
                </>
            ) : (
                <>
                    {showConnectModal === '' && (
                        <>

                            <InfoSection title="Add Creative Visuals" description="Upload the videos you want shown in your ads. We recommend selecting an Instagram Reel or post that's already received some engagement." />


                            <AssetUploadTypeSelector
                                instagramPage={selectedInstaPage}
                                selected={creativeSelectorTab}
                                onChange={onClickCreativeSelectorTab}
                            />


                            {creativeSelectorTab === INSTAGRAM_POSTS &&
                                <div className="border-b pb-6">
                                    <InstagramPosts
                                        posts={instagramPosts}
                                        setSelectedInstagramPost={setSelectedInstagramPost}
                                        selectedInstagramPost={selectedInstagramPost}
                                        showCaption={false}
                                        allowPostPreviews={true}
                                    />
                                </div>
                            }

                            {creativeSelectorTab === UPLOAD_VIDEO &&
                                <>
                                    <div className="sy-card px-5 lg:px-10">
                                        <VideoUploader
                                            maxAssets={3}
                                            uploadingAssets={uploadingAssets}
                                            selectedAssets={[...(selectedAssets || []), ...queueToUpload]}
                                            removeAsset={removeAsset}
                                            setPreviewedVideo={setPreviewedVideo}
                                            previewedVideo={previewedVideo}
                                        />


                                    </div>
                                    {uploadErrors.length > 0 ? <div className="rounded-sm bg-yellow-500 text-white py-1 text-md py-3 px-3 text-center">
                                        <p className="w-8/12 m-auto">There was an error uploading the following assets:</p>
                                        {uploadErrors.map((o: {
                                            message: string,
                                            name: string
                                        }) => {
                                            return (
                                                <p>{o.name}: {o.message}</p>
                                            )
                                        })}
                                        <button className="mt-3" onClick={() => {
                                            setUploadErrors([])
                                        }}>Close</button>
                                    </div> : null}
                                </>
                            }

                            <InfoSection title="Call-to-Action" description="Enter the caption you want to use for your ad, and what users should see as the swipe-up call-to-action on your ad." />

                            <div className={`px-6 lg:px-10 ${showContinueButton ? "py-2" : "pt-2 pb-8"} border-b`}>
                                {
                                    creativeSelectorTab === UPLOAD_VIDEO ?
                                        <>
                                            <p className="text-md font-medium mt-2">Caption</p>
                                            <p className="text-sm text-gray-500">Enter the caption you want people to see alongside your ad.</p>

                                            <textarea
                                                value={caption}
                                                onChange={(e) => setCaption(e.target.value)}
                                                placeholder={`e.g. Follow my Instagram for more fire content!`}
                                                className="rounded-md border w-full resize-none p-2 h-20 mt-2"

                                            ></textarea>
                                        </>
                                        : (selectedInstagramPost && selectedInstagramPost.caption) ?
                                            <>
                                                <p className="text-md font-medium mt-2">Caption</p>
                                                <p className="text-sm text-gray-500">Your Instagram post’s caption will be used for your ad.</p>

                                                <textarea
                                                    value={selectedInstagramPost.caption} readOnly
                                                    className="rounded-md border w-full resize-none p-2 h-20 mt-2 border-2 bg-gray-100 border-gray-200 resize-none"
                                                />

                                                {selectedInstagramPost.explicit_content && (
                                                    <ExplicitContentWarning />
                                                )}
                                            </>
                                            : null
                                }


                                <p className="text-md font-medium mt-8">Call-to-Action</p>
                                <p className="text-sm text-gray-500">Select a call-to-action for your ad - when a user clicks this, they will be sent to your Instagram Profile.</p>

                                <Select
                                    value={cta}
                                    onChange={(value: any) => setCTA(value)}
                                    options={creativeSelectorTab === UPLOAD_VIDEO
                                        ? [{ value: 'CONTACT_US', label: 'Contact Us' },
                                        { value: 'DOWNLOAD', label: 'Download' },
                                        { value: 'LEARN_MORE', label: 'Learn More' },
                                        { value: 'SHOP_NOW', label: 'Shop Now' },
                                        { value: 'SIGN_UP', label: 'Sign Up' },
                                        { value: 'LISTEN_NOW', label: 'Listen Now' }]
                                        : [{ value: 'CONTACT_US', label: 'Contact Us' },
                                        { value: 'DOWNLOAD', label: 'Download' },
                                        { value: 'LEARN_MORE', label: 'Learn More' },
                                        { value: 'SHOP_NOW', label: 'Shop Now' },
                                        { value: 'SIGN_UP', label: 'Sign Up' },
                                        { value: 'LISTEN_NOW', label: 'Listen Now' },
                                        { value: 'WATCH_MORE', label: 'Watch More' }]
                                    }
                                />
                            </div>
                            <CloudinaryUploadWidget
                                onUploadAdded={(fileId: string) => {
                                    addToCloudinaryUploadQueue(fileId)
                                }}
                                onUploadSuccess={successfulCloudinaryUpload}
                                onUploadAbortAll={() => {
                                    setUploadQueue([])
                                }}
                                onUploadAbort={(fileId: string) => {
                                    removeFromCloudinaryUploadQueue(fileId)
                                }}
                                uploadButtonId={"all_widget"}
                            // onCancel={(info: any) => cancelUploadingAssets('all')}
                            />

                        </>
                    )}
                </>
            )
            }
        </div >

    )
}