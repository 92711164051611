import { FunctionComponent, useContext, useState } from "react";
import {
  StyledLabel,
  Container,
  ListItem,
  UnorderedList,
  UpgradeToProButton,
  Icon,
  ArrowImageContainer,
} from "../../styles";
import UpArrowImage from "assets/images/settingsScreen/upArrow.svg";
import UpgradeToProLabel from "components/shareable/UpgradeToProLabel";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { StarsIcon } from "components/svg-icons";
import { BrandSubscriptionDetails } from "types/global";
import Axios from "helpers/Interceptor";
import { goToUrl } from "utils";
import * as Sentry from "@sentry/react";
import { OutlinedButton } from "styles/shared";
import Loaderv2 from "components/Loader/v2";

interface Props {
  headline?: string;
  listItems?: string[];
  isAdmin: boolean;
  brandSlug?: string | null;
  subscriptionDetails?: BrandSubscriptionDetails | undefined;
}

const FreePlanView: FunctionComponent<Props> = ({
  isAdmin,
  subscriptionDetails,
  brandSlug,
  headline = "Unlock all the power of Symphony's automated marketing tools",
  listItems = [
    'Unlimited data access',
    'Unlimited marketing campaigns',
    'Learn who your fans are, down to their name + city'
  ],

}) => {
  const { handleOpenModal } = useContext(UpgradeModalContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectToSubscriptionStatus = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `/subscription/brand/${brandSlug}/settings`,
      );
      goToUrl(response.data.data.url, setIsLoading);
    } catch (error) {
      setIsLoading(false);
      Sentry.captureException(error);
      console.error("redirectToSubscriptionStatus: ", error);
    }
  };

  return (
    <Container>
      {subscriptionDetails?.allowCustomerPortal && (<Container margin="18px 0px 0px 0px" flexDirection={"row"}>
        <OutlinedButton onClick={redirectToSubscriptionStatus} disabled={isLoading}>
          {isLoading ? <Loaderv2 height={16} width={230} /> : 'View Billing Details'}
        </OutlinedButton>
      </Container>)}

      <Container
        display="flex"
        flexDirection="column"
        padding="24px 0px"
        borderBottom="1px solid #E5E7EB"
      >
        <StyledLabel fontSize={16}>Plan</StyledLabel>
        <StyledLabel marginTop="4px" fontSize={24}>
          Free
        </StyledLabel>
      </Container>
      <Container minHeight="414px" padding="24px 0px 0px 0px" display="flex">
        {window.innerWidth >= 768 ? (<ArrowImageContainer width="30%">
          <Icon
            margin="auto"
            width={173}
            height={373}
            alt="upArrow.jpg"
            src={UpArrowImage}
          />
        </ArrowImageContainer>
        ) : null}
        <Container width={window.innerWidth >= 768 ? "70%" : "100%"} flexDirection="column" padding="32px">
          <UpgradeToProLabel
            color="YELLOW"
            label="Symphony Pro"
            maxWidth="160px"
          />
          <StyledLabel fontSize={32} fontWeight={500} marginTop="16px">
            {headline}
          </StyledLabel>
          <Container margin="24px 0px 0px 0px">
            <UnorderedList>
              {listItems.map((o: string) => {
                return (
                  <ListItem>{o}</ListItem>
                )
              })}

            </UnorderedList>
          </Container>
          <Container margin="24px 0px 0px 0px">
            <UpgradeToProButton disableTouchRipple onClick={() => {
              handleOpenModal({
                source: "Settings - Billing Tab"
              })
            }}>
              <Container display="flex" alignItems="center" gap={8}>
                <StarsIcon />
                <StyledLabel>Upgrade to Pro</StyledLabel>
                <Container transform="scaleX(-1)">
                  <StarsIcon />
                </Container>
              </Container>
            </UpgradeToProButton>
          </Container>
        </Container>
      </Container>
    </Container >
  );
};

export default FreePlanView;
