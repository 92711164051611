import { Button, Dialog, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import {
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { toast, ToastOptions } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import Axios from 'helpers/Interceptor';
import some from "lodash/some";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading_White.json"
import Lottie from "react-lottie"
import * as Sentry from "@sentry/react";
import { track } from 'analytics';
import { SHARED_TOAST_OPTIONS } from '../utils';
import { ButtonCancel, ButtonConfirm, ButtonsContainer, InputContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import { CharacterCounter } from './styles';
import { Container, StyledLabel, TextButton } from 'styles/shared';

const UpdatingCampaignLabel: FunctionComponent = () => (
    <Grid container alignItems="center" alignContent="center">
        <Grid item xs={2}>
            <Lottie
                height={16}
                width={16}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: SymphonyLoadingLottie,
                }}
            />
        </Grid>
        <Grid item xs>
            <p>Updating campaign...</p>
        </Grid>
    </Grid>
)

const TOAST_OPTIONS: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    closeButton: true,
    style: {
        background: "#07C806",
        color: "#FFFFFF",
        margin: "16px 16px auto 16px",
        fontSize: "16px",
        borderRadius: "8px",
    },
}

interface Props {
    open: boolean;
    currentCampaignName: string;
    originalCampaignName: string;
    closeModal: () => void;
    reload: (campaignName: string) => void
    onErrorHandler?: () => void;
    campaignId: number;
    trackObject?: Record<string, any>;


}


const EditCampaignNameModal: FunctionComponent<Props> = ({
    open,
    currentCampaignName,
    originalCampaignName,
    closeModal,
    reload,
    onErrorHandler,
    campaignId,
    trackObject
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [campaignNameError, setCampaignNameError] = useState<boolean>(false)
    const [campaignName, setCampaignName] = useState<string>(currentCampaignName || "")
    const classes = useStyles()
    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    useEffect(() => {
        if (campaignName.trim().length > 100) {
            setCampaignNameError(true)
        } else {
            setCampaignNameError(false)
        }
    }, [campaignName])

    useEffect(() => {
        if (!open) {
            // reset the campaign name when the modal is closed
            // after a timeout so that the user doesn't see the name change
            // during the fade
            setTimeout(() => {
                setCampaignName(currentCampaignName || "")
            }, 500)
        }
    }, [open])

    function isValidCampaignName() {
        return campaignName.trim() && campaignName.trim().length <= 100;
    }

    function handleCampaignNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setCampaignName(event.target.value)
    }

    function resetToOriginalName() {
        setCampaignName(originalCampaignName)
    }

    const handleConfirmRenameCampaign = async () => {
        setLoading(true)
        const options = SHARED_TOAST_OPTIONS
        const marginToast = setResponsiveView ? "24px 16px auto 16px" : "40px 22px auto"

        try {
            const cleanedName = campaignName.trim()
            const response = await Axios.put(`campaign/${campaignId}/name`, {
                campaignName: cleanedName
            });
            if (some([!response, !response.data, !response.data.data])) {
                throw new Error("Error renaming campaign");
            }
            reload(cleanedName)
            toast.success('Campaign renamed!', {
                ...options,
                style: {
                    ...options.style,
                    margin: marginToast
                }
            })

            track('Saved Rename Campaign', {
                ...trackObject,
                opened: 'Rename Campaign',
            })
        } catch (e) {
            Sentry.captureException(`Error renaming campaign in campaign ${campaignId}: ${e}`)
            onErrorHandler?.()
        } finally {
            closeModal()
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            classes={{
                root: classes.rootDialog,
            }}
        >
            <ModalContainer
                width={setResponsiveView ? "auto" : "678px"}
                padding={setResponsiveView ? "32px 24px" : "32px"}
            >
                <HeaderModal
                    HeaderIcon={EditIcon}
                    closeModal={closeModal}
                    title='Rename Campaign'
                    subtitle='You can rename your campaign to more easily label or identify it. Your campaign name will be shown in the "Marketing" tab and in the campaign details page.'
                />
                <InputContainer>
                    <TextField
                        InputProps={{
                            classes: {
                                root: classes.inputBorder,
                            }
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: classes.formHelperText
                            },
                        }}
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                        type="text"
                        value={campaignName}
                        onChange={handleCampaignNameChange}
                    />
                    <TextButton onClick={resetToOriginalName}>Reset to Original Name</TextButton>
                    {campaignNameError && <StyledLabel
                        color="red"
                        fontSize={16}
                    >Please only use a maximum of 100 characters. You currently have {campaignName.length} characters.</StyledLabel>}
                </InputContainer>


                <ButtonsContainer
                    flexDirection={setResponsiveView ? "column-reverse" : "row"}
                >
                    <ButtonCancel
                        disabled={loading}
                        onClick={closeModal}
                        width={setResponsiveView ? "100%" : "fit-content"}
                    >
                        Cancel
                    </ButtonCancel>
                    <ButtonConfirm
                        disabled={loading || !isValidCampaignName() || campaignName === currentCampaignName
                            || campaignNameError}
                        onClick={handleConfirmRenameCampaign}
                        width={setResponsiveView ? "100%" : "50%"}
                    >
                        {loading ? <UpdatingCampaignLabel /> : "Update campaign"}
                    </ButtonConfirm>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    )
}

export default EditCampaignNameModal;
