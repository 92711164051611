import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((_theme: Theme) => ({
  rootCheckbox: {
    color: "#07C806",
  },
  checkedCheckbox: {},
  headerCell: {
    border: "1px solid red"
  }
}));

interface HeaderTextProps {
  textAlign?: "right" | "left" | "center";
  marginLeft?: number;
}

interface HeaderContainerProps {
  padding?: string;
  gridColumn?: string;
  alignItems?: string;
  marginLeft?: string;
}

interface AvatarColorProps {
  backgroundColor?: string;
}

interface TableRowsContainerProps {
  maxHeight?: string;
  width?: string;
}

interface HeadersContainerProps {
  width?: string;
}

interface FullNameCellProps {
  gridColumn?: string;
}

interface LocationCellProps {
  gridColumn?: string;
}

interface SourceCellProps {
  minWidth?: string;
}

interface PlatformCellProps {
  gridColumn?: string;
}

export const EmailIconDummy = styled.div`
  width: 24px;
  margin-right: 8px;
`;

export const HeadersContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 4px;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  width: ${({ width }: HeadersContainerProps) => width || "100%"};
  font-size: 14px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: ${({ alignItems }: HeaderContainerProps) =>
    alignItems || "center"};
  grid-column: ${({ gridColumn }: HeaderContainerProps) =>
    gridColumn || "span 1 / span 1"};
  padding: ${({ padding }: HeaderContainerProps) => padding || "0px"};
  height: 46px;
  margin-left: ${({ marginLeft }: HeaderContainerProps) => marginLeft};
`;

export const HeaderText = styled.div`
  font-weight: 400;
  font-size: 12px;
  max-height: 20px;
  margin-left: ${({ marginLeft }: HeaderTextProps) => marginLeft || 0}px;
  color: #707070;
`;

export const OrderIcon = styled.img`
  padding-left: 4px;
  transform: rotate(270deg);
`;

export const TableRowsContainer = styled.div`
  max-height: ${({ maxHeight }: TableRowsContainerProps) =>
    maxHeight || "320px"};
  width: ${({ width }: TableRowsContainerProps) => width || "960px"};
`;

export const Row = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 4px;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
  padding: 6px 0px;
  font-size: 14px;
`;

export const AvatarImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  grid-column: span 1 / span 1;
`;

export const AvatarColor = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  grid-column: span 1 / span 1;
  border: 1px solid #000000;
  background-color: ${({ backgroundColor }: AvatarColorProps) =>
    backgroundColor || "rgba(229, 231, 235, 1)"};
`;

export const EmailCell = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  word-break: break-all;
  width: 100%;
  height: 100%;
  grid-column: span 3 / span 3;
`;

export const EmailText = styled.div`
  padding-left: 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const FullNameCell = styled.div`
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  grid-column: ${({ gridColumn }: FullNameCellProps) =>
    gridColumn || "span 2 / span 2"};
  font-size: 16px;
  font-weight: 400;
`;

export const LocationCell = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  grid-column: ${({ gridColumn }: LocationCellProps) =>
    gridColumn || "span 2 / span 2"};
  word-break: break-word;
  font-size: 16px;
  font-weight: 400;
`;

export const SourceCell = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  min-width: ${({ minWidth }: SourceCellProps) => minWidth};
`;

export const PlatformCell = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  word-break: break-word;
  grid-column: ${({ gridColumn }: PlatformCellProps) => gridColumn || "span 2 / span 2"};
  font-size: 16px;
  font-weight: 400;
`;

export const PlatformText = styled.span`
  flex-grow: 1;
`;

export const EmptyCell = styled.div`
  display: flex;
  align-items: center;
`;

export const Image = styled.img``;

export default useStyles;
