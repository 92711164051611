import styled from 'styled-components';

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  background: #EDF2F7;
  border-radius: 8px;
  align-items: center;
  gap: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
`;

export const TextLinkInput = styled.input`
  font-family: 'DIN', sans-serif;
  font-size: 16px;
  color: #000000;
  border: none;
  background: transparent;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
