export enum MediaProductType {
  REELS = "REELS",
  FEED = "FEED",
}

export enum MediaType {
  VIDEO = "VIDEO",
  CAROUSEL_ALBUM = "CAROUSEL_ALBUM",
  IMAGE = "IMAGE",
}

export interface InstagramPost {
  id: string;
  ig_id: string;
  permalink: string;
  timestamp: string;
  caption: string;
  explicit_content?: boolean;
  media_product_type: MediaProductType;
  media_type: MediaType
  like_count: number;
  comments_count: number;
  media_url?: string;
  thumbnail_url?: string;
  is_shared_to_feed?: boolean;
  children?: any;
  username?: string;
  selectable: boolean;
}

export type InstagramPostInput = {
  posts: InstagramPost[];
  setSelectedInstagramPost: any;
  selectedInstagramPost?: InstagramPost;
  showCaption: boolean;
  allowPostPreviews?: boolean;
}
