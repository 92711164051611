import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) => ({
  connected: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
  },
  addPaymentMethod: {
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
  },
}));

interface StyledLinkProps {
  display?: string;
  paddingLeft?: string;
  color?: string;
  fontSize?: number;
}

export const StyledLink = styled.a`
  display: ${({ display }: StyledLinkProps) => display};
  padding-left: ${({ paddingLeft }: StyledLinkProps) => paddingLeft};
  color: ${({ color }: StyledLinkProps) => color};
  font-size: ${({ fontSize }: StyledLinkProps) =>
    typeof fontSize === "number" ? `${fontSize}px` : undefined};
`;

export default useStyles;
