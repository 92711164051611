import Logger from 'Logger'
import Axios from 'helpers/Interceptor'
import { BrandCurrency, CurrencyCodesType } from 'types/global'

interface PlatformsIds {
  adAccountId?: string
  pixelId?: string
  businessId?: string
}

export type FacebookPermissions = {
  data?: {
    ad_account: boolean,
    business: boolean,
    pixel: boolean,
    errors: boolean,
  } | null;
  message?: string | null;
  isError?: boolean | null;
}

export const getFacebookPermissions = async ({ adAccountId, pixelId, businessId }: PlatformsIds): Promise<FacebookPermissions> => {
  try {
    const results = await Axios.get('/platform/facebook/permissions', {
      params: {
        ad_account: adAccountId,
        pixel: pixelId,
        business: businessId,
      },
    })

    return results.data
  } catch (error) {
    throw error
  }
}

export const saveCurrency = async (currency: BrandCurrency) => {
  try {
    const response = await Axios.put('/brand/connect/currency', {
      currencyCode: currency.code,
      currencySymbol: currency.symbol
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getConvertedCurrency = async ({ from, to, amount }: { from: CurrencyCodesType, to: CurrencyCodesType, amount: number }) => {
  const url = `/campaign/convert/${from}/${to}/${amount}`
  try {
    const response = await Axios.get(url);

    return response.data.data.amountConverted;
  } catch (error) {
    Logger.error(error);
    return 0;
  }
}

export const updateCampaignStatus = async (data: { campaignId: string, status: string}) => {
  const { campaignId, status } = data

  try {
    const results = await Axios.put(`/campaign/${campaignId}/status`, {
      status
    })

    return results.data
  } catch (error) {
    throw error
  }
}