export enum AdActions {
    comment = "comment",
    post = "post",
    video_view = "video_view",
    likes = "post_reaction",
    purchase = 'purchase',
    custom_conversion = "offsite_conversion.fb_pixel_custom",
    link_click = "link_click",
}

/**
 * Calculates total spent on ad campaign
 * */
export const getTotalSpent = (spend: string) => parseFloat(spend);

/**
 * Calculates total remaining on ad campaign
 * @param budget 
 * @param spend 
 * @returns 
 */
export const getTotalRemaining = (budget: number, spend: string) =>
    (budget - parseFloat(spend)).toFixed(2);

/**
 * Calculates total shares on ads
 * @param shareActions 
 * @returns 
 */
export const getAdShares = (shareActions?: { action_type: string; value: number }) => {
    if (shareActions) return shareActions.value;
    return 0;
};

/**
 * Calculates total comments on ads
 * @param commentActions 
 * @returns 
 */
export const getAdComments = (commentActions?: {
    action_type: string;
    value: number;
}) => {
    if (commentActions) return commentActions.value;
    return 0;
};


/**
 * Returns the number of likes on ads
 * @param reactions 
 * @returns 
 */
export const getAdReactions = (reactions?: {
    action_type: string;
    value: number;
}) => {
    if (reactions) return reactions.value;
    return 0;
};


/**
 * Calculates total video views on ads
 * @param videoViewActions 
 * @returns 
 */
export const getAdVideoViews = (videoViewActions?: {
    action_type: string;
    value: number;
}) => {
    if (videoViewActions) return videoViewActions.value;
    return 0;
};


/**
 * Calculates total cost per link click on ads
 * @param costPerActionType 
 * @returns 
 */
export const getCostPerClick = (
    costPerActionType: { action_type: string; value: string }[],
    actionType?: string
) => {
    const clickCost =
        costPerActionType.find((type) => type.action_type === (actionType ? actionType : "link_click"))
            ?.value || "0";

    return parseFloat(clickCost).toFixed(2);
};


export const getCostPerConversion = (
    costPerActionType: { action_type: string; value: string }[],
    eventToSearch?: string,
) => {
    const conversionCost =
        costPerActionType.find((type) => type.action_type === `offsite_conversion.fb_pixel_custom.${eventToSearch || "StreamingLinkClicked"}`)
            ?.value || "0";

    return parseFloat(conversionCost).toFixed(2);
};


export const getConversions = (
    costPerActionType: { action_type: string; value: string }[],
    eventToSearch?: string,
) => {
    const conversionCost =
        costPerActionType.find((type) => type.action_type === `offsite_conversion.fb_pixel_custom.${eventToSearch || "StreamingLinkClicked"}`)
            ?.value || "0";

    return parseFloat(conversionCost);
};


/**
 * Calculates total cost per link click on ads
 * @param costPerActionType 
 * @param actionType
 * @returns 
 */
export const getCostPerAction = (
    costPerActionType: { action_type: string; value: string }[],
    actionType: string
) => {
    const clickCost =
        costPerActionType.find((type) => type.action_type === actionType)
            ?.value || "0";

    return parseFloat(clickCost).toFixed(2);
};

export const getPurchaseRoas = (
    purchaseRoas: { action_type: string; value: string }[]
) => {
    const purchaseRoasValue =
        purchaseRoas.find((type) => type.action_type === "omni_purchase")
            ?.value || "0";

    return parseFloat(purchaseRoasValue).toFixed(2);
}