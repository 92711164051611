
import { Fragment, FunctionComponent, useContext, useState } from "react"
import { BodyContainer, BoostsContainer, ErrorBanner } from "./styles"
import { ReactComponent as BoostIcon } from "assets/images/campaignDetails/Boost.svg"
import BoostDetail from "./BoostDetail"
import { DEFAULT_BOOST_VALUES, PresaveBoosts, PresaveTotals, getFormattedBoostsData } from "./utils"
import PrimaryButton from "components/shareable/PrimaryButton"
import DialogPaper from "components/shareable/DialogPaper"
import Logger from "Logger"
import Axios from "helpers/Interceptor"
import { showToast } from "utils"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { CampaignDetails } from 'types/global'
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal'
import { track } from "analytics"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"

interface Props {
  open: boolean
  closeModal: () => void
  onSave?: (campaign: CampaignDetails) => void
  boosts?: PresaveBoosts
  totalValues: PresaveTotals
  campaignId: number
  trackObject?: Record<string, any>
  isProUser: boolean
}

const EditBoostsModal: FunctionComponent<Props> = ({
  open,
  campaignId,
  closeModal,
  onSave,
  totalValues,
  boosts = DEFAULT_BOOST_VALUES,
  trackObject = {},
  isProUser,
}: Props) => {
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const [newValues, setNewValues] = useState<PresaveBoosts>(boosts)
  const [showBanner, setShowBanner] = useState(false);
  const [loading, setLoading] = useState<boolean>(false)

  const handleOnToggle = (key: string, newValue: boolean) => {
    const newState = {
      ...newValues,
      [key]: newValue,
    }
    setNewValues(newState)
  }

  const { mobileView } = useMediaBreakpoints();
  const formattedBoosts = getFormattedBoostsData(newValues, totalValues, handleOnToggle, isProUser, handleOpenUpgradeToProModal)

  const onSaveBoosts = async () => {
    setLoading(true)
    try {
      const url = `campaign/presave/${campaignId}/boosts`;
      const response = await Axios.put(url, { boosts: newValues });
      if (!response?.data || response.data.isError) {
        throw new Error(response.data.message)
      }

      const updatedCampaign = response.data.data as CampaignDetails;
      onSave?.(updatedCampaign)
      showToast({ message: "Campaign updated", mobile: mobileView, error: false })
      setShowBanner(false)
      track("Saved Edit Boosts", {
        ...trackObject,
        opened: 'Edit Boosts Modal'
      })
      closeModal()
    } catch (error) {
      setShowBanner(true)
      Logger.error(error);
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setNewValues(boosts)
    track("Cancelled Edit Boosts", {
      ...trackObject,
      opened: 'Edit Boosts Modal'
    })
    closeModal()
  }

  return (
    <DialogPaper open={open}>
      <Fragment>
        <HeaderModal
          closeModal={handleCancel}
          title="Edit Boosts"
          subtitle="Customize the boosts you want to activate for your campaign."
          HeaderIcon={BoostIcon}
        />
        <BodyContainer>
          <BoostsContainer>
            {formattedBoosts.map((boost, index) => (
              <BoostDetail
                key={index}
                requiresPro={boost.requiresPro}
                title={boost.title}
                subtitle={boost.description}
                checked={boost.isChecked}
                onToggle={boost.onToggle}
                icon={boost.icon}
                actualDataDescription={boost.actualDataDescription}
                disabled={boost.isDisabled}
                isProUser={isProUser}
              />
            ))}
          </BoostsContainer>
          {showBanner && (
            <ErrorBanner>
              Something went wrong when trying to update your boosts - please try submitting again.
            </ErrorBanner>
          )}
          <PrimaryButton
            onClick={onSaveBoosts}
            text="Save"
            width="100%"
            loading={loading}
            disabled={loading}
          />
        </BodyContainer>
      </Fragment>
    </DialogPaper>
  );
}

export default EditBoostsModal