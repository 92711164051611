import { Body2 } from "components/shareable/Typography"
import { FieldContainer, FieldFooter, FieldsContainer } from "./SetupSection/styles"
import Switch from "@material-ui/core/Switch"
import { ReactComponent as TrashIcon } from 'assets/images/trash.svg';
import { SystemColors } from "types/globalStyles";
import { useContext } from "react";
import { WebsiteContext } from "../hooks/WebsiteContext";
import { Field } from "types/global";
import { DraggableList } from "components/shareable/DraggableList";
import { ReactComponent as DragIcon } from 'assets/images/drag.svg';
import { Container } from "styles/shared";

interface Props {
  toggleIsRequiredField: (index: number, isRequired: boolean) => void,
  deleteField: (index: number) => void,
}

export const FieldsList = ({ deleteField, toggleIsRequiredField }: Props) => {
  const { fields, setFields } = useContext(WebsiteContext)

  const renderFieldContent = (item: Field, index?: number) => {
    return (
      <FieldContainer key={`data-field-container-${index}`}>
        <Container display="flex" gap='8px'>
          <DragIcon />
          <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>{item.label}</Body2>
        </Container>
        <FieldFooter>
          <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Required</Body2>
          <Switch
            checked={item.required}
            onChange={(e) => toggleIsRequiredField(index || 0, e.target.checked)}
            color='primary'
          />
          <TrashIcon
            onClick={() => deleteField(index || 0)}
            cursor='pointer'
          />
        </FieldFooter>
      </FieldContainer>
    )
  }

  if (!fields?.length) return <></>
  return (
    <FieldsContainer>
      <DraggableList
        list={fields}
        updateList={(newFields: Field[]) => setFields(newFields)}
        renderItem={renderFieldContent}
        keyOfItem="label"
      />
    </FieldsContainer>
  )
}