import millify from "millify";
import { LoadingIndicator } from "components/Loader";
import RowItem from "components/shareable/RowItem";


interface GeographiesStatsProps {
    shown: boolean;
    geographiesBreakdown: any
}
export default function GeographiesDetails(props: GeographiesStatsProps) {

    const {
        shown,
        geographiesBreakdown
    } = props


    return (
        <div className={` py-5 pt-0 ${shown ? 'visible' : 'hidden'}`}>
            {!(geographiesBreakdown) ?
                (<div className="mx-auto my-6 flex-col flex items-center ">
                    <LoadingIndicator height="100px" color="black" />
                    <p className="mt-1 text-center">Loading...</p>
                </div>) : (
                    <>
                        <div>
                            <ul className="max-h-80 overflow-auto">
                                {
                                    geographiesBreakdown
                                        .map((item: {
                                            country: string,
                                            state: string,
                                            city: string,
                                            count: number,
                                        }, index: number) => {
                                            const name = (item.city) ? `${item.city}, ${item.country}` : item.country;
                                            return (
                                                <RowItem
                                                    key={index}
                                                    imageStyles={`h-6`}
                                                    name={name}
                                                    showRightValue={true}
                                                    rightValue={`${millify(item.count)}`}
                                                />
                                            );
                                        })
                                }
                            </ul>
                        </div>
                    </>
                )}
        </div>

    )
}