import { makeStyles } from "@material-ui/core"
import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

interface Props {
  disabled?: boolean
}

const useStyles = makeStyles(() => ({
  icon: {
    "& path": {
      stroke: ({ disabled }: Props) =>
        disabled ? SystemColors.DISABLED_TEXT_COLOR : SystemColors.ACCENT_COLOR,
    },
  },
}))

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 4px 8px;
  gap: 16px;
`

export const StyledButton = styled.button``

export default useStyles
