import React, { FunctionComponent } from 'react';
import { Severity, MainContainer, TextContainer } from './styles'; // You can import these styles from your existing styles file

interface SmallCardProps {
  text: string;
  severity: Severity;
}

export default function SmallCard({ text, severity }: SmallCardProps) {
  return (
    <MainContainer severity={severity}>
      <TextContainer>
        {text}
      </TextContainer>
    </MainContainer>
  );
};

