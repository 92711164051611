import React from 'react';

interface PlayIconProps {
    width: string;
    height: string;
    color: string;
}

const PlayIcon: React.FC<PlayIconProps> = ({ width, height, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke={color ? color : "#82A66F"} strokeLinecap="round"
                d="m12.006 8.281-6.38 4.06a.333.333 0 0 1-.512-.281V3.94c0-.262.29-.422.513-.28l6.379 4.059a.333.333 0 0 1 0 .562Z"
                clipRule="evenodd" />
        </svg>
    );
};

export default PlayIcon;
