import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    input: {
        paddingLeft: 0,
        [`& fieldset`]: {
            borderRadius: "100px",
        },
        [`& .MuiOutlinedInput-adornedStart`]: {
            paddingLeft: "6px"
        }
    },
    inputBorder: {
        borderRadius: 6,
        width: '100%',
        height: '100%',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#8800FF',
          },
          '&:hover fieldset': {
            borderColor: '#8800FF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#8800FF',
          },
        },
        "& input": {
          fontFamily: "DIN",
          padding: "16px",
        },
        "& input::placeholder": {
          fontFamily: "DIN",
        },
    },
    pill: {
        margin: theme.spacing(1),
    },
    askAiHolder: {
        width: "fit-content",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(1),
    },
    askAi: {
        width: "auto",
        borderRadius: "100px",
        marginRight: '10px',
        minWidth: "100px",
        background: "rgba(123, 97, 255, 0.1)",
        color: "#8800FF",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1.25),
        boxSizing: 'border-box',
        justifyContent: 'space-evenly'
    },
    leftSection: {
        display: "flex",
        flexDirection: "column",
        width: "20%",
        top: theme.spacing(2),
    },
    rightSection: {
        width: "100%",
        height: "auto"
    },
    campaignType: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        cursor: "pointer",
        margin: theme.spacing(1),
        border: "1px solid #f1f1f1",
        borderRadius: theme.spacing(1),
        width: "calc(100% - 16px)", // Adjust the width for margin
        boxSizing: "border-box",
        transition: "0.2s",
        background: 'white',
        overflow: 'hidden',
        "&:hover": {
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
    },
    campaignImageBg: {
        backgroundColor: "#ECE0F7",
        width: "100%",
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    campaignImage: {
        width: "50px",
        height: "auto",
        objectFit: "cover",
    },
    campaignContent: {
        padding: theme.spacing(2),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    campaignLearnSection: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        borderTop: '0.75px solid #f1f1f1',
        width: '100%'
    },
    campaignTitle: {
        fontWeight: 400,
        fontSize: "1.2rem",
        marginTop: theme.spacing(0),
        textAlign: "left",
    },
    campaignDescription: {
        fontSize: "0.875rem",
        marginTop: theme.spacing(0.5),
        textAlign: "left",
    },
    scrollButton: {
        marginBottom: theme.spacing(0.5),
        textTransform: "none",
        textAlign: "left",
    },
    scrollButtonText: {
        textAlign: "left",
        flexGrow: 1,
    },
    campaignContainer: {
        display: "flex",
        // maxHeight: "calc(100vh - 200px)", // Adjust as needed based on the height of your modal header and other elements
        // overflowY: "auto",
    },
    modalContent: {
        maxHeight: "calc(100vh - 100px)", // Adjust as needed based on the height of your modal header and other elements
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    },
    recommendationsBox: {
        borderRadius: "10px",
        backgroundColor: "#F5EBFF",
        color: "#000000",
        padding: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '99%'
    },
    chip: {
        backgroundColor: "#F5F5F5",
        color: "black",
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        [`&:hover`]: {
            backgroundColor: "#E8E7E7"
        },
        [`&:active`]: {
            backgroundColor: "#E8E7E7"
        }
    },
    playbooksContainer: {
        backgroundColor: "#F4F4F4",
    },
    playbooksContent: {
        paddingTop: theme.spacing(3)
    },
    aiContainer: {
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
        paddingBottom: theme.spacing(1)
    },
    campaignRecommendation: {
        border: "1px solid #f1f1f1",
        background: "white",
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: "0.2s",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',

        "&:hover": {
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
    },
    maintenanceText: {
        background: '#d7d7d7',
        padding: '4px',
        boxSizing: 'border-box',
        borderRadius: '5px',
        marginTop: '10px',
        fontSize: '14px',
        width: 'fit-content'
    }
   
}));

export default useStyles;