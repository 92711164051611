import { setUserProperty, track } from "analytics";
import { GettingStartedNames } from "components/dashboard/GettingStarted";
import { SYMPHONY_TASKS, SymphonyTasks } from "types/global";
import Axios from "helpers/Interceptor";
import mixpanel from "mixpanel-browser";
import { FunctionComponent, ReactNode, createContext, useEffect, useState } from "react";

interface Context {
  fetchOnboardingTasks: () => void;
  finishedTasks: SYMPHONY_TASKS[];
  completed: boolean;
  finishTask: (task: SYMPHONY_TASKS) => void;
}

export const GettingStartedContext = createContext<Context>({
  fetchOnboardingTasks: () => {},
  finishedTasks: [],
  completed: true,
  finishTask: (task: SYMPHONY_TASKS) => {},
});

type Props = {
  currentBrand?: number | null | undefined;
  children: ReactNode[] | ReactNode;
}

const GettingStartedProvider: FunctionComponent<Props> = ({
  currentBrand,
  children,
}: Props) => {
  const [finishedGettingStartedTasks, setGettingStartedTasks] = useState<SYMPHONY_TASKS[]>(Object.values(SYMPHONY_TASKS));
  const [completed, setCompleted] = useState(true);
  
  const fetchOnboardingTasks = async () => {
    try {
      if (!currentBrand) return;
      const response = await Axios.post(`/brand/${currentBrand}/onboardingtasks`);
      const tasks = response.data.data as SymphonyTasks;
      if(!completed && tasks.getting_started_completed) {
        setUserProperty("Completed All Onboarding", true);
        track('Completed All Onboarding Steps');
      }
      setGettingStartedTasks(tasks.symphony_tasks || []);
      setCompleted(tasks.getting_started_completed);
    } catch (err) {
      console.error(err);
    }
  }

  const finishTask = async (task: SYMPHONY_TASKS) => {
    try {
      if (!currentBrand) return;
      const response = await Axios.put(`/brand/${currentBrand}/onboardingtasks`, {
        task_id: task,
      });
      const tasks = response.data.data as SymphonyTasks;
      await fetchOnboardingTasks();
      setUserProperty(`Completed "${GettingStartedNames[task]}" Onboarding Step`, true)
      track(`Completed "${GettingStartedNames[task]}" Onboarding Step`, {
          remainingSteps: Object.keys(SYMPHONY_TASKS).length - finishedGettingStartedTasks.length - 2,
      });
      mixpanel.people.set({
        "completed_onboarding_steps": finishedGettingStartedTasks.length + 1, 
      });
    } catch (err) {
      console.error(err);
    }
  }
  
  useEffect(() => {
    if(currentBrand) 
    fetchOnboardingTasks();
  }, [currentBrand]);
  
  const context = {
    fetchOnboardingTasks,
    finishedTasks: finishedGettingStartedTasks,
    completed,
    finishTask,
  };

  return (
    <GettingStartedContext.Provider value={context}>
      {children}
    </GettingStartedContext.Provider>
  )
}

export default GettingStartedProvider;