import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

export const ErrorBannerContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 24px;
  background: linear-gradient(
      0deg,
      rgba(255, 0, 0, 0.16),
      rgba(255, 0, 0, 0.16)
    ),
    ${SystemColors.PAPER_CONTAINER};
  opacity: 0.8;
  border-radius: 8px;
  width: 100%;
`

export const ErrorBannerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

