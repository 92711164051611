import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";

const useStyles = makeStyles({
    confetti: {
        position: 'fixed !important' as 'fixed',
    },
});

export default function MainConfetti(props: {
    numberOfPieces?: number,
}) {
    const classes = useStyles()

    const {
        numberOfPieces = 2000
    } = props

    // handle getting rid of itself
    const [isDone, setIsDone] = useState(false)
    const [removed, setIsRemoved] = useState(false)
    useEffect(() => {
        if (isDone) {
            setTimeout(() => {
                setIsRemoved(true)
            }, 2000)
        }
    }, [isDone])

    return removed ? null : (
        <Confetti
            numberOfPieces={numberOfPieces}
            tweenDuration={15000}
            recycle={false}
            colors={[
                "#E0BFFF",
                "#B094FF",
                "#8000FF",
                "#5000BF",
                "#300080",
                "#20005A"
            ]}
            className={classes.confetti}
            onConfettiComplete={() => setIsDone(true)}
        />
    )
}