import dayjs from "dayjs";
import millify from "millify";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts";

interface SymphonyLineChartProps {
    xAxisFormat?: 'date';
    data: {
        [key: string]: number | string;
    }[]
    xAxisKey: string;
    yAxisKey: string;
    width?: number;
    height?: number;
    xTickHeight?: number;
    yTickWidth?: number;
}

const Tick = (props: any) => {
    const { payload: { value } } = props;
    const color = value === "yourdata" ? "red" : "#666";

    return (
        <Text
            {...props}
            fill={color}
            style={{
                zIndex: 99
            }}
        >
            {millify(value, { precision: 2 })}
        </Text>
    )
}

export default function SymphonyAreaChart({
    xAxisFormat,
    data,
    xAxisKey,
    yAxisKey,
    width = 500,
    height = 300,
    xTickHeight = 20,
    yTickWidth = 8,
}: SymphonyLineChartProps) {
    const lineChartSvgId = `${Date.now()}-splitColor`

    return (
        <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
            <AreaChart
                width={width}
                height={height}
                data={data}
                margin={{
                    top: 15,
                    right: 0,
                    left: window.innerWidth >= 768 ? 20 : 0,
                    bottom: 5,
                }}
            >
                <CartesianGrid
                    stroke="#8800FF29"
                    strokeWidth={1.5}
                    strokeDasharray="8 8"
                />
                <XAxis
                    dy={12}
                    axisLine={false}
                    dataKey={xAxisKey}
                    tickLine={false}
                    minTickGap={window.innerWidth < 768 ? 20 : 30}
                    width={width}
                    height={xTickHeight}
                    tickFormatter={(value: any, index: number) => {
                        const date = dayjs(value, "MM/DD").format("MM/DD")
                        return date !== 'Invalid Date' ? date : value
                    }} />
                <YAxis
                    mirror={window.innerWidth < 768}
                    height={height}
                    width={yTickWidth}
                    tick={<Tick/>}
                    tickCount={window.innerWidth < 768 ? 3 : 4}
                    axisLine={false}
                    minTickGap={window.innerWidth < 768 ? 30 : 20}
                    tickLine={false}
                    allowDecimals={false}
                    domain={['auto', 'auto']}
                />
                <Tooltip
                    cursor={{ stroke: '#8800FF', strokeWidth: 2 }}
                    content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                            return (
                                <GraphTooltip
                                    title={dayjs(label).format('dd MM/DD')}
                                    value={payload[0].value!.toLocaleString('en')}
                                />
                            );
                        }
                        return null;
                    }}
                />
                <defs>
                    <linearGradient
                        id={lineChartSvgId}
                        x1="0" y1="0" x2="0" y2="1"
                    >
                        <stop stopColor="#8800FF" stopOpacity={0.4} />
                        <stop offset="1" stopColor="#8800FF" stopOpacity={0.05} />
                    </linearGradient>
                </defs>
                <Area
                    type="monotone"
                    dataKey={yAxisKey}
                    strokeWidth={2}
                    stroke="#8800FF"
                    fill={`url(#${lineChartSvgId})`}
                    activeDot={{ r: 8 }}
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}