import { FunctionComponent } from "react"

const SupportIcon: FunctionComponent = () => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.34874 11.5414L2.06174 11.8283H2.06188C1.83049 12.0596 1.70041 12.3733 1.70027 12.7004C1.70027 13.0275 1.83007 13.3413 2.06132 13.5726C2.29256 13.804 2.60627 13.934 2.93332 13.9341H6.82326C7.3828 14.8778 8.17936 15.6588 9.13382 16.1997C10.0883 16.7405 11.1677 17.0224 12.2648 17.0174H16.5321H16.532C16.8443 17.0158 17.1432 16.8905 17.3633 16.6689C17.5832 16.4473 17.7064 16.1475 17.7059 15.8353C17.7053 15.523 17.5809 15.2238 17.36 15.003L17.1355 14.7809H17.1356C18.0184 13.7406 18.5037 12.4208 18.5053 11.0563C18.4905 9.74558 18.0431 8.47631 17.2328 7.44612C16.4225 6.41568 15.2945 5.68189 14.0243 5.3584C13.5326 4.0597 12.6537 2.94364 11.5066 2.16124C10.3594 1.37885 8.99947 0.967834 7.61101 0.984219C5.85365 0.946229 4.15285 1.60638 2.88135 2.82006C1.60985 4.03373 0.871402 5.70201 0.827637 7.45916C0.828463 8.95829 1.36833 10.4072 2.34866 11.5415L2.34874 11.5414ZM17.2721 11.0563C17.2653 12.304 16.751 13.4954 15.8473 14.3559C15.729 14.4718 15.6624 14.6302 15.6624 14.7958C15.6624 14.9612 15.729 15.1198 15.8473 15.2355L16.4023 15.7839L12.265 15.7841C10.7226 15.7893 9.26063 15.0972 8.28693 13.9011C9.92318 13.7777 11.4551 13.0496 12.5842 11.8588C13.7132 10.668 14.3585 9.09952 14.3946 7.45901C14.3939 7.22845 14.3807 6.99816 14.3551 6.76912C15.2092 7.11737 15.9416 7.7098 16.4606 8.47239C16.9795 9.23512 17.2619 10.1338 17.2723 11.0562L17.2721 11.0563ZM7.6112 2.21748C8.77776 2.19945 9.92025 2.55114 10.8749 3.22205C11.8293 3.89309 12.5472 4.84892 12.9252 5.95286C12.9283 5.96704 12.9322 5.98094 12.9367 5.99471C13.0843 6.46891 13.16 6.96252 13.1612 7.45917C13.1176 8.88921 12.509 10.2438 11.4688 11.2261C10.4286 12.2085 9.04153 12.7388 7.61132 12.7008H2.93345L3.6422 11.9912C3.75906 11.8745 3.82417 11.7157 3.82293 11.5507C3.82169 11.3855 3.75425 11.2279 3.63573 11.113C2.63447 10.1607 2.06569 8.84082 2.06115 7.45914C2.10478 6.02896 2.71334 4.67437 3.75353 3.69183C4.79376 2.70942 6.18084 2.17919 7.61105 2.21732L7.6112 2.21748Z"
      fill="#B982FB"
    />
  </svg>
)

export default SupportIcon
