import { Theme, makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) => ({

    space: {
        padding: "16px 24px",
        [theme.breakpoints.up(1023)]: {
            padding: "24px 40px",
        },
    },
    border: {
        borderBottomWidth: 1,
    },
    noPaddingBottom: {
        paddingBottom: 0,
    },
    noPaddingTop: {
        paddingTop: 0,
    }
}));


interface CardProps {
    borderRadius?: string
}

export const Card = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: rgba(229, 231, 235, 1);
    background-color: #ffffff;
    border-radius: ${({ borderRadius }: CardProps) => borderRadius};
    width: 100%;
    @media (max-width: 768px) {
      border-radius: 0px;
    };
  `;