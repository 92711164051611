import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const SoundcloudIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.71004 20.9175C9.665 20.9175 9.62954 20.9523 9.62358 21.0018L9.46631 22.1977L9.62368 23.3733C9.62954 23.4228 9.6651 23.4573 9.71014 23.4573C9.75358 23.4573 9.78904 23.4232 9.79607 23.3737V23.3735C9.79607 23.3735 9.79607 23.3735 9.79607 23.3737L9.98262 22.1977L9.79596 21.0014C9.78925 20.9521 9.75348 20.9175 9.71004 20.9175Z" fill="white" />
                <path d="M10.5956 20.2639C10.5883 20.2126 10.5513 20.177 10.5061 20.177C10.4604 20.177 10.4227 20.2136 10.4165 20.2639C10.4165 20.2643 10.2048 22.1976 10.2048 22.1976L10.4165 24.0889C10.4227 24.1395 10.4604 24.1762 10.5061 24.1762C10.5513 24.1762 10.5882 24.1404 10.5954 24.0893L10.8364 22.1976L10.5956 20.2639Z" fill="white" />
                <path d="M13.9313 18.48C13.8445 18.48 13.7728 18.551 13.7685 18.6415L13.6001 22.1997L13.7685 24.4991C13.7728 24.5888 13.8445 24.6599 13.9313 24.6599C14.0178 24.6599 14.0893 24.5888 14.0944 24.4985V24.4991L14.285 22.1997L14.0944 18.6415C14.0893 18.551 14.0178 18.48 13.9313 18.48Z" fill="white" />
                <path d="M12.205 19.7166C12.1384 19.7166 12.0838 19.7699 12.0786 19.8409L11.8887 22.1984L12.0786 24.4792C12.0838 24.5497 12.1384 24.6031 12.205 24.6031C12.2711 24.6031 12.3253 24.5497 12.3312 24.4792L12.5469 22.1984L12.3312 19.8405C12.3253 19.7699 12.2711 19.7166 12.205 19.7166Z" fill="white" />
                <path d="M15.6861 24.6594C15.7937 24.6594 15.8813 24.5725 15.8858 24.4613L16.0512 22.2005L15.8858 17.4638C15.8815 17.3525 15.7937 17.2656 15.6861 17.2656C15.5775 17.2656 15.4898 17.3527 15.4862 17.464C15.4862 17.4642 15.3396 22.2005 15.3396 22.2005L15.4862 24.4621C15.4898 24.5723 15.5775 24.6594 15.6861 24.6594Z" fill="white" />
                <path d="M19.2779 24.6701C19.426 24.6701 19.5484 24.5478 19.551 24.3978V24.3995V24.3978L19.6663 22.202L19.551 16.731C19.5486 16.581 19.4259 16.459 19.2779 16.459C19.1294 16.459 19.0068 16.581 19.0048 16.7314L18.9016 22.2001C18.9016 22.2037 19.0048 24.3993 19.0048 24.3993C19.0069 24.5478 19.1295 24.6701 19.2779 24.6701Z" fill="white" />
                <path d="M17.4681 24.6614C17.597 24.6614 17.7011 24.5578 17.7045 24.426V24.4277L17.845 22.2013L17.7045 17.4297C17.7011 17.2977 17.597 17.1946 17.4681 17.1946C17.3382 17.1946 17.2343 17.2979 17.2313 17.4297L17.1064 22.2013L17.2316 24.4271C17.2343 24.5578 17.3384 24.6614 17.4681 24.6614Z" fill="white" />
                <path d="M13.0648 24.6405C13.1415 24.6405 13.2035 24.5791 13.2093 24.4982L13.4125 22.1983L13.2093 20.0106C13.2036 19.9299 13.1416 19.8689 13.0648 19.8689C12.987 19.8689 12.9251 19.9301 12.9202 20.0116L12.741 22.1984L12.9202 24.4981C12.925 24.5791 12.9869 24.6405 13.0648 24.6405Z" fill="white" />
                <path d="M11.352 24.5146C11.408 24.5146 11.4532 24.4703 11.4598 24.4092L11.6882 22.1981L11.46 19.9034C11.4533 19.8421 11.4081 19.7979 11.352 19.7979C11.2952 19.7979 11.2496 19.8424 11.244 19.9034C11.244 19.9036 11.043 22.1981 11.043 22.1981L11.244 24.4085C11.2496 24.4703 11.2952 24.5146 11.352 24.5146Z" fill="white" />
                <path d="M18.3696 17.3503C18.2293 17.3503 18.1172 17.4618 18.1144 17.6043L18.0005 22.2013L18.1143 24.4132C18.1171 24.5542 18.2292 24.6654 18.3695 24.6654C18.5092 24.6654 18.6209 24.5542 18.6241 24.4117V24.4136L18.7519 22.2016L18.6241 17.6038C18.6211 17.4618 18.5094 17.3503 18.3696 17.3503Z" fill="white" />
                <path d="M14.8054 24.6639C14.9022 24.6639 14.9821 24.5851 14.9868 24.4842L15.1646 22.1999L14.9868 17.8282C14.9821 17.7273 14.9022 17.6484 14.8054 17.6484C14.7077 17.6484 14.6282 17.7273 14.6238 17.8282C14.6238 17.8284 14.4663 22.1999 14.4663 22.1999L14.624 24.485C14.628 24.5851 14.7077 24.6639 14.8054 24.6639Z" fill="white" />
                <path d="M16.7915 24.4484V24.4473L16.9443 22.2007L16.7915 17.3043C16.7879 17.1826 16.6919 17.0874 16.5734 17.0874C16.4545 17.0874 16.3585 17.1826 16.3551 17.3043L16.2195 22.2007L16.3553 24.4482C16.3585 24.5684 16.4545 24.6636 16.5734 24.6636C16.6919 24.6636 16.7873 24.5683 16.7915 24.4475V24.4484Z" fill="white" />
                <path d="M28.3516 19.3782C27.9887 19.3782 27.6424 19.4517 27.3271 19.5838C27.1162 17.1967 25.1145 15.3242 22.6729 15.3242C22.0755 15.3242 21.4931 15.4419 20.9786 15.6409C20.7786 15.7183 20.7255 15.7978 20.7236 15.9523V24.3587C20.7258 24.5209 20.8513 24.6559 21.0096 24.6717C21.0164 24.6725 28.3044 24.676 28.3516 24.676C29.8144 24.676 31.0002 23.49 31.0002 22.027C31.0002 20.5641 29.8144 19.3782 28.3516 19.3782Z" fill="white" />
                <path d="M20.1823 15.9448C20.0233 15.9448 19.8926 16.0755 19.8904 16.2359L19.771 22.2025L19.8906 24.368C19.8927 24.5261 20.0233 24.6567 20.1823 24.6567C20.3409 24.6567 20.4715 24.5262 20.4739 24.3658V24.3683L20.6036 22.2025L20.4739 16.2355C20.4715 16.0756 20.3409 15.9448 20.1823 15.9448Z" fill="white" />
            </svg>
        </IconHolder>
    );
};
