import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

interface Props {
  dialogStyle?: React.CSSProperties;
}

export const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    '& .MuiDialog-paper': {
      maxWidth: ({ dialogStyle }: Props) => dialogStyle?.maxWidth || '678px',
      maxHeight: ({ dialogStyle }: Props) => dialogStyle?.maxHeight,
      borderRadius: ({ dialogStyle }: Props) => dialogStyle?.borderRadius ?? '8px',
      width: ({ dialogStyle }: Props) => dialogStyle?.width || '100%',
      height: ({ dialogStyle }: Props) => dialogStyle?.height,
      padding: ({ dialogStyle }: Props) => dialogStyle?.padding,
      margin: ({ dialogStyle }: Props) => `${dialogStyle?.margin} !important` ?? 'auto',
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: 'auto',
      },
    },
  },
  backdrop: {
    backgroundColor: 'red',
  },
}));

interface ModalContainerProps {
  padding?: string | number;
  maxWidth?: string;
  gap?: string;
  height?: string | number;
  margin?: string;
};

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => height};
  max-width:  ${({ maxWidth }: ModalContainerProps) => maxWidth || '678px'};
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: ${({ gap }) => gap || '32px'};
  margin: ${({ margin }) => margin || 'auto'};
`;
