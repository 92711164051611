import { Button } from "@material-ui/core";
import useStyles from "./styles";
import { LearningIndicatorIcon } from "components/svg-icons";

interface LearningIndicatorProps {
    text: string;
    onClick: () => void;
}

export default function LearningIndicator({ text, onClick }: LearningIndicatorProps) {
    const classes = useStyles();

    return (
        <div>
            <Button
                className={classes.textButton}
                onClick={onClick}
                startIcon={<LearningIndicatorIcon />}
            >
                {text}
            </Button>
        </div>
    );
}
