import styled from 'styled-components';

interface MainContainerProps {
  mobile: boolean,
  useDefaultPadding?: boolean,
  padding?: string,
}

export const MainContainer = styled.div`
  display: flex;
  position: ${({ mobile }: MainContainerProps) => mobile ? "sticky" : "static"};
  top: 0px;
  z-index: 10;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF;
  border-radius: ${({ mobile }: MainContainerProps) => mobile ? "0" : "8px"};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: ${({ mobile }: MainContainerProps) => mobile ? "1px solid #EDECF2" : "none"};
  padding: ${({ useDefaultPadding, padding, mobile }: MainContainerProps) => (
    !useDefaultPadding ? padding :
      mobile ? "16px 24px" : "24px 40px")};
  width: 100%;
  gap: 24px;
`;


export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: ${({ useDefaultPadding, padding, mobile }: MainContainerProps) => (
    !useDefaultPadding ? padding :
      mobile ? "16px 24px" : "24px 40px")};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Title = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #000000;
`;

export const Subtitle = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
`;
