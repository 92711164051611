import { FunctionComponent } from "react";

interface CloseIconProps {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: string;
}

const CloseIcon: FunctionComponent<CloseIconProps> = ({
  width = 24,
  height = 25,
  color = "black",
  strokeWidth = "2",
}: CloseIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M18 6.01953L6 18.0195"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6.01953L18 18.0195"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
};

export default CloseIcon;
