import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 40px 24px;
  background-color: #ffffff;
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    border-radius: 0px;
  };
`;

export const MainContainer = styled.div`
  background-color: #f5f3f6;
  width: 100%;
  height: 200%;
`;

export const MainChartBackground = styled.div`
background: rgba(136,0, 255, 4%);
border-radius: 16px;
padding-top: 24px;
padding-bottom: 24px;

@media (max-width: 768px) {
  padding-top: 12px;
  padding-bottom: 12px;
}
`

export const CardContainer = styled.div`
  border-radius: 8px;
  background: white;
  max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
`


interface StyledBtnProps {
  selected?: boolean;
}


export const DateSelectorButton = styled.button`
  background: ${({ selected }: StyledBtnProps) => selected ? "#8800FF" : "none"};
  width: auto;
  color: ${({ selected }: StyledBtnProps) => selected ? "#fff" : "#8800ff"};
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  margin-right: 0.8rem;
  &:last-of-type {
    margin-right: 0;
  }
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BannerContainer = styled.div`
  max-width: 1200px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`