import styled from "styled-components";
import { MEDIA_BREAKPOINTS } from "types/global";

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    margin-top: 24px;
    max-width: 800px;
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    ${MEDIA_BREAKPOINTS.smallView} {
        border-radius: 0px;
    };
`