import { makeStyles, Theme } from "@material-ui/core";
import { SystemColors } from "types/globalStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  dataGridRoot: {
    border: 'none !important',
    outline: 'none !important',
    '& .MuiDataGrid-row:hover': {
      background: 'none !important',
    },
    '& .MuiDataGrid-main, .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `${SystemColors.PAPER_CONTAINER} !important`,
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    '& .MuiDataGrid-cell, .MuiDataGrid-row': {
      borderTop: `1px solid ${SystemColors.DIVIDER_LINE}`,
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row:last-of-type': {
      outline: 'none',
      borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    },
    '& .MuiDataGrid-cell:hover': {
      cursor: 'default',
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus':
      {
        outline: 'none !important',
      },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none !important',
    },
    '$ .MuiDataGrid-columnHeaderTitle': {
      fontSize: '16px',
      fontFamily: 'DIN',
      color: `${SystemColors.SECONDARY_TEXT_COLOR} !important`,
    },
  },
  disabledChevron: {
    cursor: 'not-allowed',
    height: '16px',
    width: '16px',
    '& path': {
      stroke: SystemColors.DISABLED_TEXT_COLOR,
    },
  },
  enabledChevron: {
    cursor: 'pointer',
    height: '16px',
    width: '16px',
    '& path': {
      stroke: SystemColors.PRIMARY_TEXT_COLOR,
    },
  },
}));