import {
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  SetStateAction,
  Dispatch,
  useContext,
  useMemo,
  ChangeEvent,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CurrentBrand,
  FbSuggestion,
  CreatedCampaign,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  ConversionsStatus,
  GeographicTargetingType,
  FacebookPageType,
  InstagramPageType,
  AdAccountPageType,
  PixelConversionEvent,
  CurrencyCodesType,
  UserData,
} from "types/global"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import LinkSelectorTab from "./LinkSelectorTab"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { useMediaQuery, useTheme } from "@material-ui/core"
import useStyles from "./styles"
import {
  handleConnectionsType,
  getNewCampaignName,
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  STEPS,
  getNextButtonLabel,
  getNextButtonWidth,
  getIfDraftHasConversionsEnabled,
} from "./utils"
import startCase from "lodash/startCase"
import Grid from "@material-ui/core/Grid"
import CustomStepper from "components/shareable/CustomStepper"
import TopBar from "components/shareable/TopBar"
import { MarketingDataContext } from "../Data/Provider"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "../SongAds/TargetingView/data"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import {
  CAMPAIGN_CREATED,
  CREATING_CAMPAIGN,
  GOAL,
  mainTitle,
} from "./constants"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import clsx from "clsx"
import {
  AssetUploadTypes,
  getAdAccountDetails,
  getFbAccountDetails,
  getIfPagesAreSelected,
  PreConnectionConfigs,
  UploadedAssetType,
  SelectedFbAdAccount,
  SelectedFbPage,
  getPreconnectionConfigurationsStatus,
  getInstagramDetails,
  validateInstagram,
} from "../utils/fbCampaigns"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { checkIsValidUrl, fixGeographicTargets } from "utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { LoadingIndicator } from "components/Loader"
import { LoadingContainer } from "./styles"
import { fbLoggedIn } from 'helpers/FB'
import useSwitch from "Hooks/useSwitch"
import useBanner from "Hooks/useBanner"
import Logger from "Logger"
import Axios from "helpers/Interceptor"
import useDropDown from "Hooks/useDropDrown"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPE_ENUM, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "../../../../constants"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { MobileAdPreviewBar } from "../SongAds/MobileAdPreviewBar"
import { AdPreview } from "./AdPreview"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { getAccountStatus, getTosStatus } from "pages/post-auth/MarketingPage/utils"
import useTermsOfServiceTimer from "Hooks/useTermsOfServiceTimer"
import { CreativeAssetTypes } from "../constants"
import { getTosStatus as getTosStatusApi } from '../api'
import { getConvertedCurrency } from "services/symphonyApi"
import { CurrentBrandContext } from "../../../../Hooks/CurrentBrandContext"

const { LINK, CREATIVE, BUDGET, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [LINK, CREATIVE, BUDGET, FINALIZATION]
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  currentBrand: CurrentBrand
  refreshCampaigns: () => void
  reloadBrands: () => void
  currentUser: UserData
}

const LinkClicks: FunctionComponent<Props> = ({
  currentBrand,
  setGoal,
  refreshCampaigns,
  reloadBrands,
  currentUser
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const location = useLocation()
  const { mediumView } = useMediaBreakpoints()
  const { reloadBrand } = useContext(CurrentBrandContext)
  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData, saveData } = useContext(ConnectModalsContext)
  const { isDisabledConnectButton, handleConnect: handleConnectModal, loading } = connectModalData
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveAdditionalPageAudiences,
    saveArtists,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)

  const defaultGeographyTargeting = geographyTargetingDefaults(CAMPAIGN_TYPE_ENUM.link_clicks)
  const [targets, setTargets] = useState<GeographicTargetingType[]>(
    defaultGeographyTargeting
  )


  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(
    Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2))
  )

  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])

  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, LINK)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] = useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)
  const [eventsLoading, setEventsLoading] = useState<boolean>(false)

  // This is primarily used for validation purposes
  // when connecting an IG
  const [instagramPagesAvailable, setInstagramPagesAvailable] = useState<boolean>(false)

  // MAIN CONNECTIONS
  const [selectedFBBusinessManager, setFBBusinessManager] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager
  )
  const [selectedFBPixel, setFBPixel] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager &&
    currentBrand.connections.facebook_ad_account &&
    currentBrand.connections.facebook_pixel
  )
  const [selectedFB_page, setSelectedFB_page] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && currentBrand.connections.facebook_business_manager
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.facebook_page
  )
  const [selectedFBAdAccount, setFBAdAccount] = useState<any>(
    currentBrand.connections?.facebook_ad_account
  )
  const [selectedInstaPage, setSelectedInstaPage] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && (currentBrand.connections.facebook_business_manager
    currentBrand.connections.facebook_page &&
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.instagram_page
  )
  const [selectedInstagramPost, setSelectedInstagramPost] =
    useState<InstagramPost>()
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(UPLOAD_VIDEO);

  const [connectionsType, setConnectionsType] = useState<string>("quick")
  const [currentLink, setCurrentLink] = useState<string>("")

  // organization 
  const primaryOrganization = currentUser.organization

  // for video uploader & ad previews
  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(UPLOAD_VIDEO)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])


  const [campaignName, setCampaignName] = useState<string>("")
  const [caption, setCaption] = useState<string>("")
  const [cta, setCTA] = useState<{
    value: string
    label: string
  }>({ value: "LEARN_MORE", label: "Learn More" })

  const [showPreview, enablePreviewMode] = useState<boolean>(false)
  const [interestTargetingAudiences, setInterestTargetingAudiences] =
    useState<any>(null)
  const [validatedAudiencesAlready, setValidatedAudiences] =
    useState<boolean>(false)

  // budgeting
  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);
  // if the budget is less than the recommended budget
  // TODO: finalize actual recommended budget

  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  let recommendedUsdBudget = 100;
  const checkMinimumBudgetStatus = async () => {
    const convertedBudget = await getConvertedCurrency({ from: currency, to: "USD", amount: budgetValue });
    return convertedBudget < recommendedUsdBudget;
  }
  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(defaultGeographyTargeting);
      saveTargets(defaultGeographyTargeting);
    }
  }, [isBelowRecommendedBudget]);

  // start and end date logistics

  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [preConnectionConfigurations, setPreConnectionConfigurations] =
    useState<PreConnectionConfigs | null>(null)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] =
    useState(false)
  const [adConnectionsAdded, setAdConnectionsAdded] = useState(false)
  const [adConnectionsConfirmed, setAdConnectionsConfirmed] = useState<boolean>(false)
  const [uploadQueueCreativeTab, setUploadQueueCreativeTab] = useState<
    Record<string, unknown>[] | null
  >(null)
  const [connectModalType, setConnectModalType] = useState("")
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<
    string,
    unknown
  > | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [isValidURL, setIsValidURL] = useState(false)
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false)
  const { time, startTimer, handleStartTimer } = useTermsOfServiceTimer({
    selectedFBAdAccount,
    setPreconnectConfigurations: setPreConnectionConfigurations,
  })

  const showLoading = !!draft && retrievingDraftData

  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
    onClickHandler: (step: string) => () => {
      if (step === LINK) setSelectedTab(step)
    },
  }))

  const conversionsStatus = currentBrand?.conversionsStatus || {} as ConversionsStatus
  const conversions_tasks_completed = conversionsStatus?.conversions_tasks_completed
  const isEventRegistered = conversionsStatus?.isEventRegistered

  const conversionsApiIndicator = useSwitch({
    initiallyEnabled: false,
  });

  const conversionsCallToAction = useBanner();
  const conversionsEvents = useDropDown({ values: [], initialValue: null })

  const genres = currentBrand.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const previouslySelectedBizMgr = useRef<any>()
  const previouslySelectedFbPage = useRef<any>()
  const previouslySelectedAdAccount = useRef<any>()

  const uploadingAssets =
    !!uploadQueueCreativeTab && uploadQueueCreativeTab.length > 0

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )

  const dataToSaveDraft = [
    currentLink,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    conversionsApiIndicator.enabled,
    conversionsEvents.selectedValue,
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = !!audiences.length ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = !!targets.length
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const saveConnectModalType = (type: string) => setConnectModalType(type)

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])
    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveUploadQueueCreativeTab = (
    queue: Record<string, unknown>[] | null
  ) => {
    setUploadQueueCreativeTab(queue)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: "increase_link_clicks",
      step: 2,
      name: "connect_accounts",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      addedFacebook: selectedFB_page ? true : false,
      addedInstagram: selectedInstaPage ? true : false,
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    track("Completed Campaign Step - Creative Assets", {
      type: "increase_link_clicks",
      step: 3,
      name: "add_creatives",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      caption: caption,
      cta: typeof cta === "object" ? cta.value : cta,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickCloseTopBar = () => {
    setGoal(null)
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === STEPS.LINK) {
      history.push("/marketing")
    } else if (selectedTab === CREATIVE) {
      if (adConnectionsConfirmed) {
        setAdConnectionsConfirmed(false)
      } else {
        setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
      }
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === LINK && currentLink) {
      setSelectedTab(CREATIVE)
    } else if (selectedTab === CREATIVE && connectModalType === "") {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === CREATIVE && connectModalType !== "") {
      handleConnectModal()
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  const createdCampaign = (campaign: CreatedCampaign) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  const handleLinkTabNextButton = () => {
    track("Completed Campaign Step - Link Selection", {
      type: "increase_link_clicks",
      step: 1,
      name: "add_link",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      link: currentLink,
    })

    setSelectedTab(CREATIVE)
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: "increase_link_clicks",
      step: 4,
      name: "set_budget",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  const loadConversionsEvents = async () => {
    try {
      setEventsLoading(true)
      const response = await Axios.get('/brand/conversions/events')
      const events: PixelConversionEvent[] = response.data.data
      if (events.length) {
        conversionsEvents.updateOptions(events)
      }
    } catch (error) {
      Logger.error(error)
    }
    setEventsLoading(false)
  }

  async function getTosConfiguration(selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null, adAccountDetails: Record<string, unknown> | false) {
    if (!adAccountDetails || !selectedFBAdAccount) return null

    const termsOfService = await getTosStatusApi(selectedFBAdAccount.id);
    const toDoAccountStatus = getAccountStatus(selectedFBAdAccount as { id: string }, adAccountDetails)

    if (termsOfService.status && !toDoAccountStatus.status) termsOfService.status = false;

    termsOfService.account_status = toDoAccountStatus.account_status
    setPreConnectionConfigurations(termsOfService)

    return termsOfService
  }

  const checkConnectedAccounts = async (args: {
    selectedInstaPage: InstagramPageType | null,
    selectedFB_page: SelectedFbPage | FacebookPageType | null,
    selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null,
  }) => {
    if (draft && !campaignDraftData) return
    if (!selectedTab || selectedTab === LINK) return

    const {
      selectedFBAdAccount,
      selectedFB_page,
      selectedInstaPage,
    } = args

    if ((conversionsStatus.isError && currentLink && !isValidURL)) {
      setSelectedTab(CREATIVE)
    }

    saveData(prev => ({ ...prev, loading: true }))

    const adAccountDetails = await getAdAccountDetails(selectedFBAdAccount)
    const fbAccountDetails = await getFbAccountDetails(selectedFB_page)
    const igAccountDetails = await getInstagramDetails(selectedInstaPage)
    const preConnectionConfigurations = await getTosConfiguration(selectedFBAdAccount, adAccountDetails)

    if (preConnectionConfigurations) {
      const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
      setAreTermsAccepted(currentTermsStatus)
    }

    const hasAllConnections = Boolean(selectedFBAdAccount && selectedInstaPage && selectedFBAdAccount)
    if (!hasAllConnections && currentLink) {
      fbLoggedIn()
      setAdConnectionsAdded(false)
      setSelectedTab(CREATIVE)
      saveData(prev => ({ ...prev, loading: false }))
      return;
    }

    const areAllConnectionsValid = Boolean(adAccountDetails && fbAccountDetails && igAccountDetails)
    if (!areAllConnectionsValid && currentLink) {
      setSelectedTab(CREATIVE)
      removeConnections()
      conversionsApiIndicator.disable()
    }

    if (hasAllConnections && areAllConnectionsValid) {
      setAdConnectionsAdded(true)
    }

    const isValidInstagram = await validateInstagram(selectedInstaPage, fbAccountDetails)
    if (!isValidInstagram) {
      setSelectedInstaPage(null)
      setAdConnectionsAdded(false)
      setAdConnectionsConfirmed(false)
    }

    saveData(prev => ({ ...prev, loading: false }))
  }

  const removeConnections = () => {
    setFBAdAccount(null)
    setSelectedFB_page(null)
    setSelectedInstaPage(null)
    setAdConnectionsAdded(false)
    setAdConnectionsConfirmed(false)
  }

  useEffect(() => {
    previouslySelectedBizMgr.current = selectedFBBusinessManager
    previouslySelectedFbPage.current = selectedFB_page
    previouslySelectedAdAccount.current = selectedFBAdAccount

    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
    })

    loadConversionsEvents()
  }, [])

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab, showTargetingView])

  useEffect(() => {
    if (!draft) {
      handleConnectionsType({
        connectionsType,
        selectedFBBusinessManager,
        selectedFBAdAccount,
        currentBrand,
        previouslySelectedBizMgr,
        previouslySelectedAdAccount,
        selectedFB_page,
        previouslySelectedFbPage,
        setSelectedInstaPage,
        setFBBusinessManager,
        setFBAdAccount,
        setFBPixel,
      })
    }
  }, [
    draft,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    selectedFB_page,
    connectionsType,
  ])

  useEffect(() => {
    const draftMetadata = campaignDraftData?.campaign_metadata as { campaign_input: { brand: CurrentBrand, conversionsEventName: string } }
    const { conversionsEventName: draftEventName } = draftMetadata?.campaign_input || {}
    const draftConnections = draftMetadata?.campaign_input.brand.connections

    const {
      facebook_page: draftFbPage,
      instagram_page: draftIgPage,
      facebook_ad_account: draftAdAccount
    } = draftConnections || {}

    conversions_tasks_completed ? conversionsCallToAction.hide() : conversionsCallToAction.show()

    const draftHasConversions = getIfDraftHasConversionsEnabled({ campaignDraftData })

    if (!conversions_tasks_completed || (draft && !draftHasConversions)) {
      conversionsApiIndicator.disable()
    } else if (draft && draftHasConversions) {
      conversionsApiIndicator.enable()
    }

    checkConnectedAccounts({
      selectedFB_page: campaignDraftData ? draftFbPage : selectedFB_page,
      selectedFBAdAccount: campaignDraftData ? draftAdAccount : selectedFBAdAccount,
      selectedInstaPage: campaignDraftData ? draftIgPage : selectedInstaPage
    })

    if (draftHasConversions && draftEventName) {
      conversionsEvents.setSelectedValue(draftEventName)
    }

  }, [selectedFB_page, selectedFBAdAccount, selectedInstaPage, campaignDraftData])

  useEffect(() => {
    if (conversionsApiIndicator.enabled && !conversionsEvents.selectedValue) {
      setSelectedTab(CREATIVE)
    }
  }, [conversionsApiIndicator.enabled])

  useEffect(() => {
    if (currentLink && currentLink.length > 0) {
      const newCampaignName = getNewCampaignName(currentLink)

      if (newCampaignName) setCampaignName(newCampaignName)
    }
  }, [currentLink])

  useDraftDataGetter({
    draft,
    checkConnectedAccounts,
    setters: {
      setCurrentLink,
      setCaption,
      setCTA,
      setRetrievingDraftData,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setFBBusinessManager,
      setFBPixel,
      setSelectedFB_page,
      setFBAdAccount,
      setSelectedInstaPage,
      setTargets,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      setAdditionalPagesAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAddVisualSelected,
      setIsValidURL
    } as Record<string, Dispatch<unknown>>,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    audiencesData,
    dataToSaveDraft,
    requiredDataChanged,
    retrievingDraftData,
    currentLink,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    currentBrand,
    draft,
    savingDraftStatus,
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean = useMemo(
    () => selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed,
    [selectedTab, adConnectionsAdded, adConnectionsConfirmed]
  )

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(
      connectionsType,
      {
        fbPixel: selectedFBPixel,
        fbAdAccount: selectedFBAdAccount,
        fbPage: selectedFB_page,
        instaPage: selectedInstaPage,
      },
      {
        fbPixel: false,
        fbAdAccount: true,
        fbPage: true,
        instaPage: false,
      }
    )
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      arePagesSelected,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
      cta,
      caption,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)

    if (disableForTargeting) return true
    switch (selectedTab) {
      case LINK:
        return Boolean(!currentLink || (currentLink && !isValidURL))
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()
        const conditionsToDisable = [
          disableNextButtonWhenCreativeTab && connectModalType === "",
          connectModalType !== "" && isDisabledConnectButton,
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          conversionsApiIndicator.enabled && !conversionsEvents.selectedValue && connectModalType === "",
          connectModalType === "" && selectedFBAdAccount && !areTermsAccepted,
          connectModalType === "instagram" && !instagramPagesAvailable,
        ]
        return conditionsToDisable.some(condition => Boolean(condition))
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView

      default:
        return false
    }
  }

  return creatingCampaign || finished ? (
    <>
      <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
      <CampaignCreationPage
        reloadBrands={reloadBrands}
        reloadBrand={reloadBrand}
        primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
        draft={campaignDraftData?.id as string}
        setupType={connectionsType}
        link={currentLink}
        campaignName={campaignName}
        brand={currentBrand}
        goal={GOAL}
        fbPage={selectedFB_page}
        fbBusinessManager={selectedFBBusinessManager}
        fbAdAccount={selectedFBAdAccount}
        fbPixel={selectedFBPixel}
        igPage={selectedInstaPage}
        igMedia={igMedia}
        budget={budgetValue}
        startDate={startDate}
        endDate={endDate}
        assets={{ all: uploadedImages }}
        caption={caption}
        cta={cta}
        createdCampaign={createdCampaign}
        finished={finished}
        customAudiences={customAudiences}
        additionalPageAudiences={additionalPageAudiences}
        recentlyCreatedCampaign={recentlyCreatedCampaign}
        refreshCampaigns={refreshCampaigns}
        setSelectedTab={setSelectedTab}
        setFinished={setFinished}
        allowCampaignCreation={allowCampaignCreation}
        fbSuggestions={fbSuggestions}
        conversionsEnabled={conversionsApiIndicator.enabled}
        conversionsEventName={conversionsEvents.selectedValue || ''}
      />
    </>
  ) : (
    <Grid
      className={
        selectedTab === CREATIVE && adConnectionsAdded
          ? classes.creativeTabHighMarginBottom
          : classes.normalMarginBottom
      }
    >
      <TopBar
        showSaveDraftChip={!!draft || !!currentLink}
        savingChanges={isSavingDraft}
        showCloseIcon
        title={mainTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            isNextButtonMobile,
            showTargetingView,
            selectedTab,
            connectModalType,
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab,
          }),
          loadingNextButton: loading,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mediumView && canShowAdPreview}
          />
        }
      >
        {showLoading && (
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        )}
        {!showLoading && (
          <MainContentContainer className={clsx(
            classes.mainContainer,
            canShowAdPreview && classes.creativeTabContainer
          )}>
            <Grid
              className={clsx(
                `bg-white h-full w-full rounded-${isMobile ? "none" : "xl mt-6"
                }`,
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ? (
                <TargetingView
                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  selectedFBPage={selectedFB_page}
                  selectedFBAdAccount={selectedFBAdAccount}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) =>
                    setAudiences(audiences)
                  }
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}
                  handleTargets={(targets: GeographicTargetingType[]) =>
                    setTargets(targets)
                  }

                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) =>
                    setIsEditingTargeting(isEditing)
                  }
                  showSaveButton={false}
                />
              ) : (
                <>
                  <div className="w-full">
                    <TabPanel hidden={selectedTab !== LINK}>
                      <LinkSelectorTab
                        currentLink={currentLink}
                        handleChangeLink={(e: ChangeEvent<HTMLInputElement>) => {
                          const value = e.target.value.trim()
                          const isValidURL = checkIsValidUrl(value)
                          setIsValidURL(isValidURL)
                          setCurrentLink(value)
                        }}
                        handleOnClickContinue={handleLinkTabNextButton}
                        showNextButton={false}
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== CREATIVE}>
                      <CreativeTab
                        setInstagramPagesAvailable={setInstagramPagesAvailable}
                        setPreviewedVideo={setPreviewedVideo}
                        previewedVideo={previewedVideo}
                        creativeSelectorTab={creativeSelectorTab}
                        setCreativeSelectorTab={setCreativeSelectorTab}
                        setAddVisualSelected={setAddVisualSelected}
                        reloadBrands={reloadBrands}
                        selectedAssets={uploadedImages}
                        setSelectedAssets={setUpload}
                        selectedFB_page={selectedFB_page}
                        setSelectedFB_page={setSelectedFB_page}
                        selectedInstaPage={selectedInstaPage}
                        setSelectedInstaPage={setSelectedInstaPage}
                        selectedFBBusinessManager={selectedFBBusinessManager}
                        setFBBusinessManager={setFBBusinessManager}
                        selectedFBPixel={selectedFBPixel}
                        setFBPixel={setFBPixel}
                        selectedFBAdAccount={selectedFBAdAccount}
                        setFBAdAccount={setFBAdAccount}
                        caption={caption}
                        setCaption={setCaption}
                        cta={cta}
                        setCTA={setCTA}
                        brand={currentBrand}
                        selectedInstagramPost={selectedInstagramPost}
                        setSelectedInstagramPost={setSelectedInstagramPost}
                        preConnectionConfigurations={preConnectionConfigurations}
                        setPreConnectionConfigurations={setPreConnectionConfigurations}
                        saveUploadQueue={saveUploadQueueCreativeTab}
                        adConnectionsAdded={adConnectionsAdded}
                        adConnectionsConfirmed={adConnectionsConfirmed}
                        showContinueButton={false}
                        saveConnectModalType={saveConnectModalType}
                        toggleConversionsIndicator={conversionsApiIndicator.toggle}
                        isConversionsEnabled={conversionsApiIndicator.enabled}
                        isEventRegistered={isEventRegistered}
                        isConversionsSetUp={conversions_tasks_completed}
                        showConversionsError={Boolean(conversionsStatus.isError)}
                        conversionsTasks={conversionsStatus.conversions_tasks}
                        showConversionsCTA={conversionsCallToAction.visible}
                        hideConversionsCTA={conversionsCallToAction.hide}
                        conversionsEvents={conversionsEvents.options}
                        selectConversionsEvent={conversionsEvents.setSelectedValue}
                        selectedConversionsEvent={conversionsEvents.selectedValue || ''}
                        eventsLoading={eventsLoading}
                        areTermsAccepted={areTermsAccepted}
                        time={time}
                        handleStartTimer={handleStartTimer}
                        startTimer={startTimer}
                      />
                    </TabPanel>
                    <SpendBudgetProvider
                      {...{ budgetValue }}
                      currentUser={currentUser}

                      step={selectedTab as string}
                      fbAccountId={selectedFBAdAccount?.id || null}
                      campaignType={GLOBAL_CAMPAIGN_TYPES.link_clicks}
                    >
                      <TabPanel hidden={selectedTab !== BUDGET}>
                        <BudgetTab
                          updatedCurrency={updatedCurrency}
                          budgetValue={budgetValue}
                          setBudgetValue={setBudgetValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          fbAdAccountId={
                            selectedFBAdAccount ? selectedFBAdAccount.id : null
                          }
                          fbAdAccountName={selectedFBAdAccount ? selectedFBAdAccount.name : null}
                          fbBusinessId={selectedFBBusinessManager}
                          brand={currentBrand!}
                          finishFlow={handleBudgetTabNextButton}
                          saveWhenNextButtonIsDisabled={
                            saveWhenBudgetTabButtonIsDisabled
                          }
                          showReviewButton={false}
                        />
                      </TabPanel>
                      <TabPanel hidden={selectedTab !== FINALIZATION}>
                        <Finalization
                          primaryOrganization={primaryOrganization}
                          isBelowRecommendedBudget={isBelowRecommendedBudget}
                          draft={campaignDraftData?.id}
                          campaignName={campaignName}
                          brand={currentBrand}
                          goal={GOAL}
                          fbPage={selectedFB_page}
                          fbBusinessManager={selectedFBBusinessManager}
                          fbAdAccount={selectedFBAdAccount}
                          fbPixel={selectedFBPixel}
                          igPage={selectedInstaPage}
                          budget={budgetValue}
                          startDate={startDate}
                          endDate={endDate}
                          selectedLink={currentLink}
                          allowCampaignCreation={allowCampaignCreation}
                          setSelectedTab={setSelectedTab}
                          caption={caption}
                          cta={cta}
                          interestTargetingAudiences={interestTargetingAudiences}
                          setInterestTargetingAudiences={
                            setInterestTargetingAudiences
                          }
                          validatedAudiencesAlready={validatedAudiencesAlready}
                          setValidatedAudiences={setValidatedAudiences}
                          setShowTargetingView={setShowTargetingView}
                          fbSuggestions={fbSuggestions}
                          assets={{
                            all: uploadedImages,
                          }}
                          igMedia={igMedia}
                          addVisualSelected={addVisualSelected}
                          cancelSaving={cancelSavingDraft}
                          conversionsEnabled={conversionsApiIndicator.enabled}
                          setTargets={setTargets}
                          handleSaveTargetingData={handleSaveTargetingData}
                          conversionsEvent={conversionsEvents.selectedValue}
                          additionalPageAudiences={additionalPageAudiences}
                        />
                      </TabPanel>
                    </SpendBudgetProvider>
                  </div>
                </>
              )}
            </Grid>
            <AdPreview
              {...{
                cta,
                selectedInstaPage,
                showPreview,
                selectedFB_page,
                creativeSelectorTab,
                selectedInstagramPost,
                caption,
                previewedVideo,
                uploadedImages,
                canShowAdPreview,
                enablePreviewMode,
                muted: Boolean(
                  !adConnectionsAdded
                  || !adConnectionsConfirmed
                  || Boolean(conversionsStatus.isError)
                  || !areTermsAccepted
                  || (conversionsApiIndicator.enabled && !(conversionsEvents?.selectedValue))
                  || selectedTab !== CREATIVE)
              }}
            />
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default LinkClicks
