import Lottie from "react-lottie";
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import SymphonyLoadingLottieBlack from 'assets/images/lotties/SymphonyLoading.json';

interface Loaderv2Props {
    height?: number;
    width?: number;
    color?: 'default' | 'black'
    rendererSettings?: {
        preserveAspectRatio?: string | undefined;
        context?: any;
        scaleMode?: any;
        clearCanvas?: boolean | undefined;
        progressiveLoad?: boolean | undefined;
        hideOnTransparent?: boolean | undefined;
        className?: string | undefined;
    } | undefined
}

export default function Loaderv2(props: Loaderv2Props) {
    const {
        height = 24,
        width = 24,
        color = "default",
        rendererSettings
    } = props;

    return (
        <Lottie
            height={height}
            width={width}
            options={{
                rendererSettings: rendererSettings,
                loop: true,
                autoplay: true,
                animationData: color === 'black' ? SymphonyLoadingLottie : SymphonyLoadingLottieBlack,
            }}
        />
    )
}