/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
} from "react"
import "styles/react-datepicker.css"

import dayjs from "dayjs"
import BudgetCard from "./Components/BudgetCard"
import DatesCard from "./Components/DatesCard"
import RunningForCard from "./Components/RunningForCard"
import { checkIfIsProTier } from "utils"
import { CurrentBrand } from "types/global"
import { Container } from "./styles"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { minimumDailyBudgetCalculator } from "../constants"

interface BudgetProps {
  budgetValue: number
  startDate: Date | null
  endDate: Date | null
  geographyType: string
  brand: CurrentBrand
  showReviewCampaignButton?: boolean
  setBudgetValue: Dispatch<SetStateAction<number>>
  setStartDate: Dispatch<SetStateAction<Date | null>>
  setEndDate: Dispatch<SetStateAction<Date | null>>
  finishFlow: () => void
}

export default function BudgetTab({
  geographyType,
  budgetValue,
  startDate,
  endDate,
  brand,
  showReviewCampaignButton,
  setBudgetValue,
  setStartDate,
  setEndDate,
  finishFlow,
}: BudgetProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const [currentEndSchedule] = useState("scheduled")
  const disableMobileButton = !(
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue >= 5 &&
    startDate &&
    endDate
  )
  const isProUser = checkIfIsProTier(brand)

  const handleChangeBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= 0) setBudgetValue(value)
  }

  const handleSelectStartDate = (date: Date | null) => setStartDate(date)

  const handleSelectEndDate = (date: Date | null) => setEndDate(date)

 

  return (
    <Container>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <BudgetCard
          {...{
            budgetValue,
            isProUser: true, // force PRO
          }}
          handleChangeInputText={handleChangeBudget}
        />
      </Grid>
      {/* <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DatesCard
          {...{
            startDate,
            endDate,
            currentEndSchedule,
            budgetValue,
            handleSelectStartDate,
            handleSelectEndDate,
          }}
        />
      </Grid> */}
      {/* <RunningForCard
        {...{
          geographyType,
          budgetValue,
          startDate,
          endDate,
          finishFlow,
          disableMobileButton,
          showReviewCampaignButton,
        }}
      /> */}
    </Container>
  )
}
