import { Backdrop, Fade, IconButton, Modal, SvgIcon } from "@material-ui/core";
import { setUserProperty, track } from "analytics";
import InstagramBusinessDiscoveryConnect from "components/connect/InstagramBusinessDiscovery";
import TiktokConnect from "components/connect/TikTok";
import TwitterConnect from "components/connect/Twitter";
import CloseButton from "components/shareable/CloseButton";
import { fbLoggedIn, loginWithFB } from "helpers/FB";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import React, { Component, useContext, useEffect, useState } from "react";
import { siteIconsData } from "./Dashboard";
import { GettingStartedContext } from 'Hooks/GettingStartedContext';
import { CurrentBrand, SpotifyArtist, SYMPHONY_TASKS } from "types/global";
import SpotifyConnect from 'components/connect/connectModals/Spotify';
import FacebookConnectv2 from "components/connect/FacebookConnectv2";
import YouTubeConnect from "../AddArtist/Components/ConnectPlatformModal/Youtube";
import LinkButton from "components/shareable/LinkButton";
import AudiomackConnectv2 from "components/connect/Audiomack";
import SoundcloudConnectv2 from "components/connect/Soundcloud";
import DeezerConnectv2 from "components/connect/Deezer";

interface PlatformsConnectorProps {
    open: boolean;
    closeModal: () => void;
    updateBrandConnections: () => Promise<void>;
    onArtistAlreadyExist: (artist: SpotifyArtist, existingBrand: CurrentBrand) => void;
}

export interface PlatformType {
    id: string;
    name: string;
    needsConnection: boolean;
    imageAsset: string,
    username: string | undefined | null,
    connectorComponent: JSX.Element
}

export default function PlatformsConnector(props: PlatformsConnectorProps) {

    const {
        open,
        closeModal,
        updateBrandConnections,
        onArtistAlreadyExist
    } = props


    const {
        currentBrand,
        setCurrentBrand,
        reloadBrand,
        loadingBrand
    } = useContext(CurrentBrandContext);

    const brand = currentBrand!

    const [editedPlatform, setEditedPlatform] = useState<PlatformType | undefined>(undefined)

    const {
        finishedTasks,
        fetchOnboardingTasks,
    } = useContext(GettingStartedContext);

    function onSave(platform: string) {
        updateBrandConnections()
        track('Linked Platform',
            {
                brand_id: brand!.id,
                brand_slug: brand!.slug,
                brand_name: brand!.name,
                platform: platform,
                page: "Dashboard",
                from: "connector"
            });

        setUserProperty(`${platform}_linked`, true)
        reloadBrand()
        setEditedPlatform(undefined)
        if (!finishedTasks.includes(SYMPHONY_TASKS.FANBASE_COMPLETED)) {
            fetchOnboardingTasks()
        }
    }

    const PLATFORMS: PlatformType[] = brand && brand.connections ?
        [
            {
                id: 'spotify',
                name: 'Spotify',
                imageAsset: siteIconsData['spotify'],
                needsConnection: !brand || !brand?.connections?.spotify_artist_page?.id,
                username: brand && brand.connections.spotify_artist_page ? brand.connections.spotify_artist_page.name : '',
                connectorComponent: <SpotifyConnect
                    goBack={() => setEditedPlatform(undefined)}
                    closeModal={() => {
                        closeModal();
                        setEditedPlatform(undefined);
                    }}
                    localSave={false}
                    open={true}
                    handleShowAlreadyExistsModal={onArtistAlreadyExist}
                    previouslyConnected={Boolean(brand?.connections?.spotify_artist_page?.id)}
                    onSave={() => onSave("spotify")}
                    artistName={brand && brand.connections.spotify_artist_page ? brand.connections.spotify_artist_page.name : ''}
                    currentBrand={brand}
                />,
            },

            {
                id: 'youtube',
                name: "YouTube",
                imageAsset: siteIconsData['youtube'],
                needsConnection: brand && brand.connections.youtube_channel ? false : true,
                username: brand && brand.connections.youtube_channel ? brand.connections.youtube_channel.snippet.title : '',
                connectorComponent: (
                    <YouTubeConnect
                        open={true}
                        youtubeSearchString={''}
                        youtubeSearchResults={[]}
                        artist={brand}
                        selectedYoutubeChannel={brand?.connections?.youtube_channel}
                        localSave={false}  // assuming you want to save the data remotely
                        setYoutubeSearchString={console.log}
                        selectYoutubeChannel={console.log}
                        closeModal={() => setEditedPlatform(undefined)}
                        onSave={() => onSave('youtube')}
                    />
                )


            },
            {
                id: 'facebook',
                name: "Facebook",
                imageAsset: siteIconsData['facebook'],
                needsConnection: brand && (brand.connections.facebook_page && brand.connections.facebook_page.id) && (!brand.connections.reauthenticateFbUser) ? false : true,
                username: brand && brand.connections.facebook_page ? brand.connections.facebook_page.name : null,
                connectorComponent: <FacebookConnectv2
                    autoconnectInstagram={true}
                    onSave={() => onSave('facebook')}
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    selected={{
                        ...brand.connections.facebook_page
                    }}
                />
            },

            {
                id: 'instagram',
                name: "Instagram",
                imageAsset: siteIconsData['instagram'],
                needsConnection: brand && brand.connections.business_discovery_instagram ? false : true,
                username: brand?.connections?.business_discovery_instagram?.username || '',
                connectorComponent: <InstagramBusinessDiscoveryConnect
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    selected={brand && brand.connections.business_discovery_instagram ? brand.connections.business_discovery_instagram : null}
                    localSave={false}
                    onSave={() => onSave('instagram')}
                    showSave={true}
                />
            },
            {
                id: 'tiktok',
                name: "TikTok",
                imageAsset: siteIconsData['tiktok'],
                needsConnection: brand && brand.connections.tiktok && brand.connections.tiktok.url && brand.connections.tiktok.username ? false : true,
                username: brand && brand.connections.tiktok ? brand.connections.tiktok.username : '',
                connectorComponent: <TiktokConnect
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    selected={brand && brand.connections.tiktok ? brand.connections.tiktok : null}
                    localSave={false}
                    onSave={() => onSave('tiktok')}

                    showSave={true}
                />
            },
            {
                id: 'soundcloud',
                name: "SoundCloud",
                imageAsset: siteIconsData['soundcloud'],
                needsConnection: brand && brand.connections.soundcloud_artist_page ? false : true,
                username: brand && brand.connections.soundcloud_artist_page ?
                    brand.connections.soundcloud_artist_page.name ||
                    brand.connections.soundcloud_artist_page.username : '',
                connectorComponent: <SoundcloudConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    selected={brand && brand.connections.soundcloud_artist_page ? brand.connections.soundcloud_artist_page.username : null}
                    onSave={() => onSave('soundcloud')}
                    open={true}
                />
            },
            {
                id: 'audiomack',
                name: "Audiomack",
                imageAsset: siteIconsData['audiomack'],
                needsConnection: brand && brand.connections.audiomack ? false : true,
                username: brand && brand.connections.audiomack ? brand.connections.audiomack.name || brand.connections.audiomack.username : '',
                connectorComponent: <AudiomackConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    open={true}
                    selected={brand && brand.connections.audiomack ?
                        (brand.connections.audiomack.name || brand.connections.audiomack.url) : null}
                    onSave={() => onSave('audiomack')}
                />
            },


            {
                id: 'deezer',
                name: "Deezer",
                imageAsset: siteIconsData['deezer'],
                needsConnection: brand && brand.connections.deezer ? false : true,
                username: brand && brand.connections.deezer ? brand.connections.deezer.name : '',
                connectorComponent: <DeezerConnectv2
                    closeModal={() => setEditedPlatform(undefined)}
                    artist={brand}
                    open={true}
                    selected={brand && brand.connections.deezer ?
                        (brand.connections.deezer.name) : null}
                    onSave={() => onSave('deezer')}
                />
            },
            // Twitter has been decommissioned at the moment - as of Oct 30, 2023
            //   this is due to changes in X's API which make pulling twitter followers
            //   super duper expensive
            // {
            //     id: 'twitter',
            //     name: "Twitter",
            //     imageAsset: siteIconsData['twitter'],
            //     needsConnection: !brand || !brand?.connections?.twitter?.url,
            //     username: brand && brand.connections.twitter ? brand.connections.twitter.screen_name : '',
            //     connectorComponent: <TwitterConnect
            //         onCloseModal={() => setEditedPlatform(undefined)}
            //         currentBrand={brand}
            //         onSave={() => onSave('twitter')}
            //         currentUserData={brand && brand.connections.twitter ? {
            //             username: brand.connections.twitter.screen_name,
            //             url: brand.connections.twitter.url
            //         } : undefined}
            //     />
            // },
        ] : []

    useEffect(() => {
        // if modal is closed, reset back to default state
        if (!open) {
            setEditedPlatform(undefined)
        }
    }, [open])

    async function selectedEditedPlatform(platformType: PlatformType) {
        // if its Facebook or IG, we should call FB login within this first
        // if (platformType.id === 'instagram') {
        //     if (!fbLoggedIn()) {
        //         console.log('not logged in, calling login')
        //         await loginWithFB()
        //         console.log('user logged in')
        //     }
        //     setEditedPlatform(platformType)
        // }
        setEditedPlatform(platformType)
    }
    return (
        <Modal
            aria-labelledby="connector-modal"
            aria-describedby="connector-modal for platforms"
            className={"flex items-center justify-center"}
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
        >
            <Fade in={open}>
                {editedPlatform ? (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md">
                        {editedPlatform.connectorComponent}
                    </div>
                ) : (
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md px-6 py-5 ">
                        <div className="flex justify-end">
                            <CloseButton onClick={closeModal} />
                        </div>
                        <h1 className=" text-2xl">Your Platforms</h1>
                        <h2 className=" text-md">Update the platforms connected to your profile.</h2>
                        <div className="mt-4">
                            <hr className="h-px	bg-gray-200" />

                            <div className="add-new">
                                {PLATFORMS
                                    .sort((a: PlatformType, b: PlatformType) => {
                                        if (a.needsConnection && !b.needsConnection) {
                                            return 1
                                        }

                                        if (b.needsConnection && !a.needsConnection) {
                                            return -1
                                        }

                                        return 0
                                    })
                                    .map((platform: PlatformType, index: Number) => {

                                        const {
                                            id,
                                            name,
                                            needsConnection,
                                            imageAsset,
                                            username
                                        } = platform

                                        return (
                                            <div key={id} className="sy-card">
                                                <div className="flex items-center">
                                                    <div className="artist-img">
                                                        <img
                                                            src={
                                                                imageAsset
                                                            }
                                                        />
                                                        {!needsConnection ? (<div className="absolute bottom-0 right-0 w-4 h-4">
                                                            <img
                                                                src={
                                                                    require("../../../assets/images/tick.svg")
                                                                        .default
                                                                } style={{ maxWidth: 20, height: 'auto' }}
                                                            />
                                                        </div>
                                                        ) : null}
                                                    </div>
                                                    <div className="px-3">
                                                        <h5 className="text-dark">{name}</h5>
                                                    </div>
                                                </div>
                                                <div>
                                                    {!needsConnection ? (
                                                        <div className="flex items-center">
                                                            <p className="pl-6 pr-3 text-fontColor-100 text-right break-all	">{username}</p>
                                                            <LinkButton onClick={() => selectedEditedPlatform(platform)} text="Edit" />
                                                            {/* <a
                                                                className="flex-shrink-0 cursor-pointer"
                                                                onClick={() => {
                                                                    selectedEditedPlatform(platform)
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        require("../../../assets/images/right-arrow.svg")
                                                                            .default
                                                                    }
                                                                />
                                                            </a> */}
                                                        </div>

                                                    ) : (
                                                        <div>
                                                            <LinkButton onClick={() => selectedEditedPlatform(platform)} text="Connect" />

                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })}

                            </div>

                        </div>
                    </div>
                )}

            </Fade>
        </Modal>
    )
}