import styled from 'styled-components';

export const PurpleContainer = styled.div`
  background: rgba(136, 0, 255, 0.08);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
`;

export const PlatformsIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
`;

export const CampaignTypeText = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;
