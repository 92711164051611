import { makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";
import styled from "styled-components";
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  automatedSwitch: {
    padding: 0,
    height: 27,
    width: 43,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#E5E7EB',
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#8800FF',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#8800FF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      borderRadius: 34,
    },
    '& .MuiSwitch-track': {
      marginTop: 2,
      marginLeft: 1,
      borderRadius: 34,
      width: 42,
      height: 24,
      backgroundColor: '#E5E7EB',
      opacity: 1,
    },
  },
}));

interface FlexContainerProps {
  hidden?: boolean;
}

interface ChartsWrapperProps {
  gap: string;
}

interface ChartsContainerProps {
  paddingY: string;
}

export const FlexContainer = styled.div`
  display: ${({ hidden }: FlexContainerProps) => hidden ? 'none' : 'flex'};
  width: 100%;
  height: 100%;
  flex-direction: row;
  background: #F5F3F6;
`;

interface MainContainerProps {
  mobile?: boolean;
}

interface ChartProps {
  mobile?: boolean;
  fullWidth?: boolean;
}

interface FanbaseSizeContainerProps {
  alignContent?: string
}

export const MainBackground = styled.div`
  position: absolute;
  background: #F5F3F6;
  height: fit-content;
  width: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 18px 56px;
  gap: 40px;
  background: ${SystemColors.PAPER_CONTAINER};
  border-radius: 8px;
  margin: ${({ mobile }: MainContainerProps) => mobile ? "0px" : "32px auto"};
  max-width: 678px;
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 48px;
`;

export const BackIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 50%;
  cursor: pointer;
`;

export const BackArrowImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const CheckImage = styled.img`
  width: 12px;
  height: 12px;
`;

export const MainTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const MainTitleLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
`;

export const SubTitelLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #707070;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;


export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 40px;
  width: 646px;
  height: 547px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

export const FanbaseSizeContainer = styled.div`
  justify-content: ${({ alignContent }: FanbaseSizeContainerProps) => alignContent ? alignContent : 'center'};
  align-items: ${({ alignContent }: FanbaseSizeContainerProps) => alignContent ? alignContent : 'center'};
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 24px;
  width: 100%;
  
`;

export const FanbaseSizeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 153px;
  height: 91px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const FanbaseSizeDescriptionLabel = styled.div`
  width: 100%;
  height: 19px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #707070;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const FanbaseSizeLabel = styled.div`
  width: 153px;
  height: 68px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 68px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const InkyArrow = styled.img`
  width: 64.75px;
  height: 88px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ChartsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: ${({ paddingY }: ChartsContainerProps) => paddingY ? `0 ${paddingY}` : "0 32px"};
  width: 100%;
`;

export const ChartsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: ${({ gap }: ChartsWrapperProps) => gap ? gap : "16px 32px"};
`;

export const ChartsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 582px;
  height: 132px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ConnectToPlatformsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 582px;
  height: 52px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

// export const NextButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 16px 24px;
//   gap: 10px;
//   width: 100%;
//   background: #8800FF;
//   border-radius: 48px;
//   color: #FFFFFF;
//   cursor: pointer;
// `;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  width:${({ mobile, fullWidth }: ChartProps) => {
    if (fullWidth) return `calc(100% - ${mobile ? '8px' : '16px'})`
    else if (mobile) {
      return `calc(50% - 8px)`
    } else {
      return `calc(50% - 16px)`
    }
  }};
`;

export const ChartDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  gap: 8px;
  padding-left: 8px;
  width: 100%;
`;

export const SpotifyIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 8px;
`;

export const YoutubeIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 36px;
  height: 36px;
  background: #191919;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: #FF0000;
`;

export const ChartLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;

export const ChartStatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;

export const ChartDescriptionLabel = styled.div`
  width: 120px;
  height: 15px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #707070;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ChartStatLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #000000;
`;

export const ChartLine = styled.img`
  width: 275px;
  height: 64px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const ConnectPlatformContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(0deg, rgba(136, 0, 255, 0.08), rgba(136, 0, 255, 0.08)), rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  gap: 8px;
  padding: 8px;
  width: 100%;
  height: fit-content;
  cursor: pointer;
`;

export const ConnectPlatformSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 275px;
  height: 36px;
  flex: none;
  order: 0;
  flex-grow: 1;
  cursor: pointer;
`;

export const SoundCloudLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 36px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const InstagramLocoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 36px;
  height: 36px;
  background: radial-gradient(144.41% 122.74% at 1.81% 90.83%, #FED576 0%, #F47133 26.34%, #BC3081 60.91%, #4C63D2 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ConnectPlatformInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 1;
  flex-grow: 1;
`;

export const ConnectPlatformLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8800FF;
`;

export const GrayContainer = styled.div`
  width: 100%;
  background: #EDECF2;
  border-radius: 8px;
  height: 132px;
`;
