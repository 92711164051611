import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) => ({
  audienceTargetingChips: {
    height: 28,
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(1),
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  chipLabelColor: {
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '8px',
  },
  space: {
    padding: "16px 24px",
    [theme.breakpoints.up(1023)]: {
      padding: "24px 40px",
    },
  },
  border: {
    borderBottomWidth: 1,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  noPaddingTop: {
    paddingTop: 0
  },
  noPaddingX: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(1023)]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  withPaddingBottom: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(1023)]: {
      paddingBottom: theme.spacing(3),
    },
  },
  geographicTargetingChips: {
    display: "flex",
    flexDirection: "row",
    color: "#8800FF",
    borderRadius: 32,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    gap: 2,
  },
  button: {
    display: "flex",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    justifyContent: "flex-start",
    alignItems: "left",
    padding: "12px 16px",
  },
  buttonText: {
    padding: "6px 12px",
    textAlign: 'left',
    alingItems: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    fontHeight: '19px',
    color: "#000000",
  },
  buttonChip: {
    display: "flex",
    color: "#ffffff",
    width: "100%",
    height: "100%",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '13px',
  },
  item: {
    paddingRight: theme.spacing(3.5),
    paddingLeft: theme.spacing(3.5)
  }
}));

interface IconProps {
  position?: string;
  right?: string;
  borderRadius?: string;
}

interface StyledSpanProps {
  color?: string;
  textDecorationLine?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  textAlign?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  fontFamily?: string;
  fontStyle?: string;
}

export interface ContainerProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
  justifyContent?: string;
}

interface CardProps {
  borderRadius?: string
}

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  background-color: #ffffff;
  @media (max-width: 768px) {
    border-radius: 0px;
  };
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-family: ${({ fontFamily }: StyledLabelProps) => fontFamily || 'DIN'};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  font-style: ${({ fontStyle }: StyledLabelProps) => fontStyle || 'normal'};
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
`;

export const Icon = styled.img`
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
  position: ${({ position }: IconProps) => position};
  right: ${({ right }: IconProps) => right};
`;

export const StyledSpan = styled.span`
  color: ${({ color }: StyledSpanProps) => color};
  text-decoration-line: ${({ textDecorationLine }: StyledSpanProps) =>
    textDecorationLine};
`;

export const Container = styled.div`
  background-color: #f5f3f6;
  min-height: 300px;
`;

export const ItemContainer = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  width: ${({ width }: ContainerProps) => width};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export default useStyles;