import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  startDate: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  endDate: {
    paddingTop: theme.spacing(2),
  },
  validDate: {
    color: 'black',
  },
  invalidDate: {
    color: "#FF3D3D",
  },
  wrapperStyles: {
    "& ::placeholder": {
      color: "#8800FF",
    }
  }
}));

interface DateLabelProps {
  hide?: boolean;
}

export const DateLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  color: ${({ hide }: DateLabelProps) =>
    !hide ? "rgba(136, 0, 255, 1)" : undefined};
  cursor: ${({ hide }: DateLabelProps) => (!hide ? "pointer" : undefined)};
  display: ${({ hide }: DateLabelProps) => (hide ? "none" : undefined)};
`;

export default useStyles;
