import {
  BrandContainer,
  DateContainer,
  MainContainer,
} from "./style";
import { FunctionComponent } from "react";
import BadgeCampaignTypeContainer from 'pages/post-auth/MarketingPage/Components/BadgeCampaignTypeContainer/BadgeCampaignTypeContainer';
import CampaignStatusBadge from "../CampaignStatusBadge";

interface Props {
  campaignName: string;
  date: string;
  campaignType: string;
}


export const Thumbnail: React.FC<{ src: string }> = ({ src }) => (
  <img className="w-12 h-12 mb-3 md:mb-0 md:w-20 md:h-20 object-cover rounded-xl mr-4" src={src} alt="" />
);

export const Title: React.FC<{ text: string }> = ({ text }) => (
  <h3 className="font-normal text-lg">{text}</h3>
);

// Subtitle component
interface SubtitleProps {
  children: React.ReactNode;
}
export const Subtitle: React.FC<SubtitleProps> = ({ children }) => (
  <div className="text-sm text-gray-500">{children}</div>
);


const CampaignDescription: FunctionComponent<Props> = ({
  campaignName,
  date,
  campaignType,
}: Props) => {
  return (
    // <MainContainer>
    //   <BadgeCampaignTypeContainer
    //     campaignType={campaignType}
    //   />
    //   <BrandContainer>{campaignName}</BrandContainer>
    //   <DateContainer>Last update: {date}</DateContainer>
    // </MainContainer>
    <div className="flex flex-col justify-center items-start">
      <div className="mb-2 flex-grow-0 flex flex-row items-center justify-center">
        <BadgeCampaignTypeContainer
          campaignType={campaignType}
        />
        <div className="ml-2">
          <CampaignStatusBadge
            campaignStatus={'draft'}
            campaignStatusLabel={'DRAFT'}
          />
        </div>
      </div>
      <div className="mb-1">
        <Title text={campaignName} />
      </div>
      <div>
        <Subtitle>Last update: {date}</Subtitle>
      </div>
    </div>
  );
};

export default CampaignDescription;
