import React from 'react'
import { IconHolder, SocialIconProps } from './styles'

export const Boomplay: React.FC<SocialIconProps> = ({
  rounded,
  borderColor,
  backgroundColor,
  iconColor,
  width = '40',
  height = '40',
  cursor,
  onClick,
}) => {
  const boomplayColor = iconColor ?? '#00FFFF'
  return (
    <IconHolder
      rounded={rounded}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      cursor={cursor}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30"
        height="30"
        viewBox="0 0 658 659"
        enable-background="new 0 0 658 659"
      >
        <path
          fill={boomplayColor}
          opacity="1.000000"
          stroke="none"
          d="
M659.000000,302.000000 
	C659.000000,322.020905 659.000000,342.041779 658.640503,362.701477 
	C657.525024,367.508026 656.866089,371.696136 655.995972,375.839905 
	C652.350342,393.201630 649.539490,410.802917 644.741882,427.843170 
	C637.830383,452.391541 627.093933,475.462891 614.194336,497.520874 
	C602.668579,517.229675 589.520142,535.822388 573.842773,552.315979 
	C561.334473,565.475464 548.037476,578.126465 533.749512,589.286194 
	C519.152161,600.687500 503.397400,610.831421 487.294708,620.029724 
	C461.560516,634.729614 434.033051,645.533569 404.902283,651.365601 
	C387.945557,654.760315 370.658173,656.500793 353.524750,659.020813 
	C352.327728,659.196899 351.173889,659.666748 350.000000,660.000000 
	C336.979095,660.000000 323.958221,660.000000 310.262939,659.619873 
	C301.738647,658.497131 293.876617,657.863586 286.041351,656.989929 
	C267.369965,654.908081 249.189377,650.587341 231.227158,645.126526 
	C208.485947,638.212891 187.108826,628.278809 166.390396,616.780945 
	C145.413528,605.139648 126.101196,591.084045 108.592125,574.805176 
	C96.977859,564.007019 86.335670,552.101868 75.827019,540.190491 
	C60.306198,522.597961 47.383976,503.022308 37.255341,481.964386 
	C28.747448,464.276123 21.258728,445.924194 15.304110,427.233551 
	C10.095929,410.885864 7.686860,393.649750 3.959659,376.824188 
	C3.151639,373.176575 1.995693,369.606049 1.000000,366.000000 
	C1.000000,365.555542 1.000000,365.111115 1.359517,364.163910 
	C1.479356,361.440765 1.239678,359.220398 1.000000,357.000000 
	C1.000000,339.645782 1.000000,322.291534 1.352837,304.306702 
	C1.470449,301.784027 1.235224,299.892029 0.999999,298.000000 
	C1.000000,297.555542 1.000000,297.111115 1.374662,296.191681 
	C2.496632,292.102661 3.218636,288.483124 3.995560,284.875458 
	C7.625420,268.020203 10.610310,250.991211 15.069270,234.357666 
	C21.301672,211.108521 31.520933,189.404083 43.203308,168.380661 
	C56.900112,143.732117 73.820557,121.460564 93.412239,101.303986 
	C102.589821,91.861763 112.378647,82.812393 122.890274,74.915894 
	C138.128082,63.469040 153.664749,52.168507 170.167130,42.713573 
	C197.144424,27.257082 226.072800,16.242290 256.612946,9.641462 
	C276.702271,5.299432 296.869385,2.021523 317.478973,1.956490 
	C319.320282,1.950680 321.159698,1.333334 323.000000,1.000001 
	C327.356903,1.000000 331.713806,1.000000 336.743835,1.381761 
	C351.039307,2.840273 364.786743,3.112872 378.250946,5.206735 
	C393.496155,7.577569 408.634064,11.030434 423.555786,15.000713 
	C447.955353,21.492807 470.749695,32.006985 492.781830,44.291561 
	C517.278809,57.950500 539.340881,74.879402 559.485657,94.224403 
	C568.982483,103.344193 577.477173,113.551216 586.022583,123.608269 
	C600.039978,140.105270 611.617920,158.310226 621.697754,177.416061 
	C631.835205,196.631195 640.505005,216.555084 645.883850,237.645264 
	C649.862915,253.246780 652.728210,269.134888 655.982422,284.917450 
	C657.149719,290.578369 658.003296,296.303925 659.000000,302.000000 
M372.974823,212.465866 
	C389.217682,222.978989 392.292358,247.385269 383.166992,263.533478 
	C373.788330,280.129944 352.916443,287.368927 333.732788,279.781464 
	C325.222565,276.415527 318.817902,270.043976 314.586121,261.987976 
	C308.005402,249.460281 308.754517,236.632965 314.981018,223.736679 
	C303.342651,222.095703 292.378784,220.522888 281.406708,219.010025 
	C269.252563,217.334183 257.078552,215.797455 244.938019,214.029739 
	C239.536774,213.243286 238.165482,213.861176 236.904373,219.355942 
	C233.445221,234.427795 230.156311,249.538757 226.697830,264.610779 
	C221.528824,287.137207 216.243759,309.637024 211.079544,332.164520 
	C210.147217,336.231476 209.558685,340.377197 208.761017,344.763763 
	C211.465424,345.252991 213.517090,345.728729 215.595886,345.980560 
	C221.784317,346.730103 228.000870,347.264771 234.174500,348.115143 
	C245.346848,349.654144 256.479980,351.482391 267.659424,352.963837 
	C279.031555,354.470764 290.458374,355.566772 301.827423,357.094360 
	C315.435944,358.922913 328.996490,361.106537 342.600067,362.974030 
	C357.396667,365.005341 369.402496,372.064911 377.874298,384.087524 
	C387.528656,397.788269 388.245758,412.839111 382.039246,428.369629 
	C375.222931,445.425995 359.239746,455.988586 341.897644,456.897705 
	C326.006470,457.730743 312.562836,452.268677 302.116394,439.716766 
	C292.280548,427.898621 290.102509,414.325836 292.766785,399.910828 
	C293.972687,393.386475 297.260773,387.246979 299.535065,381.119324 
	C297.969604,381.010956 295.647125,380.972900 293.359467,380.671082 
	C281.844147,379.151825 270.337799,377.564636 258.828522,375.999603 
	C249.750595,374.765137 240.674484,373.516998 231.594772,372.295807 
	C223.321198,371.183044 215.041489,370.115631 206.769470,368.991547 
	C203.977676,368.612213 202.541260,369.723145 201.988754,372.522308 
	C200.759216,378.751495 199.391113,384.954895 197.985931,391.147552 
	C192.727112,414.323578 187.185898,437.439331 182.274948,460.688446 
	C180.442719,469.362488 177.527679,477.942719 179.878647,487.293091 
	C184.132675,504.212341 199.307343,516.158264 216.127472,516.089905 
	C273.786621,515.855652 331.447327,516.024109 389.107330,515.948792 
	C393.197174,515.943420 397.378510,515.624390 401.360229,514.742310 
	C425.475250,509.400177 445.791107,497.859131 461.361908,478.244171 
	C473.711060,462.687561 481.168427,445.052734 482.804199,425.513367 
	C485.075714,398.379974 478.229462,373.631622 460.722473,352.063782 
	C446.653839,334.731720 429.015137,323.355408 407.488007,318.080505 
	C400.124573,316.276215 392.487915,315.587097 385.212921,314.426270 
	C390.240326,313.859039 395.548187,313.691498 400.661865,312.607697 
	C421.464050,308.198578 438.324524,297.483612 451.123535,280.404694 
	C465.801331,260.818695 470.934113,238.702576 466.722076,214.942612 
	C463.339935,195.863968 454.049164,179.660110 439.221436,166.505768 
	C423.797333,152.822311 405.763184,145.630051 385.721466,145.233841 
	C345.908234,144.446793 306.068420,144.952652 266.239807,145.040085 
	C257.897644,145.058395 253.022415,149.221497 250.897461,157.365189 
	C249.625748,162.238922 248.185181,167.075272 247.108810,171.991547 
	C245.836395,177.803284 244.874802,183.683060 243.722839,189.830185 
	C254.035217,191.292953 263.561249,192.735291 273.113556,193.975555 
	C284.488586,195.452515 295.897736,196.667587 307.270721,198.159439 
	C318.781250,199.669327 330.249268,201.508148 341.766205,202.962982 
	C352.495911,204.318405 363.168304,205.691101 372.974823,212.465866 
z"
        />
        <path
          fill="#000000"
          opacity="1.000000"
          stroke="none"
          d="
M372.704834,212.211517 
	C363.168304,205.691101 352.495911,204.318405 341.766205,202.962982 
	C330.249268,201.508148 318.781250,199.669327 307.270721,198.159439 
	C295.897736,196.667587 284.488586,195.452515 273.113556,193.975555 
	C263.561249,192.735291 254.035217,191.292953 243.722839,189.830185 
	C244.874802,183.683060 245.836395,177.803284 247.108810,171.991547 
	C248.185181,167.075272 249.625748,162.238922 250.897461,157.365189 
	C253.022415,149.221497 257.897644,145.058395 266.239807,145.040085 
	C306.068420,144.952652 345.908234,144.446793 385.721466,145.233841 
	C405.763184,145.630051 423.797333,152.822311 439.221436,166.505768 
	C454.049164,179.660110 463.339935,195.863968 466.722076,214.942612 
	C470.934113,238.702576 465.801331,260.818695 451.123535,280.404694 
	C438.324524,297.483612 421.464050,308.198578 400.661865,312.607697 
	C395.548187,313.691498 390.240326,313.859039 385.212921,314.426270 
	C392.487915,315.587097 400.124573,316.276215 407.488007,318.080505 
	C429.015137,323.355408 446.653839,334.731720 460.722473,352.063782 
	C478.229462,373.631622 485.075714,398.379974 482.804199,425.513367 
	C481.168427,445.052734 473.711060,462.687561 461.361908,478.244171 
	C445.791107,497.859131 425.475250,509.400177 401.360229,514.742310 
	C397.378510,515.624390 393.197174,515.943420 389.107330,515.948792 
	C331.447327,516.024109 273.786621,515.855652 216.127472,516.089905 
	C199.307343,516.158264 184.132675,504.212341 179.878647,487.293091 
	C177.527679,477.942719 180.442719,469.362488 182.274948,460.688446 
	C187.185898,437.439331 192.727112,414.323578 197.985931,391.147552 
	C199.391113,384.954895 200.759216,378.751495 201.988754,372.522308 
	C202.541260,369.723145 203.977676,368.612213 206.769470,368.991547 
	C215.041489,370.115631 223.321198,371.183044 231.594772,372.295807 
	C240.674484,373.516998 249.750595,374.765137 258.828522,375.999603 
	C270.337799,377.564636 281.844147,379.151825 293.359467,380.671082 
	C295.647125,380.972900 297.969604,381.010956 299.535065,381.119324 
	C297.260773,387.246979 293.972687,393.386475 292.766785,399.910828 
	C290.102509,414.325836 292.280548,427.898621 302.116394,439.716766 
	C312.562836,452.268677 326.006470,457.730743 341.897644,456.897705 
	C359.239746,455.988586 375.222931,445.425995 382.039246,428.369629 
	C388.245758,412.839111 387.528656,397.788269 377.874298,384.087524 
	C369.402496,372.064911 357.396667,365.005341 342.600067,362.974030 
	C328.996490,361.106537 315.435944,358.922913 301.827423,357.094360 
	C290.458374,355.566772 279.031555,354.470764 267.659424,352.963837 
	C256.479980,351.482391 245.346848,349.654144 234.174500,348.115143 
	C228.000870,347.264771 221.784317,346.730103 215.595886,345.980560 
	C213.517090,345.728729 211.465424,345.252991 208.761017,344.763763 
	C209.558685,340.377197 210.147217,336.231476 211.079544,332.164520 
	C216.243759,309.637024 221.528824,287.137207 226.697830,264.610779 
	C230.156311,249.538757 233.445221,234.427795 236.904373,219.355942 
	C238.165482,213.861176 239.536774,213.243286 244.938019,214.029739 
	C257.078552,215.797455 269.252563,217.334183 281.406708,219.010025 
	C292.378784,220.522888 303.342651,222.095703 314.981018,223.736679 
	C308.754517,236.632965 308.005402,249.460281 314.586121,261.987976 
	C318.817902,270.043976 325.222565,276.415527 333.732788,279.781464 
	C352.916443,287.368927 373.788330,280.129944 383.166992,263.533478 
	C392.292358,247.385269 389.217682,222.978989 372.704834,212.211517 
z"
        />
      </svg>
    </IconHolder>
  )
}
