import styled from "styled-components";

interface CardProps {
  paddingY?: number;
  paddingX?: number;
  gap?: number;
  justifyContent?: string;
  alignItems?: string;
  isMobile?: boolean;
  border?: string;
  borderRadius?: number;
}

export const CardContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }: CardProps) => (justifyContent || "center")};
  align-items: ${({ alignItems }: CardProps) => (alignItems || "center")};
  gap: ${({ gap }: CardProps) => (gap || 8)}px;
  padding: ${({ paddingY, paddingX }: CardProps) => (`${paddingY || 16}px ${paddingX|| 8}px`)};
  border: ${({border}: CardProps) => (border? border: 'none')};
  borderRadius: ${({borderRadius}: CardProps) => (borderRadius? borderRadius: '0')}px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
`;

export const FooterCard = styled.span`
  width: 100%;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
  text-align: center;
`;

export const BodyCard = styled.span`
  width: 100%;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  text-align: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: rgba(25, 25, 25, 0.08);
  border-radius: 8px;
`;

export const GettingStartedHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  gap: 8px;
  margin-bottom: ${({ isMobile }: CardProps) => (isMobile?  '0' : '16')}px;
`;

export const GettingStartedTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMobile }: CardProps) => (isMobile?  'start' : 'center')};
  align-self: ${({ isMobile }: CardProps) => (isMobile?  'start' : 'center')};
  span {
    width: 80%;
  }
`;

export const ProgressBarText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #707070;
  display: inline;
  & > span {
    color: #8800FF;
    display: inline;
  }
`;

// Responsive styles
export const CarouselContainer = styled.div`
  width: 100%;
`;
