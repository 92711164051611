import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
  FunctionComponent,
} from "react"
import {
  StyledLabel,
  ArtistImage as ChannelImage,
  CloseButtonContainer,
  CloseIconContainer,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
  StyledButton,
  SearchListContainer,
} from "../../../style"
import CloseIcon from "components/shareable/CloseIcon"
import { ReactComponent as YoutubeIcon } from "assets/images/youtube-white.svg"
import { TextField, Dialog, Grid, InputAdornment, useTheme, useMediaQuery } from "@material-ui/core"
import debounce from "lodash/debounce"
import { CurrentBrand, OnboardingArtist, YouTubeChannel } from "types/global"
import clsx from "clsx"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie"
import { getDescription, getHelperText, getModalContainerHeight } from "./utils"
import Axios from "helpers/Interceptor"
import isEqual from "lodash/isEqual"
import { track } from "analytics"
import { TextButton } from "styles/shared"
import { getChannelIdFromUrl, queryYouTubeApi } from "helpers/YouTube"

interface Props {
  open: boolean
  youtubeSearchString: string
  youtubeSearchResults: YouTubeChannel[]
  artist: OnboardingArtist | CurrentBrand | null
  selectedYoutubeChannel: YouTubeChannel | null
  localSave: boolean
  setYoutubeSearchString: Dispatch<SetStateAction<string>>
  selectYoutubeChannel: Dispatch<SetStateAction<YouTubeChannel>>
  closeModal: () => void
  onSave?: () => void | null
}

const THROTTLED_MODE = true
const YouTubeConnect: FunctionComponent<Props> = ({
  youtubeSearchString,
  youtubeSearchResults,
  artist,
  open,
  localSave,
  selectedYoutubeChannel,
  setYoutubeSearchString,
  selectYoutubeChannel,
  closeModal,
  onSave,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(421))
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [textFieldValue, setTextFieldValue] = useState("")
  const [results, setResults] = useState<YouTubeChannel[]>([])
  const [error, setError] = useState(false)
  const showConnectButton = !isEqual(results, [selectedYoutubeChannel])


  // used to determine if the user submitted a query already
  const [userSubmittedSearch, setUserSubmittedSearch] = useState(false)

  const containerHeight = isMobile ? '100%' : getModalContainerHeight(results.length)

  const helperText = getHelperText({
    value: textFieldValue,
    error,
    results,
    loading,
    youtubeSearchString,
  })

  // Helper function to validate if a string looks like a YouTube URL
  function isValidYouTubeURL(url: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?youtube\.com\/((channel|c|user)\/[a-zA-Z0-9_-]+)$/;
    return regex.test(url);
  }

  // Function to extract the channel identifier from a YouTube URL
  function extractChannelIdentifier(url: string) {
    const parts = url.split('/');
    const searchedBy = parts[parts.length - 2];
    const lastPart = parts[parts.length - 1];

    return { searchedBy, identifier: lastPart };
  }

  async function pullYouTubeResults(value: string) {
    setUserSubmittedSearch(true)

    if (!value.trim().length || (THROTTLED_MODE && !isValidYouTubeURL(value))) {
      setResults([])
      return
    }

    setLoading(true);  // Assuming setLoading is a function to update a loading state

    let data = [];
    let error = null;

    if (THROTTLED_MODE && isValidYouTubeURL(value)) {
      const { identifier, searchedBy } = extractChannelIdentifier(value);
      const isChannelWithUsername = searchedBy === 'user';
      try {
        const results = await queryYouTubeApi({
          endpoint: '/channels',
          params: {
            forUsername: isChannelWithUsername ? identifier.substring(1) : undefined,
            id: !isChannelWithUsername ? identifier : undefined,
            part: 'id,snippet',
          }
        });
        data = results?.data?.items || [];
      } catch (e) {
        error = e;
      }

      if (!data.length && isChannelWithUsername) {
        try {
            const channelId = await getChannelIdFromUrl(value);
            if (channelId) {
              const results = await queryYouTubeApi({
                endpoint: '/channels',
                params: {
                  id: channelId,
                  part: 'id,snippet',
                }
              });
              data = results?.data?.items || [];
            }
        } catch (e) {
          error = e;
        }
      }
    }

    if (error) {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      setResults(data.map((youTubeChannel: YouTubeChannel) => ({
        ...youTubeChannel,
        id: youTubeChannel.id
      })));
      setLoading(false);
      setUserSubmittedSearch(false)

    }
  }
  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value: string) => {
        await pullYouTubeResults(value)
      }, 800),
    []
  )

  const updateInBrand = (channel: YouTubeChannel) => {
    setLoading(true)
    if (artist) {
      track("Linked YouTube Channel")
      Axios.put(`/brand/${artist.slug}/connect`, {
        id: artist.id,
        name: artist.slug,
        service: "youtube_channel",
        value: channel,
      })
        .then((_result) => {
          onSave?.()
          closeModal()
          setError(false)
          setLoading(false)
        })
        .catch((err) => {
          console.log("error creating brand", err)
          setError(true)
        })
    }
  }

  const handleOnClick = (channel: YouTubeChannel) => () => {
    if (localSave) {
      selectYoutubeChannel({
        ...channel,
        searchUrl: textFieldValue
      })
      setYoutubeSearchString(textFieldValue)
    } else {
      updateInBrand(channel)
    }
  }

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value.length && open) {
      if (!THROTTLED_MODE) {
        setLoading(true)
        debouncedChangeHandler(value)
      }
    }

    setTextFieldValue(value)
  }

  useEffect(() => {
    setError(false)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (THROTTLED_MODE) pullYouTubeResults(textFieldValue)
  }, [textFieldValue])

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel()
    }
  }, [debouncedChangeHandler])

  useEffect(() => {
    if (open) setTextFieldValue(youtubeSearchString)
  }, [youtubeSearchString, open])

  useEffect(() => {
    if (open && selectedYoutubeChannel) {
      setResults([selectedYoutubeChannel])
    } else if (open && youtubeSearchResults) {
      setResults(youtubeSearchResults)
    }
  }, [selectedYoutubeChannel, youtubeSearchResults, open])

  function renderEndAdornment() {
    if (loading) {
      // Assuming LottieAnimation is a component or element that renders your Lottie animation
      return <InputAdornment position="end">
        <Lottie
          height={24}
          width={24}
          options={{
            loop: true,
            autoplay: true,
            animationData: SymphonyLoadingLottie,
          }}
        />
      </InputAdornment>;
    }

    if (THROTTLED_MODE) {
      return <TextButton
        onClick={() => pullYouTubeResults(textFieldValue)}>Search</TextButton>;
    }

    return null;  // Return null or some default/fallback UI when neither condition is met
  }


  let mainSubtitle = `What is the name or URL for ${artist?.name || "your primary"} Youtube account?`
  let mainPlaceholder = "Bad Bunny, Madonna..."
  if (THROTTLED_MODE) {
    mainSubtitle = `What is the URL for ${artist?.name || "your primary"} Youtube account?`
    mainPlaceholder = "https://youtube.com/channel/xyz"
  }
  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Connect Youtube</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              {mainSubtitle}
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <ModalPlatformInputContainer>
          <PlatformLogoContainer
            backgroundColor="#D70606"
            width="48px"
            height="48px"
          >
            <YoutubeIcon width="32px" height="32px" />
          </PlatformLogoContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: renderEndAdornment(),
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder={mainPlaceholder}
            helperText={THROTTLED_MODE
              ? (results.length === 0 && textFieldValue?.length && !loading)
                ? "No channels found. Please make sure your URL looks correct."
                : null
              : helperText
            }
            error={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </ModalPlatformInputContainer>
        {textFieldValue && !loading && (<SearchListContainer height={containerHeight}>
          <Grid container direction="column">
            {!loading && results.map((channel, index) => {
              const { snippet, etag } = channel
              const {
                title,
                thumbnails,
                description: snippetDescription,
                channelTitle,
              } = snippet
              const thumbnailUrl = thumbnails?.default?.url
              const description = getDescription(snippetDescription, isMobile)

              return (
                <Grid
                  item
                  key={etag}
                  className={clsx(
                    classes.channelItemRow,
                    index === 0 && classes.dividerBottom,
                    index !== 0 && index !== 1 && classes.dividerTop
                  )}
                >
                  <Grid
                    className="cursor-pointer"
                    onClick={handleOnClick(channel)}
                    spacing={showConnectButton && isMobile ? 2 : 3}
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item className={classes.channelImage}>
                      <ChannelImage
                        widthStyle={setResponsiveView ? "48px" : "56px"}
                        heightStyle={setResponsiveView ? "48px" : "56px"}
                        alt={`${channelTitle}-url-image`}
                        src={thumbnailUrl}
                      />
                    </Grid>
                    <Grid
                      item
                      className={clsx(showConnectButton && isMobile && "w-24")}
                      xs={showConnectButton && !isMobile || !showConnectButton}
                    >
                      <Grid container direction="column">
                        <Grid item>
                          <StyledLabel fontWeight={500} fontSize={16}>
                            {title}
                          </StyledLabel>
                        </Grid>
                        <Grid item>
                          <StyledLabel
                            fontSize={12}
                            fontWeight={400}
                            color="#707070"
                          >
                            {description}
                          </StyledLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    {showConnectButton && (
                      <Grid item className={classes.connectButtonContainer}>
                        <Grid container direction="row-reverse">
                          <StyledButton>Connect</StyledButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </SearchListContainer>)}
        <p className="text-sm mt-auto">By connecting YouTube, you agree to the terms defined by YouTube API Services, <a target="_blank" href="http://www.google.com/policies/privacy" className="underline">Google's Privacy Policy</a> and <a target="_blank" href="https://www.youtube.com/t/terms" className="underline">YouTube's Terms of Service.</a></p>
      </ModalContainer>
    </Dialog>
  )
}

export default YouTubeConnect
