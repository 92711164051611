import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  chipLabelColor: {
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  closeIcon: {
    width: 9,
    height: 9,
    marginRight: theme.spacing(1.5),
    cursor: "pointer",
  },
  rootCheckbox: {
    color: "#07C806",
  },
  checkedCheckbox: {},
  textField: { borderRightWidth: 4 },
  root: {
    "& input": {
      fontFamily: "DIN",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
}));
export default useStyles;
