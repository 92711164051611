import { BoxContrasts, StyleTypes, Website } from 'types/global';
import { BoxesButton, ButtonsContainer, Image, ImageContainer, Information, InformationContainer, MainContainer, StackedButton, TraditionalButton } from './styles';
import { lightOrDark } from 'helpers/StyleUtils';
import { Fragment } from 'react';

interface Props {
  image: string,
  style: Website,
}
const Stacked = (contrastColor: string) => {
  return (
    <Fragment>
      <StackedButton backgroundColor={contrastColor} logoColor={contrastColor}/>
      <StackedButton backgroundColor={contrastColor} logoColor={contrastColor}/>
    </Fragment>
  )
}

const Traditional = (color: string, contrastColor: string) => {
  return (
    <Fragment>
      <TraditionalButton backgroundColor={color} logoColor={contrastColor}/>
      <TraditionalButton backgroundColor={color} logoColor={contrastColor}/>
    </Fragment>
  )
}

const Boxes = (color: string, contrastColor: string) => {
  return (
    <Fragment>
      <BoxesButton backgroundColor={color} logoColor={contrastColor}/>
      <BoxesButton backgroundColor={color} logoColor={contrastColor}/>
    </Fragment>
  )
}

const getButtons = (buttonConfig: StyleTypes, primaryColorContrast: string, secondaryColor: string, secondaryColorContrast: string) => {
  switch (buttonConfig as StyleTypes) {
    case StyleTypes.STACKED:
      return Stacked(primaryColorContrast);
    case StyleTypes.TRADITIONAL:
      return Traditional(secondaryColor, primaryColorContrast);
    case StyleTypes.BOXES:
      return Boxes(secondaryColor, secondaryColorContrast);
    default:
      return Traditional(secondaryColor, primaryColorContrast);
  }
}

const PresaveMiniature = ({
  image,
  style = {} as Website,
}: Props) => {
  const { buttonConfig, primaryColor, secondaryColor } = style!;
  const primaryColorContrast = lightOrDark(primaryColor) as BoxContrasts === BoxContrasts.LIGHT ? '#000000' : '#ffffff';
  const secondaryColorContrast = lightOrDark(secondaryColor) as BoxContrasts === BoxContrasts.LIGHT ? '#000000' : '#ffffff';
  const flexDirection = buttonConfig === StyleTypes.BOXES ? 'row' : 'column';

  return (
    <MainContainer backgroundColor={primaryColor} colorContrast={primaryColorContrast}>
      <ImageContainer>
        <Image src={image} alt='presave cover'/>
        <InformationContainer>
          <Information width='35%' backgroundColor={primaryColorContrast}>{" "}</Information>
          <Information width='55%' backgroundColor={primaryColorContrast}>{" "}</Information>
        </InformationContainer>
      </ImageContainer>
      <ButtonsContainer flexDirection={flexDirection} gap='2px'>
        {getButtons(buttonConfig as StyleTypes, primaryColorContrast, secondaryColor, secondaryColorContrast)}
      </ButtonsContainer>
    </MainContainer>
  )
}

export default PresaveMiniature;
