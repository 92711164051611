import { Fragment, FunctionComponent } from 'react';
import { GrayText, WhiteText } from './styles';

interface Props {
  songsList: string[],
  formattedSource: string,
}

function getSongs (songs?: string[]) {
  if (!songs) return { songs: "", more: "" };
  if (songs.length <= 3) return {
    songs: songs.join(', '),
    more: "",
  }
  const more = songs.pop();
  return {
    songs: songs.join(', '),
    more: `, ${more} more`,
  }
}

const TooltipText: FunctionComponent<Props> = ({
  songsList,
  formattedSource,
}: Props) => {
  const { songs, more} = getSongs(songsList)
  return (
    <Fragment>
      <GrayText>{formattedSource} {songs ? ": " : ""}</GrayText>
      <WhiteText>{songs}</WhiteText>
      {more && <GrayText>{more}</GrayText>}
    </Fragment>
  )
}

export default TooltipText;