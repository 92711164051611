/* eslint-disable no-throw-literal */
import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";
import some from "lodash/some";
import { CurrentBrand, PlaylistsRecord } from "types/global";
import { track } from "analytics";

type Tier = {
  tier: number;
  cost: number;
};

export const createPayPalOrder = async (args: {
  campaignId: string | null;
  brand: CurrentBrand;
  record: PlaylistsRecord | null;
  tier: Tier;
  pitchNotes: string;
}) => {
  const { brand, record, tier, campaignId: dataCampaignId, pitchNotes } = args;

  track("Completed Campaign Step - Checkout", {
    type: "get_playlisted",
    payment_method: "paypal",
    step: 3,
    name: "confirmation",
    brand_id: brand.id,
    brand_name: brand.name,
    brand_slug: brand.slug,
    content_name: record?.name,
    content_slug: record?.slug,
    content_id: record?.id,
    tier: tier,
    pitchNotes: pitchNotes
  });

  try {
    const response = await Axios.post(`/campaign/brand/${brand.id}/checkout`, {
      campaign: {
        id: dataCampaignId,
        campaign_type: "get_playlisted",
        campaign_version: 'v2',

        pitch_notes: pitchNotes,

        price_data: {
          tier: tier.tier,
          price: tier.cost,
          name: `${tier.tier.toLocaleString(
            "en"
          )} Followers Across Playlists ("${record?.name}")`,
          description: `"${record?.name}" (Get Playlisted)`,
        },
        content: {
          spotifyId: record?.spotify_id,
          name: record?.name,
          id: record?.id,
          slug: record?.slug,
          thumbnail_url: record?.thumbnail_url,
          url: record?.url,
        },
        campaign_platforms: ["playlisting"],
        payment_method: "paypal",
      },
    });

    if (some([!response, !response.data, !response.data.data])) {
      throw "Error creating PayPal order";
    }

    const order = response.data.data;
    const orderId = order.paypalOrder.id;
    const campaignId = order.campaignId;

    return { orderId, campaignId };
  } catch (error) {
    Sentry.captureException(error);
    console.log("error: ", error);
    return { orderId: null, campaignId: null };
  }
};

export const redirectToCheckout = async (args: {
  campaignId: string | null;
  brand: CurrentBrand;
  record: PlaylistsRecord | null;
  tier: Tier;
  pitchNotes: string;
}) => {
  const { campaignId, brand, record, tier, pitchNotes } = args;

  track("Completed Campaign Step - Checkout", {
    type: "get_playlisted",
    payment_method: "stripe",
    step: 3,
    name: "confirmation",
    brand_id: brand.id,
    brand_name: brand.name,
    brand_slug: brand.slug,
    content_name: record?.name,
    content_slug: record?.slug,
    content_id: record?.id,
    tier: tier,
    pitchNotes: pitchNotes
  });

  try {
    const redirectUrlReq = await Axios.post(
      `/campaign/brand/${brand.id}/checkout`,
      {
        campaign: {
          id: campaignId,
          campaign_type: "get_playlisted",
          campaign_version: 'v2',
          pitch_notes: pitchNotes,
          price_data: {
            tier: tier.tier,
            price: tier.cost,
            name: `${tier.tier.toLocaleString(
              "en"
            )} Followers Across Playlists ("${record?.name}")`,
            description: `"${record?.name}" (Get Playlisted)`,
          },
          content: {
            spotifyId: record?.spotify_id,
            name: record?.name,
            id: record?.id,
            slug: record?.slug,
            thumbnail_url: record?.thumbnail_url,
            url: record?.url,
          },
          campaign_platforms: ["playlisting"],
        },
      }
    );

    if (
      !redirectUrlReq ||
      !redirectUrlReq.data ||
      !redirectUrlReq.data.data ||
      !redirectUrlReq.data.data.url
    ) {
      throw "Error getting URL";
    }
    window.location = redirectUrlReq.data.data.url;
  } catch (e) {
    console.error("e", e);
  }
};
