import { StyledAnchor, StyledArticle, StyledBannerDetails } from './styles'
import { IssuesCountSummary } from './IssuesCountSummary'
import { facebookErrorActions } from '../../utils/FacebookErrorsActions'
import { Body2, Subtitle2, ButtonText, Subtitle1 } from 'components/shareable/Typography'
import { SystemColors } from 'types/globalStyles'
import Intercom from 'helpers/Intercom'
import { FacebookError } from 'types/global'

export type FacebookAdAccountInfo = {
  name: string,
  id: string,
  business?: { name: string, id: string },
  pixel?: { id: string, name: string }
}

interface FacebookCampaignIssuesBannerProps {
  adAccount: FacebookAdAccountInfo
  conversionsEventName?: string
  facebookErrors: FacebookError[]
}

export function FacebookCampaignIssuesBanner({
  adAccount,
  conversionsEventName,
  facebookErrors,
}: FacebookCampaignIssuesBannerProps) {
  const { id: adAccountId, name: adAccountName, business, pixel } = adAccount;

  const validErrors: FacebookError[] = facebookErrors.filter((error) =>
    Object.keys(facebookErrorActions).includes(error.code)
  )

  const getCTALink = (ctaLink: string | ((data: { adAccountId: string, businessId?: string }) => string)): string => {
    if (typeof ctaLink === 'string') return ctaLink as string
    const [, accountId] = adAccountId.split('act_');
    return ctaLink({ adAccountId: accountId, businessId: business?.id })
  }

  return (
    <StyledBannerDetails open>
      <IssuesCountSummary issuesCount={validErrors.length} />
      <div className="flex gap-2 flex-col">
        {validErrors.map((error, index) => {
          const errorLink = (error.details as { url: string })?.url
            ? (error.details as { url: string }).url.toString()
            : null
          const { title, description, ctaLink, ctaText, supportMessage, intercomArticleId } =
            facebookErrorActions[error.code]
          const displayDivider: boolean = index !== validErrors.length - 1

          return (
            <StyledArticle key={error.code} displayBottomBorderLine={displayDivider}>
              <div className="flex flex-col gap-1">
                <Subtitle1>{title}</Subtitle1>
                <Body2>
                  {description && typeof description === 'function'
                    ? description({ adAccountName, conversionsEventName, businessName: business?.name, pixelName: pixel?.name })
                    : description}
                </Body2>
              </div>
              {ctaLink && (
                <StyledAnchor href={getCTALink(errorLink ?? ctaLink)} target="about:blank" rel="noreferrer">
                  <ButtonText>{ctaText}</ButtonText>
                </StyledAnchor>
              )}
              {intercomArticleId && (
                <ButtonText onClick={() => Intercom.openArticleWithId(intercomArticleId)}>
                  {ctaText}
                </ButtonText>
              )}
              {supportMessage && (
                <Subtitle2 color={SystemColors.WARNING_COLOR}>{supportMessage}</Subtitle2>
              )}
            </StyledArticle>
          )
        })}
      </div>
    </StyledBannerDetails>
  )
}
