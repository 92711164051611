import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const SpotifyIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    const spotifyColor = iconColor ?? "#07C806";
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg width="30" height="30" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1026_17744)">
                    <path d="M17.9997 7.15724C11.8332 7.15724 6.83398 12.1563 6.83398 18.3228C6.83398 24.4896 11.8332 29.4883 17.9997 29.4883C24.1669 29.4883 29.1656 24.4896 29.1656 18.3228C29.1656 12.1567 24.1669 7.15778 17.9996 7.15778L17.9997 7.15724ZM23.1203 23.2612C22.9203 23.5892 22.4909 23.6932 22.1629 23.4919C19.5413 21.8906 16.2411 21.5279 12.3544 22.4159C11.9799 22.5012 11.6065 22.2666 11.5212 21.8919C11.4355 21.5172 11.6692 21.1439 12.0447 21.0586C16.298 20.0864 19.9464 20.5052 22.8896 22.3039C23.2176 22.5052 23.3216 22.9332 23.1203 23.2612ZM24.4869 20.2206C24.2349 20.6306 23.6989 20.7599 23.2896 20.5079C20.2883 18.6627 15.7132 18.1284 12.1632 19.206C11.7028 19.3451 11.2165 19.0856 11.0768 18.626C10.9381 18.1656 11.1977 17.6803 11.6573 17.5403C15.7124 16.3099 20.7536 16.9059 24.2003 19.0239C24.6096 19.2759 24.7389 19.8119 24.4869 20.2207V20.2206ZM24.6043 17.0547C21.0056 14.9172 15.0683 14.7207 11.6324 15.7635C11.0807 15.9308 10.4972 15.6194 10.33 15.0676C10.1628 14.5156 10.474 13.9326 11.0261 13.7648C14.9703 12.5675 21.5269 12.7988 25.6701 15.2584C26.1675 15.553 26.3301 16.1939 26.0355 16.6895C25.7421 17.1858 25.0995 17.3494 24.6048 17.0547H24.6043Z" fill={spotifyColor} />
                </g>
                <defs>
                    <clipPath id="clip0_1026_17744">
                        <rect width="22.4" height="22.4" fill="white" transform="translate(6.7998 7.12032)" />
                    </clipPath>
                </defs>
            </svg>
        </IconHolder>
    );
};
