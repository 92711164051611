import { ChangeEvent, FunctionComponent, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useStyles, { SpendLimitContainer } from "./styles";
import { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import { getAdsComission } from "utils";
import clsx from "clsx";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { track } from "analytics";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { minimumDailyBudgetCalculator } from "../../../constants";
import SpendLimitAlertCard from "components/SpendLimitAlertCard";
import { SystemColors } from "types/globalStyles";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import { Body2 } from "components/shareable/Typography";
import Lottie from "react-lottie";
import LoadingLottie from 'assets/images/lotties/SymphonyLoading.json'
import getSymbolFromCurrency from "currency-symbol-map";
import { CURRENCY_CODE_DATA } from "helpers/currencies";
import { Container, SymphonyTooltip } from "styles/shared";
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";
import LinkButton from "components/shareable/LinkButton";
import Intercom from "helpers/Intercom";

const ERROR_COLOR = "rgba(239, 68, 68, 1)";

interface Props {
  targetingType: string;
  budgetValue: number;
  isProUser: boolean;
  handleChangeInputText?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const BudgetCard: FunctionComponent<Props> = ({
  targetingType,
  budgetValue,
  isProUser,
  handleChangeInputText,
}: Props) => {
  // TODO: Let's comment this for now in case roll back is needed
  const adsComission = 0 // getAdsComission(isProUser);
  const MIN_DAILY_BUDGET = minimumDailyBudgetCalculator(targetingType)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const { data: spendBudgetData, loading: loadingSpendBudget, currency } = useContext(SpendBudgetContext)
  const spendStatus = spendBudgetData?.status
  const spent = spendBudgetData?.spent || 0
  const isBudgetLimit = Boolean(spendStatus) && spendStatus !== 'healthy' && budgetValue > 0
  const classes = useStyles({ error: isBudgetLimit });

  const selectedCurrencyMessage = () => {
    if (currency !== "USD") {
      const currencyDetails = CURRENCY_CODE_DATA[currency]
      if (currencyDetails) {
        return (
          <Container display="flex" flexDirection="column" alignItems="flex-start" justifyContent="flex-start">
            <StyledLabel fontSize={14} fontWeight={400}>
              Your default currency is set to {currencyDetails.name} ({currencyDetails.code}).

            </StyledLabel>
            <button onClick={() => Intercom.openLearnHowEditCurrency()}>
              <SymphonyTooltip
                className="w-6 h-6"
                title="Click here to learn more."
                arrow
              >
                <LinkButton 
                fontSize="12px"
                text="Learn more"></LinkButton>
              </SymphonyTooltip>
            </button>
          </Container>
        )
      }
    }
    return null
  }
  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <StyledLabel fontSize={18} fontWeight={600}>
          Budget
        </StyledLabel>
        <StyledLabel color={SystemColors.SECONDARY_TEXT_COLOR} fontSize={16} marginTop="12px">
          Enter the total budget and dates you want to run your campaign.
        </StyledLabel>
        <Grid>
          <Grid
            className={clsx(
              classes.overall, classes.noBottomBorder, classes.paddingBottomThemeSpacing
              // TODO: Let's comment this for now in case roll back is needed
              // isProUser && classes.noBottomBorder,
              // isProUser && classes.paddingBottomThemeSpacing
            )}
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item
              style={{
                maxWidth: '50%'
              }}>
              <Grid container direction="column">
                <StyledLabel fontSize={18} fontWeight={600}>
                  Overall Budget
                </StyledLabel>
                {selectedCurrencyMessage()}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {loadingSpendBudget && budgetValue > 0 && (
                    <Lottie
                      height={24}
                      width={24}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoadingLottie,
                      }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    error={isBudgetLimit}
                    className={classes.textField}
                    variant="outlined"
                    value={budgetValue}
                    onChange={handleChangeInputText}
                    inputProps={{ className: classes.inputProps }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Body2
                            color={
                              isBudgetLimit
                                ? SystemColors.ERROR_COLOR
                                : SystemColors.PRIMARY_TEXT_COLOR
                            }
                          >
                            {getSymbolFromCurrency(currency)}
                          </Body2>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {budgetValue < MIN_DAILY_BUDGET && (
            <StyledLabel color={ERROR_COLOR} paddingTop="8px">
              Please enter a minimum budget of {getSymbolFromCurrency(currency)}{MIN_DAILY_BUDGET}.
            </StyledLabel>
          )}
          {spendBudgetData && budgetValue > 0 && spent > 0 && (
            <SpendLimitContainer>
              <SpendLimitAlertCard {...spendBudgetData} currencyCode={currency} />
            </SpendLimitContainer>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
