import { UseSwitchData } from "Hooks/useSwitch"
import { Captions, DataPlatform, Platforms, RecordSelected, SelectedPlatforms } from "../utils"
import { useEffect } from "react"
import { CreativeAssetTypes } from "../../constants"

const { UPLOAD_VIDEO } = CreativeAssetTypes
const { SPOTIFY } = Platforms

interface Setters {
  setCaptions: Function,
  setSelectedPlatforms: Function,
}

export const usePlatformsHandler = (args: {
  conversionsEnabled: Boolean,
  platformsSwitch: UseSwitchData,
  captions: Captions,
  selectedPlatforms: SelectedPlatforms,
  recordSelected: RecordSelected,
  creativeSelectorTab: string,
  campaignDraftData: Record<string, unknown> | null
  setters: Setters
}) => {
  const {
    conversionsEnabled,
    platformsSwitch,
    captions,
    selectedPlatforms,
    setters,
    recordSelected,
    creativeSelectorTab,
    campaignDraftData,
  } = args

  const { setCaptions, setSelectedPlatforms } = setters

  const deselectAllPlatforms = () => {
    const currentPlatformsStatus: Record<string, DataPlatform> = {
      ...selectedPlatforms
    }

    const currentCaptionsStatus: Record<string, string | null> = {
      ...captions
    }

    const keys = Object.keys(currentPlatformsStatus)

    keys.map((key: string) => {
      currentPlatformsStatus[key].selected = false
      if (key !== 'all') currentCaptionsStatus[key] = null
    })

    setSelectedPlatforms(currentPlatformsStatus as SelectedPlatforms)
    setCaptions(currentCaptionsStatus)
  }

  const selectAllPlatforms = () => {
    const currentPlatformsStatus: Record<string, DataPlatform> = {
      ...selectedPlatforms
    }

    const currentCaptionsStatus: Record<string, string> = {
      ...captions
    }

    const platformKeys = Object.keys(currentPlatformsStatus)

    platformKeys.map((key: string) => {
      const platformFound = recordSelected?.external_links?.find((record: { name: string }) => record.name === key)
      const addPlatformWithConversionsEnabled = conversionsEnabled && key !== 'all' && key !== 'custom' && platformFound
      const addPlatformWithConversionsDisabled = !conversionsEnabled && (platformFound || key === 'all' || key === 'custom')
      if (!currentCaptionsStatus[key] && (addPlatformWithConversionsEnabled || addPlatformWithConversionsDisabled)) {
        currentPlatformsStatus[key].selected = true
        let defaultCaption = `${recordSelected.name} out now on ${['all', 'custom'].indexOf(key) > -1 ? 'all streaming services' : currentPlatformsStatus[key].name}!`
        if(recordSelected.type === 'playlist') {
          defaultCaption = `Listen to ${recordSelected.name} on ${['all', 'custom'].indexOf(key) > -1 ? 'all streaming services' : currentPlatformsStatus[key].name}!`
        }
        currentCaptionsStatus[key] = defaultCaption
      }
    })

    setSelectedPlatforms(currentPlatformsStatus as SelectedPlatforms)
    setCaptions(currentCaptionsStatus)
  }

  const handleOnChangePlatformsSwitch = (check: boolean) => {
    platformsSwitch.toggle()
    if (check) {
      selectAllPlatforms()
    } else {
      deselectAllPlatforms()
    }
  }

  const enablePlatform = (platformKey: string) => {
    const currentPlatformsStatus: Record<string, DataPlatform> = {
      ...selectedPlatforms
    }

    currentPlatformsStatus[platformKey].selected = true

    setSelectedPlatforms(currentPlatformsStatus)

    if (recordSelected && !captions[platformKey as Platforms]) {

      let defaultCaption = `${recordSelected.name} out now on ${['all', 'custom'].indexOf(platformKey) > -1 ? 'all streaming services' : currentPlatformsStatus[platformKey].name}!`
      if (recordSelected.type === 'playlist') {
        defaultCaption = `Listen to ${recordSelected.name} on ${['all', 'custom'].indexOf(platformKey) > -1 ? 'all streaming services' : currentPlatformsStatus[platformKey].name}!`
      }
      setCaptions((prev: Captions) => ({ ...prev, [platformKey]: defaultCaption }))
    }

    checkIfAllPlatformsAreConnected();
  }

  const disablePlatform = (key: string) => {
    const currentPlatformsStatus: Record<string, DataPlatform> = {
      ...selectedPlatforms
    }

    currentPlatformsStatus[key].selected = false

    setCaptions({
      ...captions,
      [key]: null
    })
    setSelectedPlatforms(currentPlatformsStatus)
    platformsSwitch.disable()
  }

  const checkIfAllPlatformsAreConnected = () => {
    let platformsToValidate = {};

    platformsToValidate = Object.values(selectedPlatforms as {}).filter(value => {
      const platform = value as { key: string }
      const platformFound = recordSelected?.external_links?.find(
        (record: { name: string }) => record.name === platform.key
      )
      const isAllOrCustom = platform.key === 'all' || platform.key === 'custom'
      return platformFound || isAllOrCustom
    })

    if (conversionsEnabled) {
      platformsToValidate = Object.values(platformsToValidate).filter(platform => {
        const platformKey = (platform as { key: string }).key
        return platformKey !== 'all' && platformKey !== 'custom'
      })
    }
    const areAllPlatformsSelected = Object.values(platformsToValidate).every(
      platform => (platform as { selected: boolean }).selected
    )

    if (areAllPlatformsSelected)
      platformsSwitch.enable()
    else
      platformsSwitch.disable()
  }


  const setCaptionsToSelectedPlatforms = () => {
    const newCaptions: Record<string, string | null> = { ...captions }
    const platforms: Record<string, DataPlatform> = { ...selectedPlatforms }

    Object.keys(newCaptions).map((key: string) => {
      let caption = `${recordSelected?.name} out now on ${['all', 'custom'].indexOf(key) > -1 ? 'all streaming services' : platforms[key]?.name}!`
      if (recordSelected?.type === 'playlist') {
        caption = `Listen to ${recordSelected?.name} on ${['all', 'custom'].indexOf(key) > -1 ? 'all streaming services' : platforms[key]?.name}!`
      }
      if (!newCaptions[key]) newCaptions[key] = recordSelected && platforms[key]?.selected ? caption : null
    })
    setCaptions(newCaptions)
  }

  const setCaptionForService = (service: Platforms, value: string) => {
    const newCaptions = {
      ...captions,
      [service]: value
    }
    setCaptions(newCaptions)
  }

  const setCaptionForAllSelectedServices = (value: string) => {
    const newCaptions: Record<string, string | null> = { ...captions }
    const platforms: Record<string, DataPlatform> = { ...selectedPlatforms }

    Object.keys(newCaptions).forEach((key: string) => {
      if (platforms[key]?.selected || (conversionsEnabled && key === 'all')) {
        newCaptions[key] = value;
      }
    })
    setCaptions(newCaptions)
  }

  useEffect(() => {
    if (creativeSelectorTab !== UPLOAD_VIDEO && !conversionsEnabled) {
      setCaptionsToSelectedPlatforms()
    }
  }, [creativeSelectorTab])

  useEffect(() => {
    if (!recordSelected) {
      deselectAllPlatforms()
      platformsSwitch.disable()
    }
    if (conversionsEnabled) {
      selectAllPlatforms()
      platformsSwitch.enable()
    } else {
      deselectAllPlatforms()
      platformsSwitch.disable()
    }
  }, [conversionsEnabled, recordSelected])

  useEffect(() => {
    if (campaignDraftData && !Object.values(selectedPlatforms).some(platform => platform.selected)) {
      enablePlatform(SPOTIFY)
    }
  }, [selectedPlatforms])

  useEffect(() => {
    checkIfAllPlatformsAreConnected()
  }, [captions])

  useEffect(() => {
    if (!conversionsEnabled) {
      setCaptionsToSelectedPlatforms()
    }
  }, [selectedPlatforms])

  return {
    enablePlatform,
    disablePlatform,
    selectAllPlatforms,
    deselectAllPlatforms,
    handleOnChangePlatformsSwitch,
    checkIfAllPlatformsAreConnected,
    setCaptionForService,
    setCaptionForAllSelectedServices,
  }

}