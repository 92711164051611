import { Chip, Dialog } from '@material-ui/core'
import { Fragment, FunctionComponent, useState } from 'react'
import { LocationDetail } from '../MarketsDetail'
import useStyles, {
  BodyContainer,
  ButtonContainer,
  ButtonLabel,
  ChipsContainer,
  StyledModalContainer,
  TextBody,
} from './styles'
import { ReactComponent as Pause } from 'assets/images/campaignDetails/pause.svg'
import { ReactComponent as Play } from 'assets/images/campaignDetails/play.svg'
import { LoadingLottie } from 'components/Loader/LoadingLottie'
import { CampaignMapping } from 'types/global'
import HeaderModal from '../HeaderModal'
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'

interface Props {
  market?: CampaignMapping
  open: boolean
  closeModal: () => void
  onPauseMarket: () => void
  locations: LocationDetail[]
}

const MarketsConfirmationModal: FunctionComponent<Props> = ({
  market,
  open,
  closeModal,
  onPauseMarket,
  locations,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const { mobileView } = useMediaBreakpoints()

  const isLive = market?.fbCampaign.fields.status === 'ACTIVE'

  const handleOnConfirm = async () => {
    setLoading(true)
    await onPauseMarket()
    setLoading(false)
    closeModal()
  }

  return (
    <Dialog open={open} className={classes.dialog}>
      <StyledModalContainer isMobileWindow={mobileView}>
        {isLive ? (
          <HeaderModal
            closeModal={closeModal}
            title={`Pause ${market?.name}`}
            subtitle={`Are you sure you want to pause ${market?.name}?
                Your campaign will stop running ads in:`}
            HeaderIcon={Pause}
          />
        ) : (
          <HeaderModal
            closeModal={closeModal}
            title={`Resume ${market?.name}`}
            subtitle={`Are you sure you want to resume ${market?.name}?
                Your campaign will start running ads again in:`}
            HeaderIcon={Play}
          />
        )}
        <BodyContainer>
          <ChipsContainer>
            {locations.map((location) => (
              <Fragment key={location.id}>
                <Chip label={`${location.name} (${location.type})`} />
              </Fragment>
            ))}
          </ChipsContainer>
          {isLive && <TextBody>You can run ads in {market?.name} again by resuming.</TextBody>}
          <ButtonContainer onClick={handleOnConfirm} disabled={loading}>
            {loading ? (
              <LoadingLottie black height={20} width={20} />
            ) : (
              <ButtonLabel>Yes, {isLive ? 'pause' : 'resume'} this market</ButtonLabel>
            )}
          </ButtonContainer>
        </BodyContainer>
      </StyledModalContainer>
    </Dialog>
  )
}

export default MarketsConfirmationModal
