export enum PageTabs {
  RELEASED = 'Released',
  // UPCOMING = 'Upcoming',
}

export const TABS = [
  {
      value: PageTabs.RELEASED,
      label: "Released"
  },
  // TODO: uncomment when tab is ready
  // {
  //     value: PageTabs.UPCOMING,
  //     label: "Upcoming"
  // },
]