import { useState, useCallback, useContext, useEffect } from 'react';
import { LoadingIndicator } from '../Loader';
import { queryFB } from '../../helpers/FB';
import { CreativeTabConnectTypes } from 'types/global';
import { ConnectModalsContext } from 'Hooks/ConnectModalsProvider';
import Axios from '../../helpers/Interceptor';
import * as Sentry from '@sentry/browser';

interface FacebookAdAccountConnectProps {
  closeModal: () => void;
  artist: any;
  selected: any;
  set: any;
  businessManager: any;
  fbAdAccount: any;
  showConnectButton?: boolean;
}

export default function FacebookPixelConnect(
  props: FacebookAdAccountConnectProps
) {
  const {
    closeModal,
    artist,
    selected,
    set,
    fbAdAccount,
    showConnectButton = true,
  } = props;

  const { saveData } = useContext(ConnectModalsContext);

  const [availPixels, setPixels] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const [creatingNewPixel, setCreatingNewPixel] = useState<any>(false);

  // state for when items are loading
  const [loadingItems, setLoadingItems] = useState<boolean>(true);

  const isDisabledConnectButton = error || !selected;

  const getPixels = async () => {
    setLoadingItems(true);

    try {
      Sentry.setTag('flow', 'getPixels');
      Sentry.setContext('input', fbAdAccount.id);

      const results = await queryFB(`/${fbAdAccount.id}/adspixels`, {
        params: {
          fields: 'name,id,is_unavailable',
        },
      });
      const availPixels = results.data.filter((p: any) => !p.is_unavailable);
      setPixels(availPixels);
    } catch (error) {
      setError(true);
      Sentry.captureException(error);
    }
    setLoadingItems(false);
  };

  useEffect(() => {
    setError(null);
    getPixels();
  }, []);

  async function updateInBrand() {
    // setSpotifyArtist(item);
    let facebook_pixel = JSON.parse(JSON.stringify(selected));
    localStorage.setItem('facebook_pixel', JSON.stringify(facebook_pixel));

    // connect to brand
    // ~~
    // setConnectModal('');
    try {
      Sentry.setTag('flow', 'updateInBrand');
      Sentry.setContext('input', { artist, selected });

      await Axios.put(`/brand/${artist.slug}/connect`, {
        id: artist.id,
        name: artist.name,
        service: 'facebook_pixel',
        value: selected,
      });

      // reloadBrand()
      closeModal();
    } catch (error) {
      console.error('error creating brand', error);
      Sentry.captureException(error);
    }
  }

  async function createNewPixel() {
    if (creatingNewPixel) return;
    setCreatingNewPixel(true);

    // create new account
    const url = `/${fbAdAccount.id}/adspixels`;
    const payload = {
      name: 'Symphony Pixel',
    };

    const creationPromise = new Promise(async (resolve, reject) => {
      const response = await queryFB(url, {
        method: 'post',
        params: payload,
      });
      if (!response || response.error) {
        console.error('error', response);
        reject(response.error);
      } else {
        resolve(response);
      }
    });

    try {
      var created: any = await creationPromise;
      Sentry.setTag('flow', 'createNewPixel');
      Sentry.setContext('input', created);

      set({
        ...created,
        name: 'Symphony Pixel',
      });

      updateInBrand();
    } catch (error) {
      console.error('Error creating ad account', created);
      Sentry.captureException(error);
    }
  }

  const handleConnect = useCallback(() => {
    updateInBrand();
    setLoading(true);
  }, [artist.id, selected]);

  useEffect(() => {
    saveData({
      loading,
      connectModalType: CreativeTabConnectTypes.FACEBOOK_PIXEL_CONNECT,
      isDisabledConnectButton,
      handleConnect,
    });
  }, [isDisabledConnectButton, handleConnect, loading]);

  return (
    <div className="bg-white w-full h-full mx-auto">
      <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
        <p className="font-semibold text-gray-800">Connect Facebook Pixel</p>
        <a onClick={closeModal}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </a>
      </div>
      <div className="middle-wrapper px-4 lg:px-10 ">
        <div className="mt-5 pb-5 border-b border-gray-200">
          <h4 className="mb-1 font-semibold ">
            Select {artist.name}'s Facebook Pixel
          </h4>
          <p className="text-md mb-2 text-gray-500">
            If linked, clicks to your Symphony ads will send data to this pixel.
          </p>
        </div>
        {loadingItems ? (
          <div className="mt-3 flex items-center justify-center">
            <LoadingIndicator color="black" height="30px" />
          </div>
        ) : error ? (
          <p className="mt-4">No Pixels found...</p>
        ) : (
          <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
            {availPixels ? (
              availPixels.length > 0 ? (
                availPixels
                  .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item: any) => {
                    return (
                      <li
                        onChange={() => set(item)}
                        key={item.id}
                        className="cursor-pointer sy-card"
                      >
                        <div className="flex items-center">
                          <input
                            id={'facebook_' + item.id}
                            type="radio"
                            className="mr-2"
                            name={'conect-facebook-pages'}
                            value={selected}
                            checked={selected ? selected.id === item.id : false}
                          />

                          <div className="flex items-center">
                            <div className="px-3">
                              <label
                                htmlFor={'facebook_' + item.id}
                                className=""
                              >
                                {item.name}
                              </label>
                            </div>
                          </div>

                          {/*  */}
                        </div>
                      </li>
                    );
                  })
              ) : (
                // .splice(0, 3)
                <li className="cursor-pointer sy-card" onClick={createNewPixel}>
                  <div className="flex items-center">
                    {creatingNewPixel ? (
                      <div className=" flex-none loading-blk">
                        <div className="svg-loader">
                          <svg
                            className="svg-container"
                            height="36"
                            width="36"
                            viewBox="0 0 100 100"
                          >
                            <circle
                              className="loader-svg bg"
                              cx="50"
                              cy="50"
                              r="45"
                            ></circle>
                            <circle
                              className="loader-svg animate"
                              cx="50"
                              cy="50"
                              r="45"
                            ></circle>
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className="mr-1 border w-8 h-8 flex border-green-500 text-green-500 text-3xl items-center justify-center">
                        +
                      </div>
                    )}

                    <div className="flex items-center">
                      <div className="px-3">
                        <label className="text-green-500">
                          {creatingNewPixel
                            ? 'Creating New Pixel...'
                            : 'Create a New Pixel'}
                        </label>
                      </div>
                    </div>

                    {/*  */}
                  </div>
                </li>
              )
            ) : null}
          </ul>
        )}
        {showConnectButton && (
          <div className="text-center px-4 lg:px-10 py-4">
            <button
              disabled={isDisabledConnectButton}
              onClick={handleConnect}
              className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3"
            >
              {loading ? (
                <div className="h-6 m-auto flex justify-center">
                  <LoadingIndicator />
                </div>
              ) : (
                'Connect'
              )}
            </button>
          </div>
        )}
        <h6 className="w-12/12 mb-5 text-2x text-center pb-6">
          Don't see your pixel? You can create a new one via Facebook by
          following{' '}
          <a
            className="text-2x text-gray-700 text-center underline"
            href={`https://www.facebook.com/business/help/314143995668266?id=1205376682832142`}
            target="_blank"
          >
            {' '}
            these instructions. Learn how &gt;
          </a>
        </h6>
      </div>
    </div>
  );
}
