import styled from 'styled-components';

interface ButtonContainerProps {
  width: string;
  height: string;
  gap: string;
};

interface DefaultImageProps {
  borderRadius?: string,
  backgroundColor?: string,
  width: string,
  height: string,
};

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;
  border: 1px solid #ffc53a;
  gap: ${({ gap }: ButtonContainerProps) => gap};
  border-radius: 48px;
  width: ${({ width }: ButtonContainerProps) => width};
  height: ${({ height }: ButtonContainerProps) => height};
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  align-items: center;
  height: 100%;
`;

export const Label = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.64);
`;

export const DefaultImage = styled.img`
  background-color: ${({ backgroundColor }: DefaultImageProps) => backgroundColor || 'none'};
  max-width: ${({ width }: DefaultImageProps) => width};
  max-height: ${({ height }: DefaultImageProps) => height};
  min-width: ${({ width }: DefaultImageProps) => width};
  min-height: ${({ height }: DefaultImageProps) => height};
  border-radius: ${({ borderRadius }: DefaultImageProps) => borderRadius || "0"};
`;
