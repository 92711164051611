

import { Dialog, useMediaQuery, useTheme } from "@material-ui/core"
import { FunctionComponent, useState, useMemo, useEffect } from "react"
import { ModalContainer, useStyles } from "./style"
import RecordStreamsReview from "./Components/RecordStreamsReview"
import ChooseAnotherProjectView from "./Components/ChooseAnotherProjectView"
import { DuplicateSteps, getReviewInformation } from "./utils"
import {
    CreatedCampaign,
    CurrentBrand,
    DuplicateSongAdsRecord as IncreaseMusicStreamsRecord,
} from "types/global"

import { useHistory } from "react-router-dom"
import MainView from "./Components/MainView"

const {
    REVIEW,
    SELECT_DUPLICATE_OPTION,
    CHOOSE_ANOTHER_PROJECT,
    CHOOSE_ANOTHER_LINK,
} = DuplicateSteps

interface Props {
    open: boolean
    closeModal: () => void
}

const SuperboostModal: FunctionComponent<Props> = ({
    open,
    closeModal,
}: Props) => {
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyles()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))


    const [step, setStep] = useState<DuplicateSteps>(SELECT_DUPLICATE_OPTION)
    const [recordSelected, setRecordSelected] =
        useState<IncreaseMusicStreamsRecord | null>(null)




    useEffect(() => {
        if (open) {
            //   setRecordSelected(null)
            //   setStep(SELECT_DUPLICATE_OPTION)
            //   setLoadingRecordDetails(false)
            //   setErrorRecordDetails(false)
            //   setErrorDuplicateCampaign(false)
            //   setLoadingDuplicateCampaign(false)
        }
    }, [open])

    return (
        <Dialog open={open} classes={{ root: classes.rootDialog }}>
            <ModalContainer
                width={setResponsiveView ? "auto" : "678px"}
                padding={setResponsiveView ? "32px 24px" : "32px"}
            >
                <MainView
                    closeModal={closeModal} />
                {/* {step === SELECT_DUPLICATE_OPTION && (
          <SelectDuplicateOption
            error={errorDuplicateCampaign}
            loading={loadingDuplicateCampaign}
            campaignName={campaignName}
            recordType={recordType as string}
            closeModal={closeModal}
            handleDuplicateSame={handleClickDuplicateSame}
            handleDuplicateAnother={handleClickDuplicateAnother}
            duplicateAnotherIcon={duplicateIcon}
            campaignType={campaignType}
            thumbnailUrl={campaignType === CAMPAIGN_TYPES.record_streams ? thumbnailUrl : undefined}
          />
        )} */}
                {/* {step === CHOOSE_ANOTHER_PROJECT && (
          <ChooseAnotherProjectView
            {...{
              currentBrand,
              closeModal,
              isSpotifyConnected,
              campaignType,
              loadingRecordDetails,
              errorRecordDetails
            }}
            onChangeRecord={handleChangeRecord}
            handleGoBack={handleGoBackAnotherProjectView}
          />
        )}
        {step === REVIEW && (
          <RecordStreamsReview
            loading={loadingDuplicateCampaign}
            error={errorDuplicateCampaign}
            recordType={recordType as string}
            brandName={brandName as string}
            closeModal={closeModal}
            handleGoNext={handleConfirmDuplicateSongAds}
            recordName={recordName as string}
            genres={genres as string}
            handleGoBack={handleGoStep(
              recordSelected ? CHOOSE_ANOTHER_PROJECT : SELECT_DUPLICATE_OPTION
            )}
            platforms={platforms || []}
            thumbnailUrl={thumbnailUrl as string}
            mobile={setResponsiveView}
          />
        )}
        {step === CHOOSE_ANOTHER_LINK && (
          <DuplicateLinkClicksConfirmView
            loading={loadingDuplicateCampaign}
            error={errorDuplicateCampaign}
            closeModal={closeModal}
            confirmDuplicateLinkClicks={handleDuplicateLinkClicksData}
            handleGoBack={handleGoStep(SELECT_DUPLICATE_OPTION)}
          />
        )} */}
            </ModalContainer>
        </Dialog>
    )
}

export default SuperboostModal
