import DaysSelector from "components/shareable/DaysSelector";
import MenuProOption from "components/shareable/MenuProOption";
import { WebsiteEditorCard } from "../../styles";
import { Fragment, useContext, useEffect, useState } from "react";
import { KeyboardArrowDown } from "@material-ui/icons";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import { DATE_OPTIONS } from "pages/post-auth/DashboardModule/utils";
import StatsCardRow from "components/shareable/DATA_PRESENTATION/StatCards/row";
import { Stat } from "components/shareable/DATA_PRESENTATION/StatCards/utils";
import SymphonyLineChart from "components/shareable/DATA_PRESENTATION/Charts/AreaChart";
import SymphonyBarChart from "components/shareable/DATA_PRESENTATION/Charts/BarChart";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import Lottie from "react-lottie";
import { Body2, Caption, Subtitle2 } from "components/shareable/Typography";
import { WebsiteContext } from "../CustomizeWebsite/hooks/WebsiteContext";
import { SystemColors } from "types/globalStyles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { StreamingLinkTypeOptions, getIconBackgroundStyle, socialMediaIconsInit } from "../CustomizeWebsite/utils";
import { useStyles } from "../CustomizeWebsite/styles";
import { renderIcon } from "../CustomizeWebsite/components/SocialMediaIconsInputs";
import { millify } from "millify";
import { TopGeographiesBarChart } from "../OverallWebsiteDashboard/components/CountriesBarChart";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import { useTabs } from "react-headless-tabs";
import { WEBSITE_TYPES, WebsiteType } from "../../types";
import Axios from "helpers/Interceptor";
import { Fan } from "types/global";
import Avatar from "@material-ui/core/Avatar";
import { AvatarColor } from "pages/post-auth/Fans/Components/FanbaseTable/styles";
import { Container } from "styles/shared";
import LinkButton from "components/shareable/LinkButton";
import { track } from "analytics";
import { downloadCollectedDataCSV } from "./api";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import dayjs from "dayjs";

const { RECORD_STREAMS, DATA_COLLECTOR } = WEBSITE_TYPES

interface FieldResponse {
    id: string;
    label: string;
    value?: string;
    country?: string;
}

interface UserResponse {
    id: number,
    FanId: number,
    BrandContentId: number,
    fan_response_metadata: {
        fields: FieldResponse[]
    },
    fan: Fan,
    createdAt: string
}

function formatDecimalAsPercentage(decimal: number): string {
    const percentage = decimal * 100;
    return `${percentage.toFixed(0)}%`;
}


const getDateOptions = (isProUser: boolean) => [
    {
        id: "7-days-option",
        component: <p>Last 7 Days</p>,
        value: "last-7-days",
    },
    {
        id: "30-days-option",
        component: (
            <MenuProOption
                {...{ isProUser }}
                mainContainerClassName="flex items-center gap-4 w-full"
                labelClassName={!isProUser ? "w-5/12" : ""}
                label="Last 30 days"
            />
        ),
        value: "last-30-days",
    },
    {
        id: "3-months-option",
        component: (
            <MenuProOption
                {...{ isProUser }}
                mainContainerClassName="flex items-center gap-4 w-full"
                labelClassName={!isProUser ? "w-5/12" : ""}
                label="Last 3 Months"
            />
        ),
        value: "last-3-months",
    },
]

const highLevelStatsTabs = [
    { label: 'Signups', value: 'collected_data' },
    { label: 'Analytics', value: 'analytics' }
]

const highLevelStatsTabsContent = highLevelStatsTabs.map((item) => item.value)

interface WebsiteDashboardProps {
    isProUser: boolean;
    id: number;
    slug: string;
    websiteType?: WebsiteType
    handleOnChangeDates: (date: DATE_OPTIONS) => void
}

export default function WebsiteDashboardv2({
    isProUser,
    id,
    slug,
    websiteType,
    handleOnChangeDates,
}: WebsiteDashboardProps) {

    const classes = useStyles();
    const { currentBrand } = useContext(CurrentBrandContext)
    const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);
    const [selectedTab, setSelectedTab] = useTabs(highLevelStatsTabsContent, highLevelStatsTabs[0].value)
    const { metrics, fields = [] } = useContext(WebsiteContext)
    const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false)
    const [loadingResponses, setLoadingResponses] = useState<boolean>(false)

    // Date Range Selector
    const [openDaysSelector, setOpenDaysSelector] = useState(false)
    const [currentTimeRange, setTimeRange] = useState<DATE_OPTIONS>(isProUser ? "last-30-days" : 'last-7-days')
    const [dataCollected, setDataCollected] = useState<UserResponse[]>()
    const [downloadingCSV, setDownloadingCSV] = useState<boolean>(false)

    const handleOpenDaysSelector = () => setOpenDaysSelector(true);
    const handleCloseDaysSelector = () => setOpenDaysSelector(false);

    useEffect(() => {
        if (websiteType === DATA_COLLECTOR) {
            initDataCollected()
        }
    }, [])

    const initDataCollected = async () => {
        setLoadingResponses(true)
        const responses = await getDataCollected();
        setDataCollected(responses)
        setLoadingResponses(false)
    }

    const getDataCollected = async () => {
        try {
            const response = await Axios.get(`/website/${slug}/data`)
            const data = await response.data.data
            return data
        } catch (error) {
            console.error(error)
            return undefined
        }
    }

    const handleDownloadResponses = async () => {
        if (!isProUser) {
            track("Tried Downloading Data Collected CSV")
            handleOpenUpgradeToProModal();
            return;
        }

        setDownloadingCSV(true)
        const csvUrl = await downloadCollectedDataCSV(currentBrand?.slug!, id)
        if (csvUrl) window.open(csvUrl, '_blank')
        setDownloadingCSV(false)
        track("Download Data Collected CSV")
    }

    const handleChangeDaysSelector = async (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const value = event.target.value as (DATE_OPTIONS);
        const nonProInvalidValues = value === "last-30-days" || value === "last-3-months";
        const showUpgradeToProModal = !isProUser && nonProInvalidValues

        if (showUpgradeToProModal) {
            handleOpenUpgradeToProModal({
                source: "Dashboard - Date Picker"
            });
            return;
        } else {
            setTimeRange(value);
        }
        setLoadingMetrics(true)
        await handleOnChangeDates(value)
        setLoadingMetrics(false)
    };

    // Only shown for Data Collector
    function renderDataCollected() {
        if (loadingResponses || !dataCollected || !metrics) {
            return (
                <div className='p-4 w-full flex gap-2 items-center justify-center bg-white rounded-xl'>
                    <Lottie
                        height={24}
                        width={24}
                        style={{ margin: 0 }}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: SymphonyLoadingLottie,
                        }}
                    />
                </div>
            )
        }

        const fieldsSet = new Set()
        for (const fanResponse of dataCollected) {
            fanResponse.fan_response_metadata.fields.forEach(field => {
                fieldsSet.add(field.label)
            })
        }

        const historicFields = Array.from(fieldsSet) as string[]

        if (!historicFields.length) {
            return (
                <div className="w-full flex justify-center py-2">
                    <Body2>No data found</Body2>
                </div>
            )
        }


        return (
            <>
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Subtitle2 style={{ textWrap: 'nowrap' }}></Subtitle2>
                                </TableCell>
                                {historicFields.map((field) =>
                                    <TableCell>
                                        <Subtitle2 style={{ textWrap: 'nowrap' }}>{field}</Subtitle2>
                                    </TableCell>
                                )}
                                <TableCell>
                                    <Subtitle2 style={{ textWrap: 'nowrap' }}>Submission Time</Subtitle2>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataCollected.map((response) =>
                                <TableRow>
                                    <TableCell>
                                        {response.fan.profile_url ? (
                                            <Avatar className={classes.avatar} src={response.fan.profile_url} />
                                        ) : (
                                            <AvatarColor backgroundColor={''} />
                                        )}
                                        <Body2 style={{ textWrap: 'nowrap' }}>{response.fan.email}</Body2>
                                    </TableCell>
                                    {fields.map((field) =>
                                        <TableCell>
                                            <Body2 style={{ textWrap: 'nowrap' }}>{response.fan_response_metadata.fields.find((fieldResponded) => fieldResponded.label === field.label)?.value || '-'}</Body2>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Body2 style={{ textWrap: 'nowrap' }}>{response.createdAt ? dayjs(response.createdAt).format('MMM DD, YYYY H:mm A') : '-'}</Body2>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    // Renders top level summary stats
    function renderHighLevelStats() {
        if (!metrics) return null
        const { pageVisits, streamingLinkClicks, ctr, signups } = metrics.topLevelMetrics
        const stats: Stat[] = [
            {
                title: "Visits",
                value: pageVisits,
            },
            {
                title: "Clicks",
                value: streamingLinkClicks,
            },
            {
                title: "Clickthrough Rate",
                value: formatDecimalAsPercentage(ctr),
            },
        ]

        if (websiteType !== DATA_COLLECTOR) {
            return (
                <WebsiteEditorCard
                    title="High-Level Stats"
                    subtitle="See how your website is performing"
                    padding={0}
                >
                    <StatsCardRow stats={stats} />
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Visits</Caption>
                    <SymphonyLineChart
                        xAxisKey="date"
                        yAxisKey="pageViews"
                        data={metrics.overallActivity.map((item) => {
                            return {
                                date: item.date,
                                pageViews: item.pageViews
                            }
                        })}
                    />
                </WebsiteEditorCard>
            )
        } else {
            return (
                <>
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>Visits</Caption>
                    <SymphonyLineChart
                        xAxisKey="date"
                        yAxisKey="pageViews"
                        data={metrics.overallActivity.map((item) => {
                            return {
                                date: item.date,
                                pageViews: item.pageViews
                            }
                        })}
                    />
                </>
            )
        }
    }

    // Renders high level stats around a website. Only music sites are supported for now.
    // this returns data for a music site's website streaming link clicks
    function renderWebsiteActions() {
        if (!metrics) return null

        const { streamingServicesBreakdown } = metrics

        if (!streamingServicesBreakdown.length) {
            return (
                <div className="w-full flex justify-center py-2">
                    <Body2>No data found</Body2>
                </div>
            )
        }

        const sortedBreakdown = streamingServicesBreakdown.sort((a, b) => {
            return b.count - a.count
        })


        const getTickContent = (value: string) => {
            const clicks = sortedBreakdown.find((item) => item.service === value)?.count || 0
            const serviceObject = StreamingLinkTypeOptions.find((item) => item.title.includes(value))
            const valueLength = serviceObject ? 10 : 18

            return (
                <div className='flex justify-between items-center gap-2 '>
                    <div className={`flex items-center ${serviceObject ? "gap-2" : ""}`}>
                        <div className={serviceObject ? classes.socialIconContainer : classes.socialIconEmptyContainer} style={getIconBackgroundStyle(serviceObject)}>
                            {renderIcon(true, serviceObject)}
                        </div>
                        <Body2
                            color={SystemColors.PRIMARY_TEXT_COLOR}
                            style={{ width: 'fit-content', textWrap: value.length > valueLength ? 'wrap' : 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {value}
                        </Body2>
                    </div>
                    <Body2
                        color={SystemColors.PRIMARY_TEXT_COLOR}
                        style={{ width: 'fit-content' }}
                    >
                        {millify(clicks)}
                    </Body2>
                </div>
            )
        }

        return (
            <SymphonyBarChart
                direction="horizontal"
                yAxisKey="name"
                xAxisKey="value"
                tickWidth={170}
                tickHeight={46}
                barHeight={24}
                barWidth={150}
                tick={getTickContent}
                data={sortedBreakdown.map((item) => {
                    return {
                        name: item.service,
                        value: item.count
                    }
                })}
            />
        )
    }

    function renderSocialActions() {
        if (!metrics) return null
        const { socialMediaBreakdown } = metrics

        if (!socialMediaBreakdown?.length) {
            return (
                <div className="w-full flex justify-center py-2">
                    <Body2>No data found</Body2>
                </div>
            )
        }

        const sortedBreakdown = socialMediaBreakdown.sort((a, b) => {
            return b.count - a.count
        })


        const getTickContent = (value: string) => {
            const clicks = sortedBreakdown.find((item) => item.service === value)?.count || 0
            const serviceObject = socialMediaIconsInit[value]

            return (
                <div className='flex justify-between items-center gap-2 '>
                    <div className="flex gap-2 items-center">
                        <div className={classes.socialIconContainer} style={getIconBackgroundStyle(serviceObject)}>
                            {renderIcon(true, serviceObject)}
                        </div>
                        <Body2
                            color={SystemColors.PRIMARY_TEXT_COLOR}
                            style={{ width: 'fit-content', textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {serviceObject.title}
                        </Body2>
                    </div>
                    <Body2
                        color={SystemColors.PRIMARY_TEXT_COLOR}
                        style={{ width: 'fit-content' }}
                    >
                        {millify(clicks)}
                    </Body2>
                </div>
            )
        }

        return (
            <SymphonyBarChart
                direction="horizontal"
                yAxisKey="name"
                xAxisKey="value"
                tickWidth={170}
                tickHeight={46}
                barHeight={24}
                barWidth={150}
                tick={getTickContent}
                data={sortedBreakdown.map((item) => {
                    return {
                        name: item.service,
                        value: item.count
                    }
                })}
            />
        )
    }


    if (loadingMetrics || !metrics) {
        return (
            <div className='p-4 w-full flex gap-2 items-center justify-center bg-white rounded-xl'>
                <Body2>Loading data...</Body2>
                <Lottie
                    height={24}
                    width={24}
                    style={{ margin: 0 }}
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: SymphonyLoadingLottie,
                    }}
                />
            </div>
        )
    }

    // this is only shown 
    const { pageVisits, streamingLinkClicks, ctr, signups } = metrics.topLevelMetrics



    return (
        <div className='w-full flex flex-col gap-4'>
            <WebsiteEditorCard
                title="Website Dashboard"
            >
                <DaysSelector
                    options={getDateOptions(isProUser)}
                    selectProps={{
                        open: openDaysSelector,
                        className: "h-11 mt-2",
                        variant: "outlined",
                        value: currentTimeRange,
                        IconComponent: () => (
                            <div
                                className="cursor-pointer mr-2 lg:w-1/2"
                                style={{ width: "fit-content" }}
                                onClick={handleOpenDaysSelector}
                            >
                                <KeyboardArrowDown viewBox="0 -2 26 26" />
                            </div>
                        ),
                        onOpen: handleOpenDaysSelector,
                        onClose: handleCloseDaysSelector,
                        onChange: handleChangeDaysSelector,
                    }}
                />
            </WebsiteEditorCard>
            <WebsiteEditorCard gap={16}>
                {websiteType === RECORD_STREAMS && renderHighLevelStats()}
                {websiteType === DATA_COLLECTOR && (
                    <Fragment>
                        <Container display="flex" flexDirection="column" >

                            <WebsiteEditorCard
                                title="High-Level Stats"
                                subtitle="See how your website is performing"
                                padding={0}
                            >
                                <StatsCardRow
                                    disablePadding
                                    stats={[
                                        {
                                            title: "Visits",
                                            value: pageVisits,
                                        },
                                        {
                                            title: "Total Signups",
                                            value: signups,
                                        },
                                        {
                                            title: "Submission Rate",
                                            value: formatDecimalAsPercentage(ctr),
                                        },
                                    ]} />
                                <Container display="flex" alignItems="center" justifyContent="space-between">


                                    <SymphonyTabs
                                        data={highLevelStatsTabs}
                                        selectedValue={selectedTab!}
                                        onChange={setSelectedTab}
                                        gap={16}
                                        tabContainerStyle={{ paddingRight: 0, paddingLeft: 0 }}
                                        tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                                    />
                                    {(selectedTab === 'collected_data' && Boolean(dataCollected?.length)) && (
                                        <LinkButton text="Download CSV" onClick={handleDownloadResponses} loading={downloadingCSV} />
                                    )}
                                </Container>

                            </WebsiteEditorCard>
                        </Container>

                        {selectedTab === 'analytics' && renderHighLevelStats()}
                        {selectedTab === 'collected_data' && renderDataCollected()}
                    </Fragment>
                )}
            </WebsiteEditorCard>
            {websiteType === RECORD_STREAMS &&
                <Fragment>
                    <WebsiteEditorCard
                        title="Website Actions"
                        subtitle='These are the clicks on specific streaming services on this website.'>
                        {renderWebsiteActions()}
                    </WebsiteEditorCard>
                    <WebsiteEditorCard
                        title="Social Actions"
                        subtitle="These are clicks on social icons.">
                        {renderSocialActions()}
                    </WebsiteEditorCard>
                </Fragment>
            }
            <WebsiteEditorCard
                title="Top Countries by Page Views"
                subtitle="These are the countries that sent the most traffic to your site.">
                <TopGeographiesBarChart metrics={metrics.geographicBreakdown} areaType='countries' />
            </WebsiteEditorCard>
            <WebsiteEditorCard
                title="Top Cities by Clicks"
                subtitle="These are the cities that sent the most traffic to your site.">
                <TopGeographiesBarChart metrics={metrics.geographicBreakdown} areaType='cities' />
            </WebsiteEditorCard>
        </div>
    )
}
