import { ITimezoneOption } from "components/shareable/TimezoneSelect/types/timezone";
import moment, { Moment } from "moment";

export const getDatePickerDateFormat = (
  _selectedTimezone: ITimezoneOption | string
) => "MMMM d, yyyy h:mm aa";

export const getSelectedDatePickerDate = (selectedDate: Moment | null) => {
  if (selectedDate) return moment(selectedDate).toDate();
  return null;
};