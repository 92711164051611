import { FunctionComponent } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { getAdsComission } from "utils";
import { TotalBannerContainer } from "./styles";
import { useMediaQuery, useTheme } from "@material-ui/core";

interface Props {
  budget: number;
  isProUser: boolean;
}

const format = (budget: number) =>
  budget.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  });

const BudgetCard: FunctionComponent<Props> = ({ budget, isProUser }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const classes = useStyles();
  const adsComission = 0;

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <CircularStep step={1} />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Budget
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <StyledLabel fontSize={16} fontWeight={600}>
                    Marketing Budget
                  </StyledLabel>
                </Grid>
                <Grid item>
                  <StyledLabel color="#707070" fontSize={12} fontWeight={400}>
                    Your marketing budget will be fully spent on running ads.
                  </StyledLabel>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={400}>
                ${format(budget - budget * adsComission)}
              </StyledLabel>
            </Grid>
          </Grid>
        </Grid>
        {!isProUser && (
          <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <StyledLabel fontSize={16} fontWeight={600}>
                      Symphony Pro
                    </StyledLabel>
                  </Grid>
                  <Grid item>
                    <StyledLabel color="#707070" fontSize={12} fontWeight={400}>
                      When you checkout, you'll be subscribed to Symphony Pro (monthly plan)
                    </StyledLabel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <StyledLabel fontSize={16} fontWeight={400}>
                  ${format(15)}
                </StyledLabel>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item className={clsx(classes.space, classes.noPaddingX)}>
          <TotalBannerContainer>
            <StyledLabel fontSize={16} fontWeight={600}>
              Total
            </StyledLabel>
            <StyledLabel fontSize={18} fontWeight={500}>
              ${format(budget + (isProUser ? 0 : 15))}
            </StyledLabel>
          </TotalBannerContainer>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BudgetCard;
