import { FunctionComponent, useState } from "react"
import HeaderModal from "../../../HeaderModal"
import {
  CardInfo,
  CardSubTitle,
  CardTitle,
  DuplicateCard,
  DuplicateOptionsContainer,
  CardImage
} from "../../style"
import duplicateThisImage from "assets/images/campaignDetails/Temporary-Component.png"
import { ReactComponent as SuperboostIcon } from "assets/images/ui/superboost.svg"
import Lottie from "react-lottie"
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading.json"
import ErrorBanner from "../ErrorBanner"
import { CAMPAIGN_TYPES } from '../../../../../../../constants';

enum Options {
  SAME = "SAME",
  OTHER = "OTHER",
}

const { SAME, OTHER } = Options

interface Props {
  closeModal: () => void
}

const MainView: FunctionComponent<Props> = ({

  closeModal
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<Options | null>(null)

  return (
    <>
      <HeaderModal
        HeaderIcon={SuperboostIcon}
        title="Superboost Instagram Post"
        subtitle={`What do you want to grow?`}
        closeModal={closeModal}
      />
      <DuplicateOptionsContainer>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Instagram Post Engagement</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>

        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Instagram Profile Visits</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Grow Awareness on a Song / Project</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>
        <DuplicateCard
        // onClick={handleSelectDuplicateSameOption}
        >
          {/* <CardImage src={thumbnailUrl ? thumbnailUrl : duplicateThisImage} /> */}
          <CardInfo>
            <CardTitle>Website traffic</CardTitle>
            {/* <CardSubTitle>{`${campaignName}`}</CardSubTitle> */}
          </CardInfo>

        </DuplicateCard>

      </DuplicateOptionsContainer>
      {/* {!loading && error && (
        <div className="order-2">
          <ErrorBanner errorText="A problem ocurred when duplicating your campaign... Please try again." />
        </div>
      )} */}
    </>
  )
}

export default MainView
