import { FunctionComponent } from "react";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const DEFAULT_CLASSNAME = "flex items-center gap-36";

interface Props {
  selectProps: SelectProps;
  options: {
    id?: string;
    component: React.ReactNode;
    itemClassName?: string;
    value: string;
  }[];
}

const DaysSelector: FunctionComponent<Props> = ({
  selectProps,
  options,
}: Props) => {
  return (
    <Select
      fullWidth={window.innerWidth < 768}
      {...selectProps}
    >
      {options.map(({ id, component, itemClassName, value }, index) => (
        <MenuItem
          {...{ value }}
          className={itemClassName || DEFAULT_CLASSNAME}
          key={id ? id : index}
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
        >
          {component}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DaysSelector;
