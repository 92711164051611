import { Fragment, FunctionComponent, useState } from "react"
import { DateTimezoneInputContainer } from "./style"
import { ReactComponent as CalendarIcon } from "assets/images/calendar-email-campaign.svg"
import HeaderModal from "../../HeaderModal"
import DatePicker from "react-datepicker"
import moment from "moment"
import Axios from "helpers/Interceptor"
import some from "lodash/some"
import * as Sentry from "@sentry/react";
import TimezoneSelect from "components/shareable/TimezoneSelect"
import { ITimezoneOption } from "components/shareable/TimezoneSelect/types/timezone"
import momentTz from "moment-timezone";
import { getDatePickerDateFormat } from './utils';
import DialogPaper from 'components/shareable/DialogPaper'
import PrimaryButton from 'components/shareable/PrimaryButton'
import { showToast } from 'utils'
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'
import { track } from "analytics"
import ReleaseTypeSwitch from "pages/post-auth/MarketingPage/PreSave/Components/ReleaseTypeSwitch"
import { Checkbox } from '@material-ui/core';

interface Props {
  open: boolean
  campaignId: number
  releaseDate: string
  timezone: ITimezoneOption | string
  trackObject?: Record<string, any>
  isLocalRelease: boolean
  hideReleaseDate: boolean
  onErrorHandler?: () => void
  closeModal: () => void
  reload: (endDate: string, timezone: string) => void
}

const EditReleaseDateModal: FunctionComponent<Props> = ({
  open,
  campaignId,
  releaseDate,
  timezone,
  trackObject = {},
  isLocalRelease,
  hideReleaseDate,
  closeModal,
  reload,
  onErrorHandler,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [newEndDate, setNewEndDate] = useState<string>(releaseDate)
  const [newIsLocalRelease, setNewIsLocalRelease] = useState<boolean>(isLocalRelease)
  const [isHideReleaseDate, setHideReleaseDate] = useState<boolean>(hideReleaseDate)

  const toggleLocalRelease = () => setNewIsLocalRelease(prev => !prev)
  const handleHideReleaseDate = () => setHideReleaseDate(prev => !prev)

  // timezone
  const defaultSelectedTimezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimezone, setSelectedTimezone] = useState<
    ITimezoneOption | string
  >(timezone ? timezone : defaultSelectedTimezone);

  const { mobileView } = useMediaBreakpoints()

  const handleEditEndDate = async () => {
    setLoading(true)
    try {
      const toUpdate: any = {
        releaseDate: moment(newEndDate).utc().format(),
        timezone: null,
        localRelease: newIsLocalRelease,
        hideReleaseDate: isHideReleaseDate,
      }
      
      if (timezone !== selectedTimezone
        && typeof selectedTimezone != 'string') {
        toUpdate.timezone = selectedTimezone.value
      }

      const response = await Axios.put(
        `/campaign/${campaignId}/presave/releaseDate`,
        toUpdate
      )

      if (
        some([
          !response,
          !response.data,
          !response.data.data,
          response.data.isError,
        ])
      ) {
        throw new Error("Error editing editing release date on campaign")
      }
      let fixedTimezone = typeof selectedTimezone === "string" ? selectedTimezone : selectedTimezone.value
      reload(newEndDate, fixedTimezone)
      showToast({ message: 'Release date updated!', mobile: mobileView })
      track("Saved Edit Release Date", {
        ...trackObject,
        opened: 'Edit Release Date Modal'
      })
    } catch (e) {
      Sentry.captureException(`Error editing end date in campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const handleChangeDate = (date: Date) => {
    const selectedTz =
      typeof selectedTimezone === "string"
        ? selectedTimezone
        : selectedTimezone.value;

    const mo1 = moment(date); // in browser default time zone, e.g. America/Denver
    const mo2 = moment(date).tz(selectedTz); // in current state time zone e.g. Africa/Bamako

    const modifiedDate = moment(date);
    modifiedDate.subtract(mo2.utcOffset() - mo1.utcOffset(), 'minutes');

    setNewEndDate(moment(modifiedDate).format('YYYY-MM-DD HH:mm:ss'))
  }

  const filterPassedTime = (time: string | number | Date) => {
    const selectedTz =
      typeof selectedTimezone === "string"
        ? selectedTimezone
        : selectedTimezone.value;
    const currentDateTimezone = momentTz.tz(new Date(), selectedTz).toDate();
    const selectedDateTimezone = momentTz
      .tz(new Date(time), selectedTz)
      .toDate();

    return currentDateTimezone.getTime() < selectedDateTimezone.getTime();
  };

  const datePickerDateFormat = getDatePickerDateFormat(selectedTimezone);

  const DatepickerInput = ({ ...props }) => (
    <input type="text" {...props} readOnly style={{ marginBottom: 0 }} />
  );

  const formatDate = (date: string, toDate = true) => {
    const selectedTz =
      typeof selectedTimezone === "string"
        ? selectedTimezone
        : selectedTimezone.value;

    const mo1 = moment(date); // in browser default time zone, e.g. America/Denver
    const mo2 = moment(date).tz(selectedTz); // in current state time zone e.g. Africa/Bamako

    const modifiedDate = moment(date);
    modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');
    return new Date(modifiedDate.valueOf())
  }

  const handleCancelModal = () => {
    track("Cancelled Edit Release Date", {
      ...trackObject,
      opened: 'Edit Release Date Modal'
    })
    closeModal()
  }

  const formatted = formatDate(newEndDate, true);
  return (
    <DialogPaper open={open}>
      <Fragment>
        <HeaderModal
          HeaderIcon={CalendarIcon}
          closeModal={handleCancelModal}
          title='Release date'
          subtitle={'Customize the release date'}
        />
        <div>
          <DateTimezoneInputContainer>
            <DatePicker
              withPortal
              showTimeSelect
              className="p-3 rounded w-full"
              minDate={moment().add(1, "day").toDate()}
              selected={formatted}
              onChange={handleChangeDate}
              filterTime={filterPassedTime}
              placeholderText="MMMM DD, YYYY"
              dateFormat={datePickerDateFormat}
              customInput={<DatepickerInput />}
            />
            <TimezoneSelect
              styles={{
                control: (provided, _state) => ({
                  ...provided,
                  height: 56,
                  borderRadius: 8,
                }),
                singleValue: (provided, _state) => ({
                  ...provided,
                  color: "#000",
                }),
              }}
              labelStyle="abbrevNoPrefix"
              value={selectedTimezone}
              onChange={setSelectedTimezone}
            />
          </DateTimezoneInputContainer>
          <div className="flex flex-row items-center mt-2">
              <Checkbox
                checked={isHideReleaseDate}
                onChange={handleHideReleaseDate}
                color="default"
              />
              <span>Hide release date from your page</span>
            </div>
          <ReleaseTypeSwitch
            isLocalRelease={newIsLocalRelease}
            toggleSwitch={toggleLocalRelease}
          />
        </div>
        <PrimaryButton
          onClick={handleEditEndDate}
          text='Save'
          disabled={loading || !newEndDate || !selectedTimezone}
          width='100%'
          loading={loading}
        />
      </Fragment>
    </DialogPaper>
  )
}

export default EditReleaseDateModal
