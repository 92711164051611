import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";


export const useStyles = makeStyles(() => ({
  rootMenu: {
    "&.MuiPaper-rounded": {
      borderRadius: "8px",
    }
  },
}));

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
  margin-right: 8px;
`;