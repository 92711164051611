import Dialog from "@material-ui/core/Dialog"
import { FunctionComponent } from "react"
import {
  ModalConnectButton,
  ModalConnectButtonContainer,
  ModalConnectButtonLabel,
  ModalContainer,
  useStyles,
} from "../../style"
import ArtistItem from "../ArtistList/ArtistItem"
import { useHistory } from "react-router-dom"
import { OnboardingArtist } from "types/global"
import { useMediaQuery, useTheme } from '@material-ui/core'
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'

interface Props {
  open: boolean
  artist: OnboardingArtist
  closeModal: () => void
  createBrand?: () => void
}

const ConnectPlatformModal: FunctionComponent<Props> = ({
  open,
  artist,
  closeModal,
}: Props) => {
  const history = useHistory()
  const classes = useStyles()

  const goToDashboard = (_parameter: unknown) => {
    history.push('/dashboard')
  }
  
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{ 
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer 
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        maxHeight="100%"
        gap="32px"
      >
        <HeaderModal
          closeModal={closeModal}
          title="You’ve already added this artist."
          subtitle={`Looks like you’ve already added ${artist?.name || ""} to the artists under your profile.`}
        />
        <ArtistItem
          artist={artist}
          selectArtist={goToDashboard}
          buttonLabel="Go to Artist Dashboard"
        />
        <ModalConnectButtonContainer>
          <ModalConnectButton onClick={closeModal} color={"#ffffff"}>
            <ModalConnectButtonLabel color={"#8800ff"}>
              Search for another artist
            </ModalConnectButtonLabel>
          </ModalConnectButton>
        </ModalConnectButtonContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default ConnectPlatformModal
