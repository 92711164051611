import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { PlacementsBlock, TargetingBlock } from "../utils"
import useStyles, { CustomTab, CustomTabs } from "./styles";
import RowItem from "components/shareable/RowItem";
import YouTubeImage from "assets/images/youtube.svg";
import { Launch } from "@material-ui/icons";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";

const getColumnDefinition = (currency: CurrencyCodesType, mobile?: boolean) => {
    const defaultColDef: GridColDef = {
        field: "field",
        headerName: "column",
        type: "string",
        sortable: true,
        editable: false,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
        minWidth: 120,
        flex: 1,
        width: 120,
    }
    return [
        {
            ...defaultColDef,
            field: "name",
            headerName: "Audience",
            align: 'left',
            headerAlign: 'left',
            minWidth: mobile ? 250 : 300,

            renderCell: (params) => {
                const formattedRow = params.row as PlacementsBlock;

                return (
                    <div className="flex flex-row justify-center items-center">
                        {(
                            <img className="flex-shrink-0 h-10 w-10 md:w-12 md:h-12 rounded-md" src={formattedRow.thumbnails || YouTubeImage}
                                onError={console.log} />
                        )}
                        <div className="ml-2  text-ellipsis overflow-hidden">
                            <div className="text-sm md:text-base overflow-ellipsis overflow-hidden">
                                {formattedRow.channelTitle}
                            </div>
                            <div className="mt-1 flex flex-row items-center">
                                {formattedRow.channelTitle === 'youtube.com' ? (
                                    <p className="text-gray-500 text-xs flex flex-row items-center justify-center hover:text-gray-700 transition-colors "
                                    >Views from the youtube.com homepage.</p>

                                ) : formattedRow.youtubeUrl ? (<a
                                    target="_blank"
                                    href={formattedRow.youtubeUrl}
                                    className="text-gray-500 text-xs flex flex-row items-center justify-center hover:text-gray-700 transition-colors "
                                    rel="noreferrer"
                                >
                                    View on YouTube
                                    <div className=" ml-1 max-h-12"><Launch fontSize="inherit" /></div>
                                </a>) : formattedRow.id === 'other' ? (
                                    <p className="text-sm text-gray-500 w-6/12">These are channels across YouTube that drove less than 10 views to your ads.</p>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )
            },
            valueGetter: (params) => params.row?.channelTitle
        },
        {
            ...defaultColDef,
            field: "views",
            headerName: "Views",
            renderCell: (params) => {
                const formattedRow = params.row as PlacementsBlock;
                const formatted = formattedRow.data?.sum_views ? formattedRow.data.sum_views.toLocaleString('en') : 0

                return (<p className="text-base">{formatted}</p>)
            },
            valueGetter: (params) => params.row?.data.sum_views
        },

        {
            ...defaultColDef,
            field: "spend",
            headerName: "Spend",
            renderCell: (params) => {
                const formattedRow = params.row as PlacementsBlock;
                const formatted = formattedRow.data?.sum_cost ? formattedRow.data.sum_cost.toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) : 0


                return (<p className="text-base">{getSymbolFromCurrency(currency)}{formatted}</p>)
            },
            valueGetter: (params) => params.row?.data.sum_cost
        },

        {
            ...defaultColDef,
            field: "cpv",
            headerName: "Cost-per-View",
            renderCell: (params) => {
                const formattedRow = params.row as PlacementsBlock;
                const formatted = formattedRow.data?.avg_cpv ? formattedRow.data.avg_cpv.toLocaleString('en', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) : 0


                return (<p className="text-base">{getSymbolFromCurrency(currency)}{formatted}</p>)
            },
            valueGetter: (params) => params.row?.data.avg_cpv
        },
    ] as GridColDef[];
}
export default function AudiencesStats(props: {
    placements: PlacementsBlock[]
    currency: CurrencyCodesType
}) {
    const { placements } = props

    const classes = useStyles()

    const theme = useTheme()
    const showResponsiveGraphView = useMediaQuery(theme.breakpoints.down(575));


    let audiences: TargetingBlock[] = []
    let combinedAudiences: PlacementsBlock[] = []


    // composedaudiences
    if (placements && placements.length > 0) {
        combinedAudiences = placements
    }


    let mainContent = null

    if (combinedAudiences.length > 0) {
        mainContent = (

            <div>
                <DataGridPro
                    rowSelection={false}
                    // initialState={{
                    //     sorting: {
                    //         sortModel: [{ field: 'clicks', sort: 'desc' }],
                    //     },
                    // }}
                    // treeData
                    // loading={false}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        sorting: {
                            sortModel: [{ field: 'views', sort: 'desc' }],
                        }
                    }}
                    pageSizeOptions={[]}
                    rowHeight={72}
                    columns={getColumnDefinition(props.currency, showResponsiveGraphView)}
                    rows={combinedAudiences}
                    autoHeight
                    pagination={true}
                    classes={{ root: classes.datagridRoot }}
                />
                {/* // <ul className="max-h-80 overflow-auto">
                    //   {
                    //     combinedAudiences
                    //       .map((item: PlacementsBlock) => {

                    //         const img = item.thumbnails
                    //         // get friendly name of service
                    //         return (
                    //           <RowItem
                    //             key={item.id}
                    //             // onClick={() => selectYoutubeChannel(item)}
                    //             hideImage={true}
                    //             imageStyles={`h-6`}
                    //             name={(
                    //        
                    //             )}
                    //             showRightValue={true}
                    //             rightValueComponent={(
                    //               <div className="pl-6 pr-3  text-right">
                    //                 {!(item.data && item.data.sum_views && item.data.sum_cost && item.data.avg_cpv) ? (
                    //                   <p>YouTube didn't run ads against this channel.</p>
                    //                 ) : (
                    //                   <>
                    //                     {item.data && item.data.sum_views && (<p>
                    //                       {item.data.sum_views.toLocaleString('en')} views
                    //                     </p>)}
                    //                     {item.data && item.data.sum_views && (<p>
                    //                       ${(item.data.avg_cpv).toLocaleString('en', {
                    //                         minimumFractionDigits: 2,
                    //                         maximumFractionDigits: 2,
                    //                         // type: 'currency'
                    //                       })} CPV
                    //                     </p>)}
                    //                     {item.data && item.data.sum_cost && (<p>
                    //                       ${(item.data.sum_cost).toLocaleString('en', {
                    //                         minimumFractionDigits: 2,
                    //                         maximumFractionDigits: 2,
                    //                         // type: 'currency'
                    //                       })} spent
                    //                     </p>)}
                    //                   </>)
                    //                 }

                    //               </div>
                    //             )}

                    //           />
                    //         );
                    //       })
                    //   }
                    // </ul> */}

            </div>
        )
    } else {
        mainContent = (

            <p className="my-4 text-center">It looks like you didn't enter any audiences when creating your campaign.</p>

        )
    }

    return (
        <div className="px-1 py-2">
            <div className="lg:px-3 pb-5 mt-2">
                <p className="text-xl">Targeted Audiences</p>
                <p className="text-lg text-gray-500">Your YouTube Ads will run in front of these channels' audiences.</p>

            </div>
            {mainContent}
        </div>
    )
}