import styled from "styled-components";

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  paddingTop?: string;
  alignText?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
}

interface CardProps {
  borderRadius?: string
}

export const Card = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  background-color: #ffffff;
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
  @media (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  };
  @media (max-width: 768px) {
    border-radius: 0px;
  };
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-top: ${({ paddingTop }: StyledLabelProps) => paddingTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  align-text: ${({ alignText }: StyledLabelProps) => alignText};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
`;

export const Container = styled.div`
  background-color: #f5f3f6;
  min-height: 300px;
`

export const Icon = styled.img``;
