import Axios from 'helpers/Interceptor';
import { WebsiteSocialMediaIcon } from 'types/global';
import { PublishBrandContent } from './brandContentService';

type BrandWebsiteData = {
  buttonConfig?: string,
  enableSocialIcons?: boolean,
  removeSymphonyBrand?: boolean,
  socialIcons?: WebsiteSocialMediaIcon[],
}

export const saveBrandWebsite = async (brandSlug: string, websiteData: BrandWebsiteData) => {
  try {
    const response = await Axios.post(`/website/${brandSlug}`, websiteData);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const saveWebsiteChanges = async (brandSlug: string, websiteData: PublishBrandContent) => {
  try {
    const response = await Axios.post(`/website/${brandSlug}/${websiteData.slug}`, websiteData);

    return response.data;
  } catch (error) {
    console.log('error, saveWebsiteChanges', error)
    throw error;
  }
}