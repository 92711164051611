import { useState } from 'react'
import HeadCard from "./Components/HeadCard"; 
import BudgetCard from "./Components/BudgetCard"; 
import DurationCard from "./Components/DurationCard"; 
import GoLiveCard from "./Components/GoLiveCard"; 
import { CurrentBrand, SelectedTikTokSparkAd, VideoAdsRecord } from "types/global";
import { checkIfIsProTier } from "utils";
import { Container } from "./styles"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from '@material-ui/core';

interface FinalizationProps {
    campaignId: string | null
    startDate: Date | null;
    endDate: Date | null;
    sparkCode: string | null;
    budget: number;
    brand: CurrentBrand;
    targeting: VideoAdsRecord[];    
    // targetingType: string;
    link: string;
    geographyType: string;
    selectedCountries: { label: string; value: string }[];
    selectedPosts: SelectedTikTokSparkAd[]
}

export default function Finalization({
        campaignId,
        budget,
        sparkCode,
        brand,
        endDate,
        startDate,
        targeting,
        // targetingType,
        link,
        geographyType,
        selectedCountries,
        selectedPosts
}: FinalizationProps) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))
    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    const isProUser = checkIfIsProTier(brand);

    const onCancelPayPalOrder = () => setCreatingCampaign(false);
    const onErrorPayPalOrder = () => setCreatingCampaign(false);

    return (
        <Container>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <HeadCard {...{ geographyType, link }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <BudgetCard {...{ budget, isProUser }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>    
            <GoLiveCard 
                {...{ 
                    selectedPosts,
                    isProUser,
                    campaignId,
                    startDate,
                    endDate,
                    budget,
                    brand,
                    sparkCode,
                    creatingCampaign,
                    selectedCountries,
                    targeting,
                    link,
                    geographyType,
                    onCancelPayPalOrder,
                    onErrorPayPalOrder,
                    setCreatingCampaign, 
                }} 
            />
        </Container>
    )
}