import styled from 'styled-components';

export const AdviceBanner = styled.div`
  padding: 16px;
  border-radius: 8px;
  font-family: DIN;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  background: rgba(7, 200, 6, 0.08);
`;

export const CountryPaper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #EDECF2;
`

export const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`