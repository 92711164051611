import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

export interface ContainerProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
  borderRadius?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  textAlign?: string;
  padding?: string;
  width?: string;
  height?: string;
}

interface GradientRoundedContainerProps {
  width?: string;
  height?: string;
}

interface AvatarContainerProps {
  top?: string;
}

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  padding: ${({ padding }: StyledLabelProps) => padding};
  width: ${({ width }: StyledLabelProps) => width};
  max-height: ${({ height }: StyledLabelProps) => height};
  text-transform: none; 
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  flex-grow: 1;
  margin-top: 32px;
`;

export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
`;

export const Container = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  border-radius: ${({ borderRadius }: ContainerProps) => borderRadius}; 
  width: ${({ width }: ContainerProps) => width};
`;

export const GradientRoundedContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: ${({ width }: GradientRoundedContainerProps) => width || "50px"};
  height: ${({ height }: GradientRoundedContainerProps) => height || "50px"};
  background: linear-gradient(to top right, #8e33ff 25%, #ff7e33, #ffc300 90%);
  border-radius: 50%;
`;

export const WhiteRoundedContainer = styled.div`
  position: relative;
  top: 2.5px;
  left: 2.5px;
  width: 37px;
  height: 37px;
  background-color: #ffffff;
  border-radius: 50%;
`;

export const AvatarContainer = styled.div`
  position: relative;
  left: 2.5px;
  top: ${({ top }: AvatarContainerProps) => top || "3px"};
`;

export const Icon = styled.img``;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 32,
    height: 32,
  },
}));

export default useStyles;
