import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((_theme: Theme) => ({
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
  },
}));

interface BarProps {
  width?: string | number;
  borderRadius?: number;
  background?: string;
}
export const BackgroundBarContainer = styled.div<BarProps>`
  width: ${({ width }) => width || '100%'};
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  height: 16px;
`;

export const Bar = styled.div`
  width: ${({ width }: BarProps) => width || 0}%;
  background: ${({ background }) => background};
  border-radius: inherit;
  height: 16px;
`;