import useStyles from "./styles"
import Intercom from "helpers/Intercom"
import { FunctionComponent } from "react"

export const NotFoundInstaUser: FunctionComponent = () => {
    const classes = useStyles()
    return (
        <div>
            <div className={classes.mainContainer}>
                <div className={classes.textContainer}>
                    <p className={classes.headerLabel}>
                        Make sure your Instagram is a business or creator account.</p>
                    <p className={classes.content}>
                        To track followers, Instagram requires your account to be a creator or business account. We recommend switching to optimize your growth more effectively.&nbsp;
                        <p
                            className={classes.linkLabel} 
                            onClick={() => {
                                Intercom.openInstagramBusinessAccountArticle()
                            }}>Learn how to switch to a Business Account &gt;
                        </p>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default NotFoundInstaUser