import { BackgroundTypes, StyleTypes } from "types/global";

export const HEX_BLACK_COLOR = "#000000";
export const HEX_GRAY_COLOR = "#393939";
export const HEX_PURPLE_COLOR = "#7C18F7";
export const HELP_FINDING_INFO_URL =
  "https://symphonyos.notion.site/Creating-Pre-Saves-aa859d5d9d864be19ddfcab6fdce1f80";
export  const FINISH = "Finish";
export const CONTINUE = "Continue";


export const { ARTWORK, COLOR } = BackgroundTypes;
export const { BOXES, STACKED, TRADITIONAL } = StyleTypes;
