import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  overall: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    borderBottomWidth: 1,
  },
  inputBorder: {
    borderRadius: 6,
    width: '100%',
    height: '100%',
    fontFamily: "DIN",
    textAlign: "left"
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8800FF',
      },
      '&:hover fieldset': {
        borderColor: '#8800FF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
}));

interface StyledContentListProps {
  height?: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 16px;
`;

export const OptionSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;
  width: 100%;
`;

export const TabContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;

export const Input = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 13px;
  border: 1px solid #C8C7CC;
  border-radius: 6px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 16px;
  gap: 16px;
  background: #FFFFFF;
  border-bottom: 1px solid #EDECF2;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 100%;
  cursor: pointer;
`;

export const CardSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const ProjectImage = styled.img`
  width: 56px;
  height: 56px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 1;
  flex-grow: 1;
  overflow-wrap: break-word;
  width: 50px;
`;

export const ProjectName = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export const FromName = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #707070;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export const StyledContentList = styled.div`
  height: ${({ height }: StyledContentListProps) => height || "100%"};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;