/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  Dispatch,
  SetStateAction,
  useState,
  ChangeEvent,
  useEffect,
} from "react";
import InfoSection from "components/InfoSection";
import { FunctionComponent } from "react";
import { TextField, Button, Checkbox, Switch, TextFieldProps } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles";
import { STEPS } from "pages/post-auth/MarketingPage/PreSave";
import RecordPreview from "components/shareable/RecordPreview";
import { StyleTypes, BackgroundTypes, Website } from "types/global";
import {
  HEX_BLACK_COLOR,
  HEX_PURPLE_COLOR,
  HEX_GRAY_COLOR,
  ARTWORK,
  TRADITIONAL,
  BOXES,
  STACKED,
  COLOR,
} from "../../constants";
import isEqual from "lodash/isEqual";
import {
  getToggleOptions,
  getBackgroundTypeSelectClassName,
  getRecordPreviewContainerStyles,
  checkIsValidName,
} from "../../utils";
import {
  Customize,
  PreSaveDataContext,
} from "pages/post-auth/MarketingPage/Data/PreSaveProvider";
import CloudinaryUploadWidget from "components/shareable/CloudinaryUploadWidget";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  convertHexToRGB,
  convertRGBToHex,
  getTraditionalStyleButtonBackgroundColor,
} from "components/shareable/RecordPreview/utils";
import { ErrorMessage } from "./styles";
import InputWithDebounce from "components/shareable/InputWithDebounce";


interface Props {
  draft: string | null;
  website: Website;
  brandArtwork: string | null;
  selectedTab: STEPS;
  setSelectedTab: Dispatch<SetStateAction<STEPS>>;
  contentName: string | null;
}

interface ToggleButtonProps {
  initialSelectedOption: string;
  options: {
    value: string;
    component: JSX.Element;
    disabled?: boolean;
  }[];
  handleChange?: (value: StyleTypes) => void;
}

interface ColorPickerProps {
  label: string;
  value: string;
  fullHeight?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface ToggleButtonOptionProps {
  containerClassName?: string;
  imageClassName?: string;
  alt?: string;
  src?: string;
  label: string;
  value?: string;
  selectedValue?: string;
}

interface UploadArtworkSectionProps {
  artworkUrl: string | null;
}

const getToggleButtonClassName = (args: {
  alignment: string | null;
  value: string;
  classes: Record<string, string>;
}) => {
  const { alignment, value, classes } = args;

  return isEqual(alignment, value)
    ? classes.toggleButtonSelected
    : classes.toggleButtonUnselected;
};

export const ToggleButtons: FunctionComponent<ToggleButtonProps> = ({
  initialSelectedOption,
  options,
  handleChange,
}: ToggleButtonProps) => {
  const classes = useStyles();
  const [alignment, setAlignment] = useState<string | null>(
    initialSelectedOption
  );

  const handleAlignment = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (handleChange) handleChange(newAlignment as StyleTypes);
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      classes={{ groupedHorizontal: classes.groupedHorizontal }}
      value={alignment}
      exclusive
      onChange={handleAlignment}
    >
      {options.map((props, idx) => {
        const { value, component, disabled } = props;
        const toggleClassName = getToggleButtonClassName({
          alignment,
          value,
          classes,
        });
        return (
          <ToggleButton
            {...{ disabled, value }}
            key={`${value}-${idx}`}
            classes={{
              root: toggleClassName,
              selected: classes.emptyClass,
            }}
          >
            {component}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};



export const ToggleOption: FunctionComponent<ToggleButtonOptionProps> = ({
  containerClassName,
  imageClassName,
  alt,
  src,
  label,
  value,
  selectedValue,
}: ToggleButtonOptionProps) => {
  const selected = isEqual(value, selectedValue);
  const theme = useTheme();
  const showLabel = useMediaQuery(theme.breakpoints.up(856));
  const labelClassName = `${selected ? "text-withe" : "text-black"} pt-1`;

  return (
    <div className={containerClassName}>
      <div>
        <img {...{ alt, src }} className={imageClassName} />
      </div>
      {showLabel && <div className={labelClassName}>{label}</div>}
    </div>
  );
};

export const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  label,
  value,
  fullHeight = false,
  onChange,
}: ColorPickerProps) => {
  return (
    <div
      className={`flex flex-col justify-start ${fullHeight ? "justify-between h-full" : ""
        }`}
    >
      <div>
        <p className="font-bold text-base">{label}</p>
      </div>
      <div className="flex flex-row items-center gap-1">
        <input
          {...{ onChange, value }}
          className="h-8 w-8 bg-transparent"
          type="color"
          id="bg-color"
          name="bgcolor"
          style={{ border: "0.5px solid black" }}
        />
        <input
          readOnly
          className="text-center border border-black px-1 w-20"
          type="text"
          value={value.toUpperCase()}
        />
      </div>
    </div>
  );
};

export const UploadArtworkSection: FunctionComponent<UploadArtworkSectionProps> = ({
  artworkUrl,
}: UploadArtworkSectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const FLEX_COLUMN = "flex-col";
  const FLEX_ROW = "flex-row";
  const flexPosition = useMediaQuery(theme.breakpoints.down(621))
    ? FLEX_COLUMN
    : FLEX_ROW;
  const buttonClassName = artworkUrl
    ? classes.changeButton
    : classes.uploadButton;
  const buttonLabel = artworkUrl ? "Change" : "Upload";

  return (
    <div className={`flex ${flexPosition} gap-x-4 pt-3 items-center`}>
      {artworkUrl && (
        <img
          className={classes.artworkPreview}
          alt="artwork"
          src={artworkUrl}
        />
      )}
      <Button id="upload_artwork" className={buttonClassName}>
        <span className={classes.uploadButtonText}>{buttonLabel}</span>
      </Button>
    </div>
  );
};

const setValueAsBoolean = (defaultValue: boolean, value?: boolean | null) => {
  const isBoolean = typeof value === "boolean";
  if (isBoolean) return value;
  return defaultValue;
};

const getInitialSubtitle = (args: { draft: string | null; customize: Customize; contentName: string | null; }) => {
  const { draft, customize, contentName } = args

  if (draft) return customize.subtitle || contentName || ""
  return contentName || ""
}

const CustomizeTab: FunctionComponent<Props> = ({
  draft,
  website,
  brandArtwork,
  selectedTab,
  contentName
}: Props) => {
  const { details, customize, saveCustomize } = useContext(PreSaveDataContext);
  const { musicPlatforms } = details;
  const theme = useTheme();
  const classes = useStyles();
  const [artworkUrl, setArtworkUrl] = useState(customize?.artwork || brandArtwork || "");
  const [selectedBackgroundType, setSelectedBackgroundType] = useState(
    customize?.backgroundType || website?.bgType || ARTWORK
  );
  const [primaryColorPickerValue, setPrimaryColorPickerValue] = useState(
    customize?.primaryColor || website?.primaryColor || HEX_BLACK_COLOR
  );
  useState(HEX_BLACK_COLOR);
  const [buttonColorPickerValue, setButtonColorPickerValue] = useState(
    customize?.buttonColor || website?.secondaryColor || HEX_BLACK_COLOR
  );
  const [withCustomWebPage, setWithCustomWebPage] = useState(
    setValueAsBoolean(false, customize?.sendFansToCustomWebPage)
  );
  const [customWebPageUrl, setCustomWebPageUrl] = useState(
    customize?.customWebPageUrl || ""
  );

  // first set the select style to the one set by customize
  // otherwise, set it to what the user usually defaults to based on brand styles
  // and finally, default to boxes
  function getDefaultSelectedButtonConfig(): StyleTypes {
    if (customize && customize.styleType) return customize?.styleType
    if (website && website.buttonConfig && website.buttonConfig !== 'recordPlayer') return (website?.buttonConfig as StyleTypes)
    return StyleTypes.BOXES
  }
  const [selectedStyleType, setSelectedStyleType] = useState(
    getDefaultSelectedButtonConfig()
  );

  const [releaseTitle, setReleaseTitle] = useState(customize.releaseTitle || "");
  const [backgroundColor, setBackgroundColor] = useState(
    customize?.backgroundColor || website?.bgColor || HEX_GRAY_COLOR
  );
  const [addSubtitle, setAddSubtitle] = useState<boolean>(draft ? !!customize.subtitle : false)
  const [subtitle, setSubtitle] = useState<string>(getInitialSubtitle({ contentName, customize, draft }))
  const [showTitleError, setShowTitleError] = useState(false);

  const toggleButtonOptions = getToggleOptions(selectedStyleType);

  const isBoxesStyleType = isEqual(selectedStyleType, BOXES);

  const isTraditionalStyleType = isEqual(selectedStyleType, TRADITIONAL);

  const isStackedStyleType = isEqual(selectedStyleType, STACKED);

  const isArtworkBackgroundType = isEqual(selectedBackgroundType, ARTWORK);

  const isColorBackgroundType = isEqual(selectedBackgroundType, COLOR);

  const selectBackgroundTypeClassName = getBackgroundTypeSelectClassName(
    selectedBackgroundType
  );

  const handleChangeSubtitle = (e: ChangeEvent<HTMLInputElement>) => setSubtitle(e.target.value)

  const handleChangeSwitch = (e: ChangeEvent<HTMLInputElement>) => setAddSubtitle(e.target.checked)

  const handleChangeBackgroundType = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value as BackgroundTypes;

    if (isArtworkBackgroundType) {
      setBackgroundColor(HEX_GRAY_COLOR);
    }
    setSelectedBackgroundType(value);
  };

  const handleChangePrimaryColor = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPrimaryColorPickerValue(value);
    saveCustomize({ ...customize, primaryColor: value });
  };

  const handleChangeButtonColor = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setButtonColorPickerValue(value);
    saveCustomize({
      ...customize,
      backgroundColor,
      primaryColor: primaryColorPickerValue,
      buttonColor: value,
    });
  };

  const handleChangeWithCustomWebPage = () => {
    setWithCustomWebPage((prev) => !prev);
  };

  const handleChangeStyleType = (value: StyleTypes) => {
    if (!isTraditionalStyleType) {
      setButtonColorPickerValue(customize?.buttonColor || website?.secondaryColor || HEX_BLACK_COLOR);
      saveCustomize({
        ...customize,
        backgroundColor,
        primaryColor: primaryColorPickerValue,
        buttonColor: customize?.buttonColor || website?.secondaryColor || HEX_BLACK_COLOR,
      });
    }

    setSelectedStyleType(value);
    saveCustomize({
      ...customize,
      backgroundColor,
      primaryColor: primaryColorPickerValue,
      buttonColor: buttonColorPickerValue,
      styleType: value,
    });
  };

  const handleChangeCustomWebPageUrl = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setCustomWebPageUrl(event.target.value);
  };

  const handleChangeBackgroundColor = (e: ChangeEvent<HTMLInputElement>) => {
    setBackgroundColor(e.target.value);
  };

  const handleChangeTitle = (value: string) => {
    setReleaseTitle(value);
  };

  const handleOnUploadSuccess = (file: { info: { secure_url: string } }) => {
    const artwork = file.info.secure_url;
    setArtworkUrl(artwork);
    saveCustomize({ ...customize, artwork });
  };

  const { style: containerStyle, className: containerClassName } =
    getRecordPreviewContainerStyles({
      classes,
      backgroundColor,
      backgroundType: selectedBackgroundType,
    });

  useEffect(() => {
    if (releaseTitle.length) {
      const isValid = checkIsValidName(releaseTitle)
      setShowTitleError(!isValid)
    } else {
      setShowTitleError(false)
    }
  }, [releaseTitle])

  useEffect(() => {
    saveCustomize({
      artwork: artworkUrl,
      styleType: selectedStyleType,
      backgroundType: selectedBackgroundType,
      primaryColor: primaryColorPickerValue,
      buttonColor: buttonColorPickerValue,
      sendFansToCustomWebPage: withCustomWebPage,
      customWebPageUrl,
      backgroundColor,
      releaseTitle,
      subtitle: addSubtitle ? subtitle : "",
      showSecondaryLine: addSubtitle,
    })
  }, [
    selectedBackgroundType,
    customWebPageUrl,
    selectedStyleType,
    artworkUrl,
    primaryColorPickerValue,
    buttonColorPickerValue,
    withCustomWebPage,
    customWebPageUrl,
    backgroundColor,
    releaseTitle,
    subtitle,
    addSubtitle,
    selectedTab,
  ])


  useEffect(() => {
    if (isTraditionalStyleType) {
      const { values: converted } = convertHexToRGB(primaryColorPickerValue);
      const { values: traditionalColor } =
        getTraditionalStyleButtonBackgroundColor(converted);
      const rgbToHex = convertRGBToHex(traditionalColor);
      setButtonColorPickerValue(rgbToHex);
      saveCustomize({ ...customize, buttonColor: rgbToHex });
    }
  }, [selectedStyleType, primaryColorPickerValue]);

  return (
    <div className="flex">
      <div className={classes.dataColumn}>
        <InfoSection
          title="Customize your Pre-save Page"
          description="Add in your release details to automatically setup your pre-save page."
        />
        <InfoSection
          className="py-4 px-5 w-full lg:px-10 border-b"
          title="Release Title"
          description="Enter the title of your song/album."
        >
          <InputWithDebounce
            value={releaseTitle}
            onChange={handleChangeTitle}
            error={showTitleError}
            placeholder="Title"
            styling={{ marginTop: theme.spacing(1.5) } as TextFieldProps}
          />
          {showTitleError && (
            <ErrorMessage>Please enter at least 2 characters</ErrorMessage>
          )}
          <div className="mt-2 flex items-center">
            <Switch
              checked={addSubtitle}
              onChange={handleChangeSwitch}
              color="primary"
            />
            <div>
              <h2 className="mb-0">Add a secondary line</h2>
            </div>
          </div>
          {addSubtitle && (
            <div className="mt-1">
              <p className="text-base text-gray-500">
                Enter a subtitle. This can be your artist name, a message, or
                anything else you want to add."
              </p>
              <TextField
                type="text"
                size="small"
                fullWidth
                placeholder="e.g. my new song coming soon"
                value={subtitle}
                variant="outlined"
                className={classes.textField}
                onChange={handleChangeSubtitle}
              />
            </div>
          )}
        </InfoSection>
        <InfoSection
          className="py-4 px-5 w-full lg:px-10 border-b"
          title="Artwork"
          description="Select the artwork you want to display along with your pre-save. We recommend using at least an image that’s 1000x1000."
        >
          <UploadArtworkSection {...{ artworkUrl }} />
        </InfoSection>
        <InfoSection title="Style" description="">
          <div className="mt-2">
            <ToggleButtons
              initialSelectedOption={
                getDefaultSelectedButtonConfig()
              }
              options={toggleButtonOptions}
              handleChange={handleChangeStyleType}
            />
          </div>
          <div className="flex flex-col mt-5">
            <div className="flex flex-row justify-between">
              <div className="w-1/2">
                <div className="font-bold text-base">Background</div>
                <select
                  className={`${selectBackgroundTypeClassName} p-3 h-auto w-full`}
                  value={selectedBackgroundType}
                  onChange={handleChangeBackgroundType}
                >
                  <option value={COLOR}>Color</option>
                  <option value={ARTWORK}>Artwork</option>
                </select>
              </div>
              {isColorBackgroundType && (
                <div className="w-1/2 ml-8">
                  <ColorPicker
                    fullHeight
                    label="Background Color"
                    value={backgroundColor}
                    onChange={handleChangeBackgroundColor}
                  />
                </div>
              )}
            </div>
            {isArtworkBackgroundType && (
              <span className="mt-2">
                Your artwork will be used as the background image.
              </span>
            )}
            <div className="flex flex-row gap-x-8 mt-4">
              <ColorPicker
                label="Primary Color"
                value={primaryColorPickerValue}
                onChange={handleChangePrimaryColor}
              />
              {isBoxesStyleType && (
                <ColorPicker
                  label="Button Color"
                  value={buttonColorPickerValue}
                  onChange={handleChangeButtonColor}
                />
              )}
            </div>
          </div>
        </InfoSection>
        <InfoSection title="Options" description="">
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <Checkbox
                checked={withCustomWebPage}
                onChange={handleChangeWithCustomWebPage}
                color="default"
                classes={{
                  root: classes.rootCheckbox,
                  checked: classes.checkedCheckbox,
                }}
              />
              <span>After pre-saving send fans to a custom webpage.</span>
            </div>
            {withCustomWebPage && (
              <TextField
                value={customWebPageUrl}
                onChange={handleChangeCustomWebPageUrl}
                className={classes.textField}
                variant="outlined"
                placeholder="URL"
              />
            )}
          </div>
        </InfoSection>
      </div>
      <div className={classes.previewColumn}>
        <div className={containerClassName} style={containerStyle}>
          {isEqual(selectedBackgroundType, BackgroundTypes.ARTWORK) && (
            <img
              alt="artwork_as_background"
              src={artworkUrl}
              className="filter blur-lg absolute w-auto object-cover h-full"
            />
          )}
          <RecordPreview
            className={classes.principalPreviewContainer}
            contentDetails={{
              name: customize.releaseTitle,
              options: musicPlatforms
                ? musicPlatforms.filter((o) => o.show)
                : [
                  { name: "spotify", show: true },
                  { name: "apple_music", show: true },
                  { name: "audiomack", show: true },
                  { name: "soundcloud", show: true },

                ],
              artworkUrl,
              subtitle: addSubtitle ? subtitle : "",
            }}
            backgroundType={selectedBackgroundType}
            backgroundColor={backgroundColor}
            mainColor={primaryColorPickerValue}
            secondaryColor={
              isStackedStyleType
                ? primaryColorPickerValue
                : buttonColorPickerValue
            }
            styleType={selectedStyleType}
          />
        </div>
      </div>
      <CloudinaryUploadWidget
        uploadButtonId="upload_artwork"
        resourceType="image"
        maxFiles={1}
        showUploadMoreButton={false}
        clientAllowedFormats={["jpg", "png"]}
        minImageWidth={300}
        onUploadAdded={(_fileId: string) => { }}
        onUploadSuccess={handleOnUploadSuccess}
        onUploadAbortAll={() => { }}
        onUploadAbort={(_fileId: string) => { }}
        onCancel={(_info: Record<string, unknown>) => { }}
      />
    </div>
  )
};

export default CustomizeTab;