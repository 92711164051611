import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";
import { MEDIA_BREAKPOINTS, THEME_BREAKPOINTS } from "types/global";
import { SystemColors } from "types/globalStyles";

const { mobile } = THEME_BREAKPOINTS;

const useStyles = makeStyles((theme: Theme) => ({
  rootCheckbox: {
    color: "#07C806",
  },
  checkedCheckbox: {},
  datePicker: {
    "& > div > span": {
      display: "none",
    },
  },
  focusedTimezoneSelect: {
    "& > div:first-of-type": {
      borderColor: `${SystemColors.ACCENT_COLOR} !important`,
      boxShadow: `0 0 0 1px ${SystemColors.ACCENT_COLOR} !important`,
    },
  },
  datePickerContainerWhenSelected: {
    maxWidth: "100%",
  },
  datePickerContainerWhenUnselected: {
    maxWidth: "100%",
  },
  datePickerTextFieldWhenSelectedDate: {
    borderRadius: 8,
    width: "100%",
    maxWidth: 360,
    "& input": {
      fontFamily: "DIN",
      color: SystemColors.PRIMARY_TEXT_COLOR,
      fontSize: 16,
      fontWeight: 400,
    },
    "& input::placeholder": {
      fontFamily: "DIN",
      color: SystemColors.SECONDARY_TEXT_COLOR,
      fontSize: 16,
      fontWeight: 400,
      opacity: 1,
    },
    '& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: `${SystemColors.ACCENT_COLOR} !important`,
    },
  },
  datePickerTextFieldWhenNotSelectedDate: {
    borderRadius: 8,
    width: "100%",
    "& input": {
      fontFamily: "DIN",
      color: SystemColors.PRIMARY_TEXT_COLOR,
      fontSize: 16,
      fontWeight: 400,
    },
    "& input::placeholder": {
      fontFamily: "DIN",
      color: SystemColors.SECONDARY_TEXT_COLOR,
      fontSize: 16,
      fontWeight: 400,
      opacity: 1,
    },
    '& .MuiOutlinedInput-root.Mui-focused > .MuiOutlinedInput-notchedOutline': {
      borderColor: `${SystemColors.ACCENT_COLOR} !important`,
    },
  },
  datePickerWrapper: {
    width: "100%",
  },
  inputBorder: {
    borderRadius: 8,
  },
  textField: {
    marginTop: theme.spacing(1.5),
    "& input": {
      fontFamily: "DIN",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  eventIcon: {
    width: 25,
    height: 25,
    '& path': {
      color: SystemColors.SECONDARY_TEXT_COLOR
    }
  },
  recordPreviewContainerWhenColor: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: "100%",
    position: "relative",
    overflow: "auto",

    [theme.breakpoints.down(mobile)]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "30px",
      paddingBottom: "60px"
    }
  },
  recordPreviewContainerWhenArtwork: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(mobile)]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "30px",
      overflow: "auto",
      paddingBottom: "60px"
    }
  },
  dataColumn: {
    paddingBottom: theme.spacing(0),
    maxWidth: "55%",
    [theme.breakpoints.down(961)]: {
      width: "50%",
    },
    [theme.breakpoints.down(mobile)]: {
      width: "100%",
      maxWidth: "unset",
      paddingBottom: "unset",
    },
  },
  previewColumn: {
    paddingBottom: theme.spacing(0),
    width: "45%",
    [theme.breakpoints.down(961)]: {
      width: "50%",
    },
    [theme.breakpoints.down(mobile)]: {
      display: "none",
    },
  },
  uploadButton: {
    textTransform: "none",
    backgroundColor: "#8800FF",
    width: 140,
    height: 40,
    "&:hover": {
      backgroundColor: "#8800FF",
    },
  },
  changeButton: {
    backgroundColor: "#8800FF",
    width: 140,
    height: 40,
    "&:hover": {
      backgroundColor: "#8800FF",
    },
  },
  uploadButtonText: {
    color: "#fff",
  },
  copyButtonText: {
    color: "#8800FF",
  },
  toggleButtonSelected: {
    cursor: "default",
    pointerEvents: "none",
    maxWidth: "100%",
    minWidth: 150,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    "&.MuiToggleButton-root": {
      textTransform: "none",
    },
    "&.MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
    "&.MuiToggleButton-root.Mui-selected:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
    [theme.breakpoints.down(1376)]: {
      minWidth: 120,
    },
    [theme.breakpoints.down(1300)]: {
      minWidth: 110,
    },
    [theme.breakpoints.down(1112)]: {
      minWidth: 100,
    },
    [theme.breakpoints.down(967)]: {
      minWidth: 80,
    },
    [theme.breakpoints.down(937)]: {
      minWidth: "auto",
    },
  },
  toggleButtonUnselected: {
    maxWidth: "100%",
    minWidth: 150,
    backgroundColor: "#ffffff",
    color: "#8800FF",
    "&.MuiToggleButton-root": {
      textTransform: "none",
      backgroundColor: "#ffffff",
      color: "#8800FF",
    },
    [theme.breakpoints.down(1376)]: {
      minWidth: 120,
    },
    [theme.breakpoints.down(1300)]: {
      minWidth: 110,
    },
    [theme.breakpoints.down(1112)]: {
      minWidth: 100,
    },
    [theme.breakpoints.down(967)]: {
      minWidth: 80,
    },
    [theme.breakpoints.down(937)]: {
      minWidth: "auto",
    },
  },
  groupedHorizontal: {
    border: "1px solid #000000",
    borderRight: "1px solid #000000",
    borderLeft: "1px solid #000000",
    "&:not(:first-child)": {
      border: "1px solid #000000",
      borderRight: "1px solid #000000",
      borderLeft: "1px solid #000000",
    },
    "&:first-child": {
      border: "1px solid #000000",
      borderRight: "1px solid #000000",
      borderLeft: "1px solid #000000",
    },
  },
  emptyClass: {},
  successLabelContainer: {
    paddingLeft: theme.spacing(4),
  },
  successLabelPrincipalContainer: {
    display: "flex",
    flex: "auto",
    flexDirection: "row",
    backgroundColor: "#AEEDAE",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    borderRadius: 10,
  },
  iconContainer: {
    paddingTop: theme.spacing(1),
  },
  roundContainer: {
    width: 72,
    height: 72,
    backgroundColor: "#ffffff",
    display: "inline-block",
    borderRadius: "50%",
  },
  artworkPreview: {
    width: 75,
  },
  copyInput: {
    width: "100%",
    minWidth: 300,
    fontWeight: 600,
    paddingLeft: 16,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  principalPreviewContainer: {
    width: "100%",
    paddingBottom: theme.spacing(26),
    paddingTop: theme.spacing(3),
    zIndex: 10,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down(1701)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  formHelperTextPropsRoot: {
    marginLeft: 0,
  },
  formErrorHelperText: {
    fontSize: 16,
    fontFamily: "DIN",
    [theme.breakpoints.down(1024)]: {
      fontSize: 14,
    },
  },
  reviewTabInfoBanner: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down(1254)]: {
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.down(1024)]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5)
    }
  },
  previewPresaveButton: {
    display: "none",
    margin: "auto",
    background: 'white',
    padding: "10px 0px",
    width: "100%",
    border: "none",
    color: "#80f",
    fontWeight: 500,
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.up(mobile)]: {
      display: 'none'
    }
  },
  shownPreviewPresaveButton: {
    display: "block",
    [theme.breakpoints.up(mobile)]: {
      display: 'none'
    }
  },
  previewPresaveWebsiteModal: {
    display: "none",
    [theme.breakpoints.down(mobile)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  previewPresaveWebsiteContainer: {
    display: "none",
    width: "90%",
    height: "auto",

    top: 0,
    margin: "auto",
    [theme.breakpoints.down(mobile)]: {
      display: 'block'
    }
  },
  previewPresaveBackground: {
    filter: "blur(16px)",
    "height": "150%",
    "width": "150%",
    "top": "50%",
    left: "50%",
    transform: "scale(1) translate(-50%, -50%)",
    position: "absolute",
    "maxWidth": "unset",
    objectFit: "cover"
  },
  autoPresaveSwitch: {
    marginTop: 14,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#E5E7EB',
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#8800FF',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#8800FF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      borderRadius: 34,
    },
    '& .MuiSwitch-track': {
      marginTop: 2,
      marginLeft: 1,
      borderRadius: 34,
      width: 42,
      height: 24,
      backgroundColor: '#E5E7EB',
      opacity: 1,
    },
  },
}));

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  width: 600px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
  height: 250px;
`

export default useStyles;



export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  paddingTop?: string;
  alignText?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
}

interface CardProps {
  borderRadius?: string
}

export const Card = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  background-color: #ffffff;
  border-radius: ${({ borderRadius }: CardProps) => borderRadius};
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
  @media (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  };
  ${MEDIA_BREAKPOINTS.mobileView} {
    border-radius: 0px;
  };
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-top: ${({ paddingTop }: StyledLabelProps) => paddingTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  align-text: ${({ alignText }: StyledLabelProps) => alignText};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
`;

export const Container = styled.div`
  background-color: #EDECF2;
  min-height: 300px;
`

export const Icon = styled.img``;

