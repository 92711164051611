/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, FunctionComponent, Dispatch, SetStateAction, useContext } from "react";
import InfoSection from "components/InfoSection"
import { queryFB } from "helpers/FB";
import TargetRow, { LabelButton } from "./Components/TargetRow";
import { ADD_TARGET, EDIT_TARGET, REMOVE_TARGET, SAVE_TARGET_DATA, SET_LOADING_LOCATIONS, SET_LOCATIONS_RESULT, UPDATE_SEARCH_TEXT, RESET_DEFAULTS, geographyTargetingDefaults, lowBudgetCampaignDisclaimerCopy, lowBudgetAudienceCopy, defaultAudienceCopy } from "./data";
import reducer from "./reducer";
import { MarketingDataContext } from "../../Data/Provider";
import { FbSuggestion, GeographicTargetingType, LocationsResponse } from "types/global";
import { Option as SpotifyArtistsOption } from "./Components/AutoCompleteSpotifyArtists/utils";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { getIfTargetingSaveButtonIsDisabled } from "../utils";
import BudgetNotFulfilled from "components/shareable/BudgetNotFulfilled";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { ContinueButtonProps } from "../../Components/TargetingView/utils";
import { CAMPAIGN_TYPES, CAMPAIGN_TYPE_ENUM } from "../../../../../constants";

interface GeographicTargetingProps {
    audiences?: FbSuggestion[]
    artists?: SpotifyArtistsOption[]
    showTrashIcon?: boolean
    isResponsive?: boolean;
    deleteIconColor?: string;
    showSaveButton?: boolean;
    handleIsEditing?: (isEditing: boolean) => void;
    handleTargets?: (targets: GeographicTargetingType[]) => void;
    handleAudiences?: (audiences: FbSuggestion[]) => void;
    handleArtists?: (artists: SpotifyArtistsOption[]) => void;
    handleTotalBudget?: (budget: number) => void;
    setShowTargetingView: Dispatch<SetStateAction<boolean>>;
    isBelowRecommendedBudget?: boolean;
    campaignType: CAMPAIGN_TYPE_ENUM;
    minimumRecommendedBudgetText?: string | undefined;
}

const ContinueButton: FunctionComponent<ContinueButtonProps> = (
    props: ContinueButtonProps
) => (
    <div className="text-center px-4 lg:px-10 py-4">
        <button {...props}>Save</button>
    </div>
);

export const getTooltipLabel = (id?: string) => {
    if (id === 'touring-markets') return "This audience automatically targets fans in countries where you’re likely to tour, like the US, Canada, and key European markets."
    if (id === 'global-markets') return "This audience automatically targets fans in countries where you can more easily influence the Spotify algorithm, to get cheaper streams."
    return undefined
}


const GeographicTargeting = ({
    showSaveButton = true,
    audiences,
    artists,
    showTrashIcon,
    isResponsive,
    deleteIconColor,
    handleIsEditing,
    handleTargets,
    handleAudiences,
    handleArtists,
    handleTotalBudget,
    setShowTargetingView,
    isBelowRecommendedBudget,
    campaignType,
    minimumRecommendedBudgetText
}: GeographicTargetingProps) => {
    const { isMobile } = useScreenQueryDimensions();
    const { currentBrand } = useContext(CurrentBrandContext);

    const defaultState = {
        targets:  geographyTargetingDefaults(campaignType),
        selectedItem: -1,
        searchText: '',
        searchResults: [],
        isFetchingLocations: false
    }

    const { saveTargets, saveAudiences, saveArtists, targets: currentTargets } = useContext(MarketingDataContext)

    const initialState = currentTargets.length ? { ...defaultState, targets: currentTargets } : defaultState
    const [state, dispatch] = useReducer(reducer, initialState);
    const { searchText, targets, selectedItem, searchResults, isFetchingLocations } = state
    const totalBudget = Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2))
    const isContinueButtonDisabled = getIfTargetingSaveButtonIsDisabled(targets, totalBudget)


    const isEditing = selectedItem > -1
    // handlers
    const handleClickContinue = () => {
        saveTargets(targets)
        saveAudiences(audiences || [])
        saveArtists(artists || [])
        setShowTargetingView(false);
    }

    useEffect(() => {
        if (handleIsEditing) handleIsEditing(isEditing)
        if (handleTargets) handleTargets(targets)
        if (handleAudiences) handleAudiences(audiences || [])
        if (handleArtists) handleArtists(artists || [])
        if (handleTotalBudget) handleTotalBudget(totalBudget)
    }, [targets, audiences, artists, totalBudget, isEditing])

    useEffect(() => {
        if (searchText) {
            dispatch({ type: SET_LOADING_LOCATIONS, isFetchingLocations: true })
            const access_token = currentBrand?.connections?.logged_in_fb_user?.access_token;
            const url = `/search?limit=10&type=adgeolocation&location_types=["country","region","city"]&q=${searchText}`
            queryFB(url, { method: 'GET', params: { access_token } })
                .then(({ data }: LocationsResponse) => {
                    dispatch({ type: SET_LOCATIONS_RESULT, searchResults: data })
                }).catch(() => {
                    dispatch({ type: SET_LOADING_LOCATIONS, isFetchingLocations: false })
                })
        } else {
            dispatch({ type: SET_LOCATIONS_RESULT, searchResults: [] })
        }
    }, [searchText])

    const handleOnClickEdit = (index: number) => dispatch({ type: EDIT_TARGET, index })
    const handleOnClickSave = (item: number, target: GeographicTargetingType) => dispatch({ type: SAVE_TARGET_DATA, item, target })
    const handleOnChangeSearchText = (text: string) => dispatch({ type: UPDATE_SEARCH_TEXT, searchText: text })
    const handleOnResetDefaults = () => dispatch({ type: RESET_DEFAULTS })

    const handleOnClickRemove = (index: number) => {
        dispatch({ type: REMOVE_TARGET, index })
    }

    const handleOnAddTarget = () => {
        const id = state.targets.length
        const target = {
            id: `global-markets-${id}`,
            description: "",
            name: `Geographic Targeting ${id}`,
            budget: 0,
            locations: {}
        }
        dispatch({ type: ADD_TARGET, target })
    }


    /**
     * Renders the "Add More Locations" button if the budget is not below recommended.
     * @returns JSX.Element | null
     */
    function renderAddMoreButton() {
        if (!isBelowRecommendedBudget) {
            return (
                <tr>
                    <td colSpan={4} className="border border-backgound-100 px-2 py-4 text-center">
                        <LabelButton color="#8800FF" label="Add More Locations +" onClick={handleOnAddTarget} />
                    </td>
                </tr>
            )
        }

        return null
    }


    function renderTableHeadings() {
        if (isBelowRecommendedBudget) {
            return (
                <thead className="items-start">
                    <tr className="text-left">
                        <th className={`w-2/12 pl-2`}>Name</th>
                        <th className="w-5/12 pl-2">Locations</th>
                        <th className={`${isEditing ? `w-2/12` : `w-3/12`} pl-2`}>% of Budget</th>
                        <th className={`${isEditing ? `w-3/12` : `w-2/12`} pl-2`}></th>
                    </tr>
                </thead>
            )
        } else {
            if (!isResponsive) {
                return (
                    <thead className="items-start">
                        <tr className="text-left">
                            <th className={`w-3/12 pl-2`}>Name</th>
                            <th className="w-4/12 pl-2">Locations</th>
                            <th className="w-2/12 pl-2">% of Budget</th>
                            <th className={`${isEditing ? `w-2/12` : `w-2/12`} pl-2`}></th>
                        </tr>
                    </thead>
                )
            } else {
                return (
                    <thead className="items-start">
                        <tr className="text-left">
                            <th className={`w-2/12 pl-2`}>Name</th>
                            <th className="w-5/12 pl-2">Locations</th>
                            <th className="w-2/12 pl-2">% of Budget</th>
                            <th className={`w-3/12 pl-2`}></th>
                        </tr>
                    </thead>
                )
            }
        }

    }



    return (
        <>
            <InfoSection title="Geographic Targeting" description="Refine the cities, states, and countries you want to target with your marketing." />
            <div className="px-5 lg:px-10 text-center p-4 lg:p-4 border-b border-backgound-100">
                {isBelowRecommendedBudget ? lowBudgetAudienceCopy(campaignType) : defaultAudienceCopy(campaignType)}
                {isBelowRecommendedBudget ? ` ${lowBudgetCampaignDisclaimerCopy(minimumRecommendedBudgetText)}` : null}
            </div>
            <div className="px-5 lg:px-10 pt-5 font-bold">Your ads will target fans in these locations:</div>
            {!targets.length && <div className="px-5 lg:px-10 p-4">
                <table className="table-fixed w-full">
                    <thead className="items-start">
                        <tr className="text-left">
                            <th className="w-full text-center bg-yellow-500 text-white py-3 font-normal">
                                Please add at least one location to target in your ads.
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-backgound-100 px-2 py-4 text-center">
                                <LabelButton color="#8800FF" label="Reset to defaults" onClick={handleOnResetDefaults} />
                            </td>
                        </tr>
                        {renderAddMoreButton()}
                    </tbody>
                </table>
            </div>}
            {Boolean(targets.length) && <div className="px-5 lg:px-10 p-4">
                <table className="table-fixed w-full">
                    {renderTableHeadings()}
                    <tbody>
                        {targets.map(({ name, budget, locations, id }, index) => (
                            <TargetRow
                                {...{
                                    showTrashIcon,
                                    isResponsive,
                                    deleteIconColor
                                }}
                                isBelowRecommendedBudget={isBelowRecommendedBudget ? isBelowRecommendedBudget : false}
                                id={index}
                                key={`${name}-${index}`}
                                currentName={name}
                                currentBudget={budget}
                                currentLocations={locations}
                                loading={isFetchingLocations}
                                onClickEdit={handleOnClickEdit}
                                onClickSave={handleOnClickSave}
                                {...{ searchResults, searchText }}
                                isEditing={index === selectedItem}
                                onClickRemove={handleOnClickRemove}
                                onChangeSearchText={handleOnChangeSearchText}
                                editMode
                                tooltipLabel={getTooltipLabel(id)}
                            />
                        ))}
                        {
                            targets.length < 3 && !isEditing && renderAddMoreButton()
                        }
                    </tbody>
                </table>
            </div>}
            {totalBudget !== 1 && Boolean(targets.length) && <div className={`${isMobile ? 'px-4' : 'px-10'} pt-2 pb-4`}>
                <BudgetNotFulfilled totalBudget={totalBudget} />
            </div>}
            {
                showSaveButton && !isMobile ?
                    <ContinueButton
                        disabled={isContinueButtonDisabled}
                        className="btn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                        type="button"
                        onClick={handleClickContinue}
                    /> : null
            }
        </>
    )
}

export default GeographicTargeting