import React from 'react'
import { IconHolder, SocialIconProps } from './styles'
import { ReactComponent as BandCampIcon } from 'assets/images/streaming/icons/bandcamp.svg';

export const Bandcamp: React.FC<SocialIconProps> = ({
  rounded,
  borderColor,
  backgroundColor,
  iconColor,
  width = '40',
  height = '40',
  cursor,
  onClick,
}) => {
  return (
    <IconHolder
      rounded={rounded}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      cursor={cursor}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <BandCampIcon width={width} height={height}/>
    </IconHolder>
  )
}
