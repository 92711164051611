import { makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  gap?: number;
} | undefined;

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    '& .MuiButtonBase-root': {
      width: 'fit-content',
    },
    '& .Mui-selected': {
      color: 'initial !important',
    },
    '& .MuiTabs-flexContainer': {
      gap: (props: Props) => props?.gap || '0px',
    },
  },
}));

export default useStyles;
