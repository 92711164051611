import DialogPaper from "components/shareable/DialogPaper";
import { ReactComponent as TadaIcon } from "assets/images/tada.svg"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { ModalContainer, BodyContainer } from "./styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import LinkContainer from "components/shareable/LinkContainer";
import { SystemColors } from "types/globalStyles";
import MainConfetti from "components/shareable/Confetti";

interface Props {
  open: boolean
  websiteUrl: string
  onClose: () => void
}

const PublishedWebsiteModal = ({
  open,
  websiteUrl,
  onClose,
}: Props) => {

  const handleGoToDashboard = () => {
    onClose()
  }

  return (
    <DialogPaper open={open}>
      <ModalContainer>
        <HeaderModal
          closeModal={onClose}
          title="Your website has been published!"
          subtitle="You can come back and view stats in realtime from the Dashboard, and edit styling at any time. Here’s your website link, share it with the world:"
          HeaderIcon={<TadaIcon />}
        />
        <BodyContainer>
          <LinkContainer
            url={websiteUrl}
            showShareButton
            showEditButton={false}
            width="100%"
            padding="8px 16px"
            buttonTextColor={SystemColors.ACCENT_COLOR_ON_DARK}
            backgroundColor={SystemColors.DARK_DIVIDER_LINE}
            textColor={SystemColors.PRIMARY_TEXT_COLOR}
          />
          <PrimaryButton text="Explore the Dashboard" onClick={handleGoToDashboard} />
        </BodyContainer>
        <MainConfetti
          numberOfPieces={600}
        />
      </ModalContainer>
    </DialogPaper>
  );
}

export default PublishedWebsiteModal