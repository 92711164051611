import { FunctionComponent, useEffect, useState } from "react"
import { Chip } from "@material-ui/core"
import { SystemColors } from "types/globalStyles"
import { Body2, Subtitle2, Caption } from "components/shareable/Typography"
import clsx from "clsx"
import useStyles, { AnnuallyContainer, BillingRadio } from "./styles"
import { MONTHLY_PRO_PLAN_PRICE, YEARLY_BY_MONTH_PRO_PLAN_PRICE } from "../../../../../constants"
import { BillingCycle } from "../../utils"
import { CurrencyCodesType } from "types/global"
import { getConvertedCurrency } from "services/symphonyApi"
import getSymbolFromCurrency from "currency-symbol-map"
import { DEFAULT_CURRENCY } from "pages/post-auth/MarketingPage/constants"
import { getNumberWithDecimals } from "helpers/General"
import { Container } from "styles/shared"

interface BillingChipProps {
  isActive: boolean
  onClick: () => void
  cycle: BillingCycle
  isMobileView?: boolean
  discountPercentage?: number,
  currency: CurrencyCodesType
}

export const BillingChip: FunctionComponent<BillingChipProps> = ({
  isActive,
  onClick,
  cycle,
  isMobileView,
  discountPercentage = 1,
  currency,
}: BillingChipProps) => {
  const classes = useStyles({ isMobileView })
  const [annualPrice, setAnnualPrice] = useState(YEARLY_BY_MONTH_PRO_PLAN_PRICE)
  const [monthlyPrice, setMonthlyPrice] = useState(MONTHLY_PRO_PLAN_PRICE)

  const color = isActive
    ? SystemColors.ACCENT_COLOR
    : SystemColors.PRIMARY_TEXT_COLOR

  // final prices that are shown
  let finalAnnualPrice = annualPrice * discountPercentage
  let finalMonthlyPrice = monthlyPrice * discountPercentage
  let formattedAnnual = getNumberWithDecimals(finalAnnualPrice, 2)
  let formattedMonthly = getNumberWithDecimals(finalMonthlyPrice, 2)

  // compared prices that are shown
  let showComparsionPrice = discountPercentage !== 1
  let defaultAnnualPrice = annualPrice
  let defaultMonthlyPrice = monthlyPrice
  let formattedDefaultAnnual = getNumberWithDecimals(defaultAnnualPrice, 2)
  let formattedDefaultMonthly = getNumberWithDecimals(defaultMonthlyPrice, 2)

  useEffect(() => {
    if (currency !== DEFAULT_CURRENCY) {
      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currency, amount: YEARLY_BY_MONTH_PRO_PLAN_PRICE })
        .then((res) => setAnnualPrice(res))

      getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currency, amount: MONTHLY_PRO_PLAN_PRICE })
        .then((res) => setMonthlyPrice(res))
    }
  }, [currency])

  const AnnuallyLabel = (
    <AnnuallyContainer>
      <Body2 color={color}>Yearly</Body2>

      <Subtitle2 color={color}>
        {`${getSymbolFromCurrency(currency)}${formattedAnnual}`}
        <Caption color={SystemColors.SECONDARY_TEXT_COLOR}> /mo</Caption>
      </Subtitle2>
    </AnnuallyContainer>
  )

  const MonthlyLabel = (
    <AnnuallyContainer>
      <Body2 color={color}>Monthly</Body2>
      {showComparsionPrice ? (
        <Container display="flex" flexDirection="row" gap="4px">
          <Subtitle2 color={color}>
            {`${getSymbolFromCurrency(currency)}${formattedMonthly}`}
          </Subtitle2>
          <Subtitle2 color={color} style={{
            textDecoration: 'line-through',
            color: '#989898',
            fontSize: '15px'
          }}>
            {`${getSymbolFromCurrency(currency)}${formattedDefaultMonthly}`}
          </Subtitle2>
        </Container>
      ) : (
        <Subtitle2 color={color}>
          {`${getSymbolFromCurrency(currency)}${formattedMonthly}`}
        </Subtitle2>
      )}

    </AnnuallyContainer>
  )

  return (
    <Chip
      className={clsx(
        classes.billingChip,
        isActive && classes.billingChipActive
      )}
      icon={<BillingRadio style={{ color }} checked={isActive} />}
      label={cycle === BillingCycle.ANNUALLY ? AnnuallyLabel : MonthlyLabel}
      onClick={onClick}
    />
  )
}
