import { getConvertedCurrency } from "services/symphonyApi";
import { CurrencyCodesType } from "types/global";

export enum STEPS {
  RECORD = "record",
  GOAL = "goal",
  FINALIZATION = "review",
}

export const convertArrayDraftDataToSendAsObject = (
  data: Record<string, unknown>[]
) => {
  const [recordSelected, budgetValue, selectedTab, pitchNotes] = data

  return {
    recordSelected,
    budgetValue,
    selectedTab,
    pitchNotes
  }
}

const convertCostToCurrency = async ({ cost, currency }:{ cost: number, currency?: CurrencyCodesType }) => {
  if (!currency || currency === "USD") return cost
  const amountConverted = await getConvertedCurrency({ from: "USD", to: currency, amount: cost })
  if (amountConverted === 0) return cost
  return amountConverted
}

export type CostPlaylistType = (currency?: CurrencyCodesType) => Promise<number>;
export type PlaylistTier = {
  tier: number;
  cost: CostPlaylistType;
  proDiscount: number;
  bgImage: string;
}

export const availableTiers: PlaylistTier[] = [
  {
    tier: 30000,
    proDiscount: 0.15,
    bgImage: 'assets/images/playlisting/30k.png',
    cost: async (currency?: CurrencyCodesType) => {
      return await convertCostToCurrency({ cost: 250, currency });
    },
  },
  {
    tier: 100000,
    proDiscount: 0.16666,
    bgImage: 'assets/images/playlisting/100k.png',
    cost: async (currency?: CurrencyCodesType) => {
      return await convertCostToCurrency({ cost: 600, currency });
    },
  },
  {
    tier: 300000,
    proDiscount: 0.15,
    bgImage: 'assets/images/playlisting/300k.png',
    cost: async (currency?: CurrencyCodesType) => {
      return await convertCostToCurrency({ cost: 1500, currency });
    },
  },
  {
    tier: 600000,
    proDiscount: 0.15,
    bgImage: 'assets/images/playlisting/600k.png',
    cost: async (currency?: CurrencyCodesType) => {
      return await convertCostToCurrency({ cost: 3000, currency });
    },
  },
  {
    tier: 1000000,
    proDiscount: 0.15,
    bgImage: 'assets/images/playlisting/1mil.png',
    cost: async (currency?: CurrencyCodesType) => {
      return await convertCostToCurrency({ cost: 6000, currency });
    },
  }
]


export const playlistingRejectionIdToText = (id:string) => {
  switch (id) {
    case "Bad Song Fit":
      return "Our staff found problems with this song that would make it difficult to pitch effectively.";
    case "Cover/Remix":
      return "We aren’t servicing covers/remixes at this time.";
    case "Non-English Song":
      return "We’re only servicing music in English at this time.";
    case "Not a Serviceable Genre":
      return "We aren’t servicing this genre/niche at this time.";
    case "Not Enough Playlists (Try Again in the Future)":
      return "There weren’t enough playlists in our network that would align with your genre and/or specifications. Try again in the future or broaden your guidelines!";
  }
  return null
}