import axios from "axios"
import Axios from "helpers/Interceptor"
import { CurrentBrand } from "types/global"
import { showToast } from "utils"
import Logger from "Logger"
import * as Sentry from "@sentry/browser"
import { queryFB } from "helpers/FB"

export const saveConversionsToken = async (data: {
  token: string
  currentBrand?: CurrentBrand
  isMobileView?: boolean
  onLoading: (isSaving: boolean) => void
  onError: (isError: boolean) => void
  reload: () => void
}) => {
  const { token, currentBrand, isMobileView, onLoading, onError, reload } = data
  if (!token || !token.trim().length || !currentBrand) return

  try {
    onLoading(true)

    await Axios.put(`/brand/${currentBrand.slug}/connect`, {
      id: currentBrand.id,
      name: currentBrand.name,
      service: "facebook_pixel_conversions_api_access_token",
      value: token,
    })

    showToast({ message: "Saved access token!", mobile: isMobileView })
    onLoading(false)
    reload()
  } catch (e) {
    Logger.error(e)
    showToast({
      message: "Error saving access token!",
      error: true,
      mobile: isMobileView,
    })
    onLoading(false)
    onError(true)
  }
}

export const resetAccessToken = async (data: {
  currentBrand?: CurrentBrand
  reload: () => void
}) => {
  try {
    const { currentBrand, reload } = data
    if (!currentBrand) return
    await Axios.put(`/brand/${currentBrand.slug}/connect`, {
      id: currentBrand.id,
      name: currentBrand.name,
      service: "facebook_pixel_conversions_api_access_token",
      value: null,
    })
    reload()
  } catch (e) {
    Logger.error(e)
  }
}

export const getAdAccountBusiness = async (data: {
  currentBrand?: CurrentBrand
  reload: () => void
  accessToken?: string
}) => {
  try {
    const { currentBrand, reload, accessToken } = data
    const { facebook_ad_account } = currentBrand?.connections || {}

    if (!currentBrand || !facebook_ad_account?.id || !accessToken) return

    const fields = "business{id,name}"
    const results = await queryFB(facebook_ad_account.id, {
      params: {
        fields,
        access_token: accessToken,
      },
    })

    if (!results?.business) return

    facebook_ad_account.business = results.business
    await Axios.put(`/brand/${currentBrand.slug}/connect`, {
      id: currentBrand.id,
      name: currentBrand.name,
      service: "facebook_ad_account",
      value: facebook_ad_account,
    })

    reload()
  } catch (e) {
    Logger.error(e)
  }
}

export const validateConversionsToken = async (data: {
  fbPixelId?: string | null
  conversionsToken: string
}) => {
  const { conversionsToken, fbPixelId } = data

  const validations = [
    conversionsToken.length <= 3,
    //!conversionsToken?.substring(0, 3).includes("EAA"),
  ]

  if (validations.some((v) => v)) return { verified: false }

  try {
    const fbApiUrl = process.env.REACT_APP_FB_API_URL
    const url = `${fbApiUrl}/${fbPixelId}/events?access_token=${conversionsToken}`

    await axios({
      method: "POST",
      url,
      data: {
        data: [
          {
            event_name: "SymphonyVerification",
            event_time: Math.floor(new Date().getTime() / 1000),
            user_data: {
              client_ip_address: "1.2.3.4",
              client_user_agent: "test ua2",
            },
            custom_data: {},
          },
        ],
        test_event_code: "TEST123",
      },
    })

    return { verified: true }
  } catch (e) {
    Logger.error(e)
    return { verified: false }
  }
}

export const finishConversionsSetup = async () => {
  try {
    const response = await Axios.put(`/brand/conversions/finished`)
    const { data } = response
    if (!data || !Object.keys(data)?.length) {
      throw new Error('Error finishing conversions setup')
    }
    return data
  } catch (e) {
    Logger.error(e)
    return { error: true }
  }
}


export const getLinkedPixels = async (data: {
  fbAdAccount?: { id: string } | null
  onLoading: (loading: boolean) => void
  onError: (error: boolean) => void
  accessToken?: string
}) => {
  const { fbAdAccount, onLoading, onError, accessToken } = data
  try {
    Sentry.setTag("flow", "checkIfPixelLinked")
    Sentry.setContext("input", { fbAdAccount })

    const url = `${fbAdAccount?.id}/adspixels`
    const fields = "name,id"
    
    const results = await queryFB(url, {
      params: {
        fields,
        access_token: accessToken,
      },
    })
    if (!results.data) return []

    onLoading(false)
    return results.data
  } catch (error) {
    onError(true)
    onLoading(false)
    Sentry.captureException(error)
    return []
  }
}