import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  oneLineText: {
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '80%',
  },
}));

interface CardProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  height?: string;
  gap?: string;
}

interface CardHeadersProps {
  maxWidth: string;
}

interface CardStatsContainerProps {
  maxWidth: string;
  gap?: string;
  width?:string;
  justifyContent?: string;
}

interface DetailContainerProps {
  flexDirection?: string;
  width?: string;
}

interface CardSingleStatContainerProps {
  width?: string;
}

interface TimeRemaimingContainer {
  isNotFound?: boolean;
}

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: ${({ flexDirection }: CardProps) => flexDirection};
  gap: 8px;
  align-items: center;
`;

export const CardContainer = styled.div`
  border: 1px solid #e5f1eb;
  border-radius: 8px;
  display: flex;
  flex-direction: ${({ flexDirection }: CardProps) => flexDirection};
  height: ${({ height }: CardProps) => height || 'fit-content'};
  align-items: ${({ alignItems }: CardProps) => alignItems};
  justify-content: ${({ justifyContent }: CardProps) => justifyContent};
  padding: 16px 24px;
  gap: ${({ gap }: CardProps) => gap};
  align-self: stretch;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 12px;
  background-color: #8800ff14;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
`;

export const CardTitle = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  color: #000000;
  width: auto;
  text-overflow: ellipsis;
`;

export const ValueText = styled.p`
  font-family: DIN;
  font-size: 20px;
  line-height: 32px;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
`;

export const CardTitleContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  vertical-align: middle;
`;

export const CardStatHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  max-width: ${({ maxWidth }: CardHeadersProps) => maxWidth};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 80%;
`;

export const CardHeaderLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const CardStatsContainer = styled.div<CardStatsContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px; 
  width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }: CardStatsContainerProps) => gap || '24px'};
  max-width: ${({ maxWidth }) => maxWidth};
  flex: 1 0 0;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};;
`;

export const CardSingleStatContainer = styled.div<CardSingleStatContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: ${({ width }: CardSingleStatContainerProps) => width || '90px'};
`;

export const SingleStatTitle = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #707070;
`;

export const StatValueLabel = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const DetailContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: ${({ flexDirection }: DetailContainerProps) => flexDirection};
  gap: 16px;
  width: ${({ width }: DetailContainerProps) => width};
`;

export const TimeRemaimingContainer = styled.div<TimeRemaimingContainer>`
  display: flex;
  gap: 16px;
  padding: 16px;
  background: ${({ isNotFound }) => isNotFound ? SystemColors.DARK_WARNING : 'linear-gradient(90.1deg, #8800ff14 19.65%, #8800ff3d 93.37%)'};
  border-radius: 8px;
`;

export const OptionIcon = styled.img`
  height: 18px;
  width: 18px;
`

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 27px;
  background-color: #8800FF14;
  cursor: pointer;
`;

export const WhiteContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 32px;
  border-radius: 8px;
  background-color: ${SystemColors.PAPER_CONTAINER};
  width: 100%;
  flex-wrap: wrap;
`;