import { InputAdornment, TextField } from "@material-ui/core";
import { ArtistInputContainer, BackArrowImage, BackButtonContainer, BackButtonText, BackToDashboardContainer, EnterArtistContainer, EnterArtistLabel, FlexContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, PlatformLogoContainer, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import HappyFace from './images/high-bar.svg';
import NeutralFace from './images/medium-bar.svg';
import SadFace from './images/none-bar.svg';

import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OnboardingAccountTypes } from "../..";
import MarketingSelectorVertical, { MarketingSelectorOption } from "./MarketingSelectorVertical";
import { useState } from "react";
import PrimaryButton from "components/shareable/PrimaryButton";


export default function MarketingExperience(props: {
    responsiveView: boolean,
    showBackButton: boolean,
    handleGoBack: () => void,

    handleSelected: (option: MarketingSelectorOption) => void,
}) {

    const [selectedItem, setSelectedItem] = useState<MarketingSelectorOption | null>(null);

    const {
        responsiveView,
        showBackButton,
        handleGoBack,

        handleSelected
    } = props;

    const options: MarketingSelectorOption[] = [
        {
            image: SadFace,
            title: "None",
            description: "I've never run ads before or have no clue how they work.",
        },
        {
            image: NeutralFace,
            title: "Medium",
            description: "I have an idea of how to read ads data, or I've hired an agency or someone to run ads for me.",
        },
        {
            image: HappyFace,
            title: "High",
            description: "I know how to run ads through platforms like Facebook + TikTok Ads Manager.",
        },

    ]

    return (
        <>
            <LeftColumn width={responsiveView ? "100%" : "50%"} isMobile={responsiveView}>
                {showBackButton && responsiveView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={responsiveView ? "8px" : "18px"}>
                    {showBackButton && !responsiveView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>What's your experience with marketing?</MainTitle>
                    {/* <SubTitle>Whether you're a beginner or an expert, your answer will guide us in tailoring our marketing strategies to your level of experience.</SubTitle> */}
                </Titles>
                <EnterArtistContainer>

                    <MarketingSelectorVertical
                        options={options}
                        mobileView={responsiveView}
                        selected={selectedItem}
                        onSelected={(option: MarketingSelectorOption) => {
                            setSelectedItem(option)
                            // handleSelected(option);
                        }} />

                </EnterArtistContainer>
                {/* <Container width="100%" display="flex" alignItems="center" justifyContent="center"> */}
                <PrimaryButton
                    text="Continue"
                    width={responsiveView ? "100%" : "300px"}
                    disabled={!selectedItem}
                    onClick={() => {
                        handleSelected(selectedItem!)
                    }} />
                {/* </Container> */}

            </LeftColumn>
            <RightColumn display={responsiveView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}