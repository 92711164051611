import styled from 'styled-components';

interface EmptyFanbaseContainerProps {
    gap?: string;
    width?: string;
}

export const EmptyFanbaseContainer = styled.div`
  display: flex;
  margin: 16px;
  gap: ${({ gap }: EmptyFanbaseContainerProps) => gap || "8px"};
  width: ${({ width }: EmptyFanbaseContainerProps) => width || "auto"};
  align-items: center;
  flex-direction: column;
`;

export const EmptyFanbaseHeader = styled.label`
  font-family: DIN;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
`;

export const EmptyFanbaseLabel = styled.label`
  font-family: DIN;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  text-align: center;
`;
