/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from '@material-ui/core'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import {
  ModalContainer,
  LoadingContainer,
  ErrorLabelContainer,
  ListContainer,
  StyledAnchor,
  StyledSpan,
} from './styles'
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext'
import { createNewPixel, getPixels, updateInBrand } from './api'
import { FacebookBusinessManager, FBPixel } from 'types/global'
import PrimaryButton from 'components/shareable/PrimaryButton'
import { Body1, Body2 } from 'components/shareable/Typography'
import PixelsList from './Components/PixelsList'
import { fbLoggedIn, loginWithFB } from 'helpers/FB'
import Lottie from 'react-lottie'
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json'
import { getLinkedPixels } from '../../api'
import { SearchInput } from 'components/shareable/SearchInput'
import { Data } from '../../Hooks/useCommonStates'
import { BusinessesList } from './Components/BusinessesList'
import { createNewAdAccount, getBusinessManagers } from 'services/facebookApi'
import { SelectPixelHeader } from './Components/SelectPixelHeader'
import { BusinessManagerWarningMessage } from '../BusinessManagerWarningMessage'
import { useSearchFilter } from '../../Hooks/useSearchFilter'
import { sortAlphabetically } from './utils'
import { track } from 'analytics'

interface Props {
  open: boolean
  facebookPixel: Data
  facebookApiToken?: Data
  onSuccessful?: () => void
  onClose: (reload?: boolean) => void
  onError?: (error: boolean) => void
}

const ConnectFBPixelModal: FunctionComponent<Props> = ({
  open,
  facebookPixel,
  facebookApiToken,
  onSuccessful,
  onClose,
  onError,
}: Props) => {
  const { currentBrand } = useContext(CurrentBrandContext)
  const fbPixel = currentBrand?.connections.facebook_pixel
  const fbAdAccount = currentBrand?.connections.facebook_ad_account
  const fbAdAccountBusiness = fbAdAccount?.business
  const brandName = currentBrand?.name

  const [selectedPixel, setSelectedPixel] = useState(fbPixel || null)
  const [selectedBusinessManager, setSelectedBusinessManager] = useState<FacebookBusinessManager>()
  const [pixels, setPixels] = useState<FBPixel[]>([])
  const [linkedPixels, setLinkedPixels] = useState<FBPixel[]>([])
  const [businessAccounts, setBusinessAccounts] = useState<FacebookBusinessManager[]>()
  const { searchQuery, filteredAccounts, handleOnChangeSearch, setListToFilter } = useSearchFilter(pixels)
  const [pixelsByLinkedFirst, setPixelsByLinkedFirst] = useState<FBPixel[]>([])

  const [loadingOnConnect, setLoadingOnConnect] = useState(false)
  const [loadingPixels, setLoadingPixels] = useState(false)
  const [creatingNewPixel, setCreatingNewPixel] = useState(false)
  const [error, setError] = useState(false)
  const [connectingBusinessManager, setConnectingBusinessManager] = useState<boolean>(false)
  const isDisabledConnectButton = error || !selectedPixel

  useEffect(() => {
    sortAlphabetically(linkedPixels)
    sortAlphabetically(pixels)

    const sortedPixels: FBPixel[] = [
      ...linkedPixels,
      ...pixels.filter((pixel) => !linkedPixels.find((linkedPixel) => linkedPixel.id === pixel.id)),
    ]

    setPixelsByLinkedFirst(sortedPixels)
  }, [linkedPixels, pixels])

  const pixelsToDisplay = searchQuery ? (filteredAccounts as FBPixel[]) : pixelsByLinkedFirst

  const handleCreateNewPixel = () => {
    // if (!fbAdAccountBusiness) {
    //   setConnectingBusinessManager(true)
    //   return
    // }


    createNewPixel({
      selectedPixel,
      fbAdAccount,
      currentBrand,
      creatingNewPixel,
      onSelectPixel: async (pixel) => {
        setLoadingOnConnect(true)
        const response = await updateInBrand({ selectedPixel: pixel, currentBrand })
        facebookPixel.setError(false)
        facebookApiToken?.setError(!response)
        facebookPixel.setStepValue(pixel?.name || '')
        setLoadingOnConnect(false)

        track("Linked Pixel", {
          brandId: currentBrand?.id,
          brandName: currentBrand?.name,
          brandSlug: currentBrand?.slug,
          verified: true,
          newPixel: true,
        })

        onClose(true)
      },
      onCreatingNewPixel: setCreatingNewPixel,
    })
  }

  useEffect(() => {
    if (connectingBusinessManager) {
      setLoadingPixels(true)

      getBusinessManagers().then((businessManagers) => {
        setBusinessAccounts(businessManagers)
        setLoadingPixels(false)
      })
    }
  }, [connectingBusinessManager])

  const checkIsLinked = (focusPixel: FBPixel | null) => {
    const linked = linkedPixels.find((pixel) => pixel.id === focusPixel?.id)
    onError && onError(!Boolean(linked))
    return Boolean(linked)
  }

  const handleClickConnectButton = async () => {
    setLoadingOnConnect(true)
    const response = await updateInBrand({ selectedPixel, currentBrand })
    const isLinked = await checkIsLinked(selectedPixel)
    if (response && onSuccessful) onSuccessful()
    facebookPixel.setError(!isLinked)
    facebookApiToken?.setError(!response)
    facebookPixel.setStepValue(selectedPixel?.name || '')
    setLoadingOnConnect(false)

    track("Linked Pixel", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug,
      verified: isLinked,
    })

    onClose(true)
  }

  const handleConnectBusinessManager = async () => {
    if (selectedBusinessManager) {
      setLoadingOnConnect(true)
      await createNewAdAccount(selectedBusinessManager)
      setLoadingOnConnect(false)
    }
  }

  const handleClose = () => {
    onClose()
    setLoadingPixels(false)
    setCreatingNewPixel(false)
    setLoadingOnConnect(false)
    setConnectingBusinessManager(false)
  }

  useEffect(() => {
    const getData = async (open: boolean) => {
      if (open) {
        if (!fbLoggedIn()) {
          await loginWithFB()
        }
        const pixels = await getPixels({
          onLoading: setLoadingPixels,
          onError: setError,
          business: fbAdAccountBusiness,
          adAccount: {
            id: fbAdAccount ? fbAdAccount.id : ''
          },
        })

        const linkedPixels = await getLinkedPixels({
          fbAdAccount,
          onLoading: setLoadingPixels,
          onError: setError,
        })
        setPixels(pixels)
        setLinkedPixels(linkedPixels)
      }
    }
    getData(open)
  }, [open, creatingNewPixel])

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <ModalContainer>
        <SelectPixelHeader
          currentBrandName={brandName ?? ''}
          isCreatingAdAccount={connectingBusinessManager}
          closeModal={handleClose}
          handleGoBack={() => setConnectingBusinessManager(false)}
        />
        {loadingPixels && (
          <LoadingContainer>
            <Lottie
              height={24}
              width={24}
              options={{
                loop: true,
                autoplay: true,
                animationData: SymphonyLoadingLottie,
              }}
            />
          </LoadingContainer>
        )}
        {error && (
          <ErrorLabelContainer>
            <Body2>No Pixels found...</Body2>
          </ErrorLabelContainer>
        )}
        <ListContainer>
          {connectingBusinessManager && <BusinessManagerWarningMessage />}
          {!loadingPixels && pixels && pixels.length > 2 && (
            <SearchInput
              label={connectingBusinessManager ? 'Search Business Manager' : 'Search your Pixels'}
              showPlaceholder
              value={searchQuery}
              onChange={(ev) => handleOnChangeSearch(ev.target.value)}
            />
          )}
          {!error && !connectingBusinessManager && (
            <PixelsList
              {...{
                adAccount: {
                  id: fbAdAccount ? fbAdAccount.id : '',
                },
                pixels: pixelsToDisplay,
                creatingNewPixel,
                selectedPixel,
                linkedPixels,
              }}
              loading={loadingPixels}
              onSelectPixel={setSelectedPixel}
              onCreateNewPixel={handleCreateNewPixel}
              business={fbAdAccountBusiness}
            />
          )}
          {connectingBusinessManager && !fbAdAccountBusiness && (
            <BusinessesList
              businessAccounts={businessAccounts as FacebookBusinessManager[]}
              businessManager={selectedBusinessManager as FacebookBusinessManager}
              setBusinessManager={setSelectedBusinessManager}
            />
          )}
        </ListContainer>
        {/* {!fbAdAccountBusiness && (
          <CreateAccountOption
            accountType="Facebook Business Manager"
            description={
              <Fragment>
                Facebook's business manager will let you use more advanced capabilities to run ads. Create one by going
                to <StyledAnchor href="https://business.facebook.com">https://business.facebook.com</StyledAnchor>
              </Fragment>
            }
            disabled
          />
        )} */}
        <PrimaryButton
          width="100%"
          disabled={isDisabledConnectButton}
          loading={loadingOnConnect}
          text="Connect"
          onClick={connectingBusinessManager ? handleConnectBusinessManager : handleClickConnectButton}
        />
        {!pixels.length && (
          <Body1>
            Don't see your pixel? You can create a new one via Facebook by following these
            instructions.{' '}
            <StyledAnchor
              href="https://www.facebook.com/business/help/314143995668266?id=1205376682832142"
              target="_blank"
              rel="noreferrer"
            >
              <StyledSpan>Learn how</StyledSpan>
              <StyledSpan>&gt;</StyledSpan>
            </StyledAnchor>
          </Body1>
        )}
      </ModalContainer>
    </Dialog>
  )
}

export default ConnectFBPixelModal
