import { FunctionComponent } from 'react';
import { CampaignTypeText, PurpleContainer } from './styles';


export const WEBSITE_TYPES = {
    MUSIC: "record_streams",
    PRESAVE: "presave",
    DATA_COLLECTOR: "data_collector",
} as const;

export type WebsiteType = typeof WEBSITE_TYPES[keyof typeof WEBSITE_TYPES];

interface Props {
    websiteType: WebsiteType;
    additionalLabel?: string | null;
}

const WEBSITE_TYPE_MAPPINGS: Record<WebsiteType, Record<string, string | string[]>> = {
    "record_streams": {
        text: "Music Site",
        badgeIcons: ["link_clicks"],
    },
    "presave": {
        text: "Presave Site",
        badgeIcons: ["link_clicks"],
    },
    "data_collector": {
        text: "Data Collector Site",
        badgeIcons: ["link_clicks"],
    },
}

const WebsiteTypeBadge: FunctionComponent<Props> = ({
    websiteType,
    additionalLabel
}: Props) => {
    const mappingData = WEBSITE_TYPE_MAPPINGS[websiteType]
    const text = mappingData.text as string;
    const badgeIcons = mappingData.badgeIcons as string[];
    return (
        <PurpleContainer>
            <CampaignTypeText>
                {text} {additionalLabel && `(${additionalLabel})`}
            </CampaignTypeText>
        </PurpleContainer>
    )
}

export default WebsiteTypeBadge;