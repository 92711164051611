export const PLATFORMS = {
  spotify: {
    name: 'Spotify',
    key: 'spotify',
    goal: 'streams on',
    selected: true,
    subtitle: null,
    uploadingAssets: false,
  },
  apple_music: {
    name: 'Apple Music',
    key: 'apple_music',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  youtube: {
    name: 'YouTube',
    key: 'youtube',
    goal: 'views on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  soundcloud: {
    name: 'SoundCloud',
    key: 'soundcloud',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  deezer: {
    name: 'Deezer',
    key: 'deezer',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  tidal: {
    name: 'Tidal',
    key: 'tidal',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  amazon_store: {
    name: 'Amazon Store',
    key: 'amazon_store',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  amazon_music: {
    name: 'Amazon Music',
    key: 'amazon_music',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  instagram: {
    name: 'Instagram',
    key: 'instagram',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  tiktok: {
    name: 'Tiktok',
    key: 'tiktok',
    goal: 'streams on',
    selected: false,
    subtitle: null,
    uploadingAssets: false,
  },
  all: {
    name: `Symphony Website`,
    key: 'all',
    goal: 'clicks to',
    selected: false,
    subtitle:
      'We’ll send people to your song’s Symphony website, which will include all of the streaming services links for the song.',
    uploadingAssets: false,
  },
  custom: {
    name: `Custom Link`,
    key: 'custom',
    goal: 'clicks to',
    selected: false,
    subtitle: 'We’ll send people to your custom link.',
    uploadingAssets: false,
  },
}

export const SAVE = 'Save'
export const CREATING_CAMPAIGN = 'Creating campaign...'
export const CAMPAIGN_CREATED = 'Campaign created!'
export const REVIEW_YOUR_CAMPAIGN = 'Review your campaign'
export const NEXT = 'Next'
export const CONTINUE = 'Continue'
export const CONNECT = 'Connect'
export const DEFAULT_TAB = 'overall'
export const TABS = [
  { value: 'overall', label: 'Overall' },
  { value: 'demographics', label: 'Demographics' },
  { value: 'markets', label: 'Markets' },
  { value: 'daily_stats', label: 'Daily stats' },
]
