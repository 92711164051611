import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

const TikTokIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    const tikTokBlue = iconColor ?? "#00F2EA";
    const tikTokRed = iconColor ? "" :  "#FF004F";
    const tikTokWhite = iconColor ?? "white";

    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => {onClick && onClick()}}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_8168_10185)">
                    <path d="M26.8057 15.5268C28.8675 16.9999 31.3933 17.8666 34.1213 17.8666V12.6198C33.6051 12.62 33.0901 12.5661 32.585 12.4592V16.5892C29.8572 16.5892 27.3317 15.7224 25.2694 14.2494V24.9566C25.2694 30.3129 20.9251 34.6547 15.5664 34.6547C13.5669 34.6547 11.7085 34.0505 10.1647 33.0144C11.9267 34.815 14.3839 35.9321 17.1024 35.9321C22.4614 35.9321 26.8059 31.5903 26.8059 26.2338V15.5268H26.8057ZM28.701 10.2334C27.6473 9.08286 26.9554 7.59595 26.8057 5.95212V5.27727H25.3498C25.7163 7.36658 26.9663 9.15157 28.701 10.2334ZM13.5541 28.9041C12.9654 28.1326 12.6473 27.1888 12.6487 26.2184C12.6487 23.7687 14.6357 21.7824 17.0873 21.7824C17.5441 21.7823 17.9983 21.8522 18.4337 21.9903V16.6262C17.9248 16.5565 17.4113 16.5269 16.898 16.5378V20.7129C16.4623 20.5748 16.0079 20.5047 15.5509 20.5051C13.0994 20.5051 11.1125 22.4912 11.1125 24.9413C11.1125 26.6737 12.1057 28.1735 13.5541 28.9041Z" fill={tikTokRed} />
                    <path d="M25.2694 14.2493C27.3317 15.7223 29.8572 16.589 32.585 16.589V12.4591C31.0624 12.1349 29.7144 11.3396 28.7009 10.2334C26.9661 9.15146 25.7163 7.36647 25.3498 5.27727H21.5256V26.2335C21.5169 28.6766 19.5333 30.6548 17.087 30.6548C15.6455 30.6548 14.3648 29.968 13.5538 28.9041C12.1054 28.1735 11.1122 26.6735 11.1122 24.9414C11.1122 22.4915 13.0992 20.5052 15.5507 20.5052C16.0204 20.5052 16.4731 20.5783 16.8978 20.713V16.5379C11.6332 16.6466 7.39923 20.946 7.39923 26.2337C7.39923 28.8732 8.45359 31.2661 10.1648 33.0145C11.7086 34.0505 13.567 34.6548 15.5665 34.6548C20.9253 34.6548 25.2695 30.3128 25.2695 24.9566V14.2493H25.2694Z" fill={tikTokWhite} />
                    <path d="M32.585 12.4591V11.3424C31.212 11.3444 29.8659 10.9601 28.701 10.2333C29.7322 11.3618 31.0901 12.1398 32.585 12.4591ZM25.3498 5.27726C25.3149 5.07759 25.288 4.8766 25.2694 4.67485V4H19.9892V24.9565C19.9807 27.3992 17.9972 29.3774 15.5507 29.3774C14.8325 29.3774 14.1543 29.207 13.5538 28.9042C14.3648 29.968 15.6455 30.6547 17.087 30.6547C19.5331 30.6547 21.5171 28.6767 21.5256 26.2336V5.27726H25.3498ZM16.898 16.5379V15.349C16.4568 15.2888 16.012 15.2585 15.5666 15.2587C10.2074 15.2586 5.86304 19.6007 5.86304 24.9565C5.86304 28.3143 7.57043 31.2735 10.165 33.0142C8.45372 31.266 7.39937 28.873 7.39937 26.2335C7.39937 20.946 11.6332 16.6466 16.898 16.5379Z" fill={tikTokBlue} />
                </g>
                <defs>
                    <clipPath id="clip0_8168_10185">
                        <rect width="28.274" height="32" fill="white" transform="translate(5.86304 4)" />
                    </clipPath>
                </defs>
            </svg>
        </IconHolder>
    );
};

export default TikTokIcon;