import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Authenticate from "../../pages/auth/Authenticate";
import Signup from "../../pages/auth/Signup";
import Landing from "../../pages/post-auth/Landing";
import Navbar from "../../components/Navbar";

const AuthRoutes = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <div className="">
        <Switch>
          <Route exact path="/auth/login" component={Authenticate} />
          {/* <Route path="/login" component={Authenticate} />
          <Route path="/signup" component={Signup} /> */}
        </Switch>
      </div>
    </Router>
  );
};

export default AuthRoutes;
