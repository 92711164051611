import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      borderRadius: "8px",
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      },
    },
  },
}));

interface ModalContainerProps {
  width?: string
  padding?: string
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: ${({ width }: ModalContainerProps) => width || 'auto'};
  margin: auto;
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: 32px;
`;

export const ButtonConfirm = styled.button`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  background: #8800FF;
  border-radius: 48px;
  padding: 12px 24px;
  width: 100%;
  :disabled {
    background: #707070;
    color: #FFFFFF;
  }
`
