import { makeStyles, Theme } from "@material-ui/core/styles"
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  scrollPaper: {
    alignItems: "flex-start",
  },
  rootDialogFullScreen: {
    "& .MuiDialog-paper": {
      margin: 0,
      borderRadius: 0,
      height: "100%",
      boxShadow: "none",
      backgroundColor: "#F5F3F6",
    },
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      width: "100%",
      borderRadius: "8px",
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      }
    }
  },
  principalPreviewContainer: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    paddingBottom: theme.spacing(30),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down(1701)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
  recordPreviewContainerWhenArtwork: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: "100%",
    position: "relative",

    [theme.breakpoints.down(768)]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "30px",
      overflow: "auto",
      paddingBottom: "60px"
    }
  },
  recordPreviewContainerWhenColor: {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    height: "100%",
    position: "relative",
    overflow: "auto",

    [theme.breakpoints.down(769)]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: "30px",
      paddingBottom: "60px"
    }
  },
  previewColumn: {
    paddingBottom: theme.spacing(0),
    width: "45%",
    height: "inherit",
    [theme.breakpoints.down(769)]: {
      display: "none",
    },
  },
}));

interface MainContainerProps {
  paddingBottom: string,
  paddingTop: string,
}

interface ModalContainerProps {
  width?: string
  padding?: string
}

interface ButtonContainerProps {
  flexDirection: string
  gap: string
}

interface ButtonsSaveCancelContainerProps {
  padding: string
  borderTop?: string
}

export const MainContainer = styled.div`
  display: flex;
  background-color: #F5F3F6;
  justify-content: center;
  width: 100%;
  padding-bottom: ${({ paddingBottom }: MainContainerProps ) => paddingBottom };
  padding-top: ${({ paddingTop }: MainContainerProps ) => paddingTop };
  margin-left: auto;
  margin-right: auto;
  max-width: 80rem;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 678px;
  width: ${({ width }: ModalContainerProps) => width || 'auto'};
  margin: auto;
  padding: ${({ padding }: ModalContainerProps) => padding || '32px'};
  gap: 32px;
`;

export const CustomizeTabContainer = styled.div`
  background-color: yellow;
  background-color: #FFFFFF;
  width: 55%;
  border-radius: 8px;
  @media (max-width: 769px) {
    width: 100%;
  };
`;

export const RecordPreviewContainer = styled.div`
  width: 40%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ButtonContainerProps) => flexDirection || "row"};
  gap: ${({ gap }: ButtonContainerProps) => gap || "16px"};
  width: 100%;
`;

export const ButtonsSaveCancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  padding: ${({ padding }: ButtonsSaveCancelContainerProps) => padding};
  border-top: ${({ borderTop }: ButtonsSaveCancelContainerProps) => borderTop || "none"};
`;
