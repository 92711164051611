import * as Sentry from "@sentry/browser"
import { track } from "analytics"
import dayjs from "dayjs"
import { queryFB } from "helpers/FB"
import Axios from "helpers/Interceptor"
import { CurrentBrand, FBPixel } from "types/global"

export const getPixels = async (data: {
  onLoading: (loading: boolean) => void
  onError: (error: boolean) => void
  business?: {
    id: string
  }
  adAccount: {
    id: string
  }
}) => {
  const { onLoading, onError, business, adAccount } = data
  onLoading(true)

  try {
    Sentry.setTag("flow", "getPixels")

    const url = business && business.id ? business.id : adAccount.id
    const fields = "name,adspixels.limit(100){name,id,is_unavailable}"
    const results = await queryFB(url, {
      params: {
        fields,
      },
    })

    if (!results) return []

    const adPixels = results.adspixels?.data || []
    adPixels.filter((pixel: { is_unavailable: boolean }) => !pixel.is_unavailable)

    onLoading(false)
    onError(false)
    return adPixels
  } catch (error) {
    onError(true)
    onLoading(false)
    Sentry.captureException(error)
    return []
  }
}

export const updateInBrand = async (data: {
  currentBrand?: CurrentBrand
  selectedPixel: FBPixel | null
}) => {
  const { currentBrand, selectedPixel } = data
  const { facebook_pixel: brandPixel } = currentBrand?.connections || {}
  if (selectedPixel?.id === brandPixel?.id) return false

  const facebook_pixel = JSON.parse(JSON.stringify(selectedPixel))
  localStorage.setItem("facebook_pixel", JSON.stringify(facebook_pixel))

  try {
    Sentry.setTag("flow", "updateInBrand")
    Sentry.setContext("input", { currentBrand, selectedPixel })

    await Axios.put(`/brand/${currentBrand?.slug}/connect`, {
      id: currentBrand?.id,
      name: currentBrand?.name,
      service: "facebook_pixel",
      value: selectedPixel,
    })
    return true
  } catch (error) {
    console.error("error creating brand", error)
    Sentry.captureException(error)
    return false
  }
}

export async function createNewPixel(data: {
  selectedPixel: FBPixel | null
  currentBrand?: CurrentBrand
  fbAdAccount?: { id: string } | null
  creatingNewPixel: boolean
  onCreatingNewPixel: (creating: boolean) => void
  onSelectPixel: (pixel: FBPixel) => void
}) {
  const {
    selectedPixel,
    currentBrand,
    fbAdAccount,
    creatingNewPixel,
    onCreatingNewPixel,
    onSelectPixel,
  } = data
  if (creatingNewPixel) return
  onCreatingNewPixel(true)

  const url = `/${fbAdAccount?.id}/adspixels`
  const name = `[S] Symphony Pixel - ${dayjs().format('MM/DD/YYYY')}` 
  const payload = { name: name }

  const creationPromise = new Promise(async (resolve, reject) => {
    const response = await queryFB(url, {
      method: "post",
      params: payload,
    })
    if (!response || response.error) {
      console.error("error", response)
      reject(response.error)
    } else {
      resolve(response)
    }
  })

  try {
    const created = (await creationPromise) as FBPixel
    Sentry.setTag("flow", "createNewPixel")
    Sentry.setContext("input", created)

    track("Created New Pixel", {
      brandId: currentBrand?.id,
      brandName: currentBrand?.name,
      brandSlug: currentBrand?.slug
    })

    onSelectPixel({ ...created, name: name })
  } catch (error) {
    console.error("Error creating ad account", error)
    Sentry.captureException(error)
  }

  onCreatingNewPixel(false)
}

export const getPixelsAdAccountsFromBusiness = async (data: {
  business: {
    id: string
  }
}): Promise<FBPixel[]> => {
  const { business } = data
  try {
    const url = `${business.id}/adspixels`
    const fields = "name,id,owner_ad_account"

    const results = await queryFB(url, {
      params: {
        fields,
      },
    })

    return results.data;
  } catch (error) {
    Sentry.captureException(error)
    return []
  }
}