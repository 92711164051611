import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { MoreVert } from "@material-ui/icons";
import { ReactComponent as EditIcon } from "assets/images/edit.svg";
import { ReactComponent as TrashIcon } from "assets/images/trash.svg";
import { Body2 } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import { useStyles } from './styles';
import useModal from 'Hooks/useModal';
import { MemberRole, MemberRoles, TeamMember, TeamPricesData } from 'types/global';
import { RemoveMemberModal } from '../RemoveMemberModal';

type TeamMemberMenuProps = {
  member: TeamMember;
  myRole?: MemberRole;
  onEditPermissions: () => void;
  teamPrices?: TeamPricesData;
};

export const TeamMemberMenu = ({
  member,
  myRole,
  onEditPermissions,
  teamPrices,
}: TeamMemberMenuProps) => {
  const classes = useStyles();
  const removeMemberModal = useModal();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(prev => prev ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (member.role === MemberRoles.OWNER || myRole === MemberRoles.MEMBER) {
    return <></>
  }

  return (
    <div onClick={handleClick}>
      <MoreVert />
      <Menu
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={onEditPermissions}
          className='flex items-center gap-1'
        >
          <EditIcon />
          <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Edit Permissions</Body2>
        </MenuItem>
        <MenuItem
          onClick={() => removeMemberModal.openModal()}
          className='flex items-center gap-1'
        >
          <TrashIcon className={classes.redIcon} />
          <Body2 color={SystemColors.ERROR_COLOR}>Remove Member</Body2>
        </MenuItem>
      </Menu>
      <RemoveMemberModal open={removeMemberModal.open} member={member} closeModal={removeMemberModal.closeModal} teamPrices={teamPrices} />
    </div>
  );
};