import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from 'styled-components';
import { SystemColors } from "types/globalStyles";

interface MainContainerProps {
  padding: string,
  flexDirection?: string,
}

interface TabsContainerProps {
  borderRadius?: string,
  padding?: string,
  width?: string,
  gap?: string,
  background?: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  chipLabelColor: {
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  liveLabel: {
    paddingRight: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.25),
    backgroundColor: "#53D653",
    color: "#fff",
    borderRadius: 2,
  },
  completedLabel: {
    paddingRight: theme.spacing(1.25),
    paddingLeft: theme.spacing(1.25),
    backgroundColor: "#8800FF",
    color: "#fff",
    borderRadius: 2,
  },
  goToIconWhenActive: {
    cursor: "pointer",
    padding: theme.spacing(0.5),
    position: "relative",
    left: 20,
    bottom: 12,
  },
  goToIconWhenFinished: {
    cursor: "pointer",
    padding: theme.spacing(0.5),
    position: "relative",
    left: 65,
    bottom: 12,
  },
  mainContainer: {
    height: "fit-content",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    borderRadius: 12,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(801)]: {
      borderRadius: 0
    },
    [theme.breakpoints.up(1023)]: {
      width: "91.666667%",
    },
    [theme.breakpoints.up(1279)]: {
      width: "83.333333%",
    },
    [theme.breakpoints.down(1921)]: {
      maxWidth: 800,
    },
  },

  newCampaignActionsHolder: {
    // marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",

    flexDirection: "row",
    alignItems: "center",
    paddingBottom: '16px',
    [theme.breakpoints.down(801)]: {
      flexDirection: "column",
      paddingBottom: `${theme.spacing(2)}px`
    },
  },

  startNewCampaignButton: {
    padding: '0px 16px',
    // paddingBottom: '24px',
    [theme.breakpoints.down(801)]: {
      width: '100%'
    },
  },

  campaignsListHolder: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    width: '100%',
    alignItems: 'center',
    "-ms-overflow-style": "none",  /* Internet Explorer 10+ */
    "scrollbar-width": "none",  /* Firefox */
    [`&::-webkit-scrollbar`]: {
      display: "none"  /* Safari and Chrome */
    },
    [theme.breakpoints.down(801)]: {
      paddingTop: `${theme.spacing(2)}px`
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: SystemColors.ACCENT_COLOR,
      },
      '&:hover fieldset': {
        borderColor: SystemColors.ACCENT_COLOR,
      },
      '&.Mui-focused fieldset': {
        borderColor: SystemColors.ACCENT_COLOR,
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
}));

export interface DraftCircleDivProps {
  fontSize?: number;
}

interface ColumnContainerProps {
  padding?: string
}

export const CircleDiv = styled.div`
  background-color: #8800FF;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: ${({ fontSize }: DraftCircleDivProps) => fontSize || 12}px;
  line-height: 18px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: MainContainerProps) => flexDirection || "row"};
  padding: ${({ padding }: MainContainerProps) => padding};
  background: #F5F3F6;
  gap: 24px;
  min-height: 100%;
  margin-bottom: 64px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  background: #F5F3F6;
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  min-height: 100%;
`;

export const TabsContainer = styled.div<TabsContainerProps>`
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: ${({ padding }) => padding || "16px 24px"};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || "16px"};
  background: ${({ background }) => background || SystemColors.PAPER_CONTAINER};
  max-width: ${({ width }) => width || "100%"};
  min-width: ${({ width }) => width || "100%"};
  height: fit-content;
`;



export const TabsPanelsContainer = styled.div`
  padding: 0px 0px;
  height: 100%;
  background-color: ${SystemColors.PAPER_CONTAINER};
`;

export const NewCampaignActionsHolder = styled.div`
  margin-top: 14px;
`

interface CampaignDetailsContainerProps {
  backgroundColor: string;
}

export const CampaignDetailsContainer = styled.div`
  background-color: ${({ backgroundColor }: CampaignDetailsContainerProps) => (backgroundColor ? backgroundColor : "#EDECF2")};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BannerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
`

export const ColumnContainer = styled.div<ColumnContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ padding }) => padding || "16px 32px"};
  border-bottom: 1px solid ${SystemColors.DIVIDER_LINE};
  gap: 16px;
`

export const EditPlatformContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

export default useStyles;
