import { makeStyles, Theme } from "@material-ui/core/styles"
import styled from "styled-components";

export const useStyles =  makeStyles((theme: Theme) => ({
  customTooltip: {
    backgroundColor: "#2C2135 !important",
    boxShadow: "-3px 4px 9px rgba(0, 0, 0, 0.16) !important",
    borderRadius: "8px",
    padding: "8px 0px",
  },
}));

interface TooltipProps {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

export const TooltipBoxStyled = styled.div`
  background: #000000;
  box-shadow: -3px 4px 9px rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px;
  padding: 8px 16px;
  position: absolute;
  z-index: 100;
  color: #FFFFFF;
  top: ${({ top }: TooltipProps) => top};
  left: ${({ left }: TooltipProps) => left};
  right: ${({ right }: TooltipProps) => right};
  bottom: ${({ bottom }: TooltipProps) => bottom};
`;