import { ReactElement } from "react"

export const getFillList = (
  list: (ReactElement | JSX.Element)[],
  copyTimes = 1
) => {
  const newlist = []
  for (let i = 0; i < copyTimes; i++) {
    newlist.push(...list)
  }
  return newlist
}
