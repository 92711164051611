import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { DropzoneRootProps, useDropzone } from 'react-dropzone';
import { toast, ToastOptions } from 'react-toastify';
import { Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Chip } from '@material-ui/core';
import { isAdmin } from 'helpers/admin';
import GeneralPurpleButton from 'components/shareable/CommonComponent/PurpleButton';
import CardTitle from 'components/shareable/CommonComponent/CardTitle';
import csvtojson from 'csvtojson';
import styled from 'styled-components';
import { CloudUpload } from '@material-ui/icons';
import Axios from 'helpers/Interceptor';
import ReactDatePicker from 'react-datepicker';
import { DataGridPro, GridRowSelectionModel } from '@mui/x-data-grid-pro';

interface Props {
    user: any; // Define the user object type
}

// Desired hook
function useCompare(val: any) {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

// Helper hook
function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const TOAST_WARNING_OPTIONS: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    closeButton: true,
};

export const MainContainer = styled.div`
  display: flex;
  background: #F5F3F6;
  gap: 24px;
  min-height: 100%;
  flex-direction: column;
  margin-bottom: 64px;
  width: 100%;
  padding: 32px 80px;

`;

const CampaignReports: React.FC<Props> = ({ user }) => {
    const userIsAdmin = user ? isAdmin(user) : false;

    // handling table row selection
    const [rowSelectionModel, setRowSelectionModel] =
        React.useState<GridRowSelectionModel>([]);

    // State for the date selectors
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    // State for the campaign data
    const [campaignData, setCampaignData] = useState<any>(null);

    // state for button
    const [loading, setLoading] = useState<any>(false);

    // useEffect(() => {
    //     if (startDate && endDate) {
    //         fetchCampaignData();
    //     }
    // }, [startDate, endDate]);

    // const haveNewRowsBeenSelected = useCompare(rowSelectionModel);
    // useEffect(() => {
    //     if (startDate && endDate) {
    //         fetchCampaignData()
    //     }
    // }, [haveNewRowsBeenSelected])



    // Function to handle the submit button
    const fetchCampaignData = async () => {
        setLoading(true);
        try {
            // Make the API request
            const response = await Axios.post('/admin/get-campaigns', {

                startDate: startDate?.toISOString(),
                endDate: endDate?.toISOString(),
                excluded: rowSelectionModel.map((item: any) => {
                    return item
                })

            });

            const data = response.data.data

            data.rows = data.rows.map((item: any, index: number) => ({
                ...item
            }));

            // Update the campaign data
            setCampaignData(data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }

    const columns = [
        {
            field: "id", headerName: 'Campaign ID', width: 150
        },
        { field: 'brandName', headerName: 'Brand Name', width: 150 },
        { field: 'campaignType', headerName: 'Campaign Type', width: 150, filterable: true },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
        { field: 'submissionTier', headerName: 'Submission Tier', width: 150 },
        {
            field: 'totalBudget',
            headerName: 'Total Budget',
            width: 150,
            valueFormatter: (params: any) => {
                const value = parseFloat(params.value).toFixed(2);
                return `$${value}`;
            },
        },
        {
            field: 'advertisingBudget',
            headerName: 'Advertising Budget',
            width: 150,
            valueFormatter: (params: any) => {
                const value = parseFloat(params.value).toFixed(2);
                return `$${value}`;
            },
        },
        {
            field: 'revenue',
            headerName: 'Revenue',
            width: 150,
            valueFormatter: (params: any) => {
                const value = parseFloat(params.value).toFixed(2);
                return `$${value}`;
            },
        },
        { field: 'paymentPlatform', headerName: 'Payment Platform', width: 150 },
        { field: 'campaignMetadata', headerName: 'Campaign Metadata', width: 150 },

    ];

    if (!userIsAdmin) {
        return <Redirect to="/dashboard" />;
    }

    const campaignTypes = [

        "record_streams",
        "grow_playlist_followers",
        "boost_instagram",
        "pre_save",
        "link_clicks",
        "creative_generator",
        "increase_video_views",
        "get_playlisted"
    ]

    function renderDataInsights() {


        const totalCampaigns = campaignData.rows.length || 0
        return (<>
            {/* Display overall campaign stats */}
            <div>
                <p>
                    Total Number of Campaigns: {totalCampaigns}
                </p>
                <p>
                    Total Spend: ${campaignData.overall.totalSpend.toLocaleString('en', {
                        type: 'currency',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
                <p>
                    Total Advertising Budget: ${campaignData.overall.totalAdvertisingBudget.toLocaleString('en', {
                        type: 'currency',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
                <p>
                    Total Revenue: ${campaignData.overall.totalRevenue.toLocaleString('en', {
                        type: 'currency',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>

                {campaignTypes.map((campaignType: string) => {
                    if (campaignData.overall[campaignType]) {
                        return (
                            <p>
                                {campaignType} Revenue: ${campaignData.overall[campaignType].revenue.toLocaleString('en', {
                                    type: 'currency',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}
                            </p>
                        )
                    }
                })}
            </div>

            <div>
                <p>Hidden Campaign IDs:</p>
                <div>
                    {rowSelectionModel.map((item: any) => {

                        return (
                            <Chip label={item} onDelete={() => {
                                setRowSelectionModel(rowSelectionModel.filter((id: any) => id !== item))

                            }} color="primary" />
                        )
                    })}
                </div>
            </div>
            {/* Display the table */}
            <div style={{ height: 800, width: '100%' }}>
                <DataGridPro

                    checkboxSelection
                    onRowSelectionModelChange={(newRowSelectionModel) => {

                        let combined = [...rowSelectionModel, ...newRowSelectionModel]
                        combined = combined.filter(function (value, index) { return combined.indexOf(value) === index });
                        setRowSelectionModel(combined);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    rows={campaignData.rows}
                    columns={columns}
                    pagination
                    initialState={{
                        pagination: { paginationModel: { pageSize: 100 } },
                        sorting: {
                            sortModel: [{ field: 'revenue', sort: 'desc' }],
                        }
                    }}
                // rowsPerPageOptions={[50]}
                />
            </div>
        </>)
    }

    return (
        <MainContainer>
            <CardTitle title="Campaign Reporting" subtitle="Data 🛰️"
            />

            {/* Date selectors */}
            <div>
                <label>From (inclusive):</label>
                <ReactDatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} />
            </div>
            <div>
                <label>Until (exclusive):</label>
                <ReactDatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} />
            </div>

            {/* Submit button */}
            <Button onClick={fetchCampaignData} disabled={loading}>{loading ? 'Loading...' : 'Submit'}</Button>

            {/* Display campaign data */}
            {campaignData && campaignData.rows && renderDataInsights()}

        </MainContainer>
    )
}

export default CampaignReports