import { FANBASE_TASKS } from "types/global";
import { FunctionComponent, useContext } from "react";
import { Container, StyledLabel } from "./styles";
import PresaveCreatedIcon from "assets/images/fanbaseTasks/PresaveCreatedIcon.svg"
import { FanbaseContext } from "Hooks/FanbaseContext";
import { useHistory } from "react-router-dom";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { track } from "analytics";
import TaskCard from "components/TaskCard";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

interface FanbaseTaskItem {
  icon?: string;
  title: string;
  description: string;
  action: () => void;
  doneKey: FANBASE_TASKS;
  key: FANBASE_TASKS;
}

export const FanbaseTasksNames = {
  [FANBASE_TASKS.PRESAVE_CREATED]: "Create a Pre-save"
}

const getFanbaseActions = (redirect: (url: string, taskName: string) => void): FanbaseTaskItem[] => {
  return [
    {
      icon: PresaveCreatedIcon,
      title: "Create a Pre-save",
      description: "Grow your day 1 streams and email list by collecting pre-saves for your release.",
      action: () => {
        redirect("/marketing/new/presave", "Create a Pre-save");
      },
      doneKey: FANBASE_TASKS.PRESAVE_CREATED,
      key: FANBASE_TASKS.PRESAVE_CREATED,
    }
  ]
}

export const FanbaseTasks: FunctionComponent = () => {
  const {
    finishedTasks: completedFanbaseTasks,
  } = useContext(FanbaseContext);
  const theme = useTheme();
  const setBorderRadius = useMediaQuery(theme.breakpoints.down(701));
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(1200));
  const history = useHistory();

  const redirect = (url: string, taskName: string) => {
    track(`Start "${taskName}" Fanbase Step`, {
      remainingSteps:  Object.keys(FANBASE_TASKS).length - completedFanbaseTasks.length - 1,
    })
    history.push(url);
  }

  const FanbaseActions = getFanbaseActions(redirect);

  const getTaskIsFinishedCallback = (item: FanbaseTaskItem) => { return Boolean(completedFanbaseTasks.find((task) => task === item.doneKey)) }

  if (setResponsiveView) {
    return (
      <Container
        padding="24px"
        backgroundColor="#FFFFFF"
        borderRadius={setBorderRadius ? "0px" : "16px"}
        flexDirection="column"
        display="flex"
        height="fit-content"
      >
        <StyledLabel
          fontSize={20}
          padding="8px 8px"
        >
          Boost your growth by collecting more pre-saves from fans.
        </StyledLabel>
        <Swiper
          slidesPerView={1}
          navigation={true}
          modules={[Navigation]}
          style={{ padding: "8px 8px", maxWidth: "350px" }}
        >
          {
            FanbaseActions.filter((item) => !getTaskIsFinishedCallback(item)).map((item) => {
              return (
                <SwiperSlide key={`swiper-slide-${item.key}`}>
                  <TaskCard
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    action={item.action}
                    crossedOut={false}
                    headerAlign="center"
                  />
                </SwiperSlide>
              )
            })
          }
          {
            FanbaseActions.filter((item) => getTaskIsFinishedCallback(item)).map((item) => {
              return (
                <SwiperSlide key={`swiper-slide-${item.key}`}>
                  <TaskCard
                    key={item.key}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    action={item.action}
                    crossedOut
                    headerAlign="center"
                  />
                </SwiperSlide>
              )
            }
            )
          }
        </Swiper>
      </Container>
    );
  }
  return (
    <Container
      backgroundColor="#F5F3F6"
      flexDirection="column"
      display="flex"
      height="100%"
      width="40%"
    >
      <Container
        padding="24px"
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        flexDirection="column"
        display="flex"
        height="100%"
      >
        <StyledLabel
          fontSize={20}
          padding="8px 0px"
        >
          Boost your growth by collecting more pre-saves from fans.
        </StyledLabel>
        {
          FanbaseActions.filter((item) => !getTaskIsFinishedCallback(item)).map((item) => {
            return (
              <TaskCard
                key={item.key}
                icon={item.icon}
                title={item.title}
                description={item.description}
                action={item.action}
                crossedOut={false}
              />
            )
          })
        }
        {
          FanbaseActions.filter((item) => getTaskIsFinishedCallback(item)).map((item) => {
            return (
              <TaskCard
                key={item.key}
                icon={item.icon}
                title={item.title}
                description={item.description}
                action={item.action}
                crossedOut
              />
            )
          }
          )
        }
      </Container>
    </Container>
  );
}
