import { FunctionComponent, useContext } from "react";
import { Container, Image, StyledLabel, UpgradeToProButton } from "./styles";
import UpgradeToProLabel from "../UpgradeToProLabel";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";

const StarsIcon: FunctionComponent = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M11.3895 3.45894C11.5074 3.0834 12.0389 3.0834 12.1568 3.45894L13.1498 6.62056C13.1892 6.74604 13.2875 6.84432 13.413 6.88373L16.5746 7.87668C16.9501 7.99463 16.9501 8.52608 16.5746 8.64403L13.413 9.63698C13.2875 9.67639 13.1892 9.77467 13.1498 9.90015L12.1568 13.0618C12.0389 13.4373 11.5074 13.4373 11.3895 13.0618L10.3966 9.90015C10.3571 9.77467 10.2589 9.67639 10.1334 9.63698L6.97175 8.64403C6.59622 8.52608 6.59622 7.99463 6.97175 7.87668L10.1334 6.88373C10.2589 6.84432 10.3571 6.74604 10.3966 6.62056L11.3895 3.45894Z"
        fill="#ffffff"
      />
      <path
        d="M6.10218 10.6961C6.20595 10.2938 6.77722 10.2938 6.88099 10.6961L7.3116 12.3654C7.34816 12.5071 7.45883 12.6178 7.60055 12.6544L9.26988 13.085C9.67215 13.1887 9.67215 13.76 9.26989 13.8638L7.60055 14.2944C7.45883 14.3309 7.34816 14.4416 7.3116 14.5833L6.88099 16.2527C6.77722 16.6549 6.20595 16.6549 6.10218 16.2527L5.67157 14.5833C5.63501 14.4416 5.52434 14.3309 5.38262 14.2944L3.71329 13.8638C3.31102 13.76 3.31102 13.1887 3.71328 13.085L5.38262 12.6544C5.52434 12.6178 5.63501 12.5071 5.67157 12.3654L6.10218 10.6961Z"
        fill="#ffffff"
      />
    </g>
  </svg>
);

interface Props {
  image?: string;
  hideImage?: boolean;
  title?: string;
  description?: string;
  secondDescription?: string;
  imageMaxWidth?: string;
  source?: string;
}

const UnlockDataBanner: FunctionComponent<Props> = ({
  title,
  description,
  secondDescription,
  image,
  hideImage,
  imageMaxWidth,
  source
}: Props) => {
  const { handleOpenModal } = useContext(UpgradeModalContext);

  return (
    <Container
      justifyContent="center"
      borderRadius="12px"
      display="flex"
      padding="20px"
      gap="40px"
      alignItems="center"
      backgroundColor="rgba(136, 0, 255, 0.08)"
    >
      {image && !hideImage && (
        <Container
          display="flex"
          flexDirection="column"
          width="25%"
          maxWidth={imageMaxWidth}
          padding="0px 0px 0px 32px"
        >
          <Image
            alt="unlock-data-chart.svg"
            src={image}
            width="80%"
            height="80%"
            maxWidth={imageMaxWidth}
            maxHeight={imageMaxWidth}
          />
        </Container>
      )}
      <Container display="flex" flexDirection="column" width="75%">
        <UpgradeToProLabel
          color="YELLOW"
          label="Symphony Pro"
          maxWidth="169px"
        />
        {title && (
          <StyledLabel fontSize={20} fontWeight={500} marginTop="12px">
            {title}
          </StyledLabel>
        )}
        {description && (
          <StyledLabel
            fontSize={16}
            fontWeight={400}
            color="#707070"
            marginTop="2px"
          >
            {description}
          </StyledLabel>
        )}
        {secondDescription && (
          <StyledLabel fontSize={16} fontWeight={400} color="#707070">
            {secondDescription}
          </StyledLabel>
        )}
        <Container margin="12px 0px 0px 0px">
          <UpgradeToProButton disableTouchRipple onClick={() => handleOpenModal({
            source: source ? source : "Unlock Data Banner in Campaign Details"
          })}>
            <Container display="flex" alignItems="center" gap="4px">
              <StarsIcon />
              <StyledLabel fontSize={16}>Upgrade to Pro</StyledLabel>
              <Container transform="scaleX(-1)">
                <StarsIcon />
              </Container>
            </Container>
          </UpgradeToProButton>
        </Container>
      </Container>
    </Container>
  );
};

export default UnlockDataBanner;
