import { useMediaQuery, useTheme } from "@material-ui/core";
import { ReactComponent as SwipeUpArrow } from "assets/images/chevron-down-arrow-icon.svg"

interface IGStoryPreviewProps {
    callToAction?: string;
    simplePreview?: boolean;
}

/**
 * Renders what the bottom bar of the IG story preview will look like
 * @returns 
 */
export function IGStoryPreviewActionArea(props: IGStoryPreviewProps) {
    const {
        callToAction = "Listen Now",
        simplePreview = false,
    } = props

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    return (
        <div className="mb-2 absolute z-10 bottom-0 flex w-full items-center justify-center " style={simplePreview ? { marginBottom: '7%' } : {}}>
            <div className="items-center justify-center flex flex-col">
                <SwipeUpArrow width={simplePreview ? "6%" : 19} style={{ rotate: '180deg' }} />
                    <div className={`text-xs rounded-full w-auto bg-white 
                    bg-opacity-50
                    text-white
                    font-semibold
                    ${!simplePreview &&
                        `px-5 py-2
                        md:px-4 md:py-2`
                    }
                    text-sm md:text-xs`}
                    style={simplePreview ? {
                        fontSize: setResponsiveView ? "1.5vw" : "0.5vw",
                        lineHeight: "0",
                        width: setResponsiveView ? "12.5vw" : "4.2vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        aspectRatio: "3",
                    } : {}}>
                        {callToAction}
                    </div>
                
            </div>
        </div>
    )
}

