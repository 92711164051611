/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { ConversionsTask, CurrentBrand, CONVERSIONS_VIDEOS, VideoData } from "types/global"
import { Data } from "./useCommonStates"
import { UseModalData } from "Hooks/useModal"
import { UseSwitchData } from "Hooks/useSwitch"

const useSetInitialData = (data: {
  facebookAdAccount: Data
  facebookPixel: Data
  facebookApiToken: Data
  nextStep?: ConversionsTask
  currentBrand?: CurrentBrand
  setNextStep: (step: ConversionsTask) => void
  setCurrentVideoData: (data: VideoData) => void
  setIsSetupComplete: (completed: boolean) => void
  finishedModal: UseModalData
  conversionsIndicator: UseSwitchData
}) => {
  const { stepVideos } = CONVERSIONS_VIDEOS
  const {
    facebookAdAccount,
    facebookPixel,
    facebookApiToken,
    currentBrand,
    nextStep,
    setNextStep,
    setCurrentVideoData,
    setIsSetupComplete,
    finishedModal,
    conversionsIndicator,
  } = data
  const { connections } = currentBrand || {}
  const {
    facebook_pixel_conversions_api_access_token: brandAccessToken,
    facebook_pixel: brandFBPixel,
    facebook_ad_account: brandAdAccount,
  } = connections || {}
  const conversionsStatus = currentBrand?.conversionsStatus
  const tasks = conversionsStatus?.conversions_tasks

  useEffect(() => {
    const conversionsStatus = currentBrand?.conversionsStatus
    if (!conversionsStatus) {
      setNextStep(ConversionsTask.AD_ACCOUNT_CONNECTED)
      setCurrentVideoData(stepVideos[ConversionsTask.AD_ACCOUNT_CONNECTED])
      return
    }
    const isCompleted = Boolean(conversionsStatus.conversions_tasks_completed)
    const numberOfTasksCompleted = conversionsStatus.conversions_tasks.length
    const nextStep = Object.values(ConversionsTask)[numberOfTasksCompleted - 1]
    const showFinishedModal = Boolean(conversionsStatus.showFinishedModal)

    showFinishedModal ? finishedModal.openModal() : finishedModal.closeModal()
    isCompleted ? conversionsIndicator.enable() : conversionsIndicator.disable()

    setIsSetupComplete(isCompleted)
    setNextStep(nextStep)
    setCurrentVideoData(stepVideos[nextStep])
  }, [currentBrand])


  useEffect(() => {
    const isFbPixelStepAvailable = tasks?.find(
      (task) => task === ConversionsTask.AD_ACCOUNT_CONNECTED
    )
    facebookAdAccount.setStepValue(brandAdAccount?.name || null)
    if (nextStep && isFbPixelStepAvailable && !facebookPixel.error) {
      facebookPixel.setStepValue(brandFBPixel?.name || null)
      facebookPixel.setError(false)
    }
    if (nextStep) {
      facebookApiToken.setStepValue(brandAccessToken || null)
      facebookApiToken.setError(false)
    }
  }, [tasks, brandAccessToken, brandFBPixel, brandAdAccount, nextStep])
}

export default useSetInitialData
