import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  overall: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    borderBottomWidth: 1,
  },
  marketing: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
  },
  automationFee: {
    marginTop: theme.spacing(2),
  },
  noBottomBorder: {
    borderBottomWidth: 0,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
  paddingBottomThemeSpacing: {
    paddingBottom: theme.spacing(1),
  },
  textField: { height: 48, maxWidth: 116, borderRadius: 6 },
  inputProps: { fontFamily: "DIN", textAlign: "right" },
}));

export default useStyles;
