import { Fragment, FunctionComponent, useEffect, useMemo } from 'react'
import { FBPixel } from 'types/global'
import useStyles, {
  StyledUnorderedList,
} from './styles'
import { CreateAccountOption } from '../../../CreateAccountOption/CreateAccountOption'
import { StyledAnchor } from '../../styles'
import { getPixelsAdAccountsFromBusiness } from '../../api'
import { PixelOption } from './PixelOption'

interface Props {
  selectedPixel: FBPixel | null
  pixels: FBPixel[]
  linkedPixels: FBPixel[]
  creatingNewPixel?: boolean
  adAccount: {
    id: string
  }

  business?: {
    id: string;
    name: string;
  } | undefined
  onSelectPixel: (pixel: FBPixel | null) => void
  onCreateNewPixel: () => void
  loading: boolean;
}

const PixelsList: FunctionComponent<Props> = ({
  pixels,
  linkedPixels,
  selectedPixel,
  adAccount,
  creatingNewPixel,
  business,
  onSelectPixel,
  onCreateNewPixel,
  loading
}: Props) => {
  const classes = useStyles()

  useEffect(() => {
    if (linkedPixels.length === 1) {
      onSelectPixel(linkedPixels[0])
    }
  }, [linkedPixels])

  // if its a personal ad account, we shouldn't show the create option but
  // tell the user to pick an ad account tied to a business if they
  // want to make a new pixel
  let alreadyHasPersonalPixel = false


  if (!business && adAccount && adAccount.id && pixels.length > 0) {
    alreadyHasPersonalPixel = true
  }

  const alreadyLinkedPixel = useMemo(() => {
    let foundOwnerAdAccount = false
    if (business) {
      try {
        getPixelsAdAccountsFromBusiness({ business }).then(pixels => {
          pixels.forEach((pixel: any) => {
            if (pixel?.owner_ad_account?.id === adAccount.id) {
              foundOwnerAdAccount = true
            }
          });
        })
      } catch { }
    }
    return foundOwnerAdAccount;
  }, [adAccount, business])

  // if the user doesnt already have a linked pixel to their ad account
  // then we should show the create option
  let allowCreateNewPixel = true
  if (linkedPixels.length || alreadyLinkedPixel) {
    allowCreateNewPixel = false
  }


  return loading ? null : (
    <Fragment>
      {/* {pixels.length > 0 ? ( */}
      <StyledUnorderedList>
        {pixels.map((pixel) => {
          return <PixelOption
            key={pixel.id}
            pixel={pixel}
            selectedPixel={selectedPixel}
            onSelectPixel={onSelectPixel}
            business={business}
            linkedPixels={linkedPixels}
          />
        })}
        {!alreadyHasPersonalPixel && allowCreateNewPixel && (
          <CreateAccountOption
            accountType="Pixel"
            description="You'll need to make sure your Facebook Business Manager is linked to Symphony."
            onClick={onCreateNewPixel}
            isCreatingAccount={creatingNewPixel}
          />
        )}
      </StyledUnorderedList>
      {/* ) : ( */}

      {alreadyHasPersonalPixel && (

        <CreateAccountOption
          accountType="Facebook Business Manager"
          description={
            <Fragment>
              Facebook's business manager will let you use more advanced capabilities to run ads. Create one by going
              to <StyledAnchor href="https://business.facebook.com">https://business.facebook.com</StyledAnchor> or by selecting an Ad Account that's already linked to a business.
            </Fragment>
          }
          disabled
        />

      )}

      {/* )} */}
    </Fragment>
  )
}

export default PixelsList
