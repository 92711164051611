import Axios from "helpers/Interceptor";
import * as Sentry from "@sentry/react";

export const connectTwitter = async (args: {
  id: number;
  slug: string | null;
  userData: { username: string; url: string };
}) => {
  const { id, slug, userData } = args;

  try {
    const response = await Axios.put(`/brand/${slug}/connect`, {
      id,
      name: slug,
      service: "twitter",
      value: userData,
    });

    const data = response.data.data;

    return { data, error: null };
  } catch (error) {
    Sentry.captureException(`Error when connect Dashboard twitter ${error}`);
    return { data: null, error };
  }
};
