import React from "react"
import { LoadingIndicator as Loader } from "."

type Props = {
    children: React.ReactNode
}

const LoadingIndicator = ({ children }: Props) => {
    return (
        <div className="mx-auto my-6 flex-col flex items-center">
            <Loader height="100px" color="black" />
            {children}
        </div>
    )
}

export default LoadingIndicator