export enum PageTabs {
  PERFORMANCE = 'performance',
  SETUP = 'setup',
  AUDIENCE = 'audience',
  GEOGRAPHIES = 'geographies'
}

export const TABS = [
  {
      value: PageTabs.PERFORMANCE,
      label: "Stats"
  },
  {
      value: PageTabs.AUDIENCE,
      label: "Audience"
  },
  {
      value: PageTabs.GEOGRAPHIES,
      label: "Geographies"
  },
  {
      value: PageTabs.SETUP,
      label: "Setup"
  }
]