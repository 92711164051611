import { FunctionComponent } from "react";
import StarsIcon from "assets/images/stars-icon.svg";
import {
  Icon,
  MainContainer,
  StyledLabel,
  MainContainerProps,
  StyledLabelProps,
} from "./styles";
import GemIcon from "../ProCallouts/GemIcon";
import { SymphonyTooltip } from "styles/shared";

interface Props {
  mainContainerProps?: MainContainerProps;
  labelProps?: StyledLabelProps;
  width?: number;
  height?: number;
  type?: 'gem' | 'label';
  color?: 'gold' | 'purple' | 'white'
  withMargin?: boolean;
}

const UpgradeToProChip: FunctionComponent<Props> = ({
  mainContainerProps,
  width,
  height,
  type = 'gem',
  color = 'gold',
  withMargin = true
}: Props) => {

  let mainItem;
  if (type === 'label') {
    mainItem = (
      <MainContainer {...mainContainerProps} gap={4} alignItems="center" width="fit-content">
        <Icon alt="stars-icon.svg" src={StarsIcon} />
        <StyledLabel
          fontSize={14}
          fontWeight={480}
          color="#ffffff">
          PRO
        </StyledLabel>
      </MainContainer>
    )
  } else {
    mainItem = (
      <GemIcon width={width} height={height} color={color} />
    )
  }

  return (
    <SymphonyTooltip className={`${withMargin && 'ml-2'} w-6 h-6`} title={"This is a Pro-exclusive feature."} arrow>
      {mainItem}
    </SymphonyTooltip>
  );


};

export default UpgradeToProChip;
