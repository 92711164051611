import {
  useEffect,
  useRef,
  useState,
  FunctionComponent,
  Dispatch,
  SetStateAction,
  useContext,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CreatedCampaign,
  CurrentBrand,
  FbSuggestion,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  FacebookPageType,
  InstagramPageType,
  AdAccountPageType,
  CurrencyCodesType,
  UserData,
} from "types/global"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { Backdrop, Fade, Modal, useMediaQuery, useTheme } from "@material-ui/core"
import useStyles, { LoadingContainer } from "./styles"
import {
  handleConnectionsType,
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  getNextButtonLabel,
  getNextButtonWidth,
  STEPS,
  campaignOptions,
  CampaignOption
} from "./utils"
import startCase from "lodash/startCase"
import Grid from "@material-ui/core/Grid"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import { GeographicTargetingType } from "./TargetingView/reducer"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import { MarketingDataContext } from "../Data/Provider"

import { CAMPAIGN_CREATED, CREATING_CAMPAIGN, GOAL, mainTitle } from "./constants"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import clsx from "clsx"
import { AssetUploadTypes, getAdAccountDetails, getFbAccountDetails, getIfPagesAreSelected, getInstagramDetails, getPreconnectionConfigurationsStatus, PreConnectionConfigs, SelectedFbAdAccount, SelectedFbPage, UploadedAssetType, validateInstagram } from "../utils/fbCampaigns"
import AdPreviewBox from "components/shareable/FacebookAdPreview"
import useAdPreviewStyles from "components/shareable/FacebookAdPreview/styles"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { fixGeographicTargets } from "utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { LoadingIndicator } from "components/Loader"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPES, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "../../../../constants"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { fbLoggedIn } from "helpers/FB"
import { MobileAdPreviewBar } from "../SongAds/MobileAdPreviewBar"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { getAccountStatus, getTosStatus } from "pages/post-auth/MarketingPage/utils"
import useTermsOfServiceTimer from "Hooks/useTermsOfServiceTimer"
import { CreativeAssetTypes } from "../constants"
import { getTosStatus as getTosStatusApi } from '../api'
import GoalTab from "./GoalTab"
import { getConvertedCurrency } from "services/symphonyApi"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "../SongAds/TargetingView/data"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"

const { CAMPAIGN_GOAL, CREATIVE, BUDGET, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [CAMPAIGN_GOAL, CREATIVE, BUDGET, FINALIZATION]
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

interface Props {
  currentBrand: CurrentBrand
  setGoal: Dispatch<SetStateAction<string | null>>
  refreshCampaigns: () => void
  reloadBrands: () => void
  currentUser: UserData
}

const GrowInstagramEngagement: FunctionComponent<Props> = ({
  currentBrand,
  setGoal,
  refreshCampaigns,
  reloadBrands,
  currentUser
}: Props) => {
  const classes = useStyles()
  const adPreviewStyles = useAdPreviewStyles()

  const theme = useTheme()
  const location = useLocation()
  const { mediumView } = useMediaBreakpoints()
  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData, saveData } = useContext(ConnectModalsContext)
  const { isDisabledConnectButton, handleConnect: handleConnectModal, loading } = connectModalData
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveArtists,
    saveAdditionalPageAudiences,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)
  const { reloadBrand } = useContext(CurrentBrandContext)
  const [targets, setTargets] = useState<GeographicTargetingType[]>(
    geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram)
  )
  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])

  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(
    Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2))
  )
  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, CAMPAIGN_GOAL)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] =
    useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)
  const [selectedFBBusinessManager, setFBBusinessManager] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager
  )

  // organization 
  const primaryOrganization = currentUser.organization

  // This is primarily used for validation purposes
  // when connecting an IG

  const [instagramPagesAvailable, setInstagramPagesAvailable] = useState<boolean>(false)
  // MAIN CONNECTIONS
  const [selectedFBPixel, setFBPixel] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager &&
    currentBrand.connections.facebook_ad_account &&
    currentBrand.connections.facebook_pixel
  )
  const [selectedFB_page, setSelectedFB_page] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && currentBrand.connections.facebook_business_manager
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.facebook_page
  )


  const [selectedFBAdAccount, setFBAdAccount] = useState<any>(
    currentBrand?.connections?.facebook_ad_account
  )
  const [selectedInstaPage, setSelectedInstaPage] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && (currentBrand.connections.facebook_business_manager
    currentBrand.connections.facebook_page &&
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.instagram_page
  )
  const [selectedInstagramPost, setSelectedInstagramPost] =
    useState<InstagramPost>()
  const [connectionsType, setConnectionsType] = useState<string>("quick")
  // for video uploader & ad previews
  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(UPLOAD_VIDEO)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(UPLOAD_VIDEO);

  // Add state for selectedGoal with the default value
  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignOption>(campaignOptions[0]);

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  const [campaignName, setCampaignName] = useState<string>("")
  const [caption, setCaption] = useState<string>("")
  const [cta, setCTA] = useState<{
    value: string
    label: string
  }>({ value: "LEARN_MORE", label: "Learn More" })
  const [showPreview, enablePreviewMode] = useState<boolean>(false)
  const [interestTargetingAudiences, setInterestTargetingAudiences] =
    useState<any>(null)
  const [validatedAudiencesAlready, setValidatedAudiences] =
    useState<boolean>(false)

  // budgeting
  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);
  // if the budget is less than the recommended budget
  // TODO: finalize actual recommended budget

  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  let recommendedUsdBudget = 100;
  const checkMinimumBudgetStatus = async () => {
    const convertedBudget = await getConvertedCurrency({ from: currency, to: "USD", amount: budgetValue });
    return convertedBudget < recommendedUsdBudget;
  }
  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram));
      saveTargets(geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram));
    }
  }, [isBelowRecommendedBudget]);

  // start and end date logistics
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] =
    useState(false)
  const [preConnectionConfigurations, setPreConnectionConfigurations] =
    useState<PreConnectionConfigs | null>(null)
  const [adConnectionsAdded, setAdConnectionsAdded] = useState(false)
  const [adConnectionsConfirmed, setAdConnectionsConfirmed] = useState<boolean>(false)
  const [uploadQueueCreativeTab, setUploadQueueCreativeTab] = useState<
    Record<string, unknown>[] | null
  >(null)
  const [connectModalType, setConnectModalType] = useState("")
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<
    string,
    unknown
  > | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false)

  const previouslySelectedBizMgr = useRef<any>()
  const previouslySelectedFbPage = useRef<any>()
  const previouslySelectedAdAccount = useRef<any>()
  const [draftVerified, setDraftVerified] = useState<boolean>(!draft)
  const [showLoading, setShowLoading] = useState<boolean>(Boolean(draft) && retrievingDraftData)
  const { time, startTimer, handleStartTimer } = useTermsOfServiceTimer({
    selectedFBAdAccount,
    setPreconnectConfigurations: setPreConnectionConfigurations,
  })

  useEffect(() => {
    if (draft && retrievingDraftData) {
      setShowLoading(false)
    }
  }, [draft, retrievingDraftData]);

  const uploadingAssets =
    !!uploadQueueCreativeTab && uploadQueueCreativeTab.length > 0
  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
  }))

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )
  const genres = currentBrand?.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  const dataToSaveDraft = [
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    caption,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    selectedTab,
    cta,
    campaignName,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    selectedCampaignType
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = Boolean(audiences.length) ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = Boolean(targets.length)
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const saveConnectModalType = (type: string) => setConnectModalType(type)

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])

    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveUploadQueueCreativeTab = (
    queue: Record<string, unknown>[] | null
  ) => {
    setUploadQueueCreativeTab(queue)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const handleClickCloseTopBar = () => {
    setGoal(null)
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === CAMPAIGN_GOAL) {
      setGoal(null)
      history.push("/marketing")

    } else if (selectedTab === CREATIVE) {
      if (adConnectionsConfirmed) {
        setAdConnectionsConfirmed(false)
      } else {
        setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
      }
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }
  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: "grow_instagram_engagement",
      step: 1,
      name: "connect_accounts",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    track("Completed Campaign Step - Creative Assets", {
      type: "grow_instagram_engagement",
      step: 2,
      name: "add_creatives",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      caption: caption,
      cta: cta.value,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickNextButton = () => {
    if (selectedTab === CAMPAIGN_GOAL) {
      setSelectedTab(CREATIVE)
    } else if (selectedTab === CREATIVE && connectModalType === "") {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === CREATIVE && connectModalType !== "") {
      handleConnectModal()
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  const createdCampaign = (campaign: CreatedCampaign) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: "grow_instagram_engagement",
      step: 3,
      name: "set_budget",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  async function getTosConfiguration(
    selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null,
    adAccountDetails: Record<string, unknown> | false
  ) {
    if (!adAccountDetails || !selectedFBAdAccount) return null

    const termsOfService = await getTosStatusApi(selectedFBAdAccount.id);
    const toDoAccountStatus = getAccountStatus(selectedFBAdAccount as { id: string }, adAccountDetails)

    if (termsOfService.status && !toDoAccountStatus.status) termsOfService.status = false;
    termsOfService.account_status = toDoAccountStatus.account_status
    setPreConnectionConfigurations(termsOfService)

    return termsOfService
  }

  const checkConnectedAccounts = async (args: {
    selectedInstaPage: InstagramPageType | null,
    selectedFB_page: SelectedFbPage | FacebookPageType | null,
    selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null,
  }) => {
    if (draft && !campaignDraftData) return;

    const {
      selectedFBAdAccount,
      selectedFB_page,
      selectedInstaPage,
    } = args

    saveData(prev => ({ ...prev, loading: true }))

    const adAccountDetails = await getAdAccountDetails(selectedFBAdAccount)
    const fbAccountDetails = await getFbAccountDetails(selectedFB_page)
    const igAccountDetails = await getInstagramDetails(selectedInstaPage)
    const preConnectionConfigurations = await getTosConfiguration(selectedFBAdAccount, adAccountDetails)

    if (preConnectionConfigurations) {
      const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
      setAreTermsAccepted(currentTermsStatus)
    }

    const hasAllConnections = Boolean(selectedFBAdAccount && selectedInstaPage && selectedFBAdAccount)
    if (!hasAllConnections) {
      fbLoggedIn()
      setAdConnectionsAdded(false)
      setAdConnectionsConfirmed(false)
      // setSelectedTab(tabs[1])

      saveData(prev => ({ ...prev, loading: false }))
      return;
    }

    const areAllConnectionsValid = Boolean(adAccountDetails && fbAccountDetails && igAccountDetails)
    if (!areAllConnectionsValid) {
      removeConnections()
      setSelectedTab(tabs[0])
    }

    if (hasAllConnections && areAllConnectionsValid) {
      setAdConnectionsAdded(true)
    }

    const isValidInstagram = await validateInstagram(selectedInstaPage, fbAccountDetails)
    if (!isValidInstagram) {
      setSelectedInstaPage(null)
      setAdConnectionsAdded(false)
      setAdConnectionsConfirmed(false)
    }

    saveData(prev => ({ ...prev, loading: false }))
  }

  const removeConnections = () => {
    setFBAdAccount(null)
    setSelectedFB_page(null)
    setSelectedInstaPage(null)
    setAdConnectionsAdded(false)
    setAdConnectionsConfirmed(false)
  }

  useEffect(() => {
    previouslySelectedBizMgr.current = selectedFBBusinessManager
    previouslySelectedFbPage.current = selectedFB_page
    previouslySelectedAdAccount.current = selectedFBAdAccount

    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
    })
  }, [])

  useEffect(() => {
    const draftMetadata = campaignDraftData?.campaign_metadata as { campaign_input: { brand: CurrentBrand } }
    const draftConnections = draftMetadata?.campaign_input.brand.connections
    const { facebook_page: draftFbPage, instagram_page: draftIgPage, facebook_ad_account: draftAdAccount } = draftConnections || {}

    let accountsToCheck = {
      selectedFBAdAccount,
      selectedFB_page,
      selectedInstaPage,
    }

    if (campaignDraftData && !draftVerified) {
      accountsToCheck = {
        selectedFBAdAccount: draftAdAccount,
        selectedFB_page: draftFbPage,
        selectedInstaPage: draftIgPage,
      }
      setDraftVerified(true)
    }

    checkConnectedAccounts(accountsToCheck)
  }, [currentBrand, campaignDraftData])

  useEffect(() => {
    if (!draft) {
      handleConnectionsType({
        connectionsType,
        selectedFBBusinessManager,
        selectedFBAdAccount,
        currentBrand,
        previouslySelectedBizMgr,
        previouslySelectedAdAccount,
        selectedFB_page,
        previouslySelectedFbPage,
        setSelectedInstaPage,
        setFBBusinessManager,
        setFBAdAccount,
        setFBPixel,
      })
    }
  }, [
    draft,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    selectedFB_page,
    connectionsType,
  ])

  useEffect(() => {
    scrollToTop()
    scrollIntoView()
  }, [selectedTab, showTargetingView])

  useDraftDataGetter({
    draft,
    checkConnectedAccounts,
    setters: {
      setCaption,
      setCTA,
      setRetrievingDraftData,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setFBBusinessManager,
      setFBPixel,
      setSelectedFB_page,
      setFBAdAccount,
      setSelectedInstaPage,
      setTargets,
      setAdditionalPagesAudiences,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAdConnectionsAdded,
      setAddVisualSelected,
      setSelectedCampaignType
    } as Record<string, Dispatch<unknown>>
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    audiencesData,
    currentBrand,
    dataToSaveDraft,
    draft,
    requiredDataChanged,
    retrievingDraftData,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus,
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    savingDraftStatus
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean =
    selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed

  function renderAdPreview() {
    let previewModule = (shown: boolean, onClose?: () => void) => (<AdPreviewBox
      callToAction={cta.label}
      closePreview={onClose}
      shown={shown}
      facebookPage={selectedFB_page ? selectedFB_page : null}
      instagramAccount={selectedInstaPage ? selectedInstaPage.instagramAccount : null}
      captions={creativeSelectorTab === INSTAGRAM_POSTS ?
        (selectedInstagramPost ? [{
          type: 'all',
          caption: selectedInstagramPost.caption
        }] : []) :
        (caption ? [{
          type: 'all',
          caption: caption
        }] : [])
      }
      previewedPost={previewedVideo && creativeSelectorTab !== INSTAGRAM_POSTS ? {
        postType: 'uploaded',
        caption: "test",
        media_url: previewedVideo.url,
        media_type: "VIDEO",
        thumbnail_url: previewedVideo.thumbnail,
        type: previewedVideo.formatType
      } : null}
      selectedPosts={selectedInstagramPost && creativeSelectorTab === INSTAGRAM_POSTS ?
        [{
          postType: 'instagram',
          caption: selectedInstagramPost.caption,
          media_url: selectedInstagramPost.media_url,
          media_type: selectedInstagramPost.media_type,
          thumbnail_url: selectedInstagramPost.thumbnail_url,
          type: selectedInstagramPost.media_product_type === 'REELS' ? 'story' : 'feed'
        }] :
        uploadedImages && uploadedImages.length > 0 ? uploadedImages.map((uploadedVideo: UploadedAssetType) => {
          return {
            postType: 'uploaded',
            caption: "test",
            media_url: uploadedVideo.url,
            media_type: "VIDEO",
            thumbnail_url: uploadedVideo.thumbnail,
            type: uploadedVideo.formatType
          }
        }) : null}
      muted={!adConnectionsAdded || !adConnectionsConfirmed || !areTermsAccepted || selectedTab !== CREATIVE}
    />)

    if (window.innerWidth < 1200) {
      return <Modal
        aria-labelledby="connector-modal"
        aria-describedby="connector-modal for platforms"
        className={adPreviewStyles.previewAdModal}
        open={showPreview}
        onClose={() => enablePreviewMode(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
      >
        <Fade
          in={showPreview}
        >

          {previewModule(true, () => enablePreviewMode(false))}

        </Fade>
      </Modal>
    } else {
      return previewModule(canShowAdPreview)
    }
  }

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(connectionsType, {
      fbPixel: selectedFBPixel,
      fbAdAccount: selectedFBAdAccount,
      fbPage: selectedFB_page,
      instaPage: selectedInstaPage,
    }, {
      fbPixel: false,
      fbAdAccount: true,
      fbPage: true,
      instaPage: true
    })
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      selectedFB_page,
      selectedInstaPage,
      arePagesSelected,
      selectedFBAdAccount,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
      cta,
      caption,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)

    if (disableForTargeting) return true
    switch (selectedTab) {
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()
        const conditionsToDisable = [
          disableNextButtonWhenCreativeTab && connectModalType === "",
          connectModalType !== "" && isDisabledConnectButton,
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          connectModalType === "" && selectedFBAdAccount && !areTermsAccepted,
          connectModalType === "instagram" && !instagramPagesAvailable,
        ]
        return conditionsToDisable.some(condition => Boolean(condition))
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView
      default:
        return false
    }
  }

  let topbarTitle = mainTitle
  if(selectedCampaignType?.id === 'boost_views') topbarTitle = 'Boost IG Post Views'
  else if(selectedCampaignType?.id === 'boost_follows') topbarTitle = 'Boost IG Profile Visits'

  return creatingCampaign || finished ? (
    <>
      <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
      <CampaignCreationPage
        reloadBrand={reloadBrand}
        reloadBrands={reloadBrands}
        primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
        draft={campaignDraftData?.id as string}
        setupType={connectionsType}
        campaignName={campaignName}
        brand={currentBrand}
        goal={GOAL}
        fbPage={selectedFB_page}
        fbBusinessManager={selectedFBBusinessManager}
        fbAdAccount={selectedFBAdAccount}
        fbPixel={selectedFBPixel}
        igPage={selectedInstaPage}
        igMedia={igMedia}
        budget={budgetValue}
        startDate={startDate}
        selectedCampaignType={selectedCampaignType}
        endDate={endDate}
        assets={{
          all: uploadedImages,
        }}
        caption={caption}
        cta={cta}
        customAudiences={customAudiences}
        additionalPageAudiences={additionalPageAudiences}

        createdCampaign={createdCampaign}
        finished={finished}
        recentlyCreatedCampaign={recentlyCreatedCampaign}
        refreshCampaigns={refreshCampaigns}
        setSelectedTab={setSelectedTab}
        setFinished={setFinished}
        allowCampaignCreation={allowCampaignCreation}
        fbSuggestions={fbSuggestions}
      />
    </>
  ) : (
    <Grid
      className={
        selectedTab === CREATIVE && adConnectionsAdded
          ? classes.creativeTabHighMarginBottom
          : classes.normalMarginBottom
      }
    >
      <TopBar
        showCloseIcon
        showSaveDraftChip={
          !!draft ||
          (!!selectedFBAdAccount && !!selectedFB_page && !!selectedInstaPage)
        }
        savingChanges={isSavingDraft}
        title={topbarTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            showTargetingView,
            selectedTab,
            isNextButtonMobile,
            connectModalType,
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab,
          }),
          loadingNextButton: loading,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mediumView && canShowAdPreview}
          />
        }      >
        {showLoading && (
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        )}
        {!showLoading && (
          <MainContentContainer
            backgroundColor="transparent"
            className={clsx(
              classes.mainContainer,
              canShowAdPreview && classes.creativeTabContainer
            )}
          >
            <Grid
              className={clsx(
                ` h-full w-full rounded-${isMobile ? "none" : "xl mt-6"
                }`,
                selectedTab !== STEPS.CAMPAIGN_GOAL && 'bg-white',
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ? (
                <TargetingView
                  selectedFBPage={selectedFB_page}

                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  selectedFBAdAccount={selectedFBAdAccount}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) =>
                    setAudiences(audiences)
                  }
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}

                  handleTargets={(targets: GeographicTargetingType[]) =>
                    setTargets(targets)
                  }
                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) =>
                    setIsEditingTargeting(isEditing)
                  }
                  showSaveButton={false}
                />
              ) : (
                <>
                  <div className="w-full">
                    <TabPanel hidden={selectedTab !== CAMPAIGN_GOAL}>
                      <GoalTab
                        selectedGoal={selectedCampaignType}
                        setSelectedGoal={setSelectedCampaignType} // or directly pass setSelectedGoal if you want to manage the state within this component
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== CREATIVE}>
                      <CreativeTab
                        setInstagramPagesAvailable={setInstagramPagesAvailable}
                        setPreviewedVideo={setPreviewedVideo}
                        previewedVideo={previewedVideo}
                        creativeSelectorTab={creativeSelectorTab}
                        setCreativeSelectorTab={setCreativeSelectorTab}
                        setAddVisualSelected={setAddVisualSelected}
                        reloadBrands={reloadBrands}
                        selectedAssets={uploadedImages}
                        setSelectedAssets={setUpload}
                        selectedFB_page={selectedFB_page}
                        setSelectedFB_page={setSelectedFB_page}
                        selectedInstaPage={selectedInstaPage}
                        setSelectedInstaPage={setSelectedInstaPage}
                        selectedFBBusinessManager={selectedFBBusinessManager}
                        setFBBusinessManager={setFBBusinessManager}
                        selectedFBPixel={selectedFBPixel}
                        setFBPixel={setFBPixel}
                        selectedFBAdAccount={selectedFBAdAccount}
                        setFBAdAccount={setFBAdAccount}
                        caption={caption}
                        setCaption={setCaption}
                        cta={cta}
                        setCTA={setCTA}
                        brand={currentBrand}
                        selectedInstagramPost={selectedInstagramPost}
                        setSelectedInstagramPost={setSelectedInstagramPost}
                        preConnectionConfigurations={preConnectionConfigurations}
                        saveUploadQueue={saveUploadQueueCreativeTab}
                        adConnectionsAdded={adConnectionsAdded}
                        adConnectionsConfirmed={adConnectionsConfirmed}
                        showContinueButton={false}
                        saveConnectModalType={saveConnectModalType}
                        areTermsAccepted={areTermsAccepted}
                        setAreTermsAccepted={setAreTermsAccepted}
                        time={time}
                        startTimer={startTimer}
                        handleStartTimer={handleStartTimer}
                        getTosConfiguration={getTosConfiguration}
                      />
                    </TabPanel>
                    <SpendBudgetProvider
                      {...{ budgetValue }}
                      currentUser={currentUser}
                      step={selectedTab as string}
                      fbAccountId={selectedFBAdAccount?.id || null}
                      campaignType={GLOBAL_CAMPAIGN_TYPES.boost_instagram}
                    >
                      <TabPanel hidden={selectedTab !== BUDGET}>
                        <BudgetTab
                          updatedCurrency={updatedCurrency}
                          selectedTab={selectedTab}
                          budgetValue={budgetValue}
                          setBudgetValue={setBudgetValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          fbAdAccountId={
                            selectedFBAdAccount ? selectedFBAdAccount.id : null
                          }
                          fbAdAccountName={selectedFBAdAccount ? selectedFBAdAccount.name : null}
                          fbBusinessId={selectedFBBusinessManager}
                          brand={currentBrand!}
                          finishFlow={handleBudgetTabNextButton}
                          saveWhenNextButtonIsDisabled={
                            saveWhenBudgetTabButtonIsDisabled
                          }
                          showReviewButton={false}
                        />
                      </TabPanel>
                      <TabPanel hidden={selectedTab !== FINALIZATION}>
                        <Finalization
                          selectedCampaignType={selectedCampaignType}
                          primaryOrganization={primaryOrganization}
                          isBelowRecommendedBudget={isBelowRecommendedBudget}
                          draft={campaignDraftData?.id}
                          campaignName={campaignName}
                          brand={currentBrand}
                          goal={GOAL}
                          fbPage={selectedFB_page}
                          fbBusinessManager={selectedFBBusinessManager}
                          fbAdAccount={selectedFBAdAccount}
                          fbPixel={selectedFBPixel}
                          igPage={selectedInstaPage}
                          budget={budgetValue}
                          startDate={startDate}
                          endDate={endDate}
                          allowCampaignCreation={allowCampaignCreation}
                          setSelectedTab={setSelectedTab}
                          caption={caption}
                          cta={cta}
                          interestTargetingAudiences={interestTargetingAudiences}
                          setInterestTargetingAudiences={
                            setInterestTargetingAudiences
                          }
                          validatedAudiencesAlready={validatedAudiencesAlready}
                          setValidatedAudiences={setValidatedAudiences}
                          setShowTargetingView={setShowTargetingView}
                          fbSuggestions={fbSuggestions}
                          assets={{ all: uploadedImages }}
                          igMedia={igMedia}
                          addVisualSelected={addVisualSelected}
                          cancelSaving={cancelSavingDraft}
                          setTargets={setTargets}
                          handleSaveTargetingData={handleSaveTargetingData}
                          additionalPageAudiences={additionalPageAudiences}
                          customAudiences={customAudiences}
                        />
                      </TabPanel>
                    </SpendBudgetProvider>
                  </div>
                </>
              )}
            </Grid>
            {renderAdPreview()}
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default GrowInstagramEngagement
