import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  paper: { borderRadius: 12 },
  groupedHorizontal: {
    maxHeight: 36,
    border: "1px solid #8800FF",
    borderRight: "1px solid #8800FF",
    borderLeft: "1px solid #8800FF",
    "&:not(:first-child)": {
      border: "1px solid #8800FF",
      borderRight: "1px solid #8800FF",
      borderLeft: "1px solid #8800FF",
    },
    "&:first-child": {
      border: "1px solid #8800FF",
      borderRight: "1px solid #8800FF",
      borderLeft: "1px solid #8800FF",
    },
  },
  emptyClass: {},
  rootDialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down(791)]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down(361)]: {
        margin: 0,
      },
    },
    "& .MuiDialog-paperWidthMd.MuiDialog-paperScrollBody": {
      [theme.breakpoints.down(791)]: {
        maxWidth: "calc(100% - 16px)",
      },
      [theme.breakpoints.down(361)]: {
        maxWidth: "100%",
      },
    },
    "& .MuiDialog-paperFullWidth": {
      [theme.breakpoints.down(361)]: {
        maxWidth: "100%",
      },
    },
  },
}));

interface BenefitsContainerProps {
  marginTop?: string;
  marginLeft?: string;
}

interface BenefitLabelProps {
  fontWeight?: string;
}

interface StyledLabelProps {
  marginTop?: string;
  fontSize?: string;
  fontWeight?: number;
  color?: string;
  lineHeight?: string;
}

interface IconProps {
  widthStyle?: string;
  heightStyle?: string;
  borderRadius?: string;
}

interface DialogContentProps {
  flexDirection?: string;
}

interface LeftSectionProps {
  width?: string;
  height?: string;
}

interface RightSectionProps {
  width: string;
  padding: string;
}

export const DialogContent = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: DialogContentProps) => flexDirection};
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ width }: LeftSectionProps) => width};
  height: ${({ height }: LeftSectionProps) => height};
  padding: 40px 32px 40px 32px;
  background-color: rgba(136, 0, 255, 0.24);
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${({ width }: RightSectionProps) => width};
  padding: ${({ padding }: RightSectionProps) => padding};
`;

export const Icon = styled.img`
  width: ${({ widthStyle }: IconProps) => widthStyle};
  height: ${({ heightStyle }: IconProps) => heightStyle};
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
`;

export const Image = styled.img`
  width: ${({ widthStyle }: IconProps) => widthStyle};
  height: ${({ heightStyle }: IconProps) => heightStyle};
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
  max-width: ${({ widthStyle }: IconProps) => widthStyle};
  max-height: ${({ heightStyle }: IconProps) => heightStyle};
`;

export const DialogTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DialogTitle = styled.p`
  font-weight: 500;
  font-size: 32px;
`;

export const BenefitsMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f3f6;
  gap: 24px;
  padding: 32px 16px;
  border-radius: 8px;
`;

export const BenefitContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const BenefitIcon = styled.div``;

export const BenefitLabel = styled.p`
  font-family: 'DIN';
  font-style: normal;
  font-size: 18px;
  line-height: 17px;
  font-weight: ${({ fontWeight }: BenefitLabelProps) => fontWeight};
`;

export const BenefitListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
`;

export const Slide = styled.div`
  background-color: #000000;
  color: #ffffff;
  border-radius: 46px;
  min-height: 516px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.p`
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight};
  color: ${({ color }: StyledLabelProps) => color};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
`;

export const PlatformIconContainer = styled.div`
  display: flex;
  align-content: center;
  background-color: #323232;
  padding: 20px;
  width: 90px;
  height: 90px;
  border-radius: 26px;
`;

export const AvatarIconContainer = styled.div`
  position: relative;
  top: 0px;
  width: 121px;
  height: 50px;
  border-radius: 40px;
  margin: auto;
`;

export default useStyles;
