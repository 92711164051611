import { makeStyles } from '@material-ui/core'
import { SystemColors } from 'types/globalStyles'
import styled from 'styled-components'

export const useStyles = makeStyles(() => ({
  iconAccentColor: {
    '& path': {
      stroke: SystemColors.ACCENT_COLOR,
    },
  },
}))

export const StyledAccountsUl = styled.ul`
  height: 325px;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid ${SystemColors.DIVIDER_LINE};
`

export const StyledLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 16px;
  cursor: pointer;
  background-color: ${SystemColors.PAPER_ACCENT_CONTAINER};
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`

export const DisabledBannerDiv = styled.div`
  padding-left: 54px;
`
