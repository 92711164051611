import Snackbar, {
  SeverityType,
  SnackBarType,
} from "components/shareable/Snackbar";
import { useReducer, createContext, FunctionComponent, ReactNode } from "react";

export const SHOW_SNACKBAR = "SHOW_SNACK_BAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

type InitialState = {
  snackMessage: string;
  openSnack: boolean;
  options: { severity: SeverityType; type?: SnackBarType };
};

type Action =
  | { type: typeof CLOSE_SNACKBAR }
  | {
      type: typeof SHOW_SNACKBAR;
      message: string;
      options: { severity: SeverityType };
    };

const initialState: InitialState = {
  snackMessage: "",
  openSnack: false,
  options: { severity: "success" },
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackMessage: action.message,
        openSnack: true,
        options: action.options,
      };
    case CLOSE_SNACKBAR:
      return { ...state, openSnack: false };
    default:
      throw new Error("Unknown action");
  }
};

const showSnackbar = (
  _message: string,
  _options: { severity: SeverityType; linearProgress?: boolean }
) => {};

export const AlertContext = createContext({
  showSnackbar,
});

interface Props {
  children: ReactNode;
}

const Alert: FunctionComponent<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showSnackbar = (
    message: string,
    options: {
      severity: SeverityType;
      type?: SnackBarType;
      linearProgress?: boolean;
    }
  ) => dispatch({ type: SHOW_SNACKBAR, message, options });

  const closeSnackbar = () => dispatch({ type: CLOSE_SNACKBAR });

  const { snackMessage, openSnack, options } = state;

  const defaultContext = {
    ...state,
    showSnackbar,
    closeSnackbar,
    options,
  };

  return (
    <AlertContext.Provider value={defaultContext}>
      <Snackbar
        open={openSnack}
        message={snackMessage}
        options={options}
        onClose={closeSnackbar}
      />
      {children}
    </AlertContext.Provider>
  );
};

export default Alert;
