import { FunctionComponent, ReactNode } from 'react';
import Intercom from "helpers/Intercom";
import { SymphonyTooltip } from 'styles/shared';
import QuestionMarkIcon from "assets/images/question-mark.svg";
import { CardTitleContiner } from './styles';

export enum Article {
  ForeverSaves = 'FOREVER_SAVES',
  Ambassador = "AMBASSADOR",
  FbIgConnector = "FB_IG_CONNECTOR",
  FbPageAccess = "FB_PAGE_ACCESS",
  PresaveUPC = "PRESAVE_UPC",
  IgStats = "IG_STATS",
  IgBusinessAccount = "IG_BUSINESS_ACCOUNT",
  Playisting = "PLAYLISTING",
  PresaveBoost = "PRESAVE_BOOST"
}

interface ArticleTitleProps {
  children: ReactNode,
  article: Article,
  toolTipText: string,
}

const ArticleTitle: FunctionComponent<ArticleTitleProps> = ({
  children,
  article,
  toolTipText
}: ArticleTitleProps) => {

  const openArticle = () => {
    switch(article) {
      case Article.Ambassador: Intercom.openAmbassadorProgramArticle(); break;
      case Article.FbIgConnector: Intercom.openFacebookInstagramConnectorArticle(); break;
      case Article.FbPageAccess: Intercom.openFacebookPageAccessArticle(); break;
      case Article.PresaveUPC: Intercom.openFindPresaveUPCArticle(); break;
      case Article.ForeverSaves: Intercom.openForeverSavesArticle(); break;
      case Article.IgStats: Intercom.openIGPerformanceStatsArticle(); break;
      case Article.IgBusinessAccount: Intercom.openInstagramBusinessAccountArticle(); break;
      case Article.Playisting: Intercom.openPlaylistingArticle(); break;
      case Article.PresaveBoost: Intercom.openPresaveBoostArticle(); break;
    }
  }

  return (
    <CardTitleContiner>
      {children}
      <button className="ml-1.5 w-6 h-6"
        onClick={openArticle}>
        <SymphonyTooltip 
          className="block w-6 h-6 cursor-pointer"
          title={toolTipText}
          arrow>
          <img
            className="w-6 h-6"
            alt="question-mark-icon.svg"
            src={QuestionMarkIcon}
          />
        </SymphonyTooltip>
      </button>
    </CardTitleContiner>
  )
}

export default ArticleTitle;