import { CampaignMapping } from "types/global";
import Axios from 'helpers/Interceptor';
import Logger from 'Logger';
import { LocationDetail } from '.';
import { FBLocation } from '../../SongAds/TargetingView/reducer';
import * as Sentry from "@sentry/react";

export const getMarketDetail = async (args: {
  campaignId: number,
  access_token: string,
  market?: CampaignMapping,
}) => {
  const { campaignId, access_token, market } = args;
  try {
    const url = `campaign/${campaignId}/markets/performance-stats`;
    let response = await Axios.put(url, {
      marketId: market?.fbCampaign.id,
      access_token,
    });
    return response.data.data;
  } catch (e) {
    Logger.error('Error getting the markets performance data');
  }
};

export const getLocationDetails = async (args: {
  campaignId: number,
  access_token: string,
  marketId?: string,
}) => {
  const { campaignId, access_token, marketId } = args;
  try {
    const url = `campaign/${campaignId}/markets/locations-stats`;
    let response = await Axios.put(url, {
      marketId,
      access_token,
    });
    return response.data.data;
  } catch (error) {
    Logger.error('Error getting the locations data of market');
  }
};

export const pauseMarket = async (args: {
  campaignId: number,
  access_token: string,
  market?: CampaignMapping,
}) => {
  const { campaignId, access_token, market } = args;
  const marketId = market?.fbCampaign?.id;
  if (!marketId) return;
  try {
    const url = `campaign/${campaignId}/${marketId}/pause`;
    const pause = market.fbCampaign.fields.status !== 'PAUSED';
    let response = await Axios.put(url, {
      pause,
      access_token,
    });
    return response.data.data;
  } catch (error) {
    Sentry.captureException(`Error pausing/resuming location targeting in campaign ${campaignId} -> market: ${marketId}: ${error}`);
    Logger.error('Error pausing selected market for the campaign');
  }
};

export const pauseLocation = async (args: {
  location: LocationDetail,
  campaignId: number,
  access_token: string,
  marketId?: string,
}) => {
  const { location, campaignId, access_token, marketId } = args;
  try {
    const url = `campaign/${campaignId}/markets/${marketId}/pause-location`;
    const pause = location.status !== 'paused';
    const response = await Axios.put(url, {
      target_location: location.id,
      locationType: location.type,
      access_token,
      pause,
    });
    return response;
  } catch (error) {
    Sentry.captureException(`Error pausing/resuming location in campaign ${campaignId} -> market: ${marketId}: ${error}`);
    Logger.error('Error pausing selected market for the campaign');
  }
};

export const addLocationsToMarket = async (args: {
  campaignId: number,
  locations: FBLocation[],
  access_token: string,
  market?: CampaignMapping,
}) => {
  const { campaignId, locations, access_token, market } = args;
  const marketId = market?.fbCampaign?.id;
  if (!marketId) return;
  try {
    const url = `campaign/${campaignId}/markets/${marketId}/add-locations`;
    const response = await Axios.put(url, {
      locations,
      access_token,
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(`Error adding location to market: campaign ${campaignId} -> market: ${marketId}: ${error}`);
    Logger.error('Error adding locations to market');
  }
};

export const validateLocations = async (args: {
  locations: FBLocation[],
  campaignId: number,
  marketId?: string,
}) => {
  const { locations, campaignId, marketId } = args;
  if (!marketId) return;
  try {
    const url = `campaign/${campaignId}/markets/${marketId}/validate-locations`;
    const response = await Axios.put(url, { locations });
    return response.data.data;
  } catch (error) {
    Logger.error('Error validating locations');
  }
};
