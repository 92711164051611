import { FunctionComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import useStyles, {
  StyledLabel,
  EmailTemplatePreview,
  Container,
  SubjectContainer,
} from "../../styles";
import CloseIcon from "components/shareable/CloseIcon";

interface Props {
  open: boolean;
  template?: string;
  subject?: string;
  handleClose: () => void;
}

const EmailTemplateDialog: FunctionComponent<Props> = ({
  open,
  template,
  subject,
  handleClose,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      {...{ open }}
      classes={{ root: classes.root, paper: classes.paper }}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Container>
        <SubjectContainer>
          <Grid className={classes.closeIconContainer} onClick={handleClose}>
            <CloseIcon />
          </Grid>
          <Grid>
            <StyledLabel fontSize={18} fontWeight={400}>
              Subject:{subject}
            </StyledLabel>
          </Grid>
        </SubjectContainer>
        <DialogContent className={classes.dialogContent}>
          {template && (
            <EmailTemplatePreview
              dangerouslySetInnerHTML={{ __html: template }}
            />
          )}
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default EmailTemplateDialog;
