import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  svgGreen: {
    transform: "rotate(270deg)",
    '& path': {
      stroke: '#07C806',
    }
  },
}));

interface MainContainerProps {
  flexDirection?: string,
  justifyContent?: string,
  alignItems?: string,
  gap?: string,
}

export const MainContainer = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px 24px 24px 16px;
  display: flex;
  flex-direction: ${({ flexDirection }: MainContainerProps) => flexDirection || "row"};;
  justify-content: ${({ justifyContent }: MainContainerProps) => justifyContent || "flex-start"};;
  align-items: ${({ alignItems }: MainContainerProps) => alignItems || "center"};;
  gap: ${({ gap }: MainContainerProps) => gap || "8px"};
`;

export const TotalNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

export const RoseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(136, 0, 255, 0.16);
  border-radius: 12px;
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  white-space: nowrap;
`;

export const NumberText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
  white-space: nowrap;
`;

export const StatusIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ArrowGreenUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: rgba(7, 200, 6, 0.16);
  border-radius: 48px;
`;

export const PercentageText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #07C806;
  white-space: nowrap;
`;

export const CardTitleContiner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  vertical-align: middle;
`;
