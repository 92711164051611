import { useState } from 'react';

export interface UseDropDownData {
  options: any[];
  selectedValue: any | null;
  setSelectedValue: (value: any | null) => void;
  updateOptions: (options: any[]) => void;
}

interface Props {
  values: any[];
  initialValue?: number | null;
}

const useDropDown = ({ values, initialValue = 0 }: Props) => {
  const [options, setOptions] = useState<any[]>(values);
  const [selectedValue, setSelectedValue] = useState<string | null>(initialValue ? values[initialValue] : null);
  const updateOptions = (options: any[]) => setOptions(options);
  return {
    options,
    selectedValue,
    setSelectedValue,
    updateOptions,
  };
};

export default useDropDown;
