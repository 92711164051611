import styled from "styled-components";

interface ButtonContainerProps {
  width: string;
  height: string;
}

export const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  width: ${({ width }: ButtonContainerProps) => width};
  height: ${({ height }: ButtonContainerProps) => height};
  background: none;
  :disabled {
    cursor: not-allowed;
    background: none;
    border: none;
  }
`;