import {
  SetStateAction,
  Dispatch,
  FunctionComponent,
  MouseEvent,
  useState,
  useEffect,
} from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router";
import { CurrentBrand, UserData } from "types/global";
import ProfileIcon from "assets/images/profileIcon.svg";
import DropdownArrowIcon from "assets/images/chevron-down-arrow-icon.svg";
import { AvatarUserStatus } from "components/shareable/AvatarUserStatus";
import GemIcon from "assets/images/gem-icon-navbar.svg";
import useStyles, {
  MainContainer,
  StyledLabel,
  Container,
} from "./styles";
import CustomAvatar from "./Components/CustomAvatar";
import { checkIfIsProTier } from "utils";
import { isAdmin } from "helpers/admin";
import SearchSelector from "./SearchSelector";
import { Search } from "@material-ui/icons";
import Axios from "helpers/Interceptor";
import styled from "styled-components";

export const Icon = styled.img`
  margin-left: auto;
  width: 12px;
`;

interface Props {
  loadedBrands: CurrentBrand[] | null;
  currentBrand?: CurrentBrand | null;
  setCurrentBrand: Dispatch<SetStateAction<CurrentBrand>>;
  smallSidebar?: boolean;
  user: UserData | null
}

const sortAlphabetically = (brands: CurrentBrand[]) =>
  brands.sort(
    (a: CurrentBrand, b: CurrentBrand) =>
      a.name
        ?.toLowerCase()
        .localeCompare(b.name?.toLowerCase() as string) as number
  );

export const ProfileDropdown: FunctionComponent<Props> = ({
  currentBrand,
  loadedBrands,
  setCurrentBrand,
  smallSidebar,
  user,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [brandImage, setBrandImage] = useState<string | null>(currentBrand?.image || null);
  const [brandName, setBrandName] = useState<string | null>(currentBrand?.name || null);

  const opacity = currentBrand ? 1 : 0;
  const isProUser = checkIfIsProTier(currentBrand);

  useEffect(() => {
    if (currentBrand) {
      setBrandImage(currentBrand.image)
      setBrandName(currentBrand.name)
    }
  }, [currentBrand])

  const handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const [openAdminSelector, setOpenAdminSelector] = useState<boolean>(false)

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleClickAddArtist = () => history.push("/add-artist");

  const userIsAdmin = user ? isAdmin(user) : false;

  const getBrandData = async (brandId: number) => {
    try {
      const response = await Axios.get(`/brand/${brandId}`)
      return response.data.data as CurrentBrand
    } catch (error) {
      return null
    }
  }

  function returnBrandsList() {

    const mappedBrands = sortAlphabetically(loadedBrands!).map(
      (brand: CurrentBrand, idx: number) => {
        const { name, image } = brand;
        const isProUser = checkIfIsProTier(brand);

        const handleClickOption = async () => {
          const updatedBrandData = await getBrandData(brand.id)
          setCurrentBrand(updatedBrandData || brand);
          handleCloseDropdown();
        };

        return (
          <MenuItem
            className={classes.menuItem}
            key={`${name}-${idx}`}
            onClick={handleClickOption}
          >
            <Container display="flex" alignItems="center" width="100%">
              <Container>
                <CustomAvatar
                  {...{ isProUser }}
                  avatar={image ? image : ProfileIcon}
                />
              </Container>
              <StyledLabel

                padding="0px 0px 0px 16px"
                fontSize={16}
                fontWeight={400}
              >
                {name}
              </StyledLabel>
              {isProUser && (
                <StyledLabel
                  marginLeft="auto"
                  paddingLeft="16px"
                  color="#E08507"
                  fontSize={12}
                  fontWeight={400}
                >
                  PRO ACCOUNT
                </StyledLabel>
              )}
            </Container>
          </MenuItem>
        );
      }
    )

    return (
      <div>
        {userIsAdmin ? (
          <MenuItem
            className={classes.menuItem}
            key={`search`}
            onClick={() => setOpenAdminSelector(true)}
          >
            <Container margin="10px auto" display="flex" alignItems="center" width="100%">
              <Container>
                <Search />
              </Container>
              <Container display="flex" flexDirection="column" alignItems="flex-start" width="100%">

                <StyledLabel

                  padding="0px 0px 0px 16px"
                  fontSize={16}
                  fontWeight={400}
                >
                  Open Admin Search
                </StyledLabel>
                {userIsAdmin && (
                  <StyledLabel
                    paddingLeft="16px"
                    color="#E08507"
                    fontSize={12}
                    fontWeight={400}
                  >
                    Search for any artist as an admin.
                  </StyledLabel>
                )}
              </Container>
            </Container>
          </MenuItem>
        ) : null}
        {mappedBrands}
      </div>
    )
  }
  return (
    <MainContainer {...{ opacity }}>
      <Button
        classes={{ text: classes.buttonText }}
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenDropdown}
      >
        <AvatarUserStatus
          smallSidebar={smallSidebar ? true : false}
          withoutWhiteBorder
          isProUser={isProUser}
          showProIcon={isProUser}
          brandName={brandName || ""}
          mainContainerProps={{ gap: "8px" }}
          proStatusContainerProps={{ gap: "4px", padding: "0 0 0 2px" }}
          avatarContainerProps={{ width: "30%" }}
          userNameTextProps={{
            color: "white",
            fontSize: 16,
            textAlign: "left",
            padding: "0 4px 0 4px",
            width: "120px",
            height: "56px"
          }}
          proTextProps={{ color: "#F5BA23", fontSize: 10 }}
          avatar={brandImage ? brandImage : ProfileIcon}
        />
        <Icon
          className={`${smallSidebar ? 'invisible' : 'visible'}`}

          alt="dropdown-arrow.svg" src={DropdownArrowIcon} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
      >
        {loadedBrands ?
          returnBrandsList()
          : null}
        <MenuItem key="add artist" className={classes.menuItem} onClick={handleClickAddArtist}>
          <Container display="flex" alignItems="center" width="100%">
            <StyledLabel
              padding="4px 0px 4px 16px"
              fontSize={16}
              fontWeight={400}
            >
              + Add New Profile
            </StyledLabel>
          </Container>
        </MenuItem>
      </Menu>
      {userIsAdmin && <SearchSelector
        open={openAdminSelector}
        closeModal={() => {
          setOpenAdminSelector(false)
          handleCloseDropdown()
        }} />}
    </MainContainer>
  );
};

export default ProfileDropdown;
