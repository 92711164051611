import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

export const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${SystemColors.DIVIDER_LINE};
  padding: 16px;
  width: 100%;
  border-radius: 12px;
`;

export const FieldFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
