import { InputAdornment, TextField } from "@material-ui/core";
import { ArtistInputContainer, BackArrowImage, BackButtonContainer, BackButtonText, BackToDashboardContainer, EnterArtistContainer, EnterArtistLabel, FlexContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, PlatformLogoContainer, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OnboardingAccountTypes } from "../..";


export default function AccountType(props: {
    responsiveView: boolean,
    showBackButton: boolean,
    handleGoBack: () => void,
    titleText: string,
    descriptionText: string,
    setCurrentAccountType: (accountType: OnboardingAccountTypes) => void,
}) {

    const {
        responsiveView,
        showBackButton,
        handleGoBack,
        titleText,
        descriptionText,
        setCurrentAccountType
    } = props;

    const options = [
        {
            image: CreatorAccountTypeIcon,
            title: "I'm a creator myself",
            description: "Perfect for individual creators, artists, influencers, and individuals who want to make a personal account.",
            buttonText: "Get Started",
            onClick: () => setCurrentAccountType(OnboardingAccountTypes.CREATOR)
        },
        {
            image: TeamAccountTypeIcon,
            title: "I work with one or more creators",
            description: "Perfect for agencies, labels, managers, and organizations that work with multiple artists and creators.",
            buttonText: "Get Started",
            onClick: () => setCurrentAccountType(OnboardingAccountTypes.TEAM)
        }

    ]

    return (
        <>
            <LeftColumn width={responsiveView ? "100%" : "50%"} isMobile={responsiveView}>
                {showBackButton && responsiveView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                        <BackButtonText>Back to dashboard</BackButtonText>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={responsiveView ? "8px" : "18px"}>
                    {showBackButton && !responsiveView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                            <BackButtonText>Back to dashboard</BackButtonText>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>{titleText}</MainTitle>
                    <SubTitle>{descriptionText}</SubTitle>
                </Titles>
                <EnterArtistContainer>
                    <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>
                        What kind of account describes you best?
                    </Headline3>
                    <Container display="flex" flexDirection={responsiveView ? "column" : "row"} gap="24px" 
                    style={{
                        overflow: 'visible'
                    }}>
                        {options.map((option, index) => {
                            return (
                                <OptionCard
                                    key={index}
                                    imageSrc={option.image}
                                    title={option.title}
                                    description={option.description}
                                    buttonText={option.buttonText}
                                    onClick={option.onClick}
                                    mobileView={responsiveView} />
                            )

                        })}

                    </Container>

                </EnterArtistContainer>

            </LeftColumn>
            <RightColumn display={responsiveView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}