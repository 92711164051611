import millify from "millify";
import ReactCountryFlag from "react-country-flag";
import RowItem from "components/shareable/RowItem";
import { capitalizeFirstLetter } from "helpers/General";
import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import { Button, Switch, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { Component, Fragment, PureComponent, useRef, useState } from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GraphTooltip } from "pages/post-auth/DashboardModule/components/Graphs";
import { AxisInterval } from "recharts/types/util/types";
// import { fixCountryNameForUnited, performanceTextForDemos, performanceTextForLocations } from "./helpers";
import { GeneralTextButton } from "components/shareable/CommonComponent/PurpleButton";
import { BarChartOutlined } from "@material-ui/icons";
import { TableRows, TableRowsOutlined } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { performanceTextForDemos, performanceTextForLocations } from "../../CampaignDetails/helpers";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import useStyles, { CustomTab, CustomTabs } from "./styles";
import { CurrencyCodesType } from "types/global";
import getSymbolFromCurrency from "currency-symbol-map";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";

interface GeographicLocationDetails {
    id: string | number | undefined;
    name: string | undefined;
    expandedName: string | undefined;
    countryCode: string | null;
    type: string;
    country: string | null;
    views: number;
    cpv: number;
    spend: number;

}
interface DemographicsStatsProps {
    // isPro: boolean,
    // loading: boolean,
    // shown: boolean,
    marketsType: 'global' | 'english' | 'custom',
    currency: CurrencyCodesType,
    demographicBreakdown: {
        overall: {
            gender: string;
            age_range: string;
            avg_cpv: number;
            sum_cost: number;
            sum_views: number;
        }[]
    },
    geographicBreakdown: {
        cities: {
            city: string;
            country: string;
            avg_cpv: number;
            sum_cost: number;
            sum_views: number;
            country_code: string;
        }[],
        countries: {
            country: string;
            avg_cpv: number;
            sum_cost: number;
            sum_views: number;
            country_code: string;
        }[],

    }
    // showMarkets?: () => void,
    // campaignDetails: {
    //     startDate: string;
    //     endDate: string;
    //     platform_details: any;
    //     ad_account: string;
    // };
    // geographicBreakdown: {
    //     countries: any[],
    //     regions: any[],
    //     dmas: any[]
    // };
    // demographicBreakdown: any
}



export default function DemographicsStats(props: DemographicsStatsProps) {

    const {
        geographicBreakdown,
        demographicBreakdown,
        // isPro,
        // shown,
        // loading,
        // showMarkets
        marketsType,
        currency,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const [graphsView, setGraphsView] = useState<Record<'cities' | 'countries', 'bar' | 'table'>>({
        cities: 'bar',
        countries: 'bar',
    })

    const currencySymbol = getSymbolFromCurrency(currency)

    var subtitleSentences: any = useRef({
        countries: null,
        cities: null,
        genders: null,
    })
    // const hideBannerImage = useMediaQuery(theme.breakpoints.down(701))

    const showResponsiveGraphView = useMediaQuery(theme.breakpoints.down(575));

    function renderLocationData(key: 'cities' | 'countries', title: string, subtitle: string) {
        const data = geographicBreakdown[key]

        if (!data) return null

        const dataMappings: Record<string, any> = {

        }

        const processedData: GeographicLocationDetails[] = data.filter((item: {
            avg_cpv: number;
            sum_cost: number;
            sum_views: number;
            city?: string;
            country: string;

        }) => {
            return item.city ? item.city !== 'Unknown' : true
        })
            .map((item: {
                avg_cpv: number;
                sum_cost: number;
                sum_views: number;
                city?: string;
                country: string;
                country_code: string | null;
            }) => {

                let name
                let countryCode
                let countryName
                let expandedName
                switch (key) {
                    case 'cities':
                        name = item.city
                        expandedName = `${item.city}, ${item.country}`
                        countryName = item.country
                        countryCode = item.country_code
                        break;
                    case 'countries':
                        name = item.country
                        expandedName = item.country
                        countryName = item.country
                        countryCode = item.country_code

                        break

                    default:
                        break;
                }

                if (name) {
                    dataMappings[name] = {
                        type: key,
                        expandedName: expandedName,
                        countryCode: countryCode ? countryCode : null,
                        countryName: countryName ? countryName : null,
                        views: item.sum_views,
                        cpv: item.avg_cpv,
                        spend: item.sum_cost
                    }

                    if (name === 'United States') {
                    }

                }
                return {
                    id: name,
                    name: name,
                    expandedName: expandedName,
                    countryCode: countryCode ? countryCode : null,
                    type: key,
                    country: countryName ? countryName : null,
                    views: item.sum_views,
                    cpv: item.avg_cpv,
                    spend: item.sum_cost
                }
            })


        function CustomizedAxisTick(props: any) {
            const { x,
                y,

                payload } = props;


            const labelWidth = 120

            const item = dataMappings[payload.value]

            var itemName = item.type === 'countries' && showResponsiveGraphView ? item.countryCode : payload.value

            return (
                <g
                    transform={`translate(${x - (labelWidth / 2)},${y}) ${showResponsiveGraphView ? `rotate(0 ${100 / 2} ${labelWidth / 2})` : ''}`}
                >

                    <foreignObject x={0} y={0} width={labelWidth} height={100} className="overflow-visible">
                        {showResponsiveGraphView ? (
                            <div className="w-full text-center overflow-ellipses">

                                {item.countryCode && (<div >
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '0.9em'
                                        }}
                                        countryCode={item.countryCode} />
                                </div>)}
                                <div className="mt-0.5 text-sm" >{itemName}</div>
                            </div>
                        ) : (
                            <div className="w-full text-center overflow-ellipses">
                                {item.countryCode && (<div >
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '1.1em'
                                        }}
                                        countryCode={item.countryCode} />
                                </div>)}
                                <div className="mt-0.5 text-base" >{itemName}</div>
                                <div className="text-sm text-gray-500">{millify(item.views)} views</div>
                                <div className="text-sm text-gray-500">{currencySymbol}{(item.cpv).toLocaleString('en', {
                                    type: 'currency',
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2
                                })} CPV</div>
                            </div>)}

                    </foreignObject>
                </g>
            );
        }


        let barInterval: number | AxisInterval = 5
        if (showResponsiveGraphView) {
            if (key === 'countries') {
                barInterval = 0
            } else {
                barInterval = 1
            }
        } else {
            barInterval = 0
        }

        let emptyState = false
        if (processedData.length === 0) emptyState = true

        let firstLocation: any
        let secondLocation: any
        let subtitleSentence: string


        if (processedData.length > 2) {
            firstLocation = processedData[0].name
            secondLocation = processedData[1].name
            subtitleSentence = performanceTextForLocations([firstLocation, secondLocation], 'view');
            // subtitleSentence = "test"
        } else if (processedData.length === 0) {
            firstLocation = null
            secondLocation = null
            subtitleSentence = `We're still waiting for data to come in for top ${key}.`
        } else {
            // firstLocation = fixCountryNameForUnited(processedData[0].name!)
            subtitleSentence = `Top views came from ${firstLocation}.`

        }


        if (!subtitleSentences.current[key]) {
            subtitleSentences.current[key] = subtitleSentence
        }

        function renderData() {
            if (graphsView[key] === 'bar') {
                return (<div>
                    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 300 : 300} minHeight="100px">
                        <BarChart
                            width={500}
                            height={300}
                            margin={{
                                bottom: showResponsiveGraphView ? 20 : 80
                            }}

                            data={processedData?.slice(0, 5)}


                        >
                            <XAxis type="category" dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                interval={barInterval}

                                tick={<CustomizedAxisTick />}

                            />

                            <YAxis
                                hide={true}

                                domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.02)]}

                            />


                            <Tooltip
                                cursor={{ fill: '#8800FF', fillOpacity: 0.1 }}
                                content={({ active, payload, label }) => {

                                    if (active && payload && payload.length) {
                                        let mainData: any = payload[0]
                                        if (mainData && mainData.payload) {
                                            mainData = mainData.payload
                                        } else {
                                            return null
                                        }


                                        const cpv = mainData.cpv!.toLocaleString('en', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            type: 'currency'
                                        })
                                        const views = mainData.views!.toLocaleString('en', {
                                            maximumFractionDigits: 0
                                        })
                                        const spend = mainData.spend!.toLocaleString('en', {
                                            maximumFractionDigits: 0,
                                            type: 'currency'
                                        })

                                        var mainLabel = label
                                        if (key === 'cities') {
                                            mainLabel = `${mainLabel}, ${mainData.country}`
                                        }
                                        return (
                                            <GraphTooltip
                                                title={mainLabel}
                                                value={(
                                                    <div>

                                                        <p className="font-semibold text-md">
                                                            {views} Views</p>
                                                        <p className="font-semibold text-md">
                                                            {currencySymbol}{cpv} CPV</p>
                                                        <p className="font-semibold text-md">
                                                            {currencySymbol}{spend} spent</p>

                                                    </div>)}
                                            />
                                        );
                                    }

                                    return null;
                                }}

                            />


                            <Bar dataKey="views"
                                fill="#C698FF"
                                radius={6}

                            >

                            </Bar>

                        </BarChart>
                    </ResponsiveContainer>
                </div>)
            } else if (graphsView[key] === 'table') {

                const getColumnDefinition = () => {
                    const defaultColDef: GridColDef = {
                        field: "field",
                        headerName: "column",
                        type: "string",
                        sortable: true,
                        editable: false,
                        disableColumnMenu: true,
                        align: "center",
                        headerAlign: "center",
                        minWidth: 120,
                        flex: 1,
                        width: 120,
                    }
                    return [
                        {
                            ...defaultColDef,
                            field: "name",
                            headerName: "Name",
                            align: 'left',
                            headerAlign: 'left',
                            minWidth: showResponsiveGraphView ? 150 : (key === 'cities' ? 200 : 120),

                            renderCell: (params) => {
                                const formattedRow = params.row as GeographicLocationDetails;
                                return (<div className="flex items-center justify-center">{formattedRow.countryCode && (<div >
                                    <ReactCountryFlag
                                        style={{
                                            fontSize: '1.2em'
                                        }}
                                        countryCode={formattedRow.countryCode} />
                                </div>)}<p className="ml-2">{formattedRow.expandedName}</p></div>)
                            },
                            valueGetter: (params) => params.row?.name
                        },
                        {
                            ...defaultColDef,
                            field: "views",
                            headerName: "Views",
                            renderCell: (params) => {
                                const formattedRow = params.row as GeographicLocationDetails;
                                const formatted = formattedRow.views.toLocaleString('en')

                                return (<p>{formatted}</p>)
                            },
                            valueGetter: (params) => params.row?.views
                        },
                        {
                            ...defaultColDef,
                            field: "spend",
                            headerName: "Spend",
                            renderCell: (params) => {
                                const formattedRow = params.row as GeographicLocationDetails;
                                const formatted = formattedRow.spend.toLocaleString('en', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })


                                return (<p>{currencySymbol}{formatted}</p>)
                            },
                            valueGetter: (params) => params.row?.spend
                        },

                        {
                            ...defaultColDef,
                            field: "cpv",
                            headerName: "Cost-per-View",
                            renderCell: (params) => {
                                const formattedRow = params.row as GeographicLocationDetails;
                                const formatted = formattedRow.cpv.toLocaleString('en', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })


                                return (<p>{currencySymbol}{formatted}</p>)
                            },
                            valueGetter: (params) => params.row?.cpv
                        },
                    ] as GridColDef[];
                }

                return (
                    <div className="max-h-82 overflow-auto">
                        <DataGridPro
                            rowSelection={false}
                            // initialState={{
                            //     sorting: {
                            //         sortModel: [{ field: 'clicks', sort: 'desc' }],
                            //     },
                            // }}
                            // treeData
                            // loading={false}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 5 } },
                                sorting: {
                                    sortModel: [{ field: 'views', sort: 'desc' }],
                                }
                            }}
                            pageSizeOptions={[]}

                            columns={getColumnDefinition()}
                            rows={processedData}
                            autoHeight
                            pagination={true}
                            classes={{ root: classes.datagridRoot }}
                        />

                    </div>
                )
            }
        }

        return (
            <div className="border-b py-7">
                <div className="flex justify-between items-center">
                    <div className="lg:px-3 pb-5 mt-2">
                        <p className="text-lg text-gray-500 flex flex-row gap-1 items-center">{title}<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                        <p className="text-xl ">{subtitleSentences.current[key]}</p>

                    </div>
                    {!emptyState && !showResponsiveGraphView && (<div className="flex-shrink-0">
                        <CustomTabs
                            value={graphsView[key]}
                            onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
                                const mappedItems = ['bar', 'table']
                                setGraphsView({
                                    ...graphsView,
                                    [key]: newValue
                                })
                            }}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon tabs example"
                        >
                            <CustomTab value={'bar'} icon={<BarChartOutlined />} aria-label="phone" />
                            <CustomTab value={'table'} icon={<TableRows />} aria-label="favorite" />
                        </CustomTabs>
                        {/* <GeneralTextButton
                            onClick={() => {
                                setGraphsView({
                                    ...graphsView,
                                    [key]: 'bar'
                                })
                            }}><BarChartOutlined /></GeneralTextButton>
                        <GeneralTextButton
                            onClick={() => {
                                setGraphsView({
                                    ...graphsView,
                                    [key]: 'table'
                                })
                            }}><TableRows /></GeneralTextButton> */}
                    </div>)}
                </div>
                {!emptyState && renderData()}


            </div>
        )
    }


    function renderDemoData() {

        const data = demographicBreakdown.overall

        if (!data) return null

        const dataMappings: Record<string, any> = {

        }

        const processedData = data
            .map((item: {
                age_range: string,
                gender: string,
                avg_cpv: number;
                sum_cost: number;
                sum_views: number;
            }) => {

                var capitalizedGender = capitalizeFirstLetter(item.gender)
                const combined = `Age ${item.age_range} / ${capitalizedGender}`

                dataMappings[combined] = {
                    age: item.age_range,
                    gender: capitalizedGender,
                    name: combined,
                    views: item.sum_views,
                    cpv: item.avg_cpv,
                    spend: item.sum_cost
                }


                return {
                    age: item.age_range,
                    gender: capitalizedGender,
                    name: combined,
                    views: item.sum_views,
                    value: item.sum_views,

                    cpv: item.avg_cpv,
                    spend: item.sum_cost
                }
            })
            .slice(0, 5)


        function CustomizedDemoAxisTick(props: any) {
            const { x,
                y,
                height, width,
                payload } = props;


            const labelWidth = 120

            const item = dataMappings[payload.value]

            return (

                <text x={x} y={y} height={height}
                    type="category" orientation={"left"} className="recharts-text recharts-layer recharts-cartesian-axis-tick-value"
                    textAnchor="end"
                    style={{
                        fontSize: '0.95rem'
                    }}>
                    <tspan x={x} dy="-0.145em">{item.age}</tspan>
                    <tspan x={x} dy="1.3em">{item.gender}</tspan>
                </text>

            );
        }
        const renderCustomizedLabel = (props: any) => {
            const { x,
                height, value,
                offset,
                width,
                index } = props;
            const radius = 10;
            const yValue = (index * 60) + 30
            var rectanglesBox = document.querySelectorAll('#barChart .recharts-bar-rectangles')
            let boxWidth = x
            if (rectanglesBox && rectanglesBox[0]) {
                boxWidth = rectanglesBox[0].getBoundingClientRect().width
            }


            // adjust boxwidth based on label size
            //  0 - 10,000 (9k)
            //  10 - 100k (99k)
            if (value < 100) {
            } if (value < 1000) {

            } else if (value < 10000) {
                boxWidth -= 6

            } else if (value < 100000) {
                boxWidth -= 8
            } else if (value < 1000000) {
                boxWidth -= 10
            } else if (value >= 1000000) {
                boxWidth -= 12
            }
            return showResponsiveGraphView ? (
                <text y={yValue} height={height}
                    offset={offset}
                    style={{
                        fontSize: '0.8rem'
                    }}>

                    <tspan x={x - 25} dx={boxWidth} dy="-0.145em " textAnchor="middle"
                    >{millify(value)}</tspan>
                    <tspan x={x - 25} dx={boxWidth} dy="1.2em" textAnchor="middle"
                    >views</tspan>
                </text>

            ) : (
                <text x={boxWidth} y={yValue} height={height}
                    offset={offset}
                    textAnchor="start">

                    <tspan dy="0.355em" textAnchor="start">{millify(value)} views</tspan>
                </text>
            )
        };

        let emptyState = false
        if (processedData.length < 2) emptyState = true

        let firstSet: {
            age: string,
            gender: string
        }
        let secondSet: {
            age: string,
            gender: string
        }
        let subtitleSentence: string


        if (!emptyState) {
            firstSet = {
                age: processedData[0].age,
                gender: processedData[0].gender
            }
            secondSet = {
                age: processedData[1].age,
                gender: processedData[1].gender
            }
            subtitleSentence = performanceTextForDemos([firstSet, secondSet], 'view')
            // subtitleSentence = 'test'

        } else {

            subtitleSentence = "We're still waiting for data to come in."
        }

        if (!subtitleSentences.current['genders']) {
            subtitleSentences.current['genders'] = subtitleSentence
        }

        return (
            <div className="border-b py-7">
                <div className="lg:px-3 pb-5 mt-2">
                    <p className="text-lg text-gray-500 flex flex-row gap-1 items-center">{"Top Demographics by Views"}<UpgradeToProChip type="gem" color="purple" height={18} width={18} /></p>
                    <p className="text-xl ">{subtitleSentences.current['genders']}</p>

                </div>
                {!emptyState && (<div>
                    <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 300 : 300} minHeight="100px">
                        <BarChart
                            id="barChart"
                            width={500}
                            height={300}
                            // margin={{
                            //     bottom: 80
                            // }}
                            margin={{
                                left: showResponsiveGraphView ? 0 : 20
                            }}
                            layout="vertical"
                            data={processedData}


                        >

                            <XAxis type="number" hide={true}
                                domain={[(dataMin: any) => (0), (dataMax: any) => (dataMax * 1.3)]}

                            />



                            <YAxis type="category" dataKey="name" axisLine={false}
                                tickLine={false}
                                minTickGap={0}
                                tickMargin={10}

                                tick={<CustomizedDemoAxisTick />}
                            />

                            <Tooltip
                                cursor={{ fill: '#8800FF', fillOpacity: 0.1 }}
                                content={({ active, payload, label }) => {

                                    if (active && payload && payload.length) {
                                        let mainData: any = payload[0]
                                        if (mainData && mainData.payload) {
                                            mainData = mainData.payload
                                        } else {
                                            return null
                                        }


                                        const cpv = mainData.cpv!.toLocaleString('en', {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                            type: 'currency'
                                        })
                                        const views = mainData.views!.toLocaleString('en', {
                                            maximumFractionDigits: 0
                                        })
                                        const spend = mainData.spend!.toLocaleString('en', {
                                            maximumFractionDigits: 0,
                                            type: 'currency'
                                        })

                                        var mainLabel = mainData.name

                                        return (
                                            <GraphTooltip
                                                title={mainLabel}
                                                value={(
                                                    <div>

                                                        <p className="font-semibold text-md">
                                                            {views} Views</p>
                                                        <p className="font-semibold text-md">
                                                            {currencySymbol}{cpv} CPV</p>
                                                        <p className="font-semibold text-md">
                                                            {currencySymbol}{spend} spent</p>

                                                    </div>)}
                                            />
                                        );
                                    }

                                    return null;
                                }}

                            // formatter={(value: any, name: any, props: any) => [value.toLocaleString(), "Total Fanbase Size"]} 
                            />

                            <Bar dataKey="views"
                                radius={6}

                                fill="#D3B7F7"
                                background={{ fill: '#EDECF2', radius: 6 }}
                                barSize={50}
                                id="bar"
                            >
                                <LabelList dataKey="value" position="right"
                                    formatter={(value: number) => `${value.toLocaleString('en', {
                                        maximumFractionDigits: 0,
                                    })} views`}
                                    style={{ fill: "black" }}
                                    content={renderCustomizedLabel}
                                />
                            </Bar>

                        </BarChart>
                    </ResponsiveContainer>
                </div>)}

            </div>
        )
    }

    return (
        <div className="px-1">
            {renderLocationData(
                'countries',
                `Top Countries by Views`,
                'These are the top 10 countries that viewed your ads the most.'
            )}

            {renderLocationData(
                'cities',
                'Top Cities by Views',
                'These are the top 10 cities that viewed your ads the most.'
            )}

            {renderDemoData()}
        </div>

    )
}

