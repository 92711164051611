import { Chip, IconButton, SvgIcon, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import { StarsIcon } from "components/svg-icons";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import Typewriter from 'typewriter-effect';
import Recommender, { MaestroRecommendation } from "./Recommender";
import { useContext, useEffect, useState } from "react";
import { availableGoals, getIconForGoal } from "../../utils/marketing";
import { NavigateNext } from "@material-ui/icons";
import { motion } from "framer-motion";
import GeneralPurpleButton, { GeneralOutlinedButton } from "components/shareable/CommonComponent/PurpleButton";
import { trackAskMaestro, trackMaestroResponse, trackNewCampaign } from "../CampaignsSection/analytics";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { MarketingCampaignType } from "types/global";

interface MaestroCampaignInputProps {
    defaultQueryOptions?: {
        label: string;
        query: string;
    }[] | null
}

const campaignTypesData = Object.values(availableGoals).map((goal: MarketingCampaignType) => ({
    title: goal.title,
    description: goal.description,
    imageUrl: goal.icon, // Replace with the correct property name for the icon URL
    link: goal.link,
    underMaintenance: goal.underMaintenance,
    statusMessage: goal.statusMessage
}));

export default function MaestroCampaignRecommender(props: MaestroCampaignInputProps) {
    const {
        defaultQueryOptions = [
            {
                query: "Grow my Spotify streams",
                label: "Spotify streams"
            },
            {
                query: "Grow my IG engagement",
                label: "Instagram engagement"
            },
            {
                query: "Grow my YouTube views",
                label: "YouTube views"
            }
        ]

    } = props

    const classes = useStyles();

    const {
        currentBrand
    } = useContext(CurrentBrandContext);

    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(900));


    // list of current recommendations for campaigns
    const [currentRecommendations, setRecommendations] = useState<MaestroRecommendation | null>(null)
    const [inputValue, setInputValue] = useState<string>("")
    const [showRecommendations, setShowRecommendations] = useState<boolean>(false)

    const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false)

    useEffect(() => {
        // on empty state, zero out the recommendations
        if (!inputValue) {
            setRecommendations(null)
            setShowRecommendations(false)
        }
    }, [inputValue])

    async function suggestCampaigns(text: string = inputValue) {
        setRecommendations(null)
        setLoadingSuggestions(true)
        trackAskMaestro({
            currentBrand: currentBrand,
            message: text
        })
        // get recommendatiosn 
        const recommendations = await Recommender({ type: text })
        trackMaestroResponse({
            currentBrand: currentBrand,
            message: text,
            response: recommendations
        })
        setRecommendations(recommendations)
        setLoadingSuggestions(false)
    }

    // handles selection of default options 
    function askAIAutocomplete(type: string) {
        setInputValue(type)
        suggestCampaigns(type)
    }

    // resets search box
    function resetSearch() {
        setInputValue('')
        setRecommendations(null)
        setShowRecommendations(false)
    }

    return (
        <div className="w-full">
            {setResponsiveView && (
                <div className={classes.askAiHolder}>
                    <div className={classes.askAi}>
                        <StarsIcon color="#8800FF" />
                        <span>Ask AI</span>
                    </div>
                </div>
            )}
            <form className="" onSubmit={(e) => {
                e.preventDefault()
                suggestCampaigns()
            }}>
                <TextField
                    className={classes.input}
                    fullWidth
                    required
                    disabled={loadingSuggestions}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    variant="outlined"
                    placeholder={setResponsiveView ? `What do you want to grow?` : "What do you want to grow? Ask or enter a link."}
                    InputProps={{
                        startAdornment: !setResponsiveView && (
                            <div className={classes.askAi}>
                                <StarsIcon color="#8800FF" />
                                <span>Ask AI</span>
                            </div>
                        ),
                        endAdornment: (
                            <IconButton type="submit"
                                style={{
                                    "padding": "4px"
                                }}>
                                <SvgIcon viewBox="0 0 24 25" htmlColor="transparent" fontSize="large">
                                    <path stroke="#80F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 9.912 19 5.5l-4.412 15-3.53-7.059L4 9.911Z" clipRule="evenodd" />
                                    <path stroke="#80F" strokeLinecap="round" strokeWidth="2" d="m11 13.5 8-8" />
                                </SvgIcon>
                            </IconButton>
                        )
                    }}
                />
            </form>
            <div className="mt-4 flex justify-center relative items-center">
                <div className={`${loadingSuggestions ? 'block' : 'hidden'} transition`}>
                    <NewLoader black />
                    <p className="mt-1">Asking our AI...</p>
                </div>
                <div className={`${classes.recommendationsBox} relative ${currentRecommendations ? 'block' : 'hidden'} transition`}>
                    <div className={`flex items-start justify-start ${currentRecommendations && currentRecommendations.recommendations ? 'mb-4' : ''}`}>
                        <div className="flex-shrink-0"><StarsIcon color="#8800FF" /></div>
                        <div className="ml-2">{currentRecommendations ? (<Typewriter
                            onInit={(typewriter) => {
                                typewriter.typeString(currentRecommendations ? currentRecommendations.primaryMessage : '')
                                    .callFunction(() => {
                                        setShowRecommendations(true)
                                    })

                                    .start();
                            }}
                            options={{
                                autoStart: true,
                                loop: false,
                                delay: 30,
                            }}
                        />) : null}
                        </div>
                    </div>
                    {showRecommendations ? (<div className="">
                        {currentRecommendations ? currentRecommendations.recommendations?.sort((goal: any) => {
                            const foundCampaign = campaignTypesData.find((campaign: any) => campaign.title === goal.title)
                            if (foundCampaign?.underMaintenance) {
                                return 1
                            }
                            return -1
                        }).map((goal: any) => {
                            const foundCampaign = campaignTypesData.find((campaign: any) => campaign.title === goal.title)
                            if (foundCampaign?.underMaintenance) {
                                return {
                                    ...foundCampaign,
                                    ...goal
                                }
                            }
                            return goal
                        }).map((goal: {
                            key: string;
                            title: string;
                            message: string;
                            link: string;
                            underMaintenance?: boolean | null;
                            statusMessage?: string | null;
                        }, idx: number) => {
                            const goalIcon = getIconForGoal(goal.key)

                            if (goal.underMaintenance) {
                                return (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: (idx * 0.3) + 0.3 }}
                                        className={classes.campaignRecommendation}>
                                        {!setResponsiveView && (<div className="w-12 h-full flex items-center justify-center">
                                            <img className="w-full h-auto" src={goalIcon} alt={goal.title} />
                                        </div>)}
                                        <div className={setResponsiveView ? '' : "ml-4"}>
                                            <div className="flex items-center justify-start w-full">
                                                {setResponsiveView && (
                                                    <div className={`mb-1 w-8 h-full flex items-center justify-center ${setResponsiveView ? 'mr-2' : ''}`}>
                                                        <img className="w-full h-auto" src={goalIcon} alt={goal.title} />
                                                    </div>
                                                )}
                                                <p className="font-medium text-lg">{goal.title}</p>

                                            </div>
                                            <p>{goal.message}</p>
                                            {goal.underMaintenance && <h1 className="border rounded-md px-2 mt-2 fit-content bg-gray-300"
                                            style={{
                                                width: 'fit-content'
                                            }}>{goal.statusMessage ? goal.statusMessage : `This campaign type is under maintenance and will be back shortly.`}</h1>}
                                        </div>
                                        <div className="ml-auto">
                                            <IconButton
                                                aria-label={goal.title}>
                                                <NavigateNext fontSize="large"
                                                ></NavigateNext>
                                            </IconButton>
                                        </div>
                                    </motion.div>
                                )
                            } else {
                                return <Link to={`/marketing/new/${goal.link}`} onClick={() => {
                                    trackNewCampaign({
                                        type: goal.title,
                                        from: 'ai',
                                        zeroState: true,
                                        aiQuestion: inputValue,
                                        currentBrand: currentBrand
                                    })
                                }}>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ delay: (idx * 0.3) + 0.3 }}
                                        className={classes.campaignRecommendation}>
                                        {!setResponsiveView && (<div className="w-12 h-full flex items-center justify-center">
                                            <img className="w-full h-auto" src={goalIcon} alt={goal.title} />
                                        </div>)}
                                        <div className={setResponsiveView ? '' : "ml-4"}>
                                            <div className="flex items-center justify-start w-full">
                                                {setResponsiveView && (
                                                    <div className={`mb-1 w-8 h-full flex items-center justify-center ${setResponsiveView ? 'mr-2' : ''}`}>
                                                        <img className="w-full h-auto" src={goalIcon} alt={goal.title} />
                                                    </div>
                                                )}
                                                <p className="font-medium text-lg">{goal.title}</p>

                                            </div>
                                            <p>{goal.message}</p>
                                            {goal.underMaintenance && <h1 className="border rounded-md px-2 mt-2 fit-content bg-gray-300">Under maintenance - Check Back Soon</h1>}
                                        </div>
                                        <div className="ml-auto">
                                            <IconButton
                                                aria-label={goal.title}>
                                                <NavigateNext fontSize="large"
                                                ></NavigateNext>
                                            </IconButton>
                                        </div>
                                    </motion.div>
                                </Link>
                            }

                        }) : null}
                        <div className="mt-5 mx-auto text-center">
                            <GeneralOutlinedButton onClick={resetSearch}>
                                Try Another Search</GeneralOutlinedButton>
                        </div>
                    </div>) : null}
                </div>
                {defaultQueryOptions && defaultQueryOptions.length > 0 && (
                    <div className={`${currentRecommendations || loadingSuggestions ? 'opacity-0 pointer-events-none hidden' : 'opacity-1'} transition
                    flex items-center flex-wrap justify-center`}>
                        {defaultQueryOptions.map((option: {
                            label: string,
                            query: string
                        }) => (
                            <Chip
                                // icon={<FaceOutlined />}
                                onClick={() => askAIAutocomplete(option.query)}
                                label={option.label}
                                clickable
                                color="primary"
                                className={classes.chip}
                            />
                        ))}

                    </div>
                )}
                <div>

                </div>
            </div>
        </div >
    )
}