import React from 'react';
import { IconHolder, SocialIconProps } from './styles';
import {ReactComponent as AmazonIcon} from 'assets/images/platforms/amazon-music.svg';

export const AmazonMusicIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <AmazonIcon height={30} width={30}/>
        </IconHolder>
    );
};
