import React, { useContext, useEffect, useState } from "react";
import SpotifyConnect from "../connect/Spotify";
import { LoadingIndicator } from "../Loader";
import RecordSelector from "./RecordSelector";
import Axios from "../../helpers/Interceptor";
import { filterOutLinks } from "../../helpers/StreamingMappings";
import YouTubeConnect from "../connect/YouTube";
import VideoSelector from "./VideoSelector";
import axios from "axios";
import { DebounceInput } from "react-debounce-input";
import dayjs from "dayjs";
import { NavbarContext } from "../../pages/post-auth/MarketingPage/Data/NavbarProvider";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { isYouTubeVideoUrl, pullVideoIdFromUrl, queryYouTubeApi } from "helpers/YouTube";
import SmallCard from "./SmallCard";
import { Severity } from "./SmallCard/styles";

interface VideoTabInterface {
    videoSelected: any;
    setVideoSelected: any;
    nextTab: any;
    youtubeConnected: any;
    brand: any
    showNextButton?: boolean
}

const THROTTLED_MODE = false;  // You can set this value based on your requirements.


export default function VideoTab(props: VideoTabInterface) {

    const {
        videoSelected,
        setVideoSelected,
        nextTab,
        youtubeConnected,
        brand,
        showNextButton = true
    } = props

    const {
        nextButtonHandler,
        setNextButtonHandler,
    } = useContext(NavbarContext);

    const { isMobile } = useScreenQueryDimensions();

    const [currentView, setCurrentView] = useState<any>('record-search')

    // youtube video searcher
    const [searchResults, setSearchResult] = useState([]);
    const [searchStr, setSearchStr] = useState<string>('');
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

    // stores the users recently loaded videos
    const [userDefaultVideos, setUserDefaultVideos] = useState<any>([])

    // youtube searcher
    const [youtubeSearchResults, setYoutubeSearchResult] = useState<any>([]);
    const [youtubeConnectSearchString, setYoutubeConnectSearchString] = useState('');
    const [locallySelectedChannel, setLocallySelectedChannel] = useState<any>(null)
    // releases puller
    const [loadedReleases, setReleases] = useState<any>(false)


    const selectYoutubeArtist = (item: any) => {

        setLocallySelectedChannel(item)
        setCurrentView('record-search')
        Axios.put(`/brand/${brand.slug}/connect`, {
            id: brand.id,
            name: brand.name,
            service: 'youtube_channel',
            value: item
        })
            .then((result) => {
                // set the local artist ID to returned id
                // const { id } = result.data.data
                // localStorage.setItem('selected-brand', id)

                // history.push('/dashboard')
            })
            .catch((err) => {
                console.error('error creating brand', err)
            })
    }



    // pull recent releases if artist has spotify connected
    async function loadRecentReleases() {

        var channelId = youtubeConnected ? youtubeConnected.id : locallySelectedChannel ? locallySelectedChannel.id : null

        if (channelId) {
            setLoadingSearch(true)

            // Step 1: Get the channel's upload playlist ID
            const channelDetails = await queryYouTubeApi({
                endpoint: '/channels',
                params: {
                    id: channelId,
                    part: 'contentDetails',
                }
            });

            const uploadPlaylistId = channelDetails?.data?.items[0]?.contentDetails?.relatedPlaylists?.uploads;

            if (uploadPlaylistId) {
                // Step 2: Get the videos from the upload playlist
                const playlistItems = await queryYouTubeApi({
                    endpoint: '/playlistItems',
                    params: {
                        playlistId: uploadPlaylistId,
                        part: 'snippet',
                        maxResults: 30,
                    }
                });

                if (playlistItems?.data?.items?.length) {
                    const mapped = playlistItems.data.items.map((o: { snippet: { resourceId: { videoId: any; }; }; }) => ({
                        ...o.snippet,
                        id: o.snippet.resourceId.videoId
                    }));
                    setUserDefaultVideos(mapped)
                    setSearchResult(mapped);
                }
            }
            setLoadingSearch(false)
        }
    }

    useEffect(() => {
        // load recent releases
        if (!THROTTLED_MODE && (locallySelectedChannel || youtubeConnected) && (!searchResults || searchResults.length === 0 || !searchStr)) {
            loadRecentReleases()
        }

    }, [youtubeConnected, locallySelectedChannel])

    // review selected rrecord
    useEffect(() => {

        if (videoSelected) {
            setNextButtonHandler({
                handler: nextButtonHandler.handler,
                tab: nextButtonHandler.tab,
                show: true,
                text: 'Next',
                disabled: false,
            })
        } else {
            setNextButtonHandler({
                handler: nextButtonHandler.handler,
                tab: nextButtonHandler.tab,
                show: false,
                text: 'Next',
                disabled: false,
            })
        }
    }, [videoSelected])


    useEffect(() => {
        if(!searchStr) {
            if(youtubeConnected) {
                setSearchResult(userDefaultVideos)
            } else {
                setSearchResult([])
            }
        }
    }, [searchStr])

    // handles youtube search for a video
    async function executeSearch() {
        const isVideoUrl = isYouTubeVideoUrl(searchStr)
        if (!isVideoUrl) return

        const searchChannel = async () => {
            if (searchStr.length > 2) {
                setLoadingSearch(true)


                // first confirm if its a channel or jsut a string

                if (isVideoUrl) {
                    const videoId = pullVideoIdFromUrl(searchStr)

                    const results = await queryYouTubeApi({
                        endpoint: '/videos',
                        params: {
                            id: videoId,
                            part: 'id,snippet',
                            maxResults: 15,
                        }
                    })
                    if (results?.data?.items?.length) {
                        const mapped = results.data.items.map((o: any) => {
                            return {
                                ...o.snippet,
                                id: o.id
                            }
                        })
                        setSearchResult(mapped)

                    }
                } else {
                    const results = await queryYouTubeApi({
                        endpoint: '/search',
                        params: {
                            type: 'video',
                            q: searchStr,
                            part: 'id,snippet',
                            maxResults: 15,
                        }
                    })
                    if (results?.data?.items?.length) {
                        const mapped = results.data.items.map((o: any) => {
                            return {
                                ...o.snippet,
                                id: o.id.videoId
                            }
                        })
                        setSearchResult(mapped)

                    }
                }


                setLoadingSearch(false)

            }
        }
        searchChannel();
    }

    let placeholder = `Enter your YouTube video URL ${youtubeConnected ? 'or select a video from your channel.' : '.'}`
    if (THROTTLED_MODE) {
        placeholder = 'Enter your video URL to find your video details.'
    }

    function renderVideoSelectedState() {
        return (

            <div className="middle-wrapper">
                <div className="sy-card px-6 lg:px-10">
                    <div className="flex items-center">
                        <div className="w-20 flex-shrink-0">
                            <img className="rounded-md" src={videoSelected.primaryThumbnail} />
                        </div>
                        <div className="px-3">
                            <h5 className="text-dark font-bold text-lg flex flex-row justify-center items-center">{videoSelected.name}</h5>
                        </div>
                    </div>
                </div>

                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <p className="text-lg font-bold">YouTube Channel</p>
                    <p className="text-lg">{videoSelected.channelTitle}</p>
                </div>
                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <p className="text-lg font-bold">Release Date</p>
                    <p className="text-lg">{dayjs(videoSelected.publishedAt).format("M/DD/YYYY")}</p>
                </div>
                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <p className="text-lg font-bold">Video URL</p>
                    <a target="_blank" className="text-lg underline w-8/12 text-right break-all" href={`https://www.youtube.com/watch?v=${videoSelected.id}`}>{`https://www.youtube.com/watch?v=${videoSelected.id}`}</a>
                </div>
                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                    <SmallCard
                        severity={Severity.WARNING}
                        text="Please ensure your video doesn't have explicit or shocking content - if it does, it may be denied by YouTube's Ads Review. We reserve the right to charge a 5% processing fee for any refunded campaigns." />
                </div>
                {/* {columns.indexOf('type') > -1 ?
            <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                <p className="text-lg font-bold">Type</p>
                <p className="text-lg">{recordSelected.spotify_release_type.charAt(0).toUpperCase() + recordSelected.spotify_release_type.slice(1)}</p>
            </div> : null} */}

                {showNextButton && (
                    <>
                        {!isMobile ? (
                            <div className="text-center px-4 lg:px-10 py-4">
                                <button
                                    onClick={() => nextTab()}
                                    className="btn-primary w-full br-20px mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                                >
                                    Next
                                </button>S
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        )
    }

    function renderVideoSearcherState() {
        
    }

    return (
        <>
            {!videoSelected ? currentView === 'record-search' ? (

                <div className="middle-wrapper lg:px-0 relative">
                    <div className="px-5 lg:px-10 py-5">

                        <p className="font-bold text-base mb-2">Select the video you
                            want to promote.
                        </p>

                    </div>


                    {/* <div className="flex flex-row items-center border-b">
                        <p className="px-5 lg:px-10 pb-6">Recent Releases</p>
                    </div> */}
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        executeSearch()
                    }}>
                        <div className="flex flex-row items-center border-b border-t">
                            <img className="pl-5 lg:pl-10 w-auto" src={require('../../assets/images/search-icon.svg').default} />
                            <input
                                type="text"
                                placeholder={placeholder}
                                value={searchStr}
                                onChange={(e: any) => setSearchStr(e.target.value)}
                                className="pl-4 pr-6 py-6 outline-none border-gray-200 w-full"
                            />

                            <button type="submit" className="pr-5 lg:pr-10 text-primary">Search</button>
                        </div>
                    </form>
                    <VideoSelector
                        youtubeConnected={youtubeConnected || locallySelectedChannel}
                        connectYoutubeButton={() => setCurrentView('youtube-connect')}
                        loadingSearch={loadingSearch}
                        searchString={searchStr}
                        content={searchResults}
                        onRecordSelected={setVideoSelected} />


                </div>
            ) : (
                <YouTubeConnect


                    artist={brand}
                    closeModal={() => setCurrentView('record-search')}
                    selectYoutubeChannel={selectYoutubeArtist}
                    setYoutubeSearchResult={setYoutubeSearchResult}
                    youtubeSearchResults={youtubeSearchResults}
                    youtubeSearchString={youtubeConnectSearchString}
                    setYoutubeSearchString={setYoutubeConnectSearchString}
                />
            )
                : null}


            {videoSelected ? renderVideoSelectedState() : null}

        </>
    )
}