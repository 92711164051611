import styled from 'styled-components';

interface CardProps {
  flexDirection?: string;
  alignItems?: string;
}

interface CardContainerProps {
  width: string;
}

export const Card = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: CardProps) => flexDirection};
  gap: 8px;
  align-items: ${({ alignItems }: CardProps) => alignItems};
`;

export const CardContainer = styled.div`
  display: inline-block;
  flex-grow: 1;
  width: ${({ width }: CardContainerProps) => width};
  padding: 16px 12px 8px;
  border: 1px solid #e5f1eb;
  border-radius: 8px;
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  background-color: #8800ff14;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  align-content: center;
  justify-content: center;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
`;

export const CardTitle = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
  width: auto;
  text-overflow: ellipsis;
`;

export const ValueText = styled.p`
  font-family: DIN;
  font-size: 20px;
  line-height: 32px;
`;
