import { makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({

    // slider 
    ageSlider: {
        width: '100%',
        cursor: 'pointer',
        height: '16px !important',
        display: 'inline-block',
        padding: '13px 0',
        position: 'relative',
        boxSizing: 'content-box',
        touchAction: 'none',
        WebkitTapHighlightColor: 'transparent',
        '& .MuiSlider-markLabel': {
            top: '50px',
            color: 'black',
            fontSize: '18px'
        },
        '& .MuiSlider-rail': {
            width: '100%',
            height: 'inherit',
            display: 'block',
            position: 'absolute',
            backgroundColor: '#F1F1F1',
            borderRadius: '27px',
            padding: 0,
            opacity: 1,
        },
        '& .MuiSlider-track': {
            height: 'inherit',
            display: 'block',
            position: 'absolute',
            borderRadius: '27px',
            backgroundColor: 'rgba(136, 0, 255, 0.24)',
        },
        '& .MuiSlider-thumb': {
            width: '24px !important',
            height: '24px !important',
            display: 'flex',
            position: 'absolute',
            boxSizing: 'border-box',
            marginTop: '-5px !important',
            alignItems: 'center',
            marginLeft: '-11px !important',
            borderRadius: '34px',
            justifyContent: 'center',
            boxShadow: 'none !important',
            backgroundColor: '#8800FF',
            transform: 'none !important',
            '&:hover, :active': {
                transform: 'none !important',
            },
            '& .MuiSlider-valueLabel': {
                left: 'calc(-4px) !important',
                top: '-28px !important',
                position: 'absolute',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '19px',
                width: '32px',
                height: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                opacity: 1,
                transform: 'none !important',
                '& span': {
                    backgroundColor: '#FFFFFF !important',
                    color: '#000000 !important',
                    transform: 'none',
                    borderRadius: '50% !important',
                }
            },
        },
    },
    disabledThumb: {
        backgroundColor: '#FFFFFF !important',
    },
    disabledRail: {
        backgroundColor: 'rgba(136, 0, 255, 0.08)',
    },

    // chip 
    chip: {
        color: '#707070',
        border: '2px solid #707070',
        padding: '5px 10px',
        boxSizing: 'border-box',
        fontSize: '1.3rem',
        height: "60px",
        borderRadius: "99px",

        '@media (max-width: 768px)': {
            fontSize: '1.1rem',
        },
        '&.MuiChip-clickable.MuiChip-outlined:hover, .MuiChip-clickable.MuiChip-outlined:focus, .MuiChip-deletable.MuiChip-outlined:focus': {
            background: 'none',
            '&.selected, &:active': {
                background: '#F5EBFF',
                color: 'black',
                border: '2px solid #8800FF',

            },
        },
        '&:focus': {
            background: `rgba(0,0,0,0.5)`,
            '&.selected, &:active': {
                background: '#F5EBFF',
                color: 'black',
                border: '2px solid #8800FF',

            },
        },
        '&:hover': {
            // border: '2px solid #707070',
            background: `rgba(0,0,0,0.5)`,

  
        },
        '&.selected, &:active': {
            background: '#F5EBFF',
            color: 'black',
            border: '2px solid #8800FF',

            '&:hover': {
                border: '2px solid #707070',
                background: '#F5EBFF',
    
            }

        },


    },
}));