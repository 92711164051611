import Axios from "helpers/Interceptor";

export const searchSpotifyReleases = async(value: string) => {
    try {
        const url = "platform/identify/content";
        const encodedValue = encodeURIComponent(value)
        const options = { params: { url: encodedValue } };
        const response = await Axios.get(url, options);
        if (response?.data) return response.data
    } catch (e: unknown) {
        console.error("Error on searchSpotifyReleases component: ", e);
    }
};
