import { NEXT, REVIEW, REVIEW_YOUR_CAMPAIGN } from "./constants"
import COUNTRY_CODES from "helpers/Countries"

export enum STEPS {
  CREATIVE = "creative",
  BUDGET = "budget",
  FINALIZATION = "review",
}
const { BUDGET } = STEPS

export const getNextButtonLabel = (
  isNextButtonMobile: boolean,
  selectedTab?: STEPS | null
) => {
  if (selectedTab === BUDGET && isNextButtonMobile) return REVIEW
  if (selectedTab === BUDGET && !isNextButtonMobile) return REVIEW_YOUR_CAMPAIGN
  return NEXT
}

export const getNextButtonWidth = (args: {
  selectedTab?: STEPS | null
  isNextButtonResponsive: boolean
  isNextButtonMobile: boolean
}) => {
  const { selectedTab, isNextButtonMobile, isNextButtonResponsive } = args

  if (selectedTab === BUDGET && isNextButtonMobile) return undefined
  if (selectedTab === BUDGET && !isNextButtonMobile && isNextButtonResponsive) return "220px"
  if (selectedTab === BUDGET) return "300px"
  return undefined
}

export const formatCountries = (
  geographyType: string,
  selectedCountries: { label: string; value: string }[]
) => {
  if (geographyType === "custom" && selectedCountries) {
    return selectedCountries.map(({ label }) => label);
  }

  return null;
};

export const getSelectedCountries = (countries: string[]) => {
  if (countries.length) {
    let saved: { code: string; name: string; population: number | null }[] = []

    for (const countryName of countries) {
      const found = COUNTRY_CODES.find(({ name }) => name === countryName)
      if (found) saved = [...saved, found]
    }

    return saved.map(({ name, code }) => ({
      value: code,
      label: name,
    }))
  }

  return []
}

export const convertArrayDraftDataToSendAsObject = (data: Record<string, unknown>[]) => {
  const [
    recordSelected,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    campaignType,
    geographyType,
    selectedTab,
    selectedCountries
  ] = data

  return {
    recordSelected,
    budgetValue,
    startDate,
    endDate,
    selectedAudiences,
    campaignType,
    geographyType,
    selectedTab,
    selectedCountries
  }
}