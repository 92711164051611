import Axios from "./Interceptor";

const YOUTUBE_CHANNEL_REGEX = (/^https?:\/\/(www\.)?youtube\.com\/(channel\/(UC[\w-]{21}[AQgw])|(c\/|user\/)?([\w-]+))$/)
const YOUTUBE_VIDEO_REGEX = (/(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]vi?=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)
const YOUTUBE_CHANNEL_REGEX_V2 = (/^https?:\/\/(www\.)?youtube\.com\/@([\w-]+)\/?$/)

/**
 * Queries the YouTube API using the primary Symphony API key - 
 * and if it fails due to a 403 error, runs BackupKey
 * @param url URL to query (without api Key)
 * @returns response or throws error
 */
export async function queryYouTubeApi({ endpoint, params }: {
    endpoint: string,
    params: Record<string, unknown>
}) {
    try {
        const results = await Axios.post('/youtube', {
            endpoint,
            params,
        })

        return results.data
    } catch (error: any) {
        throw error
    }
}

/**
 * Helper function to query the YouTube search endpoint.
 * 
 * @param {Object} options - Options for the search query.
 * @param {string} options.type - The type of resource to search for (e.g., 'channel', 'video').
 * @param {string} [options.q] - The query term.
 * @param {string} [options.channelId] - The channel ID to search within.
 * @param {string} [options.part='id,snippet'] - The part parameter specifies a comma-separated list of one or more search resource properties that the API response will include.
 * @param {number} [options.maxResults=30] - The maxResults parameter specifies the maximum number of items that should be returned in the result set.
 * 
 * @returns {Promise<Object>} - The result of the YouTube API query.
 */
export async function queryYouTubeSearch({
    type,
    q = '',
    channelId = '',
    part = 'id,snippet',
    maxResults = 30
}: {
    type: string,
    q?: string,
    channelId?: string,
    part?: string,
    maxResults?: number
}) {
    // Prepare the params object
    const params: Record<string, unknown> = {
        type,
        part,
        maxResults
    };

    // Conditionally add query or channelId to params
    if (q) params.q = q;
    if (channelId) params.channelId = channelId;

    // Call the queryYouTubeApi function
    try {
        const results = await queryYouTubeApi({
            endpoint: '/search',
            params
        });
        return results;
    } catch (error) {
        throw error;
    }
}


/**
 * Returns whether a passed URL is actually a YouTube video URL
 * @param url 
 * @returns 
 */
export function isYouTubeVideoUrl(url: string) {
    return YOUTUBE_VIDEO_REGEX.test(url)
}

/**
 * Uses a generalized YouTube video URL regex to pull the video's id
 * @param url Channel URL
 */
export function pullVideoIdFromUrl(url: string) {

    var formattedUrl = url
    const match = formattedUrl.match(YOUTUBE_VIDEO_REGEX)

    if (!match) {
        return null
    } else {
        return match[1]
    }
}

/**
 * Returns whether a passed URL is actually a YouTube Channel URL
 * @param url 
 * @returns 
 */
export function isYouTubeChannelUrl(url: string) {
    return YOUTUBE_CHANNEL_REGEX.test(url)
}

/**
 * Returns whether a passed URL is actually a YouTube Channel URL
 * @param url 
 * @returns 
 */
export function isYouTubeChannelUrlWithProfile(url: string) {
    return YOUTUBE_CHANNEL_REGEX_V2.test(url)
}


/**
 * Uses a generalized YouTube channel URL regex to pull the channel's id
 * @param url Channel URL
 */
export function pullChannelIdFromUrl(url: string) {

    var formattedUrl = url

    let match = formattedUrl.match(YOUTUBE_CHANNEL_REGEX)

    if (!match) {
        return null
    }

    // if it's a channel with `channel` in ID, use a different part of the regex 
    // vs if it has /c/ or /user/
    if (formattedUrl.indexOf('/c/') > -1 || formattedUrl.indexOf('/user/') > -1) {

        if (match) {
            return match[5]
        }

    } else if (formattedUrl.indexOf('/channel/') > -1) {

        if (match) {
            return match[3]
        }
    }
}

/**
 * Uses a generalized YouTube channel URL regex to pull the channel's id
 * @param url Channel URL
 */
export async function getChannelIdFromUrl(url: string): Promise<string | null> {
    try {
        const results = await Axios.post('/youtube/channel-id', { url })
        const { data: channelId = null } = results.data;
        return channelId;
    } catch (e) {
        return null;
    }
}
