import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { InstagramPost, InstagramPostInput, MediaType } from "./types";
import InstagramVideoIcon from "assets/images/ig-video.svg";
import InstagramLikesIcon from "assets/images/ig-likes.svg";
import InstagramCommentsIcon from "assets/images/ig-comments.svg";
import millify from "millify"
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import times from "lodash/times";
import { Modal } from "@material-ui/core";
import dayjs from "dayjs";
import { track } from "analytics";
import { LoadingIndicator } from "components/Loader";
import { ExplicitContentWarning } from "pages/post-auth/MarketingPage/utils/fbCampaigns";
import { InstagramPostButton, PostBannerContainer, useStyles } from "./styles";
import { Caption } from "../Typography";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as QuestionMarkIcon } from "assets/images/question-mark.svg";

const InstagramPosts: FunctionComponent<InstagramPostInput> = ({
  posts,
  setSelectedInstagramPost,
  selectedInstagramPost,
  showCaption,
  allowPostPreviews = false
}) => {
  const { isMobile } = useScreenQueryDimensions();
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);

  const [viewPost, setViewPost] = useState<InstagramPost | null>(null);
  const limit = isMobile ? 2 : 4

  const [pagePosts, setPagePost] = useState<InstagramPost[]>([])
  const numberOfPages = useMemo(() => Math.ceil(posts.length / limit), [posts.length])
  const pages = <div>{page + 1} / {numberOfPages}</div>

  useEffect(() => {
    const startIndex = page * limit
    const endIndex = startIndex + limit
    const pagePosts = posts.slice(startIndex, endIndex)
    setPagePost(pagePosts)
  }, [posts, page])

  useEffect(() => {
    if (selectedInstagramPost) {
      const index = posts.findIndex(post => post.id === selectedInstagramPost.id)
      if (index >= 0) {
        const page = Math.floor(index / limit);
        setPage(page)
      } else {
        setPage(0)
      }
    }
  }, [])

  const previousPage = () => {
    const isFirstPage = page === 0
    const lastPage = numberOfPages - 1
    const prevPage = page - 1
    setPage(isFirstPage ? lastPage : prevPage)
  }

  const nextPage = () => {
    const isLastPage = page === (numberOfPages - 1)
    const fistPage = 0
    const fwdPage = page + 1
    setPage(isLastPage ? fistPage : fwdPage)
  }

  function renderPreview() {
    if (!viewPost) return null

    let previewUrl = null
    let previewType = null
    // if its a carousel, pull the first video from the carousel
    // otherwise use the main post
    if (viewPost.media_type === 'CAROUSEL_ALBUM') {
      const carouselPosts = viewPost.children.data
      if (carouselPosts[0] && carouselPosts[0].media_url) {
        previewUrl = carouselPosts[0].media_url
        previewType = carouselPosts[0].media_type === 'VIDEO' ? 'video' : 'image'
      }
    } else if (viewPost.media_url) {
      previewUrl = viewPost.media_url
      previewType = viewPost.media_type === 'VIDEO' ? 'video' : 'image'
    } else if (viewPost.thumbnail_url) {
      // if there's no preview_url, its most likely because its a reel
      // shared to feed - use the thumbnail_url instead
      previewUrl = viewPost.thumbnail_url
      previewType = 'image'

    }

    return (
      <Modal
        open={true}
        onClose={() => setViewPost(null)}
        aria-labelledby="view-user"
        aria-describedby="view Instagram Post"
      >
        <div className="top-wrapper
        h-screen
        w-screen
               w-full 
               lg:w-4/12 md:h-auto
               transform-gpu -translate-x-1/2 -translate-y-1/2 
               flex items-center justify-center
               left-1/2 top-1/2
    absolute z-50 
    card-content bg-white shadow-none lg:shadow-lg">

          <div className="w-full">
            <div className="bg-white w-full h-full mx-auto">
              <div
                className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
              >
                <p className="font-semibold text-gray-800"></p>
                <a
                  onClick={() => setViewPost(null)}
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </a>
              </div>
              <div className="h-full pb-4">
                {previewType === 'video' ? <video
                  className="bg-black mx-auto w-full h-2/5 md:h-4/6 lg:h-96"
                  autoPlay controls playsInline src={previewUrl}></video> :
                  (<img
                    className="object-contain bg-black mx-auto w-full h-2/5 md:h-4/6 lg:h-96"
                    src={previewUrl}></img>)
                }
                <div className="mt-3 px-4 lg:px-10 max-h-48	overflow-auto">
                  <p>{millify(viewPost.like_count)} Like{viewPost.like_count === 1 ? '' : 's'} / {millify(viewPost.comments_count)} Comment{viewPost.comments_count === 1 ? '' : 's'}</p>
                  <p className="my-1"><span className="font-semibold mr-2">@{viewPost.username}</span>{viewPost.caption}</p>
                  <p className="text-sm text-gray-400">{dayjs(viewPost.timestamp).format('MMM D, YYYY')}</p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </Modal>
    )
  }

  function renderInstagramPosts() {
    if (pagePosts.length === 0) {
      return (

        <div className="w-full flex items-center justify-center">
          <LoadingIndicator height="30px" color="black" />
        </div>)
    } else {
      return (
        <>
          <div className="flex flex-row mx-6 lg:mx-10 mb-2">
            {pagePosts.map((post: InstagramPost) => {
              const isPostSelected = post.id === selectedInstagramPost?.id
              const isVideo = post.media_type === MediaType.VIDEO;
              const hasMedia = !!post.media_url

              return (
                <div key={`ig-post-${post.id}`} className={`transition-all duration-300 border-2 rounded-md w-6/12 lg:w-3/12 mx-2 ${isPostSelected ? 'border-primary' : 'border-white'}`}>
                  <div className="m-auto flex items-center h-40 w-40">
                    <div className="instagram-post-container flex-auto flex-shrink-0">

                      <div
                        className={post.selectable ? "cursor-pointer" : "cursor-not-allowed"}
                        onClick={() => (!isPostSelected && post.selectable) && setSelectedInstagramPost(post)}
                      >
                        <div className={`absolute z-10 bottom-0 px-1 grid grid-cols-2 h-6 w-full rounded bg-gradient-to-t from-black`}>
                          <div className="grid grid-cols-1 left-0 pt-0.5">
                            {isVideo ? <img className="px-1 w-5 h-5" alt="ig-video-icon" src={InstagramVideoIcon} /> : <div />}
                          </div>
                          {post.selectable ? (
                            <div className="flex flex-row-reverse px-0.5">
                              <p className="pt-1 text-center text-xs text-white">{millify(post.comments_count)}</p>
                              <img className="px-1.5 w-6 h-6" alt="ig-comments-icon" src={InstagramCommentsIcon} />
                              <p className="pt-1 text-center text-xs text-white">{millify(post.like_count)}</p>
                              <img className="px-1.5 w-6 h-6" alt="ig-likes-icon" src={InstagramLikesIcon} />
                            </div>
                          ) : (
                              <PostBannerContainer>
                                <QuestionMarkIcon className={classes.questionMarkIcon} />
                                <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>Instagram posts that are smaller than 500px wide can’t be used for ads</Caption>
                              </PostBannerContainer>
                          )}
                        </div>

                        <div className="instagram-post-container">
                          {isVideo && hasMedia ?
                            <video className="instagram-post-img" autoPlay loop muted playsInline >
                              <source src={post.media_url || post.thumbnail_url} type="video/mp4" />
                            </video>
                            :
                            <img className="instagram-post-img" alt={post.id} src={post.thumbnail_url || post.media_url} />
                          }
                        </div>
                      </div>

                      <div className="flex flex-col px-1 py-2">
                        {allowPostPreviews ? (<button
                          className={`mb-1 transition-all duration-300 rounded-full h-6 w-full text-xs font-medium leading-3 mt-0 btn-secondary`}
                          onClick={() => {
                            track("Previewed Instagram Post", {
                              ...post
                            })
                            setViewPost(post)
                          }}
                        >
                          VIEW POST
                        </button>) : null}
                        <InstagramPostButton
                          onClick={() => {
                            track("Selected Instagram Post", {
                              ...post
                            })
                            return !isPostSelected && setSelectedInstagramPost(post)
                          }}
                          postSelected={isPostSelected}
                          disabled={!post.selectable}
                        >
                          {isPostSelected ? 'SELECTED' : 'USE THIS POST'}
                        </InstagramPostButton>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="flex flex-row justify-between items-center pt-16 px-16 lg:px-72">
            <div className="cursor-pointer flex-shrink-0" onClick={previousPage}>
              <img
                alt="left-arrow.svg"
                width={16}
                src={require("assets/images/black-left-arrow.svg").default}
              />
            </div>

            {pages}

            <div className="cursor-pointer flex-shrink-0" onClick={nextPage}>
              <img
                alt="right-arrow.svg"
                width={16}
                src={require("assets/images/black-right-arrow.svg").default}
              />
            </div>
          </div>

          {
            (showCaption && selectedInstagramPost && selectedInstagramPost.caption) ?
              <div className="px-10 mb-0">
                <p className="text-md font-medium mb-0 mt-6">Caption</p>
                <p className="text-sm text-gray-500">Your Instagram post’s caption will be used for your ad.</p>
                <textarea
                  value={selectedInstagramPost.caption} readOnly disabled
                  className="w-full h-16 p-2 text-md font-medium mb-0 mt-2 border-2 border-gray-200 bg-gray-100 rounded-md resize-none"
                />
                {selectedInstagramPost.explicit_content && (
                  <ExplicitContentWarning />
                )}
              </div> : null
          }

          {
            (viewPost) ? renderPreview() : null
          }
        </>
      )
    }


  }

  return (
    <React.Fragment>
      {renderInstagramPosts()}

    </React.Fragment>

  );
};

export default InstagramPosts;
