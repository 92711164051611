import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { ChangeEvent } from "react";
import { isValidURL } from "utils";

interface LinkSelectorProps {
    showNextButton?: boolean;
    handleOnClickContinue: () => void;
    currentLink: string;
    handleChangeLink: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function LinkSelectorTab(props: LinkSelectorProps) {

    const {
        showNextButton = true,
        handleOnClickContinue,
        handleChangeLink,
        currentLink
    } = props 

    const { isMobile } = useScreenQueryDimensions();

  
    return (
        <div className={`${showNextButton ? "middle-wrapper" : "h-52"} lg:px-0 relative"`}>



            <div className="px-6 lg:px-10 py-5 border-b">
                <p className="text-lg font-bold">Enter your Link</p>
                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">This can be any type of link - increase traffic to your merch page, drive ticket sales, or grow traffic anywhere else.</p>

            </div>

            <div className="input-form flex flex-row align-middle ">

                <input
                    className="border-b  py-6 px-6 lg:px-10 outline-none text-left border-gray-200 w-full"
                    // minLength={2}
                    placeholder="e.g. https://link.com"
                    value={currentLink}
                    onChange={handleChangeLink}
                />
            </div>

            {
                showNextButton && !isMobile ? 
                <div className="text-center px-4 lg:px-10 py-4">
                    <button
                        onClick={handleOnClickContinue}
                        className="btn-primary w-full br-full mb-20 sm:md-20 lg:mb-4 py-2 lg:py-3"
                        disabled={
                            !currentLink || currentLink.trim().length === 0 || !isValidURL(currentLink)
                        }
                    >
                        Next
                    </button>
                </div> : null
            }

        </div>
    )
}