/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  FunctionComponent,
  useRef,
  ReactElement,
} from "react"
import { MarqueeContainer, MarqueeArea, MarqueeItem } from "./styles"
import { getFillList } from "./utils"
import { CSSProperties } from "styled-components"

interface Props {
  toRight?: boolean
  list: (ReactElement | JSX.Element)[]
  time?: number
  marqueeContainerProps?: {
    pauseOnHover?: boolean
    styles: CSSProperties
  }
}

const Marquee: FunctionComponent<Props> = ({
  list = [],
  time = 20,
  toRight,
  marqueeContainerProps,
}: Props) => {
  const marqueeContainer = useRef<HTMLDivElement | null>(null)
  const marqueeArea = useRef<HTMLDivElement | null>(null)
  const [moveLeft, setMoveLeft] = useState(0)
  const [showList, setShowList] = useState(list)
  const [realTime, setRealTime] = useState(time)

  useEffect(() => {
    const containerWidth = Math.floor(
      marqueeContainer?.current?.offsetWidth || 0
    )
    const areaWidth = Math.floor(marqueeArea?.current?.scrollWidth || 0)
    const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth))
    const newMoveLeft = areaWidth * Math.floor(copyTimes / 2)
    const newRealTime =
      time * parseFloat((newMoveLeft / containerWidth).toFixed(2))
    setShowList(getFillList(list, copyTimes))
    setMoveLeft(newMoveLeft)
    setRealTime(newRealTime)
  }, [list])

  return (
    <MarqueeContainer {...marqueeContainerProps} ref={marqueeContainer}>
      <MarqueeArea
        ref={marqueeArea}
        move={moveLeft}
        time={realTime}
        toRight={toRight}
      >
        {showList.map((item: ReactElement | JSX.Element) => (
          <MarqueeItem key={`marquee-${Math.random() * 10000}`}>
            {item}
          </MarqueeItem>
        ))}
      </MarqueeArea>
    </MarqueeContainer>
  )
}

export default Marquee
