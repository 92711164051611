import { Chip, makeStyles } from "@material-ui/core";
import { FaceOutlined } from "@material-ui/icons";

const useStyles = makeStyles({
    chip: {
        backgroundColor: "#F5EBFF",
        color: "#8800FF",
        display: 'flex',
        width: 'auto',
        padding: '10px',
        "&:hover": {
            backgroundColor: "#C7B3FF"
        },

        "& .MuiChip-label": {
            whiteSpace: 'nowrap',
            paddingLeft: '12px',
            paddingRight: '12px',
            textOverflow: 'unset',
            overflow: 'visible'
        }

    },
    label: {

    }
});

export default function ClickableChip(props: {
    campaignName: string,
    campaignImage: string;
    campaignType?: string | undefined;
    onClick?: () => void;
}) {

    const {
        campaignName,
        campaignImage,
        onClick
    } = props

    const classes = useStyles();

    return (
        <Chip
            onClick={onClick}
            icon={<img
                className="w-6 h-6 flex-shrink-0 ml-4"
                src={campaignImage} />}
            label={campaignName}
            clickable
            color="primary"
            className={classes.chip}
        />
    )
}
