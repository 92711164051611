import { CurrentBrand, PlaylistsRecord } from "types/global"
import Axios from "helpers/Interceptor"

export const saveDraft = async (args: {
  dataToSend: Record<string, unknown>
  currentBrand: CurrentBrand
  campaignDraftData?: Record<string, unknown> | null
}) => {
  try {
    const { dataToSend, currentBrand, campaignDraftData } = args
    const campaignDraftId = campaignDraftData ? campaignDraftData.id : null
    const brandId = campaignDraftData ? campaignDraftData.brand_id : currentBrand.id
    const recordSelected = dataToSend.recordSelected as PlaylistsRecord | null
    const budgetValue = dataToSend.budgetValue as { tier: number; cost: number } | null
    const selectedTab = dataToSend.selectedTab
    const pitchNotes = dataToSend.pitchNotes
    
    const response = await Axios.put(`campaign/brand/${brandId}/save-draft`, {
      id: campaignDraftId,
      campaign_metadata: {
        pitch_notes: pitchNotes,
        record: recordSelected || null,
        content: recordSelected
          ? {
              name: recordSelected.name,
              id: recordSelected.id,
              slug: recordSelected.slug,
              thumbnail_url: recordSelected.thumbnail_url,
              url: recordSelected.url,
            }
          : null,
        price_data: {
          tier: budgetValue ? budgetValue.tier : null,
          price: budgetValue ? budgetValue.cost : null,
          name:
            budgetValue && recordSelected
              ? `${budgetValue.tier.toLocaleString(
                  "en"
                )} Followers Across Playlists ("${recordSelected.name}")`
              : null,
          description: recordSelected
            ? `"${recordSelected.name}" (Get Playlisted)`
            : null,
        },
        campaign_type: "get_playlisted",
        campaign_platforms: ["playlisting"],
        saved_draft_step: selectedTab,
      },
    })
    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error when saving draft: ", error)
    return { data: null, error }
  }
}
