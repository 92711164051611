import { Dialog, useMediaQuery, useTheme } from '@material-ui/core'
import { showIntercom } from 'components/Navbar'
import { CurrentBrand, SpotifyArtist } from 'types/global'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import HeaderModal from './Components/HeaderModal'
import InputSearchSpotify from './Components/InputSearchSpotify'
import { ContactSupportButton, ModalContainer, TextWarning, useStyles, WarningContainer } from './styles'
import SmallCard from 'components/shareable/SmallCard'
import { Severity } from 'components/shareable/SmallCard/styles'

interface Props {
  inputDisabled?: boolean,
  open: boolean,
  localSave: boolean,
  artistName?: string,
  currentBrand?: CurrentBrand,
  closeModal: () => void,
  goBack?: () => void,
  onSave: () => void,
  previouslyConnected?: boolean,
  handleShowAlreadyExistsModal: (artist: SpotifyArtist, existingBrand: CurrentBrand) => void,
  setSpotifyArtist?: Dispatch<SetStateAction<SpotifyArtist | null>>,
}

const SpotifyConnect: FunctionComponent<Props> = ({
  inputDisabled,
  open,
  localSave,
  closeModal,
  goBack,
  artistName,
  currentBrand,
  onSave,
  setSpotifyArtist,
  handleShowAlreadyExistsModal,
  previouslyConnected
}: Props) => {
  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const classes = useStyles()
  const handleShowIntercom = () => showIntercom()
  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{
        scrollPaper: classes.scrollPaper,
        root: classes.rootDialog
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="24px"
      >
        <HeaderModal
          closeModal={closeModal}
          subtitle={`What is the name or URL for ${artistName ? artistName + " " : ''}Spotify account?`}
          title='Connect Spotify'
          handleGoBack={goBack}
        />
        {previouslyConnected && (
          <SmallCard
            severity={Severity.WARNING}
            text="When changing your Spotify, all of the releases from your new Spotify profile will be pulled into Symphony. Your old Spotify releases will be removed from our system. It might take a little bit to update." />


        )}
        <InputSearchSpotify
          shown={open}
          allowNewSpotifyArtistConnection={previouslyConnected}
          disabled={inputDisabled}
          artistName={artistName || ""}
          currentBrand={currentBrand}
          closeModal={closeModal}
          onSave={onSave}
          localSave={localSave}
          setSpotifyArtist={setSpotifyArtist}
          handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
        >
        </InputSearchSpotify>
        {inputDisabled && (
          <WarningContainer>
            <TextWarning>Change Spotify account can be only updated by calling support</TextWarning>
            <ContactSupportButton
              onClick={handleShowIntercom}
            >Contact support</ContactSupportButton>
          </WarningContainer>
        )}
      </ModalContainer>
    </Dialog>
  )
}

export default SpotifyConnect
