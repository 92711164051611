import React from "react";

type Props = {
  children?: React.ReactNode;
  title: string | JSX.Element;
  description: string | JSX.Element;
  className?: string;
  titleClassName?: string;
  descriptionClassName?: string;
};

const InfoSection = ({
  title,
  description,
  children,
  className,
  titleClassName,
  descriptionClassName,
}: Props) => {
  return (
    <div className={className || "sy-card px-5 w-full lg:px-10"}>
      <div className="">
        <h5 className={titleClassName || "text-dark font-bold text-lg"}>
          {title}
        </h5>
        <p
          className={
            descriptionClassName ||
            "text-sm sm:text-sm text-gray-500 lg:text-base"
          }
        >
          {description}
        </p>
        {Boolean(children) && children}
      </div>
    </div>
  );
};

export default InfoSection;
