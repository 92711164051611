import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  scrollPaper: {
    alignItems: 'flex-start',
  },
  rootDialogFullScreen: {
    '& .MuiDialog-paper': {
      margin: 0,
      borderRadius: 0,
      height: '100%',
      boxShadow: 'none',
      backgroundColor: '#F5F3F6',
    },
  },
  rootDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '678px',
      width: '100%',
      borderRadius: '8px',
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: 'auto',
      },
    },
  },
}));

interface PagePreviewProps {
  borderRadius?: string;
  width?: string;
  height?: string;
}

interface PreviewProps {
  background?: string;
  width?: string;
  padding?: string;
  border?: string;
  aspectRatio?: string;
  margin?: string;
  borderRadius?: string;
  overflow?: string;
}

interface PreviewHeaderProps {
  padding?: string;
}

interface PreviewContainerProps {
  background?: string;
  position?: string;
  padding?: string;
  height?: string;
  alignItems?: string;
  minHeight?: string;
}

interface BlurredPictureProps {
  height?: string;
  top?: string;
}
export const PagePreview = styled.div`
  max-width: ${({ width }: PagePreviewProps) => width};
  height: ${({ height }: PagePreviewProps) => height};
  border-radius: ${({ borderRadius }: PagePreviewProps) => borderRadius};
  background: #d5d1d6;
  margin: 0;
`;

export const PreviewContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: ${({ alignItems }: PreviewContainerProps) => alignItems};
  height: ${({ height }: PreviewContainerProps) => height};
  padding: ${({ padding }: PreviewContainerProps) => padding};
  background: ${({ background }: PreviewContainerProps) => background};
  min-height: ${({ minHeight }: PreviewContainerProps) => minHeight};
`;

export const PreviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: DIN;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  width: 100%;
  height: fit-content;
  z-index: 100;
  padding: ${({ padding }: PreviewHeaderProps) => padding || '16px 8px'};
  background-color: #ffffff;
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`;

export const Preview = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  border: ${({ border }: PreviewProps) => border};
  border-radius: ${({ borderRadius }: PreviewProps) => borderRadius};
  width: ${({ width }: PreviewProps) => width};
  aspect-ratio: ${({ aspectRatio }: PreviewProps) => aspectRatio};
  overflow: ${({ overflow }: PreviewProps) => overflow};
  padding: ${({ padding }: PreviewProps) => padding};
  background: ${({ background }: PreviewProps) => background};
  margin: ${({ margin }: PreviewProps) => margin};
`;

export const BlurredPicture = styled.img`
  width: 100%;
  height: ${({ height }: BlurredPictureProps) => height};
  position: absolute;
  top: ${({ top }: BlurredPictureProps) => top};
  filter: blur(16px);
`;

export const TitleContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export default useStyles;
