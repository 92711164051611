import styled from "styled-components"
import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down(791)]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down(361)]: {
        margin: 0,
      },
    },
  },
  paperRounded: {
    borderRadius: 8,
  },
}))

interface OutlinedButtonProps {
  width?: string
}

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  margin?: string
  padding?: string
  paddingLeft?: string
  marginLeft?: string
  marginTop?: string
  flexGrow?: string
  maxHeight?: string
  overflow?: string
  whiteSpace?: string
  textOverflow?: string
  backgroundColor?: string
  borderRadius?: string
  width?: string
}

export const ActionButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`

export const CloseIconContainer = styled.div`
  margin-left: auto;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-left: 3px;
  padding-top: 3px;
  background-color: rgba(136, 0, 255, 0.04);
`

export const DialogMainContainer = styled.div`
  padding: 32px;
`

export const OutlinedButton = styled.button`
  width: ${({ width }: OutlinedButtonProps) => width || "116px"};
  border: 1px solid rgba(136, 0, 255, 1);
  background-color: #ffffff;
  color: rgba(136, 0, 255, 1);
  font-size: 16px;
  border-radius: 48px;
  padding-left: 24px;
  padding-right: 24px;
  font-family: DIN;
  text-transform: none;
  height: 40px;
  font-weight: 600;
`

export const StyledLabel = styled.p`
  width: ${({ width }: StyledLabelProps) => width};
  border-radius: ${({ borderRadius }: StyledLabelProps) => borderRadius};
  background-color: ${({ backgroundColor }: StyledLabelProps) =>
    backgroundColor};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  flex-grow: ${({ flexGrow }: StyledLabelProps) => flexGrow};
  max-height: ${({ maxHeight }: StyledLabelProps) => maxHeight};
  overflow: ${({ overflow }: StyledLabelProps) => overflow};
  white-space: ${({ whiteSpace }: StyledLabelProps) => whiteSpace};
  text-overflow: ${({ textOverflow }: StyledLabelProps) => textOverflow};
`

export default useStyles
