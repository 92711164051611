import { Body2, Subtitle2 } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import { ColumnContainer } from './styles';

interface DescriptionProps {
  title: string;
  details: string;
  titleColor?: SystemColors;
  detailsColor?: SystemColors;
}

export function Description({
  title,
  details,
  titleColor,
  detailsColor,
}: DescriptionProps) {
  return (
    <ColumnContainer>
      <Subtitle2 color={titleColor}>{title}</Subtitle2>
      <Body2 color={detailsColor}>{details}</Body2>
    </ColumnContainer>
  );
}
