import { useState } from 'react'
import HeadCard from "./Components/HeadCard";
import BudgetCard from "./Components/BudgetCard";
import DurationCard from "./Components/DurationCard";
import GoLiveCard from "./Components/GoLiveCard";
import { CurrentBrand, VideoAdsRecord } from "types/global";
import { checkIfIsProTier } from "utils";
import { Container } from "./styles"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from '@material-ui/core';
import ConfirmationStepCard from './Components/ConfirmationStep';

interface FinalizationProps {
    campaignId: string | null
    startDate: Date | null;
    endDate: Date | null;
    record: VideoAdsRecord | null;
    budget: number;
    brand: CurrentBrand;
    targeting: VideoAdsRecord[];
    targetingType: string;
    geographyType: string;
    selectedCountries: { label: string; value: string }[];
    cancelSaving: () => void;
    confirmed: boolean;
    setConfirmation: (val: boolean) => void;
    refundFeeConfirmed: boolean;
    setRefundConfirmation: (val: boolean) => void;
}

export default function Finalization({
    campaignId,
    budget,
    record,
    brand,
    endDate,
    startDate,
    targeting,
    targetingType,
    geographyType,
    selectedCountries,
    cancelSaving,
    confirmed,
    setConfirmation,
    setRefundConfirmation,
    refundFeeConfirmed
}: FinalizationProps) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))
    const [creatingCampaign, setCreatingCampaign] = useState<boolean>(false)

    const isProUser = checkIfIsProTier(brand);
    const freeTrialOver = Boolean(brand.freeTrialOver);

    const onCancelPayPalOrder = () => setCreatingCampaign(false);
    const onErrorPayPalOrder = () => setCreatingCampaign(false);

    return (
        <Container>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <HeadCard {...{ record, geographyType, targetingType }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <BudgetCard {...{ budget, isProUser, freeTrialOver }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <ConfirmationStepCard {...{ setConfirmation, confirmed, refundFeeConfirmed, setRefundConfirmation }} />
            </Grid>
            <GoLiveCard
                {...{
                    refundFeeConfirmed,
                    confirmed,
                    campaignId,
                    startDate,
                    endDate,
                    budget,
                    brand,
                    record,
                    creatingCampaign,
                    selectedCountries,
                    targeting,
                    targetingType,
                    geographyType,
                    onCancelPayPalOrder,
                    onErrorPayPalOrder,
                    setCreatingCampaign,
                    isProUser,
                    cancelSaving,
                }}
            />
        </Container>
    )
}