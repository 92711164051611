import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"
import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

export const useStyles = makeStyles((_theme: Theme) => ({
  userIcon: {
    "& path": {
      stroke: SystemColors.ACCENT_COLOR,
    },
  },
}))

interface AlertContainerProps {
  type?: string
}

export const AlertContainer = styled.div<AlertContainerProps>`
  padding: 16px;
  background-color: ${({ type }) => {
    if (type === 'success') return `${SystemColors.SUCCESS}18`
    return `${SystemColors.PAPER_WARNING_CONTAINER}`
  }};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`

export const ActionsContainer = styled.div`
  align-self: flex-end;
  gap: 16px;
  display: flex;
  margin-top: 16px;
`
