import { SystemColors } from "types/globalStyles";
import { WebsiteIconProps } from "./types"

const baseColor = SystemColors.SECONDARY_TEXT_COLOR;
const selectedColor = SystemColors.ACCENT_COLOR;

export const FullImageWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "48", selected, className: string }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 40" fill="none" style={{ minHeight: height, maxHeight: height, minWidth: width, maxWidth: width }}>
      <path d="M1 6C1 3.79086 2.79086 2 5 2H23C25.2091 2 27 3.79086 27 6V26H1V6Z" fill={selected ? SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK :"#D9D9D9"}/>
      <path d="M4 21C4 20.4477 4.44772 20 5 20H18C18.5523 20 19 20.4477 19 21V23C19 23.5523 18.5523 24 18 24H5C4.44772 24 4 23.5523 4 23V21Z" fill={color} />
      <path d="M4 29C4 28.4477 4.44772 28 5 28H9C9.55228 28 10 28.4477 10 29V31C10 31.5523 9.55228 32 9 32H5C4.44772 32 4 31.5523 4 31V29Z" fill={color}/>
      <path d="M11 29C11 28.4477 11.4477 28 12 28H16C16.5523 28 17 28.4477 17 29V31C17 31.5523 16.5523 32 16 32H12C11.4477 32 11 31.5523 11 31V29Z" fill={color}/>
      <path d="M18 29C18 28.4477 18.4477 28 19 28H23C23.5523 28 24 28.4477 24 29V31C24 31.5523 23.5523 32 23 32H19C18.4477 32 18 31.5523 18 31V29Z" fill={color}/>
      <path d="M4 18C4 17.4477 4.44772 17 5 17H12C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19H5C4.44772 19 4 18.5523 4 18Z" fill={color}/>
      <rect x="1.45455" y="1.45455" width="25.0909" height="37.0909" rx="4.19977" stroke={color} stroke-width="1.09091"/>
    </svg>
  )
}

export const VinylWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "48", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg width={width} height={height} viewBox="0 0 28 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ minHeight: height, maxHeight: height, minWidth: width, maxWidth: width }}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 16.4183 18.4183 20 14 20C9.58172 20 6 16.4183 6 12C6 7.58172 9.58172 4 14 4C18.4183 4 22 7.58172 22 12ZM16.1033 11.9998C16.1033 13.1615 15.1616 14.1032 14 14.1032C12.8383 14.1032 11.8966 13.1615 11.8966 11.9998C11.8966 10.8382 12.8383 9.8965 14 9.8965C15.1616 9.8965 16.1033 10.8382 16.1033 11.9998ZM17.2286 11.9987C17.2286 13.7817 15.7832 15.2272 14.0001 15.2272C12.2171 15.2272 10.7717 13.7817 10.7717 11.9987C10.7717 10.2157 12.2171 8.77022 14.0001 8.77022C15.7832 8.77022 17.2286 10.2157 17.2286 11.9987ZM17.9266 11.9987C17.9266 14.1672 16.1686 15.9251 14.0001 15.9251C11.8316 15.9251 10.0737 14.1672 10.0737 11.9987C10.0737 9.83019 11.8316 8.07227 14.0001 8.07227C16.1686 8.07227 17.9266 9.83019 17.9266 11.9987Z" fill={color}/>
      <path d="M6 26C6 25.4477 6.44772 25 7 25H21C21.5523 25 22 25.4477 22 26C22 26.5523 21.5523 27 21 27H7C6.44772 27 6 26.5523 6 26Z" fill={color}/>
      <path d="M4 33C4 32.4477 4.44772 32 5 32H12C12.5523 32 13 32.4477 13 33V35C13 35.5523 12.5523 36 12 36H5C4.44772 36 4 35.5523 4 35V33Z" fill={color}/>
      <path d="M15 33C15 32.4477 15.4477 32 16 32H23C23.5523 32 24 32.4477 24 33V35C24 35.5523 23.5523 36 23 36H16C15.4477 36 15 35.5523 15 35V33Z" fill={color}/>
      <path d="M9 23C9 22.4477 9.44772 22 10 22H18C18.5523 22 19 22.4477 19 23C19 23.5523 18.5523 24 18 24H10C9.44772 24 9 23.5523 9 23Z" fill={color}/>
      <rect x="1.45455" y="1.45455" width="25.0909" height="37.0909" rx="4.19977" stroke={color} stroke-width="1.09091"/>
    </svg>
  )
}

export const BasicWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "40", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 40" fill="none">
      <path d="M1 6C1 3.79086 2.79086 2 5 2H23C25.2091 2 27 3.79086 27 6V13H1V6Z" fill={selected ? SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK : "#D9D9D9" }/>
      <path d="M4 21C4 20.4477 4.44772 20 5 20H23C23.5523 20 24 20.4477 24 21V23C24 23.5523 23.5523 24 23 24H5C4.44772 24 4 23.5523 4 23V21Z" fill={color}/>
      <path d="M4 27C4 26.4477 4.44772 26 5 26H23C23.5523 26 24 26.4477 24 27V29C24 29.5523 23.5523 30 23 30H5C4.44772 30 4 29.5523 4 29V27Z" fill={color}/>
      <path d="M4 33C4 32.4477 4.44772 32 5 32H23C23.5523 32 24 32.4477 24 33V35C24 35.5523 23.5523 36 23 36H5C4.44772 36 4 35.5523 4 35V33Z" fill={color}/>
      <path d="M4 16C4 15.4477 4.44772 15 5 15H12C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17H5C4.44772 17 4 16.5523 4 16Z" fill={color}/>
      <rect x="1.45455" y="1.45455" width="25.0909" height="37.0909" rx="4.19977" stroke={color} stroke-width="1.09091"/>
    </svg>
  )
}

export const OneFieldWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "40", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40" fill="none">
      <rect x="9" y="8" width="10" height="10" rx="5" fill={selected ? SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK : "#D9D9D9"} />
      <path d="M4 27C4 26.4477 4.44772 26 5 26H23C23.5523 26 24 26.4477 24 27V29C24 29.5523 23.5523 30 23 30H5C4.44772 30 4 29.5523 4 29V27Z" fill={color}/>
      <path d="M9 23C9 22.4477 9.44772 22 10 22H17C17.5523 22 18 22.4477 18 23C18 23.5523 17.5523 24 17 24H10C9.44772 24 9 23.5523 9 23Z" fill={color}/>
      <rect x="1.45455" y="1.45455" width="25.0909" height="37.0909" rx="4.19977" stroke={color} stroke-width="1.09091"/>
    </svg>
  )
}

export const ClassicWebsiteThemeIcon: React.FC<WebsiteIconProps> = ({ width = "28", height = "40", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="40" viewBox="0 0 28 40" fill="none">
      <rect x="4" y="4" width="20" height="32" rx="3" fill={selected ? SystemColors.PAPER_ACCENT_CONTAINER_ON_DARK : "#D9D9D9"}/>
      <path d="M6 26C6 25.4477 6.44772 25 7 25H21C21.5523 25 22 25.4477 22 26C22 26.5523 21.5523 27 21 27H7C6.44772 27 6 26.5523 6 26Z" fill={color}/>
      <path d="M6 30C6 29.4477 6.44772 29 7 29H21C21.5523 29 22 29.4477 22 30V32C22 32.5523 21.5523 33 21 33H7C6.44772 33 6 32.5523 6 32V30Z" fill={color}/>
      <path d="M9 23C9 22.4477 9.44772 22 10 22H18C18.5523 22 19 22.4477 19 23C19 23.5523 18.5523 24 18 24H10C9.44772 24 9 23.5523 9 23Z" fill={color}/>
      <rect x="1.45455" y="1.45455" width="25.0909" height="37.0909" rx="4.19977" stroke={color} stroke-width="1.09091"/>
      <path d="M7 7C7 6.44772 7.44772 6 8 6H20C20.5523 6 21 6.44772 21 7V19C21 19.5523 20.5523 20 20 20H8C7.44772 20 7 19.5523 7 19V7Z" fill={color}/>
    </svg>
  );
}

export const TraditionalWebsiteLayoutIcon: React.FC<WebsiteIconProps> = ({ width = "48", height = "48", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.91524" y="5.2729" width="30.5723" height="37.4545" rx="4.19977" stroke={color} stroke-width="1.09091"/>
      <rect x="28.1096" y="23.3677" width="9.12865" height="7.30862" rx="1.46172" fill={color}/>
      <rect x="12.2037" y="23.9131" width="24.4893" height="6.21771" rx="0.91627" stroke={color} stroke-width="1.09091"/>
      <rect x="28.1096" y="32.5034" width="9.12865" height="7.30862" rx="1.46172" fill={color}/>
      <rect x="12.2037" y="33.0489" width="24.4893" height="6.21771" rx="0.91627" stroke={color} stroke-width="1.09091"/>
    </svg>
  )
}

export const StackedWebsiteLayoutIcon: React.FC<WebsiteIconProps> = ({ width = "48", height = "48", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.56">
        <rect x="9.24849" y="5.2729" width="30.5723" height="37.4545" rx="4.19977" stroke={color} stroke-width="1.09091"/>
        <rect x="12.5367" y="23.9131" width="24.4893" height="6.21771" rx="0.91627" stroke={color} stroke-width="1.09091"/>
        <rect x="12.5367" y="33.0489" width="24.4893" height="6.21771" rx="0.91627" stroke={color} stroke-width="1.09091"/>
      </g>
    </svg>
  )
}

export const BoxesWebsiteLayoutIcon: React.FC<WebsiteIconProps> = ({ width = "48", height = "48", selected }) => {
  const color = !selected ? baseColor : selectedColor;
  return (
    <svg width={width} height={height} viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.56">
        <rect x="9.58199" y="5.2729" width="30.5723" height="37.4545" rx="4.2095" stroke={color} stroke-width="1.09091"/>
        <rect x="12.9007" y="24.8887" width="10.8172" height="6.23718" rx="0.920164" stroke={color} stroke-width="1.09091"/>
        <rect x="26.6409" y="24.8887" width="10.8172" height="6.23718" rx="0.920164" stroke={color} stroke-width="1.09091"/>
        <rect x="12.9007" y="33.6827" width="10.8172" height="6.23718" rx="0.920164" stroke={color} stroke-width="1.09091"/>
        <rect x="26.6409" y="33.6827" width="10.8172" height="6.23718" rx="0.920164" stroke={color} stroke-width="1.09091"/>
      </g>
    </svg>
  )
}
