import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  modalBackground: {
    [theme.breakpoints.down(701)]: {
      backgroundColor: "#F5F3F6",
    }
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      [theme.breakpoints.down(701)]: {
        margin: "8px",
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
      },
    },
  },
  switchButton: {
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: "rgba(136, 0, 255, 0.08);",
        },

        '& .MuiSwitch-thumb': {
          backgroundColor: "#8800FF",
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: "#FFFFFF",
      },
      '& + .MuiSwitch-track': {
        backgroundColor: "rgba(136, 0, 255, 0.08);",
      },
    }
  }
}));

interface ModalContainerProps {
  minHeight?: string
  height?: string
  maxHeight?: string
  width?: string
  margin?: string
  padding?: string
  gap?: string
}

interface StyledLabelProps {
  fontSize: string,
  color?: string,
  lineHeight?: string,
  blur?: boolean,
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }: ModalContainerProps) => padding || "32px"};
  padding-top: 0;
  gap: ${({ gap }: ModalContainerProps) => gap || "48px"};
  height: ${({ height }: ModalContainerProps) => height || "484px"};
  min-height: ${({ minHeight }: ModalContainerProps) => minHeight};
  max-height: ${({ maxHeight }: ModalContainerProps) => maxHeight};
  width: ${({ width }: ModalContainerProps) => width || "673px"};
  margin: ${({ margin }: ModalContainerProps) => margin || "auto"};
  background: #ffffff;
  border-radius: 8px;
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 0;
  padding: 24px 0;
  border-bottom: 1px solid #EDECF2;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 50%;
  cursor: pointer;
  align-self: flex-end;
`;

export const FanInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
`;

export const AdditionalUserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StyledLabel = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight || "22px"};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || "16px"};
  color: ${({ color }: StyledLabelProps) => color || "#000000"};
  filter: ${({ blur }: StyledLabelProps) => blur ? " blur(7.5px)" : "none"};
  text-overflow: ellipsis;
  width: 100%;
`;

export const AutoPresavedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
`;

export const PresavesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EDECF2;
  width: 100%;
  padding: 16px 0px;
  gap: 8px;
`;

export const ProjectInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TypeBatch = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8800FF;
  background: rgba(136, 0, 255, 0.08);
  border-radius: 4px;
  padding: 4px;
`;

export const ProjectImageContainer = styled.div`
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  border-radius: 4px;
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const PresaveImage = styled.img`
  border-radius: 4px;
  background: rgba(136, 0, 255, 0.08);
  width: 32px;
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
`;

export const EmptyPresaves = styled.div`
  text-align: center;
  padding: 16px 0px;
  width: 100%;
  background: #EDECF2;
  border-radius: 8px;
`;
