import React, {
  FunctionComponent,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import Snack from "@material-ui/core/Snackbar";
import useStyles, {
  CustomSnackbarContent,
  ContentContainer,
  Message,
  ActionContainer,
  BackgroundBar,
  LinearProgressBar,
} from "./styles";
import { useTheme } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export type SnackBarType = "SUCCESS" | "ERROR" | "INFO";
export type SeverityType = "success" | "info" | "warning" | "error";
const HIDE_TIME_IN_SECONDS = 4;

interface Props {
  message: string;
  open: boolean;
  onClose: () => void;
  options: {
    severity: SeverityType;
    type?: SnackBarType;
    linearProgress?: boolean;
  };
}

const Snackbar: FunctionComponent<Props> = ({
  message,
  open,
  onClose,
  options,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const linearProgress = !!options.linearProgress;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const ref = useRef(null) as MutableRefObject<HTMLDivElement | null>;

  const SlideTransition = (props: TransitionProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    return (
      <Slide {...props} direction={isMobile ? "up" : "left"} mountOnEnter />
    );
  };

  const handleClose = (
    _event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => reason !== "clickaway" && onClose();

  useEffect(() => {
    if (open && ref.current && linearProgress) {
      const progressBar = ref.current;
      progressBar.style.transition = `width ${HIDE_TIME_IN_SECONDS}s`;
      progressBar.style.width = "0%";
    }
  }, [open, linearProgress]);

  return (
    <Snack
      {...{ open, message }}
      classes={{ root: classes.snackbarRoot }}
      onClose={handleClose}
      autoHideDuration={HIDE_TIME_IN_SECONDS * 1000}
      TransitionComponent={SlideTransition}
      anchorOrigin={{
        vertical: isMobile ? "bottom" : "top",
        horizontal: isMobile ? "center" : "right",
      }}
      children={
        linearProgress ? (
          <CustomSnackbarContent>
            <ContentContainer>
              <Message>{message}</Message>
              <ActionContainer>
                <IconButton
                  disableTouchRipple
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </ActionContainer>
            </ContentContainer>
            <BackgroundBar>
              <LinearProgressBar {...{ ref }} />
            </BackgroundBar>
          </CustomSnackbarContent>
        ) : undefined
      }
      action={
        <IconButton disableTouchRipple color="inherit" onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      }
    />
  );
};

export default Snackbar;
