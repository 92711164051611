import { makeStyles } from '@material-ui/core';
import { Theme } from '@mui/material';
import styled from 'styled-components';


const useStyles = makeStyles((theme: Theme) => ({
  dataGridWrapper: {
    width: "100%",
    border: "none !important",
  },
  dataGridRoot: {
    border: "1px solid #EDECF2",
    outline: "none !important",
    '& .MuiDataGrid-row:hover': {
      background: '#EDECF2 !important',
    },
    "& .MuiDataGrid-main, .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-cell": {
      fontFamily: "DIN",
      color: "#707070",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "19px",
      padding: "16px",
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
      borderBottom: `1px solid #EDECF2`,
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell, .MuiDataGrid-row": {
      borderBottom: `1px solid #EDECF2`,
      cursor: "pointer",
    },
    '& .MuiDataGrid-iconButtonContainer': {
      marginLeft: '2px',
      visibility: 'visible !important',
      width: 'auto !important',
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
      outline: 'none !important',
    },
  }
}));

interface PlatformCellContainerProps {
  flexDirection?: string,
  justifyContent?: string
}

export const EmailCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const UserImage = styled.img`
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
`;

export const UserImageContainer = styled.div`
  background-color: #EDECF2;
  border-radius: 50%;
`;

export const UserDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const EmailContainer = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export const UserInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

export const NameContainer = styled.div`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
`;

export const NewFanContainer = styled.div`
  padding: 4px;
  background: rgba(136, 0, 255, 0.08);
  border-radius: 4px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #8800FF;
`;

export const PlatformCellContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: PlatformCellContainerProps) => flexDirection || "row"};
  justify-content: ${({ justifyContent }: PlatformCellContainerProps) => justifyContent || "flex-end"};
  gap: 4px;
  width: 100%;
  align-items: center;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const GrayText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF72;
`;

export const WhiteText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
`;

export const BlurContainer = styled.div`
  filter: blur(5px);
`;

export default useStyles;
