import FB from 'helpers/FB'
import Axios from 'helpers/Interceptor'
import * as Sentry from '@sentry/react'

export const getTosStatus = async (adAccountId: string, access_token?: string) => {
  try {
    const fb = FB()
    if ((window as any).HARDCODED_ACCESS_TOKEN)
      access_token = (window as any).HARDCODED_ACCESS_TOKEN
    else if (!access_token) access_token = fb.getAccessToken()

    const accessTokenParam = access_token ? `&access_token=${access_token}` : ''
    const url = `brand/tos/status?ad_account=${adAccountId}${accessTokenParam}`
    const response = await Axios.get(url)

    return response.data.data
  } catch (error) {
    Sentry.captureException(
      `Error getting Terms Of Service info for ad account with ID: ${adAccountId}. ERROR: ${error}`
    )
    return { status: false }
  }
}
