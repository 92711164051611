import { useState, useRef, useEffect } from "react";

function useVisible(initialIsVisible: boolean) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const targetContent = useRef<any>(null);

  // optional ref, recomended for toggle button
  const toggleElement = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    const clickedInsideAuxRef = Boolean(toggleElement.current?.contains(event.target));
    if (clickedInsideAuxRef) return;

    const clickedOutsideRef = Boolean(targetContent.current && !targetContent.current.contains(event.target));
    if (clickedOutsideRef) setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { targetContent, toggleElement, isVisible, setIsVisible };
}

export default useVisible;