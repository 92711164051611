import React, { useState } from 'react';
import { useEffect } from 'react';
import { createRemoteComponent } from '@paciolan/remote-component';
import { createRequires } from '@paciolan/remote-component/dist/createRequires';
import { resolve } from '../../../../../remote-component.config.js';

// palette colors 1-3 will always be ordered lightest to darkest from the seedData
interface IColorPalette {
    color1: string;
    color2: string;
    color3: string;
}
interface ISerializedTrack {
    spotifyUid: string;
    trackTitle: string;
    audioSampleUrl: string;
    albumName: string;
    albumArtImageUrl: string;
    artistName: string;
    artistArtImageUrl: string;
}

/**
 * START - Import / Setup Remote Component
 */

const requires = createRequires(() => resolve);
export const RemoteComponent = createRemoteComponent({ requires });

const isProd = true;
const musicHostname = isProd
    ? 'https://music.blurb.fm'
    : 'https://music.blurb.fail';

const SVGPreviewsComponent = ({
    templateId,
    includeAspectRatios,
    colorPalette,
    trackData,
    defaultColumnCount,
}: {
    templateId: string;
    includeAspectRatios: string[];
    colorPalette: IColorPalette;
    trackData: ISerializedTrack;
    defaultColumnCount: number;
}) => (
    <RemoteComponent
        url={`${musicHostname}/components/SVGPreviews.js`}
        templateId={templateId}
        includeAspectRatios={includeAspectRatios}
        colorPalette={colorPalette}
        trackData={trackData}
        defaultColumnCount={defaultColumnCount}
    />
);

/**
 * END - Import / Setup Remote Component
 */

/**
 * START - Our method of choosing the number of columns that the previews will use for display
 */
function getWindowDimensions(): { width: number; height: number; } {
    if (typeof window !== 'undefined') {
        const { innerWidth: width, innerHeight: height } = window
        return { width, height }
    }
    return { width: -1, height: -1 }
}
const getColCount = (width: number) => {
    const BREAKPOINTS = {
        SM: 640,
        MD: 768,
    };
    return width > BREAKPOINTS.SM ? 3 : 3;
};
/**
 * END - Our method of choosing the number of columns that the previews will use for display
 */

function ButtonWithPaletteAndPreviews({
    idx,
    trackData,
    selectedTemplateId,
    colorPalette,
    isSelected,
    setIsSelected
}: {
    selectedTemplateId: string,
    idx: number,
    trackData: {
        spotifyUid: string,
        trackTitle: string,
        audioSampleUrl: string,
        albumName: string,
        albumArtImageUrl: string,
        artistName: string,
        artistArtImageUrl: string
    },
    colorPalette: {
        color1: string,
        color2: string,
        color3: string
    },
    isSelected: boolean,
    setIsSelected: (selected: {
        color1: string,
        color2: string,
        color3: string
    }) => void
}) {

    const [dimensions, setDimensions] = useState<{ width: number; height: number; }>({ 
        width: -1, height: -1,
    });

    useEffect(() => {
        function handleResize() {
            const dimensions = getWindowDimensions();
            setDimensions(dimensions);
        }
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            key={idx}
            className={`overflow-hidden w-9/12 lg:w-5/12 min-h-max lg:min-h-min border border-4 hover:shadow-md transition-all cursor-pointer rounded-md p-4 mb-4 ml-2 mr-2 text-center
            ${isSelected ? `shadow-md border-indigo-700 border-24` : ``}`}
            style={{
                minHeight: '28rem',
                maxHeight: '40rem',
            }}
            onClick={() => {
                setIsSelected(colorPalette);
            }}
        >

            <div className='flex flex-col items-center'>
                <div className='rounded-md w-full flex-row flex '>
                    {Object.keys(colorPalette).map((k: string, idx: number) => {
                        let color = (colorPalette as any)[k];
                        return (
                            <div
                                key={color.replace('#', '') + idx}
                                className='rounded-md w-10 h-10 m-2 first-child:m-10  '
                                style={{
                                    backgroundColor: color,
                                }}
                            ></div>
                        );
                    })}
                </div>
            </div>

            <div className={`overflow-hidden w-11/12 h-11/12 m-2`}>
                <SVGPreviewsComponent
                    key={idx}
                    templateId={selectedTemplateId}
                    includeAspectRatios={['9x16', '4x5']}
                    colorPalette={colorPalette}
                    trackData={trackData}
                    defaultColumnCount={
                        getColCount(dimensions.width)
                    } // if unset, = 4
                />
            </div>
        </div>
    );
}

export default ButtonWithPaletteAndPreviews;
