/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, SetStateAction, Dispatch } from 'react'
import { CAMPAIGN_TYPES, TABS } from "../../../../../constants";
import { MarketingDataContext } from "../../Data/Provider";
import { AgeRange, FBCustomAudience, FacebookPageResponse, FbSuggestion, OrganizationData } from 'types/global'
import { getNotRemovableOptions } from '../TargetingView/Components/AutoCompleteAudiences/utils';
import uniqBy from 'lodash/uniqBy'
import { confirmConnectionSettings } from "./api";
import DetailsCard from "./Components/PreviewAdsCard";
import DurationCard from "./Components/DurationCard";
import BudgetCard from "../../Components/BudgetCardSummary";
import TargetingCard from "./Components/TargetingCard";
import PreConnectionConfigsCard from './Components/PreConnectionConfigsCard';
import { checkIfIsProTier, fixGeographicTargets } from "utils";
import Grid from "@material-ui/core/Grid";
import { Container } from "./styles";
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
    PreConnectionConfigs,
    getFormattedDemographics,
    validateTargetingCountriesAge,
    removeCountries as removeCountriesUtils,
    distributeBudget
} from '../../utils/fbCampaigns';
import useModal from 'Hooks/useModal';
import TargetingWarning from '../../Components/TargetingWarningModal';
import { AgeRestrictedCountry } from '../../constants'
import { getTargetingViewGenre } from '../../Components/TargetingView/utils';
import { CampaignOption } from '../utils';
import GoLiveCard from '../../Components/GoLiveCard';
import { CampaignInput } from '../../Components/GoLiveCard/types';
import { geographyTargetingDefaults } from '../../SongAds/TargetingView/data';


const NOT_REMOVABLE = "NOT_REMOVABLE";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface FinalizationProps {
    goal: string;
    fbPage: object;
    fbBusinessManager: object;
    fbAdAccount: object;
    fbPixel: object;
    igPage: object;
    assets: object;
    captions: object;
    budget: number;
    startDate: any;
    endDate: any;
    record: any;
    brand: object;
    cta: Record<string, unknown>;
    caption: string;
    selectedLink: string;
    campaignName: string;
    countryTargetingBreakdown: any;
    setCountryTargetingBreakdown: (breakdown: any) => void;
    interestTargetingAudiences: any;
    setInterestTargetingAudiences: (audiences: any) => void;
    validatedAudiencesAlready: boolean;
    setValidatedAudiences: () => void;
    setSelectedTab: (tab: string) => void;
    setShowTargetingView: Dispatch<SetStateAction<boolean>>;
    fbSuggestions: FbSuggestion[];
    igMedia: Record<string, unknown>;
    draft: string | null;
    cancelSaving: () => void;
    handleSaveTargetingData: () => void;
    customAudiences: FBCustomAudience[];
    selectedCampaignType: CampaignOption;
    additionalPageAudiences: FacebookPageResponse[];
    primaryOrganization?: OrganizationData;
}

export default function Finalization({
    primaryOrganization,
    isBelowRecommendedBudget,
    fbPage,
    fbAdAccount,
    brand,
    fbPixel,
    igPage,
    budget,
    startDate,
    endDate,
    setSelectedTab,
    addVisualSelected,
    // finished state
    allowCampaignCreation,
    // "quick" ---- no pixel or biz mgr selected
    // "custom" --- everything avail
    setShowTargetingView,
    fbSuggestions,
    selectedLink,
    goal,
    cta,
    caption,
    campaignName,
    assets,
    igMedia,
    draft,
    cancelSaving,
    handleSaveTargetingData,
    customAudiences,
    additionalPageAudiences,
    selectedCampaignType
}: any) {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down(769))
    const {
        targets: targetingViewGeographicTargets,
        audiences: targetingViewAudiences,
        demographics: targetingDemographics,
        saveDemographics,
    } = useContext(MarketingDataContext)

    const targetingWarningModal = useModal()

    const [loadingStep, setLoadingStep] = useState(3)
    const [preconnectionConfigurations, setPreconnectConfigurations] = useState<PreConnectionConfigs | null>(null)
    const [creatingCampaign, setCreatingCampaign] = useState(false)
    const [countriesToFix, setCountriesToFix] = useState<AgeRestrictedCountry[]>([])

    const isProUser = checkIfIsProTier(brand);
    const freeTrialOver = brand.freeTrialOver;
    const genres = brand?.connections?.spotify_artist_page?.genres;
    const genre = getTargetingViewGenre(genres);
    const notRemovableOptions = getNotRemovableOptions(genre);
    const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id");
    const targetAudiences = !!targetingViewAudiences.length ? targetingViewAudiences : initialTargetingAudiences;
    const defaults = geographyTargetingDefaults(CAMPAIGN_TYPES.boost_instagram);
    const fixedGeographicTargets = !!targetingViewGeographicTargets.length ? fixGeographicTargets(targetingViewGeographicTargets) : fixGeographicTargets(defaults);
    const geographicTargets = !!targetingViewGeographicTargets.length ? targetingViewGeographicTargets : defaults;
    const interestTargetingAudiences = targetAudiences
        .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
        .map(({ id, name }) => ({
            fbId: id,
            name,
        }));

    const campaignInput: CampaignInput = {
        campaign_type: "grow_instagram_engagement",
        id: draft,
        brand,
        record: null,
        captions: null,
        assets,
        goal,
        link: selectedLink,
        caption,
        cta,
        campaignName,
        fbAdAccount,
        fbPixel,
        fbPage,
        igPage,
        igMedia,
        budget,
        startDate,
        selectedCampaignType,
        endDate,
        interestTargetingAudiences,
        customAudiences,
        targetedCountries: fixedGeographicTargets,
        targetingDemographics,
        addVisualSelected,
    };

    const handleImproveAudienceTargeting = () => setShowTargetingView(true);
    const handleEditGeographicTargeting = () => setShowTargetingView(true);
    const handleOnErrorPayPalOrder = () => setCreatingCampaign(false)
    const handleClickRefreshButton = () => confirmConnectionSettings({
        fbAdAccount, setPreconnectConfigurations
    })
    const validateCountriesToFix = () => {
        if (countriesToFix.length) {
            targetingWarningModal.openModal()
            return false
        }
        return true
    }

    const igAccount = igPage && igPage.instagramAccount && igPage.instagramAccount.username ?
        `@${igPage.instagramAccount.username}` : ''

    const updateAgeRange = (ageRange: AgeRange) => {
        const { min: minAge, max: maxAge } = ageRange
        const { gender, languages: locales } = targetingDemographics
        const demographics = getFormattedDemographics({ minAge, maxAge, gender, locales })
        saveDemographics(demographics)
        targetingWarningModal.closeModal()
        setCountriesToFix([])
    }

    const removeCountries = () => {
        const newGeographicTargets = removeCountriesUtils(geographicTargets, countriesToFix)
        distributeBudget(newGeographicTargets)

        handleSaveTargetingData()
        setCountriesToFix([])
        targetingWarningModal.closeModal()
    }

    useEffect(() => {
        const countries = validateTargetingCountriesAge(geographicTargets)
        const hasInvalidAge = countries.find(country => targetingDemographics?.age?.min < country.age)
        if (hasInvalidAge) {
            setCountriesToFix(countries)
            targetingWarningModal.openModal()
        }
    }, [geographicTargets, targetingDemographics.age])


    const igUserName = campaignInput.igPage?.instagramAccount?.username
    const igName = campaignInput.igPage?.name
    const igAccountName = igUserName ? igUserName : igName

    return (
        <Container>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DetailsCard
                    objective={selectedCampaignType}
                    linkTitle="Instagram Account"
                    link={igAccount}
                />
            </Grid>
            {!preconnectionConfigurations || preconnectionConfigurations && !preconnectionConfigurations.status && (
                <Grid className={isMobile ? "mb-0" : "mb-4"}>
                    <PreConnectionConfigsCard
                        {...{
                            handleClickRefreshButton
                        }}
                        configs={preconnectionConfigurations}
                    />
                </Grid>
            )}
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <DurationCard {...{ startDate, endDate }} />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <TargetingCard
                    {...{
                        isBelowRecommendedBudget,
                        loadingStep,
                        targetAudiences,
                        customAudiences,
                        geographicTargets,
                        demographicTargets: targetingDemographics,
                        saveDemographics,
                        handleImproveAudienceTargeting,
                        handleEditGeographicTargeting,
                        openTargetingWarningModal: targetingWarningModal.openModal,
                        additionalPageAudiences
                    }}
                />
            </Grid>
            <Grid className={isMobile ? "mb-0" : "mb-4"}>
                <BudgetCard {...{
                    budget,
                    isProUser,
                    freeTrialOver,
                    adAccountName: fbAdAccount ? fbAdAccount.name : ""
                }} />
            </Grid>
            <Grid className="mb-4">
                {/* <GoLiveCard
                    {...{
                        budget,
                        brand,
                        loadingStep,
                        creatingCampaign,
                        setCreatingCampaign,
                        isProUser,
                        campaignInput,
                        allowCampaignCreation,
                        cancelSaving,
                    }}
                    onCancelPayPalOrder={handleOnErrorPayPalOrder}
                    onErrorPayPalOrder={handleOnErrorPayPalOrder}
                    validateCountriesToFix={validateCountriesToFix}
                /> */}
                <GoLiveCard
                    primaryOrganization={primaryOrganization}
                    budget={budget}
                    brand={brand}
                    freeTrialOver={freeTrialOver}
                    creatingCampaign={creatingCampaign}
                    isProUser={isProUser}
                    campaignInput={campaignInput}
                    allowCampaignCreation={allowCampaignCreation}
                    setCreatingCampaign={setCreatingCampaign}
                    cancelSaving={cancelSaving}
                    validateCountriesToFix={validateCountriesToFix}
                    submissionName={`$${budget.toLocaleString("en")} FB/IG Campaign for "${brand.name}"`}
                    submissionDescription={`"@${igAccountName}" (Grow Instagram Engagement)`}
                    checkout_source="Grow Instagram Engagement - Campaign Creation"
                />
            </Grid>
            {Boolean(countriesToFix.length) && <TargetingWarning
                open={targetingWarningModal.open}
                closeModal={targetingWarningModal.closeModal}
                countries={countriesToFix}
                currentAgeRange={{
                    min: targetingDemographics?.age?.min || 0,
                    max: targetingDemographics?.age?.max || 0
                }}
                removeCountries={removeCountries}
                updateAgeRange={updateAgeRange}
            />}
        </Container>
    )
}