import { ToastOptions } from "react-toastify";

export const SHARED_TOAST_OPTIONS: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    closeButton: true,
    style: {
        background: "#07C806",
        color: "#FFFFFF",
        margin: "16px 16px auto 16px",
        fontSize: "16px",
        borderRadius: "8px",
    },
}

export const SHARED_TOAST_OPTIONS_ERROR: ToastOptions = {
    ...SHARED_TOAST_OPTIONS,
    style: {
        background: "#FF0000",
        color: "#FFFFFF",
        margin: "16px 16px auto 16px",
        fontSize: "16px",
        borderRadius: "8px",
    }
}