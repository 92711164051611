import { Fragment, FunctionComponent, ReactElement } from "react"
import { ButtonContainer } from "./styles"
import { LoadingLottie } from "components/Loader/LoadingLottie"
import { ButtonText } from "../Typography"
import { SystemColors } from "types/globalStyles"
import { SymphonyTooltip } from "styles/shared"

interface Props {
  text: string
  loadingText?: string
  disabled?: boolean
  loading?: boolean
  width?: string
  height?: string
  variant?: "dark" | "light" | "error" | "outlined"
  iconLeft?: ReactElement
  iconRight?: ReactElement
  disabledColor?: string
  tooltip?: string
  onClick: () => void
}

const PrimaryButton: FunctionComponent<Props> = ({
  disabled = false,
  loading = false,
  loadingText = "",
  text,
  width = "fit-content",
  height = "fit-content",
  variant,
  iconLeft,
  iconRight,
  tooltip = '',
  disabledColor,
  onClick,
}: Props) => {

  const buttonTextColor = variant === 'outlined' ? SystemColors.ACCENT_COLOR : SystemColors.WHITE_TEXT_COLOR
  return (
    <SymphonyTooltip title={tooltip}>
      <ButtonContainer
        disabled={disabled}
        onClick={onClick}
        width={width}
        height={height}
        variant={variant}
        disabledColor={disabledColor}
      >
        {loading ? (
          <Fragment>
            <LoadingLottie black={variant === 'outlined' ? true : false} height={24} width={24} />
            {loadingText.length > 0 && (
              <ButtonText color={buttonTextColor}>
                {loadingText}
              </ButtonText>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {iconLeft}
            <ButtonText color={buttonTextColor}>{text}</ButtonText>
            {iconRight}
          </Fragment>
        )}
      </ButtonContainer>
    </SymphonyTooltip>
  )
}

export default PrimaryButton
