import { FunctionComponent } from "react";
import { SystemColors } from "types/globalStyles";
import useAdPreviewStyles, { VideoAsImageWarning } from "./styles";
import { Caption } from "../Typography";
import { ReactComponent as QuestionIcon } from "assets/images/question-mark.svg"
import Intercom from "helpers/Intercom";

const VideoAsImageBanner = ({
  borderRadius = 0
}: any) => {

  const classes = useAdPreviewStyles();
  const handleOnClickVideoAsImageWarning = () => {
    //TODO: add intercom link to the article
    Intercom.openAdNotPlayingVideo()
  }

  return (
    <VideoAsImageWarning borderRadius={borderRadius}>
      <Caption color={SystemColors.PRIMARY_TEXT_COLOR}>
        Due to Facebook's restrictions, we can only show an image in this ad preview. Rest assured, your ad will run as a full video in the live campaign.
      </Caption>
      <div className="ml-2">
        <QuestionIcon
          className={classes.circleGray}
          onClick={handleOnClickVideoAsImageWarning}
        />
      </div>

    </VideoAsImageWarning>
  );
};

export default VideoAsImageBanner;