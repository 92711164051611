import { Fragment, FunctionComponent } from 'react';
import { ButtonContainer } from './styles';
import { LoadingLottie } from 'components/Loader/LoadingLottie';
import { ShowTooltip } from '../CustomMenu';
import { ButtonText } from '../Typography';
import { SystemColors } from 'types/globalStyles';

interface Props {
  text: string;
  loadingText?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  width?: string;
  height?: string;
  tooltip?: string;
  textColor?: string;
}

const TertiaryButton: FunctionComponent<Props> = ({
  disabled,
  loading,
  loadingText = "",
  onClick,
  text,
  width = "fit-content",
  height = "fit-content",
  tooltip,
  textColor = SystemColors.ACCENT_COLOR,
}: Props) => {
  const color = disabled ? SystemColors.DISABLED_TEXT_COLOR : textColor as SystemColors
  return (
    <ButtonContainer
      disabled={disabled}
      onClick={onClick}
      width={width}
      height={height}
    >
      {loading ? (
        <Fragment>
          <LoadingLottie height={18} width={18} black />
          <ButtonText color={color}>{loadingText}</ButtonText>
        </Fragment>
      ) : (
        <ShowTooltip tooltip={tooltip}>
          <ButtonText color={color}>{text}</ButtonText>
        </ShowTooltip>
      )}
    </ButtonContainer >
  );
};

export default TertiaryButton;