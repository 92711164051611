import { Switch } from '@mui/material';
import { FunctionComponent } from 'react';
import { useStyles } from './styles';

interface Props {
  checked: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const SwitchButton: FunctionComponent<Props> = ({
  checked,
  onChange,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <Switch
      className={classes.switchButton}
      checked={checked}
      onChange={(e) => onChange?.(e.target.checked)}
      disabled={disabled}
    />
  );
};

export default SwitchButton;
