import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
    paper: {
        width: '50%',
        height: 'fit-content',
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
}));