import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

interface ButtonContainerProps {
  width: string
  height: string
  variant?: "dark" | "light" | "error" | "outlined"
  disabledColor?: string
}

export const ButtonContainer = styled.button<ButtonContainerProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 48px;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  white-space: nowrap;


  background-color: ${({ variant }) => {
    if (variant === "error") return SystemColors.ERROR_COLOR

    if (variant === 'outlined') return 'none'

    return variant === "dark"
      ? SystemColors.ACCENT_COLOR_ON_DARK
      : SystemColors.ACCENT_COLOR
  }};

  border: ${({ variant }) => {

    if (variant === 'outlined') return `1px solid ${SystemColors.ACCENT_COLOR}`

    return 'none'
  }};
  :disabled {
    background: ${({ disabledColor }) => disabledColor || SystemColors.DISABLED_TEXT_COLOR};
    cursor: not-allowed;
  }
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`
