import upperCase from 'lodash/upperCase'
import { SystemColors } from 'types/globalStyles'

const { PAPER_WARNING_CONTAINER, WARNING_COLOR, ERROR_COLOR, SUCCESS, SECONDARY_TEXT_COLOR } = SystemColors
export const backgroundColorsByStatus: Record<string, string> = {
  pending: PAPER_WARNING_CONTAINER,
  denied: '#FFEFEF',
  error: '#FFEFEF',
  paused: PAPER_WARNING_CONTAINER,
  anticipated_puase: PAPER_WARNING_CONTAINER,
  in_review: PAPER_WARNING_CONTAINER,
  to_retry: PAPER_WARNING_CONTAINER,
  with_issues: PAPER_WARNING_CONTAINER,
  active: '#EBFBEB',
  pitched: '#EBFBEB',
  finished: '#E3E3E3',
  completed: '#E3E3E3',
  live: '#EBFBEB',
  live_with_no_stats: '#EBFBEB',
  live_with_issues: PAPER_WARNING_CONTAINER,
  in_review_after_active: '#EBFBEB',
  draft: PAPER_WARNING_CONTAINER,
  not_found: PAPER_WARNING_CONTAINER,
  in_progress: PAPER_WARNING_CONTAINER,
}
export const textColorsByStatus: Record<string, string> = {
  pending: WARNING_COLOR,
  denied: ERROR_COLOR,
  error: ERROR_COLOR,
  paused: WARNING_COLOR,
  anticipated_puase: WARNING_COLOR,
  in_review: WARNING_COLOR,
  to_retry: WARNING_COLOR,
  with_issues: WARNING_COLOR,
  active: SUCCESS,
  pitched: SUCCESS,
  finished: SECONDARY_TEXT_COLOR,
  completed: SECONDARY_TEXT_COLOR,
  live: SUCCESS,
  live_with_no_stats: SUCCESS,
  live_with_issues: SUCCESS,
  in_review_after_active: SUCCESS,
  draft: WARNING_COLOR,
  not_found: WARNING_COLOR,
  in_progress: WARNING_COLOR,
}

export const getAdStatusBackgroundColor = (status: string): string => backgroundColorsByStatus[status] || ''
export const getAdStatusTextColor = (status: string): string => textColorsByStatus[status] || ''

export const getAdLabelByStatus = (status?: string): string => upperCase(status)
