import { toast } from "react-toastify";
import SubscriptionStatusIndicator from "./SubscriptionStatusIndicator";
import { ToastOptions } from "react-toastify";
import Axios from "helpers/Interceptor";
import { Switch } from "@material-ui/core";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import { useState } from "react";
import { BrandSubscriptionDetails } from "types/global";
import { TextButton } from "styles/shared";
import { useHistory } from "react-router-dom";

export const TOAST_OPTIONS: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};

interface Props {
    isAdmin: boolean;
    showEnablePro: boolean;
    reloadBrand: () => void;
    brandSlug: string | null;
    enablePro: boolean;
    subscription?: BrandSubscriptionDetails | null
}

export default function AdminOptions({
    isAdmin,
    reloadBrand,
    brandSlug,
    showEnablePro,
    enablePro,
    subscription
}: Props) {

    const history = useHistory();




    const [loadingPro, setLoadingPro] = useState(false)

    if (!isAdmin) return null

    async function turnUserPro() {
        setLoadingPro(true);
        try {
            if (isAdmin) {
                var adminPro = await Axios.get(`/subscription/brand/${brandSlug}/admin/pro`);
                reloadBrand();
                toast.success("User subscription updated.", TOAST_OPTIONS);
            } else {
                throw "User isn't admin";
            }
        } catch (e) {
            toast.error("Error updating user subscription.", TOAST_OPTIONS);
            console.error(e);
        }
        setLoadingPro(false);
    }

    function createSubscriptionClicker() {
        const isActiveSubscription = subscription?.status === 'active';
        const isForcedByAdmin = subscription?.details?.forced;
        if (subscription && isActiveSubscription && isForcedByAdmin) {
            return (
                <SubscriptionStatusIndicator text="PRO via ADMIN"
                    handleOpenModal={() => {
                        if (subscription.details?.viewSubscriptionUrl) {
                            window.open(subscription.details?.viewSubscriptionUrl!, "_blank")
                        }
                    }}
                />
            )
        }

    }


    return (
        <>
            {createSubscriptionClicker()}

            {showEnablePro && (
                <div className="flex flex-row  items-center mr-auto px-4 w-full justify-start">
                    <Switch
                        disabled={loadingPro}
                        checked={enablePro}
                        onChange={() => turnUserPro()}
                        color="secondary"
                    />
                    <UpgradeToProChip
                        type="label"
                        mainContainerProps={{
                            width: 'auto',
                            flexShrink: '0'
                        }}
                        labelProps={{
                            margin: "0 auto",
                            fontSize: 14,
                            fontWeight: 480,
                            lineHeight: "1.8",
                        }}
                    />
                    {subscription && (
                        <button
                            className="bold w-full h-auto p-2 text-white m-auto text-center"
                            onClick={() => {
                                // Navigate to /settings with the query parameter
                                history.push("/settings?tab=BILLING");

                            }}
                        >
                            💰 Details
                        </button>
                    )}

                </div>
            )}
        </>
    );
}
