import { Fragment, FunctionComponent } from "react";
import { CONVERSIONS_VIDEOS, ConversionsTask } from "types/global";
import useModal from "Hooks/useModal";
import CallToActionCard from "./CallToActionCard";
import VideoModalPortrait from "../VideoModalPortrait";
import { useHistory } from "react-router-dom";

interface Props {
  conversionsTasks?: ConversionsTask[];
  videoUrl: string;
  enableCloseButton?: boolean;
  showCloseButtonAsIcon?: boolean;
  showAsRecommended?: boolean;
  rounded?: boolean;
  hide: () => void;
  padding?: string;
}

const ConversionsCallToAction: FunctionComponent<Props> = ({
  conversionsTasks = [],
  videoUrl,
  enableCloseButton,
  showCloseButtonAsIcon,
  showAsRecommended,
  rounded,
  hide,
  padding
}: Props) => {
  const numberOfTasks = Object.entries(ConversionsTask).length;
  const stepsLeft = numberOfTasks - conversionsTasks.length;
  const started = stepsLeft < numberOfTasks
  const videoModal = useModal();
  const history = useHistory();

  const handleOnClickVideo = () => videoModal.openModal();
  const { title, description } = CONVERSIONS_VIDEOS.onboardingVideo

  const redirect = (url: string, taskName: string) => {
    history.push(url, { taskName });
  }

  const handleOnClickFinishSetup = () => {
    redirect("/settings?tab=ADVANCED_SETTINGS", "Setup Conversions API");
  };

  const callToActionMetadata = {
    title,
    videoUrl,
    enableCloseButton,
    showCloseButtonAsIcon,
    showAsRecommended,
    hide,
    rounded,
    onButtonClick: handleOnClickFinishSetup,
    onVideoClick: handleOnClickVideo,
  }

  return (
    <Fragment>
      {started ? (
        <CallToActionCard
          padding={padding}
          description={`You have ${stepsLeft} ${stepsLeft > 1 ? 'steps' : 'step'} left!`}
          buttonText="Finish setup"
          {...callToActionMetadata}
        />
      ) : (
        <CallToActionCard
          padding={padding}
          description={description as string}
          buttonText="Setup"
          {...callToActionMetadata}
        />
      )}
      <VideoModalPortrait
        open={videoModal.open}
        closeModal={videoModal.closeModal}
        subtitle={description as string}
        buttonText="Get Started"
        onClickButton={handleOnClickFinishSetup}
        {...{ videoUrl, title }}
      />
    </Fragment>
  );
};

export default ConversionsCallToAction;