import { LoadingIndicator } from "components/Loader"
import { FunctionComponent } from "react"
import { LoadingContainer } from "../../styles"

const Loading: FunctionComponent = () => (
  <LoadingContainer>
    <LoadingIndicator color="black" height="80px" />
    <p className="text-center mt-4">Loading Pre-Save...</p>
  </LoadingContainer>
)

export default Loading
