import styled from "styled-components"
import { SystemColors } from "types/globalStyles"
import { Options } from "./index"

interface StyledToggleButtonProps {
  value: Options
  selected: Options
  side: "LEFT" | "RIGHT"
}

export const Container = styled.div`
  margin-top: 32px;
  padding: 24px 0px;
  display: flex;
`

export const StyledToggleButton = styled.button<StyledToggleButtonProps>`
  padding: 12px 24px;
  background-color: ${({ selected, value }) =>
    selected === value
      ? "rgba(136, 0, 255, 0.08)"
      : SystemColors.PAPER_CONTAINER};
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: ${({ side }) =>
    side === "LEFT" ? "16px 0px 0px 16px" : "0px 16px 16px 0px"};
  border: 1px solid ${SystemColors.DIVIDER_LINE};
`
