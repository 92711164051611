import { FunctionComponent, useRef } from "react"
import useStyles, { NavContainer, ChevronContainer, Container } from "./styles"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperClass, { Navigation } from "swiper"
import Testimonial from "./Components/Testimonial"
import { ReactComponent as ChevronIcon } from "assets/images/chevronRight.svg"

interface Props {
  testimonials: {
    action?: string
    quantity?: string
    units?: string
    testimony: string
    witness: string
    occupation?: string
    picture?: string
  }[]
  slideHeight?: string
  slidePadding?: string
}

const TestimonialsCarousel: FunctionComponent<Props> = ({
  testimonials,
  slideHeight,
  slidePadding,
}: Props) => {
  const classes = useStyles()
  const swiperElRef = useRef<SwiperClass | null>(null)

  const handleNext = () => swiperElRef.current?.slideNext()
  const handlePrev = () => swiperElRef.current?.slidePrev()

  return (
    <Container>
      <Swiper
        loop
        onSwiper={(swiper) => {
          swiperElRef.current = swiper
        }}
        slidesPerView={1}
        spaceBetween={0}
        pagination={{ clickable: false }}
        modules={[Navigation]}
        className={classes.root}
      >
        {testimonials.map((testimony, index) => (
          <SwiperSlide key={`swiper-slide-${index}`}>
            <Testimonial {...testimony} {...{ slideHeight, slidePadding }} />
          </SwiperSlide>
        ))}
      </Swiper>
      <NavContainer>
        <ChevronContainer onClick={handlePrev}>
          <ChevronIcon className={classes.swiperPrev} />
        </ChevronContainer>
        <ChevronContainer onClick={handleNext}>
          <ChevronIcon className={classes.swiperNext} />
        </ChevronContainer>
      </NavContainer>
    </Container>
  )
}

export default TestimonialsCarousel
