import { Dialog, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CurrencyText,
} from './style';
import { ReactComponent as MoneyIcon } from 'assets/images/money-purple.svg';
import { toast, ToastOptions } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import LowDailySpendContainer from '../../LowDailySpendContainer';
import debounce from 'lodash/debounce';
import { WarningCodesEditBudget } from '../../LowDailySpendContainer/constants';
import moment from 'moment';
import Axios from 'helpers/Interceptor';
import some from "lodash/some";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading_White.json"
import Lottie from "react-lottie"
import FB from 'helpers/FB';
import * as Sentry from "@sentry/react";
import { track } from 'analytics';
import { SHARED_TOAST_OPTIONS } from '../utils';
import { ButtonCancel, ButtonConfirm, ButtonsContainer, InputContainer, ModalContainer, useStyles } from '../../../../../../components/shareable/Modal/styles';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CurrencyCodesType } from 'types/global';
import { DEFAULT_CURRENCY } from 'pages/post-auth/MarketingPage/constants';
import { getConvertedCurrency } from 'services/symphonyApi';

const UpdatingCampaignLabel: FunctionComponent = () => (
  <Grid container alignItems="center" alignContent="center">
    <Grid item xs={2}>
      <Lottie
        height={16}
        width={16}
        options={{
          loop: true,
          autoplay: true,
          animationData: SymphonyLoadingLottie,
        }}
      />
    </Grid>
    <Grid item xs>
      <p>Updating campaign...</p>
    </Grid>
  </Grid>
)



interface Props {
  open: boolean;
  campaignId: number;
  budget: number;
  totalSpent: number;
  minimalAmountPerDay: number;
  startDate: string;
  endDate: string;
  currency?: CurrencyCodesType;
  toastOptions?: ToastOptions;
  trackObject?: Record<string, any>;
  onErrorHandler?: () => void;
  closeModal: () => void;
  reload: (budget: number) => void;
}

const FACEBOOK_MINIMAL_PERCENTAGE = 1.10;

const EditBudgetModal: FunctionComponent<Props> = ({
  open,
  campaignId,
  toastOptions,
  budget,
  totalSpent,
  minimalAmountPerDay,
  startDate,
  endDate,
  currency = DEFAULT_CURRENCY,
  trackObject,
  onErrorHandler,
  closeModal,
  reload,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isValidBudget, setIsValidBudget] = useState<boolean>(false)
  const [amountBudget, setAmountBudget] = useState<number>(budget)
  const [warningError, setWarningError] = useState<WarningCodesEditBudget | null>(null)
  const [newDailySpend, setNewDailySpend] = useState<number | null>(null)
  const [convertedMinimalAmountPerDay, setConvertedMinimalAmountPerDay] = useState<number>(minimalAmountPerDay)
  const inputRef = useRef<HTMLInputElement>()
  const classes = useStyles()
  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const campaignDays = Math.abs(moment(endDate).diff(startDate, 'days'))
  const facebookRuleMinimal = Math.ceil(totalSpent * FACEBOOK_MINIMAL_PERCENTAGE)
  const totalMinimalAmountPerDay = convertedMinimalAmountPerDay * campaignDays
  const suggestedBudget = Math.max(facebookRuleMinimal, totalMinimalAmountPerDay)

  useEffect(() => {
    if (currency !== DEFAULT_CURRENCY) {
      setLoading(true)
      convertMinimalAmountPerDay()
      setLoading(false)
    }
  }, [minimalAmountPerDay])

  const debouncedChangeHandler = useMemo(
    () =>
      debounce((newBudget: number) => {
        if (newBudget === budget) {
          setIsValidBudget(false);
          setWarningError(null)
          return;
        }
        const costPerDay = newBudget / campaignDays;
        if (newBudget < facebookRuleMinimal) {
          setIsValidBudget(false)
          setWarningError(WarningCodesEditBudget.FACEBOOK_MINIMAL)
          return;
        }
        if (costPerDay < convertedMinimalAmountPerDay) {
          setIsValidBudget(false);
          setWarningError(WarningCodesEditBudget.DAILY_MINIMAL)
          return;
        }
        setIsValidBudget(true)
        setNewDailySpend(costPerDay)
        if (newBudget < budget) {
          setWarningError(WarningCodesEditBudget.DECREASE)
        } else {
          setWarningError(WarningCodesEditBudget.INCREASE)
        }
      }, 300),
    []
  );

  const handleConfirmEditBudget = async () => {
    setLoading(true)
    const options = toastOptions || SHARED_TOAST_OPTIONS
    const marginToast = setResponsiveView ? "24px 16px auto 16px" : "40px 22px auto"

    let access_token = null
    try {
      const authResp = FB().getAuthResponse()
      if (authResp && authResp.accessToken) {
        access_token = authResp.accessToken
      }
    } catch (e) {}

    try {
      const response = await Axios.post(`campaign/${campaignId}/budget`, {
        budget: amountBudget, access_token,
      });
      if (some([!response, !response.data, !response.data.data])) {
        throw new Error("Error editing budget on campaign");
      }
      reload(amountBudget)
      toast.success('Budget updated!', {
        ...options,
        style: {
          ...options.style,
          margin: marginToast
        }
      })

      track('Saved Edit Budget', {
        ...trackObject,
        opened: 'Edit Budget Modal',
      })
    } catch (e) {
      Sentry.captureException(`Error editing budget in campaign ${campaignId}: ${e}`)
      onErrorHandler?.()
    } finally {
      closeModal()
      setLoading(false)
    }
  }

  const setAmountInput = (amount: number) => {
    setAmountBudget(amount)
    debouncedChangeHandler(amount)
    inputRef.current?.focus()
  }

  const handleChangeBudget = () => {
    setIsValidBudget(false)
    setWarningError(null)
    if (inputRef.current) {
      const amount = Number(inputRef.current.value) || 0;
      setAmountBudget(amount)
      debouncedChangeHandler(amount)
      inputRef.current.value = amount.toString();
    }
  }

  const convertMinimalAmountPerDay = async () => {
    const convertedValue = await getConvertedCurrency({ amount: minimalAmountPerDay, from: DEFAULT_CURRENCY, to: currency });
    setConvertedMinimalAmountPerDay(convertedValue)
  }

  return (
    <Dialog
      open={open}
      classes={{
        root: classes.rootDialog,
      }}
    >
      <ModalContainer
        width={setResponsiveView ? "auto" : "678px"}
        padding={setResponsiveView ? "32px 24px" : "32px"}
      >
        <HeaderModal
          HeaderIcon={MoneyIcon}
          closeModal={closeModal}
          title='Edit budget'
          subtitle='After editing your campaign, it may go into review again while your changes are reflected.'
        />
        <InputContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: <CurrencyText>{currency}</CurrencyText>,
              startAdornment: <CurrencyText>{getSymbolFromCurrency(currency)}</CurrencyText>
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText
              },
            }}
            className={classes.textField}
            variant="outlined"
            fullWidth
            type="number"
            value={amountBudget}
            defaultValue={budget}
            onChange={handleChangeBudget}
            inputRef={inputRef}
          />
        </InputContainer>
        {warningError && (
          <LowDailySpendContainer
            clickUpdate={setAmountInput}
            currentBudget={budget}
            selectedBudget={amountBudget}
            suggestedBudget={suggestedBudget || 0}
            dailySpend={newDailySpend || 0}
            currency={currency}
            errorCode={warningError}
            totalSpent={totalSpent}
          />
        )}
        <ButtonsContainer
          flexDirection={setResponsiveView ? "column-reverse" : "row"}
        >
          <ButtonCancel
            disabled={loading}
            onClick={closeModal}
            width={setResponsiveView ? "100%" : "fit-content"}
          >
            Cancel
          </ButtonCancel>
          <ButtonConfirm
            disabled={loading || !isValidBudget}
            style={{ cursor: loading || !isValidBudget ? "not-allowed" : "pointer" }}
            onClick={handleConfirmEditBudget}
            width={setResponsiveView ? "100%" : "50%"}
          >
            {loading ? <UpdatingCampaignLabel /> : "Update campaign"}
          </ButtonConfirm>
        </ButtonsContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default EditBudgetModal;
