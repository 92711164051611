import { Tab, Tabs } from "@mui/material";
import { CSSProperties, Fragment, FunctionComponent, useContext, useState } from "react";
import useStyles from "./styles";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import GemIcon from "../ProCallouts/GemIcon";
import { checkIfIsProTier } from "utils";

interface TabProps {
    data: {
        value: string,
        label: string | JSX.Element
        isProOnly?: boolean
    }[],
    selectedValue: string,
    ariaLabel?: string
    gap?: number,
    tabContainerStyle?: CSSProperties,
    tabContentStyle?: CSSProperties,
    hideTabs?: number[],
    onProTabClick?: () => void,
    onChange: (newValue: string) => void,
}
export default function SymphonyTabs(props: TabProps) {
    const {
        selectedValue,
        data,
        gap,
        ariaLabel,
        tabContainerStyle,
        tabContentStyle,
        hideTabs,
        onProTabClick,
        onChange,
    } = props

    const { currentBrand } = useContext(CurrentBrandContext)
    const classes = useStyles({ gap })
    const isProUser = checkIfIsProTier(currentBrand)

    const handleProTab = (newValue: string) => {
        const isProTab = data.find((tab) => tab.value === newValue)?.isProOnly
        if (isProTab && !isProUser && onProTabClick) onProTabClick()
    }

    return (
        <Tabs
            className={classes.tabs}
            scrollButtons="auto"
            variant="scrollable"
            value={selectedValue}
            onChange={(e, value) => onChange(value)}
            aria-label={ariaLabel}
            allowScrollButtonsMobile={true}
            TabIndicatorProps={{
                style: {
                    backgroundColor: "#8800FF",
                }
            }}
        >
            {data.map((item, index) => {
                if (hideTabs && hideTabs.find((tab) => tab === index)) return null
                return (
                    <Tab
                        disableRipple
                        key={index}
                        value={item.value}
                        style={tabContainerStyle}
                        onClick={() => handleProTab(item.value)}
                        label={
                            <div className='flex items-center gap-1 capitalize font-medium text-base px-4 py-2' style={tabContentStyle}>
                                {item.isProOnly && <GemIcon color='purple' />}
                                <p style={{ lineHeight: 'normal' }}>{item.label}</p>
                            </div>
                        }
                    />
                )
            })}
        </Tabs>
    )
}

type FooProps = {
    hidden: boolean,
    children: JSX.Element;
}

export const TabContent: FunctionComponent<FooProps> = (props) => {
    const {
        hidden
    } = props

    return hidden ? <Fragment /> : props.children
}