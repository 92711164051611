import { FunctionComponent, RefObject } from 'react';
import { TooltipBoxStyled } from './styles';

interface Props {
  children?: React.ReactNode;
  reference: RefObject<HTMLDivElement>;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

const TooltipBox: FunctionComponent<Props> = ({
    children, reference, top, left, right, bottom
}: Props) => {
  return (
    <TooltipBoxStyled
      ref={reference}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
    >
      {children}
    </TooltipBoxStyled>
  )
}

export default TooltipBox;