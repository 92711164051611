import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import Loaderv2 from "components/Loader/v2";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";
import React from "react";
import {
  Container,
  OutlinedButton,
  StyledLabel,
  TextButton,
} from "styles/shared";

export default function BrandsListItemModal(props: {
  name: string;
  image: string;
  slug: string;
  isPro: boolean;
  onClickListItem?: (slug: string) => void;
}) {
  const { name, image, slug, isPro, onClickListItem } =
    props;

  const styles: Record<string, React.CSSProperties> = {
    image: {
      width: "60px",
      height: "60px",
      borderRadius: "99%",
      marginRight: "16px",
    },
  };


  return (
    <Container
      borderBottom="1px solid #E5E7EB"
      padding="12px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      onClick={() => onClickListItem && onClickListItem(slug)}
    >
      <Container
        display="flex"
        flexDirection="row"
        gap="4px"
        alignItems="center"
        justifyContent="center"
      >
        <img style={styles.image} src={image}></img>
        <Container
          display="flex"
          flexDirection="column"
          gap="6px"
          justifyContent="center"
          alignItems="flex-start"
        >
          <StyledLabel fontSize={16}>{name}</StyledLabel>
        </Container>
      </Container>
      <Container display="flex" alignItems="center" gap="8px">
        {isPro && (
          <div>
            <UpgradeToProChip
              type="label"
              mainContainerProps={{
                margin: "0",
              }}
            />
          </div>
        )}
      </Container>
    </Container>
  );
}
