import { Button, Theme, makeStyles, withStyles } from "@material-ui/core";
import styled from "styled-components";
import { SystemColors } from "types/globalStyles";

const { ACCENT_COLOR, PAPER_CONTAINER, SECONDARY_TEXT_COLOR, DISABLED_TEXT_COLOR, PAPER_LIGHT_CONTAINER } = SystemColors;

export const useStyles = makeStyles((theme: Theme) => ({
    questionMarkIcon: {
        '& circle': {
            fill: SECONDARY_TEXT_COLOR
        }
    }
}));

interface InstagramPostButtonProps {
    postSelected: boolean;
    disabled: boolean;
}

export const SuperboostButton = withStyles((theme: Theme) => ({
    root: {
        width: '100%',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),

        borderRadius: 0,
        backgroundColor: "none",
        textTransform: 'none',
        shadowBox: "none",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
}))(Button);

export const InstagramPostButton = styled.button<InstagramPostButtonProps>`
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    border-radius: 9999px;
    height: 24px;
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin-top: 0px;
    padding: 8px;
    border-width: ${({ postSelected }) => postSelected ? '0px' : '1px'};
    border-color: ${ACCENT_COLOR};
    color: ${({ postSelected }) => postSelected ? PAPER_CONTAINER : ACCENT_COLOR};
    background-color: ${({ postSelected }) => !postSelected ? PAPER_CONTAINER : ACCENT_COLOR};
    .lg\:w-64 {
        width: 256px;
    }
    :disabled{
        background-color: ${PAPER_CONTAINER};
        border-color: ${DISABLED_TEXT_COLOR};
        color: ${DISABLED_TEXT_COLOR};
        cursor: not-allowed;
    }
`;

export const PostBannerContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: ${PAPER_LIGHT_CONTAINER};
`;