/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useContext,
} from "react";
import MainContentContainer from "components/shareable/MainContentContainer";
import Axios from "helpers/Interceptor";
import mixpanel from "mixpanel-browser";
import { track } from "analytics";
import dayjs from "dayjs";
import ThickLightIcon from "assets/images/tick-light.png";
import {
  CampaignCreationError,
  CampaignCreationErrorCodes as ErrorCodes,
  IObjectKeys,
  CreatedCampaign,
  FbSuggestion,
  FBCustomAudience,
  FacebookPageResponse,
  OrganizationData,
} from "types/global";
import { useHistory } from "react-router-dom";
import FB from "helpers/FB";
import { NavbarContext } from "../Data/NavbarProvider";
import { InstagramPost } from "components/shareable/InstagramPosts/types";
import { getNotRemovableOptions } from "./TargetingView/Components/AutoCompleteAudiences/utils";
import uniqBy from "lodash/uniqBy"
import { MarketingDataContext } from "../Data/Provider";
import { fixGeographicTargets } from "utils";
import { STEPS } from "./utils";
import useStyles from "./styles"
import { getTargetingViewGenre } from "../Components/TargetingView/utils";
import { SystemColors } from "types/globalStyles";
import { GEOGRAPHY_TARGETING_DEFAULTS } from "../../../../constants";
import UpgradeToProSucceededModal from "components/shareable/UpgradeToProSucceededModal";

const dev = process.env.NODE_ENV !== 'production'

const errorsWithTabChange = [
  { code: ErrorCodes.FB_BUDGET_TOO_LOW, tab: "budget" },
  { code: ErrorCodes.FB_ASSETS_NOT_FOUND, tab: "creative" },
  { code: ErrorCodes.FB_RECORD_NOT_FOUND, tab: "record" },
];

interface CreatingCampaignProps {

  error: CampaignCreationError;
  setSelectedTab: Dispatch<SetStateAction<STEPS | null | undefined>>;
  handleRetry: () => void;
  setFinished: Dispatch<SetStateAction<boolean>>;
  allowCampaignCreation: Dispatch<SetStateAction<boolean>>;
}

interface FinishedStateProps {
  recentlyCreatedCampaign: CreatedCampaign | null;
  brand: any;
  budget: number;
  startDate: any;
  endDate: any;
  assets: any;
  showWelcomeProModal: boolean;
}

interface CampaignCreationProps {
  draft: string | null;
  setupType: string;
  brand: any;
  goal: string;
  budget: number;
  primaryOrganization?: OrganizationData;
  startDate: any;
  endDate: any;
  assets: any;
  caption: string;
  campaignName: string;
  link: string;
  cta: any;
  fbPage: any;
  fbBusinessManager?: any;
  fbPixel: any;
  igPage: any;
  igMedia?: InstagramPost;
  fbAdAccount: any;
  customAudiences?: FBCustomAudience[];
  finished: boolean;
  recentlyCreatedCampaign: CreatedCampaign | null;
  fbSuggestions: FbSuggestion[];
  additionalPageAudiences?: FacebookPageResponse[];
  refreshCampaigns: () => void;
  createdCampaign: (campaign: any) => void;
  setSelectedTab: Dispatch<SetStateAction<STEPS | null | undefined>>;
  setFinished: Dispatch<SetStateAction<boolean>>;
  allowCampaignCreation: Dispatch<SetStateAction<boolean>>;
  conversionsEnabled: boolean;
  conversionsEventName: string;
  reloadBrand: () => void;
  reloadBrands: () => void;
}

const removeFunctionsFromProps = ([_key, value]: [string, unknown]) =>
  typeof value !== "function";


const getRemovedFunctionsFromProps = (props: CampaignCreationProps) => {
  const clonedProps = Object.assign({}, props);

  return Object.entries(clonedProps).filter(removeFunctionsFromProps);
};

const DEFAULT_ERROR_TITLE = "An error occurred. Try again later."

const CreatingCampaignState: FunctionComponent<CreatingCampaignProps> = ({
  error,
  setSelectedTab,
  handleRetry,
  setFinished,
  allowCampaignCreation,
}: CreatingCampaignProps) => {
  const classes = useStyles()
  const isError = error?.isError;
  const redirectUrl = error?.data?.error?.link;
  const errorProblemTitle = error?.data?.error?.title || DEFAULT_ERROR_TITLE;
  const suggestionMessage = error?.data?.error.message;
  const canBeRetried = error?.data?.error?.canBeRetried;
  const errorCode = error?.data?.error?.code;
  const [tabError] = errorsWithTabChange.filter(
    (err) => err.code === errorCode
  );

  const handleFixIssue = () => {
    if (!redirectUrl) {
      const tab = tabError?.tab
      setSelectedTab(tab ? tab as STEPS : STEPS.CREATIVE);
      setFinished(false);
      allowCampaignCreation(false);
    }
  };

  return (
    <MainContentContainer backgroundColor={SystemColors.PAPER_BACKGROUND_CONTAINER} className={classes.campaignCreationMainContainer}>
      <div className="card-content bg-white shadow-none lg:shadow-lg">
        <div className="top-wrapper">
          <div className="card-head relative">
            <h4 className="text-center text-dark lg:text-white text-2xl al">
              Creating Campaign...
            </h4>
          </div>
        </div>
        <div className="sy-card px-5 lg:px-10">
          <div className="">
            <h5 className="text-dark font-bold text-lg">
              Your campaign is being created
            </h5>
            <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
              Symphony is automatically creating the ads, audiences, and
              optimizations to increase link clicks.
            </p>
          </div>
        </div>
        <div className="sy-card px-5 lg:px-10">
          <div className="flex">
            <div className=" flex-none loading-blk">
              <div className="svg-loader">
                <svg
                  className="svg-container"
                  height="36"
                  width="36"
                  viewBox="0 0 100 100"
                >
                  <circle className="loader-svg bg" cx="50" cy="50" r="45" />
                  <circle
                    className="loader-svg animate"
                    cx="50"
                    cy="50"
                    r="45"
                  />
                </svg>
              </div>
            </div>
            <div className="px-3 items-center flex">
              <h5 className="text-dark text-left">
                Symphony is creating your campaign...
              </h5>
            </div>
          </div>
        </div>
        {dev ? <button onClick={handleRetry}>Submit Campaign</button> : null}
        {isError && (
          <React.Fragment>
            <div className="px-5 lg:px-10 text-center p-4 lg:p-4 bg-yellow-500 border-b border-backgound-100">
              <p className="text-white">{errorProblemTitle}</p>
              <br />
              {suggestionMessage && <p className="text-white">{suggestionMessage}</p>}
            </div>
            {(redirectUrl || tabError || isError) && (
              <a
                onClick={handleFixIssue}
                href={redirectUrl}
                target="_blank"
                className={`w-full text-center p-4 text-primary font-bold ${canBeRetried ? "border-b" : ""
                  } border-backgound-100 cursor-pointer`}
                rel="noreferrer"
              >
                Fix This Issue
              </a>
            )}
            {canBeRetried && (
              <button
                type="button"
                onClick={handleRetry}
                className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-b border-backgound-100"
              >
                Try Again
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    </MainContentContainer>
  );
};

const FinishedState: FunctionComponent<FinishedStateProps> = ({
  recentlyCreatedCampaign,
  brand,
  budget,
  startDate,
  endDate,
  assets,
  showWelcomeProModal
}: FinishedStateProps) => {
  const classes = useStyles()
  const {
    nextButtonHandler,
    setNextButtonHandler,
  } = useContext(NavbarContext);

  const history = useHistory();
  const [openWelcomeProModal, setOpenWelcomeProModal] = useState(showWelcomeProModal);

  const handleClickSeeCampaign = () => {
    history.push(`/marketing/campaigns/${recentlyCreatedCampaign?.id}`);
  };

  useEffect(() => {
    mixpanel.people.increment({
      submitted_marketing_campaign: 1,
      submitted_ig_campaign: 1,
    });

    track("Submitted Campaign", {
      type: "increase_link_clicks",
      brand_id: brand.id,
      brand_name: brand.name,
      brand_slug: brand.slug,
      budget: budget,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
      creative_assets: assets.length,
    });
  }, []);

  useEffect(() => {
    if (showWelcomeProModal) {
      setOpenWelcomeProModal(true);
    }
  }, [showWelcomeProModal])

  useEffect(() => {
    if (recentlyCreatedCampaign) {
      setNextButtonHandler({
        handler: handleClickSeeCampaign,
        tab: nextButtonHandler.tab,
        show: true,
        text: 'See Your Campaign',
        disabled: false,
      })
    }
  }, [recentlyCreatedCampaign]);

  return (
    <>
      <MainContentContainer backgroundColor={SystemColors.PAPER_BACKGROUND_CONTAINER} className={classes.campaignCreationMainContainer}>
        <div className="card-content bg-white shadow-none lg:shadow-lg">
          <div className="top-wrapper">
            <div className="card-head relative">
              <h4 className="text-center text-dark lg:text-white text-2xl al">
                Campaign created!
              </h4>
            </div>
          </div>
          <div className="px-6 text-center mx-auto py-4 sm:px-20">
            <img
              alt="tick-light.png"
              className="mx-auto m-10"
              src={ThickLightIcon}
            />
            <h5 className="font-bold text-xl">
              Your campaign has been pushed.
            </h5>
            <p>
              Your campaign to increase link clicks has been submitted. Once
              approved by Instagram, it will start running automatically.
            </p>
          </div>
          <div>
            <div className="text-center pb-4 px-4 lg:px-10">
              <button
                className="btn-primary w-auto px-3 "
                onClick={handleClickSeeCampaign}
              >
                See Your Campaign
              </button>
            </div>
          </div>
        </div>

      </MainContentContainer>
      <UpgradeToProSucceededModal
        dialogProps={{
          open: openWelcomeProModal,
          onClose: () => setOpenWelcomeProModal(false),
        }}
        isOrganization={true}
        brandSlug={brand.slug}
        brandName={brand.name}
        brandImage={brand.image}
      />
    </>
  )
};

const IS_MANUAL = true;
const MAX_RETRIES = 5;
const NOT_REMOVABLE = "NOT_REMOVABLE"

const CampaignCreationPage: FunctionComponent<CampaignCreationProps> = (
  props: CampaignCreationProps
) => {
  const {
    targets: targetingViewGeographicTargets,
    audiences: targetingViewAudiences,
    demographics: targetingDemographics,
  } = useContext(MarketingDataContext)
  const {
    brand,
    caption,
    cta,
    link,
    campaignName,
    assets,
    budget,
    startDate,
    endDate,
    finished,
    recentlyCreatedCampaign,
    fbSuggestions,
    createdCampaign,
    setSelectedTab,
    setFinished,
    allowCampaignCreation,
    draft,
    conversionsEnabled,
    conversionsEventName,
    primaryOrganization,
    reloadBrand,
    reloadBrands
  } = props;
  const genres = brand?.connections?.spotify_artist_page?.genres;
  const genre = getTargetingViewGenre(genres);
  const notRemovableOptions = getNotRemovableOptions(genre);
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id");
  const targetAudiences = !!targetingViewAudiences.length ? targetingViewAudiences : initialTargetingAudiences;
  const geographicTargets = !!targetingViewGeographicTargets.length ? fixGeographicTargets(targetingViewGeographicTargets) : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS);
  const interestTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));

  const [error, setError] = useState<CampaignCreationError>({
    isError: false,
  });

  const [organizationUpgradeStatus, setOrganizationUpgradeStatus] = useState(false);

  const saveCampaignDetails = async (
    retries?: number,
    isManualRetry?: boolean
  ) => {
    const updatedRetries = (retries || 0) + 1;
    const removedFunctionsFromProps = getRemovedFunctionsFromProps(props);
    const campaignInput = Object.fromEntries(removedFunctionsFromProps);
    const localStorageItem = localStorage.getItem("facebook_page") || "{}";
    const facebookPage = JSON.parse(localStorageItem);

    try {
      const authResp = FB().getAuthResponse();
      const response = await Axios.post(
        `/campaign/brand/${brand.id}/facebook`,
        {
          page_access_token: facebookPage?.access_token,
          access_token: authResp.accessToken,
          campaign_id: draft,
          isOrganizationUpgrade: brand.tier !== 'pro' && !!(primaryOrganization?.status === 'ready'),
          campaign_input: {
            ...campaignInput,
            interestTargetingAudiences,
            targetedCountries: geographicTargets,
            targetingDemographics
          },
        },
        {
          timeout: 90000,
        }
      );

      createdCampaign(response?.data?.data?.campaign);
      reloadBrand()
      reloadBrands()

      if (primaryOrganization && primaryOrganization.status === 'ready') {
        if (response?.data?.data?.organizationUpgradeSuccess) {
          // if the server returns teling us the user successffully upgraded to pro, 
          // then show the "welcome to pro modal"
          // TODO: ensure this doesnt show up if the user is already pro
          setOrganizationUpgradeStatus(true);
        }
      }
    } catch (e: unknown) {

      const error = e as IObjectKeys;
      const errorCode = error?.response?.data?.data?.error?.code;
      console.log('error', error)
      console.log('errorCode', errorCode)

      const retryConditions = [
        errorCode === ErrorCodes.FB_VIDEO_STILL_PROCESSING,
        updatedRetries <= MAX_RETRIES,
        !isManualRetry,
      ];
      const retry = retryConditions.every((condition) => Boolean(condition));
      console.error("Error saving campaign", error);

      if (retry) {
        track("Campaign Submission - Retry", {
          type: "increase_link_clicks",
          brand_id: brand.id,
          brand_name: brand.name,
          brand_slug: brand.slug,
          budget: budget,
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD"),
          creative_assets: assets.length,
          error: error?.response?.data,
          link: link,
          cta: cta.value,
          caption: caption,
          conversionsEnabled,
          conversionsEventName,
        });

        setTimeout(() => {
          saveCampaignDetails(updatedRetries);
        }, 5000);

        setError({ isError: false });
      } else {
        setError(error?.response?.data || { isError: true });
        track("Campaign Submission - Error", {
          type: "increase_link_clicks",
          brand_id: brand.id,
          brand_name: brand.name,
          brand_slug: brand.slug,
          budget: budget,
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD"),
          creative_assets: assets.length,
          error: error?.response?.data,
          link: link,
          cta: cta.value,
          caption: caption,
          conversionsEnabled,
          conversionsEventName,
        });
      }
    }
  };

  const handleRetry = () => {
    saveCampaignDetails(undefined, IS_MANUAL);
  };

  useEffect(() => {
    saveCampaignDetails();
  }, []);

  return !finished ? (
    <CreatingCampaignState
      {...{
        error,
        setSelectedTab,
        handleRetry,
        setFinished,
        allowCampaignCreation,
      }}
    />
  ) : (
    <FinishedState
      {...{
        brand,
        budget,
        startDate,
        endDate,
        assets,
        recentlyCreatedCampaign,
      }}
      showWelcomeProModal={organizationUpgradeStatus}
    />
  );
};

export default CampaignCreationPage;
