import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { getColumnDefinition, getGroupingColumDefinition } from "./utils";
import { LocationDetail } from "../MarketsDetail";
import useStyles from "./styles";
import { DataGridPro, GRID_TREE_DATA_GROUPING_FIELD } from "@mui/x-data-grid-pro";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { CurrencyCodesType } from "types/global";

interface Props {
  locations: LocationDetail[]
  showOverall: boolean
  loading: boolean
  campaignStatus: string
  currency?: CurrencyCodesType
  hasConversions: boolean
  openConfirmationModal: () => void
  selectLocation: Dispatch<SetStateAction<LocationDetail>>
}

const LocationsTable: FunctionComponent<Props> = ({
  locations,
  showOverall,
  loading,
  campaignStatus,
  currency,
  hasConversions,
  openConfirmationModal,
  selectLocation,
}: Props) => {
  const theme = useTheme()
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))
  const classes = useStyles()
  const marketColumnWidth = locations.find((location) => location.market && location.market.length > 14) ? 240 : 120
  const columns = getColumnDefinition(marketColumnWidth, hasConversions, currency)
  const groupingColumns = getGroupingColumDefinition(openConfirmationModal, selectLocation, showOverall, campaignStatus)

  return (
    <DataGridPro
      initialState={{
        sorting: {
          sortModel: [{ field: 'clicks', sort: 'desc' }],
        },
      }}
      treeData
      loading={loading}
      columns={columns}
      rows={locations}
      getTreeDataPath={(row) => row.path}
      autoHeight
      rowHeight={58}
      hideFooterPagination
      hideFooter
      classes={{ root: classes.datagridRoot }}
      isGroupExpandedByDefault={(_node) => false}
      pinnedColumns={setResponsiveView ? { left: [] } : { left: [GRID_TREE_DATA_GROUPING_FIELD] }}
      groupingColDef={groupingColumns}
    />
  );
}

export default LocationsTable