import LinkButton from 'components/shareable/LinkButton';
import React, { Fragment } from 'react';
import { EditPlatformContainer } from './MarketingPage/styles';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';


interface FacebookConnectionProps {
    update: () => void;
    selected: any;
    labelClassName?: string;
    showProIndicator?: boolean;
}

const FacebookGenericSelectorView: React.FC<FacebookConnectionProps> = (props: FacebookConnectionProps) => {
    const { selected, update, showProIndicator } = props;

    return (
        <Fragment>
            {selected?.id ? (
                <EditPlatformContainer>
                    <p className="pl-6 pr-3 text-fontColor-100">
                        {selected && selected.name}
                    </p>
                    <LinkButton onClick={update} text='Edit' />
                </EditPlatformContainer>
            ) : (
                <div className='flex flex-row items-center'>
                    {showProIndicator && (
                        <div className='mr-1'>
                            <UpgradeToProChip
                                type="gem"
                                color="purple"
                                width={16}
                                height={16} />
                        </div>

                    )
                    }
                    <LinkButton text='Connect' onClick={update} />
                </div>
            )}
        </Fragment >
    )
}

export default FacebookGenericSelectorView;