import FB from "helpers/FB";
import Axios from "helpers/Interceptor";

export const connectUserToFacebook = async (args: {
  brandId: number,
  brandSlug: string,
  brandName: string
}) => {
  const { brandId, brandSlug, brandName } = args
  let logged_in_fb_user = null;
  const authResp = FB().getAuthResponse()
  if (authResp && authResp.accessToken) {
    logged_in_fb_user = authResp.accessToken
  }
  if (logged_in_fb_user) {
    await Axios.put(`/brand/${brandSlug}/connect`, {
      id: brandId,
      name: brandName,
      service: 'logged_in_fb_user',
      value: {
        logged_in_fb_user,
      }
    })
  }
}