// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { HowToPopupPill } from "../HowToPopup";
import ListComponent from "components/marketing/HelperPopup/list-section";
import BadgeContainer from "../Components/BadgeCampaignTypeContainer/BadgeContainer";
import PLATFORMS_BADGE_ICONS from "../Components/BadgeCampaignTypeContainer/BADGE_ICONS";
import ContactUsHelpPopupSection from "components/marketing/HelperPopup/contact-us";

interface HelperPopup {

}

export default function VideoAdsHelperPopup(props: HelperPopup) {

    return (
        <>

            <div className=" mb-6">
                <p className="text-lg ">The "Increase Video Views" tool is the perfect way to grow views on a YouTube video using Google's YouTube Ads platform. </p>
                <div className="flex mt-3 text-sm gap-3">
                    <BadgeContainer text="Runs YouTube Ads" imageUrls={[
                        PLATFORMS_BADGE_ICONS["youtube"]
                    ]} />
                </div>
            </div>

            <ListComponent
                title="When to use it:"
                bulletPoints={[
                    "You want to grow views on your videos by finding new, highly-targeted fans that may watch, comment, and subscribe."
                ]}
            />

            <ListComponent
                title="The Symphony Advantage:"
                bulletPoints={[
                    "Symphony automatically applies best practices when running your YouTube Ads campaign to drive highly-targeted views at the lowest cost.",
                    "Our AI looks at your YouTube data and the targeted channels you add in to optimize ads better.",
                    <>Compared to setting up ads yourself, Symphony's YouTube ads tend to have a <span className="underline">4x cheaper cost-per-view</span>.</>
                ]}
            />

            <ListComponent
                title="What you need to get started:"
                bulletPoints={[
                    "Your video should be out on YouTube and publicly visible, with no content restriction settings."
                ]}
            />

            <ListComponent
                title="The Dashboard:"
                bulletPoints={[
                    "Track total views driven on your video, along with cost-per-view."
                ]}
                swiperImages={[
                    require("../../../../assets/images/onboarding/data-examples/video-views-example.png").default
                ]}
            />


            <ContactUsHelpPopupSection />

        </>
    )
}