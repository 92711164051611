import { ReactComponent as SearchIcon } from 'assets/images/search-icon-2.svg'
import { StyledDiv, StyledInput } from './styles'

interface SearchInputProps {
  label: string
  showPlaceholder?: boolean
  value?: string
  ref?: React.RefObject<HTMLInputElement>
  onClick?: () => void
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export function SearchInput({
  label,
  showPlaceholder,
  value,
  ref,
  onClick,
  onChange,
}: SearchInputProps) {
  return (
    <StyledDiv>
      <SearchIcon onClick={onClick} className="cursor-pointer" />
      <label hidden>{label}</label>
      <StyledInput
        type="search"
        ref={ref}
        placeholder={showPlaceholder ? label : ''}
        value={value}
        onSubmit={onClick}
        onChange={onChange}
      />
    </StyledDiv>
  )
}
