import { ReactComponent as FacebookRoundedIcon } from 'assets/images/facebook-rounded-icon.svg'
import { FunctionComponent } from 'react'
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'
import { useStyles } from './styles'

interface SelectAdAccountHeaderProps {
  currentBrandName: string
  isCreatingAdAccount: boolean
  closeModal: () => void
  handleGoBack: () => void
}

export const SelectAdAccountHeader: FunctionComponent<SelectAdAccountHeaderProps> = ({
  currentBrandName,
  isCreatingAdAccount,
  closeModal,
  handleGoBack,
}) => {
  const { fbIconHeader } = useStyles()

  return (
    <HeaderModal
      title={
        !isCreatingAdAccount
          ? `Select ${currentBrandName}'s Ad Account`
          : 'Select your Facebook Business Manager'
      }
      subtitle={
        !isCreatingAdAccount
          ? 'Connect the ad account you primarily use to run ads.'
          : `To create a new ad account, please select your business's primary Facebook Business Manager. This is where your ad account will be made.\n\nAfter you connect, you may receive a verification email from Facebook. If so, you'll need to confirm your business email address before proceeding.`
      }
      closeModal={closeModal}
      HeaderIcon={
        !isCreatingAdAccount ? (
          <FacebookRoundedIcon className={fbIconHeader} />
        ) : undefined
      }
      handleGoBack={isCreatingAdAccount ? handleGoBack : undefined}
    />
  )
}
