import dayjs from "dayjs"
import {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useContext,
  useMemo,
} from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import MainContentContainer from "components/shareable/MainContentContainer"
import BudgetTab from "./BudgetTab"
import CampaignCreationPage from "./CampaignCreationPage"
import CreativeTab from "./CreativeTab"
import Finalization from "./FinalizationTab"
import RecordTab, { AddedLinksType } from "components/shareable/RecordSelectorTab"
import { track } from "analytics"
import TargetingView from "./TargetingView"
import {
  CurrentBrand,
  FbSuggestion,
  CreatedCampaign,
  SavingDraftStatus,
  FBCustomAudience,
  FacebookPageResponse,
  ConversionsStatus,
  FacebookPageType,
  InstagramPageType,
  AdAccountPageType,
  CurrencyCodesType,
  UserData,
} from "types/global"
import { useHistory } from "react-router-dom"
import { InstagramPost } from "components/shareable/InstagramPosts/types"
import { Backdrop, Fade, Modal, useMediaQuery, useTheme } from "@material-ui/core"
import { useStyles, LoadingContainer } from "./styles"
import {
  handleConnectionsType,
  Platforms,
  SelectedPlatforms,
  getWhenCreativeTabNextButtonIsDisabled,
  getIfTargetingSaveButtonIsDisabled,
  STEPS,
  getNextButtonLabel,
  getNextButtonWidth,
  getIfDraftHasConversionsEnabled,
  formatValidLinksAdded,
} from "./utils"
import startCase from "lodash/startCase"
import TopBar from "components/shareable/TopBar"
import CustomStepper from "components/shareable/CustomStepper"
import Grid from "@material-ui/core/Grid"
import { CAMPAIGN_CREATED, CREATING_CAMPAIGN, PLATFORMS } from "./constants"
import { GeographicTargetingType } from "./TargetingView/reducer"
import { Option } from "./TargetingView/Components/AutoCompleteSpotifyArtists/utils"
import { geographyTargetingDefaults, lowBudgetGeographyTargetingDefaults } from "./TargetingView/data"
import { MarketingDataContext } from "../Data/Provider"
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils"
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider"
import clsx from "clsx"
import { useLocation } from "react-router-dom"
import { getNotRemovableOptions, NOT_REMOVABLE } from "./TargetingView/Components/AutoCompleteAudiences/utils"
import uniqBy from "lodash/uniqBy"
import { fixGeographicTargets } from "utils"
import { useDraftDataGetter, useDraftDataSetter } from "./Hooks/DraftsHandlers"
import { AssetUploadTypes, getAdAccountDetails, getFbAccountDetails, getIfPagesAreSelected, getInstagramDetails, getPreconnectionConfigurationsStatus, hasAtLeastOnePlatform, hasEnteredCaption, PreConnectionConfigs, SelectedFbAdAccount, SelectedFbPage, UploadedAssetType, validateInstagram } from "../utils/fbCampaigns"
import AdPreviewBox from "components/shareable/FacebookAdPreview"
import useAdPreviewStyles from "components/shareable/FacebookAdPreview/styles"
import { LoadingIndicator } from "components/Loader"
import { fbLoggedIn } from 'helpers/FB'
import useSwitch from "Hooks/useSwitch"
import useBanner from "Hooks/useBanner"
import SpendBudgetProvider from "Hooks/BudgetTabSpendContext"
import { CAMPAIGN_TYPE_ENUM, GEOGRAPHY_TARGETING_DEFAULTS, CAMPAIGN_TYPES as GLOBAL_CAMPAIGN_TYPES } from "../../../../constants"
import { getInitialTargetAudiences, getTargetingViewGenre } from "../Components/TargetingView/utils"
import { MobileAdPreviewBar } from "./MobileAdPreviewBar"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints"
import { getAccountStatus } from "pages/post-auth/MarketingPage/utils"
import { usePlatformsHandler } from "./Hooks/PlatformsHandlers"
import useTermsOfServiceTimer from "Hooks/useTermsOfServiceTimer";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { CreativeAssetTypes } from "../constants"
import { getTosStatus as getTosStatusApi } from '../api'
import { getConvertedCurrency } from "services/symphonyApi"
import getSymbolFromCurrency from "currency-symbol-map"
import { updateBrandContentExternalLinks } from "services/symphonyApi/brandContentService"

const { RECORD, BUDGET, CREATIVE, FINALIZATION } = STEPS
const { INITIAL_SAVING_STATE } = SavingDraftStatus
const tabs = [RECORD, CREATIVE, BUDGET, FINALIZATION]
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export type SongAdsCampaignSubtype = "grow_playlist_followers" | "increase_record_streams"
interface Props {
  setGoal: Dispatch<SetStateAction<string | null>>
  currentBrand: CurrentBrand
  refreshCampaigns: () => void
  reloadBrands: () => void;
  currentUser: UserData;
  subtype: SongAdsCampaignSubtype
}

const SongAds: FunctionComponent<Props> = ({
  currentBrand,
  refreshCampaigns,
  reloadBrands,
  currentUser,
  subtype = "increase_record_streams"
}: Props) => {
  const classes = useStyles()

  // styles for ad previews
  const adPreviewStyles = useAdPreviewStyles()

  // set the campaign type enum based on the subtype 
  let campaignTypeEnum = subtype === "grow_playlist_followers" ? CAMPAIGN_TYPE_ENUM.grow_playlist_followers : CAMPAIGN_TYPE_ENUM.record_streams

  const theme = useTheme()
  const location = useLocation()
  const { mediumView } = useMediaBreakpoints()
  const draft = new URLSearchParams(location.search).get("draft")
  const { data: connectModalData, saveData } = useContext(ConnectModalsContext)
  const { isDisabledConnectButton, handleConnect: handleConnectModal, loading } = connectModalData
  const {
    saveTargets,
    saveAudiences,
    saveCustomAudiences,
    saveAdditionalPageAudiences,
    saveArtists,
    saveDemographics,
    demographics,
  } = useContext(MarketingDataContext)
  const { reloadBrand } = useContext(CurrentBrandContext)
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const isNextButtonResponsive = useMediaQuery(theme.breakpoints.down(601))
  const isNextButtonMobile = useMediaQuery(theme.breakpoints.down(401))
  const isTargetingViewResponsive = useMediaQuery(theme.breakpoints.down(551))
  const history = useHistory()
  const [customAudiences, setCustomAudiences] = useState<FBCustomAudience[]>([])
  const [additionalPageAudiences, setAdditionalPagesAudiences] = useState<FacebookPageResponse[]>([])

  const defaultGeographyTargeting = geographyTargetingDefaults(campaignTypeEnum)
  const [targets, setTargets] = useState<GeographicTargetingType[]>(
    defaultGeographyTargeting
  )

  const [audiences, setAudiences] = useState<FbSuggestion[]>([])
  const [artists, setArtists] = useState<Option[]>([])
  const [totalBudget, setTotalBudget] = useState(
    Number(Object.values(targets).reduce((p, c) => p + c.budget, 0).toFixed(2))
  )
  const [isEditingTargeting, setIsEditingTargeting] = useState(false)
  const [selectedTab, setSelectedTab] = useTabs(tabs, RECORD)
  const [finished, setFinished] = useState(false)
  const [recentlyCreatedCampaign, setRecentlyCreatedCampaign] =
    useState<CreatedCampaign | null>(null)
  const [showTargetingView, setShowTargetingView] = useState(false)
  const [fbSuggestions, setFbSuggestions] = useState<FbSuggestion[]>([])
  const [loadingInitialAudiences, setLoadingInitialAudiences] = useState(false)
  const [selectedFBBusinessManager, setFBBusinessManager] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager
  )

  // organization 
  const primaryOrganization = currentUser.organization

  // This is primarily used for validation purposes
  // when connecting an IG
  const [instagramPagesAvailable, setInstagramPagesAvailable] = useState<boolean>(false)

  // MAIN CONNECTIONS
  const [selectedFBPixel, setFBPixel] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    currentBrand.connections.facebook_business_manager &&
    currentBrand.connections.facebook_ad_account &&
    currentBrand.connections.facebook_pixel
  )
  const [selectedFB_page, setSelectedFB_page] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && currentBrand.connections.facebook_business_manager
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.facebook_page
  )
  const [selectedFBAdAccount, setFBAdAccount] = useState<any>(
    currentBrand?.connections?.facebook_ad_account
  )
  const [selectedInstaPage, setSelectedInstaPage] = useState<any>(
    currentBrand &&
    currentBrand.connections &&
    // && (currentBrand.connections.facebook_business_manager
    currentBrand.connections.facebook_page &&
    !currentBrand.connections.reauthenticateFbUser &&
    currentBrand.connections.instagram_page
  )
  const [selectedInstagramPost, setSelectedInstagramPost] = useState<InstagramPost>()
  const [addVisualSelected, setAddVisualSelected] = useState<AssetUploadTypes>(UPLOAD_VIDEO);
  const [recordSelected, setRecordSelected] = useState<any>(false)

  // for video uploader & ad previews
  const [uploadedImages, setUpload] = useState<UploadedAssetType[] | null>(null)
  const [creativeSelectorTab, setCreativeSelectorTab] = useState<AssetUploadTypes>(UPLOAD_VIDEO)
  const [previewedVideo, setPreviewedVideo] = useState<UploadedAssetType | null>(null)

  useEffect(() => {
    if (!previewedVideo && uploadedImages && uploadedImages.length > 0
      || uploadedImages && uploadedImages.length === 1) {
      setPreviewedVideo(uploadedImages[0])
    }
  }, [uploadedImages])

  // DSP specific assets (not used)
  const [spotifyAssets, setSpotifyAssets] = useState<any>(null)
  const [appleAssets, setAppleAssets] = useState<any>(null)
  const [youtubeAssets, setYouTubeAssets] = useState<any>(null)

  // platform specific captions
  const [captions, setCaptions] = useState<any>({
    all: null,
    spotify: null,
    apple_music: null,
    youtube: null,
    soundcloud: null,
    custom: null,
  })

  // custom link
  const [links, setLinks] = useState<any>({
    custom: {
      value: "",
      valid: false,
      checkingValidity: false
    }
  })

  // for mobile + tablet - opens the modal to show ad preview
  const [showPreview, enablePreviewMode] = useState<boolean>(false)

  const [connectionsType, setConnectionsType] = useState<string>("quick")
  const [interestTargetingAudiences, setInterestTargetingAudiences] = useState<any>(null)
  const [validatedAudiencesAlready, setValidatedAudiences] = useState<boolean>(false)

  // budgeting
  const [budgetValue, setBudgetValue] = useState<number>(0);
  const [currency, setCurrency] = useState<CurrencyCodesType>("USD");
  const [isBelowRecommendedBudget, setIsBelowRecommendedBudget] = useState<boolean>(false);
  const [minimumRecommendedBudgetValue, setMinimumRecommendedBudgetValue] = useState<number | null>(null);
  const [minimumRecommendedBudgetFormattedString, setMinimumRecommendedBudgetFormattedString] = useState<string>(`$100 USD`);


  // TODO: finalize actual recommended budget
  function updatedCurrency(newCurrency: CurrencyCodesType) {
    setCurrency(newCurrency);
  }

  let recommendedUsdBudget = 100;

  // TOOD: Optimize this call so it doesnt call getConvertedCurrency so much
  const checkMinimumBudgetStatus = async () => {
    let localizedMinimumBudget = recommendedUsdBudget
    localizedMinimumBudget = await getConvertedCurrency({ from: "USD", to: currency, amount: recommendedUsdBudget });
    setMinimumRecommendedBudgetValue(localizedMinimumBudget)

    // set the currency string so we can use it if the budget is below recommended
    const currencySymbol = getSymbolFromCurrency(currency)
    setMinimumRecommendedBudgetFormattedString(`${currencySymbol}${localizedMinimumBudget.toFixed(0)} ${currency}`)

    return budgetValue < localizedMinimumBudget;
  }

  async function recheckIfBudgetOverRecommendation() {
    const belowRecommendedBudget = await checkMinimumBudgetStatus() || false
    setIsBelowRecommendedBudget(belowRecommendedBudget)
  }

  useEffect(() => {
    recheckIfBudgetOverRecommendation()
  }, [budgetValue, currency]);

  useEffect(() => {
    if (isBelowRecommendedBudget) {
      setTargets(lowBudgetGeographyTargetingDefaults);
      saveTargets(lowBudgetGeographyTargetingDefaults);
      // when the budget goes below recommended budget:
      // - default to the below recommended budget targets
      // - otherwise use the default targeting
    } else {
      setTargets(defaultGeographyTargeting);
      saveTargets(defaultGeographyTargeting);
    }
  }, [isBelowRecommendedBudget]);

  // start and end date logistics
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [creatingCampaign, allowCampaignCreation] = useState(false)
  const [preConnectionConfigurations, setPreConnectionConfigurations] = useState<PreConnectionConfigs | null>(null)
  const [adConnectionsAdded, setAdConnectionsAdded] = useState(false)
  const [adConnectionsConfirmed, setAdConnectionsConfirmed] = useState<boolean>(false)
  const [selectedPlatforms, setSelectedPlatforms] = useState<SelectedPlatforms>(PLATFORMS)
  const [isBudgetTabNextButtonDisabled, setIsBudgetTabNextButtonDisabled] = useState(false)
  const [connectModalType, setConnectModalType] = useState("")
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  const [campaignDraftData, setCampaignDraftData] = useState<Record<string, unknown> | null>(null)
  const [retrievingDraftData, setRetrievingDraftData] = useState(!!draft)
  const [requiredDataChanged, setRequiredDataChanged] = useState(false)
  const [savingDraftStatus, setSavingDraftStatus] = useState(INITIAL_SAVING_STATE)
  const [areTermsAccepted, setAreTermsAccepted] = useState<boolean>(false)
  const [addedLinks, setAddedLinks] = useState<AddedLinksType[]>([])

  const conversionsStatus = currentBrand?.conversionsStatus || {} as ConversionsStatus
  const { conversions_tasks_completed, isError, conversions_tasks } = conversionsStatus;
  const showCallToAction = Boolean(!conversions_tasks_completed || isError)
  const conversionsCallToAction = useBanner({ initiallyVisible: showCallToAction });

  const { time, startTimer, handleStartTimer } = useTermsOfServiceTimer({
    selectedFBAdAccount,
    setPreconnectConfigurations: setPreConnectionConfigurations,
  })

  const platformsSwitch = useSwitch()
  const conversionsApiIndicator = useSwitch({
    initiallyEnabled: true,
  });

  const uploadingAssets = selectedPlatforms["all"].uploadingAssets

  const steps = Object.values(STEPS).map((value) => ({
    description: startCase(value),
    name: value,
    onClickHandler: (step: string) => () => {
      if (step === RECORD || (step === CREATIVE && recordSelected))
        setSelectedTab(step)
    },
  }))

  let mainTitle;

  if (recordSelected) {
    mainTitle = `Promote "${(recordSelected as { name: string }).name}"`
  } else {
    if (subtype === "grow_playlist_followers") {
      mainTitle = "Grow Playlist Followers"
    } else {
      mainTitle = "Increase Music Streams"
    }
  }


  const genres = currentBrand?.connections?.spotify_artist_page?.genres
  const genre = getTargetingViewGenre(genres)

  const spotifyConnected =
    currentBrand && currentBrand.connections.spotify_artist_page
      ? currentBrand.connections.spotify_artist_page
      : null

  const disableTargetingSaveButton = getIfTargetingSaveButtonIsDisabled(
    targets,
    totalBudget
  )

  let igMedia = undefined
  if (creativeSelectorTab === INSTAGRAM_POSTS || addVisualSelected === INSTAGRAM_POSTS) {
    igMedia = selectedInstagramPost
  }

  const previouslySelectedBizMgr = useRef<any>()
  const previouslySelectedFbPage = useRef<any>()
  const previouslySelectedAdAccount = useRef<any>()

  const showLoading = !!draft && retrievingDraftData
  const dataToSaveDraft = [
    recordSelected,
    selectedFB_page,
    selectedFBAdAccount,
    selectedInstaPage,
    uploadedImages,
    selectedInstagramPost,
    captions,
    budgetValue,
    startDate,
    endDate,
    targets,
    audiences,
    customAudiences,
    additionalPageAudiences,
    spotifyAssets,
    appleAssets,
    youtubeAssets,
    selectedTab,
    demographics?.age?.min || null,
    demographics?.age?.max || null,
    demographics?.gender || null,
    demographics?.languages || null,
    addVisualSelected,
    conversionsApiIndicator.enabled,
  ]
  const notRemovableOptions = getNotRemovableOptions(genre)
  const initialTargetingAudiences = uniqBy([...notRemovableOptions, ...fbSuggestions], "id")
  const targetAudiences = Boolean(audiences.length) ? audiences : initialTargetingAudiences
  const fixedGeographicTargets = Boolean(targets.length)
    ? fixGeographicTargets(targets)
    : fixGeographicTargets(GEOGRAPHY_TARGETING_DEFAULTS)
  const fixedTargetingAudiences = targetAudiences
    .filter(({ id }) => !id?.includes(NOT_REMOVABLE))
    .map(({ id, name }) => ({
      fbId: id,
      name,
    }));
  const audiencesData = {
    geographicTargets: fixedGeographicTargets,
    interestTargetingAudiences: fixedTargetingAudiences,
  }

  const saveConnectModalType = (type: string) => setConnectModalType(type)

  const handleSaveTargetingData = () => {
    saveTargets(targets)
    saveAudiences(audiences || [])
    saveCustomAudiences(customAudiences || [])
    saveAdditionalPageAudiences(additionalPageAudiences || [])
    saveArtists(artists || [])
    setShowTargetingView(false)
  }

  const saveWhenBudgetTabButtonIsDisabled = (isDisabled: boolean) => {
    setIsBudgetTabNextButtonDisabled(isDisabled)
  }

  const handleConfirmConnections = () => {
    track("Completed Campaign Step - Account Connections", {
      type: subtype,
      step: 2,
      name: "connect_accounts",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      addedFacebook: selectedFB_page ? true : false,
      addedInstagram: selectedInstaPage ? true : false
    })

    setAdConnectionsAdded(true)
    setAdConnectionsConfirmed(true)
  }

  const handleClickContinueCreativeTab = () => {
    const keys = Object.keys(selectedPlatforms)

    const objects = keys
      .map((o: string) => {
        return {
          ...selectedPlatforms[o as Platforms],
        }
      })
      .filter((o: { selected: boolean }) => {
        return o.selected
      })
      .map((o: { name: string }) => {
        return o.name
      })

    track("Completed Campaign Step - Creative Assets", {
      type: subtype,
      step: 3,
      name: "add_creatives",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      creative_assets: selectedInstagramPost ? 1 : uploadedImages?.length || 0,
      selected_platforms: objects,
    })

    setSelectedTab(BUDGET)
  }

  const handleClickCloseTopBar = () => {
    history.push("/marketing")
  }

  const handleClickBackButton = () => {
    if (showTargetingView) {
      setShowTargetingView(false)
    } else if (selectedTab === STEPS.RECORD) {
      if (recordSelected) {
        setRecordSelected(false)
      } else {
        history.push("/marketing")
      }
    } else if (selectedTab === CREATIVE) {
      if (adConnectionsConfirmed) {
        setAdConnectionsConfirmed(false)
      } else {
        setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
      }
    } else {
      setSelectedTab(tabs[tabs.indexOf(selectedTab as STEPS) - 1])
    }
  }

  const handleClickNextButton = () => {
    if (selectedTab === RECORD && recordSelected) {
      handleRecordTabNextButton()
    } else if (selectedTab === CREATIVE && connectModalType === "") {
      if (adConnectionsAdded && adConnectionsConfirmed) {
        handleClickContinueCreativeTab()
      } else {
        handleConfirmConnections()
      }
    } else if (selectedTab === CREATIVE && connectModalType !== "") {
      handleConnectModal()
    } else if (selectedTab === BUDGET) {
      setSelectedTab(FINALIZATION)
    }
  }

  async function getTosConfiguration(selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null, adAccountDetails: Record<string, unknown> | false) {
    if (!adAccountDetails || !selectedFBAdAccount) return null

    const termsOfService = await getTosStatusApi(selectedFBAdAccount.id);
    const tosAccountStatus = getAccountStatus(selectedFBAdAccount as { id: string }, adAccountDetails)
    if (termsOfService.status && !tosAccountStatus.status) termsOfService.status = false;
    termsOfService.account_status = tosAccountStatus.account_status
    setPreConnectionConfigurations(termsOfService)
    return termsOfService
  }

  const checkConnectedAccounts = async (args: {
    selectedInstaPage: InstagramPageType | null,
    selectedFB_page: SelectedFbPage | FacebookPageType | null,
    selectedFBAdAccount: SelectedFbAdAccount | AdAccountPageType | null,
  }) => {
    if (draft && !campaignDraftData) return
    if (!selectedTab || selectedTab === RECORD) return

    const {
      selectedFBAdAccount,
      selectedFB_page,
      selectedInstaPage,
    } = args

    if (conversionsStatus.isError && recordSelected) {
      setSelectedTab(CREATIVE)
    }

    if (selectedTab === CREATIVE) saveData(prev => ({ ...prev, loading: true }))

    const adAccountDetails = await getAdAccountDetails(selectedFBAdAccount)
    const fbAccountDetails = await getFbAccountDetails(selectedFB_page)
    const igAccountDetails = await getInstagramDetails(selectedInstaPage)
    const preConnectionConfigurations = await getTosConfiguration(selectedFBAdAccount, adAccountDetails)

    if (preConnectionConfigurations) {
      const currentTermsStatus = getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'tos')
      setAreTermsAccepted(currentTermsStatus)
    }

    const hasAllConnections = Boolean(selectedFBAdAccount && selectedInstaPage && selectedFBAdAccount)
    if (!hasAllConnections && recordSelected) {
      fbLoggedIn()
      setAdConnectionsAdded(false)
      setAdConnectionsConfirmed(false)
      setSelectedTab(CREATIVE)
      saveData(prev => ({ ...prev, loading: false }))
      return;
    }

    const areAllConnectionsValid = Boolean(adAccountDetails && fbAccountDetails && igAccountDetails)
    if (!areAllConnectionsValid && recordSelected) {
      removeConnections()
      conversionsApiIndicator.disable()
      setSelectedTab(CREATIVE)
    }

    if (hasAllConnections && areAllConnectionsValid) {
      setAdConnectionsAdded(true)
    }

    const isValidInstagram = await validateInstagram(selectedInstaPage, fbAccountDetails)
    if (!isValidInstagram) {
      setSelectedInstaPage(null)
      setAdConnectionsAdded(false)
      setAdConnectionsConfirmed(false)
    }

    if (selectedTab === CREATIVE) saveData(prev => ({ ...prev, loading: false }))
  }

  const removeConnections = () => {
    setFBAdAccount(null)
    setSelectedFB_page(null)
    setSelectedInstaPage(null)
    setAdConnectionsAdded(false)
    setAdConnectionsConfirmed(false)
  }

  useEffect(() => {
    const draftMetadata = campaignDraftData?.campaign_metadata as { campaign_input: { brand: CurrentBrand } }
    const draftConnections = draftMetadata?.campaign_input.brand.connections
    const draftHasConversions = getIfDraftHasConversionsEnabled({ campaignDraftData })

    const {
      facebook_page: draftFbPage,
      instagram_page: draftIgPage,
      facebook_ad_account: draftAdAccount
    } = draftConnections || {}

    conversions_tasks_completed ? conversionsCallToAction.hide() : conversionsCallToAction.show()

    if (!conversions_tasks_completed || (draft && !draftHasConversions)) {
      conversionsApiIndicator.disable()
    } else if (draft && draftHasConversions) {
      conversionsApiIndicator.enable()
    }

    checkConnectedAccounts({
      selectedFB_page: campaignDraftData ? draftFbPage : selectedFB_page,
      selectedFBAdAccount: campaignDraftData ? draftAdAccount : selectedFBAdAccount,
      selectedInstaPage: campaignDraftData ? draftIgPage : selectedInstaPage
    })
  }, [selectedFB_page, selectedFBAdAccount, selectedInstaPage, campaignDraftData])

  useEffect(() => {
    previouslySelectedBizMgr.current = selectedFBBusinessManager
    previouslySelectedFbPage.current = selectedFB_page
    previouslySelectedAdAccount.current = selectedFBAdAccount

    getInitialTargetAudiences({
      genre,
      setFbSuggestions,
      setLoading: setLoadingInitialAudiences,
    })

  }, [])

  useEffect(() => {
    if (!draft) {
      handleConnectionsType({
        connectionsType,
        selectedFBBusinessManager,
        selectedFBAdAccount,
        currentBrand,
        previouslySelectedBizMgr,
        previouslySelectedAdAccount,
        selectedFB_page,
        previouslySelectedFbPage,
        setSelectedInstaPage,
        setFBBusinessManager,
        setFBAdAccount,
        setFBPixel,
        reloadBrand,
      })
    }
  }, [
    draft,
    selectedFBBusinessManager,
    selectedFBAdAccount,
    selectedFB_page,
    connectionsType,
  ])

  const handleRecordTabNextButton = async () => {
    if (addedLinks.length) {
      const formattedLinksToUpdate = formatValidLinksAdded(addedLinks, recordSelected.external_links);
      saveData(prev => ({ ...prev, loading: true }))

      for (const linkToAdd of formattedLinksToUpdate) {
        await updateBrandContentExternalLinks(currentBrand.slug as string, recordSelected.slug, linkToAdd)
      }

      const formattedLinksToAdd = formattedLinksToUpdate.map(link => {
        return {
          name: link.serviceName,
          url: link.url
        }
      })

      saveData(prev => ({ ...prev, loading: false }))
      setRecordSelected((prev: any) => ({ ...prev, external_links: [...prev.external_links, ...formattedLinksToAdd] }))
    }

    setSelectedTab(CREATIVE)
    track("Completed Campaign Step - Content Selection", {
      type: subtype,
      step: 1,
      name: "select_song",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
    })
  }

  const handleBudgetTabNextButton = () => {
    track("Completed Campaign Step - Budget", {
      type: subtype,
      step: 4,
      name: "set_budget",
      brand_id: currentBrand.id,
      brand_name: currentBrand.name,
      brand_slug: currentBrand.slug,
      content_name: recordSelected.name,
      content_slug: recordSelected.slug,
      content_id: recordSelected.id,
      budget: budgetValue,
      start_date: dayjs(startDate).format("YYYY-MM-DD"),
      end_date: dayjs(endDate).format("YYYY-MM-DD"),
    })

    setSelectedTab(FINALIZATION)
  }

  const createdCampaign = (campaign: CreatedCampaign | null) => {
    setFinished(true)
    setRecentlyCreatedCampaign(campaign)
  }

  useEffect(() => {
    if (!recordSelected) {
      setCaptions({
        all: null,
        spotify: null,
        apple_music: null,
        youtube: null,
        custom: null,
      })

      setUpload(null)
      setSpotifyAssets(null)
      setAppleAssets(null)
      setYouTubeAssets(null)
    }
  }, [recordSelected])

  useEffect(() => {
    scrollToTop()
    scrollIntoView();
  }, [selectedTab, showTargetingView])

  const platformsSetters = usePlatformsHandler({
    conversionsEnabled: conversionsApiIndicator.enabled,
    platformsSwitch,
    captions,
    selectedPlatforms,
    recordSelected,
    creativeSelectorTab,
    campaignDraftData,
    setters: {
      setCaptions,
      setSelectedPlatforms,
    }
  })

  useDraftDataGetter({
    draft,
    checkConnectedAccounts,
    subtype,
    setters: {
      setRetrievingDraftData,
      setRecordSelected,
      setBudgetValue,
      setSelectedTab,
      setCampaignDraftData,
      setFBBusinessManager,
      setFBPixel,
      setSelectedFB_page,
      setFBAdAccount,
      setSelectedInstaPage,
      setTargets,
      setAudiences,
      saveDemographics,
      setSelectedInstagramPost,
      setUpload,
      setSpotifyAssets,
      setAppleAssets,
      setYouTubeAssets,
      setCaptions,
      setEndDate,
      setStartDate,
      setCustomAudiences,
      setAdditionalPagesAudiences,
      saveTargets,
      saveAudiences,
      saveArtists,
      setAddVisualSelected,
    } as Record<string, Dispatch<unknown>>,
  })

  const { cancelSavingDraft } = useDraftDataSetter({
    subtype,
    audiencesData,
    dataToSaveDraft,
    recordSelected,
    requiredDataChanged,
    retrievingDraftData,
    setters: {
      setRequiredDataChanged,
      setIsSavingDraft,
      setCampaignDraftData,
      setSavingDraftStatus,
    } as Record<string, Dispatch<unknown>>,
    campaignDraftData,
    currentBrand,
    draft,
    savingDraftStatus
  })

  // only allow ad preview to be shown on creative page
  const canShowAdPreview: boolean = useMemo(
    () => selectedTab === CREATIVE && adConnectionsAdded && adConnectionsConfirmed,
    [selectedTab, adConnectionsAdded, adConnectionsConfirmed]
  )

  function renderAdPreview() {
    let previewModule = (shown: boolean, onClose?: () => void) => (
      <AdPreviewBox
        callToAction={"Listen Now"}
        closePreview={onClose}
        shown={shown}
        facebookPage={selectedFB_page ? selectedFB_page : null}
        instagramAccount={selectedInstaPage ? selectedInstaPage.instagramAccount : null}
        captions={creativeSelectorTab === INSTAGRAM_POSTS ?
          (selectedInstagramPost ? [{
            type: 'all',
            caption: selectedInstagramPost.caption
          }] : []) : Object.keys(captions)
            .filter((key: string) => {
              const captionValue: string = (captions as any)[key];
              return captionValue
            })
            .map((key: string) => {
              const captionValue: string = (captions as any)[key];
              return {
                type: key,
                caption: captionValue
              }
            })}
        previewedPost={previewedVideo && creativeSelectorTab !== INSTAGRAM_POSTS ? {
          postType: 'uploaded',
          caption: "test",
          media_url: previewedVideo.url,
          media_type: "VIDEO",
          thumbnail_url: previewedVideo.thumbnail,
          type: previewedVideo.formatType
        } : null}
        selectedPosts={selectedInstagramPost && creativeSelectorTab === INSTAGRAM_POSTS ?
          [{
            postType: 'instagram',
            caption: selectedInstagramPost.caption,
            media_url: selectedInstagramPost.media_url,
            media_type: selectedInstagramPost.media_type,
            thumbnail_url: selectedInstagramPost.thumbnail_url,
            type: selectedInstagramPost.media_product_type === 'REELS' ? 'story' : 'feed'
          }] :
          uploadedImages && uploadedImages.length > 0 ? uploadedImages.map((uploadedVideo: UploadedAssetType) => {
            return {
              postType: 'uploaded',
              caption: "test",
              media_url: uploadedVideo.url,
              media_type: "VIDEO",
              thumbnail_url: uploadedVideo.thumbnail,
              type: uploadedVideo.formatType
            }
          }) : null}
        muted={!adConnectionsAdded || !adConnectionsConfirmed || Boolean(conversionsStatus.isError) || !areTermsAccepted || selectedTab !== CREATIVE}
      />)

    if (window.innerWidth < 1200) {
      return <Modal
        aria-labelledby="connector-modal"
        aria-describedby="connector-modal for platforms"
        className={adPreviewStyles.previewAdModal}
        open={showPreview}
        onClose={() => enablePreviewMode(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
      >
        <Fade
          in={showPreview}
        >
          {previewModule(canShowAdPreview, () => enablePreviewMode(false))}
        </Fade>
      </Modal>
    } else {
      return previewModule(canShowAdPreview)
    }
  }

  const getArePagesSelected = () => {
    return getIfPagesAreSelected(connectionsType, {
      fbPixel: selectedFBPixel,
      fbAdAccount: selectedFBAdAccount,
      fbPage: selectedFB_page,
      instaPage: selectedInstaPage,
    }, {
      fbPixel: false,
      fbAdAccount: true,
      fbPage: true,
      instaPage: false,
    })
  }

  const getDisableNextButtonWhenCreativeTab = () => {
    const arePagesSelected = getArePagesSelected()
    return getWhenCreativeTabNextButtonIsDisabled({
      uploadedImages,
      adConnectionsAdded,
      adConnectionsConfirmed,
      uploadingAssets,
      selectedInstagramPost,
      selectedFBPixel,
      connectionsType,
      selectedFB_page,
      selectedInstaPage,
      arePagesSelected,
      selectedFBAdAccount,
      preConnectionConfigurations,
      selectedCreativeType: creativeSelectorTab,
    })
  }

  const getDisableNextButton = () => {
    const disableForTargeting = showTargetingView && (disableTargetingSaveButton || isEditingTargeting)
    const isConversionsEnabled: boolean = conversionsApiIndicator.enabled

    if (disableForTargeting) return true

    switch (selectedTab) {
      case RECORD:
        return !recordSelected
      case CREATIVE:
        const disableNextButtonWhenCreativeTab = getDisableNextButtonWhenCreativeTab()

        const conditionsToDisable = [
          connectModalType === "" && disableNextButtonWhenCreativeTab,
          connectModalType === "" &&
          (!hasEnteredCaption(captions) &&
            !isConversionsEnabled) &&
          adConnectionsAdded &&
          adConnectionsConfirmed,
          connectModalType === "" &&
          !hasAtLeastOnePlatform(selectedPlatforms)
          && isConversionsEnabled
          && adConnectionsAdded
          && adConnectionsConfirmed,
          connectModalType === ""
          && adConnectionsAdded
          && adConnectionsConfirmed
          && captions.custom
          && (!links.custom.value || !links.custom.valid),
          connectModalType !== "" && isDisabledConnectButton,
          selectedInstagramPost && selectedInstagramPost.explicit_content,
          connectModalType === "" && selectedFBAdAccount && !areTermsAccepted,
          // if on instagram page and it's not connected, dont allow to go to next step
          connectModalType === "instagram" && !instagramPagesAvailable,
        ]
        return conditionsToDisable.some(condition => condition)
      case BUDGET:
        return isBudgetTabNextButtonDisabled
      case FINALIZATION:
        return !showTargetingView
      default:
        return false
    }
  }

  return creatingCampaign || finished ? (
    <>
      <TopBar title={finished ? CAMPAIGN_CREATED : CREATING_CAMPAIGN} />
      <CampaignCreationPage
        reloadBrands={reloadBrands}
        reloadBrand={reloadBrand}
        primaryOrganization={primaryOrganization ? primaryOrganization : undefined}
        draft={campaignDraftData?.id as string}
        links={links}
        setupType={connectionsType}
        brand={currentBrand}
        goal={subtype}
        subtype={subtype}
        fbPage={selectedFB_page}
        fbBusinessManager={selectedFBBusinessManager}
        fbAdAccount={selectedFBAdAccount}
        fbPixel={selectedFBPixel}
        igPage={selectedInstaPage}
        igMedia={igMedia}
        budget={budgetValue}
        startDate={startDate}
        endDate={endDate}
        record={recordSelected}
        assets={{
          all: uploadedImages,
          spotify: spotifyAssets,
          apple: appleAssets,
          youtube: youtubeAssets,
          custom: []
        }}
        captions={captions}
        createdCampaign={createdCampaign}
        finished={finished}
        customAudiences={customAudiences}
        additionalPageAudiences={additionalPageAudiences}
        recentlyCreatedCampaign={recentlyCreatedCampaign}
        refreshCampaigns={refreshCampaigns}
        setSelectedTab={setSelectedTab}
        setFinished={setFinished}
        allowCampaignCreation={allowCampaignCreation}
        fbSuggestions={fbSuggestions}
        conversionsEnabled={conversionsApiIndicator.enabled}
      />
    </>
  ) : (
    <Grid>
      <TopBar
        showCloseIcon
        showSaveDraftChip={!!draft || !!recordSelected}
        savingChanges={isSavingDraft}
        title={mainTitle}
        handleClose={handleClickCloseTopBar}
      />
      <CustomStepper
        {...{ steps }}
        activeStep={steps.map(({ name }) => name).indexOf(selectedTab as STEPS)}
        stepButtonsProps={{
          handleClickBackButton,
          handleClickNextButton: showTargetingView
            ? handleSaveTargetingData
            : handleClickNextButton,
          showNextButton: !(selectedTab === FINALIZATION && !showTargetingView),
          disableNextButton: getDisableNextButton(),
          nextButtonLabel: getNextButtonLabel({
            isNextButtonMobile,
            showTargetingView,
            selectedTab,
            connectModalType
          }),
          nextButtonWidth: getNextButtonWidth({
            isNextButtonMobile,
            isNextButtonResponsive,
            selectedTab
          }),
          loadingNextButton: loading,
        }}
        stepperButtonAmendments={
          <MobileAdPreviewBar
            {...{
              creativeSelectorTab,
              enablePreviewMode,
              previewedVideo,
              selectedInstagramPost,
              uploadedImages,
            }}
            show={mediumView && canShowAdPreview}
          />
        }      >
        {showLoading && (
          <LoadingContainer>
            <LoadingIndicator color="black" height="80px" />
            <p className="text-center mt-4">Loading Campaign Draft...</p>
          </LoadingContainer>
        )}
        {!showLoading && (
          <MainContentContainer
            className={clsx(
              classes.mainContainer,
              canShowAdPreview && classes.creativeTabContainer)
            }
          >
            <Grid
              className={clsx(
                `bg-white h-full w-full mb-32 rounded-${isMobile ? "none" : "xl mt-6"}`,
                showTargetingView && classes.mbWhenTargetingView,
                !showTargetingView && classes.mbWhenNotTargetingView,
                canShowAdPreview && classes.gridCreativeTabContainer,
              )}
            >
              {showTargetingView ? (
                <TargetingView
                  minimumRecommendedBudgetText={minimumRecommendedBudgetFormattedString}
                  isBelowRecommendedBudget={isBelowRecommendedBudget}
                  selectedFBPage={selectedFB_page}
                  selectedFBAdAccount={selectedFBAdAccount}
                  deleteIconColor="#80f"
                  isResponsive={isTargetingViewResponsive}
                  showTrashIcon={showTargetingView}
                  setShowTargetingView={setShowTargetingView}
                  spotifyId={recordSelected?.spotify_id}
                  contentName={recordSelected?.name || ""}
                  fbSuggestions={fbSuggestions}
                  loading={loadingInitialAudiences}
                  genre={genre}
                  handleArtists={(artists: Option[]) => setArtists(artists)}
                  handleAudiences={(audiences: FbSuggestion[]) => setAudiences(audiences)}
                  customAudiences={customAudiences}
                  setCustomAudiences={setCustomAudiences}
                  additionalPageAudiences={additionalPageAudiences}
                  setAdditionalPagesAudiences={setAdditionalPagesAudiences}
                  handleTargets={(targets: GeographicTargetingType[]) => setTargets(targets)}
                  handleTotalBudget={(budget: number) => setTotalBudget(budget)}
                  handleIsEditing={(isEditing: boolean) => setIsEditingTargeting(isEditing)}
                  showSaveButton={false}
                />
              ) : (
                <>
                  <div className="w-full">
                    <TabPanel hidden={selectedTab !== RECORD}>
                      <RecordTab
                      title={subtype === "grow_playlist_followers" ? "Select the playlist you want to promote." : "Select the release you want to promote."}
                        subtype={subtype}
                        spotifyConnected={spotifyConnected}
                        setRecordSelected={setRecordSelected}
                        recordSelected={recordSelected}
                        brand={currentBrand}
                        reloadBrand={reloadBrand}
                        columns={[
                          "artist",
                          "type",
                          "links",
                          "links-adder",
                        ]}
                        requireLinks={["spotify", "apple_music"]}
                        setAddedLinks={setAddedLinks}
                      />
                    </TabPanel>
                    <TabPanel hidden={selectedTab !== CREATIVE}>
                      <CreativeTab
                        subtype={subtype}
                        setInstagramPagesAvailable={setInstagramPagesAvailable}
                        setLinks={setLinks}
                        links={links}
                        setPreviewedVideo={setPreviewedVideo}
                        previewedVideo={previewedVideo}
                        creativeSelectorTab={creativeSelectorTab}
                        setCreativeSelectorTab={setCreativeSelectorTab}
                        setAddVisualSelected={setAddVisualSelected}
                        nextButtonEnabled={getArePagesSelected()}
                        selectedRecord={recordSelected}
                        reloadBrands={reloadBrands}
                        setSelectedTab={setSelectedTab}
                        setUpload={setUpload}
                        selectedAssets={uploadedImages}
                        setSelectedAssets={setUpload}
                        setSpotifyAssets={setSpotifyAssets}
                        selectedSpotifyAssets={spotifyAssets}
                        setAppleAssets={setAppleAssets}
                        selectedAppleAssets={appleAssets}
                        setYoutubeAssets={setYouTubeAssets}
                        selectedYoutubeAssets={youtubeAssets}
                        selectedFB_page={selectedFB_page}
                        setSelectedFB_page={setSelectedFB_page}
                        selectedInstaPage={selectedInstaPage}
                        setSelectedInstaPage={setSelectedInstaPage}
                        selectedFBBusinessManager={selectedFBBusinessManager}
                        setFBBusinessManager={setFBBusinessManager}
                        selectedFBPixel={selectedFBPixel}
                        setFBPixel={setFBPixel}
                        selectedFBAdAccount={selectedFBAdAccount}
                        setFBAdAccount={setFBAdAccount}
                        captions={captions}
                        setCaptions={setCaptions}
                        brand={currentBrand}
                        connectionsType={connectionsType}
                        setConnectionsType={setConnectionsType}
                        selectedInstagramPost={selectedInstagramPost}
                        setSelectedInstagramPost={setSelectedInstagramPost}
                        preConnectionConfigurations={preConnectionConfigurations}
                        adConnectionsAdded={adConnectionsAdded}
                        adConnectionsConfirmed={adConnectionsConfirmed}
                        setAdConnectionsAdded={setAdConnectionsAdded}
                        setSelectedPlatforms={setSelectedPlatforms}
                        selectedPlatforms={selectedPlatforms}
                        showContinueButton={false}
                        saveConnectModalType={saveConnectModalType}
                        campaignDraftData={campaignDraftData}
                        toggleConversionsIndicator={conversionsApiIndicator.toggle}
                        isConversionsEnabled={conversionsApiIndicator.enabled}
                        isConversionsSetUp={Boolean(conversions_tasks_completed)}
                        showConversionsError={Boolean(conversionsStatus.isError)}
                        conversionsTasks={conversions_tasks}
                        showConversionsCTA={conversionsCallToAction.visible}
                        hideConversionsCTA={conversionsCallToAction.hide}
                        areTermsAccepted={areTermsAccepted}
                        platformsSwitch={platformsSwitch}
                        time={time}
                        startTimer={startTimer}
                        handleStartTimer={handleStartTimer}
                        getTosConfiguration={getTosConfiguration}
                        {...platformsSetters}
                      />
                    </TabPanel>
                    <SpendBudgetProvider
                      {...{ budgetValue }}
                      currentUser={currentUser}

                      step={selectedTab as string}
                      fbAccountId={selectedFBAdAccount?.id || null}
                      campaignType={subtype === "grow_playlist_followers" ? GLOBAL_CAMPAIGN_TYPES.grow_playlist_followers : GLOBAL_CAMPAIGN_TYPES.record_streams}
                    >
                      <TabPanel hidden={selectedTab !== BUDGET}>
                        <BudgetTab
                          updatedCurrency={updatedCurrency}
                          budgetValue={budgetValue}
                          setBudgetValue={setBudgetValue}
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          fbAdAccountId={
                            selectedFBAdAccount ? selectedFBAdAccount.id : null
                          }
                          fbAdAccountName={selectedFBAdAccount ? selectedFBAdAccount.name : null}
                          fbBusinessId={selectedFBBusinessManager}
                          brand={currentBrand!}
                          record={recordSelected}
                          finishFlow={handleBudgetTabNextButton}
                          saveWhenNextButtonIsDisabled={
                            saveWhenBudgetTabButtonIsDisabled
                          }
                          showReviewButton={false}
                          selectedTab={selectedTab}
                        />
                      </TabPanel>
                      <TabPanel hidden={selectedTab !== FINALIZATION}>
                        <Finalization
                          {...{ selectedTab }}
                          subtype={subtype}
                          primaryOrganization={primaryOrganization}
                          isBelowRecommendedBudget={isBelowRecommendedBudget}
                          customAudiences={customAudiences}
                          additionalPageAudiences={additionalPageAudiences}
                          igMedia={igMedia}
                          addVisualSelected={addVisualSelected}
                          draft={campaignDraftData?.id}
                          brand={currentBrand}
                          goal={subtype}
                          fbPage={selectedFB_page}
                          fbBusinessManager={selectedFBBusinessManager}
                          fbAdAccount={selectedFBAdAccount}
                          fbPixel={selectedFBPixel}
                          igPage={selectedInstaPage}
                          budget={budgetValue}
                          startDate={startDate}
                          endDate={endDate}
                          record={recordSelected}
                          allowCampaignCreation={allowCampaignCreation}
                          setSelectedTab={setSelectedTab}
                          assets={{
                            all: uploadedImages,
                            spotify: spotifyAssets,
                            apple: appleAssets,
                            youtube: youtubeAssets,
                            custom: []
                          }}
                          setupType={connectionsType}
                          captions={captions}
                          interestTargetingAudiences={interestTargetingAudiences}
                          setInterestTargetingAudiences={
                            setInterestTargetingAudiences
                          }
                          validatedAudiencesAlready={validatedAudiencesAlready}
                          setValidatedAudiences={setValidatedAudiences}
                          setShowTargetingView={setShowTargetingView}
                          fbSuggestions={fbSuggestions}
                          cancelSaving={cancelSavingDraft}
                          conversionsEnabled={conversionsApiIndicator.enabled}
                          setTargets={setTargets}
                          handleSaveTargetingData={handleSaveTargetingData}
                        />
                      </TabPanel>
                    </SpendBudgetProvider>
                  </div>
                </>
              )}
            </Grid>
            {renderAdPreview()}
          </MainContentContainer>
        )}
      </CustomStepper>
    </Grid>
  )
}

export default SongAds
