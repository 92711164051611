import Dialog from "@material-ui/core/Dialog"
import { FunctionComponent } from "react"
import {
  ModalConnectButton,
  ModalConnectButtonContainer,
  ModalConnectButtonLabel,
  ModalContainer,
  useStyles,
} from "./style"
import ArtistItem from "../Spotify/Components/ArtistList/ArtistItem"
import { useHistory } from "react-router-dom"
import { CurrentBrand, SpotifyArtist } from "types/global"
import { useMediaQuery, useTheme } from '@material-ui/core'
import HeaderModal from 'components/connect/connectModals/Spotify/Components/HeaderModal'

interface Props {
  open: boolean
  artist: SpotifyArtist
  existingBrand: CurrentBrand
  goBack: () => void
  closeModal: () => void
  setCurrentBrand: (currentBrand: CurrentBrand) => void,
}

const AlreadyExistingArtistModal: FunctionComponent<Props> = ({
  open,
  artist,
  existingBrand,
  goBack,
  closeModal,
  setCurrentBrand,
}: Props) => {
  const history = useHistory()
  const classes = useStyles()

  const goToDashboard = (_parameter: unknown) => {
    setCurrentBrand(existingBrand)
    closeModal()
    history.push('/dashboard')
  }

  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{ 
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer 
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        maxHeight="100%"
        gap="32px"
      >
        <HeaderModal
          closeModal={goBack}
          title="You’ve already added this artist."
          subtitle={`Looks like you’ve already added ${artist?.name || ""} to the artists under your profile.`}
        />
        <ArtistItem
          artist={artist}
          selectArtist={goToDashboard}
          buttonLabel="Go to Artist Dashboard"
        />
        <ModalConnectButtonContainer>
          <ModalConnectButton onClick={goBack} color="#ffffff">
            <ModalConnectButtonLabel color="#8800ff">
              Search for another artist
            </ModalConnectButtonLabel>
          </ModalConnectButton>
        </ModalConnectButtonContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default AlreadyExistingArtistModal
