/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useContext, useEffect, useState } from "react"
import { StyledLabel, Container } from "../../styles"
import { SYMPHONY_TASKS } from "types/global"
import { GettingStartedContext } from "Hooks/GettingStartedContext/index"
import { PulledUsers, getUsers } from "./api"
import { ButtonText, Headline3 } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import useStyles, { StyledButton } from "./styles"
import { UpgradeModalContext } from "Hooks/UpgradeModalContext"
import { DataGrid } from "@mui/x-data-grid"
import { Row, getDataGridColumns } from "./utils"
import useModal from "Hooks/useModal"
import DeleteUserModal from "../DeleteUserModal"
import AddUserModal from "../AddUserModal"
import UpgradeToProChip from "components/shareable/UpgradeToProChip"
import Axios from "helpers/Interceptor"
import { toast } from "react-toastify"
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils"
import { CurrentUserContext } from "Hooks/CurrentUserContext"
import { CurrentBrandContext } from "Hooks/CurrentBrandContext"
import { isBrandAdministrator } from "utils"
import { useHistory } from "react-router-dom"
import { PageTabs } from "../../../OrganizationPage/utils"

interface Props {
  brandId?: number | null
  isProUser?: boolean
  email?: string
  isAdmin: boolean;
}

const UsersTab: FunctionComponent<Props> = ({
  isProUser,
  brandId,
  email,
  isAdmin
}: Props) => {
  const classes = useStyles()
  const { handleOpenModal: openUpgradeToProModal } =
    useContext(UpgradeModalContext)
  const { reloadUser } = useContext(CurrentUserContext);
  const { currentBrand } = useContext(CurrentBrandContext);
  const [pulledUsers, setPulledUsers] = useState<PulledUsers>({ availableSeats: 0, users: [] })
  const addUserModal = useModal()
  const deleteUserModal = useModal()
  const [potentiallyDeletedUser, setPotentiallyDeletingUser] =
    useState<Row | null>(null)
  const history = useHistory();

  const { finishedTasks, finishTask } = useContext(GettingStartedContext)
  const dataGridColumns = getDataGridColumns({
    onRemove: (row: Row | null) => {
      if (isBrandAdministrator(currentBrand!) && currentBrand?.subscription?.tier === "organization") {
        history.push(`/organization?tab=${PageTabs.TEAM}`)
      } else {
        setPotentiallyDeletingUser(row as Row | null)
        deleteUserModal.openModal()
      }
    },
    toggleTeamPlanAccess: async (userId: string) => {
      try {
        console.log("toggling team plan access for user with id: ", userId)
        const allowAccess = await Axios.post(`/user/${userId}/toggle-team-plan-access`)
        console.log("allowAccess: ", allowAccess)
        await getUsers({
          brandId,
          onSaveUsers: setPulledUsers,
        })
        toast.success(`ADMIN: Team plan enabled for user.`, SHARED_TOAST_OPTIONS)
        await reloadUser();
      } catch (e) {
        console.error("Error toggling team plan access: ", e)
        toast.error("ADMIN: There was an error enabling team plan access for this user.", SHARED_TOAST_OPTIONS_ERROR);
      }
    },
  }, isAdmin)

  const markStepAsCompleted = () => {
    if (!finishedTasks.includes(SYMPHONY_TASKS.TEAM_INVITED)) {
      finishTask(SYMPHONY_TASKS.TEAM_INVITED)
    }
  }

  const handleCloseAddModal = () => {
    addUserModal.closeModal()
  }

  const handleCloseDeleteModal = () => {
    setPotentiallyDeletingUser(null)
    getUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    deleteUserModal.closeModal()
  }

  const handleClickAddNewMemberButton = () => {
    if (isBrandAdministrator(currentBrand!) && currentBrand?.subscription?.tier === "organization") {
      history.push(`/organization?tab=${PageTabs.TEAM}`)
    } else if (isProUser) {
      addUserModal.openModal()
    } else {
      openUpgradeToProModal({
        source: "Settings - Add New User",
      })
    }
  }

  useEffect(() => {
    getUsers({
      brandId,
      onSaveUsers: setPulledUsers,
    })
    markStepAsCompleted()
  }, [])

  const hasReachedLimit = pulledUsers.users.length >= pulledUsers.availableSeats
  const willNotTouchLimit = pulledUsers.users.length - 1 >= pulledUsers.availableSeats

  return (
    <Container>
      <StyledLabel>
        <Container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0px 0px 24px 0px"
          borderBottom="1px solid #E5E7EB"
        >
          <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>Users</Headline3>
          <StyledButton onClick={handleClickAddNewMemberButton}>
            <div className="flex flex-row items-center gap-1.5">
              <div><UpgradeToProChip type="gem" color="purple" height={18} width={18} /></div>
              <ButtonText>Add new member</ButtonText>
            </div>
          </StyledButton>
        </Container>
      </StyledLabel>
      {pulledUsers.users.length > 0 && (
        <Container margin="0px" minHeight="100px">
          <DataGrid
            autoHeight
            headerHeight={0}
            hideFooter
            classes={{ root: classes.dataGridRoot }}
            columns={dataGridColumns}
            //TODO: Data will change from the same endpoint
            rows={pulledUsers.users.map(({
              email: userEmail,
              id,
              isOwner,
              removable,
              hasTeamPlanAccess
            }) => {
              const isMe = email === userEmail
              return {
                id,
                email: userEmail,
                name: "-",
                isOwner,
                removable,
                hasTeamPlanAccess
              }
            }
            )}
          />
        </Container>
      )}
      <DeleteUserModal
        {...{ brandId }}
        open={deleteUserModal.open}
        member={potentiallyDeletedUser}
        willNotTouchLimit={willNotTouchLimit}
        onSaveUsers={setPulledUsers}
        onSetDeletedUser={setPotentiallyDeletingUser}
        onClose={handleCloseDeleteModal}
      />
      <AddUserModal
        {...{ brandId }}
        users={pulledUsers.users}
        hasReachedLimit={hasReachedLimit}
        open={addUserModal.open}
        onClose={handleCloseAddModal}
        onSaveUsers={setPulledUsers}
      />
    </Container>
  )
}

export default UsersTab
