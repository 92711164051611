import { Body2 } from "components/shareable/Typography"
import { FunctionComponent, useState } from "react"
import { SystemColors } from "types/globalStyles"
import { Container, StyledToggleButton } from "./styles"

export enum Options {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

interface Props {
  onSelect?: (option: Options) => void
}

const ToggleButtons: FunctionComponent<Props> = ({ onSelect }: Props) => {
  const [selected, setSelected] = useState(Options.MONTHLY)

  const handleSelect = (option: Options) => () => {
    if (onSelect) onSelect(option)
    setSelected(option)
  }

  return (
    <Container>
      <StyledToggleButton
        {...{ selected }}
        side="LEFT"
        value={Options.ANNUALLY}
        onClick={handleSelect(Options.MONTHLY)}
      >
        <Body2 color={SystemColors.ACCENT_COLOR}>Billed monthly</Body2>
      </StyledToggleButton>
      <StyledToggleButton
        {...{ selected }}
        side="RIGHT"
        value={Options.MONTHLY}
        onClick={handleSelect(Options.ANNUALLY)}
      >
        <Body2>Billed annually 25% off</Body2>
      </StyledToggleButton>
    </Container>
  )
}

export default ToggleButtons
