import { FunctionComponent } from "react"
import {
  ArtistFollowers,
  ArtistFollowersContainer,
  ArtistFollowersPlatform,
  ArtistImage,
  ArtistInfo,
  ArtistItem as StyledArtistItem,
  ArtistName,
  ArtistNameLabel,
  ConnectArtistLabel,
} from "../../../style"
import { ReactComponent as SpotifyFollowersIcon } from "assets/images/streaming/icons/spotify-i.svg"
import millify from "millify"
import { OnboardingArtist } from "types/global"
import Lottie from 'react-lottie'
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json'
import Grid from "@material-ui/core/Grid"
import NotFoundArtistImage from "assets/images/not-found-user.png"

const getImageUrl = (artist?: OnboardingArtist | null) => {
  const { image_url, images } = artist || {}

  if (image_url) return image_url 
  if (images) return images[0]?.url
  return undefined
}

const getFollowers = (artist?: OnboardingArtist | null) => {
  const { sp_followers, followers } = artist || { }

  if (sp_followers && sp_followers > 0) return millify(sp_followers)
  if (followers) return millify(followers?.total || 0)
  return 0
}

interface Props {
  loading?: boolean
  isSelected?: boolean
  artist: OnboardingArtist
  buttonLabel: string
  selectArtist: (artist: OnboardingArtist) => void
}

const ArtistItem: FunctionComponent<Props> = ({
  loading,
  isSelected,
  artist,
  buttonLabel,
  selectArtist,
}: Props) => {
  const imageUrl = getImageUrl(artist)
  const followers = getFollowers(artist)

  const handleClickItem = () => {
    if (!loading) {
      selectArtist({
        ...artist,
        chartmetric: true,
      })
    }
  }

  return (
    <StyledArtistItem onClick={handleClickItem}>
      <ArtistInfo>
        <ArtistImage src={imageUrl || NotFoundArtistImage}/>
        <ArtistName>
          <ArtistNameLabel>{artist?.name}</ArtistNameLabel>
          <ArtistFollowersContainer>
            <ArtistFollowersPlatform>
              <SpotifyFollowersIcon width={16} height={16} />
            </ArtistFollowersPlatform>
            <ArtistFollowers>
              {followers}{" "}
              followers
            </ArtistFollowers>
          </ArtistFollowersContainer>
        </ArtistName>
      </ArtistInfo>
      {!loading && (
        <ConnectArtistLabel>
          {buttonLabel}
        </ConnectArtistLabel>
      )}
      {(loading && isSelected) && (
        <Grid className="w-6">
          <Lottie 
            height={24}
            width={24}
            options={{
              loop: true,
              autoplay: true,
              animationData: SymphonyLoadingLottie,
            }}
          />
        </Grid>
      )}
    </StyledArtistItem>
  )
}

export default ArtistItem
