import { makeStyles, Theme } from "@material-ui/core/styles"
import styled from "styled-components"

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    color: '#ffffff',
    backgroundColor: '#2C2135',
  },
  goBackIcon: {
    color: '#ffffff',
  },
  responsiveGutters: {
    padding: theme.spacing(2),
  },
  gutters: {
    padding: theme.spacing(0, 2),
  },
}))

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  margin?: string
  padding?: string
  paddingLeft?: string
  marginLeft?: string
  marginTop?: string
  flexGrow?: string
  maxHeight?: string
  overflow?: string
  whiteSpace?: string
  textOverflow?: string
  backgroundColor?: string
  borderRadius?: string
  width?: string
}

interface SaveDraftChipContainerProps {
  flexGrow?: string | number
}

interface TitleContainerProps {
  flexGrow?: string | number
}

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ flexGrow }: TitleContainerProps) => flexGrow};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledLabel = styled.p`
  width: ${({ width }: StyledLabelProps) => width};
  border-radius: ${({ borderRadius }: StyledLabelProps) => borderRadius};
  background-color: ${({ backgroundColor }: StyledLabelProps) =>
    backgroundColor};
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  flex-grow: ${({ flexGrow }: StyledLabelProps) => flexGrow};
  max-height: ${({ maxHeight }: StyledLabelProps) => maxHeight};
  overflow: ${({ overflow }: StyledLabelProps) => overflow};
  white-space: ${({ whiteSpace }: StyledLabelProps) => whiteSpace};
  text-overflow: ${({ textOverflow }: StyledLabelProps) => textOverflow};
`

export const SaveDraftChipContainer = styled.div`
  flex-grow: ${({ flexGrow }: SaveDraftChipContainerProps) => flexGrow || "1"};
  margin-top: 2px;
`

export default useStyles
