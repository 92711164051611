import { STREAMLINK_REGEX } from "helpers/StreamingMappings"

const AT = "@"

export const getFormattedValue = (value: string) => {
  const isUrl = value.includes("http") || value.includes("https")
  let withoutAt = value

  if (isUrl) {
    if (value[value.length - 1] === "/") value = value.substring(0, value.length - 1)
    const userNameFromUrl = value.substring(value.lastIndexOf("/") + 1)
    return { url: value, username: userNameFromUrl }
  } else {
    if (withoutAt.charAt(0) === AT) {
      withoutAt = withoutAt.substring(1)
    }
    return { url: `https://instagram.com/${withoutAt}`, username: withoutAt, picture: "" }
  }
}

export const checkIsValid = (str: string, formattedValue: { url: string; username: string} | null) => {
  let isValidUrl = false
  const isUrl = str.includes("http") || str.includes("https")
  const urlIGRegexps = STREAMLINK_REGEX["business_discovery_instagram"]

  if (isUrl) {
    for (const regexp of urlIGRegexps) {
      isValidUrl = regexp.test(str.toString())
    }

    return isValidUrl
  }

  return (
    formattedValue &&
    /^[@{1}]?(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,50}$/.test(str.toString())
  )
}

export const uploadProfilePicture = async (file: string) => {
  const response = new Promise(async (resolve, _reject) => {
    try{
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        process.env.REACT_APP_CLOUDINARY_IMAGE_UPLOAD_URL as string
      );
      xhr.onreadystatechange = function() {
          if (xhr.readyState == XMLHttpRequest.DONE) {
              resolve(JSON.parse(xhr.responseText).url);
          }
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "unsigned-assets");
      xhr.send(formData);
    } catch(e) {
      resolve(false);
    }
  })
  return response
}

export const getFormattedInstaPage = async (igUser: Record<string, unknown>) => {
  const { profile_picture_url, name, username } = igUser;
  const url = `https://www.instagram.com/${username}`;
  const cloudinaryUrl = await uploadProfilePicture(profile_picture_url as string);
  return {
    url,
    picture: cloudinaryUrl as string,
    username: username as string,
    name: name as string,
  }
}
