import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import BadgeCampaignTypeContainer from "../BadgeCampaignTypeContainer/BadgeCampaignTypeContainer";
import CampaignStatusBadge from "../CampaignStatusBadge";
import { RowContainer, ButtonCampaignInfo, TextButton } from "./styles";
import SymphonyConversionsBadge from "../SymphonyConversionsBadge";
import { SystemColors } from "types/globalStyles";
import { Caption } from "components/shareable/Typography";
import Badge from "components/shareable/Badge";
import { ShowCampaignInfo } from "../Modals/AdminShowCampaignInfo";

// Subtitle component
interface SubtitleProps {
  children: React.ReactNode;
}

interface CampaignCardContentProps {
  thumbnailSrc: string;
  titleText: string;
  campaignTypeIndicatorText: string;
  campaignTypeIndicatorBgColor: string;
  subtitle: React.ReactNode;
  campaignType: string;
  campaignStatus?: string;
  createdAt?: string;
  campaignLabel?: string;
  campaignNeedsActions: boolean;
  isUsingConversionsApi?: boolean;
  onClick: () => void;
  campaignId?: string | number; // only for admin usage
  isAdmin?: boolean; // only for admin usage
}

export const Thumbnail: React.FC<{ src: string }> = ({ src }) => (
  <img
    className="w-12 h-12 mb-3 md:mb-0 md:w-20 md:h-20 object-cover rounded-xl mr-4"
    src={src}
    alt=""
  />
);

export const Title: React.FC<{ text: string }> = ({ text }) => (
  <h3 className="font-normal text-lg">{text}</h3>
);

export const Subtitle: React.FC<SubtitleProps> = ({ children }) => (
  <div className="text-sm text-gray-500">{children}</div>
);

const CampaignCardContent: React.FC<CampaignCardContentProps> = ({
  thumbnailSrc,
  titleText,
  campaignTypeIndicatorText,
  campaignTypeIndicatorBgColor,
  subtitle,
  createdAt,
  campaignType,
  campaignStatus,
  campaignLabel,
  campaignNeedsActions,
  isUsingConversionsApi,
  onClick,
  campaignId,
  isAdmin,
}) => {
  const [isShowCampaignInfoModal, setIsShowCampaignInfoModal] = useState(false);

  const openCampaignModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsShowCampaignInfoModal(true);
  }

  const closeCampaignModal = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsShowCampaignInfoModal(false);
  }

  const isFbCampaign = campaignType === "boost_instagram" || 
  campaignType === "link_clicks" || 
  campaignType === "record_streams" || 
  campaignType === "grow_playlist_followers";

  return (
    <>    
    <div className="w-full flex items-center justify-center " onClick={onClick}>
      <div className="flex  flex-col items-start md:items-center w-10/12 md:w-full md:flex-row">
        <Thumbnail src={thumbnailSrc} />
        <div className="flex flex-col justify-center items-start">
          <RowContainer>
            <BadgeCampaignTypeContainer campaignType={campaignType} />
            {isUsingConversionsApi && <SymphonyConversionsBadge />}
            {campaignNeedsActions ? (
              <Badge backgroundColor={SystemColors.PAPER_WARNING_CONTAINER}>
                <Caption color={SystemColors.WARNING_COLOR}>
                  ACTION NEEDED
                </Caption>
              </Badge>
            ) : (
              <CampaignStatusBadge
                campaignStatus={campaignStatus?.toLowerCase()!}
                campaignStatusLabel={
                  campaignLabel ? campaignLabel : campaignStatus
                }
              />
            )}
          </RowContainer>
          <div className="mb-1">
            <Title text={titleText} />
          </div>
          <div>
            <Subtitle>{subtitle}</Subtitle>
          </div>
          {createdAt && (
            <div>
              <Subtitle>Created on {createdAt}</Subtitle>
            </div>
          )}
          {isAdmin && campaignId && (
            <div>
              <Subtitle>Campaign ID: {campaignId}</Subtitle>
            </div>
          )}
        </div>
      </div>
      <div className="ml-auto flex items-center">
        {isAdmin && isFbCampaign && (
          <ButtonCampaignInfo onClick={openCampaignModal}>
            <TextButton>Admin: Campaign info</TextButton>
          </ButtonCampaignInfo>
        )}
        <IconButton
          onClick={onClick}
          aria-label={`View Campaign Details for ${titleText}`}
        >
          <NavigateNext fontSize="large"></NavigateNext>
        </IconButton>
      </div>
    </div>
      {campaignId && isFbCampaign && (
        <ShowCampaignInfo
          open={isShowCampaignInfoModal}
          closeModal={closeCampaignModal}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default CampaignCardContent;
