import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

export const WarningContainer = styled.div`
  padding: 16px;
  background-color: ${SystemColors.PAPER_ACCENT_CONTAINER};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

export const ActionsContainer = styled.div`
  align-self: flex-end;
  gap: 16px;
  display: flex;
`
