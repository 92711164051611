import { Fragment, FunctionComponent, useState } from "react";
import { AdviceBanner, CountryPaper, FlagContainer } from "./styles";
import { ReactComponent as LocationIcon } from "assets/images/campaignDetails/location.svg";
import DialogPaper from "components/shareable/DialogPaper";
import HeaderModal from "pages/post-auth/MarketingPage/Components/HeaderModal";
import CountryFlag from "react-country-flag";
import useCountryList from "react-select-country-list";
import SymphonyTabs, { TabContent } from "components/shareable/SymphonyTabs";
import { PageTabs, TABS } from "./constants";
import { Caption } from "components/shareable/Typography";

interface Props {
  open: boolean;
  markets: string[];
  closeModal: () => void;
}

const AllCountriesModal: FunctionComponent<Props> = ({
  open,
  markets,
  closeModal,
}) => {
  const [selectedView, setSelectedView] = useState<PageTabs>(PageTabs.RELEASED);
  const countryList = useCountryList();

  const getCountryName = (code: string) => {
    const country = countryList.data.find(c => c.value === code);
    return country ? country.label : code;
  }

  return (
    <DialogPaper open={open} dialogStyle={{ maxHeight: '650px' }}>
      <Fragment>
        <HeaderModal
          closeModal={closeModal}
          title="Release Breakdown"
          subtitle="As your song/album releases in different countries, you’ll be able to track them here."
          ChipIcon={LocationIcon}
          chipText="Realtime Releases"
        />
        <SymphonyTabs
          data={TABS}
          onChange={(newValue) => setSelectedView(newValue as PageTabs)}
          selectedValue={selectedView}
        />
        <TabContent hidden={selectedView !== PageTabs.RELEASED}>
          <Fragment>
            <AdviceBanner>
              When a fan from a country below visits your site, they’ll see your
              “Listen Now” website page with streaming services.
            </AdviceBanner>
            {markets?.length ? (
              markets.map((code) => (
                <CountryPaper key={code}>
                  <FlagContainer>
                    <CountryFlag countryCode={code} svg style={{ marginLeft: '10px', width: '20px', height: '15px' }} />
                    <p>{getCountryName(code)}</p>
                  </FlagContainer>
                  {/* TODO: Change to country date, when this function exists */}
                  <p>Released</p>
                </CountryPaper>
              ))
            ) : (
                <Caption>No found in any country yet.</Caption>
            )}
          </Fragment>
        </TabContent>
        {/* TODO: Uncomment when upcoming tab is ready */}
        {/* <TabContent hidden={selectedView !== PageTabs.UPCOMING}>
            <AdviceBanner>
              When a fan from a country below visits your site, they’ll see your
              “Pre-save” website page until it converts.
            </AdviceBanner>
          </TabContent> */}
      </Fragment>
    </DialogPaper>
  );
};

export default AllCountriesModal;

