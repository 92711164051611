import { ContentContainer, MainContainer, Subtitle, Title, TitlesContainer } from './styles';
import { FunctionComponent } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  mobile?: boolean,
  useDefaultPadding?: boolean,
  padding?: string,
  titleTextPadding?: string,
  additionalButtons?: JSX.Element,
  additionalContent?: JSX.Element

}

const CardTitle: FunctionComponent<Props> = ({
  mobile = false,
  useDefaultPadding = true,
  padding,
  titleTextPadding,
  title,
  subtitle,
  additionalButtons,
  additionalContent
}: Props) => {
  return (
    <MainContainer mobile={mobile}
      useDefaultPadding={useDefaultPadding}
      padding={padding}>
      <ContentContainer>
        <TitlesContainer
        mobile={mobile}
         padding={titleTextPadding}>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{`${subtitle}`}</Subtitle>}
          {additionalContent ? additionalContent : null}
        </TitlesContainer>
        {additionalButtons ? <div className='w-full'>{additionalButtons}</div> : null}
      </ContentContainer>
      {/* TODO:Add design for this button when is ready */}
    </MainContainer>
  )
}

export default CardTitle;
