import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';

interface PerformanceProps {
  alignItems: string;
}

interface ImageContainerProps {
  width?: string;
  borderRadius?: string;
  height?: string;
}

interface AdsPreviewContainerProps {
  width: string;
  paddingBottom?: string;
}

export const AdsPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  width: ${({ width }: AdsPreviewContainerProps) => width};
  padding-top: 24px;
  gap: 16px;
  height: fit-content;
  margin-bottom: 16px;
  padding-bottom: ${({ paddingBottom }: AdsPreviewContainerProps) => paddingBottom};
`;

export const Header = styled.p`
  font-family: DIN;
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
`;

export const Subtitle = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
`;

export const ImageContainer = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 0 0 12px 12px;
  gap: 10px;
  background: #d5d1d6;
  width: ${({ width }: ImageContainerProps) => width};
  height: ${({ height }: ImageContainerProps) => height};
`;

export const PerformanceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
`;

export const Performance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }: PerformanceProps) => alignItems};
  width: fit-content;
`;

export const PerformanceValue = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 19px;
`;

export const PerformanceType = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
`;

export const GraphContainer = styled.div`
  padding: 8px 16px 0px 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 16px;
`;

export const PreviewButton = styled.a`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const VideoAsImageWarning = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 10px;
  background: ${SystemColors.PAPER_LIGHT_CONTAINER};
  width: 100%;
  border-radius: 12px;
`;
