import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

export interface ContainerProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
  maxWidth?: string;
  transform?: string;
  borderRadius?: string;
  justifyContent?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  margin?: string;
  padding?: string;
  paddingLeft?: string;
  marginLeft?: string;
  marginTop?: string;
}

interface ImageProps {
  maxWidth?: string;
  width?: string;
  maxHeight?: string;
  height?: string;
}

export const Container = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  width: ${({ width }: ContainerProps) => width};
  max-width: ${({ maxWidth }: ContainerProps) => maxWidth};
  transform: ${({ transform }: ContainerProps) => transform};
  border-radius: ${({ borderRadius }: ContainerProps) => borderRadius};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
`;

export const StyledLabel = styled.span`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 12}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin: ${({ margin }: StyledLabelProps) => margin};
  padding: ${({ padding }: StyledLabelProps) => padding};
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft};
  margin-left: ${({ marginLeft }: StyledLabelProps) => marginLeft};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
`;

export const Image = styled.img`
  max-width: ${({ maxWidth }: ImageProps) => maxWidth || "none"};
  width: ${({ width }: ImageProps) => width || "auto"};
  height: ${({ height }: ImageProps) => height || "auto"};
  max-height: ${({ maxHeight }: ImageProps) => maxHeight || "none"};
`;

export const UpgradeToProButton = withStyles((theme: Theme) => ({
  root: {
    width: 216,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 40,
    shadowBox: "none",
    "&:hover": {
      backgroundColor: "#8800FF",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(Button);
