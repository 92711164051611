import { FunctionComponent, Fragment } from "react";
import { GradientRoundedContainer, AvatarContainer } from "../../styles";
import Avatar from "@material-ui/core/Avatar";

interface Props {
  avatar?: string;
  isProUser?: boolean;
}

const CustomAvatar: FunctionComponent<Props> = ({
  avatar,
  isProUser,
}: Props) => {
  return (
    <Fragment>
      {isProUser && (
        <GradientRoundedContainer width="45px" height="45px">
          <AvatarContainer top="2.3px">
            <Avatar src={avatar} />
          </AvatarContainer>
        </GradientRoundedContainer>
      )}
      {!isProUser && <Avatar src={avatar} />}
    </Fragment>
  );
};

export default CustomAvatar;
