export enum Steps {
  FACEBOOK_AD_ACCOUNT = "facebookAdAccount",
  FACEBOOK_PIXEL = "facebookPixel",
  FACEBOOK_API_TOKEN = "facebookApiToken",
}

export const commonCardsProps = {
  facebookAdAccount: {
    title: "First, connect your Facebook Ad Account",
    completedTitle: "Ad Account Connected",
    description:
      "Your Facebook Ad Account is where your Facebook + Instagram ads are run, and is responsible for the billing settings and audiences Symphony creates automatically.",
    errorTitle: "You'll need to select another ad account to proceed.",
    errorDescription: "",
    stepNumber: 1,
    buttonText: "Verify Connection",
  },
  facebookPixel: {
    title: "Next, select your Facebook Pixel",
    completedTitle: "Pixel Connected",
    description:
      "Select the Facebook Pixel you want to use to run ads. Make sure this pixel is on the website you want to run ads on.",
    stepNumber: 2,
    buttonText: "Verify Connection",
    retriedText: "We weren’t able to verify that your Ad Account and Pixel are connected. Please review the steps above to make sure everything looks correct.",
  },
  facebookApiToken: {
    title: "Last step - paste your Facebook Conversions API Access Token",
    description:
      "Follow the instructions below to copy your Conversions API Access Token from Facebook.",
    errorTitle: "It looks like your access token is invalid...",
    errorDescription:
      "Please try re-entering, or follow the steps to generate a new access token and copy / paste it directly above.",
    stepNumber: 3,
    buttonText: "Fix Issue",
  },
}
