import { makeStyles } from "@material-ui/core";
import { MEDIA_BREAKPOINTS } from "types/global";
import { SystemColors } from "types/globalStyles";

export const useStyles = makeStyles((theme) => ({
    iconContainer: {
        width: 24,
        height: 24,
        borderRadius: 8,
        padding: 4,
        backgroundColor: SystemColors.VIDEO_BUTTON_CONTAINER,
    },
    banner: {
        backgroundColor: SystemColors.PAPER_ACCENT_CONTAINER,
        padding: "16px 24px",
        gap: "4px",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        [MEDIA_BREAKPOINTS.mobileView]: {
            borderRadius: 0,
        }
    },
}));