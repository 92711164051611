import { Fragment, FunctionComponent, useContext } from 'react';
import {
  CardContainer,
  IconContainer,
  CardTitle,
  CardStatHeader,
  CardHeaderLabel,
  CardStatsContainer,
  CardSingleStatContainer,
  SingleStatTitle,
  StatValueLabel,
  TitleContainer,
} from './styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ArticleTitle, { Article } from 'components/shareable/ArticleTitle';
import { ReactComponent as GreyPlusIcon } from 'assets/images/plus.svg';
import { ReactComponent as GreyEqualIcon } from 'assets/images/equal.svg';
import { UpgradeModalContext } from 'Hooks/UpgradeModalContext';
import { Container } from 'styles/shared';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';

interface Props {
  svgIcon?: any;
  title: string;
  subtitle?: string | JSX.Element;
  upgradeToPro: boolean;
  proExclusive?: boolean;
  value: number;
  description?: JSX.Element;
  firstStatTitle: string;
  secondStatTitle: string;
  thirdStatTitle?: string;
  thirdStatValue: number;
  additionalContent?: JSX.Element | string;
}

const DetailCard2: FunctionComponent<Props> = ({
  title,
  value,
  description,
  firstStatTitle,
  secondStatTitle,
  thirdStatTitle,
  thirdStatValue,
  svgIcon,
  subtitle,
  upgradeToPro,
  proExclusive,
  additionalContent = null,
}) => {
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(2101));
  const mobileView = useMediaQuery(theme.breakpoints.down(1000));

  const valueToDisplay = Math.trunc(value).toLocaleString();
  const secondStatValueToDisplay = Math.trunc(thirdStatValue - value).toLocaleString();
  const thirdValueToDisplay = Math.trunc(Number(thirdStatValue)).toLocaleString();

  const getCardTitle = () => {
    const titleElement = <CardTitle>{title}</CardTitle>;
    return title !== 'Forever Saves' ? (
      titleElement
    ) : (
      <ArticleTitle
        children={titleElement}
        article={Article.ForeverSaves}
        toolTipText="Click to learn more about how Forever Saves work."
      />
    );
  };

  function renderSubtitle() {
    if (subtitle) {
      if (typeof subtitle === 'string') {
        return <SingleStatTitle>{subtitle}</SingleStatTitle>;
      } else if (typeof subtitle === 'object') {
        return subtitle;
      }
    } else {
      return null;
    }
  }

  return (
    <CardContainer flexDirection={'column'}>
      <Container
        width="100%"
        flexDirection={setResponsiveView ? 'column' : 'row'}
        alignItems={setResponsiveView ? 'flex-start' : 'space-between'}
        justifyContent={setResponsiveView ? 'flex-start' : 'space-between'}
        gap={setResponsiveView ? '16px' : '8px'}
        display="flex"
      >
        <CardStatHeader maxWidth={setResponsiveView ? '100%' : '50%'}>
          <IconContainer>{svgIcon}</IconContainer>
          <TitleContainer>
            <CardHeaderLabel>
              {getCardTitle()}
              {proExclusive && (
                <div className="ml-2">
                  <UpgradeToProChip type="gem" color="purple" height={18} width={18} />
                </div>
              )}
            </CardHeaderLabel>
            {renderSubtitle()}
            <p className="text-3xl font-medium">{thirdValueToDisplay}</p>
          </TitleContainer>
        </CardStatHeader>
        {upgradeToPro ? (
          <button
            className="text-sm text-primary border border-primary  text-semibold rounded-full  px-4 py-2 font-semibold"
            onClick={() => {
              handleOpenUpgradeToProModal({
                source: `Presave Campaign Details - ${title}`,
              });
            }}
          >
            Subscribe
          </button>
        ) : (
          <CardStatsContainer
            justifyContent={setResponsiveView ? 'flex-start' : 'space-between'}
            width={setResponsiveView ? '100%' : 'auto'}
            maxWidth={setResponsiveView ? 'auto' : description ? '70%' : '40%'}
            gap={setResponsiveView ? '24px' : '24px'}
          >
            <CardSingleStatContainer width="auto">
              <SingleStatTitle>{firstStatTitle}</SingleStatTitle>
              {description || <StatValueLabel>{valueToDisplay}</StatValueLabel>}
            </CardSingleStatContainer>
            <Fragment>
              <GreyPlusIcon />
              <CardSingleStatContainer width="auto">
                <SingleStatTitle>{secondStatTitle}</SingleStatTitle>
                <StatValueLabel>{secondStatValueToDisplay}</StatValueLabel>
              </CardSingleStatContainer>
            </Fragment>
            {
              !mobileView && (
                <Fragment>
                  <GreyEqualIcon />
                  <CardSingleStatContainer width="auto">
                    <SingleStatTitle>{thirdStatTitle}</SingleStatTitle>
                    <StatValueLabel>{thirdValueToDisplay}</StatValueLabel>
                  </CardSingleStatContainer>
                </Fragment>
              )
            }
          </CardStatsContainer>
        )}
      </Container>
      {additionalContent}
    </CardContainer>
  );
};

export default DetailCard2;
