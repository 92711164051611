import { FunctionComponent, useState } from "react";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";

interface ShazamConnectModalProps {
  songName?: string;
  brandSlug?: string;
  contentSlug?: string;
  closeModal: () => void;
  reload?: () => void;
}

const EMPTY = "";
const PLACEHOLDER = "e.g. https://shazam.com/abc";
const CONNECT_ERROR = "An error occurred, please try again later";
const INVALID_URL_ERROR = "You Shazam URL is invalid.";
//TODO: Test the recommendation of eslint on remove useless escape characters
const URL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /((http|https):\/\/)(www.)?[a-zA-Z0-9@:%._\+~#?&//=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%._\+~#?&//=]*)/;

const CloseIcon: FunctionComponent = () => (
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const ShazamConnectModal: FunctionComponent<ShazamConnectModalProps> = ({
  brandSlug,
  contentSlug,
  songName,
  closeModal,
  reload,
}: ShazamConnectModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(EMPTY);
  const [url, setUrl] = useState(EMPTY);
  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = URL_REGEXP.test(value);

    setError(!isValid ? INVALID_URL_ERROR : EMPTY);
    setDisableButton(!isValid);
    setUrl(value);
  };

  const onConnect = async () => {
    setError(EMPTY);

    try {
      const path = `/brand-content/${brandSlug}/${contentSlug}/link`;
      const data = { url, serviceName: "shazam" };
      setLoading(true);
      await Axios.post(path, data);
      if (reload) await reload();
      setLoading(false);
      closeModal();
    } catch (error) {
      //TODO: Add error handler
      console.error(error);
      setError(CONNECT_ERROR);
      setLoading(false);
    }
  };

  return (
    <div className=" h-full overflow-auto transform-gpu -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 fixed z-50 w-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="card-body w-full lg:w-6/12">
        <div className="card-content bg-white shadow-none lg:shadow-lg">
          <div className="top-wrapper">
            <div className="w-full">
              <div className="bg-white w-full h-full mx-auto">
                <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                  <p className="font-semibold text-gray-800">
                    Connect Shazam for "{songName}"
                  </p>
                  <div className="hover:cursor-pointer" onClick={closeModal}>
                    <CloseIcon />
                  </div>
                </div>
                <div className="px-6 mt-5 mb-5 lg:px-10">
                  <h4 className="text-2x font-semibold">
                    Enter the Shazam URL for your song.
                  </h4>
                  <p className="text-gray-500">
                    You can find it by going Shazam.com and searching for your
                    song name.
                  </p>
                </div>
                <div className="px-6 lg:px-10 border-t border-b border-gray-200">
                  <div className="grid grid-cols-12 items-center mt-5 mb-5">
                    <div className="col-span-6">
                      <h4 className="font-semibold">Shazam URL</h4>
                    </div>
                    <div className="flex justify-end col-span-6">
                      <input
                        placeholder={PLACEHOLDER}
                        value={url}
                        className="ltr w-full text-right block px-2 focus:outline-none border-none"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="mt-5 text-center text-red-500">{error}</div>
                )}
                <div className="text-center px-4 lg:px-10 py-4">
                  <button
                    disabled={disableButton || loading}
                    onClick={onConnect}
                    className="btn-primary w-full br-20px sm:md-20 lg:mb-4 py-2 lg:py-3 hover:cursor-pointer"
                  >
                    {loading ? (
                      <div className="h-6 m-auto flex justify-center">
                        <LoadingIndicator />
                      </div>
                    ) : (
                      "Connect"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShazamConnectModal;
