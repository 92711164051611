import {
  MAX_ADDITIONAL_USERS_WITH_PRO,
  MAX_ADDITIONAL_USERS_WITH_TEAM_PRO,
  MONTHLY_EXTRA_USER_PRICE,
} from "../../../../../constants"

export const getAlertProps = (hasReachedLimit?: boolean, isTeamPro?: boolean) => {
  if (isTeamPro) {
    // TODO: when the team plan beta finishes, this will change for correct texts
    return {
      type: "success",
      subtitle: `With a Team plan, you can invite ${MAX_ADDITIONAL_USERS_WITH_TEAM_PRO} users emails to your team for free, and $${MONTHLY_EXTRA_USER_PRICE} / month for additional users.`,
      body: `During our Team plan beta, additional users won't be charged! This may change in the near future...`
    }
  }

  return {
    type: hasReachedLimit ? "warning" : "success",
    subtitle: hasReachedLimit
      ? `You’ll be charged $${MONTHLY_EXTRA_USER_PRICE} / month for this new user`
      : `With Pro, you have ${MAX_ADDITIONAL_USERS_WITH_PRO} users included.`,
    body: hasReachedLimit
      ? `Every Pro account comes with ${MAX_ADDITIONAL_USERS_WITH_PRO} included user profiles, and additional ones can be added at any time for $${MONTHLY_EXTRA_USER_PRICE} monthly.`
      : "You can easily add more users for $5/ seat.",
  }
}
