import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  selected: {
    color: '#8800FF',
    backgroundColor: '#F5EBFF !important',
  },
}));

interface ContainerProps {
  flexDirection?: string;
  padding?: string;
  gap?: string;
}

interface StyledLabelProps {
  fontSize?: string;
  color?: string;
}

interface AddLabelProps {
  color?: string;
}

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  padding: ${({ padding }: ContainerProps) => padding};
  gap: ${({ gap }: ContainerProps) => gap};
`;

export const StyledLabel = styled.p`
  font-family: DIN;
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize};
  color: ${({ color }: StyledLabelProps) => color};
`;

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
export const TableHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 16px 0px 0px;
`;

export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 12px 8px;
  gap: 4px;
  :disabled {
    background-color: transparent;
    border: none;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const AddLabel = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }: AddLabelProps) => color}; ;
`;

export const TableTitleLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
  height: fit-content;
`;

export const PauseNotification = styled.p`
  font-family: DIN;
  font-size: 12px;
  height: fit-content;
  color: #707070;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export default useStyles;
