const PLATFORMS_BADGE_ICONS: Record<string, string> = {
    "spotify": require("assets/images/spotify.svg").default,
    "apple_music": require("assets/images/streaming/icons/apple-icon.svg").default,
    "deezer": require("assets/images/streaming/icons/deezer-icon.svg").default,
    "youtube": require("assets/images/video-logo.svg").default,
    "link_clicks": require("assets/images/click-gray.svg").default,
    "instagram": require("assets/images/instagram-bordered-icon.svg").default,
    "fans": require("assets/images/fans.svg").default
}

export default PLATFORMS_BADGE_ICONS