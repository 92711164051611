import styled from 'styled-components';

interface FanbaseContainerProps {
  justifyContent?: string;
}

export const FanbaseContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: ${({ justifyContent }: FanbaseContainerProps) => justifyContent || "flex-start"};
`;