import Colors from "./Colors";

export default {
    primary: {
        fontSize: 18,
        color: Colors.black,
        fontWeight: 400,
    },
    secondary: {
        fontSize: 16,
        fontWeight: 400,
        color: Colors.textGreyLight,
    },
    subHeader: {
        fontSize: 18,
        fontWeight: 500,
        color: Colors.black,
    },
    input: {
        padding: "12px 16px",
        width: "100%",
        fontSize: 16,
        fontWeight: 400,
        color: Colors.black,
        borderRadius: 6,
        border: "1px solid #EDECF2",
        outline: "none",
    },
    borderSeparator: {
        height: 1,
        width: "100%",
        backgroundColor: "#EDECF2",
        marginBottom: 16,
    },
}
