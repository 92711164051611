import { MenuItem, Select, TextField } from "@material-ui/core";
import SecondaryButton from "components/shareable/SecondaryButton";
import { Body2, Subtitle1, Subtitle2 } from "components/shareable/Typography";
import { Image, SHARED_WEBSITE_STYLES, WebsiteEditorCard } from "pages/post-auth/Website/styles";
import { Container, StyledLabel, TextButton } from "styles/shared";
import { SystemColors } from "types/globalStyles";
import { useStyles } from "../../styles";
import { Switch } from "@mui/material";
import { StreamingLinkInputs } from "../StreamingLinkInputs";
import Text from 'modules/Text';
import { checkIfIsProTier, checkIsValidUrl, deepCopy } from "utils";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { StreamingLinkTypeOptions, THEME_STYLES, WebsiteLink, WebsiteLinks, getIconBackgroundStyle, websiteLinkInitObj } from "../../utils";
import { SocialMediaIconsInputs, renderIcon } from "../SocialMediaIconsInputs";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import SymphonyLogo from 'assets/images/symphonyLogo.svg';
import { WebsiteContext } from "../../hooks/WebsiteContext";
import { STREAMLINK_REGEX, streamingLinkNameMapping } from "helpers/StreamingMappings";
import { debounce } from "lodash"
import GemIcon from "components/shareable/ProCallouts/GemIcon";
import clsx from "clsx";
import { TaskIcon, TaskIconImage, TaskLink } from "components/TaskCard/styles";
import DollarSignIcon from "assets/images/gettingStarted/dollar_sign.svg"
import { ArrowRight } from "@material-ui/icons";
import LinkButton from "components/shareable/LinkButton";
import Intercom from "helpers/Intercom";

const { ART, CLASSIC } = THEME_STYLES

export type StreamingRegexType = keyof typeof STREAMLINK_REGEX
export type StreamingNameType = keyof typeof streamingLinkNameMapping

export const MusicSiteSetupSection = () => {
  const { currentBrand } = useContext(CurrentBrandContext);
  const classes = useStyles();
  const sharedClasses = SHARED_WEBSITE_STYLES();
  const isProUser = checkIfIsProTier(currentBrand);
  const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

  const {
    subtitle, setSubtitle,
    streamingLinks = {}, setStreamingLinks,
    socialMediaIcons = {}, setSocialMediaIcons,
    titleText, setTitleText,
    showSecondaryLine, setShowSecondaryLine,
    customLinksAdded, setCustomLinksAdded,
    isNewLink, setIsNewLink,
    enableSocialIcons, setEnableSocialIcons,
    customLinkOptions, setCustomLinkOptions,
    removeSymphonyBrand, setRemoveSymphonyBrand,
    addAffiliateLinkToLogo, setAddAffiliateLinkToLogo,
    embedYt, setEmbedYt,
    youtubeVideoLink, setYTVideoLink,
    themeStyle,
  } = useContext(WebsiteContext);

  const [customLinkUrlText, setCustomLinkUrlText] = useState<string>('')
  const [customButtonText, setCustomButtonText] = useState<string>('')
  const [customLinkSelected, setCustomLinkSelected] = useState<WebsiteLink>(customLinkOptions[0])
  const [invalidUrl, setInvalidUrl] = useState<boolean>(false)

  useEffect(() => {
    checkIfInvalidUrl()
  }, [customLinkSelected, customLinkUrlText])

  const selectCustomLink = (option: string) => {
    const customLink = {
      ...websiteLinkInitObj,
      title: option,
      key: option,
      url: customLinkUrlText,
    }
    setCustomLinkSelected(customLink);
  };

  const handleStreamingLinkUrlChange = (value: string, streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded[linkIndex].url = value;
      setCustomLinksAdded(newCustomLinksAdded);
      return;
    }

    let newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    newStreamingLinks[streamingService.key].url = value;
    setStreamingLinks(newStreamingLinks);
  };

  const handleStreamingLinkEnabledSwitch = (checked: any, streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded[linkIndex].enabled = checked;
      setCustomLinksAdded(newCustomLinksAdded);
    }

    let newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    newStreamingLinks[streamingService.key].enabled = checked;
    setStreamingLinks(newStreamingLinks);
  };

  const onDeleteStreamingService = (streamingService: WebsiteLink) => {
    const isCustomLink = customLinksAdded?.find((link) => link.key === streamingService.key);

    if (isCustomLink) {
      const newCustomLinksAdded = deepCopy(customLinksAdded) as WebsiteLink[];
      const linkIndex = newCustomLinksAdded.findIndex((link) => link.key === streamingService.key);
      newCustomLinksAdded.splice(linkIndex, 1);
      setCustomLinksAdded(newCustomLinksAdded);
      return;
    }

    const newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
    delete newStreamingLinks[streamingService.key]
    setStreamingLinks(newStreamingLinks)
  }

  const onChangeCustomLinkUrl = (value: string): any => {
    debouncedCheckForValidStreamingService(value);
    setCustomLinkUrlText(value);
  }

  const checkForValidStreamingService = async (value: string) => {
    const validationRegex = Object.entries(STREAMLINK_REGEX)
    const regexMatch = validationRegex.find(([_, regex]) => regex.find((expresion) => expresion.test(value)))
    if (regexMatch) {
      const customLink = StreamingLinkTypeOptions.find((link) => link.key === regexMatch[0]);
      setCustomLinkSelected(customLink!);
    } else {
      setCustomLinkSelected({
        ...websiteLinkInitObj,
        title: 'Custom Link',
        key: 'custom_link',
        url: customLinkUrlText,
      });
    }
  }

  const checkIfInvalidUrl = () => {
    const validationRegex = STREAMLINK_REGEX[customLinkSelected?.key as StreamingRegexType]
    if (!validationRegex) {
      setInvalidUrl(!checkIsValidUrl(customLinkUrlText));
      return;
    }
    const isValid = validationRegex.find((expresion) => expresion.test(customLinkUrlText))
    setInvalidUrl(!Boolean(isValid))
  }

  const debouncedCheckForValidStreamingService = useMemo(
    () =>
      debounce(async (value: string) => {
        await checkForValidStreamingService(value)
      }, 500),
    []
  )

  const saveNewLink = () => {
    if (!customLinkSelected) return;

    const alreadyAdded = streamingLinks[customLinkSelected.key]

    if (alreadyAdded) {
      const newStreamingLinks = deepCopy(streamingLinks) as WebsiteLinks;
      newStreamingLinks[customLinkSelected.key].url = customLinkUrlText;
      setStreamingLinks(newStreamingLinks);
      setCustomLinkUrlText('');
      setIsNewLink(false);
      setCustomLinkSelected(customLinkOptions[0])
      return;
    }

    if (customButtonText.length) {
      const newStreamingLink = {
        ...customLinkSelected,
        url: customLinkUrlText,
        title: (customButtonText || customLinkSelected.title),
        key: (customButtonText || customLinkSelected.title),
        type: 'custom_link',
      }
      setCustomLinksAdded((prev) => prev?.concat(newStreamingLink));
      setStreamingLinks((prev) => ({ ...prev, [newStreamingLink.key]: newStreamingLink }));
      setCustomLinkUrlText('');
      setCustomButtonText('');
      setIsNewLink(false);
      return;
    }

    const newStreamingLink = {
      ...customLinkSelected,
      url: customLinkUrlText,
      title: (customButtonText || customLinkSelected.title),
    }

    const newCustomLinkOptions = deepCopy(customLinkOptions) as WebsiteLink[];
    const optionAddedIndex = newCustomLinkOptions.findIndex((link) => link.key === customLinkSelected.key);
    newCustomLinkOptions.splice(optionAddedIndex, 1);

    setStreamingLinks((prev) => ({ ...prev, [newStreamingLink.key]: newStreamingLink }));
    setCustomLinkSelected(customLinkOptions[0])
    setCustomLinkOptions(newCustomLinkOptions);
    setCustomLinkUrlText('');
    setIsNewLink(false);
  };

  const handleSocialMediaUrlChange = (value: string, sm: WebsiteLink) => {
    const socialMediaUrl = value;
    let newSocialMediaIcons = deepCopy(socialMediaIcons) as WebsiteLinks;

    newSocialMediaIcons[sm.key].url = socialMediaUrl;
    setSocialMediaIcons(newSocialMediaIcons);
  };

  const handleSocialMediaEnabledSwitch = (checked: any, sm: WebsiteLink) => {
    const isEnabled = checked;
    let newSocialMediaIcons = deepCopy(socialMediaIcons) as WebsiteLinks;

    newSocialMediaIcons[sm.key].enabled = isEnabled;
    setSocialMediaIcons(newSocialMediaIcons);
  };

  const handleRemoveSymphonyBrand = (
    _e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setRemoveSymphonyBrand(!checked)
    // Automatically toggle the affiliate link based on the Symphony logo's visibility
    setAddAffiliateLinkToLogo(checked);
  }

  const handleOnClickNewLink = () => {
    if (!customLinkSelected) {
      setCustomLinkSelected(
        customLinkOptions[0] || {
          ...websiteLinkInitObj,
          title: 'Custom Link',
          key: 'custom_link',
          type: 'custom_link',
        }
      );
    }
    setIsNewLink(true);
  }

  return (
    <div
      className={classes.customizeWebsiteV2Variable}
    >
      <div className='w-full flex flex-col gap-4'>
        <WebsiteEditorCard title='Title'>
          <input
            className=''
            style={Text.input}
            type='text'
            value={titleText}
            placeholder='Title'
            onChange={(e) => setTitleText(e.target.value)}
          />
          <div className={classes.secondaryTextSection}>
            <Switch
              checked={showSecondaryLine}
              onChange={(e: any) => setShowSecondaryLine(e.target.checked)}
              className={clsx(showSecondaryLine && classes.switchEnabled)}
            />
            <div className='addSecondaryText' style={Text.secondary}>
              Add secondary line
            </div>
          </div>
          {showSecondaryLine && (
            <input
              style={Text.input}
              type='text'
              value={subtitle}
              placeholder='Secondary line'
              onChange={(e) => setSubtitle(e.target.value)}
            />
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard
          title='Streaming Links'
          subtitle="Add + edit your streaming service URLs and custom links."
        >
          <StreamingLinkInputs
            {...{
              handleStreamingLinkUrlChange,
              handleStreamingLinkEnabledSwitch,
              onDeleteStreamingService,
            }}
          />
          {Boolean(isProUser || customLinksAdded?.length < 3) && (
            <div className='flex justify-center'>
              <SecondaryButton
                text='+ Add New Link'
                onClick={() => handleOnClickNewLink()}
              />
            </div>
          )}
          {isNewLink && (
            <div
              className={sharedClasses.innerPanelSection}
              style={{ display: 'flex', gap: 24, flexDirection: 'column', marginBottom: 0 }}
            >
              <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>
                Add information about your link.
              </Body2>
              <div
                className='inputTitle'
                style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
              >
                <Subtitle2>URL</Subtitle2>
                <TextField
                  variant='outlined'
                  inputProps={{ className: classes.socialMediaInput }}
                  value={customLinkUrlText}
                  placeholder='Enter your link / URL'
                  onChange={(e) => onChangeCustomLinkUrl(e.target.value)}
                />
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
              >
                <Subtitle2>Link Type</Subtitle2>
                <div
                  style={{ width: '100%', display: 'flex', gap: 8 }}
                >
                  <div
                    className={classes.iconBackground}
                    style={getIconBackgroundStyle(customLinkSelected)}
                  >
                    {renderIcon(true, customLinkSelected)}
                  </div>
                  <Select
                    placeholder='Select type of link'
                    value={customLinkSelected.key}
                    onChange={(event, value) => {
                      const selected = (value as ReactElement).props
                      selectCustomLink(selected.value)
                    }}
                    renderValue={(selected) => {
                      if (String(selected).length === 0) {
                        return <em>Select your link type</em>;
                      }
                      return streamingLinkNameMapping[selected as StreamingNameType] || 'Custom Link';
                    }}
                    variant="outlined"
                    style={{ width: '100%', height: 45 }}
                    onEmptied={() => <>You already added all streaming links</>}
                  >
                    <MenuItem value="custom_link">
                      Custom Link
                    </MenuItem>
                    {customLinkOptions.map((option) => <MenuItem value={option.key}>{option.title}</MenuItem>)}
                  </Select>
                </div>
              </div>
              {customLinkSelected.key === 'custom_link' && (
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
                >
                  <Subtitle2>Button Text</Subtitle2>
                  <TextField
                    variant='outlined'
                    style={{ height: '45px', width: '100%' }}
                    inputProps={{ style: { padding: '12px 16px' } }}
                    placeholder='Add button text'
                    value={customButtonText}
                    onChange={(e) => setCustomButtonText(e.target.value)}
                  />
                </div>
              )}
              <div className='flex gap-2'>
                <SecondaryButton
                  text='Cancel'
                  onClick={() => setIsNewLink(false)}
                />
                <SecondaryButton
                  text='Save'
                  onClick={() => saveNewLink()}
                  disabled={Boolean(!customLinkSelected || !customLinkUrlText || invalidUrl || (customLinkSelected.key === 'custom_link' && !customButtonText.length))}
                />
              </div>
            </div>
          )}
        </WebsiteEditorCard>
        {(themeStyle === ART || themeStyle === CLASSIC) && (
          <WebsiteEditorCard
            title="YouTube Embed"
            subtitle="Paste the URL of the Youtube video you want to embed in your website here."
          >
            <Switch
              checked={embedYt}
              onChange={(e: any) => setEmbedYt(e.target.checked)}
              className={clsx(embedYt && classes.switchEnabled)}
            />
            {embedYt && (
              <input
                style={Text.input}
                type='text'
                value={youtubeVideoLink}
                placeholder='YouTube Embed URL'
                onChange={(e) => setYTVideoLink(e.target.value)}
              />
            )}
          </WebsiteEditorCard>
        )}
        <WebsiteEditorCard title='Social platforms'
          subtitle="Add social icons to your website.">
          <div className='enableSocialIconsSwitch flex items-center'>
            <Switch
              checked={enableSocialIcons}
              onChange={(e: any) => setEnableSocialIcons(e.target.checked)}
              className={clsx(enableSocialIcons && classes.switchEnabled)}
            />
            <h2 className='mb-0'>Show Social Icons</h2>
          </div>
          {enableSocialIcons && (
            <SocialMediaIconsInputs
              {...{
                socialMediaIcons,
                handleSocialMediaUrlChange,
                handleSocialMediaEnabledSwitch,
              }}
            />
          )}
        </WebsiteEditorCard>
        <WebsiteEditorCard title='Additional Options'>
          <Container
            display='flex'
            flexDirection='row'
            alignItems={'center'}
            justifyContent='flex-start'
            onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
          >
            <Container alignItems='center' justifyContent='center'>
              <Switch
                disabled={!isProUser}
                onChange={handleRemoveSymphonyBrand}
                checked={!removeSymphonyBrand}
                className={clsx(!removeSymphonyBrand && classes.switchEnabled)}
              />
            </Container>
            <Container display='flex' gap="4px" alignItems="center">
              <StyledLabel fontSize={16}>Show Symphony Logo</StyledLabel>
              {!isProUser && (
                <GemIcon color="purple" width={18} height={18} />
              )}
            </Container>
          </Container>
          {!removeSymphonyBrand && (
            <Container
              display='flex'
              alignItems='center'
              gap='16px'
              borderRadius='8px'
              padding='16px'
              justifyContent='center'
              width='100%'
              backgroundColor='#8C8C8C'
            >
              <StyledLabel fontSize={16} color='#ffffff'>
                Powered by
              </StyledLabel>
              <Container
                backgroundColor='#000000'
                padding='4px 12px'
                borderRadius='4px'
              >
                <Image
                  width={130}
                  height={17}
                  alt='symphony-logo.svg'
                  src={SymphonyLogo}
                />
              </Container>
            </Container>
          )}
          <Container
            display='flex'
            flexDirection='row'
            alignItems={'center'}
            justifyContent='flex-start'
            onClick={(() => !isProUser && handleOpenUpgradeToProModal())}
          >
            <Container alignItems='center' justifyContent='center'>
              <Switch
                disabled={!isProUser}
                onChange={(e: any) => setAddAffiliateLinkToLogo(e.target.checked)}
                className={clsx(addAffiliateLinkToLogo && classes.switchEnabled)}
                checked={addAffiliateLinkToLogo}
              />
            </Container>
            <StyledLabel fontSize={16}>Add your Referral Link to Symphony Logo</StyledLabel>
          </Container>
          {(
            <div className={classes.banner}>
              <Container display="flex" gap="8px" flexDirection="column" alignItems="flex-start" justifyContent="center">
                <Container display="flex" flexDirection="row" gap={"8px"} alignItems="center">
                  <img className="w-8 h-8" src={DollarSignIcon} />
                  <Subtitle2>Get paid when people sign up using your Referral Link.</Subtitle2>
                </Container>
                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>The Symphony referral program gives you a 50% commission for every new Symphony Pro subscriber you drive. Earn up to $120 / year.</Body2>
                <LinkButton
                  onClick={() => {
                    Intercom.openArticleWithId('7002468')
                  }}
                  text={`Learn More >`}></LinkButton>
              </Container>
            </div>
          )}

        </WebsiteEditorCard>
      </div>
    </div>
  )
}