import styled from "styled-components"

interface StyledSectionProps {
  display?: string
  flexDirection?: string
  gap?: string
  width?: string
  maxWidth?: string
}

export const CardsSection = styled.section`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: auto;
  width: 86%;
  margin-bottom: 32px;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
`;

export const StyledSection = styled.section<StyledSectionProps>`
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ gap }) => gap};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
`;
