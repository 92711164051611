import { InputAdornment, Slider, TextField, makeStyles } from "@material-ui/core";
import { ArtistInputContainer, BackArrowImage, BackButtonContainer, BackButtonText, BackToDashboardContainer, EnterArtistContainer, EnterArtistLabel, FlexContainer, LeftColumn, LogoContainer, LottieContainer, MainTitle, PlatformLogoContainer, RightColumn, SubTitle, Titles } from "../../style";
import Lottie from "react-lottie";
import GetStartedLottie from 'assets/images/lotties/GetStarted.json';
import GrayArrow from "assets/images/arrow-right-icon.svg"
import { ReactComponent as SymphonyLogo } from 'assets/images/logo.svg';
import HappyFace from './images/high-bar.svg';
import NeutralFace from './images/medium-bar.svg';
import SadFace from './images/none-bar.svg';

import { Container } from "styles/shared";
import OptionCard from "./OptionCard";
import CreatorAccountTypeIcon from "./images/creator-account-type.png"
import TeamAccountTypeIcon from "./images/team-account-type.png"
import { Headline1, Headline2, Headline3 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OnboardingAccountTypes } from "../..";
import MarketingSelectorVertical, { MarketingSelectorOption } from "./MarketingSelectorVertical";
import { Theme } from "@mui/material";
import PrimaryButton from "components/shareable/PrimaryButton";
import { useEffect, useState } from "react";
import { useStyles } from "./styles";




export default function MarketingBudget(props: {
    responsiveView: boolean,
    showBackButton: boolean,
    handleGoBack: () => void,

    handleSelected: (option: {
        value: number;
        label: string;
    }) => void,
}) {

    const {
        responsiveView,
        showBackButton,
        handleGoBack,

        handleSelected
    } = props;

    const classes = useStyles();


    const [selectedValue, setSelectedValue] = useState<number>(0);

    const [userChangedValue, setUserChangedValue] = useState<boolean>(false);

    const marks: {
        value: number;
        label: string;
    }[] = [
            {
                value: 0,
                label: 'None',
            },
            {
                value: 1,
                label: '$10 - 100',
            },
            {
                value: 2,
                label: '$100 - $500',
            },
            {
                value: 3,
                label: '$500 - $1,000',
            },
            {
                value: 4,
                label: '+$1,000',
            },
        ];



    const selectedItem = marks.find(item => item.value === selectedValue)
    return (
        <>
            <LeftColumn width={responsiveView ? "100%" : "50%"} isMobile={responsiveView}>
                {showBackButton && responsiveView &&
                    <BackToDashboardContainer>
                        <BackButtonContainer onClick={handleGoBack}>
                            <BackArrowImage src={GrayArrow} />
                        </BackButtonContainer>
                    </BackToDashboardContainer>
                }
                <LogoContainer>
                    <SymphonyLogo height={'30px'} />
                </LogoContainer>
                <Titles gap={responsiveView ? "8px" : "18px"}>
                    {showBackButton && !responsiveView &&
                        <BackToDashboardContainer>
                            <BackButtonContainer onClick={handleGoBack}>
                                <BackArrowImage src={GrayArrow} />
                            </BackButtonContainer>
                        </BackToDashboardContainer>
                    }
                    <MainTitle>What's your average monthly marketing budget?</MainTitle>
                    <SubTitle>Every artist has a certain amount of budget they spend. If you do have a budget, what's yours?</SubTitle>
                </Titles>
                <Container width={responsiveView ? "85%" : "100%"}
                    margin={responsiveView ? 'auto' : 'inherit'}
                    display={responsiveView ? 'flex' : 'block'}
                    flexDirection={responsiveView ? 'column' : 'unset'}
                    justifyContent={responsiveView ? 'center' : 'unset'}
                    alignItems={responsiveView ? 'center' : 'unset'}

                >

                    <Headline1>{!userChangedValue ? 'Slide to select.' : (selectedItem ? selectedItem.label : '')}</Headline1>

                    <Slider
                        onChange={(event: any, value: any) => {
                            setUserChangedValue(true);
                            setSelectedValue(value as number)
                        }}
                        className={classes.ageSlider}
                        defaultValue={0}
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="off"
                        marks={marks.filter((o, idx) => idx === 0 || idx === marks.length - 1)}
                        step={1}
                        min={0}
                        max={4}
                        value={selectedValue}
                    />

                </Container>
                <PrimaryButton
                    text="Continue"
                    width={responsiveView ? "100%" : "300px"}

                    onClick={() => {
                        const foundItem = marks.find(item => item.value === selectedValue);
                        handleSelected(foundItem!)
                    }} />

            </LeftColumn>
            <RightColumn display={responsiveView ? 'none' : 'flex'}>
                <LottieContainer>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: GetStartedLottie,
                        }}
                    />
                </LottieContainer>
            </RightColumn>
        </>
    )
}