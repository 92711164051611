
import styled from 'styled-components'

export const PostParent = styled.div`
    width: 24%;
`

export const Posts = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
`