import { makeStyles, Theme } from "@material-ui/core/styles";
import { SystemColors } from "types/globalStyles";

const useStyles = makeStyles((theme: Theme) => ({
  chipLabelColor: {
    color: SystemColors.PRIMARY_TEXT_COLOR,
  },
  chip: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    padding: '8px 12px',
    width: 'fit-content',
  },
  chipBackgroundColor: {
    backgroundColor: `${SystemColors.ACCENT_COLOR}08`,
  },
}));

export default useStyles;