
import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const AppleMusicIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick}) => {
    const appleMusicColor = iconColor ?? "#FFFFFF";
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => {onClick && onClick()}}>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.5808 25.5202C29.2218 26.3505 28.7967 27.1148 28.3043 27.8174C27.633 28.7753 27.0834 29.4384 26.6598 29.8066C26.0032 30.411 25.2997 30.7205 24.5464 30.7381C24.0056 30.7381 23.3534 30.5841 22.5942 30.2716C21.8325 29.9606 21.1325 29.8066 20.4925 29.8066C19.8212 29.8066 19.1013 29.9606 18.3312 30.2716C17.56 30.5841 16.9387 30.7469 16.4637 30.763C15.7413 30.7939 15.0213 30.4755 14.3025 29.8066C13.8438 29.4061 13.27 28.7196 12.5826 27.747C11.8451 26.7084 11.2388 25.5041 10.7637 24.131C10.255 22.648 10 21.2118 10 19.8215C10 18.2288 10.3438 16.8552 11.0325 15.7041C11.5738 14.7795 12.2939 14.0501 13.1951 13.5147C14.0963 12.9793 15.0701 12.7064 16.1187 12.689C16.6925 12.689 17.445 12.8666 18.38 13.2157C19.3125 13.566 19.9112 13.7437 20.1737 13.7437C20.3699 13.7437 21.035 13.536 22.1625 13.1218C23.2288 12.7378 24.1287 12.5788 24.8659 12.6414C26.8636 12.8028 28.3644 13.591 29.3625 15.011C27.5759 16.0944 26.6921 17.612 26.7097 19.5587C26.7258 21.0751 27.2754 22.337 28.3556 23.3389C28.8451 23.8039 29.3918 24.1633 30 24.4186C29.8681 24.8014 29.7289 25.1682 29.5808 25.5202V25.5202ZM24.9993 7.47544C24.9993 8.66396 24.5654 9.77369 23.7007 10.8008C22.6572 12.0219 21.395 12.7275 20.0262 12.6162C20.0079 12.4668 19.9987 12.3164 19.9987 12.1658C19.9987 11.0248 20.4949 9.80375 21.3762 8.80536C21.8162 8.29986 22.3758 7.87954 23.0544 7.54424C23.7315 7.21395 24.372 7.03129 24.9744 7C24.992 7.15888 24.9993 7.31778 24.9993 7.47542V7.47544Z" fill={appleMusicColor} />
            </svg>
        </IconHolder>
    );
};
