/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useContext,
  HTMLProps,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import {
  PreSaveDataContext,
  Details as DetailsContext,
  Customize as CustomizeContext,
} from "pages/post-auth/MarketingPage/Data/PreSaveProvider";
import { useHistory } from "react-router";
import momentTz from "moment-timezone";
import Axios from "helpers/Interceptor";
import {
  CampaignPreSaveCreationData,
  BrandContentExistsData,
  PresaveCampaignExistsData,
} from "types/global";
import { LoadingIndicator } from "components/Loader";
import { formatTabsData, getPostCreationValuesFromData } from "../../utils";
import SuccessImage from "assets/images/tick-light.png";
import { track } from "analytics";
import isEqual from "lodash/isEqual";
import { PreSaveErrorTypes as ErrorTypes } from "types/global";
import CopyLinkInputButton from "components/shareable/CopyLinkInputButton";
import { Card } from "./styles";
import { STEPS } from "../../index";
import * as Sentry from "@sentry/react";

const { BRAND_CONTENT_EXISTS, PRE_SAVE_CAMPAIGN_EXISTS } = ErrorTypes;

type CustomButtonProps = HTMLProps<HTMLButtonElement> & {
  label: string;
  type?: "button" | "submit" | "reset";
};

type Data =
  | CampaignPreSaveCreationData
  | BrandContentExistsData
  | PresaveCampaignExistsData
  | null;

const CustomButton: FunctionComponent<CustomButtonProps> = (
  props: CustomButtonProps
) => {
  return (
    <div className="text-center px-4 lg:px-10 py-4">
      <button {...props}>{props.label}</button>
    </div>
  )
};

const sendDataToAPI = async (args: {
  draft: string | null;
  brandId: number;
  details: DetailsContext;
  customize: CustomizeContext;
  setLoading: (value: boolean) => void;
  setError: Dispatch<SetStateAction<boolean>>;
  setCampaignData: Dispatch<SetStateAction<any>>;
}) => {
  const { brandId, details, customize, draft, setLoading, setError, setCampaignData } = args;
  try {
    Sentry.setTag('Flow', 'CreatePresaveCampaign')
    Sentry.setContext('Input', { brandId, details, customize, draft })

    momentTz.tz.setDefault(details.timezone.value);

    const dataToSend = formatTabsData(details, customize, draft);
    const path = `/campaign/brand/${brandId}/presave`;

    const response = await Axios.post(path, dataToSend);
    const data = response?.data?.data as Data;

    if (data) {
      setCampaignData(data as Data);
      setError(false);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  } catch (error) {
    Sentry.captureException(error)
    setError(true);
    setLoading(false);
  } finally {
    momentTz.tz.setDefault();
  }
};

interface Props {
  draft: string | null;
  brandId: number;
  setGoal: Dispatch<SetStateAction<string | null>>;
  setSelectedTab: Dispatch<SetStateAction<STEPS>>
}

const DEFAULT_ERROR_MESSAGE = "An error occurred, please try again later.";

const PostCreation: FunctionComponent<Props> = ({
  draft,
  brandId,
  setGoal,
  setSelectedTab
}: Props) => {
  const { loading, setLoading, details, customize, setPostCreationErrorType, setShowPostCreation } =
    useContext(PreSaveDataContext);
  const { releaseTitle } = customize;
  const [error, setError] = useState(false);
  const [campaignData, setCampaignData] = useState<Data>(null);
  const history = useHistory();
  const {
    link,
    id: campaignId,
    errorLabel,
    errorType,
  } = getPostCreationValuesFromData(campaignData);
  const args = {
    draft,
    brandId,
    details,
    customize,
    setLoading,
    setError,
    setCampaignData,
  };
  const showDefaultError =
    !isEqual(errorType, PRE_SAVE_CAMPAIGN_EXISTS) &&
    !isEqual(errorType, BRAND_CONTENT_EXISTS) &&
    error;
  const showCampaignExistsError = isEqual(errorType, PRE_SAVE_CAMPAIGN_EXISTS);
  const showBrandContentExistsError = isEqual(errorType, BRAND_CONTENT_EXISTS);

  const handleOnClickCopy = () => navigator.clipboard.writeText(link);

  const handleClickSeeDetailsButton = () => {
    setPostCreationErrorType(null);
    history.push(`/marketing/campaigns/${campaignId}`);
  };

  const handleGoToMarketingScreen = () => {
    setPostCreationErrorType(null);
    setGoal(null);
    history.push('/marketing');
  };

  const handleRetry = () => {
    setShowPostCreation(false)
    setSelectedTab(STEPS.CUSTOMIZE)
  };

  useEffect(() => {
    sendDataToAPI(args);
  }, [brandId]);

  useEffect(() => {
    if (error || errorLabel) {
      track("Pre-Save Creation Error", {
        type: "pre_save",
        brand_id: brandId,
        error: error,
        errorLabel,
        ...(customize ? customize : {}),
        ...(details ? details : {}),
      });
    } else if (campaignData) {
      track("Submitted Campaign", {
        type: "pre_save",
        brand_id: brandId,
        ...(customize ? customize : {}),
        ...(details ? details : {}),
      });
    }
  }, [campaignData]);

  useEffect(() => {
    if (errorType) setPostCreationErrorType(errorType);
  }, [errorType]);

  return (
    <Card>
      {loading && (
        <div className="px-28 flex flex-col items-center text-center">
          <LoadingIndicator color="black" height="80px" />
          <p className="mt-1">Loading...</p>
        </div>
      )}
      {(error || errorLabel) && !loading && (
        <React.Fragment>
          <div className="px-28 text-center">
            <div className="flex flex-col gap-y-3">
              {showDefaultError && (
                <React.Fragment>
                  <span className="font-semibold text-2xl">
                    {DEFAULT_ERROR_MESSAGE}
                  </span>
                  <span className="font-semibold text-xl">{link}</span>
                </React.Fragment>
              )}
              {showCampaignExistsError && (
                <React.Fragment>
                  <span className="font-semibold text-2xl">
                    {errorLabel && errorLabel}
                  </span>
                  <div className="mt-4">
                    <span className="font-semibold text-lg">
                      Share this link with your fans to start collecting
                      pre-saves:
                    </span>
                  </div>
                  <CopyLinkInputButton
                    {...{ link }}
                    onClick={handleOnClickCopy}
                  />
                  <CustomButton
                    className="
                      font-medium py-2 px-4 bg-primary 
                      text-base lg:w-80 text-white rounded-full 
                      w-full br-20px sm:md-20 lg:py-2 mt-2"
                    type="button"
                    onClick={handleClickSeeDetailsButton}
                    label="See Campaign Details"
                  />
                </React.Fragment>
              )}
              {showBrandContentExistsError && (
                <React.Fragment>
                  <span className="font-semibold text-2xl">
                    {errorLabel && errorLabel}
                  </span>
                  <CopyLinkInputButton
                    {...{ link }}
                    onClick={handleOnClickCopy}
                  />
                  <CustomButton
                    className="
                      font-medium py-2 px-4 bg-primary 
                      text-base lg:w-80 text-white rounded-full 
                      w-full br-20px sm:md-20 lg:py-2 mt-2"
                    type="button"
                    onClick={handleGoToMarketingScreen}
                    label="Go To Marketing"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          {showDefaultError && (
            <div>
              <CustomButton
                className="
                  font-medium py-2 px-4 bg-primary 
                  text-base lg:w-80 text-white rounded-full 
                  mt-4 w-full br-20px mb-20 sm:md-20 lg:mb-4 lg:py-2"
                type="button"
                onClick={handleRetry}
                label="Retry"
              />
            </div>
          )}
        </React.Fragment>
      )}
      {!error && !errorLabel && !loading && (
        <React.Fragment>
          <div className="text-center">
            <img
              alt="tick-light.png"
              className="mx-auto my-4"
              src={SuccessImage}
            />
            <p className="font-semibold text-2xl">Your pre-save campaign is</p>
            <p className="px-6 sm:px-36 font-semibold text-2xl break-words">
              now live for “{releaseTitle}”!
            </p>
          </div>
          <div className="mt-4">
            <span className="font-semibold text-lg">
              Share this link with your fans to start collecting pre-saves:
            </span>
          </div>
          <CopyLinkInputButton {...{ link }} onClick={handleOnClickCopy} />
          <CustomButton
            className="
              font-medium py-2 px-4 bg-primary 
              text-base lg:w-80 text-white rounded-full 
              w-full br-20px sm:md-20 lg:py-2 mt-2"
            type="button"
            onClick={handleClickSeeDetailsButton}
            label="See Campaign Details"
          />
        </React.Fragment>
      )}
    </Card>
  );
};

export default PostCreation;
