import styled from "styled-components";

interface InfoLabelProps {
  color?: string;
}

interface InputTextLabelProps {
  alignItems?: string;
  flexDirection?: string;
}

export const Dialog = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const DialogHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const DialogContent = styled.div`
  min-height: 300px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const DialogTitle = styled.p`
  font-weight: 600;
  color: rgba(31, 41, 55, 1);
`;

export const DialogSubtitle = styled.h4`
  margin-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  border-bottom-width: 1px;
  border-color: rgba(229, 231, 235, 1);
`;

export const CloseIconContainer = styled.div``;

export const InputTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
`;

export const InputTextLabel = styled.label`
  position: absolute;
  height: 64px;
  background-color: #ffffff;
  align-items: ${({ alignItems }: InputTextLabelProps) => alignItems};
  display: flex;
  font-size: 18px;
  flex-direction: ${({ flexDirection }: InputTextLabelProps) => flexDirection}
`;

export const InputText = styled.input`
  border-bottom-width: 1px;
  padding: 24px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  text-align: left;
  border-color: rgba(229, 231, 235, 1);
  width: 100%;
`;

export const InfoLabel = styled.p`
  margin-top: 20px;
  text-align: center;
  color: ${({ color }: InfoLabelProps) => color};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 16px;
  @media (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const ConnectButton = styled.button`
  margin-top: 16px;
  font-size: 24px;
  color: #ffffff;
  padding: 8px;
  background-color: rgba(136, 0, 255, 1);
  width: 100%;
  border-radius: 20px;
  @media (min-width: 1024px) {
    margin-bottom: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const LoadingContainer = styled.div`
  height: 24px;
  margin: auto;
  display: flex;
  justify-content: center;
`;
