import { useMediaQuery, useTheme } from "@material-ui/core";
import Axios from "helpers/Interceptor";
import { Container, OutlinedButton, StyledLabel } from "styles/shared";
import { CurrentBrand, OrganizationBillingData, OrganizationData } from "types/global";
import { goToUrl } from "utils";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useState } from "react";
import { MONTHLY_TEAM_PLAN_PRICE, MAX_ADDITIONAL_USERS_WITH_TEAM_PRO, MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER, ADDITIONAL_SPEND_BUDGET_TEAM } from '../../../../constants';
import Loaderv2 from "components/Loader/v2";
import dayjs from "dayjs";
import { CurrentBrandContext } from "../../../../Hooks/CurrentBrandContext"
import { DEFAULT_CURRENCY } from "../../MarketingPage/constants"
import getSymbolFromCurrency from "currency-symbol-map"
import { getConvertedCurrency } from "../../../../services/symphonyApi"
import { getNumberWithDecimals } from "../../../../helpers/General"

interface Props {
    organization: OrganizationData;
    organizationBrands: CurrentBrand[];
    isAdmin: boolean;
}

export function OrganizationBilling(props: Props) {
    const {
        organization,
        organizationBrands,
        isAdmin
    } = props

    const theme = useTheme();
    const setMdWidth = useMediaQuery(theme.breakpoints.down(645));
    const setLgWidth = useMediaQuery(theme.breakpoints.between(645, 920));
    const setUpperPartAsColumn = useMediaQuery(theme.breakpoints.down(524));

    // loading status
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentOrganizationBillingData, setCurrentOrganizationBillingData] = useState<OrganizationBillingData | null>(null);
    const proArtists = organizationBrands.filter(brand => brand.tier === 'pro')
    const { currentBrand } = useContext(CurrentBrandContext)

    const [monthlyTotal, setMonthlyTotal] = useState(MONTHLY_TEAM_PLAN_PRICE)
    const [monthlyAdditionalProAccounts, setMonthlyAdditionalProAccounts] = useState(0)
    const [monthlyAdSpendLimit, setMonthlyAdSpendLimit] = useState(currentOrganizationBillingData?.spentLimit || 0)
    const [montlyTotalSpend, setMontlyTotalSpend] = useState(currentOrganizationBillingData?.totalUsage || 0)
    const [montlyAutomationFee, setMontlyAutomationFee] = useState(currentOrganizationBillingData?.feeToDate || 0)

    //TODO: get currency code from brand team plan configuration and set it instead once it is available
    const currencyCode = DEFAULT_CURRENCY
    const currencySymbol = getSymbolFromCurrency(currencyCode)

    useEffect(() => {
        const fetchOrganizationBillingData = async () => {
            try {
                setIsLoading(true);
                const response = await Axios.get(
                    `/organization/billing`,
                );
                setCurrentOrganizationBillingData(response.data.data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                Sentry.captureException(error);
                console.error("fetchOrganizationBillingData: ", error);
            }
        };
        fetchOrganizationBillingData();
    }, [currentBrand]);

    useEffect(() => {
        if (currentOrganizationBillingData) {
            if (currencyCode !== DEFAULT_CURRENCY) {
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: currentOrganizationBillingData.totalUsage })
                    .then((res) => setMontlyTotalSpend(Number(getNumberWithDecimals(res, 2))))
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: currentOrganizationBillingData.feeToDate })
                    .then((res) => setMontlyAutomationFee(Number(getNumberWithDecimals(res, 2))))
            } else {
                setMontlyTotalSpend(Number(getNumberWithDecimals(currentOrganizationBillingData.totalUsage, 2)))
                setMontlyAutomationFee(Number(getNumberWithDecimals(currentOrganizationBillingData.feeToDate, 2)))
            }
        }

        if (proArtists.length > MAX_ADDITIONAL_USERS_WITH_TEAM_PRO) {
            const totalAdditionalProAccounts = (proArtists.length - MAX_ADDITIONAL_USERS_WITH_TEAM_PRO) * MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER
            if (currencyCode !== DEFAULT_CURRENCY) {
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: MONTHLY_TEAM_PLAN_PRICE })
                    .then((res) => setMonthlyTotal(Number(getNumberWithDecimals(res, 2))))
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: totalAdditionalProAccounts })
                    .then((res) => setMonthlyAdditionalProAccounts(Number(getNumberWithDecimals(res, 2))))
                getConvertedCurrency({ from: DEFAULT_CURRENCY, to: currencyCode, amount: ADDITIONAL_SPEND_BUDGET_TEAM })
                    .then((res) => setMonthlyAdSpendLimit(Number(getNumberWithDecimals(res, 2))))

            } else {
                setMonthlyTotal(Number(getNumberWithDecimals(MONTHLY_TEAM_PLAN_PRICE, 2)))
                setMonthlyAdditionalProAccounts(Number(getNumberWithDecimals(totalAdditionalProAccounts, 2)))
                setMonthlyAdSpendLimit(Number(getNumberWithDecimals(ADDITIONAL_SPEND_BUDGET_TEAM, 2)))
            }
        }
    }, [currentOrganizationBillingData])

    // helper functions
    function dataRow(array: {
        title: string;
        text: string
        onEdit?: () => void
    }[]) {
        return (
            <Container
                display="flex"
                flexDirection={setUpperPartAsColumn ? "column" : "row"}
                padding="24px 0px"
                borderBottom="1px solid #E5E7EB"
                alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
            >
                {array.map((item, index) => (
                    <Container
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        width={setLgWidth ? "32%" : setMdWidth ? "100%" : "25%"}
                    >
                        <StyledLabel fontSize={16} fontWeight={400}>
                            {item.title}
                        </StyledLabel>
                        <Container display="flex" flexDirection="row">
                            <StyledLabel fontSize={24} fontWeight={500}>
                                {item.text}
                            </StyledLabel>
                        </Container>
                    </Container>
                ))}
            </Container>
        )
    }

    // MAin Data Points
    const basePlanRowData = []

    basePlanRowData.push({
        title: "Plan",
        text: `Team Plan`
    })

    basePlanRowData.push({
        title: "Total Profiles",
        text: organizationBrands.length.toString()
    })

    basePlanRowData.push({
        title: "Active Pro Accounts",
        text: `${proArtists.length} / ${MAX_ADDITIONAL_USERS_WITH_TEAM_PRO}`
    })

    // PAyment Info Data Points
    const paymentRowData = []

    paymentRowData.push({
        title: "Monthly Total",
        text: `${currencySymbol}${monthlyTotal} ${currencyCode}`
    })

    paymentRowData.push({
        title: "Additional Pro Accounts",
        text: `${currencySymbol}${monthlyAdditionalProAccounts} / month`
    })

    if (currentOrganizationBillingData?.nextBillingDate) {
        paymentRowData.push({
            title: "Next Billing Date",
            text: dayjs(currentOrganizationBillingData?.nextBillingDate).format("MMM DD, YYYY")
        })
    } else if (currentOrganizationBillingData?.scheduledCancellationDate) {
        paymentRowData.push({
            title: "Plan Cancels on",
            text: dayjs(currentOrganizationBillingData?.scheduledCancellationDate).format("MMM DD, YYYY")
        })
    }

    const spendRowData = []

    spendRowData.push({
        title: "Monthly Ad Spend Limit",
        text: monthlyAdSpendLimit ? `${currencySymbol}${monthlyAdSpendLimit}` : 'N/A'
    })

    spendRowData.push({
        title: "Total Spent this Month",
        text: `${currencySymbol}${montlyTotalSpend}`
    })

    spendRowData.push({
        title: "Automation Fee this Month",
        text: `${currencySymbol}${montlyAutomationFee}`
    })

    const redirectToSubscriptionStatus = async () => {
        try {
            setIsLoading(true);
            const response = await Axios.post(
                `/organization/billing-settings`,
            );
            goToUrl(response.data.data.url, setIsLoading);
        } catch (error) {
            setIsLoading(false);
            Sentry.captureException(error);
            console.error("redirectToSubscriptionStatus: ", error);
        }
    };

    const redirectToStripeSubscription = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewSubscriptionUrl) {
            window.open(currentOrganizationBillingData.viewSubscriptionUrl, "_blank");
        }
    }
    const redirectToStripeCustomer = async () => {
        if (currentOrganizationBillingData && currentOrganizationBillingData.viewCustomerUrl) {
            window.open(currentOrganizationBillingData.viewCustomerUrl, "_blank");
        }
    }

    return (
        <Container>
            <Container
                padding="0px 0px 24px 0px"
                borderBottom="1px solid #E5E7EB"
            >
                <StyledLabel fontSize={20}>Billing</StyledLabel>
            </Container>
            <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
                <Container display="flex" flexDirection="column"
                    alignItems="flex-start" gap="8px">
                    <StyledLabel fontSize={18}>{organization.name}</StyledLabel>
                    <StyledLabel fontSize={12} color="#E08507">TEAM ACCOUNT</StyledLabel>
                </Container>
            </Container>
            <Container>
                <Container padding="24px 0px"
                    borderBottom="1px solid #E5E7EB"
                    flexDirection="row"
                    display="flex"
                >
                    <Container flexDirection={"row"}>
                        <OutlinedButton onClick={redirectToSubscriptionStatus} disabled={false}>
                            {isLoading ? <Loaderv2 height={16} width={230} /> : 'View Billing Settings'}
                        </OutlinedButton>
                    </Container>
                </Container>

                {isAdmin && (
                    <Container
                        gap={setUpperPartAsColumn ? "8px" : "32px"}
                        display="flex"
                        flexDirection={"row"}
                        padding="24px 0px"
                        borderBottom="1px solid #E5E7EB"
                        alignItems={setUpperPartAsColumn ? "flex-start" : "center"}
                    >
                        <Container flexDirection={"row"}>
                            <OutlinedButton onClick={redirectToStripeSubscription}>Admin: View Stripe Subscription</OutlinedButton>
                        </Container>
                        <Container flexDirection={"row"}>
                            <OutlinedButton onClick={redirectToStripeCustomer}>Admin: View Stripe Customer</OutlinedButton>
                        </Container>
                    </Container>
                )}

                {dataRow(basePlanRowData)}
                {dataRow(paymentRowData)}

                {dataRow(spendRowData)}
            </Container>
        </Container>
    );
};
