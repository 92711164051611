import { Fragment, useContext, useEffect, useState } from "react";
import SpotifyConnect from "../connect/Spotify";
import { LoadingIndicator } from "../Loader";
import Axios from "../../helpers/Interceptor";
import { filterOutLinks, streamingLinkNameMapping, streamingLinkPlaceholderUrls, STREAMLINK_REGEX } from "../../helpers/StreamingMappings";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import SharedMusicViewSelector from "../../pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-view"
import { searchSpotifyReleases } from "../../pages/post-auth/MarketingPage/Components/SuperboostModal/Components/shared-music-selector-utils"
import getBrandContent from "../../pages/post-auth/Website/controllers/getBrandContent"
import { isValidURL } from "utils"
import { getFormattedRecords } from "../../pages/post-auth/MarketingPage/utils"
import millify from "millify";
import { RecordSelectorType, Track } from "types/global";
import InfoSection from "components/InfoSection";
import { Body2 } from "./Typography";
import Select, { components } from 'react-select'
import dayjs from "dayjs";
import { getRecordDetails } from "services/symphonyApi/brandContentService";

type RecordTabViews = 'record-search' | 'spotify-search'

export type AddedLinksType = {
    name: string,
    value: string,
    valid: boolean
}


// Custom Option component for rendering options
const CustomPlaylistSelectorOption = (props: any) => {
    function renderSubtitle() {
        const eventData = props.data as Track

        return (
            <div style={{ fontSize: 'smaller', color: props.isSelected ? 'white' : '#888' }}>
                {eventData.artist_names?.join(", ")} • {eventData.added_at ? `Added on ${dayjs(eventData.added_at).format("MMMM D")}` : `Recently Added`}
            </div>
        )
    }
    return (
        <components.Option {...props}>
            <div style={{ fontSize: 18, fontWeight: 500 }}>{props.data.label}</div>
            {renderSubtitle()}

        </components.Option>
    );
};


export default function RecordTab(props: any) {
    const {
        recordSelected,
        setRecordSelected,
        spotifyConnected,
        brand,
        reloadBrand,
        filter,
        columns = ['artist', 'type', 'links'],
        requireLinks = [],
        title = "Select the release you want to promote.",
        recordSelectedIssues,
        saveNextButtonData,
        fullInfoContainerClassName,
        additionalContent = null,
        setAddedLinks: setAddedLinksProp,
        subtype
    } = props
    const { isMobile } = useScreenQueryDimensions();
    const { saveData } = useContext(ConnectModalsContext)

    const [currentView, setCurrentView] = useState<RecordTabViews>('record-search')

    // spotify searcher
    const [spotifySearchResults, setSpotifySearchResult] = useState<any>([]);
    const [spotifySearchString, setSpotifySearchString] = useState('');

    const [loadingRecordDetails, setLoadingRecordDetails] = useState<any>(null)


    const [addedLinks, setAddedLinks] = useState<AddedLinksType[]>(requireLinks.map((requireLink: string) => {
        return {
            name: requireLink,
            value: '',
            valid: false
        }
    }))
    // set to true when button should have loading indicator
    const [loadingButton, setLoadingButton] = useState<boolean>(false)


    let allRequiredLinksValid = addedLinks ? !addedLinks.some((addedLink: AddedLinksType) => !addedLink.valid) : true



    const selectSpotifyArtist = (item: any) => {
        // setSpotifyArtist(item);
        let spotify_artist = JSON.parse(JSON.stringify(item));
        localStorage.setItem('spotify_artist', JSON.stringify(spotify_artist))

        // connect to brand
        // ~~
        // setConnectModal('');
        Axios.put(`/brand/${brand.slug}/connect`, {
            id: brand.id,
            name: brand.name,
            service: 'spotify_artist',
            value: item
        })
            .then(() => {
                // set the local artist ID to returned id
                // const { id } = result.data.data
                // localStorage.setItem('selected-brand', id)

                // history.push('/dashboard')
                reloadBrand().then(() => setCurrentView('record-search'))
            })
            .catch((err) => {
                console.error('error creating brand', err)
            })
    }

    useEffect(() => {
        if (saveNextButtonData) {
            saveNextButtonData({
                areLinksValid: allRequiredLinksValid,
                isLoadingButton: loadingButton,
            })
        }
    }, [allRequiredLinksValid, loadingButton])

    const handleChangeRecord = async (inputValue: string, slug: string | null) => {
        if (!slug && !inputValue) return

        let record = null
        let type: RecordSelectorType = 'single'

        setLoadingRecordDetails(true)
        if (inputValue && isValidURL(inputValue)) {
            const response = await searchSpotifyReleases(inputValue)
            if (response?.data) {
                record = response.data

                switch (record.content_metadata.type) {
                    case 'record':
                        type = 'single'
                        break
                    case 'project':
                        type = 'project'
                        break
                    case 'playlist':
                        type = 'playlist'

                        break
                }
            }
        } else if (brand && slug) {
            const brandContent = await getBrandContent(brand?.slug!, slug)
            if (brandContent) {
                record = brandContent
                switch (record.content_metadata.type) {
                    case 'record':
                        type = 'single'
                        break
                    case 'project':
                        type = 'project'
                        break
                    case 'playlist':
                        type = 'playlist'

                        break

                }
            }

        }
        // if its a playlist, we dont need to save this in the DB
        // (that's what getRecordDetails does)
        // we just need to return the right format

        const [recordFormatted] = getFormattedRecords({
            recentReleases: [record],
            releaseType: type,
            search: ''
        })


        const recordDetail = await getRecordDetails(brand.id, recordFormatted.spotify_id, type);

        if (type === 'playlist') {
            try {
                const url = new URL(inputValue);
                const prid = url.searchParams.get('prid');

                if (prid && prid.startsWith('spotify:track:')) {
                    const trackId = prid.split(':')[2];
                    recordDetail.prid = trackId;
                }

            } catch (e) {
                console.error('Error parsing URL', e);
            }
        }

        setRecordSelected(recordDetail)
        setLoadingRecordDetails(false)
    }

    // review selected rrecord
    useEffect(() => {
        if (recordSelected) {

            if (requireLinks?.length > 0) {
                const updatedRequiredLinks = requireLinks.map((requireLink: string) => {
                    let existingLink = null
                    if (recordSelected && recordSelected.external_links) {
                        existingLink = recordSelected.external_links.find((externalLink: {
                            url: string;
                            name: string;
                        }) => {
                            return requireLink === externalLink.name
                        })
                    }
                    return {
                        name: requireLink,
                        value: existingLink ? existingLink.url : '',
                        valid: Boolean(existingLink)
                    }
                })
                setAddedLinks(updatedRequiredLinks)
            }

            if (!recordSelected.mainTrack) {
                // if theres a prid in the URL, then its already set - so we can set the autoplay track
                if (recordSelected.prid) {
                    const track = recordSelected.tracks.find((track: Track) => track.id === recordSelected.prid)
                    if (track) {
                        setRecordSelected({
                            ...recordSelected,
                            mainTrack: {
                                ...track,
                                value: track.id,
                                label: track.name
                            }
                        })
                    }
                }
            }
        }
    }, [recordSelected])

    useEffect(() => {
        saveData(prev => ({ ...prev, loading: Boolean(loadingRecordDetails) }))
    }, [loadingRecordDetails, saveData])

    const onChangeLinkInput = (e: any, requireLink: string) => {
        const validationRegex = (STREAMLINK_REGEX as any)[requireLink] ? (STREAMLINK_REGEX as any)[requireLink] : null
        const updatedValue = e.target.value
        let validString = true

        if (validationRegex) validString = validationRegex.some((regex: RegExp) => {
            return regex.test(updatedValue)
        })

        const foundIndex = addedLinks.findIndex((currentLink: AddedLinksType) => currentLink.name === requireLink)
        if (foundIndex > -1) {
            const newArr = [...addedLinks]
            newArr[foundIndex] = {
                name: requireLink,
                value: updatedValue,
                valid: validString
            }
            Object.values(recordSelected.external_links).find((value: any) => value.name === addedLinks[foundIndex].name)
            setAddedLinks(newArr)
            setAddedLinksProp(newArr)
        }
    }

    /**
     * 
     * @returns array of link editor items
     */
    function renderLinksAdder() {
        if (requireLinks) {

            // if requireLinks includes a specific DSP and that specific DSP isnt avail in the record links,
            // show the spotify -and/or- apple music 
            return requireLinks.map((requireLink: string) => {
                const linkExists = recordSelected.external_links.find((item: {
                    name: string,
                    value: string
                }) => {
                    return item.name === requireLink
                })

                if (linkExists) {
                    return null
                }
                else {

                    const linkName: string = (streamingLinkNameMapping as any)[requireLink]
                    const currentLocalValue = addedLinks.find((currentLink: AddedLinksType) => {
                        return currentLink.name === requireLink
                    })

                    return (
                        <div className="px-6 lg:px-10 py-5 border-b flex flex-col justify-center items-start">
                            <div className="w-12/12">
                                <p className="text-lg font-bold">{linkName} Link</p>
                                <p>We weren't able to automatically find the {linkName} link for "{recordSelected.name}", please add the link here to market the song.</p>
                            </div>
                            <input
                                value={currentLocalValue ? currentLocalValue.value : ''}
                                onChange={(e: any) => onChangeLinkInput(e, requireLink)}
                                placeholder={((streamingLinkPlaceholderUrls as any)[requireLink]) as string}
                                className={`rounded-md border w-full resize-none p-2 mt-2 ${!currentLocalValue?.valid && currentLocalValue?.value ? 'border-red-500' : ''}`}
                            />
                        </div>
                    )
                }
            })


        } else {
            return null
        }
    }

    function renderReleaseDetails() {
        if (recordSelected?.full_info) {

            if (recordSelected.type === 'playlist') {


                return (
                    <div className={fullInfoContainerClassName || "middle-wrapper"}>
                        <div className="sy-card px-6 lg:px-10">
                            <div className="flex items-center">
                                <div className="artist-img">
                                    <img src={recordSelected.thumbnail_url} />
                                </div>
                                <div className="px-3">
                                    <h5 className="text-dark font-bold text-lg flex flex-row justify-center items-center">{recordSelected.name} {recordSelected.explicit ? (<div className="rounded-sm box-border	ml-2 bg-gray-300 p-2 h-4 w-4 flex items-center justify-center">
                                        <span className="text-xs">E</span>
                                    </div>) : ""}</h5>
                                </div>
                            </div>
                        </div>

                        {columns.indexOf('artist') > -1 ? <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Creator</p>
                            <p className="text-lg">{recordSelected.primary_artist}</p>
                        </div> : null}

                        <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Type</p>
                            <p className="text-lg">{recordSelected.spotify_release_type.charAt(0).toUpperCase() + recordSelected.spotify_release_type.slice(1)}</p>
                        </div>



                        {columns.indexOf('links') > -1 ? (
                            <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between items-center">
                                <p className="text-lg font-bold">Found on</p>
                                <div className="flex flex-row w-2/4 lg:w-auto items-center justify-end flex-wrap">
                                    {recordSelected.external_links.filter((link: any) => {
                                        return filterOutLinks(link.name)
                                    }).map((link: any) => {
                                        let asset = null

                                        switch (link.name) {

                                            case 'spotify':
                                                asset = require('../../assets/images/streaming/small/spotify-bw.svg').default
                                                break
                                            case 'apple_music':
                                                asset = require('../../assets/images/streaming/small/apple-bw.svg').default
                                                break
                                            case 'deezer':
                                                asset = require('../../assets/images/streaming/small/deezer-bw.svg').default
                                                break
                                            case 'youtube':
                                                asset = require('../../assets/images/streaming/small/youtube-bw.svg').default
                                                break
                                            case 'pandora':
                                                asset = require('../../assets/images/streaming/small/spotify-bw.svg').default
                                                break

                                            case 'amazon_music':
                                                asset = require('../../assets/images/streaming/small/amazon-bw.svg').default
                                                break
                                            case 'tidal':
                                                asset = require('../../assets/images/streaming/small/tidal-bw.svg').default
                                                break
                                            case 'soundcloud':
                                                asset = require('../../assets/images/streaming/small/soundcloud-bw.svg').default
                                                break
                                            case 'audiomack':
                                                asset = require('../../assets/images/streaming/small/audiomack-bw.svg').default
                                                break

                                        }
                                        if (asset) {
                                            return <img key={link.name} className="w-10 lg:w-12 px-2 py-2" alt={link.name} src={asset} />
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                            </div>
                        ) : null}

                        {/* 
                        if this is an editorial playlist with a URL for a main track,
                        we should show that. otherwise, let a user paste it in. */}
                        <div>
                            <InfoSection title="Autoplay a Track" description={"You can select a track from your playlist to autoplay when someone clicks on your ad."}>
                                <div className="py-2">
                                    <Select
                                        options={recordSelected.tracks.map((track: Track) => {
                                            return {
                                                ...track,
                                                value: track.id,
                                                label: track.name
                                            }
                                        })}
                                        value={recordSelected?.mainTrack}
                                        onChange={(track: any) => {
                                            // add the track data to recordSelected
                                            const newRecordSelected = {
                                                ...recordSelected,
                                                mainTrack: track
                                            }

                                            setRecordSelected(newRecordSelected)
                                        }}
                                        components={{ Option: CustomPlaylistSelectorOption }}
                                        // styles={selectStyles}
                                        isClearable
                                        placeholder={<Body2>Select a song</Body2>}
                                        noOptionsMessage={() => <Body2>No events found</Body2>}
                                    />
                                </div>
                            </InfoSection>
                        </div>


                        {(columns.indexOf('issues') > -1 && recordSelectedIssues) ? <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <div className="bg-yellow-600 px-9 py-3">
                                <p className="text-white text-center">
                                    <span>{recordSelectedIssues}</span>
                                </p>
                            </div>
                        </div> : null}
                    </div>
                )
            } else {
                return (
                    <div className={fullInfoContainerClassName || "middle-wrapper"}>
                        <div className="sy-card px-6 lg:px-10">
                            <div className="flex items-center">
                                <div className="artist-img">
                                    <img src={recordSelected.thumbnail_url} />
                                </div>
                                <div className="px-3">
                                    <h5 className="text-dark font-bold text-lg flex flex-row justify-center items-center">{recordSelected.name} {recordSelected.explicit ? (<div className="rounded-sm box-border	ml-2 bg-gray-300 p-2 h-4 w-4 flex items-center justify-center">
                                        <span className="text-xs">E</span>
                                    </div>) : ""}</h5>
                                </div>
                            </div>
                        </div>
                        {/* <div className="px-6 lg:px-10 py-5 border-b">
                        <p className="text-lg font-bold"></p>
                    </div> */}
                        {columns.indexOf('artist') > -1 ? <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <p className="text-lg font-bold">Artist</p>
                            <p className="text-lg">{recordSelected.primary_artist}</p>
                        </div> : null}
                        {columns.indexOf('type') > -1 ?
                            <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                                <p className="text-lg font-bold">Type</p>
                                <p className="text-lg">{recordSelected.spotify_release_type.charAt(0).toUpperCase() + recordSelected.spotify_release_type.slice(1)}</p>
                            </div> : null}
                        {columns.indexOf('genre') > -1 ?
                            spotifyConnected.genres && spotifyConnected.genres.length > 0 ? (
                                <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                                    <p className="text-lg font-bold">Genre</p>
                                    <p className="text-lg">{spotifyConnected.genres[0].replace(/\w\S*/g, function (txt: string) {
                                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                    })}</p>
                                </div>) : null : null}
                        {columns.indexOf('links') > -1 ? (
                            <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between items-center">
                                <p className="text-lg font-bold">Found on</p>
                                <div className="flex flex-row w-2/4 lg:w-auto items-center justify-end flex-wrap">
                                    {recordSelected.external_links.filter((link: any) => {
                                        return filterOutLinks(link.name)
                                    }).map((link: any) => {
                                        let asset = null

                                        switch (link.name) {

                                            case 'spotify':
                                                asset = require('../../assets/images/streaming/small/spotify-bw.svg').default
                                                break
                                            case 'apple_music':
                                                asset = require('../../assets/images/streaming/small/apple-bw.svg').default
                                                break
                                            case 'deezer':
                                                asset = require('../../assets/images/streaming/small/deezer-bw.svg').default
                                                break
                                            case 'youtube':
                                                asset = require('../../assets/images/streaming/small/youtube-bw.svg').default
                                                break
                                            case 'pandora':
                                                asset = require('../../assets/images/streaming/small/spotify-bw.svg').default
                                                break

                                            case 'amazon_music':
                                                asset = require('../../assets/images/streaming/small/amazon-bw.svg').default
                                                break
                                            case 'tidal':
                                                asset = require('../../assets/images/streaming/small/tidal-bw.svg').default
                                                break
                                            case 'soundcloud':
                                                asset = require('../../assets/images/streaming/small/soundcloud-bw.svg').default
                                                break
                                            case 'audiomack':
                                                asset = require('../../assets/images/streaming/small/audiomack-bw.svg').default
                                                break

                                        }
                                        if (asset) {
                                            return <img key={link.name} className="w-10 lg:w-12 px-2 py-2" alt={link.name} src={asset} />
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>
                            </div>
                        ) : null}

                        {columns.indexOf('links-adder') > -1 ? renderLinksAdder() : null}

                        {(columns.indexOf('issues') > -1 && recordSelectedIssues) ? <div className="px-6 lg:px-10 py-5 border-b flex flex-row justify-between">
                            <div className="bg-yellow-600 px-9 py-3">
                                <p className="text-white text-center">
                                    <span>{recordSelectedIssues}</span>
                                </p>
                            </div>
                        </div> : null}
                    </div>
                )
            }

        }
    }

    let allowedOptions: ("single" | "project" | "playlist")[] = ['single', 'project', 'playlist']

    if (subtype === "grow_playlist_followers") {
        allowedOptions = ['playlist']
    } else if (subtype === "get_playlisted") {
        allowedOptions = ['single']
    }
    return (
        <Fragment>
            {!recordSelected && !loadingRecordDetails && (
                currentView === 'record-search' ? (
                    <div className="middle-wrapper lg:px-0 relative">
                        <p className="font-bold text-base px-5 lg:px-10 py-5">{title ? title : "Select the release you want to promote."}
                        </p>
                        <SharedMusicViewSelector
                            options={allowedOptions}
                            isSpotifyConnected={Boolean(brand?.connections?.spotify_artist_page)}
                            currentBrand={brand!}
                            letUserSearchSpotify
                            letUserRefreshReleases
                            handleChangeRecord={handleChangeRecord}
                        />
                        {!spotifyConnected ? (
                            <>
                                <div className="border-2 border-gray-200 rounded-md p-5 m-auto mt-2 w-full">
                                    <div className="flex flex-row justify-between items-center">
                                        <div className="flex flex-row">
                                            <img
                                                width={50}
                                                src={
                                                    require("../../assets/images/spotify.svg").default
                                                }
                                                alt=""
                                            />
                                            <div className="text-center self-center">
                                                <p className="text-left text-sm ml-4 mr-12">
                                                    Connect <b>Spotify</b> to automatically pull your latest releases.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="w-auto">
                                            <a
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setCurrentView('spotify-search')
                                                }}
                                                className="text-primary text-sm"
                                            >
                                                Connect
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : (
                    <SpotifyConnect
                        closeModal={() => setCurrentView('record-search')}
                        selectSpotifyArtist={selectSpotifyArtist}
                        setSpotifySearchResult={setSpotifySearchResult}
                        spotifySearchResults={spotifySearchResults}
                        spotifySearchString={spotifySearchString}
                        setSpotifySearchString={setSpotifySearchString}
                    />
                )
            )
            }
            {renderReleaseDetails()}
            {
                loadingRecordDetails && (
                    <div className="flex-col flex items-center m-8 py-4">
                        <LoadingIndicator height="80px" color="black" />
                        <p className="mt-1">Loading details {recordSelected && `for "${recordSelected.name}"...`}</p>
                    </div>
                )
            }
            {additionalContent}
        </Fragment >
    )
}