import { FacebookAdAccountAccess } from 'services/facebookApi'
import { useEffect, useState } from 'react'
import { FacebookBusinessManager, FBPixel } from 'types/global'

export type FacebookAccounts = (FacebookAdAccountAccess | FacebookBusinessManager | FBPixel)[]

export function useSearchFilter(initialAccounts: FacebookAccounts) {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [listToFilter, setListToFilter] = useState<FacebookAccounts>(initialAccounts)
  const [filteredAccounts, setFilteredAccounts] = useState<FacebookAccounts>(listToFilter)

  useEffect(() => {
    setListToFilter(initialAccounts)
  }, [initialAccounts])

  const handleOnChangeSearch = (value: string) => {
    const newSearch: string = value
    setSearchQuery(newSearch)

    if (searchQuery === '') {
      setFilteredAccounts(listToFilter)
      return
    }

    const newFilteredList: FacebookAccounts = listToFilter.filter((account) =>
      account.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    setFilteredAccounts(newFilteredList)
  }

  return { searchQuery, filteredAccounts, handleOnChangeSearch, setListToFilter }
}
