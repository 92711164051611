import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  artistName: {
    color: "#80f"
  },
  chipLabelColor: {
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  closeIcon: {
    width: 9,
    height: 9,
    marginRight: theme.spacing(1.5),
  },
  container: {
    marginTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(2.5),
  },
  inputPlaceHolder: {
    "&::placeholder": {
      [theme.breakpoints.down(445)]: {
        fontSize: 14
      },
      [theme.breakpoints.down(401)]: {
        fontSize: 12
      }
    }
  },
}))

export default useStyles
