import { ArtistPermissions, CampaignDetails, CampaignMetadata, EmailGrowth, SpotifyGrowth, StyleTypes } from 'types/global';
import { get } from 'lodash';
import moment from 'moment';
import SpotifyServiceIcon from 'assets/images/streaming/svg-logos/spotify-service.svg';
import AppleServiceIcon from 'assets/images/streaming/svg-logos/apple-service.svg';
import DeezerServiceIcon from 'assets/images/streaming/svg-logos/deezer-service.svg';
import AudiomackServiceIcon from 'assets/images/streaming/svg-logos/audiomack-service.svg';
import SoundcloudServiceIcon from 'assets/images/streaming/svg-logos/soundcloud-service.svg';
import useCountryList from 'react-select-country-list';
import { Fragment, useContext, useEffect, useState } from 'react';
import Demographics, {
  PresaveStreamingServiceDemographicsItem,
} from 'pages/post-auth/MarketingPage/PreSave/Components/Demographics';
import Audience from 'pages/post-auth/MarketingPage/PreSave/Components/Audience';
import Geographies from 'pages/post-auth/MarketingPage/PreSave/Components/Geographies/index';
import {
  getDemographics,
  getSpotifyGrowth,
  getEmailsGrowth,
  getGeographies,
  getAudience,
  getPresaveDailyStats,
} from 'pages/post-auth/MarketingCampaignDetails/utils';
import { track } from 'analytics';
import { CampaignStatus } from 'types/global';
import momentTz from 'moment-timezone';
import UnlockDataBanner from 'components/shareable/UnlockDataBanner';
import UnlockPresavesByDayImage from 'assets/images/unlock-presaves-by-day.svg';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs from 'dayjs';
import { checkIfIsProTier, isBrandAdministrator } from 'utils';
import { CampaignDetailsContainer } from '../../SongAds/styles';
import EditReleaseDateModal from '../../Components/Modals/EditReleaseDateModal';
import ErrorUpdatingCampaignModal from '../../Components/ErrorUpdatingCampaignModal';
import PresaveOverviewCampaignCard from '../../Components/OverviewCampaignCard/presave';
import GeneralContactSupportModal from '../../Components/GeneralContactSupportModal';
import SymphonyTabs, { TabContent } from 'components/shareable/SymphonyTabs';
import DetailCard from './DetailCard';
import {
  DetailContainer,
  GridContainer,
  TimeRemaimingContainer,
  OptionIcon,
  OptionContainer,
  WhiteContainer,
  useStyles,
} from './styles';
import PresaveSetup from '../Components/Setup';
import { NewLoader } from 'components/Loader/ConnectBrandLoader';
import millify from 'millify';
import { GraphTooltip } from 'pages/post-auth/DashboardModule/components/Graphs';
import { ReactComponent as PreSaves } from 'assets/images/PreSaves.svg';
import { ReactComponent as Mail } from 'assets/images/mail.svg';
import { ReactComponent as Star } from 'assets/images/outlinedStar.svg';
import { TabsContainer } from '../../styles';
import { PageTabs, TABS } from './constants';
import EditPlatformsModal from '../Components/EditPlatformsModal';
import EditBoostsModal from '../Components/EditBostsModal';
import EditUPCISRCModal from '../Components/EditUPCISRCModal';
import EditPresaveModal from '../Components/EditPresaveModal';
import { PresaveTotals } from '../Components/EditBostsModal/utils';
import useModal from 'Hooks/useModal';
import { getMaxWidth } from '../../utils/fbCampaigns';
import PresavePreview from '../Components/PresavePreview';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { Body2, Body3, Caption, Headline1 } from 'components/shareable/Typography';
import { ReactComponent as CalendarIcon } from 'assets/images/calendar.svg';
import PresaveLink from '../Components/PresaveLink';
import PreviewModal from '../Components/PresavePreview/PreviewModal';
import MusicStreamsIcon from 'assets/images/campaigns/musicstreams.png';
import PlaylistingIcon from 'assets/images/campaigns/playlisting.png';
import { useHistory } from 'react-router-dom';
import { SystemColors } from 'types/globalStyles';
import WarningNoReleaseUpcCard from '../Components/WarningNoReleaseUpc/Card';
import { extractSlugsFromFrontfacingLink } from '../../utils/links';
import { EditWebsiteSlugModal } from '../../Components/Modals/EditWebsiteSlugModal';
import { Container } from 'styles/shared';
import ClickableChip from '../../Components/CreateCampaignBadge';
import { getFormattedPlatform } from '../utils';
import { siteIconsData } from 'pages/post-auth/DashboardModule/Dashboard';
import AudiomackConnectv2 from 'components/connect/Audiomack';
import SoundcloudConnectv2 from 'components/connect/Soundcloud';
import DeezerConnectv2 from 'components/connect/Deezer';
import { toast } from 'react-toastify';
import { SHARED_TOAST_OPTIONS } from '../../Components/Modals/utils';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import PrimaryButton from 'components/shareable/PrimaryButton';
import { FacebookCampaignSimpleBanner } from '../../Components/FacebookCampaignIssuesBanner/simple-banner';
import DetailCard2 from './DetailCard2';
import TertiaryButton from 'components/shareable/TertiaryButton';
import AllCountriesModal from '../Components/AllCountriesModal';
import { ManuallyReleaseModal } from '../Components/ManuallyReleaseModal';
import { forceRelease } from 'services/symphonyApi/campaignService';
import { fetchMarkets } from 'services/symphonyApi/brandContentService';
import { CurrentBrandContext } from 'Hooks/CurrentBrandContext';

let relativeTime = require('dayjs/plugin/relativeTime');
let customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
declare module 'dayjs' {
  interface Dayjs {
    toNow(): any;
  }
}

interface Props {
  details: CampaignDetails;
  updateCampaignMetadata?: (data: CampaignMetadata) => void;
  handleGoBack: () => void;
  refreshCampaignDetails: () => void;
}

export enum Modals {
  EDIT_END_DATE = 'EDIT_END_DATE',
  EDIT_PLATFORMS = 'EDIT_PLATFORMS',
  EDIT_UPC_ISRC = 'EDIT_UPC_ISRC',
  EDIT_URL = 'EDIT_URL',
  EDIT_BOOSTS = 'EDIT_BOOSTS',
  EDIT_STYLE = 'EDIT_STYLE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  ERROR_UPDATING_CAMPAIGN = 'ERROR_UPDATING_CAMPAIGN',
  VIEW_ALL_COUNTRIES = 'VIEW_ALL_COUNTRIES',
  FIND_RELEASE = 'FIND_RELEASE',
}

const { FINISHED, IN_PROGRESS, NOT_FOUND } = CampaignStatus;

const labels = {
  ACTIVE: {
    label: 'live',
    color: '#10B981',
    text: 'LIVE',
  },
  FINISHED: {
    label: 'completed',
    color: SystemColors.ACCENT_COLOR,
    text: 'COMPLETED',
  },
  WITH_ISSUES: {
    label: 'with_issues',
    color: SystemColors.WARNING_COLOR,
    text: 'Needs UPC/ISRC',
  },
  NOT_FOUND: {
    label: 'not_found',
    color: SystemColors.WARNING_COLOR,
    text: 'NOT FOUND',
  },
  IN_PROGRESS: {
    label: 'in_progress',
    color: SystemColors.WARNING_COLOR,
    text: 'IN PROGRESS',
  },
};

const icons: Record<string, any> = {
  spotify: SpotifyServiceIcon,
  apple_music: AppleServiceIcon,
  deezer: DeezerServiceIcon,
  audiomack: AudiomackServiceIcon,
  soundcloud: SoundcloudServiceIcon,
};

const geographiesTypes = {
  city: 'city',
  country: 'country',
};

export const formatDate = (date: string, timezone: string) => {
  const abbrev = momentTz.tz(timezone).zoneAbbr();
  if (timezone) return momentTz(date).tz(timezone).format(`dddd MMM D, YYYY ha [${abbrev}]`);
  return moment(date).format(`dddd MMM D, YYYY ha [${abbrev}]`);
};

const PreSaveDetails = ({ details, handleGoBack, updateCampaignMetadata, refreshCampaignDetails }: Props) => {
  const classes = useStyles();
  const { currentBrand: brand } = useContext(CurrentBrandContext);
  const isProUser = checkIfIsProTier(brand);
  const hasAdminAccess = isBrandAdministrator(brand!);
  const screenBreakpoints = useMediaBreakpoints();
  const { mobileView } = screenBreakpoints;
  const [selectedGeographiesTab, setSelectedGeographiesTab] = useState('country');
  const [selectedAudienceTab, setSelectedAudienceTab] = useState<'current' | 'previous'>('current')
  const [audienceOffset, setAudienceOffset] = useState<number>(0);
  const [audienceOrderBy, setAudienceOrderBy] = useState<string>('createdAt');
  const [audienceOrderDirection, setAudienceOrderDirection] = useState<string>('desc');
  const AUDIENCE_LIMIT = 25;
  const {
    campaign_metadata: { content, link, presave, startDate, endDate },
    emailsData,
    presavesData,
    foreverSavesData,
    newFollowersData,
  } = details || {};

  let status = details?.status || '';
  const timezone = presave?.options?.timezone || '';
  const { thumbnail_url, name, slug } = content;
  const { platforms, boosts, release_url_upc_isrc, created_with_upc, local_release } = presave || {};
  let campaignStatus: any = get(labels, status, {});

  const linkSlugs = extractSlugsFromFrontfacingLink(link, brand?.slug!);

  const [demographics, setDemographics] = useState<PresaveStreamingServiceDemographicsItem[]>([]);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [audience, setAudience] = useState<any[]>([]);
  const [audienceCount, setAudienceCount] = useState<number>(0);
  const [countryGeographies, setCountryGeographies] = useState([]);
  const [selectedView, setSelectedView] = useState<PageTabs>(PageTabs.PERFORMANCE);
  const [cityGeographies, setCityGeographics] = useState([]);
  const [dailyStats, setDailyStats] = useState([]);
  const [spotifyGrowth, setSpotifyGrowth] = useState<SpotifyGrowth | null>(null);
  const [emailsGrowth, setEmailsGrowth] = useState<EmailGrowth | null>(null);
  const [markets, setMarkets] = useState([]);
  const isAfterFansRelease = moment(startDate).isAfter('2023-03-14T20:30:34Z');

  // this is used in the WITH_ISSUEs state - a user has the option to refresh releases
  const [refreshingReleases, setRefreshingReleases] = useState<boolean>(false);
  const [errorRefreshingReleases, setErrorRefreshingReleases] = useState<boolean>(false);
  // edit options - fpr date
  const [releaseDate, setReleaseDate] = useState<{
    date: string;
    timezone: string;
  }>({
    date: endDate,
    timezone: timezone,
  });

  // sets locally when a user updates the url successfully, so we dont need to update ALL of the values
  const [newLocalSlug, setNewLocalSlug] = useState<string>(linkSlugs ? linkSlugs.urlSlug : '');
  const [localUrl, setLocalUrl] = useState(linkSlugs ? linkSlugs.brandSlug + '-' + linkSlugs.urlSlug : '');

  const countryList = useCountryList();
  const daysLeft = moment(releaseDate.date).diff(moment(), 'days');
  const statusChecks = {
    isFinalized: status === FINISHED,
    isInProgress: status === IN_PROGRESS,
    isNotFound: status === NOT_FOUND,
  };
  const { isFinalized, isInProgress, isNotFound } = statusChecks;
  const hasEndDatePassed = moment(releaseDate.date).isBefore();
  const createdWithUPC = created_with_upc ?? true;

  const trackObject = {
    campaign_id: details.id,
    campaign_type: 'pre_save',
    brand_id: brand?.id,
    brand_slug: brand?.slug,
    days_left: daysLeft,
    created_with_upc: createdWithUPC,
  };

  const editEndDateModal = useModal({
    nameTrack: 'Open Release Date',
    metadata: {
      ...trackObject,
      opened: 'Edit Release Date Modal',
    },
  });
  const editPlatformsModal = useModal({
    nameTrack: 'Open Edit Platforms',
    metadata: {
      ...trackObject,
      opened: 'Edit Platforms Modal',
    },
  });
  const editStylesModal = useModal({
    nameTrack: 'Open Edit Design',
    metadata: {
      ...trackObject,
      opened: 'Edit Design Modal',
    },
  });
  const editUPCISRCModal = useModal({
    nameTrack: 'Open Edit UPC/ISRC',
    metadata: {
      ...trackObject,
      created_with_upc: createdWithUPC,
      opened: 'Edit UPC/ISRC Modal',
    },
  });
  const editBoostsModal = useModal({
    nameTrack: 'Open Edit Boosts',
    metadata: {
      ...trackObject,
      opened: 'Edit boosts Modal',
    },
  });
  const editUrlModal = useModal({
    nameTrack: 'Open Edit URL',
    metadata: {
      ...trackObject,
      opened: 'Edit URL Modal',
    },
  });
  const viewAllCountriesModal = useModal({
    nameTrack: 'View All Countries',
    metadata: {
      ...trackObject,
      opened: 'View All Countries Modal',
    },
  });
  const showContactSupportModal = useModal();
  const errorUpdatingCampaignModal = useModal();
  const previewModal = useModal();
  const manuallyFindReleaseModal = useModal();

  const handleOpenModals = (modal: Modals, source?: string) => {
    switch (modal) {
      case Modals.EDIT_END_DATE:
        editEndDateModal.openModal(source);
        break;
      case Modals.EDIT_PLATFORMS:
        editPlatformsModal.openModal(source);
        break;
      case Modals.EDIT_STYLE:
        editStylesModal.openModal(source);
        break;
      case Modals.EDIT_UPC_ISRC:
        editUPCISRCModal.openModal(source);
        break;
      case Modals.EDIT_BOOSTS:
        editBoostsModal.openModal(source);
        break;
      case Modals.CONTACT_SUPPORT:
        showContactSupportModal.openModal(source);
        break;
      case Modals.EDIT_URL:
        editUrlModal.openModal(source);
        break;
      case Modals.ERROR_UPDATING_CAMPAIGN:
        errorUpdatingCampaignModal.openModal(source);
        break;
      case Modals.VIEW_ALL_COUNTRIES:
        viewAllCountriesModal.openModal(source);
        break;
      case Modals.FIND_RELEASE:
        manuallyFindReleaseModal.openModal(source);
        break;
      default:
        break;
    }
  };

  const handleChangeReleaseDate = (releaseDate: string, timeZone: string) => {
    setReleaseDate({
      date: releaseDate,
      timezone: timeZone,
    });
  };

  useEffect(() => {
    track('Campaign Details Viewed', {
      type: 'pre_save',
      brand_id: brand?.id,
      brand_name: brand?.name,
      brand_slug: brand?.slug,
      content_name: content.name,
      ...(details.campaign_metadata ? details.campaign_metadata : {}),
    });
    setLoading(false);
  }, [details]);

  useEffect(() => {
    if (startDate && endDate) {
      (async () => {
        try {
          const dailyGrowth = await getPresaveDailyStats(brand?.slug || '', slug);
          setDailyStats(dailyGrowth);

          if (isProUser) {
            const spotifyGrowth = await getSpotifyGrowth(startDate, endDate, brand?.id!);
            setSpotifyGrowth(spotifyGrowth);
            const emailsGrowth = await getEmailsGrowth(startDate, endDate, brand?.slug || '', slug);
            setEmailsGrowth(emailsGrowth);
          }

          const demographics: any[] = await getDemographics(brand?.slug || '', slug);
          setDemographics(
            demographics.map((demographic) => {
              const { name, platform, tier, count, percentage } = demographic;
              return {
                name,
                platform,
                tier,
                count,
                percentage,
                icon: icons[platform as string],
              };
            })
          );
          if (isProUser) {
            const geographies = await getGeographies(brand?.slug || '', slug, geographiesTypes.country);
            setCountryGeographies(geographies);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [startDate, endDate, brand?.id, brand?.slug, slug]);

  useEffect(() => {
    if (startDate && endDate && selectedView === PageTabs.AUDIENCE) {
      (async () => {
        try {
          const { audience, count } = await getAudience(brand?.slug || '', slug, selectedAudienceTab, AUDIENCE_LIMIT, audienceOffset, audienceOrderBy, audienceOrderDirection);
          setAudience(
            audience.map((audience: any[]) => ({
              ...audience,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            }))
          );
          setAudienceCount(count);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [brand?.name, endDate, selectedView, slug, startDate, selectedAudienceTab, audienceOffset, audienceOrderBy, audienceOrderDirection]);

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      !cityGeographies.length &&
      selectedGeographiesTab === geographiesTypes.city &&
      isProUser
    ) {
      (async () => {
        try {
          const geographies = await getGeographies(brand?.slug || '', slug, geographiesTypes.city);
          setCityGeographics(geographies);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [brand?.name, cityGeographies.length, endDate, selectedGeographiesTab, slug, startDate]);

  useEffect(() => {
    if (local_release) initMarkets();
  }, [local_release]);

  const initMarkets = async () => {
    try {
      const markets = await fetchMarkets(slug);
      setMarkets(markets);
    } catch (error) {
      console.log(error);
    }
  };

  const onGeographiesClickTab = (evt: any) => setSelectedGeographiesTab(evt.target.id);

  const totalValues: PresaveTotals = {
    auto_presaves: details?.presavesData?.total || 0,
    boost_follows: details?.newFollowersData?.total || 0,
    collect_emails: details?.emailsData?.total || 0,
  };

  const getTotalTimeRemaining = () => {
    let date = moment(releaseDate.date);
    if (releaseDate.timezone) date = momentTz(releaseDate.date).tz(timezone);
    const timeRemaining = date.fromNow(true).split(' ');
    if (timeRemaining[0] === 'a' || timeRemaining[0] === 'an') timeRemaining[0] = '1';
    return { amount: timeRemaining[0], unit: timeRemaining[1] };
  };

  const handleChangeCampaignMetadata = (campaign: CampaignDetails) => {
    updateCampaignMetadata?.(campaign.campaign_metadata);
  };

  const previewBgColor = presave?.style?.bgType === 'color' ? presave?.style?.bgColor : '';

  const contentDetails = {
    artworkUrl: content.thumbnail_url,
    name: content.name,
    options:
      presave?.platforms.map((platform: string) => {
        return { name: platform, show: true };
      }) || [],
    subtitle: presave?.subtitle || '',
  };

  const history = useHistory();

  const createMusicStreamsCampaign = () => {
    history.push(`/marketing/new/music-streams`);
  };

  const createPlaylistingCampaign = () => {
    history.push(`/marketing/new/playlists`);
  };

  // If a user has enabled auto-follow, this will be used to denote
  // that the platform is properly connect to allow for autofollows.
  const [showConnectBox, setShowConnectBox] = useState<string | null>(null);
  const [autofollowConnectedPlatforms, setAutofollowConnectPlatforms] = useState<{
    spotify: boolean;
    deezer: boolean;
    audiomack: boolean;
    soundcloud: boolean;
    [key: string]: boolean; // Add index signature
  }>({
    spotify: true,
    deezer: brand?.connections?.deezer ? true : false,
    audiomack: brand && brand.connections.audiomack ? true : false,
    soundcloud: brand?.connections?.soundcloud_artist_page?.id ? true : false,
  });

  function showConnectModalforStreamingService(platformId: string) {
    setShowConnectBox(platformId);
  }

  // this will update the draggable options, and set the connected value
  // to true for the platform that was just connected.
  function enableAutosaveOnPlatform(platformId: string) {
    setAutofollowConnectPlatforms({
      ...autofollowConnectedPlatforms,
      [platformId]: true,
    });

    // show a toast
    toast.success(`Enabled Boost Followers on ${getFormattedPlatform(platformId)}!`, SHARED_TOAST_OPTIONS);
  }
  function showLinkStreamingServicesButtons() {
    const mappedChips = Object.keys(autofollowConnectedPlatforms)
      .filter((platformId: string) => !autofollowConnectedPlatforms[platformId])
      .map((platformId: string) => {
        const enabled = autofollowConnectedPlatforms[platformId];

        const platformImage = siteIconsData[platformId];
        return (
          <ClickableChip
            campaignType="Test"
            campaignName={`Link ${getFormattedPlatform(platformId)} to enable auto-followers`}
            campaignImage={platformImage}
            onClick={() => showConnectModalforStreamingService(platformId)}
          />
        );
      });
    return (
      <Container
        display="flex"
        flexDirection="row"
        gap="10px"
        alignItems="flex-start"
        marginTop="12px"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {mappedChips}
      </Container>
    );
  }

  async function refreshReleasesOnError() {
    setRefreshingReleases(true);
    setErrorRefreshingReleases(false);
    try {
      const data = await forceRelease(content.slug);
      if (data) {
        await refreshCampaignDetails();
        toast.success(`Your pre-save campaign was successfully updated!`, SHARED_TOAST_OPTIONS);
      }
    } catch (e) {
      toast.error(`There was an error updating your pre-save campaign`, SHARED_TOAST_OPTIONS);
      setErrorRefreshingReleases(true);
    } finally {
      setRefreshingReleases(false);
    }
  }
  function renderIssueResolutionNotes() {
    // if the status is with issues, but the release date is past and the user has entered UPC/ISRC,
    // give them the option to manually swap over
    if (status === 'WITH_ISSUES') {
      if (releaseDate.date && moment().isAfter(moment(releaseDate.date).add(10, 'minutes')) && release_url_upc_isrc) {
        return (
          <FacebookCampaignSimpleBanner
            title="It looks like we weren't able to find your release. This may be due to an incorrect UPC / ISRC or incorrect release date."
            description="You can solve this easily by refreshing releases below."
            ctaComponent={
              <div className="flex gap-4 justify-center flex-col">
                <PrimaryButton
                  disabled={refreshingReleases}
                  loading={refreshingReleases}
                  text="Pull New Releases"
                  onClick={() => refreshReleasesOnError()}
                />
                {refreshingReleases && <p>Finding your release...</p>}
                {errorRefreshingReleases && (
                  <p>
                    There was an error finding your release... Please contact support and we can help resolve this
                    issue!
                  </p>
                )}
              </div>
            }
          />
        );
      }
    }
    return null;
  }

  const getCountryName = (code: string) => {
    const country = countryList.data.find((c) => c.value === code);
    return country ? country.label : code;
  };

  const releaseTime = moment(releaseDate.date);
  const currentTime = moment();
  const timeDiff = currentTime.diff(releaseTime, 'hours');
  const marketsToShow = markets?.length ? markets.slice(0, markets.length < 2 ? 1 : 2) : [];
  const notReleasedOnAllMarkets =
    status === FINISHED && local_release && timeDiff <= 24 && markets.length > 0 && markets.length < 200;

  if (notReleasedOnAllMarkets) campaignStatus = get(labels, 'ACTIVE', {});

  return (
    <CampaignDetailsContainer
      backgroundColor="#F3F5F6"
      maxWidth={getMaxWidth(screenBreakpoints)}
      gap={mobileView ? '16px' : '24px'}
    >
      <PresaveOverviewCampaignCard
        name={name}
        thumbnail={thumbnail_url}
        campaignStatusLabel={campaignStatus.text}
        campaignStatus={campaignStatus.label}
        goBack={handleGoBack}
        handleEditPresaveDesign={() => handleOpenModals(Modals.EDIT_STYLE, 'dropdown')}
        handleEditBoost={() => handleOpenModals(Modals.EDIT_BOOSTS, 'dropdown')}
        handleEditPlatforms={() => handleOpenModals(Modals.EDIT_PLATFORMS, 'dropdown')}
        handleEditReleaseDate={() => handleOpenModals(Modals.EDIT_END_DATE, 'dropdown')}
        handleEditUpcIsrc={() => handleOpenModals(Modals.EDIT_UPC_ISRC, 'dropdown')}
        handleEditUrl={() => handleOpenModals(Modals.EDIT_URL, 'dropdown')}
        isFinalized={isFinalized}
      />
      {renderIssueResolutionNotes()}

      <DetailContainer flexDirection="column">
        {mobileView && (
          <Fragment>
            {!isFinalized && !Boolean(release_url_upc_isrc) && (
              <WarningNoReleaseUpcCard
                handleClickAdd={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                releaseDate={releaseDate.date}
                songName={name}
                key={'warning-card-' + name}
              />
            )}
            <PresaveLink
              brandSlug={linkSlugs.brandSlug}
              contentSlug={newLocalSlug}
              brandName={brand?.name!}
              style={presave?.style!}
              songName={content.name}
              status={status}
              presaveImage={contentDetails.artworkUrl}
              openPresaveModal={previewModal.openModal}
              closePresaveModal={previewModal.closeModal}
              brandId={brand?.id!}
              campaignId={details?.id!}
              daysLeft={daysLeft}
              canEdit={hasAdminAccess}
            />
          </Fragment>
        )}
        <DetailContainer flexDirection={mobileView ? 'column' : 'row'}>
          <DetailContainer flexDirection="column" width={mobileView ? '100%' : '70%'}>
            {!mobileView && (
              <Fragment>
                {!isFinalized && !Boolean(release_url_upc_isrc) && (
                  <WarningNoReleaseUpcCard
                    handleClickAdd={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                    releaseDate={releaseDate.date}
                    songName={name}
                    key={'warning-card-no-mobile' + name}
                  />
                )}
                <PresaveLink
                  brandSlug={linkSlugs.brandSlug}
                  contentSlug={newLocalSlug}
                  onOpenEditLinkModal={() => handleOpenModals(Modals.EDIT_URL)}
                  brandName={brand?.name!}
                  style={presave?.style!}
                  songName={content.name}
                  status={status}
                  presaveImage={contentDetails.artworkUrl}
                  openPresaveModal={previewModal.openModal}
                  closePresaveModal={previewModal.closeModal}
                  brandId={brand?.id!}
                  campaignId={details?.id!}
                  daysLeft={daysLeft}
                  canEdit={hasAdminAccess}
                />
              </Fragment>
            )}
            <TabsContainer padding={mobileView ? '16px 0px' : '16px 24px'}>
              <nav className="border-b">
                <SymphonyTabs
                  data={TABS}
                  onChange={(newValue) => setSelectedView(newValue as PageTabs)}
                  selectedValue={selectedView}
                  tabContentStyle={{ paddingRight: 0, paddingLeft: 0 }}
                />
              </nav>
              {isLoading ? (
                <div className="flex-col flex items-center ">
                  <NewLoader black />
                  <p className="mt-1 text-center">Loading Dashboard...</p>
                </div>
              ) : (
                <Fragment>
                  <TabContent hidden={selectedView !== PageTabs.PERFORMANCE}>
                    <Fragment>
                      {notReleasedOnAllMarkets && (
                        <div className="flex flex-col px-6 pt-4 gap-4">
                          <TimeRemaimingContainer>
                            <div className="flex flex-col gap-1 justify-center w-full">
                              <div>
                                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                  Your song/album is currently releasing across geographies.
                                </Body2>
                                {Boolean(marketsToShow.length) && (
                                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                    {' '}
                                    It’s already been released in{' '}
                                    {marketsToShow.map((market, index) => (
                                      <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                        {index > 0 && ', '}
                                        {getCountryName(market)}
                                      </Body2>
                                    ))}
                                    {markets.length > 2 && (
                                      <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                        {' '}
                                        and {markets.length - 2} more countries
                                      </Body2>
                                    )}
                                  </Body2>
                                )}
                              </div>
                              <div className="flex gap-1 items-center">
                                <Body2>Release date:</Body2>
                                <CalendarIcon color={SystemColors.ACCENT_COLOR} width={14} height={14} />
                                <Caption className={classes.oneLineText}>
                                  {momentTz(releaseDate.date).tz(releaseDate.timezone).format(`MMMM DD, YYYY h A z`)}
                                </Caption>
                              </div>
                              <TertiaryButton
                                text="View all countries"
                                onClick={() => handleOpenModals(Modals.VIEW_ALL_COUNTRIES)}
                              ></TertiaryButton>
                            </div>
                          </TimeRemaimingContainer>
                        </div>
                      )}
                      {isInProgress && (
                        <div className="flex flex-col px-6 pt-4 gap-4">
                          <TimeRemaimingContainer>
                            <div className="flex flex-col gap-1 justify-center w-full">
                              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                We're in the process of finding your release. This may take a few minutes as find your
                                release across different platforms.
                              </Body2>
                              <Body2>Please ensure your UPC/ISRC and release date look correct below:</Body2>
                              <WhiteContainer>
                                <Body3 color={SystemColors.PRIMARY_TEXT_COLOR}>Release Date:</Body3>
                                <div className="flex gap-4 justify-between items-center w-full">
                                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR} className={classes.oneLineText}>
                                    {formatDate(releaseDate.date, releaseDate.timezone)}
                                  </Body2>
                                  <TertiaryButton
                                    onClick={() => handleOpenModals(Modals.EDIT_END_DATE)}
                                    text="Edit"
                                    height="15px"
                                  />
                                </div>
                              </WhiteContainer>
                              <WhiteContainer>
                                <Body3 color={SystemColors.PRIMARY_TEXT_COLOR}>UPC/ISRC:</Body3>
                                <div className="flex gap-4 justify-between items-center w-full">
                                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR} className={classes.oneLineText}>
                                    {release_url_upc_isrc || 'Unset'}
                                  </Body2>
                                  <TertiaryButton
                                    onClick={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                                    text="Edit"
                                    height="15px"
                                  />
                                </div>
                              </WhiteContainer>
                              <Body2>
                                If all of the above looks good, you can manually search for your release to convert it
                                automatically:
                              </Body2>
                              <PrimaryButton
                                text="Find your release manually"
                                onClick={() => handleOpenModals(Modals.FIND_RELEASE)}
                              />
                            </div>
                          </TimeRemaimingContainer>
                        </div>
                      )}
                      {isNotFound && (
                        <div className="flex flex-col px-6 pt-4 gap-4">
                          <TimeRemaimingContainer isNotFound>
                            <div className="flex flex-col gap-2 justify-center flex-wrap overflow-auto w-full">
                              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                                We’re having issues finding your release!
                              </Body2>
                              <Body2>Please ensure your UPC/ISRC and release date look correct below:</Body2>
                              <WhiteContainer>
                                <Body3 color={SystemColors.PRIMARY_TEXT_COLOR}>Release Date:</Body3>
                                <div className="flex gap-4 justify-between items-center w-full">
                                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR} className={classes.oneLineText}>
                                    {formatDate(releaseDate.date, releaseDate.timezone)}
                                  </Body2>
                                  <TertiaryButton
                                    onClick={() => handleOpenModals(Modals.EDIT_END_DATE)}
                                    text="Edit"
                                    height="15px"
                                  />
                                </div>
                              </WhiteContainer>
                              <WhiteContainer>
                                <Body3 color={SystemColors.PRIMARY_TEXT_COLOR}>UPC/ISRC:</Body3>
                                <div className="flex gap-4 justify-between items-center w-full">
                                  <Body2 color={SystemColors.PRIMARY_TEXT_COLOR} className={classes.oneLineText}>
                                    {release_url_upc_isrc}
                                  </Body2>
                                  <TertiaryButton
                                    onClick={() => handleOpenModals(Modals.EDIT_UPC_ISRC)}
                                    text="Edit"
                                    height="15px"
                                  />
                                </div>
                              </WhiteContainer>
                              <Body2>
                                If all of the above looks good, you can manually search for your release to convert it
                                automatically:
                              </Body2>
                              <PrimaryButton
                                text="Find your release manually"
                                onClick={() => handleOpenModals(Modals.FIND_RELEASE)}
                              />
                            </div>
                          </TimeRemaimingContainer>
                        </div>
                      )}
                      <div className="flex flex-col px-6 pt-4 gap-4">
                        {!notReleasedOnAllMarkets &&
                          (!isFinalized ? (
                            <TimeRemaimingContainer>
                              <Headline1>{hasEndDatePassed ? 0 : getTotalTimeRemaining().amount}</Headline1>
                              <div className="flex flex-col gap-1 justify-center">
                                <Body2>{getTotalTimeRemaining().unit} left until release</Body2>
                                <div className="flex gap-1 items-center">
                                  <CalendarIcon color="#8800FF" width={14} height={14} />
                                  <Caption>
                                    {momentTz(releaseDate.date).tz(releaseDate.timezone).format(`MMMM DD, YYYY h A z`)}
                                  </Caption>
                                </div>
                              </div>
                            </TimeRemaimingContainer>
                          ) : (
                            <TimeRemaimingContainer>
                              <div className="flex flex-col gap-4 justify-center">
                                <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Your song has been released!</Body2>
                                <Body2>Promote your song with these campaigns:</Body2>
                                <div className="flex gap-2">
                                  <OptionContainer onClick={createMusicStreamsCampaign}>
                                    <OptionIcon src={MusicStreamsIcon} />
                                    <Caption color={SystemColors.ACCENT_COLOR}>Increase music streams</Caption>
                                  </OptionContainer>
                                  <OptionContainer onClick={createPlaylistingCampaign}>
                                    <OptionIcon src={PlaylistingIcon} />
                                    <Caption color={SystemColors.ACCENT_COLOR}>Get playlisted</Caption>
                                  </OptionContainer>
                                </div>
                              </div>
                            </TimeRemaimingContainer>
                          ))}
                        <Body2>
                          {status === FINISHED
                            ? `Your presave was automatically converted into a landing page with all of your streaming links.
                                                    You can see stats for your landing page on the "Website" dashboard.`
                            : 'Once your music releases, your presave page will automatically convert into a landing page with all streaming links pulled in.'}
                        </Body2>
                      </div>
                      <div className="px-6 py-6 border-b">
                        <GridContainer>
                          <DetailCard2
                            svgIcon={<PreSaves />}
                            title="Total Pre-Saves"
                            value={presavesData?.new!}
                            firstStatTitle="Fans who pre-saved from your website"
                            secondStatTitle="Fans who Forever Saved your past releases"
                            thirdStatTitle="Total Pre-saves on this release"
                            thirdStatValue={presavesData?.total!}
                            upgradeToPro={false}
                          />
                          <DetailCard2
                            svgIcon={<Star />}
                            title="Total Forever Saves"
                            firstStatTitle="Fans who Forever Saved from this release"
                            value={foreverSavesData?.new!}
                            secondStatTitle="Fans who Forever Saved your past releases"
                            thirdStatTitle="Total Forever Save Subscribers"
                            thirdStatValue={foreverSavesData?.total!}
                            subtitle={
                              isProUser
                                ? `This release and all your future releases will start with ${foreverSavesData?.total} saves from day 1.`
                                : `Upgrade to Pro to unlock ${
                                    foreverSavesData?.total === 1
                                      ? `${foreverSavesData?.total} Forever Save.`
                                      : `${foreverSavesData?.total} Forever Saves.`
                                  }`
                            }
                            upgradeToPro={!isProUser}
                            proExclusive={true}
                          />
                          {isAfterFansRelease ? (
                            <DetailCard2
                              svgIcon={<Mail stroke="#8800FF" />}
                              title="Collected Emails"
                              firstStatTitle="Emails collected in this release"
                              value={emailsData?.new!}
                              secondStatTitle="Emails collected from past releases"
                              thirdStatTitle="Total emails"
                              thirdStatValue={emailsData?.total!}
                              subtitle={`You've collecting ${emailsData?.new} emails with this release, bringing your total list to ${emailsData?.total} emails.`}
                              upgradeToPro={false}
                            />
                          ) : (
                            <DetailCard
                              svgIcon={<Mail stroke="#8800FF" />}
                              title="Emails"
                              value={emailsData?.total!}
                              firstStatTitle="Total"
                              isMoney={false}
                              upgradeToPro={false}
                              arrow={true}
                            />
                          )}
                          <DetailCard
                            svgIcon={<Star />}
                            title="New Streaming Service Followers"
                            firstStatTitle="From this release"
                            value={newFollowersData?.new!}
                            secondStatTitle="Across all Releases"
                            secondStatValue={newFollowersData?.total!}
                            isMoney={false}
                            subtitle={
                              newFollowersData?.new
                                ? `${newFollowersData?.new} fan${
                                    newFollowersData?.new === 1 ? '' : 's'
                                  } followed you on their streaming platform when they pre-saved this release.`
                                : `When fans pre-save, they'll automatically follow you on their preferred streaming platform.`
                            }
                            upgradeToPro={false}
                            additionalContent={showLinkStreamingServicesButtons()}
                          />
                        </GridContainer>
                      </div>
                      <div className="px-6 py-6 border-b">
                        <Demographics contentName={name} shown demographicBreakdown={demographics} />
                      </div>
                      <div className="px-6 pt-6">
                        <p className="text-lg font-semibold flex items-center gap-1.5">
                          Pre-Saves by Day <UpgradeToProChip type="gem" color="purple" height={18} width={18} />
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          This graph shows how many pre-saves came in every day. You can use this information to
                          determine if your social posts around your pre-save campaign are leading fans to pre-save.
                        </p>
                        <div className="mt-4">
                          {!isProUser && (
                            <div className="py-5 pt-0">
                              <UnlockDataBanner
                                source="Presave Campaign Details - Pre-Saves by Day"
                                title="Pre-Saves by day"
                                description="Go Pro to see how many pre-saves are coming per day."
                                image={UnlockPresavesByDayImage}
                                hideImage={mobileView}
                              />
                            </div>
                          )}
                          {isProUser && (
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={dailyStats}
                                margin={{
                                  top: 0,
                                  right: 0,
                                  left: 0,
                                  bottom: 20,
                                }}
                              >
                                <CartesianGrid stroke="#8800FF29" strokeWidth={1.5} strokeDasharray="8 8" />

                                <XAxis
                                  dy={12}
                                  axisLine={false}
                                  dataKey="date"
                                  tickLine={false}
                                  minTickGap={30}
                                  tickFormatter={(value: string, _index: number) => {
                                    return dayjs(value, 'YYYY-MM-DD', true).format('MM/DD');
                                  }}
                                />
                                <YAxis
                                  dataKey={'count'}
                                  mirror={window.innerWidth < 768}
                                  tick={(e) => {
                                    const {
                                      payload: { value },
                                    } = e;
                                    const color = value === 'yourdata' ? 'red' : '#666';
                                    e['fill'] = color;
                                    if (window.innerWidth >= 768) {
                                      return (
                                        <Text
                                          {...e}
                                          style={{
                                            zIndex: 99,
                                          }}
                                        >
                                          {millify(value, {
                                            precision: 2,
                                          })}
                                        </Text>
                                      );
                                    } else {
                                      return (
                                        <>
                                          <rect></rect>
                                          <defs>
                                            <filter id="rounded-corners" x="-5%" width="110%" y="0%" height="100%">
                                              <feFlood flood-color="#fff" />
                                              {/* <feGaussianBlur stdDeviation="2" /> */}
                                              <feComponentTransfer>
                                                <feFuncA type="table" tableValues="1 1 1 1" />
                                              </feComponentTransfer>

                                              <feComponentTransfer>
                                                <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                                              </feComponentTransfer>
                                              <feComposite operator="over" in="SourceGraphic" />
                                            </filter>
                                          </defs>
                                          <Text
                                            filter="url(#rounded-corners)"
                                            {...e}
                                            style={{
                                              transform: 'translateX(-4px)',
                                              zIndex: 99,
                                            }}
                                          >
                                            {millify(value, {
                                              precision: 2,
                                            })}
                                          </Text>
                                        </>
                                      );
                                    }
                                  }}
                                  tickCount={window.innerWidth < 768 ? 3 : 4}
                                  axisLine={false}
                                  minTickGap={window.innerWidth < 768 ? 30 : 20}
                                  tickLine={false}
                                  allowDecimals={false}
                                  domain={['auto', 'auto']}
                                />
                                <Tooltip
                                  cursor={{ stroke: '#42c221', strokeWidth: 2 }}
                                  content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                      return (
                                        <GraphTooltip
                                          title={dayjs(label).format('dd MM/DD')}
                                          value={payload[0].value!.toLocaleString('en')}
                                        />
                                      );
                                    }

                                    return null;
                                  }}
                                />

                                <defs>
                                  <linearGradient id="small-graph" x1="0" y1="0" x2="0" y2="1">
                                    <stop stopColor="#42c221" stopOpacity={0.4} />
                                    <stop offset="1" stopColor="#42c221" stopOpacity={0} />
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  fill="url(#small-graph)"
                                  strokeWidth={2}
                                  dataKey="count"
                                  stroke="#42c221"
                                  fillOpacity="0.8"
                                  activeDot={{ r: 8 }}
                                />
                              </AreaChart>
                            </ResponsiveContainer>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  </TabContent>
                  <TabContent hidden={selectedView !== PageTabs.AUDIENCE}>
                    <div className="pt-5">
                      <Audience 
                        shown 
                        audienceBreakdown={audience} 
                        audienceTotal={audienceCount} 
                        orderBy={audienceOrderBy} 
                        setOrderBy={setAudienceOrderBy} 
                        setOrderDirection={setAudienceOrderDirection} 
                        limit={AUDIENCE_LIMIT} 
                        offset={audienceOffset} 
                        setOffset={setAudienceOffset} 
                        showBlurredDataRow={!isProUser} 
                        selectedAudienceTab={selectedAudienceTab} 
                        setSelectedAudienceTab={setSelectedAudienceTab} 
                      />
                    </div>
                  </TabContent>
                  <TabContent hidden={selectedView !== PageTabs.GEOGRAPHIES}>
                    <div className="pt-5">
                      <Geographies
                        cityGeographies={cityGeographies}
                        countryGeographies={countryGeographies}
                        isProUser={isProUser}
                        hideBannerImage={mobileView}
                        onGeographiesClickTab={onGeographiesClickTab}
                        selectedGeographiesTab={selectedGeographiesTab}
                      />
                    </div>
                  </TabContent>
                  <TabContent hidden={selectedView !== PageTabs.SETUP}>
                    <PresaveSetup
                      releaseDate={releaseDate.date}
                      timezone={timezone}
                      boosts={boosts}
                      platforms={platforms}
                      urlUpcIsrc={release_url_upc_isrc || ''}
                      handleOpenModals={handleOpenModals}
                      isFinalized={isFinalized}
                    />
                  </TabContent>
                </Fragment>
              )}
            </TabsContainer>
          </DetailContainer>
          <PresavePreview
            contentDetails={contentDetails}
            style={presave?.style!}
            styleType={presave?.style?.buttonConfig as StyleTypes}
            bgColor={previewBgColor}
            openEditModal={editStylesModal.openModal}
            isFinalized={isFinalized}
            canEdit={hasAdminAccess}
          />
        </DetailContainer>
      </DetailContainer>
      <EditPresaveModal
        {...editStylesModal}
        onSave={handleChangeCampaignMetadata}
        campaign={details}
        trackObject={trackObject}
      />
      <EditPlatformsModal
        {...editPlatformsModal}
        onSave={handleChangeCampaignMetadata}
        campaign={details}
        trackObject={trackObject}
      />
      <EditReleaseDateModal
        {...editEndDateModal}
        campaignId={details.id}
        releaseDate={releaseDate.date}
        isLocalRelease={Boolean(local_release)}
        timezone={timezone}
        hideReleaseDate={Boolean(details.campaign_metadata.presave?.hideReleaseDate)}
        reload={handleChangeReleaseDate}
        onErrorHandler={() => handleOpenModals(Modals.ERROR_UPDATING_CAMPAIGN)}
        trackObject={trackObject}
      />
      <GeneralContactSupportModal
        {...showContactSupportModal}
        title="Edit your Pre-save"
        message="We'll be rolling out the ability to edit pre-saves soon! In the interim, reach out to our team and we'll get things updated ASAP:"
        campaignId={details.id}
      />
      <ErrorUpdatingCampaignModal {...errorUpdatingCampaignModal} campaignId={details.id} />
      <EditUPCISRCModal
        {...editUPCISRCModal}
        onSave={handleChangeCampaignMetadata}
        campaignId={details.id}
        value={release_url_upc_isrc}
        trackObject={trackObject}
      />
      <AllCountriesModal {...viewAllCountriesModal} markets={markets} />
      <EditBoostsModal
        {...editBoostsModal}
        campaignId={details.id}
        onSave={handleChangeCampaignMetadata}
        boosts={boosts}
        totalValues={totalValues}
        trackObject={trackObject}
        isProUser={isProUser}
      />

      <EditWebsiteSlugModal
        {...editUrlModal}
        brandId={brand?.id!}
        updatingContentSlug={newLocalSlug}
        campaignId={details.id}
        brandSlug={brand?.slug!}
        onSave={(newSlug: string) => {
          setNewLocalSlug(newSlug);
        }}
        url={localUrl}
        onSaveUrl={(newUrl: string) => {
          setLocalUrl(newUrl);
        }}
      />
      <PreviewModal
        open={previewModal.open}
        closePreview={previewModal.closeModal}
        contentDetails={contentDetails}
        style={presave?.style!}
        styleType={presave?.style?.buttonConfig as StyleTypes}
        openEditModal={editStylesModal.openModal}
        bgColor={previewBgColor}
        showEditButton
        isFinalized={isFinalized}
      />
      <ManuallyReleaseModal
        open={manuallyFindReleaseModal.open}
        onClose={manuallyFindReleaseModal.closeModal}
        contentSlug={content.slug}
        refreshCampaignDetails={refreshCampaignDetails}
      />
      {showConnectBox === 'audiomack' && (
        <AudiomackConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={brand!}
          open={true}
          selected={brand && brand.connections.audiomack ? brand.connections.audiomack.url : null}
          onSave={() => enableAutosaveOnPlatform('audiomack')}
        />
      )}
      {showConnectBox === 'soundcloud' && (
        <SoundcloudConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={brand!}
          open={true}
          selected={brand?.connections?.soundcloud_artist_page?.id}
          onSave={() => enableAutosaveOnPlatform('soundcloud')}
        />
      )}
      {showConnectBox === 'deezer' && (
        <DeezerConnectv2
          closeModal={() => setShowConnectBox(null)}
          artist={brand!}
          open={true}
          selected={brand?.connections?.deezer?.id ? brand?.connections?.deezer?.id : null}
          onSave={() => enableAutosaveOnPlatform('deezer')}
        />
      )}
    </CampaignDetailsContainer>
  );
};

export default PreSaveDetails;
