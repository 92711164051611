import { FunctionComponent } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { StarsIcon } from "components/svg-icons";
import { Container } from "styles/shared";
import { CampaignOption } from "../../../utils";

interface Props {
  link: string;
  linkTitle: string;
  objective: CampaignOption;
}

const HeadCard: FunctionComponent<Props> = ({
  link,
  linkTitle = "Link",
  objective
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const classes = useStyles();

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              submit your campaign to Facebook.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={600}>
                {linkTitle}
              </StyledLabel>
            </Grid>
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={400}>
                {link}
              </StyledLabel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.space}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Container display="flex" gap="4px">
       
                <StyledLabel fontSize={16} fontWeight={600}>
                  Campaign Goal
                </StyledLabel>
              </Container>
            </Grid>
            <Grid item>
              <StyledLabel fontSize={16} fontWeight={400}>
                {objective.label}
              </StyledLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default HeadCard;
