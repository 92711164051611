import { FunctionComponent } from "react";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";

interface OptionProps {
  isProUser?: boolean;
  label?: string;
  mainContainerClassName?: string;
  labelClassName?: string;
  selected?: boolean;
}

const MenuProOption: FunctionComponent<OptionProps> = ({
  isProUser,
  label,
  labelClassName,
  mainContainerClassName,
  selected,
}: OptionProps) => (
  <div className={mainContainerClassName}>
    <p className={labelClassName}>{label}</p>{" "}
    <div className="ml-auto">
      <UpgradeToProChip
        type="gem"
        color={selected ? "white" : "purple"}
      />
    </div>
    {/* {!isProUser && (
      <div className="w-16">
        <UpgradeToProChip
          labelProps={
            {
              fontSize: 14,
              fontWeight: 480,
              lineHeight: '1.8',
            }
          }
        />
      </div>
    )} */}
  </div>
);

export default MenuProOption;
