import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  modalBackground: {
    [theme.breakpoints.down(701)]: {
      backgroundColor: "#F5F3F6",
    }
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      [theme.breakpoints.down(701)]: {
        margin: 0,
        borderRadius: 0,
        height: "100%",
        padding: "4px",
        boxShadow: "none",
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
        borderRadius: 12,
      },
    },
  },
}));

interface HeaderContainerProps {
  justifyContent?: string,
}

interface ModalContainerProps {
  minHeight?: string
  height?: string
  maxHeight?: string
  width?: string
  margin?: string
  padding?: string
  gap?: string
}

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }: ModalContainerProps) => padding || "32px"};
  gap: ${({ gap }: ModalContainerProps) => gap || "48px"};
  height: ${({ height }: ModalContainerProps) => height || "484px"};
  min-height: ${({ minHeight }: ModalContainerProps) => minHeight};
  max-height: ${({ maxHeight }: ModalContainerProps) => maxHeight};
  width: ${({ width }: ModalContainerProps) => width || "673px"};
  margin: ${({ margin }: ModalContainerProps) => margin || "auto"};
  background: #ffffff;
  border-radius: 8px;
`;

export const WarningContainer = styled.div`
  background: rgba(228, 144, 13, 0.08);
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 16px 12px;
  gap: 8px;
`;

export const TextWarning = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.72);
`;

export const ContactSupportButton = styled.span`
  cursor: pointer;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #8800FF;
  padding: 12px 0px;
`;
