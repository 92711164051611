import React, { FunctionComponent, ReactNode } from "react";
import { ConnectorIndicatorTypes } from "types/global";
import { PercentIndicatorIcon } from "components/shareable/BrandConnectorIndicator";
import { getPercentLabelColor } from "pages/post-auth/MarketingPage/CampaignDetails/ImpactStats/utils";
import { getAvgCostPerFollowerMainLabel } from "./utils";

const { INSTAGRAM_FOLLOWERS, SPOTIFY_FOLLOWERS } = ConnectorIndicatorTypes;

interface CustomChartLabelProps {
  index: number;
  showSecondaryMetric: boolean;
  isCompact?: boolean;
  metricProps: {
    icon: string;
    iconAlt: string;
    primaryValue: string | number;
    label: string;
    isReachOrVisits?: boolean;
    isFirstDay?: boolean;
    averageBeforeCampaign?: number;
    averageDuringCampaign?: number;
  };
  secondaryMetricProps: {
    value: number;
    type: ConnectorIndicatorTypes;
    customLabel?: string;
  };
  percentageProps: {
    value: number;
    iconClassName?: string;
    label?: string;
  };
  isReachOrVisits: boolean;
  isFirstDay: boolean;
  averageBeforeCampaign?: number | null | undefined;
  averageDuringCampaign?: number | null | undefined;
}

const CustomChartLabel: FunctionComponent<CustomChartLabelProps> = ({
  index,
  showSecondaryMetric,
  isCompact,
  metricProps,
  secondaryMetricProps,
  percentageProps,
  isReachOrVisits,
  isFirstDay,
  averageBeforeCampaign,
  averageDuringCampaign,
}) => {
  const wrapperClassName = isCompact ? `mb-4 ${index > 0 ? "mt-6" : ""}` : "";

  const renderPercentageIndicator = (value: number, iconClassName?: string, label?: string) => {
    const color = getPercentLabelColor(value);
    const showIcon = value !== 0;
    const defaultLabel = isReachOrVisits ? "Growth during Campaign" : "Overall Growth";

    return (
      <div className={`flex ${isCompact ? "flex-col" : "flex-col"} items-end`}>
        <div className="flex items-center gap-x-0.5">
          {showIcon && !isCompact && (
            <span className="pl-1 w-4 h-2">
              <PercentIndicatorIcon className={iconClassName} value={value} />
            </span>
          )}
          <span className={`text-${color}-500 font-semibold text-${isCompact ? "xl" : "2xl"}`}>
            {Math.abs(value).toFixed(1)}%
          </span>
        </div>
        {!isCompact && (
          <div className="text-xs text-gray-600 font-light text-right ml-2">
            <span>{label || defaultLabel}</span>
          </div>
        )}
      </div>
    );
  };

  const renderReachOrVisitsFirstDay = () => {
    const { icon, iconAlt, label } = metricProps;
    return (
      <div className="w-full flex justify-between items-center">
        <div className="w-full flex flex-col">
          <div className="flex items-center">
            <img alt={iconAlt} className="w-10 h-10 mr-2" src={icon} />
            <p className="font-light">
              Your average {label} in the last 7 days before your campaign was{' '}
              <span className="font-semibold">
                {averageBeforeCampaign?.toLocaleString()}
              </span>.
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderReachOrVisitsAfterFirstDay = () => {
    const { icon, iconAlt, label } = metricProps;
    const isValidAverage = averageDuringCampaign !== null && averageBeforeCampaign !== null &&
      isFinite(averageDuringCampaign ?? 0) && isFinite(averageBeforeCampaign ?? 0);

    const changePercentage = isValidAverage && averageDuringCampaign !== null && averageBeforeCampaign !== null
      ? ((averageDuringCampaign! - averageBeforeCampaign!) / averageBeforeCampaign!) * 100
      : null;
    const color = changePercentage !== null ? getPercentLabelColor(changePercentage) : null;
    return (
      <div className="w-full flex justify-between items-center">
        <div className={`${isCompact ? "w-3/4" : "w-2/3"} flex flex-col`}>
          <div className="flex items-center">
            <img alt={iconAlt} className="w-10 h-10 mr-2" src={icon} />
            <p className="font-light">
              <span className="font-semibold">
                {averageDuringCampaign?.toLocaleString()}
              </span> {label} everyday on average during your campaign
              {changePercentage !== null ? (
                <>
                  , a <span className={`text-${color}-500`}>
                    {changePercentage >= 0 ? '+' : ''}{changePercentage.toFixed(1)}%
                  </span> {changePercentage >= 0 ? 'increase' : 'decrease'}.
                </>
              ) : '.'}
            </p>
          </div>
        </div>
        {changePercentage !== null && (
          <div className={`${isCompact ? "w-1/4" : "w-1/3"}`}>
            {renderPercentageIndicator(changePercentage, percentageProps.iconClassName, percentageProps.label)}
          </div>
        )}
      </div>
    );
  };

  const renderRegularMetric = () => {
    const { icon, iconAlt, primaryValue, label } = metricProps;
    return (
      <div className="flex items-center">
        <img alt={iconAlt} className="w-10 h-10 mr-2" src={icon} />
        <div>
          <p className="flex text-dark font-semibold">
            <span>{primaryValue.toLocaleString('en')}</span>
          </p>
          <p className="font-light">{label}</p>
        </div>
      </div>
    );
  };

  const renderMetricDisplay = () => {
    return (
      <div className={`flex items-center ${isCompact ? "" : "w-full"}`}>
        {isReachOrVisits
          ? (isFirstDay ? renderReachOrVisitsFirstDay() : renderReachOrVisitsAfterFirstDay())
          : renderRegularMetric()
        }
      </div>
    );
  };

  const renderSecondaryMetric = () => {
    const { value, type, customLabel } = secondaryMetricProps;
    const label = customLabel || getAvgCostPerFollowerMainLabel(type);
    const shouldHide = value < 0 && [INSTAGRAM_FOLLOWERS, SPOTIFY_FOLLOWERS].includes(type);

    if (shouldHide) return null;

    return (
      <div className="flex flex-col items-end items-center">
        <span className="text-dark font-semibold text-2xl">
          ${value}
        </span>
        <span className="text-xs text-gray-600 font-light text-right">
          {label}
        </span>
      </div>
    );
  };

  const CompactView = () => (
    <div className="flex flex-row w-full ">

      {!isReachOrVisits ? (
        <>
          <div className="flex items-center w-3/4">
            {renderMetricDisplay()}
          </div>
          <div className="mt-2 w-1/4">
            {renderPercentageIndicator(percentageProps.value, percentageProps.iconClassName, percentageProps.label)}
          </div>
        </>

      ) : (
        <div className="flex items-center w-full">
          {renderMetricDisplay()}
        </div>
      )}
    </div>
  );

  const FullView = () => (
    <div className="flex w-full">
      <div className={`${isReachOrVisits ? "w-full" : showSecondaryMetric ? "w-1/3" : "w-1/2"} mr-auto`}>
        {renderMetricDisplay()}
      </div>
      {showSecondaryMetric && (
        <div className="w-1/3">
          {renderSecondaryMetric()}
        </div>
      )}
      {!isReachOrVisits && (
        <div className={`${showSecondaryMetric ? "w-1/3" : "w-1/2"} ml-auto`}>
          {renderPercentageIndicator(percentageProps.value, percentageProps.iconClassName, percentageProps.label)}
        </div>
      )}
    </div>
  );

  return (
    <div className={wrapperClassName || `mb-4 flex items-center justify-end ${index > 0 ? "mt-6" : ""}`}>
      {isCompact ? <CompactView /> : <FullView />}
    </div>
  );
};

export default CustomChartLabel;