import { FunctionComponent } from "react";
import useStyles from "./styles";
import { validateReleaseUrlUriUpcIsrc } from "../../utils";
import InputWithDebounce from "components/shareable/InputWithDebounce";

interface ReleaseUrlErrorHelperTextProps {
    isValidReleaseUrl: boolean;
    releaseUrl: string;
}

const ReleaseUrlErrorHelperText: FunctionComponent<
    ReleaseUrlErrorHelperTextProps
> = ({ isValidReleaseUrl, releaseUrl }: ReleaseUrlErrorHelperTextProps) => {
    const classes = useStyles();
    const show = !isValidReleaseUrl && releaseUrl.length > 0;

    return (
        <span className={classes.formErrorHelperText}>
            {show ? "Please enter a valid Spotify URL or UPC/ISRC." : ""}
        </span>
    );
};

interface InputUPCISRCProps {
    releaseUrl: string
    onChangeInput: (newReleaseUrl: string) => void
    size?: 'small' | 'medium'
    isValidReleaseUrl: boolean
}

const InputUPCISRC: FunctionComponent<InputUPCISRCProps> = ({
    releaseUrl,
    onChangeInput,
    size,
    isValidReleaseUrl,
}: InputUPCISRCProps) => {
    return (
        <InputWithDebounce
            value={releaseUrl}
            error={!isValidReleaseUrl && releaseUrl.length > 0}
            onChange={onChangeInput}
            size={size}
            placeholder="UPC / ISRC / Release URL"
            helperText={
                <ReleaseUrlErrorHelperText
                    {...{ isValidReleaseUrl, releaseUrl }}
                />
            }
        />
    )
}

export default InputUPCISRC