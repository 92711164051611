import { FunctionComponent, useContext, useEffect, useState } from "react"
import {
  dailyBudgetCalculator,
  estimatedCostPerView,
  estimatedStreamsCalculator,
  totalLengthCalculator,
} from "../../utils";
import { Card, StyledLabel } from "../../styles";
import useStyles, { ReviewCampaignButton, Container } from "./styles";
import Grid from "@material-ui/core/Grid";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext";
import getSymbolFromCurrency from "currency-symbol-map";
import { CurrencyCodesType } from "types/global";

interface Props {
  budgetValue: number;
  startDate: Date | null;
  endDate: Date | null;
  disableMobileButton: boolean;
  showReviewCampaignButton?: boolean;
  finishFlow: () => void;
  targetingType: string;
  geographyType: string;
}

const RunningForCard: FunctionComponent<Props> = ({
  budgetValue,
  startDate,
  endDate,
  disableMobileButton,
  showReviewCampaignButton = true,
  finishFlow,
  targetingType,
  geographyType
}: Props) => {
  const classes = useStyles();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const { currency } = useContext(SpendBudgetContext)
  const [estimatedStreams, setEstimatedStreams] = useState<string | null>(null)
  const [estimatedCostPerViewValue, setEstimatedCostPerView] = useState<string | null>(null)

  useEffect(() => {
    estimatedStreamsCalculator(budgetValue, targetingType, geographyType, currency as CurrencyCodesType)
      .then((res) => setEstimatedStreams(res))

    estimatedCostPerView(targetingType, geographyType, currency as CurrencyCodesType)
      .then((res) => setEstimatedCostPerView(res))
  }, [currency, budgetValue, targetingType, geographyType])

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Container className="text-center w-full">
        <Grid
          container
          className={classes.runningFor}
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Running for
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
            {startDate && endDate
              ? totalLengthCalculator({ startDate, endDate })
              : "..."}
          </StyledLabel>
        </Grid>
        <Grid
          className={classes.dailySpend}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Daily Spend
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
          {getSymbolFromCurrency(currency)}
            {budgetValue && budgetValue > 0
              ? dailyBudgetCalculator({ startDate, endDate, budgetValue })
              : "..."}
          </StyledLabel>
        </Grid>
        <Grid
          className={classes.estimatedNewListeners}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Avg. Cost-per-View
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
            {budgetValue && budgetValue > 0
              ? estimatedCostPerViewValue
              : "..."}
          </StyledLabel>
        </Grid>
        <Grid
          className={classes.estimatedNewListeners}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <StyledLabel fontWeight={600} fontSize={16}>
            Estimated Total New Views
          </StyledLabel>
          <StyledLabel fontWeight={400} fontSize={16}>
            {budgetValue && budgetValue > 0
              ? estimatedStreams
              : "..."}
          </StyledLabel>
        </Grid>
        {showReviewCampaignButton && (
          <ReviewCampaignButton
            disabled={disableMobileButton}
            onClick={finishFlow}
          >
            Review Your Campaign
          </ReviewCampaignButton>
        )}
      </Container>
    </Card>
  )
};

export default RunningForCard;
