import { BannerParent, BannerSubtitle, BannerTitle, IconComponentParent } from "./styles";

interface BannerSectionProps {
    title: string;
    description: string;
    iconComponent?: React.ReactNode;
}

export default function BannerSection(props: BannerSectionProps) {
    const {
        title,
        description,
        iconComponent
    } = props

    return (
        <BannerParent className="">
            {iconComponent && (<IconComponentParent>
                {iconComponent}
            </IconComponentParent>)}
            <div>
                <BannerTitle>{title}</BannerTitle>
                <BannerSubtitle>{description}</BannerSubtitle>
            </div>
        </BannerParent>
    )
}