import { useContext, useEffect, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import { BrandContent, ArtistPermissions } from "types/global";
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import GeneralPurpleButton from "components/shareable/CommonComponent/PurpleButton";
import * as Sentry from "@sentry/react";
import { pageView } from "analytics";
import Axios from "helpers/Interceptor";
import { Container, MainContainer, StyledNavBar, TabsPanelsContainer } from "styles/shared";
import { BannerContainer, ContentContainer, TabsContainer } from "../../MarketingPage/styles";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import WebsiteListItem from "./WebsiteListItem";
import { formattedWebsiteUrl, getStatusToShowPaymentFailedBanner, isBrandAdministrator } from "utils";
import { WEBSITE_TYPES, WebsiteType } from "components/shareable/ContentTypeBadge/website";
import CreateNewSiteModal, { CreateSiteOptions, Steps } from "./CreateNewSiteModal";
import { WEBSITE_CREATION_TYPE } from "../types";
import { Body2, Caption, Headline2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { OverallWebsiteDashboard } from "./OverallWebsiteDashboard";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import PaymentFailedBanner from "components/shareable/PaymentFailedBanner";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { getRecordDetails } from "services/symphonyApi/brandContentService";

enum WebsitePageTabs {
    SITES = 'sites',
    DASHBOARD = 'dashboard',
}

const WebsitesList = () => {

    const [loadingContent, setLoadingContent] = useState<boolean>(false)
    const [brandContents, setBrandContents] = useState<BrandContent[]>([])
    const [step, setStep] = useState<Steps>(Steps.SELECT_OPTION)

    const { currentBrand } = useContext(CurrentBrandContext);
    const { mobileView } = useMediaBreakpoints();

    const hasAdminAccess = isBrandAdministrator(currentBrand!)
    const tabs = [WebsitePageTabs.SITES, WebsitePageTabs.DASHBOARD];

    const showPaymentFailedBanner = getStatusToShowPaymentFailedBanner(currentBrand)

    const [selectedTab, setSelectedTab] = useTabs(tabs, WebsitePageTabs.SITES);

    const [actionedItem, setActionedItem] = useState<BrandContent | undefined>(undefined)

    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)

    const openCreateSiteModal = () => setOpenCreateModal(true)
    const closeCreateSiteModal = () => setOpenCreateModal(false)
    async function fetchBrandContent() {
        try {
            const brandId = currentBrand?.id;
            Sentry.setContext('fetchBrandContent', { brandId, screen: 'Website' });
            setLoadingContent(true);
            const brandContentResponse = await Axios.get(`/brand-content/${brandId}?includes[]=campaigns`, { timeout: 60000 });

            if (brandContentResponse.data.data) {
                const filteredReleases = brandContentResponse.data.data.filter((o: any) => {
                    return o.content_metadata.published;
                });
                try {
                    const [latestRelease] = filteredReleases;
                    const isSingle = (
                        latestRelease?.content_metadata.type === "project" &&
                        latestRelease?.content_metadata.tracks.length <= 1 &&
                        latestRelease?.content_metadata.spotify_release_type === "single"
                    );
                    if (isSingle) {
                        const [single] = latestRelease.content_metadata.tracks;
                        const singleDetails = await getRecordDetails(brandId!, single.spotify_id, 'single'); // Assuming 'single' is the appropriate record type here

                        if (singleDetails) {
                            filteredReleases[0].single = singleDetails;
                        }
                    }
                } catch (error) {
                    console.error('Error in fetching brand content single', error);
                }
                setBrandContents(filteredReleases);
            } else {
                console.error("Error loading releases");
            }
        } catch (error) {
            Sentry.captureException(error);
            console.error('Error in fetching brand content', error);
        } finally {
            setLoadingContent(false);
        }
    }

    useEffect(() => {
        pageView("Website List")
        fetchBrandContent()
    }, [currentBrand])

    useEffect(() => {
        if (selectedTab === WebsitePageTabs.DASHBOARD) {
            pageView("Website Dashboard")
        }
    }, [selectedTab])

    function renderWebsiteItems() {
        if (brandContents.length === 0 && !hasAdminAccess) {
            return (
                <div className="flex w-full justify-center">
                    <Caption color={SystemColors.SECONDARY_TEXT_COLOR}>No websites created yet.</Caption>
                </div>
            )
        }
        if (brandContents.length === 0) {
            return (
                <>
                    <div className="flex flex-col py-4">
                        <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>Create your first site</Headline2>
                        <Body2>Choose a website template based on your goal.</Body2>
                    </div>
                    <CreateSiteOptions step={step} setStep={setStep} />
                </>
            )
        }
        const items = brandContents.map((item: BrandContent, index: number) => {

            const thumbnail = item.content_metadata.thumbnail_url || currentBrand?.image || ''
            const primaryTitle = item.name
            let websiteType: WebsiteType = WEBSITE_TYPES.MUSIC
            let additionalLabel = null

            // if this is a presave, but it hasnt been saved yet - mark it as a presave site
            if (item.content_metadata.presave && !(item.content_metadata.presave.saved_at)) {
                websiteType = WEBSITE_TYPES.PRESAVE
            } else if (item.type === WEBSITE_TYPES.DATA_COLLECTOR) {
                websiteType = WEBSITE_TYPES.DATA_COLLECTOR
            } else {
                switch (item.content_metadata.type) {
                    case 'single':
                    case 'record':
                        additionalLabel = 'Single'
                        break;
                    case 'album':
                    case 'project':
                        additionalLabel = 'Album/Project'
                        break;
                    case 'playlist':
                        additionalLabel = 'Playlist'
                        break;
                }
            }

            let mainStyle = {
                bgType: 'artwork',
                bgColor: "#000000",
                primaryColor: "#7C18F7",
                secondaryColor: "#000000",
                buttonConfig: "boxes"
            }

            if (item.primary_customization) {
                mainStyle = {
                    ...mainStyle,
                    ...item.primary_customization
                }
            } else if (item.content_metadata.website?.style) {
                mainStyle = {
                    ...mainStyle,
                    ...item.content_metadata.website.style
                }
            } else if (item.content_metadata.presave?.style) {
                mainStyle = {
                    ...mainStyle,
                    ...item.content_metadata.presave.style
                }
            }

            let creationType: WEBSITE_CREATION_TYPE = WEBSITE_CREATION_TYPE.AUTOMATIC
            if (item.content_metadata.publishReason === 'marketing_campaign') {
                creationType = WEBSITE_CREATION_TYPE.CAMPAIGN
            }
            else if (item.content_metadata.presave) {
                creationType = WEBSITE_CREATION_TYPE.PRESAVE
            }

            const websiteUrl = formattedWebsiteUrl(currentBrand, item)
            const linkTo = item.content_metadata.presave && !item.content_metadata.presave.saved_at ? `/marketing/campaigns/${item.campaign_id}` : `/website/${item.type}/${item.slug}`
            return (
                <WebsiteListItem
                    content={item}
                    setActionedItem={setActionedItem}
                    titleText={primaryTitle}
                    thumbnailSrc={thumbnail}
                    subtitle={null}
                    websiteUrl={websiteUrl}
                    linkTo={linkTo}
                    onClick={() => { }}
                    creationType={creationType}
                    websiteType={websiteType}
                    websiteSubtypeLabel={additionalLabel}
                    createdAt={item.createdAt}
                    updatedAt={item.updatedAt}
                    style={{
                        bgType: mainStyle?.bgType,
                        primaryColor: mainStyle?.primaryColor,
                        buttonConfig: mainStyle?.buttonConfig,
                        secondaryColor: mainStyle?.secondaryColor,
                        bgColor: mainStyle?.bgColor,
                    }}
                />
            )
        })

        return (
            <Container>
                {items}
            </Container>)
    }

    const tabsToShow = [
        {
            value: WebsitePageTabs.SITES,
            label: "Websites"
        },
        {
            value: WebsitePageTabs.DASHBOARD,
            label: "Dashboard"
        }
    ]

    if (loadingContent) {
        return (
            <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
                <NewLoader black />
                <p className="mt-1 text-center">Loading Websites...</p>
            </div>
        )
    }

    return (
        <>
            <MainContainer
                flexDirection={"column"}
                padding={mobileView ? "0 0 92px 0" : "32px 80px"}
            >
                <ContentContainer>
                    {showPaymentFailedBanner && (
                        <BannerContainer>
                            <PaymentFailedBanner customerId={currentBrand?.subscription?.cid} />
                        </BannerContainer>
                    )}
                    <div>
                        <CardTitle title="Website"
                            useDefaultPadding={false}
                            titleTextPadding='16px 24px'
                            subtitle="Own your audience and grow with our customizable website builder."
                            mobile={mobileView}
                            additionalContent={
                                <div className="flex flex-col gap-2 w-full">
                                    {mobileView && hasAdminAccess && (
                                        <GeneralPurpleButton
                                            onClick={openCreateSiteModal}
                                            style={{
                                                width: "100%",
                                                padding: "8px 16px"
                                            }}
                                        >
                                            Create New Website
                                        </GeneralPurpleButton>
                                    )}
                                </div>
                            }
                        />
                    </div>
                    <div>
                        <TabsContainer
                            borderRadius={mobileView ? "0px" : "8px"}
                            padding="16px 0px 24px"
                            background="transparent"
                        >
                            <StyledNavBar justifyContent="space-between">
                                <SymphonyTabs
                                    selectedValue={selectedTab!}
                                    onChange={(value) => setSelectedTab(value as WebsitePageTabs)}
                                    data={tabsToShow}
                                    tabContentStyle={{ paddingTop: 0, paddingBottom: 0 }}
                                />
                                {!mobileView && hasAdminAccess && (
                                    <GeneralPurpleButton
                                        onClick={openCreateSiteModal}
                                        style={{
                                            minWidth: "fit-content",
                                            padding: "8px 16px"
                                        }}
                                    >
                                        Create New Website
                                    </GeneralPurpleButton>
                                )}
                            </StyledNavBar>
                            <TabsPanelsContainer>
                                <TabPanel hidden={selectedTab !== WebsitePageTabs.SITES}>
                                    <Container backgroundColor="#FFFFFF" padding="16px 24px" borderRadius="8px">
                                        {renderWebsiteItems()}
                                    </Container>
                                </TabPanel>
                                <TabPanel hidden={selectedTab !== WebsitePageTabs.DASHBOARD}>
                                    <Container>
                                        <OverallWebsiteDashboard />
                                    </Container>
                                </TabPanel>
                            </TabsPanelsContainer>
                        </TabsContainer>
                    </div>
                </ContentContainer>
            </MainContainer>
            <CreateNewSiteModal
                open={openCreateModal}
                closeModal={closeCreateSiteModal}
            />
        </>
    )
};
export default WebsitesList;
