import { makeStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => ({
  switchButton: {
    '& .MuiSwitch-switchBase': {
      '& .MuiSwitch-thumb': {
        backgroundColor: "#FFFFFF",
      },
      '& + .MuiSwitch-track': {
        backgroundColor: "#E5E7EB",
        opacity: 1,
      },
      '&.Mui-disabled': {
        cursor: 'not-allowed',
        '& .MuiSwitch-thumb': {
          backgroundColor: "#AFAFAF",
        },
        '& + .MuiSwitch-track ': {
          backgroundColor: "#E5E7EB !important",
          opacity: 1,
        },
      },
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          backgroundColor: "#E5E7EB",
        },

        '& .MuiSwitch-thumb': {
          backgroundColor: "#8800FF",
        },
      },
    }
  }
}));
