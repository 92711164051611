import React, { useState, useEffect } from 'react';
import Axios from 'helpers/Interceptor';
import {
    ResponsiveContainer, AreaChart, CartesianGrid, XAxis,
    YAxis, Tooltip, Area, TooltipProps,
} from 'recharts';
import millify from 'millify';
import dayjs from 'dayjs';
import { isAdmin } from 'helpers/admin';
import { Redirect } from 'react-router-dom';
import { UserData } from 'types/global';
import Loaderv2 from 'components/Loader/v2';
import { Container, OutlinedButton, StyledLabel } from 'styles/shared';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';
import { ArrowDownward, ArrowDropDown, ArrowUpward } from '@material-ui/icons';
import SymphonyTabs from 'components/shareable/SymphonyTabs';
import { DataGrid } from '@mui/x-data-grid';
import { DownloadOutlined } from '@mui/icons-material';
import { Headline3 } from 'components/shareable/Typography';



interface CustomTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
    active?: boolean;
    payload?: any[];
    label?: string;
}
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="p-2 bg-white shadow-lg border border-gray-300 rounded">
                <p className="mb-2 font-bold">{label}</p>
                {payload.map((entry, index) => (
                    <p key={`payload-${index}`} className="mb-1 text-gray-700">
                        {
                            entry.dataKey === 'brandsCreated' ? 'Artists Created' :
                                entry.dataKey === 'trialsStarted' ? 'Trials Started' :
                                    entry.dataKey === 'successfulPayments' ? 'Successful Payments' : ''
                        }: {entry.value.toLocaleString('en')}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const columns = [
    { field: 'name', headerName: 'Name', width: 200, sortable: true },
    { field: 'email', headerName: 'Email', width: 250, sortable: true },
    { field: 'signupDate', headerName: 'Signup Date', width: 130, sortable: true },
    { field: 'subscriptionStatus', headerName: 'Current Status', width: 160, sortable: true },
    { field: 'daysUntilFirstCampaign', headerName: 'Days Until First Campaign', width: 220, sortable: true, type: 'number' },
    { field: 'conversionsEnabled', headerName: 'User Enabled Conversions', width: 180, sortable: true },

    { field: 'totalSpend', headerName: 'Total Spend', width: 130, sortable: true, type: 'number' },
    { field: 'nonDraftCampaigns', headerName: 'Non-Draft Campaigns', width: 200, sortable: true, type: 'number' },
    { field: 'draftCampaigns', headerName: 'Draft Campaigns', width: 180, sortable: true, type: 'number' },
];


function CustomerDataGrid({ customerDetails }: { customerDetails: CustomerDetail[] }) {
    // Sort customer details by draftCampaigns in descending order, then by nonDraftCampaigns in descending order
    const sortedDetails = [...customerDetails]
        .sort((a, b) => {
            if (b.draftCampaigns !== a.draftCampaigns) {
                return b.draftCampaigns - a.draftCampaigns;
            }
            return b.nonDraftCampaigns - a.nonDraftCampaigns;
        })

    const rows = sortedDetails.map((customer, index) => ({
        ...customer,
        id: customer.brandId, // Since brandId may not be unique, using index as a unique identifier

        signupDate: new Date(customer.signupDate).toLocaleDateString(), // Format signupDate for display
    }));

    return (
        <div style={{ height: 500, width: '80%', maxWidth: '800px' }}> {/* Adjusted the max-width here */}
            <DataGrid

                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50]}
                checkboxSelection
                disableSelectionOnClick
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'draftCampaigns', sort: 'desc' }],
                    },
                }}
            />
        </div>
    );
}


interface CustomerDetail {
    subscriptionStatus: string;
    trialStartDate: string | null;
    trialStatus: string | null;
    upgradedAt: string | null;
    cancelledAt: string | null;
    cancellationReason: string | null;
    signupDate: string;
    id: string | null;
    name: string;
    email: string;
    totalCampaigns: number;
    draftCampaigns: number;
    nonDraftCampaigns: number;
    totalSpend: number;
    daysUntilFirstCampaign: number | null;
    brandId: number;
    // Additional fields can be added as needed
}


interface WeeklyBreakdownType {
    week: string;
    brandsCreated: number;
    trialsStarted: number;
    failedPayments: number;
    successfulPayments: number;
    totalRevenue: number;
    churnedUsers: number;

}

interface KpiDataType {
    topLevelMetrics: {
        totalSignups: number;
        users: {
            paid: number;
            trialing: number;
            free: number;
            overall: number;
        };
        trialConversion: {
            started: number;
            canceled: number;
            trialing: number;
            converted: number;
            payment_failure_cancellation: number;
        };
        payments: {
            successful: number;
            gross_revenue: number;
        };
        campaigns: {
            totalCampaigns: number;
            nonDraftCampaigns: number;
            draftCampaigns: number;
            totalSpend: number;
        }
    };
    customerDetails?: CustomerDetail[]; // This line adds the customerDetails to your existing KpiDataType
    weeklyMetrics?: WeeklyBreakdownType[];
}

interface PartnerType {
    discountAmount: number;
    partnerId: string;
    partnerName: string;
    cookieExpirationFromSignup: number;
    startDate: string;
}


const AffiliateTrends = ({
    user
}: {
    user: UserData
}) => {

    const userIsAdmin = user ? isAdmin(user) : false;
    const [loading, setLoading] = useState(true);

    const [loadingCSV, setLoadingCSV] = useState(false);
    const [kpiData, setKpiData] = useState<KpiDataType | null>(null);

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [tableData, setTableData] = useState<WeeklyBreakdownType[]>([]);


    const [partners, setPartners] = useState<PartnerType[]>([]);
    const [selectedPartner, setSelectedPartner] = useState<PartnerType | null>(null);
    const [loadingPartners, setLoadingPartners] = useState(true);



    const fetchPartners = async () => {
        setLoadingPartners(true);
        try {
            const response = await Axios.get('/admin/get-affiliate-partner-keys');
            setPartners(response.data.data.partners);
            setSelectedPartner(response.data.data.partners[0]);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingPartners(false);
        }
    };



    const handlePartnerChange = async () => {
        const partnerId = selectedPartner?.partnerId;
        try {
            const response = await Axios.post('/admin/get-affiliate-partner-snapshot', { partnerId });
            // Assuming you need to do something with the response here
            const snapshot = response.data.data.snapshot
            setKpiData({
                topLevelMetrics: snapshot
            })

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchPartners();
    }, []);

    useEffect(() => {
        if (selectedPartner) {
            // Do something with the selected partner here
            handlePartnerChange()
        }
    }, [selectedPartner]);

    // called when the material UI select box changes selected value
    function onPartnerSelectionChange(event: React.ChangeEvent<{ value: unknown }>) {
        const partnerId = event.target.value as string;
        const partner = partners.find((partner) => partner.partnerId === partnerId);
        if (partner) {
            setSelectedPartner(partner);
        }
    }


    // const fetchKPIData = async () => {
    //     try {
    //         const response = await Axios.get('/admin/get-affiliate-stats'); // Update with your actual API endpoint
    //         setKpiData(response.data);


    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setLoading(false);

    //     }
    // };
    // useEffect(() => {


    //     fetchKPIData();
    // }, []);



    // useEffect(() => {
    //     if (kpiData) {
    //         // Since kpiData.weeklyBreakdown is an array, we set tableData to that.
    //         setTableData(kpiData.weeklyMetrics);
    //     }
    // }, [kpiData]);

    const MetricsDisplay: React.FC<{
        stats: {
            name: string | JSX.Element;
            value: number;
            format?: 'default' | 'currency' | 'percent';
        }[];
        title?: string;
    }> = ({ stats, title }) => {
        return (
            <div className="flex-col w-full max-w-3xl p-6 mt-4 bg-white rounded shadow-lg flex justify-between items-center">
                {title && (
                    <StyledLabel className="mb-4" fontSize={20} padding="0px auto 20px">
                        {title}
                    </StyledLabel>
                )}
                <div className="flex flex-row justify-evenly w-full">
                    {stats.map((stat, index) => (
                        <div
                            className="flex flex-col justify-between items-center text-center"
                            style={{ maxWidth: '150px' }}
                            key={index}
                        >
                            <span className="text-xl font-bold">
                                {stat.format === 'currency' ? (
                                    stat.value.toLocaleString('en', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })
                                ) : stat.format === 'percent' ? (
                                    `${stat.value.toFixed(1)}%`
                                ) : (
                                    stat.value
                                )}
                            </span>
                            <span className="text-sm text-gray-600">{stat.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (!userIsAdmin) {
        return <Redirect to="/dashboard" />;
    }

    if (loadingPartners) {
        return <Loaderv2 />;
    }

    function getPercentageValue(value: number, total: number) {
        return `${((value / total) * 100).toFixed(1)}%`

    }

    async function downloadBrandsList() {
        if (selectedPartner) {
            setLoadingCSV(true);

            try {
                const response = await Axios.post('/admin/get-affiliate-user-csv', { partnerId: selectedPartner?.partnerId },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        responseType: 'blob', // Expecting a blob response for CSV file download
                        params: {
                            csv: 1, // Including the csv query parameter
                        },

                    });

                // Assuming CSV download by default, trigger file download
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${selectedPartner?.partnerId} Brands (${dayjs().format('MM/DD')}).csv`); // or any other filename
                document.body.appendChild(link);
                link.click();

            } catch (error) {
                console.error(error);
            } finally {
                setLoadingCSV(false);
            }
        }
    }


    return (

        <div className="flex flex-col items-center justify-center p-4 space-y-4">
            <div className="w-full max-w-2xl p-4 mb-4 bg-white rounded shadow-lg text-center">
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="partner-select">Select a partner</InputLabel>

                    <Select

                        labelId='partner-select'
                        value={selectedPartner?.partnerName}
                        onChange={onPartnerSelectionChange}
                        fullWidth
                        label="Select a partner"
                    >
                        {partners.map((partner) => (
                            <MenuItem key={partner.partnerId} value={partner.partnerId}>
                                {partner.partnerName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            {selectedPartner && (
                <>
                    <div className="w-full max-w-3xl p-4 mb-4 bg-white rounded shadow-lg text-center">
                        <h1 className="text-2xl font-semibold">{selectedPartner.partnerName} x SymphonyOS</h1>
                        <p className="text-lg font-light mt-2">Affiliate Reporting Dashboard</p>
                    </div>
                    {loading && !kpiData ? (
                        <Loaderv2 />
                    ) : kpiData && (
                        <>

                            <MetricsDisplay
                                title="Latest Snapshot"
                                stats={[
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total User Signups</p>
                                                <p>100% of the Funnel</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.totalSignups,
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total Artists Created</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.users.overall, kpiData.topLevelMetrics.totalSignups)} of Signups Made an Artist</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.users.overall,
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total Pro Users</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.users.paid, kpiData.topLevelMetrics.totalSignups)} of Signups are Pro</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.users.paid,
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total Users Trialing</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.users.trialing, kpiData.topLevelMetrics.totalSignups)} of Signups are Pro</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.users.trialing,
                                    },

                                ]}
                            />





                            <MetricsDisplay
                                title="Trial Conversion Metrics"
                                stats={[
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total Trials Started</p>
                                                <p>100% of the Funnel</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.trialConversion.started
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Total Converted Users</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.trialConversion.converted, kpiData.topLevelMetrics.trialConversion.started)} of trial starts converted to Pro</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.trialConversion.converted
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Churned trials due to user cancelling</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.trialConversion.canceled, kpiData.topLevelMetrics.trialConversion.started)} of trials cancelled</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.trialConversion.canceled
                                    },
                                    {
                                        name: (
                                            <div>
                                                <p className='font-semibold'>Churned trials due to payment failure</p>
                                                <p>{getPercentageValue(kpiData.topLevelMetrics.trialConversion.payment_failure_cancellation, kpiData.topLevelMetrics.trialConversion.started)} of trials cancelled</p>
                                            </div>
                                        ),
                                        value: kpiData.topLevelMetrics.trialConversion.payment_failure_cancellation
                                    },
                                ]}
                            />

                            <MetricsDisplay
                                title="Successful Payment Metrics"
                                stats={[
                                    {
                                        name: "Number of successful payments",
                                        value: kpiData.topLevelMetrics.payments.successful
                                    },
                                    {
                                        name: "Gross Revenue from Partner",
                                        value: (kpiData.topLevelMetrics.payments.gross_revenue),
                                        format: 'currency'
                                    },
                                ]}
                            />

                            <Container display='flex' flexDirection='column' gap="8px">
                                <Headline3>Download a list of users with metadata</Headline3>
                                <OutlinedButton onClick={downloadBrandsList}
                                    disabled={loadingCSV}>
                                    {!loadingCSV ? (
                                        <>
                                            <DownloadOutlined></DownloadOutlined>
                                            Download Brands CSV
                                        </>
                                    ) : (
                                        <>
                                            <Loaderv2 height={18} width={18}/>
                                        </>
                                    )}

                                </OutlinedButton>

                            </Container>
                            {/* <MetricsDisplay
                                title="Campaign Metrics"
                                stats={[
                                    {
                                        name: "Non-Draft Campaigns",
                                        value: kpiData.topLevelMetrics.campaigns.nonDraftCampaigns,
                                    },
                                    {
                                        name: "Total Spend on Campaigns",
                                        value: kpiData.topLevelMetrics.campaigns.totalSpend,
                                        format: 'currency'
                                    },
                                    {
                                        name: "Average Spend per Campaign",
                                        value: kpiData.topLevelMetrics.campaigns.totalSpend / kpiData.topLevelMetrics.campaigns.totalCampaigns,
                                        format: 'currency'
                                    }
                                ]}
                            />

                        */}

                            {/* <CustomerDataGrid customerDetails={kpiData.customerDetails} /> */}


                        </>
                    )
                    }
                </>)}
        </div>
    );
};

export default AffiliateTrends;
