import { Fragment, FunctionComponent } from "react"
import { SystemColors } from "types/globalStyles"
import PrimaryButton from "components/shareable/PrimaryButton"
import { Body2, Headline1 } from "components/shareable/Typography"
import { ReactComponent as SymphonyLogo } from "assets/images/symphonyLogo.svg"
import LeftArrowIcon from "assets/images/arrow-left.png"
import useStyles from "../../styles"
import {
  Container,
  ContinueButtonContainer,
  GoBackButton,
  GoBackContainer,
  LogoContainer,
  StyledIcon,
  TitleContainer,
} from "./styles"
import SetupPlan from "../../Views/SetupPlan"
import { subtitles, titles } from "../../data"

enum Steps {
  SETUP_PLAN = "SETUP_PLAN",
  PAYMENTS_DETAILS = "PAYMENTS_DETAILS",
  WELCOME = "WELCOME",
}

interface Props {
  step: Steps
  onGoBack: () => void
}

const PlanCard: FunctionComponent<Props> = ({ step, onGoBack }: Props) => {
  const classes = useStyles()

  return (
    <Container>
      <LogoContainer>
        <SymphonyLogo className={classes.logo} />
      </LogoContainer>
      <GoBackContainer>
        <GoBackButton onClick={onGoBack}>
          <StyledIcon alt="left arrow icon" src={LeftArrowIcon} />
        </GoBackButton>
        <Body2>{subtitles[step]}</Body2>
      </GoBackContainer>
      <TitleContainer>
        <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
          {titles[step]}
        </Headline1>
      </TitleContainer>
      {/* TODO: Add new views depending on step */}
      {step === Steps.SETUP_PLAN && <SetupPlan />}
      {step === Steps.PAYMENTS_DETAILS && <Fragment />}
      {step === Steps.WELCOME && <Fragment />}
      <ContinueButtonContainer>
        <PrimaryButton
          width="100%"
          text="Continue"
          onClick={() => {
            //TODO: Add this flow in the next PR
            console.log("go to next view")
          }}
        />
      </ContinueButtonContainer>
    </Container>
  )
}

export default PlanCard
