import Logger from 'Logger';
import Axios from 'helpers/Interceptor'
import { WebsiteType } from "pages/post-auth/Website/types";
import { RecordSelectorType } from 'types/global';
import * as Sentry from "@sentry/react";

export type PublishBrandContent = {
  type: WebsiteType,
  content_metadata: any,
  brand_id: number,
  slug: string,
  name?: string,
  url: string,
  id?: number
}

export const publishBrandContent = async (brandContent: PublishBrandContent) => {
  try {
    const response = await Axios.post('/brand-content/publish/website', {
      brandContent,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateBrandContentExternalLinks = async (
  brandSlug: string, recordSlug: string, links: { serviceName: string, url: string }
) => {
  try {
    const results = await Axios.post(`/brand-content/${brandSlug}/${recordSlug}/link`, links)

    return results.data
  } catch (error) {
    throw error
  }
}

export const searchSpotifyByText = async (value: string, limit: number = 10) => {
  try {
    const url = "spotify/search/music";
    const encodedValue = encodeURIComponent(value)
    const options = { params: { q: encodedValue, limit: limit } };
    const response = await Axios.get(url, options);
    if (response?.data) return response.data
  } catch (e: unknown) {
    console.error("Error on searchSpotifyByText component: ", e);
  }
}

export const fetchMarkets = async (contentSlug: string) => {
  try {
    const response = await Axios.post(`/brand-content/available-markets/${contentSlug}`);
    if (!response?.data?.data) return [];
    return response.data.data;
  } catch (error) {
    Logger.error(error);
    console.error("Error on fetchMarkets component: ", error);
  }
};


export async function getRecordDetails(brandId: string | number, spotifyId: string, recordType: RecordSelectorType) {
  Sentry.setContext('getRecordDetails', { brandId: brandId, spotifyId: 'spotifyId' });

  try {
    const response = await Axios.get(`/brand-content/${brandId}/track/${spotifyId}`, {
      params: {
        type: recordType
      }
    });

    const data = response?.data?.data;

    if (data) {
      const { content_metadata, slug, name, url } = data;
      const spotifyReleaseType = content_metadata.type === "record"
        ? 'Single'
        : (content_metadata.spotify_release_type || content_metadata.type || 'Release');

      return {
        full_info: true,
        ...content_metadata,
        slug,
        name,
        url,
        thumbnail_url: content_metadata.thumbnail_url,
        spotify_release_type: spotifyReleaseType
      };
    }
  } catch (error) {
    Logger.error(error);
    console.error("Error on getRecordDetails component: ", error);
  }

}