/* eslint-disable react-hooks/exhaustive-deps */
// Modified code from https://github.com/ndom91/react-timezone-select
import { FunctionComponent } from "react";
import Select from "react-select";
import useTimeZoneParser from "./Hooks/useTimezoneParser";
import type {
  Props,
  ITimezoneOption,
  ChangeTzHandler,
} from "./types/timezone";

const TimezoneSelect: FunctionComponent<Props> = ({
  value,
  onBlur,
  onChange,
  labelStyle = "original",
  ...props
}: Props) => {
  const { getOptions, parseTimezone } = useTimeZoneParser(labelStyle);

  const handleChange = (tz: ITimezoneOption) => {
    onChange && onChange(tz);
  };

  return (
    <Select
      value={parseTimezone(value)}
      onChange={handleChange as ChangeTzHandler}
      options={getOptions}
      onBlur={onBlur}
      {...props}
    />
  );
};

export default TimezoneSelect;
