import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

const TwitterIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    const twColor = iconColor ?? "black";
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => {onClick && onClick()}}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M22.8808 17.587L32.1892 7H29.9842L21.8983 16.1907L15.445 7H8L17.7608 20.8993L8 32H10.205L18.7383 22.2923L25.555 32H33M11.0008 8.62698H14.3883L29.9825 30.453H26.5942" fill={twColor} />

            </svg>
        </IconHolder>
    );
};

export default TwitterIcon;