import { FunctionComponent } from "react";
import { BackgroundBarContainer, Bar, useStyles } from "./styles";
import { SystemColors } from "types/globalStyles";

interface Props {
  progress: number;
  maximumProgress: number;
  squared?: boolean;
  fullWidth?: boolean;
  barColor?: string;
  backgroundColor?: string;
}

const ProgressBar: FunctionComponent<Props> = ({
  progress,
  maximumProgress,
  squared,
  fullWidth,
  barColor,
  backgroundColor,
}: Props) => {
  const classes = useStyles();
  const width = (progress / maximumProgress) * 100;
  return (
    <BackgroundBarContainer
      width={fullWidth ? "100%" : "72px"}
      borderRadius={squared ? 0 : 24}
      background={backgroundColor || SystemColors.PAPER_DIVIDER_CONTAINER}
    >
      <Bar width={width} background={barColor || SystemColors.ACCENT_COLOR} />
    </BackgroundBarContainer>
  );
};

export default ProgressBar;