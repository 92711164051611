
export default function Indicator(props: {
    selected?: boolean | undefined
}) {
    const {
        selected
    } = props

    return (<div className={`hidden lg:block 
    w-1.5 h-4/6 bg-primary abs olute left-0 rounded-r-md transition-opacity	
    ${selected ? 'opacity-1' : 'opacity-0'}`}>

    </div>
    )
}