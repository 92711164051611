export const GOAL = "increase_link_clicks"
export const mainTitle = "Increase Link Clicks"
export const NEXT = "Next"
export const CONTINUE = "Continue"
export const REVIEW_YOUR_CAMPAIGN = "Review your campaign"
export const CREATING_CAMPAIGN = "Creating campaign..."
export const CAMPAIGN_CREATED = "Campaign created!"
export const SAVE = "Save"
export const REVIEW = "Review"
export const CONNECT = "Connect"
export const DEFAULT_TAB = 'overall';
export const TABS = [
  { value: 'overall', label: 'Overall' },
  { value: 'demographics', label: 'Demographics' },
  { value: 'markets', label: 'Markets' },
  { value: 'daily_stats', label: 'Daily stats' },
];
