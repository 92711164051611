import { Body2, Caption } from "components/shareable/Typography"
import { CityCount, GeographicBreakdown } from "../../../WebsiteDashboardv2/types"
import ReactCountryFlag from "react-country-flag"
import countryList from "react-select-country-list"
import { SystemColors } from "types/globalStyles"
import SymphonyBarChart from "components/shareable/DATA_PRESENTATION/Charts/BarChart"


interface Props {
  metrics: GeographicBreakdown,
  areaType: 'countries' | 'cities'
}

export function TopGeographiesBarChart({ metrics, areaType }: Props) {
  if (!metrics) return null

  const area = areaType === 'countries' ? metrics.countries : metrics.cities
  const sortedBreakdown = area.sort((a, b) => b.count - a.count).slice(0, 5)

  if (!sortedBreakdown.length) {
    return (
      <div className="w-full flex justify-center py-2">
        <Body2>No data found</Body2>
      </div>
    )
  }

  const getTickContent = (value: string) => {
    const item = sortedBreakdown.find((item) => {
      const areaValue = areaType === 'countries' ? item.country : (item as CityCount).city
      return areaValue === value
    })

    const visits = item?.count || 0
    const countryCode = countryList().getValue(item?.country!)

    return (
      <div className='flex flex-col justify-center items-center'>
        <ReactCountryFlag countryCode={countryCode} width='fit-content' className="emojiFlag"/>
        <Body2
          color={SystemColors.PRIMARY_TEXT_COLOR}
          style={{ width: 'fit-content', textAlign: 'center' }}
        >
          {value}
        </Body2>
        <Caption
          color={SystemColors.SECONDARY_TEXT_COLOR}
          style={{ width: 'fit-content' }}
        >
          {visits} View{visits > 1 && 's'}
        </Caption>
      </div>
    )
  }

  return (
    <SymphonyBarChart
      xAxisKey="name"
      yAxisKey="value"
      direction="vertical"
      tickHeight={70}
      tickWidth={90}
      barWidth={90}
      tick={getTickContent}
      data={sortedBreakdown.map((item) => {
        return {
          name: areaType === 'countries' ? item.country : (item as CityCount).city,
          value: item.count
        }
      })}
    />
  )
}
