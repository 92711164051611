import {
  CurrentBrand,
  DuplicateSongAdsRecord as IncreaseMusicStreamsRecord,
} from "types/global"
import { CAMPAIGN_TYPES, PLATFORMS } from "../../../../../constants"

const { apple_music, deezer, sound_cloud, spotify, youtube } = PLATFORMS
const orderedPlatforms = [apple_music, deezer, sound_cloud, spotify, youtube]

export enum DuplicateSteps {
  SELECT_DUPLICATE_OPTION = 1,
  REVIEW = 2,
  CHOOSE_ANOTHER_PROJECT = 3,
  CHOOSE_ANOTHER_LINK = 4,
}

export type ReviewViewData = {
  brandName?: string
  recordName?: string
  thumbnailUrl?: string
  genres?: string
  platforms?: string[]
  recordType?: string
  campaignName?: string
  campaignType?: string
}

export const getReviewInformation = (
  campaignMetadata: unknown,
  recordSelected: IncreaseMusicStreamsRecord | null
): ReviewViewData => {
  const metadata = campaignMetadata as {
    campaign_type: string
    content: {
      name: string
      thumbnail_url: string
      link: string
    }
    campaign_state: {
      brand: CurrentBrand
      record: {
        external_links: { url: string; name: string }[]
        spotify_release_type: string
      }
    }
  }
  const campaignType = metadata.campaign_type

  if (recordSelected) {
    const platforms: string[] = []
    const externalLinks = recordSelected.external_links
    const genres =
      metadata.campaign_state?.brand?.connections?.spotify_artist_page?.genres.join(
        ", "
      )

    if (externalLinks) {
      for (const { name } of externalLinks) {
        const found = orderedPlatforms.includes(name)
        if (found) platforms.push(name)
      }
    }

    return {
      brandName: recordSelected.primary_artist || "",
      recordName: recordSelected.name || "",
      campaignName: metadata.content.name || "",
      genres: genres || "",
      recordType: recordSelected.spotify_release_type,
      platforms,
      thumbnailUrl: recordSelected.thumbnail_url,
    }
  }

  switch (campaignType) {
    case CAMPAIGN_TYPES.record_streams:
      const platforms: string[] = []
      const campaignName = metadata.content?.name
      const brandName = metadata.campaign_state?.brand?.name || ""
      const recordName = metadata.content?.name
      const thumbnailUrl = metadata.content?.thumbnail_url
      const recordType = metadata.campaign_state?.record?.spotify_release_type;
      const genres =
        metadata.campaign_state?.brand?.connections?.spotify_artist_page?.genres.join(
          ", "
        )
      const links = metadata.campaign_state?.record?.external_links

      for (const { name } of links) {
        const found = orderedPlatforms.includes(name)
        if (found) platforms.push(name)
      }

      return {
        genres: genres || "",
        platforms: platforms || [],
        recordName: recordName || "",
        thumbnailUrl: thumbnailUrl || "",
        brandName: brandName || "",
        campaignName: campaignName || "",
        recordType: recordType || "",
      }
    case CAMPAIGN_TYPES.link_clicks:
    return {
      genres: "",
      platforms: [],
      recordName: "",
      thumbnailUrl: "",
      brandName: "",
      campaignName: metadata.content?.link,
      recordType: "link",
    }
    case CAMPAIGN_TYPES.boost_instagram:
    return {
      genres: "",
      platforms: [],
      recordName: "",
      thumbnailUrl: "",
      brandName: "",
      campaignName: "",
      recordType: "post",
    }
    default:
      return {
        genres: "",
        platforms: [],
        recordName: "",
        thumbnailUrl: "",
        brandName: "",
        campaignName: "",
        recordType: "",
      }
  }
}


