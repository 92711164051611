import { makeStyles, Tab, Tabs, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import InfoSection from 'components/InfoSection';
import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import CloseButton from '../CloseButton';
import { IGReelsPreviewActionArea } from './InstagramReels';
import { IGStoryPreviewActionArea } from './InstagramStory';
import { CommentIcon, EmptyImage, HeartIcon, IGAdArtistName, ShareIcon, StoryParentDiv } from './shared';
import { ReactComponent as CommentsIcon } from "assets/images/campaignDetails/chat.svg"
import { ReactComponent as LikesIcon } from "assets/images/campaignDetails/heart.svg"
import { ReactComponent as SharesIcon } from "assets/images/campaignDetails/paperplane.svg"
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { PostContainer, VideoButton } from './styles';
import VideoAsImageBanner from './VideoAsImageBanner';

export interface SelectedPost {
    postType: 'instagram' | 'uploaded',
    caption: string;
    media_url?: string;
    media_type: string;
    thumbnail_url?: string;
    type: "story" | "feed" | "reels";
}

interface SelectedCaption {
    type: string;
    // 'all' | 'spotify' | 'apple_music' | 'youtube' | 'custom';
    caption: string;
}

interface AdPreviewBoxProps {
    selectedPosts?: SelectedPost[] | null,
    previewedPost?: SelectedPost | null,
    instagramAccount?: {
        profile_pic: string;
        username: string;
    },
    facebookPage?: any,
    shown?: boolean;
    captions?: SelectedCaption[];
    closePreview?: () => void;
    callToAction: string;
    simplePreview?: "story" | "feed" | "reels";
    muted?: boolean;
}


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        minWidth: 80
    },
    textSmall: {
        fontSize: "50%",
        lineHeight: "initial"
    },
}));

const verticalTabStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 45,
        minWidth: 45,
        background: 'white'
    }
}));

const getUrlWithCodecFormat = (url: string) => {
    const isCloudinary = url.includes('res.cloudinary.com');
    if (!isCloudinary) return url;
    const [cloudinaryPath, videoPath] = url.split('/video/');
    if (!videoPath) return url;
    const [delivery_type, ...rest] = videoPath.split('/');
    const restPath = rest.join('/');
    return `${cloudinaryPath}/video/${delivery_type}/vc_h264/${restPath}`;
}

const getBestAspectRatioForInstagramFeed = (width: number, height: number) => {
    const aspectRatio = width / height;
    const closestAspectRatio = [1, 1.91, 0.8].reduce((prev, curr) =>
        Math.abs(curr - aspectRatio) < Math.abs(prev - aspectRatio) ? curr : prev
    );
    return closestAspectRatio;
}


export default function AdPreviewBox(props: AdPreviewBoxProps) {
    const {
        selectedPosts,
        previewedPost,
        instagramAccount,
        facebookPage,
        shown,
        captions,
        closePreview,
        callToAction,
        simplePreview,
        muted = true,
    } = props

    const classes = useStyles();

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    const video = useRef<HTMLVideoElement>(null);
    const verticalTabClasses = verticalTabStyles();

    // logic:
    //  - if user selected an InstagramPost, show feed + story + reels
    //  - if a user uploaded multiple videos:
    //      - if both videos are feed, then show the selector and default to feed
    //      - if videos are mixed feed + story, show selector
    //      - if videos are all feed, show selector and show all format types
    //      - if videos are all story, hide feed and show selector


    // defines which video to show for FEED or STORY & REELS
    const [shownOptions, setShownOptions] = useState<{
        feed: SelectedPost | null,
        story: SelectedPost | null
    }>({
        feed: null,
        story: null
    })

    // defines which tab is selected for the preview
    const [tabValue, setTabValue] = useState<"story" | "feed" | "reels">(simplePreview || "story")


    // defines which caption is shown
    const [selectedCaption, setSelectedCaption] = useState<SelectedCaption | undefined | null>(captions && captions.length > 0 ? captions[0] : null)
    const [isVideoPaused, setIsVideoPaused] = useState<Boolean>(true)
    const [videoSize, setVideoSize] = useState<{ width: number, height: number } | null>(null)
    const [bestAspectRatioFeed, setBestAspectRatioFeed] = useState<string>("auto")
    const [showVideoAsImageBanner, setShowVideoAsImageBanner] = useState<boolean>(false)

    const handleOnPauseUnpauseVideo = () => {
        if (video?.current) {
            isVideoPaused ? video.current.play() : video.current.pause()
            setIsVideoPaused(prev => !prev)
        }
    }

    useEffect(() => {
        if (captions && captions.length > 0) {
            setSelectedCaption(captions[0])
        } else {
            setSelectedCaption(null)
        }
    }, [captions])

    // sets the shownOptions based on the selectedPosts
    useEffect(() => {
        if (selectedPosts && selectedPosts.length > 0) {
            if (selectedPosts.length === 1) {
                const mainPost = selectedPosts[0]
                if (mainPost.type === 'story') {
                    setShownOptions({
                        feed: null,
                        story: mainPost,
                    })
                } else {
                    setShownOptions({
                        feed: mainPost,
                        story: mainPost
                    })
                }
                setShowVideoAsImageBanner(mainPost.media_type === 'VIDEO' && !mainPost.media_url)
            } else {
                // if theres more than 1 video:
                //      - if there's 1 feed and 1 story, show the user each post dynamically (ignore preview)
                //      - if there's more than 1 feed or more than 1 story, then the user must select which post to preview
                var storyAssets = selectedPosts.filter((o: SelectedPost) => o.type === 'story')
                var feedAssets = selectedPosts.filter((o: SelectedPost) => o.type === 'feed')


                if (feedAssets.length === 1 && storyAssets.length === 1) {
                    // if theres 1 feed & 1 story,
                    //  automatically preview both based on the selected video
                    setShownOptions({
                        feed: feedAssets[0],
                        story: storyAssets[0]
                    })
                } else if (feedAssets.length === 1 && storyAssets.length === 0) {

                    // if there's only one feed, show the feed video across everyhting
                    setShownOptions({
                        feed: feedAssets[0],
                        story: feedAssets[0]
                    })
                } else if (feedAssets.length === 0 && storyAssets.length === 1) {
                    // if there's only a story asset, show story + reels, and not feed
                    setShownOptions({
                        feed: null,
                        story: storyAssets[0]
                    })

                } else if ((feedAssets.length >= 1 || storyAssets.length >= 1)
                    && previewedPost) {
                    // if theres 1 or more feed assets and 1 or more story assets,
                    // the user's selected videos are mixed, so only show the previewed post
                    setShownOptions({
                        feed: previewedPost.type === 'feed' ? previewedPost : null,
                        story: previewedPost
                    })
                }
            }
        } else {
            setShownOptions({
                feed: null,
                story: null
            })
        }
    }, [selectedPosts])


    useEffect(() => {
        // - if there's a specified previewd post, default the selector
        // - otherwise, use the defaulted shownOptions as the guidance
        //  based on the type of preview
        //  for which preivew to show

        if (simplePreview) return

        if (previewedPost) {
            if (previewedPost.type === 'feed') {
                setTabValue('feed')
            } else if (tabValue !== 'reels') {
                // dont switch to story if the user is already on reels
                setTabValue('story')

            }
        } else if (shownOptions) {
            if (shownOptions.story && tabValue !== 'reels') {
                setTabValue('story')
            }
        }
    }, [previewedPost, shownOptions])

    useEffect(() => {
        if (videoSize) {
            const aspectRatio = getBestAspectRatioForInstagramFeed(videoSize.width, videoSize.height)
            setBestAspectRatioFeed(aspectRatio.toString())
        }
    }, [videoSize])

    const onVideoLoadStart = () => {
        if (video?.current && simplePreview) {
            video.current.volume = 0.5
        }
    }

    const onLoadedMetadata = (videoMetadata: SyntheticEvent<HTMLVideoElement, Event>) => {
        const width = videoMetadata.currentTarget.videoWidth;
        const height = videoMetadata.currentTarget.videoHeight;
        setVideoSize({ width, height })
    }

    let username: string | null = null
    let profilePic: string | null = null

    if (instagramAccount) {
        username = instagramAccount.username
        profilePic = instagramAccount?.profile_pic
    } else if (facebookPage) {
        username = facebookPage.name
        profilePic = facebookPage.picture && facebookPage.picture.data ? facebookPage.picture.data.url : null
    }

    function renderPreviewItem(props: {
        cover: boolean,
        maxHeight?: string,
        type: 'feed' | 'story'
    }) {
        const {
            cover = false,
            maxHeight = null,
            type
        } = props

        let renderItem: JSX.Element | null = (null)
        let displayType: string | undefined = 'image'
        let displayUrl: string | undefined = undefined
        const selectedVideo = shownOptions[type]

        if (selectedVideo) {
            if (selectedVideo.media_type === 'VIDEO') {
                if (selectedVideo.media_url) {
                    displayUrl = getUrlWithCodecFormat(selectedVideo.media_url)
                    displayType = 'video'
                } else {
                    displayUrl = selectedVideo.thumbnail_url
                    displayType = 'image'
                }
            } else {
                displayUrl = selectedVideo.media_url
                displayType = 'image'
            }
        }

        if (displayType && displayUrl) {
            if (displayType === 'video') {
                if (simplePreview)
                    renderItem =
                        <PostContainer
                            borderRadius={type === "feed" ? "0px" : "12px"}
                            position="relative"
                        >
                            <VideoButton
                                onClick={handleOnPauseUnpauseVideo}
                                background={isVideoPaused && "#00000080"}
                                color={isVideoPaused ? "white" : "transparent"}
                                height={type !== "feed" ? "100%" : ""}
                                aspectRatio={type === "feed" ? bestAspectRatioFeed : "9 / 16"}
                            >
                                {isVideoPaused ?
                                    <PlayIcon width="13%" /> :
                                    <PauseIcon width="13%" />
                                }
                            </VideoButton>
                            <video
                                style={{
                                    maxHeight: maxHeight ? maxHeight : 'default',
                                    aspectRatio: type === "feed" ? bestAspectRatioFeed : "9 / 16",
                                    overflow: type === "feed" ? "hidden" : "",
                                    objectFit: type === "feed" ? "cover" : "contain",
                                }}
                                src={displayUrl} playsInline loop
                                autoPlay={!simplePreview}
                                ref={video}
                                muted={muted}
                                onLoadStart={onVideoLoadStart}
                                onLoadedMetadata={onLoadedMetadata}
                            />
                        </PostContainer>
                else renderItem =
                    <PostContainer
                        borderRadius={type === "feed" ? "0px" : "12px"}
                    >
                        <video
                            style={{
                                maxHeight: maxHeight ? maxHeight : 'default',
                                aspectRatio: type === "feed" ? bestAspectRatioFeed : "auto",
                                overflow: "hidden",
                                objectFit: type === "feed" ? "cover" : "contain",
                            }}
                            muted={muted}
                            onLoadStart={onVideoLoadStart}
                            src={displayUrl} autoPlay playsInline loop
                            onLoadedMetadata={onLoadedMetadata}
                        />
                    </PostContainer>
            } else {
                renderItem = (
                    <PostContainer
                        borderRadius={type === "feed" ? "0px" : "12px"}
                    >
                        <img
                            style={{
                                maxHeight: maxHeight ? maxHeight : 'default',
                                aspectRatio: 'auto',
                            }}
                            src={displayUrl}
                            alt="Instagram Post"
                        />
                    </PostContainer>)
            }
        } else {
            // show placeholder
            renderItem = (
                <div
                    className={`
                     overflow-hidden
                    flex flex-col items-center justify-center
                    p-10
                    text-center
                    w-full h-full ${cover ? 'object-cover' : 'object-contain'}
                    ${type === 'story' ? 'rounded-xl' : ''}
                    `}
                    style={
                        simplePreview ?
                            {
                                aspectRatio: "1",
                                padding: "15%",
                                height: type === "feed" ? "20%" : "100%",
                                background: type === "feed" ? "auto" : "gray",
                            } :
                            {
                                backgroundColor: "#EDECF2",
                                height: maxHeight ? maxHeight : '100%',
                                maxHeight: maxHeight ? maxHeight : 'default'
                            }
                    }>
                    {simplePreview ? (
                        <Fragment>
                            <BrokenImageIcon style={setResponsiveView ? { width: "4vw", height: "4vw" } : { width: "1.3vw", height: "1.3vw" }} />
                            <p style={{ fontSize: setResponsiveView ? "1.6vw" : "0.6vw", lineHeight: "initial" }}>
                                It looks like your post has been deleted or updated since your campaign went live, and we’re not able to pull data for it.
                            </p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <EmptyImage />
                            <p className='text-sm'>
                                Upload your ad videos to preview your ad.
                            </p>
                        </Fragment>
                    )}
                </div>)
        }

        return renderItem
    }


    function renderStoryPreview() {


        // if (!shownOptions['story']) {
        //     return (
        //         <StoryParentDiv className="m-auto block relative">
        //             <div className="z-0 h-full w-full  ">
        //                 {renderPreviewItem({
        //                     cover: false,
        //                     type: 'story'
        //                 })}
        //             </div>
        //         </StoryParentDiv>
        //     )
        // }
        return (
            <StoryParentDiv
                className="m-auto block relative"
                simplePreview={Boolean(simplePreview)}
            >
                <div className="z-0 h-full w-full">
                    {renderPreviewItem({
                        cover: true,
                        type: 'story'
                    })}
                </div>

                <div className='z-10 absolute top-0 left-0 right-0 mt-3 px-1 mx-auto w-11/12' style={simplePreview ? { marginTop: "8%" } : {}}>
                    <div className="h-0.5 w-full bg-white rounded-full" style={simplePreview ? { height: "1px" } : {}}></div>
                    {username && profilePic && (
                        <IGAdArtistName
                            showTextShadow={true}
                            imageUrl={profilePic}
                            name={username}
                            simplePreview={Boolean(simplePreview)}
                        />
                    )}
                </div>
                {shownOptions['story'] &&
                    <IGStoryPreviewActionArea
                        callToAction={callToAction}
                        simplePreview={Boolean(simplePreview)}
                    />}
            </StoryParentDiv>
        )
    }

    function renderFeedPreview() {
        return (
            <StoryParentDiv
                className="overflow-scroll m-auto bg-white block relative rounded-xl"
                simplePreview={Boolean(simplePreview)}
                autoheight
            >
                <div className='h-full w-full flex flex-col'>
                    <div className="px-1 mx-auto w-11/12"
                        style={simplePreview ? { padding: "2% 5%", margin: "0px" } : {}}
                    >
                        {username && profilePic && (
                            <IGAdArtistName
                                nameColor="black"
                                imageUrl={profilePic}
                                name={username}
                                simplePreview={Boolean(simplePreview)}
                            />
                        )}
                    </div>
                    <div>
                        <div className='border-t '>
                            {renderPreviewItem({
                                cover: true,
                                type: 'feed',
                            })}
                        </div>
                        {callToAction && (
                            <div className='text-white text-base md:text-xs font-semibold p-2 ' style={simplePreview ? {
                                background: "#00AAFF",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                textAlign: "left",
                                padding: "4% 5.5%",
                                fontSize: setResponsiveView ? "2vw" : "0.8vw",
                                lineHeight: "initial",
                            } : {
                                background: "#00AAFF",
                            }}>
                                {callToAction}
                            </div>
                        )}

                    </div>

                    <div className="py-1 px-3 text-sm" style={simplePreview ? { padding: "0px 5%", maxHeight: setResponsiveView ? "28vw" : "6.5vw" } : {}}>
                        <div className={`${!simplePreview && "my-1.5"} w-full flex justify-between flex-row`}
                            style={simplePreview ? { padding: setResponsiveView ? "2% 0px" : "3.5% 0px" } : {}}
                        >
                            {simplePreview ? (
                                <div className='flex items-center justify-between gap-1'>
                                    <LikesIcon width={setResponsiveView ? "3.3vw" : "1.1vw"} color='black' />
                                    <CommentsIcon width={setResponsiveView ? "3.3vw" : "1.1vw"} color='black' />
                                    <SharesIcon width={setResponsiveView ? "3.3vw" : "1.1vw"} color='black' />
                                </div>
                            ) : (
                                <div className='flex items-center justify-between w-4/12'>
                                    <HeartIcon color="black" />
                                    <CommentIcon color="black" />
                                    <ShareIcon color="black" />
                                </div>
                            )}
                        </div>
                        {username && selectedCaption?.caption && (
                            <p className='break-words'
                                style={simplePreview ? {
                                    fontSize: setResponsiveView ? "2vw" : "0.8vw",
                                    paddingBottom: "5%",
                                    lineHeight: "initial",
                                } : {}
                                }
                            >
                                <span className='font-semibold'>{username}</span> <span>{selectedCaption.caption}</span>
                            </p>)
                        }
                    </div>
                </div>
            </StoryParentDiv>
        )
    }


    function renderReelsPreview() {
        return (
            <StoryParentDiv
                className="m-auto  block relative "
                simplePreview={Boolean(simplePreview)}
            >
                <div className="z-0 absolute top-0 left-0 h-full w-full ">
                    {renderPreviewItem({
                        cover: true,
                        type: 'story'
                    })}
                </div>
                <div className="z-10 absolute top-0 left-0 right-0 mt-3 px-1 mx-auto w-11/12">

                </div>
                {username && profilePic && (
                    <IGReelsPreviewActionArea
                        callToAction={callToAction}
                        imageUrl={profilePic}
                        name={username}
                        simplePreview={Boolean(simplePreview)}
                        caption={selectedCaption ? selectedCaption.caption : undefined} />
                )}

            </StoryParentDiv>
        )
    }

    function renderedPreview() {
        switch (tabValue) {
            case 'story':
                return renderStoryPreview()
                break
            case 'feed':
                return renderFeedPreview()
                break
            case 'reels':
                return renderReelsPreview()
                break
        }
    }

    if (simplePreview) {
        return (
            <div className="flex h-full w-full">
                {renderedPreview()}
            </div>
        );
    }

    return (
        <div className={clsx(
            `m-0  md:ml-4 md:mt-6
            rounded-none md:rounded-xl
            h-full md:h-auto
            w-full md:max-w-sm
            flex flex-col
             bg-white
             overflow-auto md:overflow-hidden`,
            shown ? 'block' : 'hidden')}>
            {closePreview && (
                <div className="flex justify-end pt-2 pr-2">
                    <CloseButton onClick={closePreview} />
                </div>
            )}
            <InfoSection
                className='sy-card px-5 w-full'
                title="Ad Preview"
                description="This is what your ad will look like when it's run on Instagram." />

            <div>
                <Tabs
                    scrollButtons="auto"
                    variant="scrollable"
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                    aria-label="Settings Options Selector"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#8800FF"
                        }
                    }}>


                    {shownOptions.story && (<Tab
                        classes={classes}

                        disableRipple
                        value={"story"}

                        label={<span
                            className={"capitalize font-medium text-base"}>Story</span>} />)}
                    {shownOptions.story && (<Tab
                        classes={classes}

                        disableRipple
                        value={"reels"}
                        label={<span
                            className={"capitalize font-medium text-base "}>Reels</span>} />)}
                    {shownOptions.feed && (<Tab
                        classes={classes}
                        disableRipple
                        value={"feed"}
                        label={<span
                            className={"capitalize font-medium text-base"}>Feed</span>} />)}
                </Tabs>

                {showVideoAsImageBanner &&
                    <VideoAsImageBanner />
                }
            </div>
            <div className="h-full md:h-auto bg-gray-300 p-8 relative flex gap-4 flex-col">
                {renderedPreview()}
                {/* {previewedPost && captions && captions.length > 1 && <div className='absolute right-0 top-8 rounded-bl-lg rounded-tl-lg overflow-hidden'>
                    <Tabs
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#8800FF"
                            }
                        }}
                        classes={verticalTabClasses}
                        value={selectedCaption ? selectedCaption : null}
                        onChange={(event, newValue) => setSelectedCaption(newValue)}
                        orientation="vertical"
                        aria-label="icon tabs example"
                    >
                        {captions.map((caption: SelectedCaption, index: number) => {
                            var icon = <SpotifyIcon />
                            switch (caption.type) {
                                case 'spotify':
                                    icon = <SpotifyIcon />
                                    break
                                case 'apple_music':
                                    icon = <AppleIcon />
                                    break
                                case 'youtube':
                                    icon = <YoutubeIcon />
                                    break
                                case 'all':
                                    icon = <LinkIcon />
                                    break

                            }
                            return (
                                <Tab
                                    classes={verticalTabClasses}
                                    value={caption.type}
                                    icon={icon} aria-label={caption.type} />
                            )
                        })}

                    </Tabs>
                </div>} */}
            </div>
        </ div>
    )
}