import React, { useContext } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import SubscriptionStatusIndicator from "./Navbar/Components/SubscriptionStatusIndicator";
import { Container, StyledLabel } from "styles/shared";

import { CurrentBrand } from "types/global";
import { checkIfIsProTier } from "utils";
import GemIcon from "./shareable/ProCallouts/GemIcon";

interface MobileHeaderProps {
  currentBrand: CurrentBrand,
  loadedBrands: CurrentBrand[],
  setCurrentBrand: (brand: CurrentBrand) => void,
}
const MobileHeader = (props: MobileHeaderProps) => {

  const { currentBrand, setCurrentBrand, loadedBrands } = props;

  const isProUser = checkIfIsProTier(currentBrand);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      
      className="flex gap-2 flex-col lg:hidden dash-header">
      <div className="sy-card
      rounded-md
      bg-black
      bg-opacity-25 border-0 m-0 border-primary py-2">
        <Container
          display="flex"
          alignItems="center">

          <div className="artist-img">
            <img

              src={currentBrand && currentBrand.image ? currentBrand.image : require("../assets/images/profileIcon.svg").default}
              alt=""
            />
          </div>
          <Container className="px-3"
            flexDirection="column" gap="4px">
            <h5 className="text-white">
              {currentBrand ? currentBrand.name : ""}
            </h5>

            {isProUser && (<Container
              gap="4px"
              padding="0 0 0 2px"
              display="flex">

              <GemIcon />

              <StyledLabel {...{ color: "#F5BA23", fontSize: 10 }}>PRO ACCOUNT</StyledLabel>
            </Container>)}

          </Container>


        </Container>
        <div className="icon-block">
          <button
            aria-controls="simple-menu-mobile"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img
              src={require("../assets/images/right-arrow-right.svg").default}
              alt=""
            />
          </button>
        </div>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {loadedBrands ? loadedBrands
            .sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map((b: any, idx: number) => {
              return <MenuItem key={`${b.name}-${idx}`} onClick={() => {
                setCurrentBrand(b)
                handleClose()
              }}>{b.name}</MenuItem>
            }) : null}
          <MenuItem key="add artist" onClick={() => history.push('/add-artist')}>+ Add New Artist</MenuItem>
        </Menu>

      </div>
      {/* UPGRADE TO PRO TEST: TODO finalize */}
      {/* <SubscriptionStatusIndicator
        text="Upgrade to Pro"
        showStars
        handleOpenModal={() => handleOpenModal({
          source: "Desktop Navigation - Upgrade to Pro Button"
        })}
      /> */}
    </div>
  );
};

export default MobileHeader;
