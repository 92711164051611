/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
} from "react";
import {
  FollowersData,
  StreamsData,
  Platforms,
  CurrencyCodesType,
} from "types/global";
import {
  AdActions,
  getAdComments,
  getAdReactions,
  getAdShares,
  getAdVideoViews,
  getCostPerAction,
  getCostPerClick,
  getPurchaseRoas,
  getTotalRemaining,
  getTotalSpent
} from "helpers/CampaignDetails";
import StatsCardRow from "components/shareable/StatCards/row";
import ClickIcon from "assets/images/campaignDetails/click.svg"
import CommentsIcon from "assets/images/campaignDetails/comments.svg"
import EyeIcon from "assets/images/campaignDetails/eye.svg"
import MoneyIcon from "assets/images/campaignDetails/money.svg"
import MainStatCard from "components/shareable/StatCards/main-stat";
import { CampaignOption, getCampaignOptionById } from "../GrowInstagram/utils";
import BigGraph from "components/shareable/StatCards/BigGraph";
import getSymbolFromCurrency from "currency-symbol-map";
import { StatCardDataPoint } from "components/shareable/StatCards/utils";

interface GeneralPerformanceStatsProps {
  performanceStats: any;
  campaign_subtype?: CampaignOption

  budget: any;
  currentBrand: any;
  conversionsApi?: boolean;
  conversionsEventName?: string;
  campaign_type: string;
  campaign_objective?: {
    name: string | null,
    type: string | null
  } | null | undefined;
  zRatio?: number | null | undefined;
  o1?: number | null | undefined;
  startDate?: string;
  endDate?: string;
  currencyCode: CurrencyCodesType
}


const getActions = (
  actions: {
    action_type: string;
    value: number;
  }[],
  actionType: AdActions
) => actions.find((type) => type.action_type === actionType);


export const getIndicatorValue = (
  platform: Platforms.followers | Platforms.streams,
  data: FollowersData | StreamsData
) => {
  let dataByType = null;
  let value = 0;

  if (platform === Platforms.followers) {
    dataByType = data as FollowersData;
    value = dataByType?.data?.growthData?.difference || 0;
  } else {
    dataByType = data as StreamsData;
    value = dataByType?.data?.viewsGrowth?.difference || 0;
  }

  return value;
};

export const getIndicatorPercentage = (
  platform: Platforms.followers | Platforms.streams,
  data: FollowersData | StreamsData
) => {
  let percent = 0;
  let dataByType = null;

  if (platform === Platforms.followers) {
    dataByType = data as FollowersData;
    percent = dataByType?.data?.growthData?.percentage || 0;
  } else {
    dataByType = data as StreamsData;
    const percentageFromData = dataByType?.data?.viewsGrowth?.percentage || 0;
    percent = percentageFromData <= 0 ? 0 : percentageFromData;
  }

  return percent;
};


interface MarketingDashboardGraph {
  graphType: string,
  graph: string,
  latestValue: number,
  data: {
    date: string,
    value: number
  }[]
}

export default function GeneralPerformanceStats({
  performanceStats,
  budget,
  currentBrand,
  campaign_type,
  campaign_objective,
  campaign_subtype,
  zRatio,
  o1,
  conversionsApi,
  conversionsEventName,
  startDate,
  endDate,
  currencyCode,
}: GeneralPerformanceStatsProps) {
  const {
    impressions = "0",
    actions = [], inline_link_clicks = "0",
    clicks,
    cost_per_action_type = [], spend = "0",
    purchase_roas = []
  } =
    performanceStats || {};

  // Extract all additional stats from performanceStats
  const cpm = parseFloat(performanceStats?.cpm) || "0";
  const reach = parseInt((performanceStats?.reach || 0))

  const shareActions = getActions(actions, AdActions.post);
  const commentActions = getActions(actions, AdActions.comment);
  const thruplayActions = getActions(actions, AdActions.video_view);
  const conversionActions = getActions(actions, AdActions.custom_conversion);

  const reactions = getActions(actions, AdActions.likes);

  const inlineLinkClicks = parseInt(inline_link_clicks || 0)

  const overallClicks = parseInt(clicks || 0)
  const totalImpressions = parseInt(impressions || 0)

  const costPerClickToStreamingService = getCostPerClick(cost_per_action_type, conversionsApi ? "offsite_conversion.fb_pixel_custom" : undefined);

  const totalSpent = getTotalSpent(spend);
  const totalRemaining = getTotalRemaining(budget, spend);
  const adShares = getAdShares(shareActions);
  const adComments = getAdComments(commentActions);
  const adThruplays = getAdVideoViews(thruplayActions);
  const adLikes = getAdReactions(reactions);
  const conversions = conversionActions ? conversionActions.value : 0

  const [selectedCurrentBrand, setSelectedCurrentBrand] = useState(currentBrand)


  const { slug: brandSlug } = selectedCurrentBrand || {};


  const [graphStats, setGraphStats] = useState<MarketingDashboardGraph[]>([])

  // Example array of stats
  const stats: StatCardDataPoint[] = [
    {
      title: 'Total Spent', value: totalSpent,
      hideCurrencyLabel: true,
      isCurrency: true, currency: currencyCode, icon: MoneyIcon
    },
    { title: 'Reach', value: reach, isCurrency: false, icon: EyeIcon },
    { title: 'Likes & Reactions', value: adLikes, isCurrency: false, icon: EyeIcon },
    { title: 'Ad Link Clicks', value: inlineLinkClicks, isCurrency: false, currency: currencyCode, icon: ClickIcon },

    { title: 'Ad Comments', value: adComments, isCurrency: false, currency: currencyCode, icon: CommentsIcon },
    { title: 'Ad Shares', value: adShares, isCurrency: false, icon: EyeIcon },

    { title: 'Impressions', value: totalImpressions, isCurrency: false, currency: currencyCode, icon: CommentsIcon },
    {
      title: 'CPM', value: cpm, isCurrency: true,
      hideCurrencyLabel: true,

      currency: currencyCode, icon: CommentsIcon
    },

    // Add more stats as needed
  ];

  useEffect(() => {

    if (currentBrand) {
      setSelectedCurrentBrand(currentBrand)
    }
  }, [currentBrand]);




  function renderTopLevelStats() {
    switch (campaign_type) {
      case 'boost_instagram':

        const subtype = campaign_subtype ? campaign_subtype.id : 'boost_views'
        const subtyleDetails = getCampaignOptionById(subtype)

        let campaignGoal = subtyleDetails?.conversionGoal
        let primaryTitle = 'Post Engagement'
        let subtitle = "This is how many people watched, liked, and shared your post directly from ads."
        let mainActionStat = "Post Views from Ads"
        let mainActionValue = adThruplays
        let mainIcon = EyeIcon

        let secondValue = "Cost-per-View from Ads"
        let secondStat = getCostPerAction(cost_per_action_type, AdActions.video_view)

        if (subtype === 'boost_follows') {
          primaryTitle = 'Profile Visits'
          subtitle = "This is how many people visited your profile directly from ads."
          mainActionStat = "Profile Visits from Ads"
          mainActionValue = overallClicks
          secondValue = "Cost-per-Click"
          secondStat = getCostPerAction(cost_per_action_type, AdActions.link_click)
          mainIcon = ClickIcon
        }
        return (
          <>
            <MainStatCard
              currencyCode={currencyCode}
              goal={campaignGoal}
              stats={[
                {
                  primaryTitle: primaryTitle,
                  subtitle: subtitle,
                  icon: mainIcon,
                  stats: [
                    {
                      title: mainActionStat,
                      value: mainActionValue,
                      isMoney: false,

                    },
                    {
                      title: secondValue,
                      value: secondStat,
                      isMoney: true,
                    }
                  ]
                }
              ]}
            />
          </>

        )
      case 'increase_link_clicks':
      case 'link_clicks':
        if (conversionsApi && conversionsEventName) {
          return null
        }
        return (
          <MainStatCard
            currencyCode={currencyCode}
            stats={[
              {
                primaryTitle: 'Total Link Clicks',
                subtitle: inlineLinkClicks <= 1 ? `The number of people who clicked to your website from your ads.` : `${inlineLinkClicks} people clicked to your website from your ads.`,
                icon: ClickIcon,
                stats: [
                  {
                    title: 'Total Clicks',
                    value: inlineLinkClicks,
                    isMoney: false,
                  },
                  {
                    title: 'Cost-per-Click',
                    value: costPerClickToStreamingService,
                    isMoney: true
                  }
                ]
              }
            ]}
          />
        )

      case 'increase_sales':

        let mainEventLabel = 'Total Clicks'
        let mainData: any = inlineLinkClicks
        let mainCostLabel = 'Cost-per-Click'
        let mainDataCost: number | string = costPerClickToStreamingService
        // get roas
        var roas = getPurchaseRoas(purchase_roas)
        let returnOnDollar: number | string = roas

        if (campaign_objective && campaign_objective.name === 'Purchase') {
          mainEventLabel = 'Purchases from Ads'
          mainCostLabel = 'Cost-per-Purchase'
          if (campaign_objective.type === 'default') {
            // get the purchases event
            var purchaseActions = getActions(actions, AdActions.purchase)
            var purchaseCost = getCostPerAction(cost_per_action_type, 'purchase')

            // get roas
            var roas = getPurchaseRoas(purchase_roas)

            var zR = zRatio ? zRatio : 1
            if (zR && zR !== 1) {

              mainData = purchaseActions ? purchaseActions.value * zR : "0"
              if (purchaseActions) {
                // cost per purchase

                mainDataCost = purchaseActions ? (totalSpent / parseInt(mainData as any)) : 0

              } else {
                mainDataCost = purchaseCost ? purchaseCost : 0
              }

              if (o1) {
                returnOnDollar = (o1 * 1).toString()
              }
            }

          }
        }
        return (
          <>
            {/* <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-col justify-between">
              <div className="text-xl font-bold mt-2">Total Revenue Driven: </div>
              <div className="text-md font-normal">For every $1 spent on ads, you're generating ${returnOnDollar} in revenue.</div>
            </div> */}
            <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-row justify-between">
              <div className="w-6/12">
                <p className="text-md font-normal ">{mainEventLabel}</p>
                <p className="text-xl font-bold mt-2">{mainData}</p>
              </div>
              <div className="w-6/12">
                <p className="text-md font-normal ">
                  {mainCostLabel}
                </p>
                <p className="text-xl font-bold mt-2">
                  {getSymbolFromCurrency(currencyCode)}{mainDataCost.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </p>
              </div>
            </div>
            <div className="px-6 lg:px-10 py-5 border-b border-t flex flex-row justify-between">
              <div className="w-6/12">
                <p className="text-md font-normal ">Clicks</p>
                <p className="text-xl font-bold mt-2">{inlineLinkClicks}</p>
              </div>
              <div className="w-6/12">
                <p className="text-md font-normal ">
                  Cost-per-Click
                </p>
                <p className="text-xl font-bold mt-2">
                  {getSymbolFromCurrency(currencyCode)}{costPerClickToStreamingService}
                </p>
              </div>
            </div>
          </>
        )
    }
  }

  // Function to render StatsCardRows based on the stats array
  const renderStatsCardRows = (stats: StatCardDataPoint[]) => {
    return <StatsCardRow stats={stats} />
  };


  function renderGraphs() {
    switch (campaign_type) {
      case 'boost_instagram':
        return graphStats.map((data) => {
          if (data.graphType === 'big') {
            return <BigGraph
              key={data.graph}
              title={data.graph}
              data={data.data}
              latestValue={data.latestValue}
            />
          }
        })


    }
  }

  return (
    <React.Fragment>
  
      {renderTopLevelStats()}
      {renderStatsCardRows(stats)}
      {renderGraphs()}
    </React.Fragment>
  );
}
