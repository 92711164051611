import styled from 'styled-components';

interface DefaultImageProps {
  borderRadius?: string,
  backgroundColor?: string,
  width: string,
  height: string,
  objectFit?: string,
}

export const CardContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  gap: 8px;
  background: #FFFFFF;
  border-radius: 8px;
  width: -webkit-fill-available;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  padding: 4px;
  justify-content: center;
  align-items: center;
`;

export const DefaultImage = styled.img`
  background-color: ${({ backgroundColor }: DefaultImageProps) => backgroundColor || 'none'};
  max-width: ${({ width }: DefaultImageProps) => width};
  max-height: ${({ height }: DefaultImageProps) => height};
  min-width: ${({ width }: DefaultImageProps) => width};
  min-height: ${({ height }: DefaultImageProps) => height};
  border-radius: ${({ borderRadius }: DefaultImageProps) => borderRadius || "0"};
  object-fit: ${({ objectFit }: DefaultImageProps) => objectFit || 'cover'};
`;

export const RightSideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;