import { useState, createContext, ReactNode, FunctionComponent } from "react";
import UpgradeToProModal from "components/shareable/UpgradeToProModal";
import { track } from "analytics";
import { UserData } from "types/global";
import { useHistory } from "react-router-dom";
import { triggerVWOVirtualPageView } from "utils";

interface Context {
  openModal: boolean;
  handleOpenModal: (props?: {
    source?: string;
  }) => void;
  handleCloseModal: () => void;
}

export const UpgradeModalContext = createContext<Context>({
  openModal: false,
  handleOpenModal: (props?: {
    source?: string;
  }) => { },
  handleCloseModal: () => { },
});

type Props = {
  brandSlug?: string | null;
  children: ReactNode[] | ReactNode;
  user?: UserData | null;
  reloadBrands: () => void
};


declare global {
  interface Window {
    VWO: any[];
  }
}



const UpgradeModalProvider: FunctionComponent<Props> = ({
  brandSlug,
  children,
  user,
  reloadBrands,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState<string | null>(null);

  const history = useHistory()
  // if the user already has an org, rotue this through 
  const hasEnabledOrganization: boolean = Boolean(user && user.organization && user.organization.status !== "not_started")
  const hasOrganizationOverage: boolean = Boolean(user && user.organization && user.organization.totalProBrands ? user.organization.totalProBrands >= 5 : false);
  // Function to trigger VWO virtual page view with full URL

  const handleOpenModal = (props?: {
    source?: string
  }) => {
    triggerVWOVirtualPageView('/upgrade-to-pro-modal'); // Trigger VWO virtual page view with full URL

    track("Upgrade to Pro Modal Opened", { source: props?.source ? props?.source : null });
    setSource(props?.source ? props?.source : null);
    setOpen(true)
  }
  const handleCloseModal = () => setOpen(false);

  const context = {
    openModal: open,
    handleOpenModal,
    handleCloseModal,
  };

  return (
    <UpgradeModalContext.Provider value={context}>
      <UpgradeToProModal
        {...{ brandSlug }}
        isOrganization={hasEnabledOrganization}
        hasOrganizationOverage={hasOrganizationOverage}
        source={source}
        open={open}
        onClose={handleCloseModal}
        reloadBrands={reloadBrands}
      />
      {children}
    </UpgradeModalContext.Provider>
  );
};

export default UpgradeModalProvider;
