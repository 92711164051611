import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import background from "../../assets/images/piano-bg.jpg";

const Authenticate = () => {

  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if(isAuthenticated) {
      // createUser();
    }
  }, [isAuthenticated]);

  // const createUser = () => {
  //   let payload = {
  //     auth0_data: user,
  //   }
  // }

  return (
    <section style={{backgroundImage: `url(${background})`}} className="App h-screen w-full flex justify-center items-center bg-no-repeat bg-cover">
    <div className="w-full max-w-md bg-gray-400 bg-opacity-30">
      <p className="bg-gray-600 text-white p-2">Login with Symphonoy</p>
      <div className="py-5">
      <button className="bg-primary opacity-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => loginWithRedirect()}>Log In</button>
      </div>
    </div>
  </section>
  );
};

export default Authenticate;
