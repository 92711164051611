import DialogPaper from "components/shareable/DialogPaper";
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { Artist, ArtistPermissions, TeamMember } from "types/global";
import Avatar from '@mui/material/Avatar';
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import gravatar from "gravatar";
import { Body2, Headline2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { Divider } from "@material-ui/core";
import { ArtistsList } from "../ArtistsList";
import { useContext, useMemo, useState } from "react";
import PrimaryButton from "components/shareable/PrimaryButton";
import { updateMemberPermissions } from "../../../../../services/symphonyApi/organizationService"
import { TeamPlanContext } from "../../hooks/TeamMembersContext"
import { toast } from "react-toastify"
import { TOAST_ERROR_OPTIONS, TOAST_SUCCESS_OPTIONS } from "../../utils"

interface EditMemberModalProps {
  member: TeamMember;
  onClose: () => void;
}

export const EditMemberModal = ({ member, onClose }: EditMemberModalProps) => {
  const { mobileView } = useMediaBreakpoints();
  const [artists, setArtists] = useState<Artist[]>(member.artists)
  const { getTeamMembers } = useContext(TeamPlanContext);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const disableSaveButton = useMemo(() => Boolean(artists.find(artist => !artist.permissionsType)), [artists])

  const getFormattedChanges = () => {
    const artistsToAdd = artists.filter(artist => !member.artists.find(a => a.id === artist.id))
    const artistsToAddFormatted = artistsToAdd.map(artist => ({ brandId: artist.id, permission: artist?.permissionsType || 'view_only' }))

    const artistsToDelete = member.artists.filter(artist => !artists.find(a => a.id === artist.id))
    const artistsToDeleteFormatted = artistsToDelete.map(artist => artist.id)

    const artistsToEdit = member.artists.filter(artist => artists.find(a => a.id === artist.id && a.permissionsType !== artist.permissionsType))
    const artistsToEditFormatted = artistsToEdit.map(artist => ({
      brandId: artist.id,
      permission: 
        artist?.permissionsType === ArtistPermissions.VIEW_ONLY
          ? ArtistPermissions.ADMINISTRATOR
          : ArtistPermissions.VIEW_ONLY
    }))

    return {
      artistsToAdd: artistsToAddFormatted,
      artistsToEdit: artistsToEditFormatted,
      artistsToDelete: artistsToDeleteFormatted,
    }
  }

  const onEditMember = async () => {
    try {
      setIsLoading(true)
      const { artistsToAdd, artistsToEdit, artistsToDelete } = getFormattedChanges()
      await updateMemberPermissions({
        memberId: member.id,
        addedArtists: artistsToAdd,
        editedArtists: artistsToEdit,
        removedArtists: artistsToDelete
      })
      getTeamMembers()
      toast.success("Member edited", TOAST_SUCCESS_OPTIONS)
      onClose()
    } catch (error) {
      toast.error("Something went wrong editing the member", TOAST_ERROR_OPTIONS)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DialogPaper
      open
      fullScreen={mobileView}
      dialogStyle={{
        margin: mobileView ? 0 : 'auto 16px',
        borderRadius: mobileView ? 0 : 8,
        maxWidth: 559,
        maxHeight: mobileView ? 659 : undefined,
      }}
      containerStyle={{ margin: mobileView ? 0 : 'auto' }}
    >
      <div className="flex flex-col gap-6">
        <HeaderModal
          closeModal={onClose}
          title={<Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>{member.name ? `${member.name} ${member.lastName}` : member.email}</Headline2>}
          subtitle={<Body2>{member.name && member.email}</Body2>}
          HeaderIcon={<Avatar src={gravatar.url(member.email, { protocol: 'https', d: 'retro' })} sx={{ width: 32, height: 32 }} />}
        />
        <Divider />
        <ArtistsList
          artists={artists}
          onUpdateArtists={setArtists}
        />
        <div className="w-full flex justify-end">
          <PrimaryButton
            loading={isLoading}
            text='Save changes'
            onClick={onEditMember}
            height='39px'
            disabled={disableSaveButton}
            tooltip={disableSaveButton ? "Please select all artist's permissions" : ""}
          />
        </div>
      </div>
    </DialogPaper>
  );
}