import { Fragment, FunctionComponent, useContext, useEffect, useState } from "react";
import { LogoutButton, StyledLabel, Container, StyledSpan } from "../../styles";
import { useAuth0 } from "@auth0/auth0-react";
import FB, { loginWithFB } from "helpers/FB";
import { Modal } from "@material-ui/core";
import Axios from "helpers/Interceptor";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { LoadingLottie } from 'components/Loader/LoadingLottie';
import { connectUserToFacebook } from "./api";
import { AffiliateDetails, ArtistPermissions, Connections } from "types/global";
import { TextButton } from "styles/shared";
import EditBrandSlugModal from "pages/post-auth/MarketingPage/Components/Modals/AdminEditBrandSlugModal";
import { ShowFacebookInfo } from "pages/post-auth/MarketingPage/Components/Modals/AdminShowFacebookInfo";
import { isBrandAdministrator } from "utils";

interface Props {
  email?: string;
  brandId?: number | null;
  brandSlug?: string | null;
  brandName?: string | null;
  brandConnections?: Connections | null;
  isAdmin?: boolean | undefined | null;
  brandSourceDetails?: AffiliateDetails | undefined;
}

interface FacebookMeResponse {
  id: string,
  name: string,
  picture: {
    data: {
      url: string
    }
  }
}

const AccountTab: FunctionComponent<Props> = ({
  email,
  brandId,
  brandSlug,
  brandName,
  brandConnections,
  brandSourceDetails,
  isAdmin
}: Props) => {
  const { logout } = useAuth0();
  const handleClickLogoutButton = () =>
    logout({ returnTo: window.location.origin });

  const {
    currentBrand,
    reloadBrand
  } = useContext(CurrentBrandContext);

  const hasAdminAccess = isBrandAdministrator(currentBrand!);

  const [isEditSlugModalOpen, setIsEditSlugModalOpen] = useState(false);
  const [isShowFacebookModal, setIsShowFacebookModal] = useState(false);

  // for use with current Facebook login
  // get current user name
  const [me, setMe] = useState<FacebookMeResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLogout, setLoadingLogout] = useState<boolean>(false);
  const [triggerFacebookLogoutEvent, setTriggerFacebookLogoutEvent] = useState(false);

  const [connectingTikTok, setConnectingTiktok] = useState(false);

  async function checkFBConnectionStatus() {
    if (!brandId || !brandSlug || !brandName) return

    setLoading(true)
    const userLoggedIn = await new Promise((resolve, reject) => {
      FB().getLoginStatus(function (response: any) {
        if (response.status === 'connected') {
          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token 
          // and signed request each expire.
          resolve(true)
        } else if (response.status === 'not_authorized') {
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
          resolve(false)
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
          resolve(false)
        }
      });

    })
    if (userLoggedIn) {
      await connectUserToFacebook({
        brandId,
        brandSlug,
        brandName,
      })
      loggedInFb()
    } else {
      setLoading(false)
    }
  }

  const loggedInFb = async () => {
    setLoading(true)
    try {
      const request = await Axios.get("/platform/facebook/me");
      const { logged, profile } = request.data;
      if (logged && profile) {
        setMe(profile as FacebookMeResponse)
      } else {
        setMe(null)
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false)
    }
  }

  function triggerFacebookLogout() {
    setTriggerFacebookLogoutEvent(true)
  }

  async function triggerFBLogin() {
    await loginWithFB()
    await checkFBConnectionStatus()
  }

  async function reRequestPermissions() {
    const p = await loginWithFB(("rerequest" as any), "pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement,business_management")
    await checkFBConnectionStatus()
  }

  async function confirmFacebookLogout() {
    setLoadingLogout(true);
    const authResp = FB().getAuthResponse();
    if (authResp && authResp.accessToken) {
      await new Promise((resolve) => {
        FB().logout(function (response: any) {
          // user is now logged out
          // once logged out, remove items from the brand:
          //   - facebook page
          //   - instagram page
          resolve(1)
        });
      })
    }
    await Axios.put(`/brand/${brandSlug}/disconnect`, {
      id: brandId,
      name: brandName,
      service: 'facebook'
    })
    setLoadingLogout(false)
    setTriggerFacebookLogoutEvent(false)
    setMe(null)
    loggedInFb()
    reloadBrand()
  }

  useEffect(() => {
    loggedInFb()
  }, [])

  const [tiktokUser, setTikTokUser] = useState<any>(brandConnections && brandConnections.tiktok_business ? brandConnections.tiktok_business : null);

  async function loginWithTikTok() {
    setConnectingTiktok(true)
    window.open(`https://ads.tiktok.com/marketing_api/auth?app_id=7190203583793463298&state=${brandSlug}&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fapi%2Fbrand%2Ftiktok-connect&rid=6jj75ldezg4`,
      '_blank' // <- This is what makes it open in a new window.
    )

  }

  async function logoutTiktok() {
    setTikTokUser(null)
  }

  useEffect(() => {
    if (connectingTikTok) {
      const interval = setInterval(() => {
        console.log('This will run every 10 seconds!');
        // refresh the brand to see if user logged in
        reloadBrand()
      }, 10000);
      return () => clearInterval(interval);

    } else if (!connectingTikTok) {

    }
  }, [connectingTikTok]);

  useEffect(() => {
  }, [])

  function renderAdminData() {
    const openEditSlugModal = () => {
      setIsEditSlugModalOpen(true);
    };

    const closeEditSlugModal = () => {
      setIsEditSlugModalOpen(false);
    };

    const openFacebookModal = () => {
      setIsShowFacebookModal(true);
    }

    const closeFacebookModal = () => {
      setIsShowFacebookModal(false);
    }

    if (isAdmin) {
      return (
        <>
          <Container
            margin="32px 0px 0px 0px"
            padding="0px 0px 24px 0px"
            borderBottom="1px solid #E5E7EB"
          >
            <StyledLabel fontSize={20}>Admin: Brand Info</StyledLabel>
          </Container>
          <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
            <StyledLabel fontSize={16}>
              <StyledSpan color="#707070">Name:</StyledSpan>{" "}
              <StyledSpan>{brandName}</StyledSpan>
            </StyledLabel>
          </Container>
          <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
            <Container
              display="flex" flexDirection="row" gap={"24px"}>
              <StyledLabel fontSize={16}>
                <StyledSpan color="#707070">Slug / URL:</StyledSpan>{" "}
                <StyledSpan>{brandSlug}</StyledSpan>
                {hasAdminAccess && (
                  <Container display="flex"
                    gap="4px"
                    alignItems="center">
                    <TextButton onClick={openEditSlugModal}>Edit Slug</TextButton>
                    <TextButton onClick={openFacebookModal}>Facebook info</TextButton>
                </Container>
                )}
              </StyledLabel>
              <StyledLabel fontSize={16}>
                <StyledSpan color="#707070">Partner Affiliate Discount:</StyledSpan>{" "}
                <StyledSpan>{brandSourceDetails ? (`${brandSourceDetails.partnerName}-driven user / ${brandSourceDetails.discountAmount * 100}% off`) : ('Not driven by partner / No Discount Enabled')}</StyledSpan>
              </StyledLabel>
            </Container>
          </Container>
          {brandSlug && <EditBrandSlugModal
            open={isEditSlugModalOpen}
            onSuccess={(newSlug: string) => {
              reloadBrand()
              closeEditSlugModal();
            }}
            closeModal={closeEditSlugModal}
            currentSlug={brandSlug}
          />}
          {brandId && <ShowFacebookInfo
            open={isShowFacebookModal}
            closeModal={closeFacebookModal}
            brandId={brandId}
          /> }
        </>
      )
    } else {
      return null
    }
  }
  return (
    <Container>
      {renderAdminData()}
      <Container
        margin="32px 0px 0px 0px"
        padding="0px 0px 24px 0px"
        borderBottom="1px solid #E5E7EB"
      >
        <StyledLabel fontSize={20}>Account</StyledLabel>
      </Container>
      <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
        <StyledLabel fontSize={16}>
          <StyledSpan color="#707070">Email:</StyledSpan>{" "}
          <StyledSpan>{email}</StyledSpan>
        </StyledLabel>
      </Container>
      {hasAdminAccess && (
        <>
          <Container
            margin="32px 0px 0px 0px"
            padding="0px 0px 24px 0px"
            borderBottom="1px solid #E5E7EB"
          >
            <StyledLabel fontSize={20}>Connections</StyledLabel>
          </Container>
          <Container padding="24px 0px" borderBottom="1px solid #E5E7EB">
            <StyledLabel fontSize={16}>
              <StyledSpan color="#707070">Facebook:</StyledSpan>{" "}
              {me ? <StyledSpan>Logged in</StyledSpan> : "Not connected"}
            </StyledLabel>
            {me ?
              <><div className="md:w-2/5 mt-2 border rounded-md p-2 flex  justify-between">
                <div className="flex items-center">
                  <img className="w-10 h-10 mr-2" src={me.picture.data.url}></img>
                  <p>{me.name}</p>
                </div>
                <button className="text-red-500 text-sm" onClick={triggerFacebookLogout}>Log out</button>
              </div>
                <button type="button"
                  onClick={() => reRequestPermissions()}
                  className="mt-2 border px-3 py-1 rounded-lg text-purple-500 text-md">
                  Link Pages to Symphony &gt;
                </button>
              </> : (
                <button
                  className="mt-2 border px-3 py-1 rounded-lg text-purple-500 text-md"
                  onClick={triggerFBLogin}
                  disabled={loading}
                >
                  Connect Facebook
                </button>
              )}
          </Container>
        </>
      )}
      <Container padding="24px 0px 0px 0px">
        <LogoutButton
          disableTouchRipple
          variant="outlined"
          onClick={handleClickLogoutButton}
        >
          Logout
        </LogoutButton>
      </Container>
      <Modal
        open={triggerFacebookLogoutEvent}
        onClose={() => setTriggerFacebookLogoutEvent(false)}
        aria-labelledby="logout of facebook"
        aria-describedby="popup-to-confirm facebook logout user"
      >
        <div className="top-wrapper w-full lg:w-4/12 transform-gpu -translate-x-1/2 -translate-y-1/2 flex items-center justify-center left-1/2 top-1/2 absolute z-50 card-content bg-white shadow-none lg:shadow-lg">
          <div className="w-full">
            <div className="bg-white w-full h-full mx-auto">
              <div className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
                <p className="font-semibold text-gray-800">Log out of Facebook?</p>
                <a onClick={() => setTriggerFacebookLogoutEvent(false)}>
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </a>
              </div>
              <div className=" px-4 lg:px-10 ">
                <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
                  If you log out of Facebook, your Instagram + Facebook will be automatically disconnected and any running ads might have issues.
                </h4>
                <div className="flex py-2">
                  {loadingLogout ? (
                    <LoadingLottie black />
                  ) : (
                    <Fragment>
                      <button
                        type="button"
                        disabled={loadingLogout}
                        onClick={() => setTriggerFacebookLogoutEvent(false)}
                        className="w-full text-center p-3 md:p-4 text-primary font-bold border-b border-backgound-100"
                      >
                        Don't Logout
                      </button>
                      <button
                        type="button"
                        disabled={loadingLogout}
                        onClick={confirmFacebookLogout}
                        className="w-full text-center p-3 md:p-4 text-red-500 font-bold border-b border-backgound-100"
                      >
                        Yes, Logout
                      </button>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Container>

  );
};

export default AccountTab;
