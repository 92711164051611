import styled from "styled-components";

interface StyledInfoSectionProps {
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  borderBottom?: string;
  borderTop?: string;
}

export const StyledInfoSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ paddingLeft }: StyledInfoSectionProps) => (paddingLeft ? paddingLeft : '2.5rem')};
  padding-right: ${({ paddingRight }: StyledInfoSectionProps) => (paddingRight ? paddingRight : '2.5rem')};
  padding-bottom: ${({ paddingBottom }: StyledInfoSectionProps) => (paddingBottom ? paddingBottom : '1rem')};
  padding-top: ${({ paddingTop }: StyledInfoSectionProps) => (paddingTop ? paddingTop : '1rem')};
  gap: 16px;
  width: 100%;
  background: #FFFFFF;
  border-top: ${({ borderTop }: StyledInfoSectionProps) => ( borderTop ? borderTop : 'none')};

  border-bottom: ${({ borderBottom }: StyledInfoSectionProps) => ( borderBottom ? borderBottom : '1px solid #EDECF2')};
`;

export const StyledInfoSectionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
`;

export const StyledInfoSectionMainLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  overflow-wrap: break-word;
  margin-right: 14px;
`;

export const StyledInfoSectionTooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  vertical-align: middle;
`;

export const StyledInfoSectionMainText = styled.span`
  position: relative;
  align-items: center;
  vertical-align: middle;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #000000;
`;

export const StyledInfoSectionSubTitleText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const StyledInfoSectionAskToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  gap: 16px;
`;

export const ToggleAskText = styled.span`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  width: 80px;
  text-align: right;
`;
