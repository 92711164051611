import { ColorFormat } from "helpers/StyleUtils";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useStyles } from "./styles";


interface Props {
    url: string,
    playing?: boolean,
    loop?: boolean,
    progressBarColor?: string,
    backgroundContrast?: ColorFormat
}

interface ProgressState {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
}

export default function AudioPlayer(props: Props) {
    const {
        url,
        playing,
        loop,
        progressBarColor,
        backgroundContrast
    } = props;

    const classes = useStyles();
    // const [playing, setPlaying] = useState(false);
    const [progressValue, setProgressValue] = useState(0);

    const handleProgress = (state: ProgressState) => {
        // state.played is the fraction of the played audio
        setProgressValue(state.played * 100); // Convert to percentage
    };

    useEffect(() => {
        // NOTE: componentDidMount
    }, []);

    const isBlack = () => {
        return backgroundContrast && backgroundContrast === "dark";
    }

    let sharedStyles = {
        color: isBlack() ? "rgba(0, 0, 0, 0.87)" : "rgba(255, 255, 255, 0.87)",
        backgroundColor: isBlack() ? "rgba(255, 255, 255, 0.16)" : "rgba(0, 0, 0, 0.16)",
        trackColor: isBlack() ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.8)"
    }

    const playLineStyle = {
        backgroundColor: sharedStyles.backgroundColor,
    };

    const playLineProgressStyle = {
        transform: `scaleX(${progressValue}%)`,
        transformOrigin: 0,
        // width: `${progressValue}%`, // NOTE: leave this because we might want it to fix borderRadius issue with transform
        width: "100%",
        height: 8,
        backgroundColor: sharedStyles.trackColor,
        borderRadius: 100,
    };

    const audioPlayerShareableStyle = {
        width: "100%",
    };

    return (
        <div className="audioPlayerShareable" style={audioPlayerShareableStyle}>
            <ReactPlayer
                url={url}
                playing={playing}
                loop
                volume={0.8}
                config={{
                    file: {
                        forceAudio: true
                    }
                }}
                width={0}
                height={0}
                onProgress={handleProgress}
                progressInterval={250} // Call onProgress every 1000 milliseconds (1 second)
            />
            <div className={classes.playline} style={playLineStyle}>
                <div className="playLineProgress" style={playLineProgressStyle}></div>
            </div>
        </div>
    )
}



