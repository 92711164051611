import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyles = makeStyles(() => ({
  disabled: {
    cursor: "not-allowed",
  },
}))

interface ButtonContainerProps {
  width: string;
  height: string;
  textAlign?: string;
  fontSize?: string;
}

interface ButtonTextContainerProps {
  fontSize?: string;
  alignItems?: string;
  display?: string;
}

export const ButtonContainer = styled.span<ButtonContainerProps>`
  display: inline;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: none;
  text-align: ${({ textAlign }) => textAlign};
  cursor: pointer;
`;

export const ButtonTextContainer = styled.div<ButtonTextContainerProps>`
  display: ${({ display }) => display};
  gap: 4px;
  align-items: ${({ alignItems }) => alignItems};
  cursor: pointer;
  & > span {
    font-size: ${({ fontSize }) => fontSize};
  }
`;