import { useMediaQuery, useTheme } from "@material-ui/core";
import { CommentIcon, HeartIcon, IGAdArtistName, ShareIcon } from "./shared";
import { ReactComponent as CommentsIcon } from "assets/images/campaignDetails/chat.svg"
import { ReactComponent as LikesIcon } from "assets/images/campaignDetails/heart.svg"
import { ReactComponent as SharesIcon } from "assets/images/campaignDetails/paperplane.svg"

interface IGReelsPreviewProps {
    callToAction?: string;
    name?: string;
    imageUrl?: string;
    caption?: string;
    simplePreview?: boolean;
}

function SwipeUpArrow() {
    return (
        <svg width="19" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m1.529 6.17 8.38-5.028 7.82 5.027" stroke="#fff" stroke-width="1.476" stroke-linecap="round" />
        </svg>
    )
}
/**
 * Renders what the bottom bar of the IG story preview will look like
 * @returns 
 */
export function IGReelsPreviewActionArea(props: IGReelsPreviewProps) {
    const {
        callToAction = "Listen Now",
        name,
        imageUrl,
        caption = "",
        simplePreview = false,
    } = props

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    return (
        <div className={`rounded-xl px-3 absolute z-10 bottom-0 flex flex-col w-full items-center justify-center ${simplePreview && 'm-0'}`}
            style={{
                background: "linear-gradient(0deg, rgb(0 0 0 / 40%) 0%, rgb(255 255 255 / 0%) 100%)"
            }}>
            <div className={`my-1 flex justify-end items-center ${simplePreview && "flex justify-end"}`}
                style={{ width: "100%" }}>
                {!simplePreview ? <HeartIcon /> : <LikesIcon width={setResponsiveView ? "3vw" : "1vw"} color="white" />}
            </div>
            <div className={`my-1 flex justify-end items-center w-full ${simplePreview && "flex justify-end"}`}>
                {!simplePreview ? <CommentIcon /> : <CommentsIcon width={setResponsiveView ? "3vw" : "1vw"} color="white" />}
            </div>
            <div className={`${simplePreview ? "m-0 items-baseline" : "my-1"} flex justify-between items-center w-full`}>
                {name && (
                    <div>
                        <IGAdArtistName
                            showTextShadow={true}
                            simplePreview={simplePreview}
                            nameColor="white"
                            imageUrl={imageUrl ? imageUrl : undefined}
                            name={name} />
                    </div>
                )}
                <div className={simplePreview ? "my-1 flex justify-end" : "ml-auto"}>
                    {!simplePreview ? <ShareIcon /> : <SharesIcon width={setResponsiveView ? "3vw" : "1vw"} color="white" />}
                </div>
            </div>
            {caption && (
                <div className="mb-1 flex items-center w-full text-white text-sm " >
                    <p className={simplePreview ? "truncate" : "truncate h-8"}
                        style={simplePreview ? {
                            fontSize: setResponsiveView ? "1.6vw" : "0.6vw",
                            lineHeight: 3,
                        } : {}}>
                        {caption}
                    </p>
                </div>
            )}

            <div className="w-full text-xs rounded-md text-center w-auto bg-white 
            bg-opacity-30	
            text-white
            font-semibold mb-3
            px-5 py-3
            md:px-4 md:py-2 
            text-sm md:text-xs"
                style={simplePreview ? {
                    fontSize: setResponsiveView ? "1.6vw" : "0.6vw",
                    padding: "3%",
                    lineHeight: "initial",
                    borderRadius: "4px",
                } : {}}>
                <p>{callToAction}</p>
                </div>
        </div>
    )
}

