import { checkForFBPermissions } from 'helpers/FB'
import { useEffect, useState } from 'react'
import { FacebookAdAccountAccess, getAdAccounts } from 'services/facebookApi'

export enum ErrorStatesStatuses {
  NONE,
  PERMISSIONS_ERROR,
  NO_PAGES_ERROR,
}

export function useGetAdAccounts(isModalOpen: boolean) {
  const [errorState, setErrorState] = useState<ErrorStatesStatuses>(
    ErrorStatesStatuses.NONE
  )
  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(true)
  const [adAccounts, setAdAccounts] = useState<FacebookAdAccountAccess[]>([])

  useEffect(() => {
    if (isModalOpen) {
      getAdAccounts().then((accounts) => {
        setAdAccounts(accounts)
        setLoadingAccounts(false)
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    // if we checked the pages, and they're empty - there 2 things that may
    // have happened here:
    /**
     * 1. the artist declined "pages_show_list" permission, which we'll need alongside
     * "pages_read_engagement" to get access
     * 2. the artist has no pages, and we need to tell them to setup a page
     */
    if (!loadingAccounts && adAccounts.length === 0) {
      checkPermissions()
    }
  }, [adAccounts, loadingAccounts])

  const checkPermissions = async () => {
    const status: any = await checkForFBPermissions([
      'ads_management',
      'ads_read',
      'business_management',
    ])
    if (!status || status.length > 0) {
      // if invalid, show dialog to re-request permissions
      setErrorState(ErrorStatesStatuses.PERMISSIONS_ERROR)
    } else {
      // there are no pages
      setErrorState(ErrorStatesStatuses.NO_PAGES_ERROR)
    }

    setLoadingAccounts(false)
  }

  return {
    loadingAccounts,
    setLoadingAccounts,
    errorState,
    setErrorState,
    adAccounts,
    setAdAccounts,
  }
}
