import Axios from "helpers/Interceptor"
import * as Sentry from "@sentry/browser"
import { CampaignFilterType } from "pages/post-auth/MarketingPage/Components/CampaignTypesFilter/types"

export const updateInBrand = async (
  brandId: string,
  brandSlug: string,
  value: string,
  service: string
) => {
  try {
    Sentry.setTag("flow", "updateInBrand")
    Sentry.setContext("input", { brandId, value, service })

    const response = await Axios.put(`/brand/${brandSlug}/connect`, {
      id: brandId,
      name: brandSlug,
      service,
      value,
    })
    return response.data;
  } catch (error) {
    Sentry.captureException(error)
    return null;
  }
}

export const getWebsiteOverallData = async (brandSlug: string, startDate?: string, endDate?: string) => {
  try {
    let url = `/dashboard/brand/${brandSlug}/website/overall`
    if (startDate && endDate) url += `?startDate=${startDate}&endDate=${endDate}`

    const response = await Axios.get(url)
    return response.data;
  } catch (error) {
    return null;
  }
}

export const getCampaignsForBrand = async (params: CampaignFilterType, brandId: number) => {
  try {
    const response = await Axios.get(`/campaign/brand/${brandId}`, { params });
    return response.data.data || [];
  } catch (e) {
    return null
  }
}

export const getBrandStripeCustomerPortal = async (brandSlug?: string | null | undefined) => {
  try {
    if (!brandSlug) return null
    const response = await Axios.post(
      `/subscription/brand/${brandSlug}/settings`,
    );
    return response.data.data;
  } catch (e) {
    return null
  }
}