import { useEffect, useState } from "react"
import { Steps } from "../utils"

type Data = {
  loading: boolean
  onSave: () => void
}

const useEditModalHandler = (args: {
  [Steps.FACEBOOK_AD_ACCOUNT]: Data
  [Steps.FACEBOOK_PIXEL]: Data
  [Steps.FACEBOOK_API_TOKEN]: Data
}) => {
  const [selectedStepToEdit, setSelectedStepToEdit] = useState<string | null>(null)
  const [handleOnEditModal, setHandleOnEditModal] = useState<VoidFunction | null>(null)

  const handleCloseEditModal = () => setSelectedStepToEdit(null)
  const step = selectedStepToEdit as Steps

  useEffect(() => {
    const isValidStep = Object.values(Steps).includes(step)
    setHandleOnEditModal(isValidStep ? args[step]?.onSave : null)
  }, [selectedStepToEdit])

  return {
    loadingEditModal: Boolean(args[step]?.loading),
    selectedStepToEdit,
    setSelectedStepToEdit,
    handleOnEditModal,
    handleCloseEditModal,
  }
}

export default useEditModalHandler
