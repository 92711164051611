import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { Container, StyledLabel } from "./styles"; // Make sure these are your styled components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import TaskCard from "components/TaskCard";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export interface TaskItem {
  icon?: string;
  title: string;
  description: string;
  actionName?: string; // actionName is optional
  action?: () => void; // action is optional
  done?: boolean; // Indicate if the task is completed
  href?: string; // href is optional
}

interface TaskListProps {
  tasks: TaskItem[];
  listTitle?: string;
  width?: string;
  horizontal?: boolean;
}

export const TaskList: FunctionComponent<TaskListProps> = ({ tasks, listTitle, horizontal, width }) => {
  const theme = useTheme();
  const setBorderRadius = useMediaQuery(theme.breakpoints.down('sm'));
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(1200));
  const history = useHistory();

  if (setResponsiveView || horizontal) {

    if (horizontal) {
      return (
        <Container
          padding="24px"
          backgroundColor="#FFFFFF"
          borderRadius={setBorderRadius ? "0px" : "16px"}
          flexDirection={"column"}
          display="flex"
          height="fit-content"
        >
          {listTitle && (<StyledLabel fontSize={20} padding="8px 8px">
            {listTitle}
          </StyledLabel>)}
          <Swiper slidesPerView={4} navigation={true} modules={[Navigation]} style={{ padding: "8px 8px", width: '100%' }}>
            {tasks.map((item, index) => (
              <SwiperSlide key={`task-${index}`}>
                <div className="p-3">
                  <TaskCard
                    href={item.href}
                    showBoxShadow={true}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    actionName={item.actionName}
                    action={item.action} // Use noop if action is undefined
                    crossedOut={item.done}
                    headerAlign="center"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      );
    } else {
      return (
        <Container
          padding="24px"
          backgroundColor="#FFFFFF"
          borderRadius={setBorderRadius ? "0px" : "16px"}
          flexDirection={"column"}
          display="flex"
          height="fit-content"
        >
          <StyledLabel fontSize={20} padding="8px 8px">
            {listTitle}
          </StyledLabel>
          <Swiper slidesPerView={4} navigation={true} modules={[Navigation]} style={{ padding: "8px 8px" }}>
            {tasks.map((item, index) => (
              <SwiperSlide key={`task-${index}`}
                className="p-3">
                <TaskCard
                  href={item.href}

                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                  actionName={item.actionName}
                  action={item.action} // Use noop if action is undefined
                  crossedOut={item.done}
                  headerAlign="center"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      );
    }
  }

  return (
    <Container
      backgroundColor="#F5F3F6"
      flexDirection="column"
      display="flex"
      height="100%"
      width={width || "550px"}
    >
      <Container
        padding="24px"
        backgroundColor="#FFFFFF"
        borderRadius="16px"
        flexDirection="column"
        display="flex"
        height="100%"
      >
        <StyledLabel fontSize={20} padding="8px 0px">
          {listTitle}
        </StyledLabel>
        {tasks.map((item, index) => (
          <TaskCard
            href={item.href}

            key={`task-${index}`}
            icon={item.icon}
            title={item.title}
            actionName={item.actionName}

            description={item.description}
            action={item.action} // Use noop if action is undefined
            crossedOut={item.done}
          />
        ))}
      </Container>
    </Container>
  );
};
