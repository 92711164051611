import { useState, createContext, createElement, ReactNode, FunctionComponent } from "react"

interface NextButtonHandler {
  handler: () => void,
  tab: string | undefined
  show: boolean
  text: string
  disabled: boolean
}

const DefaultState = {
  handler: () => console.log('Not defined yet!'),
  tab: undefined,
  show: true,
  text: 'Next',
  disabled: false
};

interface Context {
  nextButtonHandler: NextButtonHandler
  setNextButtonHandler: (nextButtonHandler: NextButtonHandler | null) => void
}

export const NavbarContext = createContext<Context>({
  nextButtonHandler: DefaultState,
  setNextButtonHandler: () => {},
})

type Props = {
  children: ReactNode[] | ReactNode
}

const NavbarProvider: FunctionComponent<Props> = ({ children }) => {
  const [nextButtonHandler, setNextButtonHandler] = useState<(NextButtonHandler)>(DefaultState);

  const onSetNextButtonHandler = (nextButtonHandler: NextButtonHandler | null) =>
    setNextButtonHandler(nextButtonHandler || DefaultState)

  const context = {
    nextButtonHandler,
    setNextButtonHandler: onSetNextButtonHandler,
  }

  return createElement(
    NavbarContext.Provider,
    { value: context },
    children
  );
}

export default NavbarProvider
