import styled from "styled-components"

export const Container = styled.div`
  padding: 32px 24px;
  max-width: 556px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const LogoContainer = styled.div`
  width: 110px;
  height: 24px;
`

export const GoBackButton = styled.button`
  background-color: rgba(136, 0, 255, 0.04);
  border-radius: 9999px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledIcon = styled.img``

export const TitleContainer = styled.div`
  margin-top: 16px;
`

export const GoBackContainer = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 16px;
  align-items: center;
`

export const ContinueButtonContainer = styled.div`
  margin-top: 32px;
  width: 100%;
`
