import styled from "styled-components"
import { SystemColors } from "types/globalStyles"

interface ContainerProps {
  withBorderRadius?: boolean
}

interface ButtonContainerProps {
  loading?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  background-color: ${SystemColors.PAPER_ERROR_CONTAINER};
  justify-content: space-between;
  padding: 16px 24px;
  border-radius: ${({ withBorderRadius }) =>
    withBorderRadius ? "8px" : undefined};
  align-items: center;
  gap: 24px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const ButtonContainer = styled.div<ButtonContainerProps>`
  pointer-events: ${({ loading }) => (loading ? "none" : undefined)};
`
