import { TextField } from '@material-ui/core';
import { ChangeEvent, Fragment, FunctionComponent, useState } from 'react';
import HeaderModal from '../../../HeaderModal';
import { ButtonConfirm, useStyles } from '../../style';
import ErrorBanner from '../ErrorBanner';
import Lottie from "react-lottie";
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading_White.json';
import { checkIsValidUrl } from 'utils';

interface Props {
  loading: boolean;
  error: boolean;
  closeModal: () => void;
  handleGoBack: () => void;
  confirmDuplicateLinkClicks: (link: string) => void;
}

const DuplicateLinkClicksConfirmView: FunctionComponent<Props> = ({
  loading,
  error,
  closeModal,
  handleGoBack,
  confirmDuplicateLinkClicks,
}: Props) => {
  const classes = useStyles()
  const [isValidUrl, setIsValidUrl] = useState<boolean>(false)
  const [link, setLink] = useState("")

  const handleChangeLink = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setLink(value)
    setIsValidUrl(checkIsValidUrl(value))
  }

  const handleConfirm = () => confirmDuplicateLinkClicks(link)

  return (
    <Fragment>
      <HeaderModal
        closeModal={closeModal}
        subtitle="Enter your link."
        body="This can be any type of link - increase traffic to your merch page, drive ticket sales, or grow traffic anywhere else."
        title="Duplicate campaign"
        handleGoBack={handleGoBack}
      />
      <TextField
        placeholder="e.g. https://link.com"
        InputProps={{ classes: { root: classes.textField } }}
        FormHelperTextProps={{
          classes: { root: classes.formHelperText },
        }}
        className={classes.textField}
        variant="outlined"
        fullWidth
        type="url"
        onChange={handleChangeLink}
      />
      {!loading && error && (
        <ErrorBanner errorText="An error occurred when duplicating your campaign... Please try again." />
      )}
      <ButtonConfirm
        disabled={!link || link.trim().length === 0 || !isValidUrl}
        onClick={!loading ? handleConfirm : undefined}
      >
        {loading ? (
          <Lottie
            height={32}
            width={32}
            options={{
              rendererSettings: {
                className: "bg-white-500",
              },
              loop: true,
              autoplay: true,
              animationData: SymphonyLoadingLottie,
            }}
          />
        ) : (
          "Next"
        )}
      </ButtonConfirm>
    </Fragment>
  )
}

export default DuplicateLinkClicksConfirmView;
