import { InstagramPageType } from 'types/global';
import millify from 'millify';
import React from 'react';
import { useState } from 'react';
import { fbLoggedIn, loginWithFB } from '../../helpers/FB';
import LinkButton from 'components/shareable/LinkButton';
import { EditPlatformContainer } from './MarketingPage/styles';

interface InstagramProps {
    selectedFBPage: any,
    selectedInstaPage: InstagramPageType,
    selectInstagramPages: (res: any) => void,
    updateFB_Pages: (res: any) => void
}

const InstagramView: React.FC<InstagramProps> = (props: any) => {

    const {
        selectedInstaPage,
        selectInstagramPages,
        selectedFBPage,
        updateFB_Pages,
    } = props;
    const [instagramPages, setInstagramPages] = useState<any>(null);


    const [loginStatusUpdate, setLoginStatusUpdate] = useState<any>(false)

    async function handleConnectButton() {
        try {
            if (selectedFBPage) {
                selectInstagramPages()
            } else {
                if (!fbLoggedIn()) {
                    await loginWithFB()
                    setLoginStatusUpdate(true)
                }
                updateFB_Pages()
            }

        } catch (e) {
            setLoginStatusUpdate(false)
            console.error("error logging in with FB", e)
        }
    }

    let followerCount = null
    if (selectedInstaPage) {
        if (selectedInstaPage.businessAccount) {
            followerCount = selectedInstaPage.businessAccount.followers_count
        } else if (selectedInstaPage.instagramAccount) {
            followerCount = selectedInstaPage.instagramAccount.followed_by_count
        } else {
            followerCount = null
        }
    } else {
        followerCount = null
    }

    return (
        <div>
            {fbLoggedIn() && selectedInstaPage?.connected_instagram_account ? (
                <EditPlatformContainer>
                    <div className="pl-6 pr-3 text-fontColor-100 text-right">
                        <p className="text-fontColor-100">
                            {selectedInstaPage?.connected_instagram_account.username}
                        </p>
                        {followerCount && <p className="text-xs text-fontColor-100">{millify(followerCount)} Followers</p>}
                    </div>
                    <LinkButton onClick={selectInstagramPages} text='Edit' />
                </EditPlatformContainer>
            ) : (
                <div>
                    <div>
                        {instagramPages && instagramPages.length > 0 ? (
                            <div>
                                <div className="flex items-center ">
                                    <p className="pl-6 pr-3 text-fontColor-100">
                                        {instagramPages && instagramPages.name}
                                    </p>
                                    <a onClick={selectInstagramPages}>
                                        <img
                                            src={
                                                require("../../assets/images/right-arrow.svg")
                                                    .default
                                            }
                                        />
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <LinkButton text='Connect' onClick={handleConnectButton}/>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default InstagramView;