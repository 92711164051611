import { Theme, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';
import { MEDIA_BREAKPOINTS } from 'types/global'; 

export const useStyles = makeStyles((theme: Theme) => ({
  svgError: {
    '& path': {
      stroke: SystemColors.ERROR_COLOR,
    },
  },
  svgWarning: {
    '& path': {
      stroke: SystemColors.WARNING_COLOR,
    },
  },
}));

export enum Severity {
  WARNING = 'warning',
  ERROR = 'error',
}

interface MainContainerProps {
  severity: Severity;
  borderRadius?: string;
  flexDirection?: string;
  alignItems?: string;
  gap?: string;
}

interface TextContainerProps {
  gap?: string;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  gap: ${({ gap }) => gap || '24px'};
  padding: 16px 24px;
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ severity }) =>
    (severity === Severity.WARNING ?
      'linear-gradient(0deg, rgba(228, 144, 13, 0.08), rgba(228, 144, 13, 0.08)), #FFFFFF;'
      : 'linear-gradient(0deg, rgba(255, 0, 0, 0.16), rgba(255, 0, 0, 0.16)), #FFFFFF;'
    )
  };
`;

export const TextContainer = styled.div<TextContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ gap }) => gap || '8px'};
  width: 100%;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

export const CampaignModalItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  padding: 32px 0;
  border-bottom: 1px solid ${SystemColors.DIVIDER_LINE};
  ${MEDIA_BREAKPOINTS.mobileView} {
    flex-direction: column;
  }
`

export const CampaignImg = styled.img`
  width: 48px;
  height: auto;
  border-radius: 10px;
`

export const CampaignInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const PresaveCampaignList = styled.div`
  display: flex;
  flex-direction: column;
`

export const CampaignData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`