import { FunctionComponent } from "react"
import { Chip } from "@material-ui/core"
import { SystemColors } from "types/globalStyles"
import { Body2, Subtitle2 } from "components/shareable/Typography"
import clsx from "clsx"
import useStyles, { AnnuallyContainer, BillingRadio } from "./styles"
import { CurrencyCodesType } from "types/global"
import getSymbolFromCurrency from "currency-symbol-map"
import { getNumberWithDecimals } from "helpers/General"

interface SimpleBillingChipProps {
    isActive: boolean
    onClick: () => void
    isMobileView?: boolean
    currency: CurrencyCodesType
    monthlyPrice: number;
}

export const SimpleBillingChip: FunctionComponent<SimpleBillingChipProps> = ({
    isActive,
    onClick,
    isMobileView,
    currency,
    monthlyPrice,
}: SimpleBillingChipProps) => {
    const classes = useStyles({ isMobileView })

    const color = isActive
        ? SystemColors.ACCENT_COLOR
        : SystemColors.PRIMARY_TEXT_COLOR

    // final prices that are shown

    let formattedMonthly = getNumberWithDecimals(monthlyPrice, 2)

    const MonthlyLabel = (
        <AnnuallyContainer>
            <Body2 color={color}>Monthly</Body2>
            <Subtitle2 color={color}>
                {`${getSymbolFromCurrency(currency)}${formattedMonthly}`}
            </Subtitle2>
        </AnnuallyContainer>
    )

    return (
        <Chip
            className={clsx(
                classes.billingChip,
                isActive && classes.billingChipActive
            )}
            icon={<BillingRadio style={{ color }} checked={isActive} />}
            label={MonthlyLabel}
            onClick={onClick}
        />
    )
}
