import UnlockDataBanner from "components/shareable/UnlockDataBanner";
import UnlockDataImage from "assets/images/unlock-data-geographies.svg";
import GeographiesDetails from "./GeographiesDetails";
import UpgradeToProChip from "components/shareable/UpgradeToProChip";


interface GeographiesProps {
    isProUser: boolean,
    selectedGeographiesTab: string,
    onGeographiesClickTab: (event: any) => void,
    hideBannerImage: boolean,
    countryGeographies: any,
    cityGeographies: any
}
export default function Geographies(props: GeographiesProps) {
    const {
        isProUser,
        selectedGeographiesTab,
        onGeographiesClickTab,
        hideBannerImage,
        cityGeographies,
        countryGeographies
    } = props
    return (
        <div className={`px-6 lg:px-10`}>
            <div className="">
                <p className="text-lg font-semibold flex flex-row gap-1 items-center">Geographies Breakdown <UpgradeToProChip type="gem" color="purple" width={16} height={16} /></p>
                <p className="text-md text-gray-500">The top cities and countries your fans are pre-saving from.</p>
            </div>
            <div className="py-5 pt-3">
                <div id="children-tabs" className="radio-toolbar">
                    <input
                        type="radio"
                        id="country"
                        name="dataview-type-2"
                        value="country"
                        onChange={onGeographiesClickTab}
                        checked={selectedGeographiesTab === 'country'}
                    />
                    <label
                        style={{
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                        }}
                        className="w-full text-center border border-primary"
                        htmlFor="country"
                    >
                        Top Countries
                    </label>
                    <input
                        type="radio"
                        id="city"
                        name="dataview-type-3"
                        value="city"
                        onChange={onGeographiesClickTab}
                        checked={selectedGeographiesTab === 'city'}
                    />
                    <label
                        style={{
                            borderTopRightRadius: 10,
                            borderBottomRightRadius: 10,
                        }}
                        className="w-full text-center border border-primary"
                        htmlFor="city"
                    >
                        Top Cities
                    </label>
                </div>
            </div>
            {
                !isProUser && (
                    <div className="py-5 pt-0">
                        <UnlockDataBanner
                            source="Presave Campaign Details - Geographies"
                            title="Learn exactly who your fans are"
                            description="Go Pro to see geographic information on your fans, down to the city"
                            image={UnlockDataImage}
                            hideImage={hideBannerImage}
                        />
                    </div>
                )
            }
            {
                isProUser && (
                    <GeographiesDetails
                        shown
                        geographiesBreakdown={
                            selectedGeographiesTab === "country"
                                ? countryGeographies
                                : cityGeographies
                        }
                    />
                )
            }
        </div>
    )
}