import styled from "styled-components";

interface BadgeContainerProps {
  backgroundColor?: string;
}

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  background-color: ${({backgroundColor}: BadgeContainerProps) => backgroundColor || "none"};
  border-radius: 4px;
`;