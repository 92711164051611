import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const StreamingLinkIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "30", height = "30", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4388 1.5619C16.3558 -0.520634 12.9786 -0.520634 10.8961 1.5619L7.91996 4.53676C8.1556 4.51109 8.39362 4.50007 8.63288 4.50007C9.38598 4.50007 10.1184 4.61977 10.8118 4.85039L12.4983 3.16465C13.0775 2.58484 13.8478 2.26619 14.6669 2.26619C15.4861 2.26619 16.2564 2.58484 16.8355 3.16465C17.4148 3.74321 17.7333 4.51234 17.7333 5.33255C17.7333 6.15165 17.4148 6.92189 16.8355 7.50045L13.536 10.8C12.9567 11.38 12.1865 11.6986 11.3674 11.6986C10.547 11.6986 9.77802 11.38 9.19822 10.8002C8.91626 10.5194 8.69652 10.1923 8.54516 9.83578C8.16918 9.85656 7.8176 10.0128 7.54902 10.2813L6.67009 11.1613C6.91062 11.6068 7.21936 12.0267 7.59534 12.4041C9.67788 14.4868 13.0555 14.4868 15.1385 12.4041L18.4385 9.10336C20.5205 7.02096 20.5205 3.64459 18.4386 1.56202L18.4388 1.5619Z" fill="white" />
                <path d="M11.4029 15.4989C10.6485 15.4989 9.90881 15.3768 9.19954 15.1363L7.50152 16.8343C6.92296 17.4141 6.15268 17.7328 5.33362 17.7328C4.51452 17.7328 3.74553 17.4143 3.16572 16.8343C2.58591 16.2556 2.26726 15.4855 2.26726 14.6664C2.26726 13.8473 2.58591 13.077 3.16572 12.4973L6.46523 9.19778C7.04503 8.61922 7.81406 8.30057 8.63313 8.30057C9.45348 8.30057 10.2225 8.61922 10.8016 9.19778C11.0837 9.47974 11.3038 9.80691 11.4559 10.1634C11.8331 10.1438 12.1853 9.98631 12.4538 9.71787L13.3315 8.83895C13.091 8.39207 12.7816 7.97227 12.405 7.59505C10.3225 5.51251 6.94482 5.51251 4.86224 7.59505L1.56273 10.8958C-0.520911 12.9784 -0.520911 16.3546 1.56273 18.4386C3.64527 20.5212 7.0215 20.5212 9.10407 18.4386L12.0752 15.4674C11.8536 15.488 11.6297 15.4992 11.4038 15.4992L11.4029 15.4989Z" fill="white" />
            </svg>
        </IconHolder>
    );
};
