/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  useContext,
} from "react"
import "styles/react-datepicker.css"

import dayjs from "dayjs"
import BudgetCard from "./Components/BudgetCard"
import DatesCard from "./Components/DatesCard"
import RunningForCard from "./Components/RunningForCard"
import { checkIfIsProTier } from "utils"
import { CurrentBrand } from "types/global"
import { Container } from "./styles"
import Grid from "@material-ui/core/Grid"
import { useMediaQuery, useTheme } from "@material-ui/core"
import { minimumDailyBudgetCalculator } from "../constants"
import { SpendBudgetContext } from "Hooks/BudgetTabSpendContext"
import { DEFAULT_CURRENCY } from "../../constants"

interface BudgetProps {
  budgetValue: number
  startDate: Date | null
  endDate: Date | null
  targetingType: string
  geographyType: string
  brand: CurrentBrand
  showReviewCampaignButton?: boolean
  setBudgetValue: Dispatch<SetStateAction<number>>
  setStartDate: Dispatch<SetStateAction<Date | null>>
  setEndDate: Dispatch<SetStateAction<Date | null>>
  finishFlow: () => void
}

export default function BudgetTab({
  targetingType,
  geographyType,
  budgetValue,
  startDate,
  endDate,
  brand,
  showReviewCampaignButton,
  setBudgetValue,
  setStartDate,
  setEndDate,
  finishFlow,
}: BudgetProps) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(769))
  const [currentEndSchedule] = useState("scheduled")
  const disableMobileButton = !(
    typeof budgetValue !== "undefined" &&
    budgetValue !== null &&
    budgetValue >= 5 &&
    startDate &&
    endDate
  )
  const isProUser = checkIfIsProTier(brand)
  const MIN_DAILY_BUDGET = minimumDailyBudgetCalculator(targetingType)

  const { onChangeBudget: handleChangeBudgetLimit, onChangeCurrency, currency } = useContext(SpendBudgetContext)

  const handleChangeBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= 0) {
      handleChangeBudgetLimit(value)
      setBudgetValue(value)
    }
  }

  const handleSelectStartDate = (date: Date | null) => setStartDate(date)

  const handleSelectEndDate = (date: Date | null) => setEndDate(date)

  // if the budget is updated, and start + end date is set
  // and min budget doesnt meet $5 / day, remove end date
  useEffect(() => {
    if (budgetValue && startDate && endDate) {
      const maxDays = budgetValue / MIN_DAILY_BUDGET
      const difference = dayjs(endDate).diff(dayjs(startDate), "day")

      if (difference > maxDays) {
        setEndDate(null)
      }
    }

    const brandCurrency = brand?.currency?.code || DEFAULT_CURRENCY
    if (currency === brandCurrency) return
    onChangeCurrency(brandCurrency)
  }, [budgetValue])

  return (
    <Container>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <BudgetCard
          {...{
            budgetValue,
            isProUser,
            targetingType,
          }}
          handleChangeInputText={handleChangeBudget}
        />
      </Grid>
      <Grid className={isMobile ? "mb-0" : "mb-4"}>
        <DatesCard
          {...{
            targetingType,
            startDate,
            endDate,
            currentEndSchedule,
            budgetValue,
            handleSelectStartDate,
            handleSelectEndDate,
          }}
        />
      </Grid>
      <RunningForCard
        {...{
          geographyType,
          targetingType,
          budgetValue,
          startDate,
          endDate,
          finishFlow,
          disableMobileButton,
          showReviewCampaignButton,
        }}
      />
    </Container>
  )
}
