import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  formErrorHelperText: {
    fontSize: 16,
    fontFamily: 'DIN',
    [theme.breakpoints.down(1024)]: {
      fontSize: 14,
    },
  },
  textField: {
    marginTop: theme.spacing(1.5),
    '& input': {
      fontFamily: 'DIN',
    },
    '& input::placeholder': {
      fontFamily: 'DIN',
    },
  },
  formHelperTextPropsRoot: {
    marginLeft: 0,
  },
  inputBorder: {
    borderRadius: 8,
  },
}));

export const HelperLink = styled.a`
  font-family: DIN;
  font-size: 18px;
  line-height: 26px;
  color: #707070;
  cursor: pointer;
`;

export const ErrorBanner = styled.div`
  padding: 16px;
  border-radius: 8px;
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  background: #ff000029;
`;

export default useStyles;
