import { SystemColors } from 'types/globalStyles';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  videoIconStroke: {
    '& path': {
      stroke: SystemColors.WHITE_TEXT_COLOR,
    },
  },
}));

interface VideoContainerProps {
  thumbnail: string;
}

export const VideoContainer = styled.article`
  position: relative;
  width: 100%;
  height: 472px;
  border-radius: 16px;
  padding: 16px;
  background-image: url(${({ thumbnail }: VideoContainerProps) => thumbnail});
  background-size: cover;
`;

export const VideoCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${SystemColors.DARK_VIDEO_HOVER_LAYOUT};
  border-radius: inherit;
  left: 0;
  top: 0;
`;

export const HeadlineContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  bottom: 28px;
  max-width: 90%;
`;
