import { makeStyles, Theme } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme: Theme) => ({
  chipLabelColor: {
    color: '#000000',
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px',
    '& .MuiChip-label': {
      paddingLeft: '0px',
      paddingRight: '8px',
    },
  },
  chipBackgroundColor: {
    backgroundColor: 'rgba(136, 0, 255, 0.08)',
  },
  button: {
    display: 'flex',
    color: '#ffffff',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'left',
    padding: '12px 16px',
  },
  buttonChip: {
    display: "flex",
    color: "#ffffff",
    width: "100%",
    height: "fit-content",
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '13px',
    cursor: 'pointer'
  },
  automatedSwitch: {
    marginTop: 4,
    padding: 0,
    height: 27,
    width: 43,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#E5E7EB',
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#8800FF',
        },
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#8800FF',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 24,
      height: 24,
      borderRadius: 34,
    },
    '& .MuiSwitch-track': {
      marginTop: 2,
      marginLeft: 1,
      borderRadius: 34,
      width: 42,
      height: 24,
      backgroundColor: '#E5E7EB',
      opacity: 1,
    },
  },
  rootDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '519px',
      borderRadius: '8px',
      [theme.breakpoints.down(701)]: {
        margin: 'auto 16px',
      },
      [theme.breakpoints.up(701)]: {
        margin: 'auto',
      },
    },
  },
  textField: {
    borderRadius: '6px',
    "& .MuiOutlinedInput-input": {
      padding: '14px 16px',
    }
  },
}));

interface DropDownBorderProps {
  backgroundColor?: string;
}

interface LanguageInputProps {
  width?: string;
}

interface TargetDemographicsContainerProps {
  backgroundColor?: string;
}

export interface ContainerProps {
  flexDirection?: string;
  gap?: string;
  display?: string;
  padding?: string;
  margin?: string;
  backgroundColor?: string;
  borderBottom?: string;
  alignItems?: string;
  width?: string;
  justifyContent?: string;
  height?: string;
  flexFlow?: string;
  overflowWrap?: string;
  cursor?: string;
  lineHeight?: string;
}

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  textAlign?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  fontFamily?: string;
  fontStyle?: string;
}

interface LanguageListProps {
  gap?: string;
}

interface DropDownLanguageListContainerProps {
  maxHeight?: string;
  position?: string;
  top?: string;
}

interface TargetingAgeGenderModalContainerProps {
  gap?: string;
  padding?: string;
}

export const DropDownBorder = styled.div`
  border: 1px solid #C8C7CC;
  border-radius: 6px;
  width: 100%;
  height: fit-content;
  background-color: ${({ backgroundColor }: DropDownBorderProps) => backgroundColor};
`;

export const DropDownButton = styled.div`
  padding: 6px 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-transform: none;
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: fit-content;
`;

export const DropDownIcon = styled.img`
  margin-left: auto;
  width: 22px;
  height: 22px;
`;

export const ItemContainer = styled.div`
  gap: ${({ gap }: ContainerProps) => gap || "0px"};
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  display: ${({ display }: ContainerProps) => display};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  margin: ${({ margin }: ContainerProps) => margin};
  padding: ${({ padding }: ContainerProps) => padding};
  background-color: ${({ backgroundColor }: ContainerProps) => backgroundColor};
  border-bottom: ${({ borderBottom }: ContainerProps) => borderBottom};
  width: ${({ width }: ContainerProps) => width};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
  height: ${({ height }: ContainerProps) => height};
  flex-flow: ${({ flexFlow }: ContainerProps) => flexFlow};
  overflow-wrap: ${({ overflowWrap }: ContainerProps) => overflowWrap};
  cursor: ${({ cursor }: ContainerProps) => cursor};
  line-height: ${({ lineHeight }: ContainerProps) => lineHeight};
`;

export const RowContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  align-items: center;
  gap: 4px;
  overflow-wrap: break-word;
  height: fit-content;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  width: ${({ width }: LanguageInputProps) => width};
`;

export const AutomatedTargetingBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 14px;
  background: linear-gradient(0deg, rgba(136, 0, 255, 0.08), rgba(136, 0, 255, 0.08)), #FFFFFF;
  border-radius: 8px;
`;

export const AutomatedTargetingDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8800FF;
`;

export const SelectLanguageButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;
  gap: 8px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #8800FF;
  cursor: pointer;
`;

export const TargetingAgeGenderModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ gap }: TargetingAgeGenderModalContainerProps) => gap || '40px'};
  padding: ${({ padding }: TargetingAgeGenderModalContainerProps) => padding || '40px 32px'};
`;

export const SaveTargetingButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  background: #8800FF;
  border-radius: 48px;
  width: 100%;
  color: #FFFFFF;
`;

export const ExitModalButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 16px;
  top: 16px;
  background: rgba(136, 0, 255, 0.04);
  border-radius: 51px;
`;

export const TargetingDemographicsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0px 0px;
  gap: 8px;
`;

export const TargetDemographicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  border: 1px solid #EDECF2;
  border-radius: 8px;
  width: 100%;
  background-color: ${({ backgroundColor }: TargetDemographicsContainerProps) => backgroundColor};
`;

export const TargetingDemograpgicsButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px 0px;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
`;

export const EditTargetingDemographicsButton = styled.button`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #8800FF;
  padding: 8px 0;
`;

export const DropDownInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
`;

export const DropDownTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-family: ${({ fontFamily }: StyledLabelProps) => fontFamily || 'DIN'};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  font-style: ${({ fontStyle }: StyledLabelProps) => fontStyle || 'normal'};
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
`;

export const LanguageList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({gap}: LanguageListProps) => gap || "8px" };
  overflow-y: auto;
  z-index: 100;
`;

export const DropDownListContainer = styled.div`
  position: ${({ position }: DropDownLanguageListContainerProps) => position || "absolute"};
  top: ${({ top }: DropDownLanguageListContainerProps) => top};
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  background: #FFFFFF;
  box-shadow: -3px 4px 9px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  max-height: ${({ maxHeight }: DropDownLanguageListContainerProps) => maxHeight || "371px"};
  width: 100%;
  z-index: 100;
`;

export const DropDownChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: linear-gradient(0deg, rgba(136, 0, 255, 0.08), rgba(136, 0, 255, 0.08)), #FFFFFF;
  border-radius: 32px;
  cursor: default;
`;

export const ErrorBanner = styled.div`
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 24px;
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.16), rgba(255, 0, 0, 0.16)), #FFFFFF;
  border-radius: 8px;
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

export default useStyles;