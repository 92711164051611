import dayjs from "dayjs";
import { useState } from "react";
import { LoadingIndicator } from "../Loader";

var decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    var element = document.createElement('div');

    function decodeHTMLEntities(str: string) {
        var string: any = str
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
            element.innerHTML = str;
            string = element.textContent;
            element.textContent = '';
        }

        return string;
    }

    return decodeHTMLEntities;
})();

interface RecordSelectorProps {
    content: Array<any>,
    onRecordSelected: (recordDetails: any) => void;
    filter?: string;
    searchString?: string;
    loadingSearch: boolean;
    connectYoutubeButton: any;
    youtubeConnected: boolean;

}
export default function VideoSelector(props: RecordSelectorProps) {

    const {
        content,
        onRecordSelected,
        filter,
        searchString = "",
        loadingSearch,
        connectYoutubeButton,
        youtubeConnected = false
    } = props

    // determines whether to a specific track is selected
    // const [showTrackDetailsForAlbumId, setTrackDetailsId] = useState<any>(null)

    return (
        <>
            <div className="lg:max-h-96 overflow-auto	">
                {!youtubeConnected ? (<div className="border-2 border-gray-200 rounded-md p-5 m-auto my-2 w-11/12 
                            lg:w-8/12">
                    <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row">
                            <img
                                width={50}
                                src={
                                    require("../../assets/images/youtube.svg").default
                                }
                                alt=""
                            />
                            <div className="text-center self-center">
                                <p className="text-left text-sm ml-4 mr-12">
                                    Connect <b>YouTube</b> to automatically pull your latest releases.
                                </p>
                            </div>
                        </div>

                        <div className="w-auto cursor-pointer">
                            <a
                                onClick={(e) => {
                                    e.preventDefault()
                                    connectYoutubeButton()
                                }}
                                className="text-primary text-sm"
                            >
                                Connect
                            </a>
                        </div>
                    </div>
                </div>) : null}

                {(!searchString || searchString === "") && content.length === 0 && !youtubeConnected ? null :
                    loadingSearch ? (
                        <div className="flex-col flex items-center m-8 ">
                            <LoadingIndicator height="80px" color="black" />
                            <p className="mt-1">Searching...</p>
                        </div>
                    ) :
                        content && content.length > 0 ? content
                            .sort((a: {
                                publishedAt: string
                            }, b: {
                                publishedAt: string
                            }) => {
                                return new Date(b.publishedAt).valueOf() - new Date(a.publishedAt).valueOf()
                            })
                            .map((videoItem: any, idx: number) => {
                                const {
                                    id: videoId,
                                    channelId,
                                    channelTitle,
                                    description,
                                    publishedAt,
                                    thumbnails,
                                    title: videoTitle
                                } = videoItem


                                const primaryThumbnail = thumbnails.default ? thumbnails.default.url :
                                    thumbnails.high ? thumbnails.high.url : null

                                const decodedTitle = decodeEntities(videoTitle)

                                return (
                                    <div key={`${videoId}-${idx}`} className="sy-card px-5 lg:px-10">
                                        <div className="flex items-center">
                                            <div className="w-20  flex-shrink-0">
                                                <img className="flex-shrink-0 rounded-md" src={primaryThumbnail} />
                                            </div>
                                            <div className="px-3" onClick={() => onRecordSelected({
                                                ...videoItem,
                                                primaryThumbnail: primaryThumbnail,
                                                name: decodedTitle
                                            })}>
                                                <h5 className="text-dark font-bold text-md flex flex-row items-center pb-0">{decodedTitle}</h5>
                                                <div className="flex flex-row items-center pb-0.5">
                                                    <div className=" my-0.5 h-5 w-auto flex items-center ">
                                                        <img
                                                        className="h-full w-auto"
                                                            src={
                                                                require("../../assets/images/streaming/icons/youtube-logo.svg")
                                                                    .default
                                                            }
                                                        />

                                                    </div>
                                                    <div className="social-text">
                                                        <p className="text-sm ml-1">
                                                            Channel: {channelTitle}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="text-xs">
                                                    Release Date: {dayjs(publishedAt).format("M/DD/YYYY")}
                                                </p>

                                            </div>
                                        </div>
                                        <div className="icon-block cursor-pointer" onClick={() => onRecordSelected({
                                            ...videoItem,
                                            primaryThumbnail: primaryThumbnail,
                                            name: decodedTitle

                                        })}>
                                            <img className="m-4" src={require('../../assets/images/right-arrow.svg').default} />
                                        </div>
                                    </div>

                                )
                            }) : (
                            null
                        )}

            </div>
        </>
    )
}