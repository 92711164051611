import { BadgeContainer } from "./styles";

interface Props {
  children?: (string | JSX.Element | undefined)[] | string | JSX.Element
  backgroundColor?: string;
}

const Badge = ({children, backgroundColor}: Props) => {
  return (
    <BadgeContainer backgroundColor={backgroundColor}>{children}</BadgeContainer>
  )
};

export default Badge;