import { ConnectorIndicatorTypes } from "types/global";

export enum AvgLabelPlatforms {
  Follower = "Follower",
  Stream = "Stream",
  Shazam = "Shazam",
}

const KEY_LABEL = "Avg. Cost Per";
const { Shazam, Stream, Follower } = AvgLabelPlatforms;

const labels = {
  SPOTIFY_STREAMS: `${KEY_LABEL} ${Stream}`,
  SHAZAMS: `${KEY_LABEL} ${Shazam}`,
  SPOTIFY_FOLLOWERS: `${KEY_LABEL} ${Follower}`,
  SPOTIFY_PLAYLIST_FOLLOWERS: `${KEY_LABEL} ${Follower}`,
  INSTAGRAM_FOLLOWERS: `${KEY_LABEL} ${Follower}`,
  NONE: "",
  INSTAGRAM_POST_REACH: `${KEY_LABEL} ${Follower}`,
  INSTAGRAM_POST_TOTAL_INTERACTIONS: `${KEY_LABEL} ${Follower}`,
  INSTAGRAM_PROFILE_REACH: `${KEY_LABEL} ${Follower}`,
  INSTAGRAM_PROFILE_VISITS: `${KEY_LABEL} ${Follower}`,
};

export const getAvgCostPerFollowerMainLabel = (type: ConnectorIndicatorTypes) =>
  labels[type];
