import Axios from "helpers/Interceptor"

export default async function getBrandContent(brandSlug: string, contentSlug: string) {
    try {
        const getData = await Axios.get(`/brand-content/${brandSlug}/${contentSlug}`)
        if (getData.data?.data) {
            return getData.data.data
        }
    } catch (error) {
        console.log("Error getting Brand content", error)
        return null
    }
}