import {
  useState,
  Dispatch,
  SetStateAction,
  FunctionComponent,
  useMemo,
  useEffect,
  ChangeEvent,
  Fragment,
} from "react"
import {
  ArtistLabel,
  BackArrowImage,
  BackButtonContainer,
  CheckImage,
  ConnectBrandButton,
  ConnectFanbaseContainer,
  ConnectFanbaseMainTitleLabel,
  ConnectFanbaseSubTitleLabel,
  ConnectFanbaseTitlesContainer,
  ConnectFanbaseTitlesLabelsContainer,
  ConnectingLine,
  ConnectLabel,
  ConnectPlatformArtistInfo,
  ConnectPlatformContainer,
  ConnectPlatformInfo,
  PlatformLabel,
  SpotifyLogoContainer,
  MainBackground,
  PlatformsContainer,
  PlatformLogoContainer,
  TickContainer,
  ConnectButtonContainer,
  StyledLabel,
  BrandNameTextInput,
  BrandNameContainer,
} from "./style"
import CheckTick from "assets/images/check-black.svg"
import GrayArrow from "assets/images/arrow-right-icon.svg";
import { ReactComponent as SpotifyIcon } from "assets/images/streaming/square-icons/spotify-sqr.svg"
import { ReactComponent as YoutubeIcon } from "assets/images/youtube.svg"
import { ReactComponent as TiktokIcon } from "assets/images/tiktok-bordered-icon.svg"
import { ReactComponent as InstagramIcon } from "assets/images/insta.svg"
import SpotifyConnect from "./Components/ConnectPlatformModal/Spotify"
import YouTubeConnect from "./Components/ConnectPlatformModal/Youtube"
import TikTokConnect from "./Components/ConnectPlatformModal/TikTok"
import { OnboardingArtist, SpotifyArtist, YouTubeChannel } from "types/global"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useStyles } from "./style"
import { useMediaQuery, useTheme } from '@material-ui/core';
import { track } from "analytics"
import InstagramBusinessDiscoveryConnect from './Components/ConnectPlatformModal/InstagramBusinessDiscovery';
import { SEARCH_SOURCE_RESULTS } from "pages/post-auth/AddArtist";
import { NAME_REG_EXP } from "helpers/StreamingMappings";

interface Props {
  artist: OnboardingArtist | null
  showConnectModal: string
  tiktokArtist: { url: string; username: string } | null
  spotifyArtist: SpotifyArtist | null
  selectedYoutubeChannel: YouTubeChannel | null
  youtubeSearchResults: YouTubeChannel[]
  isLoadingArtistDetails: boolean
  selectYoutubeChannel: Dispatch<SetStateAction<YouTubeChannel>>
  setYoutubeSearchResult: Dispatch<SetStateAction<YouTubeChannel[]>>
  selectedInstagramPage: any
  setInstagramPage: Dispatch<SetStateAction<any>>
  setConnectModal: Dispatch<SetStateAction<string>>
  handleGoBack: () => void
  handleGoNext: () => void
  searchResults: any
  setSearchResult: any,
  searchSourceResults: string,
  setSearchSourceResults: (source: SEARCH_SOURCE_RESULTS) => void
  setArtist: any,
  setSpotifyArtist: any
  handleShowAlreadyExistsModal: (artist: OnboardingArtist) => void
  selectTiktokArtist: (selectedTiktokArtist: { url: string; username: string }) => void
  setBrandName: Dispatch<SetStateAction<string>>;
  newOnboarding: boolean;
}

const ConnectTab: FunctionComponent<Props> = ({
  artist,
  showConnectModal,
  selectedYoutubeChannel,
  youtubeSearchResults,
  tiktokArtist,
  spotifyArtist,
  isLoadingArtistDetails,
  setConnectModal,
  selectYoutubeChannel,
  selectedInstagramPage,
  setInstagramPage,
  handleGoBack,
  handleGoNext,
  searchResults,
  setSearchResult,
  searchSourceResults,
  setSearchSourceResults,
  setArtist,
  setSpotifyArtist,
  handleShowAlreadyExistsModal,
  selectTiktokArtist,
  setBrandName,
  newOnboarding
}: Props) => {
  const theme = useTheme();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const classes = useStyles()
  const [youtubeSearchString, setYoutubeSearchString] = useState("")
  const [spotifySearchString, setSpotifySearchString] = useState("")
  const [, setTikTokSearchString] = useState("")
  const [, setInstagramSearchString] = useState("")
  const [, setSpotifySearchResult] = useState<any>([])
  const [textValue, setTextValue] = useState<string>("")
  const [showNameError, setShowNameError] = useState<boolean>(false)
  const [hasModifyName, setHasModifyName] = useState<boolean>(false)

  const youtubeChannelName =
    selectedYoutubeChannel?.snippet?.customUrl ||
    selectedYoutubeChannel?.snippet?.title ||
    ""

  const initialYouTubeSearchString = selectedYoutubeChannel?.searchUrl || ""

  const updateSpotifyArtist = (artist: string) => () => {
    setSpotifySearchString(artist)
    setConnectModal("spotify")
  }

  const updateYoutubeChannel = (username: string) => () => {
    setYoutubeSearchString(initialYouTubeSearchString || username)
    setConnectModal("youtube")
  }

  const updateTikTokArtist = (artist: string) => () => {
    setTikTokSearchString(artist)
    setConnectModal("tiktok")
  }

  const updateInstagramPage = (artist: string) => () => {
    setInstagramSearchString(artist)
    setConnectModal("instagram")
  }

  const handleShowModal = (type: string) => () => setConnectModal(type)

  const hasConnectedSocialPlatforms = useMemo(() => {
    return (Boolean(tiktokArtist) || Boolean(spotifyArtist) || Boolean(selectedYoutubeChannel) || Boolean(selectedInstagramPage))
  }, [tiktokArtist, spotifyArtist, selectedYoutubeChannel, selectedInstagramPage]);

  const getDefaultName = () => {
    if (spotifyArtist)
      return spotifyArtist.name;

    if (selectedInstagramPage)
      return selectedInstagramPage.username || selectedInstagramPage.name

    if (tiktokArtist)
      return tiktokArtist.username

    if (selectedYoutubeChannel)
      return selectedYoutubeChannel.snippet?.title
    return "";
  }

  useEffect(() => {
    if(!spotifyArtist && !hasModifyName &&
      (selectedInstagramPage || tiktokArtist || selectedYoutubeChannel)) {
      const defaultName = getDefaultName()
      validateNameInput(defaultName)
    }
  }, [selectedInstagramPage, tiktokArtist, selectedYoutubeChannel, hasModifyName])

  useEffect(() => {
    if(spotifyArtist) setBrandName(spotifyArtist.name);
  }, [spotifyArtist])

  const validateNameInput = (value: string = '') => {
    if (value.trim().length) {
      const regex = new RegExp(NAME_REG_EXP)
      const isValid = regex.test(value.replace(' ', '')) && value.length < 90
      setShowNameError(!isValid)
    } else {
      setShowNameError(true)
    }
    setTextValue(value)
    setBrandName(value)
  }

  const handleOnChangeTextInput = (e: ChangeEvent<HTMLInputElement>) => {
    setHasModifyName(true);
    validateNameInput(e.target.value)
  }

  return (
    <Fragment>
        <ConnectFanbaseContainer
          gap={setResponsiveView ? "32px" : "48px"}
          margin={setResponsiveView ? "0px" : "32px auto"}
          padding={setResponsiveView ? "32px 24px" : "32px"}
        >
          <ConnectFanbaseTitlesContainer>
            <BackButtonContainer onClick={handleGoBack}>
              <BackArrowImage src={GrayArrow} />
            </BackButtonContainer>
            <ConnectFanbaseTitlesLabelsContainer>
              <ConnectFanbaseMainTitleLabel>
                {newOnboarding && `Last step: `}Connect your fanbase
              </ConnectFanbaseMainTitleLabel>
              <ConnectFanbaseSubTitleLabel>
                To help Symphony build the most effective audiences for your
                marketing campaigns, please connect your streaming services and
                social media profiles.
              </ConnectFanbaseSubTitleLabel>
            </ConnectFanbaseTitlesLabelsContainer>
          </ConnectFanbaseTitlesContainer>
          {!spotifyArtist && (
            <BrandNameContainer>
              <StyledLabel
                fontFamily="DIN"
                fontWeight={400}
                fontSize={16}
                lineHeight="19px"
              >
                Profile name
              </StyledLabel>
              <BrandNameTextInput
                padding="24px 16px"
                value={textValue}
                placeholder={textValue}
                onChange={handleOnChangeTextInput}
                maxLength={90}
              />
              {showNameError && (
                <StyledLabel
                  fontFamily="DIN"
                  fontWeight={400}
                  fontSize={16}
                  lineHeight="19px"
                  color="#FF3D3D"
                >
                  Please enter at least 3 characters.
                </StyledLabel>
              )}
            </BrandNameContainer>
          )}
          <PlatformsContainer paddingLeft={setResponsiveView ? "0" : "32px"}>
            {spotifyArtist && (
              <>
                <ConnectingLine
                  height="280px"
                  left={setResponsiveView ? "32px" : "64px"}
                />
                <ConnectPlatformContainer order={-10}>
                  <SpotifyLogoContainer>
                    <PlatformLogoContainer width="64px" height="64px">
                      <SpotifyIcon height="64px" width="64px" />
                    </PlatformLogoContainer>
                    <TickContainer>
                      {" "}
                      <CheckImage src={CheckTick} />{" "}
                    </TickContainer>
                  </SpotifyLogoContainer>
                  <ConnectPlatformInfo>
                    <ConnectPlatformArtistInfo>
                      <PlatformLabel>Spotify</PlatformLabel>
                      <ArtistLabel>{spotifyArtist?.name || ""}</ArtistLabel>
                    </ConnectPlatformArtistInfo>
                    {isLoadingArtistDetails && (
                      <CircularProgress
                        size={24}
                        classes={{
                          colorPrimary: classes.colorPrimary,
                        }}
                      />
                    )}
                    {!isLoadingArtistDetails && (
                      <ConnectLabel
                        onClick={updateSpotifyArtist(spotifyArtist?.name)}
                      >
                        Edit
                      </ConnectLabel>
                    )}
                  </ConnectPlatformInfo>
                </ConnectPlatformContainer>
              </>
            )}
            <ConnectPlatformContainer>
              <SpotifyLogoContainer>
                <PlatformLogoContainer
                  backgroundColor="#FF0000"
                  width="64px"
                  height="64px"
                >
                  <YoutubeIcon height="48px" width="48px" />
                </PlatformLogoContainer>
                {selectedYoutubeChannel && selectedYoutubeChannel.id && (
                  <TickContainer>
                    {" "}
                    <CheckImage src={CheckTick} />{" "}
                  </TickContainer>
                )}
              </SpotifyLogoContainer>
              <ConnectPlatformInfo>
                <ConnectPlatformArtistInfo>
                  <PlatformLabel>Youtube</PlatformLabel>
                  <ArtistLabel>
                    {selectedYoutubeChannel && selectedYoutubeChannel.id
                      ? selectedYoutubeChannel.snippet.title
                      : ""}
                  </ArtistLabel>
                </ConnectPlatformArtistInfo>
                {isLoadingArtistDetails && (
                  <CircularProgress
                    size={24}
                    classes={{
                      colorPrimary: classes.colorPrimary,
                    }}
                  />
                )}
                {!isLoadingArtistDetails && selectedYoutubeChannel?.id && (
                  <ConnectLabel
                    onClick={updateYoutubeChannel(youtubeChannelName)}
                  >
                    Edit
                  </ConnectLabel>
                )}
                {!isLoadingArtistDetails && !selectedYoutubeChannel?.id && (
                  <ConnectLabel onClick={handleShowModal("youtube")}>
                    Connect
                  </ConnectLabel>
                )}
              </ConnectPlatformInfo>
            </ConnectPlatformContainer>
            <ConnectPlatformContainer>
              <SpotifyLogoContainer>
                <PlatformLogoContainer
                  backgroundColor="#19191A"
                  width="64px"
                  height="64px"
                >
                  <TiktokIcon height="38px" width="38px" />
                </PlatformLogoContainer>
                {tiktokArtist && tiktokArtist.username && (
                  <TickContainer>
                    {" "}
                    <CheckImage src={CheckTick} />{" "}
                  </TickContainer>
                )}
              </SpotifyLogoContainer>
              <ConnectPlatformInfo>
                <ConnectPlatformArtistInfo>
                  <PlatformLabel>TikTok</PlatformLabel>
                  <ArtistLabel>
                    {tiktokArtist && tiktokArtist.username
                      ? tiktokArtist.username
                      : ""}
                  </ArtistLabel>
                </ConnectPlatformArtistInfo>
                {isLoadingArtistDetails && (
                  <CircularProgress
                    size={24}
                    classes={{
                      colorPrimary: classes.colorPrimary,
                    }}
                  />
                )}
                {!isLoadingArtistDetails && tiktokArtist?.username && (
                  <ConnectLabel
                    onClick={updateTikTokArtist(tiktokArtist.username)}
                  >
                    Edit
                  </ConnectLabel>
                )}
                {!isLoadingArtistDetails && !tiktokArtist?.username && (
                  <ConnectLabel onClick={handleShowModal("tiktok")}>
                    Connect
                  </ConnectLabel>
                )}
              </ConnectPlatformInfo>
            </ConnectPlatformContainer>
            <ConnectPlatformContainer order={Boolean(spotifyArtist) ? 10 : -10}>
              {!spotifyArtist && (
                <ConnectingLine
                  height="180px"
                  left={setResponsiveView ? "32px" : "64px"}
                />
              )}
              <SpotifyLogoContainer>
                <PlatformLogoContainer width="64px" height="64px">
                  <InstagramIcon height="64px" width="64px" />
                </PlatformLogoContainer>
                {selectedInstagramPage && selectedInstagramPage.username && (
                  <TickContainer>
                    {" "}
                    <CheckImage src={CheckTick} />{" "}
                  </TickContainer>
                )}
              </SpotifyLogoContainer>
              <ConnectPlatformInfo>
                <ConnectPlatformArtistInfo>
                  <PlatformLabel>Instagram</PlatformLabel>
                  <ArtistLabel>
                    {selectedInstagramPage && selectedInstagramPage.username
                      ? selectedInstagramPage.name ||
                        selectedInstagramPage.username
                      : ""}
                  </ArtistLabel>
                </ConnectPlatformArtistInfo>
                {!isLoadingArtistDetails && selectedInstagramPage?.username && (
                  <ConnectLabel
                    onClick={updateInstagramPage(
                      selectedInstagramPage.username
                    )}
                  >
                    Edit
                  </ConnectLabel>
                )}
                {!isLoadingArtistDetails &&
                  !selectedInstagramPage?.username && (
                    <ConnectLabel onClick={handleShowModal("instagram")}>
                      Connect
                    </ConnectLabel>
                  )}
              </ConnectPlatformInfo>
            </ConnectPlatformContainer>
          </PlatformsContainer>
          <ConnectButtonContainer>
            <ConnectBrandButton
              onClick={handleGoNext}
              disabled={
                isLoadingArtistDetails ||
                !hasConnectedSocialPlatforms ||
                showNameError
              }
            >
              Create your Profile
            </ConnectBrandButton>
          </ConnectButtonContainer>
        </ConnectFanbaseContainer>
      <TikTokConnect
        open={showConnectModal === "tiktok"}
        closeModal={handleShowModal("")}
        selectedTiktokArtist={tiktokArtist}
        artist={artist}
        selectTiktokArtist={selectTiktokArtist}
        localSave={true}
      />
      <YouTubeConnect
        open={showConnectModal === "youtube"}
        setYoutubeSearchString={setYoutubeSearchString}
        youtubeSearchString={youtubeSearchString}
        youtubeSearchResults={youtubeSearchResults}
        selectYoutubeChannel={selectYoutubeChannel}
        closeModal={handleShowModal("")}
        artist={artist}
        selectedYoutubeChannel={selectedYoutubeChannel}
        localSave={true}
      />
      <SpotifyConnect
        open={showConnectModal === "spotify"}
        closeModal={handleShowModal("")}
        setSpotifySearchResult={setSpotifySearchResult}
        setSpotifySearchString={setSpotifySearchString}
        spotifySearchString={spotifySearchString}
        artist={spotifyArtist?.name || ""}
        onBoardingArtist={null}
        searchResults={searchResults}
        setSearchResult={setSearchResult}
        searchSourceResults={searchSourceResults}
        setSearchSourceResults={setSearchSourceResults}
        setArtist={setArtist}
        setSpotifyArtist={setSpotifyArtist}
        handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
      />
      <InstagramBusinessDiscoveryConnect
        open={showConnectModal === "instagram"}
        artist={artist}
        localSave={true}
        closeModal={handleShowModal("")}
        selectInstagramArtist={setInstagramPage}
        selectedInstagramArtist={selectedInstagramPage}
      />
    </Fragment>
  );
}

export default ConnectTab
