import { SystemColors } from 'types/globalStyles'
import { createTheme } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles(() => ({
  iconSecondaryColor: {
    '& path': {
      stroke: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
  iconDividerColor: {
    '& path': {
      stroke: SystemColors.DIVIDER_LINE,
    },
  },
  iconBackgroundSecondaryColor: {
    '& circle': {
      fill: SystemColors.SECONDARY_TEXT_COLOR,
    },
  },
}))

export const radioBtnColorTheme = createTheme({
  palette: {
    primary: {
      main: SystemColors.ACCENT_COLOR,
    },
  },
})

export const OptionItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid ${SystemColors.DIVIDER_LINE};
`

interface StyledChipProps {
  isLinkedVariant?: boolean
}

export const StyledChip = styled.div<StyledChipProps>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: ${(props) =>
    props.isLinkedVariant ? SystemColors.DARK_SUCCESS : SystemColors.DIVIDER_LINE};
  color: ${SystemColors.SECONDARY_TEXT_COLOR};
  font-size: 16px;
  gap: 8px;
  width: fit-content;
`

export const ExternalLink = styled.a`
  color: ${SystemColors.ACCENT_COLOR};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const AdAccountInvalidCtn = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${SystemColors.PAPER_ERROR_CONTAINER};
  padding: 8px;
  gap: 10px;
  font-size: 16px;
  color: ${SystemColors.SECONDARY_TEXT_COLOR};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

interface AdAccountCtnProps {
  isValid: boolean
}

export const AdAccountCtn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  color: ${({ isValid }: AdAccountCtnProps) => isValid && SystemColors.SECONDARY_TEXT_COLOR};
`
