import { Fragment, FunctionComponent, MouseEvent } from 'react';
import useStyles, {
  DropDownBorder,
  DropDownChipContainer,
  DropDownIcon,
  DropDownListContainer,
  ItemContainer,
  ListRelativeContainer,
  List as StyledList,
} from './styles';
import { Button } from '@material-ui/core';
import DropdownArrowIcon from "assets/images/chevronDown.svg";
import AutomatedIcon from 'assets/images/automated.svg';
import useVisible from 'Hooks/useVisible';
import { Body2 } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';

interface Props {
  value: string;
  items: Array<string>;
  setSelectedValue: (value: string) => void;
  disabled?: boolean;
  isMobile?: boolean;
  setWarning?: (value: boolean) => void;
  placeholder?: string;
  padding?: string;
}

const getMenuItems = (elements: Array<string>, onClickFunction: (value: string) => void) => {
  return elements.map((element: string, index: number) => {
    return (
      <ItemContainer
        display="flex"
        alignItems="center"
        justifyContent='left'
        height='35px'
        width='100%'
        borderBottom={index === elements.length - 1 ? 'none' : '1px solid rgba(0, 0, 0, 0.08)'}
        cursor='pointer'
        lineHeight='1'
        padding='8px 24px'
        onClick={() => onClickFunction(element)}
        key={`${element}-${index}`}
      >
        {element}
      </ItemContainer>
    );
  });
}

const DropdownString: FunctionComponent<Props> = ({
  value,
  items,
  setSelectedValue,
  disabled,
  isMobile,
  setWarning,
  placeholder,
  padding,
}: Props) => {

  const { targetContent, toggleElement, isVisible, setIsVisible } = useVisible(false);

  const handleOpenDropdown = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      setWarning?.(true);
      return;
    }
    setIsVisible(!isVisible);
  }

  const onClickItem = (value: string) => {
    setSelectedValue(value);
    setIsVisible(false);
  }

  const classes = useStyles({ padding: padding || '6px 12px' });

  return (
    <DropDownChipContainer>
      <DropDownBorder backgroundColor={disabled ? SystemColors.PAPER_DIVIDER_CONTAINER : SystemColors.PAPER_CONTAINER}>
        <Button
          classes={{ text: classes.buttonText }}
          className={classes.button}
          onClick={handleOpenDropdown}
          ref={toggleElement}
        >
          <Fragment>
            {value.length ? value : <Body2>{placeholder}</Body2>}
            <DropDownIcon
              className='visible'
              alt="dropdown-arrow.svg"
              src={disabled ? AutomatedIcon : DropdownArrowIcon}
              style={{ transform: isVisible ? 'rotate(-180deg)' : '' }}
            />
          </Fragment>
        </Button>
      </DropDownBorder>
      {isVisible && items.length > 0 &&
        <ListRelativeContainer ref={targetContent}>
          <DropDownListContainer maxHeight='191px' top='8px'>
            <StyledList gap={isMobile ? "4px" : "8px"}>
              {getMenuItems(items, onClickItem)}
            </StyledList>
          </DropDownListContainer>
        </ListRelativeContainer>
      }
    </DropDownChipContainer>
  );
};

export default DropdownString;