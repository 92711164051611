import { FunctionComponent, useState } from "react"
import PlanCard from "./Components/PlanCard"
import { useHistory } from "react-router"
import { Container, LeftColumn, RightColumn } from "./styles"
import { Steps } from "./data"

const Subscription: FunctionComponent = () => {
  const [step, setStep] = useState(Steps.SETUP_PLAN)
  const history = useHistory()

  const handleGoBack = () => {
    if (step === Steps.PAYMENTS_DETAILS) history.goBack()
    if (step === Steps.SETUP_PLAN) setStep(Steps.PAYMENTS_DETAILS)
    if (step === Steps.WELCOME) setStep(Steps.SETUP_PLAN)
  }

  return (
    <Container>
      <LeftColumn>
        <PlanCard {...{ step }} onGoBack={handleGoBack} />
      </LeftColumn>
      {/*TODO: Add Testimonials component when ready */}
      <RightColumn>Testimonials</RightColumn>
    </Container>
  )
}

export default Subscription
