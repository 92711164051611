import { Theme, withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const useStyles = makeStyles((_theme: Theme) => ({
    tableContainer: {
        borderRadius: 6,
        maxHeight: 458,
    },
    tableHead: {
        color: "#707070",
    },
    withBorderBottom: {
        borderBottom: "1px solid #c8c7cc",
    },
    noBorderBottom: {
        borderBottom: "none",
    },
    downloadInvoiceButton: {
        textTransform: "none",
        borderRadius: 48,
        backgroundColor: "#ffffff",
        color: "#8800FF",
        shadowBox: "none",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#8800FF",
        },
    },
}));

const GeneralPurpleButton = withStyles((theme: Theme) => ({
    root: {
        width: 'auto',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        textTransform: "none",
        borderRadius: 48,
        backgroundColor: "#8800FF",
        color: "#ffffff",
        height: 40,
        shadowBox: "none",
        "&:hover": {
            backgroundColor: "#8800FF",
            color: "#ffffff",
        },
     
        [theme.breakpoints.down('sm')]: {
            width: (props: { fullWidthOnMobile?: boolean }) =>
            props.fullWidthOnMobile ? "100%" : "auto",
        },

        '& .MuiButton-label': {
            whiteSpace: 'nowrap'
        }
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        fontWeight: 600,
    },
}))(Button);


export const GeneralOutlinedButton = withStyles((theme: Theme) => ({
    root: {
        width: 'auto',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        textTransform: "none",
        borderRadius: 48,
        border: "1px solid #8800FF",
        backgroundColor: "none",
        color: "#8800FF",
        height: 40,
        shadowBox: "none",
        "&:hover": {
            backgroundColor: "#8800FF",
            color: "#ffffff",
        },
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        fontWeight: 600

    },
}))(Button);

export const GeneralTextButton = withStyles((theme: Theme) => ({
    root: {
        width: 'auto',
       
        textTransform: "none",
        border: "none",
        color: "#8800FF",
        height: 40,
        shadowBox: "none",
  
    },
    text: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        fontWeight: 600

    },
}))(Button);


export default GeneralPurpleButton