import { Body2 } from 'components/shareable/Typography'
import { SystemColors } from 'types/globalStyles'
import { StyledWarningDiv } from './styles'

export const BusinessManagerWarningMessage = () => {
  return (
    <StyledWarningDiv>
      <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
        You can only connect a Business Manager you have admin access to.
      </Body2>
    </StyledWarningDiv>
  )
}
