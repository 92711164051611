import FacebookView from "../../FacebookView";
import { Fragment, useContext, useEffect, useState } from "react";
import CampaignCreationFacebookConnect from "../../../../components/connect/Facebook";
import InstagramView from "../../InstagramView";
import InstagramConnect from "../../../../components/connect/Instagram";
import FacebookBusinessManagerConnect from "../../../../components/connect/FacebookBusinessManager";
import FacebookGenericSelectorView from "../../FBGenericSelectorView";
import FacebookAdAccountConnect from "../../../../components/connect/FacebookAdAccount";
import FacebookPixelConnect from "../../../../components/connect/FacebookPixel";
import CloudinaryUploadWidget from "../../../../components/shareable/CloudinaryUploadWidget";
import { uploadAdVideo } from "../../../../helpers/Facebook";
import { fbLoggedIn, queryFB } from "../../../../helpers/FB";
import InfoSection from "../../../../components/InfoSection";
import { scrollIntoView, scrollToTop } from "helpers/StyleUtils";
import InstagramPosts from "../../../../components/shareable/InstagramPosts";
import { InstagramPost } from "../../../../components/shareable/InstagramPosts/types";
import { getRefreshInstagramPost, showNotFoundIGPostWarningToast, isValidURL } from "utils";
import { ConnectModalsContext } from "Hooks/ConnectModalsProvider";
import { ConversionsPlatforms, DataPlatform, getFromDraftDataSelectedPlatforms, getWebsiteLinkWithUsersConfigurationPreview } from "./utils";
import { AssetUploadTypeSelector, containsExplicitContent, getAdAccountDetails, getPreconnectionConfigurationsStatus, loadInstagramPosts, ShowFbSelectedNoIgSelectedState, UploadedAssetType, VideoUploader } from "../utils/fbCampaigns";
import { DebounceInput } from "react-debounce-input";
import ConversionsIndicator from "../Components/ConversionsIndicator";
import { useStyles, BannerHeader, ConversionsBannerContainer, PlatformDetails, PlatformContainer, CheckedIcon, PlatformIconContainer } from "./styles";
import { Body2, Caption, Subtitle2 } from "components/shareable/Typography";
import { ReactComponent as BoostsIcon } from "assets/images/campaignDetails/Boost.svg";
import ConversionsCallToAction from "../Components/ConversionsCallToAction";
import { CONVERSIONS_VIDEOS, InstagramPageType } from "types/global";
import LinkButton from "components/shareable/LinkButton";
import { ReactComponent as ExternalLinkIcon } from "assets/images/external-link.svg";
import { Container } from "styles/shared";
import SwitchButton from "components/shareable/SwitchButton";
import { SystemColors } from "types/globalStyles";
import Intercom from "helpers/Intercom";
import AcceptFBTermsOfServiceCard from "components/shareable/AcceptFBTermsOfServiceCard";
import { AccountCardContainer, TermsOfServiceContainer } from "./styles";
import { AddAccountInvalidCtn, ExternalLink } from "components/connect/styles";
import { SymphonyTooltip } from "styles/shared";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import ExternalLinkIconSVG from "assets/images/external-link.svg";
import { ColumnContainer } from "../styles";
import { CreativeAssetTypes } from "../constants";
import { Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Axios from "helpers/Interceptor";
import { ExpandMore } from "@material-ui/icons";

const siteIconsData = {
    deezer: require("../../../../assets/images/streaming/icons/deezer-icon.svg").default,
    spotify: require("../../../../assets/images/streaming/square-icons/spotify-sqr.svg").default,
    tidal: require("../../../../assets/images/streaming/small/tidal-bw.svg").default,
    youtube: require("../../../../assets/images/youtube.svg").default,
    apple_music: require("../../../../assets/images/apple_music.svg").default,
    amazonStore: require("../../../../assets/images/streaming/small/amazon-store.png").default,
    amazon_music: require("../../../../assets/images/streaming/small/amazon-bw.svg").default,
    instagram: require("../../../../assets/images/insta.svg").default,
    tiktok: require("../../../../assets/images/tiktok.svg").default,
    soundcloud: require("../../../../assets/images/soundcloud.svg").default,
    all: require("../../../../assets/images/symphony_website.svg").default,
    custom: require("../../../../assets/images/custom_link.svg").default,
}
const { UPLOAD_VIDEO, INSTAGRAM_POSTS } = CreativeAssetTypes

export default function CreativeTab(props: any) {
    const {
        brand,
        setInstagramPagesAvailable,
        creativeSelectorTab,
        setCreativeSelectorTab,
        setAddVisualSelected,
        previewedVideo,
        setPreviewedVideo,
        selectedRecord,
        setSelectedAssets,
        selectedAssets,
        setSpotifyAssets,
        selectedSpotifyAssets,
        setAppleAssets,
        selectedAppleAssets,
        setYoutubeAssets,
        selectedYoutubeAssets,
        selectedFB_page,
        setSelectedFB_page,
        selectedInstaPage,
        setSelectedInstaPage,
        selectedFBBusinessManager,
        setFBBusinessManager,
        selectedFBPixel,
        setFBPixel,
        selectedFBAdAccount,
        setFBAdAccount,
        reloadBrands,
        captions,
        connectionsType,
        selectedInstagramPost,
        setSelectedInstagramPost,
        preConnectionConfigurations,
        adConnectionsAdded,
        adConnectionsConfirmed,
        setSelectedPlatforms,
        selectedPlatforms,
        saveConnectModalType,
        campaignDraftData,
        setLinks,
        links,
        toggleConversionsIndicator,
        isConversionsEnabled,
        isConversionsSetUp,
        conversionsTasks,
        showConversionsError,
        showConversionsCTA,
        hideConversionsCTA,
        areTermsAccepted,
        handleOnChangePlatformsSwitch,
        platformsSwitch,
        enablePlatform,
        disablePlatform,
        time,
        startTimer,
        handleStartTimer,
        getTosConfiguration,
        setCaptionForService,
        setCaptionForAllSelectedServices
    } = props

    const { data } = useContext(ConnectModalsContext)
    const { loading: isConnectModalLoading } = data
    const classes = useStyles()
    const [showConnectModal, setConnectModal] = useState('');
    const [uploadQueue, setUploadQueue] = useState<any>(null)
    const [instagramPosts, setInstagramPosts] = useState<InstagramPost[]>([])
    const { id: pixelId } = brand.connections.facebook_pixel || {}

    useEffect(() => {
        fbLoggedIn()
        if (campaignDraftData) {
            const platforms = getFromDraftDataSelectedPlatforms(campaignDraftData)
            setSelectedPlatforms(platforms)
        }
    }, [])

    useEffect(() => {
        if (saveConnectModalType) saveConnectModalType(showConnectModal)
    }, [showConnectModal])

    // scroll to top if ad connections were added
    useEffect(() => {
        scrollToTop()
        scrollIntoView()
    }, [adConnectionsAdded])

    const loadIGPosts = async () => {
        if (selectedInstaPage) {
            const instagramPosts = await loadInstagramPosts({ instagramPage: selectedInstaPage })

            const hasInstagramPosts = Boolean(instagramPosts.length)
            const hasVideosUploaded = Boolean(selectedAssets?.length)

            if (hasInstagramPosts) {
                const mappedIgPosts = instagramPosts.map((post: InstagramPost) => {
                    const caption = post.caption || ''
                    var explicitContent = false
                    if (caption) {
                        explicitContent = containsExplicitContent(caption)
                    }

                    return {
                        ...post,
                        explicit_content: explicitContent
                    }
                })

                setInstagramPosts(mappedIgPosts)

                if (hasVideosUploaded) {
                    setCreativeSelectorTab(UPLOAD_VIDEO)
                    setAddVisualSelected(UPLOAD_VIDEO)
                } else {
                    setCreativeSelectorTab(INSTAGRAM_POSTS)
                    setAddVisualSelected(INSTAGRAM_POSTS)
                    // If there is not an selectedInstagramPost, default to selecting first post
                    const refreshSelectedInstaPost = getRefreshInstagramPost(selectedInstagramPost, instagramPosts);
                    setSelectedInstagramPost(refreshSelectedInstaPost);
                }
            } else {
                setCreativeSelectorTab(UPLOAD_VIDEO)
                setAddVisualSelected(UPLOAD_VIDEO)
                setInstagramPosts([])
            }

            const igPostFound = showNotFoundIGPostWarningToast(instagramPosts, selectedInstagramPost)
            if (!igPostFound) setSelectedInstagramPost(null)
        } else {
            setCreativeSelectorTab(UPLOAD_VIDEO)
            setInstagramPosts([])
        }
    }

    useEffect(() => {
        // TODO 630: switch validation for the commented one once we fix the connections issues
        if (preConnectionConfigurations?.status && !instagramPosts.length) {
            loadIGPosts()
        }
    }, [selectedInstaPage, preConnectionConfigurations])

    useEffect(() => {
        if (uploadQueue) {
            const { type, items } = uploadQueue
            if (type === 'all') {
                var existing = selectedAssets ? selectedAssets : []
                existing = existing.concat(items).slice(0, 2)
                setSelectedAssets(existing)
            } else if (type === 'spotify') {
                var existing = selectedSpotifyAssets ? selectedSpotifyAssets : []
                existing = existing.concat(items).slice(0, 2)
                setSpotifyAssets(existing)
            } else if (type === 'apple_music') {
                var existing = selectedAppleAssets ? selectedAppleAssets : []
                existing = existing.concat(items).slice(0, 2)
                setAppleAssets(existing)
            } else if (type === 'youtube') {
                var existing = selectedYoutubeAssets ? selectedYoutubeAssets : []
                existing = existing.concat(items).slice(0, 2)
                setYoutubeAssets(existing)
            }
            setUploadQueue(null)
        }
    }, [uploadQueue])

    const handleShowConnectModal = async (modal: string) => {
        if (selectedFBAdAccount && preConnectionConfigurations === null && modal) {
            const adAccountDetails = await getAdAccountDetails(selectedFBAdAccount)
            await getTosConfiguration(selectedFBAdAccount, adAccountDetails)
        }
        setConnectModal(modal);
    }

    const updateFB_Pages = async (response?: any) => {
        handleShowConnectModal('facebook');
    }

    const selectInstagramPages = async (instaData: any) => {
        handleShowConnectModal('instagram');
    }

    const selectInstagramPage = (item: any) => {
        setSelectedInstaPage(item);
        let insta_page = JSON.parse(JSON.stringify(item));
        localStorage.setItem('insta_page', JSON.stringify(insta_page))
    }

    function cancelUploadingAssets(key: string) {
        const currentPlatformsStatus = {
            ...selectedPlatforms
        }

        currentPlatformsStatus[key].uploadingAssets = false

        // update to selected ++ set the default caption for the service
        setSelectedPlatforms(currentPlatformsStatus)
    }

    const onClickCreativeSelectorTab = (evt: any) => {
        const tabClicked = evt.target.id;
        setCreativeSelectorTab(tabClicked)
        setAddVisualSelected(tabClicked)
    }

    async function uploadCreativeAssets(assets: Array<any>) {

        var uploadedAssets = assets.map((asset: any) => {
            return new Promise(async (resolve: any, reject: any) => {
                const { url } = asset
                try {
                    const uploadedVideo = await uploadAdVideo(url, selectedFBAdAccount.id)
                    resolve({
                        ...asset,
                        fb_id: uploadedVideo.id,
                        type: 'video'
                    })
                } catch (e) {
                    alert("Error uploading video - " + asset.name + `\n${e}`)
                    console.error('e', e)
                    resolve(null)
                }
            })
        })

        var uploadedItems = await Promise.all(uploadedAssets)

        uploadedItems = uploadedItems.filter(o => o !== null)

        return uploadedItems
    }

    const handleOnClickWebsitePreview = () => {
        const websiteUrl = getWebsiteLinkWithUsersConfigurationPreview({
            brand,
            selectedRecord,
            selectedPlatforms,
            isConversionsEnabled,
        })
        window.open(websiteUrl, '_blank')
    }

    let uploadingAssets = selectedPlatforms['all'].uploadingAssets

    // ===========================
    // CLOUDINARY WIDGET FUNCTIONS
    // ===========================

    // const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<Array<string>>([])
    const [cloudinaryUploadQueue, setCloudinaryUploadQueue] = useState<any[]>([]);
    /**
     * Adds an item to the Cloudinary upload queue, to denote that 
     */
    function addToCloudinaryUploadQueue(fileId: string) {
        setCloudinaryUploadQueue((previous) => previous.concat(fileId));
    }

    /**
    * Removes an item to the Cloudinary upload queue, to denote that 
    */
    function removeFromCloudinaryUploadQueue(fileId: string) {
        const newQueue = [
            ...cloudinaryUploadQueue
        ]

        const indexOfId = newQueue.indexOf(fileId)
        if (indexOfId > -1) {
            newQueue.splice(indexOfId, 1)
        }
        setCloudinaryUploadQueue(newQueue);
    }


    /**
     * Removes an item from the cloudinary upload queue
     * and moves it into the facebook upload queue to start uploading to fb
     */
    const successfulCloudinaryUpload = (file: any) => {
        const {
            info
        } = file

        const {
            id: fileId
        } = info

        removeFromCloudinaryUploadQueue(fileId)
        try {
            addToFacebookUploadQueue(file)
        } catch (e: any) {
            const errors = [...uploadErrors]
            errors.push(e)
            setUploadErrors(errors)
        }
    }


    // ===========================
    // FACEBOOK UPLOAD FUNCTIONS
    // ===========================

    const [uploadErrors, setUploadErrors] = useState<Array<{
        name: string,
        message: string
    }>>([])

    const [facebookUploadQueue, setFacebookUploadQueue] = useState<Array<any>>(selectedAssets || [])
    const [queueToUpload, setQueueToUpload] = useState<Array<any>>([]);

    /**
     * Adds an item to the FB upload queue, to denote that it is ready to be uploaded
     */
    const addToFacebookUploadQueue = (file: any) => {

        const {
            info,
        } = file

        const {
            thumbnail_url,
            asset_id,
            height,
            width,
            secure_url,
            original_filename: name,
            format
        } = info

        var sizeRatio = width / height

        // error handling
        //  - must be minimum with of 500px
        if (width < 500) {
            const error = new Error("Video must be wider than 500px.")
            error.name = `${name}.${format}`
            throw error
        }

        var type = 'feed'

        // IG Story Sizing:
        /**
         * 16/9 to 4/5
         * 9/16
         */

        // IG Feed Sizing:
        /**
         * 16/9 to 9/16
         */
        if (sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) {
            type = 'feed'
        } else if ((sizeRatio <= 16 / 9 &&
            sizeRatio >= 4 / 5) || sizeRatio < 9 / 16 || sizeRatio >= 996 / 2156) {
            type = 'story'
        } else {
            const error = new Error("Video isn't formatted for feed or story. Please make sure the video is sized in an aspect ratio of 4x5 or 16x9 for feed, or 9x16 for story.")
            error.name = `${name}.${format}`
            throw error
        }

        // upload the asset
        const formattedValue = {
            thumbnail: thumbnail_url,
            cloudinary_id: asset_id,
            height: height,
            width: width,
            name: name,
            url: secure_url,
            formatType: type,
            uploading: true,
            fb_id: null,
        }

        setQueueToUpload(previous => previous.concat(formattedValue))
        uploadToFb(formattedValue);
    }

    async function uploadToFb(file: any) {
        const {
            thumbnail,
            cloudinary_id,
            height,
            width,
            name,
            url,
            formatType,
            uploading,
            fb_id
        } = file

        let fbUploaded: any = await uploadCreativeAssets([
            {
                thumbnail,
                cloudinary_id,
                height,
                width,
                name,
                url,
                formatType
            }
        ])
        const fbUploadedValue = fbUploaded[0];
        setFacebookUploadQueue(previous => previous.concat(fbUploadedValue));
        setQueueToUpload((previous: any) => previous.filter((file: any) => file.cloudinary_id !== fbUploadedValue.cloudinary_id))
    }

    useEffect(() => {
        setSelectedAssets(facebookUploadQueue);
    }, [facebookUploadQueue])

    // Deletes an asset from the list
    function removeAsset(cloudinary_id: string) {
        const updatedWithoutAsset = selectedAssets?.filter((item: UploadedAssetType) => {
            return item.cloudinary_id !== cloudinary_id
        }) || []
        setFacebookUploadQueue(updatedWithoutAsset);
    }

    /**
     * If the user selects express setup, this will confirm whether or not they have access.
     * 
     * @param fbPageId 
     */
    async function expressSetupConfirmation(fbPageId: string) {
        // checks if Symphony business manager has appropriate access to
        // the user's FB page

        // (1) get access token for page
        const pageInfo = await queryFB(`/${fbPageId}`, {
            params: {
                fields: 'access_token'
            }
        });
        const pageAccessToken = pageInfo.access_token


        // (2) use access token to check agencies for fb page
        const agencyStatus = await queryFB(`/${fbPageId}/agencies`, {
            params: {
                fields: "name,id,permitted_tasks,access_status,access_requested_time",
                access_token: pageAccessToken
            }
        })


        const linkedAgencies = agencyStatus.data


        // 4 states:
        //  - not linked (call link button and show button to FB)
        //  - fully linked w/ permissions (good to go)
        //  - linked, but pending (show button to FB)
        //  - linked, but awaiting all permissions (cancel request + resend, then show button to FB)
        var hasProperPermissions = false
        var isPendingLink = false
        var isConfirmedLinked = false

        // ^^^ when hasProperPermissions && isConfirmedLinked === true, we're good to go
        // if no agencies avail -> not linked (call link button and show button to FB)
        // if hasProperPermissions & isConfirmedLinked -> fully linked w/ permissions (good to go)
        // if isPendingLink || isConfirmedLinked & !hasProperPermissions ->  (cancel request + resend, then show button to FB)
        // if isPendingLink && !isConfirmedLinked && hasProperPermissions -> (show button to FB)


        const symphonyBizId = process.env.REACT_APP_SYMPHONY_FB_BUSINESS_ID
        const requiredPermissions = [
            "ANALYZE",
            "ADVERTISE",
            "CREATE_CONTENT",
            "MODERATE"
        ]

        if (linkedAgencies.length > 0) {

            // check if the agency exists within the list
            const foundAgency = linkedAgencies.find((o: {
                id: string,
                name: string,
                access_status: string,
                permitted_tasks: any
            }) => {
                const found = o.id === symphonyBizId

                return found
            })



            // if not, make the call
            if (!foundAgency) {
                isConfirmedLinked = false
                isPendingLink = false
                hasProperPermissions = false
            } else {
                // if the agency exists, check if:
                // (1) access is confirmed ~ if not, show pending state
                // (2) the appropriate permissions levels are avail
                //
                const accessConfirmed = foundAgency.access_status === "CONFIRMED"

                var allPermissions = true
                // loop through the required permissions and make sure they exist
                // in the permitted_tasks
                requiredPermissions.map((permission: string) => {

                    const idxOfPermission = foundAgency.permitted_tasks.indexOf(permission)
                    if (idxOfPermission === -1) {
                        allPermissions = false
                    }
                })

                if (!accessConfirmed) {
                    isConfirmedLinked = false
                    isPendingLink = true
                } else {
                    isPendingLink = false
                    isConfirmedLinked = true
                }

                if (!allPermissions) {
                    hasProperPermissions = false
                } else {
                    hasProperPermissions = true
                }
            }



        } else {
            hasProperPermissions = false
            isPendingLink = false
            isConfirmedLinked = false
        }

        if (!(isConfirmedLinked && hasProperPermissions)) {
            if (isPendingLink && !hasProperPermissions) {
                // TODO: remove request and re-request
            } else if (!isPendingLink && !hasProperPermissions) {
                // send new request

                const requestAgencyAccess = await queryFB(`/${fbPageId}/agencies`, {
                    method: "POST",

                    params: {
                        access_token: pageAccessToken,
                        business: symphonyBizId,
                        permitted_tasks: requiredPermissions
                    }
                })


                if (requestAgencyAccess.success) {
                    // check again to see if it's fully linked
                    await expressSetupConfirmation(fbPageId)
                } else {
                    console.log('request agency access error', requestAgencyAccess)
                }
            }
        }
    }
    const theme = useTheme();
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));

    const onInstagramPageRefresh = async () => {
        if (!selectedFB_page) return false
        try {
            const results = await queryFB(`/${selectedFB_page.id}`, {
                params: {
                    fields: 'instagram_business_account{username,id,followers_count,profile_picture_url}',
                }
            })

            const { instagram_business_account } = results
            if (!instagram_business_account) {
                console.log("No Instagram Business Account")
                return false
            }

            await Axios.put(`/brand/${brand.slug}/connect`, {
                id: brand.id,
                name: brand.name,
                service: 'instagram_page',
                value: {
                    ...selectedInstaPage,
                    businessAccount: instagram_business_account,
                }
            })

            setSelectedInstaPage((prev: InstagramPageType) => ({ ...prev, businessAccount: instagram_business_account }))
            return true
        } catch (e) {
            console.log("Error refreshing Instagram Page", e)
            return false
        }
    }

    function showConversionsPopup() {
        if (setResponsiveView) return null

        if (isConversionsSetUp || showConversionsError) {
            return (
                <ConversionsIndicator
                    conversionsEnabledDescription="Your ads will be geared towards driving fans who stream."
                    isConversionsEnabled={isConversionsEnabled}
                    toggleConversionsIndicator={toggleConversionsIndicator}
                    pixelId={pixelId}
                    hasError={showConversionsError}
                    description="If enabled, Symphony will focus on converting ad clickers into real listeners."
                    adAccountId={selectedFBAdAccount?.id}
                    bottomDescription="Symphony automatically configures Symphony with FB’s Conversions to drive more music streams."
                    onIntercomClick={Intercom.openLearnHowConversionsArticle}
                />
            )
        } else if (showConversionsCTA) {
            return (
                <ConversionsCallToAction
                    padding="16px 0px"
                    conversionsTasks={conversionsTasks}
                    videoUrl={CONVERSIONS_VIDEOS.onboardingVideo.videoUrl}
                    hide={hideConversionsCTA}
                    enableCloseButton={false}
                    showCloseButtonAsIcon={false}
                    showAsRecommended
                />
            )
        }

        return null
    }

    const isPlaylistCampaign = selectedRecord.type === 'playlist'
    return (
        <div className="middle-wrapper">
            {!adConnectionsAdded || !adConnectionsConfirmed || showConversionsError || !areTermsAccepted ? (
                <>
                    {showConnectModal === '' && (
                        <>
                            <div className="sy-card px-5 lg:px-10">
                                <div className="">
                                    <h5 className="text-dark font-bold text-lg">Connect Accounts</h5>
                                    <p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                        To create the most optimal audiences to market "{selectedRecord.name}", we'll need to plug-in with your Facebook &amp; Instagram account. (You'll only have to do this one time.)
                                    </p>
                                </div>
                            </div>
                            {isConversionsSetUp && showConversionsPopup()}
                            {connectionsType === 'quick' && <Fragment>

                                {(!isConversionsEnabled || !isConversionsSetUp) && (
                                    <ColumnContainer padding="0">
                                        <AccountCardContainer>
                                            <div className="flex flex-none text-center">
                                                <div className="artist-img">
                                                    <img src={require('../../../../assets/images/facebook.svg').default} alt="facebook-logo" />
                                                    {selectedFBAdAccount && (
                                                        <div className="con-icon">
                                                            <img
                                                                className="absolute w-4 h-4 "
                                                                src={
                                                                    require("../../../../assets/images/tick.svg")
                                                                        .default
                                                                }
                                                                alt="tick"
                                                            />
                                                        </div>)}
                                                </div>
                                                <div className="px-3 items-center flex text-left">
                                                    <h5 className="text-dark">FB Ad Account</h5>
                                                </div>
                                            </div>
                                            <FacebookGenericSelectorView
                                                selected={selectedFBAdAccount}
                                                update={() => handleShowConnectModal('facebook-ad-account')}
                                            />
                                        </AccountCardContainer>
                                    </ColumnContainer>
                                )}
                                {Boolean(selectedFBAdAccount && preConnectionConfigurations && !getPreconnectionConfigurationsStatus(preConnectionConfigurations, 'account')) &&
                                    <ColumnContainer>
                                        <AddAccountInvalidCtn>
                                            <div>
                                                <p>{`This ad account's status is ${preConnectionConfigurations?.account_status?.name} and it cannot be used.`}</p>
                                                <ExternalLink
                                                    className="text-red-500 text-xs underline"
                                                    href={preConnectionConfigurations?.account_status?.action as string}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Review account
                                                    <img className="w-6 h-6" alt="Review your account" src={ExternalLinkIconSVG} />
                                                </ExternalLink>
                                            </div>
                                            <div>
                                                <SymphonyTooltip
                                                    className="block w-6 h-6 cursor-pointer"
                                                    title="See more"
                                                    arrow
                                                    onClick={() => Intercom.openFacebookAdAccountInactiveArticle()}
                                                >
                                                    <img
                                                        className="w-6 h-6"
                                                        alt="question-mark-icon.svg"
                                                        src={QuestionMarkIcon}
                                                    />
                                                </SymphonyTooltip>
                                            </div>
                                        </AddAccountInvalidCtn>
                                    </ColumnContainer>
                                }
                                <div>
                                    {selectedFBAdAccount && preConnectionConfigurations && !areTermsAccepted && (
                                        <TermsOfServiceContainer>
                                            <AcceptFBTermsOfServiceCard
                                                timeStarted={startTimer}
                                                verifyingTime={time}
                                                configurations={preConnectionConfigurations}
                                                onStartTimer={handleStartTimer}
                                            />
                                        </TermsOfServiceContainer>
                                    )}
                                </div>
                            </Fragment>}

                            <div className="px-6 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">Pages</p>
                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">{connectionsType === 'custom' ? 'Select the pages you want to appear in your ads.' : 'Link your pages to optimize your ads and grow your following.'}</p>

                            </div>
                            <div>
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center ">
                                        <div className="artist-img">
                                            <img
                                                src={
                                                    require("../../../../assets/images/facebook.svg")
                                                        .default
                                                }
                                            />
                                            {Boolean(selectedFB_page?.name && fbLoggedIn()) && (
                                                <div className="con-icon">
                                                    <img
                                                        className="absolute w-4 h-4 "
                                                        src={
                                                            require("../../../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                    />
                                                </div>)}
                                        </div>
                                        <div className="px-3 items-center text-left  flex">
                                            <h5 className="text-dark">Facebook</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <FacebookView
                                            // facebookLoginResponse={responseFacebook}
                                            selectedFB_page={selectedFB_page}
                                            updateFB_Pages={updateFB_Pages} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sy-card px-5 lg:px-10">
                                    <div className="flex flex-none text-center ">
                                        <div className="artist-img">
                                            <img src={require('../../../../assets/images/insta.svg').default} />
                                            {Boolean(selectedInstaPage?.connected_instagram_account && fbLoggedIn()) && (
                                                <div className="con-icon">
                                                    <img
                                                        className="absolute w-4 h-4 "
                                                        src={
                                                            require("../../../../assets/images/tick.svg")
                                                                .default
                                                        }
                                                    />
                                                </div>)}
                                        </div>
                                        <div className="px-3 items-center text-left  flex">
                                            <h5 className="text-dark">Instagram</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <InstagramView
                                            updateFB_Pages={() => handleShowConnectModal('facebook')}
                                            selectedFBPage={selectedFB_page}
                                            selectedInstaPage={selectedInstaPage}
                                            selectInstagramPages={selectInstagramPages} />
                                    </div>
                                </div>
                                <ShowFbSelectedNoIgSelectedState
                                    requireInstagramConnection={false}
                                    facebookPage={selectedFB_page}
                                    instagramPage={selectedInstaPage}
                                    onInstagramPageRefresh={onInstagramPageRefresh}
                                />
                            </div>
                            {!setResponsiveView && !isConversionsSetUp && (
                                <div className="px-6 lg:px-10 py-5 border-b">

                                    <Accordion elevation={0}
                                        className={classes.accordion}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            <div className="flex flex-col">
                                                <p className="text-lg font-bold">Advanced Settings</p>
                                                <p className="text-sm sm:text-sm text-gray-500 lg:text-base">Enable Facebook Conversions API for your campaign.</p>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {showConversionsPopup()}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )}
                        </>)}

                    {showConnectModal === 'facebook-biz-mgr' && (
                        <FacebookBusinessManagerConnect
                            reloadBrand={reloadBrands}
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            selectedBusinessManager={selectedFBBusinessManager}
                            setBusinessManager={setFBBusinessManager}
                            showConnectButton={isConnectModalLoading}
                        />
                    )}

                    {showConnectModal === 'facebook-ad-account' && (
                        <FacebookAdAccountConnect
                            withBorderRadius
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            setupType={connectionsType}
                            showBusinessManagerSelector={() => handleShowConnectModal('facebook-biz-mgr')}
                            businessManager={selectedFBBusinessManager ? selectedFBBusinessManager : null}
                            selected={selectedFBAdAccount}
                            set={setFBAdAccount}
                        />
                    )}

                    {showConnectModal === 'facebook-pixel' && (
                        <FacebookPixelConnect
                            closeModal={() => handleShowConnectModal('')}
                            artist={brand}
                            businessManager={selectedFBBusinessManager}
                            selected={selectedFBPixel}
                            set={setFBPixel}
                            fbAdAccount={selectedFBAdAccount}
                            showConnectButton={isConnectModalLoading}
                        />
                    )}

                    {showConnectModal === 'facebook' && (
                        <CampaignCreationFacebookConnect
                            withBorderRadius
                            artist={brand}
                            showSave={isConnectModalLoading}
                            selectedFBPage={selectedFB_page}
                            selectFBPage={(value: any) => {
                                setSelectedFB_page(value)
                                if (connectionsType !== 'custom') {
                                    // TODO: make it so that this only calls on successful save
                                    expressSetupConfirmation(value.id)
                                }
                            }}
                            selectIgPage={selectInstagramPage}
                            closeModal={() => handleShowConnectModal('')}
                        />
                    )}

                    {showConnectModal === 'instagram' && (
                        <InstagramConnect
                            setInstagramPagesAvailable={setInstagramPagesAvailable}
                            withBorderRadius
                            artist={brand}
                            closeModal={() => handleShowConnectModal('')}
                            showSave={isConnectModalLoading}
                            selectedInstaPage={selectedInstaPage}
                            selectInstagramPage={selectInstagramPage}
                            updateFBPages={() => handleShowConnectModal('facebook')}
                            selectedFBPage={selectedFB_page}
                        />
                    )}
                </>
            ) : (
                <>
                    {showConnectModal === '' && (
                        <>
                            <InfoSection title="Add Visuals" description="Personalize your ads with the visuals and messaging you want to use for your ads." />
                            <AssetUploadTypeSelector
                                instagramPage={selectedInstaPage}
                                selected={creativeSelectorTab}
                                onChange={onClickCreativeSelectorTab}
                            />
                            {creativeSelectorTab === INSTAGRAM_POSTS &&
                                <div className="border-b pb-6">
                                    <InstagramPosts
                                        posts={instagramPosts}
                                        setSelectedInstagramPost={setSelectedInstagramPost}
                                        selectedInstagramPost={selectedInstagramPost}
                                        showCaption={true}
                                        allowPostPreviews={true}
                                    />
                                </div>
                            }
                            {creativeSelectorTab === UPLOAD_VIDEO &&
                                <>
                                    <div className="sy-card px-5 lg:px-10">
                                        <VideoUploader
                                            maxAssets={3}
                                            uploadingAssets={uploadingAssets}
                                            selectedAssets={[...(selectedAssets || []), ...queueToUpload]}
                                            removeAsset={removeAsset}
                                            setPreviewedVideo={setPreviewedVideo}
                                            previewedVideo={previewedVideo}
                                        />
                                    </div>
                                    {uploadErrors.length > 0 ? <div className="rounded-sm bg-yellow-500 text-white py-1 text-md py-3 px-3 text-center">
                                        <p className="w-8/12 m-auto">There was an error uploading the following assets:</p>
                                        {uploadErrors.map((o: {
                                            message: string,
                                            name: string
                                        }) => {
                                            return (
                                                <p>{o.name}: {o.message}</p>
                                            )
                                        })}
                                        <button className="mt-3" onClick={() => {
                                            setUploadErrors([])
                                        }}>Close</button>
                                    </div> : null}
                                </>
                            }
                            {isConversionsEnabled && creativeSelectorTab === UPLOAD_VIDEO &&
                                <div className="px-6 lg:px-10 py-5 border-b">
                                    <p className="text-md font-medium mb-0 mt-6">Caption</p>
                                    <p className="text-sm text-gray-500">Set the caption you want fans to see in your IG + FB ads.</p>
                                    <textarea
                                        value={captions['all']}
                                        placeholder={`${selectedRecord.name} out now on all streaming services`}
                                        className="rounded-md border w-full resize-none p-2 h-14 mt-2"
                                        onChange={(e) => { setCaptionForAllSelectedServices(e.target.value) }}
                                    />
                                </div>
                            }
                            <div className="px-6 lg:px-10 py-5 border-b">
                                <p className="text-lg font-bold">{isConversionsEnabled ? 'Which platforms do you want to send fans to?' : 'Platforms'}</p>
                                {!isPlaylistCampaign && (<p className="text-sm sm:text-sm text-gray-500 lg:text-base">
                                    Select the streaming services you want to send fans to with your ads. {isConversionsEnabled && 'When using the Conversions API, the selected platforms will show up on the link-in-bio we send fans to.'}
                                </p>)}
                                {isConversionsEnabled &&
                                    <Fragment>
                                        <ConversionsBannerContainer>
                                            <BannerHeader>
                                                <BoostsIcon height={16} width={16} />
                                                <Caption>Symphony Conversions Enabled</Caption>
                                            </BannerHeader>
                                            {isPlaylistCampaign ? (<Body2>When using Symphony Conversions, we'll be able to better target fans who engage with your playlist.</Body2>) : (<Body2>When using Symphony Conversions, the selected platforms will show up on the link-in-bio we send fans to.</Body2>)}
                                        </ConversionsBannerContainer>
                                        <Container paddingTop="8px">
                                            <LinkButton
                                                text="Preview your ad link"
                                                rightIcon={<ExternalLinkIcon className={classes.externalLink} />}
                                                verticalAlign="flex-start"
                                                onClick={handleOnClickWebsitePreview}
                                            />
                                        </Container>
                                    </Fragment>
                                }
                            </div>
                            {!isPlaylistCampaign && isConversionsEnabled && (
                                <PlatformContainer>
                                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Show all streaming services on website</Body2>
                                    <SwitchButton
                                        checked={platformsSwitch.enabled}
                                        onChange={handleOnChangePlatformsSwitch}
                                    />
                                </PlatformContainer>
                            )}
                            {Object.values(selectedPlatforms).filter(
                                (platform) => {
                                    const platformInfo = platform as DataPlatform
                                    const shouldBeHidden = ['all', 'custom'].indexOf(platformInfo.key) > -1

                                    if (shouldBeHidden) {
                                        if (isPlaylistCampaign) return false
                                        // hide the "landing page" or "custom link" options

                                        return !isConversionsEnabled
                                    }
                                    const links = selectedRecord?.external_links
                                    if (!selectedRecord || (selectedRecord && !links) || (!isConversionsEnabled && ConversionsPlatforms.includes(platformInfo.key))) {
                                        return false
                                    } else {
                                        const platformFound = selectedRecord.external_links.find((record: { name: string }) => record.name === platformInfo.key)
                                        return platformFound
                                    }
                                })
                                .map((platform) => {
                                    const platformInfo = platform as DataPlatform
                                    const { name, key, selected, subtitle = null } = platformInfo

                                    let linkSubtitle = subtitle


                                    if (!linkSubtitle) {
                                        if (selected) {
                                            if (isConversionsEnabled) {
                                                if (selectedRecord.type === 'playlist') {
                                                    linkSubtitle = `We'll show ${name} as an option for fans to stream on playlist landing page.`
                                                } else {
                                                    linkSubtitle = `We'll show ${name} as an option for fans to stream on your music landing page.`

                                                }
                                            } else {
                                                if (selectedRecord.spotify_release_type === 'album') {
                                                    linkSubtitle = `We'll create an ad that sends people directly to your album on ${name}.`
                                                } else if (selectedRecord.spotify_release_type === 'single') {
                                                    linkSubtitle = `We'll create an ad that sends people directly to your song on ${name}.`
                                                } else if (selectedRecord.type === 'playlist') {
                                                    linkSubtitle = `We'll create an ad that sends people directly to your playlist on ${name}.`
                                                }
                                            }
                                        } else {
                                            if (isConversionsEnabled) {
                                                linkSubtitle = `If you select this, we'll show ${name} as an option on your music landing page..`
                                            } else {
                                                linkSubtitle = `If you select this, we'll automatically create an ad that sends people directly to your ${selectedRecord.spotify_release_type === 'album' ? 'album' : 'song'} on ${name}.`
                                            }
                                        }
                                    }
                                    return (
                                        <PlatformContainer key={name} flexDirection="column">
                                            <PlatformDetails>
                                                <Container display="flex">
                                                    <PlatformIconContainer>
                                                        <img className={classes.platformIcon} src={(siteIconsData as any)[key]} />
                                                        {(selected && creativeSelectorTab === INSTAGRAM_POSTS) && (
                                                            <CheckedIcon src={require("../../../../assets/images/tick.svg").default} />
                                                        )}
                                                    </PlatformIconContainer>
                                                    <div className="flex flex-col ml-4">
                                                        <p className="text-md font-medium">
                                                            {key !== 'all' ? (
                                                                <Fragment>
                                                                    {isConversionsEnabled ? selected ? "" : "Link to" : 'Direct to'} {name} {selected && "Enabled"}
                                                                </Fragment>
                                                            ) : (
                                                                <Container display="flex" flexDirection="column">
                                                                    <Subtitle2>Symphony Website</Subtitle2>
                                                                    <LinkButton
                                                                        text="Show Preview"
                                                                        verticalAlign="flex-start"
                                                                        onClick={handleOnClickWebsitePreview}
                                                                        fontSize="12px"
                                                                    />
                                                                </Container>
                                                            )}
                                                        </p>
                                                        <p className="text-sm text-gray-500">{linkSubtitle}</p>
                                                    </div>
                                                </Container>
                                                <SwitchButton
                                                    checked={selected}
                                                    onChange={(e) => {
                                                        if (selected) {
                                                            disablePlatform(key)
                                                        } else {
                                                            enablePlatform(key)
                                                        }
                                                    }}
                                                />
                                            </PlatformDetails>
                                            {selected && key === 'custom' ?
                                                <Container display="inline" width="100%">
                                                    <p className="text-md font-medium mb-0 mt-6">Link</p>
                                                    <p className="text-sm text-gray-500">Enter your custom link.</p>
                                                    <div
                                                        className="rounded-md border w-full resize-none mt-2 relative">
                                                        <DebounceInput
                                                            className="w-full h-full h-12 p-3.5 rounded-md"
                                                            debounceTimeout={0}
                                                            placeholder={`e.g. linktree.com/xyz`}
                                                            value={links[key].value}
                                                            onChange={(e) => {
                                                                const isValid = isValidURL(links.custom.value)
                                                                setLinks({
                                                                    ...links,
                                                                    [key]: {
                                                                        ...links[key],
                                                                        valid: isValid,
                                                                        checkingValidity: e.target.value ? true : false,
                                                                        value: e.target.value
                                                                    }
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </Container>
                                                : null}
                                            {!isConversionsEnabled && selected && creativeSelectorTab === UPLOAD_VIDEO &&
                                                <Container display="inline" width="100%">
                                                    <p className="text-md font-medium mb-0 mt-6">Caption</p>
                                                    <p className="text-sm text-gray-500">Enter the caption you want people to see alongside your ad.</p>
                                                    <textarea
                                                        placeholder={`${selectedRecord.name} out now on ${key === 'all' ? 'all streaming services' : name}`}
                                                        className="rounded-md border w-full resize-none p-2 h-14 mt-2"
                                                        value={captions[key]}
                                                        onChange={(e) => setCaptionForService(key, e.target.value)}
                                                    ></textarea>
                                                </Container>
                                            }
                                        </PlatformContainer>
                                    )
                                })}
                            <CloudinaryUploadWidget
                                onUploadAdded={(fileId: string) => {
                                    addToCloudinaryUploadQueue(fileId)
                                }}
                                onUploadSuccess={successfulCloudinaryUpload}
                                onUploadAbortAll={() => {
                                    setUploadQueue([])
                                }}
                                onUploadAbort={(fileId: string) => {
                                    removeFromCloudinaryUploadQueue(fileId)
                                }}
                                uploadButtonId={"all_widget"}
                                onCancel={(info: any) => cancelUploadingAssets('all')}
                            />

                        </>
                    )}

                </>
            )
            }
        </div >

    )
}