import { makeStyles, Theme } from '@material-ui/core'
import { ModalContainer } from 'pages/post-auth/AddArtist/style'
import styled from 'styled-components'

const useStyles = makeStyles((theme: Theme) => ({
  chipStyle: {
    '& .MuiChip-label': {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '500px',
      height: 'fit-content',
    },
  },
}))

interface StyledModalContainerProps {
  isMobileWindow: boolean
}

export const StyledModalContainer = styled(ModalContainer)<StyledModalContainerProps>`
  width: 100%;
  height: fit-content;
  margin: 0;
  gap: 32px;
  ${(props) => props.isMobileWindow && 'padding: 24px'}
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8px 0px;
  width: 100%;
`

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
  width: fit-content;
`

export const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #8800ff;
  border-radius: 48px;
  cursor: pointer;
`

export const ButtonLabel = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`

export const TextBody = styled.p`
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
  color: #707070;
`

export default useStyles
