import Axios from "helpers/Interceptor"
import { Row } from "./utils"
import { showToast } from "utils"

export interface PulledUsers {
  users: Row[]
  availableSeats: number,
}

export const getUsers = async (data: {
  brandId?: number | null
  onSaveUsers: (pulledUsers: PulledUsers) => void
}) => {
  const { brandId, onSaveUsers } = data

  try {
    const call = await Axios.get(`/brand/${brandId}/users`)
    if (call && call.data) {
      const { users, availableSeats } = call.data.data;
      onSaveUsers({
        users,
        availableSeats,
      })
    }
  } catch (e) {
    // TODO: error state for not getting users
  }
}

export const addNewUser = async (data: {
  brandId?: number | null
  users: Row[]
  newUserEmail: string
  mobileView: boolean
  onLoading: (loading: boolean) => void
  onSaveUsers: (pulledUsers: PulledUsers) => void
  onChangeNewUserEmail: (email: string) => void
}) => {
  const {
    newUserEmail,
    users,
    brandId,
    mobileView,
    onLoading,
    onSaveUsers,
    onChangeNewUserEmail,
  } = data

  onLoading(true)

  const currentEmails = users.map((user: Row) => user.email)

  if (currentEmails.indexOf(newUserEmail) > -1) {
    showToast({
      message: `${newUserEmail} already has access to your profile.`,
      mobile: mobileView,
    })
  } else {
    try {
      await Axios.post(`/brand/${brandId}/users`, {
        email: newUserEmail,
      })
      getUsers({
        brandId,
        onSaveUsers,
      })
      onChangeNewUserEmail("")
      showToast({
        message: "New user added!",
        mobile: mobileView,
      })
    } catch (e) {
      console.error("error adding user", e)
      showToast({
        message: `There was an error adding ${newUserEmail} to your profile - please try again.`,
        mobile: mobileView,
        error: true,
      })
    }
  }
  onLoading(false)
}

export const deleteUserFromAccount = async (data: {
  brandId?: number | null
  potentiallyDeletedUser: Row | null
  mobileView: boolean
  onSaveUsers: (pulledUsers: PulledUsers) => void
  onSetDeletedUser: (user: Row | null) => void
}) => {
  const {
    brandId,
    potentiallyDeletedUser,
    mobileView,
    onSaveUsers,
    onSetDeletedUser,
  } = data

  try {
    await Axios.delete(`/brand/${brandId}/users`, {
      data: {
        email: potentiallyDeletedUser?.email,
      },
    })
    getUsers({
      brandId,
      onSaveUsers,
    })

    showToast({
      message: `${potentiallyDeletedUser?.email} has been deleted from your profile.`,
      mobile: mobileView,
    })
  } catch (e) {
    console.error("error adding user", e)
    showToast({
      message: `There was an error deleting ${potentiallyDeletedUser?.email} from your profile - please try again.`,
      error: true,
      mobile: mobileView,
    })
  }

  onSetDeletedUser(null)
}
