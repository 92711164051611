import Axios from "helpers/Interceptor"


export default async function getUserByAuth0Id(userId: string) {
    const response = await Axios.get(`/user/${userId}`)
    if (response.status >= 400 || !response.data) {
        throw "Error fetching user"
    }
    const userData = response.data.data
    return userData
}