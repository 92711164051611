import { ColorPalette, Template } from "types/global"

export const TEMPLATE_NAMES: Record<string, string> = {
  "MUSIC_P1_BRETON": "Volume",
  "MUSIC_P1_BULLSEYE": "Bullseye",
  "MUSIC_P1_DION": "Billboard",
  "MUSIC_P1_FLOATING_COLORS": "Floating Colors",
  "MUSIC_P1_JARED": "Psychedelic",
  "MUSIC_P1_WAVES": "Waves"
}

export const DEFAULT_TEMPLATES: Template[] = [
  {
      "name": "Volume",
      "templateId": "MUSIC_P1_SIMPLE",
      "timeToRenderSecs": 500,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_SIMPLE/Simple_9x16-4x5_520x384.mp4"
  },
  {
      "name": "Bullseye",
      "templateId": "MUSIC_P1_BULLSEYE",
      "timeToRenderSecs": 1250,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_BULLSEYE/Bullseye_9x16-4x5_520x384.mp4"
  },
  {
      "name": "Billboard",
      "templateId": "MUSIC_P1_BILLBOARD",
      "timeToRenderSecs": 250,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_BILLBOARD/Billboard_9x16-4x5_650x480.mp4"
  },
  {
      "name": "Floating Colors",
      "templateId": "MUSIC_P1_FLOATING_COLORS",
      "timeToRenderSecs": 750,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_FLOATING_COLORS/Colors_9x16-4x5_520x384.mp4"
  },
  {
      "name": "Psychedelic",
      "templateId": "MUSIC_P1_TOPO",
      "timeToRenderSecs": 500,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_TOPO/Topo_9x16-4x5_520x384.mp4"
  },
  {
      "name": "Waves",
      "templateId": "MUSIC_P1_WAVES",
      "timeToRenderSecs": 500,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_WAVES/Waves_9x16-4x5_520x384.mp4"
  },
  {
      "name": "Glitch",
      "templateId": "MUSIC_P1_GLITCH",
      "timeToRenderSecs": 750,
      "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_GLITCH/Gltch_9x16-4x5_650x480.mp4"
  }
//   {
//       "name": "Grunge",
//       "templateId": "MUSIC_P1_GRUNGE",
//       "timeToRenderSecs": 500,
//       "previewUrl": "https://storage.googleapis.com/apromowasborn-public/hosting-music-blurb-fm/MUSIC_P1_GRUNGE/Grunge_9x16-4x5_650x480.mp4"
//   }
]

export const DEFAULT_COLORS_PALETTE: ColorPalette[] = [
  {
      "color1": "#b6eda5",
      "color2": "#555f3b",
      "color3": "#131313",
  },
  {
      "color1": "#939396",
      "color2": "#b42759",
      "color3": "#131313",
  },
  {
      "color1": "#65798c",
      "color2": "#c22c27",
      "color3": "#131313",
  },
  {
      "color1": "#718782",
      "color2": "#6a5d2c",
      "color3": "#131313",
  }
  ,
  {
      "color1": "#718782",
      "color2": "#131313",
      "color3": "#ffffff",
  },
  {
      "color1": "#000000",
      "color2": "#ff0033",
      "color3": "#131313",
  },
  {
      "color1": "#000000",
      "color2": "#ff3333",
      "color3": "#ff2222",
  },

  {
      "color1": "#917c4d",
      "color2": "#5e5333",
      "color3": "#151817"
  },
  {
      "color1": "#00a6f7",
      "color2": "#0055b7",
      "color3": "#151817"
  },
  {
      "color1": "#7d8789",
      "color2": "#b80212",
      "color3": "#151817"
  },
  {
      "color1": "#00e29b",
      "color2": "#1d5f5a",
      "color3": "#151817"
  }
]
