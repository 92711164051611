import { SvgIcon } from "@material-ui/core";

export function ExpandIcon() {
    return (
        <SvgIcon fontSize="small" viewBox="0 0 15 14">
            <path stroke="#707070" strokeLinecap="round" strokeWidth="2" d="m3.672 11.029 8.132-8.132M3.319 5.725v4.657a1 1 0 0 0 1 1h4.657M12.097 8.261V3.604a1 1 0 0 0-1-1H6.44" />
        </SvgIcon>
    )
}

export function UpArrow() {
    return (
        <SvgIcon 
        fontSize={"small"}
        style={{
            fontSize: "1rem"
        }}
        viewBox="0 0 8 11" >
            <path stroke="#07C806" strokeLinecap="round" strokeWidth="2" d="M4 1.375V10M7 4 4.53 1.53a.75.75 0 0 0-1.06 0L1 4" />
        </SvgIcon>
    )
}

export function DownArrow() {
    return (
        <SvgIcon 
        fontSize="small" 
        style={{
            fontSize: "1rem"
        }}
        viewBox="0 0 8 11" >
            <path stroke="#777" strokeLinecap="round" strokeWidth="2" d="M4 9.936V1.31M7 7.31 4.53 9.78a.75.75 0 0 1-1.06 0L1 7.31" />
        </SvgIcon>
    )
}