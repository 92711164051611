import styled from "styled-components";

export const CircularContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 32px;
  border-radius: 50%;
  background-color: #8800ff;
  height: 32px;
  padding-top: 2px;
`;
