import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  chipContainer: {
    width: "100%",
    minHeight: 80,
    padding: "0px 9px 9px 9px",
  },
  principalContainer: {
    border: "1.5px solid #dadada",
    borderRadius: 4,
  },
  chipLabelColor: {
    maxWidth: 'unset',
    color: "#8800FF",
  },
  chipBackgroundColor: {
    backgroundColor: "rgba(136, 0, 255, 0.08)",
  },
  closeIcon: {
    width: 9,
    height: 9,
    marginRight: theme.spacing(1.5),
    cursor: "pointer",
  },
  inputTextField: {
    width: "100%",
    borderTop: "1.5px solid #dadada",
    padding: "8px 16px 8px 8px",
  },
  rootCheckbox: {
    color: "#07C806",
  },
  checkedCheckbox: {},
  inputTextAlign: {
    textAlign: "right",
  },
  customCloseIconButtonContainer: {
    borderTop: "1.5px solid #dadada",
    padding: "8px 4px 4px 4px",
  },
  customCloseIconButton: {
    padding: theme.spacing(0.5),
  },
  listbox: {
    maxHeight: 300, overflow: 'auto' 
  }
}));

export default useStyles;
