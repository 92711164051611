import { CurrentBrand } from "types/global"
import Axios from "helpers/Interceptor"
import { STEPS } from "./index"
import { Customize, Details } from "../Data/PreSaveProvider"
import moment from "moment"
import { availablePlatforms } from "./utils"

const getTimezone = (timezone: { value: string; abbrev: string } | string) => {
  const defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (typeof timezone === "string") return timezone
  if (timezone?.value && timezone.value.length) return timezone.value
  return defaultTz
}

const getPlatforms = (platforms?: { name: string; show: boolean }[]) => {
  if (platforms) return platforms.filter(({ show }) => show).map(({ name }) => name)
  return availablePlatforms.filter(p => p !== "deezer")
}

export const saveDraft = async (args: {
  dataToSend: { customize: Customize; details: Details; selectedTab: STEPS }
  currentBrand: CurrentBrand
  campaignDraftData?: Record<string, unknown> | null
}) => {
  try {
    const { dataToSend, currentBrand, campaignDraftData } = args
    const { customize, details, selectedTab } = dataToSend
    const {
      artwork,
      backgroundColor,
      backgroundType,
      buttonColor,
      customWebPageUrl,
      primaryColor,
      releaseTitle,
      sendFansToCustomWebPage,
      styleType,
      subtitle,
    } = customize
    const { releaseUrl, releaseDate, boosts, musicPlatforms, timezone } =
      details
    const { autoPresaves, boostFollows, collectEmails } = boosts || {}
    const campaignDraftId = (
      campaignDraftData ? campaignDraftData.id : null
    ) as string | null
    const brandId = campaignDraftData
      ? campaignDraftData.brand_id
      : currentBrand.id
    const response = await Axios.put(`campaign/brand/${brandId}/save-draft`, {
      id: campaignDraftId,
      brand_id: brandId,
      campaign_metadata: {
        saved_draft_step: selectedTab,
        campaign_type: "pre_save",
        content: {
          name: releaseTitle,
          slug: null,
          thumbnail_url: artwork,
          type: null,
          url: null,
        },
        presave: {
          boosts: {
            auto_presaves:
              typeof autoPresaves === "boolean" ? autoPresaves : true,
            boost_follows:
              typeof boostFollows === "boolean" ? boostFollows : true,
            collect_emails:
              typeof collectEmails === "boolean" ? collectEmails : true,
          },
          options: {
            redirect_to_custom_webpage: sendFansToCustomWebPage,
            redirect_url: customWebPageUrl,
            timezone: getTimezone(timezone),
          },
          platforms: getPlatforms(musicPlatforms),
          release_url_upc_isrc: releaseUrl || null,
          style: {
            bgColor: backgroundColor,
            bgType: backgroundType,
            buttonConfig: styleType,
            primaryColor,
            secondaryColor: buttonColor,
          },
          subtitle: subtitle || null,
        },
        link: null,
        startDate: null,
        endDate: releaseDate ? moment(releaseDate).utc().format() : null,
      },
      totalOfEmails: null,
      totalOfPresaves: null,
    })
    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error when saving draft: ", error)
    return { data: null, error }
  }
}
