import styled from "styled-components";

export interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  color?: string;
  cursor?: string;
  marginTop?: string;
  paddingBottom?: string;
  textAlign?: string;
  margin?: string;
  width?: string;
  marginRight?: string;
  overflowWrap?: string;
}

interface IconProps {
  position?: string;
  right?: string;
  borderRadius?: string;
}

export const CircularContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 32px;
  border-radius: 50%;
  background-color: #8800ff;
  height: 32px;
  padding-top: 2px;
`;

export const StyledLabel = styled.p`
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop};
  padding-bottom: ${({ paddingBottom }: StyledLabelProps) => paddingBottom};
  text-align: ${({ textAlign }: StyledLabelProps) => textAlign};
  margin: ${({ margin }: StyledLabelProps) => margin};
  width: ${({ width }: StyledLabelProps) => width};
  margin-right: ${({ marginRight }: StyledLabelProps) => marginRight};
  overflow-wrap: ${({ overflowWrap }: StyledLabelProps) => overflowWrap};
`;

export const Icon = styled.img`
  border-radius: ${({ borderRadius }: IconProps) => borderRadius};
  position: ${({ position }: IconProps) => position};
  right: ${({ right }: IconProps) => right};
`;