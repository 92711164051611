import styled from 'styled-components';

interface ArtistListProps {
  gap?: string;
}

interface ArtistImageProps {
  widthStyle?: string
  heightStyle?: string
}

export const ArtistList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({gap}: ArtistListProps) => gap || "8px" };
  overflow-y: auto;
`;

export const ArtistListLine = styled.div`
  height: 0px;
  border: 0.5px solid #edecf2;
  flex: none;
  align-self: stretch;
`;

export const ArtistFollowers = styled.div`
  height: 15px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #707070;
  opacity: 0.72;
`

export const ArtistFollowersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 16px;
`

export const ArtistFollowersPlatform = styled.div`
  width: 16px;
  height: 16px;
`

export const ArtistImage = styled.img`
  width: ${({ widthStyle }: ArtistImageProps) => widthStyle || "58px"};
  height: ${({ heightStyle }: ArtistImageProps) => heightStyle || "58px"};
  background: url("assets/images/not-found-user.png");
  border-radius: 12px;
`

export const ArtistInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  height: 64px;
  overflow: hidden;
`;

export const ArtistItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px 8px 0px;
  gap: 16px;
  height: 80px;
  width: 100%;
  cursor: pointer;
`;

export const ArtistName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  height: 39px;
`

export const ArtistNameLabel = styled.div`
  height: 19px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ConnectArtistLabel = styled.div`
  height: 40px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 416;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #8800ff;
  cursor: pointer;
`
