import styled from "styled-components";

interface TextProps {
  color?: string;
  textAlign?: string;
  fontSize: number;
}

interface ImageContainerProps {
  width?: string,
}

interface CardContainerProps {
  paddingY: number,
}
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  padding-bottom: 32px;
  padding-right: ${({ paddingY }: CardContainerProps) => paddingY}px;
  padding-left: ${({ paddingY }: CardContainerProps) => paddingY}px;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
`;

export const ImageContainer = styled.div`
  width: ${({ width }: ImageContainerProps) => width};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-top: 16px;
  width: 100%;
`;

export const Text = styled.span`
  text-align: ${({ textAlign }: TextProps) => textAlign || "start"};
  color: ${({ color }: TextProps) => color || "#000000"};
  font-size: ${({ fontSize }: TextProps) => fontSize}px;
`;
