import { useState, Fragment } from 'react'
import HeadCard from "./Components/HeadCard";
import BudgetCard from "./Components/BudgetCard";
import GoLiveCard from './Components/GoLiveCard';
import { CurrentBrand as Brand, PlaylistsRecord } from "types/global";

interface FinalizationProps {
    campaignId: string | null
    pitchNotes: string;
    tier: {
        tier: number;
        cost: number;
        discount: number;
        afterDiscountPrice: number;
    },
    record: PlaylistsRecord | null;
    brand: Brand;
    cancelSaving: () => void;
}

export default function Finalization({
    campaignId,
    tier,
    record,
    brand,
    pitchNotes,
    cancelSaving,
}: FinalizationProps) {
    const [, setCreatingCampaign] = useState<boolean>(false)

    return (
        <Fragment>
            <HeadCard
                {...{
                    brand,
                    record,
                    tier
                }}
            />
            <BudgetCard  budget={tier.cost} currency={brand.currency?.code}/>
            <GoLiveCard
                {...{
                    pitchNotes,
                    campaignId,
                    brand,
                    record,
                    tier,
                    setCreatingCampaign,
                    cancelSaving,
                }}
            />
        </Fragment>
    )
}