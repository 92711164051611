import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const YouTubeIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick}) => {
    const ytColor = iconColor ?? "#FF0000";
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => {onClick && onClick()}}>
            <svg
                width={width}
                height={height}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32.2164 9.67548C33.5708 10.0377 34.6274 11.0984 34.9937 12.4528C35.9517 16.317 35.8893 23.6226 35.0138 27.5472C34.6516 28.9016 33.591 29.9582 32.2365 30.3245C28.4126 31.2704 11.2855 31.1537 7.68303 30.3245C6.32856 29.9623 5.27196 28.9016 4.90567 27.5472C4.00202 23.8642 4.06441 16.0755 4.88554 12.473C5.24781 11.1185 6.30844 10.0619 7.6629 9.6956C12.7749 8.62894 30.397 8.97309 32.2164 9.67548ZM16.9208 15.2906L25.1321 20L16.9208 24.7094V15.2906Z" fill={ytColor} />
            </svg>
        </IconHolder>
    );
};
