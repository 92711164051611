import Logger from "Logger";

type MetaData = {
    [key: string]: string | number;
};

const TapfiliateClient = {
    captureTrialStart(stripeCustomerId: string) {
        this._execute('trial', stripeCustomerId);
    },

    createConversion(
        stripeChargeId: string,
        orderAmount: number,
        stripeCustomerId: string,
        metaData?: MetaData,
        commissionId?: string
    ) {
        Logger.log('Tapfiliate: create conversion', stripeChargeId, orderAmount, stripeCustomerId, metaData);
        const payload = {
            customer_id: stripeCustomerId,
            ...(metaData ? { meta_data: metaData } : {})
        };


        let commissionType = commissionId ? commissionId : 'standard'
        this._execute('conversion', stripeChargeId, orderAmount, payload, commissionType);
        Logger.log('✅ Tapfiliate: created conversion!');

    },

    _execute(...args: any[]) {
        const Tapfiliate: any = (window as any).tap;
        if (Tapfiliate) {
            Tapfiliate(...args);
        } else {
            console.error("Tapfiliate not initiated");
        }
    }
}

export default TapfiliateClient;
