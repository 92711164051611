import { FunctionComponent } from "react";
import {
  ProjectCard,
  ProjectInformationContainer,
  StyledLabel,
  TypeBatch,
} from "./styles";
import emptySongImage from "assets/images/image.svg";
import { DefaultImage } from '../../styles';

interface Props {
  imageUrl: string;
  artistName: string;
  presaveName: string;
  presaveType: string;
}

const getFormattedPresaveType = (type: string) => {
  switch (type) {
    case "project":
      return "Project";
    default:
      return "Single";
  }
}

const PresaveCard: FunctionComponent<Props> = ({
  artistName,
  imageUrl,
  presaveName,
  presaveType,
}: Props) => {
  return (
    <ProjectCard>
      <DefaultImage
        src={imageUrl || emptySongImage}
        height="32px"
        width="32px"
        backgroundColor={imageUrl ? "none" : "#70707024"}
        borderRadius="4px"
      />
      <ProjectInformationContainer>
        <StyledLabel color="#707070" fontSize="12px">
          {artistName}
        </StyledLabel>
        <StyledLabel fontSize="16px">{presaveName}</StyledLabel>
      </ProjectInformationContainer>
      <TypeBatch>{getFormattedPresaveType(presaveType)}</TypeBatch>
    </ProjectCard>
  );
};

export default PresaveCard;
