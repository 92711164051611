import millify from "millify";
import { InstagramPost, MediaType } from "./types"
import InstagramVideoIcon from "assets/images/ig-video.svg";
import InstagramLikesIcon from "assets/images/ig-likes.svg";
import InstagramCommentsIcon from "assets/images/ig-comments.svg";
import { SvgIcon } from "@material-ui/core";
import { SuperboostButton } from "./styles";


export default function PostItem(props:
    {
        post: InstagramPost,
        onClick: (post: InstagramPost) => void
    }) {

    const {
        post,
        onClick
    } = props

    const isVideo = post.media_type === MediaType.VIDEO;
    const hasMedia = !!post.media_url

    return (
        <div key={`ig-post-${post.id}`} className={`mb-4 mx-2 border rounded-lg overflow-hidden  flex items-center justify-between flex-col`}>
            <div className="flex-auto flex-shrink-0 relative  bg-black w-full cursor-pointer">


                <div className={`absolute z-10 bottom-0 px-1 grid grid-cols-2 h-6 w-full rounded bg-gradient-to-t from-black`}>
                    <div className="grid grid-cols-1 left-0 pt-0.5">
                        {isVideo ? <img className="px-1 w-5 h-5" alt="ig-video-icon" src={InstagramVideoIcon} /> : <div />}
                    </div>

                    <div className="flex flex-row-reverse px-0.5">
                        <p className="pt-1 text-center text-xs text-white">{millify(post.comments_count)}</p>
                        <img className="px-1.5 w-6 h-6" alt="ig-comments-icon" src={InstagramCommentsIcon} />
                        <p className="pt-1 text-center text-xs text-white">{millify(post.like_count)}</p>
                        <img className="px-1.5 w-6 h-6" alt="ig-likes-icon" src={InstagramLikesIcon} />
                    </div>
                </div>

                <div className=" flex items-center justify-center">
                    {isVideo && hasMedia ?
                        <video className="w-full h-60 object-cover" autoPlay loop muted playsInline >
                            <source src={post.media_url || post.thumbnail_url} type="video/mp4" />
                        </video>
                        :
                        <img className="w-full h-60 object-cover" alt={post.id} src={post.thumbnail_url || post.media_url} />
                    }
                </div>


            </div>
            <SuperboostButton
            onClick={(e) => {
                onClick(post)
            }}>
                <p className="text-primary mr-2 text-lg font-bold">Superboost</p>
                <SvgIcon viewBox="0 0 15 15" fontSize="small">
                    <path d="M8.94861 1.46631H13.8656V6.3833" stroke="#8800FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.49011 8.8418L13.8656 1.46631" stroke="#8800FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M2.31592 13.0159L3.86551 11.4663" stroke="#8800FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.1344 9.28043L4.83206 5.58276" stroke="#8800FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5835 12.6827L10.9857 9.28052" stroke="#8800FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />

                </SvgIcon>
            </SuperboostButton>
        </div>
    )
}