import Axios from "helpers/Interceptor"

export default async function getWebsiteData(brandSlug: string, contentSlug?: string, startDate?: string | null, endDate?: string | null) {
    var url = contentSlug ? (`/dashboard/brand/${brandSlug}/website/${contentSlug}`) : (`/dashboard/brand/${brandSlug}/website/overall`)

    const response = await Axios.get(url, {
        params: {
            startDate,
            endDate
        }
    });
    if (response.data?.data) {
        return response.data.data;
    }
}
