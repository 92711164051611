import { Body2, Subtitle1 } from 'components/shareable/Typography'
import { Fragment, FunctionComponent, useEffect, useState } from 'react'
import { EventSelectorContainer } from './styles'
import LinkButton from 'components/shareable/LinkButton'
import { Container } from 'styles/shared'
import Select from 'react-select'
import { components, OptionProps } from 'react-select'
import { GroupBase, OptionsOrGroups, StylesConfig } from 'react-select'
import { SystemColors } from 'types/globalStyles'
import Intercom from 'helpers/Intercom'
import { PixelConversionEvent } from 'types/global'
import dayjs from 'dayjs'
import millify from 'millify'


interface Props {
  conversionsEvents: PixelConversionEvent[]
  selectedConversionsEvent: string
  eventsLoading: boolean
  selectConversionsEvent: (event: string | null) => void
}

const selectStyles: StylesConfig = {
  input: (styles) => ({ ...styles, height: 40 }),
}

// Custom Option component for rendering options
const CustomOption = (props: any) => {
  function renderSubtitle() {
    const eventData = props.data as PixelConversionEvent
    if (eventData.type === 'fb-default' || eventData.type === 'custom') {
      return (<div style={{ fontSize: 16, color: props.isSelected ? 'white' : '#555' }}>
        This event was tracked by your pixel on a custom website.
      </div>)
    } else if (eventData.type === 'symphony') {
      return (
        <div style={{ fontSize: 16, color: props.isSelected ? 'white' : '#555' }}>
          🛰️ This event was tracked automatically by a Symphony website{props.data.description ? `: ${props.data.description}` : '.'}
        </div>
      )
    }
  }
  return (
    <components.Option {...props}>
      <div style={{ fontSize: 18, fontWeight: 500 }}>{props.data.label}</div>
      {renderSubtitle()}
      <div style={{ fontSize: 'smaller', color: props.isSelected ? 'white' : '#888' }}>
        Tracked {millify(props.data.count)} times
      </div>
      <div style={{ fontSize: 'smaller', color: props.isSelected ? 'white' : '#888' }}>
        Last tracked {dayjs(props.data.time).format('MM/DD/YYYY')}
      </div>
    </components.Option>
  );
};

export const EventSelector: FunctionComponent<Props> = ({
  conversionsEvents,
  selectedConversionsEvent,
  eventsLoading,
  selectConversionsEvent,
}: Props) => {
  const [showEventWarning, setShowEventWarning] = useState<boolean>(false)

  const eventOptions = conversionsEvents.map((event: PixelConversionEvent) => ({
    value: event.name,
    label: event.name,
    type: event.type,
    description: event.description,
    count: event.count,
  })).sort((a, b) => a.label.localeCompare(b.label)) as OptionsOrGroups<unknown, GroupBase<unknown>>

  useEffect(() => {
    const isCustomEvent = !conversionsEvents.some((o) => o.name === selectedConversionsEvent) && !eventsLoading
    setShowEventWarning(isCustomEvent)
  }, [selectedConversionsEvent])

  const onChangeSelect = (event: unknown) => {
    if (!event) {
      selectConversionsEvent(null)
      return
    }
    selectConversionsEvent((event as { value: string }).value)
  }

  useEffect(() => {
    conversionsEvents.sort()
  }, [conversionsEvents])

  const selectedFormattedOption = {
    value: selectedConversionsEvent,
    label: selectedConversionsEvent,
  }

  return (
    <Fragment>
      <EventSelectorContainer gap="4px" padding="16px 16px 8px 16px">
        <Subtitle1>Conversions Event Name</Subtitle1>
        <Body2>Select the event you want to optimize for with your ads. Please make sure the website you're driving to has your Facebook Pixel on it, and is sending back events - otherwise, we recommend turning Conversions off.</Body2>
      </EventSelectorContainer>
      <EventSelectorContainer gap="8px" padding="8px 16px 16px 16px">
        <Select
          options={eventOptions}
          value={selectedConversionsEvent && selectedFormattedOption}
          onChange={onChangeSelect}
          components={{ Option: CustomOption }}
          styles={selectStyles}
          isClearable
          placeholder={<Body2>Search for an event</Body2>}
          noOptionsMessage={() => <Body2>No events found</Body2>}
          isLoading={eventsLoading}
        />
        {Boolean(showEventWarning && selectedConversionsEvent.length) && (
          <Body2 color={SystemColors.ERROR_COLOR}>
            We weren’t able to find a Pixel event with the name “{selectedConversionsEvent}”. Please make sure your
            website is configured to track the event to make sure your ads optimize accordingly.
          </Body2>
        )}
        <Container display="flex" gap="0px">
          <LinkButton
            onClick={() => Intercom.openLearnHowFindConversionsEventArticle()}
            text="Can't find your event? Learn how to find it >"
          />
        </Container>
      </EventSelectorContainer>
    </Fragment>
  )
}
