import { track } from 'analytics';
import { FunctionComponent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CampaignDetails } from 'types/global';

interface Props {
  setGoal: (goal: string) => void;
  campaigns: Array<CampaignDetails>;
}

const ToolsTab: FunctionComponent<Props> = ({
  setGoal,
  campaigns,
}: Props) => {
  const history = useHistory();

  const handleSelectGoal = (goal: string) => () => { setGoal(goal) }

  return (
    <div className="relative mb-10">
      <Link to={`/marketing/new/creatives`}>
        <div
          onClick={handleSelectGoal("creative_generator")}
          className="
      z-10
      overflow-hidden
      bg-white
      relative
      mt-6 flex flex-row justify-between items-center border-c4c4c4 br-10px"
        >
          <div className="bg-black flex w-6/12 sm:w-6/12 h-56 sm:h-40">
            <video className="w-full h-full" autoPlay loop muted playsInline>
              <source
                src="https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/videos/content-generator.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className=" px-4 py-1 flex w-full sm:w-18/12 flex-row justify-between items-center">
            <div className="flex flex-col items-baseline">
              <div className="flex flex-row justify-center items-center">
                <div className="">
                  <p className="md:text-lg text-md font-semibold">
                    Music Video Generator
                  </p>
                </div>
              </div>
              <p className="w-11/12 mt-3 text-sm">
                Get customized videos to post your music on Instagram, YouTube,
                TikTok, Twitter, Facebook and more.
              </p>
              <p className="w-11/12 mt-3 text-sm flex items-center">
                <img
                  src={require("assets/images/sparkles.svg").default}
                  alt="Sparkles"
                />
                <p className="ml-1">The easiest way to create assets.</p>
              </p>
            </div>

            <div className="cursor-pointer p-4 flex-shrink-0">
              <img
                alt="right-arrow.svg"
                width={10}
                src={require("assets/images/right-arrow.svg").default}
              />
            </div>
          </div>
        </div>
      </Link>

      {campaigns?.filter((campaign: CampaignDetails) => {
        const { campaign_metadata, status } = campaign;

        const { campaign_type } = campaign_metadata;

        return (
          ["IN_REVIEW", "ACTIVE", "FINISHED"].indexOf(status) > -1 &&
          campaign_type === "creative_generator"
        );
      }).length > 0 ? (
        <div
          onClick={() => {
            track("Viewed Music Video Generator");
            history.push(`/marketing/tools/creatives`);
          }}
          className="cursor-pointer top-3/4 rounded-lg pt-16 sm:pt-12 z-0 absolute w-full bg-primary p-2 text-center text-white font-medium"
        >
          See your Generated Videos &gt;
        </div>
      ) : null}
    </div>
  );
}

export default ToolsTab;