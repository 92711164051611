import styled, { keyframes } from 'styled-components';

interface MainContainerProps {
  width?: string
  padding?: string
  background?: string
  flexDirection?: string
  margin?: string
}

interface LinkTextProps {
  color?: string
}

interface ButtonsContainerProps {
  alignItems?: string
  width?: string
  justifyContent?: string
}

export const fade = keyframes`
  from {
    width: 0;
    border-radius: 0;
    opacity: 0.05;
  }
  to {
    width: 100%;
    border-top-left-radius: 3px 40%;
    border-bottom-left-radius: 3px 40%;
    opacity: 0.2;
  }
`;

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: ${({padding}) => padding || '16px'};
  width: ${({width}) => width || '100%'};
  height: fit-content;
  background: ${({background}) => background || '#edecf2'};
  border-radius: 8px;
  position: relative;
  margin: ${({margin}) => margin};
`;

export const LinkText = styled.p<LinkTextProps>`
  font-family: 'DIN';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({color}) => color || '#000000'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  max-height: 100%;
  width: 100%;
`;

export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ width }) => width || 'fit-content'};
  gap: 16px;
`;

export const FadeContainer = styled.div`
  animation: ${fade} 0.4s backwards;
  position: absolute;
  background: #8800ff;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
`;