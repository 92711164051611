import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { HelperLink, ErrorBanner } from "./styles";
import { ReactComponent as LinkIcon } from "assets/images/campaignDetails/link.svg";
import InputUPCISRC from "./InputUPCISRC";
import Intercom from "helpers/Intercom";
import PrimaryButton from "components/shareable/PrimaryButton";
import DialogPaper from "components/shareable/DialogPaper";
import Axios from "helpers/Interceptor";
import Logger from "Logger";
import { showToast } from "utils";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { CampaignDetails } from "types/global";
import HeaderModal from 'pages/post-auth/MarketingPage/Components/HeaderModal';
import { validateReleaseUrlUriUpcIsrc } from '../../utils';
import { track } from "analytics";

interface Props {
  open: boolean;
  closeModal: () => void;
  onSave?: (campaign: CampaignDetails) => void;
  value?: string;
  campaignId: number;
  trackObject?: Record<string, any>;
}

const ReleaseUrlDescription = () => {
  const onOpenIntercom = () => Intercom.openFindPresaveUPCArticle();
  return (
    <span>
      Edit your release’s UPC / ISRC.&nbsp;
      <u>
        <HelperLink onClick={onOpenIntercom}>
          Need help finding this info?
        </HelperLink>
      </u>
    </span>
  );
};

const EditUPCISRCModal: FunctionComponent<Props> = ({
  open,
  closeModal,
  value = "",
  campaignId,
  onSave,
  trackObject = {},
}: Props) => {
  const [releaseUrl, setReleaseUrl] = useState(value);
  const [showBanner, setShowBanner] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidReleaseUrl, setIsValidReleaseUrl] = useState(true);

  const handleOnChangeReleaseUrl = (value: string) => setReleaseUrl(value);
  const { mobileView } = useMediaBreakpoints();

  const handleOnSave = async () => {
    setLoading(true);
    setShowBanner(false);
    try {
      const url = `campaign/presave/${campaignId}/release-url-upc-isrc`;
      const response = await Axios.put(url, { releaseUrlUpcIsrc: releaseUrl });
      if (!response?.data || response.data.isError) {
        throw new Error(response.data.message);
      }

      const {
        campaign: updatedCampaign,
        alreadyExists,
      } = response.data.data as { campaign: CampaignDetails, alreadyExists: boolean };

      if (alreadyExists) {
        showToast({
          message: "This UPC/ISRC is already in use",
          mobile: mobileView,
          error: true,
        });
      } else {
        showToast({
          message: "Campaign updated",
          mobile: mobileView,
          error: false,
        });
        onSave?.(updatedCampaign);
      }

      track("Saved Edit UPC/ISRC", {
        ...trackObject,
        opened: 'Edit UPC/ISRC Modal'
      })
      closeModal();
    } catch (error) {
      setShowBanner(true);
      Logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setReleaseUrl(value);
    setShowBanner(false);
    track("Cancelled Edit UPC/ISRC", {
      ...trackObject,
      opened: 'Edit UPC/ISRC Modal'
    })
    closeModal();
  };

  useEffect(() => {
    const isValidUrl = validateReleaseUrlUriUpcIsrc(releaseUrl);
    setIsValidReleaseUrl(isValidUrl);
  }, [releaseUrl]);

  return (
    <DialogPaper open={open}>
      <Fragment>
        <HeaderModal
          closeModal={handleCancel}
          title="UPC / ISRC"
          subtitle={ReleaseUrlDescription()}
          HeaderIcon={LinkIcon}
        />
        <InputUPCISRC
          releaseUrl={releaseUrl}
          onChangeInput={handleOnChangeReleaseUrl}
          isValidReleaseUrl={isValidReleaseUrl}
          size="small"
        />
        {showBanner && (
          <ErrorBanner>
            Something went wrong when trying to update your UPC/ISRC - please try submitting again.
          </ErrorBanner>
        )}
        <PrimaryButton
          text="Save"
          width="100%"
          onClick={handleOnSave}
          loading={loading}
          disabled={!isValidReleaseUrl || loading || !releaseUrl}
        />
      </Fragment>
    </DialogPaper>
  );
};

export default EditUPCISRCModal;
