import { FunctionComponent } from "react";
import GemIconPurple from "assets/images/gem-icon-purple.svg";
import GemIconYellow from "assets/images/gem-icon-yellow.svg";
import { UpgradeLabelContainer, Icon, UpgradeLabel } from "./styles";
import isEqual from "lodash/isEqual";

enum Colors {
  PURPLE = "PURPLE",
  YELLOW = "YELLOW",
}

const { PURPLE, YELLOW } = Colors;

const getIcon = (color?: string) =>
  isEqual(color, YELLOW)
    ? GemIconYellow
    : isEqual(color, PURPLE)
    ? GemIconPurple
    : undefined;

interface Props {
  color?: string;
  label?: string;
  maxWidth?: string;
  width?: string;
  margin?: string;
  iconBeforeLabel?: React.ReactNode;
  iconAfterLabel?: React.ReactNode;
  customColors?: {
    backgroundColor?: string;
    labelColor?: string;
    borderColor?: string;
  };
}

const UpgradeToProLabel: FunctionComponent<Props> = ({
  color,
  label,
  maxWidth,
  width,
  iconBeforeLabel,
  iconAfterLabel,
  customColors,
  margin
}: Props) => {
  const { backgroundColor, labelColor, borderColor } = customColors || {};

  return (
    <UpgradeLabelContainer
      {...{ maxWidth }}
      width={width}
      margin={margin}
      backgroundColor={backgroundColor || color}
      borderColor={borderColor}
    >
      {!customColors && (
        <Icon alt="icon.svg" src={getIcon(color)} />
      )}
      {iconBeforeLabel && iconBeforeLabel}
      <UpgradeLabel color={labelColor || color}>{label}</UpgradeLabel>
      {iconAfterLabel && iconAfterLabel}
    </UpgradeLabelContainer>
  );
};

export default UpgradeToProLabel;
