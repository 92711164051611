import { FunctionComponent, useState } from "react"
//TODO: Delete this two icons. They will not be used anymore
import { ReactComponent as CircledSubtractIcon } from "assets/images/circled-less.svg"
import { ReactComponent as CircledAddIcon } from "assets/images/circled-add.svg"
import { ButtonText } from "components/shareable/Typography"
import { SystemColors } from "types/globalStyles"
import { Container, StyledButton } from "./styles"

interface Props {
  disabledSubtractButton?: boolean
  disabledAddButton?: boolean
  onAdd?: (value: number) => void
  onSubtract?: (value: number) => void
}

const Counter: FunctionComponent<Props> = ({
  disabledSubtractButton,
  disabledAddButton,
  onAdd,
  onSubtract,
}: Props) => {
  const [value, setValue] = useState(0)

  const handleAdd = (value: number) => () => {
    if (onAdd) onAdd(value + 1)
    setValue((prev) => prev + 1)
  }

  const handleSubtract = (value: number) => () => {
    if (onSubtract) onSubtract(value - 1)
    setValue((prev) => prev - 1)
  }

  return (
    <Container>
      <StyledButton
        disabled={disabledSubtractButton}
        onClick={handleSubtract(value)}
      >
        <CircledSubtractIcon />
      </StyledButton>
      <ButtonText color={SystemColors.PRIMARY_TEXT_COLOR}>{value}</ButtonText>
      <StyledButton disabled={disabledAddButton} onClick={handleAdd(value)}>
        <CircledAddIcon />
      </StyledButton>
    </Container>
  )
}

export default Counter
