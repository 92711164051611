export function extractSlugsFromFrontfacingLink(url: string, brandSlug: string): { url: string; urlSlug: string; brandSlug: string } {
    // Split the URL by '/'
    const parts = url.split('/');
  
    // Get the last part as the content slug
    const contentSlug = parts[parts.length - 1];
  
    // Create and return an object with the URL, content slug, and brand slug
    return {
      url: url,
      brandSlug: brandSlug,
      urlSlug: contentSlug
    };
  }