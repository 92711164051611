import { Dialog, Grid, InputAdornment, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import {
    ChangeEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';

import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { toast } from 'react-toastify';
import HeaderModal from '../../HeaderModal';
import Axios from 'helpers/Interceptor';
import some from "lodash/some";
import SymphonyLoadingLottie from "assets/images/lotties/SymphonyLoading_White.json"
import Lottie from "react-lottie"
import * as Sentry from "@sentry/react";
import { track } from 'analytics';
import { SHARED_TOAST_OPTIONS } from '../utils';
import {
    ButtonCancel, ButtonConfirm, ButtonsContainer, InputContainer, ModalContainer,
    useStyles
} from '../../../../../../components/shareable/Modal/styles';
import { localStyles } from './styles';
import { StyledLabel, TextButton } from 'styles/shared';
import { SYMPHONY_WEBSITE_URL } from '../../../../../../constants';
import clsx from 'clsx';
import SmallCard from 'components/shareable/SmallCard';
import { Severity } from 'components/shareable/SmallCard/styles';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';

const UpdatingSlugLabel: FunctionComponent = () => (
    <Grid container alignItems="center" alignContent="center">
        <Grid item xs={2}>
            <Lottie
                height={16}
                width={16}
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: SymphonyLoadingLottie,
                }}
            />
        </Grid>
        <Grid item xs>
            <p>Updating website...</p>
        </Grid>
    </Grid>
)

export const EditWebsiteSlugInput = (props: { prefixUrl: string, slug: string, handleSlugChange: (event: ChangeEvent<HTMLInputElement>) => void }) => {
    const localClasses = localStyles()
    const classes = useStyles()
    const { mobileView } = useMediaBreakpoints();
    const { prefixUrl, slug, handleSlugChange } = props

    function returnTextfieldStyles() {
        if (mobileView) {
            //set mobile styles for text field if its in mobile view
            return clsx(localClasses.mobileTextField, localClasses.textField, classes.textField)

        } else {
            return clsx(localClasses.textField, classes.textField)
        }
    }

    return (
        <TextField
            InputProps={{
                classes: {
                    root: classes.inputBorder,
                },
                startAdornment: <InputAdornment
                    position="start"
                    className={localClasses.inputAdornment}>{prefixUrl}</InputAdornment>,
            }}
            FormHelperTextProps={{
                classes: {
                    root: classes.formHelperText
                },
            }}
            className={returnTextfieldStyles()}
            variant="outlined"
            fullWidth
            type="text"
            value={slug}
            onChange={handleSlugChange}
        />
    )
}

interface Props {
    open: boolean
    closeModal: () => void
    onSave?: (newLink: string) => void
    onErrorHandler?: () => void;
    updatingContentSlug: string;
    brandSlug: string;
    brandId: number;
    campaignId?: number;
    trackObject?: Record<string, any>;
    url?: string
    onSaveUrl?: (newLink: string) => void
}


export const EditWebsiteSlugModal: FunctionComponent<Props> = ({
    open,
    updatingContentSlug,
    brandSlug,
    closeModal,
    onErrorHandler,
    onSave,
    campaignId,
    trackObject,
    brandId,
    url,
    onSaveUrl,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const [slugError, setSlugError] = useState<boolean>(false)
    const [specialCharacterError, setSpecialCharacterError] = useState<boolean>(false);
    const [urlAlreadyExistsError, setUrlAlreadyExistsError] = useState<boolean>(false)

    const [slug, setSlug] = useState<string>(updatingContentSlug || "")
    const classes = useStyles()

    const theme = useTheme()
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701))

    const SLUG_FULL_LENGTH = 30

    useEffect(() => {
        if (slug.trim().length > SLUG_FULL_LENGTH) {
            setSlugError(true)
        } else {
            setSlugError(false)
        }
    }, [slug])

    useEffect(() => {
        if (!open) {
            // reset the website name when the modal is closed
            // after a timeout so that the user doesn't see the name change
            // during the fade
            setTimeout(() => {
                setSlug(updatingContentSlug || "")
            }, 500)
        }
    }, [open])


    function isValidSlug(): boolean {
        // Add Regex check for the slug.
        const slugRegex = /^[a-zA-Z0-9-_]+$/;
        return Boolean(slug.trim() && slugRegex.test(slug.trim()) && slug.trim().length <= SLUG_FULL_LENGTH);
    }


    function handleClose() {
        // setSlug(currentSlug)
        setSpecialCharacterError(false)
        setSlugError(false)
        setUrlAlreadyExistsError(false)
        closeModal()
    }

    function handleSlugChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newSlug = event.target.value;
        const slugRegex = /^[a-zA-Z0-9-_]*$/;  // Only allow alphanumeric, dash, and underscore

        if (slugRegex.test(newSlug)) {
            setSpecialCharacterError(false);  // No special characters, clear the error
            setSlug(newSlug);
        } else {
            setSpecialCharacterError(true);  // Show an error if special characters are used
        }
    }

    function resetToOriginalSlug() {
        setSpecialCharacterError(false);
        setSlugError(false);
        setSlug(updatingContentSlug)
    }

    const handleConfirmRenameWebsite = async () => {
        setLoading(true)
        const options = SHARED_TOAST_OPTIONS
        const marginToast = setResponsiveView ? "24px 16px auto 16px" : "40px 22px auto"
        try {
            const cleanedSlug = slug.trim()
            if (cleanedSlug === updatingContentSlug) {
                if (onSave) {
                    onSave(cleanedSlug)
                }
                handleClose()
                return
            }
            //TODO: update route to change website's slug
            const response = await Axios.put('/website/url', {
                url: cleanedSlug,
                contentId: url,
                campaignId,
            });
            if (some([!response, !response.data, !response.data.data])) {
                throw new Error("Error updating website url");
            }
            toast.success('Your websites\'s URL was updated!', {
                ...options,
                style: {
                    ...options.style,
                    margin: marginToast
                }
            })

            if (onSave) {
                onSave(cleanedSlug)
            }
            if (onSaveUrl) {
                onSaveUrl(brandSlug + '-' + cleanedSlug)
            }

            track('Saved New Website URL', {
                ...trackObject,
                opened: 'New Website URL',
            })
            handleClose()
        } catch (e: any) {
            Sentry.captureException(`Error updating website url ${campaignId && `in campaign ${campaignId}`}: ${e}`)

            if (e?.response?.data?.message === 'URL already exists') {
                setUrlAlreadyExistsError(true)
            }

            toast.error('Error updating URL. Please try again.')
            onErrorHandler?.()
        } finally {
            setLoading(false)
            track('Edited Website URL')
        }
    }

    const prefixUrl = `${SYMPHONY_WEBSITE_URL}/${brandSlug}/`

    return (
        <Dialog
            open={open}
            classes={{
                root: classes.rootDialog,
            }}
        >
            <ModalContainer
                width={setResponsiveView ? "auto" : "678px"}
                padding={setResponsiveView ? "32px 24px" : "32px"}
            >
                <HeaderModal
                    HeaderIcon={EditIcon}
                    closeModal={handleClose}
                    title='Edit Website URL'
                    subtitle="You can customize your website's URL to anything you want."
                />
                <InputContainer>
                    <EditWebsiteSlugInput
                        prefixUrl={prefixUrl}
                        slug={slug}
                        handleSlugChange={handleSlugChange}
                    />
                    <TextButton onClick={resetToOriginalSlug}>Reset to Original URL</TextButton>
                    {slugError && <StyledLabel
                        color="red"
                        fontSize={16}
                    >Please only use a maximum of {SLUG_FULL_LENGTH} characters. You currently have {slug.length} characters.</StyledLabel>}
                    {specialCharacterError && <StyledLabel
                        color="red"
                        fontSize={16}
                    >
                        Your URL can only include letters, numbers, dashes (-), and underscores (_). No spaces or special characters like &, %, $ are allowed.
                    </StyledLabel>}
                    {urlAlreadyExistsError && <StyledLabel
                        color="red"
                        fontSize={16}
                    >It looks like you already have another website with this URL. Please use an entirely new URL to successfully update your website.</StyledLabel>}
                    <SmallCard
                        severity={Severity.WARNING}
                        text="If you have already shared your current URL, please note that the old link will no longer work once you update to a new URL." />
                </InputContainer>
                <ButtonsContainer
                    flexDirection={setResponsiveView ? "column-reverse" : "row"}
                >
                    <ButtonCancel
                        disabled={loading}
                        onClick={handleClose}
                        width={setResponsiveView ? "100%" : "fit-content"}
                    >
                        Cancel
                    </ButtonCancel>
                    <ButtonConfirm
                        disabled={loading || !isValidSlug() || slug === updatingContentSlug
                            || slugError}
                        onClick={handleConfirmRenameWebsite}
                        width={setResponsiveView ? "100%" : "50%"}
                    >
                        {loading ? <UpdatingSlugLabel /> : "Update website"}
                    </ButtonConfirm>
                </ButtonsContainer>
            </ModalContainer>
        </Dialog>
    )
}
