import { useContext, useEffect, useState } from "react";
import {
    Backdrop,
    Fade,
    Modal,
    Grid
} from "@material-ui/core";
import CloseButton from "components/shareable/CloseButton";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { MarketingCampaignType } from "types/global";
import { CAMPAIGN_CARDS, availableGoals } from "../../utils/marketing";
import { Link, useHistory } from "react-router-dom";
import MaestroCampaignRecommender from "./MaestroCampaignRecommender";
import useStyles from "./styles";
import CreateCampaignCard from "./CreateCampaignCard";
import { trackNewCampaign } from "../CampaignsSection/analytics";
import HorizontalModalWithVideo from "components/shareable/Modal/with-video";


interface CreateNewCampaignModalProps {
    open: boolean;
    close: () => void;
}



export default function CreateNewCampaignModal(props: CreateNewCampaignModalProps) {
    const { open, close } = props;
    const { currentBrand } = useContext(CurrentBrandContext);
    const classes = useStyles();

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const [maestroInput, setMaestroInput] = useState<string>("")


    // for helper popup modal 

    const [selectedModalHelper, setSelectedModalHelper] = useState<string | null>(null);
    const [showModalHelper, setShowModalHelper] = useState<boolean>(false);

    const history = useHistory()
    // when selectedModalHelper is set to null, 
    // hide it after 
    useEffect(() => {
        if (selectedModalHelper) {
            setShowModalHelper(true)
        }
    }, [selectedModalHelper])


    function closeModalHelper() {
        setShowModalHelper(false)
        setTimeout(() => {
            setSelectedModalHelper(null)
        }, 300)
    }


    return (
        <>
            {!showModalHelper && (<Modal
                aria-labelledby="connector-modal"
                aria-describedby="connector-modal for platforms"
                className={"flex items-center justify-center"}
                open={open}
                onClose={close}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={open}>
                    <div
                        className={`${classes.modalContent} w-full h-full md:h-5/6 md:w-4/6 lg:w-4/6 max-w-5xl	bg-white md:rounded-md py-5`}
                    >
                        <div className={`${classes.aiContainer} px-6`}>
                            <div className="flex justify-end">
                                <CloseButton onClick={close} />
                            </div>
                            <h1 className="text-2xl">Create a Campaign</h1>
                            <h2 className="text-md">
                                Run automated marketing campaigns to grow your streams, views, and
                                followers.
                            </h2>
                            <div className="w-full md:w-5/6 mt-2 lg:mt-6 mx-auto">
                                <MaestroCampaignRecommender
                                />
                            </div>
                        </div>
                        <div className={classes.playbooksContainer}>
                            <div className={`${classes.playbooksContent} px-6`}>
                                <div className="mb-4 w-full flex items-center flex-row items-center justify-center">
                                    <p className="mx-3 text-gray-500">Or Choose a Playbook:</p>
                                    <div className="h-0.5 flex-1 w-auto bg-gray-300"></div>
                                </div>
                                <div className="flex mt-4" >
                                    <div className={classes.campaignContainer}>
                                        <div className={classes.rightSection}>
                                            <div id="all">
                                                <Grid container spacing={2}>
                                                    {CAMPAIGN_CARDS.sort((campaignA: any, campaignB: any) => {
                                                        if (campaignA.underMaintenance && !campaignB.underMaintenance) {
                                                            return 1; // campaignA goes after campaignB
                                                        }
                                                        if (!campaignA.underMaintenance && campaignB.underMaintenance) {
                                                            return -1; // campaignA goes before campaignB
                                                        }
                                                        return 0; // no change in order

                                                    })
                                                        .map((campaign, index) => {
                                                            if (campaign.underMaintenance) {
                                                                return <Grid item key={index} xs={12} sm={6} md={4}>
                                                                    <CreateCampaignCard
                                                                        requiresPro={campaign.requiresPro}
                                                                        backgroundColor={campaign.backgroundColor}
                                                                        title={campaign.title}
                                                                        description={campaign.description}
                                                                        imageUrl={campaign.imageUrl}
                                                                        statusMessage={campaign.underMaintenance ? campaign.statusMessage : null}

                                                                        status={campaign.underMaintenance ? "maintenance" : null}
                                                                    />
                                                                </Grid>
                                                            } else {
                                                                return <Grid item key={index} xs={12} sm={6} md={4}>

                                                                    <CreateCampaignCard
                                                                        requiresPro={campaign.requiresPro}

                                                                        link={`/marketing/new/${campaign.link}`}
                                                                        onLinkClick={() => {
                                                                            trackNewCampaign({
                                                                                type: campaign.title,
                                                                                from: 'list',
                                                                                zeroState: false,
                                                                                aiQuestion: null,
                                                                                currentBrand: currentBrand
                                                                            })
                                                                        }}
                                                                        backgroundColor={campaign.backgroundColor}
                                                                        onHelpClick={!campaign.helperContent ? undefined : () => setSelectedModalHelper(campaign.id)}

                                                                        title={campaign.title}
                                                                        description={campaign.description}
                                                                        imageUrl={campaign.imageUrl}
                                                                        status={campaign.underMaintenance ? "maintenance" : null}
                                                                        statusMessage={campaign.underMaintenance ? campaign.statusMessage : null}
                                                                        isConversionsCampaign={campaign.isConversionsCampaign}
                                                                    />
                                                                </Grid>
                                                            }
                                                        })}
                                                </Grid>
                                            </div>
                                            {/* Add similar sections for other campaign categories */}
                                            <div id="growYourReleases">
                                                {/* ... */}
                                            </div>
                                            <div id="growYourFanbase">
                                                {/* ... */}
                                            </div>
                                            <div id="growTraffic">
                                                {/* ... */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>


            </Modal>)}
            <HorizontalModalWithVideo
                icon={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].icon : ""}
                open={showModalHelper}
                closeModal={closeModalHelper}
                title={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].title : ""}
                innerContent={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].helperContent : null}
                onClickButton={() => {
                    if (selectedModalHelper) {
                        trackNewCampaign({
                            type: availableGoals[selectedModalHelper].title,
                            from: 'helper-popup',
                            zeroState: true,
                            aiQuestion: null,
                            currentBrand: currentBrand
                        })

                        history.push(`/marketing/new/${availableGoals[selectedModalHelper].link}`)
                    }
                }}
                buttonText={"Create Campaign"}
                videoUrl={selectedModalHelper && availableGoals[selectedModalHelper] ? availableGoals[selectedModalHelper].walkthroughVideoUrl : ""}
            />
        </>
    );
}
