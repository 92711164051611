import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
  DropDownInputContainer,
  TargetingAgeGenderModalContainer,
  SaveTargetingButton,
} from './styles';
import { FBCustomAudience, LanguageChip } from 'types/global';
import { queryFB } from 'helpers/FB';
import RowItem from 'components/shareable/RowItem';
import Loaderv2 from 'components/Loader/v2';
import millify from 'millify';
import HeaderModal from '../HeaderModal';
import DialogPaper from 'components/shareable/DialogPaper';
import { track } from 'analytics';

interface Props {
  selectedFBAdAccount: any;
  openTargetingModal: boolean;
  isMobile: boolean;
  customAudiences: FBCustomAudience[];
  setCustomAudiences: (value: FBCustomAudience[]) => void;
  discardChanges: () => void;
  handleClose: () => void;
  setOpenTargetingModal: (value: boolean) => void;
  handleOnChangeDemographics: (data: {
    minAge: number,
    maxAge: number,
    gender: string,
    locales: Array<LanguageChip>
  }) => void;
}

export const TargetingExistingCustomAudiences: FunctionComponent<Props> = ({
  openTargetingModal,
  isMobile,
  selectedFBAdAccount,
  customAudiences,
  setCustomAudiences,
  setOpenTargetingModal,
}: Props) => {
  const [emptyLanguages, setEmptyLanguages] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleOnCloseModal = () => setOpenTargetingModal(false);

  const handleOnSaveTargeting = () => {

    if (emptyLanguages) setEmptyLanguages(false);

    setCustomAudiences(selectedToAdd);
    handleOnCloseModal();
  }

  const [userAdAccounts, setUserAdAccounts] = useState<FBCustomAudience[]>([]);
  const [selectedToAdd, setSelectedToAdd] = useState<FBCustomAudience[]>([]);

  async function getAllCustomAudiences(fbAdAccount: {
    id: string
  }, after: string | null = null): Promise<FBCustomAudience[]> {
    let params = {
      fields: 'name,id,rule,rule_aggregation,description,delivery_status,lookalike_spec,data_source,subtype,approximate_count_lower_bound,approximate_count_upper_bound,is_value_based,opt_out_link',
      limit: 500,
      after
    };

    const response = await queryFB(`/${fbAdAccount.id}/customaudiences`, { params });
    if (response.data && response.paging && response.paging.cursors && response.paging.cursors.after) {
      let returned = await getAllCustomAudiences(fbAdAccount, response.paging.cursors.after)
      if (!returned) return response.data;
      return response.data.concat(returned);
    } else {
      return response.data;
    }
  }

  async function getAudiences(selectedFBAdAccount: {
    id: string
  } | null) {
    setLoading(true);
    if (selectedFBAdAccount) {
      try {
        const audiences: FBCustomAudience[] = await getAllCustomAudiences(selectedFBAdAccount);
        setUserAdAccounts(audiences);
      } catch (error) {
        console.error(`Failed to fetch audiences: ${error}`);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (openTargetingModal) {
      if (selectedFBAdAccount && !loading && userAdAccounts.length === 0) {
        getAudiences(selectedFBAdAccount);
      }

      if (customAudiences.length > 0) {
        setSelectedToAdd(customAudiences);
      }
    }
  }, [openTargetingModal]);

  const nonSymphonyAudiences = userAdAccounts.filter((audience) => !audience.name.includes('[S]'));

  return (
    <DialogPaper open={openTargetingModal}>
      <Fragment>
        <HeaderModal
          closeModal={() => setOpenTargetingModal(false)}
          title='Import your Existing FB Audiences'
          subtitle="Select your existing Facebook audiences to target them while your Symphony campaign is running. We'll make 1 adset per selected audience."
        />
        <TargetingAgeGenderModalContainer gap={isMobile ? '24px' : '40px'} padding={isMobile ? '0px 0px' : '0px 0px'}>
          <DropDownInputContainer>
            {loading ? (
              <div className="flex-col flex items-center m-8 mx-auto ">
                <Loaderv2 />
                <p className="mt-1">Loading FB Audiences...</p>
              </div>
            ) : (
              nonSymphonyAudiences.length > 0 ? (
                <ul className="add-new border-b max-h-72 overflow-auto">
                  {nonSymphonyAudiences
                    .sort((a, b) => {
                      return a.name.localeCompare(b.name)
                    })
                    .map((item: any) => {
                      const selected = selectedToAdd.findIndex((p: any) => {
                        return p.id === item.id
                      }) > -1

                      let sizeLabel: string | null = `Approx. ${millify(item.approximate_count_lower_bound)} - ${millify(item.approximate_count_upper_bound)} people in this audience`
                      if (item.approximate_count_lower_bound === 0 && item.approximate_count_upper_bound === 0) {
                        sizeLabel = null
                      }

                      if (item.approximate_count_lower_bound === item.approximate_count_upper_bound) {
                        sizeLabel = `Approx. ${millify(item.approximate_count_lower_bound)} people in this audience`
                      }

                      if (item.approximate_count_lower_bound === -1 && item.approximate_count_upper_bound === -1) {
                        sizeLabel = "There aren't any people in this audience."
                      }

                      let modifierText = null

                      if (item.subtype === 'LOOKALIKE') {
                        modifierText = 'Lookalike Audience'
                      } else if (item.subtype === 'CUSTOM') {
                        modifierText = 'Custom Audience'
                      } else if (item.subtype === 'IG_BUSINESS') {
                        modifierText = 'Instagram Business-based Audience'
                      }

                      return (
                        <RowItem
                          multiSelect
                          selected={selected}
                          key={item.id}
                          onClick={() => {
                            if (!selected) {
                              const updatedSelected = [
                                ...selectedToAdd
                              ]

                              updatedSelected.push(item)
                              setSelectedToAdd(updatedSelected)

                            } else {
                              const updated = selectedToAdd.filter((o: any) => {
                                return o.id !== item.id
                              })

                              track("Selected Existing FB Audience", {
                                ...item
                              })
                              setSelectedToAdd(updated)
                            }

                          }}
                          name={item.name}
                          secondRowContent={(
                            <>
                              <div className="mt-1 flex flex-row items-center">
                                <div className="social-text">
                                  <p className="text-xs">
                                    {sizeLabel}
                                  </p>
                                </div>
                              </div>
                              {modifierText && (<div className="mt-1 flex flex-row items-center">
                                <p className="text-xs p-1.5 rounded-md bg-blue-300 mt-2 w-auto">
                                  {modifierText}
                                </p>
                              </div>)}
                            </>
                          )}
                        />
                      );
                    })}
                </ul>
              ) : (
                <div className='w-full text-center'>
                  <p>It looks like you don't have any custom audiences you've created in your ad account. Once you add custom audiences, you'll be able to pull them into your ads by selecting them here.</p>
                </div>
              )
            )}
          </DropDownInputContainer>
          <SaveTargetingButton
            disabled={selectedToAdd.length === 0}
            onClick={handleOnSaveTargeting}>Save custom audiences</SaveTargetingButton>
        </TargetingAgeGenderModalContainer>
      </Fragment>
    </DialogPaper>
  )
}
