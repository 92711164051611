import { getChannelIdFromUrl, isYouTubeChannelUrl, isYouTubeChannelUrlWithProfile, pullChannelIdFromUrl, queryYouTubeApi, queryYouTubeSearch } from "helpers/YouTube";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { LoadingIndicator } from "../Loader";
import RowItem from "../shareable/RowItem";

interface YouTubeConnectProps {
    setYoutubeSearchString: any,
    youtubeSearchString: any,
    youtubeSearchResults: any,
    setYoutubeSearchResult: any,
    selectYoutubeChannel: (object: any) => void,
    closeModal: (string: String) => void,
    artist: any
}
export default function YouTubeConnect(props: YouTubeConnectProps) {
    const {
        setYoutubeSearchString,
        youtubeSearchString,
        youtubeSearchResults,
        selectYoutubeChannel,
        setYoutubeSearchResult,
        closeModal,
        artist
    } = props

    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)


    const searchYoutube = async (e?: any) => {
        setYoutubeSearchString(e.target.value);
    }

    const searchChannel = async () => {
        if (youtubeSearchString.length) {
            setLoadingSearch(true)

            let results = null
            const isChannelUrl = isYouTubeChannelUrl(youtubeSearchString)
            const isChannelWithUsername = isYouTubeChannelUrlWithProfile(youtubeSearchString)

            if (isChannelUrl || isChannelWithUsername) {
                const channelId = await getChannelIdFromUrl(youtubeSearchString)

                if (channelId) {
                    try {
                        results = await queryYouTubeApi({
                            endpoint: '/channels',
                            params: {
                                id: channelId,
                                part: 'id,snippet',
                            }
                        });
                    } catch (error) {
                        console.error(error);
                    }
                }
            } else {
                try {
                    results = await queryYouTubeSearch({
                        type: 'channel',
                        q: youtubeSearchString
                    });
                } catch (error) {
                    console.error(error);
                }
            }

            if (results?.data?.items?.length) {
                setYoutubeSearchResult(results.data.items.map((o: any) => {
                    return {
                        ...o,
                        id: o.id.channelId
                    }
                }));
            }
            setLoadingSearch(false)
        }
    }

    useEffect(() => {
        searchChannel();
    }, [youtubeSearchString]);

    return (
        <div className="bg-white w-full h-full mx-auto">
            <div
                className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
            >
                <p className="font-semibold text-gray-800">Connect Youtube</p>
                <a onClick={() => closeModal('')}>
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </a>
            </div>
            <div className="middle-wrapper px-4 lg:px-10 ">
                <h4 className="mt-5 pb-5 text-2x font-semibold border-b border-gray-200">
                    What is the name or URL for {artist.name}'s YouTube channel?
                </h4>
                {/* <hr className="h-px	bg-gray-200" /> */}
                <form noValidate action="" onSubmit={(e) => e.preventDefault()}>
                    <div className="input-form flex flex-row align-middle relative">

                        <DebounceInput
                            className="border-b p-6 outline-none text-left border-gray-200 w-full"
                            minLength={2}
                            placeholder="eg. Jay-Z"
                            debounceTimeout={300}
                            value={youtubeSearchString}
                            onChange={searchYoutube} />
                        <label
                            htmlFor=""
                            className="absolute h-16 bg-white items-center flex text-lg right-0"
                        >
                            {loadingSearch ? (
                                <div className="ml-3 flex items-center justify-center">
                                    <LoadingIndicator
                                        color="black"
                                        height="30px" />
                                </div>
                            ) : null}
                        </label>
                    </div>
                    {youtubeSearchString && youtubeSearchString.length > 0 ? (

                        <ul className="add-new border-b mb-5 max-h-80 overflow-auto">
                            {youtubeSearchResults
                                .map((item: any) => {
                                    return (
                                        <RowItem
                                            selectable
                                            key={item.id}
                                            onClick={() => selectYoutubeChannel(item)}
                                            image={item.snippet.thumbnails.default.url}
                                            name={item.snippet.title}
                                            secondRowContent={(
                                                <div className="flex flex-row items-center">
                                                    <div className="social-text">
                                                        <p className="text-xs">
                                                            {item?.snippet?.description.slice(0, 140)}
                                                        </p>
                                                    </div>
                                                </div>

                                            )}
                                        />
                                    );
                                })}

                            {!loadingSearch && youtubeSearchString && (youtubeSearchResults && youtubeSearchResults.length === 0) ? (
                                <>
                                    <li className="text-center mt-3 pb-3 border-b">No YouTube channels found for "<span className="font-semibold">{youtubeSearchString}</span>".</li>

                                </>)
                                : null}
                        </ul>)
                        : null}
                </form>
                <p className="text-sm mt-auto">By connecting YouTube, you agree to the terms defined by YouTube API Services, <a target="_blank" href="http://www.google.com/policies/privacy" className="underline">Google's Privacy Policy</a> and <a target="_blank" href="https://www.youtube.com/t/terms" className="underline">YouTube's Terms of Service.</a></p>

            </div>
        </div>
    )
}