import { DownArrow, UpArrow } from "./icons";


export function GraphTooltip(props: {
    title: string;
    value: string | JSX.Element
}) {
    const {
        title,
        value
    } = props

    function renderValue() {
        if (typeof value === 'string') {
            return (
                <p className="font-semibold text-lg">{value}</p>

            )
        } else {
            return value
        }
    }
    return (
        <div className="bg-white rounded-md px-4 py-2 shadow-md">
            <p className="text-md">{`${title}`}</p>
            {renderValue()}
        </div>
    )
}

/**
 * Component to show the up / down in growth
 * @param props 
 * @returns 
 */
export function GrowthIndicatorMessage(props: {
    percentage: any
    className?: string
}) {
    const {
        percentage,
        className
    } = props

    if (!percentage) return null

    return (
        <div
            className={`rounded-lg ml-3 py-1 px-2 flex
            items-center justify-center
             ${percentage >= 0 ? "text-green" : "text-gray-500"}
             ${className ? className : ''}`}
            style={{
                backgroundColor: percentage >= 0 ? "#E1F8E1" : "#EBE7EF"
            }}
        >
            {
                // percentage === 0 ? null :
                percentage >= 0 ?
                    <UpArrow /> :
                    <DownArrow />}
            <p className="ml-1
            text-lg font-bold">{Math.abs(percentage || 0).toFixed(2)}%</p>
        </div>
    )

}