import { Theme, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import { MEDIA_BREAKPOINTS } from 'types/global'
import { SystemColors } from 'types/globalStyles'

interface VideoCoverProps {
  background: string
}

interface VideoButtonProps {
  height?: string
  width?: string
  color?: string
  background?: string
}

interface VideoContainerProps {
  width?: string
  minHeight?: string
  height?: string
}

interface ModalContainerProps {
  flexDirection?: string
}

interface ModalBodyProps {
  padding?: string
  width?: string
  gap?: string
}

interface CloseButtonProps {
  background?: string
}

export const useStyles = makeStyles((theme: Theme) => ({
  svgWhite: {
    width: '24px',
    height: '24px',
    '& path': {
      stroke: SystemColors.PAPER_CONTAINER,
    },
  },
  svgAccent: {
    '& path': {
      fill: SystemColors.ACCENT_COLOR,
    },
    '& path + path': {
      fill: SystemColors.PAPER_CONTAINER,
    },
  },
}));

export const VideoContainer = styled.div<VideoContainerProps>`
  width: ${({ width }) => width};
  min-height: ${({ minHeight }) => minHeight};
  height: ${({ height }) => height};
  position: relative;
`;

export const ModalContainer = styled.div<ModalContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  position: relative;
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
  ${MEDIA_BREAKPOINTS.mobileOnwards} {
    overflow: scroll;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${MEDIA_BREAKPOINTS.mobileView} {
    position: absolute;
    bottom: 0;
    padding: 24px 16px;
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  border-radius: 44px;
  background: ${SystemColors.PAPER_ACCENT_CONTAINER};
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: inherit;
`;

export const VideoCover = styled.div<VideoCoverProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  :hover {
    background: ${SystemColors.LIGHT_VIDEO_HOVER_LAYOUT};
  }
  :hover > div {
    background: ${SystemColors.VIDEO_BUTTON_CONTAINER};
    color: ${SystemColors.PAPER_CONTAINER};
  }
`;

export const VideoButton = styled.div<VideoButtonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  cursor: pointer;
`;

export const CloseButton = styled.div<CloseButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${SystemColors.PAPER_DIVIDER_CONTAINER};
  margin: 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  ${MEDIA_BREAKPOINTS.mobileView} {
    background: none;
  }
`;

export const MobileBanner = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: ${SystemColors.PAPER_WARNING_CONTAINER};
  color: ${SystemColors.PAPER_WARNING_CONTAINER};
`;