import dayjs from "dayjs";


export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeFirstLetterOfEveryWord(text: string) {
    const words = text.split(" ");
    for (let i = 0;i < words.length;i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(" ");
}

function roundDays(days: number) {
    const floatingRemainder = days % 1;

    if (floatingRemainder > 0.2) {
        return Math.ceil(days);
    } else {
        return Math.floor(days);
    }
}

export function daysCalculator(startDate: string, endDate: string) {
    var date1 = dayjs(startDate);
    var date2 = dayjs(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Days = date2.diff(date1, "day", true);
    return roundDays(Difference_In_Days);

    // const floatingRemainder = Difference_In_Days % 1;
    // if (floatingRemainder > 0.2) {
    //     return Math.ceil(Difference_In_Days);
    // } else {
    //     return Math.floor(Difference_In_Days);
    // }
}


export function daysLeftCalculator(startDate: string, endDate: string) {
    const daysLeft = daysCalculator(startDate, endDate);

    return roundDays(daysLeft);

    // const floatingRemainder = daysLeft % 1;
    // if (floatingRemainder > 0.2) {
    //     return Math.ceil(daysLeft);
    // } else {
    //     return Math.floor(daysLeft);
    // }
}

export function checkImageUrlValidity(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (error) => reject({ error, url, img });
        img.src = url;
    });
}

export function getNumberWithDecimals(number: number | string, decimals: number) {
    if (typeof number === "string") number = Number(number);
    return (Math.round(number * 100) / 100).toFixed(decimals)
}