import styled from 'styled-components';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    color: '#a641ff',
    width: 32,
    height: 32,
    maxWidth: 32,
    maxHeight: 32,
    borderRadius: '100%',
    boxShadow: 'inset 0 0 0px 6px #8800ff14',
    backgroundColor: 'transparent',
  },
  overallTooltip: {
    height: 20,
    width: 20,
  },
}));

interface MainContainerProps {
  flexDirection?: string;
  gap?: string;
  alignItems?: string;
}

interface MainContainerProps {
  flexDirection?: string;
  gap?: string;
  alignItems?: string;
}

interface ButtonContainerProps {
  justifyContent?: string;
}

interface ButtonLabelProps {
  color?: string;
}

export const TitleLabel = styled.p`
  font-family: DIN;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
`;

export const RemainingBudgetLabel = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
`;

export const LiveLabel = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #07c806;
`;

export const PausedLabel = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #e4900d;
`;

export const LiveLabelContainer = styled.div`
  background: rgba(7, 200, 6, 0.08);
  border-radius: 4px;
  align-items: center;
  padding: 8px;
`;

export const PausedLabelContainer = styled.div`
  background: rgba(228, 144, 13, 0.08);
  border-radius: 4px;
  align-items: center;
  padding: 8px;
`;

export const TitleAndStatusContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px;
  gap: 8px;
`;

export const PerformanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ButtonContainer = styled.button`
  display: flex;
  justify-content: ${({ justifyContent }: ButtonContainerProps) =>
    justifyContent};
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
  border: none;
  :disabled {
    background-color: transparent;
  }
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: #ffffff;
`;

export const ButtonLabel = styled.p`
  font-family: DIN;
  color: ${({ color }: ButtonLabelProps) => color};
  font-size: 16px;
  line-height: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  padding: 24px 0px 8px;
  gap: 8px;
  align-items: ${({ alignItems }: MainContainerProps) => alignItems};
  flex-direction: ${({ flexDirection }: MainContainerProps) => flexDirection};
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default useStyles;
