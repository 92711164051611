import { SPOTIFY_AVAILABLE_MARKETS } from "./Spotify"

const COUNTRY_CODES = [{
    "code": "AD",
    "name": "Andorra",
    "population": 76965
}, {
    "code": "AE",
    "name": "United Arab Emirates",
    "population": 9400145
}, {
    "code": "AF",
    "name": "Afghanistan",
    "population": 35530081
}, {
    "code": "AG",
    "name": "Antigua and Barbuda",
    "population": 102012
}, {
    "code": "AI",
    "name": "Anguilla",
    "population": 14909
}, {
    "code": "AL",
    "name": "Albania",
    "population": 2930187
}, {
    "code": "AM",
    "name": "Armenia",
    "population": 2930450
}, {
    "code": "AO",
    "name": "Angola",
    "population": 29784193
}, {
    "code": "AQ",
    "name": "Antarctica",
    "population": null
}, {
    "code": "AR",
    "name": "Argentina",
    "population": 44271041
}, {
    "code": "AS",
    "name": "American Samoa",
    "population": 55641
}, {
    "code": "AT",
    "name": "Austria",
    "population": 8735453
}, {
    "code": "AU",
    "name": "Australia",
    "population": 24450561
}, {
    "code": "AW",
    "name": "Aruba",
    "population": 105264
}, {
    "code": "AX",
    "name": "Åland Islands",
    "population": null
}, {
    "code": "AZ",
    "name": "Azerbaijan",
    "population": 9827589
}, {
    "code": "BA",
    "name": "Bosnia and Herzegovina",
    "population": 3507017
}, {
    "code": "BB",
    "name": "Barbados",
    "population": 285719
}, {
    "code": "BD",
    "name": "Bangladesh",
    "population": 164669751
}, {
    "code": "BE",
    "name": "Belgium",
    "population": 11429336
}, {
    "code": "BF",
    "name": "Burkina Faso",
    "population": 19193382
}, {
    "code": "BG",
    "name": "Bulgaria",
    "population": 7084571
}, {
    "code": "BH",
    "name": "Bahrain",
    "population": 1492584
}, {
    "code": "BI",
    "name": "Burundi",
    "population": 10864245
}, {
    "code": "BJ",
    "name": "Benin",
    "population": 11175692
}, {
    "code": "BL",
    "name": "Saint Barthélemy",
    "population": null
}, {
    "code": "BM",
    "name": "Bermuda",
    "population": 61349
}, {
    "code": "BN",
    "name": "Brunei Darussalam",
    "population": null
}, {
    "code": "BO",
    "name": "Bolivia",
    "population": 11051600
}, {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius and Saba",
    "population": null
}, {
    "code": "BR",
    "name": "Brazil",
    "population": 209288278
}, {
    "code": "BS",
    "name": "Bahamas",
    "population": 395361
}, {
    "code": "BT",
    "name": "Bhutan",
    "population": 807610
}, {
    "code": "BV",
    "name": "Bouvet Island",
    "population": null
}, {
    "code": "BW",
    "name": "Botswana",
    "population": 2291661
}, {
    "code": "BY",
    "name": "Belarus",
    "population": 9468338
}, {
    "code": "BZ",
    "name": "Belize",
    "population": 374681
}, {
    "code": "CA",
    "name": "Canada",
    "population": 36624199
}, {
    "code": "CC",
    "name": "Cocos (Keeling) Islands",
    "population": 600
}, {
    "code": "CD",
    "name": "Congo",
    "population": 5260750
}, {
    "code": "CF",
    "name": "Central African Republic",
    "population": 4659080
}, {
    "code": "CG",
    "name": "Congo",
    "population": 5260750
}, {
    "code": "CH",
    "name": "Switzerland",
    "population": 8476005
}, {
    "code": "CI",
    "name": "Côte d'Ivoire",
    "population": null
}, {
    "code": "CK",
    "name": "Cook Islands",
    "population": 17380
}, {
    "code": "CL",
    "name": "Chile",
    "population": 18054726
}, {
    "code": "CM",
    "name": "Cameroon",
    "population": 24053727
}, {
    "code": "CN",
    "name": "China",
    "population": 1409517397
}, {
    "code": "CO",
    "name": "Colombia",
    "population": 49065615
}, {
    "code": "CR",
    "name": "Costa Rica",
    "population": 4905769
}, {
    "code": "CV",
    "name": "Cabo Verde",
    "population": null
}, {
    "code": "CW",
    "name": "Curaçao",
    "population": null
}, {
    "code": "CX",
    "name": "Christmas Island",
    "population": 2500
}, {
    "code": "CY",
    "name": "Cyprus",
    "population": 1179551
}, {
    "code": "CZ",
    "name": "Czech Republic",
    "population": 10618303
}, {
    "code": "DE",
    "name": "Germany",
    "population": 82114224
}, {
    "code": "DJ",
    "name": "Djibouti",
    "population": 956985
}, {
    "code": "DK",
    "name": "Denmark",
    "population": 5733551
}, {
    "code": "DM",
    "name": "Dominica",
    "population": 73925
}, {
    "code": "DO",
    "name": "Dominican Republic",
    "population": 10766998
}, {
    "code": "DZ",
    "name": "Algeria",
    "population": 41318142
}, {
    "code": "EC",
    "name": "Ecuador",
    "population": 16624858
}, {
    "code": "EE",
    "name": "Estonia",
    "population": 1309632
}, {
    "code": "EG",
    "name": "Egypt",
    "population": 97553151
}, {
    "code": "EH",
    "name": "Western Sahara",
    "population": 552628
}, {
    "code": "ER",
    "name": "Eritrea",
    "population": 5068831
}, {
    "code": "ES",
    "name": "Spain",
    "population": 46354321
}, {
    "code": "ET",
    "name": "Ethiopia",
    "population": 104957438
}, {
    "code": "FI",
    "name": "Finland",
    "population": 5523231
}, {
    "code": "FJ",
    "name": "Fiji Islands",
    "population": 905502
}, {
    "code": "FK",
    "name": "Falkland Islands",
    "population": 2910
}, {
    "code": "FM",
    "name": "Micronesia, Federated States of",
    "population": null
}, {
    "code": "FO",
    "name": "Faroe Islands",
    "population": 49290
}, {
    "code": "FR",
    "name": "France",
    "population": 64979548
}, {
    "code": "GA",
    "name": "Gabon",
    "population": 2025137
}, {
    "code": "GB",
    "name": "United Kingdom",
    "population": 66181585
}, {
    "code": "GD",
    "name": "Grenada",
    "population": 107825
}, {
    "code": "GE",
    "name": "Georgia",
    "population": 3912061
}, {
    "code": "GF",
    "name": "French Guiana",
    "population": 282731
}, {
    "code": "GG",
    "name": "Guernsey",
    "population": null
}, {
    "code": "GH",
    "name": "Ghana",
    "population": 28833629
}, {
    "code": "GI",
    "name": "Gibraltar",
    "population": 34571
}, {
    "code": "GL",
    "name": "Greenland",
    "population": 56480
}, {
    "code": "GM",
    "name": "Gambia",
    "population": 2100568
}, {
    "code": "GN",
    "name": "Guinea",
    "population": 12717176
}, {
    "code": "GP",
    "name": "Guadeloupe",
    "population": 449568
}, {
    "code": "GQ",
    "name": "Equatorial Guinea",
    "population": 1267689
}, {
    "code": "GR",
    "name": "Greece",
    "population": 11159773
}, {
    "code": "GS",
    "name": "South Georgia and the South Sandwich Islands",
    "population": null
}, {
    "code": "GT",
    "name": "Guatemala",
    "population": 16913503
}, {
    "code": "GU",
    "name": "Guam",
    "population": 164229
}, {
    "code": "GW",
    "name": "Guinea-Bissau",
    "population": 1861283
}, {
    "code": "GY",
    "name": "Guyana",
    "population": 777859
}, {
    "code": "HK",
    "name": "Hong Kong",
    "population": 7364883
}, {
    "code": "HM",
    "name": "Heard Island and McDonald Islands",
    "population": null
}, {
    "code": "HN",
    "name": "Honduras",
    "population": 9265067
}, {
    "code": "HR",
    "name": "Croatia",
    "population": 4189353
}, {
    "code": "HT",
    "name": "Haiti",
    "population": 10981229
}, {
    "code": "HU",
    "name": "Hungary",
    "population": 9721559
},
{
    "code": "ID",
    "name": "Indonesia",
    "population": 263991379
},
{
    "code": "IE",
    "name": "Ireland",
    "population": 4761657
}, {
    "code": "IL",
    "name": "Israel",
    "population": 8321570
}, {
    "code": "IM",
    "name": "Isle of Man",
    "population": null
},
{
    "code": "IN",
    "name": "India",
    "population": 1339180127
},
{
    "code": "IO",
    "name": "British Indian Ocean Territory",
    "population": null
}, {
    "code": "IQ",
    "name": "Iraq",
    "population": 38274618
}, {
    "code": "IS",
    "name": "Iceland",
    "population": 335025
}, {
    "code": "IT",
    "name": "Italy",
    "population": 59359900
}, {
    "code": "JE",
    "name": "Jersey",
    "population": null
}, {
    "code": "JM",
    "name": "Jamaica",
    "population": 2890299
}, {
    "code": "JO",
    "name": "Jordan",
    "population": 9702353
}, {
    "code": "JP",
    "name": "Japan",
    "population": 127484450
}, {
    "code": "KE",
    "name": "Kenya",
    "population": 49699862
}, {
    "code": "KG",
    "name": "Kyrgyzstan",
    "population": 6045117
}, {
    "code": "KH",
    "name": "Cambodia",
    "population": 16005373
}, {
    "code": "KI",
    "name": "Kiribati",
    "population": 116398
}, {
    "code": "KM",
    "name": "Comoros",
    "population": 813912
}, {
    "code": "KN",
    "name": "Saint Kitts and Nevis",
    "population": 55345
}, {
    "code": "KR",
    "name": "South Korea",
    "population": 50982212
}, {
    "code": "KW",
    "name": "Kuwait",
    "population": 4136528
}, {
    "code": "KY",
    "name": "Cayman Islands",
    "population": 61559
}, {
    "code": "KZ",
    "name": "Kazakhstan",
    "population": 18204499
}, {
    "code": "LA",
    "name": "Lao People's Democratic Republic",
    "population": 6858160
}, {
    "code": "LB",
    "name": "Lebanon",
    "population": 6082357
}, {
    "code": "LC",
    "name": "Saint Lucia",
    "population": 178844
}, {
    "code": "LI",
    "name": "Liechtenstein",
    "population": 37922
}, {
    "code": "LK",
    "name": "Sri Lanka",
    "population": 21440000
}, {
    "code": "LR",
    "name": "Liberia",
    "population": 4731906
}, {
    "code": "LS",
    "name": "Lesotho",
    "population": 2233339
}, {
    "code": "LT",
    "name": "Lithuania",
    "population": 2890297
}, {
    "code": "LU",
    "name": "Luxembourg",
    "population": 583455
}, {
    "code": "LV",
    "name": "Latvia",
    "population": 1949670
}, {
    "code": "LY",
    "name": "Libya",
    "population": 6375000
}, {
    "code": "MA",
    "name": "Morocco",
    "population": 35739580
}, {
    "code": "MC",
    "name": "Monaco",
    "population": 38695
}, {
    "code": "MD",
    "name": "Moldova",
    "population": 4051212
}, {
    "code": "ME",
    "name": "Montenegro",
    "population": null
}, {
    "code": "MF",
    "name": "Saint Martin, (French part)",
    "population": null
}, {
    "code": "MG",
    "name": "Madagascar",
    "population": 25570895
}, {
    "code": "MH",
    "name": "Marshall Islands",
    "population": 53127
}, {
    "code": "MK",
    "name": "North Macedonia",
    "population": 2024000
}, {
    "code": "ML",
    "name": "Mali",
    "population": 18541980
}, {
    "code": "MM",
    "name": "Myanmar",
    "population": 53370609
}, {
    "code": "MN",
    "name": "Mongolia",
    "population": 3075647
}, {
    "code": "MO",
    "name": "Macao",
    "population": 473000
}, {
    "code": "MP",
    "name": "Northern Mariana Islands",
    "population": 55144
}, {
    "code": "MQ",
    "name": "Martinique",
    "population": 384896
}, {
    "code": "MR",
    "name": "Mauritania",
    "population": 4420184
}, {
    "code": "MS",
    "name": "Montserrat",
    "population": 5177
}, {
    "code": "MT",
    "name": "Malta",
    "population": 430835
}, {
    "code": "MU",
    "name": "Mauritius",
    "population": 1265138
}, {
    "code": "MV",
    "name": "Maldives",
    "population": 436330
}, {
    "code": "MW",
    "name": "Malawi",
    "population": 18622104
}, {
    "code": "MX",
    "name": "Mexico",
    "population": 129163276
}, {
    "code": "MY",
    "name": "Malaysia",
    "population": 31624264
}, {
    "code": "MZ",
    "name": "Mozambique",
    "population": 29668834
}, {
    "code": "NA",
    "name": "Namibia",
    "population": 2533794
}, {
    "code": "NC",
    "name": "New Caledonia",
    "population": 276255
}, {
    "code": "NE",
    "name": "Niger",
    "population": 21477348
}, {
    "code": "NF",
    "name": "Norfolk Island",
    "population": 2000
}, {
    "code": "NG",
    "name": "Nigeria",
    "population": 190886311
}, {
    "code": "NI",
    "name": "Nicaragua",
    "population": 6217581
}, {
    "code": "NL",
    "name": "Netherlands",
    "population": 17035938
}, {
    "code": "NO",
    "name": "Norway",
    "population": 5305383
}, {
    "code": "NP",
    "name": "Nepal",
    "population": 29304998
}, {
    "code": "NR",
    "name": "Nauru",
    "population": 11359
}, {
    "code": "NU",
    "name": "Niue",
    "population": 1618
}, {
    "code": "NZ",
    "name": "New Zealand",
    "population": 4705818
}, {
    "code": "OM",
    "name": "Oman",
    "population": 4636262
}, {
    "code": "PA",
    "name": "Panama",
    "population": 4098587
}, {
    "code": "PE",
    "name": "Peru",
    "population": 32165485
}, {
    "code": "PF",
    "name": "French Polynesia",
    "population": 283007
}, {
    "code": "PG",
    "name": "Papua New Guinea",
    "population": 8251162
}, {
    "code": "PH",
    "name": "Philippines",
    "population": 104918090
}, {
    "code": "PK",
    "name": "Pakistan",
    "population": 197015955
}, {
    "code": "PL",
    "name": "Poland",
    "population": 38170712
}, {
    "code": "PM",
    "name": "Saint Pierre and Miquelon",
    "population": 6320
}, {
    "code": "PN",
    "name": "Pitcairn",
    "population": 50
}, {
    "code": "PR",
    "name": "Puerto Rico",
    "population": 3663131
}, {
    "code": "PS",
    "name": "Palestine, State of",
    "population": null
}, {
    "code": "PT",
    "name": "Portugal",
    "population": 10329506
}, {
    "code": "PW",
    "name": "Palau",
    "population": 21729
}, {
    "code": "PY",
    "name": "Paraguay",
    "population": 6811297
}, {
    "code": "QA",
    "name": "Qatar",
    "population": 2639211
}, {
    "code": "RE",
    "name": "Réunion",
    "population": null
}, {
    "code": "RO",
    "name": "Romania",
    "population": 19679306
}, {
    "code": "RS",
    "name": "Serbia",
    "population": 7022000
},
//  {
//     "code": "RU",
//     "name": "Russian Federation",
//     "population": 143989754
// }, 
{
    "code": "RW",
    "name": "Rwanda",
    "population": 12208407
}, {
    "code": "SA",
    "name": "Saudi Arabia",
    "population": 32938213
}, {
    "code": "SB",
    "name": "Solomon Islands",
    "population": 611343
}, {
    "code": "SC",
    "name": "Seychelles",
    "population": 94737
}, {
    "code": "SE",
    "name": "Sweden",
    "population": 9910701
}, {
    "code": "SG",
    "name": "Singapore",
    "population": 5708844
}, {
    "code": "SH",
    "name": "Saint Helena, Ascension and Tristan da Cunha",
    "population": null
}, {
    "code": "SI",
    "name": "Slovenia",
    "population": 2079976
}, {
    "code": "SJ",
    "name": "Svalbard and Jan Mayen",
    "population": 3200
}, {
    "code": "SK",
    "name": "Slovakia",
    "population": 5447662
}, {
    "code": "SL",
    "name": "Sierra Leone",
    "population": 7557212
}, {
    "code": "SM",
    "name": "San Marino",
    "population": 33400
}, {
    "code": "SN",
    "name": "Senegal",
    "population": 15850567
}, {
    "code": "SO",
    "name": "Somalia",
    "population": 14742523
}, {
    "code": "SR",
    "name": "Suriname",
    "population": 563402
}, {
    "code": "SS",
    "name": "South Sudan",
    "population": 12575714
}, {
    "code": "ST",
    "name": "Sao Tome and Principe",
    "population": 204327
}, {
    "code": "SV",
    "name": "El Salvador",
    "population": 6377853
}, {
    "code": "SX",
    "name": "Sint Maarten, (Dutch part)",
    "population": null
}, {
    "code": "SZ",
    "name": "Eswatini",
    "population": null
}, {
    "code": "TC",
    "name": "Turks and Caicos Islands",
    "population": 35446
}, {
    "code": "TD",
    "name": "Chad",
    "population": 14899994
}, {
    "code": "TF",
    "name": "French Southern Territories",
    "population": null
}, {
    "code": "TG",
    "name": "Togo",
    "population": 7797694
},
//  {
//     "code": "TH",
//     "name": "Thailand",
//     "population": 69037513
// },
{
    "code": "TJ",
    "name": "Tajikistan",
    "population": 8921343
}, {
    "code": "TK",
    "name": "Tokelau",
    "population": 1300
}, {
    "code": "TL",
    "name": "Timor-Leste",
    "population": null
}, {
    "code": "TM",
    "name": "Turkmenistan",
    "population": 5758075
}, {
    "code": "TN",
    "name": "Tunisia",
    "population": 11532127
}, {
    "code": "TO",
    "name": "Tonga",
    "population": 108020
}, {
    "code": "TR",
    "name": "Turkey",
    "population": 80745020
}, {
    "code": "TT",
    "name": "Trinidad and Tobago",
    "population": 1369125
}, {
    "code": "TV",
    "name": "Tuvalu",
    "population": 11192
}, {
    "code": "TW",
    "name": "Taiwan",
    "population": 23780000
}, {
    "code": "TZ",
    "name": "Tanzania",
    "population": 57310019
}, {
    "code": "UA",
    "name": "Ukraine",
    "population": 44222947
}, {
    "code": "UG",
    "name": "Uganda",
    "population": 42862958
}, {
    "code": "UM",
    "name": "United States Minor Outlying Islands",
    "population": null
}, {
    "code": "US",
    "name": "United States of America",
    "population": 324459463
}, {
    "code": "UY",
    "name": "Uruguay",
    "population": 3456750
}, {
    "code": "UZ",
    "name": "Uzbekistan",
    "population": 31910641
}, {
    "code": "VA",
    "name": "Holy See",
    "population": null
}, {
    "code": "VC",
    "name": "Saint Vincent and the Grenadines",
    "population": 109897
}, {
    "code": "VE",
    "name": "Venezuela",
    "population": 31977065
}, {
    "code": "VG",
    "name": "Virgin Islands, British",
    "population": null
}, {
    "code": "VI",
    "name": "Virgin Islands, U.S.",
    "population": null
}, {
    "code": "VN",
    "name": "Vietnam",
    "population": 95540800
}, {
    "code": "VU",
    "name": "Vanuatu",
    "population": 276244
}, {
    "code": "WF",
    "name": "Wallis and Futuna",
    "population": 11773
}, {
    "code": "WS",
    "name": "Samoa",
    "population": 196440
}, {
    "code": "YE",
    "name": "Yemen",
    "population": 28250420
}, {
    "code": "YT",
    "name": "Mayotte",
    "population": 253045
}, {
    "code": "ZA",
    "name": "South Africa",
    "population": 56717156
}, {
    "code": "ZM",
    "name": "Zambia",
    "population": 17094130
}, {
    "code": "ZW",
    "name": "Zimbabwe",
    "population": 16529904
}]

export const isoCountries = {
    'AF': 'Afghanistan',
    'AX': 'Aland Islands',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'American Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua And Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaijan',
    'BS': 'Bahamas',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgium',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia',
    'BA': 'Bosnia And Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet Island',
    'BR': 'Brazil',
    'IO': 'British Indian Ocean Territory',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'KH': 'Cambodia',
    'CM': 'Cameroon',
    'CA': 'Canada',
    'CV': 'Cape Verde',
    'KY': 'Cayman Islands',
    'CF': 'Central African Republic',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands',
    'CO': 'Colombia',
    'KM': 'Comoros',
    'CG': 'Congo',
    'CD': 'Congo, Democratic Republic',
    'CK': 'Cook Islands',
    'CR': 'Costa Rica',
    'CI': 'Cote D\'Ivoire',
    'HR': 'Croatia',
    'CU': 'Cuba',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DK': 'Denmark',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'EC': 'Ecuador',
    'EG': 'Egypt',
    'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'ET': 'Ethiopia',
    'FK': 'Falkland Islands (Malvinas)',
    'FO': 'Faroe Islands',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'France',
    'GF': 'French Guiana',
    'PF': 'French Polynesia',
    'TF': 'French Southern Territories',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germany',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Greece',
    'GL': 'Greenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard Island & Mcdonald Islands',
    'VA': 'Holy See (Vatican City State)',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IN': 'India',
    // 'ID': 'Indonesia',
    'IR': 'Iran, Islamic Republic Of',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IM': 'Isle Of Man',
    'IL': 'Israel',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KR': 'Korea',
    'KW': 'Kuwait',
    'KG': 'Kyrgyzstan',
    'LA': 'Lao People\'s Democratic Republic',
    'LV': 'Latvia',
    'LB': 'Lebanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libyan Arab Jamahiriya',
    'LI': 'Liechtenstein',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MK': 'Macedonia',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshall Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesia, Federated States Of',
    'MD': 'Moldova',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Morocco',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Netherlands',
    'AN': 'Netherlands Antilles',
    'NC': 'New Caledonia',
    'NZ': 'New Zealand',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk Island',
    'MP': 'Northern Mariana Islands',
    'NO': 'Norway',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestinian Territory, Occupied',
    'PA': 'Panama',
    'PG': 'Papua New Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippines',
    'PN': 'Pitcairn',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'RE': 'Reunion',
    'RO': 'Romania',
    // 'RU': 'Russian Federation',
    'RW': 'Rwanda',
    'BL': 'Saint Barthelemy',
    'SH': 'Saint Helena',
    'KN': 'Saint Kitts And Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint Martin',
    'PM': 'Saint Pierre And Miquelon',
    'VC': 'Saint Vincent And Grenadines',
    'WS': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome And Principe',
    'SA': 'Saudi Arabia',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'SB': 'Solomon Islands',
    'SO': 'Somalia',
    'ZA': 'South Africa',
    'GS': 'South Georgia And Sandwich Isl.',
    'ES': 'Spain',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard And Jan Mayen',
    'SZ': 'Swaziland',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'SY': 'Syrian Arab Republic',
    'TW': 'Taiwan',
    'TJ': 'Tajikistan',
    'TZ': 'Tanzania',
    // 'TH': 'Thailand',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad And Tobago',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TM': 'Turkmenistan',
    'TC': 'Turks And Caicos Islands',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom',
    'US': 'United States',
    'UM': 'United States Outlying Islands',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Viet Nam',
    'VG': 'Virgin Islands, British',
    'VI': 'Virgin Islands, U.S.',
    'WF': 'Wallis And Futuna',
    'EH': 'Western Sahara',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe'
}

export const country_continents = {
    "AD": {
        "name": "Andorra",
        "native": "Andorra",
        "phone": "376",
        "continent": "EU",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "languages": [
            "ca"
        ]
    },
    "AE": {
        "name": "United Arab Emirates",
        "native": "دولة الإمارات العربية المتحدة",
        "phone": "971",
        "continent": "AS",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "languages": [
            "ar"
        ]
    },
    "AF": {
        "name": "Afghanistan",
        "native": "افغانستان",
        "phone": "93",
        "continent": "AS",
        "capital": "Kabul",
        "currency": "AFN",
        "languages": [
            "ps",
            "uz",
            "tk"
        ]
    },
    "AG": {
        "name": "Antigua and Barbuda",
        "native": "Antigua and Barbuda",
        "phone": "1268",
        "continent": "NA",
        "capital": "Saint John's",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "AI": {
        "name": "Anguilla",
        "native": "Anguilla",
        "phone": "1264",
        "continent": "NA",
        "capital": "The Valley",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "AL": {
        "name": "Albania",
        "native": "Shqipëria",
        "phone": "355",
        "continent": "EU",
        "capital": "Tirana",
        "currency": "ALL",
        "languages": [
            "sq"
        ]
    },
    "AM": {
        "name": "Armenia",
        "native": "Հայաստան",
        "phone": "374",
        "continent": "AS",
        "capital": "Yerevan",
        "currency": "AMD",
        "languages": [
            "hy",
            "ru"
        ]
    },
    "AO": {
        "name": "Angola",
        "native": "Angola",
        "phone": "244",
        "continent": "AF",
        "capital": "Luanda",
        "currency": "AOA",
        "languages": [
            "pt"
        ]
    },
    "AQ": {
        "name": "Antarctica",
        "native": "Antarctica",
        "phone": "672",
        "continent": "AN",
        "capital": "",
        "currency": "",
        "languages": []
    },
    "AR": {
        "name": "Argentina",
        "native": "Argentina",
        "phone": "54",
        "continent": "SA",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "languages": [
            "es",
            "gn"
        ]
    },
    "AS": {
        "name": "American Samoa",
        "native": "American Samoa",
        "phone": "1684",
        "continent": "OC",
        "capital": "Pago Pago",
        "currency": "USD",
        "languages": [
            "en",
            "sm"
        ]
    },
    "AT": {
        "name": "Austria",
        "native": "Österreich",
        "phone": "43",
        "continent": "EU",
        "capital": "Vienna",
        "currency": "EUR",
        "languages": [
            "de"
        ]
    },
    "AU": {
        "name": "Australia",
        "native": "Australia",
        "phone": "61",
        "continent": "OC",
        "capital": "Canberra",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "AW": {
        "name": "Aruba",
        "native": "Aruba",
        "phone": "297",
        "continent": "NA",
        "capital": "Oranjestad",
        "currency": "AWG",
        "languages": [
            "nl",
            "pa"
        ]
    },
    "AX": {
        "name": "Åland",
        "native": "Åland",
        "phone": "358",
        "continent": "EU",
        "capital": "Mariehamn",
        "currency": "EUR",
        "languages": [
            "sv"
        ]
    },
    "AZ": {
        "name": "Azerbaijan",
        "native": "Azərbaycan",
        "phone": "994",
        "continent": "AS",
        "capital": "Baku",
        "currency": "AZN",
        "languages": [
            "az"
        ]
    },
    "BA": {
        "name": "Bosnia and Herzegovina",
        "native": "Bosna i Hercegovina",
        "phone": "387",
        "continent": "EU",
        "capital": "Sarajevo",
        "currency": "BAM",
        "languages": [
            "bs",
            "hr",
            "sr"
        ]
    },
    "BB": {
        "name": "Barbados",
        "native": "Barbados",
        "phone": "1246",
        "continent": "NA",
        "capital": "Bridgetown",
        "currency": "BBD",
        "languages": [
            "en"
        ]
    },
    "BD": {
        "name": "Bangladesh",
        "native": "Bangladesh",
        "phone": "880",
        "continent": "AS",
        "capital": "Dhaka",
        "currency": "BDT",
        "languages": [
            "bn"
        ]
    },
    "BE": {
        "name": "Belgium",
        "native": "België",
        "phone": "32",
        "continent": "EU",
        "capital": "Brussels",
        "currency": "EUR",
        "languages": [
            "nl",
            "fr",
            "de"
        ]
    },
    "BF": {
        "name": "Burkina Faso",
        "native": "Burkina Faso",
        "phone": "226",
        "continent": "AF",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "languages": [
            "fr",
            "ff"
        ]
    },
    "BG": {
        "name": "Bulgaria",
        "native": "България",
        "phone": "359",
        "continent": "EU",
        "capital": "Sofia",
        "currency": "BGN",
        "languages": [
            "bg"
        ]
    },
    "BH": {
        "name": "Bahrain",
        "native": "‏البحرين",
        "phone": "973",
        "continent": "AS",
        "capital": "Manama",
        "currency": "BHD",
        "languages": [
            "ar"
        ]
    },
    "BI": {
        "name": "Burundi",
        "native": "Burundi",
        "phone": "257",
        "continent": "AF",
        "capital": "Bujumbura",
        "currency": "BIF",
        "languages": [
            "fr",
            "rn"
        ]
    },
    "BJ": {
        "name": "Benin",
        "native": "Bénin",
        "phone": "229",
        "continent": "AF",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    "BL": {
        "name": "Saint Barthélemy",
        "native": "Saint-Barthélemy",
        "phone": "590",
        "continent": "NA",
        "capital": "Gustavia",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "BM": {
        "name": "Bermuda",
        "native": "Bermuda",
        "phone": "1441",
        "continent": "NA",
        "capital": "Hamilton",
        "currency": "BMD",
        "languages": [
            "en"
        ]
    },
    "BN": {
        "name": "Brunei",
        "native": "Negara Brunei Darussalam",
        "phone": "673",
        "continent": "AS",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "languages": [
            "ms"
        ]
    },
    "BO": {
        "name": "Bolivia",
        "native": "Bolivia",
        "phone": "591",
        "continent": "SA",
        "capital": "Sucre",
        "currency": "BOB,BOV",
        "languages": [
            "es",
            "ay",
            "qu"
        ]
    },
    "BQ": {
        "name": "Bonaire",
        "native": "Bonaire",
        "phone": "5997",
        "continent": "NA",
        "capital": "Kralendijk",
        "currency": "USD",
        "languages": [
            "nl"
        ]
    },
    "BR": {
        "name": "Brazil",
        "native": "Brasil",
        "phone": "55",
        "continent": "SA",
        "capital": "Brasília",
        "currency": "BRL",
        "languages": [
            "pt"
        ]
    },
    "BS": {
        "name": "Bahamas",
        "native": "Bahamas",
        "phone": "1242",
        "continent": "NA",
        "capital": "Nassau",
        "currency": "BSD",
        "languages": [
            "en"
        ]
    },
    "BT": {
        "name": "Bhutan",
        "native": "ʼbrug-yul",
        "phone": "975",
        "continent": "AS",
        "capital": "Thimphu",
        "currency": "BTN,INR",
        "languages": [
            "dz"
        ]
    },
    "BV": {
        "name": "Bouvet Island",
        "native": "Bouvetøya",
        "phone": "47",
        "continent": "AN",
        "capital": "",
        "currency": "NOK",
        "languages": [
            "no",
            "nb",
            "nn"
        ]
    },
    "BW": {
        "name": "Botswana",
        "native": "Botswana",
        "phone": "267",
        "continent": "AF",
        "capital": "Gaborone",
        "currency": "BWP",
        "languages": [
            "en",
            "tn"
        ]
    },
    "BY": {
        "name": "Belarus",
        "native": "Белару́сь",
        "phone": "375",
        "continent": "EU",
        "capital": "Minsk",
        "currency": "BYN",
        "languages": [
            "be",
            "ru"
        ]
    },
    "BZ": {
        "name": "Belize",
        "native": "Belize",
        "phone": "501",
        "continent": "NA",
        "capital": "Belmopan",
        "currency": "BZD",
        "languages": [
            "en",
            "es"
        ]
    },
    "CA": {
        "name": "Canada",
        "native": "Canada",
        "phone": "1",
        "continent": "NA",
        "capital": "Ottawa",
        "currency": "CAD",
        "languages": [
            "en",
            "fr"
        ]
    },
    "CC": {
        "name": "Cocos [Keeling] Islands",
        "native": "Cocos (Keeling) Islands",
        "phone": "61",
        "continent": "AS",
        "capital": "West Island",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "CD": {
        "name": "Democratic Republic of the Congo",
        "native": "République démocratique du Congo",
        "phone": "243",
        "continent": "AF",
        "capital": "Kinshasa",
        "currency": "CDF",
        "languages": [
            "fr",
            "ln",
            "kg",
            "sw",
            "lu"
        ]
    },
    "CF": {
        "name": "Central African Republic",
        "native": "Ködörösêse tî Bêafrîka",
        "phone": "236",
        "continent": "AF",
        "capital": "Bangui",
        "currency": "XAF",
        "languages": [
            "fr",
            "sg"
        ]
    },
    "CG": {
        "name": "Republic of the Congo",
        "native": "République du Congo",
        "phone": "242",
        "continent": "AF",
        "capital": "Brazzaville",
        "currency": "XAF",
        "languages": [
            "fr",
            "ln"
        ]
    },
    "CH": {
        "name": "Switzerland",
        "native": "Schweiz",
        "phone": "41",
        "continent": "EU",
        "capital": "Bern",
        "currency": "CHE,CHF,CHW",
        "languages": [
            "de",
            "fr",
            "it"
        ]
    },
    "CI": {
        "name": "Ivory Coast",
        "native": "Côte d'Ivoire",
        "phone": "225",
        "continent": "AF",
        "capital": "Yamoussoukro",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    "CK": {
        "name": "Cook Islands",
        "native": "Cook Islands",
        "phone": "682",
        "continent": "OC",
        "capital": "Avarua",
        "currency": "NZD",
        "languages": [
            "en"
        ]
    },
    "CL": {
        "name": "Chile",
        "native": "Chile",
        "phone": "56",
        "continent": "SA",
        "capital": "Santiago",
        "currency": "CLF,CLP",
        "languages": [
            "es"
        ]
    },
    "CM": {
        "name": "Cameroon",
        "native": "Cameroon",
        "phone": "237",
        "continent": "AF",
        "capital": "Yaoundé",
        "currency": "XAF",
        "languages": [
            "en",
            "fr"
        ]
    },
    "CN": {
        "name": "China",
        "native": "中国",
        "phone": "86",
        "continent": "AS",
        "capital": "Beijing",
        "currency": "CNY",
        "languages": [
            "zh"
        ]
    },
    "CO": {
        "name": "Colombia",
        "native": "Colombia",
        "phone": "57",
        "continent": "SA",
        "capital": "Bogotá",
        "currency": "COP",
        "languages": [
            "es"
        ]
    },
    "CR": {
        "name": "Costa Rica",
        "native": "Costa Rica",
        "phone": "506",
        "continent": "NA",
        "capital": "San José",
        "currency": "CRC",
        "languages": [
            "es"
        ]
    },
    "CU": {
        "name": "Cuba",
        "native": "Cuba",
        "phone": "53",
        "continent": "NA",
        "capital": "Havana",
        "currency": "CUC,CUP",
        "languages": [
            "es"
        ]
    },
    "CV": {
        "name": "Cape Verde",
        "native": "Cabo Verde",
        "phone": "238",
        "continent": "AF",
        "capital": "Praia",
        "currency": "CVE",
        "languages": [
            "pt"
        ]
    },
    "CW": {
        "name": "Curacao",
        "native": "Curaçao",
        "phone": "5999",
        "continent": "NA",
        "capital": "Willemstad",
        "currency": "ANG",
        "languages": [
            "nl",
            "pa",
            "en"
        ]
    },
    "CX": {
        "name": "Christmas Island",
        "native": "Christmas Island",
        "phone": "61",
        "continent": "AS",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "CY": {
        "name": "Cyprus",
        "native": "Κύπρος",
        "phone": "357",
        "continent": "EU",
        "capital": "Nicosia",
        "currency": "EUR",
        "languages": [
            "el",
            "tr",
            "hy"
        ]
    },
    "CZ": {
        "name": "Czech Republic",
        "native": "Česká republika",
        "phone": "420",
        "continent": "EU",
        "capital": "Prague",
        "currency": "CZK",
        "languages": [
            "cs",
            "sk"
        ]
    },
    "DE": {
        "name": "Germany",
        "native": "Deutschland",
        "phone": "49",
        "continent": "EU",
        "capital": "Berlin",
        "currency": "EUR",
        "languages": [
            "de"
        ]
    },
    "DJ": {
        "name": "Djibouti",
        "native": "Djibouti",
        "phone": "253",
        "continent": "AF",
        "capital": "Djibouti",
        "currency": "DJF",
        "languages": [
            "fr",
            "ar"
        ]
    },
    "DK": {
        "name": "Denmark",
        "native": "Danmark",
        "phone": "45",
        "continent": "EU",
        "capital": "Copenhagen",
        "currency": "DKK",
        "languages": [
            "da"
        ]
    },
    "DM": {
        "name": "Dominica",
        "native": "Dominica",
        "phone": "1767",
        "continent": "NA",
        "capital": "Roseau",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "DO": {
        "name": "Dominican Republic",
        "native": "República Dominicana",
        "phone": "1809,1829,1849",
        "continent": "NA",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "languages": [
            "es"
        ]
    },
    "DZ": {
        "name": "Algeria",
        "native": "الجزائر",
        "phone": "213",
        "continent": "AF",
        "capital": "Algiers",
        "currency": "DZD",
        "languages": [
            "ar"
        ]
    },
    "EC": {
        "name": "Ecuador",
        "native": "Ecuador",
        "phone": "593",
        "continent": "SA",
        "capital": "Quito",
        "currency": "USD",
        "languages": [
            "es"
        ]
    },
    "EE": {
        "name": "Estonia",
        "native": "Eesti",
        "phone": "372",
        "continent": "EU",
        "capital": "Tallinn",
        "currency": "EUR",
        "languages": [
            "et"
        ]
    },
    "EG": {
        "name": "Egypt",
        "native": "مصر‎",
        "phone": "20",
        "continent": "AF",
        "capital": "Cairo",
        "currency": "EGP",
        "languages": [
            "ar"
        ]
    },
    "EH": {
        "name": "Western Sahara",
        "native": "الصحراء الغربية",
        "phone": "212",
        "continent": "AF",
        "capital": "El Aaiún",
        "currency": "MAD,DZD,MRU",
        "languages": [
            "es"
        ]
    },
    "ER": {
        "name": "Eritrea",
        "native": "ኤርትራ",
        "phone": "291",
        "continent": "AF",
        "capital": "Asmara",
        "currency": "ERN",
        "languages": [
            "ti",
            "ar",
            "en"
        ]
    },
    "ES": {
        "name": "Spain",
        "native": "España",
        "phone": "34",
        "continent": "EU",
        "capital": "Madrid",
        "currency": "EUR",
        "languages": [
            "es",
            "eu",
            "ca",
            "gl",
            "oc"
        ]
    },
    "ET": {
        "name": "Ethiopia",
        "native": "ኢትዮጵያ",
        "phone": "251",
        "continent": "AF",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "languages": [
            "am"
        ]
    },
    "FI": {
        "name": "Finland",
        "native": "Suomi",
        "phone": "358",
        "continent": "EU",
        "capital": "Helsinki",
        "currency": "EUR",
        "languages": [
            "fi",
            "sv"
        ]
    },
    "FJ": {
        "name": "Fiji",
        "native": "Fiji",
        "phone": "679",
        "continent": "OC",
        "capital": "Suva",
        "currency": "FJD",
        "languages": [
            "en",
            "fj",
            "hi",
            "ur"
        ]
    },
    "FK": {
        "name": "Falkland Islands",
        "native": "Falkland Islands",
        "phone": "500",
        "continent": "SA",
        "capital": "Stanley",
        "currency": "FKP",
        "languages": [
            "en"
        ]
    },
    "FM": {
        "name": "Micronesia",
        "native": "Micronesia",
        "phone": "691",
        "continent": "OC",
        "capital": "Palikir",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "FO": {
        "name": "Faroe Islands",
        "native": "Føroyar",
        "phone": "298",
        "continent": "EU",
        "capital": "Tórshavn",
        "currency": "DKK",
        "languages": [
            "fo"
        ]
    },
    "FR": {
        "name": "France",
        "native": "France",
        "phone": "33",
        "continent": "EU",
        "capital": "Paris",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "GA": {
        "name": "Gabon",
        "native": "Gabon",
        "phone": "241",
        "continent": "AF",
        "capital": "Libreville",
        "currency": "XAF",
        "languages": [
            "fr"
        ]
    },
    "GB": {
        "name": "United Kingdom",
        "native": "United Kingdom",
        "phone": "44",
        "continent": "EU",
        "capital": "London",
        "currency": "GBP",
        "languages": [
            "en"
        ]
    },
    "GD": {
        "name": "Grenada",
        "native": "Grenada",
        "phone": "1473",
        "continent": "NA",
        "capital": "St. George's",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "GE": {
        "name": "Georgia",
        "native": "საქართველო",
        "phone": "995",
        "continent": "AS",
        "capital": "Tbilisi",
        "currency": "GEL",
        "languages": [
            "ka"
        ]
    },
    "GF": {
        "name": "French Guiana",
        "native": "Guyane française",
        "phone": "594",
        "continent": "SA",
        "capital": "Cayenne",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "GG": {
        "name": "Guernsey",
        "native": "Guernsey",
        "phone": "44",
        "continent": "EU",
        "capital": "St. Peter Port",
        "currency": "GBP",
        "languages": [
            "en",
            "fr"
        ]
    },
    "GH": {
        "name": "Ghana",
        "native": "Ghana",
        "phone": "233",
        "continent": "AF",
        "capital": "Accra",
        "currency": "GHS",
        "languages": [
            "en"
        ]
    },
    "GI": {
        "name": "Gibraltar",
        "native": "Gibraltar",
        "phone": "350",
        "continent": "EU",
        "capital": "Gibraltar",
        "currency": "GIP",
        "languages": [
            "en"
        ]
    },
    "GL": {
        "name": "Greenland",
        "native": "Kalaallit Nunaat",
        "phone": "299",
        "continent": "NA",
        "capital": "Nuuk",
        "currency": "DKK",
        "languages": [
            "kl"
        ]
    },
    "GM": {
        "name": "Gambia",
        "native": "Gambia",
        "phone": "220",
        "continent": "AF",
        "capital": "Banjul",
        "currency": "GMD",
        "languages": [
            "en"
        ]
    },
    "GN": {
        "name": "Guinea",
        "native": "Guinée",
        "phone": "224",
        "continent": "AF",
        "capital": "Conakry",
        "currency": "GNF",
        "languages": [
            "fr",
            "ff"
        ]
    },
    "GP": {
        "name": "Guadeloupe",
        "native": "Guadeloupe",
        "phone": "590",
        "continent": "NA",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "GQ": {
        "name": "Equatorial Guinea",
        "native": "Guinea Ecuatorial",
        "phone": "240",
        "continent": "AF",
        "capital": "Malabo",
        "currency": "XAF",
        "languages": [
            "es",
            "fr"
        ]
    },
    "GR": {
        "name": "Greece",
        "native": "Ελλάδα",
        "phone": "30",
        "continent": "EU",
        "capital": "Athens",
        "currency": "EUR",
        "languages": [
            "el"
        ]
    },
    "GS": {
        "name": "South Georgia and the South Sandwich Islands",
        "native": "South Georgia",
        "phone": "500",
        "continent": "AN",
        "capital": "King Edward Point",
        "currency": "GBP",
        "languages": [
            "en"
        ]
    },
    "GT": {
        "name": "Guatemala",
        "native": "Guatemala",
        "phone": "502",
        "continent": "NA",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "languages": [
            "es"
        ]
    },
    "GU": {
        "name": "Guam",
        "native": "Guam",
        "phone": "1671",
        "continent": "OC",
        "capital": "Hagåtña",
        "currency": "USD",
        "languages": [
            "en",
            "ch",
            "es"
        ]
    },
    "GW": {
        "name": "Guinea-Bissau",
        "native": "Guiné-Bissau",
        "phone": "245",
        "continent": "AF",
        "capital": "Bissau",
        "currency": "XOF",
        "languages": [
            "pt"
        ]
    },
    "GY": {
        "name": "Guyana",
        "native": "Guyana",
        "phone": "592",
        "continent": "SA",
        "capital": "Georgetown",
        "currency": "GYD",
        "languages": [
            "en"
        ]
    },
    "HK": {
        "name": "Hong Kong",
        "native": "香港",
        "phone": "852",
        "continent": "AS",
        "capital": "City of Victoria",
        "currency": "HKD",
        "languages": [
            "zh",
            "en"
        ]
    },
    "HM": {
        "name": "Heard Island and McDonald Islands",
        "native": "Heard Island and McDonald Islands",
        "phone": "61",
        "continent": "AN",
        "capital": "",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "HN": {
        "name": "Honduras",
        "native": "Honduras",
        "phone": "504",
        "continent": "NA",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "languages": [
            "es"
        ]
    },
    "HR": {
        "name": "Croatia",
        "native": "Hrvatska",
        "phone": "385",
        "continent": "EU",
        "capital": "Zagreb",
        "currency": "HRK",
        "languages": [
            "hr"
        ]
    },
    "HT": {
        "name": "Haiti",
        "native": "Haïti",
        "phone": "509",
        "continent": "NA",
        "capital": "Port-au-Prince",
        "currency": "HTG,USD",
        "languages": [
            "fr",
            "ht"
        ]
    },
    "HU": {
        "name": "Hungary",
        "native": "Magyarország",
        "phone": "36",
        "continent": "EU",
        "capital": "Budapest",
        "currency": "HUF",
        "languages": [
            "hu"
        ]
    },
    // "ID": {
    //     "name": "Indonesia",
    //     "native": "Indonesia",
    //     "phone": "62",
    //     "continent": "AS",
    //     "capital": "Jakarta",
    //     "currency": "IDR",
    //     "languages": [
    //         "id"
    //     ]
    // },
    "IE": {
        "name": "Ireland",
        "native": "Éire",
        "phone": "353",
        "continent": "EU",
        "capital": "Dublin",
        "currency": "EUR",
        "languages": [
            "ga",
            "en"
        ]
    },
    "IL": {
        "name": "Israel",
        "native": "יִשְׂרָאֵל",
        "phone": "972",
        "continent": "AS",
        "capital": "Jerusalem",
        "currency": "ILS",
        "languages": [
            "he",
            "ar"
        ]
    },
    "IM": {
        "name": "Isle of Man",
        "native": "Isle of Man",
        "phone": "44",
        "continent": "EU",
        "capital": "Douglas",
        "currency": "GBP",
        "languages": [
            "en",
            "gv"
        ]
    },
    "IN": {
        "name": "India",
        "native": "भारत",
        "phone": "91",
        "continent": "AS",
        "capital": "New Delhi",
        "currency": "INR",
        "languages": [
            "hi",
            "en"
        ]
    },
    "IO": {
        "name": "British Indian Ocean Territory",
        "native": "British Indian Ocean Territory",
        "phone": "246",
        "continent": "AS",
        "capital": "Diego Garcia",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "IQ": {
        "name": "Iraq",
        "native": "العراق",
        "phone": "964",
        "continent": "AS",
        "capital": "Baghdad",
        "currency": "IQD",
        "languages": [
            "ar",
            "ku"
        ]
    },
    "IR": {
        "name": "Iran",
        "native": "ایران",
        "phone": "98",
        "continent": "AS",
        "capital": "Tehran",
        "currency": "IRR",
        "languages": [
            "fa"
        ]
    },
    "IS": {
        "name": "Iceland",
        "native": "Ísland",
        "phone": "354",
        "continent": "EU",
        "capital": "Reykjavik",
        "currency": "ISK",
        "languages": [
            "is"
        ]
    },
    "IT": {
        "name": "Italy",
        "native": "Italia",
        "phone": "39",
        "continent": "EU",
        "capital": "Rome",
        "currency": "EUR",
        "languages": [
            "it"
        ]
    },
    "JE": {
        "name": "Jersey",
        "native": "Jersey",
        "phone": "44",
        "continent": "EU",
        "capital": "Saint Helier",
        "currency": "GBP",
        "languages": [
            "en",
            "fr"
        ]
    },
    "JM": {
        "name": "Jamaica",
        "native": "Jamaica",
        "phone": "1876",
        "continent": "NA",
        "capital": "Kingston",
        "currency": "JMD",
        "languages": [
            "en"
        ]
    },
    "JO": {
        "name": "Jordan",
        "native": "الأردن",
        "phone": "962",
        "continent": "AS",
        "capital": "Amman",
        "currency": "JOD",
        "languages": [
            "ar"
        ]
    },
    "JP": {
        "name": "Japan",
        "native": "日本",
        "phone": "81",
        "continent": "AS",
        "capital": "Tokyo",
        "currency": "JPY",
        "languages": [
            "ja"
        ]
    },
    "KE": {
        "name": "Kenya",
        "native": "Kenya",
        "phone": "254",
        "continent": "AF",
        "capital": "Nairobi",
        "currency": "KES",
        "languages": [
            "en",
            "sw"
        ]
    },
    "KG": {
        "name": "Kyrgyzstan",
        "native": "Кыргызстан",
        "phone": "996",
        "continent": "AS",
        "capital": "Bishkek",
        "currency": "KGS",
        "languages": [
            "ky",
            "ru"
        ]
    },
    "KH": {
        "name": "Cambodia",
        "native": "Kâmpŭchéa",
        "phone": "855",
        "continent": "AS",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "languages": [
            "km"
        ]
    },
    "KI": {
        "name": "Kiribati",
        "native": "Kiribati",
        "phone": "686",
        "continent": "OC",
        "capital": "South Tarawa",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "KM": {
        "name": "Comoros",
        "native": "Komori",
        "phone": "269",
        "continent": "AF",
        "capital": "Moroni",
        "currency": "KMF",
        "languages": [
            "ar",
            "fr"
        ]
    },
    "KN": {
        "name": "Saint Kitts and Nevis",
        "native": "Saint Kitts and Nevis",
        "phone": "1869",
        "continent": "NA",
        "capital": "Basseterre",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "KP": {
        "name": "North Korea",
        "native": "북한",
        "phone": "850",
        "continent": "AS",
        "capital": "Pyongyang",
        "currency": "KPW",
        "languages": [
            "ko"
        ]
    },
    "KR": {
        "name": "South Korea",
        "native": "대한민국",
        "phone": "82",
        "continent": "AS",
        "capital": "Seoul",
        "currency": "KRW",
        "languages": [
            "ko"
        ]
    },
    "KW": {
        "name": "Kuwait",
        "native": "الكويت",
        "phone": "965",
        "continent": "AS",
        "capital": "Kuwait City",
        "currency": "KWD",
        "languages": [
            "ar"
        ]
    },
    "KY": {
        "name": "Cayman Islands",
        "native": "Cayman Islands",
        "phone": "1345",
        "continent": "NA",
        "capital": "George Town",
        "currency": "KYD",
        "languages": [
            "en"
        ]
    },
    "KZ": {
        "name": "Kazakhstan",
        "native": "Қазақстан",
        "phone": "76,77",
        "continent": "AS",
        "capital": "Astana",
        "currency": "KZT",
        "languages": [
            "kk",
            "ru"
        ]
    },
    "LA": {
        "name": "Laos",
        "native": "ສປປລາວ",
        "phone": "856",
        "continent": "AS",
        "capital": "Vientiane",
        "currency": "LAK",
        "languages": [
            "lo"
        ]
    },
    "LB": {
        "name": "Lebanon",
        "native": "لبنان",
        "phone": "961",
        "continent": "AS",
        "capital": "Beirut",
        "currency": "LBP",
        "languages": [
            "ar",
            "fr"
        ]
    },
    "LC": {
        "name": "Saint Lucia",
        "native": "Saint Lucia",
        "phone": "1758",
        "continent": "NA",
        "capital": "Castries",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "LI": {
        "name": "Liechtenstein",
        "native": "Liechtenstein",
        "phone": "423",
        "continent": "EU",
        "capital": "Vaduz",
        "currency": "CHF",
        "languages": [
            "de"
        ]
    },
    "LK": {
        "name": "Sri Lanka",
        "native": "śrī laṃkāva",
        "phone": "94",
        "continent": "AS",
        "capital": "Colombo",
        "currency": "LKR",
        "languages": [
            "si",
            "ta"
        ]
    },
    "LR": {
        "name": "Liberia",
        "native": "Liberia",
        "phone": "231",
        "continent": "AF",
        "capital": "Monrovia",
        "currency": "LRD",
        "languages": [
            "en"
        ]
    },
    "LS": {
        "name": "Lesotho",
        "native": "Lesotho",
        "phone": "266",
        "continent": "AF",
        "capital": "Maseru",
        "currency": "LSL,ZAR",
        "languages": [
            "en",
            "st"
        ]
    },
    "LT": {
        "name": "Lithuania",
        "native": "Lietuva",
        "phone": "370",
        "continent": "EU",
        "capital": "Vilnius",
        "currency": "EUR",
        "languages": [
            "lt"
        ]
    },
    "LU": {
        "name": "Luxembourg",
        "native": "Luxembourg",
        "phone": "352",
        "continent": "EU",
        "capital": "Luxembourg",
        "currency": "EUR",
        "languages": [
            "fr",
            "de",
            "lb"
        ]
    },
    "LV": {
        "name": "Latvia",
        "native": "Latvija",
        "phone": "371",
        "continent": "EU",
        "capital": "Riga",
        "currency": "EUR",
        "languages": [
            "lv"
        ]
    },
    "LY": {
        "name": "Libya",
        "native": "‏ليبيا",
        "phone": "218",
        "continent": "AF",
        "capital": "Tripoli",
        "currency": "LYD",
        "languages": [
            "ar"
        ]
    },
    "MA": {
        "name": "Morocco",
        "native": "المغرب",
        "phone": "212",
        "continent": "AF",
        "capital": "Rabat",
        "currency": "MAD",
        "languages": [
            "ar"
        ]
    },
    "MC": {
        "name": "Monaco",
        "native": "Monaco",
        "phone": "377",
        "continent": "EU",
        "capital": "Monaco",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "MD": {
        "name": "Moldova",
        "native": "Moldova",
        "phone": "373",
        "continent": "EU",
        "capital": "Chișinău",
        "currency": "MDL",
        "languages": [
            "ro"
        ]
    },
    "ME": {
        "name": "Montenegro",
        "native": "Црна Гора",
        "phone": "382",
        "continent": "EU",
        "capital": "Podgorica",
        "currency": "EUR",
        "languages": [
            "sr",
            "bs",
            "sq",
            "hr"
        ]
    },
    "MF": {
        "name": "Saint Martin",
        "native": "Saint-Martin",
        "phone": "590",
        "continent": "NA",
        "capital": "Marigot",
        "currency": "EUR",
        "languages": [
            "en",
            "fr",
            "nl"
        ]
    },
    "MG": {
        "name": "Madagascar",
        "native": "Madagasikara",
        "phone": "261",
        "continent": "AF",
        "capital": "Antananarivo",
        "currency": "MGA",
        "languages": [
            "fr",
            "mg"
        ]
    },
    "MH": {
        "name": "Marshall Islands",
        "native": "M̧ajeļ",
        "phone": "692",
        "continent": "OC",
        "capital": "Majuro",
        "currency": "USD",
        "languages": [
            "en",
            "mh"
        ]
    },
    "MK": {
        "name": "North Macedonia",
        "native": "Северна Македонија",
        "phone": "389",
        "continent": "EU",
        "capital": "Skopje",
        "currency": "MKD",
        "languages": [
            "mk"
        ]
    },
    "ML": {
        "name": "Mali",
        "native": "Mali",
        "phone": "223",
        "continent": "AF",
        "capital": "Bamako",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    "MM": {
        "name": "Myanmar [Burma]",
        "native": "မြန်မာ",
        "phone": "95",
        "continent": "AS",
        "capital": "Naypyidaw",
        "currency": "MMK",
        "languages": [
            "my"
        ]
    },
    "MN": {
        "name": "Mongolia",
        "native": "Монгол улс",
        "phone": "976",
        "continent": "AS",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "languages": [
            "mn"
        ]
    },
    "MO": {
        "name": "Macao",
        "native": "澳門",
        "phone": "853",
        "continent": "AS",
        "capital": "",
        "currency": "MOP",
        "languages": [
            "zh",
            "pt"
        ]
    },
    "MP": {
        "name": "Northern Mariana Islands",
        "native": "Northern Mariana Islands",
        "phone": "1670",
        "continent": "OC",
        "capital": "Saipan",
        "currency": "USD",
        "languages": [
            "en",
            "ch"
        ]
    },
    "MQ": {
        "name": "Martinique",
        "native": "Martinique",
        "phone": "596",
        "continent": "NA",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "MR": {
        "name": "Mauritania",
        "native": "موريتانيا",
        "phone": "222",
        "continent": "AF",
        "capital": "Nouakchott",
        "currency": "MRU",
        "languages": [
            "ar"
        ]
    },
    "MS": {
        "name": "Montserrat",
        "native": "Montserrat",
        "phone": "1664",
        "continent": "NA",
        "capital": "Plymouth",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "MT": {
        "name": "Malta",
        "native": "Malta",
        "phone": "356",
        "continent": "EU",
        "capital": "Valletta",
        "currency": "EUR",
        "languages": [
            "mt",
            "en"
        ]
    },
    "MU": {
        "name": "Mauritius",
        "native": "Maurice",
        "phone": "230",
        "continent": "AF",
        "capital": "Port Louis",
        "currency": "MUR",
        "languages": [
            "en"
        ]
    },
    "MV": {
        "name": "Maldives",
        "native": "Maldives",
        "phone": "960",
        "continent": "AS",
        "capital": "Malé",
        "currency": "MVR",
        "languages": [
            "dv"
        ]
    },
    "MW": {
        "name": "Malawi",
        "native": "Malawi",
        "phone": "265",
        "continent": "AF",
        "capital": "Lilongwe",
        "currency": "MWK",
        "languages": [
            "en",
            "ny"
        ]
    },
    "MX": {
        "name": "Mexico",
        "native": "México",
        "phone": "52",
        "continent": "NA",
        "capital": "Mexico City",
        "currency": "MXN",
        "languages": [
            "es"
        ]
    },
    "MY": {
        "name": "Malaysia",
        "native": "Malaysia",
        "phone": "60",
        "continent": "AS",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "languages": [
            "ms"
        ]
    },
    "MZ": {
        "name": "Mozambique",
        "native": "Moçambique",
        "phone": "258",
        "continent": "AF",
        "capital": "Maputo",
        "currency": "MZN",
        "languages": [
            "pt"
        ]
    },
    "NA": {
        "name": "Namibia",
        "native": "Namibia",
        "phone": "264",
        "continent": "AF",
        "capital": "Windhoek",
        "currency": "NAD,ZAR",
        "languages": [
            "en",
            "af"
        ]
    },
    "NC": {
        "name": "New Caledonia",
        "native": "Nouvelle-Calédonie",
        "phone": "687",
        "continent": "OC",
        "capital": "Nouméa",
        "currency": "XPF",
        "languages": [
            "fr"
        ]
    },
    "NE": {
        "name": "Niger",
        "native": "Niger",
        "phone": "227",
        "continent": "AF",
        "capital": "Niamey",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    "NF": {
        "name": "Norfolk Island",
        "native": "Norfolk Island",
        "phone": "672",
        "continent": "OC",
        "capital": "Kingston",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "NG": {
        "name": "Nigeria",
        "native": "Nigeria",
        "phone": "234",
        "continent": "AF",
        "capital": "Abuja",
        "currency": "NGN",
        "languages": [
            "en"
        ]
    },
    "NI": {
        "name": "Nicaragua",
        "native": "Nicaragua",
        "phone": "505",
        "continent": "NA",
        "capital": "Managua",
        "currency": "NIO",
        "languages": [
            "es"
        ]
    },
    "NL": {
        "name": "Netherlands",
        "native": "Nederland",
        "phone": "31",
        "continent": "EU",
        "capital": "Amsterdam",
        "currency": "EUR",
        "languages": [
            "nl"
        ]
    },
    "NO": {
        "name": "Norway",
        "native": "Norge",
        "phone": "47",
        "continent": "EU",
        "capital": "Oslo",
        "currency": "NOK",
        "languages": [
            "no",
            "nb",
            "nn"
        ]
    },
    "NP": {
        "name": "Nepal",
        "native": "नपल",
        "phone": "977",
        "continent": "AS",
        "capital": "Kathmandu",
        "currency": "NPR",
        "languages": [
            "ne"
        ]
    },
    "NR": {
        "name": "Nauru",
        "native": "Nauru",
        "phone": "674",
        "continent": "OC",
        "capital": "Yaren",
        "currency": "AUD",
        "languages": [
            "en",
            "na"
        ]
    },
    "NU": {
        "name": "Niue",
        "native": "Niuē",
        "phone": "683",
        "continent": "OC",
        "capital": "Alofi",
        "currency": "NZD",
        "languages": [
            "en"
        ]
    },
    "NZ": {
        "name": "New Zealand",
        "native": "New Zealand",
        "phone": "64",
        "continent": "OC",
        "capital": "Wellington",
        "currency": "NZD",
        "languages": [
            "en",
            "mi"
        ]
    },
    "OM": {
        "name": "Oman",
        "native": "عمان",
        "phone": "968",
        "continent": "AS",
        "capital": "Muscat",
        "currency": "OMR",
        "languages": [
            "ar"
        ]
    },
    "PA": {
        "name": "Panama",
        "native": "Panamá",
        "phone": "507",
        "continent": "NA",
        "capital": "Panama City",
        "currency": "PAB,USD",
        "languages": [
            "es"
        ]
    },
    "PE": {
        "name": "Peru",
        "native": "Perú",
        "phone": "51",
        "continent": "SA",
        "capital": "Lima",
        "currency": "PEN",
        "languages": [
            "es"
        ]
    },
    "PF": {
        "name": "French Polynesia",
        "native": "Polynésie française",
        "phone": "689",
        "continent": "OC",
        "capital": "Papeetē",
        "currency": "XPF",
        "languages": [
            "fr"
        ]
    },
    "PG": {
        "name": "Papua New Guinea",
        "native": "Papua Niugini",
        "phone": "675",
        "continent": "OC",
        "capital": "Port Moresby",
        "currency": "PGK",
        "languages": [
            "en"
        ]
    },
    "PH": {
        "name": "Philippines",
        "native": "Pilipinas",
        "phone": "63",
        "continent": "AS",
        "capital": "Manila",
        "currency": "PHP",
        "languages": [
            "en"
        ]
    },
    "PK": {
        "name": "Pakistan",
        "native": "Pakistan",
        "phone": "92",
        "continent": "AS",
        "capital": "Islamabad",
        "currency": "PKR",
        "languages": [
            "en",
            "ur"
        ]
    },
    "PL": {
        "name": "Poland",
        "native": "Polska",
        "phone": "48",
        "continent": "EU",
        "capital": "Warsaw",
        "currency": "PLN",
        "languages": [
            "pl"
        ]
    },
    "PM": {
        "name": "Saint Pierre and Miquelon",
        "native": "Saint-Pierre-et-Miquelon",
        "phone": "508",
        "continent": "NA",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "PN": {
        "name": "Pitcairn Islands",
        "native": "Pitcairn Islands",
        "phone": "64",
        "continent": "OC",
        "capital": "Adamstown",
        "currency": "NZD",
        "languages": [
            "en"
        ]
    },
    "PR": {
        "name": "Puerto Rico",
        "native": "Puerto Rico",
        "phone": "1787,1939",
        "continent": "NA",
        "capital": "San Juan",
        "currency": "USD",
        "languages": [
            "es",
            "en"
        ]
    },
    "PS": {
        "name": "Palestine",
        "native": "فلسطين",
        "phone": "970",
        "continent": "AS",
        "capital": "Ramallah",
        "currency": "ILS",
        "languages": [
            "ar"
        ]
    },
    "PT": {
        "name": "Portugal",
        "native": "Portugal",
        "phone": "351",
        "continent": "EU",
        "capital": "Lisbon",
        "currency": "EUR",
        "languages": [
            "pt"
        ]
    },
    "PW": {
        "name": "Palau",
        "native": "Palau",
        "phone": "680",
        "continent": "OC",
        "capital": "Ngerulmud",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "PY": {
        "name": "Paraguay",
        "native": "Paraguay",
        "phone": "595",
        "continent": "SA",
        "capital": "Asunción",
        "currency": "PYG",
        "languages": [
            "es",
            "gn"
        ]
    },
    "QA": {
        "name": "Qatar",
        "native": "قطر",
        "phone": "974",
        "continent": "AS",
        "capital": "Doha",
        "currency": "QAR",
        "languages": [
            "ar"
        ]
    },
    "RE": {
        "name": "Réunion",
        "native": "La Réunion",
        "phone": "262",
        "continent": "AF",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "RO": {
        "name": "Romania",
        "native": "România",
        "phone": "40",
        "continent": "EU",
        "capital": "Bucharest",
        "currency": "RON",
        "languages": [
            "ro"
        ]
    },
    "RS": {
        "name": "Serbia",
        "native": "Србија",
        "phone": "381",
        "continent": "EU",
        "capital": "Belgrade",
        "currency": "RSD",
        "languages": [
            "sr"
        ]
    },
    "RU": {
        "name": "Russia",
        "native": "Россия",
        "phone": "7",
        "continent": "EU",
        "capital": "Moscow",
        "currency": "RUB",
        "languages": [
            "ru"
        ]
    },
    "RW": {
        "name": "Rwanda",
        "native": "Rwanda",
        "phone": "250",
        "continent": "AF",
        "capital": "Kigali",
        "currency": "RWF",
        "languages": [
            "rw",
            "en",
            "fr"
        ]
    },
    "SA": {
        "name": "Saudi Arabia",
        "native": "العربية السعودية",
        "phone": "966",
        "continent": "AS",
        "capital": "Riyadh",
        "currency": "SAR",
        "languages": [
            "ar"
        ]
    },
    "SB": {
        "name": "Solomon Islands",
        "native": "Solomon Islands",
        "phone": "677",
        "continent": "OC",
        "capital": "Honiara",
        "currency": "SBD",
        "languages": [
            "en"
        ]
    },
    "SC": {
        "name": "Seychelles",
        "native": "Seychelles",
        "phone": "248",
        "continent": "AF",
        "capital": "Victoria",
        "currency": "SCR",
        "languages": [
            "fr",
            "en"
        ]
    },
    "SD": {
        "name": "Sudan",
        "native": "السودان",
        "phone": "249",
        "continent": "AF",
        "capital": "Khartoum",
        "currency": "SDG",
        "languages": [
            "ar",
            "en"
        ]
    },
    "SE": {
        "name": "Sweden",
        "native": "Sverige",
        "phone": "46",
        "continent": "EU",
        "capital": "Stockholm",
        "currency": "SEK",
        "languages": [
            "sv"
        ]
    },
    "SG": {
        "name": "Singapore",
        "native": "Singapore",
        "phone": "65",
        "continent": "AS",
        "capital": "Singapore",
        "currency": "SGD",
        "languages": [
            "en",
            "ms",
            "ta",
            "zh"
        ]
    },
    "SH": {
        "name": "Saint Helena",
        "native": "Saint Helena",
        "phone": "290",
        "continent": "AF",
        "capital": "Jamestown",
        "currency": "SHP",
        "languages": [
            "en"
        ]
    },
    "SI": {
        "name": "Slovenia",
        "native": "Slovenija",
        "phone": "386",
        "continent": "EU",
        "capital": "Ljubljana",
        "currency": "EUR",
        "languages": [
            "sl"
        ]
    },
    "SJ": {
        "name": "Svalbard and Jan Mayen",
        "native": "Svalbard og Jan Mayen",
        "phone": "4779",
        "continent": "EU",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "languages": [
            "no"
        ]
    },
    "SK": {
        "name": "Slovakia",
        "native": "Slovensko",
        "phone": "421",
        "continent": "EU",
        "capital": "Bratislava",
        "currency": "EUR",
        "languages": [
            "sk"
        ]
    },
    "SL": {
        "name": "Sierra Leone",
        "native": "Sierra Leone",
        "phone": "232",
        "continent": "AF",
        "capital": "Freetown",
        "currency": "SLL",
        "languages": [
            "en"
        ]
    },
    "SM": {
        "name": "San Marino",
        "native": "San Marino",
        "phone": "378",
        "continent": "EU",
        "capital": "City of San Marino",
        "currency": "EUR",
        "languages": [
            "it"
        ]
    },
    "SN": {
        "name": "Senegal",
        "native": "Sénégal",
        "phone": "221",
        "continent": "AF",
        "capital": "Dakar",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    "SO": {
        "name": "Somalia",
        "native": "Soomaaliya",
        "phone": "252",
        "continent": "AF",
        "capital": "Mogadishu",
        "currency": "SOS",
        "languages": [
            "so",
            "ar"
        ]
    },
    "SR": {
        "name": "Suriname",
        "native": "Suriname",
        "phone": "597",
        "continent": "SA",
        "capital": "Paramaribo",
        "currency": "SRD",
        "languages": [
            "nl"
        ]
    },
    "SS": {
        "name": "South Sudan",
        "native": "South Sudan",
        "phone": "211",
        "continent": "AF",
        "capital": "Juba",
        "currency": "SSP",
        "languages": [
            "en"
        ]
    },
    "ST": {
        "name": "São Tomé and Príncipe",
        "native": "São Tomé e Príncipe",
        "phone": "239",
        "continent": "AF",
        "capital": "São Tomé",
        "currency": "STN",
        "languages": [
            "pt"
        ]
    },
    "SV": {
        "name": "El Salvador",
        "native": "El Salvador",
        "phone": "503",
        "continent": "NA",
        "capital": "San Salvador",
        "currency": "SVC,USD",
        "languages": [
            "es"
        ]
    },
    "SX": {
        "name": "Sint Maarten",
        "native": "Sint Maarten",
        "phone": "1721",
        "continent": "NA",
        "capital": "Philipsburg",
        "currency": "ANG",
        "languages": [
            "nl",
            "en"
        ]
    },
    "SY": {
        "name": "Syria",
        "native": "سوريا",
        "phone": "963",
        "continent": "AS",
        "capital": "Damascus",
        "currency": "SYP",
        "languages": [
            "ar"
        ]
    },
    "SZ": {
        "name": "Swaziland",
        "native": "Swaziland",
        "phone": "268",
        "continent": "AF",
        "capital": "Lobamba",
        "currency": "SZL",
        "languages": [
            "en",
            "ss"
        ]
    },
    "TC": {
        "name": "Turks and Caicos Islands",
        "native": "Turks and Caicos Islands",
        "phone": "1649",
        "continent": "NA",
        "capital": "Cockburn Town",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "TD": {
        "name": "Chad",
        "native": "Tchad",
        "phone": "235",
        "continent": "AF",
        "capital": "N'Djamena",
        "currency": "XAF",
        "languages": [
            "fr",
            "ar"
        ]
    },
    "TF": {
        "name": "French Southern Territories",
        "native": "Territoire des Terres australes et antarctiques fr",
        "phone": "262",
        "continent": "AN",
        "capital": "Port-aux-Français",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "TG": {
        "name": "Togo",
        "native": "Togo",
        "phone": "228",
        "continent": "AF",
        "capital": "Lomé",
        "currency": "XOF",
        "languages": [
            "fr"
        ]
    },
    // "TH": {
    //     "name": "Thailand",
    //     "native": "ประเทศไทย",
    //     "phone": "66",
    //     "continent": "AS",
    //     "capital": "Bangkok",
    //     "currency": "THB",
    //     "languages": [
    //         "th"
    //     ]
    // },
    "TJ": {
        "name": "Tajikistan",
        "native": "Тоҷикистон",
        "phone": "992",
        "continent": "AS",
        "capital": "Dushanbe",
        "currency": "TJS",
        "languages": [
            "tg",
            "ru"
        ]
    },
    "TK": {
        "name": "Tokelau",
        "native": "Tokelau",
        "phone": "690",
        "continent": "OC",
        "capital": "Fakaofo",
        "currency": "NZD",
        "languages": [
            "en"
        ]
    },
    "TL": {
        "name": "East Timor",
        "native": "Timor-Leste",
        "phone": "670",
        "continent": "OC",
        "capital": "Dili",
        "currency": "USD",
        "languages": [
            "pt"
        ]
    },
    "TM": {
        "name": "Turkmenistan",
        "native": "Türkmenistan",
        "phone": "993",
        "continent": "AS",
        "capital": "Ashgabat",
        "currency": "TMT",
        "languages": [
            "tk",
            "ru"
        ]
    },
    "TN": {
        "name": "Tunisia",
        "native": "تونس",
        "phone": "216",
        "continent": "AF",
        "capital": "Tunis",
        "currency": "TND",
        "languages": [
            "ar"
        ]
    },
    "TO": {
        "name": "Tonga",
        "native": "Tonga",
        "phone": "676",
        "continent": "OC",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "languages": [
            "en",
            "to"
        ]
    },
    "TR": {
        "name": "Turkey",
        "native": "Türkiye",
        "phone": "90",
        "continent": "AS",
        "capital": "Ankara",
        "currency": "TRY",
        "languages": [
            "tr"
        ]
    },
    "TT": {
        "name": "Trinidad and Tobago",
        "native": "Trinidad and Tobago",
        "phone": "1868",
        "continent": "NA",
        "capital": "Port of Spain",
        "currency": "TTD",
        "languages": [
            "en"
        ]
    },
    "TV": {
        "name": "Tuvalu",
        "native": "Tuvalu",
        "phone": "688",
        "continent": "OC",
        "capital": "Funafuti",
        "currency": "AUD",
        "languages": [
            "en"
        ]
    },
    "TW": {
        "name": "Taiwan",
        "native": "臺灣",
        "phone": "886",
        "continent": "AS",
        "capital": "Taipei",
        "currency": "TWD",
        "languages": [
            "zh"
        ]
    },
    "TZ": {
        "name": "Tanzania",
        "native": "Tanzania",
        "phone": "255",
        "continent": "AF",
        "capital": "Dodoma",
        "currency": "TZS",
        "languages": [
            "sw",
            "en"
        ]
    },
    "UA": {
        "name": "Ukraine",
        "native": "Україна",
        "phone": "380",
        "continent": "EU",
        "capital": "Kyiv",
        "currency": "UAH",
        "languages": [
            "uk"
        ]
    },
    "UG": {
        "name": "Uganda",
        "native": "Uganda",
        "phone": "256",
        "continent": "AF",
        "capital": "Kampala",
        "currency": "UGX",
        "languages": [
            "en",
            "sw"
        ]
    },
    "UM": {
        "name": "U.S. Minor Outlying Islands",
        "native": "United States Minor Outlying Islands",
        "phone": "1",
        "continent": "OC",
        "capital": "",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "US": {
        "name": "United States",
        "native": "United States",
        "phone": "1",
        "continent": "NA",
        "capital": "Washington D.C.",
        "currency": "USD,USN,USS",
        "languages": [
            "en"
        ]
    },
    "UY": {
        "name": "Uruguay",
        "native": "Uruguay",
        "phone": "598",
        "continent": "SA",
        "capital": "Montevideo",
        "currency": "UYI,UYU",
        "languages": [
            "es"
        ]
    },
    "UZ": {
        "name": "Uzbekistan",
        "native": "O‘zbekiston",
        "phone": "998",
        "continent": "AS",
        "capital": "Tashkent",
        "currency": "UZS",
        "languages": [
            "uz",
            "ru"
        ]
    },
    "VA": {
        "name": "Vatican City",
        "native": "Vaticano",
        "phone": "379",
        "continent": "EU",
        "capital": "Vatican City",
        "currency": "EUR",
        "languages": [
            "it",
            "la"
        ]
    },
    "VC": {
        "name": "Saint Vincent and the Grenadines",
        "native": "Saint Vincent and the Grenadines",
        "phone": "1784",
        "continent": "NA",
        "capital": "Kingstown",
        "currency": "XCD",
        "languages": [
            "en"
        ]
    },
    "VE": {
        "name": "Venezuela",
        "native": "Venezuela",
        "phone": "58",
        "continent": "SA",
        "capital": "Caracas",
        "currency": "VES",
        "languages": [
            "es"
        ]
    },
    "VG": {
        "name": "British Virgin Islands",
        "native": "British Virgin Islands",
        "phone": "1284",
        "continent": "NA",
        "capital": "Road Town",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "VI": {
        "name": "U.S. Virgin Islands",
        "native": "United States Virgin Islands",
        "phone": "1340",
        "continent": "NA",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "languages": [
            "en"
        ]
    },
    "VN": {
        "name": "Vietnam",
        "native": "Việt Nam",
        "phone": "84",
        "continent": "AS",
        "capital": "Hanoi",
        "currency": "VND",
        "languages": [
            "vi"
        ]
    },
    "VU": {
        "name": "Vanuatu",
        "native": "Vanuatu",
        "phone": "678",
        "continent": "OC",
        "capital": "Port Vila",
        "currency": "VUV",
        "languages": [
            "bi",
            "en",
            "fr"
        ]
    },
    "WF": {
        "name": "Wallis and Futuna",
        "native": "Wallis et Futuna",
        "phone": "681",
        "continent": "OC",
        "capital": "Mata-Utu",
        "currency": "XPF",
        "languages": [
            "fr"
        ]
    },
    "WS": {
        "name": "Samoa",
        "native": "Samoa",
        "phone": "685",
        "continent": "OC",
        "capital": "Apia",
        "currency": "WST",
        "languages": [
            "sm",
            "en"
        ]
    },
    "XK": {
        "name": "Kosovo",
        "native": "Republika e Kosovës",
        "phone": "377,381,383,386",
        "continent": "EU",
        "capital": "Pristina",
        "currency": "EUR",
        "languages": [
            "sq",
            "sr"
        ]
    },
    "YE": {
        "name": "Yemen",
        "native": "اليَمَن",
        "phone": "967",
        "continent": "AS",
        "capital": "Sana'a",
        "currency": "YER",
        "languages": [
            "ar"
        ]
    },
    "YT": {
        "name": "Mayotte",
        "native": "Mayotte",
        "phone": "262",
        "continent": "AF",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "languages": [
            "fr"
        ]
    },
    "ZA": {
        "name": "South Africa",
        "native": "South Africa",
        "phone": "27",
        "continent": "AF",
        "capital": "Pretoria",
        "currency": "ZAR",
        "languages": [
            "af",
            "en",
            "nr",
            "st",
            "ss",
            "tn",
            "ts",
            "ve",
            "xh",
            "zu"
        ]
    },
    "ZM": {
        "name": "Zambia",
        "native": "Zambia",
        "phone": "260",
        "continent": "AF",
        "capital": "Lusaka",
        "currency": "ZMW",
        "languages": [
            "en"
        ]
    },
    "ZW": {
        "name": "Zimbabwe",
        "native": "Zimbabwe",
        "phone": "263",
        "continent": "AF",
        "capital": "Harare",
        "currency": "USD,ZAR,BWP,GBP,AUD,CNY,INR,JPY",
        "languages": [
            "en",
            "sn",
            "nd"
        ]
    }
}


export const linkClicksCheapestClicksGeographies = [
    {
        id: 'worldwide',
        description: "This audience automatically targets people globally.",
        name: "Worldwide",
        budget: 1,
        countries: getCountriesList()
        // filtering to only include countries where Spotify is avail
    }
]

export function searchCountries(searchString) {

    const filtered = COUNTRY_CODES.filter(c => {
        return c.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    })
    return filtered
}
export function getSpecificContinent(continentKey) {

    const countryCodeKeys = Object.keys(country_continents)
    var countries = countryCodeKeys.map(k => {
        const countryData = country_continents[k]

        return {
            ...countryData,
            countryKey: k
        }
    }).filter(countryData => {
        if (countryData.continent === continentKey) return true
        return false
    }).map(o => {
        return {
            value: o.countryKey,
            label: o.name
        }
    })

    return countries
}

export const CONTINENTS = [
    {
        label: "North America",
        value: "NA"
    },
    {
        label: "Europe",
        value: "EU"
    },
    {
        label: "Africa",
        value: "AF"
    },
    {
        label: "Asia",
        value: "AS"
    },
    {
        label: "South America",
        value: "SA"
    },
    {
        label: "Oceania",
        value: "OC"
    },
]
export function getEurope() {
    return getSpecificContinent('EU')
}

export function getAfrica() {
    return getSpecificContinent('AF')
}

export function getAsia() {
    return getSpecificContinent('AS')
}

export function getSouthAmerica() {
    return getSpecificContinent('SA')
}

export function getCountriesList(type) {
    if (!type) {
        return COUNTRY_CODES.map(o => {
            return {
                value: o.code,
                label: o.name

            }
        })
    }
    switch (type) {

        case 'tiktok':
            return COUNTRY_CODES.filter(o => [
                'US', // united stats,
                'CA', // canada,
                'EG', // egypt
                'KR', // korea,
                'KW', // kuwait,
                'MA', // morocco,
                'QA', // qatar,
                'SA', // saudi arabia,
                'ZA', // south africa,
                'TR', // turkey
                'AE'
            ].indexOf(o.code) > -1).map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })
        case 'us':
            return COUNTRY_CODES.filter(o => ['US'].indexOf(o.code) > -1).map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })
            break
        case 'global-default':
            // return COUNTRY_CODES.filter(o => ['FR', 'GB', 'CA', 'DE', 'AU', 'US'].indexOf(o.code) === -1).map(o => {
            return COUNTRY_CODES.filter(o => [

                'TR', // turkey
                'IT', // italy
                'PL', // poland
                'SE', // sweden
                'NO', // norway
                // 'BR', // brazil
                'MX', // mexico
                'NL', // netherlands
                'ES', // spain
                // 'AR', // argentia
                'PE' // peru
            ].indexOf(o.code) > -1).map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })
            break
        case 'gp-default':
            return COUNTRY_CODES.filter(o => ['US', 'FR', 'GB', 'CA', 'DE', 'AU', 'NZ'].indexOf(o.code) > -1).map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break


        case 'europe':
            return getEurope().map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break

        case 'asia':
            return getAsia().map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break

        case 'africa':
            return getAfrica().map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break
        case 'south-america':
            return getSouthAmerica().map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break

        case 'global-cheap':
            return COUNTRY_CODES.filter(o => ['MX', 'IN', 'ID', 'PH', 'BR'].indexOf(o.code) > -1).map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

            break
        default:
            return COUNTRY_CODES.map(o => {
                return {
                    value: o.code,
                    label: o.name

                }
            })

    }
}
var PUBLISHER_PLATFORMS = [
    {
        name: "Facebook",
        id: "facebook",
        positionsId: 'facebook_positions',
        positions: [
            { "id": "feed", "name": "feed" },
            { "id": "right_hand_column", "name": "right hand column" },
            { "id": "instant_article", "name": "instant article" },
            { "id": "marketplace", "name": "marketplace" },
            { "id": "video_feeds", "name": "video feeds" },
            { "id": "story", "name": "story" },
            { "id": "search", "name": "search" }
        ]
    },
    // {
    //     name: "Audience Network",
    //     id: "audience_network"
    // },
    // {
    //     name: "Messenger",
    //     id: "messenger"
    // },
    {
        name: "Instagram",
        id: "instagram",
        positionsId: 'instagram_positions',
        positions: [
            { "id": "stream", "name": "stream" },
            { "id": "story", "name": "story" },
            { "id": "explore", "name": "explore" }
        ]
    },

]



const TYPE_MAPPING = {
    'global-default': "Global",
    'gp-default': "Global Primary",
    'us': 'United States',
    'europe': 'Europe',
    'asia': 'Asia',
    'africa': 'Africa',
    'south-america': 'South America',
    'global-cheap': 'Global Cheap',
    'custom': 'Custom'
}

const defaultRegions = [
    {
        name: "Global",
        id: 'global-default',
        type: {
            value: 'global-default',
            label: TYPE_MAPPING['global-default']
        },

        countries: getCountriesList('global-default')
    },
    {
        name: "US",
        id: 'us',
        type: {
            value: 'us',
            label: TYPE_MAPPING['us']
        },
        countries: getCountriesList('us')
    },
    {
        name: "Global Primary",
        id: 'gp-default',
        type: {
            value: 'gp-default',
            label: TYPE_MAPPING['gp-default']
        },

        countries: getCountriesList('gp-default')
    },
]

const regionMappings = [
    {
        name: "Global",
        id: 'global-default',
        type: {
            value: 'global-default',
            label: TYPE_MAPPING['global-default']
        },

        countries: getCountriesList('global-default')
    },
    {
        name: "US",
        id: 'us',
        type: {
            value: 'us',
            label: TYPE_MAPPING['us']
        },
        countries: getCountriesList('us')
    },
    {
        name: "Global Primary",
        id: 'gp-default',
        type: {
            value: 'gp-default',
            label: TYPE_MAPPING['gp-default']
        },

        countries: getCountriesList('gp-default')
    },
    {
        name: "Asia",
        id: 'asia',
        type: {
            value: 'asia',
            label: TYPE_MAPPING['asia']
        },

        countries: getCountriesList('asia')
    },
    {
        name: "Europe",
        id: 'europe',
        type: {
            value: 'europe',
            label: TYPE_MAPPING['europe']
        },
        countries: getCountriesList('europe')
    },
    {
        name: "Africa",
        id: 'africa',
        type: {
            value: 'africa',
            label: TYPE_MAPPING['africa']
        },

        countries: getCountriesList('africa')
    },
    {
        name: "South America",
        id: 'south-america',
        type: {
            value: 'south-america',
            label: TYPE_MAPPING['south-america']
        },

        countries: getCountriesList('south-america')
    },
    {
        name: "Global Cheap",
        id: 'global-cheap',
        type: {
            value: 'global-cheap',
            label: TYPE_MAPPING['global-cheap']
        },

        countries: getCountriesList('global-cheap')
    },
]




export default COUNTRY_CODES