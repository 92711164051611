import { useMediaQuery, useTheme } from "@material-ui/core"
import { FunctionComponent } from "react"
import {
  ContainedButton,
  OutlinedButton,
  FlexContainer,
  DummyDiv,
} from "../../styles"
import { LoadingLottie } from "components/Loader/LoadingLottie"

const BACK = "Back"
const NEXT = "Next"

export interface Props {
  width?: string
  loadingNextButton?: boolean
  backButtonWidth?: string
  nextButtonWidth?: string
  showBackButton?: boolean
  showNextButton?: boolean
  disableBackButton?: boolean
  disableNextButton?: boolean
  nextButtonLabel?: string
  backButtonLabel?: string
  handleClickNextButton?: () => void
  handleClickBackButton?: () => void
}

const StepButtons: FunctionComponent<Props> = ({
  width,
  loadingNextButton,
  backButtonWidth,
  nextButtonWidth,
  showBackButton = true,
  showNextButton = true,
  disableBackButton,
  disableNextButton,
  nextButtonLabel,
  backButtonLabel,
  handleClickNextButton,
  handleClickBackButton,
}: Props) => {
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down(601))

  return (
    <FlexContainer
      width={width || "100%"}
      paddingBottom="16px"
      paddingTop="16px"
      alignItems="center"
      paddingRight={isResponsive ? "16px" : undefined}
      paddingLeft={isResponsive ? "16px" : undefined}
      justifyContent={isResponsive ? "space-between" : "space-evenly"}
      borderTop="1px solid #C8C7CC"
    >
      {showBackButton ? (
        <OutlinedButton
          width={backButtonWidth}
          disabled={disableBackButton}
          onClick={handleClickBackButton}
        >
          {backButtonLabel || BACK}
        </OutlinedButton>
      ) : (
        <DummyDiv width={backButtonWidth} />
      )}
      {showNextButton ? (
        <ContainedButton
          width={nextButtonWidth}
          disabled={disableNextButton || loadingNextButton}
          onClick={handleClickNextButton}
        >
          {loadingNextButton
            ? <LoadingLottie height={24} width={24} black={disableNextButton || loadingNextButton} />
            : nextButtonLabel || NEXT}
        </ContainedButton>
      ) : (
        <DummyDiv width={nextButtonWidth} />
      )}
    </FlexContainer>
  )
}

export default StepButtons
