import { useEffect, useState } from "react";
import millify from "millify";
import FB, { checkForFBPermissions, fbLoggedIn, loginWithFB, queryFB } from "../../helpers/FB";
import Axios from "../../helpers/Interceptor";
import { LoadingIndicator } from "../Loader";
import { track } from "analytics";
import { Connections, CurrentBrand, FacebookPageType, InstagramPageType } from "types/global";
import { ConnectedInstagramAccountType, InstagramBusinessAccountType } from "./shared/FacebookTypes";
import { siteIconsData } from "pages/post-auth/DashboardModule/Dashboard";
import { SentryError } from "@sentry/utils";
import { TroubleshootSharp } from "@mui/icons-material";
import PopupHeader from "components/shareable/Popup/header";
import Intercom from "helpers/Intercom";
import { useIsSafari } from "utils";
import PrimaryButton from "components/shareable/PrimaryButton";

enum ERROR_STATES_STATUSES {
    NONE,
    PERMISSIONS_ERROR,
    NO_PAGES_ERROR
}
const ERROR_STATES = {
    PERMISSIONS_ERROR: {
        message: "We can't load your Facebook Pages because we don't have permission. Click the button below to authorize access to your pages."
    },
    NO_PAGES_ERROR: {
        message: "It looks like you don't have any Facebook pages associated with your account. Please create a new Facebook page by following the instructions at the link."
    }
}

interface FacebookConnectProps {
    closeModal: () => void;
    artist: CurrentBrand;
    onSave: () => void;
    selected?: FacebookPageResponse;
    autoconnectInstagram?: boolean;
}

interface FacebookPageResponse {
    name: string,
    id: string,
    category: string,
    access_token: string,
    followers_count: number,
    picture: {
        data: {
            url: string
        }
    },
    is_published?: boolean,
    connected_instagram_account?: ConnectedInstagramAccountType,
    instagram_business_account?: InstagramBusinessAccountType,
}



export default function FacebookConnectv2(props: FacebookConnectProps) {

    const {
        closeModal,
        artist,
        onSave,
        selected,
        autoconnectInstagram = false
    } = props

    // status for Facebook Account
    const [checkedFBLoginStatus, setFBCheckedLoginStatus] = useState<boolean>(false);
    const [userFBLoggedIn, setUserFBLoggedIn] = useState<boolean>(false);

    // 
    const [facebookPages, setFacebookPages] = useState<FacebookPageResponse[] | null>(null);
    const [checkedPages, setCheckedPages] = useState<boolean>(false)
    const [loadingPages, setLoadingPages] = useState<boolean>(false)

    // for Safari, users may need to block their popup blocker
    const isSafari = useIsSafari();

    // selector state 
    const [selectedFacebookPage, setSelectedFacebookPage] = useState<FacebookPageResponse | null>(selected ? selected : null)

    // loading indicator for connect
    const [loading, setLoading] = useState<any>(null)

    // error status
    const [error, setError] = useState<any>(null);

    // permissions error
    const [permissionsError, setPermissionsError] = useState<ERROR_STATES_STATUSES | null>(null)

    // searching for pages
    const [searchStr, setSearchStr] = useState<string>('');

    /**
     * If the user has already selected a Facebook Page, then 
     * show it selected.
     * 
     * Alternatively, if the user has not selected a Facebook Page,
     * then show a list of all of their Facebook Pages.
    */
    async function getPages() {
        if (loadingPages) return
        setCheckedPages(true)
        setLoadingPages(true)
        try {


            const results = await queryFB(`/me/accounts`, {
                params: {
                    fields: 'name,category,id,access_token,followers_count,picture{url},is_published',
                    limit: 200
                }
            })

            const filtered: FacebookPageResponse[] = results.data
                .sort((a: any, b: any) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
                .sort((a: any, b: any) => {


                    const accountStatusA = a.is_published
                    const accountStatusB = b.is_published

                    if (accountStatusA && accountStatusB || !accountStatusA && !accountStatusB) {
                        return 0
                    }

                    if (accountStatusB && !accountStatusA) {
                        return 1
                    }

                    if (!accountStatusB && accountStatusA) {
                        return -1
                    }
                })
            setFBCheckedLoginStatus(true)
            setUserFBLoggedIn(true)
            setFacebookPages(filtered);
        } catch (e) {
            console.log('error pulling FB pages')
            track('Error pulling Facebook Pages', {
                error: e
            })
            setError(true)
        }
        setLoadingPages(false)



    }

    useEffect(() => {

        const loggedIn = fbLoggedIn()
        setFBCheckedLoginStatus(true)
        if (!loggedIn) {
            setUserFBLoggedIn(false)
            // subscribe to changes in login status
            FB().Event.subscribe('auth.statusChange', function (response: any) {
                if (response.status === "connected") {
                    setUserFBLoggedIn(true)
                    setError(null)
                    getPages()
                }
            });
        } else {
            setUserFBLoggedIn(true)
            setError(null)
            getPages()
        }
        // if fb not logged in, show login with FB button


        // Cleanup function to unsubscribe
        return () => {
            FB().Event.unsubscribe('auth.statusChange')
        };

    }, []);

    async function callLoginWithFB() {
        console.log("callLoginWithFB")
        const p = await loginWithFB()
        console.log("p", p)
        // const loggedIn = fbLoggedIn()
        // console.log("loggedIn", loggedIn)
        // setFBCheckedLoginStatus(true)
        // if (!loggedIn) {
        //     setUserFBLoggedIn(false)
        // } else {
        //     setUserFBLoggedIn(true)
        //     setError(null)
        //     getPages()
        // }
    }

    async function checkPermissions() {
        const status: any = await checkForFBPermissions([
            'pages_show_list',
            'pages_read_engagement',
            'instagram_basic',
            'instagram_manage_insights',
            'ads_management',
            'ads_read'
        ])
        if (!status || status.length > 0) {
            // if invalid, show dialog to re-request permissions
            setPermissionsError(ERROR_STATES_STATUSES.PERMISSIONS_ERROR)
        } else {
            // there are no pages
            setPermissionsError(ERROR_STATES_STATUSES.NO_PAGES_ERROR)
        }

        setLoadingPages(false)

    }

    async function reRequestPermissions() {
        const p = await loginWithFB(("rerequest" as any), "pages_show_list,pages_read_engagement")
        if (p) {
            setError(null)
            setPermissionsError(ERROR_STATES_STATUSES.NONE)
            getPages()
        }
    }

    useEffect(() => {
        // if we checked the pages, and they're empty - there 2 things that may
        // have happened here: 
        /**
         * 1. the artist declined "ads_management" permission, which we'll need alongside
         * "pages_read_engagement" to get access
         * 2. the artist has no pages, and we need to tell them to setup a page
         */
        if (checkedPages && facebookPages && facebookPages.length === 0) {
            checkPermissions()
        }
    }, [facebookPages])


    async function updateInBrand() {

        // if the user hasn't linked their FB Page, we should auto-select it.
        /**
         * effectively, we're making 1 calls here:
         *  - a call to Facebook to link the page
         */

        try {
            // get the Facebook Page details 

            const connectFBPromise = new Promise(async (resolve, reject) => {
                try {
                    var fbPayload = {
                        facebook_page: selectedFacebookPage,
                        logged_in_fb_user: null
                    }

                    const authResp = FB().getAuthResponse()
                    if (authResp && authResp.accessToken) {
                        fbPayload.logged_in_fb_user = authResp.accessToken
                    } else {
                        // login w fb and try again 
                        await loginWithFB()
                        const authResp = FB().getAuthResponse()
                        if (authResp && authResp.accessToken) {
                            fbPayload.logged_in_fb_user = authResp.accessToken
                        }
                    }

                    await Axios.put(`/brand/${artist.slug}/connect`, {
                        id: artist.id,
                        name: artist.name,
                        service: 'facebook_page',
                        value: fbPayload
                    })

                    resolve(1)
                } catch (e) {
                    console.error("Error connecting Facebook", e)
                    track('Error connecting Facebook within FacebookConnect', {
                        error: e
                    })
                    reject("Error connecting Facebook")
                }
            })

            await connectFBPromise

            // Instagram connect box

            if (autoconnectInstagram && selectedFacebookPage) {
                try {
                    const connectIGPromise = new Promise(async (resolve, reject) => {
                        try {


                            // to connect IG, we need to get the `instagram_accounts` field from the FB Page

                            // In case of error just get the instagram_accounts with the page access token to safely continue

                            var igAccount = await queryFB(`/${selectedFacebookPage.id}/instagram_accounts`, {
                                params: {
                                    fields: 'id,follow_count,followed_by_count,username,profile_pic,is_private,is_published',
                                    access_token: selectedFacebookPage?.access_token
                                }
                            })

                            if (igAccount && igAccount.data && igAccount.data.length > 0) {
                                igAccount = igAccount.data[0]

                            } else {
                                throw "No Instagram Accounts found"
                            }



                            var connectedAccount = null
                            var igBusinessAccount = null

                            var getFbPageData = await queryFB(`/${selectedFacebookPage.id}`, {
                                params: {
                                    fields: 'instagram_business_account{username,id,followers_count,profile_picture_url},connected_instagram_account{username,id,followers_count,profile_picture_url}',
                                    access_token: selectedFacebookPage?.access_token
                                }
                            })
                            if (getFbPageData) {
                                if (getFbPageData.instagram_business_account) {
                                    igBusinessAccount = getFbPageData.instagram_business_account
                                }

                                if (getFbPageData.connected_instagram_account) {
                                    connectedAccount = getFbPageData.connected_instagram_account
                                }

                            }


                            const p = await Axios.put(`/brand/${artist.slug}/connect`, {
                                id: artist.id,
                                name: artist.name,
                                service: 'instagram_page',
                                value: {
                                    businessAccount: igBusinessAccount,
                                    instagramAccount: igAccount,
                                    connected_instagram_account: connectedAccount
                                }
                            })

                            resolve(1)
                        } catch (e) {
                            console.error("Error connecting Instagram within Facebook", e)
                            reject("Error connecting Instagram")

                        }
                    })

                    await connectIGPromise

                } catch (e) {
                    console.error("Error connecting Instagram", e)

                    track('Error connecting Instagram - updateInBrand in Facebook Connector', {
                        error: e
                    })
                }
            }


            onSave()
            closeModal()


        } catch (e) {
            console.error("Error connecting Facebook", e)
            track('Error connecting Facebook', {
                error: e
            })
        }


    }

    function handleNonError() {
        if (permissionsError === ERROR_STATES_STATUSES.NO_PAGES_ERROR) {
            return (
                <>    <><p className="my-4">{ERROR_STATES.NO_PAGES_ERROR.message}</p>
                    <a target="_blank" rel="noopener"
                        href="https://www.facebook.com/help/104002523024878/?helpref=uf_share" className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Create a New Page &gt;
                    </a>
                </>
                </>
            )
        } else if (permissionsError === ERROR_STATES_STATUSES.PERMISSIONS_ERROR) {
            return (
                <><p className="my-4">{ERROR_STATES.PERMISSIONS_ERROR.message}</p>
                    <button type="button"
                        onClick={() => reRequestPermissions()}
                        className="flex items-center justify-center w-full text-center p-4 text-primary font-bold border-t border-b border-backgound-100">
                        Add Facebook Pages Permissions &gt;
                    </button>
                </>
            )
        } else {

        }

    }

    function postLoadingComponent() {
        if (facebookPages && facebookPages.length > 0) {
            return (
                <>
                    {loadingPages ? (<li className="cursor-pointer sy-card flex items-center justify-center">
                        <LoadingIndicator color="black" height="30px" />
                    </li>) : null}

                    {
                        facebookPages.length > 0 && facebookPages
                            .filter((a: FacebookPageResponse) => {
                                if (!(searchStr && searchStr !== "")) {
                                    return true
                                } else {
                                    return a.name.toLowerCase().includes(searchStr.toLowerCase())
                                }
                            })
                            .map((item: FacebookPageResponse) => {
                                return (
                                    <label htmlFor={'facebook_' + item.id}>
                                        <li onChange={() => setSelectedFacebookPage(item)} key={item.id} className="cursor-pointer sy-card">

                                            <div className="flex items-center">
                                                <input
                                                    disabled={!item.is_published}

                                                    id={'facebook_' + item.id} type="radio" className="mr-2" name={'conect-facebook-pages'}
                                                    value={item.id}
                                                    checked={selectedFacebookPage ? selectedFacebookPage.id === item.id : false} />

                                                <div className="flex items-center">
                                                    <div className="artist-img flex-shrink-0"
                                                    >
                                                        <img
                                                            src={(item.picture && item.picture.data && item.picture.data.url) || require("../../assets/images/profileIcon.svg").default}
                                                        />

                                                    </div>
                                                    <div className="px-3">
                                                        <label htmlFor={'facebook_' + item.id} className="cursor-pointer" style={{ width: "100%", display: "inline-block" }}>{item.name} [{item.category}]</label>
                                                        <div className="flex flex-row items-center">

                                                            <div className="social-text">
                                                                <p className="text-xs">
                                                                    {item.followers_count > 0 ? millify(item.followers_count) : 0} followers
                                                                </p>
                                                                {!item.is_published ? (
                                                                    <>
                                                                        <p className={'text-red-500 text-xs'}>This Page is not published, and must be live before you can use it to start running ads.</p>
                                                                        <a className="text-red-500 text-xs underline"
                                                                            href={`https://www.facebook.com/${item.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                            target="_blank">Click here to publish the page: {`https://www.facebook.com/${item.id}/settings/?tab=settings&ref=page_edit&section=page_visibility&view`}
                                                                        </a>
                                                                    </>
                                                                )
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*  */}
                                            </div>

                                        </li>
                                    </label>
                                );
                            })
                        // .splice(0, 3)
                    }

                    <li
                    >
                        <div className="flex flex-col items-center">

                            <p className="my-4 px-5 md:px-10">Don't see your Facebook page listed? Make sure you've linked your page with Symphony by clicking below. If you still don't see it, you may need to get access to the page.</p>
                            <button type="button"
                                onClick={() => reRequestPermissions()}
                                className="flex items-center justify-center w-full text-center p-4 text-primary font-semibold border-t border-b border-backgound-100">
                                Link a Page to Symphony &gt;
                            </button>
                            <button type="button"
                                onClick={() => Intercom.openFacebookPageAccessArticle()}
                                className="flex items-center justify-center w-full text-center p-4 text-primary font-semibold border-t border-b border-backgound-100">
                                How to get Facebook Page Access &gt;
                            </button>

                        </div>

                    </li>


                </>
            )

        } else {
            return (
                <div className="">
                    {handleNonError()}
                </div>
            )
        }
    }

    return (
        <div className="bg-white w-full h-full mx-auto">
            <PopupHeader
                text="Connect Facebook Page"
                onClose={closeModal} />
            <div className="middle-wrapper ">
                <div className="px-4 lg:px-10 mt-5 pb-5 border-b border-gray-200">
                    <h4 className=" text-2x font-semibold  ">
                        Select {artist.name}'s Facebook Page
                    </h4>
                    <p>This is the Facebook business Page that you primarily use to post updates and run ads.{checkedFBLoginStatus && !userFBLoggedIn && ` If you're using a popup blocker or Safari, you may need to disable it to allow for Facebook Login to popup.`}</p>


                    {checkedFBLoginStatus && !userFBLoggedIn && (
                        <>
                            <div className="mt-2">
                                <PrimaryButton
                                    onClick={() => callLoginWithFB()}
                                    text="Login with Facebook" />
                            </div>
                            {/* {isSafari && (<button
                                className="mt-2 font-medium"
                                style={{
                                    color: "#8800FF"
                                }}
                                onClick={() => {
                                    Intercom.openFacebookInstagramConnectorArticle()
                                }}>Learn how to allow Facebook Login on Safari &gt;</button>)} */}
                        </>
                    )}
                </div>
                {/* Show search bar if user has more than 3 pages */}
                {!loadingPages && facebookPages && facebookPages.length > 3 ? <div className="flex flex-row items-center border-b">
                    <img className="pl-5 lg:pl-10 w-auto" src={require('../../assets/images/search-icon.svg').default} />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchStr}
                        onChange={(e: any) => setSearchStr(e.target.value)}
                        className="pl-4 pr-6 py-4 outline-none border-gray-200 w-full"
                    />
                </div> : null}

                <ul className="add-new border-b  max-h-80 overflow-auto">
                    {loadingPages ? (<li className="cursor-pointer sy-card flex items-center justify-center">
                        <LoadingIndicator color="black" height="30px" />
                    </li>) : null}

                    {!loadingPages && postLoadingComponent()}

                </ul>


            </div>
            <div className="text-center px-4 lg:px-10 py-4">
                <button disabled={error || !selectedFacebookPage || !userFBLoggedIn} onClick={() => {
                    updateInBrand()
                    setLoading(true)
                }} className="btn-primary 
               br-20px lg:mb-4 py-2 lg:py-3 w-full">{loading ? <div className="h-6 m-auto flex justify-center"><LoadingIndicator /></div> : 'Connect'}</button>
            </div>
        </div >
    )
}
