import { IntercomArticleIds } from "helpers/Intercom"
import { FacebookAdAccountInfo, FacebookCampaignIssuesBanner } from "../Components/FacebookCampaignIssuesBanner";
import { FacebookCampaignSimpleBanner, SimpleBannerProps } from "../Components/FacebookCampaignIssuesBanner/simple-banner";
import { OverallCampaignStatus } from "types/global";

type ErrorActionDetails = {
  title: string
  description?: string |
  ((variables: {
    adAccountName?: string,
    conversionsEventName?: string,
    businessName?: string,
    pixelName?: string,
  }) => string)
  ctaText?: string
  ctaLink?: string |
  ((linkParams: { adAccountId?: string, businessId?: string }) => string)
  supportMessage?: string
  intercomArticleId?: string
}

export type FacebookErrorAction = Record<string, ErrorActionDetails>

/**
 * A collection of Facebook errors indexed by an error code and
 * a description of the actions a user must take in order to solve them.
 * */
export const facebookErrorActions: FacebookErrorAction = {
  FB_HEALTH_SPEND_LIMIT: {
    title: 'Facebook spend limit',
    description:
      'Your Facebook ad account is nearing its spend limit. Update your spend limit to ensure your campaign doesn’t pause unexpectedly.',
    ctaText: 'Set spent limit',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_HEALTH_PENDING_BALANCE: {
    title: 'Clear your pending balance',
    description:
      'Facebook charges you after you’ve run a campaign. To ensure you can continue spending on your future campaigns seamlessly, it’s essential to clear any pending balance from previous campaigns.',
    ctaText: 'Clear pending balance',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_HEALTH_FUNDING_SOURCE_NOT_FOUND: {
    title: 'Ad Account Issue: Your Ad Account requires a funding source to run ads',
    description: ({ adAccountName }) =>
      `Your Ad Account (${adAccountName}) doesn’t have a valid funding source attached to it. You’ll need to set that up before ads are able to run.`,
    ctaText: 'Verify funding',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },

  FB_HEALTH_INVALID_FUNDING_SOURCE: {
    title: 'Ad Account Issue: Your Ad Account requires a valid payment method to run ads.',
    description: ({ adAccountName }) =>
      `Your Ad Account (${adAccountName}) doesn’t have a valid payment method attached to it. You’ll need to set that up before ads are able to run.`,
    ctaText: 'Verify payment method',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_HEALTH_INVALID_ACCOUNT_STATUS: {
    title: 'Ad account status changed during campaign',
    description:
      'The status can change during your campaign. Keep a close watch on your account status to ensure your ads run smoothly.',
    ctaText: 'Update status',
    ctaLink: 'https://www.facebook.com/business-support-home/',
  },
  FB_HEALTH_DISABLED_REASON: {
    title: 'Optimize your campaign performance',
    description:
      'Ensure your campaign keeps rolling smoothly. If it’s not spending, it might be time to check and update your account status.',
    ctaText: 'Update status',
    ctaLink: 'https://www.facebook.com/business-support-home/',
  },
  FB_HEALTH_TWO_FACTOR_DISABLED: {
    title: 'Enable Two-Factor Authentication on Facebook',
    description:
      'Two-factor authentication is a way to more securely log into Facebook, and you’ll want to set it up to ensure campaigns run without pausing.',
    ctaText: 'Enable Two-Factor authentication',
    ctaLink: 'https://www.facebook.com/settings?tab=security',
  },
  FB_HEALTH_INVALID_USER_TOKEN: {
    title: 'Brand access token updated',
    description:
      'If you’ve lost access to your ad account, it’s likely due to an expired or removed brand token.',
  },
  FB_INVALID_ACCOUNT_STATUS: {
    title: 'Ad Account Issue: Your ad account status is not active.',
    description: ({ adAccountName }) =>
      `It looks like after submitting your campaign, your ad account (${adAccountName}) status became inactive. Your campaign won’t be able to start spending until you review and clear the issues.`,
    ctaText: 'Review status',
    ctaLink: 'https://www.facebook.com/business-support-home/',
  },
  FB_DISABLED_REASON: {
    title: 'Campaign may be paused due to a disable reason',
    description:
      'Ensure your campaign success. We initially set it up smoothly, but sometimes, things change during the campaign.',
    ctaText: 'Check campaign',
    ctaLink: 'https://www.facebook.com/business-support-home/',
  },
  FB_FUNDING_SOURCE_NOT_FOUND: {
    title: 'Ad Account Issue: Your Ad Account requires a funding source to run ads.',
    description: ({ adAccountName }) =>
      `Your Ad Account (${adAccountName}) doesn’t have a valid funding source attached to it. You’ll need to set that up before ads are able to run.`,
    ctaText: 'Verify funding',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_INVALID_FUNDING_SOURCE: {
    title: 'Ad Account Issue: Your Ad Account requires a valid payment method to run ads.',
    description: ({ adAccountName }) =>
      `Your Ad Account (${adAccountName}) doesn’t have a valid payment method attached to it. You’ll need to set that up before ads are able to run.`,
    ctaText: 'Verify payment method',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_UNABLE_TO_RUN_ADS_DUE_TO_PAID_META_SUBSCRIPTION: {
    title: 'You\'re unable to run ads currently due to a Meta paid ad-free subscription.',
    description: () =>
      `Due to restrictions from Facebook + Instagram, ad-free profiles on Meta aren\'t able to run ads or boost posts on Instagram + Facebook. You can remove the ad-free subscription to start running ads again.`,
    ctaText: 'Learn more',
    ctaLink: 'https://www.facebook.com/business/help/1563729837497242',
  },
  FB_PENDING_BALANCE: {
    title: 'Ad Account Issue: You have an unpaid balance preventing you from running ads.',
    description: ({ adAccountName }) =>
      `When running ads, Facebook charges you as the budget is spent. It looks like your Ad Account (${adAccountName}) has a remaining unpaid balance from one of your last campaigns. Please pay off the balance to ensure your ads can run without pausing.`,
    ctaText: 'Clear pending balance',
    ctaLink: 'https://business.facebook.com/billing_hub/payment_settings',
  },
  FB_TWO_FACTOR_DISABLED: {
    title: 'Enable Two-Factor Authentication on Facebook',
    description:
      'Two-factor authentication is a way to more securely log into Facebook, and you’ll want to set it up to ensure campaigns run without pausing.',
    ctaLink: 'https://www.facebook.com/settings?tab=security',
    ctaText: 'Enable Two-factor authentication',
  },
  FB_USER_REMOVED_FROM_AD_ACCOUNT: {
    title: 'Permissions Issue: It looks like you don’t have permission to your ad account or page.',
    description:
      'When running ads, you login to Facebook to provide Symphony permission to run ads on your behalf. It looks like your permission status has changed, and Symphony can no longer run ads.',
    supportMessage: 'Contact support',
  },
  FB_INVALID_USER_TOKEN: {
    title: 'Verify permissions to brand users',
    description:
      'Your Symphony profile is accessible to multiple invited users. When creating a campaign, please make sure all users have proper permissions for your Meta Business Manager, Ad Account, and FB/IG Page.',
    supportMessage:
      'If a team user lacks ad account permissions, your campaign could be stuck in review.',
  },
  FB_USER_TEMP_BLOCKED: {
    title: 'Please review your Ad Account status on Facebook.',
    description:
      'Facebook occasionally blocks users and apps from creating campaigns - this happens automatically on their end. You can review your ad account’s status with a quick check at a link below.',
    ctaText: 'Verify status',
    ctaLink: 'https://www.facebook.com/business-support-home/',
  },
  FB_NON_DISCRIMINATION_POLICY: {
    title: 'Accept non-discrimination policy',
    description:
      'Your ad journey begins with responsibility. To create your campaign, accept the non-discrimination policy. It’s a one-time step. Ensure your ads are seen by all.',
    ctaText: 'Accept policy',
    ctaLink: 'https://www.facebook.com/certification/nondiscrimination',
  },
  FB_IG_PAGE_RESTRICTED: {
    title: "Verify your page's eligibility to run ads.",
    description: 'Meta updates Facebook page statuses for various reasons, and it looks like your Facebook page is currently restricted from advertising. Please resolve the issue to continue running ads with this page.',
    ctaText: 'Review page status on Meta',
    ctaLink: 'https://facebook.com/accountquality'
  },
  FB_ADVANTAGE_CREATIVE: {
    title: 'Ineligible for Advantage Plus Creative',
    description:
      'Unfortunately, the campaign was deleted directly from Facebook. As a result, you are ineligible for Advantage Plus Creative.',
    supportMessage: 'Please ensure to keep your campaigns active on Facebook',
  },
  FB_INVALID_CONVERSIONS_EVENT: {
    title: 'Your conversions event wasn’t found on your pixel',
    description: ({ conversionsEventName }) => `We’ve setup Conversions to go to your site, but it looks like there haven’t been any "${conversionsEventName}" events tracked on your site. Please make sure you’ve added your Facebook Pixel and your site is configured to send events.`,
    ctaText: 'Learn more',
    intercomArticleId: IntercomArticleIds.HOW_TO_FIND_CONVERSIONS_EVENT,
  },
  FB_TOS: {
    title: 'One last step - accept Facebook’s Ad Terms of Service',
    description: 'Facebook requires you to accept their Terms of Service before you can run ads. Accept Facebook’s Terms of Service to get your campaign running. You’ll only have to do this once.',
    ctaText: 'Accept Terms of Service',
    ctaLink: 'https://www.facebook.com/customaudiences/app/tos',
  },
  FB_NO_PERMISSIONS_OVER_AD_ACCOUNT: {
    title: "It looks like you need access to the ad account.",
    description: ({ businessName, adAccountName }) => `You have admin access on the Meta Business ${businessName}, but you don't have access to the linked ad account ${adAccountName} for this campaign. Please review your Meta Business Manager settings and make sure you're an admin on the ad account.`,
    ctaText: 'Review Permissions on Meta Business Manager',
    ctaLink: ({ adAccountId, businessId }) => `https://business.facebook.com/settings/ad-accounts/${adAccountId}?business_id=${businessId}`,
  },
  FB_NO_PERMISSIONS_OVER_PIXEL: {
    title: "It looks like you need access to the pixel.",
    description: ({ businessName, pixelName }) => `You have admin access on the Meta Business ${businessName}, but you don't have access to the linked ads pixel ${pixelName} for this campaign. Please review your Meta Business Manager settings and make sure you have access to the pixel.`,
    ctaText: "Review Permissions on Meta Business Manager",
    ctaLink: ({ businessId }) => `https://business.facebook.com/settings/events-dataset-news?business_id=${businessId}`,
  },
  FB_BILLING_HOLD_ERROR: {
    title: 'It looks like there may be an issue with your Meta payment method.',
    description: "Ads aren't able to run at the moment due to an issue with your Ad Account's payment method on Meta. You can resolve this directly from the payment settings, and once fixed - your ads should start running automatically.",
    ctaText: 'Review your Meta Billing Settings',
    ctaLink: ({ adAccountId }) => `https://business.facebook.com/billing_hub/payment_settings/?asset_id=${adAccountId}`
  },
  FB_EMAIL_NOT_VERIFIED: {
    title: 'It looks like you need to verify your email in Meta before you can run ads.',
    description: 'Meta sometimes requires email verification before you can use the Meta Business Suite and run ads. Once verified, your ads should start running automatically.',
    ctaText: 'Review Meta Business Settings',
    ctaLink: 'https://www.facebook.com/settings/info'
  },
  FB_AD_ACCOUNT_CANT_ACCESS_IG: {
    title: 'It looks like the Instagram you used to create this campaign isn’t linked to your Facebook anymore.',
    description: 'To run effective ads on Instagram, Meta requires your Facebook + Instagram to be properly connected.',
    ctaText: 'Learn how to connect your IG',
    intercomArticleId: IntercomArticleIds.FACEBOOK_INSTAGRAM_CONNECTOR,
  },
  FB_CAMPAIGN_ADS_DENIED: {
    title: "Your ad campaign wasn't approved by Facebook's Ads Review.",
    description: "It looks like your ad campaign was denied during Facebook's Ads Review - this happens automatically on Facebook's side after a campaign is submitted. Please make sure you use assets with no swear words or explicit content. You can re-submit your campaign by creating a new campaign, or request a review below.",
    ctaLink: 'https://business.facebook.com/business-support-home',
    ctaText: 'Request a Review on Facebook',
  },
  FB_AD_ACCOUNT_UNSETTLED: {
    title: 'It looks like your Facebook Ad Account is unsettled.',
    description: 'Your ad campaign was submitted but your Ad Account has a remaining balance to pay before your ads can keep running. Please take care of that, and your ads should resume automatically.',
    ctaText: 'View Ad Account Payment Status',
    ctaLink: ({ adAccountId }) => `https://business.facebook.com/billing_hub/payment_settings/?asset_id=${adAccountId}`
  },
  FB_CAMPAIGN_WITH_ISSUES: {
    title: 'Your campaign is partially live, but some of your ads got denied by Facebook.',
    description: 'It looks one or more of the ad assets you uploaded were denied by Facebook’s Ads Review process - though not all of them. Your campaign will still run, but we recommend requesting a review on the denied assets.',
  },
}

export const errorCodesInErrorActions = (codes: string[]): boolean => {
  if (!codes || codes.length <= 0) {
    return false
  }

  return codes.some((code) => Object.keys(facebookErrorActions).includes(code))
}

interface RenderErrorStatesProps {
  adAccountInfo: FacebookAdAccountInfo,
  facebookErrors?: any,
  facebookErrorCodes?: string[],
  campaignStatus: string,
  conversionsEventName?: string,
  adAccountStatus?: { error: string, good: boolean },
}

const getSimpleBannerData = ({
  adAccountInfo,
  campaignStatus,
  adAccountStatus,
}: RenderErrorStatesProps): SimpleBannerProps | null => {
  const { DENIED, ERROR, LIVE_WITH_ISSUES } = OverallCampaignStatus;
  switch (campaignStatus) {
    case DENIED: {
      return facebookErrorActions.FB_CAMPAIGN_ADS_DENIED as SimpleBannerProps
    }
    case ERROR: {
      if (!adAccountStatus?.good) {
        const ctaLink: string = (
          facebookErrorActions.FB_AD_ACCOUNT_UNSETTLED.ctaLink as (linkParams: { adAccountId?: string, businessId?: string }) => string
        )({ adAccountId: adAccountInfo.id })
        return { ...facebookErrorActions.FB_AD_ACCOUNT_UNSETTLED, ctaLink } as SimpleBannerProps
      }
      break
    }
    case LIVE_WITH_ISSUES: return facebookErrorActions.FB_CAMPAIGN_WITH_ISSUES as SimpleBannerProps
  }

  return null
}

export function renderErrorStates({
  adAccountInfo,
  facebookErrors,
  campaignStatus,
  facebookErrorCodes,
  conversionsEventName,
  adAccountStatus,
}: RenderErrorStatesProps) {
  if (facebookErrorCodes && errorCodesInErrorActions(facebookErrorCodes)) {
    return (
      <FacebookCampaignIssuesBanner
        adAccount={{ ...adAccountInfo }}
        facebookErrors={facebookErrors}
        conversionsEventName={conversionsEventName}
      />
    )
  }

  const simpleBannerData = getSimpleBannerData({
    adAccountInfo,
    campaignStatus,
    facebookErrorCodes,
    facebookErrors,
    adAccountStatus,
  })

  if (simpleBannerData) return <FacebookCampaignSimpleBanner {...simpleBannerData} />

  return null
}