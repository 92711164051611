/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react"
import { queryFB } from "helpers/FB"
import { getAccountStatus, getTosStatus } from "pages/post-auth/MarketingPage/utils"
import Logger from "Logger"
import { PreConnectionConfigs } from "pages/post-auth/MarketingPage/utils/fbCampaigns"

const useTermsOfServiceTimer = (data: {
  selectedFBAdAccount: {
    id: string
  }
  setPreconnectConfigurations: (configs: PreConnectionConfigs) => void
}) => {
  const [time, setTime] = useState(5)
  const [startTimer, setStartTimer] = useState(false)
  const [termsType, setTermsType] = useState("")
  const { selectedFBAdAccount, setPreconnectConfigurations } = data

  const ref = useRef<ReturnType<typeof setInterval> | null>(null)

  const handleStartTimer = (type: string) => {
    setStartTimer(true)
    setTermsType(type)
  }

  useEffect(() => {
    if (startTimer) {
      const interval = setInterval(() => {
        setTime((prev: number) => prev - 1)
      }, 1000)
      ref.current = interval

      return () => clearInterval(interval)
    }
  }, [startTimer])

  useEffect(() => {
    const getTermsAudienceStatus = async (type: string | null) => {
      try {
        const results = await queryFB(`/${selectedFBAdAccount.id}`, {
          params: {
            fields:
              "tos_accepted,offsite_pixels_tos_accepted,user_tos_accepted,business,account_status",
          },
        })

        const configs = getTosStatus(selectedFBAdAccount, results)
        const toDoAccountStatus = getAccountStatus(selectedFBAdAccount, results)
        if (configs.status && !toDoAccountStatus.status) configs.status = false;
        configs.account_status = toDoAccountStatus.account_status

        setPreconnectConfigurations(configs)

        let status = false
        switch (type) {
          case "Custom": status = Boolean(configs?.custom_audience_accepted.status); break;
          case "ValueBased": status = Boolean(configs?.value_based_custom_audience_tos.status); break;
          case "UserTos": status = Boolean(configs?.user_tos_accepted.status); break;
        }

        return status
      } catch (e) {
        Logger.error("Error getting preconfigs", e)
        return false
      }
    }

    const verifyPreConfigs = async (time: number) => {
      try {
        if (time === 0) {
          const verified = await getTermsAudienceStatus(termsType)

          if (verified) {
            if (ref.current) {
              clearInterval(ref.current as ReturnType<typeof setInterval>)
              ref.current = null
            }
            setStartTimer(false)
          }
          setTime(5)
        }
      } catch (error) {
        Logger.error("Error when verifying preconfigs", error)
        setTime(5)
      }
    }

    verifyPreConfigs(time)
  }, [time])

  return {
    startTimer,
    time,
    handleStartTimer,
  }
}

export default useTermsOfServiceTimer
