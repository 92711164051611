import { Grid } from "@material-ui/core"
import { Fragment, FunctionComponent, useState } from "react"
import { ReactComponent as CalendarIcon } from "assets/images/calendar-email-campaign.svg"
import HeaderModal from "../../HeaderModal"
import DialogPaper from 'components/shareable/DialogPaper'
import { ButtonCancel, ButtonsContainer } from "../../../../../../components/shareable/Modal/styles"
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints'
import { AdAccountPageType, FBPixel, FacebookPageType, InstagramPageType } from "types/global"
import { Container, StyledLabel } from "styles/shared"
import { BusinessChip } from "pages/post-auth/SettingsPage/Components/AdvancedSettingsTab/Components/BusinessChip"

interface Props {
    selectedCampaign: {
        campaign_metadata: {
            campaign_state: {
                accounts: {
                    fbPage: FacebookPageType
                    fbPixel: FBPixel
                    igPage: InstagramPageType
                    fbAdAccount: AdAccountPageType
                }
                campaign: {
                    conversions: {
                        conversionsEnabled: boolean
                        conversionsEventName: string
                    }
                }
            }
        }

    };
    open: boolean
    closeModal: () => void
}

const ViewSetupOptionsModal: FunctionComponent<Props> = ({
    selectedCampaign,
    open,
    closeModal
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const { mobileView } = useMediaBreakpoints();

    const { fbPage, fbPixel, igPage, fbAdAccount } = selectedCampaign?.campaign_metadata.campaign_state?.accounts || {};
    const { conversionsEnabled, conversionsEventName } = selectedCampaign?.campaign_metadata.campaign_state?.campaign?.conversions || {};

    function createSection(title: string, asset: {
        name: string,
        id: string,
        business?: {
            id: string,
            name: string
        } | undefined
    }, hideBusinessChip?: boolean) {
        return asset && (
            <Container display="flex" flexDirection="column" justifyContent="space-between"
                borderBottom="1px solid gray" >
                <StyledLabel fontWeight={600} fontSize={18}>{title}</StyledLabel>
                <Container>
                    <StyledLabel fontWeight={400} fontSize={18}>{asset.name}</StyledLabel>
                    {!hideBusinessChip && <BusinessChip business={asset.business ? asset.business : undefined} assetId={asset.id} isDisabled={false}
                        fallbackText={asset.id ? `ID: ${asset.id}` : ''} />}
                </Container>

            </Container>
        )
    }
    return (
        <DialogPaper open={open}>
            <Fragment>
                <HeaderModal
                    closeModal={closeModal}
                    title='Campaign Setup Details'
                    subtitle='These are the connections used when initially making your campaign.'
                />

                <div>
                    <Container display="flex" flexDirection="column"
                        gap="24px"
                    >
                        {createSection('Facebook Ad Account', fbAdAccount)}
                        {createSection('Facebook Page', fbPage, false)}
                        {igPage && igPage.instagramAccount && igPage.instagramAccount.username && createSection('Instagram Page', {
                            name: `@${igPage.instagramAccount.username}`,
                            id: igPage.id!,
                            business: undefined
                        }, true)}
                        {conversionsEnabled && conversionsEventName && createSection('Facebook Pixel', fbPixel, false)}

                        {conversionsEnabled && conversionsEventName && createSection('Conversions Event', {
                            name: conversionsEventName,
                            id: '',
                            business: undefined
                        }, true)}


                    </Container>
                </div>
                <ButtonsContainer
                    flexDirection={mobileView ? "column-reverse" : "row"}
                >
                    <ButtonCancel
                        width={mobileView ? "100%" : "fit-content"}
                        disabled={loading}
                        onClick={closeModal}
                    >
                        Close
                    </ButtonCancel>

                </ButtonsContainer>
            </Fragment>
        </DialogPaper>
    )
}

export default ViewSetupOptionsModal
