import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  dialog: {
    '& .MuiPaper-root': {
      height: 'fit-content',
      maxWidth: '550px',
    },
  },
  input: {
    border: '1px solid #ADB7BC',
    width: '100%',
    height: '43px',
    borderRadius: '6px',
    padding: '12px 16px',
    ':focus': {
      border: '1px solid #8800FF',
    },
  },
}));

export const InputContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const ButtonContainer = styled.button`
  display: flex;
  background: #8800ff;
  border-radius: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:disabled {
    cursor: default;
  }
`;

export const ButtonLabel = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid #edecf2;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TypeText = styled.p`
  font-family: DIN;
  color: #707070;
  font-size: 12px;
`;

export const OptionLabel = styled.div`
  font-family: DIN;
  font-size: 16px;
  line-height: 20px;
  color: #8800ff;
  cursor: pointer;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fdf6ec;
  gap: 8px;
  padding: 16px 16px 4px;
  border-radius: 6px;
`;

export const WarningHeader = styled.p`
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
`;

export const LocationsContainer = styled.div`
  display: flex;
  color: #707070;
  gap: 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const StyledButton = styled.button`
  color: #8800ff;
  padding: 12px 8px;
`;

export const OptionsContainer = styled.div`
  max-height: 196px;
  overflow-y: scroll;
`;

export const NoResultLabel = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  margin: 24px 0px;
`;

export default useStyles;
