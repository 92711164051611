/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import useStyles, { Label, TableRowFlexContainer } from "../../styles";
import { AvatarColor } from "../../../FanbaseTable/styles";

type Item = {
  id?: number;
  fullName: string;
  location: string;
  email: string;
  profileUrl?: string | null;
  color: string;
};

interface Props {
  data: Item[];
}

const CustomTable: FunctionComponent<Props> = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              <Label>Email</Label>
            </TableCell>
            <TableCell className={classes.tableHead} align="center">
              <Label>Name</Label>
            </TableCell>
            <TableCell className={classes.tableHead} align="right">
              <Label>Location</Label>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map(
            ({ id, email, fullName, location, profileUrl, color }, index) => {
              const isLast = index === data.length - 1;
              const borderClassName = isLast
                ? classes.noBorderBottom
                : classes.withBorderBottom;
              const key = !!id
                ? `${id}-${email}-${index}`
                : `${email}-${index}`;

              return (
                <TableRow key={key}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={borderClassName}
                  >
                    <TableRowFlexContainer>
                      {profileUrl ? (
                        <Avatar className={classes.avatar} src={profileUrl} />
                      ) : (
                        <AvatarColor backgroundColor={color} />
                      )}
                      <Label>{email}</Label>
                    </TableRowFlexContainer>
                  </TableCell>
                  <TableCell align="center" className={borderClassName}>
                    <Label>{fullName}</Label>
                  </TableCell>
                  <TableCell align="right" className={borderClassName}>
                    <Label>{location}</Label>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
