import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  inputBorder: {
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    '&:hover': {
      borderColor: '#8800FF',
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  inputDisable: {
    background: "#EDECF2",
    borderColor: "#EDECF2",
    color: "#000000",
    cursor: 'not-allowed',
    borderRadius: "6px",
    '& fieldset': {
      border: 'none',
    },
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16
    }
  },
}))


interface ModalPlatformInputContainerProps {
  alignItems?: string
}

interface PlatformLogoContainerProps {
  backgroundColor?: string;
  padding?: string;
  width?: string;
  height?: string;
}

interface SearchListContainerProps {
  height?: string
}

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;


export const ModalPlatformInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }: ModalPlatformInputContainerProps) => alignItems || "flex-start"};
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const PlatformLogoContainer = styled.div`
  background: ${({ backgroundColor }: PlatformLogoContainerProps) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: ${({ padding }: PlatformLogoContainerProps) => padding};
  height: ${({ height }: PlatformLogoContainerProps) => height};
  min-width: ${({ width }: PlatformLogoContainerProps) => width};
  z-index: 10;
`;

export const SearchListContainer = styled.div`
  width: 100%;
  height: ${({ height }: SearchListContainerProps) => height || "100%"};
  overflow: auto;
`