import { ButtonContainer, Label, LabelContainer, DefaultImage } from './style';
import paypalIcon from "assets/images/paypal-icon.png";
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';

interface Props {
  width: string;
  height: string;
}

const PaypalButtonNonUSD = ({ width, height }: Props) => {
  const { mobileView: isMobile } = useMediaBreakpoints();

  return (
    <ButtonContainer width={width} height={height} gap={isMobile ? "8px" : "10px"}>
      <DefaultImage
        height='32px'
        width='auto'
        src={paypalIcon}
      />
      <LabelContainer>
        <Label>only available in USD</Label>
      </LabelContainer>
    </ButtonContainer>
  );
};

export default PaypalButtonNonUSD;
