import { FunctionComponent } from 'react';
import { IconContainer, MainContainer, Severity, TextContainer, useStyles } from './styles';
import SecondaryButton from 'components/shareable/SecondaryButton';
import { ReactComponent as WarningIcon } from 'assets/images/campaignDetails/warning.svg';
import { SystemColors } from 'types/globalStyles';
import { Body1 } from 'components/shareable/Typography';
import useMediaBreakpoints from 'Hooks/useMediaBreakpoints';
import { daysUntilRelease, daysUntilReleaseText } from './utils';

interface Props {
  songName: string;
  releaseDate: string | Date;
  handleClickAdd: () => void;
}

const WarningNoReleaseUpcCard: FunctionComponent<Props> = ({
  songName,
  releaseDate,
  handleClickAdd,
}) => {
  const classes = useStyles();
  const { mobileView } = useMediaBreakpoints();

  const daysLeft = daysUntilRelease(releaseDate);
  const daysText = daysUntilReleaseText(releaseDate)
  const severity = daysLeft < 0 ? Severity.ERROR : Severity.WARNING;
  const text = severity === Severity.ERROR ?
    'Your Pre-Save campaign is ready to be launched. Please add the release’s UPC / ISRC to have your fans save your release.' :
    `Don't forget to add the UPC / ISRC to your pre-save campaign for "${songName}", releasing ${daysText}.`;

  return (
    <MainContainer
      severity={severity}
      borderRadius={mobileView ? '0px' : '8px'}
      flexDirection={mobileView ? 'column' : 'row'}
      alignItems={mobileView ? 'flex-end' : 'center'}
      gap={mobileView ? '12px' : '24px'}
    >
      <TextContainer
        gap={mobileView ? '16px' : '8px'}
      >
        <IconContainer>
          <WarningIcon
            width="24px"
            height="24px"
            className={severity === Severity.ERROR ? classes.svgError : classes.svgWarning}
          />
        </IconContainer>
        <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>{text}</Body1>
      </TextContainer>
      <SecondaryButton
        onClick={handleClickAdd}
        text="Add UPC or ISRC"
      />
    </MainContainer>
  );
}

export default WarningNoReleaseUpcCard;