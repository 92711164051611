import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import { THEME_BREAKPOINTS } from 'types/global';

const { mobile, large } = THEME_BREAKPOINTS;

const useStyles = makeStyles((theme: Theme) => ({
  creationButton: {
    border: '1px solid #000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'initial',
    padding: '1.8% 5%',
    borderRadius: '5px',
  },
  button: {
    border: '1px solid #000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 'initial',
    padding: '1.8% 5%',
    borderRadius: '5px',
    fontSize: '2vw',
    [theme.breakpoints.up(large)]: {
      fontSize: '20px',
    },
  },
  title: {
    fontSize: '1.1rem',
    lineHeight: 'inherit',
    marginBottom: '0px',
  },
  name: {
    fontSize: '1.3rem',
    lineHeight: 'inherit',
    fontWeight: 600,
    marginBottom: '2px',
    marginTop: '-2px',
  },
  subtitle: {
    fontSize: '1rem',
    lineHeight: 'inherit',
    fontWeight: 500,
  },
  relativeTitle: {
    fontSize: '0.55vw',
    lineHeight: 'inherit',
    marginBottom: '0px',
    [theme.breakpoints.down(mobile)]: {
      fontSize: '3.8vw',
      lineHeight: 'inherit',
    },
  },
  relativeName: {
    fontSize: '0.65vw',
    lineHeight: 'inherit',
    fontWeight: 600,
    marginBottom: '2px',
    marginTop: '-2px',
    [theme.breakpoints.down(mobile)]: {
      fontSize: '4.5vw',
      margin: '0px',
    },
  },
  relativeSubtitle: {
    fontSize: '0.5vw',
    lineHeight: 'inherit',
    fontWeight: 500,
    [theme.breakpoints.down(mobile)]: {
      fontSize: '3.5vw',
      lineHeight: 'inherit',
    },
  },
  anchors: {
    borderRadius: '0 0 0.3em 0.3em',
    overflow: 'hidden',
    '& .stacked-ratio': {
      [theme.breakpoints.down(mobile)]: {
        aspectRatio: 6,
      },
      '& .optioncontainer': {
        padding: '5%',
        height: 'fit-content',
      },
    },
    '& .boxes-ratio': {
      padding: '5% 0px',
      borderRadius: '8px',
      marginBottom: '5%',
    },
    '& .boxes-relative': {
      borderRadius: '4px',
      padding: '7% 0px',
      marginBottom: '5%',
      [theme.breakpoints.down(mobile)]: {
        borderRadius: '8px',
      },
    },
    '& .spotify': {
      backgroundColor: 'transparent',
    },
    '& .deezer': {
      margin: '3.81% 0px',
    },
    '& .border-b': {
      borderBottomWidth: '0.1px',
      borderBottomColor: 'gray',
    },
    '& .border-t': {
      borderTopWidth: '0.1px',
      borderTopColor: 'gray',
    },
    '& .traditionaloption': {
      padding: '5%',
      paddingLeft: '8%',
    },
    '& .traditional-button': {
      width: 'fit-content',
      fontSize: '0.40vw',
      borderRadius: '1.8px',
      borderWidth: '0.1px',
      [theme.breakpoints.down(mobile)]: {
        fontSize: '3.5vw',
        borderRadius: '3px',
        borderWidth: '1px',
      },
    },
  },
}));

interface StyledIconProps {
  width?: string;
}
export const StyledIcon = styled.img`
  width: ${({width} : StyledIconProps) => width};
`;

export default useStyles;
