/**
 * Targeting Tab Data
 */
import _ from 'lodash'
import { getCountriesList } from "../../../../../helpers/Countries"
import { SPOTIFY_AVAILABLE_MARKETS } from "../../../../../helpers/Spotify"
import { CAMPAIGN_TYPE_ENUM } from '../../../../../constants'

export const touringDefaultLocations = getCountriesList('gp-default').filter(o => SPOTIFY_AVAILABLE_MARKETS.indexOf(o.value) > -1).map(o => ({ ...o, type: "country", region_id: null, country_code: null }))
export const globalDefaultLocations = getCountriesList('global-default').filter(o => SPOTIFY_AVAILABLE_MARKETS.indexOf(o.value) > -1).map(o => ({ ...o, type: "country", region_id: null, country_code: null }))
export const worldwideTargeting = getCountriesList().map(o => ({ ...o, type: "country", region_id: null, country_code: null }))

// DEFAULT MUSIC STREAMS CAMPAIGN
export const geographyTargetingDefaults = (campaignType?: CAMPAIGN_TYPE_ENUM) => {
    switch (campaignType) {
        case CAMPAIGN_TYPE_ENUM.boost_instagram:
            return [{
                id: "touring-markets",
                description:
                    "This audience automatically targets people in countries where you're likely to get higher, more valuable engagement.",
                name: "High Engagement Markets",
                budget: 1.0,
                locations: _.keyBy(touringDefaultLocations, "value"),
            }]
        case CAMPAIGN_TYPE_ENUM.record_streams:
            return [
                {
                    id: 'touring-markets',
                    description: "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
                    name: "Touring Markets",
                    budget: 0.7,
                    locations: _.keyBy(touringDefaultLocations, 'value')
                },
                {
                    id: 'global-markets',
                    description: "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive your algorithmic streams.",
                    name: "Global Markets",
                    budget: 0.3,
                    locations: _.keyBy(globalDefaultLocations, 'value')
                }]
        case CAMPAIGN_TYPE_ENUM.grow_playlist_followers:
            return [
                {
                    id: 'touring-markets',
                    description: "This audience automatically targets people in higher engagement markets, including the US, Canada, and primary markets in Europe.",
                    name: "Touring Markets",
                    budget: 0.5,
                    locations: _.keyBy(touringDefaultLocations, 'value')
                },
                {
                    id: 'global-markets',
                    description: "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive discovery.",
                    name: "Global Markets",
                    budget: 0.5,
                    locations: _.keyBy(globalDefaultLocations, 'value')
                }]
        case CAMPAIGN_TYPE_ENUM.link_clicks:
            return [
                {
                    id: 'touring-markets',
                    description: "This audience automatically targets people in countries where you're likely to get higher-quality traffic.",
                    name: "High Engagement Markets",
                    budget: 1.0,
                    locations: _.keyBy(touringDefaultLocations, 'value')
                }]
        default:
            return [
                {
                    id: 'touring-markets',
                    description: "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
                    name: "Touring Markets",
                    budget: 0.7,
                    locations: _.keyBy(touringDefaultLocations, 'value')
                },
                {
                    id: 'global-markets',
                    description: "This audience automatically targets people in countries where it's cheaper to drive a stream, to help build your global fanbase and drive your algorithmic streams.",
                    name: "Global Markets",
                    budget: 0.3,
                    locations: _.keyBy(globalDefaultLocations, 'value')
                }]

    }
}


// Used as copy for music streams campaigns, when recomending audiences 
export const defaultAudienceCopy = (campaignType: CAMPAIGN_TYPE_ENUM) => {
    switch (campaignType) {
        case CAMPAIGN_TYPE_ENUM.boost_instagram:
            return (
                <>
                    Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build an engaged fanbase</span>.
                </>
            )
            break
        case CAMPAIGN_TYPE_ENUM.record_streams:
        case CAMPAIGN_TYPE_ENUM.grow_playlist_followers:
            return (
                <>
                    Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build a touring fanbase</span> and <span className="text-primary underline">global countries that help grow your monthly listeners more easily</span>.
                </>
            )
            break
        case CAMPAIGN_TYPE_ENUM.link_clicks:
            return (
                <>
                    Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you maximize traffic</span>.
                </>
            )
            break
        default:
            return (
                <>
                    Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you maximize traffic</span>.
                </>
            )
    }
}


// LOW BUDGET CAMPAIGN SETUP
/**
 * Used when the budget is below internal recommendations.
 */
export const lowBudgetGeographyTargetingDefaults = [
    {
        id: 'touring-markets',
        description: "This audience automatically targets people in countries where you're likely to tour, including the US, Canada, and primary markets in Europe.",
        name: "Recommended Markets",
        budget: 1,
        locations: _.keyBy(touringDefaultLocations, 'value')
    }
]

// Used as copy for music streams campaigns, when recomending audiences 
export const lowBudgetAudienceCopy = (campaignType: CAMPAIGN_TYPE_ENUM) => {
    switch (campaignType) {
        case CAMPAIGN_TYPE_ENUM.boost_instagram:
            return (
                <>
                    Based on your budget, Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build an engaged fanbase</span>.
                </>
            )
            break
        case CAMPAIGN_TYPE_ENUM.record_streams:
        case CAMPAIGN_TYPE_ENUM.grow_playlist_followers:
            return (
                <>
                    Based on your budget, Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build an engaged fanbase</span>.
                </>
            )
            break
        case CAMPAIGN_TYPE_ENUM.link_clicks:
            return (
                <>
                    Based on your budget, Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build an engaged fanbase</span>.
                </>
            )
            break
        default:
            return (
                <>
                    Based on your budget, Symphony will automatically target your marketing in <span className="text-primary underline">primary countries that help you build an engaged fanbase</span>.
                </>
            )
    }
}

export const lowBudgetCampaignDisclaimerCopy = (budgetValue?: string) => {
    return `If your budget is over ${budgetValue ? budgetValue : `$100 USD`}, you'll be able to add multiple location targets.`
}
// Reducer actions
export const ADD_TARGET = 'addTarget'
export const REMOVE_TARGET = 'removeTarget'
export const EDIT_TARGET = 'editTarget'
export const ADD_LOCATION = 'addLocation'
export const UPDATE_SEARCH_TEXT = 'updateSearchText'
export const SET_LOCATIONS_RESULT = 'setFBLocationsResult'
export const SET_LOADING_LOCATIONS = 'setLoadingLocations'
export const SET_TARGET_NAME = 'setTargetName'
export const SAVE_TARGET_DATA = 'saveTargetData'
export const DISABLE_CONTINUE_BUTTON = 'disableContinueButton'
export const SET_IS_FIRST_RENDER = 'setIsFirstRender'
export const RESET_DEFAULTS = 'resetDefaults'

