import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const TidalIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg width="30" height="30" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1149_17693)">
                    <path d="M11.1928 11.6512H11.2349C11.5559 12.0245 11.9188 12.3552 12.2607 12.7126L14.5858 15.0701V15.1128C13.4602 16.2489 12.3396 17.3956 11.2139 18.5317C10.0829 17.3903 8.95716 16.2435 7.82617 15.1021C7.88414 14.9901 7.9945 14.9154 8.07867 14.8248C9.11497 13.7634 10.167 12.7179 11.1928 11.6512ZM17.9787 11.6512H18.0208C18.0525 11.6992 18.0839 11.7419 18.126 11.7845C19.2149 12.8779 20.2882 13.9874 21.3875 15.0755C21.3612 15.1395 21.3033 15.1821 21.256 15.2301C20.1672 16.3289 19.0834 17.433 17.9945 18.5317C16.8583 17.3956 15.7431 16.2382 14.6016 15.1075C15.7062 13.9394 16.8688 12.8139 17.9787 11.6512ZM24.7647 11.6512H24.8067C25.0171 11.9072 25.2644 12.1259 25.4906 12.3659C26.3428 13.23 27.1898 14.0887 28.0419 14.9528C28.0841 15.0008 28.1524 15.0435 28.1681 15.1128C27.0266 16.2382 25.9219 17.4063 24.7752 18.5317L21.54 15.2515C21.4874 15.1928 21.419 15.1501 21.3927 15.0755C21.9661 14.5101 22.5237 13.9287 23.0918 13.358C23.6494 12.7873 24.2176 12.2326 24.7647 11.6512ZM14.6489 21.9187C15.7589 20.7932 16.8741 19.6678 17.984 18.5371L18.0312 18.5584C19.1464 19.6945 20.2616 20.8306 21.3874 21.956C21.3507 22.0413 21.2717 22.0893 21.2138 22.1587C20.4038 22.9748 19.5936 23.8015 18.7835 24.6175C18.5205 24.8736 18.268 25.1616 17.9998 25.4016C17.6158 25.0496 17.2633 24.6549 16.8897 24.2922C16.1323 23.5241 15.3801 22.7561 14.6173 21.9933C14.6279 21.972 14.6384 21.94 14.6489 21.9187Z" fill="white" stroke="white" stroke-width="0.161675" />
                </g>
                <defs>
                    <clipPath id="clip0_1149_17693">
                        <rect width="20.4" height="13.8" fill="white" transform="translate(7.7998 11.6203)" />
                    </clipPath>
                </defs>
            </svg>
        </IconHolder>
    );
};
