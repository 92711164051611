import { useState } from 'react';

export interface UseSwitchData {
    enabled: boolean;
    disable: () => void;
    enable: () => void;
    toggle: () => void;
}

interface Props {
    initiallyEnabled?: boolean;
};

const useSwitch = ({
    initiallyEnabled = false,
} : Props = {} ) => {
    const [enabled, setEnabled] = useState<boolean>(initiallyEnabled);
    const disable = () => setEnabled(false);
    const enable = () => setEnabled(true);
    const toggle = () => setEnabled(previous => !previous);

    return {
        enabled,
        disable,
        enable,
        toggle,
    };
}

export default useSwitch;