import { Button } from "@material-ui/core";
import useStyles from "./styles";
import { Book, ImportContacts, ImportContactsRounded } from "@material-ui/icons";
import VideoModalStacked from "../VideoModalStacked";
import { useState } from "react";
import { track } from "analytics";
import { DashIcon, FansIcon, GiftIcon, LearningIndicatorIcon, MktgIcon, WebsiteIcon } from "components/svg-icons";
import { SystemColors } from "types/globalStyles";

export enum LearningIndicatorType {
    DASHBOARD,
    MARKETING,
    WEBSITE,
    FANS,
    REFERRAL
}
interface DefaultLearningIndicatorProps {
    text: string;
    type: LearningIndicatorType
}


export default function DefaultLearningIndicator(props: DefaultLearningIndicatorProps) {
    const {
        text,
        type
    } = props

    const classes = useStyles();

    const [openPopup, setOpenPopup] = useState(false);

    let popupTitle: any = null
    let popupSubtitle: any = null
    let popupVideoUrl: any = null
    let popupChipText: any = null
    let popupChipIcon: any = null

    switch (type) {
        case LearningIndicatorType.DASHBOARD:
            popupTitle = "Maximize your use of the Symphony Dashboard"
            popupSubtitle = "Your Symphony dashboard contains helpful data on how your fanbase is growing across relevant social and streaming platforms. If your username changes, you may need to edit your platform link to accurately pull data."
            popupVideoUrl = "https://youtu.be/T7hLVlskigY"
            popupChipText = "Dashboard"
            popupChipIcon = <DashIcon
                height="14"
                width="14"
                color={SystemColors.ACCENT_COLOR} />

            break;
        case LearningIndicatorType.MARKETING:
            popupTitle = "Symphony helps with every step of your release"
            popupSubtitle = "Your Symphony dashboard contains helpful data on how your fanbase is growing across relevant social and streaming platforms. If your username changes, you may need to edit your platform link to accurately pull data."
            popupVideoUrl = "https://youtu.be/HjcpJQUPAhk"
            popupChipText = "Marketing"
            popupChipIcon = <MktgIcon
                height="14"
                width="14"
                color={SystemColors.ACCENT_COLOR} />
            break;
        case LearningIndicatorType.WEBSITE:
            popupTitle = "Maximize your use of the Symphony Dashboard"
            popupSubtitle = "Your Symphony dashboard contains helpful data on how your fanbase is growing across relevant social and streaming platforms. If your username changes, you may need to edit your platform link to accurately pull data."
            popupVideoUrl = "https://youtu.be/GNVEieoVVWU"
            popupChipText = "Website"
            popupChipIcon = <WebsiteIcon
                height="14"
                width="14"
                color={SystemColors.ACCENT_COLOR} />
            break;
        case LearningIndicatorType.FANS:
            popupTitle = "Centralize your fan data with the Fans tab"
            popupSubtitle = "The Fans CRM is your most valuable resource - holding your fan contact list. Symphony makes it easy to make the most of your data collection."
            popupVideoUrl = "https://youtu.be/-Aaych57D5Q"
            popupChipText = "Fans"
            popupChipIcon = <FansIcon
                height="14"
                width="14"
                color={SystemColors.ACCENT_COLOR} />
            break;
        case LearningIndicatorType.REFERRAL:
            popupTitle = "Refer your friends and get paid with the referral program"
            popupSubtitle = "The Symphony Referral Program is our way of giving back to the community - as we grow, you grow. All you need to do is share your link, and when friends sign up, you'll get 50% of their subscription revenue for a year."
            popupVideoUrl = "https://www.youtube.com/watch?v=mvu20k11jow"
            popupChipText = "Referral Program"
            popupChipIcon = <GiftIcon
                height="14"
                width="14"
                color={SystemColors.ACCENT_COLOR} />
            break;
        default:

            break;
    }


    function onClick() {
        track("Learning Indicator Clicked", {
            text: text,
            type: type,
            popupTitle: popupTitle,
            popupSubtitle: popupSubtitle,
            popupVideoUrl: popupVideoUrl,
            popupChipText: popupChipText
        })

        setOpenPopup(true)
    }
    return (
        <div>
            <Button className={classes.textButton}
                onClick={onClick}
                startIcon={<LearningIndicatorIcon />}>{text}</Button>

            {openPopup &&
                popupTitle &&
                popupSubtitle &&
                popupVideoUrl &&

                (<VideoModalStacked
                    chipIcon={popupChipIcon}
                    open={openPopup}
                    closeModal={() => setOpenPopup(false)}
                    chipText={popupChipText}
                    title={popupTitle}
                    subtitle={popupSubtitle}
                    videoUrl={popupVideoUrl}

                />)}
        </div>
    )
}