import { Container, OutlinedButton, StyledLabel } from "styles/shared";
import { finishOrganizationSetup, redirectToOrganizationCheckout } from "../controllers";
import { BillingCycle } from "components/shareable/UpgradeToProModal/utils";
import { CurrentBrand, OrganizationData, PaymentMethods, Tiers } from "types/global";
import { useEffect, useState } from "react";
import { Divider, Switch } from "@material-ui/core";
import SelectableArtist from "./SelectableArtist";
import SelectedArtistCountSection from "./SelectedArtistCountSection";
import { GeneralPurpleButton } from "../styles";
import PrimaryButton from "components/shareable/PrimaryButton";
import { AlertContainer } from "../Components/AddUserModal/styles";
import { Body1, Body2, Caption, Subtitle1 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { MONTHLY_PRO_PLAN_PRICE, MONTHLY_TEAM_PLAN_PRICE, MAX_ADDITIONAL_USERS_WITH_TEAM_PRO, MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER } from "../../../../constants";

const DEFAULT_INCLUDED_PRO_ACCOUNTS = MAX_ADDITIONAL_USERS_WITH_TEAM_PRO; // Number of included Pro accounts
const PRO_PROFILE_OVERAGE_PRICE = MONTHLY_TEAM_PLAN_PRICE_EXTRA_USER; // Price for each additional Pro account
interface SelectableBrand {
    name: string;
    image: string;
    slug: string;
    selectedForPro: boolean;
    alreadyOnPro: boolean;
    proSeatIndex: number;
}

export default function OrganizationNeedsSetupState(props: {
    currentBrand: CurrentBrand;
    existingBrands: CurrentBrand[];
    organization: OrganizationData | null;
    onFinished: () => void;
}) {


    const {
        existingBrands,
        organization,
        onFinished
    } = props

    // Organization Name
    const [nameValue, setNameValue] = useState<string>(organization?.name || "")

    // total allocated seats - by default organization plans come with 5.
    // any additional seats are charged at $15 per profile.
    const defaultBrands = defaultSelectableBrands()
    const defaultUsedSeats = defaultBrands.filter((o: SelectableBrand) => o.selectedForPro).length
    const [selectableBrands, setSelectableBrands] = useState<SelectableBrand[]>(defaultBrands)
    const [totalUsedSeats, setTotalUsedSeats] = useState<number>(defaultUsedSeats)

    // loading state
    const [loading, setLoading] = useState<boolean>(false)

    // Selected Artist State
    function defaultSelectableBrands(): SelectableBrand[] {
        if (existingBrands && existingBrands.length) {
            let proCount = 0
            return existingBrands.map((o: CurrentBrand) => {
                let selectedForPro = o.tier === Tiers.PRO
                if (o.tier === Tiers.PRO) proCount++
                return {
                    selectedForPro: selectedForPro,
                    proSeatIndex: selectedForPro ? proCount : 0,
                    name: o.name!,
                    image: o.image!,
                    slug: o.slug!,
                    alreadyOnPro: o.tier === Tiers.PRO
                }
            })
        }
        return []
    }

    function overageCalculator() {

        const overage = totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS
        if (overage > 0) {
            return overage * PRO_PROFILE_OVERAGE_PRICE
        }

        return 0
    }

    const OVERAGE_COST_PER_PROFILE = 15; // $15 per month for each profile over the 5 included


    function savingsCalculator() {
        const PER_PROFILE_TEAM_PLAN_COST = MONTHLY_TEAM_PLAN_PRICE / DEFAULT_INCLUDED_PRO_ACCOUNTS;
        // Cost per profile under the team plan

        let savings = 0;

        if (totalUsedSeats <= DEFAULT_INCLUDED_PRO_ACCOUNTS) {
            // Calculate savings per profile when total used seats are less than or equal to 5
            savings = (MONTHLY_PRO_PLAN_PRICE - PER_PROFILE_TEAM_PLAN_COST) * totalUsedSeats;
        } else {
            // Calculate total savings when there are more than 5 profiles
            const baseSavings = (MONTHLY_PRO_PLAN_PRICE - PER_PROFILE_TEAM_PLAN_COST) * DEFAULT_INCLUDED_PRO_ACCOUNTS;
            const overageSeats = totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS;
            const overageSavings = (MONTHLY_PRO_PLAN_PRICE - OVERAGE_COST_PER_PROFILE) * overageSeats;
            savings = baseSavings + overageSavings;
        }

        return Math.round(savings);
    }



    useEffect(() => {
        const newTotalUsedSeats = selectableBrands.filter((o: SelectableBrand) => o.selectedForPro).length
        setTotalUsedSeats(newTotalUsedSeats)
    }, [selectableBrands])

    const styles: Record<string, React.CSSProperties> = {
        inputStyles: {
            'borderRadius': '6px',
            'border': '1px solid #ADB7BC',
            'height': '67px',
            'width': '100%',
            'margin': '8px 0px',
            padding: "24px 16px",
            maxWidth: '400px'
        },
        artistList: {
            maxHeight: '35vh',
            overflow: 'auto'
        }
    }


    function handleSelectedForPro(brand: SelectableBrand, selected: boolean) {
        const newSelectableBrands = selectableBrands.map((o: SelectableBrand) => {
            if (o.name === brand.name) {

                return {
                    ...o,
                    selectedForPro: selected
                }
            }
            return o
        }).map((o: SelectableBrand, idx: number) => {
            if (o.selectedForPro) {
                return {
                    ...o,
                    proSeatIndex: idx + 1
                }
            }
            return o
        })
        setSelectableBrands(newSelectableBrands)
    }

    const [allPro, setAllPro] = useState(false)

    useEffect(() => {
        if (allPro) {
            setAllSelectedForPro(true)
        } else {
            setAllSelectedForPro(false)
        }
    }, [allPro])

    function setAllSelectedForPro(selected: boolean) {

        const newSelectableBrands = selectableBrands.map((o: SelectableBrand) => {
            return {
                ...o,
                selectedForPro: o.alreadyOnPro ? true : selected
            }
        }).map((o: SelectableBrand, idx: number) => {
            if (o.selectedForPro) {
                return {
                    ...o,
                    proSeatIndex: idx + 1
                }
            }
            return o
        })
        setSelectableBrands(newSelectableBrands)
    }

    function renderSelectArtistList() {


        // Filter and map over brands selected for Pro
        const proSelectedBrands = selectableBrands.filter(brand => brand.selectedForPro)
            .map((brand, index) => ({
                ...brand,
                proSeatIndex: index + 1,
                overageSeat: index >= DEFAULT_INCLUDED_PRO_ACCOUNTS // true if it's beyond the included accounts
            }));

        return (
            <div style={styles.artistList}>
                {selectableBrands.map((brand: SelectableBrand, idx: number) => {
                    // Find the brand in the proSelectedBrands array to get the correct proSeatIndex
                    const proBrand = proSelectedBrands.find(proBrand => proBrand.name === brand.name);

                    return (
                        <SelectableArtist
                            overageSeat={brand.selectedForPro && proBrand ? proBrand.overageSeat : false}

                            isAlreadyPro={brand.alreadyOnPro}
                            seatCount={brand.selectedForPro && proBrand ? proBrand.proSeatIndex : 0}
                            name={brand.name!}
                            selected={brand.selectedForPro}
                            image={brand.image!}
                            setSelected={(selected) => handleSelectedForPro(brand, selected)} />
                    )

                })}
            </div>
        )
    }

    async function onSubmit() {
        setLoading(true)
        if (loading) return
        try {
            // redirectToOrganizationCheckout(selectedBrands, nameValue)
            const mappedBrands: {
                brandSlug: string;
                currentTier: "pro" | "free";
                upgradeToPro: boolean;
            }[] = selectableBrands.map((o: SelectableBrand) => {
                return {
                    brandSlug: o.slug,
                    currentTier: o.alreadyOnPro ? "pro" : "free",
                    upgradeToPro: !o.alreadyOnPro && o.selectedForPro,
                }
            })

            console.log("mappedBrands", mappedBrands)


            const finishSetup = await finishOrganizationSetup(nameValue, mappedBrands)
            console.log("finishSetup", finishSetup)
            onFinished()
        } catch (error) {
            console.error("error: ", error)
        } finally {
            setLoading(false)
        }


    }

    const userHasOverage = Boolean(totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS > 0)
    return (
        <Container display="flex" flexDirection="column" gap="12px">
            <StyledLabel fontSize={28}>Finalize your Team</StyledLabel>
            <StyledLabel
                fontWeight={400}
                fontSize={20}
                lineHeight={'28px'}
            >
                You can manage unlimited profiles through your Team, and you have {MAX_ADDITIONAL_USERS_WITH_TEAM_PRO} Pro accounts included by default.
            </StyledLabel>
            <Container marginTop="18px" display="flex" flexDirection="column">
                <StyledLabel
                    fontWeight={400}
                    fontSize={16}
                    lineHeight="19px"
                >
                    What do you want to call your team?
                </StyledLabel>
                <input
                    style={styles.inputStyles}
                    value={nameValue}
                    placeholder={'Enter your team\'s name'}
                    onChange={(e) => setNameValue(e.target.value)}
                    maxLength={90}
                />
            </Container>

            <Container marginTop="18px" display="flex" flexDirection="column" gap="8px">

                {/* <SelectedArtistCountSection
                    numberOfArtistsSelected={totalUsedSeats}
                    totalArtists={5}
                /> */}
                <Container display="flex" flexDirection="row" justifyContent="space-between">
                    <Container width={selectableBrands.length > 2 ? "50%" : '100%'}>
                        <StyledLabel
                            fontWeight={600}
                            fontSize={20}
                            lineHeight={'28px'}

                        >
                            Select the profiles you want to upgrade to Pro.
                        </StyledLabel>
                    </Container>
                    {selectableBrands.length > 2 && (<Container display="flex" flexDirection="row" alignItems="center">
                        <StyledLabel>
                            Upgrade All to Pro
                        </StyledLabel>
                        <Switch onChange={() => {
                            setAllPro(!allPro)
                        }} />
                    </Container>)}
                </Container>

                <Divider />
                {renderSelectArtistList()}
                <Divider />

            </Container>

            <Container marginTop="18px" display="flex" flexDirection="column" gap="12px">

                <AlertContainer type="success">
                    <Body1 color={SystemColors.PRIMARY_TEXT_COLOR}>You have {MAX_ADDITIONAL_USERS_WITH_TEAM_PRO} Pro accounts included in your Team plan.</Body1>
                    <Container padding="12px 0px" display="flex" flexDirection="column" gap="4px">
                        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Selected Pro Accounts: {totalUsedSeats}</Body2>
                        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Team Plan: ${MONTHLY_TEAM_PLAN_PRICE}</Body2>
                        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Additional Pro Accounts: ${overageCalculator()} ({totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS} profile{totalUsedSeats - DEFAULT_INCLUDED_PRO_ACCOUNTS !== 1 ? 's' : ''})</Body2>
                        <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Total Monthly Cost: ${MONTHLY_TEAM_PLAN_PRICE + overageCalculator()}</Body2>

                    </Container>
                    {savingsCalculator() > 0 && (<Caption>You're saving ${savingsCalculator()} / month with a Team plan.</Caption>)}

                </AlertContainer>
            </Container>
            <Container marginTop="8px" display="flex" alignItems="center" justifyContent="center">
                <PrimaryButton text="Finish Setup"
                    onClick={() => onSubmit()}
                    disabled={!nameValue || nameValue.trim().length < 3}
                    loading={loading} />
            </Container>
        </Container>
    )
}