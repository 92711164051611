import DialogPaper from "components/shareable/DialogPaper";
import { FunctionComponent, useRef, useState } from "react";
import { ChipContainer, CloseButton, MobileBanner, ModalBody, ModalContainer, ModalHeader, Video, VideoButton, VideoContainer, VideoCover, useStyles } from "../../../../../components/shareable/Modal/with-video/styles";
import { SystemColors } from "types/globalStyles";
import { ReactComponent as PlayIcon } from "assets/images/campaignDetails/play.svg"
import { ReactComponent as PauseIcon } from "assets/images/campaignDetails/pause.svg"
import { ReactComponent as CloseIcon } from "assets/images/close-black.svg"
import PrimaryButton from "components/shareable/PrimaryButton";
import { Body2, Caption, Headline1, Headline2 } from "components/shareable/Typography";
import ConversionsChart from "assets/images/conversions-chart.png";
import { ReactComponent as FacebookIcon } from "assets/images/facebook-rounded-icon.svg";
import { ReactComponent as DesktopIcon } from "assets/images/computer.svg";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

interface Props {
  open: boolean;
  closeModal: () => void;
  title: string;
  subtitle: string;
  onClickButton: () => void;
  buttonText: string;
  videoUrl: string;
}

const VideoModalPortrait: FunctionComponent<Props> = ({
  open,
  closeModal,
  title,
  subtitle,
  onClickButton,
  buttonText,
  videoUrl,
}: Props) => {

  const classes = useStyles();
  const { mobileView } = useMediaBreakpoints();
  const [paused, setPaused] = useState(false);
  const video = useRef<HTMLVideoElement>(null);

  const handleOnPauseUnpauseVideo = () => {
    if (video?.current) {
      paused ? video.current.play() : video.current.pause()
      setPaused(prev => !prev)
    }
  }

  const dialogStyleMobile = {
    maxWidth: "500px",
    width: "95%",
    height: "90%",
    maxHeight: "649px",
    gap: "0px",
    padding: "0px",
  }

  const dialogStyleDesktop = {
    maxWidth: "894px",
    width: "95%",
    height: "90%",
    maxHeight: "615px",
    gap: "0px",
    padding: "0px",
  }

  if (mobileView) {
    return (
      <DialogPaper
        open={open}
        dialogStyle={dialogStyleMobile}
      >
        <ModalContainer flexDirection="column">
          <VideoContainer height="100%">
            <CloseButton
              onClick={closeModal}
            >
              <CloseIcon className={classes.svgWhite} />
            </CloseButton>
            <VideoCover
              background={paused ? SystemColors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
              onClick={handleOnPauseUnpauseVideo}
            >
              <VideoButton
                height="50px"
                width="50px"
                background={paused ? SystemColors.VIDEO_BUTTON_CONTAINER : "transparent"}
                color={paused ? SystemColors.WHITE_TEXT_COLOR : "transparent"}
              >
                {paused ?
                  <PlayIcon height="24px" color='inherit' /> :
                  <PauseIcon height="24px" color='inherit' />
                }
              </VideoButton>
            </VideoCover>
            <ModalHeader>
              <ChipContainer>
                <FacebookIcon className={classes.svgAccent} />
                <Caption>Powered by Facebook Conversions API</Caption>
              </ChipContainer>
              <Headline2 color={SystemColors.WHITE_TEXT_COLOR}>
                {title}
              </Headline2>
              <Body2 color={SystemColors.WHITE_TEXT_COLOR}>
                {subtitle}
              </Body2>
            </ModalHeader>
            <Video
              ref={video}
              src={videoUrl}
              loop
              playsInline
              autoPlay
            />
          </VideoContainer>
          <ModalBody padding="16px" gap="16px">
            <MobileBanner>
              <DesktopIcon width="24px" height="24px" />
              <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>
                Go to your laptop or Desktop browser to set up Conversion API
              </Body2>
            </MobileBanner>
            <img src={ConversionsChart} alt="conversions-chart" />
          </ModalBody>
        </ModalContainer>
      </DialogPaper>
    )
  }

  return (
    <DialogPaper
      open={open}
      dialogStyle={dialogStyleDesktop}
    >
      <ModalContainer>
        <VideoContainer width="48%">
          <VideoCover
            background={paused ? SystemColors.LIGHT_VIDEO_HOVER_LAYOUT : "transparent"}
            onClick={handleOnPauseUnpauseVideo}
          >
            <VideoButton
              width="80px"
              height="80px"
              background={paused ? SystemColors.VIDEO_BUTTON_CONTAINER : "transparent"}
              color={paused ? SystemColors.WHITE_TEXT_COLOR : "transparent"}
            >
              {paused ?
                <PlayIcon width="32px" color='inherit' /> :
                <PauseIcon width="32px" color='inherit' />
              }
            </VideoButton>
          </VideoCover>
          <Video
            ref={video}
            src={videoUrl}
            loop
            playsInline
            autoPlay
          />
        </VideoContainer>
        <ModalBody
          padding="56px 32px 32px 32px"
          width="52%"
          gap="24px"
        >
          <CloseButton
            onClick={closeModal}
            background={SystemColors.PAPER_DIVIDER_CONTAINER}
          >
            <CloseIcon width={24} height={24} />
          </CloseButton>
          <ModalHeader>
            <ChipContainer>
              <FacebookIcon className={classes.svgAccent} />
              <Caption>Powered by Facebook Conversions API</Caption>
            </ChipContainer>
            <Headline1 color={SystemColors.PRIMARY_TEXT_COLOR}>
              {title}
            </Headline1>
            <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>
              {subtitle}
            </Body2>
          </ModalHeader>
          <img src={ConversionsChart} alt="conversions-chart" />
          <PrimaryButton
            width="100%"
            text={buttonText}
            onClick={onClickButton}
          />
        </ModalBody>
      </ModalContainer>
    </DialogPaper>
  );
}

export default VideoModalPortrait;