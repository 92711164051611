import { Divider, Slider, useMediaQuery } from "@material-ui/core";
import { pageView, track } from "analytics";
import { NewLoader } from "components/Loader/ConnectBrandLoader";
import SymphonyTabs from "components/shareable/SymphonyTabs";
import Axios from "helpers/Interceptor";
import { FunctionComponent, useEffect, useState } from "react";
import { TabPanel } from "react-headless-tabs";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { Container, MainContainer, StyledLabel, TextButton } from "styles/shared";
import { FansContainer, TabsContainer, TabsPanelsContainer } from "../Fans/styles";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import LinkCopyShareComponent from "components/shareable/CopyableLink";
import { Body1, Body2, Headline1, Headline2, Headline3, Subtitle1 } from "components/shareable/Typography";
import { TaskItem, TaskList } from "../Fans/Components/FanbaseTasks/generic";
import PrimaryButton from "components/shareable/PrimaryButton";
import { ContentContainer } from "../MarketingPage/styles";
import { SystemColors } from "types/globalStyles";
import LearningIndicator from "components/shareable/LearningIndicator";
import Intercom from "helpers/Intercom";
import TweetLink from "assets/images/referral-tasks/twitter.png"
import LinkInBio from "assets/images/gettingStarted/customizeYourWebsite.png"
import EmailLetter from "assets/images/gettingStarted/completeYourFanbase.png"
import TextIcon from "assets/images/mobile.svg"
import TaskCard from "components/TaskCard";
import useScreenQueryDimensions from "Hooks/useScreenQueryDimensions";
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";

enum Tabs {
    CALCULATOR, "Calculate your Earnings",
    LINK = "Your Link",
    FAQs = "FAQs",
}

const { LINK, FAQs, CALCULATOR } = Tabs;

interface Props {

}

const PrettySlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

const ReferralProgram: FunctionComponent<Props> = ({ }: Props) => {
    const theme = useTheme();

    const { mobileView } = useMediaBreakpoints();

    const setResponsiveView = useMediaQuery(theme.breakpoints.down(1200));
    const [selectedTab, setSelectedTab] = useState<Tabs>(CALCULATOR);

    // slider value
    const startingNumber = 10
    const [currentNumber, setCurrentNumber] = useState<number>(startingNumber)

    // Use states
    const [userReferralLink, setUserReferralLink] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingLink, setLoadingLink] = useState<boolean>(false);

    // initial loading
    useEffect(() => {
        const fetchAffiliateDetails = async () => {
            setLoading(true);
            try {
                const response = await Axios.get('/user/me/affiliate');
                console.log(response.data)
                const {
                    data
                } = response.data

                if (!data) throw "No data found"
                if (data) {
                    setUserReferralLink(data.rewardful_affiliate_link); // Assuming this is the correct key
                }
            } catch (error) {
                console.error('Error fetching affiliate details:', error);
                setUserReferralLink("")
            } finally {
                setLoading(false);
            }
        };

        fetchAffiliateDetails();
        pageView("Refer");
    }, []);


    function revenueCalculator(): string {
        const monthlySubscription = 20
        const totalReferralPercentage = 0.5
        const referralTimeline = 12 // yearly
        const totalReferrals = currentNumber


        return (monthlySubscription * totalReferralPercentage * totalReferrals * referralTimeline).toLocaleString('en-US', {
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'USD'
        })
    }

    async function createAffiliate() {
        try {
            const response = await Axios.get('/user/me/affiliate/create'); // Adjusted for creation endpoint
            if (response.data) {
                setUserReferralLink(response.data.affiliateLink); // Assuming this is the correct key for the referral link
            }
        } catch (error) {
            console.error('Error creating affiliate:', error);
            // Handle error (show message to the user, etc.)
        }
    }

    // This function is called when the user clicks on "View your Referrals"
    async function openRewardfulDashboard() {
        setLoadingLink(true);
        track('Opened Rewardful Dashboard')
        try {
            // Call your API endpoint to get the affiliate dashboard link
            const response = await Axios.get('/user/me/affiliate/dashboard');
            if (response.data && response.data.data) {
                const link = response.data.data;
                // If the link is successfully retrieved, redirect the user to their Rewardful dashboard
                window.location.href = link
            } else {
                console.error('Failed to retrieve dashboard link');
                // Handle the case where no link is returned (e.g., show an error message)
            }
        } catch (error) {
            console.error('Error opening Rewardful dashboard:', error);
            // Handle error (e.g., show an error message to the user)
        } finally {
            setLoadingLink(false);
        }
    };


    function ReferralSlider() {
        return (
            <div className="rounded-md border-primary text-black h-full w-full text-white px-2 py-6 md:px-10 md:py-6 mt-2">
                <div className="w-11/12 md:w-6/12 m-auto text-center text-black">
                    <p>When you refer</p>
                    <p className=" my-1 rounded-md w-full text-5xl"  >{currentNumber}</p>
                    <p>user{currentNumber === 1 ? '' : 's'} who subscribe{currentNumber === 1 ? 's' : ''} to Pro</p>

                    <div className="w-full">
                        <PrettySlider
                            value={currentNumber}
                            onChange={(event: any, newValue: number | number[]) => {
                                setCurrentNumber(newValue as number);
                            }}

                            min={10}
                            step={1}
                            max={1000}
                            aria-label="pretto slider" defaultValue={startingNumber} />

                    </div>
                    <div className="mt-4 text-center">
                        <p className="mb-3">You could earn</p>
                        <p className="text-5xl font-bold mb-1"><span>{revenueCalculator()}</span> / year</p>
                        <p className="mb-4">based on average Symphony Pro subscription fee</p>
                    </div>
                </div>
            </div>
        )
    }

    if (loading) return (
        <div className="flex-col fixed top-1/2 left-1/2 lg:pl-56 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
            <NewLoader black />
            <p className="mt-1 text-center">Loading...</p>
        </div>
    )


    function tweetLink() {
        // Assuming userReferralLink is available in the scope
        // Template string for the Twitter share URL
        const tweetMessages = [
            "Just joined @symphonyos_! Check it out here:",
            "Joined @symphonyos_ recently. Check it out:",
            "Using @symphonyos_ to manage my music marketing! Join with my referral link:",
            "Just joined @symphonyos_, a platform for managing music careers:",
            "Giving @symphonyos_ a try for better fan engagement. Check it out:",
            "Exploring @symphonyos_ for music marketing. Check it out:",
            "Using @symphonyos_ to track and grow my audience. Here’s a link:",
            "Recently started with @symphonyos_, a platform for artists. Check it out:",
            "Looking at new marketing tools on @symphonyos_ :",
            "Trying out @symphonyos_ for some fresh marketing insights. Here’s the link:",
            "Just started using @symphonyos_ :",
            "Seeing what @symphonyos_ can do for music creators. Here's my referral link:",
            "Discovering what’s possible with @symphonyos_ :",
            "Working with @symphonyos_ to find new ways to reach fans. Details:",
            "Just signed up with @symphonyos_. Check out their marketing tools:",
            "Just tapped into @symphonyos_—a new layer of marketing for artists. See more:",
            "Joined @symphonyos_ to enhance my music marketing strategies. Check it out:",
            "Signed up for @symphonyos_ to explore new artist marketing tools. Check it out:",
            "Just got into @symphonyos_, exploring its potential for artists. Check it out:",
            "Diving deeper into @symphonyos_ for marketing insights. Join me:"
        ];

        // Select a random tweet message from the array
        const tweetIndex = Math.floor(Math.random() * tweetMessages.length)
        const tweetText = `${tweetMessages[tweetIndex]} ${userReferralLink}`;
        const twitterIntentUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;

        // Open the Twitter Web Intent in a new tab/window
        track('Clicked "Tweet Referral Link"', {
            message: tweetText,
            index: tweetIndex
        })
        window.open(twitterIntentUrl, '_blank');
    }

    const showShareButton = Boolean(navigator.share);

    function shareLink() {
        const link = userReferralLink;
        const tweetMessages = [
            "Just joined @symphonyos_! Check it out here:",
            "Joined @symphonyos_ recently. Check it out:",
            "Using @symphonyos_ to manage my music marketing! Join with my referral link:",
            "Just joined @symphonyos_, a platform for managing music careers:",
            "Giving @symphonyos_ a try for better fan engagement. Check it out:",
            "Exploring @symphonyos_ for music marketing. Check it out:",
            "Using @symphonyos_ to track and grow my audience. Here’s a link:",
            "Recently started with @symphonyos_, a platform for artists. Check it out:",
            "Looking at new marketing tools on @symphonyos_ :",
            "Trying out @symphonyos_ for some fresh marketing insights. Here’s the link:",
            "Just started using @symphonyos_ :",
            "Seeing what @symphonyos_ can do for music creators. Here's my referral link:",
            "Discovering what’s possible with @symphonyos_ :",
            "Working with @symphonyos_ to find new ways to reach fans. Details:",
            "Just signed up with @symphonyos_. Check out their marketing tools:",
            "Just tapped into @symphonyos_—a new layer of marketing for artists. See more:",
            "Joined @symphonyos_ to enhance my music marketing strategies. Check it out:",
            "Signed up for @symphonyos_ to explore new artist marketing tools. Check it out:",
            "Just got into @symphonyos_, exploring its potential for artists. Check it out:",
            "Diving deeper into @symphonyos_ for marketing insights. Join me:"
        ];

        // Select a random tweet message from the array
        const tweetIndex = Math.floor(Math.random() * tweetMessages.length)
        const tweetText = `${tweetMessages[tweetIndex]} ${userReferralLink}`;
        track('Clicked "Text Referral Link"', {
            message: tweetText,
            index: tweetIndex
        })

        navigator.share({
            title: "Get a 2-week free trial to SymphonyOS",
            text: tweetText,
            url: link,
        }).catch((error) => console.log('Error sharing', error));


    }





    const taskItems: TaskItem[] = [
        {
            icon: TweetLink, // Replace with your actual icon paths
            title: 'Tweet it out',
            description: 'We love hearing your feedback! Share a Symphony review with your referral link and earn commission.',
            actionName: 'Tweet',
            action: () => {
                tweetLink()
            }
        }
    ];

    if (showShareButton) {
        taskItems.push(
            {
                icon: TextIcon, // Replace with your actual icon paths
                title: 'Text it to friends',
                description: 'You can share your custom link anywhere - your friends get a 2 week free trial, and you get referral credits. Win-win 💜',
                actionName: showShareButton ? 'Share' : undefined,
                action: () => {
                    shareLink()
                }
            }
        )
    } else {
        const message = `Yo! I just joined Symphony Pro, they can help automate your marketing. Here's my referral link, you'll get a 2-week free trial, and it's only $20 / month. Check it out: ${userReferralLink}`
        taskItems.push(
            {
                icon: TextIcon, // Replace with your actual icon paths
                title: 'Text it to friends',
                description: 'You can share your custom link anywhere - your friends get a 2 week free trial, and you get referral credits. Win-win 💜',
                actionName: showShareButton ? 'Share' : 'Text',
                href: `sms:?body=${message}.`,
                action: () => {
                    track('Clicked "Text Referral Link"', {
                        message: message
                    })
                }
            }
        )
    }

    const mailToHref = `mailto:?subject=Join Symphony Pro&body=Hey! I just joined Symphony Pro and I think you should too. Here's my referral link, you'll get a 2-week free trial, and it's only $20 / month. Check it out: ${userReferralLink}.`
    taskItems.push(...[
        {
            icon: EmailLetter, // Replace with your actual icon paths
            title: 'Send it to your mailing list',
            description: 'Add your link to your newsletter and get signups when you blast out.',
            actionName: "Email your link",
            href: mailToHref,
            action: () => {
                track('Clicked "Email Referral Link"')
            }
        },
        {
            icon: LinkInBio, // Replace with your actual icon paths
            title: 'Post it on your story when sharing stats',
            description: 'Share Symphony\'s dashboard stats with your referral link to show your growth + get signups.',

        }
    ])

    const howItWorksCardData = [
        {
            title: "1. Sign up and get your link",
            description: "Get your referral link from your Symphony dashboard.",
            crossedOut: false,
            headerAlign: "center"
        },
        {
            title: "2. Share, post, and invite",
            description: "Share your link to anyone you know that could benefit from Symphony.",
            crossedOut: false,
            headerAlign: "center"
        },
        {
            title: "3. Earn 50% of their first year",
            description: "Get paid for every time someone subscribes to Symphony Pro from your link.",
            crossedOut: false,
            headerAlign: "center"
        }
    ];


    return (
        <MainContainer
            flexDirection={"row"}
            padding={setResponsiveView ? "0 0 92px 0" : "32px 80px"}
        >
            <ContentContainer>
                <FansContainer>
                    <CardTitle
                        title="Symphony Referral Program"
                        subtitle="Refer friends and get paid when you share your referral link."
                        mobile={setResponsiveView}
                        additionalContent={
                            <DefaultLearningIndicator
                                text="Learn how to refer & earn"
                                type={LearningIndicatorType.REFERRAL}
                            />}

                    />


                    <TabsContainer
                        borderRadius={setResponsiveView ? "0px" : "8px"}
                        padding={setResponsiveView ? "16px 8px" : "16px 24px"}
                    >

                        <TabsPanelsContainer>

                            <TabPanel hidden={selectedTab !== CALCULATOR}>
                                <Container display="flex" flexDirection="column" padding="16px" margin="0px auto 16px" gap="12px">
                                    <Container display="flex" flexDirection="column" gap="4px">
                                        <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>Your Referral Link</Headline2>
                                        <StyledLabel fontSize={16} color={SystemColors.SECONDARY_TEXT_COLOR}>
                                            Share this referral link with your friends - when they signup using it, you'll get credited for their subscription revenue.
                                        </StyledLabel>
                                    </Container>
                                    <Container display="flex" flexDirection="column" gap="8px">
                                        <LinkCopyShareComponent
                                            padding="8px 0px"
                                            link={userReferralLink} />
                                        <PrimaryButton
                                            width={mobileView ? '100%' : 'auto'}
                                            loading={loadingLink}
                                            disabled={loadingLink}
                                            onClick={openRewardfulDashboard}
                                            text="View your Earnings Dashboard" />
                                        {setResponsiveView && (
                                            <>
                                                <TextButton onClick={tweetLink}>
                                                    Tweet your Link
                                                </TextButton>

                                                {showShareButton && <TextButton onClick={shareLink}>
                                                    Text your Link
                                                </TextButton>}

                                                <TextButton onClick={() => {
                                                    track('Clicked "Email Referral Link"')
                                                    window.open(mailToHref)
                                                }}>
                                                    Email your Link
                                                </TextButton>
                                            </>)}
                                    </Container>
                                </Container>
                                <Divider />

                                <Container display="flex" flexDirection="column" padding="16px" margin="16px auto" gap="8px">
                                    <Container display="flex" flexDirection="column" gap="4px">

                                        <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>How It Works</Headline2>
                                        <LearningIndicator
                                            text="Frequently Asked Questions"
                                            onClick={() => {
                                                Intercom.openArticleWithId('7002468')
                                            }} />
                                    </Container>
                                    <Container display="flex" flexDirection={!setResponsiveView ? "row" : "column"} gap="8px">
                                        {howItWorksCardData.map((item) => {
                                            return (
                                                <Container
                                                    flex={1}
                                                    display="flex"
                                                    flexDirection="column"
                                                    padding="16px"
                                                    borderRadius="8px" backgroundColor="rgba(136, 0, 255, .08)">
                                                    <Body1 color={SystemColors.ACCENT_COLOR}>{item.title}</Body1>
                                                    <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>{item.description}</Body2>
                                                </Container>
                                            )
                                        })}

                                    </Container>

                                </Container>

                                <Divider />
                                <Container display="flex" flexDirection="column" padding="16px" margin="16px auto" gap="8px">
                                    <Headline2 color={SystemColors.PRIMARY_TEXT_COLOR}>Calculate your Potential Earnings</Headline2>
                                    <Container display="flex" flexDirection="row" gap="8px">
                                        <ReferralSlider />

                                    </Container>
                                </Container>


                            </TabPanel>
                        </TabsPanelsContainer>
                    </TabsContainer>
                </FansContainer>

            </ContentContainer>
            {!setResponsiveView && <TaskList tasks={taskItems} listTitle="Ideas on how to share your link" />}

        </MainContainer>
    );
};

export default ReferralProgram;
