import Dialog from "@material-ui/core/Dialog"
import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
} from "react"
import { useState } from "react"
import {
  CloseButtonContainer,
  CloseIconContainer,
  ModalConnectButton,
  ModalConnectButtonContainer,
  ModalConnectButtonLabel,
  ModalContainer,
  ModalMainTitleLabel,
  ModalPlatformInputContainer,
  ModalSubtitleLabel,
  ModalTitlesContainer,
  ModalTitlesLabelsContainer,
  PlatformLogoContainer,
  useStyles,
} from "../../../style"
import CloseIcon from "components/shareable/CloseIcon"
import { ReactComponent as TiktokIcon } from "assets/images/tiktok-bordered-icon.svg"
import { TextField, useMediaQuery, useTheme, InputAdornment } from "@material-ui/core"
import { getFormattedValue, checkIsValid } from "./utils"
import { OnboardingArtist } from "types/global"
import { getTiktokPicture, updateInBrand } from "./api"
import Lottie from 'react-lottie';
import SymphonyLoadingLottie from 'assets/images/lotties/SymphonyLoading.json';
import { track } from "analytics"
import { uploadProfilePicture } from "../InstagramBusinessDiscovery/utils"
import { useAuth0 } from "@auth0/auth0-react";
import gravatar from "gravatar";

interface Props {
  artist: OnboardingArtist | null
  selectedTiktokArtist: { url: string; username: string, picture?: string } | null
  selectTiktokArtist: (selectedTiktokArtist: { url: string; username: string, picture?: string }) => void
  open: boolean
  closeModal: () => void
  localSave?: boolean
  buttonLabel?: string,
}

const INVALID_URL_MESSAGE =
  "Looks like this TikTok username or URL is invalid…"

const TiktokConnect: FunctionComponent<Props> = ({
  artist,
  selectedTiktokArtist,
  selectTiktokArtist,
  open,
  closeModal,
  localSave,
  buttonLabel,
}: Props) => {
  const theme = useTheme();
  const { user } = useAuth0();
  const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState("")
  const [textFieldValue, setTextFieldValue] = useState<string>(
    selectedTiktokArtist?.username || ""
  )
  const [formattedValue, setFormattedValue] = useState(
    selectedTiktokArtist || null
  )

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const isValidUrl = checkIsValid(value, formattedValue)

    if (value.length) {
      const formatted = getFormattedValue(value)
      setFormattedValue(formatted)
      setError(!isValidUrl ? INVALID_URL_MESSAGE : "")
    }
    setTextFieldValue(value)
  }

  const handleOnClickConnectButton = async () => {
    setLoading(true)
    track("Linked TikTok")
    if (!localSave && artist && formattedValue) {
      const id = String(artist.id)
      const slug = String(artist.slug)
      await updateInBrand({ id, slug }, formattedValue)
    }
    if (formattedValue) { 
      let cloudinaryUrl = gravatar.url(user?.email as string, { protocol: 'https', d: 'retro' })
      //TODO: get tiktok picture
      /*try{ 
            const picture = await getTiktokPicture(formattedValue.username)
            cloudinaryUrl = await uploadProfilePicture(picture) as string
            if (cloudinaryUrl) {
                setFormattedValue({ ...formattedValue, picture: cloudinaryUrl })
            }
        } catch(e) {
            console.error("Error fetching Tiktok profile picture")
        }*/
      setFormattedValue({ ...formattedValue, picture: cloudinaryUrl })
      selectTiktokArtist({ ...formattedValue, picture: cloudinaryUrl })
    }
    closeModal()
    setLoading(false)
  }

  useEffect(() => {
    setTextFieldValue(selectedTiktokArtist?.username || "")
  }, [selectedTiktokArtist])

  return (
    <Dialog
      open={open}
      BackdropProps={{ className: classes.modalBackground }}
      classes={{ 
        root: classes.rootDialog,
        scrollPaper: classes.scrollPaper,
      }}
      fullScreen={setResponsiveView}
    >
      <ModalContainer 
        width={setResponsiveView ? "auto" : "678px"}
        margin={setResponsiveView ? "0px" : "auto"}
        height="100%"
        padding={setResponsiveView ? "16px" : "32px"}
        gap="32px"
      >
        <ModalTitlesContainer>
          <CloseButtonContainer>
            <CloseIconContainer onClick={closeModal}>
              <CloseIcon />
            </CloseIconContainer>
          </CloseButtonContainer>
          <ModalTitlesLabelsContainer>
            <ModalMainTitleLabel>Connect Tiktok</ModalMainTitleLabel>
            <ModalSubtitleLabel>
              What is the name or URL for {artist?.name || ""} Tiktok
              account?
            </ModalSubtitleLabel>
          </ModalTitlesLabelsContainer>
        </ModalTitlesContainer>
        <ModalPlatformInputContainer>
          <PlatformLogoContainer backgroundColor='#191919' width="48px" height="48px">
            <TiktokIcon width="32px" height="32px"/>
          </PlatformLogoContainer>
          <TextField
            InputProps={{
              classes: {
                root: classes.inputBorder,
              },
              endAdornment: loading ? (
                <InputAdornment position="end">
                  <Lottie 
                    height={24}
                    width={24}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: SymphonyLoadingLottie,
                    }}
                  />
                </InputAdornment>
              ) : undefined,
            }}
            value={textFieldValue}
            onChange={handleChangeTextField}
            className={classes.textField}
            fullWidth
            variant="outlined"
            placeholder="Bad Bunny, Madonna..."
            error={!!error}
            helperText={error}
            FormHelperTextProps={{
              classes: {
                root: classes.formHelperText,
              },
            }}
          />
        </ModalPlatformInputContainer>
        <ModalConnectButtonContainer>
          <ModalConnectButton
            disabled={
              !!loading ||
              !!error ||
              !textFieldValue ||
              textFieldValue === "" ||
              !checkIsValid(textFieldValue, formattedValue)
            }
            onClick={handleOnClickConnectButton}
          >
            <ModalConnectButtonLabel>{buttonLabel || "Connect"}</ModalConnectButtonLabel>
          </ModalConnectButton>
        </ModalConnectButtonContainer>
      </ModalContainer>
    </Dialog>
  )
}

export default TiktokConnect
