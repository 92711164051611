import styled, { CSSProperties, keyframes } from "styled-components"

interface MarqueeAareaProps {
  move: number
  time: number
  toRight?: boolean
}

interface MarqueeContainerProps {
  pauseOnHover?: boolean
  styles?: CSSProperties
}

const moveLeft = keyframes`
  from {
    transform: translateX(0);
  }
`

export const MarqueeContainer = styled.div<MarqueeContainerProps>`
  position: relative;
  width: ${({ styles }) => styles?.width || "100%"};
  height: 100%;
  margin-top: 20px;
  padding: 10px 0;
  background-color: white;
  overflow: hidden;
  &:hover {
    animation-play-state: ${({ pauseOnHover }) =>
      pauseOnHover ? "paused" : "running"};
  }
  &::after {
    position: absolute;
    content: "";
    width: ${({ styles }) => styles?.width || "100%"};
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
`

export const MarqueeArea = styled.div<MarqueeAareaProps>`
  display: inline-block;
  white-space: nowrap;
  transform: translateX(-${(props) => props.move}px);
  animation: ${moveLeft} ${(props) => props.time}s linear infinite
    ${(props) => (props.toRight ? " reverse" : "")};
  animation-play-state: inherit;
`

export const MarqueeItem = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 2em;
`
