import { FunctionComponent } from "react";
import { Chip as MaterialChip } from '@material-ui/core';
import useStyles from './styles';
import { SystemColors } from "types/globalStyles";

interface Props {
  children: string | string[]
  backgroundColor?: SystemColors
}

const Chip: FunctionComponent<Props> = ({
  children,
  backgroundColor = `${SystemColors.ACCENT_COLOR}08`
}) => {
  const classes = useStyles();

  return (
    <MaterialChip
      style={{ backgroundColor }}
      className={classes.chip}
      classes={{
        label: classes.chipLabelColor,
        colorPrimary: classes.chipBackgroundColor,
      }}
      color="primary"
      label={children}
    />
  )
}

export default Chip