
import React, { Component } from "react";

const dev = process.env.NODE_ENV !== 'production'

class CloudinaryUploadWidget extends Component<any> {
    widgetRef: any;

    constructor(props: any) {
        super(props);
        this.widgetRef = React.createRef();
    }

    componentDidUpdate() {
        const {
            uploadButtonId } = this.props

        var uploadButton = document.getElementById(uploadButtonId)

        var widgetRef = this.widgetRef
        if (uploadButton?.getAttribute('listener') !== 'true') {
            uploadButton?.setAttribute('listener', 'true');

            uploadButton?.addEventListener(
                "click",
                function () {

                    // added this to prevent ghost events from opening popup,
                    // for example once the element with uploadButtonId leaves the page
                    // (unsure why that happens)
                    if (document.getElementById(uploadButtonId)) {
                        if(widgetRef) widgetRef.current.open();

                    } else {
                        console.log(`no document with id ${uploadButtonId} found`)
                    }

                },
                false
            );


        }


    }
    componentDidMount() {
        const {
            onUploadAdded,
            onUploadSuccess,
            onUploadAbort,
            //onBatchCancelled,
            onUploadAbortAll,
            uploadButtonId,
            onCancel,
            resourceType,
            clientAllowedFormats,
            maxFiles,
            showUploadMoreButton,
            minImageWidth,
            maxFileSize
        } = this.props

        // if (!((window as any).createdCloudinary)) {
        var myWidget = (window as any).cloudinary.createUploadWidget(
            {
                cloudName: "symphonyos",
                uploadPreset: "unsigned-assets",
                maxFiles: maxFiles || 2,
                sources: ['local', 'url', 'camera'],
                resourceType: resourceType || "video",
                minImageWidth: minImageWidth || 500,
                clientAllowedFormats: clientAllowedFormats || ['video'],
                showPoweredBy: false,
                showCompletedButton: true,
                showUploadMoreButton: showUploadMoreButton || true,
                maxFileSize,
            },
            (error: any, result: any) => {
                if (error) {
                    onCancel([])

                } else {
                    // success event
                    // console.log('result.event', result.event)
                    if (result) {
                        if (result.event === 'upload-added') {
                            if(dev) console.log('upload added', result)
                            onUploadAdded(result.info.id)

                        } else if (result.event === 'success') {
                            if(dev)  console.log('successfully added upload', result)
                            onUploadSuccess(result)

                        } else if (result.event === 'abort') {
                            if(dev) console.log('aborted upload', result)
                            if (result.info.all) {
                                onUploadAbortAll()
                            } else {
                                onUploadAbort(result.info.ids[0])
                            }

                        } else if (result.event === 'batch-cancelled') {
                            if(dev)   console.log('batch cancelled upload', result)
                        }
                    }
                    // if (result && [
                    //     "abort",
                    //     "batch-cancelled"].indexOf(result.event) > -1) {
                    //     onCancel(result.info)
                    // } else if (result && result.event === "queues-end") {
                    //     const { files } = result.info

                    //     var updatedFiles = files.filter((o: any) => !o.aborted && !o.failed)

                    //     if (updatedFiles.length > 0) {

                    //         onUpload({
                    //             files: updatedFiles
                    //         })

                    //     } else {
                    //         onCancel(result.info)
                    //     }

                    // }
                }
            }
        );

        var uploadButton = document.getElementById(uploadButtonId)

        uploadButton?.setAttribute('listener', 'true');

        uploadButton?.addEventListener(
            "click",
            function () {
                // added this to prevent ghost events from opening popup,
                // for example once the element with uploadButtonId leaves the page
                // (unsure why that happens)
                if (document.getElementById(uploadButtonId)) {
                    myWidget.open();

                } else {
                    console.log(`no document with id ${uploadButtonId} found`)
                }

            },
            false
        );

        this.widgetRef.current = myWidget


        //     (window as any).createdCloudinary = true
        // }
    }

    render() {
        return null
    }
}

export default CloudinaryUploadWidget;
