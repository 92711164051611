import { Container, StyledLabel, TextButton } from "styles/shared";
import { CurrentBrand, OrganizationData, Tiers } from "types/global";
import { useContext, useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import BrandsListItem from "./BrandsListItem";
import { useHistory } from "react-router-dom";
import Axios from "helpers/Interceptor";
import ConfirmChangeSubscriptionModal from "./ConfirmChangeSubscriptionModal";
import { SHARED_TOAST_OPTIONS, SHARED_TOAST_OPTIONS_ERROR } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import { toast } from "react-toastify";
import { TeamPlanContext } from "../hooks/TeamMembersContext"

interface DisplayedBrand {
    name: string;
    image: string;
    slug: string;
    isPro: boolean;
    isLoading: boolean; // Initialize isLoading to false
    id: string;
}

export default function OrganizationFinish(props: {
    currentBrand: CurrentBrand;
    setCurrentBrand: (brand: CurrentBrand) => void;
    existingBrands: CurrentBrand[];
    organization: OrganizationData;
    reloadOrganization: () => void;
}) {
    const {
        existingBrands,
        reloadOrganization,
        currentBrand,
        setCurrentBrand
    } = props

    const history = useHistory();

    // total allocated seats - by default organization plans come with 5.
    // any additional seats are charged at $15 per profile.
    const [proAccounts, setProAccounts] = useState<number>(0)
    // const defaultBrands = defaultDisplayedBrands()
    const [displayedBrands, setDisplayedBrands] = useState<DisplayedBrand[]>([])
    const { teamArtists } = useContext(TeamPlanContext);

    // upgrade modal
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false)
    const [inProgressUpdatingBrandSlug, setInProgressUpdatingBrandSlug] = useState<{
        slug: string;
        status: 'upgrade' | 'downgrade'
    } | null>(null)

    // Selected Artist State
    function defaultDisplayedBrands(): DisplayedBrand[] {
        if (teamArtists.length && existingBrands.length) {
            let proCount = 0
            const filteredBrands = existingBrands.filter(({ id }: CurrentBrand) => {
                const isMemberOfTeam = teamArtists.some(artist => artist.id === id);
                return isMemberOfTeam;
            })
            const formattedBrands = filteredBrands.map((o: CurrentBrand) => {
                let selectedForPro = o.tier === Tiers.PRO
                if (o.tier === Tiers.PRO) proCount++
                return {
                    id: String(o.id),
                    name: o.name!,
                    image: o.image!,
                    slug: o.slug!,
                    isPro: selectedForPro,
                    isLoading: false // Initialize isLoading to false

                }
            })
            setProAccounts(proCount)
            return formattedBrands
        }
        return []
    }


    useEffect(() => {

        // when this slug is updated, we should open the modal
        if (inProgressUpdatingBrandSlug) {
            setShowUpgradeModal(true)
        }
    }, [inProgressUpdatingBrandSlug])

    useEffect(() => {
        if (!showUpgradeModal) {
            setInProgressUpdatingBrandSlug(null)
        }
    }, [showUpgradeModal])

    useEffect(() => {
        if (teamArtists.length && existingBrands.length) {
            setDisplayedBrands(defaultDisplayedBrands())
        }
    }, [existingBrands, teamArtists])

    // useEffect(() => {
        
    // }, [])


    /**
     * This lets you upgrade a brand to Pro within the organization - the backend handles
     * the billing and updates the status of the brand to Pro.
     * @param slug slug of brand that represents the upgrade
     */
    async function beginUpgradeToPro(slug: string) {
        // Find the brand and set its isLoading to true
        setInProgressUpdatingBrandSlug({
            slug: slug,
            status: 'upgrade'
        })
    }

    async function beginDowngradeToFree(slug: string) {
        // Find the brand and set its isLoading to true
        setInProgressUpdatingBrandSlug({
            slug: slug,
            status: 'downgrade'
        })
    }

    async function handleProceedWithSubscriptionAction() {
        setShowUpgradeModal(false)
        const slug = inProgressUpdatingBrandSlug?.slug

        // set the selected brand to isLoading state
        const updatedBrands = displayedBrands.map(brand => brand.slug === slug ? { ...brand, isLoading: true } : brand);
        setDisplayedBrands(updatedBrands);

        try {
            if (inProgressUpdatingBrandSlug?.status === 'upgrade') {
                await Axios.post('/organization/upgrade-to-pro', { brandSlug: slug });
                // Here you might want to update the brand's tier to PRO based on the result
            } else {
                await Axios.post('/organization/downgrade-to-free', { brandSlug: slug });
            }

            // Reset the isLoading state for the brand
            const resetLoadingBrands = displayedBrands.map(brand => brand.slug === slug ? {
                ...brand,
                isPro: inProgressUpdatingBrandSlug?.status === 'upgrade',
                isLoading: false
            } : brand);
            setDisplayedBrands(resetLoadingBrands);
            reloadOrganization()
            toast.success("Subscription successfully updated.", SHARED_TOAST_OPTIONS);
        } catch (error) {
            console.error(error);
            toast.error("There was an error enabling pro plan access for this user.", SHARED_TOAST_OPTIONS_ERROR);
        } finally {

        }
    }

    const styles: Record<string, React.CSSProperties> = {
        inputStyles: {
            'borderRadius': '6px',
            'border': '1px solid #ADB7BC',
            'height': '67px',
            'width': '100%',
            'margin': '8px 0px',
            padding: "24px 16px",
            maxWidth: '400px'
        },
        artistList: {
            maxHeight: '35vh',
            overflow: 'auto'
        }
    }


    async function changeSelectedBrand(brandId: string) {
        try {
            const response = await Axios.get(`/brand/${brandId}`)
            const newBrand = response.data.data as CurrentBrand
            setCurrentBrand(newBrand || currentBrand);
            history.push(`/`)

        } catch (error) {
            return null
        }
    }

    function renderSelectArtistList() {

        // Filter and map over brands selected for Pro
        return (
            <div style={styles.artistList}>
                {displayedBrands.map((brand: DisplayedBrand, idx: number) => {
                    // Find the brand in the proSelectedBrands array to get the correct proSeatIndex
                    return (
                        <BrandsListItem
                            onChangeBrand={(brandId: string) => {
                                // handleSelectBrand(brandId)
                                changeSelectedBrand(brandId)
                            }}
                            brandId={brand.id!}
                            slug={brand.slug!}
                            isLoading={brand.isLoading}
                            name={brand.name!}
                            isPro={brand.isPro}
                            image={brand.image!}
                            onClickUpgradeToPro={(slug: string) => {
                                beginUpgradeToPro(slug)

                            }}
                            onClickUpgradeToFree={(slug: string) => {
                                beginDowngradeToFree(slug)
                            }}
                        />
                    )

                })}
            </div>
        )
    }


    return (
        <Container display="flex" flexDirection="column" gap="8px">
            <Container display="flex" flexDirection="row"
                justifyContent="space-between">
                <StyledLabel
                    fontWeight={400}
                    fontSize={20}
                    lineHeight={'28px'}
                >
                    Your Profiles
                </StyledLabel>
                <TextButton onClick={() => {
                    history.push('/add-artist');

                }}>
                    Create New Profile
                </TextButton>
            </Container>
            <Container display="flex" flexDirection="column">
                <Divider />
                {renderSelectArtistList()}
                <Divider />

            </Container>
            <ConfirmChangeSubscriptionModal
                open={showUpgradeModal}
                type={inProgressUpdatingBrandSlug?.status}
                onClose={() => setShowUpgradeModal(false)}
                onConfirm={() => handleProceedWithSubscriptionAction()}
                proAccounts={proAccounts}
            />
        </Container>
    )
}