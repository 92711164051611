import { makeStyles } from "@material-ui/core";
import { Theme } from "@mui/material";

const useStyles = makeStyles((_theme: Theme) => ({
    textButton: {
        color: "#8800FF",
        fontFamily: 'DIN',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'initial',
        letterSpacing: '0em',
        textAlign: 'left',
    },
}))

export default useStyles;