import DialogPaper from "components/shareable/DialogPaper";
import { FunctionComponent } from "react";
import { ReactComponent as TadaIcon } from "assets/images/tada.svg"
import MusicStreamsIcon from "assets/images/campaigns/musicstreams.png"
import LinkClicksIcon from "assets/images/campaigns/linkclicks.png"
import HeaderModal from "components/connect/connectModals/Spotify/Components/HeaderModal";
import { ModalContainer, BodyContainer, CampaignCardIcon, CampaignCardBody, CampaignCard, CampaignIcon } from "./styles";
import { Body2, Subtitle2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { useHistory } from "react-router-dom";
import Intercom from "helpers/Intercom";
import MainConfetti from "../../../../../../../components/shareable/Confetti";

interface Props {
  open: boolean
  onClose: () => void
}

const FinishedConversionsSetupModal: FunctionComponent<Props> = ({
  open,
  onClose,
}: Props) => {

  const history = useHistory();

  const handleOnClickMusicStreams = () => {
    history.push('/marketing/new/music-streams');
  }

  const handleOnClickLinkClicks = () => {
    history.push('/marketing/new/link-clicks');
  }

  const handleIntercomPopup = (type: 'presaves' | 'tour' | 'merch') => {
    switch (type) {
      case 'presaves':
        Intercom.openPresavesConversionsArticle()
        break;
      case 'tour':
        Intercom.openTourConversionsArticle()
        break;
      case 'merch':
        Intercom.openMerchConversionsArticle()
        break;
    }
  }

  return (
    <DialogPaper open={open}>
      <ModalContainer>
        <HeaderModal
          closeModal={onClose}
          title="Congrats, you've setup Symphony Conversions!"
          subtitle="Start growing with our automated playbooks:"
          HeaderIcon={<TadaIcon />}
        />
        <BodyContainer>
          <CampaignCard onClick={handleOnClickMusicStreams}>
            <CampaignCardIcon>
              <CampaignIcon src={MusicStreamsIcon} alt="Music Streams" />
            </CampaignCardIcon>
            <CampaignCardBody>
              <Subtitle2>Increase Music Streams</Subtitle2>
              <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Grow music streams with Conversions-powered Instagram Ads - great for follower + streaming growth.</Body2>
            </CampaignCardBody>
          </CampaignCard>
          <CampaignCard onClick={handleOnClickLinkClicks}>
            <CampaignCardIcon>
              <CampaignIcon src={LinkClicksIcon} alt="Music Streams" />
            </CampaignCardIcon>
            <CampaignCardBody>
              <Subtitle2>Increase Link Clicks</Subtitle2>
              <Body2 color={SystemColors.SECONDARY_TEXT_COLOR}>Grow anything by driving Conversions-powered ads to a site with your selected pixel.</Body2>
            </CampaignCardBody>
          </CampaignCard>
        </BodyContainer>
        {/* <BodyContainer>
          <Body1>Learn how to use "Increase Link Clicks" to:</Body1>
          <ul>
            <li className="ml-2 mb-4">
              <button className="flex items-center hover:underline "
                onClick={() => handleIntercomPopup('presaves')}>
                <ImportContacts htmlColor="#80f" className="mr-2" />
                💾 Grow pre-saves with Conversions
              </button>
            </li>
            <li className="ml-2">
              <button className="flex items-center hover:underline "
                onClick={() => handleIntercomPopup('tour')}>
                <ImportContacts htmlColor="#80f" className="mr-2" />
                🎟️ Grow ticket sales with Conversions
              </button>
            </li>
            <li className="ml-2 mt-4">
              <button className="flex items-center hover:underline "
                onClick={() => handleIntercomPopup('merch')}>
                <ImportContacts htmlColor="#80f" className="mr-2" />
                👕 Grow purchases with Conversions
              </button>
            </li>
          </ul>

        </BodyContainer> */}
        <MainConfetti
          numberOfPieces={600}
        />
      </ModalContainer>
    </DialogPaper>
  );
}

export default FinishedConversionsSetupModal