import { makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  boostIcon: {
    width: '40px',
    height: '40px',
  },
}));

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 8px 0px;
  width: 100%;
`;

export const BoostDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  border: 1px solid #edecf2;
  border-radius: 12px;
`;

export const BoostTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: flex-start;
`;

export const BoostDetailDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const BoostTitle = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 19px;
`;

export const BoostSubtitle = styled.p`
  font-family: DIN;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
`;

export const BoostDetailCount = styled.p`
  font-family: DIN;
  font-size: 12px;
  line-height: 15px;
  color: #8800ff;
`;

export const BoostsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BoostIcon = styled.img`
  border-radius: 12px;
`;

export const ErrorBanner = styled.div`
  padding: 16px;
  border-radius: 8px;
  font-family: DIN;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  background: #ff000029;
`;

export const SpotifyIconContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
`;



export default useStyles;
