import styled from 'styled-components';
import { SystemColors } from 'types/globalStyles';

export const BadgeContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${SystemColors.PAPER_BACKGROUND_CONTAINER};
`;

export const EmptyIconContainer = styled.div`
  background-color: ${SystemColors.PAPER_DIVIDER_CONTAINER};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

export const EmptyIconCenter = styled.div`
  background-color: ${SystemColors.PAPER_DIVIDER_CONTAINER_DARK};
  border-radius: 50%;
  width: 8px;
  height: 8px;
`;