import { Body1, Headline1 } from "components/shareable/Typography";
import { Container, StyledLabel } from "styles/shared";
import { SystemColors } from "types/globalStyles";
import Card from "./Card";
import { useContext, useEffect, useState } from "react";
import Axios from "helpers/Interceptor";
import Loaderv2 from "components/Loader/v2";
import { CAMPAIGN_TYPE_MAPPINGS } from "pages/post-auth/MarketingPage/utils/marketing";
import { track } from "analytics";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { CardContainer } from "../styles";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import '../../../../components/dashboard/swiper.css';
import { Autoplay, Navigation, Pagination } from "swiper";
import VideoModalStacked from "components/shareable/VideoModalStacked";
import { motion } from "framer-motion"
import CompleteFanbaseIcon from "assets/images/gettingStarted/completeYourFanbase.png"
import CustomizeWebsiteIcon from "assets/images/gettingStarted/customizeYourWebsite.png"
import InviteYourTeamIcon from "assets/images/gettingStarted/inviteYourTeam.png"
import StartCampaignIcon from "assets/images/gettingStarted/startCampaign.png"
// import GrowYoutubeIcon from "assets/images/gettingStarted/growYoutubeEngagements.png"
import GrowYoutubeIcon from "assets/images/campaigns/youtube.png"
import DollarSignIcon from "assets/images/gettingStarted/dollar_sign.svg"
import MusicSiteIcon from 'assets/images/websiteThemes/music-site.svg';
import DataCollectIcon from 'assets/images/websiteThemes/data-collector-site.svg';
import PresaveIcon from 'assets/images/websiteThemes/presave-site.svg';

import InstagramEngagement from "assets/images/gettingStarted/InstagramEngagement.svg"
import useMediaBreakpoints from "Hooks/useMediaBreakpoints";
import { v4 } from "uuid";
import { CurrentUserContext } from "Hooks/CurrentUserContext";
import PrimaryButton from "components/shareable/PrimaryButton";
import { toast } from "react-toastify";
import { SHARED_TOAST_OPTIONS } from "pages/post-auth/MarketingPage/Components/Modals/utils";
import clsx from "clsx";

enum CAMPAIGN_KEYS {

}
export const KEY_MAPPING: Record<string, {
    icon: string;
    fullSizeIcon?: boolean;
    color?: string;
    badge?: 'Instagram Ads' | 'Website' | 'Pre-save Website' | 'Data Collector Website';
}> = {
    'link_clicks': {
        icon: CustomizeWebsiteIcon,
        badge: 'Website'
    },
    'getting_started': {
        icon: 'https://symphonyos-public-assets.s3.us-east-2.amazonaws.com/images/thumbnails/conversions.jpg',
        color: '#fbd7cc',
        fullSizeIcon: true
    },
    'refer': {
        icon: DollarSignIcon
    },
    'data_collector': {
        fullSizeIcon: false,
        icon: DataCollectIcon,
        badge: 'Data Collector Website'
    },
    'music_site': {
        fullSizeIcon: false,
        icon: MusicSiteIcon
    },
    'record_streams': {
        fullSizeIcon: false,
        icon: StartCampaignIcon,
        badge: 'Instagram Ads'
    },
    'pre_save': {
        fullSizeIcon: false,
        icon: PresaveIcon,
        badge: 'Pre-save Website'
    },
    'boost_ig_engagement': {
        fullSizeIcon: false,
        icon: InstagramEngagement,
        badge: 'Instagram Ads'
    },
    'complete_fanbase': {
        icon: CompleteFanbaseIcon
    },
    'marketing_tab': {
        icon: StartCampaignIcon
    },
    'website_tab': {
        icon: MusicSiteIcon
    },
    'schedule_call': {
        icon: GrowYoutubeIcon
    },
    'schedule_Call': {
        icon: GrowYoutubeIcon
    }
}
interface Recommendation {
    // for all
    preset: "video" | "copy" | "custom"
    title: string;
    text: string;
    description: string;
    completed: boolean;

    size: '1/2' | '1/3';

    icon: string;
    bgColor?: string;
    fullSizeIcon?: boolean;

    // for custom links
    key?: string;
    url?: string;
    playbook?: string;

    // for video
    videoDetails?: {
        cardImage: string;
        cardImageAlt: string;
        cardTitle: string;
        cardDescription: string;
        videoUrl: string;
        cardButton: string;
    }

    // for copy
    copyDetails?: {
        copyUrl: string;
    }
}

interface RecommendationsProps {
    brandSlug: string;
    showDashboardAction: () => void;
    isAdmin?: boolean;
}



const RecommendationsContainer = styled(CardContainer)`
    background-color: #E7D5F7;
    display: flex;
    flex-direction:column;
    gap: 18px;
    max-width: 1200px;
  
`

const ArrowBtn = styled.button`
    width: 40px;
    height: 40px;
    background: white;
    border: 1px solid #E7D5F7;
    z-index: 99;
    top: 50%;
    border-radius: 99px;
    left: 0%;
    transform: translateY(-50%);
    text-align:center;
`

const RightArrowBtn = styled(ArrowBtn)`
    right: 0%;
    left: unset;
    `
const SwiperNavParent = styled.div`
    position: absolute;
    top: -10%;

`

function SwiperNavButtons() {
    const swiper = useSwiper();

    return (
        <SwiperNavParent>
            <ArrowBtn onClick={() => swiper.slidePrev()}>Prev</ArrowBtn>
            <RightArrowBtn onClick={() => swiper.slideNext()}>Next</RightArrowBtn>
        </SwiperNavParent>
    );
}

export default function Recommendationsv1UI({
    brandSlug,
    isAdmin,
    showDashboardAction,
}: RecommendationsProps) {

    const { smallView } = useMediaBreakpoints()
    const [loadingRecommendations, setLoadingRecommendations] = useState<boolean>(true)
    const [recommendations, setRecommendations] = useState<Recommendation[]>([])

    // tracks if a user has viewed the recommendations
    const [trackedView, setTrackedView] = useState<boolean>(false);
    // used for video player popups
    const [openPopup, setOpenPopup] = useState<{
        open: boolean;
        title: string;
        description: string;
        videoUrl: string;
    }>({
        open: false,
        title: "",
        description: "",
        videoUrl: "",
    });

    // for Admin View
    const [isLoadingResetOnboarding, setIsLoadingResetOnboarding] = useState<boolean>(false);

    const history = useHistory()

    async function getRecommendations() {
        setLoadingRecommendations(true)
        try {
            const response = await Axios.get(`/brand/${brandSlug}/recommendations`);
            const { data } = response.data; // Assuming this is the structure based on your example
            console.log('data', data)
            const recos: Recommendation[] = data.recommendations.map((reco: any) => {

                switch (reco.preset.toLowerCase()) {
                    case "custom": {
                        const campaignUrl = reco.key && CAMPAIGN_TYPE_MAPPINGS[reco.key]?.route;
                        if (!campaignUrl) return null;
                        return {
                            preset: reco.preset,
                            title: reco.type,
                            playbook: reco.type,
                            text: reco.description,
                            url: campaignUrl,
                            completed: reco.completed,
                            icon: KEY_MAPPING[reco.key]?.icon,
                            bgColor: KEY_MAPPING[reco.key]?.color,
                            size: '1/3',
                            fullSizeIcon: KEY_MAPPING[reco.key]?.fullSizeIcon,
                            badge: KEY_MAPPING[reco.key]?.badge
                        };
                    }

                    case "action": {
                        let onClick;
                        switch (reco.key.toLowerCase()) {
                            case 'marketing_tab':
                                onClick = () => {

                                    history.push(`/marketing`)
                                }

                                break;
                            case 'schedule_call':
                                onClick = () => {

                                    window.open('https://calendly.com/symphony-intro/30min?month=2024-03', '_blank')
                                }

                                break;
                            case 'website_tab':
                                onClick = () => {

                                    history.push(`/website`)
                                }
                                break;
                            case 'data_collector': {

                                onClick = () => {
                                    // Funnel item 2: click on a recommendation
                                    const slug = v4()
                                    history.push(`/website/data_collector/${slug}`)
                                }
                                break
                            }

                            case 'refer': {

                                onClick = () => {
                                    // Funnel item 2: click on a recommendation
                                    history.push(`/refer`)
                                }
                                break
                            }
                            case 'complete_fanbase': {
                                onClick = () => {
                                    // Funnel item 2: click on a recommendation
                                    showDashboardAction()
                                }
                                break
                            }
                            default:
                                return null;
                        }

                        return {
                            preset: reco.preset,
                            title: reco.title,
                            playbook: reco.type,
                            text: reco.description,
                            onClick: onClick,
                            completed: reco.completed,
                            icon: KEY_MAPPING[reco.key]?.icon,
                            bgColor: KEY_MAPPING[reco.key]?.color,
                            size: '1/3',
                            fullSizeIcon: KEY_MAPPING[reco.key]?.fullSizeIcon,
                            badge: KEY_MAPPING[reco.key]?.badge
                        };
                    }
                    case "copy": {
                        let onClick = () => {

                            // Funnel item 2: click on a recommendation
                            history.push(`/refer`)
                        }
                        return {
                            preset: reco.preset,
                            title: reco.title,
                            text: reco.description,
                            completed: reco.completed,
                            copyDetails: {
                                copyUrl: reco.copyUrl,
                            },
                            onClick,
                            icon: KEY_MAPPING[reco.key]?.icon,
                            bgColor: KEY_MAPPING[reco.key]?.color,
                            size: '1/2',
                            fullSizeIcon: KEY_MAPPING[reco.key]?.fullSizeIcon,
                            badge: KEY_MAPPING[reco.key]?.badge
                        };
                    }
                    case "video": {
                        return {
                            preset: reco.preset,
                            title: reco.cardTitle,
                            text: reco.cardDescription,
                            completed: reco.completed,
                            videoDetails: {
                                cardImage: reco.cardImage,
                                cardImageAlt: reco.cardImageAlt,
                                cardTitle: reco.cardTitle,
                                cardDescription: reco.cardDescription,
                                videoUrl: reco.videoUrl,
                                cardButton: reco.cardButton,
                            },
                            icon: KEY_MAPPING[reco.key]?.icon,
                            bgColor: KEY_MAPPING[reco.key]?.color,
                            size: '1/3',
                            fullSizeIcon: KEY_MAPPING[reco.key]?.fullSizeIcon,
                            badge: KEY_MAPPING[reco.key]?.badge
                        };
                    }
                    default:
                        return null;
                }
            }).filter((reco: Recommendation) => reco !== null); // Filter out any null values if presets are not matched


            setRecommendations(recos);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingRecommendations(false);
        }
    }

    useEffect(() => {

        // This is a dummy API call to get the data
        getRecommendations()

    }, []);

    useEffect(() => {
        if (recommendations && !trackedView) {
            setTrackedView(true);

            // Funnel item 1: view recommendations
            track('Recommendations Viewed', {})
        }
    }, [recommendations])

    function renderCards() {
        if (loadingRecommendations) {
            return (
                <Loaderv2 />
            )
        } else {
            // Group recommendations into pairs if in smallView
            const groupedRecommendations: any[][] = smallView
                ? recommendations.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 2);

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                    }

                    resultArray[chunkIndex].push(item);

                    return resultArray;
                }, [] as any[]) // Provide initial value as an empty array
                : recommendations.map(reco => [reco]); // Wrap each item in an array for uniform processing



            const recommendationSlides = groupedRecommendations.map((group, groupIndex) => {
                const cards = group.map((reco, index) => {

                    function onClick() {
                        // Funnel item 2: click on a recommendation
                        track('Recommendation Clicked', {
                            title: reco.title,
                            playbook: reco.playbook,
                            completed: reco.completed,
                        });

                        // Ensure we only navigate for types without a specific URL action (e.g., video might play inline)
                        if (reco.url && (reco.preset === "custom")) {

                            if (reco.key === "data_collector") {
                                const slug = v4()
                                history.push(`/website/data_collector/${slug}`)
                            } else {
                                history.push(reco.url);
                            }
                        } else if (reco.preset === "video") {
                            setOpenPopup({
                                open: true,
                                title: reco.videoDetails?.cardTitle || reco.title,
                                description: reco.videoDetails?.cardDescription || reco.text,
                                videoUrl: reco.videoDetails?.videoUrl || "" // Default URL
                            });
                        } else if (reco.onClick) {
                            reco.onClick()
                        }
                    }

                    let url = reco.url; // Default URL


                    return {
                        size: reco.size,
                        element: (
                            // <SwiperSlide

                            //     style={{
                            //         width: smallView ? '100%' : reco.size === '1/2' ? '40%' : '30%',
                            //     }}>
                            //     <motion.div
                            //         initial={{ opacity: 0, y: '-100%' }}
                            //         animate={{ opacity: 1, y: '0%' }}
                            //         transition={{ duration: 1, delay: index * 0.5 }}
                            //         style={{
                            //             height: '100%'
                            //         }}

                            //     >
                            <Card
                                badge={reco.badge}
                                key={index}
                                icon={reco.icon}
                                iconBg={reco.bgColor}
                                fullSizeIcon={reco.fullSizeIcon}
                                preset={reco.preset}
                                title={reco.title || reco.playbook} // Fallback to playbook if title is not available
                                description={reco.text}
                                onClick={onClick}
                                videoDetails={reco.preset === "video" ? {
                                    cardImage: reco.videoDetails?.cardImage,
                                    videoUrl: reco.videoDetails?.videoUrl,
                                } : undefined}
                                copyDetails={reco.preset === "copy" ? {
                                    copyUrl: reco.copyDetails?.copyUrl,
                                } : undefined}
                            />
                            // </motion.div>
                            // </SwiperSlide>
                        )
                    }
                })

                // For smallView, wrap cards in a Container; otherwise, return individual SwiperSlides
                return false ? (
                    <SwiperSlide key={groupIndex} style={{ width: '100%' }}>
                        <Container display="flex" flexDirection="column" gap="16px">
                            {cards.map(card => card.element)}
                        </Container>
                    </SwiperSlide>
                ) : (
                    cards.map(card => <SwiperSlide key={groupIndex} style={{
                        width: card.size === '1/2' ? '40%' : '30%',
                    }}>{card.element}</SwiperSlide>)
                );
            })

            return (
                <Swiper

                    loop={true}
                    slidesPerView={smallView ? 1 : 'auto'}
                    spaceBetween={smallView ? 15 : 30}
                    direction={"horizontal"}

                    navigation={true}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                        type: 'fraction',
                    }}
                    modules={[Navigation, Autoplay]}
                    className="mySwiper"
                    style={{
                        paddingTop: !smallView ? '50px' : undefined,
                        transform: !smallView ? 'translateY(-50px)' : undefined,
                        marginBottom: !smallView ? '-50px' : undefined
                    }}
                >

                    {recommendationSlides}
                    <SwiperNavButtons />

                </Swiper>

            )

        }
    }

    async function resetOnboardingAnswers() {
        try {
            setIsLoadingResetOnboarding(true);
            const resetAnswers = await Axios.get('/user/me/reset-onboarding-answers')

            const {
                data

            } = resetAnswers.data
            toast.success("Onboarding answers reset successfully.", SHARED_TOAST_OPTIONS);
        } catch (error) {
            toast.error("Error resetting onboarding answers.", { autoClose: 5000 });
            console.error(error)
        } finally {
            setIsLoadingResetOnboarding(false);
        }
    }


    return (
        <>
            <RecommendationsContainer
                className={clsx(window.innerWidth <= 1200 ? 'w-full md:my-3' : 'md:my-3 lg:w-9/12 md:my-3')}

                style={{
                    padding: smallView ? '24px' : '32px',
                }}>

                <Container display="flex"
                    flexDirection="column">
                    <StyledLabel fontWeight={500} fontSize={24}>Welcome to SymphonyOS!</StyledLabel>
                    <Body1>Here are some initial playbooks to get started.</Body1>
                </Container>

                <Container
                    display="flex"
                    flexDirection="row" gap="16px">

                    {renderCards()}
                </Container>

                {isAdmin && (
                    <PrimaryButton
                        loading={isLoadingResetOnboarding}
                        disabled={isLoadingResetOnboarding}
                        text="Reset Onboarding Answers" onClick={resetOnboardingAnswers} />)}

            </RecommendationsContainer>

            {openPopup.open && (<VideoModalStacked
                open={openPopup.open}
                closeModal={() => setOpenPopup({
                    open: false,
                    title: "",
                    description: "",
                    videoUrl: "",

                })}
                title={openPopup.title}
                subtitle={openPopup.description}
                videoUrl={openPopup.videoUrl}
            />)}
        </>
    );
}