import { FunctionComponent } from "react";
import { StyledLabel, Icon } from "../../styles";
import { CircularContainer } from "./styles";

interface Props {
  step?: number | string;
  icon?: {
    alt?: string;
    src?: string;
    className?: string;
  };
}

const CircularStep: FunctionComponent<Props> = ({ step, icon }: Props) => (
  <CircularContainer>
    {icon && (
      <Icon className={icon?.className} alt={icon?.alt} src={icon?.src} />
    )}
    {!icon && (
      <StyledLabel color="#ffffff" fontSize={18} fontWeight={400}>
        {step}
      </StyledLabel>
    )}
  </CircularContainer>
);

export default CircularStep;
