import { FunctionComponent } from "react"
import { InfoBanner } from "./styles"
import useStyles, { Card, StyledLabel, Icon } from "../../styles"
import Grid from "@material-ui/core/Grid"
import { VideoAdsRecord } from "types/global"
import YoutubeIcon from "assets/images/streaming/icons/youtube-logo.svg"
import { VideoGeographyTypes, TiktokCampaignTypes } from "../../../constants"
import clsx from "clsx"
import { useMediaQuery, useTheme } from "@material-ui/core"

interface Props {
  geographyType: string,
  link: string
}

interface CampaignTypeBannerProps {
  hideIcons?: boolean
  marginLeft?: string
}

const getVideoTargetingType = (targetingType?: string) => {
  const found = TiktokCampaignTypes.find(({ id }) => id === targetingType)
  return found ? found.name : ""
}

const getVideoGeographyType = (geographyType?: string) => {
  const found = VideoGeographyTypes.find(({ id }) => id === geographyType)
  return found ? found.name : ""
}

const CampaignTypeBanner: FunctionComponent<CampaignTypeBannerProps> = ({
  hideIcons,
  marginLeft,
}: CampaignTypeBannerProps) => (
  <InfoBanner
    {...{ marginLeft }}
    width={hideIcons ? "220px" : undefined}
    minWidth={hideIcons ? "140px" : "244px"}
  >
    {!hideIcons && (
      <Grid>
        <Icon width={20} height={20} alt="spotify-icon.svg" src={YoutubeIcon} />
      </Grid>
    )}
    <Grid>
      <StyledLabel
        textAlign={hideIcons ? "center" : "right"}
        fontSize={12}
        fontWeight={400}
      >
        Run TikTok Ads
      </StyledLabel>
    </Grid>
  </InfoBanner>
)

const HeadCard: FunctionComponent<Props> = ({
  geographyType,
  link,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const hideIcons = useMediaQuery(theme.breakpoints.down(395))
  const isResponsive = useMediaQuery(theme.breakpoints.down(621))
  // const foundVideoCampaignType = TiktokCampaignTypes.find(
  //   (o) => o.id === targetingType
  // )
  // const { id: videoCampaignId } = foundVideoCampaignType || {}
  const videoGeographyType = getVideoGeographyType(geographyType)
  // const videoTargetingType = getVideoTargetingType(targetingType)

  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container direction="column">
            <StyledLabel fontSize={18} fontWeight={600}>
              Review Campaign Details
            </StyledLabel>
            <StyledLabel
              fontSize={16}
              fontWeight={400}
              color="#707070"
              marginTop="8px"
            >
              Review your details to make sure everything looks good before we
              submit your campaign to TikTok.
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.space, classes.border)}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={600}>
              Link
            </StyledLabel>
          </Grid>
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={400}>
              {link}
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid
          className={classes.space}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={600}>
              Locations
            </StyledLabel>
          </Grid>
          <Grid item>
            <StyledLabel fontSize={16} fontWeight={400}>
              {videoGeographyType}
            </StyledLabel>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HeadCard
