import { FunctionComponent, SetStateAction, Dispatch, useRef, Fragment } from "react";
import useStyles, { Card, StyledLabel } from "../../styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import CircularStep from "../CircularStep";
import { CurrentBrand, SelectedTikTokSparkAd, VideoAdsRecord } from "types/global";
import { useHistory } from "react-router-dom";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import {
  ButtonCheckoutContainer,
  ButtonContainer,
  StripeButton,
  SubmitButton,
} from "./styles";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { createPayPalOrder, redirectToCheckout } from "../../api";
import { useTheme, useMediaQuery } from "@material-ui/core"
import { formatCountries } from "../../../utils";
import Axios from "helpers/Interceptor";

const payPalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

type PayPalOrder = {
  orderId: string | null;
  campaignId: string | null;
};

interface Props {
  campaignId: string | null
  startDate: string | Date | null;
  endDate: string | Date | null;
  budget: number;
  brand: CurrentBrand;
  creatingCampaign?: boolean;
  sparkCode: string | null;

  targeting: VideoAdsRecord[];
  selectedCountries: { label: string; value: string }[];
  // targetingType: string;
  link: string;
  geographyType: string;
  onCancelPayPalOrder?: () => void;
  onErrorPayPalOrder?: () => void;
  setCreatingCampaign: Dispatch<SetStateAction<boolean>>;
  isProUser: boolean;
  selectedPosts: SelectedTikTokSparkAd[];
}

const GoLiveCard: FunctionComponent<Props> = ({
  campaignId,
  startDate,
  endDate,
  budget,
  brand,
  sparkCode,
  creatingCampaign,
  selectedCountries,
  targeting,
  // targetingType,
  link,
  geographyType,
  onCancelPayPalOrder,
  onErrorPayPalOrder,
  setCreatingCampaign,
  isProUser,
  selectedPosts

}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768))
  const payPalOrder = useRef<PayPalOrder>({ orderId: null, campaignId: null });

  const handleClickStripeButton = () => {
    const data = {
      campaign: {
        id: campaignId,
        campaign_type: "tiktok_ads",
        price_data: {
          price: budget,
          name: `TikTok Ads Campaign (${geographyType})`,
          description: `${link}`,
        },
        logistics: {
          budget: budget,
          startDate: startDate,
          endDate: endDate,
        },
        content: {
          sparkCode: sparkCode,
          link: link
        },
        targeting: targeting,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["tiktok"],
      },
    };

    setCreatingCampaign(true);
    redirectToCheckout({ brand, sparkCode, data });
  };

  const onPayPalOrderApprove = async (
    _data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    const { campaignId, orderId } = payPalOrder.current;
    const url = `/marketing/success?campaign_id=${campaignId}&session_id=${orderId}`;

    history.push(url);
  };

  const createOrder = async () => {
    const data = {
      id: campaignId,
      campaign_type: "tiktok_ads",
      creatives: selectedPosts,
      price_data: {
        price: budget,
        name: `TikTok Ads Campaign (${geographyType})`,
        description: `${link}`,
      },
      logistics: {
        budget: budget,
        startDate: startDate,
        endDate: endDate,
      },
      content: {
        sparkCode: sparkCode,
        link: link
      },
      targeting: targeting,
      geographies: {
        type: geographyType,
        countries: formatCountries(geographyType, selectedCountries),
      },
      campaign_platforms: ["tiktok"],
      payment_method: "paypal"
    };

    setCreatingCampaign(true);

    const order = await Axios.post(`/campaign/brand/${brand.id}/tiktok`, {
      campaign_input: {
        ...data
      }
    });
    console.log('order', order)

    return order;
  };



  return (
    <Card borderRadius={isMobile ? undefined : "12px"}>
      <Grid container direction="column">
        <Grid item className={clsx(classes.space, classes.border)}>
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <CircularStep step="⚡" />
            </Grid>
            <StyledLabel fontSize={18} fontWeight={600}>
              Let’s go live
            </StyledLabel>
          </Grid>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingBottom)}>
          <StyledLabel color="#707070" fontSize={16} fontWeight={400}>
            Your marketing campaign may take up to 48 hours to go live. You will
            be redirected to a payment page to finish checkout.
          </StyledLabel>
        </Grid>
        <Grid item className={clsx(classes.space, classes.noPaddingTop)}>
          <Grid container direction="column">
            {isProUser && (
              <ButtonCheckoutContainer>
                <SubmitButton onClick={createOrder}>
                  Submit Campaign
                </SubmitButton>
              </ButtonCheckoutContainer>
            )}
            {!isProUser && (
              <Fragment>
                <ButtonCheckoutContainer>
                  <StripeButton
                    onClick={handleClickStripeButton}
                  // disabled={
                  //   error ? !error : creatingCampaign || loadingStep !== 3
                  // }
                  >
                    Checkout with credit or debit card
                  </StripeButton>
                </ButtonCheckoutContainer>

              </Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GoLiveCard;
