import React from 'react';
import { IconHolder, SocialIconProps } from './styles';

export const DeezerIcon: React.FC<SocialIconProps> = ({ rounded, borderColor, backgroundColor, iconColor, width = "40", height = "40", cursor, onClick }) => {
    return (
        <IconHolder rounded={rounded} backgroundColor={backgroundColor} borderColor={borderColor} cursor={cursor} onClick={() => { onClick && onClick() }}>
            <svg width="30" height="30" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.1013 12.5344H23.9492V14.9711H28.1013V12.5344Z" fill="#40AB5D" />
                <path d="M28.1013 15.9128H23.9492V18.3495H28.1013V15.9128Z" fill="url(#paint0_linear_1149_17641)" />
                <path d="M28.1013 19.2912H23.9492V21.7279H28.1013V19.2912Z" fill="url(#paint1_linear_1149_17641)" />
                <path d="M13.0515 22.6696H8.89941V25.1063H13.0515V22.6696Z" fill="url(#paint2_linear_1149_17641)" />
                <path d="M18.0651 22.6696H13.9131V25.1063H18.0651V22.6696Z" fill="url(#paint3_linear_1149_17641)" />
                <path d="M23.0876 22.6696H18.9355V25.1063H23.0876V22.6696Z" fill="url(#paint4_linear_1149_17641)" />
                <path d="M28.1013 22.6696H23.9492V25.1063H28.1013V22.6696Z" fill="url(#paint5_linear_1149_17641)" />
                <path d="M23.0876 19.2912H18.9355V21.7279H23.0876V19.2912Z" fill="url(#paint6_linear_1149_17641)" />
                <path d="M18.0651 19.2912H13.9131V21.7279H18.0651V19.2912Z" fill="url(#paint7_linear_1149_17641)" />
                <path d="M18.0651 15.9128H13.9131V18.3495H18.0651V15.9128Z" fill="url(#paint8_linear_1149_17641)" />
                <defs>
                    <linearGradient id="paint0_linear_1149_17641" x1="26.0456" y1="18.3705" x2="26.0063" y2="15.877" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#358C7B" />
                        <stop offset="0.5256" stop-color="#33A65E" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_1149_17641" x1="23.8882" y1="21.6028" x2="28.1696" y2="19.4279" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#222B90" />
                        <stop offset="1" stop-color="#367B99" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_1149_17641" x1="8.90017" y1="23.8889" x2="13.0488" y2="23.8889" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF9900" />
                        <stop offset="1" stop-color="#FF8000" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_1149_17641" x1="13.9177" y1="23.8889" x2="18.0663" y2="23.8889" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FF8000" />
                        <stop offset="1" stop-color="#CC1953" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_1149_17641" x1="18.9344" y1="23.8889" x2="23.0829" y2="23.8889" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CC1953" />
                        <stop offset="1" stop-color="#241284" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_1149_17641" x1="23.947" y1="23.8889" x2="28.0956" y2="23.8889" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#222B90" />
                        <stop offset="1" stop-color="#3559A6" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_1149_17641" x1="18.7706" y1="21.1665" x2="23.2488" y2="19.861" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CC1953" />
                        <stop offset="1" stop-color="#241284" />
                    </linearGradient>
                    <linearGradient id="paint7_linear_1149_17641" x1="13.77" y1="19.6989" x2="18.2171" y2="21.3126" gradientUnits="userSpaceOnUse">
                        <stop offset="0.00266984" stop-color="#FFCC00" />
                        <stop offset="0.9999" stop-color="#CE1938" />
                    </linearGradient>
                    <linearGradient id="paint8_linear_1149_17641" x1="14.2664" y1="15.5341" x2="17.7273" y2="18.7179" gradientUnits="userSpaceOnUse">
                        <stop offset="0.00266984" stop-color="#FFD100" />
                        <stop offset="1" stop-color="#FD5A22" />
                    </linearGradient>
                </defs>
            </svg>
        </IconHolder>
    );
};
