import Axios from 'helpers/Interceptor';
import { Artist, ArtistPermission, MemberRole, TeamPricesData } from '../../types/global'

interface AddNewMember {
  member: {
    name: string;
    lastName: string;
    email: string;
    role: MemberRole;
  },
  brands: {
    id: number;
    permissionLevel: ArtistPermission;
  }[]
}

interface UpdateMemberPermissions {
  memberId: number
  addedArtists: {
    brandId: number;
    permission: ArtistPermission;
  }[]
  editedArtists: {
    brandId: number;
    permission: ArtistPermission;
  }[]
  removedArtists: number[]
}

interface GetSimulationPrices {
  typeSimulation: 'add-seat' | 'remove-seat'
}

export const getOrganizationMembers = async () => {
  try {
    const response = await Axios.get(`/organization/members`);
    if (!response?.data?.data)
      throw new Error('Error fetching organization members');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

export const getStripeTeamPlanCosts = async () => {
  //TODO: return data from endpoint service
  return {
    priceExtraSeat: 5,
    priceExtraSeatRemaining: 5,
    remainingDays: 10,
  }
};

export const removeOrganizationMember = async (memberId: number) => {
  try {
    const response = await Axios.delete(`/organization/member/${memberId}`);
    if (!response?.data?.data)
      throw new Error('Error removing organization member');
    return response.data.data;
  } catch (error) {
    return null;
  }
};


export const getOrganizationBrands = async (): Promise<Artist[] | null> => {
  try {
    const response = await Axios.get(`/organization/brands`);
    if (!response?.data?.data)
      throw new Error('Error fetching organization brands');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export const getSimulationPrices = async ({ typeSimulation }: GetSimulationPrices): Promise<TeamPricesData | null> => {
  try {
    const response = await Axios.get(`/organization/simulation-prices/${typeSimulation}`);
    if (!response?.data?.data)
      throw new Error('Error fetching extra prices');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export const addNewMember = async (data: AddNewMember) => {
  try {
    const response = await Axios.post(`/organization/new-member`, data);
    if (!response?.data?.data)
      throw new Error('Error adding member');
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export const updateMemberPermissions = async (data: UpdateMemberPermissions) => {
  try {
    const response = await Axios.put(`/organization/member-permissions`, data);
    if (!response?.data?.data)
      throw new Error('Error updating member permissions');
    return response.data.data;
  } catch (error) {
    throw error
  }
}

export const getBillingInfo = async () => {
  try {
    const response = await Axios.get('/organization/billing');
    if (!response?.data?.data)
      throw new Error('Error fetching billing info');
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export const getOrganizationCampaigns = async (offset: number, limit: number) => {
  try {
    const response = await Axios.get('/organization/campaigns?offset=' + offset + '&limit=' + limit);
    if (!response?.data?.data)
      throw new Error('Error fetching organization campaigns');
    return response.data.data;
  } catch (error) {
    return null;
  }
};

