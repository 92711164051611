import { CurrentBrand, VideoAdsRecord } from "types/global"
import Axios from "helpers/Interceptor"
import { formatCountries } from "./utils"

export const saveDraft = async (args: {
  dataToSend: Record<string, unknown>
  currentBrand: CurrentBrand
  campaignDraftData?: Record<string, unknown> | null
}) => {
  try {
    const { dataToSend, currentBrand, campaignDraftData } = args
    const campaignDraftId = campaignDraftData ? campaignDraftData.id : null
    const brandId = campaignDraftData
      ? campaignDraftData.brand_id
      : currentBrand.id
    const recordSelected = dataToSend.recordSelected as VideoAdsRecord | null
    const budgetValue = dataToSend.budgetValue as number
    const startDate = dataToSend.startDate as Date | null
    const endDate = dataToSend.endDate as Date | null
    const selectedAudiences = dataToSend.selectedAudiences
    const campaignType = dataToSend.campaignType
    const geographyType = dataToSend.geographyType as string
    const selectedTab = dataToSend.selectedTab
    const selectedCountries = dataToSend.selectedCountries as {
      label: string
      value: string
    }[]

    const response = await Axios.put(`campaign/brand/${brandId}/save-draft`, {
      id: campaignDraftId,
      campaign_metadata: {
        record: recordSelected,
        campaign_type: "increase_video_views",
        price_data: {
          price: budgetValue,
          name: recordSelected
            ? `YouTube Ads Campaign for "${recordSelected.name}"`
            : null,
          description: recordSelected
            ? `"${recordSelected.name}" (Increase Video Views Campaign)`
            : null,
        },
        logistics: {
          budget: budgetValue,
          startDate: startDate,
          endDate: endDate,
        },
        content: recordSelected
          ? {
              name: recordSelected.name,
              id: recordSelected.id,
              thumbnail_url: recordSelected.primaryThumbnail,
              channelId: recordSelected.channelId,
              channelTitle: recordSelected.channelTitle,
            }
          : null,
        targeting: selectedAudiences,
        targetingType: campaignType,
        geographies: {
          type: geographyType,
          countries: formatCountries(geographyType, selectedCountries),
        },
        campaign_platforms: ["youtube"],
        saved_draft_step: selectedTab,
      },
    })
    const data = response.data.data

    return { data, error: null }
  } catch (error) {
    console.log("error when saving draft: ", error)
    return { data: null, error }
  }
}
