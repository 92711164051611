import { Tooltip, TooltipProps } from '@mui/material';
import { useStyles } from './styles';

const CustomTooltip = (props: TooltipProps) => {
  const classes = useStyles();
  return (
    <Tooltip
      {...props}
      classes={{
        tooltip: classes.customTooltip
      }}
    />
  )
}

export default CustomTooltip;