import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    border: "1px solid #c8c7cc",
    borderRadius: 6,
    paddingBottom: theme.spacing(4.5),
    maxHeight: 458,
  },
  tableHead: {
    color: "#707070",
  },
  avatar: {
    width: 24,
    height: 24,
  },
  paddedGrid: {
    padding: "20px 32px 24px 32px",
  },
  withBorderBottom: {
    borderBottom: "1px solid #c8c7cc",
  },
  noBorderBottom: {
    borderBottom: "none",
  },
  topSpaced: {
    marginTop: theme.spacing(3),
  },
  highWidth: {
    maxWidth: 115,
  },
  midWidth: {
    maxWidth: 70,
  },
  lowWidth: {
    maxWidth: 21,
  },
  closeIconContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(0.75),
    cursor: "pointer",
  },
  dialogContent: {
    padding: 0,
    marginTop: theme.spacing(2),
    backgroundColor: "#FBFBFB",
  },
  root: {
    "& .MuiDialog-paper": {
      [theme.breakpoints.down(601)]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    "& .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody": {
      [theme.breakpoints.down(601)]: {
        maxWidth: "calc(100% - 16px)",
      },
    },
    "& .MuiDialog-paperFullWidth": {
      [theme.breakpoints.down(601)]: {
        width: "calc(100% - 16px)",
      },
    },
  },
  paper: {
    borderRadius: 8,
  },
}));

interface RoundContainerProps {
  width?: number;
  height?: number;
}

interface StyledLabelProps {
  fontSize?: number;
  fontWeight?: number;
  paddingTop?: number;
  marginTop?: number;
  lineHeight?: string;
  paddingLeft?: number;
  color?: string;
  cursor?: string;
}

export const Container = styled.div``;

export const RoundContainer = styled.div`
  width: ${({ width }: RoundContainerProps) => width || 72}px;
  height: ${({ height }: RoundContainerProps) => height || 72}px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 50%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
`;

export const StyledLabel = styled.p`
  font-size: ${({ fontSize }: StyledLabelProps) => fontSize || 18}px;
  font-weight: ${({ fontWeight }: StyledLabelProps) => fontWeight || 400};
  padding-top: ${({ paddingTop }: StyledLabelProps) => paddingTop || 0}px;
  padding-left: ${({ paddingLeft }: StyledLabelProps) => paddingLeft || 0}px;
  margin-top: ${({ marginTop }: StyledLabelProps) => marginTop || 0}px;
  line-height: ${({ lineHeight }: StyledLabelProps) => lineHeight};
  color: ${({ color }: StyledLabelProps) => color};
  cursor: ${({ cursor }: StyledLabelProps) => cursor};
`;

export const InfoLabelContainer = styled.div`
  margin-top: 24px;
`;

export const OpenRateIndicatorsContainer = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.nav`
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, 1);
`;

export const TopSpacedContainer = styled.div`
  margin-top: 24px;
`;

export const TableRowFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const LoadingContainer = styled.div`
  margin: 92px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

export const LinearProgressContainer = styled.div`
  @media (min-width: 1281px) {
    padding-left: 80px;
  }
  @media (min-width: 961px) and (max-width: 1280px) {
    padding-left: 48px;
  }
  @media (min-width: 601px) and (max-width: 960px) {
    padding-left: 32px;
  }
  @media (max-width: 600px) {
    padding-left: 24px;
  }
`;

export const Icon = styled.img``;

export const Label = styled.p``;

export const HeadContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  @media (min-width: 768px) {
    padding-top: 16px;
    padding-bottom: 80px;
  }
`;

export const MainNavbar = styled.div`
  position: relative;
  width: 100%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 1rem;
  text-align: center;
  @media (min-width: 1024px) {
    --tw-bg-opacity: 1;
    background-color: rgba(136, 0, 255, var(--tw-bg-opacity));
  }
`;

export const ViewTitle = styled.h4`
  text-align: center;
  @media (min-width: 1024px) {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
  }
`;

export const SubjectContainer = styled.div`
  margin: 16px 30px 0px 30px;
`;

export const EmailTemplatePreview = styled.div`
  border-top: 1px solid #707070;
  padding-top: 24px;
  padding-bottom: 40px;
`;

export default useStyles;
