import { BillingCycle } from "components/shareable/UpgradeToProModal/utils"
import Axios from "helpers/Interceptor"
import { PaymentMethods } from "types/global"
import * as Sentry from "@sentry/react"

/**
 * Creates a checkout for a subscription.
 * @param data
 * @param brandSlug
 */
export const redirectToOrganizationCheckout = async (
    data: {
        recurring_interval: BillingCycle
        payment_method: PaymentMethods.STRIPE
    },
    brandSlug?: string | null,
    is_trial?: boolean
) => {
    // pull rewardful data here and add it into the data object

    try {
        const response = await Axios.post(
            `/subscription/brand/${brandSlug}/checkout`,
            {
                ...data,
                is_trial,
                isOrganizationCheckout: true
            }
        )

        window.location = response.data.data.url
    } catch (error) {
        Sentry.captureException(error)
        console.error("error: ", error)
    }
}


/**
 * Completes organization setup with selected name + brand slug.
 * @param name
 * @param profiles
 */
export const finishOrganizationSetup = async (
    name: string,
    profiles: {
        brandSlug: string;
        currentTier: "pro" | "free";
        upgradeToPro: boolean;
    }[]
) => {
    try {
        const response = await Axios.post(
            `/organization/finish-setup`,
            {
                name: name,
                profiles: profiles
            }
        )

        console.log("response: ", response.data)
        return response.data
    } catch (error) {
        Sentry.captureException(error)
        console.error("error: ", error)
    }
}