import styled from "styled-components"
import { makeStyles, Theme } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme: Theme) => ({
  loginWithFBButton: {
    height: 51,
    textTransform: "none",
    justifyContent: "space-between",
    backgroundColor: "#8800ff",
    color: "#ffffff"
  },
  colorPrimary: {
    color: "#80f",
  },
  paper: { borderRadius: 12 },
  inputBorder: {
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  igInput: {
    backgroundColor: "#EDECF2",
    borderRadius: 6,
    width: '100%',
    height: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#8800FF',
      },
      '&:hover fieldset': {
        borderColor: '#8800FF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8800FF',
      },
    },
    "& input": {
      fontFamily: "DIN",
      padding: "16px",
    },
    "& input::placeholder": {
      fontFamily: "DIN",
    },
  },
  modalBackground: {
    [theme.breakpoints.down(701)]: {
      backgroundColor: "#F5F3F6",
    }
  },
  scrollPaper: {
    alignItems: "flex-start",
  },
  rootDialog: {
    "& .MuiDialog-paper": {
      maxWidth: "678px",
      [theme.breakpoints.down(701)]: {
        margin: 0,
        borderRadius: 0,
        height: "100%",
        padding: "4px",
        boxShadow: "none",
      },
      [theme.breakpoints.up(701)]: {
        margin: "auto",
        borderRadius: 12,
      },
    },
  },
  dividerTop: {
    borderTop: "1px solid #EDECF2",
  },
  dividerBottom: {
    borderBottom: "1px solid #EDECF2",
  },
  channelItemRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  channelImage: {
    width: 80,
  },
  formHelperText: {
    "&.MuiFormHelperText-root": {
      fontSize: 16
    }
  },
  connectButtonContainer: {
    width: 116,
  },
  warningContainer: {
    backgroundColor: "#FDF6EC",
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: "left",
  },
}))

export interface StyledLabelProps {
  fontSize?: number
  fontWeight?: number
  lineHeight?: string
  color?: string
  cursor?: string
  margin?: string
  padding?: string
  paddingLeft?: string
  marginLeft?: string
  fontFamily?: string
}

interface ModalContainerProps {
  minHeight?: string
  height?: string
  maxHeight?: string
  width?: string
  margin?: string
  padding?: string
  gap?: string
}


export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }: ModalContainerProps) => padding || "32px"};
  gap: ${({ gap }: ModalContainerProps) => gap || "48px"};
  height: ${({ height }: ModalContainerProps) => height || "484px"};
  min-height: ${({ minHeight }: ModalContainerProps) => minHeight};
  max-height: ${({ maxHeight }: ModalContainerProps) => maxHeight};
  width: ${({ width }: ModalContainerProps) => width || "673px"};
  margin: ${({ margin }: ModalContainerProps) => margin || "auto"};
  background: #ffffff;
  border-radius: 8px;
`

export const ModalConnectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 44px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`

export const ModalConnectButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 48px;
  background: ${({ color }: StyledLabelProps) => color || "#8800ff" };
  border-radius: 48px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border: 0.5px solid #8800ff;
  cursor: pointer;
`

export const ModalConnectButtonLabel = styled.label`
  height: 20px;
  font-family: "DIN";
  font-style: normal;
  font-weight: 416;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${({ color }: StyledLabelProps) => color || "#ffffff" };
  flex: none;
  order: 0;
  flex-grow: 0;
  line-height: 0;
  cursor: pointer;
`
