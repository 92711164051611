import { Theme, makeStyles } from '@material-ui/core';
import { MEDIA_BREAKPOINTS } from 'types/global';
import { SystemColors } from 'types/globalStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '&.MuiSelect-select': {
      fontFamily: 'DIN',
      fontSize: '16px',
    },
    '& > .MuiSelect-root': {
      paddingRight: '0px !important',
    },
    '&.MuiInput-underline::before': {
      border: 'none !important',
    },
    '&.MuiInput-underline::after': {
      border: 'none !important',
    },
    '&.Mui-focused > .MuiSelect-select:focus': {
      backgroundColor: `${SystemColors.PAPER_CONTAINER} !important`,
    },
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    borderBottom: `1px solid ${SystemColors.DIVIDER_LINE}`,
    [MEDIA_BREAKPOINTS.mobileView]: {
      flexDirection: 'column',
      gap: '8px',
    }
  },
  optionsContainer: {
    display: 'flex',
    gap: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [MEDIA_BREAKPOINTS.mobileView]: {
      justifyContent: 'space-between',
      gap: '8px',
    }
  },
}));