import { makeStyles, Radio } from "@material-ui/core";
import { Theme } from "@mui/material";
import { ChangeEvent } from "react";
import { SystemColors } from "types/globalStyles";

interface SymphonyRadioButtonProps {
    checked: boolean;
    classes?: any;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
    name?: string;
    id?: string;
    value?: string;
}


export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: SystemColors.ACCENT_COLOR,
        "&$checked": {
            color: `${SystemColors.ACCENT_COLOR} !important`,
        },
    },
    checked: {},
}))


export default function SymphonyRadioButton(props: SymphonyRadioButtonProps) {
    const {
        checked,
        onChange,
        disabled,
        name,
        value
    } = props

    const classes = useStyles()

    return (
        <Radio
            {...{ checked }}
            classes={{
                root: classes.root,
                checked: classes.checked,
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                onChange(checked)
            }}
            disabled={disabled}
            name={name}
            value={value}
        />)

}