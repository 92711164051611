import styled from "styled-components";
import { Theme, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const ButtonCheckoutContainer = styled.div`
  margin-top: 24px;
`;

export const StripeButton = withStyles((theme: Theme) => ({
  root: {
    width: "100%",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    textTransform: "none",
    borderRadius: 48,
    backgroundColor: "#8800FF",
    color: "#ffffff",
    height: 48,
    "&:hover": {
      backgroundColor: "#7500db",
      color: "#ffffff",
    },
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontSize: "16px",
  },
}))(Button);
