import dayjs from 'dayjs';
import { SystemColors } from 'types/globalStyles';
import { CampaignDetails } from 'types/global'

export const daysUntilRelease = (releaseDate: string | Date) => dayjs(releaseDate).diff(dayjs(), 'day')

export const getReleaseCaptionColor = ({ campaign_metadata }: CampaignDetails) => {
  const days = daysUntilRelease(campaign_metadata.endDate);
  return days < 0 ? SystemColors.ERROR_COLOR : SystemColors.SECONDARY_TEXT_COLOR;
}

export const daysUntilReleaseText = (releaseDate: string | Date) => {
  const days = daysUntilRelease(releaseDate);
  if (days === 0) return 'today';
  if (days === 1) return 'in 1 day';
  return `in ${days} days`;
}