import React from 'react';
import { StyledInfoSection, StyledInfoSectionInfoContainer, StyledInfoSectionMainLabel, StyledInfoSectionSubTitleText, StyledInfoSectionAskToggle, StyledInfoSectionMainText, StyledInfoSectionTooltipContainer } from './styles';
import UpgradeToProChip from 'components/shareable/UpgradeToProChip';
import { Switch } from '@material-ui/core';
import useStyles from "pages/post-auth/MarketingPage/PreSave/styles";
import { SymphonyTooltip } from 'styles/shared';

// Import other necessary components and types

interface InfoSectionProps {
    mainText: string;
    descriptionText?: string;
    isProUser: boolean;
    requiresPro: boolean;
    boostEnabled: boolean;
    isResponsiveView: boolean;
    showBorderTop?: boolean;
    showBorderBottom?: boolean;
    // to show a tooltip
    tooltipText?: string;
    tooltipIcon?: string;
    onChange: (ev: any) => void;
    tooltipOnClick?: () => void;
}

const BoostOption: React.FC<InfoSectionProps> = ({
    mainText,
    descriptionText,
    isProUser,
    requiresPro,
    boostEnabled,
    isResponsiveView,
    tooltipText,
    tooltipIcon,
    showBorderBottom,
    showBorderTop,
    onChange,
    tooltipOnClick,
}) => {

    const classes = useStyles();

    return (
        <StyledInfoSection
            borderBottom={showBorderBottom ? "1px solid #EDECF2" : "none"}
            borderTop={showBorderTop ? "1px solid #EDECF2" : "none"}
            paddingTop={isResponsiveView ? '0.7rem' : '1rem'}
            paddingBottom={isResponsiveView ? '0.7rem' : '1rem'}
            paddingLeft={isResponsiveView ? '1.25rem' : '0.5rem'}
            paddingRight={isResponsiveView ? '1.25rem' : '0.5rem'}
        >

            <StyledInfoSectionInfoContainer>
                <StyledInfoSectionMainLabel>
                    <StyledInfoSectionTooltipContainer>
                        <StyledInfoSectionMainText>{mainText}</StyledInfoSectionMainText>
                        {tooltipText && tooltipIcon && tooltipOnClick && (
                            <button className="ml-1.5 w-6 h-6"
                                onClick={tooltipOnClick}>
                                <SymphonyTooltip className="block w-6 h-6 cursor-pointer" title={
                                    tooltipText
                                } arrow>
                                    <img
                                        className="w-6 h-6"
                                        alt="question-mark-icon.svg"
                                        src={tooltipIcon}
                                    />
                                </SymphonyTooltip>
                            </button>
                        )}
                        {requiresPro && (
                            <div className="ml-1">
                                <UpgradeToProChip
                                    type="gem"
                                    color="purple"
                                    height={20}
                                    width={20}

                                />
                            </div>
                        )}
                    </StyledInfoSectionTooltipContainer>
                    <StyledInfoSectionSubTitleText>
                        {descriptionText}
                    </StyledInfoSectionSubTitleText>
                </StyledInfoSectionMainLabel>
                <StyledInfoSectionAskToggle>
                    <Switch
                        checked={requiresPro ? (isProUser ? boostEnabled : false) : boostEnabled}
                        onChange={onChange}
                        {...{ classes: { root: classes.autoPresaveSwitch } }} // Modify as needed
                    />
                </StyledInfoSectionAskToggle>
            </StyledInfoSectionInfoContainer>
        </StyledInfoSection>
    );
};

export default BoostOption;
