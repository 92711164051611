import { FunctionComponent, useContext, useEffect, useState } from "react";
import DropdownString from "components/shareable/DropDown/DropDownString";
import { Container } from "../../../styles";
import { Body2 } from "components/shareable/Typography";
import { SystemColors } from "types/globalStyles";
import { CURRENCY_SYMBOLS } from "../../../../../../constants";
import { saveCurrency } from 'services/symphonyApi';
import { CurrencyCodesType } from "types/global";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";

const mapCurrencyItems = () => {
  return Object.keys(CURRENCY_SYMBOLS).map((key) => {
    return `${key} (${CURRENCY_SYMBOLS[key as CurrencyCodesType]})`
  })
}

interface Props {
  initialValue: string
}

export const CurrencyDropdown: FunctionComponent<Props> = ({
  initialValue
}: Props) => {
  const { reloadBrand } = useContext(CurrentBrandContext)
  const [selectedValue, setSelectedValue] = useState<string>(initialValue)

  const handleOnSelectCurrency = async (value: string) => {
    const [currencyCode] = value.split('(')
    const currencyTypeValue = currencyCode.trim() as CurrencyCodesType
    const response = await saveCurrency({ code: currencyTypeValue, symbol: CURRENCY_SYMBOLS[currencyTypeValue] })
    if (response?.data?.code) setSelectedValue(`${currencyTypeValue} (${CURRENCY_SYMBOLS[currencyTypeValue]})`)
  }

  useEffect(() => {
    if (selectedValue !== initialValue) {
      reloadBrand()
      setSelectedValue(initialValue)
    }
  }, [selectedValue, initialValue])

  return (
    <Container
      padding="24px 0px"
      borderBottom={`1px solid ${SystemColors.DIVIDER_LINE}`}
      gap={8}
      display="flex"
      flexDirection="column"
    >
      <Body2 color={SystemColors.PRIMARY_TEXT_COLOR}>Currency</Body2>
      <DropdownString
        items={mapCurrencyItems()}
        setSelectedValue={handleOnSelectCurrency}
        value={selectedValue}
      />
    </Container>
  )
}