import styled from 'styled-components';

interface ContainerProps {
  flexDirection: string;
  justifyContent: string;
  alignItems: string;
  gap: string;
  width: string;
}

interface PropertyDataLabelProps {
  textAlign?: string;
}

interface AddUrlContainerProps {
  textAlign?: string;
  alignItems?: string;
}

interface RowContainerProps {
  flexDirection: string;
  justifyContent: string;
  padding: string;
  gap: string;
}

interface ChipContainerProps {
  justifyContent: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ContainerProps) => flexDirection};
  justify-content: ${({ justifyContent }: ContainerProps) => justifyContent};
  align-items: ${({ alignItems }: ContainerProps) => alignItems};
  gap: ${({ gap }: ContainerProps) => gap};
  width: ${({ width }: ContainerProps) => width};
`;

export const PropertyLabelTitle = styled.span`
  margin-right: auto;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-align: start;
  white-space: nowrap;
  width: fit-content;
`;

export const PropertyDataLabel = styled.div`
  font-size: 18px;
  line-height: 28px;
  text-align: ${({ textAlign }: PropertyDataLabelProps) => textAlign};
  overflow-wrap: anywhere;
  white-space: normal;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: RowContainerProps) => flexDirection};
  justify-content: ${({ justifyContent }: RowContainerProps) => justifyContent};
  align-items: center;
  gap: ${({ gap }: RowContainerProps) => gap};
  padding: ${({ padding }: RowContainerProps) => padding};
  border-bottom-width: 1px;
  width: 100%;
  :last-child {
    border-bottom-width: 0px;
  }
  @media (min-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }: ChipContainerProps) => justifyContent};
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Chip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 93px;
  white-space: nowrap;
  font-family: DIN;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const AddUrlContainer = styled.div<AddUrlContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ alignItems }: AddUrlContainerProps) => alignItems};
  gap: 4px;
  text-align: ${({ textAlign }: AddUrlContainerProps) => textAlign};
`;
