import millify from "millify"
import SmallGraph from "../../../components/dashboard/small"
import { LoadingIndicator } from "../../../components/Loader"
import { useContext, useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text } from 'recharts';
import TiktokConnect from "../../../components/connect/TikTok";
import { setUserProperty, track } from "../../../analytics";
import SoundcloudConnect from "../../../components/connect/Soundcloud";
import TwitterConnect from "components/connect/Twitter";
import { Backdrop, Fade, Modal, SvgIcon, useMediaQuery, useTheme } from "@material-ui/core";
import YouTubeConnectv2 from "components/connect/YouTubeConnectv2";
import InstagramBusinessDiscoveryConnect from "components/connect/InstagramBusinessDiscovery";
import { OutlinedButton, StyledLabel, SymphonyTooltip, TextButton } from "styles/shared";
import { MainChartBackground } from "./styles";
import { GraphTooltip, GrowthIndicatorMessage } from "./components/Graphs";
import QuestionMarkIcon from "assets/images/question-mark.svg";
import LargeGraphPopup, { CustomDateSelector } from "./components/LargeGraphPopup";
import dayjs from "dayjs";
import { DATE_OPTIONS } from "./utils";
import { KeyboardArrowDown } from "@material-ui/icons";
import DaysSelector from "components/shareable/DaysSelector";
import Option from "components/shareable/MenuProOption";
import { UpgradeModalContext } from "Hooks/UpgradeModalContext";
import DatePicker from "react-datepicker";
import "styles/react-datepicker.css";
import FacebookConnectv2 from "components/connect/FacebookConnectv2";
import SpotifyConnect from 'components/connect/connectModals/Spotify';
import { CurrentBrand, SpotifyArtist } from 'types/global';
import AlreadyExistingArtist from 'components/connect/connectModals/AlreadyExistingArtist';
import CardTitle from "components/shareable/CommonComponent/CardTitle";
import DefaultLearningIndicator, { LearningIndicatorType } from "components/shareable/LearningIndicator/default";
import { FanbaseMetricsType, siteIconsData } from "./Dashboard";
import Loaderv2 from "components/Loader/v2";
import AudiomackConnectv2 from "components/connect/Audiomack";
import DeezerConnectv2 from "components/connect/Deezer";
import { CurrentBrandContext } from "Hooks/CurrentBrandContext";
import { isBrandAdministrator } from "utils";


interface DashboardData {
    chartData: any,
    isYTLoginAdmin: boolean,
    overallData: any,
    fanbaseMetrics: FanbaseMetricsType
    isLoading: boolean,
    refreshDashboardData: () => void,
    setLoading: any,
    timeRange: string | null;
    setPlatformsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    updateBrandConnections: () => Promise<void>;
    isProUser: boolean;
    setTimeRange: any;
    selectedDate: {
        startDate: string;
        endDate: string;
    };
    setSelectedDate: any;
    isLoadingNewDate: boolean;
}

const overallChartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
        {
            data: [],
            fill: true,
            backgroundColor: "#E8F9E8",
            borderColor: "#19C221",
        },
    ],
};

const overallChartOptions = {
    scales: {
        x: {
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'right',
            align: 'start',
            labels: {
                boxWidth: 0,
            },
            title: {
                display: true,
                text: '5.9%'
            }
        },
    },
    maintainAspectRatio: true,
    responsive: true
};


export default function OverallDashboard(props: DashboardData) {

    const {
        chartData,
        isLoading,
        overallData,
        fanbaseMetrics,
        refreshDashboardData,
        setLoading,
        timeRange,
        setPlatformsModalOpen,
        updateBrandConnections,
        setTimeRange,
        isProUser,
        selectedDate,
        setSelectedDate,
        isYTLoginAdmin,
        isLoadingNewDate
    } = props

    const { handleOpenModal: handleOpenUpgradeToProModal } = useContext(UpgradeModalContext);

    const theme = useTheme();
    const { currentBrand, setCurrentBrand, reloadBrand } = useContext(CurrentBrandContext);
    const setResponsiveView = useMediaQuery(theme.breakpoints.down(701));
    const hasAdminAccess = isBrandAdministrator(currentBrand!);

    const [openDaysSelector, setOpenDaysSelector] = useState(false)
    const [alreadyExistArtist, setAlreadyExistArtist] = useState<SpotifyArtist>({} as SpotifyArtist);
    const [existingBrand, setExistingBrand] = useState<CurrentBrand>({} as CurrentBrand);
    const handleOpenDaysSelector = () => setOpenDaysSelector(true);

    const handleCloseDaysSelector = () => setOpenDaysSelector(false);

    const handleChangeDaysSelector = (
        event: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const value = event.target.value as string;
        const nonProInvalidValues = value === "last-30-days" || value === "last-3-months" || value === 'custom';
        const showUpgradeToProModal = !isProUser && nonProInvalidValues

        if (showUpgradeToProModal) {
            handleOpenUpgradeToProModal({
                source: "Dashboard - Overall Fanbase Date Picker"
            });
        } else {
            setTimeRange(value);
        }
    };

    const [showConnectBox, setShowConnectBox] = useState<any>(null)

    const handleShowAlreadyExistsModal = (artist: SpotifyArtist, existingBrand: CurrentBrand) => {
        setAlreadyExistArtist(artist)
        setExistingBrand(existingBrand)
        setShowConnectBox('spotify_already_exists')
    }
    const handleCloseAlreadyExistsModal = () => {
        setAlreadyExistArtist({} as SpotifyArtist)
        setExistingBrand({} as CurrentBrand)
        setShowConnectBox(false)
    }
    const handleGoBackAlreadyExistsModal = () => {
        setAlreadyExistArtist({} as SpotifyArtist)
        setExistingBrand({} as CurrentBrand)
        setShowConnectBox('spotify')
    }

    const [showLargeGraph, setShowLargeGraph] = useState<{
        name: string;
        platformName: string;
        statLabel: string;
        chartData: any;
        imageAsset: string;
        primaryValue: number;
        platformId: string;
        statIdentifier: string;
        shown: boolean;
        additionalContent?: JSX.Element | null | undefined

    } | null>(null)

    useEffect(() => {
        if (showLargeGraph && showLargeGraph.shown) {
            track('Viewed Large Graph',
                {
                    brand_id: currentBrand?.id,
                    brand_slug: currentBrand?.slug,
                    brand_name: currentBrand?.name,
                    ...showLargeGraph,
                    page: "Dashboard",

                });
        }
    }, [showLargeGraph])

    function onSave(name: string) {
        updateBrandConnections()
        track('Linked Platform',
            {
                brand_id: currentBrand?.id,
                brand_slug: currentBrand?.slug,
                brand_name: currentBrand?.name,
                platform: name,
                page: "Dashboard",
                from: "dashboard connect button"
            });
        setUserProperty(`${name}_linked`, true)
        reloadBrand()
        setShowConnectBox(false)
    }

    if (overallData) {
        overallChartData.datasets[0].data = overallData.chartData
        overallChartData.labels = overallData.labels
    }

    var showRefresh = true
    if (overallData && overallData.chartData) {
        for (var i = overallData.chartData.length - 1;i >= overallData.chartData.length - 3;i--) {
            if (overallData.chartData[i] === 0 || overallData.chartData[i] === null || typeof overallData.chartData[i] === 'undefined') {
                showRefresh = true
                break
            }
        }
    }

    var lastDaysString = "Last 7 Days"
    var interval: any = "preserveEnd"
    if (timeRange === 'last-30-days') {
        lastDaysString = "Last 30 Days"
        interval = 6
    } else if (timeRange === "last-3-months") {
        lastDaysString = "Last 3 Months"
        interval = 9
    } else if (timeRange === 'custom') {
        interval = "preserveStart"
        lastDaysString = `${dayjs(selectedDate.startDate).format('M/D')} to ${dayjs(selectedDate.endDate).format('M/D')}`
    }

    if (window.innerWidth < 576) {
        interval = "preserveEnd"
    }

    var overallChartHasData = overallData && overallData.chartData && overallData.chartData.length > 0
    var fanbaseSize = null
    if (overallChartHasData) {
        // get the last value from the overall fanbase chast
        const lastItem = overallData.chartData[overallData.chartData.length - 1]
        fanbaseSize = lastItem.value
    }

    function renderDateSelectorButtons() {
        return (
            <div className="w-10/12 mt-3 md:mt-0">
                <DaysSelector
                    options={[
                        {
                            id: "7-days-option",
                            component: <p>Last 7 Days</p>,
                            value: "last-7-days",
                        },
                        {
                            id: "30-days-option",
                            component: (
                                <Option
                                    {...{ isProUser }}
                                    mainContainerClassName="flex items-center gap-4 w-full"
                                    labelClassName={!isProUser ? "w-7/12" : ""}
                                    label="Last 30 days"
                                />
                            ),
                            value: "last-30-days",
                        },
                        {
                            id: "3-months-option",
                            component: (
                                <Option
                                    {...{ isProUser }}
                                    mainContainerClassName="flex items-center gap-4 w-full"
                                    labelClassName={!isProUser ? "w-7/12" : ""}
                                    label="Last 3 Months"
                                />
                            ),
                            value: "last-3-months",
                        },
                        {
                            id: "custom-option",
                            component: (
                                <Option
                                    {...{ isProUser }}
                                    mainContainerClassName="flex items-center gap-4 w-full"
                                    labelClassName={!isProUser ? "w-7/12" : ""}
                                    label="Custom"
                                />
                            ),
                            value: "custom",
                        }
                    ]}
                    selectProps={{
                        open: openDaysSelector,
                        className: "h-11",
                        variant: "outlined",
                        value: timeRange,
                        IconComponent: () => (
                            <div
                                className="cursor-pointer mr-2 lg:w-1/2"
                                onClick={handleOpenDaysSelector}
                            >
                                <KeyboardArrowDown viewBox="0 -2 26 26" />
                            </div>
                        ),
                        onOpen: handleOpenDaysSelector,
                        onClose: handleCloseDaysSelector,
                        onChange: handleChangeDaysSelector,
                    }}
                />
            </div>
        )
    }

    function renderCustomDatesPicker() {
        if (timeRange !== 'custom') return null
        return (
            <div className="mt-3 md:mt-0 flex items-center justify-center">
                <p className="mr-1">From:</p>
                <DatePicker
                    disabled={!isProUser}
                    filterDate={(date: Date) => {
                        return dayjs(date) < dayjs(selectedDate.endDate).subtract(1, 'day');
                    }}
                    selected={dayjs(selectedDate.startDate).toDate()}
                    onChange={(date) => {
                        setSelectedDate({
                            startDate: dayjs(date).format("YYYY-MM-DD"),
                            endDate: selectedDate.endDate
                        })
                    }}
                    customInput={<CustomDateSelector />}
                />
                <p className="mr-1">To:</p>
                <DatePicker
                    disabled={!isProUser}
                    minDate={dayjs(selectedDate.startDate).add(1, 'day').toDate()}
                    maxDate={dayjs().toDate()}
                    selected={dayjs(selectedDate.endDate).toDate()}
                    onChange={(date) => {
                        setSelectedDate({
                            endDate: dayjs(date).format("YYYY-MM-DD"),
                            startDate: selectedDate.startDate
                        })
                    }}
                    customInput={<CustomDateSelector />}
                />
            </div>
        )
    }

    function getOverallGraphTooltipText() {
        let text = `This graph shows the total size of your fanbase across your tracked social & streaming platforms.`
        if (isYTLoginAdmin) {
            text += ' Note: YouTube data is not included in this graph.'
        }
        return text
    }

    let restrictDateRange: DATE_OPTIONS[] | null = null
    let disableReport: boolean = false

    if (showLargeGraph && showLargeGraph.platformId === 'youtube' && isYTLoginAdmin) {
        restrictDateRange = ['last-30-days', 'last-7-days']
        disableReport = true
    }

    interface InputType {
        [key: string]: {
            totalFans: number;
            foreverSaves: number;
        };
    }

    interface ChartData {
        date: string;
        value: number;
    }

    const mapInputToCharts = (input: InputType): { totalFansChart: ChartData[], foreverSavesChart: ChartData[] } => {
        const totalFansChart: ChartData[] = [];
        const foreverSavesChart: ChartData[] = [];

        for (const [date, data] of Object.entries(input)) {
            totalFansChart.push({
                date: date,
                value: data.totalFans,
                // platform: "YourPlatform", // Adjust accordingly
                // platform_identifier: "totalFans",
                // percentageGrowth: 0 // Placeholder value, adjust as needed
            });

            foreverSavesChart.push({
                date: date,
                value: data.foreverSaves,
            });
        }

        return {
            totalFansChart,
            foreverSavesChart
        };
    }

    function renderFanbaseMetrics() {
        if (fanbaseMetrics && fanbaseMetrics.totalFanbase > 0) {
            const { totalFansChart, foreverSavesChart } = mapInputToCharts(fanbaseMetrics.dailyMetrics);

            interface GraphData {
                name: string;
                platformName: string;
                statLabel: string;
                chartData: ChartData[];
                imageAsset: string;
                primaryValue: number;
                platformId: string;
                statIdentifier: string;
                shown: boolean;
                changeValue: number;
                decommissionMessage?: string;
            }

            const graphData: GraphData[] = [
                {
                    name: 'fanbase',
                    platformName: 'Symphony',
                    statLabel: 'Connected Fans',
                    chartData: totalFansChart,
                    imageAsset: siteIconsData['fans'],
                    primaryValue: fanbaseMetrics.totalFanbase,
                    platformId: 'symphony',
                    statIdentifier: 'collected-fans',
                    shown: true,
                    changeValue: parseFloat(fanbaseMetrics.fanbaseGrowth)
                },
                {
                    name: 'forever-saves',
                    platformName: 'Symphony',
                    statLabel: 'Forever Saves',
                    chartData: foreverSavesChart,
                    imageAsset: siteIconsData['forever-saves'],
                    primaryValue: fanbaseMetrics.foreverPresaveFans,
                    platformId: 'symphony',
                    statIdentifier: 'forever-saves',
                    shown: true,
                    changeValue: parseFloat(fanbaseMetrics.foreverPresaveFansGrowth)
                }
            ];

            const renderGraphs = () => {
                return graphData.map((graph, index) => (
                    <SmallGraph
                        hideChangeValueAtZero={true}
                        key={graph.name}
                        index={index}
                        expandGraph={() => setShowLargeGraph({
                            ...graph,
                            additionalContent: null
                        })}
                        platformName={graph.platformName}
                        imageAsset={graph.imageAsset}
                        primaryValue={graph.primaryValue ? millify(graph.primaryValue) : 0}
                        subtitle={graph.statLabel}
                        graphOptions={{}}
                        changeValue={graph.primaryValue ? graph.changeValue : 0}
                        graphData={graph.chartData}
                    />
                ));
            };
            return (
                <>
                    {renderGraphs()}
                </>
            );
        }
        return null
    }

    return (
        <>
            {!setResponsiveView && (<CardTitle
                padding="12px 8px 24px 8px"
                useDefaultPadding={false}
                title="Fanbase Growth"
                subtitle={"Track how your fanbase is growing across the board."}
                // mobile={setResponsiveView}
                additionalContent={
                    <DefaultLearningIndicator
                        text="Learn how to use your Dashboard"
                        type={LearningIndicatorType.DASHBOARD} />
                }
            />)}
            <div className="mx-auto relative">
                {!isLoading ? overallChartHasData ? (
                    <MainChartBackground>
                        <div className="mx-4 flex flex-row justify-between">
                            <div className="">
                                <div className=" flex items-center mb-2">
                                    <p className="text-lg md:text-xl">Your Fanbase</p>
                                    <SymphonyTooltip className=" ml-2 w-6 h-6" title={getOverallGraphTooltipText()} arrow>
                                        <img
                                            className="w-6 h-6"
                                            alt="question-mark-icon.svg"
                                            src={QuestionMarkIcon}
                                        />
                                    </SymphonyTooltip>
                                </div>
                                {fanbaseSize && (
                                    <div className="flex items-center mb-4">
                                        <div className="flex flex-row align-items justify-center gap-2">
                                            <p
                                                className="text-4xl md:text-4xl 2xl:text-5xl font-semibold"
                                                style={{ lineHeight: 0.8 }}
                                            >
                                                {millify(fanbaseSize)}
                                            </p>
                                            {window.innerWidth < 768 && isLoadingNewDate && (
                                                <div className="mr-3 flex items-center justify-center">
                                                    <Loaderv2 color="default" height={18} width={18} />
                                                </div>
                                            )}
                                        </div>
                                        {window.innerWidth >= 768 && overallChartHasData ? (
                                            <GrowthIndicatorMessage
                                                percentage={overallData && overallData.percentage ? overallData.percentage : null} />
                                        ) : null}
                                    </div>
                                )}
                            </div>
                            {window.innerWidth < 768 && overallChartHasData ? (
                                <div className="ml-auto flex items-center justify-center">
                                    <GrowthIndicatorMessage
                                        percentage={overallData && overallData.percentage ? overallData.percentage : null} />
                                </div>
                            ) : null}
                            {window.innerWidth >= 768 && (
                                <div className="flex-row flex items-center">
                                    {isLoadingNewDate ? (
                                        <div className="mr-3">
                                            <Loaderv2 color="default" height={18} width={18} />
                                        </div>
                                    ) : null}
                                    {renderCustomDatesPicker()}
                                    {renderDateSelectorButtons()}
                                </div>)}
                        </div>
                        <ResponsiveContainer className="mx-auto" width="100%" height={window.innerWidth < 576 ? 175 : window.innerHeight * 0.3} minHeight="100px">
                            <AreaChart
                                width={500}
                                height={300}
                                data={overallData.chartData}
                                margin={{
                                    top: 15,
                                    right: 0,
                                    left: window.innerWidth >= 768 ? 20 : 0,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid stroke="#8800FF29"
                                    strokeWidth={1.5}
                                    strokeDasharray="8 8" />
                                <XAxis dy={12}
                                    axisLine={false}
                                    dataKey="date"
                                    tickLine={false}
                                    minTickGap={window.innerWidth < 768 ? 20 : 30}
                                    tickFormatter={(value: any, index: number) => {
                                        return dayjs(value).format("MM/DD")
                                    }} />
                                <YAxis
                                    mirror={window.innerWidth < 768}
                                    tick={(e) => {
                                        const { payload: { value } } = e;
                                        const color = value === "yourdata" ? "red" : "#666";
                                        e["fill"] = color;
                                        if (window.innerWidth >= 768) {
                                            return (
                                                <Text
                                                    {...e}
                                                    style={{
                                                        zIndex: 99
                                                    }}>
                                                    {millify(value, {
                                                        precision: 2
                                                    })}
                                                </Text>
                                            )
                                        } else {
                                            return (<>
                                                <rect></rect>
                                                <defs>
                                                    <filter id="rounded-corners-overall" x="-5%" width="110%" y="0%" height="100%">
                                                        <feFlood flood-color="#fff" />
                                                        {/* <feGaussianBlur stdDeviation="2" /> */}
                                                        <feComponentTransfer>
                                                            <feFuncA type="table" tableValues="1 1 1 1" />
                                                        </feComponentTransfer>
                                                        <feComponentTransfer>
                                                            <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                                                        </feComponentTransfer>
                                                        <feComposite operator="over" in="SourceGraphic" />
                                                    </filter>
                                                </defs>
                                                <Text
                                                    filter="url(#rounded-corners-overall)"
                                                    {...e}
                                                    style={{
                                                        transform: "translateX(-4px)",
                                                        zIndex: 99
                                                    }}>
                                                    {millify(value, {
                                                        precision: 2
                                                    })}
                                                </Text>
                                            </>
                                            )
                                        }
                                    }}
                                    tickCount={window.innerWidth < 768 ? 3 : 4}
                                    axisLine={false}
                                    minTickGap={window.innerWidth < 768 ? 30 : 20}
                                    tickLine={false}
                                    allowDecimals={false}
                                    domain={['auto', 'auto']}
                                />
                                <Tooltip
                                    cursor={{ stroke: '#8800FF', strokeWidth: 2 }}
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <GraphTooltip
                                                    title={dayjs(label).format('dd MM/DD')}
                                                    value={payload[0].value!.toLocaleString('en')}
                                                />
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <defs>
                                    <linearGradient id="splitColor"
                                        x1="0" y1="0" x2="0" y2="1"
                                    >
                                        <stop stopColor="#8800FF" stopOpacity={0.4} />
                                        <stop offset="1" stopColor="#8800FF" stopOpacity={0.05} />
                                    </linearGradient>
                                </defs>
                                <Area type="monotone" dataKey="value"
                                    strokeWidth={2}
                                    stroke="#8800FF" fill="url(#splitColor)" activeDot={{ r: 8 }} />
                            </AreaChart>
                        </ResponsiveContainer>
                        {window.innerWidth < 768 && (
                            <div className="flex items-center justify-center flex-col">
                                {renderDateSelectorButtons()}
                                {renderCustomDatesPicker()}
                            </div>
                        )}
                    </MainChartBackground>
                ) : (<div className="relative">
                    <div className="z-10 w-8/12 md:w-4/12 flex-col absolute top-1/2 left-1/2 flex items-center transform-gpu -translate-x-1/2 -translate-y-1/2 ">
                        <LoadingIndicator height="20px" color="black" />
                        <p className="text-sm md:text-base  text-center">We're still gathering your fanbase data. Check back tomorrow for an update.</p>
                    </div>
                    <img className={`mx-auto w-full h-40 md:h-56 opacity-70`} src={window.innerWidth < 576 ? require("../../../assets/images/empty-state/mobile-graph-preview.png").default : require("../../../assets/images/empty-state/desktop-graph-preview.png").default} alt="No Chart Data" />
                </div>) : null}
            </div>
            {setResponsiveView && (
                <div className="mt-4"><DefaultLearningIndicator
                    text="Learn how to use your Dashboard"
                    type={LearningIndicatorType.DASHBOARD} />
                </div>
            )}
            <div className="flex flex-row justify-between mt-5 md:mt-8">
                <div className="items-start flex-col justify-center">
                    <p className="text-xl" >Platforms ({lastDaysString})</p>
                    <p className="text-md w-10/12 md:w-full text-gray-500"
                    >The total change in fans {timeRange === 'custom' ? 'from ' : 'over the '} {lastDaysString.toLowerCase()}.</p>
                </div>
                {hasAdminAccess &&
                    <div className="flex items-center justify-center">
                        <TextButton
                            onClick={() => setPlatformsModalOpen(true)}
                            aria-label="edit" >
                            <StyledLabel color="#707070" fontSize={14} fontWeight={600} margin="auto 4px auto auto">Edit</StyledLabel>
                            <SvgIcon viewBox="0 0 24 25"
                                style={{
                                    fontSize: 32
                                }}
                            >
                                <path fill="none" d="M12 15.0703C13.6569 15.0703 15 13.7272 15 12.0703C15 10.4135 13.6569 9.07031 12 9.07031C10.3431 9.07031 9 10.4135 9 12.0703C9 13.7272 10.3431 15.0703 12 15.0703Z" stroke="#707070" strokeWidth="2" strokeLinecap="round" />
                                <path fill="none" fillRule="evenodd" clipRule="evenodd" d="M10.069 3.43312C10.7151 1.61604 13.2849 1.61604 13.931 3.43311C14.338 4.57741 15.6451 5.11883 16.742 4.59744C18.4837 3.76949 20.3008 5.58656 19.4729 7.32834C18.9515 8.42522 19.4929 9.73234 20.6372 10.1393C22.4543 10.7854 22.4543 13.3552 20.6372 14.0013C19.4929 14.4083 18.9515 15.7154 19.4729 16.8123C20.3008 18.554 18.4837 20.3711 16.742 19.5432C15.6451 19.0218 14.338 19.5632 13.931 20.7075C13.2849 22.5246 10.7151 22.5246 10.069 20.7075C9.66203 19.5632 8.35491 19.0218 7.25803 19.5432C5.51625 20.3711 3.69918 18.554 4.52713 16.8123C5.04852 15.7154 4.5071 14.4083 3.3628 14.0013C1.54573 13.3552 1.54573 10.7854 3.36281 10.1393C4.5071 9.73234 5.04852 8.42522 4.52713 7.32834C3.69918 5.58656 5.51625 3.76949 7.25803 4.59744C8.35491 5.11883 9.66203 4.57741 10.069 3.43312Z" stroke="#707070" strokeWidth="2" strokeLinecap="round" />
                            </SvgIcon>
                        </TextButton>
                    </div>
                }
            </div>
            <div className="flex flex-wrap mt-1">
                {renderFanbaseMetrics()}

                {chartData
                    .sort((a: any, b: any) => {
                        if (a.decommissionMessage && !b.decommissionMessage) return 1;
                        if (b.decommissionMessage && !a.decommissionMessage) return -1;
                        if (a.needsConnection && !b.needsConnection) return 1;
                        else if (b.needsConnection && !a.needsConnection) return -1;

                        return 0;
                    })

                    .sort((a: {
                        chartData: any
                    }, b: {
                        chartData: any
                    }) => {
                        const aHasData = a.chartData && a.chartData.data.length > 0
                        const bHasData = b.chartData && b.chartData.data.length > 0
                        if (!aHasData && (bHasData)) return 1
                        else if (!bHasData && (aHasData)) return -1
                        else {
                            return 0
                        }
                    })

                    .map((c: any, idx: number) => {
                        const {
                            name,
                            platformId,
                            platformName,
                            statIdentifier,
                            chartData,
                            chartOptions,
                            secondaryData,
                            imageAsset,
                            statLabel,
                            needsConnection,
                            decommissionMessage = null
                        } = c

                        if (needsConnection) {
                            if (decommissionMessage) return null
                            return <div key={`${platformId}-${statIdentifier}`} className="relative my-1.5 border rounded-md flex flex-col w-full overflow-hidden"
                                style={{
                                    marginLeft: idx % 2 === 0 ? 0 : (window.innerWidth > 1200 ? "0.5%" :
                                        window.innerWidth >= 768 ? '0.5%' : "0%"),
                                    marginRight: idx % 2 === 1 ? 0 : (window.innerWidth > 1200 ? "0.5%" :
                                        window.innerWidth >= 768 ? '0.5%' : "0%"),
                                    width:
                                        window.innerWidth > 1200 ? "49%" :
                                            window.innerWidth >= 768 ? '49%' : "100%"
                                }}>
                                <div className="flex w-full justify-between items-center py-2 px-4">
                                    <div className="flex flex-row">
                                        <img
                                            className="flex-shrink-0"
                                            width={50}
                                            src={
                                                imageAsset
                                            }
                                            alt=""
                                        />
                                        <div className="ml-2 text-left self-center">
                                            <p className="text-md font-medium  flex items-center">Track {platformName} {statLabel}</p>
                                            <p className="text-xs">Connect your {platformName} account to track your {statLabel}.</p>
                                        </div>
                                    </div>
                                </div>
                                {hasAdminAccess &&
                                    <div className="m-auto my-2">
                                        <OutlinedButton onClick={() => setShowConnectBox(platformId)}>Connect</OutlinedButton>
                                    </div>
                                }
                            </div>
                        } else {
                            return <SmallGraph
                                index={idx}
                                decommissionMessage={decommissionMessage}
                                expandGraph={() => setShowLargeGraph({
                                    ...c,
                                    chartData: chartData.data ? chartData.data : chartData,
                                    primaryValue: secondaryData.currentGrowthValue,
                                    shown: true
                                })}
                                key={`${platformId}-${statIdentifier}`}
                                platformName={platformName}
                                imageAsset={imageAsset}
                                primaryValue={secondaryData.currentGrowthValue && millify(secondaryData.currentGrowthValue)}
                                subtitle={statLabel}
                                graphOptions={chartOptions}
                                changeValue={secondaryData.percentage ? secondaryData.percentage.toFixed(1) : 0}
                                graphData={chartData.data}
                            />
                        }
                    })}
            </div>
            {
                showRefresh ? <div className="text-center">
                    <button onClick={refreshDashboardData} className="btn-primary rounded-full sm:md-20 lg:mb-4 py-2 lg:py-3">Refresh Data</button>
                </div> : null
            }

            <Modal
                aria-labelledby="large-graph"
                aria-describedby="large-graph"
                className={"flex items-center justify-center"}
                open={showLargeGraph && showLargeGraph.shown ? true : false}
                onClose={() => setShowLargeGraph({
                    ...showLargeGraph!,
                    shown: false
                })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={showLargeGraph && showLargeGraph.shown ? true : false}>
                    <LargeGraphPopup
                        disableReport={disableReport}
                        restrictDateRange={restrictDateRange}
                        brand={currentBrand!}
                        platformId={showLargeGraph ? showLargeGraph.platformId : null}
                        statIdentifier={showLargeGraph ? showLargeGraph.statIdentifier : null}
                        primaryValue={showLargeGraph ? showLargeGraph.primaryValue : null}
                        chartData={showLargeGraph && showLargeGraph.chartData ? (showLargeGraph.chartData.data ? showLargeGraph.chartData.data : showLargeGraph.chartData) : null}
                        imageAsset={showLargeGraph ? showLargeGraph.imageAsset : null}
                        platformName={showLargeGraph ? showLargeGraph.platformName : null}
                        statLabel={showLargeGraph ? showLargeGraph.statLabel : null}
                        onClose={() => setShowLargeGraph({
                            ...showLargeGraph!,
                            shown: false
                        })}
                        additionalContent={showLargeGraph ? showLargeGraph.additionalContent : null}
                    />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={"flex items-center justify-center"}
                open={showConnectBox}
                onClose={() => setShowConnectBox(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 200,
                }}
            >
                <Fade in={showConnectBox}>
                    <div className="w-full h-full overflow-auto md:h-auto md:w-4/6 lg:w-2/6 bg-white md:rounded-md">
                        {showConnectBox === 'youtube' && (
                            <YouTubeConnectv2
                                onSave={() => onSave('youtube')}
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand!}
                                selected={{
                                    ...currentBrand?.connections.youtube_channel
                                }}
                            />
                        )}

                        {showConnectBox === 'audiomack' && (
                            <AudiomackConnectv2
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand!}
                                open={true}
                                selected={currentBrand && currentBrand.connections.audiomack ? currentBrand.connections.audiomack.url : null}
                                onSave={() => onSave('audiomack')}
                            />
                        )}

                        {showConnectBox === 'deezer' && (
                            <DeezerConnectv2
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand!}
                                open={true}
                                selected={currentBrand && currentBrand.connections.deezer ? currentBrand.connections.deezer.id : null}
                                onSave={() => onSave('deezer')}
                            />
                        )}

                        {showConnectBox === 'tiktok' && (
                            <TiktokConnect
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand}
                                selected={currentBrand && currentBrand.connections.tiktok ? currentBrand.connections.tiktok : null}
                                localSave={false}
                                onSave={() => onSave('tiktok')}

                                showSave={true}
                            />
                        )}

                        {showConnectBox === 'soundcloud' && (
                            <SoundcloudConnect
                                open={true}
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand!}
                                selected={currentBrand?.connections?.soundcloud_artist_page?.id || null}
                                onSave={() => onSave('soundcloud')}
                            />
                        )}



                        {showConnectBox === 'instagram' && (
                            <InstagramBusinessDiscoveryConnect
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand}
                                selected={currentBrand?.connections.business_discovery_instagram}
                                localSave={false}
                                onSave={() => onSave('instagram')}
                                showSave={true}
                            />
                        )}

                        {showConnectBox === 'facebook' && (
                            <FacebookConnectv2
                                autoconnectInstagram={true}
                                closeModal={() => setShowConnectBox(false)}
                                artist={currentBrand!}
                                selected={currentBrand?.connections.facebook_page}
                                onSave={() => onSave('facebook')}
                            />
                        )}

                        {showConnectBox === 'twitter' && (
                            <TwitterConnect
                                onCloseModal={() => setShowConnectBox(false)}
                                currentBrand={currentBrand!}
                                onSave={() => onSave('twitter')}
                                currentUserData={currentBrand && currentBrand.connections.twitter ? {
                                    username: currentBrand.connections.twitter.screen_name,
                                    url: currentBrand.connections.twitter.url
                                } : undefined}
                            />
                        )}

                        {showConnectBox === 'spotify' && (
                            <SpotifyConnect
                                goBack={() => setShowConnectBox(false)}
                                closeModal={() => setShowConnectBox(false)}
                                open={showConnectBox === 'spotify'}
                                localSave={false}
                                onSave={() => {
                                    onSave('spotify')
                                }}
                                handleShowAlreadyExistsModal={handleShowAlreadyExistsModal}
                                currentBrand={currentBrand}
                            />
                        )}

                        {showConnectBox === 'spotify_already_exists' && (
                            <AlreadyExistingArtist
                                open={showConnectBox === 'spotify_already_exists'}
                                goBack={handleGoBackAlreadyExistsModal}
                                closeModal={handleCloseAlreadyExistsModal}
                                artist={alreadyExistArtist}
                                existingBrand={existingBrand}
                                setCurrentBrand={setCurrentBrand}
                            />
                        )}
                    </div>

                </Fade>
            </Modal>

        </>
    )
}