import styled from 'styled-components';

export enum Severity {
    WARNING = 'warning',
    ERROR = 'error',
}

interface MainContainerProps {
    severity: Severity;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  padding: 16px 24px;
  border-radius: 8px;
  background: ${({ severity }) =>
        severity === Severity.WARNING
            ? 'linear-gradient(0deg, rgba(228, 144, 13, 0.08), rgba(228, 144, 13, 0.08)), #FFFFFF;'
            : 'linear-gradient(0deg, rgba(255, 0, 0, 0.16), rgba(255, 0, 0, 0.16)), #FFFFFF;'
    };
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;
