import styled from 'styled-components';

//TODO: Remove all calc in this file

interface MainContainerProps {
  backgroundColor: string;
  colorContrast: string;
}

interface InformationProps {
  width?: string;
}


interface ButtonsContainerProps {
  flexDirection?: string;
  gap?: string;
}

export const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(4px * 1) calc(3px * 1);
  gap: calc(4px * 1);
  width: calc(38px * 1);
  height: calc(64px * 1);
  background-color: ${({ backgroundColor }: MainContainerProps) => backgroundColor};
  border: calc(1px * 1) solid ${({ colorContrast }: MainContainerProps) => colorContrast}30;
  border-radius: calc(6px * 1);
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: calc(2px * 1);
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: calc(2px * 1);
  width: 100%;
`;

interface InformationProps {
  backgroundColor: string;
}

export const Information = styled.div`
  background: ${({ backgroundColor }: InformationProps) => backgroundColor};
  opacity: 0.5;
  border-radius: calc(2px * 1);
  height: calc(4px * 1);
  width: ${({ width }: InformationProps) => width || "100%"};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }: ButtonsContainerProps) => flexDirection};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: calc(${({ gap }: ButtonsContainerProps) => gap} * 1);
`;

interface BoxesButtonProps {
  backgroundColor: string;
  logoColor: string;
}

export const BoxesButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(7px * 1);
  background: ${({ backgroundColor }: BoxesButtonProps) => backgroundColor};
  border-radius: calc(1px * 1);
  :after {
    height: calc(3px * 1);
    width: calc(7px * 1);
    background: ${({ logoColor }: BoxesButtonProps) => logoColor};
    border-radius: calc(2px * 1);
    content: "";
  }
`;

interface StackedButtonProps {
  backgroundColor: string;
  logoColor: string;
}

export const StackedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(7px * 1);
  background-color: ${({ backgroundColor }: StackedButtonProps) => backgroundColor}20;
  :after {
    width: calc(8px * 1);
    height: calc(3px * 1);
    background: ${({ logoColor }: StackedButtonProps) => logoColor};
    border-radius: calc(32px * 1);
    content: "";
  }
`;

interface TraditionalButtonProps {
  logoColor: string;
  backgroundColor: string;
}

export const TraditionalButton = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: calc(7px * 1);
  border-bottom: calc(0.5px * 1) solid ${({ logoColor }: TraditionalButtonProps) => logoColor};
  padding: 0 calc(2px * 1);
  :before {
    width: calc(8px * 1);
    height: calc(3px * 1);
    background: ${({ logoColor }: TraditionalButtonProps) => logoColor};
    border-radius: calc(32px * 1);
    content: "";
  }
  :after {
    width: calc(8px * 1);
    height: calc(3px * 1);
    background:${({ backgroundColor }: TraditionalButtonProps) => backgroundColor};
    border-radius: calc(32px * 1);
    content: "";
  }
`;

export const Image = styled.img`
  width: 65%;
  height: auto;
  border-radius: calc(1px * 1);
`;
