import { Body2 } from 'components/shareable/Typography';
import { FunctionComponent } from 'react';
import { SystemColors } from 'types/globalStyles';
import { StyledChip } from '../../ConversionsSteps/FacebookAdAccount/SelectAdAccountModal/AccountsList/AdAccountOption/styles';
import { FacebookBusinessManager } from 'types/global';

interface BusinessChipProps {
  business?: {
    id: string;
    name: string;
  } | undefined;
  assetId: string;
  isDisabled: boolean;
  fallbackText?: string;
}

export const BusinessChip: FunctionComponent<BusinessChipProps> = ({
  business,
  assetId,
  isDisabled,
  fallbackText
}: BusinessChipProps) => {
  const handleIsDisabled = isDisabled
    ? SystemColors.DISABLED_TEXT_COLOR
    : undefined;

  if (business && business.name) {
    return (
      <StyledChip>
        <Body2 color={handleIsDisabled}>Business: {business.name} (ID: {assetId})</Body2>
      </StyledChip>
    )
  } else {
    // this is a personal account, so we should let the user know
    let text = fallbackText ? fallbackText : 'Personal (Not linked to a Meta Business Manager)'

    return (
      <StyledChip>
        <Body2 color={handleIsDisabled}>{text}</Body2>
      </StyledChip>
    )
  }


}
