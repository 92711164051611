import { useState } from "react"

export interface Data {
  stepValue: string | null
  setStepValue: (value: string | null) => void
  loading: boolean
  setLoading: (loading: boolean) => void
  error: boolean
  setError: (error: boolean) => void
}

const useCommonStates = (step: string, initialValue?: string | null) => {
  const [stepValue, setStepValue] = useState(initialValue || null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  return {
    [step]: {
      stepValue,
      setStepValue,
      loading,
      setLoading,
      error,
      setError,
    },
  }
}

export default useCommonStates
