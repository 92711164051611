import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles(() => ({
  linkButtonIcon: {
    width: '20px',
    height: '20px',
  },
}))

export const StyledBusinessLi = styled.li`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

export const BusinessDetailsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
`

export const StyledArtistImgDiv = styled.div`
  width: 32px;
  height: 32px;
`

export const StyledArtistImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  object-fit: cover;
`

export const StyledLinkButtonDiv = styled.div`
  span {
    display: flex;
    gap: 4px
  }
`
