import SymphonyPurpleLogo from 'assets/images/logos/symphony-badge-purple.png';
import SymphonyBlackLogo from 'assets/images/logos/symphony-badge-black.png';

interface Props {
    width: number;
    height: number;
    variant: 'purple' | 'black';
}

export const SymphonyLogo = ({
    width,
    height,
    variant,
}: Props) => {
    switch (variant) {
        case 'purple':
            return <img src={SymphonyPurpleLogo} height={height} width={width} />
        case 'black':
            return <img src={SymphonyBlackLogo} height={height} width={width} />
        default:
            return <img src={SymphonyBlackLogo} height={height} width={width} />
    }
}