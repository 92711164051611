import { Headline3 } from 'components/shareable/Typography';
import { SystemColors } from 'types/globalStyles';
import { TeamMemberList } from '../TeamMemberList';
import { Divider, TextField } from '@material-ui/core';
import PrimaryButton from 'components/shareable/PrimaryButton';
import useModal from 'Hooks/useModal';
import { AddMemberModal } from '../AddMemberModal';
import { AvailableSeatBadge } from '../AvailableSeatBadge';
import { useContext, useState } from 'react';
import { TeamPlanContext } from '../../hooks/TeamMembersContext';
import { ReactComponent as SearchIcon } from "assets/images/search-icon-2.svg";
import { useStyles } from './styles';
import { MAX_ADDITIONAL_USERS_WITH_TEAM_PRO } from '../../../../../constants'

export const TeamTab = () => {
  const { teamMembers } = useContext(TeamPlanContext);
  const addMemberModal = useModal();
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>();

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex gap-2 items-center justify-between'>
        <Headline3 color={SystemColors.PRIMARY_TEXT_COLOR}>Team</Headline3>
        <div className='flex gap-4 items-center'>
          <TextField
            value={searchTerm}
            type="search"
            variant="outlined"
            className={classes.input}
            placeholder="Search"
            onChange={(event) => setSearchTerm(event.target.value)}
            InputProps={{ startAdornment: (<SearchIcon height={24} width={24} className="mr-3" />) }}
          />
          <PrimaryButton text='Add member' onClick={addMemberModal.openModal} height='39px' />
        </div>
      </div>
      <Divider />
      <div className='flex flex-col'>
        <TeamMemberList searchTerm={searchTerm} />
        {teamMembers.length < MAX_ADDITIONAL_USERS_WITH_TEAM_PRO && <AvailableSeatBadge onAddMember={addMemberModal.openModal} currentSeats={teamMembers.length} />}
      </div>
      {addMemberModal.open && (
        <AddMemberModal
          onClose={addMemberModal.closeModal}
        />
      )}
    </div>
  );
};